import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomerCategory, CustomerCategoryData} from './customer-category.model';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';

@Injectable({
    providedIn: 'root',
})
export class CustomerCategoryService {
    protected resourceUrl: string;

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}customer-categories`;
    }

    /**
     * Get all customer categories
     */
    public getCategories(): Observable<CustomerCategoryData[]> {
        return this.builder.get<CustomerCategoryData[]>(this.resourceUrl)
            .request().pipe(
                map(res => {
                    return this.mapCustomerCategory(res.data);
                }),
            );
    }

    public getCategory(value: string): Observable<CustomerCategory> {
        return this.builder.get<CustomerCategoryData>(`${this.resourceUrl}/${value}`)
            .request().pipe(
                map(res => this.createCustomerCategory(res.data)),
            );
    }

    public mapCustomerCategory(data: CustomerCategoryData[]): CustomerCategory[] {
        return data.map(item => this.createCustomerCategory(item));
    }

    public createCustomerCategory(data: CustomerCategoryData): CustomerCategory {
        return new CustomerCategory(data);
    }
}
