<div class="info-res" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
    <div fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon
            fxLayoutAlign="center center"
            fxLayout="row"
            fxFlex="0 0 auto"
            fontSet="klickdata"
            [fontIcon]="ResourceTypes.fontIconType(resource.type_id)"
        ></mat-icon>
        <span i18n *ngIf="displayLabel">Related {{ ResourceTypes.label(resource.type_id) }}:</span>
    </div>
    <span class="inf">{{ resource.title }}</span>
</div>
