import { HttpClient } from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    ChangeDetectorRef,
    ViewChild,
    Input,
    AfterViewInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface GeoLocation {
    latitude: number;
    longitude: number;
}

@Component({
    selector: 'app-geo-location-control',
    templateUrl: './geo-location-control.component.html',
    styleUrls: ['./geo-location-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GeoLocationControlComponent),
            multi: true,
        },
    ],
})
export class GeoLocationControlComponent implements ControlValueAccessor, AfterViewInit {
    mapOptions: google.maps.MapOptions = {
        center: { lat: 60.1282, lng: 18.6435 },
        zoom: 8,
    };
    markerPosition: google.maps.LatLngLiteral;
    markerOptions: google.maps.MarkerOptions = { draggable: false };
    // @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
    @ViewChild(MapMarker) marker: MapMarker;
    geocoder: google.maps.Geocoder;
    public addressSub: Subject<string> = new Subject<string>();
    public address: string;
    @Input() infoWindowTitle = $localize`Event Address`;
    @Output() onMarkerClick: EventEmitter<string> = new EventEmitter<string>();

    constructor(protected cdr: ChangeDetectorRef, protected http: HttpClient) {}
    ngAfterViewInit(): void {}
    addMarker(event: google.maps.MapMouseEvent) {
        this.markerPosition = event.latLng.toJSON();
        // setTimeout(() => {
        this.showAddress();
        // }, 300);
        this.notify();
    }

    // openInfoWindow(marker: MapMarker) {
    //     this.infoWindow.open();
    // }
    showAddress() {
        // This needs billing
        // var latLng = new google.maps.LatLng(this.markerPosition.lat, this.markerPosition.lng);
        // var geocoder = new google.maps.Geocoder();
        // geocoder.geocode({ location: latLng }, (results, status) => {
        //     if (status == google.maps.GeocoderStatus.OK) {
        //         if (results[0]) {
        //             this.address = results[0].formatted_address;
        //             console.log(this.address);
        //         } else {
        //             alert('No results found');
        //         }
        //     } else {
        //         alert('Geocoder failed due to: ' + status);
        //     }
        // });
        const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${this.markerPosition.lat}+${this.markerPosition.lng}&key=c8c9b3b674454c7b81f8c4684ffd3909`;
        this.http.get<any>(apiUrl).subscribe((data: any) => {
            if (data.results.length > 0) {
                this.onMarkerClick.emit(data.results[0].formatted);
            } else {
                this.address = '';
                this.onMarkerClick.emit($localize`Pls set address manually`);
            }
        });
    }
    writeValue(location: GeoLocation): void {
        if (location && location.latitude && location.longitude) {
            this.markerPosition = { lat: location.latitude, lng: location.longitude };
            // this.showAddress();
            this.cdr.markForCheck();
        }
    }
    public notify() {
        this.propagateChange({
            latitude: this.markerPosition.lat,
            longitude: this.markerPosition.lng,
        });
    }
    propagateChange = (_: any) => {};

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}
}
