<div class="res-listing-wrapper res-listing-mobile" fxLayout="column" fxFlex="0 0 100%">
    <mat-accordion *ngIf="allowExpandedFilterPanel" displayMode="flat">
        <mat-expansion-panel [expanded]="true" #expansionPanel>
            <mat-expansion-panel-header> </mat-expansion-panel-header>
            <ng-container [ngTemplateOutlet]="filtersParent"></ng-container>
        </mat-expansion-panel>
    </mat-accordion>
    <ng-container *ngIf="!allowExpandedFilterPanel" [ngTemplateOutlet]="filtersParent"></ng-container>

    <div *ngIf="showActionHeader" class="header-replace">
        <ng-container [ngTemplateOutlet]="tableMultipleRowsTools"></ng-container>
    </div>
    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        matSortDirection="desc"
        matSortActive="last_action"
        matSort
        [class.showHeader]="showActionHeader"
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? masterToggle() : null; showActionHeader = $event.checked"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(resource) : null; showActionHeader = $event.checked"
                    [checked]="selection.isSelected(resource)"
                    [aria-label]="checkboxLabel(resource)"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="columns">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@image">Image</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <app-image-view
                    [media_id]="resource.media$"
                    [proportional]="!isMobile"
                    [paddingBottom]="'58px'"
                    [smallSpinner]="true"
                ></app-image-view>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <span *ngIf="!isMobile" class="original">{{ resource.title }}</span>
                <div *ngIf="!isMobile" class="hover">
                    <ng-container
                        [ngTemplateOutlet]="tableSingleRowTools"
                        [ngTemplateOutletContext]="{ resource: resource }"
                    ></ng-container>
                </div>
                <div class="mobile-row-title" *ngIf="isMobile">
                    <div class="mobile-img-wrapper">
                        <app-image-view
                            [smallSpinner]="true"
                            [media_id]="resource.media$"
                            [proportional]="true"
                        ></app-image-view>
                    </div>
                    <div class="info-main-wrapper">
                        <span class="item-title">{{ resource.title }}</span>
                        <div class="nu-items">
                            <span class="nu">
                                {{ resource.number_of_items }}
                            </span>
                            <span class="last-action-mob">{{
                                resource.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm'
                            }}</span>
                        </div>
                    </div>
                    <button
                        class="expand-btn"
                        mat-icon-button
                        (click)="expandedRow = expandedRow === resource ? null : resource"
                    >
                        <mat-icon>
                            {{ expandedRow === resource ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="summary">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.bullets" *matCellDef="let resource">
                {{ resource.bullets }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="occasionStatus">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@status">Status</span>
                </div>
            </mat-header-cell>
            <mat-cell
                *matCellDef="let resource"
                class="pr-0-m"
                [ngStyle]="{ color: (resource | resourceStatus : true)?.color }"
            >
                <span *ngIf="isMobile" class="mobile-label" i18n="@@status">Status</span>
                <mat-icon>
                    {{ (resource | resourceStatus : true)?.icon }}
                </mat-icon>
                &nbsp;
                {{ (resource | resourceStatus : true)?.status }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="examination">
            <mat-header-cell *matHeaderCellDef i18n="@@certificate"> Certificate </mat-header-cell>
            <mat-cell
                *matCellDef="let resource"
                class="pr-0-m"
                [ngStyle]="{ color: (resource | resourceCertificate).color }"
                (click)="download(resource)"
            >
                <span *ngIf="isMobile" class="mobile-label" i18n="@@certificate">Certificate</span>
                <mat-icon [ngClass]="{ 'disabled-icon': !resource.downloads }" class="cert-icon">
                    {{ (resource | resourceCertificate).icon }}
                </mat-icon>
                &nbsp;
                {{ (resource | resourceCertificate).certificate }}
            </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="done">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@done">
                Done
            </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@done">Done</span>
                <span *ngIf="resource.done">
                    {{ resource.done | kdDateFormat: 'YYYY-MM-DD' }}
                </span>
                <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span>
            </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="opportunity_end_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@deadline"> Deadline </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.opportunity_end_date" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@deadline">Deadline</span>
                <span>
                    {{ resource.opportunity_end_date | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="end_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@deadline"> Deadline </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.opportunity_end_date" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@deadline">Deadline</span>
                <span>
                    {{ resource.opportunity_end_date | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="last_action">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@lastAction"> Last action </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.last_action" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@lastAction">Last action</span>
                <span>
                    {{ resource.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="stats">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@status"> Status </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.occasionStatus" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@status">Status</span>
                <span>
                    {{ (resource | resourceStatus : true)?.status }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdBy">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdBy"> Created by </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@createdBy">Created by</span>
                <span>
                    <ng-container *ngIf="resource.author$ | async; let loadedAuthor">
                        {{ $any(loadedAuthor).name }}
                    </ng-container>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="publishedAt">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@publishedAt"> Published at </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.published" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@publishedAt">Published at</span>
                <span>
                    {{ resource.published | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="endReward">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@endReward"> End reward </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@endReward">End reward</span>
                <span>
                    <span
                        *ngIf="
                            (resource.examination?.type !== 'Certification' &&
                                resource.examination?.type !== 'Diploma') ||
                            !resource.examination
                        "
                        i18n="@@participation"
                        >Participation</span
                    >
                    <span *ngIf="resource.examination?.type === 'Certification'" i18n="@@certification"
                        >Certification</span
                    >
                    <span *ngIf="resource.examination?.type === 'Diploma'" i18n="@@diploma">Diploma</span>

                    <!-- <span *ngIf="resource.examination && resource.examination?.label">{{
                        resource.examination?.label
                    }}</span>
                    <span *ngIf="!resource.examination" i18n="@@participationCertificate"
                        >Participation Certificate</span
                    > -->
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="duration">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@duration"> Duration </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.duration" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@duration">Duration</span>
                <span>
                    {{ resource?.duration | formatIsoDuration }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@price"> Price </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@price">Price</span>
                <span *ngIf="resource.price !== 0 && resource.price" class="row-content"
                    >{{ resource.price }}&nbsp;{{ resource.currency }}</span
                >
                <span *ngIf="resource.price === 0 || !resource.price" class="row-content" i18n="@@free">Free</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="done">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@completed"> Completed </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.done" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@completed">Completed</span>
                <span *ngIf="resource.done">
                    {{ resource.done | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
                <!-- <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="opportunity_assign_date">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@assignedDate"> Assigned date </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@assignedDate">Assigned date</span>
                <span>
                    {{ resource.opportunity_assign_date | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
                <!-- <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="result">
            <mat-header-cell *matHeaderCellDef i18n="@@result"> Results </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@result">Results</span>
                <span *ngIf="resource.done">
                    {{ resource.done }}
                </span>
                <!-- <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span> -->
            </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="question_number">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Q#">
                Q#
            </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@Q#">Q#</span>
                {{ resource.number_of_items }}
            </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="certifier">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@certifier"> Certifier </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@certifier">Certifier</span>
                <span>
                    {{ resource.author_name || $any(resource.author$ | async)?.name }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef i18n="@@name"> Name </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@name">Name</span>
                <span>
                    {{ resource.user.name }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assigned_by">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@assignedBy"> Assigned by </mat-header-cell>
            <mat-cell
                [class.no-display-cell]="!(resource.assigned_by && resource.assigned_by.length > 0)"
                *matCellDef="let resource"
            >
                <span *ngIf="isMobile" class="mobile-label" i18n="@@assignedBy">Assigned by</span>
                <ng-container *ngIf="resource.assigned_by && resource.assigned_by.length > 0">
                    <div class="recommend-wrapper" *ngFor="let assignee of resource.assigned_by">
                        <div class="user-img-wrapper">
                            <div [matTooltip]="assignee.name" class="user-img">
                                {{ assignee.name | nameSign }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="recommended_by">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@recommendedBy"> Recommended by </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@recommendedBy">Recommended by</span>
                <ng-container *ngIf="resource.recommended_by && resource.recommended_by.length > 0">
                    <div class="recommend-wrapper" *ngFor="let recommendee of resource.recommended_by">
                        <div class="user-img-wrapper">
                            <div [matTooltip]="recommendee.name" class="user-img">
                                {{ recommendee.name | nameSign }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="done_items">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@activityProgress"> Progress </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@activityProgress">Progress</span>
                <span>
                    <div class="progress-wrapper">
                        <span class="progress" [ngStyle]="{ width: '50%' }"></span>
                        <span class="remain">{{ resource.done_items ? resource.done_items : '0' }}</span>
                        <span class="total">{{ resource.number_of_items }}</span>
                    </div>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="question_number">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@activityProgress"> Progress </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@activityProgress">Progress</span>
                <span>
                    <div class="progress-wrapper">
                        <span class="progress" [ngStyle]="{ width: '50%' }"></span>
                        <span class="remain">{{ resource.done_items ? resource.done_items : '0' }}</span>
                        <span class="total">{{ resource.number_of_items }}</span>
                    </div>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="language_id">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@lang">Lang</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@lang">Lang</span>
                <ng-container>
                    <app-language-selector
                        [language_id]="resource.language_id"
                        class="icon-resource-builder"
                        fxFlex="0 0 auto"
                    ></app-language-selector>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools_play">
            <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <div fxLayoutAlign="start center" class="tools-cell-wrapper">
                    <button
                        class="tools-btn"
                        color="primary"
                        mat-icon-button
                        matTooltip="View progress"
                        (click)="onBrowse.emit(resource)"
                        i18n-matTooltip="@@viewProgress"
                    >
                        <mat-icon>visibility</mat-icon>
                    </button>

                    <button
                        *ngIf="resource.occasionStatus == 'completed'"
                        class="tools-btn"
                        color="primary"
                        mat-icon-button
                        matTooltip="Signoff for learner"
                        (click)="onCompleteCourse(resource)"
                        i18n-matTooltip="@@signoffForLearner"
                    >
                        <mat-icon>task_alt</mat-icon>
                    </button>

                    <button
                        *ngIf="resource.occasionStatus == 'signed_off'"
                        matTooltip="Pending signoff approvals"
                        i18n-matTooltip="@@PendingApprovals"
                        class="tools-btn signoff-btn"
                        color="primary"
                        mat-icon-button
                        (click)="showSignOff(resource)"
                    >
                        <mat-icon class="material-icons-outlined">assignment_turned_in</mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <!-- <span *ngIf="isMobile" class="mobile-label" i18n="@@tools">Tools</span> -->
                <div class="tools-cell-wrapper">
                    <button
                        class="tools-btn"
                        mat-icon-button
                        matTooltip="View"
                        (click)="$event.stopPropagation(); onBrowse.emit(resource)"
                        i18n-matTooltip="@@view"
                        [class.disableIt]="resource.occasionStatus !== 'ongoing'"
                    >
                        <mat-icon>visibility</mat-icon>
                    </button>
                    <ng-container *ngIf="resource.last_publish">
                        <button
                            class="tools-btn"
                            *ngIf="!(shareLoading | async); else shareLoadTemp"
                            mat-icon-button
                            (click)="shareService.openShareDialog(resource, shareLoading)"
                        >
                            <mat-icon>share</mat-icon>
                        </button>
                    </ng-container>
                    <button *ngIf="resource.downloads" class="tools-btn" mat-icon-button (click)="download(resource)">
                        <mat-icon>save_alt</mat-icon>
                    </button>
                    <button
                        *ngIf="authUser?.isAdmin()"
                        class="tools-btn admin-green assign-res"
                        mat-icon-button
                        (click)="!resource.loading && authUser?.isAdmin() && onAssign.emit(resource)"
                    >
                        <mat-icon *ngIf="!resource.assign_id">assignment_ind</mat-icon>
                        <img
                            *ngIf="resource.assign_id"
                            src="assets/images/edit-assign.svg"
                            width="24"
                            alt="Edit Assign"
                        />
                    </button>
                    <button
                        *ngIf="resource.occasionStatus === 'cancelled'"
                        class="tools-btn admin-green"
                        mat-icon-button
                        (click)="mailUser(resource.user)"
                    >
                        <mat-icon>email</mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="extra">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label"></span>
                <div class="extra-cell-wrapper">
                    <button
                        class="play"
                        mat-raised-button
                        [matTooltip]="getPlayBtnTxt(resource)"
                        (click)="onPlay.emit(resource)"
                    >
                        <mat-icon *ngIf="!resource.opportunity_id">play_circle</mat-icon>
                        <mat-icon *ngIf="resource.opportunity_id">play_circle_outline</mat-icon>
                        <ng-container *ngIf="resource.opportunity_id" i18n="@@continue">Continue</ng-container>
                        <ng-container *ngIf="!resource.opportunity_id" i18n="@@start">Start</ng-container>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            [ngClass]="!resource.loading ? 'link' : 'disableIt'"
            (click)="$event.stopPropagation()"
            *matRowDef="let resource; columns: columns"
            [class.res-expanded-row]="expandedRow === resource"
        ></mat-row>
    </mat-table>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #rowHover>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #shareLoadTemp>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
<ng-template #filtersParent>
    <app-table-filter
        (filterChange)="onFilterChange($event)"
        [cacheScope]="cacheScope"
        [inGlobalFilter]="true"
        color="accent"
        #filter
    >
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-tag-chip-search-filter
            [inGlobalFilter]="true"
            [languageChange]="langFilter.filterChange"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            fxLayoutAlign="start"
            tagsFilterRef
        ></app-tag-chip-search-filter>
        <app-category-chip-search-filter
            [inGlobalFilter]="true"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            [scope_id]="type_scope_ids"
            categoriesFilterRef
        ></app-category-chip-search-filter>

        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Status"
            i18n-label
            [property]="GlobalFilterProperty.RESOURCS_OCCASION"
            [options]="resourceStatusOptions"
            resStatusFilterRef
        ></app-select-filter>

        <app-table-date-filter [inGlobalFilter]="true" color="accent" dateFilterRef></app-table-date-filter>

        <app-language-filter
            [inGlobalFilter]="true"
            #langFilter
            fxFlex="0 0 auto"
            fxLayout="row"
            langFilterRef
        ></app-language-filter>
    </app-table-filter>
</ng-template>
<ng-template #tableMultipleRowsTools>
    <div class="hover-wrapper top" fxLayoutAlign="space-around center">
        <!-- <button
            *ngIf="false"
            class="hover-tools-btn"
            mat-raised-button
            matTooltip="Download PDF"
            i18n-matTooltip="@@downloadPDF"
            (click)="download()"
        >
            <mat-icon>save_alt</mat-icon>
            <span class="btn-label" i18n="@@download">Download</span>
        </button> -->
        <button
            class="hover-tools-btn disableIt"
            mat-raised-button
            matTooltip="Export CSV"
            i18n-matTooltip="@@exportCSV"
        >
            <mat-icon>get_app</mat-icon>
            <span class="btn-label" i18n="@@exportcsv">Export CSV</span>
        </button>
    </div>
</ng-template>
<ng-template #tableSingleRowTools let-resource="resource">
    <div class="hover-wrapper" fxLayoutAlign="space-between center">
        <button
            class="hover-tools-btn view"
            mat-raised-button
            matTooltip="View"
            (click)="onBrowse.emit(resource)"
            i18n-matTooltip="@@view"
        >
            <mat-icon>visibility</mat-icon>
        </button>

        <ng-container *ngIf="resource.last_publish">
            <button
                class="hover-tools-btn"
                *ngIf="!(shareLoading | async); else shareLoadTemp"
                mat-icon-button
                matTooltip="Share"
                i18n-matTooltip="@@share"
                (click)="shareService.openShareDialog(resource, shareLoading)"
            >
                <mat-icon>share</mat-icon>
            </button>
        </ng-container>
        <button
            *ngIf="resource.downloads"
            class="hover-tools-btn"
            mat-raised-button
            matTooltip="Download PDF"
            i18n-matTooltip="@@downloadPDF"
            (click)="download(resource)"
        >
            <mat-icon>save_alt</mat-icon>
        </button>
        <button
            *ngIf="resource.occasionStatus === 'cancelled'"
            class="hover-tools-btn view admin-green"
            mat-raised-button
            matTooltip="Email User"
            (click)="mailUser(resource.user)"
        >
            <mat-icon>email</mat-icon>
        </button>
        <button
            *ngIf="authUser?.isAdmin()"
            class="hover-tools-btn assign-res view admin-green"
            mat-raised-button
            matTooltip="Assign"
            i18n-matTooltip="@@assign"
            (click)="!resource.loading && authUser?.isAdmin() && onAssign.emit(resource)"
        >
            <mat-icon *ngIf="!resource.assign_id">assignment_ind</mat-icon>
            <img *ngIf="resource.assign_id" src="assets/images/edit-assign.svg" width="24" alt="Edit Assign" />
        </button>
    </div>
</ng-template>
