<div
    *ngIf="!inGlobalFilter; else globalFilterTemplate"
    fxLayout="row"
    fxFlex.lt-sm="0 0 80%"
    fxFlex="0 0 350px"
    class="app-search-table-wrapper"
    fxLayoutAlign="start center"
>
    <mat-form-field fxFlex="1 1 100%" class="search-box">
        <input
            fxFlex="0 0 100%"
            matInput
            #search
            placeholder="Filter by name..."
            i18n-placeholder="@@filterByName"
            [formControl]="control"
        />
        <button matSuffix mat-icon-button *ngIf="!search.value.length">
            <mat-icon class="search-icon">search</mat-icon>
        </button>
        <button mat-icon-button matSuffix *ngIf="search.value.length" (click)="clear()">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-form-field>
</div>
<!-- <div
    *ngIf="inGlobalFilter"
    fxLayout="row"
    fxFlex.lt-sm="0 0 80%"
    fxFlex="0 0 100px"
    class="app-search-table-wrapper"
    fxLayoutAlign="start center"
> -->
<!-- <mat-form-field [floatLabel]="never" fxFlex="1 1 100%" class="search-box">

        <input
            fxFlex="0 0 100%"
            matInput
            #search
            placeholder="Filter by name..."
            i18n-placeholder="@@filterByName"
            [formControl]="control"
        />
        <button matSuffix mat-icon-button *ngIf="!search.value.length">
            <mat-icon class="search-icon">search</mat-icon>
        </button>
        <button mat-icon-button matSuffix *ngIf="search.value.length" (click)="clear()">
            <mat-icon>clear</mat-icon>
        </button>
    </mat-form-field> -->
<!-- </div> -->

<ng-template #globalFilterTemplate>
    <mat-form-field class="global-search-title" appearance="fill">
        <mat-label i18n="@@search">Search</mat-label>
        <input matInput #search [placeholder]="placeHolder" [formControl]="control" />
        <!-- <button mat-icon-button matSuffix *ngIf="search.value.length" (click)="clear()">
            <mat-icon>clear</mat-icon>
        </button> -->
    </mat-form-field>
</ng-template>

<ng-template #activeFilterTemplate let-filter="filter">
    <mat-icon>{{ filter.icon }}</mat-icon>
    <span>{{ filter.items }}</span>
</ng-template>
