import { ChangeDetectionStrategy, Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { ApplicationService } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { CustomerLanding } from '@klickdata/core/customer';
import { User } from '@klickdata/core/user';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-footer',
    templateUrl: 'footer.component.html',
    styleUrls: ['footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
    public image: string;
    user$: Observable<User>;
    @Input() existInTC: boolean;
    @Input() smallFooter: boolean;
    date = new Date();
    showAcademy: boolean;
    academyLink: {
        title: String;
        subLinks: {
            link: String;
            title: String;
        }[];
    };
    public version$: Observable<{ version: string; date: string }>;
    public customerLanding$: Observable<CustomerLanding>;
    constructor(
        protected appService: ApplicationService,
        @Inject(LOCALE_ID) protected localeId: string,
        protected auth: AuthService
    ) {
        this.version$ = appService.getVersion();
        this.user$ = this.auth.getUser();
    }

    ngOnInit() {
        this.image = 'assets/images/logo_liggande_gra.svg';
        this.customerLanding$ = this.auth.getCustomerLanding();
        // TODO: show Academylink from admin via API
        /**
         * enable: showAcademy
         */
        this.showAcademy = false;
        this.academyLink = {
            title: 'Academy',
            subLinks: [
                { link: '#', title: 'Contact Person' },
                { link: '#', title: 'Email' },
                { link: '#', title: 'Telephone' },
            ],
        };
    }

    public redirectTo() {
        this.localeId === 'en'
            ? window.open('https://klickdata.se/english', '_blank')
            : window.open('https://klickdata.se/', '_blank');
    }
}
