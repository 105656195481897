import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Customer, CustomerData } from '@klickdata/core/customer';
import { MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile/src/mobile.service';
import { AppScope, ResourceTag, ResourceTagData } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterColor,
    GlobalFilterProperty,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { FilterSpecs } from '@klickdata/core/table/src/table-filter/filter';
import { Task } from '@klickdata/core/task';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { Subject, filter, shareReplay, switchMap, takeUntil } from 'rxjs';
import { TaskListService } from '../task-list-service';

@Component({
    selector: 'app-task-listing-child',
    templateUrl: './task-listing-child.component.html',
    styleUrls: ['./task-listing-child.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TaskListService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TaskListingChildComponent implements OnInit, AfterViewInit, OnDestroy {
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @Input() public columns = [
        'select',
        'assignee',
        'priority',
        'body',
        'customers',
        'tags',
        'deadline',
        'type',
        'action',
        'tools',
    ];
    @Input() tableFilterItems: Filter<string | number>[];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChildren('taskCellRef') taskCellRefs: QueryList<ElementRef>;
    @ViewChildren('customerCellRef') customerCellRefs: QueryList<ElementRef>;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    @Input() authUser: User;
    @Input() parentId: number;
    @Output() onSelectCustomer: EventEmitter<Customer> = new EventEmitter<Customer>();
    selection = new SelectionModel<Task>(true, []);
    public expandedRow: Task | null;
    public dataSource = new TableSource<Task>();
    public taskActionsOptions: SelectFilterOption[];
    public taskPrioOptions: SelectFilterOption[];
    public taskTypesOptions: SelectFilterOption[];
    public taskDoneOptions: SelectFilterOption[];
    public loadingTaskDone: Subject<boolean> = new Subject<boolean>();
    public isAllSelected = false;
    public isMobile: boolean;
    public assigneeFilterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.ASSIGNEES,
        label: $localize`:@@assignee:Assignee`,
        icon: 'assignment_ind',
        color: { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.WHITE },
    };
    public managerFilterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.MANAGERS,
        label: $localize`:@@owner:Owner`,
        icon: 'badge',
        color: { bg: GlobalFilterColor.C5, fg: GlobalFilterColor.WHITE },
    };
    public deadlineFilterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.DEADLINE,
        items: [],
        composite: [
            { property: 'deadline_from', items: [] },
            { property: 'deadline_to', items: [] },
        ],
        label: $localize`:@@deadline:Deadline`,
        icon: 'event_busy',
        color: { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 },
        styleClass: 'global-date-filter-selector',
    };

    constructor(
        protected mobileService: MobileService,
        protected cdRef: ChangeDetectorRef,
        protected taskService: TaskListService,
        protected messageService: MessageService,
        protected router: Router,
        @Inject(LOCALE_ID) public localeId: string
    ) {
        this.taskActionsOptions = Utils.getTaskActionsOptions();
        this.taskPrioOptions = Utils.getTaskPrioOptions();
        this.taskTypesOptions = Utils.getTaskTypesOptions();
        this.taskDoneOptions = Utils.getTaskDoneOptions();
    }

    ngOnInit(): void {
        this.mobileService
            .isMobile()
            .pipe(shareReplay(), takeUntil(this.destroy))
            .subscribe((mobile) => {
                this.isMobile = mobile;
                this.cdRef.markForCheck();
            });
        this.dataSource.service = this.taskService;
        this.dataSource.status.pipe(takeUntil(this.destroy)).subscribe((loaded) => {
            if (loaded === 'loaded') {
                this.updateTaskCellUI();
            }
        });
    }
    updateTaskCellUI() {
        setTimeout(() => {
            this.taskCellRefs.forEach((item) => {
                if (item.nativeElement.clientHeight > 60) {
                    item.nativeElement.classList.add('add-dots');
                }
            });
            this.customerCellRefs.forEach((item) => {
                if (item.nativeElement.clientHeight > 80) {
                    item.nativeElement.classList.add('customer-cell-exceeds');
                }
            });
        }, 500);
    }
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.filter.createOrUpdateFilter([new Filter('parent', [this.parentId])]);
    }

    onTagClick(tag: ResourceTagData) {
        this.filter.filterByModel(new ResourceTag(tag).getFilter());
    }
    onSelectAssignee(user: User) {
        this.filter.filterByModel(
            new User({
                id: user.id,
                name: `${user.fname} ${user.lname}`,
                staffRoleProperty: this.GlobalFilterProperty.ASSIGNEES,
            }).getFilter()
        );
    }
    onCustomerClick(customer: CustomerData) {
        // this.onSelectCustomer.emit(new Customer(customer));
        this.router.navigate(['/master/customers/', customer.id]);
    }
    updateTaskPrio(task: Task, prio: any) {
        this.taskService
            .update({ id: task.id, priority: prio })
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedTask) => {
                task.loading = false;
                task.priority = updatedTask.priority;
                this.cdRef.markForCheck();
            });
    }
    changeTaskDone(task: Task, status: boolean) {
        this.taskService
            .updateTaskDone([task.id], status)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                task.loading = false;
                // this.messageService.openMessage(MessageSavedComponent, $localize`Task marked as done!`);
                this.dataSource.removeById(task.id);
                this.cdRef.markForCheck();
                // this.dataSource.replace(task);
            });
    }
    markSelectedAsDone() {}
    getInfo(task: Task): string {
        return [
            $localize`Created: ` +
                new DatePipe(this.localeId).transform(
                    task.created_at.toString(),
                    this.localeId === 'en' ? 'MMM d, y' : 'd MMM y'
                ),
            $localize`Updated: ` +
                new DatePipe(this.localeId).transform(
                    task.updated_at.toString(),
                    this.localeId === 'en' ? 'MMM d, y' : 'd MMM y'
                ),
            task.managers && task.managers.length ? $localize`Owner: ` + this.getTaskOwners(task) : '',
        ].join('\n');
    }
    private getTaskOwners(task: Task): string {
        return task.managers
            .map((manager) => {
                return manager.fname + ' ' + manager.lname;
            })
            .join(', ');
    }
    delete(task?: Task) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: task ? $localize`Delete task` : $localize`Delete tasks`,
                contentBody: $localize`You are about to delete task. Please use caution and make sure that you really intend to delete before confirming delete below.`,
                positiveBtn: $localize`Delete`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap((res) => {
                    if (res) {
                        return this.taskService.destroy(
                            task ? [task.id] : this.selection.selected.map((task) => task.id)
                        );
                    }
                })
            )
            .subscribe((action) => {
                if (action) {
                    task
                        ? this.dataSource.removeById(task.id)
                        : this.dataSource.removeByIds(this.selection.selected.map((task) => task.id));
                    this.selection.clear();
                }
            });
    }
    UpdateTask(task: Task) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_TASK,
            data: {
                type: 'updateTask',
                task: task,
            },
        });
        this.mobileService
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'updateTask'),
                takeUntil(this.destroy)
            )
            .subscribe((data: any) => {
                if (data.task) {
                    this.dataSource.replace(data.task);
                    if (data.isDuplicate) {
                        this.dataSource.add(data.task);
                    }
                    this.updateTaskCellUI();
                }
            });
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
