<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon>fact_check</mat-icon>
        <div *ngIf="!navData.data.title">Course task done</div>
        <div *ngIf="navData.data.title">{{ navData.data.title }}</div>
    </div>
    <div *ngIf="!navData.data.desc" class="desc" i18n>Do you want to mark this part of the course as completed?</div>
    <div *ngIf="navData.data.desc" class="desc">{{ navData.data.desc }}</div>
    <ng-container *ngIf="navData.data.actions && navData.data.actions?.length > 0">
        <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
            <ng-container *ngFor="let action of navData.data.actions">
                <button
                    *ngIf="!action?.customBtn"
                    fxFlex="0 0 auto"
                    class="mobile-button positive {{ action.class }}"
                    (click)="onClickAction(action)"
                    mat-button
                >
                    {{ action.label }}
                </button>
            </ng-container>
        </div>

        <ng-container *ngFor="let action of navData.data.actions">
            <ng-container *ngIf="doneBtnClicked">
                <div
                    *ngIf="action.customBtn && navData.data.showCustomBtnAfterClickDone"
                    fxLayoutGap="20px"
                    fxLayout="column"
                    class="mt-1"
                >
                    <span *ngIf="action.specs?.desc">{{ action.specs.desc }}</span>
                    <div
                        *ngIf="action.specs.hasFeedback"
                        class="input-field-parent"
                        fxLayout="column"
                        fxLayoutGap="5px"
                    >
                        <mat-form-field
                            cdkFocusInitial
                            appearance="outline"
                            floatLabel="never"
                            class="w-100"
                            fxFlex="grow"
                        >
                            <textarea
                                #taskInput
                                matInput
                                type="text"
                                [formControl]="feedback"
                                placeholder="Write feedback to admin"
                                i18n-placeholder
                                cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize"
                                cdkAutosizeMinRows="4"
                                cdkAutosizeMaxRows="10"
                            ></textarea>
                        </mat-form-field>
                    </div>
                    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <button
                            fxFlex="0 0 auto"
                            class="mobile-button positive {{ action.class }}"
                            (click)="
                                onClose.emit({
                                    data: {
                                        type: navData.data.type,
                                        response: action.value,
                                        feedback: feedback.value
                                    }
                                })
                            "
                            mat-button
                        >
                            {{ action.label }}
                        </button>
                    </div>
                </div>
            </ng-container>
            <div
                *ngIf="action.customBtn && !navData.data.showCustomBtnAfterClickDone"
                fxLayoutGap="20px"
                fxLayout="column"
                class="mt-1"
            >
                <span *ngIf="action.specs.desc">{{ action.specs.desc }}</span>
                <div *ngIf="action.specs.hasFeedback" class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
                    <mat-form-field cdkFocusInitial appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                        <textarea
                            #taskInput
                            matInput
                            type="text"
                            [formControl]="feedback"
                            placeholder="Write feedback to admin"
                            i18n-placeholder
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="4"
                            cdkAutosizeMaxRows="10"
                        ></textarea>
                    </mat-form-field>
                </div>
                <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
                    <button
                        fxFlex="0 0 auto"
                        class="mobile-button positive {{ action.class }}"
                        (click)="
                            onClose.emit({
                                data: {
                                    type: navData.data.type,
                                    response: action.value,
                                    feedback: feedback.value
                                }
                            })
                        "
                        mat-button
                    >
                        {{ action.label }}
                    </button>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>
