<div fxFlex="0 0 100%" fxLayout="row wrap" class="assignments-select">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.contextTitle"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss()"
        [savingStatus]="saving"
    ></app-sticky-header>
    <mat-tab-group class="user-manage-cp-access" fxFlex="0 0 90%" mat-stretch-tabs ngClass.xs="xs">
        <mat-tab>
            <ng-template mat-tab-label>
                <span i18n="@@users">Users</span>
            </ng-template>
            <app-admin-users-select
                [referenceFilter]="data.referenceFilter"
                [defaultSorting]="data.defaultSorting"
                [accessForm]="assignForm"
            ></app-admin-users-select>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <span i18n="@@groups">Groups</span>
            </ng-template>
            <app-admin-groups-select
                [referenceFilter]="data.referenceFilter"
                [defaultSorting]="data.defaultSorting"
                [accessForm]="assignForm"
            ></app-admin-groups-select>
        </mat-tab>
    </mat-tab-group>
</div>
