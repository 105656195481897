<button
    *ngIf="add.observers.length"
    class="add-btn-mobile"
    fxFlex="0 0 auto"
    add-btn
    mat-button
    fxLayoutAlign="center center"
    type="button"
    color="accent"
    mat-raised-button
    (click)="add.emit()"
>
    <mat-icon>add</mat-icon>
    <ng-container i18n="@@addSection">Add Section</ng-container>
</button>

<app-table-filter [inGlobalFilter]="true" color="accent" #filter>
    <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
    <app-table-date-filter [inGlobalFilter]="true" color="accent" dateFilterRef></app-table-date-filter>
    <app-select-filter
        [inGlobalFilter]="true"
        class="filter-space"
        label="Filter by selection"
        i18n-label="@@filterBySelection"
        [property]="GlobalFilterProperty.SELECTED"
        [options]="itemSelectedOptions"
        selectedItemsRef
    >
    </app-select-filter>
</app-table-filter>

<div fxLayoutAlign="start center" fxLayoutGap="10px" class="selection-Info">
    <div fxLayoutAlign="start center" fxLayoutGap="5px" class="main">
        <span i18n="@@numerOfSections" class="label">Number of sections:</span>
        <span class="total">{{ total }}</span>
    </div>
    <div fxLayoutAlign="start center" fxLayoutGap="5px" class="selected" *ngIf="selected">
        <span i18n="@@selected">Selected:</span>
        {{ selected }}
    </div>
</div>

<mat-progress-bar
    class="progress progress-table-loader"
    *ngIf="(dataSource.status | async) === 'loading'"
    mode="indeterminate"
>
</mat-progress-bar>
<mat-table
    #table
    [dataSource]="dataSource"
    matSort
    [matSortActive]="hideTools ? 'updated_at' : 'public_order'"
    matSortDirection="desc"
    cdkDropList
    [cdkDropListDisabled]="'public' !== scope"
    (cdkDropListDropped)="drop($event)"
>
    <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="masterToggle()"
                [checked]="isChecked()"
                [indeterminate]="isIndeterminate()"
            >
            </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let resource">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="selectToggle(resource)"
                [checked]="modelChecked(resource)"
            >
            </mat-checkbox>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
        <mat-cell *matCellDef="let resource">{{ resource.title }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="updated_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@updated"> Updated at </mat-header-cell>
        <mat-cell *matCellDef="let resource">
            {{ resource.updated_at | kdDateFormat: 'YYYY-MM-DD' }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="public_order">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@order">Order</mat-header-cell>
        <mat-cell *matCellDef="let resource"> {{ resource.public_order }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tools">
        <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
        <mat-cell *matCellDef="let resource" (click)="$event.stopPropagation()">
            <ng-container>
                <button
                    class="tools-btn"
                    matTooltip="view"
                    mat-icon-button
                    (click)="$event.stopPropagation(); showResource(resource)"
                    i18n-matTooltip="@@view"
                >
                    <mat-icon>search</mat-icon>
                </button>
            </ng-container>
            <ng-container>
                <button
                    *ngIf="edit.observers.length"
                    class="tools-btn"
                    matTooltip="edit"
                    i18n-matTooltip="@@edit"
                    mat-icon-button
                    (click)="$event.stopPropagation(); edit.emit(resource)"
                >
                    <mat-icon>edit</mat-icon>
                </button>
            </ng-container>
            <ng-container>
                <button
                    class="tools-btn"
                    matTooltip="Delete section"
                    mat-icon-button
                    (click)="$event.stopPropagation(); deleteSection(resource)"
                    i18n-matTooltip="@@deleteSection"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-container>

            <button class="tools-btn" matTooltip="Sort" mat-icon-button i18n-matTooltip cdkDragHandle>
                <mat-icon>sort</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let resource; columns: columns" (click)="selectToggle(resource)" cdkDrag></mat-row>
</mat-table>

<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
