<div class="logo" [style.padding]="iconStyle">
    <img fxShow
         fxHide.lt-md
         [src]="image" alt="KlickData"
         (click)="redirectTo()"
         [style.maxHeight]="iconImgStyleHeight"
         [style.maxWidth]="iconImgStyleWidth">

    <img fxShow.lt-md
         fxHide
         [src]="image" alt="KlickData"
         (click)="redirectTo()"
         [style.maxHeight]="iconImgStyleHeight"
         [style.maxWidth]="iconImgMdStyle">
</div>
