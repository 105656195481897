import { Location } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Host,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatCheckbox } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { ActivityLogService } from '@klickdata/core/activity-log';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { Customer } from '@klickdata/core/customer';
import { FormHelper } from '@klickdata/core/form';
import { GlobalSearchData } from '@klickdata/core/global-search';
import { MessageDeletedComponent, MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import {
    AppScope,
    Resource,
    ResourceCategory,
    ResourceData,
    ResourceService,
    ResourceTag,
    ResourceTypes,
} from '@klickdata/core/resource';
import { Currency } from '@klickdata/core/resource/src/resource.model';
import { StartResourceService } from '@klickdata/core/resource/src/start-resource.service';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { VTQ, VTQService } from '@klickdata/core/vtq';
import { CategoryChipSelectComponent } from '@klickdata/shared-components/src/chip-selection/category-chip-select/category-chip-select.component';
import { TagChipSelectComponent } from '@klickdata/shared-components/src/tag-chip-select/tag-chip-select.component';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { Observable, Subject, merge } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ResourceStaffRoles } from '../../../../core/resource/src/types.enum';
import { ResourceAvailabilitySheetComponent } from '../../resource-assignment/resource-availability-sheet/resource-availability-sheet.component';
import { ResourceBuilderComponent } from '../../resource-builder/resource-builder.component';
import { ResourceListingSheetComponent } from '../../resource-listing-sheet/resource-listing-sheet.component';
import { ResourceManagerEvent } from '../resource-manager-event';
import { ResourceManagementComponent } from '../resource-manager.component';

@Component({
    selector: 'app-resource-manager-controls',
    templateUrl: './resource-manager-controls.component.html',
    styleUrls: ['./resource-manager-controls.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ResourceManagerControlsComponent implements OnInit, OnChanges {
    public resourceForm: FormGroup;
    @Input() resource: Resource;
    @Input() typeId: ResourceTypes;
    @Input() set resourceBuilder(value: ResourceBuilderComponent) {
        if (value) {
            this._resourceBuilder = value;
            merge(value.getCurrentForm().valueChanges, value.getLoading().pipe(filter((loading) => !loading)))
                .pipe(takeUntil(this.destroy))
                .subscribe(() => this.cdRef.markForCheck());
        }
    }

    private _resourceBuilder: ResourceBuilderComponent;
    public get resourceBuilder(): ResourceBuilderComponent {
        return this._resourceBuilder;
    }

    ResourceTypes = ResourceTypes;
    @ViewChild('publishCheckbox') publishCheckbox: MatCheckbox;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @Input() public resKeysValidaty: { [key: string]: string | boolean }[];
    @ViewChild(CategoryChipSelectComponent) categoriesComp: CategoryChipSelectComponent;
    @ViewChild(TagChipSelectComponent) tagsComp: TagChipSelectComponent;
    @Input() public loading: boolean;
    // public loadingUserManager: boolean;
    public defaultResourceOnlineEventId: number;
    public onFormReset: Subject<boolean> = new Subject<boolean>();
    public accessLevel: 'all' | 'partial' | 'none' = 'none';
    @Input() user: User;
    @Input() customer: Customer;
    ResourceStaffRoles = ResourceStaffRoles;
    public resSubmitDisabledStatus$: Observable<boolean>;
    public vTQ$: Observable<VTQ>;
    public currencies: Currency[];

    constructor(
        @Host() protected host: ResourceManagementComponent,
        protected parentFormDirective: FormGroupDirective,
        protected bottomSheet: MatBottomSheet,
        protected message: MessageService,
        protected cdRef: ChangeDetectorRef,
        protected mobileService: MobileService,
        protected resourceService: ResourceService,
        protected location: Location,
        protected downloadHelper: DownloadHelperService,
        protected configService: ConfigService,
        protected router: Router,
        protected activityLogService: ActivityLogService,
        protected vTQService: VTQService,
        protected auth: AuthService,
        protected startResourceService: StartResourceService
    ) {
        this.defaultResourceOnlineEventId = this.configService.config.defaultResourceOnlineEventId;
        this.currencies = Utils.getAllCurrencies();
    }

    ngOnInit(): void {
        this.resourceForm = this.parentFormDirective.form;
        this.resSubmitDisabledStatus$ = this.resourceService.getResSubmitDisabled();
        this.vTQ$ = this.vTQService.getActiveVTQ();
        // this.setUpAutoSave();
    }
    // setUpAutoSave() {
    //     this.resourceForm.valueChanges
    //         .pipe(
    //             takeUntil(this.destroy),
    //             debounceTime(5000),
    //             filter(() => this.resourceForm.dirty && this.resourceForm.valid)
    //         )
    //         .subscribe(() => {
    //             this.parentFormDirective.ngSubmit.emit(ResourceManagerEvent.SUBMIT);
    //         });
    // }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.resource && changes.resource.currentValue) {
            this.handleAccessLevelIndicator(this.resource);
        }

        if (changes.resKeysValidaty) {
            this.resKeysValidaty = changes.resKeysValidaty.currentValue;
            this.cdRef.markForCheck();
        }
    }

    public handleAccessLevelIndicator(res: Resource) {
        this.accessLevel = res.users_access_open
            ? 'all'
            : res.users_attached || res.groups_attached
            ? 'partial'
            : 'none';
        this.cdRef.markForCheck();
    }
    public onSubmit() {
        this.parentFormDirective.ngSubmit.emit(ResourceManagerEvent.SUBMIT);
    }
    resetForm() {
        FormHelper.resetForm(this.resourceForm);
        this.cdRef.markForCheck();
    }
    authorizeUsers() {
        this.host.openCollaborationSheet();
    }

    public specify() {
        this.bottomSheet
            .open(ResourceAvailabilitySheetComponent, {
                data: {
                    context: 'publish',
                    contextTitle: $localize`:@@specifyPublish:Specify Publish`,
                    contextIcon: 'publish_outline',
                    resource: this.resource,
                    title: this.resource.title,
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe((value: Resource) => {
                this.message.openMessage(MessageSavedComponent);
                this.resource.users_access_open = value.users_access_open;
                this.handleAccessLevelIndicator(value);
                this.resourceForm.markAsDirty();
            });
    }

    public view(id: number) {
        this.router.navigate(['/home/dashboard/resource-details/' + id]);
    }

    public showTagResources(tag: ResourceTag) {
        this.showResources({ id: tag.id, label: tag.name, scope_id: AppScope.TAGS });
    }
    public showCatgeoryResources(category: ResourceCategory) {
        this.showResources({ id: category.id, label: category.title, scope_id: AppScope.CATEGORIES });
    }
    private showResources(item: GlobalSearchData) {
        this.bottomSheet.open(ResourceListingSheetComponent, {
            data: {
                id: item.id,
                label: item.label,
                scope_id: item.scope_id,
            },
            panelClass: 'sheet-wrapper',
        });
    }
    createQuiz(vTQ: VTQ) {
        vTQ.user_id = this.user.id;
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.VIDEO_TO_QIUZ,
            data: {
                resource: this.resource,
                vTQ: vTQ,
            },
        });
    }
    makeResourcePublic() {
        if (
            this.user.privileges.public == 'not_allowed' ||
            (this.user.privileges.public == 'approval_required' && !this.resourceForm.value.publish)
        ) {
            return;
        }
        this.startResourceService
            .makeResourcePublic(this.resource)
            .pipe(takeUntil(this.destroy))
            .subscribe((res) => {
                if (!this.resource.publication) {
                    this.resource.publication = res.publication;
                    this.cdRef.markForCheck();
                }
            });
    }
    getPubToolTip() {
        if (this.user.privileges.public == 'not_allowed') {
            return $localize`You don't have the privilege required to public`;
        } else {
            if (this.resource?.publication?.approval_status) {
                switch (this.resource.publication.approval_status) {
                    case 'approved':
                        return $localize`Request to public is approved`;
                    case 'pending':
                        return $localize`Request to public is pending`;
                    case 'rejected':
                        return $localize`Request to public is rejected`;
                }
            } else {
                return $localize`Request to public`;
            }
        }
    }
    publishResource() {
        if (this.user.privileges.publish == 'not_allowed') {
            return;
        }
        this.startResourceService
            .publishResource(this.resource)
            .pipe(takeUntil(this.destroy))
            .subscribe((res) => {
                if (!this.resource.publishment) {
                    this.resource.publishment = res.publishment;
                    this.cdRef.markForCheck();
                }
            });
    }
    getPublishToolTip() {
        if (this.user.privileges.publish == 'not_allowed') {
            return $localize`You don't have the privilege required to publish`;
        } else {
            if (this.resource?.publishment?.approval_status) {
                switch (this.resource.publishment.approval_status) {
                    case 'approved':
                        return $localize`Request to publish is approved`;
                    case 'pending':
                        return $localize`Request to publish is pending`;
                    case 'rejected':
                        return $localize`Request to publish is rejected`;
                }
            } else {
                return $localize`Request to publish`;
            }
        }
    }
    public checkEfficiency(type: ResourceTypes) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.CHECK_EFFICIENCY,
            data: {
                icon: 'task_alt',
                title: $localize`Check before publish`,
                contentBody: $localize`Before you publish, make sure you review the quality of the ${ResourceTypes.label(
                    type,
                    { capitalize: false }
                )}. Some fields are mandatory and some is complimentary.
                You can improve the quality of your ${ResourceTypes.label(type, {
                    capitalize: false,
                })} by adding more information.`,
                positiveBtn: $localize`Ok`,
                mandatory: this.resKeysValidaty.filter((key) => key.mandatory),
                optional: this.resKeysValidaty.filter((key) => !key.mandatory),
            },
        });
    }
    public delete(resource: Resource) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: $localize`Delete ${resource.title}`,
                contentBody: $localize`You are about to delete this ${ResourceTypes.label(this.typeId, {
                    capitalize: false,
                })}. Please use caution and make sure that you really intend to delete before confirming delete below.`,
                positiveBtn: $localize`Delete`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap((res) => {
                    if (res) {
                        return this.resourceService.destroy(resource);
                    }
                })
            )
            .subscribe((action) => {
                if (action) {
                    this.message.openMessage(MessageDeletedComponent);
                    FormHelper.resetForm(this.resourceForm);
                    this.location.back();
                }
            });
    }
    public addResNote(resource: Resource) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_NOTE,
            data: {
                privacy: null,
                resources: [resource],
                styleClass: 'playerTC',
                status: 'create',
            },
        });
    }
    public getLocalizedLabel(isSearchLabel: boolean) {
        return this.typeId === ResourceTypes.GeneralCoursePlan
            ? isSearchLabel
                ? $localize`Search authors`
                : $localize`Create a new author`
            : !isSearchLabel
            ? $localize`Create a new tutor`
            : $localize`Search tutors`;
    }
    public download(resource: Resource) {
        this.downloadHelper.download(resource.downloads);
    }

    public getMainEduTooltip() {
        return this.typeId === ResourceTypes.EVENT
            ? $localize`Main educator or organizer of the event.`
            : $localize`Main author`;
    }
    public list() {
        const resType = ResourceTypes.playerRoute(
            this.typeId,
            this.auth.getNK3PlatformValue() != 'user' && this.auth.getNK3PlatformValue() != 'guest'
        );
        this.router.navigate(
            this.auth.getNK3PlatformValue() == 'user'
                ? [`/home/manage/${resType}/list`]
                : [`/admin/content/${resType}/list`]
        );
    }
    public viewResLog(res: Resource) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_PROGRESS,
            data: {
                resource: res,
                logs: this.activityLogService.getUserRelatedResourceActivities(
                    this.typeId === ResourceTypes.MATERIAL
                        ? { resource: res.id, eager: ['user_prompt'], sort: 'activity_id' }
                        : { resource: res.id }
                ),
            },
        });
    }
    public viewRelatedResources(resourecs: ResourceData[]) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.RELATED_RESOURCES,
            data: {
                resources: resourecs,
            },
        });
    }
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    get isSaveDisabled(): boolean {
        // If builderForm is not present, only check resourceForm
        if (!this.resourceBuilder) {
            return !this.resourceForm.value.title || this.resourceForm.pristine;
        }
        // If both resourceForm and builderForm are present, check their states
        return (
            !this.resourceForm.value.title ||
            (this.resourceForm.pristine && this.resourceBuilder.getCurrentForm().pristine)
        );
    }
}
