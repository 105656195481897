import { ChangeDetectionStrategy, Component } from '@angular/core';
import { customFieldsBase } from '../custom-fields-base';

@Component({
    selector: 'app-custom-fields-date-type',
    templateUrl: './custom-fields-date-type.component.html',
    styleUrls: ['./custom-fields-date-type.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsDateTypeComponent extends customFieldsBase {}
