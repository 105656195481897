<div *ngIf="group.get('question_type_value')" [formGroup]="group" fxLayout="column">
    <ng-container *ngIf="isQuestionTypesWithAlts">
        <div
            fxFlex="0 0 100%"
            fxLayout="row"
            fxLayout.lt-sm="column"
            fxLayoutAlign.lt-sm="start"
            fxLayoutAlign="start center"
        >
            <mat-form-field class="qs-type">
                <mat-select
                    formControlName="question_type_value"
                    required
                    placeholder="Choose a question type"
                    i18n-placeholder="@@ChooseQuestionType"
                >
                    <mat-option *ngFor="let type of availableTypes" [value]="type.value">{{ type.label }}</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="other-form-field" *ngIf="group.value.question_type_value === QuestionTypes.RADIO">
                <mat-checkbox formControlName="other_alternative" i18n="@@allowOtherAlternative"
                    >Allow other alternative</mat-checkbox
                >
            </div>

            <div class="other-form-field" *ngIf="group.value.question_type_value === QuestionTypes.CHECKBOX">
                <mat-checkbox formControlName="other_alternative" i18n="@@allowOtherAlternative"
                    >Allow other alternative</mat-checkbox
                >
            </div>

            <div class="other-form-field">
                <mat-checkbox formControlName="randomize_alternatives" i18n="@@randomizeAlternatives"
                    >Randomize question alternatives</mat-checkbox
                >
            </div>
        </div>

        <app-resource-builder-alternatives
            formArrayName="alternatives"
            [question]="question"
            [editable]="editable"
            [alternativeFormArray]="group.get('alternatives') | cast"
            [questionType]="group.get('question_type_value').value"
            [usePoints]="usePoints"
        >
        </app-resource-builder-alternatives>
    </ng-container>
    <ng-container *ngIf="!isQuestionTypesWithAlts">
        <mat-form-field class="max-points-wrap">
            <input
                matInput
                [formControl]="group.get('points') | cast"
                type="number"
                required
                placeholder="Max question points"
                i18n-placeholder
            />
        </mat-form-field>
    </ng-container>
</div>
