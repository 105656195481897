import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
    selector: 'app-collection-browse-bottom-sheet',
    templateUrl: './collection-browse-bottom-sheet.component.html',
    styleUrls: ['./collection-browse-bottom-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionBrowseBottomSheetComponent implements OnInit {
    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}

    ngOnInit(): void {}
}
