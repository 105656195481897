import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { Observable, map } from 'rxjs';
import { CustomField, CustomFieldData } from './custom-field.model';

@Injectable({
    providedIn: 'root',
})
export class CustomFieldService {
    protected customFieldUrl: string;
    protected user_id: Observable<number>;
    protected customer_id: Observable<number>;

    constructor(
        protected config: ConfigService,
        protected builder: RequestBuilderService // protected auth: AuthService
    ) {
        this.customFieldUrl = `${config.config.apiUrl}custom-fields`;
        // this.user_id = this.auth.getUser().pipe(
        //     first(),
        //     map((user) => user.id)
        // );

        // this.customer_id = this.auth.getCustomer().pipe(
        //     first(),
        //     map((customer) => customer.id)
        // );
    }

    public addCustomField(field: CustomFieldData): Observable<CustomField> {
        return this.builder
            .post<CustomField>(`${this.customFieldUrl}`, field)
            .request()
            .pipe(map((res) => this.mapCustomField(res.data)));
    }

    public deleteCustomField(id: number, customerId: number): Observable<{ success: boolean }> {
        const req = this.builder.delete<{ success: boolean }>(`${this.customFieldUrl}/${id}`);
        req.param('customer', customerId);
        return req.request().pipe(map((res) => res.data));
    }

    public delete(id: number): Observable<{ success: boolean }> {
        const req = this.builder.delete<{ success: boolean }>(`${this.customFieldUrl}/${id}`);
        return req.request().pipe(map((res) => res.data));
    }

    public getCustomFieldTypes(): Observable<{ type: string; options: string[] }[]> {
        return this.builder
            .get<{ type: string; options: string[] }[]>(`${this.customFieldUrl}/types`)
            .request()
            .pipe(map((res) => res.data));
    }
    public restore(ids: number | number[]): Observable<CustomField | CustomField[]> {
        return this.builder
            .put<CustomField | CustomField[]>(`${this.customFieldUrl}/${ids}/restore`, null)
            .request()
            .pipe(
                map((res) =>
                    Array.isArray(res.data)
                        ? res.data.map((res) => this.mapCustomField(res))
                        : this.mapCustomField(res.data)
                )
            );
    }
    public getCustomFields(): Observable<CustomField[]> {
        return this.builder
            .get<CustomField[]>(`${this.customFieldUrl}`)
            .request()
            .pipe(map((res) => res.data));
    }

    public updateCustomField(field: CustomFieldData): Observable<CustomField> {
        return this.builder
            .put<CustomField>(`${this.customFieldUrl}/${field.id}`, field)
            .request()
            .pipe(map((res) => this.mapCustomField(res.data)));
    }

    protected mapCustomField(data: CustomFieldData): CustomField {
        return new CustomField(data);
    }
}
