import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { Question } from '@klickdata/core/question';
import { ResourceItem } from '@klickdata/core/resource-item';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceOpportunity, ResourceOpportunityData } from './resource-opportunity.model';

@Injectable({
    providedIn: 'root',
})
export class ResourceOpportunityService {
    protected resourceUrl: string;
    protected assignUrl: string;

    constructor(protected builder: RequestBuilderService, protected config: ConfigService) {
        this.resourceUrl = `${config.config.apiUrl}resource-opportunities`;
        this.assignUrl = `${config.config.apiUrl}resource-assign`;
    }

    public getOpportunity(id: number): Observable<ResourceOpportunity> {
        return this.builder
            .get<ResourceOpportunityData>(`${this.resourceUrl}/${id}`)
            .param('withStatistics', '1')
            .request()
            .pipe(map((res) => this.makeOpportunity(res.data)));
    }

    public getUserResourceOpportunities(userId: number, resourceId: number) {
        return this.builder
            .get<ResourceOpportunityData[]>(`${this.resourceUrl}`)
            .param('user', userId)
            .param('resource', resourceId.toString(10))
            .request()
            .pipe(map((res) => this.mapOpportunity(res.data)));
    }

    public assignOccasionMsg(id: number, data: { subject?: string; body?: string }): Observable<ResourceOpportunity> {
        return this.builder
            .post<ResourceOpportunityData>(`${this.resourceUrl}/${id}/message`, data)
            .request()
            .pipe(map((res) => this.makeOpportunity(res.data)));
    }
    public createOpportunity(
        data: ResourceOpportunityData,
        eager?: string | string[]
    ): Observable<ResourceOpportunity> {
        const req = this.builder.post<ResourceOpportunityData>(`${this.resourceUrl}`, data);
        if (eager) {
            req.putEager(eager);
        }
        return req.request().pipe(map((res) => this.makeOpportunity(res.data)));
    }
    public update(data: ResourceOpportunityData, eager?: string | string[]): Observable<ResourceOpportunity> {
        const req = this.builder.put<ResourceOpportunityData>(`${this.resourceUrl}/${data.id}`, data);
        if (eager) {
            req.putEager(eager);
        }
        return req.request().pipe(map((res) => this.makeOpportunity(res.data)));
    }
    public postReviews(ids: number[], data: ResourceOpportunityData): Observable<any> {
        return this.builder
            .post<ResourceOpportunityData[]>(`${this.resourceUrl}/reviews`, {
                ids: ids,
                ...data,
            })
            .request()
            .pipe(map((res) => res.data));
    }
    public destroy(id: number): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${id}`)
            .request()
            .pipe(map((res) => res.data));
    }

    public destroyAssign(id: number): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.assignUrl}/${id}`)
            .request()
            .pipe(map((res) => res.data));
    }

    public getResults(id: number): Observable<ResourceItem[]> {
        return this.builder
            .get<ResourceItem[]>(`${this.resourceUrl}/${id}/result`)
            .request()
            .pipe(map((res) => res.data));
    }

    public getQsResults(id: number): Observable<Question[]> {
        return this.builder
            .get<Question[]>(`${this.resourceUrl}/${id}/result`)
            .request()
            .pipe(map((res) => res.data));
    }

    protected mapOpportunity(data: ResourceOpportunityData[]): ResourceOpportunity[] {
        return data.map((item) => this.makeOpportunity(item));
    }

    protected makeOpportunity(data: ResourceOpportunityData): ResourceOpportunity {
        return new ResourceOpportunity(data);
    }

    protected sortResults(data: ResourceItem[]): ResourceItem[] {
        return data.sort((a: ResourceItem, b: ResourceItem) => a.index - b.index);
    }
    public done(opportunity: ResourceOpportunity): Observable<ResourceOpportunity> {
        return this.builder
            .put<ResourceOpportunityData>(`${this.resourceUrl}/${opportunity.id}`, { done: moment() })
            .request()
            .pipe(map((res) => this.makeOpportunity(res.data)));
    }
}
