<div fxFlex="grow" fxLayout="column">
    <app-table-filter-expansion-panel [expanded]="false" [hasTopBorder]="false">
        <app-table-filter
            [filtersTogglesInOnBtn]="true"
            [cacheScope]="AppScope.TASK"
            [inGlobalFilter]="true"
            color="accent"
            #filter
        >
            <app-table-search
                toggleBtnLabel="Task"
                i18n-toggleBtnLabel
                [inGlobalFilter]="true"
                class="align-space"
                searchFilterRef
            ></app-table-search>
            <app-table-date-filter
                [filterSpecs]="deadlineFilterSpecs"
                [inGlobalFilter]="true"
                color="accent"
                deadlineFilterRef
            ></app-table-date-filter>
            <app-user-chip-search-filter
                [inGlobalFilter]="true"
                [additionalSearchFilters]="[{ property: 'userRole', items: ['superadmin'] }]"
                [isMaster]="true"
                [filterSpecs]="assigneeFilterSpecs"
                i18n-searchLabel
                searchLabel="Assignee"
                i18n-chosingLabel
                chosingLabel="Choose assignee"
                i18n-searchPlaceholder
                searchPlaceholder="Filter by assignees"
                assigneesFilterRef
            ></app-user-chip-search-filter>
            <app-user-chip-search-filter
                [inGlobalFilter]="true"
                [additionalSearchFilters]="[{ property: 'userRole', items: ['superadmin'] }]"
                [isMaster]="true"
                [filterSpecs]="managerFilterSpecs"
                i18n-searchLabel
                searchLabel="Owner"
                i18n-chosingLabel
                chosingLabel="Choose owner"
                i18n-searchPlaceholder
                searchPlaceholder="Filter by owners"
                managersFilterRef
            ></app-user-chip-search-filter>
            <app-tag-chip-search-filter
                [inGlobalFilter]="true"
                fxFlex="50%"
                fxFlex.lt-md="100%"
                fxLayoutAlign="start"
                tagsFilterRef
            ></app-tag-chip-search-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Action"
                i18n-label="@@action"
                [property]="GlobalFilterProperty.TASK_ACTION"
                [options]="taskActionsOptions"
                taskActionRef
            ></app-select-filter>
            <!-- <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Type"
                i18n-label="@@type"
                [property]="GlobalFilterProperty.TASK_TYPE"
                [options]="taskTypesOptions"
                taskTypeRef
            ></app-select-filter> -->
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Done"
                i18n-label="@@done"
                [property]="GlobalFilterProperty.TASK_DONE"
                [options]="taskDoneOptions"
                taskDoneRef
            ></app-select-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Prio"
                i18n-label="@@prio"
                [property]="GlobalFilterProperty.TASK_PRIO"
                [options]="taskPrioOptions"
                taskPrioRef
            ></app-select-filter>
        </app-table-filter>
    </app-table-filter-expansion-panel>
    <mat-progress-bar
        class="progress-table-loader mt-1"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>

    <mat-table
        #table
        class="mt-1 todos-table"
        [dataSource]="dataSource"
        matSortActive="deadline"
        matSortDirection="asc"
        matSort
        multiTemplateDataRows
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (click)="$event.stopPropagation()" (change)="isAllSelected = $event?.checked">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <mat-checkbox
                    *ngIf="!task.loading"
                    (click)="$event.stopPropagation()"
                    (change)="task.loading = true; changeTaskDone(task, $event?.checked)"
                    [checked]="task.done"
                >
                </mat-checkbox>
                <ng-container *ngIf="task.loading">
                    <app-kd-logo-loading size="small"></app-kd-logo-loading>
                </ng-container>
                <div *ngIf="isAllSelected" fxLayoutAlign="space-around center" class="multi-select-tasks">
                    <!-- <button mat-button (click)="markSelectedAsDone()">
                        <mat-icon>checked</mat-icon>
                        <span i18n>Mark as done</span>
                    </button> -->
                    <button mat-button (click)="delete()">
                        <mat-icon>delete</mat-icon>
                        <span i18n>Delete</span>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deadline">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@deadline">Deadline</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <span class="mobile-label" i18n="@@deadline">Deadline:</span>
                <span *ngIf="task.deadline" class="time-label">
                    {{ task.deadline | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="owner">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Owner</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <span class="mobile-label" i18n>Owner:</span>
                <ng-container *ngIf="!!task.managers?.length">
                    <span
                        [matTooltip]="manager?.fname + ' ' + manager?.lname"
                        class="pointer task-name"
                        *ngFor="let manager of task.managers; let isLast = last"
                        ><span>{{ manager?.fname | nameSign }}{{ manager?.lname | nameSign }}</span>
                        <span *ngIf="!isLast">,</span></span
                    >
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assignee">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Assignee</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <span class="mobile-label" i18n>Assignee:</span>
                <ng-container *ngIf="!!task.assignees?.length">
                    <span
                        [matTooltip]="assignee?.fname + ' ' + assignee?.lname"
                        class="pointer task-name"
                        (click)="$event.stopPropagation(); onSelectAssignee(assignee)"
                        *ngFor="let assignee of task.assignees; let isLast = last"
                        ><span>{{ assignee?.fname | nameSign }}{{ assignee?.lname | nameSign }}</span>
                        <span *ngIf="!isLast">,</span></span
                    >
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="body">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Task</span>
                </div>
            </mat-header-cell>
            <mat-cell [ngClass]="{ 'cell-is-expanded': expandedRow === task }" #taskCellRef *matCellDef="let task">
                <span class="task">{{ task.body }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Tags</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <div class="shadow-maker"></div>
                <div *ngIf="!!task.tags?.length" class="tags-wrapper">
                    <div (click)="$event.stopPropagation(); onTagClick(tag)" *ngFor="let tag of task.tags">
                        <span>{{ tag.name }}</span>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="customers">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Customers</span>
                </div>
            </mat-header-cell>
            <mat-cell [ngClass]="{ 'cell-is-expanded': expandedRow === task }" #customerCellRef *matCellDef="let task">
                <div class="customer-shadow-maker"></div>
                <div *ngIf="!!task.customers?.length" class="customers-wrapper">
                    <div
                        (click)="$event.stopPropagation(); onCustomerClick(customer)"
                        *ngFor="let customer of task.customers"
                    >
                        <span>{{ customer.name }}</span>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="priority">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Prio</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <span
                    class="prio-task task capitaliza"
                    (click)="$event.stopPropagation()"
                    [matMenuTriggerFor]="prioMenu"
                    >{{ task.priority }}</span
                >
                <mat-menu #prioMenu="matMenu">
                    <button
                        (click)="task.loading = true; updateTaskPrio(task, prio.value)"
                        *ngFor="let prio of taskPrioOptions"
                        mat-menu-item
                    >
                        <span>{{ prio.title }}</span>
                    </button>
                </mat-menu>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Action</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <span class="task orange capitaliza">{{ task.action }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Type</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <mat-icon
                    [matTooltip]="(task.type | taskType)?.label"
                    [ngStyle]="{ color: (task.type | taskType)?.color }"
                    class="task"
                    >{{ (task.type | taskType).icon }}</mat-icon
                >
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef>Tools</mat-header-cell>
            <mat-cell class="btns-actions" *matCellDef="let task">
                <button
                    (click)="$event.stopPropagation(); UpdateTask(task)"
                    matTooltip="Edit task"
                    mat-icon-button
                    i18n-matTooltip="@@editTask"
                    class="expand-btn"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation()"
                    [matTooltip]="getInfo(task)"
                    matTooltipClass="task-info-tip"
                    mat-icon-button
                    class="expand-btn"
                >
                    <mat-icon class="material-icons-outlined">info</mat-icon>
                </button>
                <button
                    *ngIf="!task.loading"
                    (click)="$event.stopPropagation(); expandedRow = expandedRow === task ? null : task"
                    matTooltip="View details"
                    mat-icon-button
                    i18n-matTooltip="@@viewDetails"
                    [ngClass]="{ 'has-child': task.children_count > 0 }"
                    class="expand-btn"
                >
                    <mat-icon>{{ expandedRow === task ? 'expand_less' : 'expand_more' }}</mat-icon>
                </button>
                <ng-container *ngIf="task.loading">
                    <app-kd-logo-loading size="small"></app-kd-logo-loading>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let task" [attr.colspan]="columns.length">
                <div class="example-element-detail" [@detailExpand]="task == expandedRow ? 'expanded' : 'collapsed'">
                    <ng-container *ngIf="expandedRow == task">
                        <div class="expanded-item-wrapper">
                            <div class="expand-btns" fxLayoutGap="10px" fxLayoutAlign="center center">
                                <button
                                    (click)="$event.stopPropagation(); delete(task)"
                                    matTooltip="Delete task"
                                    mat-icon-button
                                    i18n-matTooltip="@@deleteTask"
                                    class="expand-btn"
                                >
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                            <app-task-notes-list
                                class="customer-hr-notes-parent"
                                [taskId]="task.id"
                            ></app-task-notes-list>
                            <!-- <app-shared-hr-notes
                                *ngIf="!!task.assignees?.length"
                                [user]="task.assignees[0]"
                                class="customer-hr-notes-parent"
                            ></app-shared-hr-notes> -->
                        </div>
                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let task; columns: columns"
            [class.example-expanded-row]="expandedRow === task"
            [class.loading-row]="task.loading"
            class="example-element-row"
            (click)="expandedRow = expandedRow === task ? null : task"
        ></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>
