import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { RoleService } from '@klickdata/core/role';

@Component({
    selector: 'app-menu-side-add-role',
    templateUrl: './menu-side-add-role.component.html',
    styleUrls: ['./menu-side-add-role.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddRoleComponent implements OnInit, OnChanges {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public addRoleForm: FormGroup;

    constructor(protected fb: FormBuilder, protected roleService: RoleService) {
        this.addRoleForm = this.fb.group({
            title: ['', Validators.required],
            description: [''],
            baseRole: ['', Validators.required],
        });
    }
    public submit() {
        if (this.addRoleForm.invalid) {
            FormHelper.markForm(this.addRoleForm);
            return;
        }
        this.onClose.emit({ action: SideNaveActionsTypes.POSITIVE, data: this.addRoleForm.value });
        this.addRoleForm.reset();
    }
    ngOnInit(): void {

    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.navData && this.navData.data.context === 'edit') {
            this.addRoleForm.patchValue({
                title: this.navData.data.role.title,
                description: this.navData.data.role.description,
                baseRole: this.navData.data.role.parent_id,
            });
            this.addRoleForm.get('baseRole').disable();
        }
    }
    compareFn(r1: any, r2: any): boolean {
        return r1 && r2 && r1.id === r2.id;
    }
}
