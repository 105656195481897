import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { SectionType } from '@klickdata/core/resource/src/types.enum';
import { Section } from '@klickdata/core/section';
import { DashboardSectionDataService } from '@klickdata/home/src/dashboard-with-sections/dashboard-section-data.service';

@Component({
    selector: 'app-sections-viewer-sheet',
    templateUrl: './sections-viewer-sheet.component.html',
    styleUrls: ['./sections-viewer-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DashboardSectionDataService],
})
export class SectionsViewerSheetComponent implements OnInit {
    SectionType = SectionType;

    constructor(
        public bottomSheetRef: MatBottomSheetRef<SectionsViewerSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public sections: Section[],
        public service: DashboardSectionDataService
    ) {}

    ngOnInit(): void {}
}
