import { IDataModel, Model } from 'libs/core/application';

export interface PermissionData extends IDataModel {
    id?: number;
    title?: string;
    checked?: boolean;
    function_title?: string;
    name?: string;
    role_permission_level?: string;
    levels?: string[];
    default_level?: string;
}
export class Permission extends Model<PermissionData> {
    id: number;
    title: string;
    checked: boolean;
    function_title: string;
    name: string;
    role_permission_level: string;
    levels: string[];
    default_level: string;
}

export interface Policy {
    id: string;
    title?: string;
    checked?: boolean;
    permissions?: Permission[];
}

export interface FunctionGroup {
    id: string;
    title?: string;
    permission_count?: number;
    checked?: boolean;
    selected_permission_count?: number;
    policies?: Policy[];
}

export interface AccessLevel {
    id: string;
    title?: string;
    checked?: boolean;
    permissions?: Permission[];
}

// const functionData = [
//     {
//         id: 'iam',
//         title: 'Academy settings',
//         permission_count: 3,
//         selected_permission_count: 2,
//         policies: [
//             {
//                 id: 'iam.roles',
//                 title: '',
//                 permissions: [
//                     { title: 'Read', id: 'iam.roles:GET', checked: true },
//                     { title: 'List', id: 'iam.roles:LIST', checked: true },
//                     { title: 'Delete', id: 'iam.roles:DELETE', checked: false },
//                 ],
//             },
//         ],
//     },
// ];
// const accessLevelData = [
//     {
//         id: 'GET',
//         title: 'Read',
//         permissions: [
//             { function_title:'Identity and Access Management',  title: 'Academy roles', id: 'iam.roles:GET', checked: true },
//         ],
//     },
// ];
