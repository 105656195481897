<div class="admin-switch" *appCanAccess="accessAdminOrMaster">
    <mat-toolbar color="primary" ngClass.xs="xs" fxLayout.xs="row" fxLayoutAlign.xs="space-between center">
        <button class="learner" mat-button [routerLink]="['/home']" routerLinkActive="active">
            <mat-icon class="kd-icon-16 bold">person</mat-icon>
            <ng-container i18n="@@learner">Learner</ng-container>
        </button>
        <button class="admin" mat-button [routerLink]="['/admin']" routerLinkActive="active"
                *appCanAccess="accessAdmin">
            <mat-icon class="kd-icon-16 bold">people_alt</mat-icon>
            <ng-container i18n="@@admin">Admin</ng-container>
        </button>
        <button class="master" mat-button [routerLink]="['/master']" routerLinkActive="active"
                *appCanAccess="accessMaster">
            <mat-icon>account_balance</mat-icon>
            Master
        </button>
    </mat-toolbar>
</div>
