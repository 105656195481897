import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-tags-assign-sheet',
  templateUrl: './tags-assign-sheet.component.html',
  styleUrls: ['./tags-assign-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagsAssignSheetComponent implements OnInit, OnDestroy {
    public assignForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public saving: boolean;

    constructor(
        public bottomSheetRef: MatBottomSheetRef<TagsAssignSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected resourceService: ResourceService
    ) {
        this.assignForm = this.fb.group({
            id: [],
            sync_all_users_recommend: [],
            users_attach_recommend: [[]],
            users_detach_recommend: [[]],

            // sync_all_groups: [],
            // groups_attach: [[]],
            // groups_detach: [[]],
        });
    }

    ngOnInit() {
        this.assignForm.patchValue({ id: this.data.id });
    }

    public submit() {
        if (
            this.assignForm.value.sync_all_users_recommend !== null ||
            this.assignForm.value.users_attach_recommend?.length ||
            this.assignForm.value.users_detach_recommend?.length
            // this.assignForm.value.sync_all_groups !== null
            // this.assignForm.value.groups_attach?.length ||
            // this.assignForm.value.groups_detach?.length ||
        ) {
            const data = new Resource(this.assignForm.value).getData();
            this.saving = true;
            this.resourceService
                .update(data)
                .pipe(
                    map((res) => {
                        this.assignForm.patchValue({ id: data.id });
                        return res;
                    }),
                    takeUntil(this.destroy)
                )
                .subscribe(() => {
                    this.saving = false;
                    this.bottomSheetRef.dismiss(this.data);
                });
        } else {
            this.bottomSheetRef.dismiss(this.data);
        }
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
