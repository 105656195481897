import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { Customer, CustomerService } from '@klickdata/core/customer';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, first, map, share, shareReplay } from 'rxjs/operators';
import { ResourceData } from '../resource.model';
import { ResourceService } from '../resource.service';
import { ResourceTypes } from '../types.enum';
import { ResourceTag, ResourceTagData } from './resource-tag.model';
import { Utils } from '@klickdata/core/util';

@Injectable({
    providedIn: 'root',
})
export class TagService {
    protected resourceUrl: string;
    protected customer_id: Observable<number>;
    protected user_id: Observable<number>;
    protected parent_id: number;
    private customersMap = new Map<number, Observable<Customer>>();

    constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected logger: LoggerService,
        protected resourceService: ResourceService,
        protected customerService: CustomerService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}tags`;

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );

        this.user_id = this.auth.getUser().pipe(
            first(),
            map((user) => user.id)
        );
    }

    public getCustomerTags(params: {
        langId?: number | number[];
        query?: string;
        limit?: number;
    }): Observable<ResourceTag[]> {
        const builder = this.builder.get<ResourceTagData[]>(this.resourceUrl);
        if (this.auth.getNK3PlatformValue() !== 'master') {
            builder.param('publicOrCustomer', this.customer_id);
        } else {
            builder.param('master', this.user_id);
        }
        builder.limit(params.limit || 25);
        if (params.query) {
            builder.param('query', params.query);
        }

        if (!Utils.isEmpty(params.langId)) {
            builder.param('language', Array.isArray(params.langId) ? params.langId.join() : params.langId);
        }
        return builder.request().pipe(map((res) => this.mapTags(res.data)));
    }

    public getResourceTags(id: number, sortAlpabtacally?: boolean): Observable<ResourceTag[]> {
        const builder = this.builder.get<ResourceTagData[]>(this.resourceUrl);

        builder.param('resource', id);
        if (sortAlpabtacally) {
            builder.param('sort', 'name');
        }

        return builder.request().pipe(map((res) => this.mapTags(res.data)));
    }
    public getTags(ids: number[], sortAlpabtacally?: boolean): Observable<ResourceTag[]> {
        const builder = this.builder.get<ResourceTagData[]>(this.resourceUrl).param('ids', ids.join());
        if (this.auth.getNK3PlatformValue() !== 'master') {
            builder.param('publicOrCustomer', this.customer_id);
        } else {
            builder.param('master', this.user_id);
        }
        if (sortAlpabtacally) {
            builder.param('sort', 'name');
        }
        return builder.request().pipe(map((res) => this.mapTags(res.data)));
    }

    public createManyTags(names: string[], langId: number, customerId: number): Observable<ResourceTag[]> {
        return this.builder
            .post<ResourceTagData[]>(`${this.resourceUrl}/batch`, {
                names: names,
                language_id: langId,
                customer_id: customerId,
            })
            .request()
            .pipe(map((res) => this.mapTags(res.data)));
    }

    public createResourceTag(tag: ResourceTagData): Observable<ResourceTag> {
        return this.builder
            .post<ResourceTagData>(this.resourceUrl, tag)
            .request()
            .pipe(map((res) => this.createTag(res.data)));
    }

    public updateResourceTag(tag: ResourceTagData): Observable<ResourceTag> {
        return this.builder
            .put<ResourceTagData>(`${this.resourceUrl}/${tag.id}`, tag)
            .request()
            .pipe(map((res) => this.createTag(res.data)));
    }

    protected mapTags(data: ResourceTagData[], query?: string, resourceType?: string): ResourceTag[] {
        return data.map((item) => this.createTag(item, query, resourceType));
    }

    protected createTag(data: ResourceTagData, query?: string, resourceType?: string): ResourceTag {
        const tag = new ResourceTag(data);

        if (tag.resource_ids) {
            tag.resources = this.resourceService.getResources(tag.resource_ids).pipe(shareReplay());
            tag.courses = tag.resources.pipe(
                map((resources) =>
                    resources.filter(
                        (resource) => ResourceTypes.parentType(resource.type_id) === ResourceTypes.E_COURSE
                    )
                )
            );
        }
        tag.customer = tag.customer_id ? this.getCustomer(tag.customer_id) : of(new Customer({ name: 'Anonymous' }));
        return tag;
    }

    protected getCustomer(customer_id: number): Observable<Customer> {
        let customer = this.customersMap.get(customer_id);
        if (customer) {
            return customer;
        }
        customer = this.customerService.getCustomer(customer_id).pipe(
            share(),
            catchError(() => of(new Customer({ name: 'Anonymous' })))
        );
        this.customersMap.set(customer_id, customer);
        return customer;
    }

    public destroy(resource: ResourceTag): Observable<{ success: boolean } | {}> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${resource.id}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((res: HttpErrorResponse) => {
                    this.logger.error(res.error.error.messages[0]);

                    return EMPTY;
                })
            );
    }

    public restore(tagId: number): any {
        return this.builder
            .put<ResourceData>(`${this.resourceUrl}/${tagId}/restore`, null)
            .request()
            .pipe(map((res) => this.createTag(res.data)));
    }
}
