import { IDataModel, Model } from '@klickdata/core/application/src/model/model-interface';
import { Media } from '@klickdata/core/media/src/media.model';
import { Observable } from 'rxjs';

export interface AnswerData extends IDataModel {
    id?: number;
    resource_id?: number;
    question_id?: number;
    alternative_id?: number;
    value?: string;
    media_id?: number;
    opportunity_id?: number;
    question_item_id?: number;
}

export class Answer extends Model<AnswerData> {
    id: number;
    resource_id: number;
    question_id: number;
    alternative_id: number;
    value: string;
    opportunity_id: number;
    question_item_id: number;
    media$: Observable<Media>;
}
