import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl } from '@angular/forms';
import { MediaType } from '@klickdata/core/media/src/media-type';
import { Media } from '@klickdata/core/media/src/media.model';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { S3MediaService } from '@klickdata/core/media/src/s3-media.service';
import { AppScope } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import {
    BehaviorSubject,
    Observable,
    Subject,
    debounceTime,
    distinctUntilChanged,
    filter,
    of,
    switchMap,
    takeUntil,
} from 'rxjs';

@Component({
    selector: 'app-video-link-control',
    templateUrl: './video-link-control.component.html',
    styleUrls: ['./video-link-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VideoLinkControlComponent),
            multi: true,
        },
    ],
})
export class VideoLinkControlComponent implements ControlValueAccessor, OnDestroy, OnInit {
    public videoTypeControl = new FormControl('file');
    public linkControl = new FormControl('');
    public videoMedia: Media;
    public progress$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public onSavingFile: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private destroy: Subject<boolean> = new Subject<boolean>();
    MediaType = MediaType;
    @Input() radioLabel = $localize`Video Source`;
    @Input() scope: AppScope;
    @Input() _mediaId: number;
    Utils = Utils;
    get mediaId(): number {
        return this._mediaId;
    }
    set mediaId(mediaId: number) {
        this._mediaId = mediaId;
        this.propagateChange(this._mediaId);
    }
    @Input() dropSize = '200px';
    public get dropStyle() {
        return {
            height: this.dropSize,
            width: this.dropSize,
            flex: `0 1 ${this.dropSize}`,
        };
    }
    constructor(
        protected mediaService: MediaService,
        protected cdr: ChangeDetectorRef,
        protected s3MediaService: S3MediaService
    ) {}
    ngOnInit(): void {
        this.linkControl.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                takeUntil(this.destroy),
                filter((link) => Utils.isVideoUrl(link) && Utils.isUrl(link)),
                switchMap((link) => this.mediaService.uploadMediaByLink({ link: link, type: MediaType.LINK }))
            )
            .subscribe((media) => {
                this.videoMedia = media;
                this.mediaId = Number(media.id);
            });
    }
    public onChange(e: any): void {
        this.mediaId = e.value;
    }

    public writeValue(mediaId: any): void {
        this._mediaId = mediaId;
        if (mediaId) {
            this.mediaService
                .getMedia(mediaId)
                .pipe(takeUntil(this.destroy))
                .subscribe((media) => {
                    this.videoMedia = media;
                    this.videoTypeControl.patchValue(media.type === MediaType.LINK ? 'link' : 'file');
                    if (media.type === MediaType.LINK) {
                        this.linkControl.patchValue(media.url || media.src);
                    }
                    this.cdr.markForCheck();
                });
        }
    }
    public clearMedia() {
        this.videoMedia = null;
        this.mediaId = null;
        this.propagateChange(this.mediaId);
        this.progress$.next(null);
    }
    goToLink(url: string) {
        if (Utils.isUrl(url)) {
            window.open(url, '_blank');
        }
    }
    public prepareFile(event) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.fileHandler(fileList[0]);
        }
    }
    public fileHandler(file: File) {
        this.onSavingFile.next(true);
        this.s3MediaService
            .uploadMediaToS3(file, this.progress$)
            .pipe(
                switchMap((media) =>
                    this.mediaService.uploadMediaByLink({
                        ...media.getData(),
                        scope_id: this.scope,
                    })
                )
            )
            .subscribe(
                (media) => {
                    this.onSavingFile.next(false);
                    if (media) {
                        this.videoMedia = media;
                        this.mediaId = Number(media.id);
                    }
                },
                (err) => {} // TODO on error
            );
    }
    public propagateChange = (_: any) => {};

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}
    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
