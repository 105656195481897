<div class="mt-2" fxFlex="0 1 1000px" fxFlex.lt-lg="80%" fxFlex.lt-md="100%">
    <mat-accordion fxFlex="0 1 100%" displayMode="flat" multi="true">
        <div (click)="open()" class="toggler" fxLayoutAlign="end">
            <span *ngIf="openAll" i18n="@@resultHideAll">
                Hide all
            </span>
            <span *ngIf="!openAll" i18n="@@resultShowAll">
                Show all
            </span>
        </div>
        <mat-expansion-panel *ngFor="let item of items | async; let i = index" [expanded]="openAll">
            <mat-expansion-panel-header [collapsedHeight]="'60px'" [expandedHeight]="'60px'">
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="0 1 auto" class="question-title-index">
                        {{ i + 1 }}
                    </div>
                    <div fxFlex="0 1 auto" fxLayout="column" class="item-desc">
                        <ng-container [ngSwitch]="item.item_type_value">
                            <span *ngSwitchCase="ResourceItemTypes.COURSE" fxFlex="0 1 auto" fxLayout="row">
                                <mat-icon fontSet="klickdata" fontIcon="kd-icon-player"></mat-icon>
                                <span i18n="@@e_course">Course</span>
                            </span>
                            <span *ngSwitchCase="ResourceItemTypes.TEST" fxFlex="0 1 auto" fxLayout="row">
                                <mat-icon fontSet="klickdata" fontIcon="kd-icon-test"></mat-icon>
                                <span i18n="@@test">Test</span>
                            </span>
                            <span *ngSwitchCase="ResourceItemTypes.SURVEY" fxFlex="0 1 auto" fxLayout="row">
                                <mat-icon fontSet="klickdata" fontIcon="kd-icon-survey"></mat-icon>
                                <span i18n="@@survey">Survey</span>
                            </span>
                            <span *ngSwitchCase="ResourceItemTypes.MATERIAL" fxFlex="0 1 auto" fxLayout="row">
                                <mat-icon fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                                <span i18n="@@material">Material</span>
                            </span>
                        </ng-container>
                        <p fxFlex="0 1 auto">{{ item.name }}</p>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container [ngSwitch]="item.item_type_value">
                <app-resource-item-question
                    *ngSwitchCase="ResourceItemTypes.QUESTION"
                    [item]="item"
                    [showPoints]="showPoints"
                ></app-resource-item-question>
                <app-resource-item
                    *ngSwitchCase="ResourceItemTypes.COURSE"
                    [item]="item"
                    previewTooltip="Preview course"
                    i18n-previewTooltip="@@previewCourse"
                ></app-resource-item>
                <app-resource-item
                    *ngSwitchCase="ResourceItemTypes.TEST"
                    [item]="item"
                    previewTooltip="Preview test"
                    i18n-previewTooltip="@@previewTest"
                ></app-resource-item>
                <app-resource-item
                    *ngSwitchCase="ResourceItemTypes.SURVEY"
                    [item]="item"
                    previewTooltip="Preview survey"
                    i18n-previewTooltip="@@previewSurvey"
                ></app-resource-item>
                <app-resource-item
                    *ngSwitchCase="ResourceItemTypes.MATERIAL"
                    [item]="item"
                    previewTooltip="Preview material"
                    i18n-previewTooltip="@@previewMaterial"
                ></app-resource-item>
            </ng-container>
        </mat-expansion-panel>
        <div (click)="open()" class="toggler" fxLayoutAlign="end">
            <span *ngIf="openAll" i18n="@@resultHideAll">
                Hide all
            </span>
            <span *ngIf="!openAll" i18n="@@resultShowAll">
                Show all
            </span>
        </div>
    </mat-accordion>
</div>
