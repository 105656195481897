import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { MessageDeletedComponent, MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceTypes } from '@klickdata/core/resource';
import { Section, SectionPublicOrder, SectionService } from '@klickdata/core/section';
import { GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { BrowseFolderComponent } from '../../folder/browse-folder/browse-folder.component';
import { SelectModelBaseDirective } from '../select-model-base/select-model-base.component';
import { SectionSelectDatatableService } from './section-select-datatable.service';

@Component({
    selector: 'app-section-select',
    templateUrl: './section-select.component.html',
    styleUrls: ['./section-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SectionSelectDatatableService],
})
export class SectionSelectComponent
    extends SelectModelBaseDirective<Section>
    implements OnInit, AfterViewInit, OnDestroy
{
    @Input() accessForm: FormGroup;
    @Input() referenceFilter: string;
    @Input() scope: 'public' | 'assignedOrCustomer' | 'customer' = 'assignedOrCustomer';
    @Input() hideTools: boolean;
    @Input() params: [{ property: string; items: any[] }];
    @Output() add: EventEmitter<void> = new EventEmitter<void>();
    @Output() edit: EventEmitter<Section> = new EventEmitter<Section>();
    ResourceTypes = ResourceTypes;
    @Input() sync_all_key = 'sync_all_sections';
    @Input() attachKey = 'sections_attach';
    @Input() detachKey = 'sections_detach';
    columns: string[];
    public itemSelectedOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;

    constructor(
        public datatableService: SectionSelectDatatableService,
        protected bottomSheet: MatBottomSheet,
        protected mobileService: MobileService,
        protected sectionService: SectionService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected messageService: MessageService,
        protected cdRef: ChangeDetectorRef
    ) {
        super(datatableService, mobileService);
        this.itemSelectedOptions = Utils.getItemSelectedOptions();
    }

    /**
     *
     */
    public ngOnInit(): void {
        super.ngOnInit();
        this.columns = this.hideTools
            ? ['select', 'title', 'updated_at']
            : ['select', 'title', 'updated_at', 'public_order', 'tools'];
    }

    protected refresh(id: any): void {
        if ('public' === this.scope) {
            this.filter.createOrUpdateFilter([
                { property: this.referenceFilter, items: [id] },
                { property: this.scope, items: [1] },
            ]);
        } else {
            this.auth
                .getCustomer()
                .pipe(first(), takeUntil(this.destroy))
                .subscribe((customer) => {
                    const filters = [
                        { property: this.referenceFilter, items: [id] },
                        {
                            property: this.scope,
                            items: [customer.id],
                        },
                    ];
                    this.filter.createOrUpdateFilter(
                        this.params && this.params.length ? [...filters, ...this.params] : [...filters]
                    );
                });
        }
    }

    public showResource(section: Section) {
        this.bottomSheet.open(BrowseFolderComponent, {
            data: section,
            panelClass: 'sheet-wrapper',
        });
    }

    public deleteSection(section: Section) {
        this.dialog
            .open(DeleteDialogComponent)
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => result),
                switchMap(() => this.sectionService.destroy(section))
            )
            .subscribe((success) => {
                if (success) {
                    this.dataSource.refresh();
                    this.messageService.openMessage(MessageDeletedComponent);
                }
            });
    }

    drop(event: CdkDragDrop<Section[]>) {
        this.dataSource.moveItemInArray(event.previousIndex, event.currentIndex);
        const section = this.dataSource.data[event.currentIndex];
        if (
            section.updateOrder(
                this.dataSource.data,
                event.currentIndex,
                event.previousIndex,
                SectionPublicOrder.getInstance()
            )
        )
            this.updatePriority(section);
    }

    private updatePriority(section: Section) {
        this.sectionService
            .update({
                id: section.id,
                customer_id: <number>this.filter.getFilterValue(this.referenceFilter)[0],
                public_order: section.public_order,
            })
            .pipe(takeUntil(this.destroy), first())
            .subscribe();
    }
}
