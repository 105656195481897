import { Cast, CastType, IDataModel } from '@klickdata/core/application/src/model/model-interface';
import { Customer } from '@klickdata/core/customer';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { TranslatableModel } from './../../resource/translatable.model';
import { Grade } from './grade.model';

export interface GradeSystemData extends IDataModel {
    id?: number;
    title?: string;
    customer_id?: number;
    created_by?: number;
    grade_ids?: number[];
    public?: boolean;
    created_at?: string;
    updated_at?: string;
    title_translation?: { [key: string]: string };
}

export class GradeSystem extends TranslatableModel<GradeSystemData> {
    title: string;
    customer_id: number;
    created_by: number;
    grade_ids?: number[];
    public?: boolean;
    @Cast(CastType.MOMENT)
    created_at: moment.Moment;
    @Cast(CastType.MOMENT)
    updated_at: moment.Moment;

    customer: Observable<Customer>;
    grades: Observable<Grade[]>;
}
