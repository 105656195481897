<div class="user-select-wrapper" fxLayout="column" fxFlex="0 0 100%">
    <app-table-filter [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Filter by selection"
            i18n-label="@@filterBySelection"
            [property]="GlobalFilterProperty.SELECTED"
            [options]="itemSelectedOptions"
            selectedItemsRef
        >
        </app-select-filter>
    </app-table-filter>

    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="selection-Info">
        <div fxLayoutAlign="start center" fxLayoutGap="5px" class="main">
            <span i18n="@@numberOfCategories" class="label">Number of categories:</span>
            <span class="total">{{ total }}</span>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="5px" class="selected" *ngIf="selected">
            <span class="selectedNu" i18n="@@selected">Selected:</span>
            {{ selected }}
        </div>
    </div>
    <mat-progress-bar
        class="progress progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        [matSortActive]="defaultSorting"
        (matSortChange)="onSortDataChages($event)"
        matSort
        [class.mobileView]="isMobile"
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let category">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectToggle(category)"
                    [checked]="modelChecked(category)"
                    [disabled]="category.disabled"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let category">
                <span i18n="@@title" class="mobile-label"> Title </span>
                {{ category.title }}</mat-cell
            >
        </ng-container>

        <ng-container matColumnDef="parent">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@categoryname"> User name </mat-header-cell>
            <mat-cell *matCellDef="let category">
                <span i18n="@@categoryname" class="mobile-label"> User name </span>
                <span *ngIf="category.parent?.title" class="parent-cat cat-chip-item">{{
                    category.parent?.title
                }}</span>
            </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@email"> Email </mat-header-cell>
            <mat-cell *matCellDef="let category">
                <span i18n="@@email" class="mobile-label"> Email </span>
                {{ category.email }}</mat-cell
            >
        </ng-container> -->

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let category; columns: columns"
            [class.disable-row]="category.disabled"
            (click)="!category.disabled && selectToggle(category)"
        ></mat-row>
    </mat-table>

    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>
