<form class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>{{ navData.data.icon }}</mat-icon
        ><span>{{ navData.data.title }}</span
        ><button
            *ngIf="navData.data.type === AppScope.QUESTIONS"
            class="qs-add-icon"
            mat-icon-button
            (click)="goToFAQ()"
        >
            <mat-icon>question_mark</mat-icon>
        </button>
    </div>

    <!-- <div class="desc">{{ navData.data.description }}</div> -->

    <mat-selection-list
        class="qs-options mt-2"
        (selectionChange)="onSelectionChange($event.options[0].value)"
        [multiple]="false"
        fxLayoutGap="1em"
        fxLayout="column"
    >
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(QuestionTypes.RADIO) != -1"
            [value]="QuestionTypes.RADIO"
            (click)="onSelectionChange(QuestionTypes.RADIO)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>radio_button_checked</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n="@@singleChoice"> Single Choice </span>
                <mat-icon
                    class="qs-m"
                    matTooltip="A multiple choice question (MCQ) with one correct alternatives and one or many incorrect alternatives. "
                    i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(QuestionTypes.CHECKBOX) != -1"
            [value]="QuestionTypes.CHECKBOX"
            (click)="onSelectionChange(QuestionTypes.CHECKBOX)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>check_box</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n="@@multipleChoice"> Multiple Choice </span>
                <mat-icon
                    class="qs-m"
                    matTooltip="A multiple choice question (MCQ) with one or many (all) correct answers and zero, one or many incorrect alternatives. "
                    i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>

        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(QuestionTypes.INPUT) != -1"
            [value]="QuestionTypes.INPUT"
            (click)="onSelectionChange(QuestionTypes.INPUT)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>edit_note</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n> Open Question </span>
                <mat-icon
                    class="qs-m"
                    matTooltip="An Open question is a question with an open answer in which the answer is written in a text field. The input is then later reviewed by a third party like the teacher, the course creator or an admin for review. It is used in surveys and tests."
                    i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(QuestionTypes.TEXTAREA) != -1"
            [value]="QuestionTypes.TEXTAREA"
            (click)="onSelectionChange(QuestionTypes.TEXTAREA)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>text_fields</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n="Resource tools text btn@@text"> Text </span>
                <mat-icon class="qs-m" matTooltip="Add a multi line answer" i18n-matTooltip="@@addMultiLineAnswer"
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>

        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(QuestionTypes.WOK) != -1"
            [value]="QuestionTypes.WOK"
            (click)="onSelectionChange(QuestionTypes.WOK)"
            class="blue-wok"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <img src="assets/images/WOK_icon.png" />
                <span fxFlex="grow" class="tool-button-text" i18n="@@importMCQsFromWOK"> Import MCQs from WOK </span>
                <mat-icon
                    class="qs-m"
                    matTooltip="WOK is a database with over 500000 MCQs with single choice questions with or without images from Wikimedia Commons that are indexed to Wikipedia articles and part of the ecosystem of the KLMS. If you use the images in these imported questions when you import, you are bound to follow the Open Source legal licence. "
                    i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(QuestionTypes.AI) != -1"
            [value]="null"
            (click)="showdAIQs.next(true)"
            class="blue-ai"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>smart_toy</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n="Resource tools text btn@@text">
                    Add questions with AI
                </span>
                <mat-icon class="qs-m" matTooltip="Add questions with AI" i18n-matTooltip>question_mark</mat-icon>
            </div>
        </mat-list-option>

        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.CREATE_NEW_TEST) != -1"
            [value]="ResourceBuildingItems.CREATE_NEW_TEST"
            (click)="onSelectionChange(ResourceBuildingItems.CREATE_NEW_TEST)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>edit_document</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>Create a new test</span>
                <mat-icon class="qs-m" matTooltip="Create a new test and use it directly" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.CREATE_NEW_SURVEY) != -1"
            [value]="ResourceBuildingItems.CREATE_NEW_SURVEY"
            (click)="onSelectionChange(ResourceBuildingItems.CREATE_NEW_SURVEY)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>edit_document</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>Create a new survey</span>
                <mat-icon class="qs-m" matTooltip="Create a new survey and use it directly" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.CREATE_NEW_EVENT) != -1"
            [value]="ResourceBuildingItems.CREATE_NEW_EVENT"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>edit_document</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>Create a new event</span>
                <mat-icon class="qs-m" matTooltip="Create a new event and use it directly" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.CREATE_NEW_LINK_MATERIAL) != -1"
            [value]="ResourceBuildingItems.CREATE_NEW_LINK_MATERIAL"
            (click)="onSelectionChange(ResourceBuildingItems.CREATE_NEW_LINK_MATERIAL)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>add_link</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>Link</span>
                <mat-icon class="qs-m" matTooltip="Add link and use it directly" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL) != -1"
            [value]="ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL"
            (click)="onSelectionChange(ResourceBuildingItems.CREATE_NEW_FILE_MATERIAL)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>cloud_upload</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>File</span>
                <mat-icon class="qs-m" matTooltip="Upload file and use it directly" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>

        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL) != -1"
            [value]="ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL"
            (click)="onSelectionChange(ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>post_add</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>Text</span>
                <mat-icon class="qs-m" matTooltip="Add text as material and use it directly" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.CREATE_NEW_RECORD_MATERIAL) != -1"
            [value]="ResourceBuildingItems.CREATE_NEW_RECORD_MATERIAL"
            (click)="onSelectionChange(ResourceBuildingItems.CREATE_NEW_RECORD_MATERIAL); showdHint.next(true)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>radio_button_checked</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>Record</span>
                <mat-icon class="qs-m" matTooltip="Record a video or audio or both" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL) != -1"
            [value]="ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL"
            (click)="onSelectionChange(ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL)"
            class="blue-ai"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>smart_toy</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>AI Prompter</span>
                <mat-icon class="qs-m" matTooltip="Create AI Prompter" i18n-matTooltip>question_mark</mat-icon>
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.TEST_LIBRARY) != -1"
            [value]="ResourceBuildingItems.TEST_LIBRARY"
            (click)="onSelectionChange(ResourceBuildingItems.TEST_LIBRARY)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>library_books</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>Library</span>
                <mat-icon class="qs-m" matTooltip="All the tests that exist in KOL" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.EVENT_LIBRARY) != -1"
            [value]="ResourceBuildingItems.EVENT_LIBRARY"
            (click)="onSelectionChange(ResourceBuildingItems.EVENT_LIBRARY)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>library_books</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>Library</span>
                <mat-icon class="qs-m" matTooltip="All the events that exist in KOL" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.MATERIAL_LIBRARY) != -1"
            [value]="ResourceBuildingItems.MATERIAL_LIBRARY"
            (click)="onSelectionChange(ResourceBuildingItems.MATERIAL_LIBRARY)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>library_books</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>Library</span>
                <mat-icon class="qs-m" matTooltip="All the materials that exist in KOL" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
        <mat-list-option
            *ngIf="navData.data.buildingItems.indexOf(ResourceBuildingItems.SURVEY_LIBRARY) != -1"
            [value]="ResourceBuildingItems.SURVEY_LIBRARY"
            (click)="onSelectionChange(ResourceBuildingItems.SURVEY_LIBRARY)"
        >
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>library_books</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>Library</span>
                <mat-icon class="qs-m" matTooltip="All the surveys that exist in KOL" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </mat-list-option>
    </mat-selection-list>
</form>

<div
    class="record-hint"
    *ngIf="
        selectedValue === ResourceBuildingItems.CREATE_NEW_RECORD_MATERIAL &&
        showdHint.value &&
        navData.data.context !== 'materialCreation'
    "
>
    <span i18n>
        Due to the complexity of the record inside the Course: You need to record to Create Material first in the upper
        right menu and then use this recording with the button add from Library when you create the Course.</span
    ><mat-icon class="record-tooltip" matTooltip="FAQ" i18n-matTooltip (click)="goToRecordFAQ()"
        >question_mark</mat-icon
    >
</div>

<div [formGroup]="promptForm" class="record-hint" *ngIf="showdAIQs.value">
    <app-user-setting-input
        type="text"
        i18n-placeholder
        placeholder="Enter questions prompt"
        [control]="promptForm.get('text') | cast"
    >
        <label role="input-label" fxLayoutGap="4px">
            <span i18n>Questions prompt</span>
        </label>
    </app-user-setting-input>
    <div class="qs-num">
        <div class="head-title mt-0" i18n>Number of questions</div>
        <mat-slider formControlName="question_count" fxFlex="grow" min="1" max="20" step="1" showTickMarks discrete>
            <input matSliderThumb />
        </mat-slider>
        <div class="number-labels qs-num-label">
            <p class="sec-font">1</p>
            <p class="sec-font">5</p>
            <p class="sec-font">10</p>
            <p class="sec-font">15</p>
            <p class="sec-font">20</p>
        </div>
    </div>
    <div class="qs-diff">
        <div class="head-title mb-0" i18n>Difficulity level</div>
        <mat-slider formControlName="difficulty_level" fxFlex="grow" min="1" max="5" step="1">
            <input matSliderThumb />
        </mat-slider>
        <div class="number-labels diff-label">
            <p class="sec-font" i18n>Up to 10</p>
            <p class="sec-font" i18n>Up to 15</p>
            <p class="sec-font" i18n>Adult</p>
            <p class="sec-font" i18n>Professional</p>
            <p class="sec-font" i18n>Master</p>
        </div>
    </div>
    <div fxLayout="column" class="mt-1" fxLayoutGap="10px">
        <div class="head-title mt-1" i18n>Questions Type</div>
        <div
            [formGroup]="promptForm.get('question_type_value') | cast"
            class="course-sett-not"
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <mat-checkbox formControlName="radio" i18n>Single</mat-checkbox>
            <mat-checkbox formControlName="checkbox" i18n>Multiple</mat-checkbox>
            <mat-checkbox formControlName="input" i18n>Open</mat-checkbox>
        </div>
    </div>
    <div class="action mt-1" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            [disabled]="!promptForm.valid"
            (click)="showdAIQs.next(false); onSelectionChange(QuestionTypes.AI)"
            fxFlex="0 0 auto"
            class="mobile-button positive"
            mat-raised-button
            i18n
        >
            Generate questions
        </button>
    </div>
</div>
<!-- THis is deprecated since we display
the ai prompter inside the materials like all others -->
<!-- <div
    class="record-hint"
    fxLayout="column"
    *ngIf="selectedValue === ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL && showdHint"
>
    <app-ai-prompter-manager
        [resource]="navData.data.resource"
        (onListMaterial)="onListMaterial()"
        (onMaterialCreated)="onEditMaterial($event)"
        (onMaterialEdited)="onEditMaterial($event, true)"
    ></app-ai-prompter-manager>
</div> -->
