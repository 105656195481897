import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigService } from '@klickdata/core/config/src/config.service';

@Injectable({ providedIn: 'root' })
export class LoggerService {
    constructor(protected snackBar: MatSnackBar, protected config: ConfigService) {}

    public error(message: string): void {
        this.snackBar.open(message, 'X', {
            duration: 3000,
            panelClass: ['error'],
        });

        if (this.config.config.buildMode !== 'production') {
            console.error(message);
        }
    }

    public info(message: string): void {
        if (this.config.config.buildMode !== 'production') {
            // tslint:disable-next-line
            console.info(message);
        }
    }

    public log(...data: any[]): void {
        if (this.config.config.buildMode !== 'production') {
            // tslint:disable-next-line
            console.log(...data);
        }
    }
}
