<mat-tab-group [ngClass]="{ 'one-item-test': testItems.length == 1 }" class="midddle-part-tabs qs-tabs">
    <mat-tab *ngFor="let item of testItems; let index = index" fxFlex>
        <ng-template mat-tab-label>
            <span i18n>Test</span> <ng-container *ngIf="index !== 0">{{ index + 1 }} </ng-container></ng-template
        >
        <ng-template matTabContent>
            <div *ngIf="item.child_resource | async; let resource" fxFlex="0 1 100%" fxLayout="column">
                <ng-container *ngIf="resource && item.child_occasion">
                    <app-general-test-results
                        *ngIf="item.child_occasion | async; let occasion"
                        class="course-results-qs"
                        [user]="user"
                        [isCourseItem]="true"
                        [resource]="resource"
                        [occasion]="occasion"
                    ></app-general-test-results>
                </ng-container>
                <ng-container *ngIf="!resource || !item.child_occasion">
                    <h3 fxLayoutAlign="center center" i18n="@@youDidnotFinishThisTest">Test is not completed</h3>
                </ng-container>
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>
