import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { EMPTY ,  Observable } from 'rxjs';
import { ResourceItemType } from '@klickdata/core/resource-item/src/type/resource-item-type.model';

@Injectable({
    providedIn: 'root',
})
export class ResourceItemTypeService {
    protected resourceUrl: string;

    constructor(
        protected config: ConfigService,
        protected builder: RequestBuilderService,
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}resource-item-types`;
    }

    public getType(type_value: string): Observable<ResourceItemType> {
        return EMPTY;
    }
}
