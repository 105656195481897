import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { SubMessage } from '@klickdata/core/notification';
import { UserService } from '@klickdata/core/user';

@Component({
    selector: 'app-chat-dialog',
    templateUrl: './chat-dialog.component.html',
    styleUrls: ['./chat-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubMessageComponent implements OnInit {
    @Input() dialog: SubMessage;
    @Input() authorDir: 'left' | 'right';
    constructor(protected userService: UserService) {}

    ngOnInit(): void {}
}
