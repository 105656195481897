<div class="fields-wrapper">
    <ng-container [ngSwitch]="customFieldForm.value.type">
        <app-custom-fields-input-type
            *ngSwitchCase="FieldType.TEXT_INPUT"
            [customFieldForm]="customFieldForm"
        ></app-custom-fields-input-type>
        <app-custom-fields-dropdown-type
            *ngSwitchCase="FieldType.DROPDOWN"
            [customFieldForm]="customFieldForm"
        ></app-custom-fields-dropdown-type>
        <app-custom-fields-date-type
            *ngSwitchCase="FieldType.DATE"
            [customFieldForm]="customFieldForm"
        ></app-custom-fields-date-type>
        <app-custom-fields-switch-type
            *ngSwitchCase="FieldType.SWITCH"
            [customFieldForm]="customFieldForm"
        ></app-custom-fields-switch-type>
        <app-custom-fields-radio-type
            *ngSwitchCase="FieldType.SINGLE_CHOICE"
            [ngClass]="{ wide: customFieldForm.value.options?.length < 4 }"
            [customFieldForm]="customFieldForm"
        ></app-custom-fields-radio-type>
        <app-custom-fields-checkbox-type
            *ngSwitchCase="FieldType.MULTIPLE_CHOICE"
            [ngClass]="{ wide: customFieldForm.value.options?.length < 4 }"
            [customFieldForm]="customFieldForm"
        ></app-custom-fields-checkbox-type>
    </ng-container>
</div>
