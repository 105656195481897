import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerLicense } from './customer-license.model';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';

@Injectable({
    providedIn: 'root',
})
export class CustomerLicenseService {
    public resourceUrl: string;

    constructor(protected builder: RequestBuilderService, protected config: ConfigService) {
        this.resourceUrl = `${config.config.apiUrl}licenses`;
    }

    public getLicenses(): Observable<CustomerLicense[]> {
        return this.builder
            .get<CustomerLicense[]>(this.resourceUrl)
            .request()
            .pipe(map((res) => res.data));
    }

    public getLicense(id: string): Observable<CustomerLicense> {
        return this.builder
            .get<CustomerLicense>(`${this.resourceUrl}/${id}`)
            .request()
            .pipe(map((res) => res.data));
    }
}
