import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'resourceExaminationTypes',
})
export class ResourceExaminationTypesPipe implements PipeTransform {
    transform(value: string) {
        switch (value) {
            case 'Participation':
                return $localize`Participation`;
            case 'Diploma':
                return $localize`Diploma`;
            case 'Certification':
                return $localize`Certification`;
            default:
                return $localize`Participation`;
        }
    }
}
