import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LanguageService } from '@klickdata/core/localization';
import { ResourceTag } from '@klickdata/core/resource';
import { Observable, Subject, debounceTime, distinctUntilChanged, map, merge, of, takeUntil } from 'rxjs';

@Component({
    selector: 'app-table-tags-on-the-fly',
    templateUrl: './table-tags-on-the-fly.component.html',
    styleUrls: ['./table-tags-on-the-fly.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTagsOnTheFlyComponent implements OnInit, AfterViewInit, OnDestroy {
    private _tags: ResourceTag[];
    @Input() set tags(tags: ResourceTag[] | number[]) {
        if (!tags?.length) return;

        if (typeof tags[0] === 'number') {
            this.handlingTagIds = true;
            this.tagIdsControl.patchValue(tags as number[]);
        } else {
            this._tags = tags as ResourceTag[];
            this.handlingTagIds = false;
            this.tagItemsControl.patchValue(tags);
        }
    }
    public handlingTagIds: boolean;
    @Input() currentLaguageId: number;
    @Input() canRemoveTags = false;
    @Output() onUpdateTags: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() onTagClick: EventEmitter<ResourceTag> = new EventEmitter<ResourceTag>();
    public destroy: Subject<boolean> = new Subject<boolean>();
    public tagIdsControl = new FormControl([]);
    public tagItemsControl = new FormControl([]);
    public currentLaguageId$: Observable<number>;
    constructor(protected langService: LanguageService) {}

    ngOnInit(): void {
        this.currentLaguageId$ = Number(this.currentLaguageId)
            ? of(this.currentLaguageId)
            : this.langService
                  .getLanguageByKey(this.langService.getCurrentLanguage().value)
                  .pipe(map((lang) => lang.id));
    }
    ngAfterViewInit(): void {
        merge(this.tagIdsControl.valueChanges, this.tagItemsControl.valueChanges)
            .pipe(takeUntil(this.destroy), debounceTime(300), distinctUntilChanged())
            .subscribe((tagsIds) => this.onUpdateTags.emit(tagsIds));
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
