import { Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { AppScope } from '@klickdata/core/resource';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MediaService } from './media.service';

@Directive()
export abstract class MediaImgUploaderBase implements OnInit, OnDestroy, OnChanges {
    @Input() uploaderOption: FileUploaderOptions;
    @Input() mediaIdKey = 'id';
    @Input() uploadUrl: string;
    @Input() params: {
        [key: string]: any;
    };
    @Input() itemAlias: string;
    @Input() allowedMimeType: string[];
    @Input() maxFileSize: number;
    @Input() scope: AppScope;
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onMediaStored: EventEmitter<number> = new EventEmitter<number>();
    @Output() onError: EventEmitter<string | string[]> = new EventEmitter<string | string[]>();
    public destroy: Subject<boolean> = new Subject<boolean>();
    public uploader: FileUploader;
    public mediaId: number;

    constructor(protected mediaService: MediaService, protected auth: AuthService, protected message: MessageService) {}

    public ngOnInit(): void {
        if (!this.uploaderOption) {
            this.auth
                .getToken()
                .pipe(first(), takeUntil(this.destroy))
                .subscribe((token) => {
                    const options: FileUploaderOptions = {
                        url: this.uploadUrl ? this.uploadUrl : this.mediaService.mediaURL,
                        autoUpload: true,
                        authToken: `Bearer ${token}`,
                        additionalParameter: this.scope ? { scope_id: this.scope } : {},
                    };

                    if (this.itemAlias) {
                        options.itemAlias = this.itemAlias;
                    }

                    if (this.params) {
                        options.additionalParameter = { ...options.additionalParameter, ...this.params };
                    }

                    if (this.allowedMimeType) {
                        options.allowedMimeType = this.allowedMimeType;
                    }

                    if (this.maxFileSize) {
                        options.maxFileSize = this.maxFileSize;
                    }

                    this.uploader = new FileUploader(options);
                });
        } else {
            this.uploader = new FileUploader(this.uploaderOption);
        }

        this.uploader.onCompleteItem = (
            item: FileItem,
            response: string,
            status: number,
            headers: ParsedResponseHeaders
        ) => {
            this.onComplete(item, response, status, headers);
        };

        this.uploader.onBeforeUploadItem = () => {
            this.saving.emit(true);
            this.onError.emit(null);
        };

        this.uploader.onWhenAddingFileFailed = (item, filter) => {
            if (this.maxFileSize) {
                const msg = `File size exceeds the limit of ${this.maxFileSize / 1024 / 1024} MB`;
                this.message.openMessage(MessageErrorComponent, msg);
                this.onError.emit(msg);
            }
        };
    }
    public ngOnChanges(): void {
        if (this.allowedMimeType && this.uploader) {
            this.uploader.options.allowedMimeType = this.allowedMimeType;
        }

        if (this.maxFileSize && this.uploader) {
            this.uploader.options.maxFileSize = this.maxFileSize;
        }
    }
    public abstract onComplete(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): void;

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
