import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageDeletedComponent, MessageService } from '@klickdata/core/message';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { FadeInOutAnimation } from '../../../../core/animations/fade-in.animation';
import { EditorTemplate } from '../editor-template.model';
import { EditorTemplateService } from '../editor-template.service';

@Component({
    selector: 'app-editor-templates',
    templateUrl: './templates.component.html',
    styleUrls: ['./templates.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [FadeInOutAnimation],
})
export class TemplatesComponent implements OnInit {
    /**
     * Content of a new possible template.
     */
    @Input()
    public newContent: string;

    @Input()
    public customerId: number;

    @Input()
    public subject: string;

    @Input()
    public fieldName: string;

    @Input()
    public templateSelected: boolean;

    @Input()
    public fitTemplatesInSmallScreen = true;

    /**
     * Emits the value of the selected template.
     * @event
     */
    @Output()
    public selected: EventEmitter<EditorTemplate> = new EventEmitter<EditorTemplate>();

    /**
     * Active mode of the editor templates components.
     */
    public mode: 'select' | 'edit' = 'select';
    public templates: Observable<EditorTemplate[]>;
    public selectedTemplate: EditorTemplate;
    protected updateSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    protected filterSubject: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(protected templateService: EditorTemplateService, protected message: MessageService) {}

    public ngOnInit(): void {
        this.templates = combineLatest(
            this.updateSubject,
            this.filterSubject.pipe(distinctUntilChanged(), debounceTime(300))
        ).pipe(switchMap(([didUpdate, query]) => this.templateService.getUserFieldTemplates(this.fieldName, query)));
    }

    public selectTemplate(template: EditorTemplate): void {
        this.mode = 'select';
        this.selected.emit(template);
    }

    public createTemplate(): void {
        this.mode = 'edit';
        this.selectedTemplate = null;
    }

    public onCancelled(): void {
        this.mode = 'select';
        this.selectedTemplate = null;
    }

    public editTemplate(template: EditorTemplate): void {
        this.selectedTemplate = template;
        this.mode = 'edit';
    }

    public deleteTemplate(template: EditorTemplate): void {
        this.templateService.destroy(template).subscribe(() => {
            this.message.openMessage(MessageDeletedComponent);
            this.updateSubject.next(true);
        });
    }

    public onSearch(term: string) {
        this.filterSubject.next(term);
    }
}
