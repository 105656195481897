<div [formGroup]="contextTypeForm" fxLayout="column">
    <div class="input-field-parent">
        <mat-form-field class="role-field w-100">
            <mat-label i18n>Select context type</mat-label>
            <mat-select fxFlex="1 1 100%" formControlName="type">
                <mat-option *ngFor="let option of noteContextOptions" [value]="option.property">{{
                    option.label
                }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="mt-1" *ngIf="contextTypeForm.value.type === 'learner'">
        <app-user-chip-select
            titleLabel="Search Connection"
            i18n-titleLabel
            formControlName="user"
        ></app-user-chip-select>
    </div>
    <div class="mt-1" *ngIf="contextTypeForm.value.type === 'client'">
        <app-customer-chip-select
            titleLabel="Search Client"
            i18n-titleLabel
            formControlName="customer"
        ></app-customer-chip-select>
    </div>
</div>
