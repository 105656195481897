import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { Folder } from '@klickdata/core/folder';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceTypes } from '@klickdata/core/resource';
import { GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { first, takeUntil } from 'rxjs/operators';
import { BrowseFolderComponent } from '../../folder/browse-folder/browse-folder.component';
import { SelectModelBaseDirective } from '../select-model-base/select-model-base.component';
import { TagSelectDatatableService } from './tag-select-datatable.service';

@Component({
  selector: 'app-tags-select',
  templateUrl: './tags-select.component.html',
  styleUrls: ['./tags-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TagSelectDatatableService],
})
export class TagsSelectComponent extends SelectModelBaseDirective<Folder>
implements OnInit, AfterViewInit, CanComponentDeactivate, OnDestroy
{
@Input() accessForm: FormGroup;
@Input() referenceFilter: string;
@Input() hideTools = false;
@Input() scope: 'public' | 'assignedOrCustomer' = 'assignedOrCustomer';
@Input() customer_id: number;
ResourceTypes = ResourceTypes;
public columns: string[];
protected sync_all_key = 'sync_all_folders';
protected attachKey = 'folders_attach';
protected detachKey = 'folders_detach';
public itemSelectedOptions: SelectFilterOption[];
GlobalFilterProperty = GlobalFilterProperty;

constructor(
    public datatableService: TagSelectDatatableService,
    protected dialog: MatDialog,
    protected auth: AuthService,
    protected mobileService: MobileService,
    protected bottomSheet: MatBottomSheet
) {
    super(datatableService, mobileService);
    this.itemSelectedOptions = Utils.getItemSelectedOptions();
}

/**
 *
 */
public ngOnInit(): void {
    super.ngOnInit();
    this.columns = this.hideTools ? ['select', 'title', 'updated_at'] : ['select', 'title', 'updated_at', 'tools'];
}

protected refresh(id: any): void {
    if ('public' === this.scope) {
        this.filter.createOrUpdateFilter([
            { property: this.referenceFilter, items: [id] },
            { property: this.scope, items: [1] },
        ]);
    } else {
        if (this.customer_id) {
            this.filter.createOrUpdateFilter([
                { property: this.referenceFilter, items: [id] },
                {
                    property: this.scope,
                    items: [this.customer_id],
                },
            ]);
        } else {
            this.auth
                .getCustomer()
                .pipe(first(), takeUntil(this.destroy))
                .subscribe((customer) =>
                    this.filter.createOrUpdateFilter([
                        { property: this.referenceFilter, items: [id] },
                        {
                            property: this.scope,
                            items: [customer.id],
                        },
                    ])
                );
        }
    }
}

public showResource(folder: Folder) {
    this.bottomSheet.open(BrowseFolderComponent, {
        data: { ...folder, scope: this.scope },
        panelClass: 'sheet-wrapper',
    });
}
}
