import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, AsyncValidatorFn, FormControl } from '@angular/forms';
import { getAllProperties } from './prop.decorator';

@Injectable({
    providedIn: 'root',
})
export class FormGeneratorService {
    constructor(protected fb: FormBuilder) {}

    public generate<T>(form: T): FormGroup {
        const controls = {};
        const properties = getAllProperties(form.constructor);

        for (const property of Object.keys(properties)) {
            controls[property] = this.fb.control(properties[property].default, properties[property].validation);
        }

        return this.fb.group(controls);
    }

    public array(): FormArray {
        return this.fb.array([]);
    }

    public control(
        formState: any,
        validator?: ValidatorFn | ValidatorFn[] | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
    ): FormControl {
        return this.fb.control(formState, validator, asyncValidator);
    }
}
