import { Pipe, PipeTransform } from '@angular/core';
import { MessageScopes } from '@klickdata/core/notification';

@Pipe({
    name: 'notificationType',
})
export class NotificationTypePipe implements PipeTransform {
    transform(type: number): { title: string; color: string; icon: string; toolTip: string } {
        switch (type) {
            case MessageScopes.CASE:
                return { title: $localize`Case`, color: '#3e5365', icon: 'school', toolTip: $localize`A case message` };
            case MessageScopes.DIALOGE:
                return {
                    title: $localize`Chat`,
                    color: '#3e5365',
                    icon: 'forum',
                    toolTip: $localize`Saved Chat Dialog`,
                };
            case MessageScopes.ALERT:
                return {
                    title: $localize`Report`,
                    color: '#3e5365',
                    icon: 'warning_amber',
                    toolTip: $localize`Report tickets`,
                };

            case MessageScopes.NOTIFICATION:
                return {
                    title: $localize`Notification`,
                    color: '#3e5365',
                    icon: 'manage_accounts',
                    toolTip: $localize`Notification / System Alerts`,
                };

            default:
                break;
        }
        return null;
    }
}
