import { IDataModel } from '@klickdata/core/application/src/model/model-interface';

export interface PlayerItemData extends IDataModel {
    position?: number;
    artnr?: string;
    course_id?: string;
    video_id?: number;
    parent_id?: number;
    video_type?: string;
}

export class PlayerItem {
    position: number;
    artnr: string;
    course_id: string;
    video_id: number;
    video_type: string;

    constructor(data?: PlayerItemData) {
        if (data) {
            this.artnr = data.artnr;
            this.course_id = data.course_id;
            this.position = data.position;
            this.video_id = data.video_id;
            this.video_type = data.video_type;
        }
    }

    public getData(): PlayerItemData {
        return {
            position: this.position,
            video_id: this.video_id,
            video_type: this.video_type,
        };
    }
}
