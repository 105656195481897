import { Injectable } from '@angular/core';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Task, TaskData, TaskService } from '@klickdata/core/task';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TaskListService extends TaskService implements TableHttpService<Task> {
    public fetchData(source: TableSource<Task>): Observable<ResponseData<Task[]>> {
        const req = this.builder.get<TaskData[]>(this.tasksUrl);
        req.putEager('tags,managers,customers');
        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<TaskData[]>) => {
                return {
                    data: this.mapTask(res.data),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }
}
