import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Customer } from '@klickdata/core/customer';
import { AppScope, Resource, ResourceData, ResourceService } from '@klickdata/core/resource';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-resource-access-specific-permission',
    templateUrl: './resource-access-specific-permission.component.html',
    styleUrls: ['./resource-access-specific-permission.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceAccessSpecificPermissionComponent implements OnInit, OnDestroy {
    public permForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public saving: boolean;
    AppScope = AppScope;

    constructor(
        public bottomSheetRef: MatBottomSheetRef<ResourceAccessSpecificPermissionComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected resourceService: ResourceService
    ) {
        this.permForm = this.fb.group({
            users: this.fb.group({
                id: [],
                sync_all: [],
                attach_ids: [[]],
                detach_ids: [[]],
            }),
            groups: this.fb.group({
                id: [],
                sync_all: [],
                attach_ids: [[]],
                detach_ids: [[]],
            }),
            teams: this.fb.group({
                id: [],
                sync_all: [],
                attach_ids: [[]],
                detach_ids: [[]],
            }),
        });
    }

    ngOnInit() {
        this.permForm.get('users').get('id').patchValue(this.data.resource.id);
        this.permForm.get('groups').get('id').patchValue(this.data.resource.id);
        this.permForm.get('teams').get('id').patchValue(this.data.resource.id);
        this.permForm.markAsPristine();
    }

    public submit() {
        if (
            this.permForm.value.users.sync_all !== null ||
            this.permForm.value.users.detach_ids?.length ||
            this.permForm.value.users.attach_ids?.length ||
            this.permForm.value.groups.sync_all !== null ||
            this.permForm.value.groups.detach_ids?.length ||
            this.permForm.value.groups.attach_ids?.length ||
            this.permForm.value.teams.sync_all !== null ||
            this.permForm.value.teams.detach_ids?.length ||
            this.permForm.value.teams.attach_ids?.length
        ) {
            this.saving = true;
            this.resourceService
                .update(this.prepareResData(), true)
                .pipe(
                    takeUntil(this.destroy),
                    map((resource) => resource.collaboration)
                )
                .subscribe((res) => {
                    this.saving = false;
                    this.bottomSheetRef.dismiss(res);
                });
        } else {
            this.bottomSheetRef.dismiss();
        }
    }
    prepareResData(): ResourceData {
        let data = this.permForm.value;
        data.id = this.data.resource.id;
        delete data.users.id;
        delete data.groups.id;
        delete data.teams.id;
        data.permissionType = this.data.perm.type;
        if (!this.data.customer.division_mode) {
            delete data.teams;
        }
        return new Resource(data).getData();
    }
    getScopeName(customer: Customer, scope: number) {
        return customer.scope_names[scope] ?? AppScope.label(scope);
    }
    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
