<div class="courses-list-container" fxLayout="column" fxLayoutGap="1em">
    <mat-accordion class="filters-acc catalog-accordion" displayMode="flat">
        <mat-expansion-panel class="catalog-panel" [expanded]="true" #expansionPanel>
            <mat-expansion-panel-header class="catalog-panel-header"> </mat-expansion-panel-header>
            <app-table-toolbar class="toolbar" color="accent">
                <app-table-filter
                    [cacheScope]="AppScope.RESOURCES"
                    [inGlobalFilter]="true"
                    color="accent"
                    class="res-list-table-filter catalog-table-filter"
                    #filter
                >
                    <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
                    <app-language-filter
                        [inGlobalFilter]="true"
                        #langFilter
                        fxFlex="0 0 auto"
                        fxLayout="row"
                        langFilterRef
                    ></app-language-filter>
                    <app-instructor-chip-search-filter
                        fxFlex="50%"
                        fxFlex.lt-md="100%"
                        fxLayoutAlign="start"
                        instructorsFilterRef
                    ></app-instructor-chip-search-filter>
                    <app-author-chip-search-filter
                        fxFlex="50%"
                        fxFlex.lt-md="100%"
                        fxLayoutAlign="start"
                        authorsFilterRef
                        [scope_id]="scope"
                    ></app-author-chip-search-filter>
                    <app-tag-chip-search-filter
                        [inGlobalFilter]="true"
                        [languageChange]="langFilter.filterChange"
                        fxFlex="50%"
                        fxFlex.lt-md="100%"
                        fxLayoutAlign="start"
                        tagsFilterRef
                    ></app-tag-chip-search-filter>
                    <app-select-filter
                        [inGlobalFilter]="true"
                        class="filter-space"
                        label="Source"
                        i18n-label="@@source"
                        [property]="GlobalFilterProperty.CATALOG_SOURCE"
                        [options]="catalogSourceOptions"
                        catalogAcademyRef
                    >
                    </app-select-filter>
                </app-table-filter>
            </app-table-toolbar>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-progress-bar class="progress progress-table-loader mt-1" *ngIf="isLoading" mode="indeterminate">
    </mat-progress-bar>
    <mat-accordion fxFlex="1 1 100%" [multi]="true">
        <mat-expansion-panel *ngFor="let category of categories$ | async" [expanded]="true">
            <mat-expansion-panel-header class="category-title">{{ category.title }}</mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <ng-container *ngFor="let child of category.loadedChildren">
                    <div class="child-wrapper">
                        <ng-container *ngIf="child.resources$ | async; let loadedResources; else: loading">
                            <p class="course-list-title cat-chip-item" mat-list-item>
                                {{ child.title }} ({{ loadedResources.length }})
                            </p>
                            <ng-container>
                                <div mat-list-item class="course-item">
                                    <app-main-catalog-resource-item
                                        *ngFor="let res of loadedResources"
                                        [resource]="res"
                                        (onSelectTag)="onTagClick($event)"
                                    ></app-main-catalog-resource-item>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </ng-container>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
