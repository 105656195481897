import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { APP_MOMENT_DATE_FORMATS } from '@klickdata/core/application/src/dates/app-date-format';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Component({
    selector: 'app-date-time-picker-item',
    templateUrl: './date-time-picker-item.component.html',
    styleUrls: ['./date-time-picker-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: APP_MOMENT_DATE_FORMATS }],
    // providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: APP_MOMENT_DATE_TIME_FORMATS }],
})
export class DateTimePickerItemComponent implements OnInit, OnDestroy {
    @ViewChild('input') input: ElementRef<HTMLInputElement>;
    @Input() set value(dateTime: any) {
        if (dateTime) {
            this.dateTime.setValue(dateTime ? new Date(dateTime) : null);
            this.updateTimeHover(dateTime);
        }
    }
    public destroy: Subject<boolean> = new Subject<boolean>();
    public showTimeHover: boolean;
    public dateTime = new FormControl(null);
    @Output() onDateTimeChanged: EventEmitter<Date> = new EventEmitter<Date>();
    constructor(protected cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.dateTime.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged((r1, r2) => r1.getTime() == r2.getTime())
            )
            .subscribe((value) => {
                this.onDateTimeChanged.emit(value);
                this.updateTimeHover(value);
            });
    }
    private updateTimeHover(time: any) {
        let date = new Date(time);
        this.showTimeHover = !(date.getHours() === 0 && date.getMinutes() === 0);
        this.cdr.markForCheck();
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
