import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input, LOCALE_ID } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
    selector: 'app-logotype',
    templateUrl: 'logotype.component.html',
    styleUrls: ['logotype.component.scss'],
    animations: [
        trigger('fadeInOut', [
            transition(':enter', [style({ opacity: 0 }), animate('225ms', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('255ms', style({ opacity: 0 }))]),
        ]),
    ],
})
export class LogoTypeComponent {
    // @HostBinding('@fadeInOut') fadeInOut;
    @Input() image: string;
    @Input() alt: string;
    public iconStyle: SafeStyle;
    public iconImgStyleHeight: SafeStyle;
    public iconImgStyleWidth: SafeStyle;
    public iconImgMdStyle: SafeStyle;
    protected _padding: number;

    constructor(protected sanatizer: DomSanitizer, @Inject(LOCALE_ID) protected localeId: string) {}

    @Input()
    set padding(padding: number) {
        this._padding = padding;

        const maxHeight = 70 - 2 * padding;
        const maxWidth = 200 - 2 * padding;
        const maxWidthMd = 160 - 2 * padding;

        this.iconStyle = this.sanatizer.bypassSecurityTrustStyle(`${padding}px`);

        this.iconImgStyleHeight = this.sanatizer.bypassSecurityTrustStyle(`${maxHeight}px`);

        this.iconImgStyleWidth = this.sanatizer.bypassSecurityTrustStyle(`${maxWidth}px`);

        this.iconImgMdStyle = this.sanatizer.bypassSecurityTrustStyle(`max-width: ${maxWidthMd}px`);
    }

    public redirectTo() {
        if (this.image?.includes('klickdata-logo-small.png')) {
            this.localeId === 'en'
                ? window.open('https://klickdata.se/english', '_blank')
                : window.open('https://klickdata.se/', '_blank');
        }
    }
}
