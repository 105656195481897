import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService, ResponseData } from '@klickdata/core/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Payment, PaymentData } from './payment.model';

export interface SubsPlan {
    id: number;
    max_users: number;
    trial_period: number;
    priceInUsd: number;
    recurring: string;
    description: string;
    title: string;
    type: string;
    amounts: SubsPlanAmount[];
}
export interface SubsPlanAmount {
    currency_code: string;
    value: number;
}

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    protected paypaymentUrl: string;
    protected subscriptionPlansUrl: string;

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
    ) {
        this.paypaymentUrl = `${config.config.apiUrl}payment`;
        this.subscriptionPlansUrl = `${config.config.apiUrl}subscription/plans`;
    }

    public getSubscriptionPlans(): Observable<SubsPlan[]> {
        return this.builder
            .get<SubsPlan[]>(this.subscriptionPlansUrl)
            .request()
            .pipe(map((res) => this.mapSubsPlan(res.data)));
    }
    private mapSubsPlan(plans: SubsPlan[]) {
        plans.forEach(
            (plan) => (plan.priceInUsd = plan.amounts.find((amount) => amount.currency_code === 'USD').value)
        );
        return plans;
    }
    public get(id: number): Observable<ResponseData<PaymentData>> {
        return this.builder.get<PaymentData>(`${this.paypaymentUrl}/${id}`).request();
        // .pipe(map((res) => this.makePayment(res.data)));
    }

    public store(data: PaymentData): Observable<Payment> {
        return this.builder
            .post<PaymentData>(`${this.paypaymentUrl}`, data)
            .request()
            .pipe(map((res) => this.makePayment(res.data)));
    }

    public checkPayment(payer_id: number, resource_id: number): Observable<Payment> {
        return this.builder
            .get<PaymentData[]>(`${this.paypaymentUrl}`)
            .param('payer', payer_id)
            .param('resource', resource_id)
            .request()
            .pipe(map((res) => (res.data.length ? this.makePayment(res.data[0]) : null)));
    }

    protected makePayment(data: PaymentData): Payment {
        return new Payment(data);
    }

    public pay(gateway: string, params: { planId?: number; resourceId?: number }): any {
        const req = this.builder.get<PaymentData[]>(`${this.paypaymentUrl}/page/${gateway}`);
        if (params.resourceId) {
            req.param('resource', params.resourceId);
        }
        if (params.planId) {
            req.param('plan', params.planId);
        }
        return req.request().pipe(map((res) => res.data));
    }
}
