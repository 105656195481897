<app-admin-menu *ngIf="!isHeaderWithOnlyLang"></app-admin-menu>
<app-version-alert *ngIf="notifications.indexOf('version-alert') > -1"></app-version-alert>
<mat-toolbar
    *ngIf="isHeaderWithOnlyLang"
    class="nk3-toolbar header bar"
    color="primary"
    fxLayoutAlign="space-between center"
>
    <button [routerLink]="['/guest/start']" class="back-btn">
        <mat-icon>arrow_back_ios</mat-icon>
        <span i18n="@@back">Back</span>
    </button>

    <app-language-selector class="mr-1"></app-language-selector>
</mat-toolbar>
<mat-toolbar
    *ngIf="!isHeaderWithOnlyLang"
    class="nk3-toolbar header bar"
    color="primary"
    fxLayoutAlign="space-between center"
>
    <ng-container *ngIf="customerLanding$ | async; let customerLanding; else: smallLoading">
        <app-logotype
            [image]="customerLanding.logotype_url"
            [padding]="customerLanding.logotype_padding"
            [alt]="customerLanding.title"
        ></app-logotype>
    </ng-container>

    <div class="scroll-horizontal">
        <div class="inner-menu" fxShow fxHide.lt-md="false">
            <ng-content></ng-content>
        </div>
        <div *ngIf="guest" class="contact-info">
            <span class="white" i18n="@@contactsales:">Contact sales:</span>
            <span class="red">+46 8 667 4980</span>
            <span class="white" i18n="@@or">or</span>
            <a target="_blank" class="red" href="https://klickdata.se/kontakta-oss" i18n="@@bookAMeeting"
                >Book a meeting</a
            >
        </div>
        <div *ngIf="onboarding" fxLayoutAlign="center center">
            <button (click)="showLogin()" class="login-btn" i18n="@@login">Log in</button>
            <app-language-selector></app-language-selector>
        </div>
        <div *ngIf="!onboarding" class="items-holder">
            <button
                *ngIf="checkResAllowedforAU() | async"
                class="link-material-header-btn"
                mat-icon-button
                i18n-matTooltip
                matTooltip="Create material"
                (click)="addLink()"
            >
                <mat-icon>add_circle_outline</mat-icon>
            </button>
            <app-general-chat-gpt *ngIf="checkResAllowedforAU() | async"></app-general-chat-gpt>
            <app-general-recorder
                *ngIf="checkResAllowedforAU() | async"
                fxHide.lt-md="true"
                [user]="user$ | async"
            ></app-general-recorder>
            <!-- <app-search></app-search> -->
            <button
                class="search"
                mat-icon-button
                i18n-matTooltip="@@search"
                matTooltip="Search"
                (click)="toggleSearch = !toggleSearch; this.searchService.activateSearchBox(toggleSearch)"
            >
                <mat-icon class="kd-icon-24">search</mat-icon>
            </button>
            <!-- <button class="search" mat-icon-button i18n-matTooltip="@@search" matTooltip="Search" (click)="onSearch()">
                <mat-icon class="kd-icon-24">search</mat-icon>
            </button> -->
            <ng-container *ngIf="authenticated | async">
                <app-post></app-post>

                <ng-container *ngIf="user$ | async; let user">
                    <app-image-view
                        class="userr-img-wrapper"
                        *ngIf="user.media_id"
                        [matMenuTriggerFor]="profile"
                        [ngStyle]="{ minWidth: '24px' }"
                        [isCircularImg]="true"
                        [media_id]="user.media_id"
                        paddingBottom="24px"
                    >
                        <ng-container *ngIf="!!countdown" [ngTemplateOutlet]="counter"></ng-container>
                    </app-image-view>
                    <button
                        *ngIf="!user.media_id"
                        mat-icon-button
                        class="user-main-menu-img userr-img-wrapper"
                        [matMenuTriggerFor]="profile"
                        i18n-matTooltip="@@userProfileShortcut"
                        matTooltip="User Profile"
                        matTooltipShowDelay="1000"
                    >
                        <ng-container *ngIf="!!countdown" [ngTemplateOutlet]="counter"></ng-container>
                        <mat-icon class="kd-icon-24">person</mat-icon>
                    </button>
                </ng-container>
            </ng-container>
            <mat-menu class="menu-header-options" #profile="matMenu" [overlapTrigger]="false">
                <a mat-menu-item [routerLink]="['/home/user-profile-settings']">
                    <mat-icon>settings</mat-icon>
                    <ng-container i18n="@@settings">Settings</ng-container>
                </a>

                <a mat-menu-item (click)="reset()">
                    <mat-icon>autorenew</mat-icon>
                    <ng-container i18n>Reload</ng-container>
                </a>
                <a mat-menu-item (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <ng-container i18n>Logout</ng-container>
                </a>
                <a [routerLink]="['/changelog']" class="version" mat-menu-item>
                    <ng-container *ngIf="version$ | async; let ver">
                        {{ ver.version.slice(0, ver.version.lastIndexOf('-')) }} -
                        {{ ver.date | date : 'MMM d HH.mm' }} GMT
                    </ng-container>
                </a>
            </mat-menu>
            <!-- <app-language-selector></app-language-selector> -->
        </div>
    </div>
</mat-toolbar>
<app-main-search
    (dismissSearchArea)="toggleSearch = false; searchService.activateSearchBox(false)"
    *ngIf="isSearchBoxActive | async"
></app-main-search>
<ng-template #smallLoading>
    <app-kd-logo-loading class="small-load-header" size="small"></app-kd-logo-loading>
</ng-template>

<ng-template #counter>
    <div i18n-matTooltip matTooltip="Countdown to logout" class="count-wrap">
        <div class="num">{{ countdown }}</div>
    </div>
</ng-template>
