import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { JwtInterceptor } from '@auth0/angular-jwt';
import { ConfigModule } from '@klickdata/core/config';
import { HttpModule } from '@klickdata/core/http';
import { AnswerService } from '@klickdata/player/src/core/answers/answer.service';
import { ExitService } from '@klickdata/player/src/core/exit/exit.service';
import { GradeService } from '@klickdata/player/src/core/grade/grade.service';
// import { StatisticService } from '@klickdata/player/src/core/statistic/statistic.service';
import { OccasionService } from '@klickdata/player/src/core/occasion/occasion.service';
import { PlayerService } from '@klickdata/player/src/core/player/player.service';
import { PlaylistService } from '@klickdata/player/src/core/playlist/playlist.service';
import { AlternativeService } from '@klickdata/player/src/core/question/alternative/alternative.service';
import { QuestionService } from '@klickdata/player/src/core/question/question.service';
import { ResourceItemOpportunityService } from '@klickdata/player/src/core/resource-item/resource-item.service';
import { ResourceService } from '@klickdata/player/src/core/resource/resource.service';

@NgModule({
    imports: [CommonModule, RouterModule, HttpModule, ConfigModule],
    providers: [
        ResourceService,
        QuestionService,
        AlternativeService,
        GradeService,
        PlaylistService,
        PlayerService,
        AnswerService,
        ResourceItemOpportunityService,
        // StatisticService,
        OccasionService,
        ExitService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
    ],
})
export class PlayerCoreModule {}
