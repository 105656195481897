import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Resource, ResourceData, ResourceService } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-resource-manadatory-sheet',
    templateUrl: './resource-manadatory-sheet.component.html',
    styleUrls: ['./resource-manadatory-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceManadatorySheetComponent implements OnInit, OnDestroy {
    public mandatoryForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public saving: boolean;

    constructor(
        public bottomSheetRef: MatBottomSheetRef<ResourceManadatorySheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected resourceService: ResourceService
    ) {
        this.mandatoryForm = this.fb.group({
            users: this.fb.group({
                id: [],
                sync_all: [],
                attach_ids: [[]],
                detach_ids: [[]],
            }),
            groups: this.fb.group({
                id: [],
                sync_all: [],
                attach_ids: [[]],
                detach_ids: [[]],
            }),
            mandatory: [false],
        });
    }

    ngOnInit() {
        this.mandatoryForm.patchValue({
            mandatory: this.data.resource.mandatory,
        });
        this.mandatoryForm
            .get('mandatory')
            .valueChanges.pipe(
                filter((value) => !!value),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                this.mandatoryForm.get('users').get('sync_all').patchValue(true);
                this.mandatoryForm.get('groups').get('sync_all').patchValue(true);
            });
        this.mandatoryForm.get('users').get('id').patchValue(this.data.resource.id);
        this.mandatoryForm.get('groups').get('id').patchValue(this.data.resource.id);
    }

    public submit() {
        if (
            this.mandatoryForm.value.mandatory ||
            !this.mandatoryForm.value.mandatory ||
            this.mandatoryForm.value.users.sync_all !== null ||
            this.mandatoryForm.value.users.detach_ids?.length ||
            this.mandatoryForm.value.users.attach_ids?.length ||
            this.mandatoryForm.value.groups.sync_all !== null ||
            this.mandatoryForm.value.groups.detach_ids?.length ||
            this.mandatoryForm.value.groups.attach_ids?.length
        ) {
            this.saving = true;
            this.resourceService
                .update(this.prepareResData(), true)
                .pipe(takeUntil(this.destroy))
                .subscribe((res) => {
                    this.saving = false;
                    this.bottomSheetRef.dismiss(res);
                });
        } else {
            this.bottomSheetRef.dismiss();
        }
    }
    prepareResData(): ResourceData {
        let data = this.mandatoryForm.value;
        data.id = this.data.resource.id;
        delete data.users.id;
        delete data.groups.id;
        data.users.relation_type = 'mandatory';
        data.groups.relation_type = 'mandatory';
        return new Resource(data).getData();
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
