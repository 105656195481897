import { ChangeDetectionStrategy, Component, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '@klickdata/core/application';
import { ConfirmQuitDialogComponent } from '@klickdata/core/application/src/confirm-quit-dialog/confirm-quit-dialog.component';
import { Capabilities } from '@klickdata/core/auth/src/capabilities/capabilities';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { CustomerLanding, CustomerService } from '@klickdata/core/customer';
import { MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile/src/mobile.service';
import { User } from '@klickdata/core/user';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-mobile-header',
    templateUrl: './mobile-header.component.html',
    styleUrls: ['./mobile-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHeaderComponent implements OnInit, OnChanges, OnDestroy {
    public capability = Capabilities;

    public authenticated: Observable<boolean>;
    public developer = false;
    public active: string;
    public icon: string;
    public notificationSubscription: Subscription;
    public notifications: string[] = [];
    public user$: Observable<User>;
    public customerLanding$: Observable<CustomerLanding>;
    public isSearching = false;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public version$: Observable<{ version: string; date: string }>;
    public isMasterPlatform$: Observable<boolean>;

    constructor(
        protected auth: AuthService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected config: ConfigService,
        protected application: ApplicationService,
        protected customerService: CustomerService,
        protected mobile: MobileService,
        protected dialog: MatDialog,
        protected messageService: MessageService
    ) {
        this.developer = config.config.buildMode === 'local';
        this.authenticated = this.auth.check();
    }

    public ngOnInit() {
        this.notificationSubscription = this.application
            .getNotifications()
            .pipe(takeUntil(this.destroy))
            .subscribe((notifications: string[]) => {
                this.notifications = notifications;
            });
        this.customerLanding$ = this.auth.getCustomerLanding();
        this.active = this.router.url;
        this.version$ = this.application.getVersion();
        this.user$ = this.auth.getUser();
        this.isMasterPlatform$ = this.auth.getNK3Platform().pipe(map((platform) => platform === 'master'));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.active = this.route.component['name'];
    }

    public onToggleMenu(): void {
        this.mobile.updateSideNavSub({ dataType: SideNaveDataTypes.MOBILE_MENU });
    }

    public logout(): void {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'exit_to_app',
                title: $localize`Are you sure you want to quit ?`,
                contentBody: $localize`You are about to log out of KlickData KLMS.`,
                positiveBtn: $localize`Yes`,
                negativeBtn: $localize`No`,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(filter((action) => action === SideNaveActionsTypes.POSITIVE))
            .subscribe((action) => {
                if (action) {
                    this.auth.logout();
                }
            });
    }

    /**
     * Reset authentication and reload.
     * Used for debugging.
     */
    public reset(): void {
        this.auth.reset();
    }
    addLink() {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_LINK_MATERIAL,
        });
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
