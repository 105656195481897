<div class="res-wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
    <div class="res-img-wrapper">
        <app-language-selector
            [language_id]="resource.language_id"
            class="item-catalog-lang"
            fxFlex="0 0 auto"
        ></app-language-selector>
        <app-image-view
            [smallSpinner]="true"
            [paddingBottom]="'60%'"
            [media_id]="resource.media$"
            [proportional]="true"
        ></app-image-view>
    </div>
    <div class="main-info" fxLayout="column">
        <h3 [routerLink]="['/home/dashboard/resource-details/', resource.id]" class="res-title pointer">
            <span>{{ resource.title }}</span>
            <span *ngIf="resource.type_id === ResourceTypes.GeneralTest" class="qs-number"
                >({{ resource.number_of_items }} Qs)</span
            >
        </h3>
        <p class="res-desc">
            <ng-container *ngIf="resource.bullets">{{ resource.bullets }}</ng-container>
        </p>
        <div class="info-wrrappe" fxLayoutGap="10px" fxLayoutAlign="space-between start">
            <span class="normal"
                ><span i18n>Author</span>{{ resource.author_name || $any(resource.author$ | async)?.name }}</span
            >
            <!-- Instruuctor refactor needed -->
            <!-- <span class="normal" *ngIf="resource.instructor && resource.instructor?.name"
                ><span i18n>Instructor</span>{{ resource.instructor?.name }}</span
            > -->
            <span class="normal" *ngIf="resource.price">
                <span i18n>Price</span> {{ resource.price }}&nbsp;{{ resource.currency }}
            </span>
        </div>
        <div *ngIf="resource.tags_attached" class="tags-wrapper mt-1">
            <mat-chip-list class="res-tags">
                <mat-chip
                    class="tag-chip-item"
                    *ngFor="let tag of resource.tags$ | async"
                    (click)="onSelectTag.emit(tag)"
                    >{{ tag.name }}</mat-chip
                >
            </mat-chip-list>
        </div>
    </div>
</div>
<ng-template #smallLoading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
