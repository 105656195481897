import { Injectable } from '@angular/core';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DownloadService {
    constructor(protected builder: RequestBuilderService) {}

    public download(url: string, params: { [key: string]: (number | string)[] }): Observable<Blob> {
        const builder = this.builder.get<Blob>(url);
        for (const key in params) {
            builder.param(key, params[key].join());
        }
        return builder.download();
    }
}
