export enum BookmarkCapability {
    NONE                  = 0,
    VIEW_OWN_BOOKMARK     = 1 << 0,
    MANAGE_OWN_BOOKMARK   = 1 << 1,
    VIEW_GROUP_BOOKMARK   = 1 << 2,
    MANAGE_GROUP_BOOKMARK = 1 << 3,
}
export namespace BookmarkCapability {
    export function getName(): string {
        return 'bookmark';
    }
}
