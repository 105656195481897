<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon class="icon">touch_app</mat-icon>
        <div fxLayoutAlign="start center" fxLayoutGap="3px">
            <span class="break">{{ navData.data.user?.name || navData.data.resource?.title }}</span>
        </div>
    </div>
    <div class="wrapper" fxLayout="column" *ngIf="navData.data.logs | async; let logs; else: loading">
        <div fxLayout="column" class="table-body" *ngFor="let log of $any(logs)">
            <div
                *ngIf="log.event | actionLogEvent as event"
                fxLayoutAlign="start center"
                fxLayoutGap="3px"
                class="info-wrap"
            >
                <mat-icon>{{ event.icon }}</mat-icon>
                <span class="fz-11"> {{ log.action }}:</span>
                <span class="fz-11">{{ log.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                <br />
            </div>
            <div
                *ngIf="log.author && (log.author.fname || log.author.lname)"
                fxLayoutAlign="start start"
                fxLayoutGap="3px"
                class="author-wrap fz-11"
            >
                <span i18n>By:</span>
                <span>{{ log.author?.fname }} {{ log.author?.lname }}</span>
            </div>
            <div class="activity-title fz-11" *ngIf="!log.isResource">{{ log.subject_title }}</div>
        </div>
    </div>
    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button fxFlex="0 0 auto" class="mobile-button positive" (click)="onClose.emit()" mat-raised-button i18n>
            Ok
        </button>
    </div>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
