<div class="slider-wrapper">
    <ng-container *ngIf="quotes">
        <div id="sliderWrapper" class="quotes-slider-wrapper">
            <owl-carousel-o class="cd-carousel" [options]="customOptions">
                <ng-container *ngFor="let quote of quotes">
                    <ng-template carouselSlide [id]="quote.id.toString()" [width]="scrolledItemWidth">
                        <app-image-view
                            class="quote-img"
                            [ngStyle]="{ minWidth: '80px' }"
                            [isCircularImg]="true"
                            [media_id]="quote?.quotee?.media"
                            paddingBottom="72px"
                        ></app-image-view>
                        <div class="quote-wrapper" fxLayout="column" fxLayoutAlign="center center">
                            <span class="name">{{ quote.quotee?.name }}</span>
                            <mat-icon>format_quote</mat-icon>
                            <div>{{ quote.quote || quote.label }}</div>
                            <p *ngIf="quote.created_at" class="date">
                                {{ quote.created_at | kdDateFormat : 'MMM D, YYYY' }}
                            </p>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </ng-container>
</div>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
