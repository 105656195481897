import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'dateFormatLocalized',
})
export class DateFormatLocalizedPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) protected localeId: string) {
        localeId = localeId === 'en' ? 'en-US' : localeId;
    }
    transform(value: moment.MomentInput, format?: string): string {
        const date = formatDate(moment(value).toDate(), format, this.localeId);
        return date;
    }
}
