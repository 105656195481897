import { AfterViewInit, ChangeDetectionStrategy, Component, Input, EventEmitter, Output } from '@angular/core';
import { Resource, ResourceTag, ResourceTypes } from '@klickdata/core/resource';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-main-catalog-resource-item',
    templateUrl: './main-catalog-resource-item.component.html',
    styleUrls: ['./main-catalog-resource-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainCatalogResourceItemComponent {
    @Input() resource: Resource;
    ResourceTypes = ResourceTypes;
    @Output() onSelectTag: EventEmitter<ResourceTag> = new EventEmitter();
    constructor(protected tagService: TagService) {}
}
