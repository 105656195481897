import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { ResourceManagementComponent } from '@klickdata/shared-components/src/resource-manager/resource-manager.component';
import { Observable, Subject, of } from 'rxjs';
import { first, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class ResourceContainerDirective implements OnInit, OnDestroy, CanComponentDeactivate {
    ResourceTypes = ResourceTypes;
    public destroy: Subject<boolean> = new Subject<boolean>();

    public resource$: Observable<Resource>;
    public user$: Observable<User>;

    abstract fieldsLabels: { [key: string]: string }[];
    abstract resourceManagerComp: ResourceManagementComponent;

    constructor(
        protected route: ActivatedRoute,
        protected resourceService: ResourceService,
        protected cdr: ChangeDetectorRef,
        protected auth: AuthService
    ) {}

    ngOnInit(): void {
        this.user$ = this.auth.getUser().pipe(first());
        this.resource$ = this.route.params.pipe(
            switchMap((params) => {
                if (params.resourceId && Number(params.resourceId)) {
                    return this.resourceService.getResource(params.resourceId, ['publication', 'publishment']);
                }
                return of(null);
            }),
            shareReplay(),
            takeUntil(this.destroy)
        );
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public canDeactivate(): boolean {
        return this.resourceManagerComp ? this.resourceManagerComp.canDeactivate() : true;
    }

    public saveAndDeactivate(): Observable<boolean> {
        return this.resourceManagerComp.saveAndDeactivate();
    }

    abstract openResourceListing(): void;
}
