<ng-container *ngIf="user$ | async; let user">
    <div class="header">
        <div class="filter-news">
            <app-table-filter
                (filterChange)="updateFilters($event)"
                [cacheScope]="AppScope.POST"
                [inGlobalFilter]="true"
                color="accent"
                #filter
            >
                <app-select-filter
                    [inGlobalFilter]="true"
                    class="filter-news-type-space"
                    label="All News"
                    i18n-label
                    [property]="GlobalFilterProperty.NEWS_TYPE"
                    [options]="newsOptions"
                    newsTypeRef
                >
                </app-select-filter>
                <app-language-filter
                    [inGlobalFilter]="true"
                    #langFilter
                    fxFlex="0 0 auto"
                    fxLayout="row"
                    langFilterRef
                ></app-language-filter>
                <app-tag-chip-search-filter
                    [inGlobalFilter]="true"
                    [languageChange]="langFilter.filterChange"
                    fxFlex="50%"
                    fxFlex.lt-md="100%"
                    fxLayoutAlign="start"
                    tagsFilterRef
                ></app-tag-chip-search-filter>
            </app-table-filter>
        </div>
        <button
            *ngIf="user.isAdmin()"
            add-btn
            mat-icon-button
            type="button"
            color="primary"
            fxFlex="0 0 auto"
            [routerLink]="['/admin/content/posts/create']"
            matTooltip="Add Post"
            i18n-matTooltip="@@addPost"
            class="add-post"
        >
            <mat-icon class="kd-icon-36">add_circle</mat-icon>
        </button>

        <button
            mat-icon-button
            *ngIf="expandAll"
            type="button"
            color="primary"
            fxFlex="0 0 auto"
            matTooltip="Show extra"
            i18n-matTooltip="@@showExtra"
            (click)="$event.stopPropagation(); fullPostBody = !fullPostBody"
        >
            <mat-icon [class.expanded-all-body]="fullPostBody">{{ fullPostBody ? 'notes' : 'reorder' }}</mat-icon>
        </button>
        <button
            mat-icon-button
            type="button"
            color="primary"
            fxFlex="0 0 auto"
            matTooltip="Open All"
            i18n-matTooltip="@@openAll"
            (click)="expandAll = !expandAll"
        >
            <mat-icon class="expanding" [class.expanded-all]="expandAll">first_page</mat-icon>
        </button>
    </div>

    <mat-card
        fxLayout="column"
        *ngIf="posts | async; let loadedPosts; else: loading"
        class="kd-green-top posts-details-wrapper"
        fxFlex="0 1 100%"
    >
        <div *ngFor="let post of loadedPosts">
            <app-post-item
                [user]="user"
                [fullPostBody]="fullPostBody"
                [expandAll]="expandAll"
                [expandedItem]="openedId === post.id"
                [post]="post"
                (onFilterByTag)="filterByTag($event)"
            ></app-post-item>
        </div>
    </mat-card>
</ng-container>
<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
