import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'kdDateFormat',
})
export class KdDateFormatPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) protected localeId: string) {
        localeId = localeId === 'en' ? 'en-US' : localeId;
    }
    transform(value: moment.MomentInput, format?: string): string {
        return value ? (value = moment(value).local().locale(this.localeId).format(format)) : '';
    }
}
