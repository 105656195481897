import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DeleteDialogComponent } from '@klickdata/core/application';
import { AlertDialogComponent } from '@klickdata/core/application/src/alert-dialog/alert-dialog.component';
import { InfoDialogComponent } from '@klickdata/core/application/src/info-dialog/info-dialog.component';
import { AuthService } from '@klickdata/core/auth';
import {
    MessageDeletedComponent,
    MessageDeletedUndoComponent,
    MessageDuplicatedComponent,
    MessageSavedComponent,
    MessageService,
} from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceData } from '@klickdata/core/resource';
import { ResourceTableActions } from '@klickdata/core/resource/src/types.enum';
import {
    Filter,
    SelectFilterOption,
    StatusFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, first, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ResourcesAssignSheetComponent } from '../../resource-assignment/resource-assign-sheet/resource-assign-sheet.component';
import { ResourceCollectSheetComponent } from '../../resource-assignment/resource-collect-sheet/resource-collect-sheet.component';
import { ShareService } from '../../share/share.service';
import { ResourceListingService } from '../resource-listing.service';

@Component({
    selector: 'app-generic-items-listing',
    templateUrl: './generic-items-listing.component.html',
    styleUrls: ['./generic-items-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceListingService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class GenericItemsListingComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @Input() type_scope_id: number;
    @Input() table_columns: string[];
    @Input() activeSortingCol: string;
    @Input() activeServiceContext: string;
    @Input() selectingStyleClass: string;
    @Input() allowAddingItem: boolean;
    @Input() allowExpandedFilterPanel = true;
    @Input() allowActionHeader: boolean;
    @Input() allowActionRow: boolean;
    @Input() tableToolsAction: ResourceTableActions[];
    @Input() tableFilterItems: Filter<string | number>[];
    @Output() onBrowse: EventEmitter<any> = new EventEmitter<any>();
    @Output() onAdd: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onOpenAnalytics: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onRowClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSendEmail: EventEmitter<any> = new EventEmitter<any>();
    public dataSource = new TableSource<Resource>();
    public columns: string[];
    public isMobile: boolean;
    public authUser: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    selection = new SelectionModel<Resource>(true, []);
    public showActionHeader = false;
    public showColumnsFilter = false;
    public showStatusFilter = false;
    public showLangFilter = false;
    public allResourcesArePublished = false;
    public allResourcesArePubliced = false;
    public allResourcesAreNotPublished = false;
    public allResourcesAreNotPubliced = false;
    public shareLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    expandedRow: Resource | null;
    AppScope = AppScope;
    ResourceTableActions = ResourceTableActions;
    public resourceSelectOptions: SelectFilterOption[];
    public resourceStatusOptions: StatusFilterOption[];

    constructor(
        protected resourceService: ResourceListingService,
        protected userService: ResourceListingService,
        protected questionService: ResourceListingService,
        protected mobile: MobileService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        protected bottomSheet: MatBottomSheet,
        protected messageService: MessageService,
        public shareService: ShareService
    ) {}
    public ngOnInit() {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((mobile) => {
                this.isMobile = mobile;
                this.cdRef.markForCheck();
            });
        this.getDataSourceService();
        this.columns = this.table_columns;
        if (this.isMobile) {
            // this.allowActionRow ? this.columns.push('tools', 'sticky') : this.columns.push('sticky');
            this.columns.splice(1, 0, 'summary');
        }
    }

    private getDataSourceService() {
        switch (this.activeServiceContext) {
            case 'user':
                this.dataSource.service = this.userService;
                break;
            case 'question':
                this.dataSource.service = this.questionService;
                break;
            default:
                this.dataSource.service = this.resourceService;
        }
    }
    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => {
                this.authUser = user;
                this.updatefilters(false);
            });
        this.selection.changed.pipe(takeUntil(this.destroy)).subscribe((values) => {
            if (this.allowActionHeader) {
                this.showActionHeader = values.source.selected.length > 0;
            }
            let publishedNu = 0;
            let publicNu = 0;
            let notPublishedNu = 0;
            let notPublicNu = 0;
            values.source.selected.forEach((selected) => {
                selected.last_publish ? publishedNu++ : notPublishedNu++;
                selected.public ? publicNu++ : notPublicNu++;
            });
            this.allResourcesArePublished = publishedNu === values.source.selected.length;
            this.allResourcesAreNotPublished = notPublishedNu === values.source.selected.length;
            this.allResourcesArePubliced = publicNu === values.source.selected.length;
            this.allResourcesAreNotPubliced = notPublicNu === values.source.selected.length;
            this.cdRef.markForCheck();
        });
    }

    private updatefilters(withEmmiter: boolean) {
        const appliedFilters = [];
        if (this.tableFilterItems) {
            this.tableFilterItems.forEach((filterItem) => {
                appliedFilters.push(
                    new Filter(filterItem.property, filterItem.items),
                    new Filter(
                        'eager',
                        this.type_scope_id === AppScope.SCORM ? ['tags', 'categories', 'scorm'] : ['tags', 'categories']
                    )
                );
            });
        }
        withEmmiter
            ? this.filter.createOrUpdateFilter(appliedFilters)
            : this.filter.createOrUpdateWithoutEmitFilter(appliedFilters);
    }

    ngOnChanges() {
        if (this.tableFilterItems && this.filter && this.authUser) {
            this.updatefilters(true);
        }
    }

    public functionNotImpl() {
        this.dialog.open(InfoDialogComponent, {
            disableClose: false,
            data: {
                contentBody: $localize`:@@thisFunctionWillSoonWorkGreat! :This function will soon work great! `,
                neutralBtn: $localize`:@@ok:Ok`,
            },
        });
    }
    public remove(resource?: Resource): void {
        if (resource) {
            resource.loading = true;
        } else {
            this.functionNotImpl();
            return;
        }

        this.dialog
            .open(DeleteDialogComponent)
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                tap((result) => {
                    if (!result) {
                        if (resource) {
                            resource.loading = false;
                        }
                        this.cdRef.markForCheck();
                    }
                }),
                filter((result) => !!result),
                switchMap(() =>
                    resource
                        ? this.resourceService.destroy(resource)
                        : this.resourceService.destroyMultipleResources(this.selection.selected.map((res) => res.id))
                )
            )
            .subscribe((success) => {
                if (success) {
                    this.messageService.openMessage(MessageDeletedComponent);
                    resource
                        ? this.dataSource.removeById(resource.id)
                        : this.selection.selected.map((res) => this.dataSource.removeById(res.id));
                    if (!resource) {
                        return;
                    }
                    const ref = this.messageService.openMessage(MessageDeletedUndoComponent);
                    ref.instance.snackBarRef = ref;
                    // Check if user want to undo.
                    ref.onAction()
                        .pipe(takeUntil(this.destroy))
                        .subscribe(() => {
                            this.restoreResource(resource);
                        });
                }
            });
    }
    public restoreResource(resource: Resource) {
        this.resourceService
            .restore(resource.id)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.messageService.openMessage(MessageSavedComponent);
                this.dataSource.refresh();
            });
    }

    public duplicate(resource: Resource): void {
        this.resourceService
            .duplicate(resource)
            .pipe(takeUntil(this.destroy))
            .subscribe((newResource) => {
                if (newResource) {
                    this.messageService.openMessage(MessageDuplicatedComponent);
                    this.onBrowse.emit(newResource);
                }
            });
    }

    public publish(publish: boolean, resource?: Resource) {
        if (resource) {
            resource.loading = true;
        } else {
            this.functionNotImpl();
            return;
        }
        this.dialog
            .open(AlertDialogComponent, {
                disableClose: true,
                data: {
                    icon: publish ? 'publish_outline' : 'unpublished',
                    iconClass: 'material-icons-outlined',
                    title: publish ? $localize`:@@publish:Publish` : $localize`:@@unpublish:Unpublish`,
                    contentBody: $localize`:@@sureUpdatePublication:Are you sure you want to change the status of publication?`,
                    positiveBtn: publish ? $localize`:@@publish:Publish` : $localize`:@@unpublish:Unpublish`,
                    negativeBtn: $localize`:@@cancel:Cancel`,
                },
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                tap((result) => {
                    if (!result) {
                        resource.loading = false;
                        this.cdRef.markForCheck();
                    }
                }),
                filter((result) => !!result),
                switchMap(() => this.resourceService.update(this.getPublishData(resource, publish)))
            )
            .subscribe(
                (updatedResource) => {
                    resource.loading = false;
                    resource.published = updatedResource.published;
                    resource.last_publish = updatedResource.last_publish;
                    this.cdRef.markForCheck();
                    this.messageService.openMessage(MessageSavedComponent);
                },
                (err) => {
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                    }
                }
            );
    }

    private getPublishData(resource: Resource, publish: boolean): ResourceData {
        const publishDate = moment().format('YYYY-MM-DD HH:mm:ss');
        return {
            id: resource.id,
            published: resource.published || publishDate,
            last_publish: publish ? publishDate : null,
        };
    }

    public public(makePublic: boolean, resource?: Resource) {
        if (resource) {
            resource.loading = true;
        } else {
            this.functionNotImpl();
            return;
        }

        this.dialog
            .open(AlertDialogComponent, {
                disableClose: true,
                data: {
                    icon: makePublic ? 'public' : 'public_off',
                    title: makePublic ? $localize`:@@makePublic:Make public` : $localize`:@@makeUnpublic:Make unpublic`,
                    contentBody: $localize`Are you sure you want change the public status?`,
                    positiveBtn: makePublic
                        ? $localize`:@@makePublic:Make public`
                        : $localize`:@@makeUnpublic:Make unpublic`,
                    negativeBtn: $localize`:@@cancel:Cancel`,
                },
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                tap((result) => {
                    if (!result) {
                        resource.loading = false;
                        this.cdRef.markForCheck();
                    }
                }),
                filter((result) => !!result),
                switchMap(() =>
                    this.resourceService.update({
                        id: resource.id,
                        public: makePublic,
                    })
                )
            )
            .subscribe(
                (updatedResource) => {
                    if (resource) {
                        resource.loading = false;
                        resource.public = updatedResource.public;
                    }
                    this.cdRef.markForCheck();
                    this.messageService.openMessage(MessageSavedComponent);
                },
                (err) => {
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                    }
                }
            );
    }

    public showAssignments(resource?: Resource): void {
        if (resource) {
            this.bottomSheet.open(ResourceCollectSheetComponent, {
                data: {
                    id: resource.id,
                    title: resource.title,
                },
                panelClass: 'sheet-wrapper',
            });
        } else {
        }
    }

    public sendCoursePlan(resource: Resource): void {
        this.dialog.open(ResourcesAssignSheetComponent, {
            data: {
                width: !this.isMobile ? '70%' : '100%',
                id: resource.id,
                context: $localize`Assign`,
                contextIcon: 'assignment_ind',
                title: resource.title,
                referenceIdKey: 'resource_id',
                referenceFilter: 'assignedResource',
            },
            disableClose: true,
            panelClass: 'sheet-wrapper',
        });
    }

    public browse(resource: Resource): void {
        this.onBrowse.emit(resource);
    }

    public add(): void {
        this.onAdd.emit(true);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Resource): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    public isChecked() {}
    public isIndeterminate() {}
    public selectToggle(res: Resource) {}
    public modelChecked(res: Resource) {}
    // public playResource(resource: Resource) {
    //     this.onPlay.emit(resource);
    // }
    public browseResource(resource: Resource) {
        this.onBrowse.emit(resource);
    }
    public handleRowClick(res: Resource) {
        if (!this.isMobile) {
            this.onRowClicked.emit(res);
        }
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
