<div class="user-select-wrapper" fxLayout="column" fxFlex="0 0 100%">
    <app-table-filter [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Academy Roles"
            i18n-label="@@academyRoles"
            [property]="GlobalFilterProperty.USER_ROLE"
            [options]="userRoleOptions"
            userRolesRef
        >
        </app-select-filter>

        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Status"
            i18n-label="@@status"
            [property]="{ name: GlobalFilterProperty.STATUS, type: GlobalFilterPropertyType.STATUS_USER }"
            [options]="userStatusOptions"
            userStatusRef
        >
        </app-select-filter>
        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Filter by selection"
            i18n-label="@@filterBySelection"
            [property]="GlobalFilterProperty.SELECTED"
            [options]="itemSelectedOptions"
            selectedItemsRef
        >
        </app-select-filter>
    </app-table-filter>

    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="selection-Info">
        <div fxLayoutAlign="start center" fxLayoutGap="5px" class="main">
            <span i18n="@@numberOfUsers" class="label">Number of users:</span>
            <span class="total">{{ total }}</span>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="5px" class="selected" *ngIf="selected">
            <span class="selectedNu" i18n="@@selected">Selected:</span>
            {{ selected }}
        </div>
    </div>
    <mat-progress-bar
        class="progress progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        [matSortActive]="defaultSorting"
        (matSortChange)="onSortDataChages($event)"
        matSort
        [class.mobileView]="isMobile"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="masterToggle()"
                    [checked]="isChecked()"
                    [indeterminate]="isIndeterminate()"
                >
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectToggle(user)"
                    [checked]="modelChecked(user)"
                    [disabled]="user.disabled"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name"> Name </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span i18n="@@name" class="mobile-label"> Name </span>
                {{ user.name }}</mat-cell
            >
        </ng-container>

        <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@username"> User name </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span i18n="@@username" class="mobile-label"> User name </span>
                {{ user.username }}</mat-cell
            >
        </ng-container>

        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@email"> Email </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span i18n="@@email" class="mobile-label"> Email </span>
                {{ user.email }}</mat-cell
            >
        </ng-container>

        <ng-container matColumnDef="started_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@started"> Started </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span class="mobile-label" i18n="@@started"> Started </span>
                <span *ngIf="user.occasion?.started_at">
                    {{ user.occasion?.started_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="end_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n> End date </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span class="mobile-label" i18n> End date </span>
                <span *ngIf="user.occasion?.end_date">
                    {{ user.occasion?.end_date | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="occasionStatus">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@status"> Status </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@status">Status</span>
                <div
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                    class="pr-0-m"
                    [ngStyle]="{ color: occasionStatus.color }"
                    *ngIf="getUserStatus(user.occasion?.occasionStatus) as occasionStatus"
                >
                    <mat-icon>
                        {{ occasionStatus.icon }}
                    </mat-icon>
                    &nbsp;
                    {{ occasionStatus.status }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="completed">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@completed"> Completed </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span class="mobile-label" i18n="@@completed"> Completed </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span class="mobile-label" i18n="@@tools"> Tools </span>
                <ng-container *ngIf="user.occasion && user.occasion.occasionStatus != 'deleted' && user.disabled">
                    <app-users-select-tools
                        (onUpdateDate)="user.occasion = $event"
                        (onCancelAssign)="
                            user.disabled = false;
                            user.occasion.occasionStatus = 'deleted';
                            user.occasion.end_date = null
                        "
                        (onReminder)="expandedElement = expandedElement === user ? null : user"
                        [user]="user"
                        [maxDate]="accessForm.value.end_date"
                        [minDate]="accessForm.value.start_date"
                    ></app-users-select-tools>
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let user" [attr.colspan]="columns.length">
                <div
                    fxLayout="column"
                    class="example-element-detail"
                    [@detailExpand]="user == expandedElement ? 'expanded' : 'collapsed'"
                >
                    <app-users-select-reminder
                        *ngIf="user == expandedElement"
                        [user]="user"
                        (onReminderSent)="expandedElement = null"
                        [body]="accessForm.value.body"
                    ></app-users-select-reminder>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let user; columns: columns"
            [class.disable-row]="user.disabled"
            (click)="!user.disabled && selectToggle(user)"
        ></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>

    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>
