import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { ResourceOpportunityService } from '@klickdata/core/opportunity';
import { User } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'app-users-select-reminder',
    templateUrl: './users-select-reminder.component.html',
    styleUrls: ['./users-select-reminder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersSelectReminderComponent extends OnDestroyHandler implements OnInit {
    public form: FormGroup;
    @Input() user: User;
    @Input() body: string;
    @Output() onReminderSent: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        protected oppoService: ResourceOpportunityService,
        protected messageService: MessageService,
        protected fb: FormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            subject: [''],
            body: [''],
        });
        this.form.get('body').patchValue(this.body);
    }
    sendReminder() {
        this.oppoService
            .assignOccasionMsg(this.user.occasion.id, this.form.value)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.messageService.openMessage(MessageSavedComponent, $localize`A reminder has been sent.`);
                this.onReminderSent.emit();
            });
    }
}
