import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@klickdata/material';
import { FormErrorComponent } from './error/form-error.component';
import { HasOneDirective } from './form/relations/has-one.directive';
import { PikadayComponent } from './pikaday/pikaday.component';
import { SavingButtonComponent } from './saving-button/saving-button.component';
import { AlertSuccessComponent } from './success/alert-success.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { KdLoaderModule } from '@klickdata/kd-loader';

@NgModule({
    imports: [CommonModule, MaterialModule, KdLoaderModule],
    declarations: [
        FormErrorComponent,
        AlertSuccessComponent,
        SavingButtonComponent,
        HasOneDirective,
        PikadayComponent,
        DatePickerComponent,
    ],
    exports: [
        FormErrorComponent,
        AlertSuccessComponent,
        SavingButtonComponent,
        HasOneDirective,
        PikadayComponent,
        DatePickerComponent,
    ],
})
export class FormModule {}
