<mat-card class="flex-item nk3-login" *ngIf="customerLanding$ | async; let customerLanding">
    <mat-card-content fxLayout="column" fxFlexAlign="center center">
        <app-form-error header="Login failed" i18n-header [messages]="(error | async)?.error.messages"></app-form-error>
        <app-logotype
            [image]="customerLanding.logotype_url"
            [padding]="customerLanding.logotype_padding"
            [alt]="customerLanding.title"
        ></app-logotype>
        <h3
            [style.color]="customerLanding.styles?.landing?.color_code"
            [style.fontSize]="customerLanding.styles?.landing?.font_size + 'px'"
            class="subdomain"
        >
            <ng-container *ngIf="customerLanding.welcome_message">{{ customerLanding.welcome_message }}</ng-container>
            <ng-container *ngIf="!customerLanding.welcome_message">
                <span i18n="@@welcomeTo">Welcome to</span>
                {{ customerLanding.title }}
            </ng-container>
        </h3>

        <!-- <h3 *ngIf="academies" class="subdomain">
            <span i18n="@@chooseAcademy">Choose academy</span>
        </h3> -->
        <!-- <app-social-login *ngIf="!academies"></app-social-login> -->
        <form
            [formGroup]="loginForm"
            (ngSubmit)="loading || onSubmit()"
            fxLayout="row wrap"
            fxFlexAlign="center center"
            fxLayoutGap="0.5em"
        >
            <mat-form-field *ngIf="!academies" fxFlex="1 1 100%" appearance="fill">
                <mat-label i18n="@@username(email)">Username (email)</mat-label>
                <input matInput formControlName="username" name="username" required />
                <mat-error i18n>Username is required!</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="!academies" fxFlex="1 1 100%" appearance="fill">
                <mat-label i18n="@@password">Password</mat-label>
                <input matInput type="password" formControlName="password" name="password" required />
                <mat-error i18n>Password is required!</mat-error>
            </mat-form-field>

            <!-- <mat-form-field *ngIf="!academies" fxFlex="1 0 100%">
                <mat-placeholder>
                    <i class="material-icons app-input-icon">account_box</i>
                    <ng-container i18n>User name</ng-container>
                </mat-placeholder>
                <input matInput formControlName="username" name="username" required />
                <mat-error i18n>Username is required!</mat-error>
            </mat-form-field> -->

            <!-- <mat-form-field *ngIf="!academies" fxFlex="1 0 100%">
                <mat-placeholder>
                    <i class="material-icons app-input-icon">lock</i>
                    <ng-container i18n>Password</ng-container>
                </mat-placeholder>
                <input matInput type="password" formControlName="password" name="password" required />
                <mat-error i18n>Password is required!</mat-error>
            </mat-form-field> -->

            <app-academy-select
                class="academy-select"
                *ngIf="academies"
                [academies]="academies"
                [control]="loginForm.get('short_name') | cast"
            ></app-academy-select>

            <div
                *ngIf="!academies"
                fxLayout="column"
                fxLayoutAlign="center center"
                fxFlex="1 1 100%"
                class="signup-button"
            >
                <div fxFlex="1 1 100%" fxLayoutAlign="center center">
                    <button *ngIf="!loading" mat-raised-button color="primary" type="submit" i18n="@@login">
                        Login
                    </button>
                    <app-saving-button *ngIf="loading"></app-saving-button>
                </div>
                <div fxLayoutAlign="center center" fxFlex="1 1 100%" fxLayout="column">
                    <p (click)="forgotPassword()" class="blue pointer">
                        <strong i18n="@@forgotYourPassword?"> Forgot your password ? </strong>
                    </p>
                </div>
            </div>
            <div *ngIf="academies" fxLayoutAlign="center center" fxFlex="1 1 100%" class="signup-button mb-1">
                <div fxFlex="1 1 100%">
                    <button
                        *ngIf="!loading && !(isLoading$ | async)"
                        mat-raised-button
                        color="primary"
                        type="submit"
                        i18n="@@next"
                    >
                        Next
                    </button>
                    <app-saving-button *ngIf="loading || (isLoading$ | async)"></app-saving-button>
                </div>
                <!-- <div fxFlex="1 1 48%" fxLayout="column">
                    <p (click)="onCancel()" class="forgot">
                        <strong i18n="@@cancel">
                            Cancel
                        </strong>
                    </p>
                </div> -->
            </div>

            <!-- <div fxFlex="0 0 100%" fxLayout="row" class="mt-1" fxLayoutAlign="start center">
                <button *ngIf="!loading" mat-raised-button color="primary" type="submit" i18n>
                    Login
                </button>
                <app-saving-button *ngIf="loading"></app-saving-button>
                <span class="space"></span>
                <button *ngIf="academies" mat-button (click)="onCancel()" i18n>
                    Cancel
                </button>
            </div> -->
        </form>
        <!-- <p class="white mt-0">
            <strong>
                <span i18n>Don't have an account?</span>
            </strong>
        </p> -->
        <p *ngIf="!dialogSignInMode && customerLanding.enable_anonymous_register" class="pointer red mt-0">
            <strong>
                <span class="pointer mt-0 red" i18n [routerLink]="['/guest/signup']"> Create account </span>
            </strong>
        </p>
    </mat-card-content>
    <!-- <mat-card-actions class="flex">
        <div class="flex-space"></div>
        <button mat-button (click)="forgotPassword()" i18n="@@forgot-password-button">
            Forgot password?
        </button>
        <div class="space"></div>
        <button mat-button (click)="trial()" i18n="@@try-klickdata-button">
            Try KlickData
        </button>
    </mat-card-actions> -->
</mat-card>
<app-footer *ngIf="!dialogSignInMode" [smallFooter]="true" class="login-footer" fxFlex="1 1 auto"></app-footer>
