import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Language, LanguageService } from '@klickdata/core/localization';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-preferred-langs-select',
    templateUrl: './preferred-langs-select.component.html',
    styleUrls: ['./preferred-langs-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PreferredLangsSelectComponent),
            multi: true,
        },
    ],
})
export class PreferredLangsSelectComponent implements OnInit, ControlValueAccessor, OnDestroy {
    @Input() label: string;
    @Input() placeholder: string;
    @Input() floatLabel: 'always' | 'never' = 'never';
    @Output() openedChange: EventEmitter<boolean> = new EventEmitter();
    public attachedLanguages: Observable<Language[]>;
    public prefLangsCtrl = new FormControl([]);
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(protected languageService: LanguageService, protected cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.attachedLanguages = this.languageService.attachedLanguages;
        this.prefLangsCtrl.valueChanges.subscribe(() => this.notify());
    }

    compareFn(l1: Language, l2: Language): boolean {
        return l1?.id === l2?.id;
    }

    onLangRemoved(lang: Language) {
        this.prefLangsCtrl.setValue((<Language[]>this.prefLangsCtrl.value).filter((lng) => lng.id !== lang.id));
    }

    public propagateChange = (_: any) => {};

    writeValue(ids: any): void {
        if (!ids?.length || ids === null) {
            this.prefLangsCtrl.reset();
            return;
        }
        this.languageService
            .getLanguagesByKeys(ids)
            .pipe(takeUntil(this.destroy))
            .subscribe((langs) => this.prefLangsCtrl.setValue(langs, { emitEvent: false }));
    }

    private notify() {
        const value = this.prefLangsCtrl.value;
        this.propagateChange(Array.isArray(value) ? value.map((lang: Language) => lang.id) : value);
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {}
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
