import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { HttpErrorService, RequestBuilderService } from '@klickdata/core/http';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { Observable, map } from 'rxjs';
import { File, FileData } from './file.model';

@Injectable({
    providedIn: 'root',
})
export class FileService {
    protected filesUrl: string;

    constructor(
        protected auth: AuthService,
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected error: HttpErrorService,
        protected mediaService: MediaService
    ) {
        this.filesUrl = `${config.config.apiUrl}toolbox/files`;
    }

    public create(file: FileData, eager?: string | string[]): Observable<File> {
        const req = this.builder.post<FileData>(this.filesUrl, file);
        req.putEager(eager);
        return req.request().pipe(
            map((res) => {
                return this.createFile(res.data);
            })
        );
    }
    public listenToNewFile(): Observable<File> {
        return this.auth.listenUserChannel('NewFile').pipe(map((data: FileData) => this.createFile(data)));
    }
    public update(data: FileData, eager?: string | string[]): Observable<File> {
        const req = this.builder.put<FileData>(`${this.filesUrl}/${data.id}`, data);
        req.putEager(eager);
        return req.request().pipe(
            map((res) => {
                return this.createFile(res.data);
            })
        );
    }

    public destroy(file: File): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.filesUrl}/${file.id}`)
            .request()
            .pipe(map((res) => res.data));
    }
    protected createFile(data: FileData): File {
        const file = data;
        file.priority = data.priority?.toLowerCase() ?? 'a3';
        file.files$ = this.mediaService.getMediaByIds(file.medias?.file);
        return new File(file);
    }
}
