import { Validators, AbstractControl } from '@angular/forms';
type ValidationErrors = {
    [key: string]: any;
};

export class instructorFormValidator extends Validators {
    public static instNameExitIfImgUpload(control: AbstractControl): ValidationErrors | null {
        const name = control.get('name').value;
        const images = control.get('images').value;
        return images?.length > 0 && !name ? ({ instructorNameShouldExist: true }) : null;
    }
}
