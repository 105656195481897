import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, AbstractControl } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { FormHelper } from '@klickdata/core/form';
import { Alternative, QuestionTypes } from '@klickdata/core/question';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ResourceBuilderService } from '../../../resource-builder.service';
import { AlternativeService } from '../../../shared/alternative.service';

@Component({
    selector: 'app-resource-builder-alternative-form',
    templateUrl: './alternative-form.component.html',
    styleUrls: ['./alternative-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlternativeFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public formArray: FormArray;

    @Input()
    public alternatives: Alternative[];

    @Input()
    public questionType: string;
    public QuestionTypes = QuestionTypes;

    @Input()
    public usePoints = true;

    @Input()
    public editable: boolean;

    private destroy: Subject<boolean> = new Subject<boolean>();
    public published: boolean;

    constructor(
        protected resourceBuilderService: ResourceBuilderService,
        protected fb: FormBuilder,
        protected changeRef: ChangeDetectorRef,
        protected mobileService: MobileService,
        protected alternativeService: AlternativeService
    ) {}

    public ngOnInit() {
        if (this.alternatives) {
            this.updateAlternatives(this.alternatives);
            this.formArray.parent.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
                this.changeRef.detectChanges();
            });
        }
        this.resourceBuilderService
            .isLoading()
            .pipe(takeUntil(this.destroy))
            .subscribe((isLoading) => {
                if (!isLoading) {
                    // Refresh question alts views after saving completed, clear removed alts view.
                    this.changeRef.markForCheck();
                }
            });
    }

    public ngOnChanges() {
        if (this.alternatives) {
            this.updateAlternatives(this.alternatives);
            this.published = this.resourceBuilderService.published;
        }
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public addAlternative() {
        const index = this.alternatives?.length || this.formArray.controls.length;
        const firstAlt = this.alternatives?.length
            ? this.alternatives[0].alternative
            : this.formArray.controls?.length
            ? this.formArray.controls[0].value.alternative
            : 0;
        const alternative = !isNaN(+firstAlt) ? `${index + 1}` : String.fromCharCode(65 + index);
        this.formArray.push(
            this.alternativeService.createFormGroup({ alternative: alternative, points: 0 }, index, this.usePoints)
        );
    }

    protected updateAlternatives(alternatives: Alternative[]) {
        if (!this.formArray.controls.length) {
            this.alternativeService.createFormGroups(alternatives, this.usePoints).forEach((group) => {
                if (!this.editable) {
                    group.controls.alternative.disable();
                    group.controls.title.disable();
                }
                this.formArray.push(group);
            });
            FormHelper.resetForm(this.formArray);
        }
    }

    public updateCorrect(event: any) {
        if (event instanceof MatRadioChange) {
            for (const alternative of <FormGroup[]>this.formArray.controls) {
                if (alternative.value.index === event.value) {
                    alternative.patchValue({ correct: true, points: 1 });
                } else {
                    alternative.patchValue({ correct: false, points: 0 });
                }
            }
            FormHelper.markForm(this.formArray);
        }
    }

    public getCorrect(): number {
        for (const alternative of <FormGroup[]>this.formArray.controls) {
            if (alternative.value.correct === true) {
                return alternative.value.index;
            }
        }
    }
    public showExplaination(alt: Alternative, index: number, control: AbstractControl) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_TEXT_AREA,
            data: {
                icon: 'lightbulb_circle',
                title: $localize`Alternative explanation`,
                description: $localize`Explanation for learner who select this answer`,
                btnLabel: $localize`Done`,
                type: index,
                value: alt?.explanation,
            },
        });

        this.mobileService
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === index),
                takeUntil(this.destroy),
                map((data) => data.value)
            )
            .subscribe((explain) => {
                if (alt) {
                    alt.explanation = explain;
                }
                control.get('explanation')?.patchValue(explain);
                FormHelper.markForm(<FormGroup>control);
                this.changeRef.markForCheck();
            });
    }
}
