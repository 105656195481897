import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output, Inject, LOCALE_ID } from '@angular/core';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
  selector: 'app-menu-side-users-status',
  templateUrl: './menu-side-users-status.component.html',
  styleUrls: ['./menu-side-users-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSideUsersStatusComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public expandAll = true;
    constructor(@Inject(LOCALE_ID) public localeId: string) {}

    ngOnInit(): void {}

}
