import { Injectable } from '@angular/core';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { Media, MediaData } from '@klickdata/core/media/src/media.model';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { AppScope } from '@klickdata/core/resource';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class K3MediaListingService extends MediaService implements TableHttpService<Media> {
    public fetchData(source: TableSource<Media>): Observable<ResponseData<Media[]>> {
        const req = this.builder.get<MediaData[]>(this.mediaURL);

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<MediaData[]>) => {
                return {
                    data: res.data.map((media) => new Media(media)),
                    notify: res.notify,
                    more: res.more,
                    paginator: res.paginator,
                };
            })
        );
    }
}
