<div
    (click)="goToDetails()"
    (mouseenter)="hovered = true"
    (mouseleave)="hovered = false"
    class="item-wrapper"
    fxFlex="0 0 100%"
    fxLayoutAlign="flex-start center"
    fxLayoutAlign="flex-start center"
    fxLayout="column"
>
    <app-language-selector
        [language_id]="resource.language_id"
        class="item-lang"
        fxFlex="0 0 auto"
    ></app-language-selector>
    <div class="flex res-img-wrap">
        <app-image-view fxFlex="0 1 100%" [smallSpinner]="true" [media_id]="resource.media$"> </app-image-view>
    </div>

    <div
        *ngIf="showOccasion"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="pr-0-m res-occ"
        [ngStyle]="{ color: (resource | resourceStatus : true)?.color }"
    >
        <mat-icon>
            {{ (resource | resourceStatus : true)?.icon }}
        </mat-icon>
        <span>
            {{ (resource | resourceStatus : true)?.status }}
        </span>
    </div>

    <ng-container *ngIf="hasThumbnailHoverEffect">
        <div
            [fxShow]="hovered"
            fxLayout="column"
            fxLayoutGap="10px"
            class="hover-info"
            fxLayoutAlign="center space-around"
            [class.hover-remaining-time]="hovered"
        >
            <div fxLayout="column" fxLayoutAlign="center center">
                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
                    <span i18n="@@deadline:">Deadline:</span>
                    <strong>{{ resource.opportunity_end_date | kdDateFormat : 'MMM d, y' }}</strong>
                </div>
                <div
                    class="remain-days-wrapper"
                    *ngIf="remainingTime >= 0"
                    fxLayout="row"
                    fxLayoutGap="5px"
                    fxLayoutAlign="center center"
                >
                    <strong>{{ remainingTime }}</strong>
                    <span i18n="@@daysLeft">days left</span>
                </div>
                <div class="warn" *ngIf="remainingTime < 0" fxLayout="row" fxLayoutAlign="center center">
                    <span>{{ ResourceTypes.label(this.resource.type_id, { plural: false, capitalize: true }) }}</span
                    >&nbsp;
                    <span i18n="@@isExpired">is expired</span>
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column" fxLayoutAlign="center start">
                    <ng-container *ngIf="resource.assigned_by && resource.assigned_by.length > 0">
                        <span i18n="@@assignedBy:">Assigned by:</span>
                        <div class="recommend-wrapper">
                            <strong>
                                {{ resource.assigned_by[0].name }}
                            </strong>
                        </div>
                    </ng-container>
                </div>
                <button
                    add-btn
                    mat-button
                    type="button"
                    color="primary"
                    mat-raised-button
                    fxFlex="0 0 auto"
                    (click)="goToDetails()"
                >
                    <ng-container *ngIf="!resource.opportunity_id" i18n="@@start">Start</ng-container>
                    <ng-container *ngIf="resource.opportunity_id" i18n="@@continue">Continue</ng-container>
                </button>
            </div>
        </div>
    </ng-container>

    <div class="flex" fxFlex.gt-sm="0 1 100%" fxLayout="column" fxLayoutAlign="flex-start center">
        <span class="title">{{ resource.title }}</span>
    </div>
</div>
