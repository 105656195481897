<div *ngIf="user$ | async; let user" class="quotes-listing" fxLayout="column" fxFlex="0 0 100%">
    <app-table-toolbar class="toolbar" color="accent">
        <mat-accordion fxFlex="0 0 100%" displayMode="flat">
            <mat-expansion-panel [expanded]="true" #expansionPanel>
                <mat-expansion-panel-header> </mat-expansion-panel-header>
                <app-table-filter [cacheScope]="AppScope.USERS" [inGlobalFilter]="true" color="accent" #filter>
                    <app-table-search
                        [inGlobalFilter]="true"
                        toggleBtnLabel="Quote"
                        i18n-toggleBtnLabel
                        class="align-space"
                        searchFilterRef
                    ></app-table-search>

                    <app-user-chip-search-filter
                        [inGlobalFilter]="true"
                        [filterSpecs]="filterSpecs"
                        [roleValue]="ResourceStaffRoles.QUOTEE"
                        searchLabel="Quote"
                        chosingLabel="Choose quotee"
                        searchPlaceholder="Filter by quote name.."
                        i18n-chosingLabel
                        i18n-searchPlaceholder
                        i18n-searchLabel
                        quoteeFilterRef
                    ></app-user-chip-search-filter>
                    <app-language-filter
                        [inGlobalFilter]="true"
                        #langFilter
                        fxFlex="0 0 auto"
                        fxLayout="row"
                        langFilterRef
                    ></app-language-filter>
                    <app-tag-chip-search-filter
                        [inGlobalFilter]="true"
                        [languageChange]="langFilter.filterChange"
                        fxFlex="50%"
                        fxFlex.lt-md="100%"
                        fxLayoutAlign="start"
                        tagsFilterRef
                    ></app-tag-chip-search-filter>
                    <app-select-filter
                        [inGlobalFilter]="true"
                        class="filter-space mr-2"
                        label="Status"
                        i18n-label
                        [property]="{
                            name: GlobalFilterProperty.STATUS,
                            type: GlobalFilterPropertyType.STATUS_RESOURCE
                        }"
                        [options]="generalModelStatusSelectOptions"
                        resourceStateFilterRef
                    ></app-select-filter>

                    <!-- <app-table-date-filter [inGlobalFilter]="true" color="accent" customTimeSpentFilterRef></app-table-date-filter> -->
                </app-table-filter>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-progress-bar
            class="progress progress-table-loader"
            *ngIf="(dataSource.status | async) === 'loading'"
            mode="indeterminate"
        >
        </mat-progress-bar>
    </app-table-toolbar>

    <div class="table-wrapper">
        <mat-table #table [dataSource]="dataSource" matSort [class.mobileView]="isMobile">
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()"
                    >
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let quote">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(quote) : null"
                        [checked]="selection.isSelected(quote)"
                        [aria-label]="checkboxLabel(quote)"
                    >
                    </mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="quotee">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@quotee"> Quotee </mat-header-cell>
                <mat-cell (click)="$event.stopPropagation(); onQuoteeClick(quote.quotee?.name)" *matCellDef="let quote">
                    <span *ngIf="isMobile" class="mobile-label" i18n> Quotee </span>
                    {{ quote.quotee?.name }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="img">
                <mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
                <mat-cell *matCellDef="let quote">
                    <!-- <app-image-view
                        [ngStyle]="{ minWidth: '60px' }"
                        [isCircularImg]="true"
                        [media_id]="quote.media$"
                        paddingBottom="50px"
                    ></app-image-view> -->
                    <app-image-view
                        [smallSpinner]="true"
                        [media_id]="quote.media"
                        [proportional]="true"
                    ></app-image-view>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="language">
                <mat-header-cell *matHeaderCellDef>
                    <div fxLayoutAlign="start center">
                        <span i18n="@@lang">Language</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let quote">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@lang">Lang</span>
                    <ng-container *ngIf="quote.language_id">
                        <app-language-selector
                            [language_id]="quote.language_id"
                            class="icon-resource-builder"
                            fxFlex="0 0 auto"
                        ></app-language-selector>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="quote">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Quote </mat-header-cell>
                <mat-cell #quoteCellRef *matCellDef="let quote">
                    <ng-container *ngIf="!isMobile">{{ quote.quote }}</ng-container>
                    <div *ngIf="isMobile" class="title-comp" fxLayout="start start">
                        <div class="mobile-img-wrapper">
                            <app-image-view [media_id]="quote.media" [proportional]="true"></app-image-view>
                        </div>
                        <span class="mobile-tite">{{ quote.quote }}</span>
                        <button
                            *ngIf="isMobile"
                            class="expand-btn"
                            mat-icon-button
                            (click)="expandedRow = expandedRow === quote ? null : quote"
                        >
                            <mat-icon>
                                {{ expandedRow === quote ? 'expand_less' : 'expand_more' }}
                            </mat-icon>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tags">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Tags </mat-header-cell>
                <mat-cell *matCellDef="let quote">
                    <div class="tags-wrapper">
                        <div (click)="$event.stopPropagation(); onTagClick(tag)" *ngFor="let tag of quote.tags">
                            <span>{{ tag.name }}</span>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Status </mat-header-cell>
                <mat-cell *matCellDef="let quote">
                    <span *ngIf="isMobile" class="mobile-label" i18n> Status </span>
                    <div
                        [matTooltip]="quote.status?.label"
                        i18n-matTooltip
                        [ngStyle]="{ color: quote.status?.color }"
                        fxLayout="start center"
                        fxLayoutGap="5px"
                    >
                        <mat-icon>
                            {{ quote.status?.icon }}
                        </mat-icon>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="author">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n> Added by </mat-header-cell>
                <mat-cell *matCellDef="let quote">
                    <span *ngIf="isMobile" class="mobile-label" i18n> Added by </span>
                    <ng-container *ngIf="quote.author$ | async; let loadedAuthor">
                        {{ $any(loadedAuthor).name }}
                    </ng-container>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="created_at">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdAt"> Creation date </mat-header-cell>
                <mat-cell *matCellDef="let quote">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@createdAt"> Created </span>
                    {{ quote.created_at | kdDateFormat : 'YYYY-MM-DD' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tools">
                <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
                <mat-cell *matCellDef="let quote">
                    <button
                        class="tools-btn edit-user-btn"
                        [disabled]="!user.canEdit(quote)"
                        mat-icon-button
                        (click)="$event.stopPropagation(); user.canEdit(quote) && editQuote(quote.id)"
                        matTooltip="Edit"
                        i18n-matTooltip="@@edit"
                    >
                        <mat-icon class="material-icons-outlined">edit</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row
                (click)="$event.stopPropagation(); !isMobile && user.canEdit(quote) && editQuote(quote.id)"
                *matRowDef="let quote; columns: columns"
                [class.res-expanded-row]="expandedRow === quote"
            ></mat-row>
        </mat-table>
    </div>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #rowHover>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #shareLoadTemp>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
