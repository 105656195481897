import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from '@klickdata/core/auth';
import { Resource, AppScope, ResourceTypeService } from '@klickdata/core/resource';
import { Filter, TableFilterComponent, TableSource } from '@klickdata/core/table';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ResourceListService } from '../resource-list.service';
@Component({
    selector: 'app-resource-library',
    templateUrl: './resource-library.component.html',
    styleUrls: ['./resource-library.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceListService],
})
export class ResourceLibraryComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() set selection(selection: number[]) {
        this.selectionSub.next(selection);
    }
    @Input() type_scope_id: number;
    @Input() context: 'author' | 'academy' | 'kol' = 'academy';
    public dataSource = new TableSource<Resource>();
    public columns: string[];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    @Output() select: EventEmitter<Resource> = new EventEmitter<Resource>();
    private destroy: Subject<boolean> = new Subject<boolean>();
    protected selectionSub: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    AppScope = AppScope;

    constructor(
        protected resourceTableService: ResourceListService,
        protected auth: AuthService,
        protected typeService: ResourceTypeService,
        protected cdRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.columns =
            this.type_scope_id === AppScope.TEST || this.type_scope_id === AppScope.SURVEY
                ? this.context === 'author'
                    ? ['select', 'title', 'question_number', 'created_at', 'published', 'language']
                    : ['select', 'title', 'question_number', 'author_name', 'published', 'language']
                : this.context === 'author'
                ? ['select', 'title', 'created_at', 'published', 'language']
                : ['select', 'title', 'author_name', 'published', 'language'];
    }

    public ngAfterViewInit(): void {
        this.dataSource.service = this.resourceTableService;
        this.dataSource.filter = this.filter;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        const filters = [new Filter('scope', [this.type_scope_id]), new Filter('published', ['1'])];
        if (this.type_scope_id == AppScope.MATERIAL) {
            filters.push(new Filter('eager', ['tags']));
        }
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => {
                filters.push(
                    this.context === 'author'
                        ? new Filter('author', [user.id])
                        : this.context === 'academy'
                        ? new Filter('customer', [user.customer_id])
                        : new Filter('availableForCustomer', [user.customer_id])
                );
                this.filter?.createOrUpdateWithoutEmitFilter(filters); // Get all languages remove language filter.
            });

        // Handle selections
        combineLatest([this.dataSource.latestData, this.selectionSub])
            .pipe(takeUntil(this.destroy))
            .subscribe(([resources, selection]) => {
                resources.data.forEach((resource) => (resource.selected = selection.indexOf(resource.id) !== -1));
                this.cdRef.detectChanges();
            });
    }

    public onSelect(resource: Resource) {
        resource.selected = true;
        this.cdRef.markForCheck();
        this.select.emit(resource);
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
