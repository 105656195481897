<div fxFlex="0 0 100%" fxLayout="row wrap" class="assignments-select">
    <app-sticky-header
        fxFlex="grow"
        [title]="data.title"
        [context]="data.context"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss()"
        [savingStatus]="saving | async"
    ></app-sticky-header>
    <div class="user-manage-cp-access" fxFlex="grow" ngClass.xs="xs">
        <app-title-translation
            type="title"
            class="title-trans-section"
            placeholder="Insert section title"
            i18n-placeholder
            [formControl]="collectionForm.get('title_translation') | cast"
        ></app-title-translation>
        <div [ngSwitch]="data.scope">
            <app-collections-children-select
                *ngSwitchCase="AppScope.SECTIONS"
                [customer]="data.customer"
                [accessForm]="collectionForm"
                [referenceFilter]="data.referenceFilter"
                [defaultSorting]="data.defaultSorting"
                [public]="data.public"
            ></app-collections-children-select>
        </div>
    </div>
</div>
