import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { QuestionTypes } from '@klickdata/core/question';
import { ResourceService } from '@klickdata/core/resource';
import { ResourceItemDirective } from '../resource-item.directive';

@Component({
    selector: 'app-resource-builder-test',
    templateUrl: './test.component.html',
    styleUrls: ['./test.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestComponent extends ResourceItemDirective implements OnDestroy {
    public questionTypes = [
        QuestionTypes.CHECKBOX,
        QuestionTypes.RADIO,
        QuestionTypes.WOK,
        QuestionTypes.INPUT,
        QuestionTypes.AI,
    ];
    constructor(
        protected resourceService: ResourceService,
        protected fb: FormBuilder,
        protected changeRef: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected authService: AuthService,
        protected mobileService: MobileService
    ) {
        super(resourceService, changeRef, dialog, authService, mobileService);
    }
}
