<div fxLayout="column" fxFlex="1 0 100%">
    <div
        fxFlex="1 0 100%"
        fxFlex.lt-md="0 1 100%"
        fxLayout="row"
        fxLayout.lt-md="column"
        class="form-wrapper"
        *ngFor="let translationForm of translationsFormArray.controls; let index = index"
    >
        <div
            [formGroup]="translationForm | cast"
            [ngClass]="{ 'options-wrapper': index !== 0 }"
            fxLayoutAlign="center center"
            fxFlex="1 1 100%"
            fxLayout="row"
        >
            <mat-form-field *ngIf="type === 'title'" fxFlex="0 1 100%" floatLabel="never">
                <input matInput formControlName="value" [placeholder]="placeholder" i18n />
                <mat-error>Title is required</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="type === 'description'" fxFlex="0 1 100%" floatLabel="never">
                <textarea matInput [placeholder]="placeholder" minRows="3" formControlName="value"></textarea>
            </mat-form-field>

            <app-language-selector
                fxLayoutAlign="center center"
                formControlName="language_id"
                fxFlex="0 0 auto"
                [propagate]="false"
            ></app-language-selector>
            <button
                (click)="addLang()"
                *ngIf="index === 0 && !noMoreLanguages"
                type="button"
                mat-button
                mat-icon-button
                class="icon-lang"
            >
                <mat-icon>translate</mat-icon>
            </button>

            <button
                *ngIf="index !== 0"
                (click)="$event.stopPropagation(); removeTranslation(translationForm)"
                mat-icon-button
                color="warn"
                matTooltip="Remove translation"
                i18n-matTooltip
            >
                <mat-icon class="md-24">delete_outline</mat-icon>
            </button>
        </div>
    </div>
</div>
