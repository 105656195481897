import { Cast, CastType, IDataModel, Model } from '@klickdata/core/application';
import { Resource } from '@klickdata/core/resource';

export interface MsgCaseData extends IDataModel {
    id?: number;
    message_id?: number;
    resource_ids?: number[];
    start_date?: string;
    end_date?: string;
    author_id?: number;
    status?: CaseStatus[];
    user_status?: CaseStatus;
    resources?: Resource[];
    created_at?: string;
    updated_at?: string;
    last_status?: CaseStatus;
}
export class MsgCase extends Model<MsgCaseData> {
    id: number;
    message_id: number;
    resource_ids: number[];
    start_date: string;
    end_date: string;
    author_id: number;
    status: CaseStatus[];
    last_status: CaseStatus;
    user_status: CaseStatus;
    resources: Resource[];
    users: CaseUser[];
    @Cast(CastType.MOMENT)
    created_at: string;
    @Cast(CastType.MOMENT)
    updated_at: string;
}

export interface CaseUser {
    fname: string;
    lname: string;
    id: number;
    status: CaseStatus[];
}

export interface CaseStatus {
    date?: any;
    type?: string;
    comment?: string;
}
export enum CaseStatusTypes {
    OPEN = 'inbox',
    CLOSE = 'close',
    REOPEN = 'reopen',
    APPROVED = 'approved',
    NOT_APPROVED = 'not_approved',
    DONE = 'done',
}
export namespace CaseStatusTypes {
    export function getAllStatus() {
        return [
            {
                title: $localize`open`,
                icon: 'lock_open',
                value: 'open',
            },
            {
                title: $localize`close`,
                icon: 'lock',
                value: 'close',
            },
            {
                title: $localize`reopen`,
                icon: 'event_repeat',
                value: 'reopen',
            },
            {
                title: $localize`approved`,
                icon: 'event_available',
                value: 'approved',
            },
            {
                title: $localize`disapproved`,
                icon: 'event_busy',
                value: 'not_approved',
            },
            {
                title: $localize`done`,
                icon: 'verified',
                value: 'done',
            },
        ];
    }
}
