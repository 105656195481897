import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FormHelper } from '@klickdata/core/form';
import { AddFormativeEvaluationComponent } from '../add-formative-evaluation/add-formative-evaluation.component';

@Component({
    selector: 'app-formative-evaluation-bottom-sheet',
    templateUrl: './formative-evaluation-bottom-sheet.component.html',
    styleUrls: ['./formative-evaluation-bottom-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormativeEvaluationBottomSheetComponent implements OnInit {
    @ViewChild('addFormEval') addFormEval: AddFormativeEvaluationComponent;
    constructor(
        public bottomSheetRef: MatBottomSheetRef<FormativeEvaluationBottomSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {}

    ngOnInit(): void {}

    submit() {
        const evaluationFormValues = this.addFormEval.evaluationForm.value;
        if (!!evaluationFormValues?.criteria?.length) {
            evaluationFormValues?.criteria.forEach((cri) => delete cri.editable);
        }
        this.bottomSheetRef.dismiss({ evaluation: evaluationFormValues });
    }
}
