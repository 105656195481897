<div fxLayout="column" class="main-wrapper-core">
    <div class="title-label" fxLayoutGap="20px" fxLayout="row" fxLayoutAlign="space-between center">
        <span fxFlex="grow" i18n>Event date</span>
        <mat-checkbox [formControl]="notSetYet" color="primary" i18n>Not yet set </mat-checkbox>
        <button
            class="add-date-btn"
            coloor="primary"
            mat-icon-button
            (click)="addDate()"
            matTooltip="Add another event date"
            i18n-matTooltip
        >
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
        <ng-container>
            <div class="w-100" fxLayout="column" formArrayName="occasion_dates" fxFlex="grow">
                <ng-container *ngIf="eventOccasions.controls && !!eventOccasions.controls.length">
                    <div
                        *ngFor="let occasion of eventOccasions.controls; let i = index"
                        class="w-100"
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxFlex="grow"
                        fxLayoutGap="10px"
                    >
                        <mat-expansion-panel
                            [formGroup]="occasion | cast"
                            fxFlex="grow"
                            #dateExpansionPanel
                            class="user-manage-course-expantion"
                        >
                            <mat-expansion-panel-header
                                [ngStyle]="{ background: dateExpansionPanel.expanded ? '#fff' : '#d9e8e3' }"
                            >
                                <mat-panel-title>
                                    <div
                                        *ngIf="!dateExpansionPanel.expanded"
                                        fxLayout="row"
                                        fxLayout.lt-md="column"
                                        fxLayoutAlign="start center"
                                        fxLayoutGap="20px"
                                        fxLayoutGap.lt-md="0px"
                                    >
                                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                                            <mat-icon fxHide.lt-md="true">date_range</mat-icon>
                                            <span i18n>Start at:</span>
                                            <span
                                                >{{ occasion.value.start_date | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
                                                {{ resourceForm.value.time_zone }}</span
                                            >
                                        </div>
                                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                                            <span i18n>Ends at:</span>
                                            <span
                                                >{{ occasion.value.end_date | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
                                                {{ resourceForm.value.time_zone }}</span
                                            >
                                        </div>
                                    </div>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div fxFlex="100%" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="20px">
                                <div
                                    class="date-row-wrapper w-100"
                                    fxFlex="100%"
                                    fxLayout="row"
                                    fxLayout.lt-md="column"
                                    fxLayoutAlign="space-between center"
                                    fxLayoutAlign.lt-md="center start"
                                    fxLayoutGap=".5em"
                                >
                                    <div
                                        class="date-calndar-pick"
                                        fxLayout="column"
                                        fxFlex="0 0 45%"
                                        fxFlex="0 0 45%"
                                        fxLayoutAlign="center center"
                                    >
                                        <app-date-time-displayed-calendar-picker
                                            formControlName="start_date"
                                            title="Starts at"
                                            i18n-title
                                            icon="hourglass_top"
                                        ></app-date-time-displayed-calendar-picker>
                                    </div>

                                    <div
                                        class="date-calndar-pick"
                                        fxLayout="column"
                                        fxFlex="0 0 45%"
                                        fxFlex="0 0 45%"
                                        fxLayoutAlign="center center"
                                    >
                                        <app-date-time-displayed-calendar-picker
                                            formControlName="end_date"
                                            title="Ends at"
                                            i18n-title
                                            icon="hourglass_bottom"
                                        ></app-date-time-displayed-calendar-picker>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <button
                            class="add-date-btn remove"
                            coloor="primary"
                            mat-icon-button
                            (click)="removeDate(i)"
                            matTooltip="Remove event date"
                            i18n-matTooltip
                        >
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </ng-container>
            </div>
            <div class="w-100 timezone-wrapper" fxLayoutAlign="start center" fxLayoutGap="10px">
                <span i18n>Select time zone</span>
                <mat-select
                    appearance="fill"
                    class="select-timzone"
                    placeholder="Place choose timezone"
                    i18n-placeholder
                    formControlName="time_zone"
                >
                    <mat-select-trigger fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="start center">
                        <span>{{ resourceForm.value.time_zone }}</span>
                    </mat-select-trigger>
                    <mat-option *ngFor="let timezone of timezones" [value]="timezone.value">
                        {{ timezone.title }}
                    </mat-option>
                </mat-select>
            </div>
        </ng-container>
    </div>
</div>
<div fxLayout="column" class="main-wrapper-core mt-1">
    <div class="title-label" fxLayout="row" fxLayoutAlign="space-between center">
        <span i18n>Event Specs</span>
        <mat-radio-group
            [formControl]="mainEventTypeId"
            fxLayoutAlign="start center"
            fxLayoutGap="70px"
            fxLayoutGap.lt-sm="20px"
        >
            <mat-radio-button [value]="1">
                <span i18n>Onsite</span>
            </mat-radio-button>
            <mat-radio-button [value]="2">
                <span i18n>Online</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="w-100 mt-1" fxLayoutAlign="start center" fxLayoutGap="1em" fxLayout="row">
        <span i18n>Type:</span>
        <mat-radio-group
            formControlName="type_id"
            fxLayoutAlign="space-between center"
            class="event-types"
            fxLayoutGap="0"
            fxLayoutGap.lt-sm="20px"
        >
            <mat-radio-button *ngFor="let eventType of filteredEventTypes()" [value]="eventType.value">
                <span>{{ eventType.label }}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <mat-expansion-panel *ngIf="mainEventTypeId.value === 1" #locationExpansionPanel class="location-panel">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>location_on</mat-icon>
                <div i18n="@@location">Location</div>
                <mat-icon class="icon-explain" matTooltip="Set event location" i18n-matTooltip>help_outline</mat-icon>
            </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end center">
                <span
                    matTooltip="Indicator of field change"
                    i18n-matTooltip
                    class="cell-indicator"
                    [ngClass]="{ green: resourceForm.get('address').dirty }"
                ></span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <form [formGroup]="$any(resourceForm.get('address'))" fxLayout="column" fxLayoutGap="10px" class="mt-1">
            <mat-form-field fxFlex="0 1 100%">
                <input matInput placeholder="Enter address" i18n-placeholder formControlName="street" />
            </mat-form-field>
            <mat-form-field class="w-100">
                <textarea
                    matInput
                    placeholder="Enter extra info needed for the location"
                    i18n-placeholder
                    formControlName="other"
                ></textarea>
            </mat-form-field>

            <app-geo-location-control
                *ngIf="locationExpansionPanel.expanded"
                fxFlex="0 1 100%"
                class="mt-1"
                formGroupName="coordinates"
                (onMarkerClick)="resourceForm.get('address.coordinates').patchValue($event)"
            ></app-geo-location-control>
        </form>
    </mat-expansion-panel>
    <form
        [formGroup]="$any(resourceForm.get('communication.link'))"
        fxLayout="column"
        fxLayoutAlign="start center"
        class="mt-1"
        *ngIf="mainEventTypeId.value === 2"
    >
        <div class="w-100" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-form-field fxFlex="0 1 50%">
                <input
                    matInput
                    placeholder="Pls set online event link"
                    type="url"
                    i18n-placeholder
                    formControlName="value"
                />
                <button
                    matTooltip="Open link in new tab"
                    i18n-matTooltip
                    type="button"
                    class="open-link"
                    mat-icon-button
                    matSuffix
                    (click)="goToLink(resourceForm?.value?.communication?.link?.value)"
                >
                    <mat-icon>open_in_new</mat-icon>
                </button>
            </mat-form-field>
            <ng-container [formGroup]="$any(resourceForm.get('communication.link.metadata'))">
                <mat-form-field fxFlex="0 1 25%">
                    <input matInput placeholder="Enter password" i18n-placeholder formControlName="password" />
                </mat-form-field>
            </ng-container>
        </div>
        <mat-form-field class="w-100">
            <textarea
                matInput
                placeholder="Enter online event extra info"
                i18n-placeholder
                [formControl]="$any(resourceForm.get('communication.link.metadata').get('other'))"
            ></textarea>
        </mat-form-field>
    </form>
</div>
<div fxLayout="column" class="main-wrapper-core mt-1">
    <span i18n class="title-label">Event agenda</span>
    <div class="w-100">
        <app-text-editor
            *ngIf="active"
            fxFlex="1 1 100%"
            title="Event agenda"
            i18n-title
            [showTemplates]="false"
            formControlName="agenda"
            class="agenda-event"
        ></app-text-editor>
    </div>
</div>
<ng-container formGroupName="medias">
    <mat-expansion-panel #exerciseExpansionPanel class="user-manage-course-expantion mt-1">
        <mat-expansion-panel-header [ngStyle]="{ background: exerciseExpansionPanel.expanded ? '#fff' : '#d9e8e3' }">
            <mat-panel-title>
                <mat-icon class="mr-5p">topic</mat-icon>
                <div i18n>Exercise Documents</div>
                <mat-icon
                    class="icon-explain"
                    matTooltip="Upload exercise document that belongs to the event for practice. It can be a documents pre-event, during event or post the event."
                    i18n-matTooltip
                    >help_outline</mat-icon
                >
            </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end center">
                <span
                    matTooltip="Indicator of field change"
                    i18n-matTooltip
                    class="cell-indicator"
                    [ngClass]="{ green: resourceForm.get('medias').get('exercise').dirty }"
                ></span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="alert-media-attach mt-1">
            <app-media-attachment
                #uploader
                type="file"
                dropLabel="Drop documents"
                i18n-dropLabel
                [formControl]="resourceForm.get('medias.exercise') | cast"
                [mediaIds]="resourceForm.value.medias?.exercise"
                [isCreateForm]="true"
                [isIconBtns]="false"
            ></app-media-attachment>
            <div fxLayoutAlign="center center" *ngIf="uploader.saving | async">
                <app-kd-logo-loading size="small"></app-kd-logo-loading>
            </div>
        </div>
    </mat-expansion-panel>
</ng-container>

<ng-template #contentTemplateToBeInParent>
    <app-event-manager-core-secondary-controls [resource]="resource"></app-event-manager-core-secondary-controls>
</ng-template>
