import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@klickdata/material';
import { MobileModule } from '@klickdata/core/mobile';
import { MessageFormErrorComponent } from './message-form-error/message-form-error.component';
import { MessageDeletedComponent } from './message-deleted/message-deleted.component';
import { MessageDeletedUndoComponent } from './message-deleted-undo/message-deleted-undo.component';
import { MessageDuplicatedComponent } from './message-duplicated/message-duplicated.component';
import { MessageSavedComponent } from './message-saved/message-saved.component';
import { MessageSentComponent } from './message-sent/message-sent.component';
import { MessageErrorComponent } from './message-error/message-error.component';

@NgModule({
    imports: [CommonModule, MaterialModule, MobileModule],
    declarations: [
        MessageFormErrorComponent,
        MessageDeletedComponent,
        MessageDeletedUndoComponent,
        MessageDuplicatedComponent,
        MessageSavedComponent,
        MessageSentComponent,
        MessageErrorComponent,
    ],
})
export class MessageModule {}
