import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MediaComponent } from '@klickdata/core/media/src/media.component';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope } from '@klickdata/core/resource';
import { ResourceItem, ResourceItemTypes } from '@klickdata/core/resource-item';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, takeUntil } from 'rxjs/operators';
import { ResourceBuilderService } from '../resource-builder.service';
import { QuestionType, QuestionTypes } from '@klickdata/core/question';

@Component({
    selector: 'app-question-item-form',
    templateUrl: './question-item-form.component.html',
    styleUrls: ['./question-item-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionItemFormComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @ViewChild(MatExpansionPanel) questionExtrasPanel: MatExpansionPanel;
    @ViewChild('title') titleInput: ElementRef;
    @Input()
    public item: ResourceItem;

    @Input()
    public itemGroup: FormGroup;

    @Input()
    public usePoints = true;
    @Input()
    public index: number;
    public editable: boolean;
    public ItemTypes = ResourceItemTypes;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public displayDesc = false;
    public questionLoaded: boolean;
    public selection: number[] = [];
    AppScope = AppScope;
    @ViewChild('app_media') mediaCompRef: MediaComponent;

    constructor(
        protected builderService: ResourceBuilderService,
        protected changeRef: ChangeDetectorRef,
        protected auth: AuthService,
        protected mobileService: MobileService
    ) {}

    ngOnInit() {
        this.itemGroup
            .get('question')
            .valueChanges.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy))
            .subscribe((question) => {
                this.questionLoaded = !!question;
                this.checkQuestionEditMode();
                this.changeRef.markForCheck();
            });
        this.itemGroup
            .get('name')
            .valueChanges.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.destroy))
            .subscribe(() => this.itemGroup.get('question').markAsDirty());

        combineLatest([this.auth.getUser().pipe(first()), this.builderService.getResource()])
            .pipe(
                map(([user, resource]) => (resource ? user.canEdit(resource) : true)),
                takeUntil(this.destroy)
            )
            .subscribe((editable) => {
                this.editable = editable;
            });

        this.builderService
            .getItems()
            .pipe(takeUntil(this.destroy))
            .subscribe((items) => (this.selection = items.map((item) => item.child_resource_id)));
    }

    ngAfterViewInit() {
        this.titleInput?.nativeElement?.focus();
    }

    checkQuestionEditMode() {
        if (!this.editable) {
            this.itemGroup.controls.name.disable();
            const question = <FormGroup>this.itemGroup.get('question');
            question.controls.description.disable({ emitEvent: false });
            question.controls.learning_field.disable({ emitEvent: false });
            question.controls.references.disable({ emitEvent: false });
            question.controls.question_type_value.disable({ emitEvent: false });
            question.controls.other_alternative?.disable({ emitEvent: false });
            question.controls.randomize_alternatives.disable({ emitEvent: false });
        }
    }

    ngOnChanges(): void {}

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public removeImage(): void {
        this.item.media_id = null;
        this.item.media.url = null;
        this.itemGroup.get('question').patchValue({
            img_url: null,
            media_id: null,
        });
        FormHelper.markForm(this.itemGroup);
    }

    public toggleDisplayDesc() {
        this.displayDesc = !this.displayDesc;
    }
    public getQsExtraInfo(question: AbstractControl) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.QUESTION_EXTRA_INFO,
            data: {
                type: this.index,
                value: question.value,
                editable: this.editable,
            },
        });

        this.mobileService
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === this.index),
                takeUntil(this.destroy),
                map((data) => data.info)
            )
            .subscribe((info) => {
                if (info) {
                    question.get('description')?.patchValue(info.description);
                    question.get('learning_field')?.patchValue(info.learning_field);
                    question.get('references')?.patchValue(info.references);
                    FormHelper.markForm(<FormGroup>question);
                    FormHelper.markForm(this.itemGroup);
                    this.changeRef.markForCheck();
                }
            });
    }
}
