import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MobileService } from '@klickdata/core/mobile';
import { QuestionTypes } from '@klickdata/core/question';
import { Resource, ResourceService, ResourceTypes, AppScope } from '@klickdata/core/resource';
import { ResourceItem } from '@klickdata/core/resource-item';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import { ResourceEditDialogComponent } from '@klickdata/shared-components/src/resource-edit-dialog/resource-edit-dialog.component';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ResourceItemDirective } from '../resource-item.directive';

@Component({
    selector: 'app-resource-builder-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyComponent extends ResourceItemDirective implements OnDestroy {
    public questionTypes = [QuestionTypes.CHECKBOX, QuestionTypes.RADIO, QuestionTypes.INPUT, QuestionTypes.TEXTAREA];

    constructor(
        protected resourceService: ResourceService,
        protected changeRef: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected authService: AuthService,
        protected mobileService: MobileService
    ) {
        super(resourceService, changeRef, dialog, authService, mobileService);
    }
}
