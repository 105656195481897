import { Injectable } from '@angular/core';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { Quote, QuoteData, QuoteService } from '@klickdata/core/quote';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class QuoteListService extends QuoteService implements TableHttpService<Quote> {
    public fetchData(source: TableSource<Quote>): Observable<ResponseData<Quote[]>> {
        const req = this.builder.get<QuoteData[]>(this.resourceUrl);
        req.putEager('tags');
        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<QuoteData[]>) => {
                return {
                    data: res.data.map((item) => {
                        return this.createQuote(item);
                    }),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }
}
