import { Component, OnInit, ChangeDetectionStrategy, ViewChildren, AfterViewInit } from '@angular/core';
import { MatTabNav } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { MobileService } from '@klickdata/core/mobile';
import { Utils } from '@klickdata/core/util';
import { WidgetsService } from '@klickdata/core/widget/src/widget.service';
import { GenericTabsOption } from '@klickdata/shared-components/src/generic-mobile-tabs/generic-mobile-tabs.component';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
    selector: 'app-shared-action-log',
    templateUrl: './shared-action-log.component.html',
    styleUrls: ['./shared-action-log.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedActionLogComponent implements OnInit, AfterViewInit {
    public activityOptions: GenericTabsOption[];
    public isMobile: Observable<boolean>;
    public activeOption: GenericTabsOption;
    public authCustomer$: Observable<Customer>;
    public activeIndex: number;
    @ViewChildren('navBar') navBar: MatTabNav;
    constructor(
        protected router: Router,
        protected mobile: MobileService,
        protected auth: AuthService,
        protected widgetsService: WidgetsService
    ) {
        this.activityOptions = [
            {
                label: $localize`Overall`,
                url: './overall',
                value: 'overall',
                index: 2,
            },
            {
                label: $localize`Users`,
                url: './users',
                value: 'users',
                index: 1,
            },
            {
                label: $localize`Resources`,
                url: './resources',
                value: 'resources',
                index: 0,
            },
        ];
    }
    ngOnInit(): void {
        this.isMobile = this.mobile.isMobile().pipe(shareReplay());
        this.authCustomer$ = this.auth.getCustomer();
        this.activeOption = Utils.getSelectedMobileTabsOption(this.router, this.activityOptions);
    }
    ngAfterViewInit(): void {
        this.activeIndex = this.navBar.selectedIndex;
    }
    public resetWidgetsFilter() {
        this.widgetsService.setActiveWidgetsFilters([]);
    }
}
