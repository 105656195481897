import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Config } from './config.model';

export const APP_CONFIG_TOKEN = new InjectionToken('app-config');

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private _config: Config;

    constructor(@Inject(APP_CONFIG_TOKEN) protected env: Config) {
        this._config = env;
    }

    getByKey(key): any {
        return this._config[key];
    }

    get config(): Config {
        return this._config;
    }
}
