import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorResponse } from '@klickdata/core/http';
import { Subject, combineLatest, of } from 'rxjs';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from '../token/auth.service';
import { OccasionAuthService } from './occasion-auth.service';

export interface OccasionLogin {
    state: 'error' | 'authenticated_same' | 'authenticated_other' | 'none_authenticated';
    response?: ErrorResponse<any>;
    occasionId?: number;
    token?: string;
}

@Component({
    selector: 'app-occasion-auth',
    templateUrl: './occasion-auth.component.html',
    styleUrls: ['./occasion-auth.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [OccasionAuthService],
})
export class OccasionAuthComponent implements OnInit, OnDestroy {
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected auth: AuthService,
        protected occasionAuthService: OccasionAuthService,
        protected route: ActivatedRoute,
        protected router: Router
    ) {}

    ngOnInit() {
        combineLatest([
            this.route.params.pipe(map((params) => params.occasionId)),
            this.route.queryParams.pipe(map((params) => params.token)),
            this.auth.check().pipe(first()),
        ])
            .pipe(
                switchMap(([occasionId, token, authenticated]) =>
                    this.occasionAuthService.tokenLogin(occasionId, token).pipe(
                        switchMap((response: ErrorResponse<any>) =>
                            response.error
                                ? of(<OccasionLogin>{ state: 'error', occasionId: occasionId })
                                : !authenticated
                                ? of(<OccasionLogin>{
                                      state: 'none_authenticated',
                                      response: response,
                                      occasionId: occasionId,
                                      token: token,
                                  })
                                : this.auth.sameLogin(response.data.token).pipe(
                                      first(),
                                      map((sameUserLogin) =>
                                          sameUserLogin
                                              ? <OccasionLogin>{ state: 'authenticated_same', occasionId: occasionId }
                                              : <OccasionLogin>{
                                                    state: 'authenticated_other',
                                                    response: response,
                                                    occasionId: occasionId,
                                                }
                                      )
                                  )
                        )
                    )
                ),
                tap((occasionLogin: OccasionLogin) => {
                    switch (occasionLogin.state) {
                        case 'error':
                            this.router.navigate(['guest/login'], {
                                queryParams: {
                                    redirect_uri: this.router
                                        .createUrlTree([`player/occasion`, occasionLogin.occasionId])
                                        .toString(),
                                },
                            });
                            break;
                        case 'none_authenticated':
                        case 'authenticated_other':
                            this.auth.handleLogin(occasionLogin.response);
                            this.router.navigate([`player/occasion`, occasionLogin.occasionId]);
                            break;
                        case 'authenticated_same':
                            this.router.navigate([`player/occasion`, occasionLogin.occasionId]);
                            break;
                        default:
                            this.router.navigate(['/unauthorised']);
                    }
                }),
                takeUntil(this.destroy)
            )
            .subscribe();
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
