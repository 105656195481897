<div class="res-list-wrapper" fxLayout="column" fxFlex="0 0 100%">
    <app-table-toolbar class="toolbar" color="accent">
        <app-table-filter [cacheScope]="AppScope.RESOURCES" color="accent" #filter>
            <mat-toolbar class="m-left-1" color="accent" fxLayout="row">
                <app-table-search class="align-space"></app-table-search>

                <div fxFlex="1 1 100%"></div>
                <button mat-button (click)="filter.clear()" fxFlex="0 0 auto">
                    <mat-icon>close</mat-icon>
                    <ng-container i18n="@@clearFilters">Clear filters</ng-container>
                </button>
                <button
                    mat-icon-button
                    (click)="expansion.toggle()"
                    fxFlex="0 0 auto"
                    matTooltip="Show/hide filters"
                    i18n-matTooltip="@@showHideFilters"
                >
                    <mat-icon *ngIf="expansion">
                        {{ expansion.expanded ? 'expand_less' : 'expand_more' }}
                    </mat-icon>
                </button>

                <app-table-menu [columns]="columns" fxHide="true" #tableMenu></app-table-menu>
            </mat-toolbar>

            <app-table-filter-expansion #expansion>
                <div class="m-left-1" fxLayout="column" fxFlexAlign="space-between">
                    <app-tag-chip-search-filter
                        [languageChange]="langFilter.filterChange"
                        fxFlex="50%"
                        fxLayoutAlign="start"
                    ></app-tag-chip-search-filter>
                    <app-category-chip-search-filter
                        fxFlex="50%"
                        [scope_id]="type_scope_id"
                    ></app-category-chip-search-filter>
                </div>
                <div class="m-left-1 mr-1" fxLayout="row" fxLayout.lt-lg="column" fxFlexAlign="space-between">
                    <app-table-date-filter color="accent"></app-table-date-filter>
                    <div fxFlex="1 1 100%"></div>
                    <app-select-filter
                        *ngIf="master"
                        class="filter-space mr-2"
                        label="Resource State"
                        i18n-label="@@resourceState"
                        [property]="{
                            name: GlobalFilterProperty.STATUS,
                            type: GlobalFilterPropertyType.STATUS_RESOURCE
                        }"
                        [options]="resourceStatusOptions"
                    ></app-select-filter>
                    <app-language-filter
                        [prefLangIds]="customer?.pref_lang_ids"
                        #langFilter
                        fxFlex="0 0 auto"
                        fxLayout="row"
                    ></app-language-filter>
                </div>
            </app-table-filter-expansion>
        </app-table-filter>

        <mat-progress-bar
            class="progress progress-table-loader"
            *ngIf="(dataSource.status | async) === 'loading'"
            mode="indeterminate"
        >
        </mat-progress-bar>
    </app-table-toolbar>

    <mat-table
        #table
        [dataSource]="dataSource"
        [matSortActive]="author ? 'updated_at' : 'published'"
        matSortDirection="desc"
        matSort
        [class.mobileView]="isMobile"
    >
        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <span i18n="@@title" class="mobile-label"> Title </span>
                {{ resource.title }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdAt"> Created at </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@createdAt" class="mobile-label"> Created at </span>
                {{ resource.created_at | kdDateFormat : 'YYYY-MM-DD' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updated_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@updatedAt"> Updated at </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@updatedAt" class="mobile-label"> Updated at </span>
                {{ resource.updated_at | kdDateFormat : 'YYYY-MM-DD' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="author_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdBy"> Created by </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@createdBy" class="mobile-label"> Created by </span>
                <ng-container *ngIf="resource.author$ | async; let loadedAuthor">
                    {{ $any(loadedAuthor).name }}
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="customer_name">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@customer"> Customer </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@customer" class="mobile-label"> Customer </span>
                <ng-container *ngIf="resource.customer | async; let loadedCustomer">
                    {{ $any(loadedCustomer).name }}
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="question_number">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Q#"> Q# </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span class="mobile-label" i18n="@@Q#"> Q# </span>
                {{ resource.number_of_items }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="priority">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@priority"> Priority </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span class="mobile-label" i18n="@@priority"> Priority </span>
                <!-- <mat-form-field (click)="$event.stopPropagation()" floatLabel="never">
                    <input
                        matInput
                        type="number"
                        placeholder="Priority"
                        i18n-placeholder="@@priority"
                        [min]="0"
                        [step]="1"
                        [max]="10000"
                        (change)="updatePriority(resource)"
                        [(ngModel)]="resource.priority"
                    />
                </mat-form-field> -->
                {{ resource.priority }}
                <!-- <sat-popover
                    #popover
                    [hasBackdrop]="true"
                    xAlign="start"
                    yAlign="start"
                    (closed)="
                        $event !== undefined &&
                            $event !== resource.priority &&
                            (resource.priority = $event) &&
                            updatePriority(resource)
                    "
                >
                    <app-inline-edit
                        [title]="resource.title"
                        type="number"
                        placeholder="Priority"
                        i18n-placeholder="@@priority"
                        [value]="resource.priority"
                    >
                    </app-inline-edit>
                </sat-popover> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="published">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@publishedAt"> Published at </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@publishedAt" class="mobile-label"> Published at </span>
                <span *ngIf="resource.published">
                    {{ resource.published | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
                <span *ngIf="!resource.published" i18n="@@notPublishedYet"> Not yet. </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="language">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@language"> Language </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@language" class="mobile-label"> Language </span>
                <ng-container *ngIf="resource.language_id">
                    <app-language-selector
                        [language_id]="resource.language_id"
                        class="icon-resource-builder"
                        fxFlex="0 0 auto"
                    ></app-language-selector>
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
            <mat-cell *matCellDef="let resource" (click)="$event.stopPropagation()" class="tools_restore">
                <span i18n="@@tools" class="mobile-label mobile-label-tools"> Tools </span>
                <ng-container *ngIf="!(resource.loading || resource.deleted_at); else restore_btn">
                    <button
                        class="tools-btn"
                        matTooltip="Assignments"
                        mat-icon-button
                        [class.disableIt]="!authUser.canAssign(resource)"
                        (click)="$event.stopPropagation(); authUser.canAssign(resource) && showAssignments(resource)"
                        i18n-matTooltip
                    >
                        <mat-icon>assignment</mat-icon>
                    </button>

                    <button
                        *ngIf="type_scope_id === AppScope.COURSE"
                        class="tools-btn"
                        matTooltip="Send course plan"
                        mat-icon-button
                        [class.disableIt]="!authUser.canAssign(resource)"
                        (click)="$event.stopPropagation(); authUser.canAssign(resource) && sendCoursePlan(resource)"
                        i18n-matTooltip="@@sendCoursePlan"
                    >
                        <mat-icon
                            fontSet="klickdata"
                            fontIcon="kd-icon-task"
                            class="course-plan-icon kd-icon-24 no-shadow"
                        ></mat-icon>
                    </button>
                    <button
                        class="tools-btn"
                        mat-icon-button
                        matTooltip="Duplicate"
                        (click)="duplicate(resource)"
                        i18n-matTooltip="@@duplicate"
                    >
                        <mat-icon>content_copy</mat-icon>
                    </button>

                    <button
                        class="tools-btn red"
                        *ngIf="!resource.last_publish"
                        mat-icon-button
                        matTooltip="Click to publish"
                        [class.disableIt]="!authUser.canPublish(resource)"
                        (click)="$event.stopPropagation(); authUser.canPublish(resource) && publish(resource, true)"
                        i18n-matTooltip="@@clickToPublish"
                    >
                        <mat-icon class="material-icons-outlined">unpublished</mat-icon>
                    </button>

                    <button
                        class="tools-btn"
                        *ngIf="resource.last_publish"
                        mat-icon-button
                        matTooltip="Click to unpublish"
                        [class.disableIt]="!authUser.canUnPublished(resource)"
                        (click)="
                            $event.stopPropagation(); authUser.canUnPublished(resource) && publish(resource, false)
                        "
                        i18n-matTooltip="@@clickToUnpublish"
                    >
                        <mat-icon class="material-icons-outlined">published_with_changes</mat-icon>
                    </button>

                    <button
                        class="tools-btn red"
                        *ngIf="!resource.public"
                        mat-icon-button
                        matTooltip="Click to make public"
                        [class.disableIt]="!authUser.canPublicize(resource)"
                        (click)="$event.stopPropagation(); authUser.canPublicize(resource) && public(resource, true)"
                        i18n-matTooltip="@@clickToMakePublic"
                    >
                        <mat-icon>public_off</mat-icon>
                    </button>

                    <button
                        class="tools-btn"
                        *ngIf="resource.public"
                        mat-icon-button
                        matTooltip="Click to make unpublic"
                        [class.disableIt]="!authUser.canUnPublicized(resource)"
                        (click)="
                            $event.stopPropagation(); authUser.canUnPublicized(resource) && public(resource, false)
                        "
                        i18n-matTooltip="@@clickToMakeUnPublic"
                    >
                        <mat-icon>public</mat-icon>
                    </button>

                    <button
                        class="tools-btn"
                        [class.disableIt]="!authUser.canEdit(resource)"
                        mat-icon-button
                        (click)="$event.stopPropagation(); authUser.canEdit(resource) && browse(resource)"
                        matTooltip="Edit"
                        i18n-matTooltip="@@edit"
                    >
                        <mat-icon class="material-icons-outlined">edit</mat-icon>
                    </button>

                    <button
                        class="tools-btn"
                        [class.disableIt]="!authUser.canDelete(resource)"
                        mat-icon-button
                        (click)="$event.stopPropagation(); authUser.canDelete(resource) && remove(resource)"
                        matTooltip="Delete"
                        i18n-matTooltip="@@delete"
                    >
                        <mat-icon class="material-icons-outlined">delete</mat-icon>
                    </button>
                </ng-container>
                <ng-template #restore_btn>
                    <button
                        *ngIf="resource.deleted_at; else loadingTemp"
                        mat-icon-button
                        matTooltip="Restore"
                        (click)="$event.stopPropagation(); authUser.canDelete(resource) && restoreResource(resource)"
                        [class.disableIt]="!authUser.canDelete(resource)"
                        i18n-matTooltip="@@restore"
                    >
                        <mat-icon>undo</mat-icon>
                    </button>
                </ng-template>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            [ngClass]="!resource.loading ? 'link' : 'disableIt'"
            (click)="$event.stopPropagation(); !resource.loading && authUser.canEdit(resource) && browse(resource)"
            *matRowDef="let resource; columns: columns"
        ></mat-row>
    </mat-table>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
