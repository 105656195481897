<div class="dialog-wrapper">
    <div matDialogContent fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon color="warn">info</mat-icon>
        <span color="warn">{{ contentBody }}</span>
    </div>
    <div class="flex" fxLayoutAlign="end center" mat-dialog-actions>
        <button mat-button type="button" (click)="dialogRef.close(null)">
            {{ neutralBtn }}
        </button>
    </div>
</div>
