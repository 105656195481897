import { Utils } from '@klickdata/core/util';
import { switchMap, takeUntil } from 'rxjs/operators';
import { UserService } from '@klickdata/core/user';
import { Widget, WidgetData } from '@klickdata/core/widget';
import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output, OnDestroy } from '@angular/core';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AuthService } from '@klickdata/core/auth';
import { Subject } from 'rxjs';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';

@Component({
    selector: 'app-menu-side-dashboard-column-settings',
    templateUrl: './menu-side-dashboard-column-settings.component.html',
    styleUrls: ['./menu-side-dashboard-column-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideDashboardColumnSettingsComponent implements OnDestroy {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public kdColorsSet: string[];
    private destroy = new Subject<boolean>();
    SideNaveActionsTypes = SideNaveActionsTypes;
    constructor(protected userService: UserService, protected auth: AuthService, protected message: MessageService) {
        this.kdColorsSet = Utils.getKDColors();
    }
    drop(event: CdkDragDrop<Widget[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    public onSubmit(widgets: Widget[]) {
        this.onClose.emit();
        return;
        this.auth
            .getUser()
            .pipe(
                switchMap((user) =>
                    this.userService.update(
                        this.navData.data.customerId
                            ? {
                                  id: user.id,
                                  widgets: widgets.map(
                                      (widget, index) =>
                                          <WidgetData>{
                                              id: widget.id,
                                              visible: widget.visible,
                                              order: index,
                                              color: widget.color,
                                          }
                                  ),
                                  customer_id: this.navData.data.customerId,
                              }
                            : {
                                  id: user.id,
                                  widgets: widgets.map(
                                      (widget, index) =>
                                          <WidgetData>{
                                              id: widget.id,
                                              visible: widget.visible,
                                              order: index,
                                              color: widget.color,
                                          }
                                  ),
                              }
                    )
                ),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                this.message.openMessage(MessageSavedComponent);
                this.onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: { widgets: widgets, type: this.navData.data.type },
                });
            });
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
