import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-master-well',
    templateUrl: './master-well.component.html',
    styleUrls: ['./master-well.component.scss'],
})
export class MasterWellComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
