<mat-card *ngIf="resourceBuilder.showWOKGenerator | async" #generatorWrapper fxFlex="0 1 100%" fxLayout="column">
    <div class="wok_full main-top">
        <div fxLayoutAlign="space-between end" fxLayout="row">
            <app-user-setting-input
                type="text"
                fxFlex="grow"
                i18n-placeholder="@@WriteWikipediaArticleNameToChooseMCQFrom"
                placeholder="Write a Wikipedia article name to choose MCQ from"
                [control]="searchControl | cast"
            >
                <label role="input-label" i18n>Please enter a Wiki article or a subject</label>
            </app-user-setting-input>
            <app-language-selector
                tooltip="Select Wikipedia search language"
                i18n-tooltip
                [formControl]="lang"
                fxFlex="0 0 auto"
            ></app-language-selector>
        </div>
        <div class="wok_loading" *ngIf="loading">
            <app-kd-logo-loading size="small"></app-kd-logo-loading>
        </div>
    </div>
    <div class="wok_full">
        <div class="wok_small_item" *ngFor="let tag of data; let i = index" (click)="pickTag(div)" [id]="i" #div>
            {{ tag.tag_name }}
            <b>{{ tag.tqt }}</b>
        </div>
    </div>
    <div class="mt-2 slider" fxLayout="row" fxFlex="grow" fxLayoutAlign="start start" fxLayoutGap="5px">
        <div class="pt-1p" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <span i18n class="grey">Total number of questions in a quiz :</span>
            <span class="qs-nu-value">{{ matslider.value }}</span>
        </div>
        <mat-slider
            class="right"
            thumbLabel
            tickInterval="auto"
            [min]="min_total_number_of_qs_value"
            [max]="max_total_number_of_qs_value"
            [value]="total_number_of_qs_value"
            (input)="total_number_of_qs($event)"
            [disabled]="disable_slider"
            #matslider
        ></mat-slider>
        <span class="total-max-nu qs-nu-value">{{ max_total_number_of_qs_value }}</span>
    </div>
    <div class="wok_full mt-1">
        <div class="wok_small_item light-green-bg" *ngFor="let tag_item of picked_data; let i = index">
            {{ tag_item.tag_name }}
            <button
                class="white"
                mat-icon-button
                matTooltipPosition="above"
                matTooltip="Remove Wikipedia article"
                i18n-matTooltip="@@testCreateWokComponent_removeWikiArticle"
            >
                <mat-icon class="md-24" (click)="remove_tag_from_picked(tag_item)"> cancel </mat-icon>
            </button>
            <br />
            <input
                class="small_input"
                type="number"
                [value]="tag_item.selected_amount"
                min="1"
                [max]="tag_item.tqt"
                (input)="manual_pic_number_of_qs(tag_item, $event)"
            />
            / {{ tag_item.tqt }}
        </div>
    </div>
    <div class="wok_full auto-mh mt-1" fxLayout="row" fxFlex="grow" fxLayoutAlign="space-between center">
        <button *ngIf="loading_questions" mat-raised-button type="button" [disabled]="true" class="right btn-dis" i18n>
            <app-kd-logo-loading class="small-load-wok" size="small"></app-kd-logo-loading>
        </button>
        <button
            *ngIf="!loading_questions"
            mat-raised-button
            type="button"
            class="right light-green-bg white"
            [disabled]="picked_data.length <= 0 || loading_questions"
            (click)="generateQuestions()"
            i18n
        >
            Generate random questions
        </button>
        <button
            mat-icon-button
            class="grey"
            matSuffix
            type="button"
            (click)="resourceBuilder.showWOKGenerator.next(false)"
        >
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</mat-card>
