import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';
import { User, UserService } from '@klickdata/core/user';
import {
    Observable,
    Subject,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    of,
    shareReplay,
    startWith,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';

@Component({
    selector: 'app-signature-change-on-the-fly',
    templateUrl: './signature-change-on-the-fly.component.html',
    styleUrls: ['./signature-change-on-the-fly.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureChangeOnTheFlyComponent implements AfterViewInit, OnDestroy, OnChanges {
    @Input() manager: User;
    @Input() allManagersList: User[] = [];
    @Output() onSelectSignature: EventEmitter<any> = new EventEmitter<any>();
    public managers$: Observable<User[]>;
    public managerCtrl = new FormControl();
    public destroy: Subject<boolean> = new Subject<boolean>();
    public wrongSignature = false;

    constructor(protected userService: UserService, protected cdr: ChangeDetectorRef) {}
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.manager?.currentValue) {
            const user = new User(changes.manager.currentValue);
            this.managerCtrl.patchValue(user, { emitEvent: false });
            this.cdr.markForCheck();
        }
    }
    ngAfterViewInit(): void {
        this.managers$ = this.managerCtrl.valueChanges.pipe(
            startWith(''),
            // filter((term: string) => typeof term === 'string' && term != ''),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term) =>
                term
                    ? this.userService.getLightMasterUsers({
                          query: term,
                          limit: 20,
                          userRole: 'superadmin',
                          role: ResourceStaffRoles.MANAGER,
                      })
                    : of(this.allManagersList)
            ),
            shareReplay(),
            tap((filteredUsers) => (this.wrongSignature = !filteredUsers.length)),
            takeUntil(this.destroy)
        );
    }
    selectedSignature(event: MatAutocompleteSelectedEvent) {
        this.onSelectSignature.emit(event.option.value);
    }
    public displaySign(user: User): string {
        return user ? user.signature : '';
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
