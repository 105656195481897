<app-sticky-header
    class="sticky-header"
    [title]="title"
    (onSubmit)="mail.submitWelcomeMessage()"
    [savingStatus]="mail.loading"
    (onCancel)="onClose()"
></app-sticky-header>

<h1 matDialogTitle i18n></h1>
<div mat-dialog-content>
    <admin-welcome-message #mail (sent)="onMessageSent()" [params]="data" [hideActions]="true"></admin-welcome-message>
</div>
