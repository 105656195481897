import { NonNullAssert } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { PaginatorResponse } from '@klickdata/core/http';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { Filter } from '@klickdata/core/table';
import { SectionDataService } from '@klickdata/shared-components/src/section-item-parent/section-data.service';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardSectionDataService implements SectionDataService<Resource> {
    constructor(protected resourceService: ResourceService) {}
    getData(config: {
        param?: { [key: string]: any };
        language?: number[];
        query?: string;
        page?: number;
        limit?: number;
        sort?: Filter<string>;
        direction?: Filter<string>;
    }): Observable<{ data: Resource[]; total_count: number }> {
        return this.resourceService.getResourcesBySectionId(config);
    }
}
