<mat-list-item class="alternative-controls" [class.disabled]="alternativeForm.disabled" [formGroup]="alternativeForm">
    <div matLine *ngIf="!(isMobile | async)" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
        <mat-checkbox
            fxFlex="0 1 auto"
            color="primary"
            formControlName="correct"
            (change)="updateValue($event)"
            class="check-btn"
        ></mat-checkbox>
        <div class="qs-forms-wrapper">
            <mat-form-field fxFlex="0 0 85px">
                <input
                    matInput
                    formControlName="alternative"
                    type="text"
                    required
                    placeholder="Alternative"
                    i18n-placeholder
                />
            </mat-form-field>
            <mat-form-field fxFlex="0 0 70px" *ngIf="!alternativeForm.get('points').disabled">
                <input matInput formControlName="points" type="number" required placeholder="Points" i18n-placeholder />
            </mat-form-field>
            <mat-form-field fxFlex="1 1 auto" floatPlaceholder="auto">
                <!-- <input
                    #title
                    matInput
                    formControlName="title"
                    type="text"
                    required
                    placeholder="Title"
                    i18n-placeholder="@@title"
                /> -->
                <textarea
                    #title
                    matInput
                    type="text"
                    formControlName="title"
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="10"
                    required
                    [ngClass]="{ 'bold-text': alternativeForm.value.correct }"
                    placeholder="Title (max 256 characters)"
                    i18n-placeholder
                    [maxLength]="256"
                >
                </textarea>
                <span [ngClass]="{ orange: title.value.length >= 256 }" *ngIf="title.value" class="char-count">
                    {{ title.value.length }}
                </span>
            </mat-form-field>
        </div>
        <ng-container *ngTemplateOutlet="tools"></ng-container>
    </div>
    <div matLine *ngIf="isMobile | async" fxLayout="row" fxLayoutAlign="start center">
        <div class="qs-forms-wrapper-toggler" fxLayoutGap="10px">
            <mat-checkbox
                fxFlex="0 1 auto"
                color="primary"
                formControlName="correct"
                (change)="updateValue($event)"
            ></mat-checkbox>
            <mat-form-field fxFlex="1 1 200px" floatPlaceholder="auto" floatLabel="never">
                <input
                    matInput
                    formControlName="title"
                    type="text"
                    required
                    placeholder="Title"
                    i18n-placeholder="@@title"
                />
            </mat-form-field>
        </div>
        <div class="qs-forms-wrapper" fxLayoutGap="5px">
            <mat-form-field fxFlex="0 0 80px">
                <input
                    matInput
                    formControlName="alternative"
                    type="text"
                    required
                    placeholder="Alternative"
                    i18n-placeholder
                />
            </mat-form-field>
            <mat-form-field fxFlex="0 0 80px" *ngIf="!alternativeForm.get('points').disabled">
                <input matInput formControlName="points" type="number" required placeholder="Points" i18n-placeholder />
            </mat-form-field>

            <ng-container *ngTemplateOutlet="tools"></ng-container>
        </div>
    </div>
    <mat-divider fxHide="true" fxHide.lt-md="false"></mat-divider>
    <div matLine fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap=".5em">
        <div class="explain-text" *ngIf="alternativeForm.value.explanation">
            <!-- <div i18n>Explanation:</div> -->
            <span>{{ alternativeForm.value.explanation }}</span>
        </div>
        <app-image-view
            *ngIf="alternativeForm.get('media_id').value as mediaId"
            fxFlex="0 1 90%"
            [proportional]="false"
            [media_id]="mediaId"
        >
            <button
                *ngIf="editable"
                class="remove-question-image"
                color="warn"
                fxFlex="0 1 auto"
                mat-icon-button
                matTooltip="Remove image"
                i18n-matTooltip="Remove alternative image"
                (click)="removeImage()"
            >
                <mat-icon class="md-24">delete_outline</mat-icon>
            </button>
        </app-image-view>
    </div>
    <div matLine></div>
    <div matLine></div>
</mat-list-item>

<ng-template #tools>
    <div class="on-hover" fxLayoutAlign="center center">
        <app-media
            fxFlex="0 0 auto"
            [simple]="true"
            [formControl]="alternativeForm.get('media_id') | cast"
            [scope]="AppScope.ALTERNATIVES"
            dropSize="200px"
            dropLabel="Drag and drop alternative image here"
            i18n-dropLabel="Drag and drop for alternative images@@alternativeImageDnD"
        >
        </app-media>
        <button
            fxFlex="0 0 auto"
            mat-icon-button
            (click)="onShowExplaination.emit(true)"
            [ngClass]="{ 'has-explain': alternativeForm.value.explanation }"
            matTooltip="Explanation for learner who select this answer"
            i18n-matTooltip
            class="explain-btn"
        >
            <div class="indicator"></div>
            <mat-icon class="md-24">lightbulb_circle </mat-icon>
        </button>
        <button
            fxFlex="0 1 auto"
            mat-icon-button
            (click)="onToggle()"
            matTooltip="Remove alternative"
            i18n-matTooltip
            [disabled]="alternativeForm.parent.disabled || !editable"
        >
            <mat-icon *ngIf="!alternativeForm.disabled || alternativeForm.parent.disabled" class="md-24">
                delete_outline
            </mat-icon>
            <mat-icon *ngIf="alternativeForm.disabled && !alternativeForm.parent.disabled" class="md-24">
                undo
            </mat-icon>
        </button>
    </div>
</ng-template>
