<div class="user-select-wrapper user-list-access-control" fxLayout="column" fxFlex="0 0 100%">
    <app-table-filter [filtersTogglesInOnBtn]="true" [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Academy Roles"
            i18n-label="@@academyRoles"
            [property]="GlobalFilterProperty.USER_ROLE"
            [options]="userRoleOptions"
            userRolesRef
        >
        </app-select-filter>

        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Status"
            i18n-label="@@status"
            [property]="{ name: GlobalFilterProperty.STATUS, type: GlobalFilterPropertyType.STATUS_USER }"
            [options]="userStatusOptions"
            userStatusRef
        >
        </app-select-filter>
        <app-group-chip-search-filter [inGlobalFilter]="true" groupSearchRef></app-group-chip-search-filter>
    </app-table-filter>
    <mat-progress-bar
        class="progress progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        matSortActive="name"
        matSortDirection="desc"
        matSort
        [class.mobileView]="isMobile"
        [ngClass]="{ sv: localeId == 'sv' }"
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectToggle(user, $event.checked); user.some_resource_perm_checked = false"
                    [checked]="user.all_resource_perm_checked"
                    [indeterminate]="user.some_resource_perm_checked"
                    [disabled]="user.disabled"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name"> Name </mat-header-cell>
            <mat-cell [matTooltip]="user.name" *matCellDef="let user">
                <span i18n="@@name" class="mobile-label"> Name </span>
                <strong *ngIf="user.isAdmin()">{{ user.name }}</strong
                ><span *ngIf="!user.isAdmin()">{{ user.name }}</span></mat-cell
            >
        </ng-container>

        <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@email"> Email </mat-header-cell>
            <mat-cell [matTooltip]="user.email" *matCellDef="let user">
                <span i18n="@@email" class="mobile-label"> Email </span>
                {{ user.email }}</mat-cell
            >
        </ng-container>
        <ng-container matColumnDef="permissions">
            <mat-header-cell *matHeaderCellDef>
                <div class="perm" fxLayout="row" fxLayoutAlign="space-between center">
                    <div
                        (click)="onTogglePerm(perm.value)"
                        class="{{ (perm.value | resourcePermissionPipe).class }}"
                        *ngFor="let perm of resourcePermissions"
                    >
                        <span [matTooltip]="perm.tooltip">{{ perm.label }}</span>
                    </div>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <span class="mobile-label perm">
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                        <div
                            class="{{ (perm.value | resourcePermissionPipe).class }}"
                            *ngFor="let perm of resourcePermissions"
                        >
                            <span [matTooltip]="perm.tooltip">{{ perm.label }}</span>
                        </div>
                    </div>
                </span>
                <app-resource-permissions-handler
                    #resourcePermHandler
                    *ngIf="userPermissionsArray"
                    [permissionsArray]="userPermissionsArray"
                    [user]="user"
                    [permissions]="user.resource_permissions"
                    [resourcePermissions]="resourcePermissions"
                ></app-resource-permissions-handler>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let user; columns: columns" [class.disable-row]="user.disabled"></mat-row>
    </mat-table>

    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>
