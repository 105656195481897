<div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
    <span i18n>{{ customFieldForm.value.name }}</span>
    <mat-form-field
        appearance="outline"
        floatLabel="never"
        class="w-100"
        fxFlex="grow"
        fxFlex="1 1 100%"
        fxFlex.lt-md="1 1 100%"
    >
        <input
            matInput
            [formControl]="customFieldForm.get('value') | cast"
            [placeholder]="customFieldForm.value.placeholder"
        />
    </mat-form-field>
</div>
