<mat-card class="mt-2" fxFlex="0 0 100%">
    <mat-card-content>
        <div
            fxFlex="1 1 100%"
            fxLayoutAlign="center"
            fxLayout="column wrap"
            fxLayoutAlign="center"
            class="mt-1 mb-1 home-test-create"
        >
            <form #formDirective="ngForm" fxLayout="row wrap" [formGroup]="testForm">
                <div fxFlex="0 0 100%">
                    <mat-form-field fxFlex="0 1 100%">
                        <input matInput placeholder="Test name" i18n-placeholder formControlName="title" required />
                        <mat-error i18n>Title field is required!</mat-error>
                    </mat-form-field>
                    <div fxLayoutAlign="end end" fxFlex="0 0 40px" class="coursePlan-layout">
                        <app-language-selector
                            formControlName="language_id"
                            class="icon-resource-builder"
                            fxFlex="0 0 auto"
                        ></app-language-selector>
                    </div>
                </div>

                <!--
                    Grade system KL,EB agreed about:
                    1. Test should has it's own default grade.
                    2. When test included into course (as final test) = test default grade suggested for course creator to use it or choose another.
                -->
                <app-admin-grade-system-control
                    fxFlex="0 1 100%"
                    [resource]="resource"
                    [languageId]="testForm.get('language_id').value"
                    [gradeControl]="testForm.get('grade_system_id') | cast"
                >
                </app-admin-grade-system-control>
                <app-tag-chip-select
                    fxFlex="0 1 100%"
                    [resource_id]="resource?.id"
                    [language_id]="testForm?.get('language_id').value"
                    formControlName="tag_ids"
                ></app-tag-chip-select>
                <app-category-chip-select
                    fxFlex="0 1 100%"
                    [resource_id]="resource?.id"
                    [language_id]="testForm?.get('language_id').value"
                    formControlName="category_ids"
                    [type_id]="ResourceTypes.TEST"
                ></app-category-chip-select>
                <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                    <mat-expansion-panel #expansionPanelDesc>
                        <mat-expansion-panel-header>
                            <div *ngIf="!expansionPanelDesc.expanded" i18n="@@Description">Description</div>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <app-text-editor
                                fxFlex="1 1 95%"
                                title="Description"
                                i18n-title
                                [showTemplates]="false"
                                formControlName="description"
                            ></app-text-editor>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                    <mat-expansion-panel #expansionPanelPrio>
                        <mat-expansion-panel-header>
                            <div *ngIf="!expansionPanelPrio.expanded" i18n="@@priority">Priority</div>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <mat-form-field fxFlex="1 1 95%">
                                <input
                                    matInput
                                    fxLayoutAlign="end center"
                                    type="number"
                                    placeholder="Please set priority"
                                    i18n-placeholder="@@plsSetPriority"
                                    formControlName="priority"
                                />
                            </mat-form-field>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                    <mat-expansion-panel #expansionPanelImg>
                        <mat-expansion-panel-header>
                            <div *ngIf="!expansionPanelImg.expanded" i18n="@@coverImg">Cover image</div>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <div fxLayoutAlign="center center" class="upload-img-test">
                                <app-media
                                    #app_media
                                    *ngIf="!testForm.get('media_id')?.value"
                                    fxFlex="1 1 40%"
                                    fxFlex.lt-md="1 1 100%"
                                    formControlName="media_id"
                                    [scope]="AppScope.TEST"
                                    dropLabel="Drop course image"
                                    i18n-dropLabel="@@dropCourseImage"
                                ></app-media>
                                <app-image-view
                                    (click)="
                                        showImgModal(
                                            testForm.get('media_id')?.value
                                                ? testForm.get('media_id')?.value
                                                : 'assets/images/Default_courses_img.png'
                                        )
                                    "
                                    class="mt-2 mb-2"
                                    *ngIf="testForm.get('media_id')?.value"
                                    fxFlex="0 1 50%"
                                    fxFlex.lt-md="0 1 100%"
                                    [media_id]="
                                        testForm.get('media_id')?.value
                                            ? testForm.get('media_id')?.value
                                            : 'assets/images/Default_courses_img.png'
                                    "
                                    paddingBottom="50%"
                                >
                                    <button
                                        class="remove-image"
                                        mat-icon-button
                                        color="warn"
                                        *ngIf="testForm.get('media_id').value as mediaId"
                                        matTooltip="Remove image"
                                        i18n-matTooltip
                                        (click)="testForm.get('media_id').setValue(null)"
                                    >
                                        <mat-icon class="md-24">delete_outline</mat-icon>
                                    </button>
                                </app-image-view>
                            </div>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>

                <app-resource-builder
                    fxFlex="0 0 100%"
                    #builderForm
                    [resource]="resource"
                    [language_id]="testForm.get('language_id').value"
                    [questionTypes]="questionTypes"
                    [resource_type_id]="ResourceTypes.GeneralTest"
                    [resourceType]="ResourceTypes.TEST"
                    (saved)="$event && savingQuestions && submitWithQuestion()"
                    [active]="bottomToolSheet"
                >
                </app-resource-builder>

                <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="end" fxLayoutGap=".5em">
                    <button
                        *ngIf="!isLoading && (isAdmin$ | async) && !resource?.published"
                        mat-raised-button
                        (click)="createQs()"
                        color="primary"
                        class="addQsBtn"
                        i18n="@@addQuestion"
                    >
                        Add question
                    </button>
                    <div fxLayoutGap=".5em">
                        <button
                            *ngIf="!isLoading && !publishOnly"
                            [disabled]="!testForm.valid"
                            mat-raised-button
                            color="primary"
                            (click)="onSubmit()"
                            i18n="@@save"
                        >
                            Save
                        </button>

                        <button
                            *ngIf="
                                !isLoading && (isAdmin$ | async) && (!resource?.published || !resource?.last_publish)
                            "
                            mat-raised-button
                            [disabled]="!testForm.valid"
                            (click)="!resource?.published ? onSubmit(true) : makePublish(resource, true)"
                            color="primary"
                        >
                            {{ publishLabel }}
                        </button>

                        <button
                            *ngIf="!isLoading && (isAdmin$ | async) && resource?.last_publish"
                            mat-raised-button
                            [disabled]="!testForm.valid"
                            (click)="makePublish(resource, false)"
                            color="primary"
                            i18n="@@unpublish"
                        >
                            Unpublish
                        </button>
                    </div>
                    <app-saving-button *ngIf="isLoading"></app-saving-button>
                </div>
            </form>
        </div>
    </mat-card-content>
</mat-card>
