import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { filter, first, switchMap } from 'rxjs/operators';
import { PostService } from '../post.service';
import { PostPageContentComponent } from './post-page-content/post-page-content.component';
import { Observable } from 'rxjs';
import { Post } from '../post.model';
import { User } from '@klickdata/core/user';
import { AuthService } from '@klickdata/core/auth';

@Component({
    selector: 'app-post-page',
    templateUrl: './post-page.component.html',
    styleUrls: ['./post-page.component.scss'],
})
export class PostPageComponent implements OnInit {
    public post$: Observable<Post>;
    public user$: Observable<User>;
    constructor(protected route: ActivatedRoute, protected postService: PostService, protected auth: AuthService) {}

    public ngOnInit(): void {
        this.user$ = this.auth.getUser().pipe(first());
        this.post$ = this.route.params.pipe(
            filter((param) => !!param.id),
            switchMap((param) => this.postService.getPost(param.id))
        );
    }
}
