import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from '@klickdata/core/application/src/logger/logger.service';
import { ErrorResponse } from '@klickdata/core/http/src/responce/error-responce';
import { throwError, EMPTY } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HttpErrorService {
    constructor(protected logger: LoggerService) {}

    /**
     * Error handling
     */
    public handle(error: HttpErrorResponse) {
        if (error.error instanceof Error) {
            return this.handleJsError(error);
        }

        return this.handleResponseError(error);
    }

    protected handleJsError(error: HttpErrorResponse) {
        const response: ErrorResponse<any> = {
            error: {
                messages: [error.error.message],
                statusCode: 0,
            },
        };

        this.logger.error(response.error.messages[0]);

        return throwError(response);
    }

    protected handleResponseError(error: HttpErrorResponse) {
        const response: ErrorResponse<any> = error.error;

        if (response && response.error) {
            response.error.statusCode = error.status; // assign status of error,

            if (Array.isArray(response.error.messages)) {
                for (const message of response.error.messages) {
                    if (Array.isArray(message)) {
                        this.logger.error(message.join(' '));
                    } else {
                        this.logger.error(message);
                    }
                }
            } else {
                this.logger.error(response.error.messages);
            }
        }

        return throwError(response);
    }
}
