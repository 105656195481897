import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceCategoryService } from '@klickdata/core/resource';
import { Downloads } from '@klickdata/core/resource/src/download.model';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { GenericTabsOption } from '../generic-mobile-tabs/generic-mobile-tabs.component';

@Component({
    selector: 'app-main-catalog',
    templateUrl: './main-catalog.component.html',
    styleUrls: ['./main-catalog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainCatalogComponent implements OnInit {
    public activityOptions: GenericTabsOption[];
    public isMobile: Observable<boolean>;
    public disableDownload: Observable<boolean>;
    constructor(
        protected mobileService: MobileService,
        protected downloadHelper: DownloadHelperService,
        public categoryService: ResourceCategoryService
    ) {
        this.isMobile = this.mobileService.isMobile().pipe(shareReplay());
        this.activityOptions = [
            {
                label: $localize`Courses`,
                url: './course',
                value: 'course',
                index: 2,
            },
            {
                label: $localize`Tests`,
                url: './test',
                value: 'test',
                index: 1,
            },
            {
                label: $localize`Surveys`,
                url: './survey',
                value: 'survey',
                index: 2,
            },
            {
                label: $localize`Materials`,
                url: './material',
                value: 'material',
                index: 3,
            },
            {
                label: $localize`E-Courses`,
                url: './ecourse',
                value: 'ecourse',
                index: 4,
            },
        ];
    }

    ngOnInit(): void {}

    public downloadCatalog(downloads: Downloads) {
        this.downloadHelper.download(downloads);
    }
}
