import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { customFieldsBase } from '../custom-fields-base';

@Component({
    selector: 'app-custom-fields-input-type',
    templateUrl: './custom-fields-input-type.component.html',
    styleUrls: ['./custom-fields-input-type.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsInputTypeComponent extends customFieldsBase {}
