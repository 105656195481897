<div class="alt-data" fxLayout.lt-sm="column">
    <span class="info-correct wrong" *ngIf="question.user_answer === null" i18n="@@notAnswerSelected">
        Your didn't select any answer
    </span>
    <ul>
        <li
            fxLayout="column"
            fxLayoutAlign="center start"
            [class.alt-list-img]="alt.media_id"
            *ngFor="let alt of question.alternatives"
        >
            <div fxLayoutAlign="start center">
                <div [ngClass]="alt.correct ? 'green-color' : 'grey-color'" fxLayoutAlign="center center">
                    <mat-icon class="correct correct_checkbox material-icons-outlined alt-icon">
                        {{
                            alt.correct
                                ? question.question_type_value === QuestionTypes.CHECKBOX
                                    ? 'check_box'
                                    : 'check_circle_outline'
                                : 'clear'
                        }}
                    </mat-icon>
                </div>
                <ng-container *ngIf="alt.media_id">
                    <div *ngIf="alt.media$ | async; let media" class="example-element-diagram alt-media">
                        <app-image-view [media_id]="media.url" [proportional]="false"></app-image-view>
                    </div>
                </ng-container>
                <div [ngClass]="alt.correct ? 'green-color' : 'grey-color'" fxLayoutAlign="center center">
                    <span>{{ alt.title }}</span
                    >&nbsp;&nbsp;
                    <span *ngIf="alt.percentage">({{ alt.percentage }}%)</span>
                </div>

                <div
                    *ngIf="alt.user_choice"
                    [ngClass]="alt.correct ? 'green-color-img' : 'grey-color-img'"
                    class="user-img"
                >
                    {{ user?.name | nameSign }}
                </div>
                <button
                    *ngIf="alt.explanation"
                    mat-icon-button
                    color="primary"
                    class="explain-btn"
                    (click)="explanationExpansionPanel.toggle()"
                >
                    <mat-icon>{{
                        explanationExpansionPanel?.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                    }}</mat-icon>
                </button>
            </div>
            <div [fxHide]="!alt.explanation">
                <mat-expansion-panel class="explanation-expansion" #explanationExpansionPanel>
                    <mat-expansion-panel-header></mat-expansion-panel-header>
                    <span class="expl-txt"> {{ alt.explanation }}</span>
                </mat-expansion-panel>
            </div>
        </li>
    </ul>
    <div class="points">
        <div>
            <span i18n="@@score">Score:</span>
            <span> {{ question.user_points }}/{{ question.max_points }} </span>
        </div>
    </div>
</div>
