<form [formGroup]="addRoleForm" *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>{{ navData.data.icon }}</mat-icon>
        <div>{{ navData.data.title }}</div>
    </div>
    <div class="desc">{{ navData.data.desc }}</div>

    <div class="role-name">
        <mat-form-field class="role-name-field" appearance="outline">
            <mat-label i18n>Role name</mat-label>
            <input matInput type="text" formControlName="title" />
            <mat-error i18n>New role name is required</mat-error>
        </mat-form-field>
    </div>

    <div class="role-name">
        <mat-form-field class="role-name-field" appearance="outline">
            <mat-label i18n>Role description</mat-label>
            <textarea formControlName="description" matInput minRows="2"></textarea>
        </mat-form-field>
    </div>

    <div [fxHide]="navData.data.context === 'edit'" class="role-select">
        <span i18n>Copy from role:</span>
        <mat-form-field class="user-role-select add-new-role-select" appearance="outline">
            <mat-select
                formControlName="baseRole"
                placeholder="Select role"
                i18n-placeholder
                panelClass="user-roles-panel-select"
                #rolePanel
            >
                <mat-option *ngFor="let role of navData.data.roles" [value]="role.id">
                    {{ role.title }}
                </mat-option>
            </mat-select>
            <mat-error i18n>You need to select a base role</mat-error>
        </mat-form-field>
    </div>
    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            [disabled]="addRoleForm.invalid"
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="submit()"
            mat-raised-button
        >
            <span>{{ navData.data.positiveBtnText }}</span>
        </button>
        <button
            fxFlex="0 0 auto"
            class="mobile-button negative"
            (click)="onClose.emit(); addRoleForm.reset()"
            mat-button
        >
            <span i18n>Cancel</span>
        </button>
    </div>
</form>

<!-- <ng-container *ngIf="navData && navData.data.context === 'edit'">
    <div class="data-wrap" fxLayout="column">
        <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon>{{ navData.data.icon }}</mat-icon>
            <div>{{ navData.data.title }}</div>
        </div>
        <div class="desc">{{ navData.data.desc }}</div>

        <div class="role-name">
            <mat-form-field class="role-name-field" appearance="outline">
                <mat-label i18n>Role name</mat-label>
                <input matInput type="text" [formControl]="title" />
                <mat-error i18n>New role name is required</mat-error>
            </mat-form-field>
        </div>

        <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
            <button
                [disabled]="addRoleForm.invalid"
                fxFlex="0 0 auto"
                class="mobile-button positive"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n> Save</span>
            </button>
            <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button>
                <span i18n>Cancel</span>
            </button>
        </div>
    </div>
</ng-container> -->
