import { AccessCapability } from '../can-access.directive';

export namespace AccountCapability {
    export const NONE = 0;
    export const VIEW_USERS = 1 << 0;
    export const VIEW_GROUPS = 1 << 1;
    export const VIEW_DIVISIONS = 1 << 2;
    export const VIEW_USER_IMPORT = 1 << 3;
    export const VIEW_USER_EMAIL = 1 << 4;
    export const VIEW_PLANNER = 1 << 5;

    export function getName(): string {
        return 'account';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
