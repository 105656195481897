import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { PublishMessage } from '@klickdata/core/resource';
import { CoursePlanForm } from '@klickdata/shared-components/src/create-course-plan-simple/course-plan.form';
import { Subject } from 'rxjs';
import { CoursePlanPublishComponent } from '../../create-course-plan-simple/course-plan-publish/course-plan-publish.component';
import { CoursePlanRemindersComponent } from '../../create-course-plan-simple/course-plan-reminders/course-plan-reminders.component';
import { Reminder } from '../../create-course-plan-simple/course-plan-reminders/reminder.model';

@Component({
    selector: 'app-resource-assign-sheet',
    templateUrl: './resource-assign-sheet.component.html',
    styleUrls: ['./resource-assign-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CoursePlanForm],
})
export class ResourcesAssignSheetComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(CoursePlanRemindersComponent) reminderComp: CoursePlanRemindersComponent;
    @ViewChild(CoursePlanPublishComponent) publishComp: CoursePlanPublishComponent;
    actionText = $localize`:@@send:Send`;
    public isLoading: boolean;
    protected destroy: Subject<boolean> = new Subject<boolean>();
    reminderControl = new FormControl(false);
    hideReminderToggle: boolean;

    constructor(
        protected bottomSheetRef: MatBottomSheetRef<ResourcesAssignSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected messageService: MessageService,
        public courseplanFrom: CoursePlanForm
    ) {}

    ngOnInit(): void {
        this.courseplanFrom.import(this.data);
    }

    ngAfterViewInit() {
        this.reminderControl.valueChanges.subscribe((value) => {
            if (value) {
                this.reminderComp.form.enable();
            } else {
                this.reminderComp.form.disable();
            }
        });
        this.reminderControl.setValue(!!this.data.assign_id);
    }

    onReminderLoaded(reminders: Reminder[]) {
        this.hideReminderToggle = !!reminders.length;
        this.reminderControl.setValue(this.hideReminderToggle);
    }

    submitAction() {
        this.publishComp.publish();
        this.isLoading = this.publishComp.isLoading;
    }

    /**
     * After store publish message and complete assignment.
     * @param message PublishMessage
     */
    onPublished(message: PublishMessage) {
        if (this.reminderControl.value) {
            this.reminderComp.submit(message.assign_id);
            this.isLoading = this.reminderComp.loading;
        } else {
            this.onClose({ status: true, assignId: message.assign_id });
        }
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    onReminderDone(done: boolean) {
        this.isLoading = false;
        if (done) {
            this.onClose({ status: true });
        }
    }

    dismissBottomSheet() {
        this.bottomSheetRef.dismiss();
    }

    public onClose(publishment: { status?: boolean; assignId?: number }) {
        this.bottomSheetRef.dismiss(publishment?.assignId);
        if (publishment.status) {
            this.messageService.openMessage(
                MessageSavedComponent,
                $localize`:@@courseplanSubmittedLearnersWillNotified:Course plan has been successfully submitted and is being processed by our server, Learners will be notified by email when processing is complete`
            );
        }
    }
}
