import { Injectable } from '@angular/core';
import { ResourceOpportunity, ResourceOpportunityData, ResourceOpportunityService } from '@klickdata/core/opportunity';
import { AppScope } from '@klickdata/core/resource';
import { map } from 'rxjs/operators';

@Injectable()
export class OccasionService extends ResourceOpportunityService {
    public getUnfinishedUserOccasions(scope: AppScope) {
        return this.builder
            .get<ResourceOpportunityData[]>(this.resourceUrl)
            .param('scope', scope)
            .param('started', 'true')
            .request()
            .pipe(
                map(res => {
                    this.mapOpportunity(res.data);
                })
            );
    }

    public getOccasionChildren(occasion: ResourceOpportunity) {
        return this.builder
            .get<ResourceOpportunityData[]>(this.resourceUrl)
            .param('parent', `${occasion.id}`)
            .request()
            .pipe(
                map(res => {
                    this.mapOpportunity(res.data);
                })
            );
    }
}
