import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    LOCALE_ID,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
    Filter,
    GlobalFilterProperty,
    GlobalFilterPropertyType,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { UserListService } from './user-list.service';
import { MobileService } from '@klickdata/core/mobile';
import { takeUntil, shareReplay } from 'rxjs';
import { Utils } from '@klickdata/core/util';
import { ResourcePermissionsHandlerComponent } from '../resource-permissions-handler/resource-permissions-handler.component';

@Component({
    selector: 'app-resource-access-control-users',
    templateUrl: './resource-access-control-users.component.html',
    styleUrls: ['./resource-access-control-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UserListService],
})
export class ResourceAccessControlUsersComponent extends OnDestroyHandler implements OnInit, AfterViewInit {
    @Input() permissionsForm: FormGroup;
    @Input() resourcePermissions: {
        index: number;
        value: string;
        label: string;
        tooltip?: string;
        disableUser?: boolean;
    }[];
    @ViewChildren(ResourcePermissionsHandlerComponent)
    resourcePermHandlerchilldren: QueryList<ResourcePermissionsHandlerComponent>;
    public permissionsColSelectionState = {};
    public dataSource = new TableSource<User>();
    public columns = ['select', 'name', 'email', 'permissions'];
    public isMobile: boolean;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    GlobalFilterProperty = GlobalFilterProperty;
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    public userStatusOptions: SelectFilterOption[];
    public userRoleOptions: SelectFilterOption[];
    constructor(
        protected mobile: MobileService,
        protected cdRef: ChangeDetectorRef,
        protected userService: UserListService,
        @Inject(LOCALE_ID) public localeId: string
    ) {
        super();
        this.userRoleOptions = Utils.getUserRoleOptions();
        this.userStatusOptions = Utils.getUserStatusOptions();
    }

    ngOnInit(): void {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((mobile) => {
                this.isMobile = mobile;
                this.cdRef.markForCheck();
            });
        this.dataSource.service = this.userService;
        // Handle vertical selection
        // this.permissionsColSelectionState = this.resourcePermissions.reduce((acc, perm) => {
        //     acc[perm.value] = false;
        //     return acc;
        // }, {});
    }
    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        if (this.filter && this.permissionsForm) {
            this.filter.createOrUpdateFilter([
                new Filter('referenceResource', [this.permissionsForm.value.id]),
                // new Filter('referenceCount', [1]),
                new Filter('customer', [this.userService.customer_id]),
            ]);
        }
    }
    get userPermissionsArray(): FormArray {
        if (this.permissionsForm) {
            return this.permissionsForm?.get('user_permissions') as FormArray;
        }
    }
    onTogglePerm(permName: string) {
        // this.permissionsColSelectionState[permName] = !this.permissionsColSelectionState[permName];
        // this.resourcePermHandlerchilldren.forEach((child) =>
        //     child.togglePerm(this.permissionsColSelectionState[permName], permName)
        // );
    }
    selectToggle(user: User, status: boolean) {
        this.resourcePermHandlerchilldren.forEach((child) => child.toggleAll(status, user.id));
    }
}
