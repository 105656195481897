import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { Resource, ResourceTypes, AppScope } from '@klickdata/core/resource';

@Component({
    selector: 'app-course-plan-items',
    templateUrl: './course-plan-items.component.html',
    styleUrls: ['./course-plan-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoursePlanItemsComponent implements OnInit {
    @Input() selection: number[] = [];
    @Input() resource_type_id: ResourceTypes;
    ResourceTypes = ResourceTypes;
    AppScope = AppScope;
    @Output() add: EventEmitter<Resource> = new EventEmitter<Resource>();

    constructor() {}

    ngOnInit() {}
    public addResource(resource: Resource): boolean {
        if (resource instanceof Resource) {
            this.add.emit(resource);
            return true;
        }
        return false;
    }
}
