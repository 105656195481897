<form [formGroup]="form" class="main-wrap" fxLayout="column" fxFlex="grow" fxLayoutGap="1em">
    <mat-checkbox
        *ngIf="hasCheckBox"
        (change)="onCheckBoxChange.emit(!$event.checked)"
        [checked]="true"
        class="draft-checkbox"
    >
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <span fxLayoutAlign="start center" fxLayoutGap="5px" i18n> Create Quiz </span>
        </div></mat-checkbox
    >
    <div class="qs-num">
        <div class="head-title mt-0" i18n>Number of questions</div>
        <mat-slider fxFlex="grow" formControlName="question_count" min="1" max="20" step="1" showTickMarks discrete>
            <input matSliderThumb />
        </mat-slider>
        <div class="number-labels qs-num-label">
            <p class="sec-font">1</p>
            <p class="sec-font">5</p>
            <p class="sec-font">10</p>
            <p class="sec-font">15</p>
            <p class="sec-font">20</p>
        </div>
    </div>
    <div class="qs-diff">
        <div class="head-title mb-0" i18n>Difficulity level</div>
        <mat-slider fxFlex="grow" formControlName="difficulty_level" min="1" max="5" step="1">
            <input matSliderThumb />
        </mat-slider>
        <div class="number-labels diff-label">
            <p class="sec-font" i18n>Up to 10</p>
            <p class="sec-font" i18n>Up to 15</p>
            <p class="sec-font" i18n>Adult</p>
            <p class="sec-font" i18n>Professional</p>
            <p class="sec-font" i18n>Master</p>
        </div>
    </div>
    <div fxLayout="column" class="mt-1" fxLayoutGap="10px">
        <div class="head-title mt-1" i18n>Questions Type</div>
        <div
            [formGroup]="form.get('question_type_value') | cast"
            class="course-sett-not"
            fxLayout="row"
            fxLayoutAlign="space-between center"
        >
            <mat-checkbox formControlName="radio" i18n>Single</mat-checkbox>
            <mat-checkbox formControlName="checkbox" i18n>Multiple</mat-checkbox>
            <mat-checkbox formControlName="input" i18n>Open</mat-checkbox>
        </div>
    </div>
    <mat-checkbox formControlName="create_course" class="course-checkbox">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <span fxLayoutAlign="start center" fxLayoutGap="5px" i18n> Create Course</span>
            <mat-icon
                matTooltip="This option will create a course that contains the created quiz by AI"
                i18n-matTooltip
                class="help"
                >help_outline</mat-icon
            >
        </div></mat-checkbox
    >
</form>
