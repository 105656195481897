<div>
    <button
        *ngIf="!(isRecording$ | async)"
        type="button"
        class="start-record"
        mat-icon-button
        matTooltip="Start recording material"
        i18n-matTooltip
        [matMenuTriggerFor]="recorder"
        #recorderMenuTrigger="matMenuTrigger"
    >
        <mat-icon>radio_button_checked</mat-icon>
    </button>
    <button
        *ngIf="isRecording$ | async"
        type="button"
        (click)="stopRecorder()"
        class="stop-record"
        mat-icon-button
        matTooltip="Stop recording"
        i18n-matTooltip
    >
        <mat-icon>fiber_manual_record</mat-icon>
    </button>
</div>
<mat-menu class="menu-header-options" #recorder="matMenu" [overlapTrigger]="false">
    <app-recorder-screen></app-recorder-screen>
    <app-recorder-audio></app-recorder-audio>
    <app-recorder-video></app-recorder-video>
    <app-recorder-screen-with-video></app-recorder-screen-with-video>
</mat-menu>
<ng-template #smallLoading>
    <div fxLayout="row" fxLayoutAlign="start center" class="spinner-container mt-2">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
