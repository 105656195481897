import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { PaginatorResponse } from '@klickdata/core/http';
import { Resource } from '@klickdata/core/resource';
import { TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-general-res-listing-mobile',
    templateUrl: './general-res-listing-mobile.component.html',
    styleUrls: ['./general-res-listing-mobile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * @Deprecated
 */
export class GeneralResListingMobileComponent implements OnInit {
    @Input() dataSource: TableSource<Resource>;
    @Output() onBrowse: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onPlay: EventEmitter<Resource> = new EventEmitter<Resource>();
    public resources$: Observable<Resource[]>;
    constructor() {}

    ngOnInit(): void {
        this.resources$ = this.dataSource.latestData.pipe(map((data: PaginatorResponse<Resource[]>) => data.data));
    }
    public getPlayBtnTxt(resource: Resource): string {
        return resource.opportunity_id ? $localize`Continue` : $localize`Play`;
    }
}
