import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TitleService {
    constructor(protected title: Title) {}

    public setTitle(newTitle: string): void {
        this.title.setTitle(newTitle);
    }
}
