import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { Grade, GradeData } from '@klickdata/core/grade-system/src/grade.model';
import { ConfigService } from '@klickdata/core/config/src/config.service';

@Injectable()
export class GradeService {
    protected resourceUrl: string;

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
    ) {
        this.resourceUrl = `${this.config.config.resourceUrl}grades`;
    }

    getGrades(gradeSystemId: number) {
        return this.builder.get<GradeData[]>(this.resourceUrl)
            .param('gradeSystem', `${gradeSystemId}`)
            .param('sort', 'percentage')
            .request()
            .pipe(map(res => {
                return res.data.map(data => new Grade(data));
            }));
    }

    getMinRequirement(grades: Grade[]) {
        let grade: Grade;
        for (let i = 0; i < grades.length; i++) {
            if (!grade && grades[i].passing) {
                grade = grades[i];
            }
        }
        return grade;
    }

    getUserGrade(grades: Grade[], userPercent): Grade | false {
        const filterGrades = grades.filter(grade => {
            return grade.percentage <= userPercent;
        }).sort((a, b) => {
            return b.percentage - a.percentage;
        });
        if (filterGrades.length > 0) {
            return filterGrades[0];
        }
        return false;
    }

    getNextGrade(grades: Grade[], userPercent): Grade | false {
        const filterGrades = grades.filter(grade => {
            return grade.percentage > userPercent;
        }).sort((a, b) => {
            return a.percentage - b.percentage;
        });
        if (filterGrades.length > 0) {
            return filterGrades[0];
        }
        return false;
    }

}
