<div class="main-wrap" fxLayout="column">
    <div *ngIf="displayedScope" class="w-100">
        <ng-container [ngSwitch]="displayedScope">
            <ng-container *ngSwitchCase="AppScope.MATERIAL">
                <app-course-manager-core-add-material
                    #compRef
                    [display]="displayTab"
                    [selection]="selection"
                    (select)="addResource($event); displayedScope = null"
                    (onError)="onError.emit($event)"
                    (onCloseView)="displayedScope = null"
                    (onMultiSelect)="addmultipleResources($event); displayedScope = null"
                ></app-course-manager-core-add-material
            ></ng-container>
            <ng-container *ngSwitchCase="AppScope.TEST">
                <app-course-manager-core-add-test
                    #compRef
                    [display]="displayTab"
                    [selection]="selection"
                    (add)="addResource($event); displayedScope = null"
                    (onMultiSelect)="addmultipleResources($event); displayedScope = null"
                    (onError)="onError.emit($event)"
                    (onCloseView)="displayedScope = null"
                >
                </app-course-manager-core-add-test
            ></ng-container>
            <ng-container *ngSwitchCase="AppScope.SURVEY">
                <app-course-manager-core-add-survey
                    #compRef
                    [display]="displayTab"
                    [selection]="selection"
                    (add)="addResource($event); displayedScope = null"
                    (onMultiSelect)="addmultipleResources($event); displayedScope = null"
                    (onError)="onError.emit($event)"
                    (onCloseView)="displayedScope = null"
                ></app-course-manager-core-add-survey
            ></ng-container>
            <ng-container *ngSwitchCase="AppScope.EVENT">
                <app-course-manager-core-add-event
                    #compRef
                    [display]="displayTab"
                    [selection]="selection"
                    (add)="addResource($event); displayedScope = null"
                    (onMultiSelect)="addmultipleResources($event); displayedScope = null"
                    (onError)="onError.emit($event)"
                    (onCloseView)="displayedScope = null"
                ></app-course-manager-core-add-event
            ></ng-container>
        </ng-container>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="10px" class="w-100 adding-btns">
        <button
            type="button"
            *ngIf="displayedScope != AppScope.MATERIAL"
            mat-button
            (click)="addItem(AppScope.MATERIAL)"
        >
            <mat-icon>add_circle_outline</mat-icon>
            <span fxHide.lt-md i18n="@@addMaterial">Add Material</span>
            <span fxHide.gt-sm i18n="@@material">Material</span>
        </button>
        <button type="button" *ngIf="displayedScope != AppScope.TEST" mat-button (click)="addItem(AppScope.TEST)">
            <mat-icon>add_circle_outline</mat-icon>
            <span fxHide.lt-md i18n="@@addTest">Add Test</span>
            <span fxHide.gt-sm i18n="@@test">Test</span>
        </button>
        <button type="button" *ngIf="displayedScope != AppScope.SURVEY" mat-button (click)="addItem(AppScope.SURVEY)">
            <mat-icon>add_circle_outline</mat-icon>
            <span fxHide.lt-md i18n="@@addSurvey">Add Survey</span>
            <span fxHide.gt-sm i18n="@@survey">Survey</span>
        </button>
        <button *ngIf="displayedScope != AppScope.EVENT" type="button" mat-button (click)="addItem(AppScope.EVENT)">
            <mat-icon>add_circle_outline</mat-icon>
            <span fxHide.lt-md i18n="@@addEvent">Add Event</span>
            <span fxHide.gt-sm i18n="@@event">Event</span>
        </button>
    </div>

    <!-- <div class="add-item-wrap" [ngStyle]="{ order: !showAddMaterialBtn ? -1 : 1 }"></div>
    <div class="add-item-wrap" [ngStyle]="{ order: !showAddTestBtn ? -1 : 2 }"></div>
    <div class="add-item-wrap" [ngStyle]="{ order: !showAddSurveyBtn ? -1 : 3 }"></div>
    <div class="add-item-wrap" [ngStyle]="{ order: !showAddSurveyBtn ? -1 : 4 }"> -->
    <!-- <app-course-manager-core-add-survey
            *ngIf="!showAddSurveyBtn"
            [display]="displayTab"
            [selection]="selection"
            (add)="addResource($event); showAddSurveyBtn = true"
            (onCloseView)="$event && (showAddSurveyBtn = true)"
        ></app-course-manager-core-add-survey> -->
    <!-- </div> -->
</div>
