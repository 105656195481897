export enum SectionSort {
    AlPHABETIC_ORDER = 'alphabetically',
    Random = 'random',
    NEWELY = 'newely',
    RATE_ORDER = 'rated',
    POPULAR = 'popularity',
}

export namespace SectionSort {
    export function label(section_sort: SectionSort): string {
        switch (section_sort) {
            case SectionSort.AlPHABETIC_ORDER:
                return $localize`:@@alphabeticOrder:Alphabetic Order`;
            case SectionSort.Random:
                return $localize`:@@random:Random`;
            case SectionSort.NEWELY:
                return $localize`:@@newlyAdded:Newly Added`;
            case SectionSort.RATE_ORDER:
                return $localize`:@@rateOrder:Rate Order`;
            case SectionSort.POPULAR:
                return $localize`:@@popular:Popular`;
        }
    }
    export function values(): SectionSort[] {
        return [
            SectionSort.AlPHABETIC_ORDER,
            SectionSort.Random,
            SectionSort.NEWELY,
            SectionSort.RATE_ORDER,
            SectionSort.POPULAR,
        ];
    }
}
