<div class="btn-wrapper-update" *ngIf="isCreateForm">
    <div class="video-filed-wrapper" *ngIf="mediaItems.length < maxImgCount">
        <div *ngIf="type === 'both' || type === 'file'" class="uploader">
            <ng-container *ngIf="!(saving$ | async); else loading">
                <div class="media-dropzone" fxLayout="row wrap" fxLayoutAlign="center center">
                    <button
                        *ngIf="isIconBtns"
                        type="button"
                        class="media-upload-button"
                        mat-icon-button
                        (click)="uploader.click()"
                        color="primary"
                        [matTooltip]="fileTooltip"
                    >
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <div
                        fxLayout="column"
                        fxLayoutAlign="space-evenly center"
                        *ngIf="!isIconBtns"
                        class="media-upload-button-wrapper"
                        ng2FileDrop
                        [uploader]="fileUploader"
                    >
                        <span>{{ dropLabel }}</span>
                        <button
                            type="button"
                            class="media-upload-button-wrapped"
                            mat-button
                            (click)="uploader.click()"
                            color="primary"
                        >
                            <span>{{ fileTooltip }}</span>
                        </button>
                    </div>
                </div>
                <input
                    #uploader
                    class="media-upload"
                    type="file"
                    [accept]="allowedMimeType?.toString()"
                    type="file"
                    ng2FileSelect
                    [uploader]="fileUploader"
                    [multiple]="multiple"
                />
            </ng-container>
        </div>
        <span *ngIf="!isIconBtns && type === 'both'" i18n class="or">or</span>
        <div *ngIf="type === 'both' || type === 'link'" class="input-wrapper" fxLayout="column" fxLayoutGap="10px">
            <button
                *ngIf="isIconBtns"
                type="button"
                class="media-upload-button link"
                mat-icon-button
                (click)="uploadLink()"
                color="primary"
                [matTooltip]="linkTooltip"
            >
                <mat-icon>link</mat-icon>
            </button>
            <button
                *ngIf="!isIconBtns"
                type="button"
                class="media-upload-button-wrapped"
                mat-button
                (click)="uploadLink()"
                color="primary"
                [matTooltip]="linkTooltip"
            >
                <mat-icon>link</mat-icon>
                <span>{{ linkTooltip }}</span>
            </button>
        </div>
    </div>
</div>
<div *ngIf="isCreateForm && mediaItems.length > 0" class="attachments">
    <div (click)="media.url && openLink(media.url)" class="attachs" *ngFor="let media of mediaItems; let index = index">
        <mat-icon>{{ media.filename ? 'attach_file' : 'link' }}</mat-icon>
        <span *ngIf="media.provide" class="media-label">{{ media.provide }} :: </span>
        <span class="media-label">{{ media.filename || media.url }}</span>
        <button mat-icon-button (click)="removeAttachment(mediaItems.indexOf(media))">
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="!isCreateForm" class="multi-img-wrapper">
    <div *ngFor="let media of mediaItems; let index = index" class="img-view-wrapper">
        <app-media-view fxLayoutAlign="center center" fxFlex="0 0 100%" [media]="media"> </app-media-view>
    </div>
</div>

<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
