import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { MediaImgUploaderBase } from '@klickdata/core/media/src/media-img-uploader-base';
import { Media } from '@klickdata/core/media/src/media.model';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService } from '@klickdata/core/mobile';
import { FileItem, ParsedResponseHeaders } from 'ng2-file-upload';

@Component({
    selector: 'app-menu-side-media-upload-img',
    templateUrl: './menu-side-media-upload-img.component.html',
    styleUrls: ['./menu-side-media-upload-img.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideMediaUploadImgComponent extends MediaImgUploaderBase implements OnInit {
    @Output() onMediaReady: EventEmitter<Media> = new EventEmitter<Media>();
    constructor(
        protected mediaService: MediaService,
        protected auth: AuthService,
        protected message: MessageService,
        protected mobileService: MobileService
    ) {
        super(mediaService, auth, message);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }
    public onComplete(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): void {
        const responseJSON = JSON.parse(response);
        try {
            if (item.isSuccess) {
                this.onMediaReady.emit(responseJSON.data);
            } else if (responseJSON.error) {
                this.message.openMessage(MessageErrorComponent, responseJSON.error.messages);
                this.onError.emit(responseJSON.error.messages);
            }
        } catch (e) {
            this.message.openMessage(MessageErrorComponent, responseJSON.error.messages);
            this.onError.emit(response);
        }
        this.saving.emit(false);
    }
}
