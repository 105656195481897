import { Injectable } from '@angular/core';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { User, UserData, UserService } from '@klickdata/core/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UserListService extends UserService implements TableHttpService<User> {
    public fetchData(source: TableSource<User>): Observable<ResponseData<User[]>> {
        const req = this.builder.get<UserData[]>(this.resourceUrl);
        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<UserData[]>) => {
                return {
                    data: res.data.map((user) => this.mapUser(user)),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }
}
