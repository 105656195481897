import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { Resource } from '@klickdata/core/resource';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CoursePlanForm } from '../course-plan.form';
import { ReminderForm } from './reminder.form';
import { Reminder } from './reminder.model';
import { ReminderService } from './reminder.service';

@Component({
    selector: 'app-course-plan-reminders',
    templateUrl: './course-plan-reminders.component.html',
    styleUrls: ['./course-plan-reminders.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ReminderForm],
})
export class CoursePlanRemindersComponent implements OnInit, OnDestroy {
    @Input() hideActions: boolean;
    public minDate: moment.Moment;
    public maxDate: moment.Moment;
    @Output() done: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() loaded: EventEmitter<Reminder[]> = new EventEmitter<Reminder[]>();
    public loading: boolean;

    protected destroy: Subject<boolean> = new Subject<boolean>();
    constructor(
        protected reminderForm: ReminderForm,
        protected remindersService: ReminderService,
        protected messageService: MessageService,
        protected cdRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.reminderForm.onParentUpdated
            .pipe(first(), takeUntil(this.destroy))
            .subscribe(model => this.initReminders(model));

        if (this.reminderForm.parent?.model) {
            this.initReminders((<CoursePlanForm>this.reminderForm.parent).model);
        }
    }

    public get form(): FormGroup {
        return this.reminderForm.form;
    }
    public get days(): number {
        return this.form.value.dates.length;
    }

    protected initReminders(model: Resource): void {
        this.minDate = model.start_date;
        this.maxDate = model.end_date;
        if (!this.minDate || !this.maxDate) {
            this.initDatePeriod();
        }

        /**
         * When assign already created get his reminders,
         * IF new one just build template.
         */
        (model.assign_id
            ? this.remindersService.getAssignedReminders(model.assign_id).pipe(
                  takeUntil(this.destroy),
                  tap(reminders => this.loaded.emit(reminders)),
                  switchMap(reminders =>
                      reminders.length
                          ? of(reminders)
                          : this.reminderForm.create(model).pipe(map(reminder => [reminder]))
                  )
              )
            : this.reminderForm.create(model).pipe(map(reminder => [reminder]))
        ) // Creation make new reminder templating.
            .subscribe(reminders => {
                let dates = reminders
                    .filter((rmd, i, rmds) => rmds.findIndex(r => r.date_time?.isSame(rmd.date_time, 'day')) === i)
                    .map(reminder => reminder.date_time);
                if (!dates.length) {
                    dates = this.calculateReminders(this.minDate, this.maxDate);
                }
                this.reminderForm.import(reminders);
                this.form.reset({
                    subject: this.reminderForm.model.subject,
                    body: this.reminderForm.model.body,
                    dates: dates,
                });
                FormHelper.resetForm(this.form);
                this.cdRef.markForCheck();
            });
    }

    private initDatePeriod() {
        this.minDate = moment()
            .hour(0)
            .minute(0)
            .second(0);
        this.maxDate = moment()
            .hour(23)
            .minute(59)
            .second(0)
            .add(1, 'M');
    }

    protected calculateReminders(start: moment.Moment, end: moment.Moment, count?: number): moment.Moment[] {
        const duration = moment.duration(end.diff(start, null, true));

        count = count ? count : Math.round(duration.asWeeks());
        const days = Math.round(duration.asDays());
        const lastDay = end.clone().locale('sv');

        const dates: moment.Moment[] = [];
        for (let i = 1; i < count; i++) {
            const date = lastDay.clone().subtract((days / count) * i, 'd');

            if (date.weekday() === 5 || date.weekday() === 6) {
                date.weekday(4).format('dddd');
            }

            dates.push(date);
        }

        dates.push(lastDay);
        return dates;
    }

    public submit(assign_id?: number) {
        if (this.reminderForm.form.invalid) {
            FormHelper.markForm(this.reminderForm.form);
            return;
        }
        this.reminderForm.model.assign_id = assign_id;
        this.loading = true;
        this.reminderForm
            .submit()
            .pipe(takeUntil(this.destroy))
            .subscribe(
                () => {
                    this.loading = false;
                    this.done.emit(true);
                },
                err => {
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                        this.done.emit(false);
                    }
                }
            );
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
