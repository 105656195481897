import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceService } from '@klickdata/core/resource';
import { ResourceEducatorHandlerComponent } from '../resource-educator-handler/resource-educator-handler.component';
import { Educator } from '@klickdata/core/resource/src/resource.model';
import { takeUntil } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';

@Component({
    selector: 'app-resource-manager-author-handler',
    templateUrl: './resource-manager-author-handler.component.html',
    styleUrls: ['./resource-manager-author-handler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ResourceManagerAuthorHandlerComponent),
            multi: true,
        },
    ],
})
export class ResourceManagerAuthorHandlerComponent extends ResourceEducatorHandlerComponent {
    constructor(
        protected resourceService: ResourceService,
        protected cdr: ChangeDetectorRef,
        protected mobileService: MobileService,
        protected message: MessageService
    ) {
        super(resourceService, cdr, mobileService, message);
    }
    removeIns(educator: Educator) {
        const index = this.educators.findIndex((edu) => edu.id === educator.id);
        if (index !== -1) {
            this.educators.splice(index, 1);
            this.defaultEdu = this.educators[0];
            // if (this.educators.length > 1 && this.setDefault) {
            this.notify();
            // }
        }
    }

    public addEducator(educator: Educator) {
        const index = this.educators.findIndex((edu) => edu.id === educator.id);
        if (index !== -1) {
            this.educators.splice(index, 1, educator);
        } else {
            this.educators.push(educator);
        }
        this.setDefaultEdu(educator);
    }
}
