<mat-form-field class="chip-list" fxFlex="0 1 100%" class="mb-4">
    <mat-chip-list class="groups-cats-chips" #chipList aria-label="Add Groups">
        <mat-chip
            *ngFor="let group of selection"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(group)"
        >
            {{ group.name }}
            <mat-icon matChipRemove *ngIf="removable"> cancel </mat-icon>
        </mat-chip>

        <input
            #searchInput
            placeholder="Add group"
            i18n-placeholder="@@addGroup"
            [formControl]="control"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="add($event)"
        />
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelected($event)">
        <mat-option *ngFor="let group of filteredGroups | async" [value]="group">
            {{ group.name }}
        </mat-option>
    </mat-autocomplete>

    <mat-hint *ngIf="maxSelected" i18n="@@addGroups"> Add up to {{ maxSelected }} groups* </mat-hint>
    <mat-error i18n>Groups are required!</mat-error>
</mat-form-field>
