import {
    ConnectedPosition,
    FlexibleConnectedPositionStrategy,
    HorizontalConnectionPos,
    Overlay,
    OverlayRef,
    VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ElementRef, ViewContainerRef } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { NotificationService } from '@klickdata/core/notification';
import { Observable, merge } from 'rxjs';
import { PostPanelComponent } from './post-panel/post-panel.component';

@Component({
    selector: 'app-post',
    templateUrl: 'post.component.html',
    styleUrls: ['post.component.scss'],
})
export class PostComponent implements AfterViewInit {
    protected overlayRef: OverlayRef = null;
    public badgeNumber: Observable<number>;

    constructor(
        public overlay: Overlay,
        public viewContainerRef: ViewContainerRef,
        private _element: ElementRef,
        protected bottomSheet: MatBottomSheet,
        protected notificationService: NotificationService
    ) {}
    ngAfterViewInit(): void {
        this.badgeNumber = merge(
            this.notificationService.getNotificatioBadgeNumber(),
            this.notificationService.listenToNotificationBadge()
        );
    }
    togglePost() {
        this.bottomSheet.open(PostPanelComponent);
    }
    // togglePost() {
    //     if (!this.overlayRef) {
    //         this.openPost();
    //     } else {
    //         this.closePost();
    //     }
    // }

    openPost() {
        this.overlayRef = this.overlay.create({
            positionStrategy: this.getPosition(),
            width: window.innerWidth / 2,
            hasBackdrop: true,
            backdropClass: 'transparent-backdrop-black',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });

        this.overlayRef.backdropClick().subscribe(() => this.closePost());
        const portal = new ComponentPortal(PostPanelComponent);
        portal.attach(this.overlayRef as any).instance.exit.subscribe(() => {
            this.closePost();
        });
    }

    closePost() {
        this.overlayRef.detach();
        this.overlayRef.dispose();
        this.overlayRef = null;
    }

    protected getPosition(): FlexibleConnectedPositionStrategy {
        const [posX, fallbackX]: HorizontalConnectionPos[] = ['end', 'start'];
        const [overlayY, fallbackOverlayY]: VerticalConnectionPos[] = ['top', 'bottom'];

        const originY = overlayY === 'top' ? 'bottom' : 'top';
        const fallbackOriginY = fallbackOverlayY === 'top' ? 'bottom' : 'top';

        return this.overlay
            .position()
            .flexibleConnectedTo(this._element)
            .withPositions([{ originX: posX, originY: originY, overlayX: posX, overlayY: overlayY }]);
        // .withFallbackPosition([{ originX: fallbackX, originY: originY, overlayX: fallbackX, overlayY: overlayY }])
        // .withFallbackPosition(
        //     { originX: posX, originY: fallbackOriginY },
        //     { overlayX: posX, overlayY: fallbackOverlayY }
        // )
        // .withFallbackPosition(
        //     { originX: fallbackX, originY: fallbackOriginY },
        //     { overlayX: fallbackX, overlayY: fallbackOverlayY }
        // );
    }
}
