import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
    selector: 'app-browse-section',
    templateUrl: './browse-section.component.html',
    styleUrls: ['./browse-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrowseSectionComponent implements OnInit {
    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {}

    ngOnInit(): void {}
}
