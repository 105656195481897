import { Injectable } from '@angular/core';
import { LoggerService } from '@klickdata/core/application/src/logger/logger.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Resource } from '../resource.model';
import { AppScope } from '../type.scope.enum';
import { ResourceType, ResourceTypeData } from './resource-type.model';

@Injectable({
    providedIn: 'root',
})
export class ResourceTypeService {
    protected resourceUrl: string;
    protected typesMap: Map<number, Observable<ResourceType[]>> = new Map<number, Observable<ResourceType[]>>();
    constructor(
        protected logger: LoggerService,
        protected builder: RequestBuilderService,
        protected config: ConfigService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}app-types`;
    }

    public getResourceTypes(scope: AppScope): Observable<ResourceType[]> {
        const cached = this.typesMap.get(scope);
        if (cached) {
            return cached;
        }
        const fetched = this.builder
            .get<ResourceTypeData[]>(this.resourceUrl)
            .param('scope', `${scope}`)
            .request()
            .pipe(
                map((res) => this.mapResourceTypes(res.data)),
                shareReplay()
            );

        // add to cache.
        this.typesMap.set(scope, fetched);
        return fetched;
    }

    public fetchResourceTypes(scope: AppScope, params = {}): Observable<ResourceType[]> {
        return this.builder
            .get<ResourceTypeData[]>(this.resourceUrl)
            .param('scope', `${scope}`)
            .putParam(params)
            .request()
            .pipe(map((res) => this.mapResourceTypes(res.data)));
    }

    private getResourceTypesByScopes(scopes: AppScope[]): Observable<ResourceType[]> {
        const obs = scopes.map((scope) => this.getResourceTypes(scope));
        return combineLatest(obs).pipe(map((types) => (<ResourceType[]>[]).concat(...types)));
    }

    public checkTypeInScopes(type_id: number, ...scopes: AppScope[]): Observable<boolean> {
        return this.getResourceTypesByScopes(scopes).pipe(
            map((types) => types.map((type) => type.id)),
            map((type_ids) => type_ids.indexOf(type_id) !== -1)
        );
    }

    public getResourceTypeInScope(type_id: number, ...scopes: AppScope[]): Observable<ResourceType> {
        return this.getResourceTypesByScopes(scopes).pipe(map((types) => types.find((type) => type.id === type_id)));
    }

    protected mapResourceTypes(data: ResourceTypeData[]): ResourceType[] {
        return data.map((item) => this.createResourceType(item));
    }

    protected createResourceType(data: ResourceTypeData): ResourceType {
        return new ResourceType(data);
    }
}
