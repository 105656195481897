import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LanguageService } from '@klickdata/core/localization';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { AppScope } from '@klickdata/core/resource';
import { SelectFilterOption } from '@klickdata/core/table';
import { NotesContext } from '@klickdata/core/user-notes';
import { Utils } from '@klickdata/core/util';
import { Observable, Subject, map } from 'rxjs';
import { NotesContextComponent } from '../notes-context/notes-context.component';

@Component({
    selector: 'app-menu-side-add-hr-note',
    templateUrl: './menu-side-add-hr-note.component.html',
    styleUrls: ['./menu-side-add-hr-note.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddHrNoteComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    @ViewChild(NotesContextComponent) notesContextComp: NotesContextComponent;
    AppScope = AppScope;
    public form: FormGroup;
    public buttonDisabled: boolean;
    public saving: Subject<boolean> = new Subject<boolean>();
    SideNaveActionsTypes = SideNaveActionsTypes;
    customerContactTypeOptions: SelectFilterOption[];
    public currentLaguageId$: Observable<number>;
    public noteContext: any;

    constructor(protected fb: FormBuilder, protected cd: ChangeDetectorRef, protected langService: LanguageService) {
        this.form = this.fb.group({
            body: ['', Validators.required],
            media_ids: [[]],
            tag_ids: [[]],
            staff: this.fb.group({
                manager: [[]],
            }),
            created_at: [new DatePipe('en').transform(Date.now(), 'yyyy-MM-dd').toString()],
        });
        this.customerContactTypeOptions = Utils.getCustomerContactTypeOptions();
    }
    ngOnInit(): void {
        if (this.navData.data.hasContactType) {
            this.form.addControl('contact_type', new FormControl());
        }
        if (!!this.navData.data.noteVisibilityOptions?.length) {
            this.form.addControl('visibility', new FormControl(this.navData.data.noteVisibilityOptions[0]));
        }
        if (this.navData?.data.note) {
            this.updateForm();
        }
        this.form.valueChanges.subscribe(() => {
            this.buttonDisabled = false;
            this.cd.markForCheck();
        });
        this.currentLaguageId$ = this.langService
            .getLanguageByKey(this.langService.getCurrentLanguage().value)
            .pipe(map((lang) => lang.id));
    }

    private updateForm() {
        this.form.patchValue(this.navData.data.note);
        if (this.navData.data.note?.context) {
            setTimeout(() => {
                this.updateNoteContext(this.navData.data.note.context);
            }, 500);
        }
        this.buttonDisabled = true;
        this.cd.markForCheck();
    }
    private updateNoteContext(context: NotesContext) {
        if (context.user) {
            this.notesContextComp?.contextTypeForm?.patchValue(
                { type: 'learner', user: [context.user.id] },
                { emitEvent: false }
            );
            this.noteContext = { user_id: context.user.id };
        } else if (context.customer) {
            this.notesContextComp?.contextTypeForm?.patchValue(
                { type: 'client', customer: [context.customer.id] },
                { emitEvent: false }
            );
            this.noteContext = { customer_id: context.customer.id };
        } else if (context.resource) {
            this.notesContextComp?.contextTypeForm?.patchValue({ type: 'none' }, { emitEvent: false });
            this.noteContext = { resource_id: context.resource.id };
        } else if (context.task) {
            this.notesContextComp?.contextTypeForm?.patchValue({ type: 'none' }, { emitEvent: false });
            this.noteContext = { task_id: context.task.id };
        }
        this.cd.markForCheck();
    }
    public submit() {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                ...this.form.value,
                ...{
                    sideNavType: this.navData.data.sideNavType,
                },
                ...(this.navData.data.hasContactType ? { contact_type: this.form.value.contact_type } : {}),
                ...(!!this.navData.data.noteContextOptions?.length ? this.noteContext : {}),
            },
        });
        this.clear();
    }
    public clear() {
        this.form.patchValue({ media_ids: [], body: '' });
        this.buttonDisabled = true;
        this.cd.markForCheck();
    }
}
