import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-tests-results-header',
    templateUrl: './tests-results-header.component.html',
    styleUrls: ['./tests-results-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestsResultsHeaderComponent implements OnInit {
    @Input() public resource: Resource;
    constructor() {}

    ngOnInit(): void {
    }
}
