import {animate, style, transition, trigger} from '@angular/animations';

export const ExpandAnimation = trigger('expand', [
    transition(':enter', [
        style({height: '0px', minHeight: '0px', overflow: 'hidden'}),
        animate('375ms cubic-bezier(0.4,0.0,0.2,1)', style({height: '*', minHeight: '*', overflow: 'hidden'})),
    ]),
    transition(':leave', [
        style({height: '*', minHeight: '*', overflow: 'hidden'}),
        animate('375ms cubic-bezier(0.4,0.0,0.2,1)', style({height: '0px', minHeight: '*', overflow: 'hidden'})),
    ]),
]);


export const HorizontalExpandAnimation = trigger('horizontalExpand', [
    transition(':enter', [
        style({width: '0px'}),
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)', style({width: '*'})),
    ]),
    transition(':leave', [
        style({width: '*'}),
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)', style({width: '0px'})),
    ]),
]);
