<form class="data-wrap" fxLayout="column" [formGroup]="form">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>{{ navData.data.icon }}</mat-icon
        ><span>{{ navData.data.title }}</span>
    </div>

    <div class="desc">{{ navData.data.description }}</div>

    <mat-form-field class="mb-0" floatLabel="never" appearance="fill" fxFlex="0 0 100%">
        <textarea
            maxlength="5000"
            rows="5"
            [placeholder]="navData.data.placeholder"
            #message
            matInput
            formControlName="body"
        ></textarea>
    </mat-form-field>
    <div *ngIf="navData.data.hasContactType" class="contact-type">
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>Contact Type</span>
            <mat-form-field class="role-field">
                <mat-label i18n>Select contact type</mat-label>
                <mat-select fxFlex="1 1 100%" formControlName="contact_type">
                    <mat-option
                        [ngClass]="{ 'next-border': contactType.class }"
                        *ngFor="let contactType of customerContactTypeOptions"
                        [value]="contactType.value"
                        i18n
                        >{{ contactType.title }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="!!navData.data.noteVisibilityOptions?.length" class="mt-1">
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>Visibility</span>
            <mat-form-field class="role-field">
                <mat-label i18n>Select note visibility</mat-label>
                <mat-select fxFlex="1 1 100%" formControlName="visibility">
                    <mat-option
                        *ngFor="let visibilityScope of navData.data.noteVisibilityOptions"
                        [value]="visibilityScope"
                        class="capitalize-option"
                        >{{ visibilityScope }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div *ngIf="this.navData.data.noteContextOptions?.length" class="mt-1">
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>Context</span>
            <app-notes-context
                #notesContext
                [noteContextOptions]="this.navData.data.noteContextOptions"
                (onNotesContextUpdate)="noteContext = $event"
            ></app-notes-context>
        </div>
    </div>
    <ng-container formGroupName="staff">
        <app-user-chip-select
            class="mt-1"
            titleLabel="Signature"
            i18n-titleLabel
            [searchUsersParams]="{ limit: 20, userRole: 'superadmin' }"
            [defaultMe]="true"
            formControlName="manager"
        ></app-user-chip-select>
    </ng-container>
    <div fxLayoutGap="4px" fxLayout="column" class="w-100 mt-1 input-field-parent deadline">
        <span i18n>Date</span>
        <mat-form-field
            appearance="outline"
            floatLabel="never"
            class="w-100"
            fxFlex="grow"
            fxFlex="1 1 100%"
            fxFlex.lt-md="1 1 100%"
        >
            <mat-label i18n>Enter date</mat-label>
            <input
                matInput
                [matDatepicker]="deadlineDatepicker"
                (focus)="deadlineDatepicker.open()"
                formControlName="created_at"
            />
            <mat-datepicker-toggle matIconSuffix [for]="deadlineDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #deadlineDatepicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div fxLayoutGap="4px" fxLayout="column" class="w-100 mt-1 input-field-parent k3-crm-tags">
        <span i18n>Tags</span>
        <app-tag-chip-select
            class="w-100"
            placeholder="Add Tag"
            [language_id]="currentLaguageId$ | async"
            i18n-placeholder
            formControlName="tag_ids"
        ></app-tag-chip-select>
    </div>
    <div *ngIf="navData.data.hasMediaUploader" class="alert-media-attach">
        <app-media-attachment
            #uploader
            [maxImgCount]="10"
            formControlName="media_ids"
            [scope]="navData.data.scope"
            [mediaIds]="form.get('media_ids').value"
            [isCreateForm]="true"
            (onUploadOrRemoveCompleted)="buttonDisabled = false"
            type="file"
        ></app-media-attachment>
        <div fxLayoutAlign="center center" *ngIf="uploader.saving | async">
            <app-kd-logo-loading></app-kd-logo-loading>
        </div>
    </div>
    <div class="action">
        <button
            [disabled]="!form.valid || buttonDisabled"
            fxFlex="0 0 auto"
            class="mobile-button"
            (click)="submit()"
            mat-raised-button
            type="button"
        >
            <span>{{ navData.data.btnLabel }}</span>
        </button>
    </div>
</form>
