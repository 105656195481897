<div fxFlex="0 0 100%" fxLayout="row wrap">
    <app-sticky-header
        class="sticky-header tag-related"
        [title]="tag.name"
        context="Tag"
        contextIcon="tag"
        savingTitle="Done"
        (onSubmit)="bottomSheetRef.dismiss()"
    ></app-sticky-header>
    <div class="table-wrap" fxLayout="column">
        <app-table-filter-expansion-panel class="task-expansion" #filterExpansion [hasTopBorder]="false">
            <app-table-filter [filtersTogglesInOnBtn]="true" [inGlobalFilter]="true" color="accent" #filter>
                <app-table-search
                    toggleBtnLabel="Task"
                    i18n-toggleBtnLabel
                    [inGlobalFilter]="true"
                    class="align-space"
                    searchFilterRef
                ></app-table-search>
                <app-select-filter
                    [inGlobalFilter]="true"
                    class="filter-space"
                    label="Type"
                    i18n-label
                    [property]="{
                        name: GlobalFilterProperty.SCOPE,
                        type: GlobalFilterPropertyType.RESOURCE_TYPE
                    }"
                    [options]="mainScopes"
                    scopesFilterRef
                ></app-select-filter>
            </app-table-filter>
        </app-table-filter-expansion-panel>
        <mat-progress-bar
            class="progress-table-loader mt-1"
            *ngIf="(dataSource.status | async) === 'loading'"
            mode="indeterminate"
        ></mat-progress-bar>

        <mat-table
            #table
            class="mt-1 todos-table"
            [dataSource]="dataSource"
            matSortActive="updated_at"
            matSortDirection="asc"
            matSort
        >
            <ng-container matColumnDef="label">
                <mat-header-cell mat-sort-header *matHeaderCellDef>
                    <div fxLayoutAlign="start center">
                        <span i18n>Title</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <span class="mobile-label" i18n>Title:</span>
                    <span class="data-label">{{ data.label }}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="scope_id">
                <mat-header-cell mat-sort-header *matHeaderCellDef>
                    <div fxLayoutAlign="start center">
                        <span i18n>Type</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <span class="mobile-label" i18n>type:</span>
                    <div
                        *ngIf="AppScope.getScopeOptionByScopeId(data.scope_id) as scope"
                        fxLayoutAlign="start center"
                        fxLayoutGap="5px"
                    >
                        <mat-icon
                            *ngIf="scope.isMainResource"
                            fontSet="klickdata"
                            [fontIcon]="scope.icon"
                            class="kd-icon-24"
                        ></mat-icon>
                        <mat-icon *ngIf="!scope.isMainResource">{{ scope.icon }}</mat-icon>
                        <span>{{ scope.title }}</span>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="updated_at">
                <mat-header-cell mat-sort-header *matHeaderCellDef>
                    <div fxLayoutAlign="start center">
                        <span i18n>Last updated</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <span class="mobile-label" i18n>Last updated:</span>
                    <span class="task-mobile-type">{{ data.updated_at | kdDateFormat : 'YYYY-MM-DD' }}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tools">
                <mat-header-cell mat-sort-header *matHeaderCellDef>
                    <div fxLayoutAlign="start center">
                        <span i18n>View</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let data">
                    <span class="mobile-label" i18n>Tools:</span>
                    <button (click)="view(data)" matTooltip="View" mat-icon-button class="expand-btn">
                        <mat-icon>visibility</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let data; columns: columns" class="example-element-row" (click)="view(data)"></mat-row>
        </mat-table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"> </mat-paginator>
    </div>
</div>
