<form #formDirective="ngForm" fxLayout="column" [formGroup]="materialForm">
    <p fxHide.lt-md i18n class="mt-1 third-column-title">Save options</p>
    <button
        [ngClass]="{ draft: !materialForm.value.publish }"
        (click)="onSubmit()"
        mat-raised-button
        class="save-btn"
    >
        <mat-icon>save</mat-icon>
        <span *ngIf="materialForm.value.publish">Save Material</span>
        <span *ngIf="!materialForm.value.publish">Save Material as a draft</span>
    </button>
    <div fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-checkbox #pulishCheckbox formControlName="publish" color="primary" class="draft-checkbox">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon class="option">published_with_changes</mat-icon>
                <span i18n> Publish the material in academy</span>
            </div></mat-checkbox
        >
        <button
            matTooltip="Specify which learners and groups who will have access and Publish"
            i18n-matTooltip
            mat-icon-button
            (click)="specify()"
        >
            <mat-icon>manage_accounts</mat-icon>
        </button>
    </div>
    <mat-checkbox formControlName="public" color="primary" class="draft-checkbox">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon class="option">public</mat-icon>
            <span i18n> Make material public in KLMS</span>
        </div></mat-checkbox
    >
    <div class="lang-select-profile mt-1">
        <span i18n="@@displayLanguage">Display Language</span>
        <app-language-selector
            formControlName="language_id"
            [showLabel]="true"
            [disableRipple]="true"
        ></app-language-selector>
    </div>
    <app-tag-chip-select
        class="mt-1"
        placeholder="Add Material Tag"
        i18n-placeholder
        [language_id]="materialForm.value.language_id"
        formControlName="tag_ids"
    ></app-tag-chip-select>
    <app-category-chip-select
        fxFlex="0 1 100%"
        [resource_id]="resource?.id"
        [language_id]="materialForm.get('language_id').value"
        formControlName="category_ids"
        [type_id]="ResourceTypes.MATERIAL"
    ></app-category-chip-select>
    <div class="third-colmn-field-wrapper">
        <mat-form-field>
            <input
                matInput
                fxLayoutAlign="end center"
                type="number"
                placeholder="Please set priority"
                i18n-placeholder="@@plsSetPriority"
                formControlName="priority"
            />
        </mat-form-field>
        <mat-icon
            class="third-colmn-editor-title-icon"
            matTooltip="Prio will determine the order that it will be displayed in sections"
            i18n-matTooltip
            >question_mark</mat-icon
        >
    </div>
</form>
