import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Customer, CustomerService } from '@klickdata/core/customer';
import { FormHelper } from '@klickdata/core/form';
import { LanguageService } from '@klickdata/core/localization';
import { MessageFormErrorComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { AppScope, ResourceService } from '@klickdata/core/resource';
import { Educator } from '@klickdata/core/resource/src/resource.model';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';
import { User, UserData, UserService } from '@klickdata/core/user';
import { Observable, Subject, debounceTime, distinctUntilChanged, filter, map, of, switchMap, takeUntil } from 'rxjs';

@Component({
    selector: 'app-menu-side-add-educator',
    templateUrl: './menu-side-add-educator.component.html',
    styleUrls: ['./menu-side-add-educator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddEducatorComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public educatorForm: FormGroup;
    AppScope = AppScope;
    public customerSearch$: Observable<Customer[]>;
    public customerRecipient: Customer;
    @ViewChild('customerRecieverInput') customerRecieverInput: ElementRef<HTMLInputElement>;
    public customerCtrl = new FormControl();
    public destroy: Subject<boolean> = new Subject<boolean>();
    public educator: Educator;
    public currentLaguageId$: Observable<number>;
    ResourceStaffRoles = ResourceStaffRoles;

    constructor(
        protected fb: FormBuilder,
        protected educatorService: ResourceService,
        protected messageService: MessageService,
        protected userService: UserService,
        protected customerService: CustomerService,
        protected changeRef: ChangeDetectorRef,
        protected langService: LanguageService
    ) {
        this.buildForm();
    }
    ngOnInit(): void {
        if (this.navData.data.role === ResourceStaffRoles.CONTACT) {
            this.educatorForm.addControl('customer_id', new FormControl());
            this.educatorForm.addControl('contact_date', new FormControl(''));
            this.educatorForm.addControl('tag_ids', new FormControl([]));
        }
        if (this.navData.data.educator) {
            this.educator = this.navData.data.educator;
            this.updateData();
        }
        this.currentLaguageId$ = this.langService
            .getLanguageByKey(this.langService.getCurrentLanguage().value)
            .pipe(map((lang) => lang.id));
    }
    buildForm() {
        this.educatorForm = this.fb.group({
            name: ['', Validators.required],
            title: [''],
            media_id: [],
            about: [''],
            phone: [''],
            email: ['', Validators.email],
        });
    }
    private updateData() {
        this.educatorForm.patchValue({
            name: this.educator.name,
            title: this.educator.title,
            media_id: this.educator.media_id,
            about: this.educator.about,
            phone: this.educator.phone,
            email: this.educator.email,
        });
        if (this.educator.customer) {
            this.customerRecipient = this.educator.customer;
        }
        FormHelper.resetForm(this.educatorForm);
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.navData.data.educator) {
            this.educator = this.navData.data.educator;
            this.updateData();
        }
    }
    ngAfterViewInit(): void {
        this.customerSearch$ = this.customerCtrl.valueChanges
            .pipe(
                filter((term: string) => typeof term === 'string' && term != ''),
                debounceTime(300),
                distinctUntilChanged()
            )
            .pipe(
                switchMap((term) => (term.trim() ? this.customerService.getCustomers(term, 20) : of(<Customer[]>[]))),
                takeUntil(this.destroy)
            );
    }
    public selected(auto: MatAutocomplete, event: MatAutocompleteSelectedEvent, isCutomer: boolean): void {
        this.customerRecipient = event.option.value;
        this.customerRecieverInput.nativeElement.value = '';
        this.customerCtrl.setValue('');
        auto.options.reset([]);
        this.educatorForm.get('customer_id').setValue(this.customerRecipient.id);
        this.changeRef.markForCheck();
    }
    public removeCustomer(): void {
        this.customerRecipient = null;
        this.educatorForm.get('customer_id').setValue(null);
    }

    public submit() {
        if (this.educatorForm.invalid) {
            FormHelper.markForm(this.educatorForm);
            return;
        }
        this.createOrUpdate().subscribe((educator) => {
            educator.customer = this.customerRecipient;
            this.emitEducator(educator);
            this.educatorForm.reset();
        });
    }
    emitEducator(educator) {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: { value: educator, type: this.navData.data.type },
        });
    }
    createOrUpdate(): Observable<Educator> {
        return this.educator
            ? this.userService.update(<UserData>this.prepareData(), ['task']).pipe(map((user) => user.userAsEducator))
            : this.educatorService.createNewResEducator(this.prepareData(), ['task']);
    }

    prepareData(): Educator {
        if (!this.educatorForm.valid) {
            this.messageService.openMessage(MessageFormErrorComponent);
            return;
        }
        const data = this.educatorForm.value;
        if (this.educator) {
            data.id = this.educator.id;
        }
        if (this.navData.data.resource_id) {
            data.resource_id = this.navData.data.resource_id;
        }
        if (!this.educatorForm.value.customer_id) {
            delete data.customer_id;
        }
        data.role = this.navData.data.role;
        return data;
    }

    compareFn(r1: any, r2: any): boolean {
        return r1 && r2 && r1.id === r2.id;
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
