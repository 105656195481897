<div *ngIf="!hidden" class="chip-container" fxLayout="column">
    <label class="filter-label mat-caption">
        {{ title }}
    </label>
    <mat-chip-list [ngClass]="{ 'mobile-view': isMobile | async }" [multiple]="multiple">
        <ng-container *ngFor="let item of source">
            <mat-chip class="chip-point" [value]="item.id" [selected]="item.selected">
                {{ item.label }}
            </mat-chip>
        </ng-container>
    </mat-chip-list>
</div>

<ng-template #activeFilterTemplate let-filter="filter">
    <mat-icon>{{ filter.icon }}</mat-icon>
    <span *ngFor="let item of filter.chips; let last = last">{{ item.label }}<span *ngIf="!last">,&nbsp;</span> </span>
</ng-template>
