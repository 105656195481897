import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { Alternative, AlternativeData } from '@klickdata/core/question/src/alternative/alternative';
import { map } from 'rxjs/operators';

@Injectable()
export class AlternativeService {
    protected resourceUrl: string;

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected mediaService: MediaService
    ) {
        this.resourceUrl = `${this.config.config.resourceUrl}alternatives`;
    }

    public getAlternatives(id: number): Observable<Alternative[]> {
        return this.builder
            .get<AlternativeData[]>(this.resourceUrl)
            .param('question', `${id}`)
            .param('sort', 'alternative')
            .request()
            .pipe(map(res => this.mapAlternatives(res.data)));
    }

    protected createAlternative(data: AlternativeData): Alternative {
        const alternative = new Alternative(data);
        if (alternative.media_id) {
            alternative.media$ = this.mediaService.getMedia(alternative.media_id);
        }
        return alternative;
    }

    protected mapAlternatives(data: AlternativeData[]): Alternative[] {
        return data.map(item => this.createAlternative(item));
    }
}
