import { AccessCapability } from '../can-access.directive';

export namespace CustomMaterialCapability {
    export const NONE = 0;
    export const VIEW_CORONAVIRUS_TUTORIALS = 1;

    export function getName(): string {
        return 'coronaVirus';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
