import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Grade, GradeData } from '@klickdata/core/grade-system';
import { RequestBuilderService } from '@klickdata/core/http';
import { ConfigService } from '@klickdata/core/config';
import { AuthService } from '@klickdata/core/auth';

@Injectable({
    providedIn: 'root',
})
export class GradeService implements OnInit {
    protected api: string;
    protected customer_id: Observable<number>;

    constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected config: ConfigService
    ) {
        this.api = `${this.config.config.apiUrl}grades`;
    }

    protected static makeGrade(data: GradeData): Grade {
        return new Grade(data);
    }

    public ngOnInit(): void {
        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map(customer => customer.id)
        );
    }

    public getGrades(ids?: number[]): Observable<Grade[]> {
        const builder = this.builder.get<GradeData[]>(this.api);

        if (ids) {
            builder.param('ids', ids.join(','));
            builder.limit(0);
        } else {
            builder.param('customer', this.customer_id);
        }

        return builder.request().pipe(map(res => this.mapGrades(res.data)));
    }

    public getGradeSystemGrades(gradeSystemId: number): Observable<Grade[]> {
        return this.builder
            .get<GradeData[]>(this.api)
            .param('gradeSystem', gradeSystemId)
            .request()
            .pipe(map(res => this.mapGrades(res.data)));
    }

    public create(grade: GradeData): Observable<Grade> {
        return this.builder
            .post<GradeData>(this.api, grade)
            .request()
            .pipe(map(res => GradeService.makeGrade(res.data)));
    }

    public update(grade: GradeData): Observable<Grade> {
        return this.builder
            .put<GradeData>(`${this.api}/${grade.id}`, grade)
            .request()
            .pipe(map(res => GradeService.makeGrade(res.data)));
    }

    public destroy(grade: GradeData): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.api}/${grade.id}`)
            .request()
            .pipe(map(res => res.data));
    }

    protected mapGrades(data: GradeData[]): Grade[] {
        return data.map(item => GradeService.makeGrade(item));
    }
}
