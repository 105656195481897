<mat-tab-group mat-stretch-tabs ngClass.xs="xs">
    <mat-tab>
        <ng-template mat-tab-label i18n> Library </ng-template>
        <ng-template matTabContent>
            <app-resource-library
                [selection]="selection"
                [type_scope_id]="AppScope.SURVEY"
                (select)="add.emit($event)"
            ></app-resource-library>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label i18n> My surveys </ng-template>
        <ng-template matTabContent>
            <app-resource-library
                [selection]="selection"
                [type_scope_id]="AppScope.SURVEY"
                context="author"
                (select)="add.emit($event)"
            ></app-resource-library>
        </ng-template>
    </mat-tab>
    <mat-tab *ngIf="isAdmin$ | async">
        <ng-template mat-tab-label i18n> Create new survey </ng-template>
        <app-create-survey [publishOnly]="true" (published)="add.emit($event.res)"></app-create-survey>
    </mat-tab>
</mat-tab-group>
