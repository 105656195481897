import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Injector, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TagChipSelectComponent } from '../tag-chip-select/tag-chip-select.component';
import { AuthService } from '@klickdata/core/auth';
import { HttpErrorService } from '@klickdata/core/http';
import { MessageService } from '@klickdata/core/message';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { ConfigService } from '@klickdata/core/config';
import { LanguageService } from '@klickdata/core/localization';
import { ResourceTag } from '@klickdata/core/resource';

@Component({
    selector: 'app-tag-item-chip-select',
    templateUrl: './tag-item-chip-select.component.html',
    styleUrls: ['./tag-item-chip-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagItemChipSelectComponent),
            multi: true,
        },
    ],
})
export class TagItemChipSelectComponent extends TagChipSelectComponent implements OnInit, ControlValueAccessor {
    constructor(
        protected tagService: TagService,
        protected cdRef: ChangeDetectorRef,
        protected config: ConfigService,
        protected auth: AuthService,
        protected errorService: HttpErrorService,
        protected injector: Injector,
        protected messageService: MessageService,
        protected languageService: LanguageService
    ) {
        super(tagService, cdRef, config, auth, errorService, injector, messageService, languageService);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }
    writeValue(tags: ResourceTag[]): void {
        if (!tags?.length || tags === null) {
            this.selection.length = 0;
            this.tagCtrl.reset();
        } else {
            this.initSelection(this.sortedAlph ? this.sortTagsAlph(tags) : tags);
        }
    }
}
