import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { AbstractFormGroup, FormGeneratorService, FormHelper } from '@klickdata/core/form';
import { combineLatest, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { EditorTemplate } from '@klickdata/shared-components/src/text-editor/editor-template.model';
import { EditorTemplateService } from '@klickdata/shared-components/src/text-editor/editor-template.service';

@Injectable()
export class EditForm extends AbstractFormGroup<EditorTemplate> {
    constructor(
        protected auth: AuthService,
        protected generator: FormGeneratorService,
        protected templateService: EditorTemplateService
    ) {
        super(EditorTemplate, generator);
    }

    public import(data: EditorTemplate): void {
        this._model = new EditorTemplate(data);
        this.form.reset(this._model);

        FormHelper.resetForm(this.form);
    }

    public create(data = {}): Observable<EditorTemplate> {
        this._model = new EditorTemplate({
            ...this.model.getData(),
            ...data,
        });
        this.import(this._model);

        return combineLatest(this.auth.getCustomer(), this.auth.getUser()).pipe(
            first(),
            map(([customer, user]) => {
                this._model.customer_id = customer.id;
                this._model.author_id = user.id;
                this.form.patchValue(
                    {
                        customer_id: customer.id,
                        author_id: user.id,
                    },
                    { emitEvent: false }
                );
                return this._model;
            })
        );
    }

    public submit(): Observable<EditorTemplate> {
        this.commit();

        if (!!this.model.id) {
            return this.templateService.update(this.model);
        } else {
            return this.templateService.create(this.model);
        }
    }

    public submitNew(template: EditorTemplate): Observable<EditorTemplate> {
        this.commit();
        if (template.title === this.model.title) {
            this.model.title = this.model.title.replace(
                /_([12]\d{3}_(0[1-9]|1[0-2])_(0[1-9]|[12]\d|3[01]))_[12]\d{5}/g,
                ''
            );
            this.model.title += new DatePipe('en').transform(Date.now(), '_yyyy_MM_dd_HHmmss');
        }
        return this.templateService.create(this.model);
    }
}
