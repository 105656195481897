<app-sticky-header
    class="sticky-header"
    [title]="title"
    (onSubmit)="download()"
    [savingTitle]="savingTitle"
    [savingStatus]="downloading"
    (onCancel)="onClose()"
></app-sticky-header>

<h1 matDialogTitle i18n></h1>
<div class="download-pdf-dialog-content" mat-dialog-content>
    <section class="section-download" [formGroup]="form">
        <h4 i18n>Please select following:</h4>
        <form [formGroup]="form.get('downloadOptionsForm') | cast">
            <div *ngFor="let option of downloads.data">
                <p>
                    <mat-checkbox [formControlName]="option.value"
                        ><mat-icon>save_alt</mat-icon> <span class="btn-label">{{ option.label }}</span></mat-checkbox
                    >
                </p>
            </div>
        </form>
    </section>
</div>
