import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SideNaveDataTypes } from '@klickdata/core/mobile';
import { ResourceOpportunity } from '@klickdata/core/opportunity';
import { Resource } from '@klickdata/core/resource';
import { ResourceItem, ResourceItemTypes } from '@klickdata/core/resource-item';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { Observable, Subject, filter, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { ExitService } from './core/exit/exit.service';
import { OccasionService } from './core/occasion/occasion.service';
import { PlayerService } from './core/player/player.service';
export interface CourseItemsActionsOnNext {
    label: string;
    value: TakeResActionValue;
    class: string;
    customBtn: boolean;
    specs?: any;
}
export enum TakeResActionValue {
    CANCEL,
    CONTINUE_LATER,
    CONTINUE,
    GO_TO_NEXT_MANDATORY,
    SIGNOFF,
    SIGNOFF_REQUIRED,
    GO_BACK,
}
/**
 * 1- When click next on TSM and the next item is mandatory (Go to next, continue later, cancel) > FIXED
 * 2- When click next on TSM and the next item is optional (Continue, Go to next mandatory, cancel)
 * 3- When click next on TSM and all mandatory items are done and there are some optionals remaining (Continue, continue later, cancel, signoff with text)
 * 4- When click next on TSM and all items are done (Continue later, cancel, signoff)
 * 5- When you land and all remaining are optional (Continue, continue later, cancel, signoff with text)
 * 6- When you land and all items are done (He didn't done the course yet but done all items) (Continue later, cancel, signoff)
 *
 * Material cases inside a course
 * A- When show popup of done with this part > FIXED
 * B- When show popup of continue video or start from begining > FIXED
 * C- When text or link material to show slide-in > FIXED,
 *
 *
 * Material cases OUTSIDE a course
 * A- When show popup of done with this part > FIXED
 * B- When show popup of continue video or start from begining > FIXED
 * C- When text or link material to show slide-in > FIXED
 *
 */
@Injectable()
export class NextStepService extends OnDestroyHandler {
    private items: ResourceItem[];
    private currentIndex: number;
    private currentItemTypeValue: string;
    private course: Resource;
    private actions: CourseItemsActionsOnNext[];
    private slideInDesc: string;
    private onUserAction: Subject<TakeResActionValue> = new Subject<TakeResActionValue>();
    constructor(
        protected exitService: ExitService,
        protected playerService: PlayerService,
        protected occasionService: OccasionService,
        protected router: Router
    ) {
        super();
    }
    public setUserAction(action: TakeResActionValue) {
        this.onUserAction.next(action);
    }
    public getUserAction(): Observable<TakeResActionValue> {
        return this.onUserAction.asObservable();
    }
    
    updateItemsAndIndex(items: ResourceItem[], index: number, resource: Resource) {
        this.items = items;
        this.currentIndex = index;
        this.course = resource;
        this.updateActions();
    }

    updateActions() {
        this.currentItemTypeValue = this.items[this.currentIndex]?.item_type_value;
        this.actions = [
            { label: $localize`Continue`, value: TakeResActionValue.CONTINUE, class: 'pos-btn', customBtn: false },
            {
                label: $localize`Continue later`,
                value: TakeResActionValue.CONTINUE_LATER,
                class: 'neg-btn',
                customBtn: false,
            },
            { label: $localize`close`, value: TakeResActionValue.CANCEL, class: 'null-btn', customBtn: false },
        ];
        this.slideInDesc = $localize`Well done you have finished this task of the course! How would you like to proceed?`;

        if (this.canJumpToNextMandatory()) {
            this.actions.unshift({
                label: $localize`Go to next mandatory`,
                value: TakeResActionValue.GO_TO_NEXT_MANDATORY,
                class: 'neg-btn',
                customBtn: false,
            });
        }

        // For adding signoff --> 1- All mandatory are done,
        if (
            this.items.filter((item) => item.mandatory).every((item) => item.child_opportunity_done) ||
            this.items.length == 1 ||
            this.isLastMandatoryItem()
        ) {
            const isNotCompletedOptionals = !!this.items.find(
                (item) => !item.child_opportunity_done && !item.mandatory
            );
            this.actions.push({
                label: $localize`Signoff`,
                value: this.course.collaboration?.signoff_required
                    ? TakeResActionValue.SIGNOFF_REQUIRED
                    : TakeResActionValue.SIGNOFF,
                class: 'sign-btn',
                customBtn: true,
                specs: {
                    desc: this.course.collaboration?.signoff_required
                        ? $localize`Now you have completed all ${
                              isNotCompletedOptionals ? 'mandatory' : ''
                          } parts, You can signoff directly. The admin will need to approve in order for you to download your PDF of ${
                              this.course?.examination?.label
                          }. Check status in the Activity overview. You can send a message to the admin below`
                        : $localize`Now you have completed all ${
                              isNotCompletedOptionals ? 'mandatory' : ''
                          } parts, You can signoff directly and you will be able to get a ${
                              this.course?.examination?.label
                          }. In case you want to go back; You need to restart the course again since you are Signing off.`,
                    hasFeedback: this.course.collaboration?.signoff_required,
                },
            });
        }
    }
    private isLastMandatoryItem(): boolean {
        return (
            this.items.reduceRight((lastIndex, item, index) => {
                return item.mandatory && lastIndex === -1 ? index : lastIndex;
            }, -1) == this.currentIndex
        );
    }
    private isAllItemsAreDone(): boolean {
        return !!this.items && this.items.every((item) => item.child_opportunity_done);
    }
    private isLastItem(): boolean {
        return !!this.items && this.currentIndex == this.items.length - 1;
    }
    private showCustomBtnAfterClickDone(): boolean {
        return !!this.items && this.isLastMandatoryItem() && !this.isAllItemsAreDone();
    }
    private canJumpToNextMandatory(): boolean {
        // For adding 'Go to next mandatory' --> 1-next item is optional, 2- there still mandatory after current index
        return (
            this.items[this.currentIndex + 1] &&
            !this.items[this.currentIndex + 1]?.mandatory && // 1-next item is optional
            !!this.items.find((item) => item.mandatory && item.index - 1 > this.currentIndex) // item.index is not zero based
        );
    }
    onNext(
        type: string,
        childOccasion$: Observable<ResourceOpportunity>,
        customSpecs?: {
            actions?: CourseItemsActionsOnNext[];
            desc?: string;
            title?: string;
        }
    ): Observable<{
        oppo: ResourceOpportunity;
        action: TakeResActionValue;
    }> {
        if (this.isAllItemsAreDone() || this.isLastItem()) {
            this.playerService.updateSliderData({
                dataType: SideNaveDataTypes.COURSE_ITEM_DONE_ALL,
                data: {
                    type: type,
                    course: this.course,
                    isLastItem: this.isLastItem(),
                    customDesc: customSpecs?.desc,
                },
            });
        } else {
            this.playerService.updateSliderData({
                dataType:
                    this.currentItemTypeValue == ResourceItemTypes.MATERIAL
                        ? SideNaveDataTypes.COURSE_MATERIAL_ITEM_DONE
                        : SideNaveDataTypes.COURSE_ITEM_DONE,
                data: {
                    type: type,
                    title:
                        customSpecs?.title ?? this.currentItemTypeValue == ResourceItemTypes.MATERIAL
                            ? $localize`Are you done?`
                            : null,
                    actions: customSpecs?.actions ?? this.actions,
                    desc: customSpecs?.desc ?? this.slideInDesc,
                    showCustomBtnAfterClickDone: this.showCustomBtnAfterClickDone(),
                },
            });
        }

        return this.playerService.getSideNavResponseData().pipe(
            takeUntil(this.destroy),
            filter((data) => data.type == type),
            switchMap((data) =>
                childOccasion$.pipe(
                    switchMap((opp) =>
                        data.response != TakeResActionValue.CONTINUE_LATER ? this.occasionService.done(opp) : of(opp)
                    ),
                    tap((opp) =>
                        data.response != TakeResActionValue.CONTINUE_LATER &&
                        data.response != TakeResActionValue.GO_TO_NEXT_MANDATORY
                            ? this.occasionService.updateLastestDoneOpportunity(opp)
                            : null
                    ),
                    tap(() => {
                        if (
                            data.response == TakeResActionValue.SIGNOFF_REQUIRED ||
                            data.response == TakeResActionValue.SIGNOFF
                        ) {
                            this.playerService.setSignOff({ didSignoff: true, signoff: data.signoff });
                        }
                        this.setUserAction(data.response);
                    }),
                    map((opp) => ({ oppo: opp, action: data.response }))
                )
            )
        );
    }
    public onExit(): void {
        this.exitService.exit();
    }
}
