import { Injectable } from '@angular/core';
import { LoggerService } from '@klickdata/core/application';
import { AuthService, CapabilityService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { CustomerService } from '@klickdata/core/customer';
import { GradeSystemService } from '@klickdata/core/grade-system';
import { PaginatorResponse, RequestBuilderService, ResponseData } from '@klickdata/core/http';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { AppScope, Resource, ResourceData, ResourceService, ResourceTypeService } from '@klickdata/core/resource';
import { ResourceItemService } from '@klickdata/core/resource-item';
import { Filter, TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { UserService } from '@klickdata/core/user';
import { WidgetsService } from '@klickdata/core/widget/src/widget.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LogUsersResourcesService extends ResourceService implements TableHttpService<Resource> {
    constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected typeService: ResourceTypeService,
        protected gradeSystemService: GradeSystemService,
        protected logger: LoggerService,
        protected userService: UserService,
        protected customerService: CustomerService,
        protected itemService: ResourceItemService,
        protected capabilityService: CapabilityService,
        protected mediaService: MediaService,
        protected widgetsService: WidgetsService
    ) {
        super(
            auth,
            builder,
            config,
            typeService,
            gradeSystemService,
            logger,
            userService,
            customerService,
            itemService,
            capabilityService,
            mediaService
        );
    }
    public fetchData(source: TableSource<Resource>): Observable<ResponseData<Resource[]>> {
        const req = this.builder.get<ResourceData[]>(this.resourceLogUrl);

        this.widgetsService.setActiveWidgetsFilters(
            source.filter?.filter?.filters?.length
                ? source.filter.filter.filters.map((filter) => new Filter(filter.property, filter.items))
                : []
        );
        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<ResourceData[]>) => {
                return {
                    data: this.mapResource(res.data),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }
}
