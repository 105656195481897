import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { FormHelper } from '@klickdata/core/form';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import { Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-course-register',
    templateUrl: './menu-side-course-register.component.html',
    styleUrls: ['./menu-side-course-register.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideCourseRegisterComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    @ViewChild('stepper') stepper: MatStepper;
    SideNaveActionsTypes = SideNaveActionsTypes;
    public registrationForm: FormGroup;
    public formats: { [key: string]: string };
    private destroy: Subject<boolean> = new Subject<boolean>();
    ResourceTypes = ResourceTypes;
    public displayedTitle: string;

    constructor(
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected message: MessageService,
        protected cdr: ChangeDetectorRef
    ) {
        this.buildForm();
        this.formats = {
            fname: $localize`First name`,
            lname: $localize`Last name`,
            email: $localize`Email`,
            box: $localize`Your address box`,
        };
    }
    ngOnInit(): void {
        this.displayedTitle = this.navData.data.user ? $localize`Invoice info` : $localize`Invoice info`;
        if (this.navData.data.user) {
            this.registrationForm.get('personalRegForm').patchValue({
                fname: this.navData.data.user.fname,
                lname: this.navData.data.user.lname,
                email: this.navData.data.user.email,
                phone: this.navData.data.user.phone,
            });
        } else {
            (this.registrationForm.get('personalRegForm') as FormGroup).addControl('remember', new FormControl());
            (this.registrationForm.get('personalRegForm') as FormGroup).addControl('add', new FormControl());
        }
        this.resourceService
            .getUserInvoiceInfo(this.navData.data.user.id)
            .pipe(takeUntil(this.destroy))
            .subscribe((data) => this.patchUserInvoiceData(data));
        this.cdr.markForCheck();
    }
    public patchUserInvoiceData(data: any) {
        if (data) {
            const invoiceRegForm = this.registrationForm.get('invoiceRegForm') as FormGroup;
            invoiceRegForm.patchValue({
                business_company: data.business_company,
                department: data.department,
                orgnumber: data.orgnumber,
                person_role: data.person_role,
                purchase_order: data.purchase_order,
                cost_department: data.cost_department,
                campaign_code: data.campaign_code,
            });
        }
    }
    ngAfterViewInit(): void {}
    private buildForm() {
        this.registrationForm = this.fb.group({
            payment_method: ['invoice'],
            policy_agree: ['', [Validators.required]],

            personalRegForm: this.fb.group({
                fname: ['', [Validators.required]],
                lname: ['', [Validators.required]],
                email: ['', [Validators.email, Validators.required]],
                phone: [''],
            }),

            invoiceRegForm: this.fb.group({
                business_company: [''],
                department: [''],
                orgnumber: [''],
                person_role: [''],
                purchase_order: [''],
                cost_department: [''],
                campaign_code: [''],
                address: this.fb.group({
                    box: [''],
                    street: [''],
                    zipcode: [''],
                    city: [''],
                    country: [''],
                    other: [''],
                }),
            }),
        });
    }
    public register() {
        if (this.registrationForm.invalid) {
            this.message.openMessage(
                MessageErrorComponent,
                $localize`${FormHelper.formatInvalidForm(this.registrationForm, this.formats)} is missing`
            );
            return;
        }

        this.resourceService
            .registerResource(this.prepareData())
            .pipe(
                map((regData) => regData.id),
                tap((regId) => localStorage.setItem('registrationId', regId)),
                switchMap((regId) => this.resourceService.bookResource(this.navData.data.resource.id, regId)),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                this.message.openMessage(
                    MessageSavedComponent,
                    $localize`Congratulations, you have registered successfully.`
                );
                this.onClose.emit({ action: SideNaveActionsTypes.POSITIVE, clearCompData: false });
                this.destroy.next(true);
                this.destroy.unsubscribe();
            });
    }
    private prepareData() {
        const data = this.registrationForm.value;
        data.email = this.registrationForm.value.email.trim();
        data.address = this.filterEmptyFields(this.registrationForm.get('address').value);
        return this.filterEmptyFields(data);
    }
    filterEmptyFields(data: any): any {
        let fields = {};
        Object.keys(data).forEach((key) => (data[key] != '' ? (fields[key] = data[key]) : key));

        return fields;
    }
    public readMoreLink() {
        window.open('https://klickdata.se/faq-klms/legal-links-for-klick-data', '_blank');
    }
    public getCurrenySign(value): string {
        return `${Utils.getAllCurrencies().find((curr) => curr.code === value).label} ${
            Utils.getAllCurrencies().find((curr) => curr.code === value).sign
        }`;
    }
    public editStep() {
        this.stepper.selectedIndex = 1;
    }
    public getDisplayedTitle(stepperEvent: StepperSelectionEvent) {
        if (this.navData.data.user) {
            switch (stepperEvent.selectedIndex) {
                case 0:
                    this.displayedTitle = $localize`Invoice info`;
                    break;
                case 1:
                    this.displayedTitle = this.navData.data.resource.price
                        ? $localize`Payment`
                        : $localize`Confirmation`;
                    break;
                case 2:
                    this.displayedTitle = $localize`Confirmation`;
                    break;
            }
        } else {
            switch (stepperEvent.selectedIndex) {
                case 0:
                    this.displayedTitle = $localize`Personal info`;
                    break;
                case 1:
                    this.displayedTitle = $localize`Invoice info`;
                    break;
                case 2:
                    this.displayedTitle = this.navData.data.resource.price
                        ? $localize`Payment`
                        : $localize`Confirmation`;
                    break;
                case 2:
                    this.displayedTitle = $localize`Confirmation`;
                    break;
            }
        }
        this.cdr.markForCheck();
    }
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
