import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import { Group } from '@klickdata/core/group';
import { HttpErrorService, RequestBuilderService } from '@klickdata/core/http';
import { Resource, ResourceTag } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user/src/user.model';
import { UserService } from '@klickdata/core/user/src/user.service';
import { Utils } from '@klickdata/core/util';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HrNotes, HrNotesData } from './hr-notes.model';
import { UserNotes, UserNotesData } from './user-notes.model';

@Injectable({
    providedIn: 'root',
})
export class UserNotesService {
    protected notesUrl: string;
    protected authUser: User;

    constructor(
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected error: HttpErrorService,
        protected userService: UserService
    ) {
        this.notesUrl = `${config.config.apiUrl}notes`;
    }

    public getAllActivities(customerId?: number): Observable<UserNotes[]> {
        const request = this.builder.get<UserNotesData[]>(`${this.notesUrl}`);
        if (customerId) {
            request.param('customer', customerId);
        }
        return request.request().pipe(map((res) => res.data.map((userNotes) => this.mapUserNotes(userNotes))));
    }

    public getAllTaskNotes(taskId: number): Observable<UserNotes[]> {
        const request = this.builder.get<UserNotesData[]>(`${this.notesUrl}`);
        request.param('task', taskId);

        return request.request().pipe(map((res) => res.data.map((userNotes) => this.mapUserNotes(userNotes))));
    }

    public getNotesRelatedToResource(resId: number): Observable<UserNotes[]> {
        const request = this.builder.get<UserNotesData[]>(`${this.notesUrl}`);
        request.param('resource', resId);
        return request.request().pipe(map((res) => res.data.map((userNotes) => this.mapUserNotes(userNotes))));
    }

    public addHrNote(hrNotesData: HrNotesData, eager?: string[]): Observable<HrNotes> {
        return this.builder
            .post<HrNotesData>(`${this.notesUrl}`, hrNotesData)
            .putEager(eager)
            .request()
            .pipe(map((res) => this.mapHrNotes(res.data)));
    }

    public addUserNote(userNotesData: UserNotesData, eager?: string[]): Observable<UserNotes> {
        return this.builder
            .post<UserNotesData>(`${this.notesUrl}`, userNotesData)
            .putEager(eager)
            .request()
            .pipe(map((res) => this.mapUserNotes(res.data)));
    }

    public updateHrNote(hrNotesData: HrNotesData, eager?: string[]): Observable<HrNotes> {
        return this.builder
            .put<HrNotesData>(`${this.notesUrl}/${hrNotesData.id}`, hrNotesData)
            .putEager(eager)
            .request()
            .pipe(map((res) => this.mapHrNotes(res.data)));
    }

    public updateUserNote(userNotesData: UserNotesData, eager?: string[]): Observable<UserNotes> {
        return this.builder
            .put<UserNotesData>(`${this.notesUrl}/${userNotesData.id}`, userNotesData)
            .putEager(eager)
            .request()
            .pipe(map((res) => this.mapUserNotes(res.data)));
    }

    public deleteHrNote(id: number): any {
        return this.builder
            .delete<any>(`${this.notesUrl}/${id}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((err) => this.error.handle(err))
            );
    }

    public deleteUserNote(id: number): any {
        return this.builder
            .delete<any>(`${this.notesUrl}/${id}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((err) => this.error.handle(err))
            );
    }

    protected mapUserNotes(userNotesData: UserNotesData): UserNotes {
        const notes = new UserNotes(userNotesData);
        const privacyList = Utils.getNotesPrivacyOptions();
        if (notes.author) {
            notes.creator = new User(notes.author);
            notes.author$ = this.userService.getAuthor(notes.author.id);
        }
        if (userNotesData.public) {
            notes.privacy = privacyList.find((opt) => opt.value === 'public');
        }
        if (
            userNotesData.users &&
            userNotesData.users.length === 1 &&
            userNotesData.users[0].id === userNotesData.author.id
        ) {
            notes.privacy = privacyList.find((opt) => opt.value === 'private');
        }
        if (userNotesData.users && userNotesData.users.length && userNotesData.users[0].id != userNotesData.author.id) {
            notes.noteUsers = userNotesData.users.map((userData) => new User(userData));
            notes.privacy = privacyList.find((opt) => opt.value === 'user');
        }
        if (userNotesData.groups && userNotesData.groups.length) {
            notes.noteGroups = userNotesData.groups.map((groupData) => new Group(groupData));
            notes.privacy = privacyList.find((opt) => opt.value === 'group');
        }
        if (userNotesData.academy && !notes.privacy) {
            notes.privacy = privacyList.find((opt) => opt.value === 'academy');
        }
        if (userNotesData.resource?.id) {
            notes.noteResource = new Resource(userNotesData.resource);
            notes.noteResource.media$ = of(notes.noteResource.media);
        }
        return notes;
    }

    protected mapHrNotes(data: HrNotesData): HrNotes {
        const item = new HrNotes(data);
        if (data.author) {
            item.creator = new User(data.author);
        }

        item.tags = data.tags?.map((tagData) => new ResourceTag(tagData)) || [];

        return item;
    }
}
