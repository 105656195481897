import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceItem } from '@klickdata/core/resource-item';
import { Question, QuestionService, QuestionTypes } from '@klickdata/core/question';

@Component({
    selector: 'app-resource-item-question',
    templateUrl: './resource-item-question.component.html',
    styleUrls: ['./resource-item-question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceItemQuestionComponent implements OnInit {
    @Input()
    public item: ResourceItem;

    @Input()
    public showPoints = true;

    public question: Observable<Question>;

    public QuestionTypes = QuestionTypes;

    constructor(protected questionService: QuestionService) {}

    ngOnInit() {
        if (this.item && this.item.id) {
            this.question = this.questionService.getQuestionByItem(this.item.id);
        }
    }
}
