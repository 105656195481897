<div fxFlex="1 1 100%" fxLayoutAlign="center" fxLayout="column wrap">
    <form fxLayout="row wrap" [formGroup]="form">
        <div fxFlex="0 0 100%">
            <mat-form-field fxFlex="0 1 100%">
                <input matInput placeholder="Name" i18n-placeholder formControlName="title" required />

                <mat-error i18n>Title field is required!</mat-error>
            </mat-form-field>
            <div fxLayoutAlign="center center" fxFlex="0 0 40px" class="coursePlan-layout">
                <app-language-selector
                    [language_id]="form.get('language_id').value"
                    formControlName="language_id"
                    class="icon-resource-builder mainImg"
                    fxFlex="0 0 auto"
                ></app-language-selector>
            </div>
            <div fxLayoutAlign="center center" fxFlex="0 0 40px" class="coursePlan-layout">
                <app-media
                    #app_media
                    fxFlex="0 0 auto"
                    [simple]="true"
                    *ngIf="!form.get('media_id')?.value"
                    formControlName="media_id"
                    [scope]="appScope"
                    dropLabel="Drop course plan image"
                    i18n-dropLabel="@@coursePlanImageDropzoneLabel"
                ></app-media>
            </div>
        </div>
        <app-image-view
            class="mt-2 mb-2"
            *ngIf="form.get('media_id')?.value"
            fxFlex="0 1 300px"
            [media_id]="form.get('media_id')?.value"
            [proportional]="false"
        >
            <button
                class="remove-image"
                mat-icon-button
                color="warn"
                *ngIf="form.get('media_id').value as mediaId"
                matTooltip="Remove image"
                i18n-matTooltip
                (click)="form.get('media_id').setValue(null)"
            >
                <mat-icon class="md-24">delete_outline</mat-icon>
            </button>
        </app-image-view>
        <div fxFlex="0 1 100%">
            <mat-form-field class="summary-field" fxFlex="0 1 100%">
                <textarea
                    #summary
                    matInput
                    placeholder="Enter a summary (max 256 characters)*"
                    i18n-placeholder
                    minRows="2"
                    [maxLength]="defaultResourceSummaryCount"
                    formControlName="bullets"
                ></textarea>
                <span *ngIf="summary.value" class="char-count">
                    {{ summary.value.length }}
                    <span>(</span>
                    {{ defaultResourceSummaryCount - summary.value.length }}
                    <span i18n="@@left">left</span>
                    <span>)</span>
                </span>
            </mat-form-field>
        </div>
        <div fxLayout="column" *ngIf="resourceType === ResourceTypes.PACKAGE" fxFlex="0 1 100%">
            <mat-form-field fxFlex="0 1 100%">
                <input matInput placeholder="Please set course code" i18n-placeholder formControlName="article_code" />
            </mat-form-field>
            <span *ngIf="form.get('article_code').dirty">
                <p *ngIf="vaildArticleCode | async; let isValid; else: smallLoading">
                    <span class="valid" *ngIf="isValid" i18n="@@courseCodeIsValid">Course code is valid</span>
                    <span class="error" *ngIf="!isValid" i18n="@@courseCodeIsTakenPleaseEnterAnotherCourseCode"
                        >Course code is taken, please enter another course code</span
                    >
                </p>
            </span>
        </div>
        <ng-container *ngIf="resourceType === ResourceTypes.COURSE">
            <div
                class="date-row-wrapper"
                fxFlex="0 0 100%"
                fxLayout="row"
                fxLayout.lt-md="column"
                fxLayoutAlign="space-between center"
                fxLayoutAlign.lt-md="center start"
                fxLayoutGap=".5em"
            >
                <mat-checkbox #time_open formControlName="always_available" color="primary" i18n="@@alwaysAvailable"
                    >Always available
                </mat-checkbox>
                <div
                    class="date"
                    fxFlex="0 0 40%"
                    fxFlex.lt-md="0 0 40%"
                    fxLayoutAlign.gt-md="end center"
                    *ngIf="!time_open.checked"
                >
                    <mat-form-field fxFlex="0 0 90%">
                        <input
                            matInput
                            formControlName="start_date"
                            [matDatepicker]="startDate"
                            placeholder="Available between"
                            i18n-placeholder="@@availableBetween"
                        />
                        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                    </mat-form-field>
                </div>

                <div
                    class="date"
                    fxFlex="0 0 40%"
                    fxFlex.lt-md="0 0 40%"
                    fxLayoutAlign.gt-md="end center"
                    *ngIf="!time_open.checked"
                >
                    <mat-form-field fxFlex="0 0 90%">
                        <input
                            matInput
                            formControlName="end_date"
                            [matDatepicker]="endDate"
                            placeholder="Until"
                            i18n-placeholder="@@until"
                        />
                        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>

            <mat-accordion multi="false" fxFlex="0 1 100%" displayMode="flat">
                <mat-expansion-panel #mainExpansionPanel class="user-manage-course-expantion main-panel">
                    <mat-expansion-panel-header>
                        <mat-icon>scatter_plot</mat-icon>
                        <div [ngClass]="{ 'main-info-label': mainExpansionPanel.expanded }" i18n="@@courseInfo">
                            Course info
                        </div>
                    </mat-expansion-panel-header>

                    <div fxLayout="column" class="main-body-wrapper">
                        <mat-expansion-panel #mainExpansionPanelMain class="user-manage-course-expantion main-panel">
                            <mat-expansion-panel-header>
                                <mat-icon>settings_applications</mat-icon>
                                <div
                                    [ngClass]="{ 'main-info-label': mainExpansionPanelMain.expanded }"
                                    i18n="@@mainInfo"
                                >
                                    Main info
                                </div>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <div class="panel-body-wrapper">
                                    <!-- <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <mat-icon>speaker_notes</mat-icon>
                                            <div i18n="@@summary">
                                                Summary
                                            </div>
                                            <mat-icon class="icon-explain" matTooltip="Set summary" i18n-matTooltip
                                                >help_outline</mat-icon
                                            >
                                        </mat-panel-title>
                                        <mat-panel-description fxLayoutAlign="end center">
                                            <span
                                                matTooltip="Indicator of field change"
                                                i18n-matTooltip
                                                class="cell-indicator"
                                                [ngClass]="{ green: form.get('bullets').dirty }"
                                            ></span>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <ng-template matExpansionPanelContent>
                                        <mat-form-field class="summary-field" fxFlex="0 1 100%">
                                            <textarea
                                                matInput
                                                placeholder="Enter a summary for the course (max 256 characters)."
                                                i18n-placeholder
                                                minRows="3"
                                                formControlName="bullets"
                                            ></textarea>
                                        </mat-form-field>
                                    </ng-template>
                                </mat-expansion-panel> -->
                                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>description</mat-icon>
                                                <div i18n="@@description">Description</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Set course description"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('description').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <!-- <div>
                                        <app-text-editor
                                            fxFlex="1 1 100%"
                                            title="Course Description"
                                            i18n-title
                                            placeholder="Write a description and list what you will learn in this course. Why the learner shall spend time and what will be the benefit of take the Course."
                                            i18n-placeholder
                                            fieldName="course-plan-description"
                                            formControlName="description"
                                        ></app-text-editor>
                                    </div> -->
                                        <ng-template matExpansionPanelContent>
                                            <mat-form-field class="summary-field" fxFlex="0 1 100%">
                                                <textarea
                                                    #desc
                                                    matInput
                                                    placeholder="Write a description and list what you will learn in this course. Why the learner shall spend time and what will be the benefit of take the Course."
                                                    i18n-placeholder
                                                    [maxlength]="defaultCourseGoalCount"
                                                    minRows="3"
                                                    formControlName="description"
                                                ></textarea>
                                                <span *ngIf="desc.value" class="char-count">
                                                    {{ desc.value.length }}
                                                    <span>(</span>
                                                    {{ defaultCourseGoalCount - desc.value.length }}
                                                    <span i18n="@@left">left</span>
                                                    <span>)</span>
                                                </span>
                                            </mat-form-field>
                                        </ng-template>
                                    </mat-expansion-panel>
                                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>task_alt</mat-icon>
                                                <div i18n="@@courseGoal">Course goal</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Set course goal"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('goal').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <mat-form-field class="summary-field" fxFlex="0 1 100%">
                                                <textarea
                                                    #goal
                                                    matInput
                                                    placeholder="Enter a goal for the course"
                                                    i18n-placeholder
                                                    minRows="3"
                                                    [maxlength]="defaultCourseGoalCount"
                                                    formControlName="goal"
                                                ></textarea>
                                                <span *ngIf="goal.value" class="char-count">
                                                    {{ goal.value.length }}
                                                    <span>(</span>
                                                    {{ defaultCourseGoalCount - goal.value.length }}
                                                    <span i18n="@@left">left</span>
                                                    <span>)</span>
                                                </span>
                                            </mat-form-field>
                                        </ng-template>
                                    </mat-expansion-panel>
                                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>subject</mat-icon>
                                                <div i18n="@@courseInstructions">Course instruction</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Set course instruction"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('instructions').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <mat-form-field class="summary-field" fxFlex="0 1 100%">
                                                <textarea
                                                    #instructions
                                                    matInput
                                                    placeholder="Enter instructions for the course"
                                                    i18n-placeholder
                                                    minRows="3"
                                                    [maxlength]="defaultCourseGoalCount"
                                                    formControlName="instructions"
                                                ></textarea>
                                                <span *ngIf="instructions.value" class="char-count">
                                                    {{ instructions.value.length }}
                                                    <span>(</span>
                                                    {{ defaultCourseGoalCount - instructions.value.length }}
                                                    <span i18n="@@left">left</span>
                                                    <span>)</span>
                                                </span>
                                            </mat-form-field>
                                        </ng-template>
                                    </mat-expansion-panel>
                                    <!-- End reward -->
                                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>military_tech</mat-icon>
                                                <div i18n="@@endReward">End reward</div>

                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Set the reward for this Course. When you have a Final Test in the Course; the learner will receive either a Participation Certificate, a Diploma or a Certification."
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: examinationForm.get('type')?.dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div>
                                            <form [formGroup]="examinationForm" fxLayout="column">
                                                <div
                                                    class="mb-1"
                                                    fxLayoutGap.gt-md="2em"
                                                    fxLayoutAlign="start center"
                                                    fxLayout="row"
                                                    fxLayout.lt-md="column"
                                                    fxLayoutAlign.lt-md="center start"
                                                    fxFlex="1 0 100%"
                                                >
                                                    <br fxHide.gt-md fxShow.lt-md />
                                                    <div
                                                        fxLayoutGap.gt-md="5px"
                                                        fxLayoutAlign="start center"
                                                        fxLayout="row"
                                                        fxFlex="1 0 50%"
                                                        fxFlex.lt-md="1 0 100%"
                                                    >
                                                        <span fxFlex="0 0 auto" class="bold" i18n>Final test : </span>
                                                        <mat-select
                                                            fxFlex="0 0 60%"
                                                            fxFlex.lt-md="0 0 auto"
                                                            placeholder="Pls select final test"
                                                            i18n-placeholder
                                                            formControlName="final_test_id"
                                                        >
                                                            <mat-option
                                                                *ngFor="let test of testResourceItems | async"
                                                                [value]="test.child_resource_id"
                                                            >
                                                                {{ test.name }}
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                    <br fxHide.gt-md fxShow.lt-md />
                                                    <div
                                                        fxLayoutGap.gt-md="5px"
                                                        fxLayoutAlign="start center"
                                                        fxLayout="row"
                                                        fxFlex="1 0 50%"
                                                        fxFlex.lt-md="1 0 100%"
                                                    >
                                                        <span fxFlex="0 0 auto" class="bold" i18n>Reward type : </span>
                                                        <mat-select
                                                            fxFlex="0 0 60%"
                                                            fxFlex.lt-md="0 0 100%"
                                                            placeholder="Place choose reward type"
                                                            i18n-placeholder
                                                            formControlName="type"
                                                        >
                                                            <mat-option value="Participation" i18n="@@participation">
                                                                Participation
                                                            </mat-option>
                                                            <mat-option
                                                                *ngIf="itemsHaveTests"
                                                                value="Diploma"
                                                                i18n="@@diploma"
                                                            >
                                                                Diploma
                                                            </mat-option>
                                                            <mat-option
                                                                *ngIf="itemsHaveTests"
                                                                value="Certification"
                                                                i18n="@@certification"
                                                            >
                                                                Certification
                                                            </mat-option>
                                                        </mat-select>
                                                    </div>
                                                </div>

                                                <div
                                                    class="mb-1"
                                                    fxLayoutGap.gt-md="2em"
                                                    fxLayoutAlign="start center"
                                                    fxLayout="row"
                                                    fxLayout.lt-md="column"
                                                    fxFlex="1 0 100%"
                                                    fxLayoutAlign.lt-md="center start"
                                                >
                                                    <div
                                                        fxLayoutGap.gt-md="5px"
                                                        fxLayoutAlign="start center"
                                                        fxLayout="row"
                                                        fxFlex="1 0 20%"
                                                        fxFlex.lt-md="1 0 70%"
                                                        *ngIf="examinationForm.get('type').value === 'Diploma'"
                                                    >
                                                        <span fxFlex="0 0 auto" class="bold">
                                                            <span i18n="@@diplomaLevel">Diploma level</span>
                                                            <mat-icon
                                                                class="icon-reward-explain"
                                                                matTooltip="Diploma; either you pass the level set as a percentage and get a Diploma or you don’t and get a Participation Certificate."
                                                                i18n-matTooltip
                                                                >help_outline</mat-icon
                                                            >:
                                                        </span>

                                                        <mat-form-field fxFlex="0 0 25%" fxFlex.lt-md="0 0 60px">
                                                            <input
                                                                matInput
                                                                type="number"
                                                                min="0"
                                                                max="100"
                                                                formControlName="diploma_level"
                                                            />
                                                            <span class="percent" fxFlex="0 0 auto">%</span>
                                                        </mat-form-field>
                                                    </div>
                                                    <div
                                                        fxLayoutGap.gt-md="5px"
                                                        fxLayoutAlign="start center"
                                                        fxLayout="row"
                                                        fxFlex="1 0 70%"
                                                        fxFlex.lt-md="1 0 100%"
                                                        class="grade-system-wrapper"
                                                        *ngIf="examinationForm.get('type').value !== 'Participation'"
                                                    >
                                                        <span fxFlex="0 0 auto" class="bold" fxHide.lt-md="true">
                                                            <span i18n="@@gradeSystem">Grade system</span>
                                                            <mat-icon
                                                                class="icon-reward-explain"
                                                                matTooltip="The Grade system is used for different levels of reward passing the Final test. You can define your own Grade System or use a template."
                                                                i18n-matTooltip
                                                                >help_outline</mat-icon
                                                            >
                                                            :
                                                        </span>

                                                        <app-admin-grade-system-control
                                                            fxFlex="0 0 70%"
                                                            fxFlex.lt-md="0 0 100%"
                                                            [gradeControl]="
                                                                examinationForm.get('grade_system_id') | cast
                                                            "
                                                            [languageId]="form.get('language_id').value"
                                                        >
                                                        </app-admin-grade-system-control>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </mat-expansion-panel>
                                    <!-- Categories -->
                                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>apps</mat-icon>
                                                <div i18n="@@categories*">Categories</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Categories of the course"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator green"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div>
                                            <app-category-chip-select
                                                fxFlex="0 1 100%"
                                                [resource_id]="resource?.id"
                                                [language_id]="form?.get('language_id').value"
                                                formControlName="category_ids"
                                                [type_id]="ResourceTypes.COURSE"
                                            ></app-category-chip-select>
                                        </div>
                                    </mat-expansion-panel>
                                    <!-- Tags -->
                                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>label</mat-icon>
                                                <div i18n="@@tags">Tags</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Tags of the course"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator green"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div>
                                            <app-tag-chip-select
                                                fxFlex="0 1 100%"
                                                [resource_id]="resource?.id"
                                                [language_id]="form?.get('language_id').value"
                                                formControlName="tag_ids"
                                            ></app-tag-chip-select>
                                        </div>
                                    </mat-expansion-panel>
                                    <!-- Playtime and duration -->
                                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>hourglass_top</mat-icon>
                                                <div i18n="@@playtimeAndDuration">Playtime and duration</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Playtime and duration"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{
                                                        green:
                                                            form.get('time_limit').dirty || form.get('duration').dirty
                                                    }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <div class="mb-2" fxLayout="column" fxFlex="0 1 100%" [fxHide]="false">
                                                <div
                                                    fxFlex="0 0 100%"
                                                    fxLayout="row"
                                                    fxLayout.lt-md="column"
                                                    fxLayoutAlign="space-between"
                                                    class="duration-picker-wrapper"
                                                >
                                                    <div
                                                        fxLayout.lt-md="column"
                                                        fxLayoutAlign="flex-start center"
                                                        fxLayoutAlign.lt-md="flex-start start"
                                                        fxFlex="0 0 48%"
                                                        fxFlex.lt-md="0 0 100%"
                                                    >
                                                        <span class="controller-title">Play time</span>
                                                        <ngx-duration-picker
                                                            formControlName="time_limit"
                                                            [options]="playTimeOptions"
                                                        ></ngx-duration-picker>
                                                    </div>
                                                    <div
                                                        fxLayout.lt-md="column"
                                                        fxLayoutAlign="flex-start center"
                                                        fxLayoutAlign.lt-md="flex-start start"
                                                        fxFlex="0 0 48%"
                                                        fxFlex.lt-md="0 0 100%"
                                                    >
                                                        <span class="controller-title">Course duration</span>
                                                        <ngx-duration-picker
                                                            formControlName="duration"
                                                            [options]="durationOptions"
                                                        ></ngx-duration-picker>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </mat-expansion-panel>
                                    <!-- Other information -->
                                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>info</mat-icon>
                                                <div i18n="@@other_info">Other information</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Here you can add other information needed for the users who will take this course"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('other_info').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <mat-form-field fxFlex="0 1 100%">
                                                <textarea
                                                    matInput
                                                    placeholder="Enter other information"
                                                    i18n-placeholder
                                                    formControlName="other_info"
                                                ></textarea>
                                            </mat-form-field>
                                        </ng-template>
                                    </mat-expansion-panel>
                                </div>
                            </ng-template>
                        </mat-expansion-panel>
                        <mat-expansion-panel #mainExpansionPanelImages class="user-manage-course-expantion main-panel">
                            <mat-expansion-panel-header>
                                <mat-icon>collections</mat-icon>
                                <div
                                    [ngClass]="{ 'main-info-label': mainExpansionPanelImages.expanded }"
                                    i18n="@@courseImages"
                                >
                                    Course images
                                </div>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <div class="panel-body-wrapper">
                                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>add_photo_alternate</mat-icon>
                                                <div i18n="@@coverImg">Cover image</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="It's the image of the course"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('media_id').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <div fxLayoutAlign="center center">
                                                <app-media
                                                    #app_media
                                                    *ngIf="!form.get('media_id')?.value"
                                                    fxFlex="1 1 50%"
                                                    fxFlex.lt-md="1 1 100%"
                                                    formControlName="media_id"
                                                    [scope]="AppScope.COURSE"
                                                    dropLabel="Drop course image"
                                                    i18n-dropLabel="@@dropCourseImage"
                                                ></app-media>
                                                <app-image-view
                                                    (click)="
                                                        showImgModal(
                                                            form.get('media_id')?.value
                                                                ? form.get('media_id')?.value
                                                                : 'assets/images/Default_courses_img.png'
                                                        )
                                                    "
                                                    class="mt-2 mb-2"
                                                    *ngIf="form.get('media_id')?.value"
                                                    fxFlex="0 1 50%"
                                                    fxFlex.lt-md="0 1 100%"
                                                    [media_id]="
                                                        form.get('media_id')?.value
                                                            ? form.get('media_id')?.value
                                                            : 'assets/images/Default_courses_img.png'
                                                    "
                                                    paddingBottom="50%"
                                                >
                                                    <button
                                                        class="remove-image"
                                                        mat-icon-button
                                                        color="warn"
                                                        *ngIf="form.get('media_id').value as mediaId"
                                                        matTooltip="Remove image"
                                                        i18n-matTooltip
                                                        (click)="form.get('media_id').setValue(null)"
                                                    >
                                                        <mat-icon class="md-24">delete_outline</mat-icon>
                                                    </button>
                                                </app-image-view>
                                            </div>
                                        </ng-template>
                                    </mat-expansion-panel>
                                    <!-- <form [formGroup]="screenshotsForm">
                                        <mat-expansion-panel #expansionPanelExtra class="user-manage-course-expantion">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <mat-icon>photo_size_select_actual</mat-icon>
                                                    <div
                                                        [ngClass]="{ 'main-info-label': expansionPanelExtra.expanded }"
                                                        i18n="@@backgroundImg"
                                                    >
                                                        Background image
                                                    </div>
                                                    <mat-icon
                                                        class="icon-explain"
                                                        matTooltip="Background for the course in the course details page."
                                                        i18n-matTooltip
                                                        >help_outline</mat-icon
                                                    >
                                                </mat-panel-title>
                                                <mat-panel-description fxLayoutAlign="end center">
                                                    <span
                                                        matTooltip="Indicator of field change"
                                                        i18n-matTooltip
                                                        class="cell-indicator"
                                                        [ngClass]="{
                                                            green: screenshotsForm.get('bg_img').dirty
                                                        }"
                                                    ></span>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <ng-template matExpansionPanelContent>
                                                <div
                                                    fxLayoutAlign="center center"
                                                    fxLayout="row"
                                                    fxLayout.lt-md="column"
                                                >
                                                    <div
                                                        class="imgWrapper"
                                                        fxLayout="column"
                                                        fxLayoutAlign="center center"
                                                    >
                                                        <div fxLayoutAlign="center center">
                                                            <app-media
                                                                *ngIf="!screenshotsForm.value.bg_img"
                                                                formControlName="bg_img"
                                                                [scope]="AppScope.COURSE"
                                                                dropLabel="Drop background image"
                                                                i18n-dropLabel="@@dropBGImage"
                                                            ></app-media>
                                                            <app-image-view
                                                                (click)="showImgModal(mediaId)"
                                                                *ngIf="screenshotsForm.value.bg_img as mediaId"
                                                                [media_id]="mediaId"
                                                                [proportional]="false"
                                                            >
                                                                <button
                                                                    *ngIf="screenshotsForm.value.bg_img"
                                                                    class="remove-image"
                                                                    mat-icon-button
                                                                    color="warn"
                                                                    matTooltip="Remove image"
                                                                    i18n-matTooltip
                                                                    (click)="
                                                                        screenshotsForm.get('bg_img').setValue(null)
                                                                    "
                                                                >
                                                                    <mat-icon class="md-24">delete_outline</mat-icon>
                                                                </button>
                                                            </app-image-view>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </mat-expansion-panel>
                                        <mat-expansion-panel #expansionPanelExtra class="user-manage-course-expantion">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <mat-icon>center_focus_strong</mat-icon>
                                                    <div
                                                        [ngClass]="{ 'main-info-label': expansionPanelExtra.expanded }"
                                                        i18n="@@screenshotImg"
                                                    >
                                                        Screenshot image
                                                    </div>
                                                    <mat-icon
                                                        class="icon-explain"
                                                        matTooltip="A thumbnail from the video (or a homepage) This image is a Screenshot from the creator to be uploaded."
                                                        i18n-matTooltip
                                                        >help_outline</mat-icon
                                                    >
                                                </mat-panel-title>
                                                <mat-panel-description fxLayoutAlign="end center">
                                                    <span
                                                        matTooltip="Indicator of field change"
                                                        i18n-matTooltip
                                                        class="cell-indicator"
                                                        [ngClass]="{
                                                            green: screenshotsForm.get('screenshot').dirty
                                                        }"
                                                    ></span>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <ng-template matExpansionPanelContent>
                                                <div
                                                    fxLayoutAlign="center center"
                                                    fxLayout="row"
                                                    fxLayout.lt-md="column"
                                                >
                                                    <div
                                                        class="imgWrapper"
                                                        fxLayout="column"
                                                        fxLayoutAlign="center center"
                                                    >
                                                        <div fxLayoutAlign="center center">
                                                            <app-media
                                                                *ngIf="!screenshotsForm.value.screenshot"
                                                                formControlName="screenshot"
                                                                [scope]="AppScope.COURSE"
                                                                dropLabel="Drop screenshot image"
                                                                i18n-dropLabel="@@dropScreenshotImage"
                                                            ></app-media>
                                                            <app-image-view
                                                                (click)="showImgModal(mediaId)"
                                                                *ngIf="screenshotsForm.value.screenshot as mediaId"
                                                                [media_id]="mediaId"
                                                                [proportional]="false"
                                                            >
                                                                <button
                                                                    *ngIf="screenshotsForm.value.screenshot"
                                                                    class="remove-image"
                                                                    mat-icon-button
                                                                    color="warn"
                                                                    matTooltip="Remove image"
                                                                    i18n-matTooltip
                                                                    (click)="
                                                                        screenshotsForm.get('screenshot').setValue(null)
                                                                    "
                                                                >
                                                                    <mat-icon class="md-24">delete_outline</mat-icon>
                                                                </button>
                                                            </app-image-view>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </mat-expansion-panel>
                                    </form> -->
                                </div>
                            </ng-template>
                        </mat-expansion-panel>
                        <mat-expansion-panel
                            #mainExpansionPanelSpecifications
                            class="user-manage-course-expantion main-panel"
                        >
                            <mat-expansion-panel-header>
                                <mat-icon>announcement</mat-icon>
                                <div
                                    [ngClass]="{ 'main-info-label': mainExpansionPanelSpecifications.expanded }"
                                    i18n="@@courseSpecifications"
                                >
                                    Course specifications
                                </div>
                            </mat-expansion-panel-header>
                            <ng-template matExpansionPanelContent>
                                <div class="panel-body-wrapper">
                                    <form class="inst-form" [formGroup]="instructorForm">
                                        <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <mat-icon>engineering</mat-icon>
                                                    <div i18n="@@instructor">Instructor</div>
                                                    <mat-icon
                                                        class="icon-explain"
                                                        matTooltip="The one who explain and teach in the Course. Can be the narrator in the video on screen or voice over. In the film industry: This will be equal to Actor "
                                                        i18n-matTooltip
                                                        >help_outline</mat-icon
                                                    >
                                                </mat-panel-title>
                                                <mat-panel-description fxLayoutAlign="end center">
                                                    <span
                                                        matTooltip="Indicator of field change"
                                                        i18n-matTooltip
                                                        class="cell-indicator"
                                                        [ngClass]="{ green: instructorForm.dirty }"
                                                    ></span>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>

                                            <div class="inst-settings" fxLayoutAlign="center center" fxLayout="column">
                                                <mat-form-field fxFlex="0 1 100%">
                                                    <input
                                                        matInput
                                                        placeholder="Please enter instructor name"
                                                        i18n-placeholder
                                                        formControlName="name"
                                                    />
                                                </mat-form-field>
                                                <p
                                                    class="inst-error"
                                                    *ngIf="
                                                        instructorForm.hasError('instructorNameShouldExist') &&
                                                        instructorForm.get('images').value.length > 0
                                                    "
                                                    i18n="@@instructorNameShouldExist"
                                                >
                                                    Instructor name should exist if images uploaded
                                                </p>
                                                <div class="insta-img" fxLayoutAlign="center center" fxLayout="column">
                                                    <div
                                                        fxLayoutAlign="center center"
                                                        *ngIf="instructorUploading.value"
                                                    >
                                                        <app-kd-logo-loading></app-kd-logo-loading>
                                                    </div>
                                                    <app-multiple-image-view
                                                        [maxImgCount]="2"
                                                        formControlName="images"
                                                        [scope]="AppScope.USERS"
                                                        [mediaIds]="instructorForm.get('images').value"
                                                        [enableUpload]="true"
                                                        dropLabel="Drop images of the instructor"
                                                        i18n-dropLabel="@@dropImagesForTheInstructor"
                                                        (saving)="toggleLoading($event)"
                                                    >
                                                    </app-multiple-image-view>
                                                    <span>{{ instructorForm.value.images?.length }} / 2</span>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>
                                    </form>
                                    <!-- Author -->
                                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>account_circle</mat-icon>
                                                <div i18n="@@author">Author</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Enter the name of the author of this course."
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('bullets').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <mat-form-field fxFlex="0 1 100%">
                                                <input
                                                    matInput
                                                    placeholder="Please set author name"
                                                    i18n-placeholder
                                                    formControlName="author_name"
                                                />
                                            </mat-form-field>
                                        </ng-template>
                                    </mat-expansion-panel>
                                    <!-- Publisher -->
                                    <!-- <form class="inst-form" [formGroup]="publisherForm" [formGroup]>
                                        <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <mat-icon>publish</mat-icon>
                                                    <div i18n="@@publisher">Publisher</div>
                                                    <mat-icon
                                                        class="icon-explain"
                                                        matTooltip="The one who publish the Course."
                                                        i18n-matTooltip
                                                        >help_outline</mat-icon
                                                    >
                                                </mat-panel-title>
                                                <mat-panel-description fxLayoutAlign="end center">
                                                    <span
                                                        matTooltip="Indicator of field change"
                                                        i18n-matTooltip
                                                        class="cell-indicator"
                                                        [ngClass]="{ green: publisherForm.get('name').dirty }"
                                                    ></span>
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <mat-form-field fxFlex="0 1 100%">
                                                <input
                                                    matInput
                                                    placeholder="Please enter publisher name"
                                                    i18n-placeholder
                                                    formControlName="name"
                                                />
                                            </mat-form-field>
                                        </mat-expansion-panel>
                                    </form> -->
                                    <!-- Price -->
                                    <mat-expansion-panel #priceExpansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>local_offer</mat-icon>
                                                <div i18n="@@price">Price</div>

                                                <mat-icon class="icon-explain" matTooltip="Price" i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('price').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <div
                                                class="mb-2"
                                                fxLayoutGap.gt-md="2em"
                                                fxLayout.lt-md="column"
                                                fxFlex="0 1 100%"
                                                [fxHide]="false"
                                            >
                                                <mat-form-field fxFlex="0 0 40%" fxLayout.lt-md="0 0 90%">
                                                    <input
                                                        matInput
                                                        placeholder="Please set course price"
                                                        i18n-placeholder
                                                        formControlName="price"
                                                    />
                                                </mat-form-field>
                                                <mat-select
                                                    class="select-curr"
                                                    fxFlex="0 0 40%"
                                                    fxLayout.lt-md="0 0 90%"
                                                    placeholder="Place choose currency"
                                                    i18n-placeholder
                                                    formControlName="currency"
                                                >
                                                    <mat-option
                                                        *ngFor="let currency of currencies"
                                                        [value]="currency.code"
                                                    >
                                                        {{ currency.label }} - {{ currency.sign }}
                                                    </mat-option>
                                                </mat-select>
                                            </div>
                                        </ng-template>
                                    </mat-expansion-panel>
                                    <!-- Promo code -->
                                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>card_giftcard</mat-icon>
                                                <div i18n="@@promoCode">Promo code</div>
                                                <mat-icon class="icon-explain" matTooltip="Promo code" i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('promo_code').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <mat-form-field fxFlex="0 1 100%">
                                                <input
                                                    matInput
                                                    placeholder="Please set a promo code for this course"
                                                    i18n-placeholder
                                                    formControlName="promo_code"
                                                />
                                            </mat-form-field>
                                        </ng-template>
                                    </mat-expansion-panel>
                                    <!-- Course code -->
                                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>fingerprint</mat-icon>
                                                <div i18n="@@SKU-ArticleNumber">SKU/ Article number</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Course code is a unique field, autogenerated when empty."
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('article_code').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <mat-form-field fxFlex="0 1 100%">
                                                <input
                                                    matInput
                                                    placeholder="Please set course code"
                                                    i18n-placeholder
                                                    formControlName="article_code"
                                                />
                                            </mat-form-field>
                                            <span *ngIf="form.get('article_code').dirty">
                                                <p *ngIf="vaildArticleCode | async; let isValid; else: smallLoading">
                                                    <span class="valid" *ngIf="isValid" i18n="@@courseCodeIsValid"
                                                        >Course code is valid</span
                                                    >
                                                    <span
                                                        class="error"
                                                        *ngIf="!isValid"
                                                        i18n="@@courseCodeIsTakenPleaseEnterAnotherCourseCode"
                                                        >Course code is taken, please enter another course code</span
                                                    >
                                                </p>
                                            </span>
                                        </ng-template>
                                    </mat-expansion-panel>
                                    <!-- Subtitle languages -->
                                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>subtitles</mat-icon>
                                                <div i18n="@@subtitles">Subtitle languages</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="If Subtitles are availible in the video for exampel ; Info Copied from TED or YouTube"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('subtitles').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div fxLayoutGap="1em" fxLayoutAlign="start center">
                                            <app-title-translation
                                                type="description"
                                                placeholder="Enter language subtitle"
                                                i18n-placeholder
                                                formControlName="subtitles"
                                                class="icon-resource-builder"
                                                fxFlex="0 0 auto"
                                            ></app-title-translation>
                                        </div>
                                    </mat-expansion-panel>
                                    <!-- Course seats -->
                                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>event_seat</mat-icon>
                                                <div i18n="@@courseSeats">Course seats</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Total seats can be unlimited or specified"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{
                                                        green: form.get('total_seats').dirty
                                                    }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <div
                                            fxFlex="0 1 100%"
                                            fxLayout.lt-md="column"
                                            fxLayoutAlign="space-between center"
                                            fxLayoutAlign.lt-md="space-between start"
                                        >
                                            <!-- <mat-checkbox
                                                #unlimited
                                                fxLayoutAlign="start center"
                                                fxFlex="0 0 20%"
                                                color="primary"
                                                i18n="@@unlimited"
                                                formControlName="unlimited_seats"
                                                >Unlimited</mat-checkbox
                                            >
                                            <mat-form-field fxFlex="0 1 80%">
                                                <input
                                                    matInput
                                                    fxLayoutAlign="end center"
                                                    type="number"
                                                    placeholder="Please set total seats of the course"
                                                    i18n-placeholder
                                                    formControlName="total_seats"
                                                />
                                            </mat-form-field> -->
                                        </div>
                                    </mat-expansion-panel>
                                    <!-- Extra seats -->
                                    <mat-expansion-panel #expansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>weekend</mat-icon>
                                                <div i18n="@@extraSeats">Extra seats</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Extra seats activated when seats are full."
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('extra_seats').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <mat-form-field fxFlex="0 1 100%">
                                                <input
                                                    matInput
                                                    type="number"
                                                    placeholder="Please set extra seats needed for the course"
                                                    i18n-placeholder
                                                    formControlName="extra_seats"
                                                />
                                            </mat-form-field>
                                        </ng-template>
                                    </mat-expansion-panel>
                                    <!-- Copyrights -->
                                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon>copyright</mat-icon>
                                                <div i18n="@@copyrights">Copyrights</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Set course copyrights"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('copyrights').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <mat-form-field class="summary-field" fxFlex="0 1 100%">
                                                <textarea
                                                    matInput
                                                    placeholder="Enter copyrights for the course"
                                                    i18n-placeholder
                                                    minRows="1"
                                                    formControlName="copyrights"
                                                ></textarea>
                                            </mat-form-field>
                                        </ng-template>
                                    </mat-expansion-panel>
                                    <mat-expansion-panel #promoCodeExpansionPanel class="user-manage-course-expantion">
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <mat-icon class="prio-icon">low_priority</mat-icon>
                                                <div i18n="@@priority">Priority</div>
                                                <mat-icon
                                                    class="icon-explain"
                                                    matTooltip="Set course priority"
                                                    i18n-matTooltip
                                                    >help_outline</mat-icon
                                                >
                                            </mat-panel-title>
                                            <mat-panel-description fxLayoutAlign="end center">
                                                <span
                                                    matTooltip="Indicator of field change"
                                                    i18n-matTooltip
                                                    class="cell-indicator"
                                                    [ngClass]="{ green: form.get('priority').dirty }"
                                                ></span>
                                            </mat-panel-description>
                                        </mat-expansion-panel-header>
                                        <ng-template matExpansionPanelContent>
                                            <mat-form-field class="summary-field" fxFlex="0 1 100%">
                                                <input
                                                    matInput
                                                    fxLayoutAlign="end center"
                                                    type="number"
                                                    placeholder="Please set priority"
                                                    i18n-placeholder="@@plsSetPriority"
                                                    formControlName="priority"
                                                />
                                            </mat-form-field>
                                        </ng-template>
                                    </mat-expansion-panel>
                                </div>
                            </ng-template>
                        </mat-expansion-panel>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>

            <p class="note">
                <span i18n="@@actionsBtnsDescInCoureCreation">
                    <strong>Save :</strong> It will save the course, but not publish it. You can edit later. <br />
                    <strong>Publish: </strong>Publish this course in this academy. Depending on the administrator
                    settings, admin can review and approve before publish completed. You can also assign course to
                    learners.<br />
                    <strong>Make public : </strong>Make this course public in this academy and make it also available in
                    the Klick Data Open Library for other academies to access it as well.<br />
                </span>
            </p>
        </ng-container>
        <div
            class="btns-row-wrapper"
            fxFlex="0 0 100%"
            fxLayout="row"
            fxLayoutAlign="start baseline"
            fxLayoutGap="1em"
            fxLayoutGap.lt-md="5px"
        >
            <button
                fxFlex="0 0 auto"
                *ngIf="!(loading | async)"
                [disabled]="form.invalid"
                mat-raised-button
                color="primary"
                (click)="onSubmit.emit(true)"
            >
                <mat-icon>save</mat-icon>
                <span i18n="@@save">Save</span>
            </button>
            <ng-container *ngIf="resourceType === ResourceTypes.COURSE">
                <button
                    fxFlex="0 0 auto"
                    *ngIf="canPublish && !(loading | async)"
                    mat-raised-button
                    (click)="makePublish(resource, !isPublished)"
                    [disabled]="form.invalid"
                    color="primary"
                >
                    <mat-icon> {{ !isPublished ? 'publish_outline' : 'unpublished' }}</mat-icon>
                    <span *ngIf="!isPublished" i18n="@@publish">Publish</span>
                    <span *ngIf="isPublished" i18n="@@unpublish">Unpublish</span>
                </button>

                <button
                    fxFlex="0 0 auto"
                    *ngIf="canPublish && !(loading | async)"
                    mat-raised-button
                    (click)="onManagePublic(form.get('public').value)"
                    [disabled]="form.invalid"
                    color="primary"
                >
                    <mat-icon> {{ form.get('public').value ? 'public_off' : 'public' }}</mat-icon>
                    <span *ngIf="form.get('public').value" i18n="@@makeUnpublic">Make unpublic</span>
                    <span *ngIf="!form.get('public').value" i18n="@@makePublic">Make public</span>
                </button>
            </ng-container>
            <button fxFlex="0 0 auto" mat-raised-button color="primary" [routerLink]="['../library']">
                <mat-icon>list</mat-icon>
                <span i18n="@@list">List</span>
            </button>
            <app-saving-button fxFlexOrder.gt-md="3" *ngIf="loading | async"></app-saving-button>
            <div *ngIf="!published" fxFlex="1 1 100%"></div>
        </div>

        <!-- <div class="btns-row-wrapper" fxFlex="0 0 100%" fxLayoutGap="2em" fxLayout="row" fxLayoutAlign="start baseline">
            <mat-checkbox
                fxFlex="0 0 auto"
                *ngIf="!published"
                formControlName="public"
                color="primary"
                i18n="@@makePublicUnCheckToAssignCourse"
                >Uncheck to assign course</mat-checkbox
            >
        </div> -->
        <!-- <mat-checkbox
            *ngIf="isAdmin | async"
            fxFlex="0 0 auto"
            formControlName="verified"
            color="primary"
            [disabled]="true"
            i18n="@@approvCourse"
            >Approve Courses created by Academy Learners</mat-checkbox
        > -->
    </form>
</div>
<ng-template #smallLoading>
    <div fxLayout="row" fxLayoutAlign="start center" class="spinner-container mt-2">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
