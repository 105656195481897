<div fxLayout="row" fxLayoutAlin="center center" *ngIf="gaugeType === 'pie'" class="pie-wrapper">
    <div class="pie {{ mainColor }} {{ size }}" [ngStyle]="{ background: pieStyle }"></div>
</div>
<div *ngIf="gaugeType === 'chart'" class="clearfix">
    <div class="c100 p{{ percentage }} {{ mainColor }} {{ size }}">
        <span class="label {{ mainColor }}">{{ percentage }}%</span>
        <div class="slice">
            <div class="bar"></div>
            <div class="fill"></div>
        </div>
    </div>
</div>
