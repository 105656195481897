import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CapabilityService } from '../capabilities/capability.service';
import { ServicesAccessCapability } from '../capabilities/capability/services-access-capability.enum';

@Injectable()
export class AuthoringToolGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(protected capability: CapabilityService, protected router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.check();
    }

    canLoad(route: Route) {
        return this.check();
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.check();
    }

    private check(): Observable<boolean> {
        return this.capability
            .check(ServicesAccessCapability.getName(), ServicesAccessCapability.ACCESS_MATERIALS)
            .pipe(
                first(),
                map(result => {
                    if (!result) {
                        this.router.navigate(['/unauthorised']);
                    }

                    return result;
                })
            );
    }
}
