<div fxLayoutGap="4px" class="input-wrapper-user default-select-user" fxLayout="column">
    <span class="label">{{ titleLabel }}</span>
    <div *ngIf="!hideInput" fxLayout="column" fxLayoutGap="10px" fxLayoutGap.lt-md="10px">
        <mat-form-field class="small-field users-rec-input" appearance="fill">
            <input
                type="text"
                matInput
                #customerInput
                i18n-placeholder
                placeholder="Search"
                [formControl]="customerCtrl"
                [matAutocomplete]="autoCustomer"
                (keyup.enter)="$event.stopPropagation(); addDraftCustomer()"
                (keyup.,)="addDraftCustomer()"
            />
            <mat-autocomplete #autoCustomer="matAutocomplete" (optionSelected)="selectCustomer($event.option.value)">
                <mat-option *ngFor="let customer of customerSearch$ | async" [value]="customer">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span>{{ customer.name }}</span>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <span class="input-hint" *ngIf="hint">{{ hint }}</span>
    </div>
    <div fxLayout="column wrap" [ngClass]="{ 'mt-1': !!customers.length }">
        <mat-chip-list *ngIf="customers.length" class="reci-chips mb-1" #chipList aria-label="Recievers selection">
            <mat-chip
                *ngFor="let customer of customers"
                (removed)="removeCustomer(customer)"
                [selectable]="false"
                class="users-wrap customer"
                [matTooltip]="(customer.type | clientStatus)?.label"
                [ngStyle]="{
                    color: (customer?.type | clientStatus)?.fg,
                    background: (customer?.type | clientStatus)?.bg,
                    border: (customer?.type | clientStatus)?.border
                }"
            >
                <div
                    [ngStyle]="{
                        color: (customer?.type | clientStatus)?.fg
                    }"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <span class="cust-name">{{ customer?.name }}</span>
                </div>
                <button
                    [ngStyle]="{
                        color: (customer?.type | clientStatus)?.fg
                    }"
                    *ngIf="chipRemovable"
                    matChipRemove
                >
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip></mat-chip-list
        >
    </div>
    <div *ngIf="hasDrafts" fxLayout="column wrap">
        <mat-chip-list
            *ngIf="draftCustomers.length"
            class="draft-chips mb-1"
            #chipList
            aria-label="Recievers selection"
        >
            <mat-chip
                *ngFor="let draftCustomer of draftCustomers"
                (removed)="removeCustomer(draftCustomer)"
                [selectable]="false"
                class="users-wrap customer"
            >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <span>{{ draftCustomer }}</span>
                </div>
                <button *ngIf="chipRemovable" matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip></mat-chip-list
        >
    </div>
</div>
