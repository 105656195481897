import { Injectable } from '@angular/core';
import { Group, GroupData, GroupService } from '@klickdata/core/group';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UsersGroupsService extends GroupService implements TableHttpService<Group> {
    public fetchData(source: TableSource<Group>): Observable<ResponseData<Group[]>> {
        const req = this.builder.get<GroupData[]>(this.resourceUrl);
        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<GroupData[]>) => {
                return {
                    data: this.mapGroups(res.data),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }
}
