import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { SideNaveData, SideNaveDataTypes } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-mobile-bottom-sheet',
    templateUrl: './menu-side-mobile-bottom-sheet.component.html',
    styleUrls: ['./menu-side-mobile-bottom-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideMobileBottomSheetComponent implements OnInit {
    SideNaveDataTypes = SideNaveDataTypes;
    constructor(
        public bottomSheetRef: MatBottomSheetRef<MenuSideMobileBottomSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: SideNaveData
    ) {}

    ngOnInit(): void {}
}
