<form class="example-form">
    <mat-form-field fxFlex="100%" class="full-width">
        <input
            type="text"
            matInput
            placeholder="Set publisher name"
            i18n-placeholder
            (keyup.enter)="addNewEdu()"
            [formControl]="publisherControl"
            [value]="publisher?.name"
            [matAutocomplete]="auto"
        />
        <mat-autocomplete
            (optionActivated)="optionActivated($event)"
            class="full-width"
            #auto="matAutocomplete"
            [displayWith]="displayName"
        >
            <mat-option class="full-width" *ngFor="let publisher of publishers$ | async" [value]="publisher">
                {{ publisher.name }}
            </mat-option>
        </mat-autocomplete>
        <button *ngIf="!publisherExist" mat-icon-button matSuffix (click)="addNewEdu()">
            <mat-icon>person_add_alt_1</mat-icon>
        </button>
        <mat-hint align="end" *ngIf="!publisherExist">Click enter to create this new publisher</mat-hint>
    </mat-form-field>
</form>
