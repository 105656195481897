<mat-accordion
    [ngSwitch]="resource_type_id"
    class="course-items-wrapper"
    fxFlex="0 1 100%"
    displayMode="flat"
    multi="true"
>
    <ng-container *ngSwitchCase="ResourceTypes.GeneralCoursePlan">
        <mat-expansion-panel #materialPanel>
            <mat-expansion-panel-header>
                <mat-icon mat-list-icon fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                <ng-container i18n="@@addMaterial">Add material</ng-container>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-add-materials
                    [selection]="selection"
                    (select)="addResource($event) && materialPanel.close()"
                ></app-add-materials>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel #testPanel>
            <mat-expansion-panel-header>
                <mat-icon mat-list-icon fontSet="klickdata" fontIcon="kd-icon-test"></mat-icon>
                <ng-container i18n="@@addTest">Add test</ng-container>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-add-tests [selection]="selection" (add)="addResource($event) && testPanel.close()">
                </app-add-tests>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel #surveyPanel color="accent">
            <mat-expansion-panel-header>
                <mat-icon mat-list-icon fontSet="klickdata" fontIcon="kd-icon-survey"></mat-icon>
                <ng-container i18n="@@addSurvey">Add survey</ng-container>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-add-surveys
                    [selection]="selection"
                    (add)="addResource($event) && surveyPanel.close()"
                ></app-add-surveys>
            </ng-template>
        </mat-expansion-panel>
    </ng-container>
    <ng-container *ngSwitchCase="ResourceTypes.PACKAGE_E_COURSE">
        <mat-expansion-panel #ecoursePanel color="accent">
            <mat-expansion-panel-header>
                <mat-icon mat-list-icon fontSet="klickdata" fontIcon="kd-icon-player"></mat-icon>
                <ng-container i18n="@@addEcourses">Add ecourses</ng-container>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-add-ecourse
                    [selection]="selection"
                    (add)="addResource($event) && ecoursePanel.close()"
                ></app-add-ecourse>
            </ng-template>
        </mat-expansion-panel>
    </ng-container>
    <ng-container *ngSwitchCase="ResourceTypes.PACKAGE_COURSE">
        <mat-expansion-panel #coursePanel color="accent">
            <mat-expansion-panel-header>
                <mat-icon mat-list-icon fontSet="klickdata" fontIcon="kd-icon-task"></mat-icon>
                <ng-container i18n="@@addCourse">Add course</ng-container>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-add-course
                    [selection]="selection"
                    (add)="addResource($event) && coursePanel.close()"
                ></app-add-course>
            </ng-template>
        </mat-expansion-panel>
    </ng-container>
    <ng-container *ngSwitchCase="ResourceTypes.CURRICULUM">
        <mat-expansion-panel #coursePanel color="accent">
            <mat-expansion-panel-header>
                <mat-icon mat-list-icon fontSet="klickdata" fontIcon="kd-icon-task"></mat-icon>
                <ng-container i18n="@@addCourse">Add course</ng-container>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-add-course
                    [selection]="selection"
                    (add)="addResource($event) && coursePanel.close()"
                ></app-add-course>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel #materialPanel>
            <mat-expansion-panel-header>
                <mat-icon mat-list-icon fontSet="klickdata" fontIcon="kd-icon-document"></mat-icon>
                <ng-container i18n="@@addMaterial">Add material</ng-container>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-add-materials
                    [selection]="selection"
                    (select)="addResource($event) && materialPanel.close()"
                ></app-add-materials>
            </ng-template>
        </mat-expansion-panel>
        <mat-expansion-panel #testPanel>
            <mat-expansion-panel-header>
                <mat-icon mat-list-icon fontSet="klickdata" fontIcon="kd-icon-test"></mat-icon>
                <ng-container i18n="@@addTest">Add test</ng-container>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-add-tests [selection]="selection" (add)="addResource($event) && testPanel.close()">
                </app-add-tests>
            </ng-template>
        </mat-expansion-panel>
    </ng-container>
</mat-accordion>
