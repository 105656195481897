import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AuthService } from '@klickdata/core/auth';
import { GlobalSearchData } from '@klickdata/core/global-search';
import { User } from '@klickdata/core/user';
import { Observable, first } from 'rxjs';

@Component({
    selector: 'app-resource-listing-sheet',
    templateUrl: './resource-listing-sheet.component.html',
    styleUrls: ['./resource-listing-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceListingSheetComponent implements OnInit {
    public user$: Observable<User>;
    constructor(
        public bottomSheetRef: MatBottomSheetRef<ResourceListingSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: GlobalSearchData,
        protected auth: AuthService
    ) {}

    ngOnInit(): void {
        this.user$ = this.auth.getUser().pipe(first());
    }
    public dismissSheet() {
        this.bottomSheetRef.dismiss();
    }
}
