import { AccessCapability } from '../can-access.directive';

export namespace PostCapability {
    export const NONE = 0;
    export const MANAGE_GROUP_POST = 1 << 0;
    export const MANAGE_CUSTOMER_POST = 1 << 1;
    export const MANAGE_PUBLIC_POST = 1 << 2;
    export const VIEW_CUSTOMER_POST = 1 << 3;

    export function getName(): string {
        return 'post';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
