import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'colorsCodesByNames',
})
export class ColorsCodesByNamesPipe implements PipeTransform {
    transform(colorName: string): string {
        switch (colorName) {
            case 'magenta':
                return '#e44a66';
            case 'cyan':
                return '#93cbd1';
            case 'blue':
                return '#3e5365';
            case 'orange':
                return '#ff9961';
            case 'green':
                return '#bfd8d0';
        }
    }
}
