import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DirectionalityService } from '@klickdata/core/localization';
import { Angulartics2GoogleAnalytics } from 'angulartics2';

@Component({
    selector: 'app-klickdata',
    viewProviders: [MatIconRegistry],
    template: `
        <div class="parent-root" [dir]="directionality.value">
            <router-outlet></router-outlet>
            <router-outlet name="popup"></router-outlet>
        </div>
    `,
})
export class AppComponent implements OnInit {
    constructor(
        @Inject(LOCALE_ID) protected localeId: string,
        protected mdIconRegistry: MatIconRegistry,
        protected sanitizer: DomSanitizer,
        protected angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
        public directionality: DirectionalityService
    ) {
        this.localeId = localeId === 'en' ? 'en-US' : localeId;
        this.angulartics2GoogleAnalytics.startTracking();
        this.mdIconRegistry.registerFontClassAlias('klickdata', 'klickdata');
    }
    ngOnInit() {}
}
