import { Cast, CastType, IDataModel, Model } from '@klickdata/core/application/src/model/model-interface';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { GradeSystem } from './grade-system.model';

export interface GradeData extends IDataModel {
    id?: string;
    passing?: boolean;
    title?: string;
    percentage?: number;
    system_id?: number;
    created_by?: number;
    crated_at?: string;
    updated_at?: string;
    language_id?: number;
}

export class Grade extends Model<GradeData> {
    id: string;
    passing: boolean;
    title: string;
    percentage: number;
    system_id: number;
    created_by: number;
    @Cast(CastType.MOMENT)
    crated_at: moment.Moment;
    @Cast(CastType.MOMENT)
    updated_at: moment.Moment;
    language_id: number;

    grade_system: Observable<GradeSystem>;
}
