import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppValidators } from '@klickdata/core/form';
import { AlternativeData, AlternativeService as AS, Question } from '@klickdata/core/question';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AlternativeService {
    constructor(protected fb: FormBuilder, protected alternativeService: AS) {}

    public createDuplicateFormGroupsFromQuestion(question: Question, usePoints: boolean): Observable<FormGroup[]> {
        return this.alternativeService.getByQuestion(question.id).pipe(
            map((alternatives) => {
                return this.createDuplicateFormGroups(alternatives, usePoints);
            })
        );
    }

    public createDuplicateFormGroups(alternatives: AlternativeData[], usePoints: boolean): FormGroup[] {
        return alternatives.map((alternative, index) => {
            alternative.id = null; // When duplicate remove alternative id...
            return this.createFormGroup(alternative, index, usePoints);
        });
    }

    public createFormGroups(alternatives: AlternativeData[], usePoints: boolean): FormGroup[] {
        return alternatives.map((alternative, index) => this.createFormGroup(alternative, index, usePoints));
    }

    public createFormGroup(data: AlternativeData, index: number, usePoints: boolean) {
        const group = this.fb.group({
            index: [index],
            id: [data.id],
            alternative: [data.alternative],
            title: [data.title, AppValidators.notDelete(Validators.required)],
            correct: [!!data.correct],
            points: [data.points, AppValidators.notDelete(Validators.required)],
            explanation: [data.explanation],
            media_id: [data.media_id],
        });

        if (!usePoints) {
            group.get('correct').disable();
            // group.get('points').disable();
            group.get('points').setValidators(null);
        }
        return group;
    }
}
