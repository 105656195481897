<div class="dialog-wrapper">
    <div
        matDialogTitle
        class="color-warn"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="5px"
        fxLayoutAlign.lt-md="start flex-start"
    >
        <mat-icon fxFlex="0 0 36px" class="md-36"> link </mat-icon>
        <div class="title-wrapper" fxFlex="0 1 auto">
            <span class="title" i18n>Add Link</span>
        </div>
    </div>

    <div matDialogContent>
        <mat-form-field appearance="fill">
            <mat-label i18n>Pls enter a valid url</mat-label>
            <input type="url" matInput [formControl]="linkUrl" />
            <mat-error *ngIf="linkUrl.hasError('required')" i18n> Url required </mat-error>
            <mat-error *ngIf="linkUrl.hasError('pattern')" i18n> Url is Invalid </mat-error>
        </mat-form-field>
    </div>

    <div class="flex link-add" mat-dialog-actions>
        <div class="neutrals">
            <button class="neutral" i18n mat-button type="button" (click)="dialogRef.close(null)">Cancel</button>
        </div>

        <button
            mat-button
            type="button"
            [disabled]="linkUrl.invalid"
            i18n
            color="accent"
            (click)="dialogRef.close(linkUrl.value)"
        >
            Ok
        </button>
    </div>
</div>
