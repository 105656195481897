import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Resource } from '@klickdata/core/resource';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { AccessForm } from './access.form';

@Component({
    selector: 'app-course-plan-access',
    templateUrl: './course-plan-access.component.html',
    styleUrls: ['./course-plan-access.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AccessForm],
})
export class CoursePlanAccessComponent implements OnInit, OnDestroy {
    @Input() hideActions: boolean;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @Output() saved: EventEmitter<Resource> = new EventEmitter<Resource>();
    public isLoading: boolean;

    constructor(protected accessForm: AccessForm, protected cdRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.accessForm.create();
    }

    get form(): FormGroup {
        return this.accessForm.form;
    }

    public submit() {
        this.isLoading = true;
        this.accessForm
            .submit()
            .pipe(takeUntil(this.destroy))
            .subscribe(data => {
                this.saved.emit(data);
                this.isLoading = false;
            });
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
