import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EditorMergeField } from './editor-merge-field.model';
import { RequestBuilderService } from '@klickdata/core/http';
import { ConfigService } from '@klickdata/core/config';
import { HttpErrorService } from '@klickdata/core/http/src/error/http-error.service';

@Injectable()
export class EditorMergeFieldService {
    protected resourceUrl: string;

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected error: HttpErrorService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}merge-fields`;
    }

    public getFieldMerge(name: string): Observable<EditorMergeField[]> {
        return this.builder
            .get<EditorMergeField[]>(this.resourceUrl)
            .param('field', name)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((err) => {
                    this.error.handle(err);
                    return of(null);
                })
            );
    }
}
