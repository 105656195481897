import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { Customer } from '@klickdata/core/customer/src/customer.model';
import { ResponseData } from '@klickdata/core/http';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { QuestionService } from '@klickdata/core/question/src/question.service';
import { ResourceItemTypeService } from '@klickdata/core/resource-item/src/type/resource-item-type.service';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PromptType, ResourceItem, ResourceItemData } from './resource-item';

@Injectable({
    providedIn: 'root',
})
export class ResourceItemService {
    protected resourceUrl: string;

    constructor(
        protected builder: RequestBuilderService,
        protected typeService: ResourceItemTypeService,
        protected config: ConfigService,
        protected auth: AuthService,
        protected questionService: QuestionService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}items`;
    }

    /**
     * Get resource items belonging to a customer.
     */
    public getCustomerResourceItems(customer: Customer | string): Observable<ResourceItem[]> {
        const customerId = customer instanceof Customer ? customer.id : customer;

        return this.builder
            .get<ResourceItemData[]>(this.resourceUrl)
            .param('customer', customerId)
            .request()
            .pipe(map((res) => this.mapItems(res.data)));
    }

    /**
     * Get items for a resource.
     */
    public getResourceItems(resourceId: number, limit?: number): Observable<ResourceItem[]> {
        return this.getResourceItemsData(resourceId, limit).pipe(map((res) => this.mapItems(res.data)));
    }

    public getResourceItemsByParentItemId(resourceId: number, parentItemId: number): Observable<ResourceItem[]> {
        return this.builder
            .get<ResourceItemData[]>(this.resourceUrl)
            .param('resource', resourceId)
            .param('parentItem', parentItemId)
            .request()
            .pipe(map((res) => this.mapItems(res.data)));
    }

    public getResourceItemsData(resourceId: number, limit?: number): Observable<ResponseData<ResourceItemData[]>> {
        return this.builder
            .get<ResourceItemData[]>(this.resourceUrl)
            .param('resource', resourceId)
            .limit(limit)
            .request();
    }
    public getResourceItemsDataWithCompletionTime(
        resourceId: number,
        interval: number
    ): Observable<ResponseData<ResourceItemData[]>> {
        return this.builder
            .get<ResourceItemData[]>(this.resourceUrl)
            .param('resource', resourceId)
            .param('prompt_completion_time', interval)
            .request();
    }
    /**
     * Get a item.
     */
    public getResourceItemByResourceId(resourceId: number): Observable<ResourceItem> {
        return this.getResourceItems(resourceId, 1).pipe(map((items) => items[0]));
    }
    public mergeItems(ids: number[]): Observable<ResourceItem> {
        return this.builder
            .post<ResourceItem>(`${this.resourceUrl}/${ids.join()}/merge`, {})
            .request()
            .pipe(map((res) => res.data));
    }

    public translateItems(ids: number[], langId: number, model_name = null): Observable<ResourceItem[]> {
        return this.builder
            .post<ResourceItem[]>(`${this.resourceUrl}/${ids.join()}/translate`, {
                language_id: langId,
                model_name: model_name,
            })
            .request()
            .pipe(map((res) => res.data));
    }

    public regenerateItems(
        ids: number[],
        type: PromptType = 'regenerate',
        model_name = null,
        params?: {}
    ): Observable<ResourceItem[]> {
        const req = this.builder.post<ResourceItem[]>(`${this.resourceUrl}/${ids.join()}/regenerate`, {
            type: type,
            model_name: model_name,
        });
        if (!!params) {
            Object.keys(params).forEach((key) => req.param(key, params[key]));
        }

        return req.request().pipe(map((res) => res.data));
    }

    public downlaod(ids: number[], type: string): Observable<Blob> {
        const builder = this.builder.get<Blob>(`${this.resourceUrl}/${ids.join()}/download`);
        builder.param('type', type);
        return builder.download();
    }

    /**
     * Get a item.
     */
    public getResourceItem(itemId: number): Observable<ResourceItem> {
        return this.builder
            .get<ResourceItemData>(`${this.resourceUrl}/${itemId}`)
            .request()
            .pipe(map((res) => this.createItem(res.data)));
    }

    public getItem(itemId: number): Observable<ResponseData<ResourceItemData>> {
        return this.builder.get<ResourceItemData>(`${this.resourceUrl}/${itemId}`).request();
    }

    public store(item: ResourceItemData): Observable<ResourceItem> {
        return this.builder
            .post<ResourceItemData>(this.resourceUrl, item)
            .request()
            .pipe(map((res) => this.createItem(res.data)));
    }

    /**
     * Method used for storing multiple items at a time.
     */
    public storeAll(items: ResourceItemData[]): Observable<ResourceItem[]> {
        return this.builder
            .post<ResourceItemData[]>(`${this.resourceUrl}/all`, { resource_items: items })
            .request()
            .pipe(map((res) => this.mapItems(res.data)));
    }

    public sync(payload: any): Observable<ResourceItem[]> {
        return this.builder
            .post<ResourceItemData[]>(`${this.resourceUrl}/sync`, payload)
            .request()
            .pipe(map((res) => this.mapItems(res.data)));
    }

    public update(item: ResourceItemData): Observable<ResourceItem> {
        return this.builder
            .put<ResourceItemData>(`${this.resourceUrl}/${item.id}`, item)
            .request()
            .pipe(map((res) => this.createItem(res.data)));
    }

    /**
     * Method used for updating multiple items at a time.
     */
    public updateAll(items: ResourceItemData[]): Observable<ResourceItem[]> {
        return this.builder
            .put<ResourceItemData[]>(`${this.resourceUrl}/all`, { resource_items: items })
            .request()
            .pipe(map((res) => this.mapItems(res.data)));
    }

    public destroy(ids: number[]): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${ids.join()}`)
            .request()
            .pipe(map((res) => res.data));
    }

    public listenToResourceItemsUpdate(resource: Resource, interval = 5000): Observable<ResourceItem[]> {
        return this.auth
            .listenPrivate(
                `resource.${resource.id}`,
                'ResourceItemsEvent',
                this.getResourceItemsDataWithCompletionTime(resource.id, interval),
                interval
            )
            .pipe(map((res) => this.mapItems(res.data)));
    }

    public mapItems(data: ResourceItemData[]): ResourceItem[] {
        return data.map((item) => this.createItem(item));
    }

    protected createItem(data: ResourceItemData): ResourceItem {
        const item = new ResourceItem(data);
        item.itemType = this.typeService.getType(data.item_type_value);
        if (data.next_item_id) {
            item.nextItem = this.getResourceItem(data.next_item_id);
        }
        if (data.previous_item_id) {
            item.previousItem = this.getResourceItem(data.previous_item_id);
        }
        item.question$ = this.questionService.getQuestionByItem(data.id);
        item.media$ = of(item.media);

        return item;
    }
}
