<div class="chip-container" fxLayout="column" fxFlex="0 1 auto">
    <label class="filter-label mat-caption">{{ label }}</label>
    <mat-checkbox
        fxFlex="1 0 auto"
        [checked]="stateFilter?.state"
        [indeterminate]="stateFilter?.state === null"
        (click)="toggle()"
        color="primary"
    >
        {{ title }}
    </mat-checkbox>
</div>

<ng-template #activeFilterTemplate let-filter="filter">
    <mat-icon>{{ filter.icon }}</mat-icon>
    <span>{{ filter.items }}</span>
</ng-template>
