import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { Resource, ResourceCategory, ResourceService } from '@klickdata/core/resource';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-categories-assign-sheet',
    templateUrl: './categories-assign-sheet.component.html',
    styleUrls: ['./categories-assign-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesAssignSheetComponent implements OnInit, OnDestroy {
    public assignForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public saving: boolean;
    public allCategories: ResourceCategory[];

    constructor(
        public bottomSheetRef: MatBottomSheetRef<CategoriesAssignSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected message: MessageService
    ) {
        this.assignForm = this.fb.group({
            id: [],
            sync_all_categories: [],
            categories_attach: [[]],
            categories_detach: [[]],
        });
    }

    ngOnInit() {
        this.assignForm.patchValue({
            id: this.data.id,
            categories_attach: this.data.selectedCategories.map((cat) => cat.id),
        });
        // EB >> Category shall have a warning after chose more than one since its not ”tags”.
        this.assignForm
            .get('categories_attach')
            .valueChanges.pipe(takeUntil(this.destroy))
            .subscribe((attachedCats) => {
                if (attachedCats.length > this.data.selectedCategories.length + 1) {
                    this.message.openMessage(
                        MessageErrorComponent,
                        $localize`We suggest that you use multiple tags but only one (1) category since the catalog will be showing duplicates if multiple category is chosen.`
                    );
                }
            });
    }

    public submit() {
        if (this.assignForm.value.categories_attach?.length && this.allCategories?.length) {
            const mergeCategories = this.allCategories
                .filter((cat) => this.assignForm.value.categories_attach.includes(cat.id))
                .concat(this.data.selectedCategories);
            this.bottomSheetRef.dismiss(
                mergeCategories.filter((item, index, array) => array.findIndex((v2) => v2.id === item.id) === index)
            );
        } else {
            this.bottomSheetRef.dismiss();
        }
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
