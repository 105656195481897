<div *ngIf="customFields?.length">
    <mat-form-field
        floatLabel="never"
        class="selector-wrapper global-filter-select mat-form-select-main"
        fxFlex="0 1 auto"
        fxFlex.lt-md="0 1 90%"
        fxLayout="column"
    >
        <mat-select #matSelect [placeholder]="label" (selectionChange)="onSelectionChange($event.value)">
            <mat-select-trigger class="option-trigger" *ngIf="matSelect.value">
                <mat-icon class="main-icon-option">{{ getCustomFieldTypeInfo(matSelect.value.type).icon }}</mat-icon>
                <span>{{ matSelect.value.name }}</span>
            </mat-select-trigger>

            <mat-option *ngFor="let cf of customFields" [value]="cf">
                <mat-icon [matTooltip]="getCustomFieldTypeInfo(cf.type).label" class="main-icon-option">{{
                    getCustomFieldTypeInfo(cf.type).icon
                }}</mat-icon>
                <span>{{ cf.name }}</span>
            </mat-option>
        </mat-select></mat-form-field
    >
</div>

<app-custom-fields-wrapper
    *ngIf="customFieldForm.value.id"
    class="default-custom-field mt-1 mb-0"
    [customFieldForm]="customFieldForm"
></app-custom-fields-wrapper>
<ng-template #activeFilterTemplate let-filter="filter">
    <mat-icon [matTooltip]="getFilterChip(filter).tooltip">{{ getFilterChip(filter).icon }}</mat-icon>
    <span>{{ getFilterChip(filter).name }} : {{ getFilterChip(filter).value }}</span>
</ng-template>
