import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ResourceItemDirective } from '../resource-item.directive';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceService, ResourceTag } from '@klickdata/core/resource';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FormHelper } from '@klickdata/core/form';
import { ResourceItem, ResourceItemService } from '@klickdata/core/resource-item';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { ResourceListingSheetComponent } from '@klickdata/shared-components/src/resource-listing-sheet/resource-listing-sheet.component';
import { Observable, tap } from 'rxjs';

@Component({
    selector: 'app-resource-builder-event',
    templateUrl: './resource-builder-event.component.html',
    styleUrls: ['./resource-builder-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceBuilderEventComponent extends ResourceItemDirective {
    public resourceTags: Observable<ResourceTag[]>;
    constructor(
        protected resourceService: ResourceService,
        protected changeRef: ChangeDetectorRef,
        protected tagService: TagService,
        protected bottomSheet: MatBottomSheet,
        protected dialog: MatDialog,
        protected authService: AuthService,
        protected mobileService: MobileService
    ) {
        super(resourceService, changeRef, dialog, authService, mobileService);
    }

    protected afterResourceInit() {
        this.resourceTags = this.tagService.getResourceTags(this.selected.id);
    }

    public showTagResources(tag: ResourceTag) {
        this.bottomSheet.open(ResourceListingSheetComponent, {
            data: {
                id: tag.id,
                label: tag.name,
                scope_id: AppScope.TAGS,
            },
            panelClass: 'sheet-wrapper',
        });
    }
}
