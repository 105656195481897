<div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="15px">
    <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="column" fxLayoutGap="15px">
        <div class="lang-select-profile">
            <span i18n="@@displayLanguage">Display Language</span>
            <app-language-selector
                [user]="user"
                class="user-setting-display-lang"
                [showLabel]="true"
                [disableRipple]="true"
            ></app-language-selector>
        </div>
        <app-preferred-langs-select
            label="Preferred languages in content"
            placeholder="Choose language"
            i18n-placeholder
            i18n-label
            [formControl]="preferredContentLanguages"
            class="pref-lang-content user-content"
        ></app-preferred-langs-select>
        <app-user-setting-input type="switch">
            <span class="label" role="switch-label">
                <ng-container i18n="enableBankIdForLogin">
                    Keep KLMS mail client as <b>default mail sender.</b></ng-container
                >
            </span>
        </app-user-setting-input>
        <div fxHide>
            <app-user-setting-input type="switch" [switchDisabled]="true" [showLabel]="true">
                <label role="switch-title">
                    <span i18n="@@coursePlan">Course Plan</span>
                    <span class="field-description">
                        (
                        <ng-container i18n="@@youCanSetupPersonalCoursePlan"
                            >You can setup personal course plan</ng-container
                        >
                        <a class="text-orange" i18n="@@ReadMore">Read More</a>
                        )
                    </span>
                </label>
                <span class="label" role="switch-label">
                    <ng-container i18n="@@activate"> Activate</ng-container>
                    <b class="field-description" i18n="@@personalCourseplan">Perosnal courseplan</b>
                </span>
            </app-user-setting-input>

            <app-user-setting-input type="select">
                <span class="label" role="select-label">
                    <ng-container i18n="@@studyNumberOfCoursesPerPlan"> Study number of courses per month</ng-container>
                    <span class="field-description" i18n="@@defaultis4Cources">(default is 4 cources)</span>
                </span>
            </app-user-setting-input>

            <app-user-setting-input type="select">
                <span class="label" role="select-label">
                    <ng-container i18n="@@setRemainingDaysForWarningAlert">
                        Set remaining days for warning alert</ng-container
                    >
                    <span class="field-description" i18n="@@daulultis5days">(default is 5 days)</span>
                </span>
            </app-user-setting-input>

            <app-user-setting-input type="select">
                <span class="label" role="select-label">
                    <ng-container i18n="@@setRemainingDaysForCriiticalAlert">
                        Set remaining days for critical alert</ng-container
                    >
                    <span class="field-description" i18n="@@defaultis2days">(default is 2 days)</span>
                </span>
            </app-user-setting-input>
            <div class="form-field">
                <a class="text-orange" i18n="@@seeyourcourseplan">see your coursesplan</a>
            </div>
        </div>
    </div>
    <ng-container *ngIf="user && user?.id">
        <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="column" fxLayoutGap="15px">
            <app-user-perosonal-settings-fav-categories [user]="user"></app-user-perosonal-settings-fav-categories>
            <app-user-perosonal-settings-personal-interest
                [user]="user"
            ></app-user-perosonal-settings-personal-interest>
            <!-- <app-user-favorite-authors></app-user-favorite-authors> -->
        </div>
    </ng-container>
</div>
