import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { AuthService } from '@klickdata/core/auth';
import { AppValidators, FormHelper } from '@klickdata/core/form';
import { MediaType } from '@klickdata/core/media/src/media-type';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { QuestionType, ResourceMediaTypes } from '@klickdata/core/question/src/question-type/question-type';
import { AppScope, Resource, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItem, ResourceItemTypes } from '@klickdata/core/resource-item';
import { User } from '@klickdata/core/user';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { catchError, filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { ResourceBuilderService } from '../resource-builder.service';
import { QuestionService } from '../shared/question.service';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MessageService } from '@klickdata/core/message';
import { ResourceItemFormComponent } from '../resouce-items/resource-item-form.component';
import { QuestionItemFormComponent } from './question-item-form.component';
import { Media } from '@klickdata/core/media/src/media.model';

@Component({
    selector: 'app-questions-items',
    templateUrl: './questions-items.component.html',
    styleUrls: ['./questions-items.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsItemsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @Input()
    public itemFormArray: FormArray;

    @Input()
    public items: ResourceItem[];

    @Input()
    public usePoints = true;

    public reorder = false;
    /**
     * Index of expansion panel to expand.
     */
    public expanded: number;
    public allExpanded: boolean;
    private destroy: Subject<boolean> = new Subject<boolean>();
    private timeOutIDs: number[] = [];
    public published: boolean;
    @ViewChild(MatAccordion, { static: true }) accordion: MatAccordion;
    private user: User;
    @ViewChild('qs_item_ref') qsItemFormComp: QuestionItemFormComponent;
    public qsImgLoader: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public loadingUploader: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public resource: Resource;
    QuestionType = QuestionType;

    constructor(
        protected resourceBuilderService: ResourceBuilderService,
        protected fb: FormBuilder,
        protected changeRef: ChangeDetectorRef,
        protected elementRef: ElementRef,
        protected questionService: QuestionService,
        protected auth: AuthService,
        protected mobileService: MobileService,
        protected mediaService: MediaService,
        protected messageService: MessageService
    ) {
        this.auth
            .getUser()
            .pipe(takeUntil(this.destroy), first())
            .subscribe((user) => {
                this.user = user;
                this.changeRef.markForCheck();
            });
    }
    public ngOnInit(): void {
        this.itemFormArray.valueChanges
            .pipe(takeUntil(this.destroy))
            .subscribe(() => this.resourceBuilderService.updateForm(<FormGroup>this.itemFormArray.parent));

        this.resourceBuilderService
            .onCreateItem()
            .pipe(takeUntil(this.destroy))
            .subscribe((data) => this.createItem(data));

        this.resourceBuilderService
            .onSort()
            .pipe(takeUntil(this.destroy))
            .subscribe((value: boolean) => {
                this.reorder = value;
                this.expanded = -1; // colapse expanded item when start reordering.
                this.changeRef.markForCheck();
            });

        this.resourceBuilderService
            .isLoading()
            .pipe(takeUntil(this.destroy))
            .subscribe((isLoading) => {
                if (!isLoading) {
                    // Refresh item panels after saving completed, that clear removed items from displyed panels
                    this.changeRef.markForCheck();
                }
            });

        this.resourceBuilderService
            .getResource()
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => (this.resource = resource));
    }
    public ngAfterViewInit() {
        this.resourceBuilderService
            .getItemsFoldingState()
            .pipe(takeUntil(this.destroy))
            .subscribe((isAllFolded) => {
                this.allExpanded = !isAllFolded;
                isAllFolded ? this.accordion.closeAll() : this.accordion.openAll();
                this.changeRef.markForCheck();
            });
    }
    public ngOnChanges(): void {
        if (this.items && this.items.length) {
            this.updateItems(this.items);
            this.published = this.resourceBuilderService.published;
        }
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
        this.timeOutIDs.forEach((id) => clearTimeout(id));
    }

    public onOpen(index: number) {
        this.expanded = index;
    }

    public drop(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.itemFormArray.controls, event.previousIndex, event.currentIndex);
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    }

    public toggleDisable(ev: Event, item: AbstractControl) {
        ev.stopPropagation();

        if (!item.disabled) {
            item.disable();
        } else {
            item.enable();
        }
        item.markAsDirty();
    }

    public editable(item: ResourceItem) {
        return item.editable(this.user, this.resource);
    }

    public duplicate(ev: Event, item: AbstractControl) {
        ev.stopPropagation();
        // TODO handle all types
        if (this.resourceBuilderService.resource_type_id === ResourceTypes.GeneralCoursePlan) {
            this.createItem({
                item_type_value: item.get('item_type_value').value,
                name: item.get('name').value,
                mandatory: item.get('mandatory').value,
                description: item.get('description').value,
                instructions: item.get('instructions').value,
                child_resource_id: item.get('child_resource_id').value,
                child_resource_language_id: item.get('child_resource_language_id').value,
                child_resource_customer_id: item.get('child_resource_customer_id').value,
                child_resource_author_id: item.get('child_resource_author_id').value,
            });
        } else {
            const question = item.get('question');
            this.questionService
                .duplicateFormGroup(question, this.usePoints, item.get('id').value)
                .subscribe((duplicatedQuestion) => {
                    const duplicatedItem = this.createItem({
                        item_type_value: item.get('item_type_value').value,
                        questionForm: duplicatedQuestion,
                        name: item.get('name').value,
                        description: item.get('description').value,
                        learning_field: item.get('learning_field')?.value,
                        references: item.get('references')?.value,
                    });

                    FormHelper.markForm(duplicatedItem);
                });
        }
    }

    public createItem(data: {
        item_type_value?: ResourceItemTypes;
        questionForm?: FormGroup;
        name?: string;
        mandatory?: boolean;
        description?: string;
        instructions?: string;
        learning_field?: string;
        references?: string;
        child_resource_id?: number;
        child_resource_language_id?: number;
        collapse?: boolean;
        child_resource_customer_id?: number;
        child_resource_author_id?: number;
        isEmpty?: boolean;
    }): FormGroup {
        this.items = [...this.items, new ResourceItem(data)];
        const isResource = this.isItemTypeResource(data.item_type_value);

        const item = this.fb.group({
            id: [null],
            name: [data.name, AppValidators.notDelete(Validators.required)],
            description: [data.description],
            instructions: [data.instructions],
            mandatory: [
                null === data.mandatory ? data.mandatory : true,
                isResource ? AppValidators.notDelete(Validators.required) : null,
            ],
            learning_field: [data.learning_field],
            references: [data.references],
            item_type_value: [data.item_type_value, AppValidators.notDelete(Validators.required)],
            question: data.questionForm
                ? data.questionForm
                : this.fb.group({}, [
                      ResourceItemTypes.QUESTION === data.item_type_value
                          ? AppValidators.notDelete(Validators.required)
                          : null,
                  ]),
            child_resource_language_id: [
                data.child_resource_language_id,
                isResource ? AppValidators.notDelete(Validators.required) : null,
            ],

            child_resource_id: [data.child_resource_id],
            child_resource_customer_id: [data.child_resource_customer_id],
            child_resource_author_id: [data.child_resource_author_id],
        });

        // handle user can edit resource flag.
        if (
            isResource &&
            !this.user?.canEdit({
                customer_id: data.child_resource_customer_id,
                author_id: data.child_resource_author_id,
            })
        ) {
            item.get('child_resource_language_id').disable();
        }
        this.itemFormArray.push(item);

        this.onOpen(
            data.collapse || this.resourceBuilderService.selectedWOKQuestionType.getValue()
                ? -1
                : this.itemFormArray.length - 1
        );
        this.changeRef.markForCheck();
        this.timeOutIDs.push(
            window.setTimeout(() => {
                const elements = this.elementRef.nativeElement.getElementsByTagName('mat-expansion-panel');
                if (elements.length && !this.resourceBuilderService.selectedWOKQuestionType.getValue()) {
                    elements[elements.length - 1].scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest',
                    });
                }
                if (data.isEmpty) {
                    FormHelper.resetForm(item, true);
                }
            }, 300)
        );
        return item;
    }

    protected updateItems(items: ResourceItem[]) {
        this.itemFormArray.controls.splice(0, this.itemFormArray.controls.length);

        items
            .map((item) => {
                const isResource = this.isItemTypeResource(item.item_type_value);

                const group = this.fb.group({
                    id: [item.id],
                    name: [item.name, AppValidators.notDelete(Validators.required)],
                    description: [item.description],
                    instructions: [item.instructions],
                    mandatory: [item.mandatory, isResource ? AppValidators.notDelete(Validators.required) : null],
                    item_type_value: [item.item_type_value, AppValidators.notDelete(Validators.required)],

                    question: this.fb.group({}),

                    child_resource_language_id: [
                        item.child_resource_language_id,
                        isResource ? AppValidators.notDelete(Validators.required) : null,
                    ],

                    child_resource_id: [item.child_resource_id],
                    child_resource_customer_id: [item.child_resource_customer_id],
                    child_resource_author_id: [item.child_resource_author_id],
                });

                // handle user can edit resource flag.
                if (
                    isResource &&
                    !this.user?.canEdit({
                        customer_id: item.child_resource_customer_id,
                        author_id: item.child_resource_author_id,
                    })
                ) {
                    group.get('child_resource_language_id').disable();
                }

                return group;
            })
            .forEach((group) => this.itemFormArray.push(group, { emitEvent: false }));
    }

    private isItemTypeResource(type: string | ResourceItemTypes) {
        return (
            type === ResourceItemTypes.COURSE ||
            type === ResourceItemTypes.TEST ||
            type === ResourceItemTypes.MATERIAL ||
            type === ResourceItemTypes.SURVEY
        );
    }

    public displayCorrectIcon(item) {
        if (item.indexOf('test') !== -1) {
            return 'test';
        } else if (item.indexOf('survey') !== -1) {
            return 'survey';
        } else if (item.indexOf('e-course') !== -1) {
            return 'e-course';
        } else if (item.indexOf('text') !== -1) {
            return 'text';
        } else if (item.indexOf('url') !== -1) {
            return 'url';
        } else if (item.indexOf('doc') !== -1) {
            return 'doc';
        } else if (item.indexOf('question') !== -1) {
            return 'question';
        } else if (item.indexOf('material') !== -1) {
            return 'material';
        } else if (item.indexOf('course') !== -1) {
            return 'course';
        }
    }

    public getTooltipText(checked: boolean) {
        return checked
            ? $localize`:@@ChangeToComplimentaryPartOfTheCourse:Change to complimentary part of the Course`
            : $localize`:@@ChangeToMandatoryPartOfTheCourse:Change to mandatory part of the Course`;
    }

    public addMedia(item: AbstractControl, resItem: ResourceItem, index: number) {
        this.changeRef.markForCheck();
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_RESOURCE_MEDIA,
            data: {
                icon: 'add_photo_alternate',
                title: $localize`Add an image`,
                description: $localize`Please select your preferred way to add an image.`,
                type: ResourceItemTypes.QUESTION,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === ResourceItemTypes.QUESTION),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                if (data && data.value) {
                    this.loadingUploader.next(index);
                    this.initUpload(data, item as FormGroup, resItem, index);
                    item.markAsDirty();
                }
            });
    }

    private initUpload(data: any, item: FormGroup, resItem: ResourceItem, index: number) {
        this.qsImgLoader.next({ id: resItem.id, state: true });
        if (data.value === ResourceMediaTypes.FILE) {
            const question = <FormGroup>item.get('question');
            question.contains('media_id')
                ? question.patchValue({ media_id: data.mediaId })
                : question.addControl('media_id', new FormControl(data.mediaId));
            this.loadingUploader.next(null);
            question.markAsDirty();
            this.changeRef.markForCheck();
        } else {
            this.qsItemFormComp?.mediaCompRef.saving.emit(true);
            this.mediaService
                .uploadMediaByLink({
                    prompt: data.aiText,
                    type: MediaType.PROMPT,
                    scope_id: AppScope.QUESTIONS,
                })
                .pipe(
                    takeUntil(this.destroy),
                    catchError((err) => {
                        this.handleAIRequestFailure(err);
                        return of(null);
                    })
                )
                .subscribe((media) => {
                    if (media && media.id) {
                        this.qsItemFormComp?.mediaCompRef.saving.emit(false);
                        this.qsImgLoader.next(null);
                        const question = <FormGroup>item.get('question');
                        question.contains('media_id')
                            ? question.patchValue({ media_id: media.id })
                            : question.addControl('media_id', new FormControl(media.id));
                        question.markAsDirty();
                        if (resItem.media) {
                            resItem.media.type = 'gpt';
                        } else {
                            resItem.media = new Media({ type: 'gpt' });
                        }
                        this.loadingUploader.next(null);
                        this.changeRef.markForCheck();
                    }
                });
        }
    }
    handleAIRequestFailure(err) {
        this.loadingUploader.next(null);
        if (err && err.error && err.error.error) {
            this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
        }
    }
}
