import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../token/auth.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(private authService: AuthService, private router: Router, private location: Location) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkTrialValid();
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.checkTrialValid();
    }

    canLoad(route: Route): Observable<boolean> {
        return this.checkTrialValid();
    }

    protected checkTrialValid(): Observable<boolean> {
        return this.authService.getUser().pipe(
            map((user) => {
                if (user.isTrialEnded) {
                    this.router.navigate(['/guest/payment']);
                }
                return !user.isTrialEnded;
            })
        );
    }
}
