<div fxLayout="column" fxFlex="0 0 100%" class="hr-notes-wrapper">
    <button
        (click)="$event.stopPropagation(); addNote()"
        matTooltip="Add New note"
        mat-icon-button
        i18n-matTooltip
        class="note-add"
        [ngClass]="{ 'table-has-no-items': !dataSource.length }"
    >
        <mat-icon>library_add</mat-icon>
    </button>
    <!-- <mat-accordion displayMode="flat">
        <mat-expansion-panel expanded="true" #expansionPanel>
            <mat-expansion-panel-header> </mat-expansion-panel-header> -->
    <app-table-filter [cacheScope]="AppScope.USERS" [inGlobalFilter]="true" color="accent" #filter>
        <app-select-filter
            *ngIf="!customer"
            [inGlobalFilter]="true"
            class="filter-space"
            label="All Time"
            i18n-label
            [property]="GlobalFilterProperty.TIME_SPENT"
            [options]="predefinedTimeSpentOptions"
            fixedTimeSpentFilterRef
        >
        </app-select-filter>
        <app-select-filter
            *ngIf="!customer"
            [inGlobalFilter]="true"
            class="filter-space"
            label="Academy Roles"
            i18n-label="@@academyRoles"
            [property]="GlobalFilterProperty.USER_ROLE"
            [options]="userRoleOptions"
            userRolesRef
        >
        </app-select-filter>
    </app-table-filter>
    <!-- </mat-expansion-panel>
    </mat-accordion> -->
    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        matSortActive=""
        matSortDirection=""
        matSort
        [class.mobileView]="isMobile"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="notes">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Notes</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="mobile-label" i18n>Notes:</span>
                <span class="notes">{{ hrNote.body }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@date">Date</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="mobile-label" i18n="@@date">Date:</span>
                <span class="time-label">
                    {{ hrNote.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="author">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Signature</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let hrNote">
                <span class="mobile-label" i18n>Signature:</span>

                <span class="pointer notes-name" [matTooltip]="hrNote.creator?.name"
                    >{{ hrNote.author?.fname | nameSign }}{{ hrNote.author?.lname | nameSign }}</span
                >
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="btns-actions" *matCellDef="let hrNote">
                <span class="mobile-label" i18n="@@action">Action:</span>
                <button
                    *ngIf="hrNote.media_ids && hrNote.media_ids.length"
                    (click)="$event.stopPropagation(); showNoteMedia(hrNote)"
                    matTooltip="View attachment"
                    mat-icon-button
                    i18n-matTooltip="@@viewAttachment"
                    class="comment-add"
                >
                    <mat-icon>attachment</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); editNote(hrNote)"
                    matTooltip="Edit"
                    mat-icon-button
                    i18n-matTooltip="@@edit"
                    class="comment-add"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); deleteNote(hrNote)"
                    matTooltip="Delete"
                    mat-icon-button
                    i18n-matTooltip="@@delete"
                    class="comment-add"
                >
                    <mat-icon>delete</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); addComment(hrNote)"
                    matTooltip="Add comments"
                    mat-icon-button
                    i18n-matTooltip="@@addComments"
                    class="comment-add"
                >
                    <mat-icon>maps_ugc</mat-icon>
                </button>
                <button
                    *ngIf="hrNote.comments?.length"
                    (click)="$event.stopPropagation(); expandedRow = expandedRow === hrNote ? null : hrNote"
                    matTooltip="View comments"
                    mat-icon-button
                    i18n-matTooltip="@@viewComments"
                    class="expand-btn"
                >
                    <mat-icon>{{ expandedRow === hrNote ? 'expand_less' : 'expand_more' }}</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let hrNote" [attr.colspan]="columns.length">
                <div class="example-element-detail" [@detailExpand]="hrNote == expandedRow ? 'expanded' : 'collapsed'">
                    <ng-container *ngIf="hrNote.comments">
                        <app-shared-hr-notes-comments [comments]="hrNote.comments"></app-shared-hr-notes-comments>
                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let hrNote; columns: columns"
            [class.example-expanded-row]="expandedRow === hrNote"
            class="example-element-row"
            (click)="!isMobile && hrNote.comments && (expandedRow = expandedRow === hrNote ? null : hrNote)"
        ></mat-row>
        <mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            [ngClass.lt-md]="{ 'expanded-row-note': row == expandedRow }"
            class="example-detail-row"
        ></mat-row>
    </mat-table>
    <mat-paginator
        [ngClass]="{ 'table-has-no-items': !dataSource.length }"
        [pageSize]="25"
        [pageSizeOptions]="[25, 50, 100]"
    >
    </mat-paginator>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <mat-spinner fxFlex="0 0 auto"></mat-spinner>
    </div>
</ng-template>
