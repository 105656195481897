import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AccessCapability, CourseCapability } from '@klickdata/core/auth';
import { ResourceTypes, AppScope } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';

@Component({
    selector: 'app-admin-course-from',
    templateUrl: './course-from.component.html',
    styleUrls: ['./course-from.component.scss'],
})
export class CourseFromComponent {
    @Input() resourceSettings: FormGroup;
    @Input() viewMode: 'normal' | 'disabled' = 'normal';
    @Output() submit: EventEmitter<void> = new EventEmitter();
    AppScope = AppScope;
    ResourceTypes = ResourceTypes;

    public managePublicCourse: AccessCapability = CourseCapability.access(CourseCapability.MANAGE_PUBLIC_COURSES);
    currencies: { code: string; sign: string; label: string }[];

    constructor() {
        this.currencies = Utils.getAllCurrencies();
    }

    public onSubmit(): void {
        this.submit.emit();
    }

    /**
     * Remove course cover image
     */
    public removeImage(): void {
        this.resourceSettings.get('media_id').setValue(null);
    }
}
