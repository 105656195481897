import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { GradeSystem, GradeSystemData } from './grade-system.model';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { CustomerService } from '@klickdata/core/customer/src/customer.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { AuthService } from '@klickdata/core/auth';

@Injectable({
    providedIn: 'root',
})
export class GradeSystemService {
    protected api: string;
    protected customer_id: Observable<number>;

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected customerService: CustomerService,
        protected auth: AuthService
    ) {
        this.api = `${this.config.config.apiUrl}grade-systems`;
        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map(customer => customer.id)
        );
    }

    public getGradeSystem(grade_system_id: number): Observable<GradeSystem> {
        return this.builder
            .get<GradeSystemData>(`${this.api}/${grade_system_id}`)
            .request()
            .pipe(map(res => this.makeGradeSystem(res.data)));
    }

    public makeGradeSystem(data: GradeSystemData): GradeSystem {
        const gradeSystem = new GradeSystem(data);

        gradeSystem.customer = this.customerService.getCustomer(gradeSystem.customer_id);

        return gradeSystem;
    }
}
