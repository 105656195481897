import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { AppScope } from '@klickdata/core/resource';
import { FileItem, FileUploader, FileUploaderOptions, ParsedResponseHeaders } from 'ng2-file-upload';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { MediaService } from '../media.service';

@Component({
    selector: 'app-multiple-image-view',
    templateUrl: './multiple-image-view.component.html',
    styleUrls: ['./multiple-image-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultipleImageViewComponent),
            multi: true,
        },
    ],
})
export class MultipleImageViewComponent implements OnInit, ControlValueAccessor, OnChanges {
    private _mediaIds: number[];
    @Input() enableUpload: boolean;
    @Input() maxImgCount: number;
    @Input() simple: boolean;
    @Input() multiple = true;
    @Input() enableDrop = true;
    @Input() dropLabel: string;
    @Input() allowedMimeType: string[];
    @Input() uploadUrl: string;
    @Input() itemAlias: string;
    @Input() mediaIdKey = 'id';
    @Input() viewMode: 'normal' | 'disabled' = 'normal';
    @Input() uploaderOption: FileUploaderOptions;
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onError: EventEmitter<string | string[]> = new EventEmitter<string | string[]>();
    public saving$: Observable<boolean>;
    @Input() scope: AppScope;

    /**
     * FileUploader
     */
    public uploader: FileUploader;

    public get mediaIds(): number[] {
        return this._mediaIds || [];
    }

    @Input() public set mediaIds(mediaIds: number[]) {
        this._mediaIds = mediaIds;
        // this.propagateChange(this._mediaIds);
    }
    public propagateChange = (_: any) => {};
    constructor(
        protected mediaService: MediaService,
        protected auth: AuthService,
        protected token: AuthService,
        protected cd: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        if (!this.uploaderOption) {
            this.auth
                .getToken()
                .pipe(first())
                .subscribe((token) => {
                    const options: FileUploaderOptions = {
                        url: this.uploadUrl ? this.uploadUrl : this.mediaService.mediaURL,
                        autoUpload: true,
                        authToken: `Bearer ${token}`,
                        queueLimit: this.maxImgCount,
                        additionalParameter: this.scope ? { scope_id: this.scope } : {},
                    };

                    if (this.itemAlias) {
                        options.itemAlias = this.itemAlias;
                    }

                    if (this.allowedMimeType) {
                        options.allowedMimeType = this.allowedMimeType;
                    }

                    this.uploader = new FileUploader(options);
                });
        } else {
            this.uploader = new FileUploader(this.uploaderOption);
        }

        this.uploader.onCompleteItem = (
            item: FileItem,
            response: string,
            status: number,
            headers: ParsedResponseHeaders
        ) => {
            this.onComplete(item, response, status, headers);
        };

        this.uploader.onBeforeUploadItem = () => {
            this.saving.emit(true);
            this.onError.emit(null);
        };
    }

    public ngOnChanges(): void {
        if (this.allowedMimeType && this.uploader) {
            this.uploader.options.allowedMimeType = this.allowedMimeType;
        }
    }
    public writeValue(mediaIds: number[]): void {
        this._mediaIds = mediaIds;
    }
    public onComplete(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): void {
        try {
            const responseJSON = JSON.parse(response);
            if (item.isSuccess) {
                this.mediaIds.push(responseJSON.data[this.mediaIdKey]);
                this.propagateChange(this._mediaIds);
            } else if (responseJSON.error) {
                this.onError.emit(responseJSON.error.messages);
            }
        } catch (e) {
            this.onError.emit(response);
        }
        this.saving.emit(false);
        this.cd.markForCheck();
    }

    public removeImg(index: number) {
        this.mediaIds.splice(index, 1);
        this.uploader.queue[index]?.remove();
    }
    public fileOverBase(ev: any): void {}

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}
}
