import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { User } from '@klickdata/core/user';
import { Observable, forkJoin, of, switchMap, take, takeUntil } from 'rxjs';
import { AddFormativeEvaluationComponent } from '../formative-evaluation/add-formative-evaluation/add-formative-evaluation.component';
import { ResourceOpportunityService } from '@klickdata/core/opportunity';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { EditorTemplateService } from '../text-editor/editor-template.service';
import { EditorTemplate } from '../text-editor/editor-template.model';

@Component({
    selector: 'app-menu-side-resource-enrollment-approve',
    templateUrl: './menu-side-resource-enrollment-approve.component.html',
    styleUrls: ['./menu-side-resource-enrollment-approve.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideResourceEnrollmentApproveComponent {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public value = new FormControl();
    constructor() {}
}
