import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectFilterOption } from '@klickdata/core/table';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs';

@Component({
    selector: 'app-item-change-on-the-fly',
    templateUrl: './item-change-on-the-fly.component.html',
    styleUrls: ['./item-change-on-the-fly.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemChangeOnTheFlyComponent extends OnDestroyHandler implements OnInit {
    @Input() type = 'select';
    @Input() min = 0;
    @Input() max = 100;
    @Input() toolTip: string;
    @Input() capitalize: boolean;
    @Input() selectionOptions: SelectFilterOption[];
    @Input() set value(val: any) {
        this._value = val;
    }
    public numberControl = new FormControl();
    private _value: any;
    public get value(): any {
        return this._value;
    }
    @Output() onValueChange: EventEmitter<any> = new EventEmitter<any>();
    constructor() {
        super();
    }
    ngOnInit(): void {
        if (this.type == 'number') {
            this.numberControl.patchValue(this.value);
        }
        this.numberControl.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                filter((code) => !!code)
            )
            .subscribe((value) => this.onValueChange.emit(value));
    }
}
