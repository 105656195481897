import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResourceDetailsBaseComponent } from '../resource-details-base/resource-details-base.component';

@Component({
    selector: 'app-resource-details-scorm',
    templateUrl: './resource-details-scorm.component.html',
    styleUrls: ['./resource-details-scorm.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDetailsScormComponent extends ResourceDetailsBaseComponent {
    constructor() {
        super();
    }
}
