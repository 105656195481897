import { Component, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-form-error',
    templateUrl: 'form-error.component.html',
    styleUrls: ['form-error.component.scss'],
})
export class FormErrorComponent {
    @Input()
    header: string;

    @Input()
    public set messages(messages: string[] | string) {
        if (messages) {
            this._messages = [];
            if (Array.isArray(messages)) {
                for (const message of messages) {
                    if (Array.isArray(message)) {
                        this._messages = this._messages.concat(message);
                    } else {
                        this._messages.push(message);
                    }
                }
            } else {
                this._messages.push(messages);
            }
        } else {
            this._messages = messages;
        }
    }
    public get messages(): string[] | string {
        return this._messages;
    }
    public _messages: string[] | string;
    constructor(protected router: Router) {}

    /**
     * Global handling for error msg with routerLink redirect, Server control redirect specific route.
     * @param event click event.
     */
    @HostListener('click', ['$event'])
    public onClick(event) {
        if (event.target.tagName === 'A') {
            this.router.navigate([event.target.getAttribute('href')]);
            event.preventDefault();
        }
    }
}
