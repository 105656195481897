import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KdLogoLoadingComponent } from './kd-logo-loading/kd-logo-loading.component';

@NgModule({
    declarations: [KdLogoLoadingComponent],
    imports: [CommonModule],
    exports: [KdLogoLoadingComponent],
})
export class KdLoaderModule {}
