import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityLogService } from '@klickdata/core/activity-log';
import { AuthService } from '@klickdata/core/auth';
import { MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope, Resource } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { Subject, Observable } from 'rxjs';
import { shareReplay, takeUntil, map, filter } from 'rxjs/operators';
import { UserListingService } from '../../shared-action-log-users/user-listing.service';
import { Customer } from '@klickdata/core/customer';

@Component({
    selector: 'app-shared-action-log-resources-users',
    templateUrl: './shared-action-log-resources-users.component.html',
    styleUrls: ['./shared-action-log-resources-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UserListingService],
})
export class SharedActionLogResourcesUsersComponent implements OnInit, AfterViewInit, OnDestroy {
    public dataSource = new TableSource<User>();
    public columns = ['title', 'last_action', 'role', 'tools'];
    public isMobile: boolean;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    public userRoleOptions: SelectFilterOption[];
    public predefinedTimeSpentOptions: SelectFilterOption[];
    public resourceEventOptions: SelectFilterOption[];
    public expandedRow: User | null;
    public expandedCell: User | null;
    public klmsSpentTimeColTitle = $localize`Login time`;
    public coursesSpentTimeColTitle = $localize`Learning Time`;
    public isMasterPlatform$: Observable<boolean>;
    @Input() resource: Resource;
    @Input() customer: Customer;

    constructor(
        protected userService: UserListingService,
        protected mobile: MobileService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        protected router: Router,
        protected message: MessageService,
        protected route: ActivatedRoute,
        protected activityLogService: ActivityLogService
    ) {
        this.userRoleOptions = Utils.getUserRoleOptions();
        this.predefinedTimeSpentOptions = Utils.getPredefinedTimeSpentOptions();
        this.isMasterPlatform$ = auth.getNK3Platform().pipe(map((platform) => platform === 'master'));
        this.resourceEventOptions = Utils.getActionLogEventOptions().filter((option) => option.data.isResRelated);
    }
    public ngOnInit() {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((mobile) => {
                this.isMobile = mobile;
                this.cdRef.markForCheck();
            });

        this.dataSource.service = this.userService;
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        // this.dataSource.refresh();
        if (this.resource) {
            const filters = [new Filter('resource', [this.resource.id])];
            if (this.customer) {
                filters.push(new Filter('customer', [this.customer.id]));
            }
            this.filter.createOrUpdateFilter(filters);
        }
    }
    public onItemClick(filter: GlobalFilterProperty, value: string) {
        this.filter.setActiveFilterWithSelectedOption(filter, value);
    }
    public onSearchFilterClick(filter: GlobalFilterProperty, query: string) {
        this.filter.setActiveFilterWithQuery(filter, query);
    }

    public sendEmail(user: User) {
        if (user) {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.SEND_MESSAGE,
                data: { model: user, isUser: true },
            });
        }
    }
    public noteUser(user: User) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_NOTE,
            data: {
                privacy: 'user',
                user: user,
                status: 'create',
            },
        });
    }
    public viewUserResults(user: User) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_PROGRESS,
            data: {
                user: user,
                logs: this.activityLogService.getUserRelatedResourceActivities({
                    resource: this.resource.id,
                    user: user.id,
                }),
            },
        });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
