import { AppScope } from '@klickdata/core/resource';
import { SectionQuery } from './section.model';
export enum SectionTypes {
    ALL = 'all',
    STARTED = 'started',
    ASSIGNED = 'assigned',
    FAVORITE = 'favorite',
    COMPLETED = 'completed',
    RECOMMENDED = 'recommended',
}

export namespace SectionTypes {
    export function label(section_type: SectionTypes): string {
        switch (section_type) {
            case SectionTypes.ALL:
                return $localize`:@@all:All`;
            case SectionTypes.ASSIGNED:
                return $localize`:@@assigned:Assigned`;
            case SectionTypes.COMPLETED:
                return $localize`:@@completed:Completed`;
            case SectionTypes.STARTED:
                return $localize`:@@started:Started`;
            case SectionTypes.FAVORITE:
                return $localize`:@@favorite:Favorite`;
            case SectionTypes.RECOMMENDED:
                return $localize`:@@recommended:Recommended`;
        }
    }

    export function verified(type: AppScope): SectionTypes[] {
        switch (type) {
            case AppScope.SURVEY:
                return [SectionTypes.ASSIGNED, SectionTypes.FAVORITE, SectionTypes.RECOMMENDED];
            default:
                return [
                    SectionTypes.ALL,
                    SectionTypes.STARTED,
                    SectionTypes.ASSIGNED,
                    SectionTypes.COMPLETED,
                    SectionTypes.FAVORITE,
                    SectionTypes.RECOMMENDED,
                ];
        }
    }

    export function scopes(): AppScope[] {
        return [
            AppScope.COURSE,
            AppScope.SURVEY,
            AppScope.TEST,
            AppScope.MATERIAL,
            AppScope.E_COURSE,
            AppScope.EVENT,
            AppScope.TAGS,
            AppScope.CATEGORIES,
            AppScope.FOLDERS,
        ];
    }

    export function hasGenre(scope: AppScope): boolean {
        return (
            scope &&
            [
                AppScope.COURSE,
                AppScope.SURVEY,
                AppScope.TEST,
                AppScope.MATERIAL,
                AppScope.E_COURSE,
                AppScope.EVENT,
            ].some((key) => key === scope)
        );
    }
    export function mapQuery(query: SectionQuery): SectionQuery {
        const payload: SectionQuery = { scope: query.scope, sort: query.sort };
        if (hasGenre(query.scope)) {
            payload.genre = query.genre;
        } else if (query.scope === AppScope.TAGS) {
            payload.tags = query.tags;
        } else if (query.scope === AppScope.CATEGORIES) {
            payload.categories = query.categories;
        } else if (query.scope === AppScope.FOLDERS) {
            payload.folders = query.folders;
        }
        return payload;
    }
}
