<div class="res-listing-wrapper user-gen-listing action-log-users" fxLayout="column" fxFlex="0 0 100%">
    <app-table-toolbar class="toolbar" color="accent">
        <mat-accordion fxFlex="0 0 100%" displayMode="flat">
            <mat-expansion-panel [expanded]="true" #expansionPanel>
                <mat-expansion-panel-header> </mat-expansion-panel-header>
                <app-table-filter
                    [cacheScope]="AppScope.USERS"
                    [toggleFilterButtons]="true"
                    [inGlobalFilter]="true"
                    color="accent"
                    #filter
                >
                    <app-table-search
                        toggleBtnLabel="Name"
                        i18n-toggleBtnLabel
                        [inGlobalFilter]="true"
                        class="align-space"
                        searchFilterRef
                    ></app-table-search>
                    <app-select-filter
                        [inGlobalFilter]="true"
                        class="filter-space"
                        label="All Time"
                        i18n-label
                        [property]="GlobalFilterProperty.TIME_SPENT"
                        [options]="predefinedTimeSpentOptions"
                        fixedTimeSpentFilterRef
                    >
                    </app-select-filter>
                    <app-select-filter
                        [inGlobalFilter]="true"
                        class="filter-space"
                        label="Academy Roles"
                        i18n-label="@@academyRoles"
                        [property]="GlobalFilterProperty.USER_ROLE"
                        [options]="userRoleOptions"
                        userRolesRef
                    >
                    </app-select-filter>
                    <app-select-filter
                        [inGlobalFilter]="true"
                        class="filter-space"
                        label="Resource Action"
                        i18n-label="@@resourceAction"
                        [property]="GlobalFilterProperty.LOG_EVENT"
                        [options]="resourceEventOptions"
                        logEventRef
                    ></app-select-filter>
                    <ng-container *ngIf="isMasterPlatform$ | async; let isMasterPlatform">
                        <app-customer-filter
                            *ngIf="isMasterPlatform && !customer"
                            [inGlobalFilter]="true"
                            class="customer-select"
                            fxFlex="1 1 auto"
                            customerSearchRef
                        ></app-customer-filter>
                    </ng-container>
                </app-table-filter>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-progress-bar
            class="progress progress-table-loader"
            *ngIf="(dataSource.status | async) === 'loading'"
            mode="indeterminate"
        >
        </mat-progress-bar>
    </app-table-toolbar>

    <div class="table-wrapper">
        <mat-table #table [dataSource]="dataSource" matSort [class.mobileView]="isMobile">
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name"> Name </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <app-image-view
                        [ngStyle]="{ minWidth: '50px' }"
                        [isCircularImg]="true"
                        [media_id]="user.media"
                        paddingBottom="50px"
                    ></app-image-view>
                    {{ user.name }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_action">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@lastAction"> Last action </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@lastAction"> Last action</span>
                    <span *ngIf="user.last_login">{{ user.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="event">
                <mat-header-cell mat-sort-header *matHeaderCellDef>
                    <div fxLayoutAlign="start center">
                        <span i18n="@@action">Action</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let resource" class="pr-0-m">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@action">Action</span>
                    <div
                        (click)="onItemClick(GlobalFilterProperty.LOG_EVENT, resource.event)"
                        fxLayoutAlign="start center"
                        fxLayoutGap="5px"
                        class="info-wrap"
                    >
                        <mat-icon [ngStyle]="{ color: (resource.event | actionLogEvent)?.color }">
                            {{ (resource.event | actionLogEvent)?.icon }}</mat-icon
                        >
                        <span [ngStyle]="{ color: (resource.event | actionLogEvent)?.color }">
                            {{ (resource.event | actionLogEvent).title }}</span
                        >
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef i18n="@@role"> Role </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@role"> Role</span>
                    <div
                        (click)="$event.stopPropagation(); onItemClick(GlobalFilterProperty.USER_ROLE, user.role_value)"
                        fxLayout="row"
                        fxLayoutGap="5px"
                        fxLayoutAlign="start center"
                        class="pointer"
                    >
                        <mat-icon>{{ user.roleValueSpecs.icon }}</mat-icon>
                        <span class="orange">{{ user.roleValueSpecs.title }}</span>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tools">
                <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <button
                        *ngIf="user.fname"
                        class="tools-btn"
                        mat-icon-button
                        [matTooltip]="'Send message to ' + user.name"
                        (click)="$event.stopPropagation(); sendEmail(user)"
                        i18n-matTooltip
                    >
                        <mat-icon>speaker_notes</mat-icon>
                    </button>

                    <button
                        class="tools-btn orange"
                        mat-icon-button
                        (click)="$event.stopPropagation(); noteUser(user)"
                        matTooltip="Add note"
                        i18n-matTooltip
                    >
                        <mat-icon class="material-icons-outlined">note_alt</mat-icon>
                    </button>
                    <button
                        class="tools-btn orange"
                        mat-icon-button
                        (click)="$event.stopPropagation(); viewUserResults(user)"
                        [matTooltip]="'Check ' + user.name + ' actions'"
                        i18n-matTooltip
                    >
                        <mat-icon>touch_app</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row (click)="$event.stopPropagation()" *matRowDef="let resource; columns: columns"></mat-row>
        </mat-table>
    </div>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
