<div fxFlex="0 0 100%" fxLayout="column" class="evaluation-wrapper">
    <app-sticky-header
        class="sticky-header"
        context="Evaluation"
        contextIcon="school"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss()"
    ></app-sticky-header>
    <app-add-formative-evaluation #addFormEval [data]="data"></app-add-formative-evaluation>
</div>
