<button
    mat-icon-button
    i18n-matTooltip
    [matMenuTriggerFor]="userCenter"
    matTooltip="User Center"
    matTooltipShowDelay="500"
>
    <ng-container *ngIf="badgeNumber | async; let number; else: default">
        <mat-icon [matBadgeHidden]="!number" [matBadge]="number" matBadgeColor="warn" class="postIcon"
            >campaign</mat-icon
        >
    </ng-container>
</button>
<mat-menu class="menu-header-options" #userCenter="matMenu" [overlapTrigger]="false">
    <a mat-menu-item [routerLink]="['/home/messages']">
        <mat-icon>email</mat-icon>
        <ng-container i18n="@@messages">Messages</ng-container>
    </a>
    <a mat-menu-item [routerLink]="['/home/news']">
        <mat-icon>speaker_notes</mat-icon>
        <ng-container i18n="@@news">News</ng-container>
    </a>
    <a mat-menu-item [routerLink]="['/home/notes']">
        <mat-icon>note_alt</mat-icon>
        <ng-container i18n="@@notes">Notes</ng-container>
    </a>
</mat-menu>
<ng-template #default>
    <mat-icon class="postIcon">campaign</mat-icon>
</ng-template>
