<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon
            fxFlex="0 0 auto"
            color="primary"
            fontSet="klickdata"
            [fontIcon]="ResourceTypes.fontIconType(navData.data.typeId)"
            class="course-plan-icon kd-icon-24 no-shadow"
        ></mat-icon>
        <span> <span i18n>Create </span>{{ ResourceTypes.label(navData.data.typeId) }} </span>
    </div>

    <form fxLayoutGap="20px" fxLayout="column" [formGroup]="resourceForm">
        <app-user-setting-input
            type="text"
            i18n-placeholder
            placeholder="Enter title"
            [control]="resourceForm.get('title') | cast"
        >
            <label role="input-label" fxLayoutGap="4px">
                <span i18n>Title</span>
            </label>
        </app-user-setting-input>

        <div class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
            <span i18n>Summary</span>
            <mat-form-field appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                <textarea
                    #title
                    matInput
                    type="text"
                    formControlName="bullets"
                    placeholder="Enter summary"
                    i18n-placeholder
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>
        </div>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent k3-crm-tags">
            <span i18n>Tags</span>
            <app-tag-chip-select
                class="w-100"
                placeholder="Add Tag"
                [language_id]="currentLaguageId$ | async"
                i18n-placeholder
                formControlName="tag_ids"
            ></app-tag-chip-select>
        </div>
        <div class="action">
            <button
                [disabled]="!resourceForm.value.title || !resourceForm.value.bullets"
                *ngIf="!(saving | async)"
                fxFlex="0 0 auto"
                class="mobile-button"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n>Submit</span>
            </button>
            <ng-container *ngIf="saving | async">
                <app-saving-button></app-saving-button>
            </ng-container>
        </div>
    </form>
</div>
