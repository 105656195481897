import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppErrorHandler } from '@klickdata/core/application/src/app-error-handler.service';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { MobileService } from '@klickdata/core/mobile';
import {
    Resource,
    ResourceCategory,
    ResourceCategoryService,
    ResourceService,
    ResourceSocketService,
    ResourceTag,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemService } from '@klickdata/core/resource-item';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { Observable, combineLatest, merge } from 'rxjs';
import { filter, first, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-resource-details',
    templateUrl: './resource-details.component.html',
    styleUrls: ['./resource-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDetailsComponent extends OnDestroyHandler implements OnInit {
    @Input() public resourceId: number;
    public resource: Resource;
    public resourceItems$: Observable<ResourceItem[]>;
    public resourceCategories$: Observable<ResourceCategory[]>;
    public resourceTags$: Observable<ResourceTag[]>;
    public user$: Observable<User>;
    public customer$: Observable<Customer>;
    public isMob$: Observable<boolean>;
    ResourceTypes = ResourceTypes;

    constructor(
        protected resourceService: ResourceService,
        protected route: ActivatedRoute,
        protected resourceItemService: ResourceItemService,
        protected categoriesService: ResourceCategoryService,
        protected tagService: TagService,
        protected auth: AuthService,
        protected mobilesSerice: MobileService,
        protected cdr: ChangeDetectorRef,
        protected errorHandler: AppErrorHandler,
        protected resourceSocketService: ResourceSocketService
    ) {
        super();
        this.isMob$ = this.mobilesSerice.isMobile();
    }

    ngOnInit() {
        this.user$ = this.auth.getUser().pipe(startWith(new User({ role_value: 'guest' })));
        this.customer$ = this.auth.getCustomer().pipe(first());
        this.route.params
            .pipe(
                switchMap((params) =>
                    this.resourceService.getResource(this.resourceId ?? +params['id'], [
                        'managers',
                        'publishers',
                        'educators',
                        'hosts',
                        'organizers',
                        'enrollment',
                        'user_collaboration',
                    ])
                ),
                tap((resource) => {
                    this.getResourceData(resource);
                }),
                takeUntil(this.destroy)
            )
            .subscribe((res) => {
                this.resource = res;
                this.cdr.markForCheck();
                this.prepareSocket();
            });
    }
    prepareSocket() {
        this.resourceSocketService
            .listenToResourceUpdates({ res_id: this.resource.id, eager: 'enrollment' })
            .pipe(takeUntil(this.destroy))
            .subscribe((res) => (this.resource.enrollment = res.enrollment));
    }
    getResourceData(resource: Resource) {
        this.resourceItems$ = this.resourceItemService.getResourceItems(resource.id);
        this.resourceCategories$ = combineLatest([
            this.categoriesService.getResourceCategoriesByResourceId(resource.id),
            this.categoriesService.getSuggestedCategory(ResourceTypes.parentType(resource.type_id)),
        ]).pipe(
            map(([cats, defaultCat]) =>
                Utils.arraysEqual(cats, [defaultCat], false, (ai, bi) => ai.id === bi.id) ? null : cats
            )
        );
        this.resourceTags$ = this.tagService.getResourceTags(resource.id, true);
    }
}
