import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MobileService } from '@klickdata/core/mobile';
import { UsersSelectComponent } from '../users-select.component';
import { UsersService } from '../users.service';
import { Filter } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';

@Component({
    selector: 'app-users-select-has-occasions',
    templateUrl: './users-select-has-occasions.component.html',
    styleUrls: ['./users-select-has-occasions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UsersService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class UsersSelectHasOccasionsComponent extends UsersSelectComponent implements AfterViewInit {
    public columns = ['select', 'name', 'email', 'end_date', 'started_at', 'occasionStatus', 'tools'];
    @Output() onDismissBottomSheet: EventEmitter<any> = new EventEmitter<any>();
    expandedElement: User | null;

    constructor(
        protected datatableService: UsersService,
        protected mobileService: MobileService,
        protected auth: AuthService
    ) {
        super(datatableService, mobileService, auth);
    }
    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (this.assignId) {
            this.filter.createOrUpdateFilter([new Filter('assign', [this.assignId])]);
        }
    }

    public getUserStatus(status: string): { status: string; color: string; icon: string } {
        switch (status) {
            case 'done':
                return { status: $localize`:@@completedSingular:Completed`, color: '#3e5365', icon: 'task_alt' };
            case 'completed':
                return { status: $localize`:@@done:Done`, color: '#3e5365', icon: 'done_all' };
            case 'ongoing':
                return { status: $localize`:@@ongoing:Ongoing`, color: '#ff9961', icon: 'cached' };
            case 'not_started':
                return { status: $localize`:@@notStarted:Not Started`, color: '#93cbd1', icon: 'directions_run' };
            case 'overdue':
                return { status: $localize`:@@overdue:Overdue`, color: '#e44a66', icon: 'event_busy' };
            case 'cancelled':
                return { status: $localize`:@@cancelled:Cancelled`, color: '#e44a66', icon: 'cancel' };
            case 'deleted':
                return { status: $localize`:@@deleted:Deleted`, color: '#e44a66', icon: 'delete_forever' };
            default:
                return {
                    status: $localize`:@@notAssigned:Not assigned`,
                    color: '#3e5365',
                    icon: 'cancel_presentation',
                };
        }
    }
}
