<div *ngIf="navData" fxLayoutGap="10px" class="data-wrap pt-0" fxLayout="column">
    <div class="title mt-0" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon class="title-icon">assignment_turned_in</mat-icon>
        <div class="title-label" fxFlex="grow">
            <span i18n> Completed </span>
            <span>{{ ResourceTypes.label(navData.data.resource.type_id) }}</span>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        You didn't signoff this {{ResourceTypes.label(navData.data.resource.type_id)}} when you took it. What do you
        want to do?
    </div>
    <mat-radio-group
        [formControl]="signoffOption"
        class="course-sett-not"
        fxLayout="column"
        fxLayoutGap="15px"
        aria-label="Select an option"
    >
        <mat-radio-button value="signOff">Sign off and mark as Completed</mat-radio-button>
        <mat-radio-button value="continue">I am not done yet</mat-radio-button>
        <mat-radio-button value="restart">I'd like to restart from the beginning</mat-radio-button>
    </mat-radio-group>
</div>
