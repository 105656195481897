import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-soft-delete-dialog',
    templateUrl: './soft-delete-dialog.component.html',
    styleUrls: ['./soft-delete-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftDeleteDialogComponent {
    public title: string;
    public body: string;
    constructor(
        @Inject(MAT_DIALOG_DATA)
        protected data: {
            title: string;
            body: string;
            type: string;
        }
    ) {
        const type = data?.type ?? $localize`item`;
        this.title = data?.title ?? $localize`Remove ${type}?`;
        this.body =
            data?.body ?? $localize`You are about to soft delete this ${type}, You will be able to restore it again.`;
    }
}
