<div *ngIf="breadcrumbs.length" fxLayout="row">
    <ol fxFlex="0 0 auto" class="breadcrumb">
        <li *ngFor="let item of breadcrumbs; let i = index" [class.active]="i === breadcrumbs.length - 1">
            <label *ngIf="i !== breadcrumbs.length - 1" (click)="select(i + 1)">
                {{ item.title }}
            </label>
            <span *ngIf="i === breadcrumbs.length - 1">{{ item.title }}</span>
        </li>
    </ol>

    <div fxFlex="1 1 100%"></div>

    <!-- back button -->
    <button class="back-icon" mat-icon-button fxFlex="0 0 auto" (click)="select(breadcrumbs.length - 1)">
        <mat-icon>subdirectory_arrow_left</mat-icon>
    </button>
</div>

<ng-template #activeFilterTemplate let-filter="filter">
    <mat-icon>{{ filter.icon }}</mat-icon>
    <span>{{ filter.items }}</span>
</ng-template>
