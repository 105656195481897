import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@klickdata/core/auth';
import { RecorderTypes } from '@klickdata/core/media';
import { RecorderService, RecordResultsMetaData } from '@klickdata/core/media/src/recorder.service';
import { MobileService } from '@klickdata/core/mobile';
import { takeUntil } from 'rxjs/operators';
import { RecorderBase } from '../recorder-base';

@Component({
    selector: 'app-recorder-video',
    templateUrl: './recorder-video.component.html',
    styleUrls: ['./recorder-video.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecorderVideoComponent extends RecorderBase {
    public recorderConfig: any;
    public recordResultsMetaData: RecordResultsMetaData;
    constructor(
        protected snackBar: MatSnackBar,
        protected recordingService: RecorderService,
        protected cdr: ChangeDetectorRef,
        protected auth: AuthService,
        protected mobile: MobileService,
        protected platform: Platform
    ) {
        super(snackBar, recordingService, auth, mobile, platform);
        this.recorderConfig = {
            type: 'video',
            mimeType: 'video/webm',
            canvas: {
                width: 1280,
                height: 720,
            },
        };
        this.recordResultsMetaData = {
            title: $localize`KLMS_recorded_video`,
            extension: 'webm',
            type: 'video/webm',
        };
    }

    public startRecorder(canRecordUnlimited: boolean) {
        if (this.recordingService.recorder) {
            return;
        }
        if (this.isMobile) {
            this.mobile.toggleMenu();
        }

        const microphone = 'microphone' as PermissionName;
        const camera = 'camera' as PermissionName;
        this.haveRequestedPermissions([{ name: microphone }, { name: camera }])
            .pipe(takeUntil(this.destroy))
            .subscribe((havePermissions: boolean) => {
                if (!havePermissions) {
                    return;
                } else {
                    this.recordingService.activeRecordingType = RecorderTypes.VIDEO;
                    return new Promise((resolve, reject) => {
                        var browser = <any>navigator;
                        browser.mediaDevices
                            .getUserMedia({
                                audio: true,
                                video: { facingMode: 'user' },
                            })
                            .then((camera: MediaStream) => {
                                this.recordingService.record(
                                    camera,
                                    this.recorderConfig,
                                    this.recordResultsMetaData,
                                    !canRecordUnlimited,
                                    camera
                                );
                                this.showSnackBar();
                                this.cdr.markForCheck();
                                resolve(this.stream);
                            })
                            .catch((error: any) => {
                                this.initPermissionWarningSlider({
                                    body: $localize`Pls give KlickData needed camera permissions from your browser/system security settings to record and then reload the page`,
                                });
                            });
                    });
                }
            });
    }
}
