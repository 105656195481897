import { CommonModule } from '@angular/common';
import { ApplicationModule, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from '@klickdata/core/auth';
import { ConfigModule } from '@klickdata/core/config';
import { HttpModule } from '@klickdata/core/http';
import { MobileModule } from '@klickdata/core/mobile';
import { MaterialModule } from '@klickdata/material';
import { CustomFieldsCheckboxTypeComponent } from './custom-fields-checkbox-type/custom-fields-checkbox-type.component';
import { CustomFieldsDateTypeComponent } from './custom-fields-date-type/custom-fields-date-type.component';
import { CustomFieldsDropdownTypeComponent } from './custom-fields-dropdown-type/custom-fields-dropdown-type.component';
import { CustomFieldsFileTypeComponent } from './custom-fields-file-type/custom-fields-file-type.component';
import { CustomFieldsInputTypeComponent } from './custom-fields-input-type/custom-fields-input-type.component';
import { CustomFieldsRadioTypeComponent } from './custom-fields-radio-type/custom-fields-radio-type.component';
import { CustomFieldsSwitchTypeComponent } from './custom-fields-switch-type/custom-fields-switch-type.component';
import { CustomFieldsWrapperComponent } from './custom-fields-wrapper/custom-fields-wrapper.component';
import { CastPipe } from './cast.pipe';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        AuthModule,
        ConfigModule,
        FormsModule,
        ReactiveFormsModule,
        ApplicationModule,
        MobileModule,
        FlexLayoutModule,
        MaterialModule,
    ],
    declarations: [
        CustomFieldsWrapperComponent,
        CustomFieldsSwitchTypeComponent,
        CustomFieldsRadioTypeComponent,
        CustomFieldsInputTypeComponent,
        CustomFieldsFileTypeComponent,
        CustomFieldsDropdownTypeComponent,
        CustomFieldsDateTypeComponent,
        CustomFieldsCheckboxTypeComponent,
        CastPipe,
    ],
    exports: [
        CustomFieldsWrapperComponent,
        CustomFieldsSwitchTypeComponent,
        CustomFieldsRadioTypeComponent,
        CustomFieldsInputTypeComponent,
        CustomFieldsFileTypeComponent,
        CustomFieldsDropdownTypeComponent,
        CustomFieldsDateTypeComponent,
        CustomFieldsCheckboxTypeComponent,
        CastPipe,
    ],
})
export class CustomFieldsModule {}
