import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../token/auth.service';

@Injectable({
    providedIn: 'root',
})
export class GuestGuard implements CanActivate, CanLoad {

    constructor(
        protected authService: AuthService,
        protected router: Router
    ) {
    }

    public canActivate(): Observable<boolean> {
        return this.authService.check().pipe(
            map(authenticated => {
                // If authenticated redirect
                if (authenticated) {
                    this.redirect();
                }

                return !authenticated;
            }));
    }

    public canLoad(route: Route): Observable<boolean> {
        return this.canActivate();
    }

    protected redirect(): void {
        this.router.navigate(['home']);
    }

}
