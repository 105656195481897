import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { Resource, AppScope } from '@klickdata/core/resource';

@Component({
    selector: 'app-add-ecourse',
    templateUrl: './add-ecourse.component.html',
    styleUrls: ['./add-ecourse.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEcourseComponent implements OnInit {
    @Input() selection: number[] = [];
    @Output() add: EventEmitter<Resource> = new EventEmitter<Resource>();
    AppScope = AppScope;
    constructor() {}

    ngOnInit(): void {}
}
