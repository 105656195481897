<google-map (mapClick)="addMarker($event)" width="100%" class="go-map" [options]="mapOptions">
    <map-marker
        #marker="mapMarker"
        *ngIf="markerPosition"
        [position]="markerPosition"
        [options]="markerOptions"
    ></map-marker>
    <!-- <map-info-window>
        <div class="info-wrapper">
            <span class="title">{{ infoWindowTitle }}</span>
            <span>{{ address }}</span>
        </div>
    </map-info-window> -->
</google-map>
