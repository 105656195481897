import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { MobileService } from '@klickdata/core/mobile';
import { Alternative } from '@klickdata/core/question';
import { AppScope } from '@klickdata/core/resource';
import { Observable, Subscription } from 'rxjs';
import { ResourceBuilderService } from '../../../../resource-builder.service';

@Component({
    selector: 'app-resource-builder-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit, AfterViewInit {
    @ViewChild('title') titleInput: ElementRef;
    @Input()
    public alternative: Alternative;

    @Input()
    public alternativeForm: FormGroup;

    @Input()
    public editable: boolean;
    @Output() onShowExplaination: EventEmitter<boolean> = new EventEmitter<boolean>();
    public changeSub: Subscription;
    // public published: boolean;
    public isMobile: Observable<boolean>;
    AppScope = AppScope;

    constructor(
        protected changeRef: ChangeDetectorRef,
        protected builder: ResourceBuilderService,
        protected mobile: MobileService
    ) {}
    ngOnInit() {
        this.isMobile = this.mobile.isMobile();
        this.changeSub = this.alternativeForm.valueChanges.subscribe(() => {
            this.changeRef.markForCheck();
        });
        // this.published = this.builder.published;
        /**
         * EB asked on 221215 for >> the qs can be edited (if permitted)
         * even if published
         */
        // if (this.published) {
        //     this.alternativeForm.get('points').disable();
        //     this.alternativeForm.get('correct').disable();
        // }
        if (this.alternativeForm.disabled || !this.editable) {
            this.alternativeForm.get('correct').disable();
        }
    }

    ngAfterViewInit() {
        this.titleInput?.nativeElement?.focus();
    }

    public onToggle() {
        if (!this.alternativeForm.disabled) {
            this.alternativeForm.disable();
        } else {
            this.alternativeForm.enable();
        }
        FormHelper.markForm(this.alternativeForm);
    }

    public removeImage() {
        this.alternativeForm.patchValue({ media_id: null });
        FormHelper.markForm(this.alternativeForm);
    }

    public updateValue(ev) {
        if (!this.alternative && this.alternativeForm.get('points').untouched) {
            if (ev.checked) {
                this.alternativeForm.patchValue({ points: 1 }, { emitEvent: false });
            } else {
                this.alternativeForm.patchValue({ points: 0 }, { emitEvent: false });
            }
            FormHelper.markForm(this.alternativeForm);
        }
    }
}
