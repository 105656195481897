<div class="wrapper" fxLayout="column" *ngIf="resLogs$ | async; let logs; else: loading">
    <ng-container *ngFor="let log of $any(logs)">
        <div *ngIf="!(isMobile$ | async)" fxLayout="row" class="table-body">
            <div
                *ngIf="log.event | actionLogEvent as event"
                fxLayoutAlign="start center"
                fxLayoutGap="8px"
                class="info-wrap"
            >
                <mat-icon>{{ event.icon }}</mat-icon>
                <span class="fz-11"> {{ log.action }}:</span>
                <span class="fz-11">{{ log.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                <br />
            </div>
            <div
                *ngIf="log.author && (log.author.fname || log.author.lname)"
                fxLayoutAlign="start start"
                fxLayoutGap="3px"
                class="author-wrap fz-11"
            >
                <span i18n>By:</span>
                <span>{{ log.author?.fname }} {{ log.author?.lname }}</span>
            </div>
            <!-- <div class="activity-title fz-11" *ngIf="!log.isResource">{{ log.subject_title }}</div> -->
        </div>
        <div *ngIf="isMobile$ | async" fxLayout="column" class="table-body">
            <div
                *ngIf="log.event | actionLogEvent as event"
                fxLayoutAlign="space-between center"
                fxLayoutGap="8px"
                class="info-wrap"
            >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon>{{ event.icon }}</mat-icon>
                    <div class="fz-11">{{ log.action }}:</div>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <div class="fz-11" i18n>On</div>
                    <div class="fz-11">{{ log.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</div>
                </div>
            </div>
            <div
                *ngIf="log.author && (log.author.fname || log.author.lname)"
                fxLayoutAlign="start start"
                fxLayoutGap="3px"
                class="author-wrap fz-11"
            >
                <span i18n>By:</span>
                <span>{{ log.author?.fname }} {{ log.author?.lname }}</span>
            </div>
            <!-- <div class="activity-title fz-11" *ngIf="!log.isResource">{{ log.subject_title }}</div> -->
        </div>
    </ng-container>
</div>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
