import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Optional,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { IModel } from '@klickdata/core/application';
import { ResourceCollectSheetComponent } from '@klickdata/shared-components/src/resource-assignment/resource-collect-sheet/resource-collect-sheet.component';
import { Observable } from 'rxjs';
import { SectionSelectComponent } from '../section-select.component';

@Component({
    selector: 'app-section-select-sheet',
    templateUrl: './section-select-sheet.component.html',
    styleUrls: ['./section-select-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionSelectSheetComponent implements OnInit {
    @ViewChild(SectionSelectComponent) selectComponent: SectionSelectComponent;
    @Input() data: any;
    @Input() hideSticky: boolean;
    public assignForm: FormGroup;
    @Output()
    public submit: EventEmitter<any> = new EventEmitter();
    public saving: boolean;

    constructor(
        @Optional() public bottomSheetRef: MatBottomSheetRef<ResourceCollectSheetComponent>,
        @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public sheetData: any,
        protected fb: FormBuilder
    ) {
        this.assignForm = this.fb.group({
            id: [],
            sync_all_sections: [],
            sections_attach: [[]],
            sections_detach: [[]],
        });
    }

    ngOnInit() {
        this.data = this.data ?? this.sheetData;
        this.assignForm.patchValue({ id: this.data.id });
    }

    public updateForm() {
        this.saving = false;
        this.assignForm.reset({ id: this.data.id });
        this.bottomSheetRef?.dismiss(this.data);
    }

    public onSubmit() {
        if (
            this.assignForm.value.sections_attach?.length ||
            this.assignForm.value.sections_detach?.length ||
            this.assignForm.value.sync_all_sections !== null
        ) {
            this.saving = true;
            const data = this.assignForm.value;
            this.submit.next(data);
        } else {
            this.bottomSheetRef?.dismiss(this.data);
        }
    }

    public get formData() {
        return this.assignForm.value;
    }

    public canDeactivate(): boolean {
        return this.selectComponent ? this.selectComponent.canDeactivate() : true;
    }

    public dirty(): boolean {
        return !this.canDeactivate();
    }
}
