<div fxLayoutAlign="space-between start" fxLayout="row">
    <app-table-filter [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-table-date-filter [inGlobalFilter]="true" color="accent" dateFilterRef></app-table-date-filter>
        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Filter by selection"
            i18n-label="@@filterBySelection"
            [property]="GlobalFilterProperty.SELECTED"
            [options]="itemSelectedOptions"
            selectedItemsRef
        >
        </app-select-filter>
    </app-table-filter>
    <button
        *ngIf="AdditionBtn"
        class="add-btn"
        add-btn
        mat-button
        fxLayoutAlign="center center"
        type="button"
        color="accent"
        mat-raised-button
        (click)="addCollection()"
    >
        <mat-icon>add</mat-icon>
        <ng-container i18n="@@addSection">Add Collection</ng-container>
    </button>
</div>

<div fxLayoutAlign="start center" fxLayoutGap="10px" class="selection-Info">
    <div fxLayoutAlign="start center" fxLayoutGap="5px" class="main">
        <span i18n="@@numerOfSections" class="label">Number of collections:</span>
        <span class="total">{{ total }}</span>
    </div>
    <div fxLayoutAlign="start center" fxLayoutGap="5px" class="selected" *ngIf="selected">
        <span i18n="@@selected">Selected:</span>
        {{ selected }}
    </div>
</div>

<mat-progress-bar
    class="progress progress-table-loader"
    *ngIf="(dataSource.status | async) === 'loading'"
    mode="indeterminate"
>
</mat-progress-bar>
<mat-table #table [dataSource]="dataSource" matSort matSortActive="updated_at" matSortDirection="desc">
    <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="masterToggle()"
                [checked]="isChecked()"
                [indeterminate]="isIndeterminate()"
            >
            </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let collection">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="selectToggle(collection)"
                [checked]="modelChecked(collection)"
            >
            </mat-checkbox>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
        <mat-cell *matCellDef="let collection">{{ collection.title }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="updated_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@updated"> Updated at </mat-header-cell>
        <mat-cell *matCellDef="let collection">
            {{ collection.updated_at | kdDateFormat: 'YYYY-MM-DD' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="created_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@created"> Created at </mat-header-cell>
        <mat-cell *matCellDef="let collection">
            {{ collection.created_at | kdDateFormat: 'YYYY-MM-DD' }}
        </mat-cell>
    </ng-container>
    <!-- <ng-container matColumnDef="default">
        <mat-header-cell *matHeaderCellDef i18n="@@default"> Default </mat-header-cell>
        <mat-cell *matCellDef="let collection">
            <mat-slide-toggle
                fxFlex="5"
                (click)="$event.stopPropagation()"
                (change)="handleDefaultToggle($event.checked, collection)"
                class="example-margin"
                [checked]="collection.default"
            >
            </mat-slide-toggle>
        </mat-cell>
    </ng-container> -->
    <ng-container matColumnDef="tools">
        <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
        <mat-cell *matCellDef="let collection" (click)="$event.stopPropagation()">
            <ng-container>
                <button
                    class="tools-btn"
                    matTooltip="view"
                    mat-icon-button
                    (click)="$event.stopPropagation(); showSections(collection)"
                    i18n-matTooltip="@@view"
                >
                    <mat-icon>search</mat-icon>
                </button>
            </ng-container>
            <ng-container>
                <button
                    class="tools-btn"
                    matTooltip="edit"
                    i18n-matTooltip="@@edit"
                    mat-icon-button
                    (click)="$event.stopPropagation(); editCollection(collection)"
                >
                    <mat-icon>edit</mat-icon>
                </button>
            </ng-container>
            <ng-container>
                <button
                    class="tools-btn"
                    matTooltip="Delete collection"
                    mat-icon-button
                    (click)="$event.stopPropagation(); deleteCollection(collection)"
                    i18n-matTooltip="@@deleteSection"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-container>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let collection; columns: columns" (click)="selectToggle(collection)"></mat-row>
</mat-table>

<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
