import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { interval, map, Observable, Subscription, take, tap, timer } from 'rxjs';
import { OnDestroyHandler } from '../../onDestroy-handler/onDestroy-handler';

@Component({
    selector: 'app-dialog-token-expiration-countdown',
    templateUrl: './dialog-token-expiration-countdown.component.html',
    styleUrls: ['./dialog-token-expiration-countdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogTokenExpirationCountdownComponent extends OnDestroyHandler implements OnInit {
    countdownSub: Subscription;
    countdown$: Observable<number>;
    constructor(
        public dialogRef: MatDialogRef<DialogTokenExpirationCountdownComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
    }

    ngOnInit(): void {
        this.countdown$ = timer(0, 1000).pipe(
            take(this.data.period + 1),
            map((value) => this.data.period - value),
            tap((value) => this.handleWhenCountEnds(value))
        );
    }

    public handleWhenCountEnds(value: number) {
        if (value < 1) {
            this.logout();
        }
    }

    public refeshToken() {
        this.dialogRef.close(1);
    }

    public logout() {
        this.dialogRef.close();
    }
}
