<form [formGroup]="taskForm" *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>today</mat-icon>
        <div *ngIf="navData.data.type === 'updateTask'" i18n>Update task</div>
        <div *ngIf="navData.data.task?.parentId && navData.data.type === 'addTask'" i18n>Add a sub task</div>
        <div *ngIf="!navData.data.task?.parentId && navData.data.type === 'addTask'" i18n>Add a new task</div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
            <span i18n>Task*</span>
            <mat-form-field cdkTrapFocus appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                <textarea
                    cdkFocusInitial
                    #taskInput
                    matInput
                    type="text"
                    formControlName="body"
                    placeholder="Task"
                    i18n-placeholder
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="50"
                    required
                ></textarea>

                <mat-error i18n>Task text field is required!</mat-error>
            </mat-form-field>
        </div>
        <mat-checkbox formControlName="private" color="primary" i18n="@@private">Private</mat-checkbox>
        <div fxLayoutGap="10px" fxLayout="column" class="w-100" formGroupName="staff">
            <app-user-chip-select
                titleLabel="Signature"
                i18n-titleLabel
                formControlName="manager"
            ></app-user-chip-select>
            <app-user-chip-select
                titleLabel="Assignee"
                i18n-titleLabel
                [searchUsersParams]="{ limit: 20, userRole: 'superadmin' }"
                formControlName="assignee"
            ></app-user-chip-select>
        </div>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>Priority</span>
            <mat-form-field class="role-field">
                <mat-select fxFlex="1 1 100%" formControlName="priority">
                    <mat-option value="a1" i18n>A1</mat-option>
                    <mat-option value="a2" i18n>A2</mat-option>
                    <mat-option value="a3" i18n>A3</mat-option>
                    <mat-option value="b1" i18n>B1</mat-option>
                    <mat-option value="b2" i18n>B2</mat-option>
                    <mat-option value="b3" i18n>B3</mat-option>
                    <mat-option value="c1" i18n>C1</mat-option>
                    <mat-option value="c2" i18n>C2</mat-option>
                    <mat-option value="c3" i18n>C3</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div
            *ngIf="navData.data.type != 'updateTask'"
            class="input-field-parent notes"
            fxLayout="column"
            fxLayoutGap="5px"
        >
            <span i18n>Note</span>
            <mat-form-field cdkFocusInitial appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                <textarea
                    #taskInput
                    matInput
                    type="text"
                    formControlName="note"
                    placeholder="Note"
                    i18n-placeholder
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="4"
                    cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>
        </div>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent k3-crm-tags">
            <span i18n>Tags</span>
            <app-tag-chip-select
                class="w-100"
                placeholder="Add Tag"
                [language_id]="currentLaguageId$ | async"
                i18n-placeholder
                formControlName="tag_ids"
            ></app-tag-chip-select>
        </div>

        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>Action</span>
            <mat-form-field class="role-field">
                <mat-select fxFlex="1 1 100%" formControlName="action">
                    <mat-option value="call" i18n>Call</mat-option>
                    <mat-option value="email" i18n>Email</mat-option>
                    <mat-option value="followup" i18n>Follow Up</mat-option>
                    <mat-option value="other" i18n>Other</mat-option>
                    <mat-option value="demo" i18n>Demo</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <app-customer-chip-select
            class="w-100"
            titleLabel="Academy"
            i18n-titleLabel
            formControlName="customer_ids"
        ></app-customer-chip-select>

        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
            <span i18n>Deadline</span>
            <mat-form-field
                appearance="outline"
                floatLabel="never"
                class="w-100"
                fxFlex="grow"
                fxFlex="1 1 100%"
                fxFlex.lt-md="1 1 100%"
            >
                <mat-label i18n>Enter deadline date</mat-label>
                <input
                    matInput
                    [matDatepicker]="deadlineDatepicker"
                    (focus)="deadlineDatepicker.open()"
                    formControlName="deadline"
                />
                <mat-datepicker-toggle matIconSuffix [for]="deadlineDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #deadlineDatepicker></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <div class="action" fxLayout="row-reverse wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-container *ngIf="!(onLoading | async)">
            <button
                [disabled]="taskForm.invalid"
                fxFlex="0 0 auto"
                class="mobile-button custom"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n *ngIf="task?.id">Update</span>
                <span i18n *ngIf="!task?.id">Create</span>
            </button>
        </ng-container>
        <app-kd-logo-loading *ngIf="onLoading | async" size="small"></app-kd-logo-loading>
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit(); taskForm.reset()" mat-button>
            <span i18n>Cancel</span>
        </button>
    </div>
</form>
