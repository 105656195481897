import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@klickdata/core/config';
import { ApplicationModule } from '@klickdata/core/application';
import { HttpModule } from '@klickdata/core/http';

@NgModule({
    imports: [
        CommonModule,

        HttpModule,
        ConfigModule,
        ApplicationModule,
    ],
})
export class ResourceModule {
}
