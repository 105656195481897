import { Injectable } from '@angular/core';
import { Customer, CustomerData, CustomerService } from '@klickdata/core/customer';
import { PaginatorResponse } from '@klickdata/core/http';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomerSelectDatatableService extends CustomerService implements TableHttpService<Customer> {
    public fetchData(source: TableSource<Customer>): Observable<PaginatorResponse<Customer[]>> {
        const req = this.builder.get<CustomerData[]>(this.resourceUrl);

        req.param('referenceCount', 1);
        // req.param('customer', this.config.config.klickdataCustomerId);

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<CustomerData[]>) => {
                return {
                    data: res.data.map(item => this.createCustomer(item)),
                    notify: res.notify,
                    paginator: res.paginator,
                    more: res.more,
                };
            })
        );
    }
}
