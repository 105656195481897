import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CanComponentDeactivate } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { Resource, ResourceData, ResourceService } from '@klickdata/core/resource';
import { Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-course-settings',
    templateUrl: './course-settings.component.html',
    styleUrls: ['./course-settings.component.scss'],
})
export class CourseSettingsComponent implements OnInit, OnDestroy, CanComponentDeactivate {
    @Input() resource: Resource;
    @Input() showInDialog: boolean;
    @Output() saving: EventEmitter<boolean> = new EventEmitter();
    @Output() onDismss: EventEmitter<boolean> = new EventEmitter();
    public saved: boolean;
    public resourceSettings: FormGroup;
    public changeSub: Subscription;
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.route.parent?.params
            .pipe(
                switchMap((params) => this.resourceService.getResource(+params['id'])),
                takeUntil(this.destroy)
            )
            .subscribe((resource) => {
                this.updateForm(resource);
            });

        this.resourceSettings = this.fb.group({
            article_code: [null, Validators.required],
            title: [null, Validators.required],
            description: [null, Validators.required],
            public: [null, Validators.required],
            language_id: [null, Validators.required],
            author_name: [null, Validators.required],
            category_ids: [],
            price: null,
            currency: null,
            media_id: null,
            id: null,
        });

        if (this.resource) {
            this.updateForm(this.resource);
        }
    }

    public updateForm(resource: Resource): void {
        this.resource = resource;
        this.resourceSettings.reset({
            article_code: resource.article_code,
            title: resource.title,
            description: resource.description,
            author_name: resource.author_name,
            language_id: resource.language_id,
            public: resource.public,
            media_id: resource.media_id || resource.media?.url,
            currency: resource.currency,
            price: resource.price,
            category_ids: resource.category_ids,
            id: resource.id,
        });
    }

    public onSubmit(): void {
        this.saving.next(true);
        this.resourceService
            .update(this.prepareForm())
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => {
                this.updateForm(resource);
                FormHelper.resetForm(this.resourceSettings);
                this.saving.next(false);
                this.onDismss.next(true);
                this.saved = true;
            });
    }

    public onChange(): void {
        this.saved = false;
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public canDeactivate(): boolean {
        return this.resourceSettings.pristine;
    }

    /**
     * Prepare form data for creation.}
     */
    protected prepareForm(): ResourceData {
        return this.resource.getPayload(this.resourceSettings.value);
    }
}
