import { AccessCapability } from '../can-access.directive';

export namespace TestCapability {
    export const NONE = 0;
    export const VIEW_ALL_TESTS = 1 << 0;
    export const VIEW_CUSTOMER_TESTS = 1 << 1;
    export const MANAGE_TESTS = 1 << 3;

    // Tests - Calculated/Settings
    export const VIEW_ALL_GENERAL_TESTS = 1 << 4;
    export const VIEW_SPECIFIC_GENERAL_TESTS = 1 << 5;
    export const VIEW_ALL_COURSE_TESTS = 1 << 6;
    export const VIEW_SPECIFIC_COURSE_TESTS = 1 << 7;
    export const VIEW_ALL_INTERNAL_TESTS = 1 << 8;
    export const VIEW_SPECIFIC_INTERNAL_TESTS = 1 << 9;
    export const MANAGE_GENERAL_TESTS = 1 << 10;
    export const MANAGE_COURSE_TESTS = 1 << 11;
    export const MANAGE_INTERNAL_TESTS = 1 << 12;
    export const MANAGE_PUBLIC_TESTS = 1 << 13;
    export const MANAGE_PUBLIC_TESTS_CATEGORIES = 1 << 14;

    export function getName(): string {
        return 'test';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
