<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div fxLayoutAlign="space-between center" fxLayout="row" fxFlex="grow">
        <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon class="{{ navData.data.iconClass }}">{{ navData.data.icon }}</mat-icon>
            <div>{{ navData.data.title }}</div>
        </div>
        <app-ai-model-selector #modelSelector></app-ai-model-selector>
    </div>
    <div class="desc">{{ navData.data.contentBody }}</div>
    <app-ai-prompter-manager
        [model_name]="modelSelector.model"
        (onListMaterial)="onClose.emit({ clearCompData: true })"
        (onMaterialCreated)="onCreateMaterial($event)"
        (onMaterialEdited)="onEditMaterial($event)"
    ></app-ai-prompter-manager>
</div>
