import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Host,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { HttpErrorService } from '@klickdata/core/http';
import { RecordingOutput } from '@klickdata/core/media/src/media-type';
import { MediaViewComponent } from '@klickdata/core/media/src/media-view/media-view.component';
import { Media } from '@klickdata/core/media/src/media.model';
import { S3MediaService } from '@klickdata/core/media/src/s3-media.service';
import { AppScope, ResourceService } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util/src/utils';
import { VTQ, VTQService } from '@klickdata/core/vtq';
import { BehaviorSubject, Observable, Subject, forkJoin } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { MaterialItemComposerDirective } from '../material-item-composer-directive';
import { MaterialsManagerCoreComponent } from '../materials-manager-core.component';
import { RecorderService } from '@klickdata/core/media/src/recorder.service';
import { ResourceItemData } from '@klickdata/core/resource-item';

@Component({
    selector: 'app-materials-manager-file',
    templateUrl: './materials-manager-file.component.html',
    styleUrls: ['./materials-manager-file.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class MaterialsManagerFileComponent extends MaterialItemComposerDirective implements AfterViewInit, OnChanges {
    @ViewChild('fileInput') fileInput!: ElementRef;
    @ViewChild('mediaView') mediaView: MediaViewComponent;
    @Output() onSavingFile: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onCreateNewItems: EventEmitter<ResourceItemData[]> = new EventEmitter<ResourceItemData[]>();
    Utils = Utils;
    AppScope = AppScope;
    public materialFileType: BehaviorSubject<string> = new BehaviorSubject<string>('File');
    public canDownloadControl = new FormControl(true);
    public progress$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public showChapters: Subject<boolean> = new Subject<boolean>();

    dropSize = '200px';
    public get dropStyle() {
        return {
            height: this.dropSize,
            width: this.dropSize,
            flex: `0 1 ${this.dropSize}`,
        };
    }
    constructor(
        @Host() protected readonly host: MaterialsManagerCoreComponent,
        protected cdRef: ChangeDetectorRef,
        protected auth: AuthService,
        protected parentFormDirective: FormGroupDirective,
        protected resourceService: ResourceService,
        protected s3MediaService: S3MediaService,
        protected error: HttpErrorService,
        protected vTQService: VTQService,
        protected recordingService: RecorderService
    ) {
        super(host, cdRef, parentFormDirective);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            if (this.mediaView) {
                this.mediaView.media$
                    .asObservable()
                    .pipe(
                        takeUntil(this.destroy),
                        filter((media) => !!media?.mediaType)
                    )
                    .subscribe((media) => this.materialFileType.next(media.mediaType ?? 'File'));
            }
        }, 500);
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.itemForm?.currentValue?.value?.content) {
            this.handleFiles([changes.itemForm.currentValue.value.content]);
        }
    }
    public prepareFiles(event) {
        const files = event.target.files;
        this.handleFiles(files);
    }
    public handleFiles(files) {
        this.onSavingFile.emit(true);
        this.resourceService.setResSubmitDisabled(true);
        const obs: Observable<Media>[] = [];
        for (let i = 0; i < files.length; i++) {
            obs.push(this.uploadFiles(files[i]));
        }
        forkJoin(obs)
            .pipe(takeUntil(this.destroy))
            .subscribe(
                (mediaKeys) => {
                    this.onSavingFile.emit(false);
                    this.resourceService.setResSubmitDisabled(false);
                    this.resourceForm.patchValue({
                        title: this.resourceForm.value.title || mediaKeys[0].filename,
                    });
                    this.materialFileType.next(mediaKeys[0].mediaType ?? 'File');
                    this.itemForm.addControl('can_download', new FormControl(true));
                    this.itemForm.get('name').patchValue(mediaKeys[0].src);
                    this.itemForm.get('title').patchValue(mediaKeys[0].filename);
                    this.progress$.next(null);
                    if (this.fileInput?.nativeElement) {
                        this.fileInput.nativeElement.value = '';
                    }

                    FormHelper.markForm(this.resourceForm);
                    if (this.resourceForm.value.id) {
                        this.handleVTQ(mediaKeys);
                    }
                    this.recordingService.recordedSub.next(null);
                    if (mediaKeys.length > 1) {
                        this.handleOtherUploadedFiles(mediaKeys);
                    }
                },
                (err) => {}
            );
    }
    handleOtherUploadedFiles(mediaKeys: Media[]) {
        const items: ResourceItemData[] = [];
        mediaKeys.splice(1).forEach((mediaKey) => items.push({ name: mediaKey.src, item_type_value: 'doc_material' }));
        this.onCreateNewItems.emit(items);
    }
    handleVTQ(mediaKeys: Media[]) {
        const firstVideoMediaIndex = mediaKeys.findIndex((mediaKey) => mediaKey.mediaType === 'video');
        if (firstVideoMediaIndex >= 0) {
            this.vTQService.setActiveVTQ(
                new VTQ({
                    material_name: mediaKeys[firstVideoMediaIndex].filename,
                    material_type: mediaKeys[firstVideoMediaIndex].type,
                    language_id: this.resourceForm.value.language_id,
                    material_id: this.resource.id,
                })
            );
        } else {
            this.clearVTQ();
        }
    }

    removeFile() {
        // this.itemForm.removeControl('can_download');
        // this.itemForm.get('name').patchValue(null);
        this.deleteItem(this.index);
        this.itemForm.markAsDirty();
    }

    uploadFiles(file: File): Observable<Media> {
        return this.auth.getCustomer().pipe(
            first(),
            switchMap((customer) => this.s3MediaService.uploadMaterialToS3(customer.id, file, this.progress$))
        );
    }

    clearVTQ() {
        this.vTQService.setActiveVTQ(null);
    }

    afterItemFormInit() {
        if (this.itemForm.value.name) {
            this.itemForm.addControl('can_download', new FormControl(this.itemForm.value.can_download ?? true));
        }
    }
}
