export enum GroupCapability {
    NONE              = 0,
    VIEW_ALL_GROUPS   = 1 << 0,
    MANAGE_ALL_GROUPS = 1 << 1,
    VIEW_OWN_GROUPS   = 1 << 2,
    MANAGE_OWN_GROUPS = 1 << 3,
}
export namespace GroupCapability {
    export function getName(): string {
        return 'group';
    }
}
