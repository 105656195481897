<ng-container *ngIf="isNavTab">
    <div *ngIf="activeOption; else loading" [class]="wrapperClass" class="mobile-custom-tabs-wrapper">
        <mat-accordion displayMode="flat">
            <mat-expansion-panel #panel>
                <mat-expansion-panel-header class="main-head-exp">
                    <ng-container>{{ activeOption.label }}</ng-container>
                </mat-expansion-panel-header>
                <div class="activites-list-wrapper">
                    <mat-selection-list (selectionChange)="panel.close()" #activities [multiple]="false">
                        <ng-container *ngFor="let option of options">
                            <mat-list-option
                                *ngIf="option !== activeOption"
                                [value]="option.value"
                                (click)="activeOption = option"
                                [routerLink]="option.url"
                            >
                                <span>{{ option.label }}</span>
                                <mat-icon mat-list-icon>navigate_next</mat-icon>
                            </mat-list-option>
                        </ng-container>
                    </mat-selection-list>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</ng-container>
<ng-container *ngIf="!isNavTab">
    <div [class]="wrapperClass" class="mobile-custom-tabs-wrapper">
        <mat-accordion displayMode="flat">
            <mat-expansion-panel #panel>
                <mat-expansion-panel-header>
                    <ng-container>{{ activeOption.label }}</ng-container>
                </mat-expansion-panel-header>
                <div class="activites-list-wrapper">
                    <mat-selection-list (selectionChange)="panel.close()" #activities [multiple]="false">
                        <ng-container *ngFor="let option of options">
                            <mat-list-option
                                *ngIf="option !== activeOption"
                                [value]="option.value"
                                (click)="activeOption = option; selectedOption.emit(activeOption)"
                            >
                                <span>{{ option.label }}</span>
                                <mat-icon mat-list-icon>navigate_next</mat-icon>
                            </mat-list-option>
                        </ng-container>
                    </mat-selection-list>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="displayed-content">
        <div *ngFor="let templ of templateRefs; let index = index">
            <ng-container *ngIf="index === activeOption.index">
                <ng-container *ngTemplateOutlet="templ"></ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>
<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="left" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
