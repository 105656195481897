<ng-container *ngIf="!icon">
    <mat-form-field
        *ngIf="!inGlobalFilter; else globalFilterTemplate"
        fxFlex="0 1 auto"
        fxFlex.lt-md="0 1 90%"
        fxLayout="column"
        class="mat-form-select-main"
    >
        <mat-select #matSelect [placeholder]="label" (selectionChange)="onSelectionChanged($event.value)">
            <mat-select-trigger class="option-trigger" *ngIf="matSelect.value">
                <mat-icon class="main-icon-option" [ngClass]="matSelect.value.class ? matSelect.value.class : ''">{{
                    matSelect.value.icon
                }}</mat-icon>
                <span>{{ matSelect.value.title }}</span>
            </mat-select-trigger>
            <mat-option [value]="null">
                <mat-icon>clear_all</mat-icon>
                <span i18n="@@select">Select</span>
            </mat-option>
            <mat-option *ngFor="let option of options" [value]="option">
                <mat-icon [ngClass]="option.class ? option.class : ''">{{ option.icon }}</mat-icon>
                <span>{{ option.title }}</span>
            </mat-option>
        </mat-select></mat-form-field
    >
</ng-container>
<ng-template #globalFilterTemplate>
    <mat-form-field
        floatLabel="never"
        class="selector-wrapper global-filter-select mat-form-select-main"
        fxFlex="0 1 auto"
        fxFlex.lt-md="0 1 90%"
        fxLayout="column"
    >
        <mat-select #matSelect [placeholder]="label" (selectionChange)="onSelectionChanged($event.value)">
            <mat-select-trigger class="option-trigger" *ngIf="matSelect.value">
                <mat-icon
                    class="main-icon-option"
                    *ngIf="matSelect.value.isMainResource"
                    matListIcon
                    fontSet="klickdata"
                    [fontIcon]="matSelect.value.icon"
                ></mat-icon>
                <mat-icon
                    class="main-icon-option"
                    *ngIf="!matSelect.value.isMainResource"
                    [ngClass]="matSelect.value.class ? matSelect.value.class : ''"
                    >{{ matSelect.value.icon }}</mat-icon
                >
                <span>{{ matSelect.value.title }}</span>
            </mat-select-trigger>
            <mat-option *ngFor="let option of options" [value]="option">
                <mat-icon
                    class="main-icon-option"
                    *ngIf="!option.isMainResource"
                    [ngClass]="option.class ? option.class : ''"
                    >{{ option.icon }}</mat-icon
                >
                <mat-icon
                    class="main-icon-option"
                    *ngIf="option.isMainResource"
                    matListIcon
                    fontSet="klickdata"
                    [fontIcon]="option.icon"
                ></mat-icon>
                <span>{{ option.title }}</span>
            </mat-option>
        </mat-select></mat-form-field
    >
</ng-template>

<ng-template #activeFilterTemplate let-filter="filter">
    <ng-container
        [ngTemplateOutlet]="
            filter.property === GlobalFilterProperty.TIME_SPENT && filter.items.length > 1
                ? timeRangeTemplate
                : selectOptionTemplate
        "
        [ngTemplateOutletContext]="{ filter: filter }"
    >
    </ng-container>
</ng-template>

<ng-template #timeRangeTemplate let-filter="filter">
    <div class="date-range-wrap" *ngIf="filter.items.length > 1">
        <mat-icon>date_range</mat-icon>
        <span i18n>From:</span> <span>{{ filter.items[0] }}</span>
        <span>/</span>
        <span i18n>To: </span>
        <span>{{ filter.items[1] }}</span>
    </div>
</ng-template>

<ng-template #selectOptionTemplate let-filter="filter">
    <div fxLayout="row" class="chip-filter-choosen" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon
            class="main-icon-option-chip"
            *ngIf="filter.chips[0].isMainResource"
            matListIcon
            fontSet="klickdata"
            [fontIcon]="filter.chips[0].icon"
        ></mat-icon>
        <mat-icon *ngIf="!filter.chips[0].isMainResource">{{ filter.chips[0].icon }}</mat-icon>
        <span>{{ filter.chips[0].title }}</span>
    </div>
</ng-template>
