import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AuthService } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItem } from '@klickdata/core/resource-item';
import { User } from '@klickdata/core/user';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { shareReplay, startWith } from 'rxjs/operators';

@Component({
    selector: 'app-resource-details-sheet',
    templateUrl: './resource-details-sheet.component.html',
    styleUrls: ['./resource-details-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDetailsSheetComponent implements OnInit {
    @ViewChild('specsExpansionPanel') expansionPanel: MatExpansionPanel;
    public resourceItems: Observable<ResourceItem[]>;
    AppScope = AppScope;
    ResourceTypes = ResourceTypes;
    public user$: Observable<User>;
    public mediaUrl: string;
    public isMobile: Observable<boolean>;
    public moment = moment();
    public hideImg = false;
    public showAuthorName = false;
    constructor(
        public bottomSheetRef: MatBottomSheetRef<ResourceDetailsSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected auth: AuthService,
        protected mobile: MobileService
    ) {
        this.user$ = this.auth.getUser().pipe(startWith(new User({ role_value: 'guest' })));
        this.isMobile = this.mobile.isMobile().pipe(shareReplay());
        this.resourceItems = data.items;
    }

    ngOnInit() {}

    displayCorrectIcon(type) {
        if (type.indexOf('test') !== -1) {
            return 'kd-icon-test';
        } else if (type.indexOf('course_plan') !== -1) {
            return 'kd-icon-task';
        } else if (type.indexOf('survey') !== -1) {
            return 'kd-icon-survey';
        } else if (type.indexOf('course') !== -1) {
            return 'kd-icon-player';
        } else if (type.indexOf('material') !== -1) {
            return 'kd-icon-document';
        }
    }

    public styleInnerHTML(description) {
        if (description) {
            return '<div class="inner-HTML-course">' + description + '</div>';
        } else {
            return;
        }
    }
    togglePanel() {
        this.expansionPanel.toggle();
    }
    styleBullets(bullets) {
        return bullets.replace(/↵/g, '<br/>');
    }
}
