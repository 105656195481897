<h2 matDialogTitle>
    <ng-container *ngIf="$any(data.customer$ | async)?.medias; let medias">
        <app-image-view
            *ngIf="medias?.logotype"
            class="logo-type-view"
            [media_id]="medias?.logotype[0]"
            paddingBottom="70px"
        ></app-image-view>
    </ng-container>
    <span i18n>Session expires in {{ countdown$ | async }} seconds</span>
</h2>

<mat-dialog-content i18n> For your security, you will soon be automatically logged out. </mat-dialog-content>

<mat-dialog-actions>
    <div class="flex-space"></div>
    <button mat-raised-button class="logout" type="button" tabindex="1" (click)="logout()" i18n>Log out</button>
    <div class="space"></div>
    <button mat-raised-button type="button" tabindex="2" (click)="refeshToken()" color="primary" i18n>
        Stay logged in
    </button>
</mat-dialog-actions>
