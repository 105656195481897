<app-table-toolbar color="accent" class="toolbar">
    <app-table-filter #filter>
        <mat-toolbar class="compact" fxLayout="row">
            <app-table-search></app-table-search>
        </mat-toolbar>

        <app-table-date-filter class="ml-1"></app-table-date-filter>

        <mat-toolbar color="accent" class="compact" fxLayout="row" fxFlexAlign="space-between" fxLayoutGap="0.5em">
            <span i18n="@@numerOfCustomer">Number of customers:</span>
            {{ total }}
            <ng-container *ngIf="selected">
                <div fxFlex="0 0 1em"></div>
                <span i18n="@@selected">Selected:</span>
                {{ selected }}
            </ng-container>
            <app-checkbox-filter
                [stateFilter]="{
                    property: 'select',
                    state: null,
                    neutralFilter: null,
                    activeFilter: [1],
                    notActiveFilter: [0]
                }"
                title="Filter by selected"
                i18n-title="@@filterBySelected"
            ></app-checkbox-filter>
            <div fxFlex="1 1 100%"></div>
            <app-table-menu
                matTooltip="Show all"
                (click)="filter.selectAll()"
                [columns]="columns"
                #tableMenu
                i18n-matTooltip
            ></app-table-menu>
        </mat-toolbar>
    </app-table-filter>

    <mat-progress-bar class="progress progress-table-loader" *ngIf="(dataSource.status | async) === 'loading'" mode="indeterminate">
    </mat-progress-bar>
</app-table-toolbar>

<mat-table #table [dataSource]="dataSource" matSort matSortActive="name">
    <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="masterToggle()"
                [checked]="isChecked()"
                [indeterminate]="isIndeterminate()"
            >
            </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let customer">
            <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="selectToggle(customer)"
                [checked]="modelChecked(customer)"
            >
            </mat-checkbox>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name">
            Name
        </mat-header-cell>
        <mat-cell *matCellDef="let customer">{{ customer.name }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableMenu.displayed"></mat-header-row>
    <mat-row *matRowDef="let customer; columns: tableMenu.displayed" (click)="selectToggle(customer)"></mat-row>
</mat-table>

<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
