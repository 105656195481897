<!-- <p>custom-fields-switch-type works!</p>
<app-user-setting-input type="switch" [control]="customFieldForm.get('value') | cast">
    <span class="label" role="switch-label">
        <ng-container>{{ customFieldForm.value.name }}</ng-container>
    </span>
</app-user-setting-input> -->

<div class="input-switch" fxLayout="row" fxLayoutGap="10px">
    <mat-slide-toggle
        [formControl]="customFieldForm.get('value') | cast"
        data-on="On"
        data-off="Off"
        color="primary"
        class="slide-togg-wrap"
    >
        <span>{{ customFieldForm.value.name }}</span>
    </mat-slide-toggle>
</div>
