import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from '@klickdata/core/resource';

@Pipe({
    name: 'resourceStatus',
})
export class ResourceStatusPipe implements PipeTransform {
    transform(res: Resource, isStatsContext?: boolean | string): { status: string; color: string; icon: string } {
        const strContext = typeof isStatsContext === 'string';
        if (isStatsContext || strContext) {
            const status = strContext ? isStatsContext : res.occasionStatus;
            switch (status) {
                case 'created':
                    return {
                        status: $localize`:@@created:Created`,
                        color: '#3e5365',
                        icon: 'edit',
                    };
                case 'done':
                    return { status: $localize`:@@completedSingular:Completed`, color: '#3e5365', icon: 'task_alt' };
                case 'completed': // pending_signoff
                    return { status: $localize`:@@unsiged:Unsigned`, color: '#3e5365', icon: 'done_all' };
                case 'ended':
                    return { status: $localize`:@@ended:Ended`, color: '#3e5365', icon: 'check_circle' };
                case 'ongoing':
                    return { status: $localize`:@@ongoing:Ongoing`, color: '#ff9961', icon: 'cached' };
                case 'not_started':
                    return { status: $localize`:@@notStarted:Not Started`, color: '#93cbd1', icon: 'directions_run' };
                case 'overdue':
                    return { status: $localize`:@@overdue:Overdue`, color: '#e44a66', icon: 'event_busy' };
                case 'cancelled':
                    return { status: $localize`:@@cancelled:Cancelled`, color: '#e44a66', icon: 'cancel' };
                case 'deleted':
                    return { status: $localize`:@@deleted:Deleted`, color: '#e44a66', icon: 'delete_forever' };
            }
        } else {
            if (!res.isAvailable()) {
                return { status: $localize`Expired`, color: '#e44a66', icon: 'event_busy' };
            } else if (!res.last_publish) {
                return { status: $localize`Draft`, color: '#a7a7a7', icon: 'public_off' };
            } else if (res.public) {
                return { status: $localize`Public`, color: '#3e5365', icon: 'public' };
            } else if (!res.public) {
                return { status: $localize`:@@academy:Academy`, color: '#93cbd1', icon: 'published_with_changes' };
            }
        }
    }
}
