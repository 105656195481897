<app-page-header class="folder-header">
    <mat-icon color="primary" class="header-icon kd-icon-36 no-shadow">folder_open</mat-icon>
    <ng-container i18n="@@folders">Folders</ng-container>
</app-page-header>

<app-table-toolbar class="toolbar" color="accent">
    <app-table-filter color="accent" #filter>
        <!-- breadcrumb -->
        <app-breadcrumb-filter #breadcrumbFilter [item]="breadcrumb$ | async"></app-breadcrumb-filter>
        <div class="ml-1 mr-1" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0.5">
            <app-table-search></app-table-search>
            <div fxFlex="1 1 100%"></div>
            <app-language-filter
                [fxHide]="breadcrumbFilter.isEmpty()"
                class="mr-1 ml-1"
                fxLayout="row"
            ></app-language-filter>

            <button mat-button (click)="filter.clear()" fxFlex="0 0 auto">
                <mat-icon>close</mat-icon>
                <ng-container i18n="@@clearFilters">Clear filters</ng-container>
            </button>

            <button
                mat-button
                fxFlex="0 0 auto"
                *ngIf="createOrUpdate.observers.length"
                (click)="createOrUpdate?.emit()"
            >
                <mat-icon>add</mat-icon>
                <ng-container i18n="@@add">Add</ng-container>
            </button>
        </div>
    </app-table-filter>

    <mat-toolbar color="accent" fxLayout="row" fxFlexAlign="space-between" fxHide="true" fxLayoutGap="0.5em">
        <div fxFlex="1 1 100%"></div>
        <app-table-menu
            matTooltip="Show all"
            (click)="filter.selectAll()"
            [columns]="columns$ | async"
            #tableMenu
            i18n-matTooltip
        >
        </app-table-menu>
    </mat-toolbar>

    <mat-progress-bar
        class="progress progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    >
    </mat-progress-bar>
</app-table-toolbar>

<mat-table #table [dataSource]="dataSource" matSort matSortActive="title">
    <ng-container matColumnDef="title">
        <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="!!breadcrumbFilter.breadcrumbs.length"
            i18n="@@title"
        >
            Title
        </mat-header-cell>
        <mat-cell *matCellDef="let folder">
            <span class="mobile-label" i18n="@@title"> Title </span>
            <mat-icon *ngIf="!folder.type_id" class="kd-icon-24 folder-list-icon title-icon"> folder_open </mat-icon>
            <mat-icon
                *ngIf="folder.type_id"
                [fontIcon]="ResourceTypes.fontIconType(folder.type_id)"
                class="kd-icon-24 folder-list-icon title-icon"
            ></mat-icon>
            {{ folder.title }}
            <a
                *ngIf="!isFolder(folder)"
                fxFlex="0 0 auto"
                target="_blank"
                (click)="viewResource(folder)"
                mat-icon-button
                matTooltip="Preview Item"
                i18n-matTooltip="@@previewItem"
            >
                <mat-icon class="md-24 view-icon">open_in_new</mat-icon>
            </a>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef i18n="@@type"> Type </mat-header-cell>
        <mat-cell *matCellDef="let folder">
            <span class="mobile-label" i18n="@@type"> Type </span>
            {{
                folder.type_id
                    ? ResourceTypes.label(folder.type_id, { capitalize: true })
                    : ResourceTypes.label(folder.type_ids[0], { plural: true, capitalize: true })
            }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="author_name">
        <mat-header-cell *matHeaderCellDef i18n="@@createdBy"> Created by </mat-header-cell>
        <mat-cell *matCellDef="let folder">
            <span class="mobile-label" i18n="@@createdBy"> Created by </span>
            {{ $any(folder.author$ | async)?.name || 'KlickData team' }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created_at">
        <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="!!breadcrumbFilter.breadcrumbs.length"
            i18n="@@createdAt"
        >
            Created at
        </mat-header-cell>
        <mat-cell *matCellDef="let folder">
            <span class="mobile-label" i18n="@@createdAt"> Created at </span>
            {{ folder.created_at | kdDateFormat: 'YYYY-MM-DD' }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="updated_at">
        <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            [disabled]="!!breadcrumbFilter.breadcrumbs.length"
            i18n="@@updated"
        >
            Updated at
        </mat-header-cell>
        <mat-cell *matCellDef="let folder">
            <span class="mobile-label" i18n="@@updated"> Updated at </span>
            {{ folder.updated_at | kdDateFormat: 'YYYY-MM-DD' }}
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="language">
        <mat-header-cell *matHeaderCellDef i18n="@@language"> Language </mat-header-cell>
        <mat-cell *matCellDef="let gradeSystem">
            <ng-container *ngIf="gradeSystem.language_id">
                <app-language-selector
                    [language_id]="gradeSystem.language_id"
                    class="icon-resource-builder"
                    fxFlex="0 0 auto"
                ></app-language-selector>
            </ng-container>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tools">
        <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
        <mat-cell *matCellDef="let folder">
            <span class="mobile-label mobile-label-tools" i18n="@@tools"> Tools </span>

            <button
                class="tools-btn"
                matTooltip="Edit"
                [class.disableIt]="!authUser.canEdit(folder)"
                mat-icon-button
                (click)="$event.stopPropagation(); authUser.canEdit(folder) && edit(folder)"
                i18n-matTooltip="@@edit"
            >
                <mat-icon>edit</mat-icon>
            </button>

            <button
                class="tools-btn"
                matTooltip="Delete"
                [class.disableIt]="!authUser.canEdit(folder)"
                mat-icon-button
                (click)="$event.stopPropagation(); authUser.canEdit(folder) && remove(folder, $event)"
                i18n-matTooltip="@@delete"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="tableMenu.displayed"></mat-header-row>
    <mat-row
        [ngClass]="isFolder(folder) ? 'link' : 'disableRow'"
        *matRowDef="let folder; columns: tableMenu.displayed"
        (click)="$event.stopPropagation(); open(folder)"
        matRipple
    ></mat-row>
</mat-table>

<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
