import { SectionData } from '@klickdata/core/section/src/section.model';
import { Section, SectionService } from '@klickdata/core/section';
import { Injectable } from '@angular/core';
import { PaginatorResponse } from '@klickdata/core/http';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CollectionData, CollectionService, Collection } from '@klickdata/core/collection';

@Injectable()
export class CollectionSelectService extends CollectionService implements TableHttpService<Collection> {
    public fetchData(source: TableSource<Collection>): Observable<PaginatorResponse<Collection[]>> {
        const req = this.builder.get<CollectionData[]>(this.collectionUrl);

        req.param('referenceCount', 1);
        req.param('translations', 1);

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<CollectionData[]>) => {
                return {
                    data: res.data.map((item) => this.mapCollection(item)),
                    notify: res.notify,
                    paginator: res.paginator,
                    more: res.more,
                };
            })
        );
    }
}
