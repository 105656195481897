import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Register } from '@klickdata/core/auth/src/auth/register.model';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { CustomerLanding, CustomerService } from '@klickdata/core/customer';
import { ErrorResponse } from '@klickdata/core/http/src/responce/error-responce';
import { PaymentService } from '@klickdata/core/payment';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoginFormComponent } from '../../login/login-form/login-form.component';

@Component({
    selector: 'app-signup-form',
    templateUrl: './signup-form.component.html',
    styleUrls: ['./signup-form.component.scss'],
})
export class SignupFormComponent implements OnDestroy, OnInit {
    register: Register;
    subscription: Subscription;
    error$: Observable<ErrorResponse<null>>;
    canRegister = true;
    termsAndPrivacyCounter = 2;
    public dialogSignUPMode = false;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public lowerCaseCritiriaMsg = $localize`contains at least one lower character`;
    public upperCaseCritiriaMsg = $localize`contains at least one upper character`;
    public digitsCriteriaMsg = $localize`contains at least one digit character`;
    public specialCharsCriteriaMsg = $localize`contains at least one special character`;
    public minCharsCriteriaMsg = $localize`contains at least 8 characters`;
    public customerLanding$: Observable<CustomerLanding>;

    constructor(
        protected auth: AuthService,
        protected route: Router,
        protected activateRoute: ActivatedRoute,
        protected location: Location,
        protected cdRef: ChangeDetectorRef,
        @Inject(LOCALE_ID) public localeId: string,
        protected customerService: CustomerService,
        protected dialog: MatDialog,
        public dialogRef: MatDialogRef<SignupFormComponent>
    ) {
        this.register = new Register();
    }
    ngOnInit() {
        if (this.dialog.getDialogById('signupDialog')) {
            this.dialogSignUPMode = true;
        }
        this.customerLanding$ = this.auth.getCustomerLanding();
    }
    onSubmit() {
        this.subscription = this.auth
            .register({
                ...this.register,
                subscription_plan_id: Number(this.activateRoute.snapshot.queryParams.subscription_plan),
            })
            .pipe(takeUntil(this.destroy))
            .subscribe(
                () => {
                    this.dialogSignUPMode ? this.dialogRef.close(true) : this.route.navigate(['/home']);
                },
                (err) => {
                    this.error$ = of(err.error);
                }
            );
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
    public handleLoginRequest() {
        if (!this.dialogSignUPMode) {
            this.route.navigate(['../guest/login']);
        } else {
            this.dialog
                .open(LoginFormComponent, {
                    panelClass: 'signUp-wrapper',
                    id: 'signinDialog',
                    closeOnNavigation: true,
                })
                .afterClosed()
                .pipe(
                    takeUntil(this.destroy),
                    filter((result) => !!result)
                )
                .subscribe(() => this.dialogRef.close(true));
            this.dialog.getDialogById('signupDialog').close();
            this.dialogSignUPMode = false;
        }
    }

    public handleRegisterBtnAvailability(isChecked: boolean) {
        if (isChecked) {
            this.termsAndPrivacyCounter++;
            this.canRegister = this.termsAndPrivacyCounter < 2 ? false : true;
        } else {
            this.termsAndPrivacyCounter--;
            this.canRegister = this.termsAndPrivacyCounter < 2 ? false : true;
        }
        this.cdRef.markForCheck();
    }

    public isPrivacyChecked(isChecked: boolean) {
        this.canRegister = isChecked;
        this.cdRef.markForCheck();
    }

    public goBack(): void {
        this.location.back();
    }
}
