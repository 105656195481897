import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@klickdata/core/http';
import { ConfigModule } from '@klickdata/core/config';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        ConfigModule,
    ],
})
export class OpportunityModule {
}
