import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AppScope, ResourceTag } from '@klickdata/core/resource';
import { SearchRelatedTagListingService } from './search-related-tag-listing.service';
import {
    Filter,
    GlobalFilterProperty,
    GlobalFilterPropertyType,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Utils } from '@klickdata/core/util';
import { Router } from '@angular/router';
import { GlobalSearchData } from '@klickdata/core/global-search';

@Component({
    selector: 'app-tag-related-scopes-listing',
    templateUrl: './tag-related-scopes-listing.component.html',
    styleUrls: ['./tag-related-scopes-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SearchRelatedTagListingService],
})
export class TagRelatedScopesListingComponent implements OnInit, AfterViewInit {
    public dataSource = new TableSource<any>();
    public columns = ['label', 'updated_at', 'scope_id', 'tools'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    public mainScopes: SelectFilterOption[];
    constructor(
        protected service: SearchRelatedTagListingService,
        public bottomSheetRef: MatBottomSheetRef<TagRelatedScopesListingComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public tag: ResourceTag,
        protected router: Router
    ) {
        this.mainScopes = AppScope.getMainScopesOptions();
    }

    ngOnInit(): void {
        this.dataSource.service = this.service;
    }
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.filter.createOrUpdateFilter([new Filter('tag', [this.tag.id])]);
    }
    getScopeData(scopeId: number) {
        return this.mainScopes.find((scope) => scope.value == scopeId) ?? null;
    }
    view(data: GlobalSearchData) {
        this.bottomSheetRef.dismiss();
        switch (data.scope_id) {
            case AppScope.USERS:
                this.router.navigate(['/master/customers/k3-connections/connection/', data.id]);
                break;
            case AppScope.GROUPS:
                this.router.navigate(['/admin/accounts/groups/', data.id]);
                break;
            case AppScope.CUSTOMERS:
                this.router.navigate(['/master/customers/customer-manager/', data.id]);
                break;
            case AppScope.TASK:
                this.router.navigate(['/master/customers/k3-tasks'], { queryParams: { id: data.id } });
                break;
            case AppScope.NOTE:
                this.router.navigate(['/master/customers/k3-notes'], { queryParams: { id: data.id } });
                break;
            case AppScope.E_COURSE:
            case AppScope.EVENT:
            case AppScope.MATERIAL:
            case AppScope.TEST:
            case AppScope.SURVEY:
            case AppScope.COURSE:
            case AppScope.SCORM:
                this.router.navigate(['/home/dashboard/resource-details/', data.id]);
                break;
            // case AppScope.EVENT:
            //     this.router.navigate(['/admin/content/events/', data.id]);
            //     break;
            // case AppScope.MATERIAL:
            //     this.router.navigate(['/admin/content/materials/', data.id]);
            //     break;
            // case AppScope.TEST:
            //     this.router.navigate(['/admin/content/tests/', data.id]);
            //     break;
            // case AppScope.SURVEY:
            //     this.router.navigate(['/admin/content/surveys/', data.id]);
            //     break;
            // case AppScope.COURSE:
            //     this.router.navigate(['/admin/content/courses/', data.id]);
            //     break;
            // case AppScope.SCORM:
            //     this.router.navigate(['/admin/content/scorms/', data.id]);
            //     break;
            case AppScope.SECTIONS:
                this.router.navigate(['/admin/settings/sections']);
                break;
            case AppScope.CATEGORIES:
                this.router.navigate(['/admin/content/categories']);
                break;
        }
    }
}
