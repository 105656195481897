import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { File, FileData, FileService } from '@klickdata/core/file';
import { LanguageService } from '@klickdata/core/localization';
import { Media } from '@klickdata/core/media/src/media.model';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveActionsTypes, SideNaveData, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Notification, NotificationService } from '@klickdata/core/notification';
import { AppScope } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import { Observable, Subject, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-add-media-file',
    templateUrl: './menu-side-add-media-file.component.html',
    styleUrls: ['./menu-side-add-media-file.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddMediaFileComponent implements OnDestroy, OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public fileForm: FormGroup;
    public file: File;
    AppScope = AppScope;
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    public currentLaguageId$: Observable<number>;
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(protected fb: FormBuilder, protected fileService: FileService, protected langService: LanguageService) {
        this.fileForm = this.fb.group({
            title: [''],
            description: [''],
            priority: ['a3'],
            visibility: [''],
            tag_ids: [[]],
            medias: this.fb.group({
                file: [[]],
            }),
        });
    }
    ngOnInit(): void {
        this.currentLaguageId$ = this.langService
            .getLanguageByKey(this.langService.getCurrentLanguage().value)
            .pipe(map((lang) => lang.id));
        if (this.navData.data.file) {
            this.file = this.navData.data.file;
            this.fileForm.patchValue(this.navData.data.file);
        }
        this.fileForm.get('visibility').patchValue(this.navData.data?.visibility ?? 'private');
    }
    onUploadComplete(mediaItems: Media[]) {
        if (this.fileForm.value.title) {
            return;
        }
        if (mediaItems && !!mediaItems.length) {
            this.fileForm.get('title').patchValue(mediaItems[0]?.filename);
            this.fileForm.get('title').markAsDirty();
        }
    }
    public submit() {
        if (!this.fileForm.value.medias?.file?.length) {
            return;
        }
        this.saving.emit(true);
        this.prepareData()
            .pipe(
                switchMap((data) =>
                    this.file?.id
                        ? this.fileService.update(data, ['author', 'tags'])
                        : this.fileService.create(data, ['author', 'tags'])
                ),
                takeUntil(this.destroy)
            )
            .subscribe((file) => {
                this.saving.emit(false);
                this.onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: { value: file, type: this.navData.data.type, visibility: this.fileForm.value.visibility },
                });
                this.fileForm.reset();
            });
    }
    prepareData(): Observable<FileData> {
        const formData: FileData = Utils.changedControls(this.fileForm);
        const data = this.file?.id ? this.file.getPayload(formData) : new File(formData).getPayload();
        if (this.file?.id) {
            data.id = this.file.id;
        }
        data.visibility = this.fileForm.value.visibility;
        return of(data);
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
