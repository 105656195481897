<app-user-listing-general
    class="app-user-listing-general app-item-assignment-overview"
    [table_columns]="[
        'name',
        'occasionStatus',
        'done_items',
        'last_action',
        'tools'
    ]"
    [allowActionHeader]="false"
    [attachedResource]="resource"
    [allowActionRow]="false"
    activeSortingCol="name"
    activeSortingDirection="asc"
    [areFiltersInAccordionInDesktop]="true"
    (onSendEmail)="sendEmail($event)"
    (onBrowse)="sendEmail($event)"
    [tableFilterItems]="tableFilterItems"
    
    [tableToolsAction]="[ResourceTableActions.EMAIL]"
>
</app-user-listing-general>
