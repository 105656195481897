import { Clipboard } from '@angular/cdk/clipboard';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import {
    MessageFormErrorComponent,
    MessageSavedComponent,
    MessageSentComponent,
    MessageService,
} from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { UserMessage, UserService } from '@klickdata/core/user';
import { EMPTY, Observable, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-send-mail',
    templateUrl: './menu-side-send-mail.component.html',
    styleUrls: ['./menu-side-send-mail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideSendMailComponent implements OnDestroy, OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<boolean> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    private destroy: Subject<boolean> = new Subject<boolean>();
    public mailForm: FormGroup;
    public showMailFields: boolean;
    public activationLink$: Observable<string>;
    public kam = new FormControl(false);
    public ma = new FormControl(false);

    constructor(
        protected fb: FormBuilder,
        private clipboard: Clipboard,
        protected messageService: MessageService,
        protected userService: UserService,
        protected auth: AuthService,
        protected cdr: ChangeDetectorRef
    ) {
        this.buildForm();
    }
    ngOnInit(): void {
        if (this.navData.data.activated) {
            this.showMailFields = true;
            this.cdr.markForCheck();
        }
        this.activationLink$ = this.userService.getUserActivation(this.navData.data.id);
    }

    public buildForm() {
        this.mailForm = this.fb.group({
            subject: ['', Validators.required],
            body: [''],
        });
    }
    public submit(activationOnly?: boolean) {
        if (this.mailForm.invalid && !activationOnly) {
            FormHelper.markForm(this.mailForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            return;
        }
        this.saving.emit(true);
        this.userService
            .send(
                activationOnly
                    ? { users_attach: [this.navData.data.id] }
                    : {
                          ...new UserMessage(this.mailForm.value),
                          users_attach: [this.navData.data.id],
                      }
            )
            .pipe(
                takeUntil(this.destroy),
                catchError((err) => {
                    this.messageService.openMessage(MessageErrorComponent, err?.error?.error?.messages.join('/n'));
                    this.saving.emit(false);
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.onClose.emit(true);
                this.saving.emit(false);
                this.messageService.openMessage(MessageSentComponent);
                this.mailForm.reset();
                this.mailForm.patchValue({ body: '' });
                FormHelper.resetForm(this.mailForm);
            });
    }

    copy(link: string) {
        this.clipboard.copy(link);
        this.messageService.openMessage(
            MessageSavedComponent,
            $localize`Activation link have been copied to the clipboard.`
        );
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
