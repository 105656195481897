<form [formGroup]="resourceSettings" fxLayout="row wrap">
    <mat-form-field fxFlex="1 0 15%">
        <input matInput placeholder="Article code" i18n-placeholder formControlName="article_code" required />
    </mat-form-field>

    <div fxFlex="0 0 100%">
        <mat-form-field fxFlex="0 1 100%">
            <input matInput placeholder="Title" i18n-placeholder formControlName="title" required />
        </mat-form-field>
        <div fxLayoutAlign="end end" fxFlex="0 0 40px" class="coursePlan-layout">
            <app-language-selector
                formControlName="language_id"
                class="icon-resource-builder"
                fxFlex="0 0 auto"
            ></app-language-selector>
        </div>
    </div>

    <mat-form-field fxFlex="1 0 15%">
        <input matInput placeholder="Author" i18n-placeholder formControlName="author_name" required />
    </mat-form-field>

    <app-media-view
        fxLayoutAlign="center center"
        *ngIf="resourceSettings.get('article_code').value; let article_code"
        fxFlex="0 0 100%"
        [articleCode]="article_code"
    >
    </app-media-view>

    <div fxFlex="0 0 100%" fxLayoutAlign="center center">
        <app-media
            fxFlex="0 1 50%"
            *ngIf="!resourceSettings.get('media_id').value && viewMode !== 'disabled'"
            formControlName="media_id"
            [scope]="AppScope.E_COURSE"
            dropSize="200px"
            dropLabel="Drag cover image here"
            i18n-dropLabel
        >
            <p i18n>Course cover image</p>
        </app-media>

        <app-image-view
            fxFlex="0 1 50%"
            *ngIf="resourceSettings.get('media_id').value; let mediaId"
            [media_id]="mediaId"
        >
            <button
                [disabled]="viewMode === 'disabled'"
                class="remove-image"
                mat-icon-button
                color="warn"
                *ngIf="mediaId"
                matTooltip="Remove image"
                i18n-matTooltip="Remove question image"
                (click)="removeImage()"
            >
                <mat-icon class="md-24">delete_outline</mat-icon>
            </button>
        </app-image-view>
    </div>

    <div fxFlex="1 1 100%">
        <app-category-chip-select
            fxFlex="0 1 100%"
            [resource_id]="resourceSettings?.get('id')?.value"
            [language_id]="resourceSettings?.get('language_id')?.value"
            formControlName="category_ids"
            [type_id]="ResourceTypes.E_COURSE"
        ></app-category-chip-select>
    </div>

    <app-text-editor
        fxFlex="1 1 100%"
        title="Description"
        i18n-title
        fieldName="courseFormDescription"
        [showTemplates]="false"
        formControlName="description"
    ></app-text-editor>

    <app-master-well fxFlex="0 1 100%" fxLayout="column" *appCanAccess="managePublicCourse">
        <mat-slide-toggle fxFlex="0 0 auto" formControlName="public" i18n> Display as public </mat-slide-toggle>

        <!-- Price -->
        <div fxLayoutAlign="start start" fxLayoutGap="0px" class="ml-1 mb-1 mt-1" fxLayoutGap.gt-md="2em">
            <mat-form-field fxFlex="0 0 40%">
                <input
                    matInput
                    type="number"
                    placeholder="Please set course price"
                    i18n-placeholder
                    formControlName="price"
                />
            </mat-form-field>
            <mat-select
                class="mt-1"
                fxFlex="0 0 20%"
                placeholder="Place choose currency"
                i18n-placeholder
                formControlName="currency"
            >
                <mat-option *ngFor="let currency of currencies" [value]="currency.code">
                    {{ currency.label }} - {{ currency.sign }}
                </mat-option>
            </mat-select>
        </div>
    </app-master-well>

    <ng-content></ng-content>
</form>
