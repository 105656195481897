import { BehaviorSubject } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { QuestionTypes } from '@klickdata/core/question';
import { AppScope, ResourceService } from '@klickdata/core/resource';
import { ResourceItemTypes } from '@klickdata/core/resource-item';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-menu-side-add-resource-questions',
    templateUrl: './menu-side-add-resource-questions.component.html',
    styleUrls: ['./menu-side-add-resource-questions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddResourceQuestionsComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public showdHint: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showdAIQs: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public selectedValue: ResourceBuildingItems;
    QuestionTypes = QuestionTypes;
    ResourceItemTypes = ResourceItemTypes;
    AppScope = AppScope;
    public promptForm: FormGroup;
    SideNaveActionsTypes = SideNaveActionsTypes;
    ResourceBuildingItems = ResourceBuildingItems;
    constructor(protected fb: FormBuilder, protected router: Router) {}
    ngOnInit(): void {
        this.promptForm = this.fb.group({
            text: ['', Validators.required],
            question_count: [5, Validators.required],
            difficulty_level: [2, Validators.required],
            question_type_value: this.fb.group(
                {
                    radio: [true],
                    checkbox: [true],
                    input: [true],
                },
                { validators: this.requireCheckboxesToBeChecked }
            ),
        });
    }
    requireCheckboxesToBeChecked(group: FormGroup) {
        const checked = Object.keys(group.controls).some((key) => group.get(key).value);
        return checked ? null : { requireCheckboxesToBeChecked: true };
    }
    goToFAQ() {
        window.open('https://klickdata.se/faq-klms/question-types-can-you-add-in-a-test-in-the-klms', '_blank');
    }
    goToRecordFAQ() {
        window.open(
            'https://klickdata.se/faq-klms/record-your-course-material-directly-in-the-klms-with-one-clic',
            '_blank'
        );
    }
    onSelectionChange(value) {
        this.selectedValue = value;
        if (!value) {
            return;
        }
        if (value === QuestionTypes.AI) {
            const selectedQsTypes = [];
            Object.keys(this.promptForm.value.question_type_value).forEach((key) => {
                if (this.promptForm.get('question_type_value').get(key).value) {
                    selectedQsTypes.push(key);
                }
            });

            this.onClose.emit({
                action: SideNaveActionsTypes.POSITIVE,
                data: {
                    value: value,
                    type: this.navData.data.type,
                    aiMetaData: { ...this.promptForm.value, ...{ question_type_value: selectedQsTypes.join(',') } },
                },
            });
            return;
        }
        if (
            (value === ResourceBuildingItems.CREATE_NEW_RECORD_MATERIAL &&
                this.navData.data.context === 'materialCreation') ||
            value != ResourceBuildingItems.CREATE_NEW_RECORD_MATERIAL
        ) {
            this.showdHint.next(false);
            this.onClose.emit({
                action: SideNaveActionsTypes.POSITIVE,
                data: {
                    value: value,
                    type: this.navData.data.type,
                },
            });
        }
    }

    onListMaterial() {
        this.showdHint.next(false);
        this.onClose.emit();
        this.router.navigate(['/admin/content/materials/list']);
    }

    onEditMaterial(resource: Resource, close = false) {
        if (close) {
            this.onClose.emit();
        }
        this.showdHint.next(false);
        this.router.navigate(['/admin/content/materials', resource.id]);
    }

    onMaterialExist(res: Resource) {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                value: ResourceBuildingItems.CREATE_NEW_TEXT_MATERIAL,
                type: this.navData.data.type,
                resource: res,
            },
        });
    }
}
