<div [formGroup]="itemGroup" fxLayout="row wrap" (keydown.enter)="$event.preventDefault()">
    <div fxLayout="row wrap" fxFlex="1 1 100%" fxLayoutAlign="start start">
        <mat-form-field fxFlex="0 1 100%" *ngIf="itemGroup.get('item_type_value').value !== ItemTypes.QUESTION">
            <input matInput type="text" formControlName="title" placeholder="Title" i18n-placeholder />
        </mat-form-field>

        <mat-form-field fxFlex="0 1 100%" *ngIf="itemGroup.get('item_type_value').value !== ItemTypes.QUESTION">
            <input
                #title
                matInput
                type="text"
                formControlName="name"
                required
                placeholder="Overview"
                i18n-placeholder="@@overview"
            />
            <mat-error i18n>This field is required!</mat-error>
        </mat-form-field>
    </div>

    <ng-container [ngSwitch]="itemGroup.value.item_type_value">
        <app-resource-builder-course
            *ngSwitchCase="ItemTypes.COURSE"
            fxFlex="1 1 100%"
            [item]="item"
            [selection]="selection"
            [childGroup]="itemGroup"
        ></app-resource-builder-course>

        <app-resource-builder-test
            *ngSwitchCase="ItemTypes.TEST"
            fxFlex="1 1 100%"
            [item]="item"
            [selection]="selection"
            [childGroup]="itemGroup"
        ></app-resource-builder-test>

        <app-resource-builder-survey
            *ngSwitchCase="ItemTypes.SURVEY"
            fxFlex="1 1 100%"
            [item]="item"
            [selection]="selection"
            [childGroup]="itemGroup"
        ></app-resource-builder-survey>

        <app-resource-builder-material
            *ngSwitchCase="ItemTypes.MATERIAL"
            fxFlex="1 1 100%"
            [item]="item"
            [selection]="selection"
            [childGroup]="itemGroup"
        ></app-resource-builder-material>
        <app-resource-builder-event
            *ngSwitchCase="ItemTypes.EVENT"
            fxFlex="1 1 100%"
            [item]="item"
            [selection]="selection"
            [childGroup]="itemGroup"
        ></app-resource-builder-event>
    </ng-container>
    <mat-accordion fxFlex="0 1 100%" displayMode="flat">
        <mat-expansion-panel #expansionPanel>
            <mat-expansion-panel-header>
                <div *ngIf="!expansionPanel.expanded">
                    <span i18n="@@description"> Description </span>
                </div>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-text-editor
                    fxFlex="1 1 100%"
                    title="Description"
                    i18n-title
                    [showTemplates]="false"
                    formControlName="description"
                ></app-text-editor>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
    <mat-accordion fxFlex="0 1 100%" displayMode="flat" *ngIf="itemGroup.value.item_type_value === ItemTypes.MATERIAL">
        <mat-expansion-panel #expansionPanelDesc>
            <mat-expansion-panel-header>
                <div *ngIf="!expansionPanelDesc.expanded" i18n="@@contentInformation">Content information</div>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
                <app-text-editor
                    fxFlex="1 1 100%"
                    title="Content information"
                    i18n-title
                    [showTemplates]="false"
                    formControlName="instructions"
                ></app-text-editor>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</div>
