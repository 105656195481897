<app-title-translation
    type="title"
    class="title-trans-section"
    placeholder="Insert section title"
    i18n-placeholder
    formControlName="data.formGroup.get('title_translation')"
></app-title-translation>
<!-- <div [ngSwitch]="data.scope">
            <app-section-select
                *ngSwitchCase="AppScope.SECTIONS"
                [hideTools]="true"
                [accessForm]="data.formGroup"
                referenceFilter="referenceCollection"
            ></app-section-select>
        </div> -->
