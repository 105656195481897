import { Collection, CollectionData } from './collection.model';
import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import { HttpErrorService, RequestBuilderService } from '@klickdata/core/http';
import { DatatableHttpService } from 'apps/klickdata/src/app/shared/datatable/datatable-http.service';
import { Observable } from 'rxjs';
import { catchError, map, first } from 'rxjs/operators';
import { AuthService } from '@klickdata/core/auth';
import { AppScope } from '@klickdata/core/resource';

@Injectable({
    providedIn: 'root',
})
export class CollectionService {
    protected collectionUrl: string;
    protected customer_id: Observable<number>;

    constructor(
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected error: HttpErrorService,
        protected auth: AuthService
    ) {
        this.collectionUrl = `${config.config.apiUrl}collections`;
        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }

    public getCustomerCollections(scope: AppScope, customerId?: number): Observable<Collection[]> {
        const request = this.builder
            .get<CollectionData[]>(`${this.collectionUrl}`)
            .param('scope', scope)
            .param('translations', 1);
        request.param('customer', customerId || this.customer_id);

        return request.request().pipe(map((res) => res.data.map((collection) => this.mapCollection(collection))));
    }

    public getPublicCollections(): Observable<Collection[]> {
        const request = this.builder.get<CollectionData[]>(`${this.collectionUrl}`);
        request.param('public', 'true');
        return request.request().pipe(map((res) => res.data.map((collection) => this.mapCollection(collection))));
    }

    public updateCollection(collectionData: CollectionData): Observable<Collection> {
        const req = this.builder.put<CollectionData>(`${this.collectionUrl}/${collectionData.id}`, collectionData);
        return req.request().pipe(map((res) => this.mapCollection(res.data)));
    }

    public setDefaultCollection(id: number, customerId: number): Observable<Collection> {
        const req = this.builder.put<CollectionData>(`${this.collectionUrl}/${id}/default`, null);
        if (customerId) {
            req.param('customer', customerId);
        }
        return req.request().pipe(map((res) => this.mapCollection(res.data)));
    }

    public addCollection(collectionData: CollectionData): Observable<Collection> {
        const req = this.builder.post<CollectionData>(`${this.collectionUrl}`, collectionData);
        return req.request().pipe(map((res) => this.mapCollection(res.data)));
    }

    public deleteCollection(id: number): any {
        return this.builder
            .delete<any>(`${this.collectionUrl}/${id}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((err) => this.error.handle(err))
            );
    }

    protected mapCollection(collectionData: CollectionData): Collection {
        const collection = new Collection(collectionData);
        return collection;
    }
}
