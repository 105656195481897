<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon>add_circle</mat-icon>
        <div i18n>What type of quote?</div>
    </div>
    <div class="items">
        <button
            mat-button
            (click)="onClose.emit({ action: SideNaveActionsTypes.POSITIVE, data: { type: QuoteTypes.TEXT } })"
        >
            <mat-icon>text_fields</mat-icon>
            <span i18n>Text</span>
        </button>
        <button
            mat-button
            (click)="onClose.emit({ action: SideNaveActionsTypes.POSITIVE, data: { type: QuoteTypes.VIDEO } })"
        >
            <mat-icon>play_circle</mat-icon>
            <span i18n>Video</span>
        </button>
        <button
            mat-button
            (click)="onClose.emit({ action: SideNaveActionsTypes.POSITIVE, data: { type: QuoteTypes.IMAGE } })"
        >
            <mat-icon>image</mat-icon>
            <span i18n>Image</span>
        </button>
        <button
            mat-button
            (click)="onClose.emit({ action: SideNaveActionsTypes.POSITIVE, data: { type: QuoteTypes.AUDIO } })"
        >
            <mat-icon>hearing</mat-icon>
            <span i18n>Audio</span>
        </button>
    </div>
</div>
