import { Pipe, PipeTransform } from '@angular/core';
import { CaseStatusTypes } from '@klickdata/core/notification';

@Pipe({
    name: 'caseStatus',
})
export class CaseStatusPipe implements PipeTransform {
    transform(status: string): {
        title: string;
        icon: string;
        value: string;
        action: string;
    } {
        switch (status) {
            case 'open':
                return {
                    title: $localize`created`,
                    icon: 'lock_open',
                    value: 'open',
                    action: $localize`submit case`,
                };
            case 'approved':
                return {
                    title: $localize`approved`,
                    icon: 'event_available',
                    value: 'approved',
                    action: $localize`Approve case`,
                };
            case 'close':
                return {
                    title: $localize`closed`,
                    icon: 'lock',
                    value: 'close',
                    action: $localize`Close case`,
                };
            case 'done':
                return {
                    title: $localize`wrapped`,
                    icon: 'verified',
                    value: 'done',
                    action: $localize`Mark case as wrapped`,
                };
            case 'reopen':
                return {
                    title: $localize`reopened`,
                    icon: 'event_repeat',
                    value: 'reopen',
                    action: $localize`Reopen case`,
                };
            case 'not_approved':
                return {
                    title: $localize`need more work`,
                    icon: 'event_busy',
                    value: 'not_approved',
                    action: $localize`Reject case`,
                };
        }
    }
}
