<div class="purchse-container" fxLayout="column" *ngIf="resource">
    <div ngClass.lt-md="mt-1" class="w-100" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div fxHide.lt-md class="img-wrapper-item">
            <app-image-view
                [proportional]="false"
                [media_id]="resource.media$"
                paddingBottom="70%"
                (srcLoaded)="resource.media_url = $event"
            >
            </app-image-view>
        </div>
        <div fxFlex="grow" fxLayout="column">
            <p class="about-buy" i18n>You are about to buy</p>
            <h3 class="mt-0">{{ resource.title }}</h3>
        </div>
        <div class="price-circle">
            <p class="m-0 white" i18n>Price</p>
            <h3 class="m-0 white">
                <span
                    fxLayoutGap="5px"
                    [fxLayout]="resource.currency === 'SEK' ? 'row-reverse' : 'row'"
                    fxLayoutAlign="center center"
                    ><span>{{ (resource.currency | currencyType).sign }}</span
                    ><strong>{{ resource.price }}</strong></span
                >
            </h3>
        </div>
    </div>
    <mat-horizontal-stepper class="purchase-stepper mt-1" linear #stepper>
        <mat-step [stepControl]="paymentOptionControl" label="Choose Plan" i18n-label="@@choosPlan">
            <div class="btn-group-wrapper" fxLayout="column">
                <mat-radio-group
                    fxLayout="column"
                    [formControl]="paymentOptionControl"
                    fxLayoutAlign="start center"
                    fxLayoutGap="20px"
                    class="pur-radio-group"
                >
                    <mat-radio-button (click)="goToNext(stepper)" [value]="0">
                        <div
                            fxLayout.lt-md="column-reverse"
                            fxLayoutAlign.lt-md="center center"
                            fxLayoutAlign="space-between start"
                            class="pur-pay-option-content"
                            fxLayoutGap.lt-md="10px"
                        >
                            <div class="main-info" fxLayout="column">
                                <h3 class="m-0">
                                    <strong i18n>Single Access</strong>
                                    <strong class="ml-5" *ngIf="resource.currency !== 'SEK'">{{
                                        (resource.currency | currencyType).sign
                                    }}</strong>
                                    <strong> {{ resource.price }}</strong>
                                    <strong class="ml-5" *ngIf="resource.currency === 'SEK'"
                                        >{{ (resource.currency | currencyType).sign }}.</strong
                                    >
                                    <strong class="ml-5" i18n>one time only</strong>
                                </h3>
                                <p>
                                    <span i18n>
                                        You buy this
                                        {{ ResourceTypes.label(resource.type_id, { capitalize: false }) }} and will have
                                        a 12-month access with another 12 months free of charge in case you need it.
                                        Learn this topic at any time and repeat it endlessly.</span
                                    >
                                </p>
                            </div>

                            <div [ngStyle]="{ 'background-color': '#bfd8d0' }" class="saving-circle" fxLayout="column">
                                <h3 class="m-0 white" i18n>Great choice!</h3>
                            </div>
                        </div>
                    </mat-radio-button>
                    <mat-radio-button
                        *ngIf="customer.enable_subscription_plans"
                        (click)="goToNext(stepper)"
                        [value]="1"
                    >
                        <div
                            fxLayout.lt-md="column-reverse"
                            fxLayoutAlign.lt-md="center center"
                            fxLayoutAlign="space-between start"
                            class="pur-pay-option-content"
                            fxLayoutGap.lt-md="10px"
                        >
                            <div class="main-info" fxLayout="column">
                                <h3 class="m-0">
                                    <strong i18n>Buy a Site License for only </strong>
                                    <strong> {{ resource.currency === 'SEK' ? '246 SEK' : '$29' }} /</strong>
                                    <strong i18n>month</strong>
                                </h3>
                                <p>
                                    <span i18n>
                                        You will have access to this Course and also access to ALL other courses in KLMS
                                        for a year. We will charge your credit card
                                        {{ resource.currency === 'SEK' ? '246 SEK' : '$29' }}
                                        every month (total cost
                                        {{ resource.currency === 'SEK' ? '2958 SEK' : '$348' }}
                                        /year). You have the right to cancel your service at any time. The minimum
                                        charge will be 3 months and then one-month cancellation. Easy and flexible. No
                                        hassle. To clarify the minimum you will need to pay and why this is most likely
                                        the best deal: You can cancel within 60 days of purchase to get the lowest fee
                                        in just 90 days. The amount you will be charged per month is stated above. Think
                                        about how much you will learn for less! This great offer may not last long so
                                        you will not regret this choice.</span
                                    >
                                </p>
                            </div>

                            <div [ngStyle]="{ 'background-color': '#93cbd1' }" class="saving-circle" fxLayout="column">
                                <h3 class="m-0 white" i18n>Most flexible!</h3>
                            </div>
                        </div>
                    </mat-radio-button>
                    <mat-radio-button
                        *ngIf="customer.enable_subscription_plans"
                        (click)="goToNext(stepper)"
                        [value]="2"
                    >
                        <div
                            fxLayout.lt-md="column-reverse"
                            fxLayoutAlign.lt-md="center center"
                            fxLayoutAlign="space-between start"
                            class="pur-pay-option-content"
                            fxLayoutGap.lt-md="10px"
                        >
                            <div class="main-info" fxLayout="column">
                                <h3 class="m-0">
                                    <strong i18n>Buy a Site License for only</strong>
                                    <strong>
                                        {{
                                            resource.currency === 'SEK'
                                                ? '2541
                                    SEK'
                                                : '$299'
                                        }}
                                        /</strong
                                    >
                                    <strong i18n>year</strong>
                                </h3>
                                <p>
                                    <span i18n>
                                        You will have access to this Course and also access to ALL other courses in KLMS
                                        for a year. We will debit your credit card
                                        {{
                                            resource.currency === 'SEK'
                                                ? '2541
                                    SEK'
                                                : '$299'
                                        }}/year. You have the right to cancel your service at any time. You have a
                                        one-month cancellation. Easy and flexible. No hassle.</span
                                    >
                                </p>
                            </div>

                            <div [ngStyle]="{ 'background-color': '#3e5264' }" class="saving-circle" fxLayout="column">
                                <h3 class="m-0 white" i18n>Best choice!</h3>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
                <button matStepperNext mat-raised-button color="primary" i18n="@@next">Next</button>
            </div>
        </mat-step>
        <mat-step label="Choose Method" i18n-label="@@choosMethod" [stepControl]="paymentMethodControl">
            <div class="btn-group-wrapper" fxLayout="column">
                <mat-radio-group
                    fxLayout="column"
                    [formControl]="paymentMethodControl"
                    fxLayoutAlign="start center"
                    fxLayoutGap="20px"
                    class="method-radio-group"
                >
                    <mat-radio-button
                        *ngFor="let option of paymentMethods"
                        [value]="option.id"
                        [disabled]="option.disabled"
                        (click)="goToNext(stepper, option.disabled)"
                    >
                        <div
                            fxLayout.lt-md="column"
                            fxLayoutGap.lt-md="30px"
                            fxLayoutAlign="space-between center"
                            class="pur-pay-option-content"
                        >
                            <div fxLayout="column">
                                <h3 class="m-0 method-title">
                                    <strong>{{ option.title }}</strong>
                                </h3>
                            </div>
                            <div
                                class="method-logo"
                                [class.apple-logo-wrapper]="option.name === 'apple'"
                                fxLayout="column"
                            >
                                <img [src]="option.logo_url" />
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
                <div fxLayout="row" fxLayoutGap="10px">
                    <button mat-button matStepperPrevious i18n="@@back">Back</button>
                    <button matStepperNext mat-raised-button color="primary" i18n="@@next">Next</button>
                </div>
            </div>
        </mat-step>
        <mat-step label="Payment" i18n-label="@@payment">
            <div class="pay-value">
                <span i18n>You are about to pay</span
                ><span class="orange"> {{ selectedPaymentOption.price }} {{ selectedPaymentOption.currency }}</span>
                <span i18n> for a great learning experience. Please confirm.</span>
            </div>
            <div (click)="purchasePermission()">
                <app-purchase-manager
                    [gateway]="selectedPaymentMethod.name"
                    [ngClass]="{ 'block-purchase': !termsCheckControl.value }"
                    (paymentSucceeded)="paymentSucceeded.emit($event)"
                    [resource]="resource"
                ></app-purchase-manager>
                <app-swish-purchase *ngIf="paymentMethodControl.value === 2"></app-swish-purchase>
                <app-apple-by-purchase *ngIf="paymentMethodControl.value === 3"></app-apple-by-purchase>
            </div>
            <button matStepperPrevious mat-raised-button color="primary" i18n="@@back">Back</button>
        </mat-step>
    </mat-horizontal-stepper>
    <div class="faq-section">
        <app-purchase-faq></app-purchase-faq>
    </div>
    <div class="rights">
        <mat-checkbox [formControl]="termsCheckControl" color="primary"
            ><span i18n
                >I accept KlickData's <span (click)="readMoreLink()" class="orange">terms of use</span></span
            ></mat-checkbox
        >
    </div>
</div>
