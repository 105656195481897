<!-- <div class="mb-1 results-wrapper-overall" fxLayout="column" fxLayoutAlign="center center">
    <div class="results-wrapper" fxLayout="column wrap">
        <app-table-toolbar class="toolbar" color="accent">
            <mat-accordion fxFlex="0 0 100%" displayMode="flat">
                <mat-expansion-panel [expanded]="false" #expansionPanel>
                    <mat-expansion-panel-header> </mat-expansion-panel-header>
                    <app-table-filter
                        [inGlobalFilter]="true"
                        
                        color="accent"
                        #filter
                    >
                        <app-table-search
                            [inGlobalFilter]="true"
                            class="align-space"
                            searchFilterRef
                        ></app-table-search>
                    </app-table-filter>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-progress-bar class="progress" *ngIf="(dataSource.status | async) === 'loading'" mode="indeterminate">
            </mat-progress-bar>
        </app-table-toolbar>
        <div class="table-wrapper">
            <mat-table #table [dataSource]="dataSource" matSort matSortDirection="desc">
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name">
                        Name
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <div class="user-img">{{ user.name | nameSign }}</div>
                        {{ user.name }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="correct">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Correct">
                        Correct
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user"> {{ user.finalResult?.percentage }}% </mat-cell>
                </ng-container>
                <ng-container matColumnDef="qs_correct">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@QSCorrect">
                        QS Correct
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        {{ user.finalResult?.correct }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="grade">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@grade">
                        Grade
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        {{ user.finalResult?.grade }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="passing">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@pass">
                        Pass
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <span i18n="@@yes" *ngIf="user.finalResult?.passed"> Yes </span>
                        <span i18n="@@no" *ngIf="!user.finalResult?.passed"> No </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="test_date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@testDate">
                        Test date
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        {{ user.finalResult?.started_at | kdDateFormat: 'YYYY-MM-DD' }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="test_time">
                    <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@testTime">
                        Test time
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        {{
                            user.finalResult?.done_at
                                | amDifference: user.finalResult?.started_at:'seconds':true
                                | formatIsoDuration
                        }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tools">
                    <mat-header-cell *matHeaderCellDef i18n="@@tools">
                        Tools
                    </mat-header-cell>
                    <mat-cell class="tools_btn" *matCellDef="let user">
                        <ng-container>
                            <button
                                matTooltip="Email this user"
                                i18n-matTooltip="@@emailThisUser"
                                mat-icon-button
                                (click)="$event.stopPropagation(); sendEmail(user)"
                            >
                                <mat-icon>email</mat-icon>
                            </button>
                        </ng-container>
                        <ng-container>
                            <button
                                matTooltip="Review results"
                                i18n-matTooltip="@@reviewResults"
                                mat-icon-button
                                (click)="$event.stopPropagation(); viewUserResults(user)"
                            >
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </ng-container>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row
                    class="link"
                    *matRowDef="let user; columns: columns"
                    matRipple
                    (click)="viewUserResults(user)"
                ></mat-row>
            </mat-table>
        </div>
        <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
    </div>
</div> -->

<app-user-listing-general
    [table_columns]="['name', 'percentage', 'correct', 'passed', 'grade', 'started_at', 'duration', 'tools']"
    [type_scope_id]="AppScope.TEST"
    selectingStyleClass="app-test-results-overview"
    [allowActionHeader]="false"
    [allowActionRow]="false"
    activeSortingCol="name"
    activeSortingDirection="asc"
    [areFiltersInAccordionInDesktop]="true"
    (onSendEmail)="sendEmail($event)"
    (onRowClicked)="viewUserResults($event)"
    (onBrowse)="viewUserResults($event)"
    [tableFilterItems]="tableFilterItems"
    
    [tableToolsAction]="[ResourceTableActions.VIEW, ResourceTableActions.EMAIL]"
>
</app-user-listing-general>
