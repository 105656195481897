import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss'],
})
export class FullLayoutComponent implements OnInit {
    @Input() hideFooter = false;
    @Input() haveLoginFooter = false;
    constructor() {}

    ngOnInit() {}
}
