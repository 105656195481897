<div fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutAlign.lt-sm="start" class="content-title-wrapper">
    <div fxLayoutAlign="start center">
        <mat-icon fxFlex="0 0 auto" color="primary" class="course-plan-icon kd-icon-48 no-shadow"
            >library_books</mat-icon
        >
        <h1 i18n>Catalog</h1>
    </div>
</div>
<div
    class="manage-res-content-wrapper content-catalog-wrapper"
    fxFlex="0 0 100%"
    fxLayout="column wrap"
    fxLayoutAlign="start center"
>
    <ng-container
        [ngTemplateOutlet]="downloadBtn"
        [ngTemplateOutletContext]="{ downloads: this.categoryService.onDownloadCatalog | async }"
    ></ng-container>
    <mat-toolbar>
        <nav *ngIf="!(isMobile | async)" mat-tab-nav-bar fxFlex="0 0 100%" ngClass.xs="xs">
            <a
                mat-tab-link
                [routerLink]="['./course']"
                routerLinkActive
                #rlaCourses="routerLinkActive"
                [active]="rlaCourses.isActive"
                i18n="@@courses"
            >
                Courses
            </a>
            <a
                mat-tab-link
                [routerLink]="['./test']"
                routerLinkActive
                #rlaTests="routerLinkActive"
                [active]="rlaTests.isActive"
                i18n="@@tests"
            >
                Tests
            </a>
            <a
                mat-tab-link
                [routerLink]="['./survey']"
                routerLinkActive
                #rlaSurveys="routerLinkActive"
                [active]="rlaSurveys.isActive"
                i18n="@@surveys"
            >
                Surveys
            </a>
            <a
                mat-tab-link
                [routerLink]="['./material']"
                routerLinkActive
                #rlaMaterials="routerLinkActive"
                [active]="rlaMaterials.isActive"
                i18n="@@materials"
            >
                Materials
            </a>
            <a
                mat-tab-link
                [routerLink]="['./ecourse']"
                routerLinkActive
                #rlaECourses="routerLinkActive"
                [active]="rlaECourses.isActive"
                i18n="@@ecourses"
            >
                E-Courses
            </a>
        </nav>
        <app-generic-mobile-tabs
            fxFlex="100"
            *ngIf="isMobile | async"
            wrapperClass="activites-accordion-list"
            [options]="activityOptions"
        ></app-generic-mobile-tabs>
    </mat-toolbar>
    <router-outlet></router-outlet>
</div>
<ng-template #downloadBtn let-downloads="downloads">
    <div class="add-btn-content">
        <div *ngIf="!downloads.disabled" class="hits">{{downloads.totalResCount}}<span i18n> hits </span></div>
        <button
            add-btn
            mat-icon-button
            type="button"
            [disabled]="downloads.disabled"
            fxFlex="0 0 auto"
            class="down-btn"
            matTooltip="Export"
            i18n-matTooltip="@@export"
            (click)="downloadCatalog(downloads.downloads)"
        >
            <mat-icon class="kd-icon-36">save_alt</mat-icon>
        </button>
    </div>
</ng-template>
