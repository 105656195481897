import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MobileService } from '@klickdata/core/mobile';
import { User, UserService } from '@klickdata/core/user';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../../global-filters-types.enum';
import { ChipFilterComponent } from '../chip-filter/chip-filter.component';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';
import { String } from 'aws-sdk/clients/acm';

@Component({
    selector: 'app-user-chip-search-filter',
    templateUrl: './user-chip-search-filter.component.html',
    styleUrls: ['./user-chip-search-filter.component.scss'],
    providers: [
        { provide: ChipFilterComponent, useExisting: forwardRef(() => UserChipSearchFilterComponent) },
        { provide: FilterBase, useExisting: UserChipSearchFilterComponent },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserChipSearchFilterComponent extends ChipFilterComponent implements OnInit, OnDestroy {
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Input() inGlobalFilter: boolean;
    @Input() isMaster: boolean;
    @Input() roleValue: ResourceStaffRoles;
    @Input() searchLabel = $localize`Learner`;
    @Input() searchPlaceholder = $localize`Filter by learner name...`;
    @Input() chosingLabel = $localize`Choose learner`;
    @Input() additionalSearchFilters: Filter<string>[] = [];
    public control: FormControl = new FormControl();
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.USERS,
        items: [],
        label: $localize`:@@learners:Learners`,
        icon: 'person',
        color: { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.WHITE },
        styleClass: 'global-users-filter-selector',
    };
    constructor(
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService,
        protected userService: UserService
    ) {
        super(changeRef, mobile);
    }

    ngOnInit() {
        this.control.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((query) =>
                    query
                        ? this.getUsers(query).pipe(
                              map((users) =>
                                  users.map((user, index) => {
                                      return {
                                          id: user.id,
                                          label: user.name,
                                          selected: null, // (AS, EB SSC on 230918 skype)
                                      };
                                  })
                              )
                          )
                        : of([])
                )
            )
            .subscribe((items) => {
                this.source = items;
                if (items?.length) {
                    this.updateSelection();
                } else {
                    this.removeFilter();
                }
                this.changeRef.markForCheck();
            });
    }
    private getUsers(query: String): Observable<User[]> {
        return this.isMaster
            ? this.userService.getLightMasterUsers({
                  ...{ query: query, limit: 10, role: this.roleValue },
                  ...this.getparamsFromFilters(this.additionalSearchFilters),
              })
            : this.userService.getCustomerUsers({ term: query, limit: 10, role: this.roleValue });
    }
    getparamsFromFilters(filters: Filter<string>[]) {
        return filters.reduce((acc, item) => {
            acc[item.property] = item.items.join(',');
            return acc;
        }, {});
    }
    public setQuery(query: string): void {
        this.control.setValue(query);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
