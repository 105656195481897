import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { EditorTemplate } from '@klickdata/shared-components/src/text-editor/editor-template.model';
import { EditorTemplateService } from '@klickdata/shared-components/src/text-editor/editor-template.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExpandAnimation } from '../../../core/animations/expand.animation';
import { ButtonModel, EditorComponent } from './editor/editor.component';

@Component({
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',
    styleUrls: ['./text-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextEditorComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ExpandAnimation],
})
export class TextEditorComponent implements OnInit, OnDestroy, ControlValueAccessor {
    @ViewChild(EditorComponent) editor: EditorComponent;
    @Input()
    public title: string;

    @Input()
    public customButton: ButtonModel;

    @Input()
    public placeholder: string;

    @Input()
    public fieldName: string = null;

    @Input()
    public showTemplates = true;
    // Optional filters
    @Input()
    public groupId: string;

    @Input()
    public customerId: number;

    @Input()
    public fitTemplatesInSmallScreen: boolean;

    @Input()
    public master: boolean;
    public error = false;
    public focus = false;
    public templateSelected = false;

    private _body: string;
    public get body(): string {
        return this._body;
    }

    public set body(value: string) {
        this._body = value;
    }

    @Input()
    public subject: string;

    /**
     * Emits the value of title.
     * @event
     */
    @Output()
    public onSubject: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('tabs')
    public tabs: MatTabGroup;

    protected destroy: Subject<boolean> = new Subject<boolean>();
    public formControlName: NgControl;

    constructor(
        protected dialog: MatDialog,
        protected editorService: EditorTemplateService,
        protected fb: FormBuilder,
        protected injector: Injector,
        protected changeRef: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        this.formControlName = this.injector.get(NgControl);
        if (this.fieldName) {
            this.editorService
                .getDefaultTemplate(this.fieldName)
                .pipe(takeUntil(this.destroy))
                .subscribe(template => {
                    if (template) {
                        this.onTemplateSelect(template);
                    }
                });
        }
    }

    public onChange(content: string): void {
        this.propagateChange(content);
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public onFocus(value: boolean): void {
        this.focus = value;
    }

    public onBlur(value: string): void {
        this.body = value;
    }

    public onTemplateSelect(template: EditorTemplate): void {
        this.setBody(template.body);
        this.onSubject.emit(template.subject || template.title);
        if (this.tabs) {
            this.tabs.selectedIndex = 0;
        }
    }

    public setBody(value: string) {
        this.formControlName.control?.setValue(value);
    }

    public writeValue(value: string): void {
        if (value === null) {
            this.formControlName.control?.setValue('');
        }
        this.body = value;
    }

    public propagateChange = (_: any) => {};

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}
}
