import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { AppScope } from '@klickdata/core/resource';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-menu-side-question-extra-info',
    templateUrl: './menu-side-question-extra-info.component.html',
    styleUrls: ['./menu-side-question-extra-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideQuestionExtraInfoComponent implements OnChanges, OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    AppScope = AppScope;
    public form: FormGroup;
    public buttonDisabled: boolean;
    public saving: Subject<boolean> = new Subject<boolean>();
    SideNaveActionsTypes = SideNaveActionsTypes;

    constructor(protected fb: FormBuilder, protected cd: ChangeDetectorRef) {
        this.form = this.fb.group({
            description: [''],
            learning_field: [''],
            references: [''],
        });
    }
    ngOnInit(): void {
        if (this.navData?.data) {
            this.updateForm();
        }
        this.form.valueChanges.subscribe(() => {
            this.buttonDisabled = false;
            this.cd.markForCheck();
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.navData?.data?.value) {
            this.updateForm();
        }
        if (!this.navData.data?.editable) {
            this.form.disable();
        }
    }
    private updateForm() {
        this.form.patchValue({
            description: this.navData.data.value.description,
            learning_field: this.navData.data.value.learning_field,
            references: this.navData.data.value.references,
        });

        this.buttonDisabled = true;
        this.cd.markForCheck();
    }
    public submit() {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                info: this.form.value,
                type: this.navData.data.type,
            },
        });
        this.clear();
    }
    public clear() {
        this.form.reset();
        this.buttonDisabled = true;
        this.cd.markForCheck();
    }
}
