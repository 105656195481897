<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>attachment</mat-icon>
        <div i18n>View Attachments</div>
    </div>
    <ng-container *ngIf="mediaItems$ | async; let mediaItems; else: smallLoading">
        <div *ngIf="mediaItems.length" class="medias">
            <div class="mediaItem" *ngFor="let mediaItem of mediaItems">
                <div class="row">
                    <span class="row-title" i18n>Name:</span>
                    <span class="row-info">{{ mediaItem.filename }}</span>
                </div>
                <div class="row">
                    <span class="row-title" i18n>Created:</span>
                    <span class="row-info">{{ mediaItem.created_at | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
                    <button
                        (click)="downlaod(mediaItem)"
                        matTooltip="Download"
                        mat-icon-button
                        i18n-matTooltip="@@download"
                        class="downlaod-btn"
                    >
                        <mat-icon>cloud_download</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            i18n
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="onClose.emit({ action: SideNaveActionsTypes.POSITIVE });"
            mat-raised-button
        >
            OK
        </button>
    </div>
</div>
<ng-template #smallLoading>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
