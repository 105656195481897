import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@klickdata/core/util';

@Pipe({
    name: 'currencyType',
})
export class CurrencyTypePipe implements PipeTransform {
    transform(resourceCurrency: string): any {
        return Utils.getAllCurrencies().find(curr => curr.code === resourceCurrency);
    }
}
