import {
    Directive,
    EmbeddedViewRef,
    Input,
    OnDestroy,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { LoggerService } from '@klickdata/core/application/src/logger/logger.service';
import { Subscription } from 'rxjs';
import { CapabilityService } from './capability.service';

export interface AccessCapability {
    /**
     * Capability name
     */
    name: string;

    /**
     * Capability value
     */
    value: number;
}

@Directive({
    selector: '[appCanAccess]',
})
export class CanAccessDirective implements OnDestroy {
    protected viewRef: EmbeddedViewRef<any> = null;
    protected capabilitySub: Subscription;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private capabilityService: CapabilityService,
        protected logger: LoggerService
    ) {}

    @Input()
    set appCanAccess(capability: AccessCapability) {
        if (!this.capabilitySub) {
            this.capabilitySub = this.capabilityService
                .check(capability.name, capability.value)
                .subscribe((access: boolean) => {
                    // const types = Capabilities.getType(capability.name);
                    // let type: number | string = capability.value;
                    // for (const key in types) {
                    //     if (types.hasOwnProperty(key) && types[key] === capability.value) {
                    //         type = key;
                    //     }
                    // }
                    // this.logger.info(`AccessControl: ${capability.name}:${type} access ${access}`);

                    if (access) {
                        if (!this.viewRef) {
                            this.viewContainer.clear();
                            this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
                        }
                    } else {
                        this.viewContainer.clear();
                        this.viewRef = null;
                    }
                });
        }
    }

    ngOnDestroy(): void {
        if (this.capabilitySub) {
            this.capabilitySub.unsubscribe();
            this.capabilitySub = null;
        }
    }
}
