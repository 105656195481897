import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RequestBuilder } from '@klickdata/core/http/src/request/request-builder';
import { RequestMethods } from '@klickdata/core/http/src/request/request-methods.enum';

@Injectable({
    providedIn: 'root',
})
export class RequestBuilderService {
    constructor(protected httpClient: HttpClient) {}

    get<T>(url: string): RequestBuilder<T> {
        return new RequestBuilder(this.httpClient, RequestMethods.GET, url);
    }

    post<T>(url: string, body: any): RequestBuilder<T> {
        return new RequestBuilder(this.httpClient, RequestMethods.POST, url, body);
    }

    put<T>(url: string, body: any): RequestBuilder<T> {
        return new RequestBuilder<T>(this.httpClient, RequestMethods.PUT, url, body);
    }

    delete<T>(url: string): RequestBuilder<T> {
        return new RequestBuilder<T>(this.httpClient, RequestMethods.DELETE, url);
    }

    head(url: string) {
        return this.httpClient.head(url, {
            params: { mode: 'no-cors' },
        });
    }
}
