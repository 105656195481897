import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Subject } from 'rxjs';
import { PublishMessageService } from '@klickdata/core/resource';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';

@Component({
    selector: 'app-resource-invite-sheet',
    templateUrl: './resource-invite-sheet.component.html',
    styleUrls: ['./resource-invite-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceInviteSheetComponent implements OnInit {
    actionText = $localize`Invite`;
    public isLoading: boolean;
    protected destroy: Subject<boolean> = new Subject<boolean>();
    public inviteForm: FormGroup;
    public invitationMails: string[] = [];
    public showSubmit: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected bottomSheetRef: MatBottomSheetRef<ResourceInviteSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected changeRef: ChangeDetectorRef,
        protected service: PublishMessageService,
        protected messageService: MessageService
    ) {
        this.buildForm();
    }

    ngOnInit(): void {}
    buildForm() {
        this.inviteForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            body: [''],
            subject: [''],
        });
    }
    public handleEmailKeyPress(ev: KeyboardEvent, email: string) {
        if (this.inviteForm.get('email').valid && (ev.key === 'Enter' || ev.key === ',')) {
            this.addToInvitationEmail(email);
        }
    }

    public addToInvitationEmail(email: string) {
        setTimeout(() => {
            if (!this.invitationMails.includes(email)) {
                this.inviteForm.get('email').setValue('');
                this.invitationMails.push(email);
            }
        }, 100);
    }

    public removeMail(invitationMail: string) {
        const index = this.invitationMails.indexOf(invitationMail);
        if (index >= 0) {
            this.invitationMails.splice(index, 1);
            this.changeRef.markForCheck();
        }
    }

    public submitAction() {
        this.service
            .inviteResource({
                body: this.inviteForm.get('body').value,
                resource_id: this.data.id,
                subject: this.inviteForm.get('subject').value,
                emails: this.invitationMails,
            })
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.messageService.openMessage(MessageSavedComponent, $localize`Invitation sent successfully`);
                this.onClose();
            });
    }
    public onClose() {
        this.bottomSheetRef.dismiss();
    }
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
