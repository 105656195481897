<mat-tab-group fxFlex="0 0 100%" class="test-results-tabs">
    <mat-tab label="Overview" i18n-label="@@overview">
        <ng-template matTabContent>
            <app-tests-results-questions [resource]="resource"></app-tests-results-questions>
        </ng-template>
    </mat-tab>
    <mat-tab label="Learners" i18n-label="@@learners">
        <ng-template matTabContent>
            <app-tests-results-overall [resource]="resource"></app-tests-results-overall>
        </ng-template>
    </mat-tab>
    <mat-tab label="Answers" i18n-label="@@Answer">
        <ng-template matTabContent>
            <app-tests-results-answers [resource]="resource"></app-tests-results-answers>
        </ng-template>
    </mat-tab>

</mat-tab-group>
