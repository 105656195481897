import { Injectable } from '@angular/core';
import { LoggerService } from '@klickdata/core/application';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http';
import { Credentials, CredentialsOptions } from 'aws-sdk/lib/credentials';
import moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AwsService {
    private readonly AWS_EXPIRES_AT = 'aws_expires_at';
    private readonly AWS_CREDENTIALS = 'aws_credentials_obj';
    protected url: string;
    constructor(
        protected builder: RequestBuilderService,
        protected logger: LoggerService,
        protected config: ConfigService
    ) {
        this.url = `${this.config.config.apiUrl}auth/aws/s3/token`;
    }

    getAWSCredentials(ignoreCache = false): Observable<CredentialsOptions> {
        try {
            const cachedCredentialsJson = localStorage.getItem(this.AWS_CREDENTIALS);
            const cachedCredentials: CredentialsOptions = cachedCredentialsJson
                ? JSON.parse(cachedCredentialsJson)
                : null;
            const expiresAt = localStorage.getItem(this.AWS_EXPIRES_AT);
            const now = moment().add(1, 'minute'); // add a 1-minute buffer to avoid expired credentials
            if (!ignoreCache && cachedCredentials && expiresAt && now.isBefore(moment(expiresAt))) {
                this.logger.log('AWS credentials loaded from cache and are valid');
                return of(cachedCredentials);
            } else {
                this.logger.log('AWS credentials were not found in the cache or have expired');
            }
        } catch (error) {
            this.logger.error(`Failed to use AWS credentials with error: ${error.message}`);
        }
        return this.builder
            .get<CredentialsOptions | Credentials>(this.url)
            .request()
            .pipe(
                first(),
                catchError((err) => throwError(`Unable to load AWS S3 token from server with error ${err.message}!`)),
                map((res) => res.data),
                tap((data) => {
                    if (!data) {
                        throwError('Unable to load AWS S3 token!');
                    }
                    this.setCredentials(data);
                })
            );
    }

    private setCredentials(value: CredentialsOptions | Credentials) {
        this.logger.info('AWS credentials cached successfully!');
        localStorage.setItem(this.AWS_CREDENTIALS, JSON.stringify(value));
        localStorage.setItem(this.AWS_EXPIRES_AT, moment((<Credentials>value).expireTime).format());
    }

    public resetCredentials() {
        this.logger.info('AWS credentials cache cleared!');
        localStorage.removeItem(this.AWS_CREDENTIALS);
        localStorage.removeItem(this.AWS_EXPIRES_AT);
    }
}
