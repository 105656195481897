<div *ngIf="user$ | async; let user" class="layout res-details-page course-details-wrapper">
    <div fxLayout="column" class="relative">
        <div class="mb-1 bg-wrapper">
            <div class="lay-over"></div>
            <app-image-view
                [paddingBottom]="'320px'"
                [media_id]="
                    (data.resource.medias?.background?.length && data.resource.medias?.background?.length[0]) ||
                    data.resImg
                "
                (srcLoaded)="mediaUrl = $event"
            >
            </app-image-view>
        </div>
        <div class="course-content-wrapper" fxLayout="column">
            <div class="pre-wrapper" fxLayout="row" fxLayoutGap="3em">
                <!-- Main img and course items in desktop -->
                <div *ngIf="!(isMobile | async)" class="left-info-wrapper" fxLayout="column">
                    <div class="kd-lib">
                        <img
                            *ngIf="data.resource.public"
                            src="assets/images/KD_KOL_logo_long_white.png"
                            alt="KlickData"
                        />
                    </div>
                    <div class="main-img">
                        <app-image-view [media_id]="data.resImg" (srcLoaded)="mediaUrl = $event"></app-image-view>
                    </div>
                    <ng-container *ngIf="ResourceTypes.parentType(data.resource.type_id) !== ResourceTypes.MATERIAL">
                        <div *ngIf="resourceItems | async; let items" class="res-items mt-1" fxLayout="column">
                            <div fxLayoutGap="7px" fxLayoutAlign="start center" class="items-label">
                                <mat-icon>category</mat-icon>
                                <span
                                    >{{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                    <span i18n="@@include">include</span
                                    ><ng-container
                                        *ngIf="
                                            data.resource.type_id === ResourceTypes.GeneralSurvey ||
                                            data.resource.type_id === ResourceTypes.GeneralTest
                                        "
                                    >
                                        {{ items?.length }}
                                        <span i18n="@@questions">Questions</span>
                                    </ng-container>
                                </span>
                            </div>

                            <ng-container
                                *ngIf="
                                    data.resource.type_id === ResourceTypes.GeneralCoursePlan ||
                                    data.resource.type_id === ResourceTypes.General_E_Course ||
                                    data.resource.type_id === ResourceTypes.SCORM
                                "
                            >
                                <div class="res-bull mt-1" *ngFor="let item of items; let index = index">
                                    <ng-container *ngIf="data.resource.type_id !== ResourceTypes.GeneralCoursePlan">
                                        <span class="index">{{ index + 1 }}</span>
                                        <span
                                            class="res-item-name"
                                            [class.ecourse]="
                                                data.resource.type_id === ResourceTypes.General_E_Course ||
                                                data.resource.type_id === ResourceTypes.SCORM
                                            "
                                            [class.parent]="!item.parent_id"
                                            >{{ item.name }}</span
                                        >
                                    </ng-container>
                                    <div
                                        class="course-items-bull"
                                        *ngIf="data.resource.type_id === ResourceTypes.GeneralCoursePlan"
                                    >
                                        <span class="index">{{ index + 1 }}</span>
                                        <!-- <mat-icon
                                            fontSet="klickdata"
                                            [fontIcon]="displayCorrectIcon(item.item_type_value)"
                                            class="kd-icon"
                                        ></mat-icon> -->
                                        <!-- <app-image-view
                                            [smallSpinner]="true"
                                            [media_id]="item.media_id"
                                            [proportional]="true"
                                        ></app-image-view> -->
                                        <span class="res-item-name">{{ item.name }}</span>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>

                <div class="right-info-wrapper">
                    <div class="right-top-wrapper" fxLayoutAlign="space-between start" fxLayout="column">
                        <!-- lang, date, ...... in desktop -->
                        <div *ngIf="!(isMobile | async)" class="play-btn" fxLayout="column">
                            <button class="take" mat-raised-button>
                                <mat-icon>play_circle</mat-icon>
                                <span *ngIf="!data.resource.opportunity_id">
                                    <span
                                        *ngIf="ResourceTypes.parentType(data.resource.type_id) === ResourceTypes.COURSE"
                                        i18n="@@enroll"
                                        >Enroll</span
                                    >
                                    <span
                                        *ngIf="ResourceTypes.parentType(data.resource.type_id) !== ResourceTypes.COURSE"
                                        i18n="@@start"
                                        >Start</span
                                    >
                                </span>
                            </button>
                            <div class="small-info flag">
                                <mat-icon>flag</mat-icon>
                                <div class="dates-wrapper">
                                    <span
                                        *ngIf="ResourceTypes.parentType(data.resource.type_id) === ResourceTypes.COURSE"
                                    >
                                        <span i18n="@@courseLanguage">Course Language</span>
                                    </span>
                                    <span
                                        *ngIf="ResourceTypes.parentType(data.resource.type_id) !== ResourceTypes.COURSE"
                                    >
                                        {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                        <span i18n="@@Language">Language </span>
                                    </span>
                                    <app-language-selector
                                        [language_id]="data.resource.language_id"
                                        class="icon-resource-builder"
                                        fxFlex="0 0 auto"
                                    ></app-language-selector>
                                </div>
                            </div>
                            <div *ngIf="data.resource.public" class="timer-off small-info">
                                <mat-icon>public</mat-icon>
                                <span>
                                    {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                    <span i18n="@@isPublic"> is public </span>
                                </span>
                            </div>
                        </div>
                        <div class="title-des-wrapper" [ngClass]="{ 'w-70': !data.resource.description }">
                            <div class="mobile-top-part">
                                <div class="kol-title-wrapper">
                                    <div *ngIf="(isMobile | async) && data.resource.public" class="kd-lib">
                                        <img src="assets/images/KD_KOL_logo_long.png" alt="KlickData" />
                                    </div>
                                    <h2>
                                        {{ data.resource.title }}
                                    </h2>
                                </div>

                                <div class="main-img mobile-main-img" *ngIf="isMobile | async">
                                    <app-image-view
                                        [media_id]="data.resImg"
                                        (srcLoaded)="mediaUrl = $event"
                                    ></app-image-view>
                                </div>
                            </div>
                            <span
                                *ngIf="data.resource.bullets"
                                class="desc-res"
                                [innerHtml]="styleInnerHTML(data.resource.bullets)"
                            ></span>
                            <span *ngIf="!data.resource.bullets" class="desc-res"></span>
                            <!-- lang, date, ...... in mobile -->
                            <div *ngIf="isMobile | async" class="mobile-tools">
                                <div class="play-btn-wrapper-mob"></div>
                                <div class="down-arrow-wrapper-mob">
                                    <button (click)="hideImg = !hideImg" class="expand" mat-icon-button>
                                        <span i18n="@@info">Info</span>
                                        <mat-icon [ngClass]="{ 'rotate-expand-icon': hideImg }">expand_more</mat-icon>
                                    </button>
                                    <button class="take" mat-raised-button>
                                        <mat-icon>play_circle</mat-icon>
                                        <span *ngIf="!data.resource.opportunity_id">
                                            <span
                                                *ngIf="
                                                    ResourceTypes.parentType(data.resource.type_id) ===
                                                    ResourceTypes.COURSE
                                                "
                                                style="margin-right: 3px"
                                                i18n="@@enroll"
                                                >Enroll</span
                                            >
                                            <span
                                                *ngIf="
                                                    ResourceTypes.parentType(data.resource.type_id) !==
                                                    ResourceTypes.COURSE
                                                "
                                                style="margin-right: 3px"
                                                i18n="@@start"
                                                >Start</span
                                            >
                                        </span>
                                        <span *ngIf="data.resource.opportunity_id" i18n="@@Continue">Continue</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="right-middle-wrapper mb-2"
                        [fxLayoutGap]="(isMobile | async) ? '1px' : '3em'"
                        [fxLayout]="(isMobile | async) ? 'column' : 'row'"
                    >
                        <div
                            *ngIf="isMobile | async"
                            fxLayout="row"
                            class="mobile-course-extraInfo-wrapper"
                            fxLayoutAlign="space-between center"
                        >
                            <div class="mobile-course-extraInfo">
                                <mat-accordion displayMode="flat">
                                    <mat-expansion-panel #specsExpansionPanel>
                                        <mat-expansion-panel-header>
                                            <mat-icon>announcement</mat-icon>
                                            <ng-container>
                                                {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                                <span i18n="@@specifications">specifications</span>
                                            </ng-container>
                                        </mat-expansion-panel-header>
                                        <!-- Language -->
                                        <div class="res-details desc-elem mob-lang-wrapper">
                                            <div class="pre">
                                                <mat-icon>flag</mat-icon>
                                                <span
                                                    *ngIf="
                                                        ResourceTypes.parentType(data.resource.type_id) ===
                                                        ResourceTypes.COURSE
                                                    "
                                                >
                                                    <span i18n="@@courseLanguage">Course Language</span>
                                                </span>
                                                <span
                                                    *ngIf="
                                                        ResourceTypes.parentType(data.resource.type_id) !==
                                                        ResourceTypes.COURSE
                                                    "
                                                >
                                                    {{
                                                        ResourceTypes.label(data.resource.type_id, { capitalize: true })
                                                    }}
                                                    <span i18n="@@Language">Language </span>
                                                </span>
                                            </div>
                                            <span class="normal">
                                                <app-language-selector
                                                    [language_id]="data.resource.language_id"
                                                    class="icon-resource-builder"
                                                    fxFlex="0 0 auto"
                                                ></app-language-selector>
                                            </span>
                                        </div>

                                        <!-- Duration -->
                                        <div *ngIf="data.resource.duration" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>timelapse</mat-icon>
                                                <span
                                                    *ngIf="
                                                        ResourceTypes.parentType(data.resource.type_id) ===
                                                        ResourceTypes.COURSE
                                                    "
                                                >
                                                    <span i18n="@@courseDuration">Course duration</span>
                                                </span>
                                                <span
                                                    *ngIf="
                                                        ResourceTypes.parentType(data.resource.type_id) !==
                                                        ResourceTypes.COURSE
                                                    "
                                                >
                                                    {{
                                                        ResourceTypes.label(data.resource.type_id, { capitalize: true })
                                                    }}
                                                    <span i18n="@@duration">duration</span>
                                                </span>
                                            </div>
                                            <span class="normal"
                                                >{{
                                                    data.resource?.duration
                                                        | formatIsoDuration
                                                            : data.resource.type_id ===
                                                                  ResourceTypes.General_E_Course ||
                                                                  data.resource.type_id === ResourceTypes.SCORM
                                                }}
                                            </span>
                                        </div>
                                        <!-- Play time -->
                                        <div *ngIf="data.resource.time_limit" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>timer</mat-icon>
                                                <span i18n="@@playTime">Play time </span>
                                            </div>
                                            <span class="normal"
                                                >{{ data.resource?.time_limit | formatIsoDuration }}
                                            </span>
                                        </div>
                                        <!-- Creator -->
                                        <!-- Instruuctor refactor needed -->
                                        <!-- <div *ngIf="data.resource.instructor?.name" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>engineering</mat-icon>
                                                <span i18n="@@instructor">Instructor </span>
                                            </div>
                                            <span class="normal">
                                                {{ data.resource.instructor?.name }}
                                            </span>
                                        </div> -->
                                        <!-- Course code -->
                                        <div *ngIf="data.resource.article_code" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>center_focus_strong</mat-icon>
                                                <span *ngIf="data.resource.type_id === ResourceTypes.GeneralCoursePlan">
                                                    <span i18n="@@courseCode">Course code</span>
                                                </span>
                                                <span *ngIf="data.resource.type_id === ResourceTypes.GeneralTest">
                                                    <span i18n="@@testCode">Test code</span>
                                                </span>
                                                <span *ngIf="data.resource.type_id === ResourceTypes.GeneralSurvey">
                                                    <span i18n="@@surveyCode">Survey code</span>
                                                </span>
                                                <span *ngIf="data.resource.type_id === ResourceTypes.General_E_Course">
                                                    <span i18n="@@ecourseCode">E-course code</span>
                                                </span>
                                                <span *ngIf="data.resource.type_id === ResourceTypes.SCORM">
                                                    <span i18n="@@scormCode">SCORM code</span>
                                                </span>
                                                <span
                                                    *ngIf="
                                                        ResourceTypes.parentType(data.resource.type_id) ===
                                                        ResourceTypes.MATERIAL
                                                    "
                                                >
                                                    <span i18n="@@materialCode">Material code</span>
                                                </span>
                                            </div>
                                            <span class="normal">
                                                {{ data.resource.article_code }}
                                            </span>
                                        </div>

                                        <!-- Material type -->
                                        <div
                                            *ngIf="
                                                ResourceTypes.parentType(data.resource.type_id) ===
                                                ResourceTypes.MATERIAL
                                            "
                                            class="res-details desc-elem"
                                        >
                                            <div class="pre">
                                                <mat-icon>save</mat-icon>
                                                <span i18n="@@materialType">Material type </span>
                                            </div>

                                            <span class="normal">
                                                {{ data.resource.type_id | materialTypeLabel }}
                                            </span>
                                        </div>
                                        <!-- Price -->
                                        <div
                                            *ngIf="data.resource.price !== 0 && data.resource.price"
                                            class="res-details desc-elem"
                                        >
                                            <div class="pre">
                                                <mat-icon>local_offer</mat-icon>
                                                <span i18n="@@price">Price </span>
                                            </div>

                                            <span class="normal">
                                                {{ data.resource.price }}&nbsp;{{ data.resource.currency }}
                                            </span>
                                        </div>
                                        <!-- Total seats -->
                                        <div *ngIf="data.resource.total_seats" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>event_seat</mat-icon>
                                                <span>
                                                    {{
                                                        ResourceTypes.label(data.resource.type_id, { capitalize: true })
                                                    }}
                                                    <span i18n="@@seats">seats</span>
                                                </span>
                                            </div>
                                            <span class="normal">
                                                {{ data.resource.total_seats }}
                                            </span>
                                        </div>
                                        <!-- Extra seats -->
                                        <div *ngIf="data.resource.extra_seats" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>weekend</mat-icon>
                                                <span>
                                                    {{
                                                        ResourceTypes.label(data.resource.type_id, { capitalize: true })
                                                    }}
                                                    <span i18n="@@extraSeats">extra seats</span>
                                                </span>
                                            </div>
                                            <span class="normal">
                                                {{ data.resource.extra_seats }}
                                            </span>
                                        </div>

                                        <!-- Cats -->
                                        <div
                                            *ngIf="data.ategories"
                                            fxLayoutAlign="center start"
                                            class="res-details desc-elem"
                                        >
                                            <div class="pre">
                                                <mat-icon>apps</mat-icon>
                                            </div>
                                            <mat-chip-list class="mt-1">
                                                <mat-chip class="cat-chip-item" *ngFor="let cat of data.ategories">{{
                                                    cat.title
                                                }}</mat-chip>
                                            </mat-chip-list>
                                        </div>

                                        <!-- Tags -->
                                        <div
                                            *ngIf="data.tags"
                                            fxLayoutAlign="center start"
                                            class="res-details desc-elem"
                                        >
                                            <div class="pre">
                                                <mat-icon>label</mat-icon>
                                            </div>
                                            <mat-chip-list class="mt-1">
                                                <mat-chip *ngFor="let tag of data.tags" class="tag-chip-item">{{
                                                    tag.name
                                                }}</mat-chip>
                                            </mat-chip-list>
                                        </div>
                                        <!-- End reward -->
                                        <div *ngIf="data.resource.examination?.label" class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>military_tech</mat-icon>
                                                <span i18n="@@endReward">End reward </span>
                                            </div>
                                            <span class="middle-details">
                                                {{ data.resource.examination?.label }}
                                            </span>
                                        </div>
                                        <!-- Creator -->
                                        <div class="res-details desc-elem">
                                            <div class="pre">
                                                <mat-icon>account_circle</mat-icon>
                                                <span i18n="@@author">Author </span>
                                            </div>
                                            <span class="middle-details">
                                                {{ user.name }}
                                            </span>
                                        </div>

                                        <!-- Screenshot img -->
                                        <div
                                            class="res-details desc-elem"
                                            *ngIf="
                                                data.resource.medias?.screenshot?.length > 1 &&
                                                data.resource.medias?.screenshot[0]
                                            "
                                        >
                                            <div class="pre">
                                                <mat-icon>center_focus_strong</mat-icon>
                                                <span i18n="@@screenshotImage">Screenshot image </span>
                                            </div>
                                            <div class="middle-details-img">
                                                <div class="screenshoot-img-wrapper first-screenshot">
                                                    <app-image-view
                                                        [media_id]="data.resource.medias?.screenshot[1]"
                                                        [paddingBottom]="'200px'"
                                                    >
                                                    </app-image-view>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                        <!-- First line after BG on mobie -->
                        <div
                            *ngIf="isMobile | async"
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                            class="mobile-right-middle m-0"
                        >
                            <!-- Instruuctor refactor needed -->
                            <!-- <div
                                *ngIf="!data.resource.instructor?.name"
                                fxLayoutGap="4px"
                                fxLayout="column"
                                fxLayoutAlign="start start"
                                class="creator-inst-wrapper-mobile ml-10"
                                [ngClass]="{ authorNameExistLetters: showAuthorName }"
                            >
                                <div fxLayoutAlign="center start" fxLayout="column">
                                    <div
                                        *ngIf="!hideImg"
                                        fxLayoutAlign="center center"
                                        fxLayout="column"
                                        class="user-img-wrapper"
                                    >
                                        <button class="toggle-author-name" mat-icon-button>
                                            <mat-icon [ngClass]="{ 'rotate-expand-icon': showAuthorName }"
                                                >expand_more</mat-icon
                                            >
                                        </button>
                                        <div class="user-img">
                                            {{ user.name | nameSign }}
                                        </div>
                                    </div>
                                    <div
                                        [ngClass]="{ 'fix-layout': hideImg }"
                                        fxLayoutAlign="center start"
                                        fxLayout="column"
                                        class="inst-name-wrapper"
                                    >
                                        <div
                                            *ngIf="showAuthorName"
                                            class="instructor"
                                            fxLayoutGap="5px"
                                            fxLayoutAlign="center center"
                                        >
                                            <mat-icon>account_circle</mat-icon>
                                            <span i18n="@@author">Author</span>
                                        </div>
                                        <div *ngIf="showAuthorName" class="instructor-name">
                                            {{ user.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                *ngIf="data.resource.instructor?.name"
                                fxLayoutGap="4px"
                                fxLayout="column"
                                fxLayoutAlign="start start"
                                class="creator-inst-wrapper-mobile ml-10"
                                [ngClass]="{ authorNameExist: showAuthorName }"
                            >
                                <div fxLayoutAlign="center start" fxLayout="column">
                                    <div *ngIf="!hideImg" fxLayoutAlign="center center" fxLayout="column">
                                        <div fxLayout="row" class="user-img">
                                            <ng-container *ngIf="data.resource.instructor?.images?.length > 0">
                                                <div
                                                    *ngFor="let imgId of data.resource.instructor?.images"
                                                    class="img-wrapper instructor-img"
                                                >
                                                    <app-image-view
                                                        [scope]="AppScope.USERS"
                                                        [media_id]="imgId"
                                                        [paddingBottom]="'52px'"
                                                    >
                                                    </app-image-view>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="!data.resource.instructor?.images?.length">
                                                <div class="user-img mob-img-auth">
                                                    {{ data.resource.instructor?.name | nameSign }}
                                                </div>
                                            </ng-container>

                                            <button
                                                class="toggle-author-name"
                                                (click)="showAuthorName = !showAuthorName"
                                                mat-icon-button
                                            >
                                                <mat-icon [ngClass]="{ 'rotate-expand-icon': showAuthorName }"
                                                    >expand_more</mat-icon
                                                >
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        [ngClass]="{ 'fix-layout': hideImg }"
                                        fxLayoutAlign="center start"
                                        fxLayout="column"
                                        class="inst-name-wrapper"
                                    >
                                        <div
                                            *ngIf="showAuthorName"
                                            class="instructor"
                                            fxLayoutGap="5px"
                                            fxLayoutAlign="center center"
                                        >
                                            <mat-icon>engineering</mat-icon>
                                            <span i18n="@@instructor">Instructor</span>
                                        </div>
                                        <div *ngIf="showAuthorName" class="instructor-name">
                                            <span *ngIf="data.resource.instructor?.name">{{
                                                data.resource.instructor?.name
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div *ngIf="isMobile | async" fxLayoutAlign="center center" class="social-icons m-0">
                                <button
                                    class="grey"
                                    matTooltip="Add to favorite"
                                    i18n-matTooltip="@@addToFavorite"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>{{ data.resource.favorite ? 'star' : 'star_border' }}</mat-icon>
                                </button>
                                <button
                                    class="grey"
                                    i18n-matTooltip="@@like"
                                    matTooltip="Like"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>{{ data.resource.like ? 'favorite' : 'favorite_border' }}</mat-icon>
                                </button>
                                <button
                                    class="grey"
                                    matTooltip="Download"
                                    i18n-matTooltip="@@download"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>save_alt</mat-icon>
                                </button>

                                <!-- Share btns here -->
                                <ng-container
                                    *ngIf="data.resource.last_publish"
                                    [ngTemplateOutlet]="shareTemp"
                                    [ngTemplateOutletContext]="{ resource: data.resource }"
                                ></ng-container>
                                <ng-container
                                    *ngIf="data.resource.published"
                                    [ngTemplateOutletContext]="{ resource: data.resource }"
                                    [ngTemplateOutlet]="inviteBtn"
                                ></ng-container>
                                <button
                                    *ngIf="
                                        user.role_value !== 'user' &&
                                        user.role_value !== 'guest' &&
                                        user.canAssign(data.resource)
                                    "
                                    matTooltip="Assign"
                                    i18n-matTooltip="@@assign"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                    class="green"
                                >
                                    <mat-icon>assignment_ind</mat-icon>
                                </button>
                                <ng-container
                                    *ngIf="
                                        (ResourceTypes.parentType(data.resource.type_id) === ResourceTypes.E_COURSE &&
                                            user.role_value === 'superadmin') ||
                                        ResourceTypes.parentType(data.resource.type_id) !== ResourceTypes.E_COURSE
                                    "
                                >
                                    <div fxFlex="0 0 auto" *ngIf="user.canEdit(data.resource)">
                                        <button
                                            i18n-matTooltip="@@edit"
                                            matTooltip="Edit"
                                            class="green"
                                            mat-icon-button
                                        >
                                            <mat-icon>create</mat-icon>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <mat-divider *ngIf="isMobile | async"></mat-divider>
                        <!-- Course items on mobile -->
                        <ng-container
                            *ngIf="ResourceTypes.parentType(data.resource.type_id) !== ResourceTypes.MATERIAL"
                        >
                            <div *ngIf="isMobile | async" fxLayout="row" fxLayoutAlign="space-between center">
                                <div class="mobile-course-include" *ngIf="resourceItems | async; let items">
                                    <div
                                        class="item-include-mob"
                                        *ngIf="
                                            data.resource.type_id === ResourceTypes.GeneralSurvey ||
                                            data.resource.type_id === ResourceTypes.GeneralTest
                                        "
                                    >
                                        <mat-icon>category</mat-icon>
                                        <ng-container>
                                            {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                            <span style="margin-left: 3px" i18n="@@include">include</span>
                                        </ng-container>
                                        &nbsp;{{ items.length }}&nbsp;
                                        <span i18n="@@questions">Questions</span>
                                    </div>
                                    <mat-accordion
                                        *ngIf="
                                            data.resource.type_id === ResourceTypes.GeneralCoursePlan ||
                                            data.resource.type_id === ResourceTypes.General_E_Course ||
                                            data.resource.type_id === ResourceTypes.SCORM
                                        "
                                        displayMode="flat"
                                    >
                                        <mat-expansion-panel>
                                            <mat-expansion-panel-header>
                                                <mat-icon>category</mat-icon>
                                                <ng-container>
                                                    {{
                                                        ResourceTypes.label(data.resource.type_id, { capitalize: true })
                                                    }}
                                                    <span style="margin-right: 3px" i18n="@@include">include</span>
                                                </ng-container>
                                                &nbsp;({{ items.length }})
                                            </mat-expansion-panel-header>
                                            <div class="res-bull mt-1" *ngFor="let item of items; let index = index">
                                                <ng-container
                                                    *ngIf="
                                                        data.resource.type_id === ResourceTypes.General_E_Course ||
                                                        data.resource.type_id === ResourceTypes.SCORM
                                                    "
                                                >
                                                    <span
                                                        class="index"
                                                        [class.taken-task]="!!item.child_opportunity_done"
                                                        >{{ index + 1 }}</span
                                                    >
                                                    <span
                                                        class="res-item-name ecourse"
                                                        [class.parent]="!item.parent_id"
                                                        >{{ item.name }}</span
                                                    >
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        data.resource.type_id !== ResourceTypes.General_E_Course &&
                                                        data.resource.type_id !== ResourceTypes.SCORM
                                                    "
                                                >
                                                    <span class="index">{{ index + 1 }}</span>
                                                    <mat-icon
                                                        fontSet="klickdata"
                                                        [fontIcon]="displayCorrectIcon(item.item_type_value)"
                                                        class="kd-icon"
                                                    ></mat-icon>
                                                    <span class="res-item-name">{{ item.name }}</span>
                                                </ng-container>
                                            </div>
                                        </mat-expansion-panel>
                                    </mat-accordion>
                                </div>
                            </div>
                            <mat-divider *ngIf="isMobile | async"></mat-divider>
                        </ng-container>
                        <!-- Course main info on mobile -->
                        <div *ngIf="isMobile | async" fxLayout="row" fxLayoutAlign="space-between center">
                            <div class="mobile-course-mainInfo" *ngIf="resourceItems | async; let items">
                                <div class="mobile-course-info-details">
                                    <div *ngIf="data.resource.description">
                                        <div class="pre">
                                            <mat-icon>description</mat-icon>
                                            <span>
                                                {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                                <span i18n="@@description">description</span>
                                            </span>
                                        </div>
                                        <span
                                            class="middle-details desc"
                                            [innerHtml]="styleInnerHTML(data.resource.description)"
                                        >
                                        </span>
                                    </div>
                                    <!-- Goals -->
                                    <div *ngIf="data.resource.goal">
                                        <div class="pre">
                                            <mat-icon>task_alt</mat-icon>
                                            <span>
                                                {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                                <span i18n="@@goal">goal</span>
                                            </span>
                                        </div>
                                        <span class="middle-details">
                                            {{ data.resource.goal }}
                                        </span>
                                    </div>
                                    <!-- Other information -->
                                    <div *ngIf="data.resource.other_info">
                                        <div class="pre">
                                            <mat-icon>info</mat-icon>
                                            <span i18n="@@otherInformation">Other information </span>
                                        </div>
                                        <span class="middle-details">
                                            {{ data.resource.other_info }}
                                        </span>
                                    </div>

                                    <!-- Instr -->
                                    <div
                                        *ngIf="
                                            data.resource.instructions &&
                                            ResourceTypes.parentType(data.resource.type_id) !== ResourceTypes.MATERIAL
                                        "
                                    >
                                        <div class="pre">
                                            <mat-icon>subject</mat-icon>
                                            <span>
                                                {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                                <span i18n="@@instruction">instruction</span>
                                            </span>
                                        </div>
                                        <span
                                            class="middle-details"
                                            [innerHtml]="styleInnerHTML(data.resource.instructions)"
                                        >
                                            <!-- {{ data.resource.instructions }} -->
                                        </span>
                                    </div>
                                    <div
                                        *ngIf="
                                            data.resource.instructions &&
                                            ResourceTypes.parentType(data.resource.type_id) === ResourceTypes.MATERIAL
                                        "
                                    >
                                        <div class="pre">
                                            <mat-icon>subject</mat-icon>
                                            <span>
                                                <span i18n>Content information</span>
                                            </span>
                                        </div>
                                        <span
                                            class="middle-details"
                                            [innerHtml]="styleInnerHTML(data.resource.instructions)"
                                        >
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!(isMobile | async)" class="other-info" fxLayoutGap="2em" fxLayout="column">
                            <!-- Summary -->
                            <div *ngIf="data.resource.description">
                                <div class="pre">
                                    <mat-icon>description</mat-icon>
                                    <span>
                                        {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                        <span i18n="@@description">description</span>
                                    </span>
                                </div>
                                <span class="middle-details" [innerHtml]="styleInnerHTML(data.resource.description)">
                                </span>
                            </div>

                            <!-- Goals -->
                            <div *ngIf="data.resource.goal">
                                <div class="pre">
                                    <mat-icon>task_alt</mat-icon>
                                    <span>
                                        {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                        <span i18n="@@goal">goal</span>
                                    </span>
                                </div>
                                <span class="middle-details">
                                    {{ data.resource.goal }}
                                </span>
                            </div>
                            <!-- Other info -->
                            <div *ngIf="data.resource.other_info">
                                <div class="pre">
                                    <mat-icon>info</mat-icon>
                                    <span i18n="@@otherInformation">Other information </span>
                                </div>
                                <span class="middle-details">
                                    {{ data.resource.other_info }}
                                </span>
                            </div>
                            <!-- Instr -->
                            <div
                                *ngIf="
                                    data.resource.instructions &&
                                    ResourceTypes.parentType(data.resource.type_id) !== ResourceTypes.MATERIAL
                                "
                            >
                                <div class="pre">
                                    <mat-icon>subject</mat-icon>
                                    <span>
                                        {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                        <span i18n="@@instruction">instruction</span>
                                    </span>
                                </div>
                                <span class="middle-details" [innerHtml]="styleInnerHTML(data.resource.instructions)">
                                </span>
                            </div>
                            <!-- Material Instr -->
                            <div
                                *ngIf="
                                    data.resource.instructions &&
                                    ResourceTypes.parentType(data.resource.type_id) === ResourceTypes.MATERIAL
                                "
                            >
                                <div class="pre">
                                    <mat-icon>subject</mat-icon>
                                    <span>
                                        <span i18n>Content information</span>
                                    </span>
                                </div>
                                <span class="middle-details" [innerHtml]="styleInnerHTML(data.resource.instructions)">
                                </span>
                            </div>

                            <!-- Screenshot img -->
                            <div
                                *ngIf="
                                    data.resource.medias?.screenshot?.length > 1 && data.resource.medias?.screenshot[0]
                                "
                            >
                                <div class="pre">
                                    <mat-icon>center_focus_strong</mat-icon>
                                    <span i18n="@@screenshotImage">Screenshot image </span>
                                </div>
                                <div class="middle-details-img">
                                    <div class="screenshoot-img-wrapper">
                                        <app-image-view
                                            [media_id]="data.resource.medias?.screenshot[0]"
                                            [proportional]="false"
                                        >
                                        </app-image-view>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!(isMobile | async)" class="extra-info">
                            <!-- Instruuctor refactor needed -->
                            <!-- <ng-container
                                [ngTemplateOutlet]="instructor"
                                [ngTemplateOutletContext]="{ resource: data.resource, user: user }"
                            ></ng-container> -->
                            <div fxLayoutAlign="center center" class="social-icons">
                                <button
                                    class="grey"
                                    matTooltip="Add to favorite"
                                    i18n-matTooltip="@@addToFavorite"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>{{ data.resource.favorite ? 'star' : 'star_border' }}</mat-icon>
                                </button>
                                <button
                                    class="grey"
                                    matTooltip="Like"
                                    i18n-matTooltip="@@like"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>{{ data.resource.like ? 'favorite' : 'favorite_border' }}</mat-icon>
                                </button>
                                <!-- Share btns here -->
                                <ng-container
                                    *ngIf="data.resource.last_publish"
                                    [ngTemplateOutlet]="shareTemp"
                                    [ngTemplateOutletContext]="{ resource: data.resource }"
                                ></ng-container>
                                <button
                                    class="grey"
                                    matTooltip="Download"
                                    i18n-matTooltip="@@download"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                >
                                    <mat-icon>save_alt</mat-icon>
                                </button>
                                <ng-container
                                    *ngIf="data.resource.published"
                                    [ngTemplateOutletContext]="{ resource: data.resource }"
                                    [ngTemplateOutlet]="inviteBtn"
                                ></ng-container>
                                <button
                                    *ngIf="
                                        user.role_value !== 'user' &&
                                        user.role_value !== 'guest' &&
                                        user.canAssign(data.resource)
                                    "
                                    matTooltip="Assign"
                                    i18n-matTooltip="@@assign"
                                    mat-icon-button
                                    fxFlex="0 0 auto"
                                    class="green"
                                >
                                    <mat-icon>assignment_ind</mat-icon>
                                </button>
                                <ng-container
                                    *ngIf="
                                        (ResourceTypes.parentType(data.resource.type_id) === ResourceTypes.E_COURSE &&
                                            user.role_value === 'superadmin') ||
                                        ResourceTypes.parentType(data.resource.type_id) !== ResourceTypes.E_COURSE
                                    "
                                >
                                    <div fxFlex="0 0 auto" *ngIf="user.canEdit(data.resource)">
                                        <button matTooltip="Edit" i18n-matTooltip="edit" class="green" mat-icon-button>
                                            <mat-icon>create</mat-icon>
                                        </button>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="others">
                                <!-- Duration -->
                                <div *ngIf="data.resource.duration" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>timelapse</mat-icon>
                                        <span
                                            *ngIf="
                                                ResourceTypes.parentType(data.resource.type_id) === ResourceTypes.COURSE
                                            "
                                        >
                                            <span i18n="@@courseDuration">Course duration</span>
                                        </span>
                                        <span
                                            *ngIf="
                                                ResourceTypes.parentType(data.resource.type_id) !== ResourceTypes.COURSE
                                            "
                                        >
                                            {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                            <span i18n="@@duration">duration</span>
                                        </span>
                                    </div>
                                    <span class="normal"
                                        >{{
                                            data.resource?.duration
                                                | formatIsoDuration
                                                    : data.resource.type_id === ResourceTypes.General_E_Course ||
                                                          data.resource.type_id === ResourceTypes.SCORM
                                        }}
                                    </span>
                                </div>
                                <!-- Material type -->
                                <div
                                    *ngIf="ResourceTypes.parentType(data.resource.type_id) === ResourceTypes.MATERIAL"
                                    class="res-details desc-elem"
                                >
                                    <div class="pre">
                                        <mat-icon>save</mat-icon>
                                        <span i18n="@@materialType">Material type </span>
                                    </div>
                                    <span class="normal">{{ data.resource.type_id | materialTypeLabel }} </span>
                                </div>
                                <!-- Play time -->
                                <div *ngIf="data.resource.time_limit" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>timer</mat-icon>
                                        <span i18n="@@playTime">Play time </span>
                                    </div>
                                    <span class="normal">{{ data.resource?.time_limit | formatIsoDuration }} </span>
                                </div>

                                <!-- End reward -->
                                <div *ngIf="data.resource.examination" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>military_tech</mat-icon>
                                        <span i18n="@@endReward">End reward </span>
                                    </div>
                                    <span class="normal">{{ data.resource.examination?.label }} </span>
                                </div>

                                <!-- Author -->
                                <div class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>account_circle</mat-icon>
                                        <span i18n="@@author">Author </span>
                                    </div>
                                    <span class="normal">{{ user.name }} </span>
                                </div>

                                <!-- Tags -->
                                <div *ngIf="data.tags" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>label</mat-icon>
                                        <span i18n="@@tags">Tags </span>
                                    </div>
                                    <span class="normal">
                                        <mat-chip-list class="mt-1">
                                            <mat-chip *ngFor="let tag of data.tags" class="tag-chip-item">{{
                                                tag.name
                                            }}</mat-chip>
                                        </mat-chip-list>
                                    </span>
                                </div>

                                <!-- Cats -->
                                <div *ngIf="data.categories" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>apps</mat-icon>
                                        <span i18n="@@categories">Categories </span>
                                    </div>
                                    <span class="normal">
                                        <mat-chip-list class="mt-1">
                                            <mat-chip class="cat-chip-item" *ngFor="let cat of data.categories">{{
                                                cat.title
                                            }}</mat-chip>
                                        </mat-chip-list>
                                    </span>
                                </div>
                                <!-- Instruuctor refactor needed -->
                                <!-- Creator -->
                                <!-- <div *ngIf="data.resource.instructor?.name" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>engineering</mat-icon>
                                        <span i18n="@@instructor">Instructor </span>
                                    </div>
                                    <span class="normal">
                                        {{ data.resource.instructor?.name }}
                                    </span>
                                </div> -->
                                <!-- Course code -->
                                <div *ngIf="data.resource.article_code" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>center_focus_strong</mat-icon>
                                        <!-- <span>
                                        {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                        <span i18n="@@code">code</span>
                                    </span> -->
                                        <span *ngIf="data.resource.type_id === ResourceTypes.GeneralCoursePlan">
                                            <span i18n="@@courseCode">Course code</span>
                                        </span>
                                        <span *ngIf="data.resource.type_id === ResourceTypes.GeneralTest">
                                            <span i18n="@@testCode">Test code</span>
                                        </span>
                                        <span *ngIf="data.resource.type_id === ResourceTypes.GeneralSurvey">
                                            <span i18n="@@surveyCode">Survey code</span>
                                        </span>
                                        <span *ngIf="data.resource.type_id === ResourceTypes.General_E_Course">
                                            <span i18n="@@ecourseCode">E-course code</span>
                                        </span>
                                        <span *ngIf="data.resource.type_id === ResourceTypes.SCORM">
                                            <span i18n="@@scormCode">SCORM code</span>
                                        </span>
                                        <span
                                            *ngIf="
                                                ResourceTypes.parentType(data.resource.type_id) ===
                                                ResourceTypes.MATERIAL
                                            "
                                        >
                                            <span i18n="@@materialCode">Material code</span>
                                        </span>
                                    </div>
                                    <span class="normal">
                                        {{ data.resource.article_code }}
                                    </span>
                                </div>

                                <!-- Price -->
                                <div
                                    *ngIf="data.resource.price !== 0 && data.resource.price"
                                    class="res-details desc-elem"
                                >
                                    <div class="pre">
                                        <mat-icon>local_offer</mat-icon>
                                        <span i18n="@@price">Price </span>
                                    </div>
                                    <span class="normal">
                                        {{ data.resource.price }}&nbsp;{{ data.resource.currency }}
                                    </span>
                                </div>
                                <!-- Total seats -->
                                <div *ngIf="data.resource.total_seats" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>event_seat</mat-icon>
                                        <span>
                                            {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                            <span i18n="@@seats">seats</span>
                                        </span>
                                    </div>
                                    <span class="normal">
                                        {{ data.resource.total_seats }}
                                    </span>
                                </div>
                                <!-- Extra seats -->
                                <div *ngIf="data.resource.extra_seats" class="res-details desc-elem">
                                    <div class="pre">
                                        <mat-icon>weekend</mat-icon>
                                        <span>
                                            {{ ResourceTypes.label(data.resource.type_id, { capitalize: true }) }}
                                            <span i18n="@@extraDuration">extra seats</span>
                                        </span>
                                    </div>
                                    <span class="normal">
                                        {{ data.resource.extra_seats }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>

<ng-template #small_loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>

<ng-template #shareTemp let-resource="resource">
    <button class="grey" fxFlex="0 0 auto" matTooltip="Share" i18n-matTooltip="@@share" mat-icon-button>
        <mat-icon>share</mat-icon>
    </button>
</ng-template>
<ng-template #inviteBtn let-resource="resource">
    <button class="green" matTooltip="Invite a friend" i18n-matTooltip mat-icon-button fxFlex="0 0 auto">
        <mat-icon>outgoing_mail</mat-icon>
    </button>
</ng-template>

<ng-template #shareLoadTemp>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
<ng-template #instructor let-resource="resource" let-user="user">
    <!-- Instruuctor refactor needed -->
    <!-- <div class="author-wrapper" *ngIf="!resource.instructor?.name" fxLayoutAlign="center center" fxLayout="column">
        <div class="user-img">
            {{ user.name | nameSign }}
        </div>
        <div class="instructor" fxLayoutGap="5px" fxLayoutAlign="center center">
            <mat-icon>account_circle</mat-icon>
            <span i18n="@@author">Author</span>
        </div>
        <div>
            {{ user.name }}
        </div>
    </div> -->

    <!-- <div *ngIf="resource.instructor && resource.instructor?.name" fxLayoutAlign="center center" fxLayout="column">
        <div class="ins-img">
            <div class="middle-details-img">
                <ng-container *ngIf="resource.instructor?.images?.length > 0">
                    <div *ngFor="let imgId of resource.instructor?.images" class="img-wrapper instructor-img">
                        <app-image-view [scope]="AppScope.USERS" [media_id]="imgId" [paddingBottom]="'122px'">
                        </app-image-view>
                    </div>
                </ng-container>
                <ng-container *ngIf="!resource.instructor?.images?.length">
                    <div class="user-img">
                        {{ resource.instructor?.name | nameSign }}
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="instructor" fxLayoutGap="5px" fxLayoutAlign="center center">
            <mat-icon>engineering</mat-icon>
            <span i18n="@@instructor">Instructor</span>
        </div>
        <div>
            <span *ngIf="resource.instructor?.name">{{ resource.instructor?.name }}</span>
        </div>
    </div> -->
    <ng-template #author_img>
        <div class="author-wrapper" fxLayoutAlign="center center" fxLayout="column">
            <div class="user-img">
                {{ resource.author_name || $any(resource.author$ | async)?.name | nameSign }}
            </div>
            <div class="instructor" fxLayoutGap="5px" fxLayoutAlign="center center">
                <mat-icon>account_circle</mat-icon>
                <span i18n="@@author">Author</span>
            </div>
            <div>
                {{ resource.author_name || $any(resource.author$ | async)?.name }}
            </div>
        </div>
    </ng-template>
</ng-template>
<ng-template #resAvailability let-resource="resource">
    <div *ngIf="resource.always_available" class="timer-off small-info times-parent">
        <mat-icon>timer_off</mat-icon>
        <span i18n="@@alwaysAvailable">Always available</span>
    </div>
    <div
        *ngIf="resource.start_date?.isAfter(moment) && resource.end_date?.isAfter(moment) && !resource.always_available"
        class="small-info timer-off times-parent"
    >
        <mat-icon>timer</mat-icon>
        <div class="dates-wrapper times">
            <span i18n="@@availableBetween">Available between</span>
            <div>
                <span>{{ resource.start_date | kdDateFormat : 'MMM d, y' }}</span> /
                <span>{{ resource.end_date | kdDateFormat : 'MMM d, y' }}</span>
            </div>
        </div>
    </div>
    <div
        *ngIf="
            resource.start_date?.isBefore(moment) && resource.end_date?.isAfter(moment) && !resource.always_available
        "
        class="small-info timer-off times-parent"
    >
        <mat-icon>timelapse</mat-icon>
        <div class="dates-wrapper times">
            <span i18n="@@availableBetween">Available between</span>
            <div>
                <span>{{ resource.start_date | kdDateFormat : 'MMM d, y' }}</span> /
                <span>{{ resource.end_date | kdDateFormat : 'MMM d, y' }}</span>
            </div>
        </div>
    </div>
    <div
        *ngIf="
            resource.start_date?.isBefore(moment) && resource.end_date?.isBefore(moment) && !resource.always_available
        "
        class="small-info timer-off times-parent"
    >
        <mat-icon>hourglass_bottom</mat-icon>
        <div class="dates-wrapper times">
            <span>
                {{ ResourceTypes.label(resource.type_id, { capitalize: true }) }}
                <span i18n="@@isClosed">is closed</span>
            </span>
        </div>
    </div>
</ng-template>
