import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../global-filters-types.enum';
import { Filter } from '../table-filter/filter';
import { FilterBase } from '../table-filter/filter-base';

@Component({
    selector: 'app-table-search',
    templateUrl: './table-search.component.html',
    styleUrls: ['./table-search.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: FilterBase, useExisting: TableSearchComponent }],
})
export class TableSearchComponent extends FilterBase implements OnDestroy, OnChanges {
    @Input() inGlobalFilter: boolean;
    @Input() toggleBtnLabel = $localize`Title`;
    @Input() placeHolder = $localize`Filter by name...`;
    @Input() ignoreCaching: boolean;
    @Input() minQueryCharLength = 3;
    @Output() filterChange: EventEmitter<Filter<string>> = new EventEmitter<Filter<string>>();
    @Output() filterRemoval: EventEmitter<Filter<string>> = new EventEmitter<Filter<string>>();
    /** @Deprecated with filterChange to follow table filter structure */
    // @Output() search: EventEmitter<string> = new EventEmitter();
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.SEARCH,
        items: [],
        label: this.toggleBtnLabel,
        icon: 'search',
        color: { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE },
        styleClass: 'global-search-filter-selector',
    };

    public control: FormControl = new FormControl();
    public searchSubscription: Subscription;
    public never = 'never';

    constructor(protected cdRef: ChangeDetectorRef) {
        super();
        this.searchSubscription = this.control.valueChanges
            .pipe(
                filter((query) => !query?.length || query.length >= this.minQueryCharLength),
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe((search) => {
                this._filter.items = search ? [search] : [];
                // this.search.emit(search);
                if (search) {
                    this.filterChange.emit(this.filter);
                } else {
                    this.filterRemoval.emit(this.filter);
                }
                this.cdRef.detectChanges();
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.toggleBtnLabel) {
            this._filter.label = changes.toggleBtnLabel.currentValue;
        }
    }
    protected ignoreCache(): boolean {
        return this.ignoreCaching;
    }
    public ngOnDestroy(): void {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
            this.searchSubscription = null;
        }
    }

    public clear() {
        this.control.setValue('');
    }
    public setQuery(query: string): void {
        this.control.setValue(query);
    }
    public get filter(): Filter<string> {
        return this._filter;
    }
}
