import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-collection-item-builder',
  templateUrl: './collection-item-builder.component.html',
  styleUrls: ['./collection-item-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionItemBuilderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
