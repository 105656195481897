import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { Language } from './language';
import { User } from '@klickdata/core/user';

@Injectable({ providedIn: 'root' })
export class LanguageService {
    protected currentLanguage: Language;
    protected languages: Language[];
    protected scormPlayerLanguages: Language[];
    private _supportedLanguages: Observable<Language[]>;
    public get supportLanguages(): Observable<Language[]> {
        return this._supportedLanguages;
    }

    private _attachedLanguages: Observable<Language[]>;
    public get attachedLanguages(): Observable<Language[]> {
        return this._attachedLanguages;
    }
    protected languageUrl: string;

    constructor(
        protected config: ConfigService,
        protected authService: AuthService,
        @Inject(LOCALE_ID) protected localeId: string,
        protected builder: RequestBuilderService
    ) {
        this.languages = config.config.languages;
        this.scormPlayerLanguages = config.config.scormPlayerLanguages;
        this.currentLanguage = this.getLanguage(localeId);
        this.languageUrl = `${this.config.config.apiUrl}languages`;
        this._supportedLanguages = this.getSupportedLanguages();
        this._attachedLanguages = this.getSupportedLanguages(true);
    }

    private getSupportedLanguages(attached = false): Observable<Language[]> {
        const builder = this.builder.get<Language[]>(this.languageUrl);
        if (attached) {
            builder.param('attached', '1');
        }
        return builder.request().pipe(
            shareReplay(),
            map((res) => res.data?.sort((a, b) => a.label.localeCompare(b.label)))
        );
    }

    public getAttachedLanguages(param: { [key: string]: string }): Observable<Language[]> {
        const builder = this.builder.get<Language[]>(this.languageUrl);
        Object.keys(param).forEach((key) => builder.param(key, param[key]));
        return builder.request().pipe(
            shareReplay(),
            map((res) => res.data?.sort((a, b) => a.label.localeCompare(b.label)))
        );
    }

    public getCustomerPrefLanguages(): Observable<Language[]> {
        const builder = this.builder.get<Language[]>(this.languageUrl);
        return this.authService
            .getCustomer()
            .pipe(switchMap((customer) => this.getLanguagesByKeys(customer.pref_lang_ids)));
    }

    getCurrentLanguage(): Language {
        return this.currentLanguage;
    }

    /**
     * Get language by key.
     * 1. Using langauge id.
     * 2. Using langauge value.
     * 3. Default current UI language.
     */
    getLanguageByKey(value?: number | string): Observable<Language> {
        const langValue = value || this.siteLanguage || this.localeId;
        return this.supportLanguages.pipe(
            map(
                (languages) =>
                    languages.find((lang) => lang.id === langValue) ||
                    languages.find((lang) => lang.value === langValue) ||
                    languages.find((lang) => lang.lang === langValue) ||
                    languages.find((lang) => lang.value === this.getCurrentLanguage().value)
            )
        );
    }

    getLanguagesByKeys(keys: (number | string)[]): Observable<Language[]> {
        return this.supportLanguages.pipe(
            map((languages) =>
                keys.map((key: number | string) =>
                    languages.find(
                        (lang) =>
                            (typeof key === 'string' && lang.value === key) ||
                            (typeof key === 'string' && lang.lang === key) ||
                            (typeof key === 'number' && lang.id === key)
                    )
                )
            )
        );
    }

    getLanguageNotIn(keys: [number | string]): Observable<Language> {
        return this.supportLanguages.pipe(
            map((languages) =>
                languages.find((lang) => keys.indexOf(lang.value) === -1 && keys.indexOf(lang.id) === -1)
            )
        );
    }

    /**
     * Get user content language.
     */
    getContentLanguage(): Observable<Language> {
        return this.getLanguageByKey();
    }

    getLanguages(): Language[] {
        return this.languages;
    }

    getScormPlayerLanguages(): Language[] {
        return this.scormPlayerLanguages;
    }

    private getLanguage(key: string): Language {
        return (
            this.languages.find((language) => language.value.split('-')[0] === key) ??
            this.languages.find((lng) => lng.value === 'en')
        );
    }

    public updateUserLanguage(language: Language, user?: User) {
        return this.authService.updateUserLanguage(language, user);
    }

    get siteLanguage(): string {
        return this.authService.siteLanguage;
    }
}
