<mat-card class="mt-2" fxFlex="0 0 100%">
    <mat-card-content>
        <div fxFlex="1 1 100%" fxLayoutAlign="center" fxLayout="column wrap" fxLayoutAlign="center" class="mt-1 mb-1">
            <form
                #formDirective="ngForm"
                fxLayout="row wrap"
                (ngSubmit)="submitAIMaterial(formDirective)"
                [formGroup]="materialAIForm"
            >
                <div fxFlex="0 0 100%">
                    <mat-form-field fxFlex="0 1 100%">
                        <input
                            matInput
                            placeholder="Add material headline"
                            i18n-placeholder
                            formControlName="title"
                            required
                        />
                        <mat-error i18n>Title field is required!</mat-error>
                    </mat-form-field>
                    <div fxLayoutAlign="center center" fxFlex="0 0 40px" class="coursePlan-layout">
                        <app-language-selector
                            formControlName="language_id"
                            class="icon-resource-builder"
                            fxFlex="0 0 auto"
                        ></app-language-selector>
                    </div>
                    <div fxLayoutAlign="center center" fxFlex="0 0 40px" class="coursePlan-layout">
                        <app-media
                            #app_media
                            fxFlex="0 0 auto"
                            [simple]="true"
                            *ngIf="!materialAIForm.get('media_id')?.value"
                            formControlName="media_id"
                            [scope]="AppScope.MATERIAL"
                            dropLabel="Drop course plan image"
                            i18n-dropLabel="@@coursePlanImageDropzoneLabel"
                        ></app-media>
                    </div>
                </div>
                <app-image-view
                    class="mt-2 mb-2"
                    *ngIf="materialAIForm.get('media_id')?.value"
                    fxFlex="0 1 300px"
                    [media_id]="materialAIForm.get('media_id')?.value"
                    [proportional]="false"
                >
                    <button
                        class="remove-image"
                        mat-icon-button
                        color="warn"
                        *ngIf="materialAIForm.get('media_id').value as mediaId"
                        matTooltip="Remove image"
                        i18n-matTooltip
                        (click)="materialAIForm.get('media_id').setValue(null)"
                    >
                        <mat-icon class="md-24">delete_outline</mat-icon>
                    </button>
                </app-image-view>

                <app-tag-chip-select
                    fxFlex="0 1 100%"
                    [resource_id]="resource?.id"
                    [language_id]="materialAIForm?.get('language_id').value"
                    formControlName="tag_ids"
                ></app-tag-chip-select>

                <app-category-chip-select
                    fxFlex="0 1 100%"
                    [resource_id]="resource?.id"
                    [language_id]="materialAIForm?.get('language_id').value"
                    formControlName="category_ids"
                    [type_id]="ResourceTypes.MATERIAL"
                ></app-category-chip-select>

                <!-- <app-materials-manager-text
                    *ngIf="resource"
                    #aiCompRef
                    [resource]="resource"
                    [resourceForm]="materialAIForm"
                    [customer]="customer$ | async"
                    [materialAddedItem]="ResourceBuildingItems.CREATE_AI_PROMPTER_MATERIAL"
                    [active]="active"
                    (saved)="onSaved($event)"
                ></app-materials-manager-text> -->
                <app-materials-manager-text
                    *ngIf="resource"
                    #aiCompRef
                    [resource]="resource"
                    [itemForm]="materialAIForm"
                    [active]="active"
                ></app-materials-manager-text>

                <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                    <mat-expansion-panel #insexpansionPanel>
                        <mat-expansion-panel-header class="material-acc-header-explain">
                            <div *ngIf="!insexpansionPanel.expanded" i18n="@@descriptionText">Description Text</div>
                            <mat-icon
                                matTooltip="The description text that will be displayed within the material"
                                i18n-matTooltip
                                >help_outline</mat-icon
                            >
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <app-text-editor
                                *ngIf="active"
                                fxFlex="1 1 95%"
                                title="Description"
                                i18n-title
                                [showTemplates]="false"
                                formControlName="description"
                            ></app-text-editor>
                        </ng-template>
                    </mat-expansion-panel>
                    <mat-expansion-panel class="mt-30" #expansionPanel>
                        <mat-expansion-panel-header class="material-acc-header-explain">
                            <div *ngIf="!expansionPanel.expanded" i18n="@@priority">Priority</div>
                            <mat-icon
                                matTooltip="Prio will determine the order that it will be displayed in sections"
                                i18n-matTooltip
                                >help_outline</mat-icon
                            >
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <mat-form-field fxFlex="1 1 95%">
                                <input
                                    matInput
                                    fxLayoutAlign="end center"
                                    type="number"
                                    placeholder="Please set priority"
                                    i18n-placeholder="@@plsSetPriority"
                                    formControlName="priority"
                                />
                            </mat-form-field>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>

                <div *ngIf="aiCompRef" fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="end" fxLayoutGap=".5em">
                    {{ aiCompRef.isItemsProcessing | json }}
                    <button
                        *ngIf="!(saving | async) && !aiCompRef?.isItemsProcessing && !publishOnly"
                        [disabled]="materialAIForm.invalid"
                        mat-raised-button
                        color="primary"
                        type="submit"
                        i18n="@@save"
                    >
                        Save
                    </button>

                    <button
                        *ngIf="
                            !(saving | async) &&
                            !aiCompRef?.isItemsProcessing &&
                            (isAdmin$ | async) &&
                            !resource?.published
                        "
                        mat-raised-button
                        [disabled]="materialAIForm.invalid"
                        (click)="submitAIMaterial(formDirective, true)"
                        color="primary"
                    >
                        {{ publishLabel }}
                    </button>
                    <app-saving-button *ngIf="(saving | async) || aiCompRef?.isItemsProcessing"></app-saving-button>
                </div>
            </form>
        </div>
    </mat-card-content>
</mat-card>
