<mat-tab-group
    (selectedTabChange)="templateSelected = $event.index !== 0"
    #tabs
    *ngIf="showTemplates && fieldName; else noTemplate"
    color="primary"
    backgroundColor="accent"
    [dynamicHeight]="true"
>
    <mat-tab [label]="title" #editorTab>
        <div @expand class="tiny-container">
            <app-text-editor-editor
                *ngIf="editorTab.isActive"
                #editor
                [fieldName]="fieldName"
                [placeholder]="placeholder"
                [formControl]="formControlName.control | cast"
                (change)="onChange($event)"
                (focus)="onFocus($event)"
                (blur)="onBlur($event)"
            ></app-text-editor-editor>
        </div>
    </mat-tab>

    <mat-tab [disabled]="formControlName.disabled" label="Templates" i18n-label="@@templates">
        <app-editor-templates
            [templateSelected]="templateSelected"
            [fieldName]="fieldName"
            [newContent]="body"
            [subject]="subject"
            [customerId]="customerId"
            (selected)="onTemplateSelect($event)"
            [fitTemplatesInSmallScreen]="fitTemplatesInSmallScreen"
        >
        </app-editor-templates>
    </mat-tab>
</mat-tab-group>

<ng-template #noTemplate>
    <mat-toolbar color="accent">
        <p>{{ title }}</p>
    </mat-toolbar>

    <app-text-editor-editor
        [fieldName]="fieldName"
        [customButton]="customButton"
        [formControl]="formControlName.control | cast"
        (change)="onChange($event)"
        (focus)="onFocus($event)"
    >
    </app-text-editor-editor>
</ng-template>

<div class="text-input-underline" [ngClass]="{ disabled: formControlName.disabled }">
    <div class="text-input-focusline" [ngClass]="{ focused: focus }"></div>
</div>

<div class="error-content">
    <ng-content select="mat-error"></ng-content>
</div>
