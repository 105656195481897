<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>mail</mat-icon><span i18n>Send Email</span>
        <div class="action">
            <button
                *ngIf="!(saving | async)"
                [disabled]="mailForm.invalid && mailForm.touched"
                fxFlex="0 0 auto"
                class="mobile-button"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n>Send Email</span>
            </button>
            <ng-container *ngIf="saving | async">
                <app-saving-button></app-saving-button>
            </ng-container>
        </div>
    </div>

    <form fxLayout="column" [formGroup]="mailForm">
        <mat-radio-group class="mail-type" fxLayoutAlign="space-between center" formControlName="type">
            <mat-radio-button value="client">
                <span>Your email client</span>
            </mat-radio-button>
            <mat-radio-button value="klms">
                <span>KLMS</span>
            </mat-radio-button>
        </mat-radio-group>
        <div
            class="input-wrapper default-note mt-1"
            fxLayoutGap="10px"
            fxLayout="column"
            *ngIf="mailForm.value.type === 'klms'"
        >
            <div class="title-mail">
                <mat-icon>person_search</mat-icon>
                <span i18n>Recipient</span>
            </div>
            <div fxLayout="row" fxFlex="grow" fxLayoutGap="10px" fxLayoutGap.lt-md="10px">
                <mat-form-field class="small-field users-rec-input" appearance="fill">
                    <input
                        type="text"
                        matInput
                        #userRecieverInput
                        i18n-placeholder
                        placeholder="Search users"
                        [formControl]="userCtrl"
                        [matAutocomplete]="autoUser"
                    />
                    <mat-autocomplete #autoUser="matAutocomplete" (optionSelected)="selected(autoUser, $event)">
                        <mat-option *ngFor="let user of userSearch$ | async" [value]="user">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon> {{ user.role_value | userRoleIcon }}</mat-icon>
                                <span>{{ user.name }}</span>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="chips-wrapper" fxLayout="column wrap">
                <mat-chip-list
                    *ngIf="userRecipients.length"
                    class="reci-chips mb-1"
                    #chipList
                    aria-label="Recievers selection"
                >
                    <mat-chip
                        *ngFor="let recipient of userRecipients"
                        (removed)="removeUser(recipient)"
                        [selectable]="false"
                        class="users-wrap"
                        [ngClass]="{
                            'dark-blue-bg': recipient.role_value === 'user',
                            'green-bg': recipient.role_value !== 'user'
                        }"
                    >
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <mat-icon>{{ recipient.role_value | userRoleIcon }}</mat-icon>
                            <span>{{ recipient.fname }}</span>
                            <span> {{ recipient.lname }}</span>
                        </div>
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip></mat-chip-list
                >
            </div>
        </div>
        <div
            class="mt-1"
            [ngClass]="{ 'klms-selected': mailForm.value.type === 'klms', hide: mailForm.value.type === 'client' }"
            fxLayoutGap="10px"
            fxLayout="column"
        >
            <div class="title-mail">
                <mat-icon>title</mat-icon>
                <span i18n>Subject</span>
            </div>

            <mat-form-field appearance="fill" class="templates-select" fxFlex="0 0 100%">
                <input matInput placeholder="Type in the subject*" i18n-placeholder formControlName="subject" />
            </mat-form-field>
        </div>
        <div [ngClass]="{ hide: mailForm.value.type === 'client' }" fxLayoutGap="10px" fxLayout="column">
            <div class="title-mail">
                <mat-icon>subject</mat-icon>
                <span i18n>Body</span>
            </div>
            <app-text-editor
                [subject]="mailForm.value.subject"
                (onSubject)="mailForm.controls['subject'].patchValue($event)"
                [fitTemplatesInSmallScreen]="true"
                formControlName="body"
                fieldName="userWelcomeMessage"
                title="Content"
                i18n-title
                fxFlex="1 0 100%"
                class="mail-body-menu"
            >
            </app-text-editor>
        </div>
    </form>
</div>
