import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import { HttpErrorService, RequestBuilderService } from '@klickdata/core/http';
import { Observable, map } from 'rxjs';
import { Task, TaskData } from './task.model';
import { User } from '@klickdata/core/user';
import { ResourceTag } from '@klickdata/core/resource';

@Injectable({
    providedIn: 'root',
})
export class TaskService {
    protected tasksUrl: string;

    constructor(
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected error: HttpErrorService
    ) {
        this.tasksUrl = `${config.config.apiUrl}tasks`;
    }
    public getAllTasks(params: { limit: number }): Observable<Task[]> {
        const req = this.builder.get<TaskData[]>(this.tasksUrl);
        return req.request().pipe(map((res) => this.mapTask(res.data)));
    }
    public create(task: TaskData, eager?: string[]): Observable<Task> {
        return this.builder
            .post<TaskData>(this.tasksUrl, task)
            .putEager(eager)
            .request()
            .pipe(
                map((res) => {
                    return this.createTask(res.data);
                })
            );
    }

    public duplicate(task: Task, eager?: string[]): Observable<Task> {
        return this.builder
            .post<TaskData>(`${this.tasksUrl}/${task.id}/duplicate`, null)
            .putEager(eager)
            .request()
            .pipe(
                map((res) => {
                    return this.createTask(res.data);
                })
            );
    }

    public downlaod(filters?: { [key: string]: any }): Observable<Blob> {
        const builder = this.builder.get<Blob>(`${this.tasksUrl}/download`);
        builder.putParam(filters);
        return builder.download();
    }

    public update(task: TaskData, eager?: string[]): Observable<Task> {
        return this.builder
            .put<TaskData>(`${this.tasksUrl}/${task.id}`, task)
            .putEager(eager)
            .request()
            .pipe(
                map((res) => {
                    return this.createTask(res.data);
                })
            );
    }
    public updateTaskDone(taskIds: number[], isDone: boolean): Observable<any> {
        const req = this.builder.put<TaskData[]>(
            `${this.tasksUrl}/${taskIds.join(',')}/${isDone ? 'done' : 'undone'}`,
            {}
        );
        return req.request().pipe(map((res) => res.data));
    }
    public destroy(ids: number[]): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.tasksUrl}/${ids}`)
            .request()
            .pipe(map((res) => res.data));
    }
    protected mapTask(data?: TaskData[]): Task[] {
        return data.map((item) => this.createTask(item));
    }
    public createTask(taskData: TaskData): Task {
        const task = new Task(taskData);
        if (task.children_count < 1) {
            task.displayTaskNotes = true;
        }

        task.tags = taskData.tags?.map((tagData) => new ResourceTag(tagData)) || [];

        return task;
    }
}
