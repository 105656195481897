<plyr
    *ngIf="!(media.provider === 'html5' && media.mediaType === 'video')"
    style="display: block; width: 100%"
    plyrTitle="NK3 Player"
    [plyrPlaysInline]="true"
    [plyrType]="media.mediaType | cast"
    [plyrSources]="[media]"
    [plyrOptions]="media.playerOptions"
    (plyrInit)="onInit($event)"
    (plyrReady)="onReady()"
    (plyrPlay)="played.emit(true)"
    (plyrError)="onError($event)"
    (plyrEnded)="mediaCompleted.emit(true)"
>
</plyr>
<!-- https://videogular.github.io/ngx-videogular/docs/getting-started/using-the-api.html -->
<!-- https://videogular.github.io/ngx-videogular/docs/modules/core/services/vg-api/ -->
<vg-player *ngIf="media.provider === 'html5' && media.mediaType === 'video'" (onPlayerReady)="onPlayerReady($event)">
    <vg-overlay-play></vg-overlay-play>
    <vg-buffering></vg-buffering>

    <vg-scrub-bar>
        <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
        <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
    </vg-scrub-bar>

    <vg-controls [vgAutohide]="true" [vgAutohideTime]="1.5">
        <vg-play-pause></vg-play-pause>
        <vg-playback-button></vg-playback-button>

        <vg-scrub-bar style="pointer-events: none"></vg-scrub-bar>

        <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

        <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

        <vg-track-selector></vg-track-selector>

        <vg-mute></vg-mute>
        <vg-volume></vg-volume>

        <vg-fullscreen></vg-fullscreen>
    </vg-controls>

    <video [vgMedia]="$any(myMedia)" #myMedia id="singleVideo" preload="auto" crossorigin>
        <source [src]="media.url || media.src" [type]="media.type" />
    </video>
</vg-player>
