import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourceItemService } from '@klickdata/core/resource-item/src/resource-item.service';
import { ResourceItemTypeService } from '@klickdata/core/resource-item/src/type/resource-item-type.service';
import { QuestionModule } from '@klickdata/core/question';

@NgModule({
    imports: [CommonModule, QuestionModule],
})
export class ResourceItemModule {}
