import 'reflect-metadata';
import { AbstractForm } from '../abstract-form';
import { Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { IModel } from '@klickdata/core/application';

export class HasOne<T> {
    public change: Subject<void> = new Subject<void>();

    constructor(
        protected _parent: AbstractForm<IModel>,
        public _childClass: Type<T>,
        public _propertyName: string,
    ) {
    }

    protected _child: AbstractForm<IModel>;

    public get child(): AbstractForm<IModel> {
        return this._child;
    }

    public get childClass(): Type<T> {
        return this._childClass;
    }

    public attach(child: AbstractForm<IModel>) {
        this._child = child;
        this._parent.connect(this._child.form, this._propertyName);
        this._child.setParent(this._parent);
    }

    public validate(): boolean {
        return this._child.validate();
    }

    public submit(): Observable<IModel> {
        return this._child.submit(false);
    }

    public isPristine(): boolean {
        return this._child.isPristine();
    }
}
