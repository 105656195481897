<div class="data-wrap" fxLayout="column">
    <div fxLayoutAlign="space-between center">
        <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon>{{ displayedIcon }}</mat-icon>
            <div>{{ displayedTitle }}</div>
        </div>
        <div class="action-btns">
            <button
                *ngIf="stepper.selectedIndex !== 0"
                color="accent"
                fxFlex="0 0 auto"
                mat-icon-button
                (click)="stepper?.previous()"
                type="button"
            >
                <mat-icon>arrow_back_ios</mat-icon>
            </button>
            <button
                *ngIf="stepper.selectedIndex < stepper.steps.length - 1"
                fxFlex="0 0 auto"
                mat-button
                color="primary"
                type="button"
                (click)="stepper?.next()"
                i18n
            >
                Next
            </button>
            <button
                *ngIf="stepper.selectedIndex === stepper.steps.length - 1"
                fxFlex="0 0 auto"
                mat-button
                color="primary"
                type="submit"
                (click)="onClose.emit()"
                i18n
            >
                Order
            </button>
        </div>
    </div>
    <div class="res-details" fxLayoutGap="10px" fxLayoutAlign="space-between center">
        <div fxLayout="column">
            <span class="title">{{ navData.data.resource.title }}</span>
            <span class="orange" *ngIf="navData.data.resource.price"
                >{{ navData.data.resource.price }} &nbsp;{{ navData.data.resource.currency }}
            </span>
        </div>
        <app-image-view [paddingBottom]="'70px'" [media_id]="navData.data.resource.media$"></app-image-view>
    </div>
    <form [formGroup]="registrationForm">
        <div class="regis-stepper-wrapper" fxFlex="100%" fxLayout="row">
            <mat-stepper
                (selectionChange)="getDisplayedTitle($event)"
                class="regis-stepper event"
                labelPosition="bottom"
                linear
                #stepper
            >
                <mat-step [editable]="true">
                    <div [formGroup]="registrationForm.get('ticketsForm') | cast" fxLayout="column" fxLayoutGap="1em">
                        <div fxLayout="column" fxLayoutGap="10px">
                            <span class="normal">How many tickets</span>
                            <app-user-setting-input
                                type="number"
                                i18n-placeholder
                                [numbersCounterChanger]="true"
                                placeholder="Tickets"
                                [control]="registrationForm.get('ticketsForm').get('ticketsAmount') | cast"
                            >
                            </app-user-setting-input>
                        </div>
                        <span class="align-left normal">Summary of orders</span>
                        <div class="align-left amount">
                            {{ registrationForm.get('ticketsForm').get('ticketsAmount').value }} *
                            {{ navData.data.resource.title }}
                        </div>
                        <div class="align-left price-total">
                            <span>Total:</span>
                            <span class="orange">{{
                                registrationForm.get('ticketsForm').get('ticketsAmount').value *
                                    navData.data.resource.price
                            }}</span>
                            <span class="orange">{{ navData.data.resource.currency }}</span>
                        </div>
                        <div>
                            <span class="normal">Voucher code</span>
                            <app-user-setting-input
                                type="text"
                                [control]="registrationForm.get('ticketsForm').get('voucher') | cast"
                            ></app-user-setting-input>
                        </div>
                        <button mat-button class="proceed" (click)="stepper?.next()">Proceed</button>
                    </div>
                </mat-step>
                <mat-step [editable]="true">
                    <div [formGroup]="registrationForm.get('personalRegForm') | cast">
                        <div fxLayout="column" fxLayoutGap="15px" formArrayName="personalInfo">
                            <div
                                fxLayout="column"
                                fxLayoutGap="15px"
                                *ngFor="let userInfo of personalInfo.controls; let i = index"
                                [formGroup]="userInfo | cast"
                            >
                                <div *ngIf="i === 0" fxLayout="column" fxLayoutGap="15px">
                                    <app-user-setting-input
                                        type="text"
                                        i18n-placeholder
                                        placeholder="First Name*"
                                        [control]="userInfo.get('fname') | cast"
                                    >
                                        <label role="input-label" i18n="@@firstName">First Name</label>
                                    </app-user-setting-input>

                                    <app-user-setting-input
                                        type="text"
                                        i18n-placeholder
                                        placeholder="Last Name*"
                                        [control]="userInfo.get('lname') | cast"
                                    >
                                        <label role="input-label" i18n="@@lastName">Last Name</label>
                                    </app-user-setting-input>
                                    <app-user-setting-input
                                        type="email"
                                        i18n-placeholder
                                        placeholder="Email*"
                                        [control]="userInfo.get('email') | cast"
                                    >
                                        <label role="input-label">
                                            <ng-container i18n="@@email"> Email</ng-container>
                                        </label>
                                    </app-user-setting-input>
                                </div>
                                <mat-expansion-panel *ngIf="i > 0" [expanded]="false">
                                    <mat-expansion-panel-header class="main">
                                        <span>Information for ticket {{ i + 1 }}</span>
                                    </mat-expansion-panel-header>
                                    <div fxLayout="column" fxLayoutGap="10px">
                                        <app-user-setting-input
                                            type="text"
                                            i18n-placeholder
                                            placeholder="First Name*"
                                            [control]="userInfo.get('fname') | cast"
                                        >
                                            <label role="input-label" i18n="@@firstName">First Name</label>
                                        </app-user-setting-input>

                                        <app-user-setting-input
                                            type="text"
                                            i18n-placeholder
                                            placeholder="Last Name*"
                                            [control]="userInfo.get('lname') | cast"
                                        >
                                            <label role="input-label" i18n="@@lastName">Last Name</label>
                                        </app-user-setting-input>
                                        <app-user-setting-input
                                            type="email"
                                            i18n-placeholder
                                            placeholder="Email*"
                                            [control]="userInfo.get('email') | cast"
                                        >
                                            <label role="input-label">
                                                <ng-container i18n="@@email"> Email</ng-container>
                                            </label>
                                        </app-user-setting-input>
                                    </div>
                                </mat-expansion-panel>
                            </div>
                        </div>

                        <div class="mt-1" fxLayoutGap="5px" fxLayoutAlign="start center">
                            <mat-checkbox formControlName="update" color="primary" i18n
                                >Keep me updated on more events and news from this event organizer.</mat-checkbox
                            >
                        </div>
                        <div class="mt-1" fxLayoutGap="5px" fxLayoutAlign="start center">
                            <mat-checkbox formControlName="events" color="primary" i18n
                                >Send me emails about the best events happening nearby or online.</mat-checkbox
                            >
                        </div>
                        <div class="mt-1" fxLayoutGap="5px" fxLayoutAlign="start center">
                            <mat-checkbox formControlName="terms" color="primary" i18n
                                >I accept
                                <span (click)="readMoreLink()" class="orange">Terms of services</span></mat-checkbox
                            >
                        </div>
                        <button mat-button class="proceed" (click)="stepper?.next()">Proceed</button>
                    </div>
                </mat-step>
                <mat-step
                    *ngIf="navData.data.resource.price"
                    [stepControl]="registrationForm.get('payment_method')"
                    [editable]="true"
                >
                    <div [formGroup]="registrationForm.get('paymentForm') | cast" fxLayout="column" fxLayoutGap="15px">
                        <span class="normal">Choose payment alternative</span>
                        <mat-radio-group
                            class="reg-payment"
                            fxLayout="row"
                            fxLayoutAlign="space-between center"
                            formControlName="payment_method"
                            aria-label="Select payment"
                        >
                            <mat-radio-button i18n value="card">Card</mat-radio-button>
                            <mat-radio-button i18n value="paypal">PayPal</mat-radio-button>
                            <mat-radio-button i18n value="klarna">Klarna</mat-radio-button>
                            <mat-radio-button i18n value="invoice">Invoice</mat-radio-button>
                        </mat-radio-group>
                        <div
                            *ngIf="registrationForm.get('paymentForm').value.payment_method === 'card'"
                            class="card"
                            fxLayout="column"
                            fxLayoutGap="15px"
                        >
                            <app-user-setting-input
                                type="number"
                                [control]="registrationForm.get('paymentForm').get('card_number') | cast"
                            >
                                <label role="input-label">
                                    <ng-container> Card number</ng-container>
                                </label>
                            </app-user-setting-input>
                            <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="row" fxLayoutGap="10px">
                                <app-user-setting-input
                                    type="number"
                                    [control]="registrationForm.get('paymentForm').get('exp_month') | cast"
                                >
                                    <label role="input-label">
                                        <ng-container> Exp month</ng-container>
                                    </label>
                                </app-user-setting-input>
                                <app-user-setting-input
                                    type="number"
                                    [control]="registrationForm.get('paymentForm').get('exp_year') | cast"
                                >
                                    <label role="input-label">
                                        <ng-container> Exp year</ng-container>
                                    </label>
                                </app-user-setting-input>
                            </div>

                            <app-user-setting-input
                                type="number"
                                [control]="registrationForm.get('paymentForm').get('cvv') | cast"
                            >
                                <label role="input-label">
                                    <ng-container> CVV code</ng-container>
                                </label>
                            </app-user-setting-input>
                        </div>
                        <div
                            *ngIf="registrationForm.get('paymentForm').value.payment_method === 'paypal'"
                            class="paypal mt-1"
                            fxLayout="column"
                            fxLayoutGap="15px"
                        >
                            <img src="assets/images/paypal_logo.png" />
                            <span class="normal"
                                >Click "Order" to pay with PayPal. You can check out as a guest or with your PayPal
                                account. Return with this page when you done.</span
                            >
                        </div>
                        <div
                            *ngIf="registrationForm.get('paymentForm').value.payment_method === 'klarna'"
                            class="klarna mt-1"
                            fxLayout="column"
                            fxLayoutGap="15px"
                        >
                            <img src="assets/images/klarna_logo.png" />
                            <span class="normal"
                                >Click "Order" to pay with Klarna. You can check out as a guest or with your Klarna
                                account. Return with this page when you done.</span
                            >
                        </div>
                        <div
                            *ngIf="registrationForm.get('paymentForm').value.payment_method === 'invoice'"
                            class="invoice mt-1"
                            fxLayout="column"
                            fxLayoutGap="15px"
                        >
                            <ng-container [ngTemplateOutlet]="invoiceRegForm"></ng-container>
                        </div>
                    </div>
                    <button mat-button class="proceed last" (click)="onClose.emit()">Order</button>
                </mat-step>
            </mat-stepper>
        </div>
    </form>
</div>
<ng-template #invoiceRegForm>
    <div
        fxLayout="column"
        fxLayoutGap="10px"
        fxLayout="column"
        [formGroup]="registrationForm.get('invoiceRegForm') | cast"
    >
        <app-user-setting-input
            type="text"
            i18n-placeholder
            placeholder="Organization name"
            [control]="registrationForm.get('personalRegForm').get('business_company') | cast"
        >
        </app-user-setting-input>
        <app-user-setting-input
            type="text"
            i18n-placeholder
            placeholder="Department"
            [control]="registrationForm.get('invoiceRegForm').get('department') | cast"
        >
        </app-user-setting-input>

        <app-user-setting-input
            type="text"
            i18n-placeholder
            placeholder="Organization address"
            [control]="registrationForm.get('invoiceRegForm').get('orgnumber') | cast"
        >
        </app-user-setting-input>
        <div
            fxLayout="column"
            fxLayoutGap="10px"
            [formGroup]="$any(registrationForm.get('invoiceRegForm').get('address'))"
        >
            <app-user-setting-input
                type="text"
                i18n-placeholder
                placeholder="Street adress"
                [control]="registrationForm.get('invoiceRegForm').get('address').get('street') | cast"
            >
            </app-user-setting-input>
            <app-user-setting-input
                type="text"
                i18n-placeholder
                placeholder="Box"
                [control]="registrationForm.get('invoiceRegForm').get('address').get('box') | cast"
            >
            </app-user-setting-input>
            <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="row" fxLayoutGap="10px">
                <app-user-setting-input
                    type="text"
                    i18n-placeholder
                    placeholder="Zipcode"
                    [control]="registrationForm.get('invoiceRegForm').get('address').get('zipcode') | cast"
                >
                </app-user-setting-input>
                <app-user-setting-input
                    type="text"
                    i18n-placeholder
                    placeholder="City"
                    [control]="registrationForm.get('invoiceRegForm').get('address').get('city') | cast"
                >
                </app-user-setting-input>
            </div>
            <app-user-setting-input
                type="text"
                i18n-placeholder
                placeholder="Country"
                [control]="registrationForm.get('invoiceRegForm').get('address').get('country') | cast"
            >
            </app-user-setting-input>
            <app-user-setting-input
                type="text"
                i18n-placeholder
                placeholder="Other info"
                [control]="registrationForm.get('invoiceRegForm').get('address').get('other') | cast"
            >
            </app-user-setting-input>
        </div>
        <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="row" fxLayoutGap="10px">
            <app-user-setting-input
                type="text"
                i18n-placeholder
                placeholder="Role in organization"
                [control]="registrationForm.get('invoiceRegForm').get('person_role') | cast"
            >
            </app-user-setting-input>
            <app-user-setting-input
                type="text"
                i18n-placeholder
                placeholder="Cost department"
                [control]="registrationForm.get('invoiceRegForm').get('cost_department') | cast"
            >
            </app-user-setting-input>
        </div>
        <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="row" fxLayoutGap="10px">
            <app-user-setting-input
                type="text"
                i18n-placeholder
                placeholder="Purchase order"
                [control]="registrationForm.get('invoiceRegForm').get('purchase_order') | cast"
            >
            </app-user-setting-input>

            <app-user-setting-input
                type="text"
                i18n-placeholder
                placeholder="Campaign code"
                [control]="registrationForm.get('invoiceRegForm').get('campaign_code') | cast"
            >
            </app-user-setting-input>
        </div>
    </div>
</ng-template>
