import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { Customer, CustomerData, CustomerService } from '@klickdata/core/customer';
import { LanguageService } from '@klickdata/core/localization';

import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';
import { TaskService } from '@klickdata/core/task';
import { User, UserService } from '@klickdata/core/user';
import { BehaviorSubject, EMPTY, Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserChipSelectComponent } from '../chip-selection/user-chip-select/user-chip-select.component';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MessageService } from '@klickdata/core/message/src/message.service';

@Component({
    selector: 'app-menu-side-add-customer',
    templateUrl: './menu-side-add-customer.component.html',
    styleUrls: ['./menu-side-add-customer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddCustomerComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public customerForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public onLoading: Subject<boolean> = new Subject<boolean>();
    public onLoadingCustomerName: Subject<boolean> = new Subject<boolean>();
    ResourceStaffRoles = ResourceStaffRoles;
    customerExist: boolean;
    onSearchCustomer$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public currentLaguageId$: Observable<number>;
    public connectionsControl = new FormControl();
    public formConnections: { id?: number; name?: string }[] = [];
    @ViewChild('customerInput') customerInput: ElementRef<HTMLInputElement>;
    @ViewChild('userChipSelect') userInput: UserChipSelectComponent;
    public customerCtrl = new FormControl('');
    public customerSearch$: Observable<Customer[]>;
    public selectedCustomer: Customer;

    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        protected changeRef: ChangeDetectorRef,
        protected customerService: CustomerService,
        protected messageService: MessageService,
        protected taskService: TaskService,
        protected auth: AuthService,
        protected langService: LanguageService
    ) {}

    ngOnInit(): void {
        this.buildForm();
        this.customerSearch$ = this.customerForm.get('name').valueChanges.pipe(
            filter((term: string) => typeof term === 'string' && term != ''),
            tap(() => this.onSearchCustomer$.next(true)),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term) => (term.trim() ? this.customerService.getCustomers(term) : of(<Customer[]>[]))),
            tap(() => this.onSearchCustomer$.next(false))
        );

        this.currentLaguageId$ = this.langService
            .getLanguageByKey(this.langService.getCurrentLanguage().value)
            .pipe(map((lang) => lang.id));
    }
    protected buildForm(): void {
        this.customerForm = this.fb.group(
            {
                id: [''],
                name: [''],
                staff: this.fb.group({
                    manager: [[]],
                }),
                connections: [[]],
                note: [''],
                tag_ids: [''],
                type: ['prospect'],
                task: this.fb.group({
                    deadline: [
                        this.navData.data.clientCard
                            ? new DatePipe('en').transform(Date.now(), 'yyyy-MM-dd').toString()
                            : '',
                    ],
                }),
            },
            { validator: this.atLeastOneFieldValidator('name', 'note') }
        );
    }

    atLeastOneFieldValidator(nameKey: string, noteKey: string): ValidatorFn {
        return (formGroup: FormGroup): { [key: string]: boolean } | null => {
            const name = formGroup.get(nameKey);
            const note = formGroup.get(noteKey);
            if (!name.value && !note.value) {
                return { atLeastOneFieldRequired: true };
            }
            return null;
        };
    }

    canSubmitForm() {
        const controls = Object.values(this.customerForm.controls);
        const controlsStatusWithValue = [];
        controls.some((control) => {
            if (control instanceof FormGroup) {
                controlsStatusWithValue.push(control.value.contact.length > 0);
            } else {
                controlsStatusWithValue.push(control.value != '');
            }
        });
        return controlsStatusWithValue.some((value) => value === true);
    }
    updateConections(data: { users: User[]; draftUsers: string[] }) {
        this.formConnections = [
            ...data.draftUsers.map((str) => ({ name: str })),
            ...data.users.map((user) => ({ id: user.id })),
        ];
    }

    submit() {
        this.onLoading.next(true);
        const createOrUpdate = this.prepareData().id
            ? this.customerService.update(this.prepareData(), ['task,managers,customFields'])
            : this.customerService.create(this.prepareData(), ['task,managers,customFields']);
        createOrUpdate.pipe(takeUntil(this.destroy)).subscribe({
            next: (customer: Customer) => {
                this.onLoading.next(false);
                this.onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: { customer: customer, type: this.navData.data.type },
                });
                this.customerForm.reset();
                this.customerExist = false;
            },
            error: (err) => {
                if (err?.error?.error) {
                    this.onLoading.next(false);
                    this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                }
            },
        });
    }
    prepareData(): CustomerData {
        const data = {
            ...new Customer(this.customerForm.value).getData(),
            card: true,
        };
        if (this.customerForm.value.staff && this.customerForm.value.staff?.manager) {
            data.staff = { manager: this.customerForm.value.staff?.manager };
        }
        if (data.name && data.name?.length) {
            delete data.id;
        }
        if (data.id) {
            delete data.name;
        }
        data.connections = [
            ...this.formConnections,
            ...(this.userInput.userCtrl.value != '' ? [{ name: this.userInput.userCtrl.value }] : []),
        ];
        return data;
    }
}
