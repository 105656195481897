<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title">{{ navData?.title }}</div>
    <div class="desc">{{ navData?.desc }}</div>
    <div class="action">
        <button
            fxFlex="0 0 auto"
            class="mobile-button"
            (click)="onClose.emit({ action: SideNaveActionsTypes.POSITIVE })"
            mat-icon-button
        >
            <mat-icon>{{ navData?.actionIcon }}</mat-icon>
        </button>
    </div>
</div>
