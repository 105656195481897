import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config';
import { CustomerService } from '@klickdata/core/customer';
import { GradeSystem, GradeSystemData } from '@klickdata/core/grade-system';
import { RequestBuilderService } from '@klickdata/core/http';
import { Resource } from '@klickdata/core/resource';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { GradeService } from './grade.service';

@Injectable({
    providedIn: 'root',
})
export class GradeSystemService {
    protected api: string;
    protected customer_id: Observable<number>;

    constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected gradeService: GradeService,
        protected customerService: CustomerService,
        protected configService: ConfigService
    ) {
        this.api = `${this.config.config.apiUrl}grade-systems`;

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map(customer => customer.id)
        );
    }

    public getGradeSystems(resource?: Resource): Observable<GradeSystem[]> {
        return this.auth.getUser().pipe(
            first(),
            switchMap(user => {
                const builder = this.builder
                    .get<GradeSystemData[]>(this.api)
                    .param(
                        'publicOrCustomer',
                        resource && user.canEdit(resource) ? resource.customer_id : this.customer_id
                    );
                return builder.request().pipe(map(res => this.mapGradeSystem(res.data)));
            })
        );
    }

    public getGradeSystem(grade_system_id: number): Observable<GradeSystem> {
        return this.builder
            .get<GradeSystemData>(`${this.api}/${grade_system_id}`)
            .request()
            .pipe(map(res => this.makeGradeSystem(res.data)));
    }

    public create(gradeSystem: GradeSystemData): Observable<GradeSystem> {
        return this.builder
            .post<GradeSystemData>(this.api, gradeSystem)
            .request()
            .pipe(map(res => this.makeGradeSystem(res.data)));
    }

    public update(gradeSystem: GradeSystemData): Observable<GradeSystem> {
        return this.builder
            .put<GradeSystemData>(`${this.api}/${gradeSystem.id}`, gradeSystem)
            .request()
            .pipe(map(res => this.makeGradeSystem(res.data)));
    }

    public destroy(system: GradeSystem): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.api}/${system.id}`)
            .request()
            .pipe(map(res => res.data));
    }

    public mapGradeSystem(data: GradeSystemData[]): GradeSystem[] {
        return data.map(item => this.makeGradeSystem(item));
    }

    public makeGradeSystem(data: GradeSystemData): GradeSystem {
        const gradeSystem = new GradeSystem(data);

        gradeSystem.grades = this.gradeService.getGrades(gradeSystem.grade_ids);
        gradeSystem.customer = this.customerService.getCustomer(gradeSystem.customer_id);

        return gradeSystem;
    }
}
