import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanComponentDeactivate } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { MobileService } from '@klickdata/core/mobile';
import { Filter } from '@klickdata/core/table';
import { SelectModelBaseDirective } from '../select-model-base/select-model-base.component';
import { CustomerSelectDatatableService } from './customer-select-datatable.service';

@Component({
    selector: 'app-customer-select',
    templateUrl: './customer-select.component.html',
    styleUrls: ['./customer-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CustomerSelectDatatableService],
})
export class CustomerSelectComponent
    extends SelectModelBaseDirective<Customer>
    implements OnInit, AfterViewInit, CanComponentDeactivate, OnDestroy
{
    @Input() accessForm: FormGroup;
    @Input() referenceFilter: string;
    @Input() selectionFilters: Filter<any>[];
    public columns = ['select', 'name'];
    protected sync_all_key = 'sync_all_customers';
    protected attachKey = 'customers_attach';
    protected detachKey = 'customers_detach';

    constructor(protected datatableService: CustomerSelectDatatableService, protected mobileService: MobileService) {
        super(datatableService, mobileService);
    }

    protected refresh(id: any): void {
        const appliedFilters = [];
        if (this.selectionFilters && this.selectionFilters.length > 0) {
            this.selectionFilters.forEach((filterItem) => {
                appliedFilters.push(new Filter(filterItem.property, filterItem.items));
            });
        } else {
            appliedFilters.push({ property: this.referenceFilter, items: [id] });
        }
        this.filter.createOrUpdateFilter(appliedFilters);
    }
}
