<div
    class="{{ selectingStyleClass }}"
    class="res-listing-wrapper res-listing-mobile general-items-lists"
    fxLayout="column"
    fxFlex="0 0 100%"
>
    <mat-accordion class="filters-acc" *ngIf="allowExpandedFilterPanel" fxFlex="0 0 100%" displayMode="flat">
        <mat-expansion-panel [expanded]="true" #expansionPanel>
            <mat-expansion-panel-header> </mat-expansion-panel-header>
            <ng-container [ngTemplateOutlet]="filtersParent"></ng-container>
        </mat-expansion-panel>
    </mat-accordion>

    <ng-container *ngIf="!allowExpandedFilterPanel" [ngTemplateOutlet]="filtersParent"></ng-container>

    <div *ngIf="showActionHeader" class="header-replace">
        <div class="hover-wrapper top" fxLayoutAlign="space-around center">
            <ng-container [ngTemplateOutlet]="selectiveTableActionBtns"></ng-container>
        </div>
    </div>
    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <div class="table-wrapper">
        <mat-table
            #table
            [dataSource]="dataSource"
            [matSortActive]="activeSortingCol ? activeSortingCol : 'title'"
            matSortDirection="desc"
            matSort
            [class.showHeader]="showActionHeader"
        >
            <ng-container matColumnDef="select">
                <mat-header-cell *matHeaderCellDef>
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()"
                    >
                    </mat-checkbox>
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(resource) : null"
                        [checked]="selection.isSelected(resource)"
                        [aria-label]="checkboxLabel(resource)"
                    >
                    </mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="columns">
                <mat-header-cell *matHeaderCellDef>
                    <div fxLayoutAlign="start center">
                        <span i18n="@@columns">Columns</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <app-image-view [media_id]="resource.media$" [proportional]="false"></app-image-view>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span *ngIf="!isMobile" [class.original]="allowActionRow">{{ resource.title }}</span>

                    <div class="mobile-row-title" *ngIf="isMobile">
                        <div class="mobile-img-wrapper">
                            <app-image-view
                                (click)="handleRowClick(resource)"
                                [media_id]="resource.media$"
                                [proportional]="true"
                            ></app-image-view>
                        </div>
                        <div class="info-main-wrapper">
                            <span class="item-title">{{ resource.title }}</span>
                            <div class="nu-items">
                                <span>
                                    {{ resource.number_of_items }}
                                </span>
                            </div>
                        </div>
                        <button
                            class="expand-btn"
                            mat-icon-button
                            (click)="expandedRow = expandedRow === resource ? null : resource"
                        >
                            <mat-icon>
                                {{ expandedRow === resource ? 'expand_less' : 'expand_more' }}
                            </mat-icon>
                        </button>
                    </div>
                    <div
                        *ngIf="!isMobile"
                        [ngClass]="allowActionHeader ? 'hover' : 'non-display'"
                        [class.hover]="allowActionRow"
                    >
                        <div class="hover-wrapper" fxLayoutAlign="space-between center">
                            <ng-container
                                [ngTemplateOutlet]="tableCellActionBtns"
                                [ngTemplateOutletContext]="{ resource: resource }"
                            ></ng-container>
                            <ng-container *ngIf="resource.last_publish">
                                <button
                                    class="hover-tools-btn"
                                    *ngIf="!(shareLoading | async); else shareLoadTemp"
                                    mat-icon-button
                                    matTooltip="Share"
                                    i18n-matTooltip="@@share"
                                    (click)="shareService.openShareDialog(resource, shareLoading)"
                                >
                                    <mat-icon>share</mat-icon>
                                </button>
                            </ng-container>
                        </div>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="summary">
                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                <mat-cell [class.no-display-cell]="!resource.bullets" *matCellDef="let resource">
                    {{ resource.bullets }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="last_taken">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@lastTaken"> Last taken </mat-header-cell>
                <mat-cell [class.no-display-cell]="!resource.last_taken" *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@lastTaken">Last taken</span>
                    {{ resource.last_taken | kdDateFormat : 'YYYY-MM-DD' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="occasions_count">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@takenTimes"> Taken times </mat-header-cell>
                <mat-cell [class.no-display-cell]="!resource.occasions_count" *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@takenTimes">Taken times</span>
                    {{ resource.occasions_count }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="occasions_started">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@startedCourses">
                    Started courses
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@startedCourses">Started courses</span>
                    {{ resource.occasions_started }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="occasions_done">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@completedCourses">
                    Completed courses
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@completedCourses">Completed courses</span>
                    {{ resource.occasions_done }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="author_name">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdBy"> Created by </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@createdBy">Created by</span>
                    <ng-container *ngIf="resource.author$ | async; let loadedAuthor">
                        {{ $any(loadedAuthor).name }}
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="users_count">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@users"> Users </mat-header-cell>
                <mat-cell [class.no-display-cell]="!resource.users_count" *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@users">Users</span>
                    {{ resource.users_count }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="question_number">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Q#"> Q# </mat-header-cell>
                <mat-cell [class.no-display-cell]="!resource.number_of_items" *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@Q#">Q#</span>
                    {{ resource.number_of_items }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="language">
                <mat-header-cell *matHeaderCellDef>
                    <div fxLayoutAlign="start center">
                        <span i18n="@@lang">Lang</span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@lang">Lang</span>
                    <ng-container *ngIf="resource.language_id">
                        <app-language-selector
                            [language_id]="resource.language_id"
                            class="icon-resource-builder"
                            fxFlex="0 0 auto"
                        ></app-language-selector>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tools">
                <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
                <mat-cell *matCellDef="let resource">
                    <div class="tools-cell-wrapper">
                        <button
                            *ngIf="tableToolsAction.includes(ResourceTableActions.ANALYTICS) && isMobile"
                            class="tools-btn"
                            mat-raised-button
                            matTooltip="View stats"
                            (click)="onOpenAnalytics.emit(resource)"
                            i18n-matTooltip="@@viewStats"
                            color="accent"
                        >
                            <mat-icon>analytics</mat-icon>
                            <span>Stats</span>
                        </button>
                        <button
                            *ngIf="tableToolsAction.includes(ResourceTableActions.VIEW) && isMobile"
                            class="tools-btn"
                            mat-raised-button
                            matTooltip="View"
                            (click)="$event.stopPropagation(); onBrowse.emit(resource)"
                            i18n-matTooltip="@@view"
                            color="primary"
                            [class.no-oppo]="!resource.opportunity_id"
                        >
                            <mat-icon>visibility</mat-icon>
                            <span>View</span>
                        </button>
                        <button
                            *ngIf="tableToolsAction.includes(ResourceTableActions.ANALYTICS) && !isMobile"
                            class="tools-btn"
                            mat-icon-button
                            matTooltip="View stats"
                            (click)="onOpenAnalytics.emit(resource)"
                            i18n-matTooltip="@@viewStats"
                        >
                            <mat-icon>analytics</mat-icon>
                        </button>
                        <button
                            *ngIf="tableToolsAction.includes(ResourceTableActions.VIEW) && !isMobile"
                            class="tools-btn"
                            mat-icon-button
                            matTooltip="View"
                            (click)="$event.stopPropagation(); onBrowse.emit(resource)"
                            i18n-matTooltip="@@view"
                            [class.no-oppo]="!resource.opportunity_id"
                        >
                            <mat-icon>visibility</mat-icon>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row
                [ngClass]="!resource.loading ? 'link' : 'disableIt'"
                (click)="handleRowClick(resource)"
                class="example-element-row"
                [class.res-expanded-row]="expandedRow === resource"
                *matRowDef="let resource; columns: columns"
            ></mat-row>
        </mat-table>
    </div>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #rowHover>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #shareLoadTemp>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
<ng-template #tableCellActionBtns let-resource="resource"> </ng-template>
<ng-template #selectiveTableActionBtns>
    <button
        class="tools-btn red"
        mat-raised-button
        matTooltip="Click to publish"
        [class.disableIt]="allResourcesArePublished"
        (click)="$event.stopPropagation(); publish(true)"
        i18n-matTooltip="@@clickToPublish"
    >
        <mat-icon class="material-icons-outlined">unpublished</mat-icon>
        <span class="btn-label" i18n="@@publish">Publish</span>
    </button>

    <button
        class="tools-btn"
        mat-raised-button
        matTooltip="Click to unpublish"
        [class.disableIt]="allResourcesAreNotPublished"
        (click)="$event.stopPropagation(); publish(false)"
        i18n-matTooltip="@@clickToUnpublish"
    >
        <mat-icon class="material-icons-outlined">published_with_changes</mat-icon>
        <span class="btn-label" i18n="@@unpublish">Unpublish</span>
    </button>

    <button
        class="tools-btn red"
        mat-raised-button
        matTooltip="Click to make public"
        [class.disableIt]="allResourcesArePubliced"
        (click)="$event.stopPropagation(); public(true)"
        i18n-matTooltip="@@clickToMakePublic"
    >
        <mat-icon>public_off</mat-icon>
        <span class="btn-label" i18n="@@public">Public</span>
    </button>

    <button
        class="tools-btn"
        mat-raised-button
        matTooltip="Click to make unpublic"
        [class.disableIt]="allResourcesAreNotPubliced"
        (click)="$event.stopPropagation(); public(false)"
        i18n-matTooltip="@@clickToMakeUnPublic"
    >
        <mat-icon>public</mat-icon>
        <span class="btn-label" i18n="@@unpublic">Unpublic</span>
    </button>
    <button
        class="tools-btn"
        mat-raised-button
        (click)="$event.stopPropagation(); remove()"
        matTooltip="Delete"
        i18n-matTooltip="@@delete"
    >
        <mat-icon class="material-icons-outlined">delete</mat-icon>
        <span class="btn-label" i18n="@@delete">Delete</span>
    </button>
</ng-template>
<ng-template #filtersParent>
    <app-table-toolbar class="toolbar" color="accent">
        <app-table-filter [cacheScope]="AppScope.RESOURCES" [inGlobalFilter]="true" color="accent" #filter>
            <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
            <app-tag-chip-search-filter
                [inGlobalFilter]="true"
                [languageChange]="langFilter.filterChange"
                fxFlex="50%"
                fxFlex.lt-md="100%"
                fxLayoutAlign="start"
                tagsFilterRef
            ></app-tag-chip-search-filter>
            <app-category-chip-search-filter
                [inGlobalFilter]="true"
                fxFlex="50%"
                fxFlex.lt-md="100%"
                [scope_id]="type_scope_id"
                categoriesFilterRef
            ></app-category-chip-search-filter>
            <app-table-date-filter [inGlobalFilter]="true" color="accent" dateFilterRef></app-table-date-filter>
            <app-language-filter
                [inGlobalFilter]="true"
                #langFilter
                fxFlex="0 0 auto"
                fxLayout="row"
                langFilterRef
            ></app-language-filter>
        </app-table-filter>
    </app-table-toolbar>
</ng-template>
