<div>
    <div fxFlex="33%" fxLayout="column">
        <h3>
            <span i18n="course">Course</span>
            <span>{{ resource.title }}</span>
        </h3>
        <div>
            <app-image-view fxFlex="100%" [smallSpinner]="true" class="mt-2" [media_id]="resource.media$">
            </app-image-view>
            <!-- <div fxFlex="40%">

            </div> -->
        </div>
    </div>
    <div fxFlex="33%">
        <!-- <app-home-gauges
                [type]="'course_plan'"
                typeLabel="completed courses"
                displayLayout="group"
                i18n-typeLabel="@@completedCourses"
                fxLayout.xs="column"
            ></app-home-gauges> -->
    </div>
    <div fxFlex="33%"></div>
</div>
<ng-template #loading>
    <div class="mt-2 mb-2" fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="center center">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
