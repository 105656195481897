import { AbstractControl, FormArray, FormGroup, ValidatorFn } from '@angular/forms';

export class AppValidators {
    public static notDelete(validator: (control: AbstractControl) => any): (control: AbstractControl) => any | null {
        if (!validator) {
            return null;
        }

        return (control: AbstractControl) => {
            if (!control.parent) {
                return validator(control);
            }

            const deleteValue = AppValidators.findParentDelete(control);
            const isDelete = deleteValue && deleteValue.value;

            if (isDelete) {
                return null;
            }

            return validator(control);
        };
    }

    protected static findParentDelete(control: AbstractControl): AbstractControl {
        if (!control.parent) {
            return null;
        }

        if (control.parent.get('delete')) {
            return control.parent.get('delete');
        }

        return AppValidators.findParentDelete(control.parent);
    }

    public static minLength(min: number): ValidatorFn | any {
        return (control: AbstractControl[]) => {
            if (!(control instanceof FormArray)) {
                return;
            }
            return control.value.length < min ? { minLength: true } : null;
        };
    }

    public static hasCorrect(usePoints: boolean): ValidatorFn | any {
        return (alts: AbstractControl) => {
            if (
                usePoints &&
                alts instanceof FormArray &&
                !alts.parent?.value.other_alternative &&
                'radio' === alts.parent?.value.question_type_value
            ) {
                for (const item of <FormGroup[]>alts.controls) {
                    if (!item.disabled && item.value.correct) {
                        return null;
                    }
                }
                return { missCorrect: true };
            }
            return null;
        };
    }
}
