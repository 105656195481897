<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon
            fxFlex="0 0 auto"
            fontSet="klickdata"
            fontIcon="kd-icon-test"
            class="course-plan-icon side-nave-icon no-shadow"
        ></mat-icon>
        <div i18n>Create test from material</div>
    </div>
    <div class="desc" i18n>
        With one click you can create a quiz from this material, just set the number of questions and level of
        difficulty! It will be saved under Tests in Content.
    </div>
    <app-vtq-sliders (valuesChange)="updateForm($event)"></app-vtq-sliders>
    <div class="action" *ngIf="!(loading | async)">
        <button fxFlex="0 0 auto" (click)="onClose.emit()" class="mobile-button negative" mat-button i18n>
            Cancel
        </button>
        <button fxFlex="0 0 auto" class="mobile-button positive" (click)="submitForm()" mat-raised-button i18n>
            Create
        </button>
    </div>
    <ng-container *ngIf="relatedTests | async; let tests">
        <div class="created-test" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="5px" *ngIf="tests.length">
            <span>Created Tests</span>
            <app-resource-title-with-icon
                *ngFor="let test of tests"
                (click)="onTestClick(test.id)"
                [displayLabel]="false"
                [resource]="test"
            ></app-resource-title-with-icon>
        </div>
    </ng-container>
    <div class="loading" *ngIf="loading | async">
        <app-saving-button></app-saving-button>
    </div>
</div>
