import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-resource-enrollment-request',
    templateUrl: './menu-side-resource-enrollment-request.component.html',
    styleUrls: ['./menu-side-resource-enrollment-request.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideResourceEnrollmentRequestComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();

    public value = new FormControl();
    constructor() {}

    ngOnInit(): void {}
}
