import { ENTER } from '@angular/cdk/keycodes';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import {
    MatAutocomplete,
    MatAutocompleteActivatedEvent,
    MatAutocompleteSelectedEvent,
    MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { FormHelper } from '@klickdata/core/form';
import { HttpErrorService } from '@klickdata/core/http';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { ResourceTag } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import {
    catchError,
    debounceTime,
    distinctUntilChanged,
    filter,
    first,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { TagService } from './../../../core/resource/src/tag/tag.service';
import { LanguageService } from '@klickdata/core/localization';

@Component({
    selector: 'app-tag-chip-select',
    templateUrl: './tag-chip-select.component.html',
    styleUrls: ['./tag-chip-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagChipSelectComponent),
            multi: true,
        },
    ],
})
export class TagChipSelectComponent implements OnInit, ControlValueAccessor {
    @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Input() visible = true;
    @Input() sortedAlph = false;
    @Input() selectable = true;
    @Input() removable = true;
    @Input() placeholder = $localize`Add tag`;
    @Input() language_id: number | number[];
    @Output() afterInit: EventEmitter<any> = new EventEmitter<any>();
    @Input() set resource_id(resource_id: number) {
        if (resource_id) {
            this.handleTagIds(resource_id);
        }
    }
    @Output() onTagClick: EventEmitter<ResourceTag> = new EventEmitter<ResourceTag>();

    separatorKeyCodes: number[] = [ENTER];
    tagCtrl = new FormControl();
    filteredTags: BehaviorSubject<ResourceTag[]> = new BehaviorSubject<ResourceTag[]>([]);
    private _selection: ResourceTag[] = [];
    private _optionActivated: boolean;
    public loading = false;

    get selection(): ResourceTag[] {
        return this._selection;
    }
    set selection(value: ResourceTag[]) {
        this._selection = value;
        this.notify();
    }

    constructor(
        protected tagService: TagService,
        protected cdRef: ChangeDetectorRef,
        protected config: ConfigService,
        protected auth: AuthService,
        protected errorService: HttpErrorService,
        protected injector: Injector,
        protected messageService: MessageService,
        protected languageService: LanguageService
    ) {}

    ngOnInit() {
        this.tagCtrl.valueChanges
            .pipe(
                filter((term) => !!term && typeof term === 'string'),
                tap(() => (this.loading = true)),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((term) => this.tagService.getCustomerTags({ langId: this.language_id, query: term })),
                takeUntil(this.destroy)
            )
            .subscribe((results) => {
                this.loading = false;
                this.filteredTags.next(results);
            });
    }

    /**
     * Not yet supported!
     * @param event from separators events.
     */
    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value?.trim();
        if (!value || this._optionActivated || this.loading) {
            return;
        }
        // Add Tag
        const suggested = this.filteredTags.value.find((item) => item.name.toLowerCase() === value.toLowerCase());
        if (suggested) {
            this.setTagInput(input, suggested);
        } else {
            this.auth
                .getUser()
                .pipe(
                    first(),
                    // filter(user => user.role_value !== 'user'), // Tags are public and user can add tags
                    switchMap((user) => {
                        if (!Array.isArray(this.language_id)) {
                            return this.createTag(value, user, this.language_id);
                        }
                        return combineLatest(this.language_id.map((langid) => this.createTag(value, user, langid)));
                    }),
                    takeUntil(this.destroy),
                    catchError((err) => {
                        if (err && err.error && err.error.error) {
                            // this.messageService.openMessage(
                            //     MessageErrorComponent,
                            //     $localize`${value} already exists in another type, Go to admin settings to attach.`
                            // );
                            this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                        }
                        return of(null);
                    })
                )
                .subscribe((tag) => this.setTagInput(input, tag));
        }
    }

    private createTag(value: string, user: User, langId: any): Observable<ResourceTag> {
        if (typeof langId === 'string') {
            return this.languageService.getLanguageByKey(langId).pipe(
                switchMap((lang) =>
                    this.tagService.createResourceTag({
                        name: value.trim(),
                        customer_id: user.customer_id,
                        language_id: lang.id,
                    })
                )
            );
        } else {
            return this.tagService.createResourceTag({
                name: value.trim(),
                customer_id: user.customer_id,
                language_id: langId,
            });
        }
    }

    private setTagInput(input: HTMLInputElement, tag: ResourceTag | ResourceTag[]) {
        if (!Array.isArray(tag)) {
            this.addTag(tag);
        } else {
            tag.forEach((el) => this.addTag(el));
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        this.tagCtrl.setValue(null);
    }

    remove(tag: ResourceTag): void {
        const index = this.selection.findIndex((removedTag) => removedTag?.name === tag.name);
        if (index !== -1) {
            this.selection.splice(index, 1);
            this.notify();
            this.tagCtrl.enable();
        }
    }

    onSelected(event: MatAutocompleteSelectedEvent): void {
        const selected = event.option.value;
        this.addTag(selected);
    }

    optionActivated(event: MatAutocompleteActivatedEvent) {
        this._optionActivated = !!event.option;
    }

    optionClosed() {
        this._optionActivated = false;
    }

    private addTag(selected: any) {
        // Duplication not aceptable.
        if (this.selection.findIndex((tag) => tag?.id === selected.id) === -1) {
            this.selection.push(selected);
            this.notify();
            this.tagInput.nativeElement.value = '';
            this.tagCtrl.setValue(null);
            this.cdRef.markForCheck();
        }
    }

    public propagateChange = (_: any) => {};
    writeValue(ids: any): void {
        if (!ids?.length || ids === null) {
            this.selection.length = 0;
            this.tagCtrl.reset();
        } else {
            this.initTags(ids);
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    registerOnTouched(fn: any): void {}
    setDisabledState?(isDisabled: boolean): void {
        isDisabled ? this.tagCtrl.disable() : this.tagCtrl.enable();
    }

    private notify() {
        this.propagateChange(this.selection.map((tag) => tag.id));
    }

    private handleTagIds(id: number) {
        this.tagService
            .getResourceTags(id, this.sortedAlph)
            .pipe(takeUntil(this.destroy))
            .subscribe((tags) => this.initSelection(this.sortedAlph ? this.sortTagsAlph(tags) : tags));
    }

    private initTags(ids: number[]) {
        this.tagService
            .getTags(ids, this.sortedAlph)
            .pipe(takeUntil(this.destroy))
            .subscribe((tags) => this.initSelection(this.sortedAlph ? this.sortTagsAlph(tags) : tags));
    }
    inputFocused(trg: MatAutocompleteTrigger) {
        setTimeout(() => {
            trg.closePanel();
        });
    }
    inputkeyDown(trg: MatAutocompleteTrigger, value: string) {
        if (value.length <= 0) {
            setTimeout(() => {
                trg.closePanel();
            });
        }
    }
    protected initSelection(value: ResourceTag[]) {
        this.selection = value;
        this.afterInit.emit();
        this.resetFormControl();
        this.cdRef.markForCheck();
    }

    protected sortTagsAlph(tags: ResourceTag[]): ResourceTag[] {
        const sortedTags = tags.sort((a, b) => {
            const tagA = a.name.toLowerCase();
            const tagB = b.name.toLowerCase();

            if (tagA < tagB) {
                return -1;
            }
            if (tagA > tagB) {
                return 1;
            }
            return 0;
        });
        return sortedTags;
    }

    private resetFormControl() {
        const control = this.injector.get(NgControl)?.control;
        if (control instanceof FormControl) {
            FormHelper.resetForm(control);
        }
    }
}
