<app-table-filter [inGlobalFilter]="true" color="accent" #filter
    ><app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search
></app-table-filter>
<div class="cases-users-log">
    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table #table [dataSource]="dataSource" matSortDirection="desc" matSortActive="time" matSort>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Name</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <span *ngIf="isMobile" class="mobile-label" i18n>Name</span>
                {{ group.name }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="user_count">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Users number</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <span *ngIf="isMobile" class="mobile-label" i18n>Users number</span>
                {{ group.user_count }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="admin_name">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Admin</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <span *ngIf="isMobile" class="mobile-label" i18n>Admin</span>
                {{ group.admins_names.join(', ') }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Tools</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let group">
                <button
                    class="tools-btn"
                    mat-icon-button
                    matTooltip="Email group"
                    (click)="$event.stopPropagation(); sendEmail(group)"
                    i18n-matTooltip="@@emailgroup"
                >
                    <mat-icon>email</mat-icon>
                </button>
                <button
                    class="tools-btn"
                    mat-icon-button
                    matTooltip="Note group"
                    (click)="$event.stopPropagation(); noteGroup(group)"
                    i18n-matTooltip="@@noteGroup"
                >
                    <mat-icon class="material-icons-outlined">note_alt</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row (click)="$event.stopPropagation()" *matRowDef="let case; columns: columns"></mat-row>
    </mat-table>
</div>
