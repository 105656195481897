import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../token/auth.service';
import { Capabilities } from './capabilities';

@Injectable({ providedIn: 'root' })
export class CapabilityService {
    /**
     * Constructor for CapabilityService
     */
    constructor(protected token: AuthService) {}

    /**
     * Returns a observable that updates with the token.
     * Each token update only notify Observable when Capability changed;
     * No need to notify subscribers with each token update even if no change happend.
     * @returns boolean
     */
    public check(name: string, value: number): Observable<boolean> {
        const type = Capabilities.getType(name);
        if (type) {
            let oldCapability: boolean;
            return this.token.getClaim(type.getName()).pipe(
                map(capability => (capability & value) === value),
                filter(capability => {
                    // only notify Observable when Capability changed;
                    const result = oldCapability === undefined || capability !== oldCapability;
                    oldCapability = capability;
                    return result;
                })
            );
        }
        return of(false);
    }
}
