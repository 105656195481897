<div class="tabs-log-wrapper" fxLayout="row" fxFlex="grow">
    <mat-tab-group fxFlex="0 0 100%">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">space_dashboard</mat-icon>
                <span i18n>Overall</span>
            </ng-template>
            <app-shared-action-log-overall [user]="user"></app-shared-action-log-overall>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon
                    fxFlex="0 0 auto"
                    fontSet="klickdata"
                    fontIcon="kd-icon-task"
                    class="course-plan-icon side-nave-icon no-shadow kd-icon-logs-user"
                ></mat-icon>
                <span i18n>Courses</span>
            </ng-template>
            <ng-template matTabContent>
                <app-shared-log-users-tabs-resources
                    [user]="user"
                    [customer]="customer"
                    [scope]="AppScope.COURSE"
                    [isMobile]="isMobile"
                ></app-shared-log-users-tabs-resources>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon
                    fxFlex="0 0 auto"
                    fontSet="klickdata"
                    fontIcon="kd-icon-test"
                    class="course-plan-icon side-nave-icon no-shadow kd-icon-logs-user"
                ></mat-icon>
                <span i18n>Tests</span>
            </ng-template>
            <ng-template matTabContent>
                <app-shared-log-users-tabs-resources
                    [user]="user"
                    [customer]="customer"
                    [scope]="AppScope.TEST"
                    [isMobile]="isMobile"
                ></app-shared-log-users-tabs-resources>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon
                    fxFlex="0 0 auto"
                    fontSet="klickdata"
                    fontIcon="kd-icon-document"
                    class="course-plan-icon side-nave-icon no-shadow kd-icon-logs-user"
                ></mat-icon>
                <span i18n>Materials</span>
            </ng-template>
            <ng-template matTabContent>
                <app-shared-log-users-tabs-resources
                    [user]="user"
                    [isMobile]="isMobile"
                    [customer]="customer"
                    [scope]="AppScope.MATERIAL"
                ></app-shared-log-users-tabs-resources>
            </ng-template>
        </mat-tab>
        <mat-tab *ngIf="user.cases_count || user.author_cases_count">
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">school</mat-icon>
                <span i18n>Cases</span>
            </ng-template>
            <ng-template matTabContent>
                <app-shared-log-users-tabs-cases
                    [customer]="customer"
                    [user]="user"
                    [isMobile]="isMobile"
                ></app-shared-log-users-tabs-cases>
            </ng-template>
        </mat-tab>
        <!-- <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">speaker_notes</mat-icon>
                <span i18n>Messages</span>
            </ng-template>
            <app-log-users-messages [user]="user"></app-log-users-messages>
        </mat-tab> -->
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="example-tab-icon">groups</mat-icon>
                <span i18n>Groups</span>
            </ng-template>
            <ng-template matTabContent>
                <app-shared-log-users-tabs-groups
                    [user]="user"
                    [customer]="customer"
                    [isMobile]="isMobile"
                ></app-shared-log-users-tabs-groups>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
