import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuSideCourseItemDoneComponent } from '../menu-side-course-item-done/menu-side-course-item-done.component';

@Component({
    selector: 'app-menu-side-course-item-done-all',
    templateUrl: './menu-side-course-item-done-all.component.html',
    styleUrls: ['./menu-side-course-item-done-all.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideCourseItemDoneAllComponent extends MenuSideCourseItemDoneComponent implements OnInit {
    public userIsDone: boolean;
    ngOnInit(): void {}
}
