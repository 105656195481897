<div resizeObserver (resize)="onResize($event.contentRect?.width)">
    <app-resources-sub-grid-base
        [tableFilterItems]="tableFilterItems"
        [showOccasion]="showOccasion"
        [resBlockWidth]="resBlockWidth"
        [hasThumbnailHoverEffect]="hasThumbnailHoverEffect"
        [label]="label"
        [pageEvent]="pageEvent"
        (onEmptyResources)="onEmptyResources.emit($event)"
        (onPaginatorPageChange)="onPaginatorPageChange.emit($event)"
    ></app-resources-sub-grid-base>
</div>
