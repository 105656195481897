<ng-container *ngIf="!loading; else loadingTemp">
    <div fxFlex="0 0 100%" class="selected-wrapper" fxLayout="row wrap">
        <app-image-view
            fxFlex="0 1 20%"
            *ngIf="selected.media_id"
            [media_id]="selected.media_id"
            [proportional]="true"
        ></app-image-view>
        <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="start center">
                <h2 class="title-header-res" fxFlex="1 1 auto">{{ selected.title }}</h2>
            </div>
        </div>

        <div
            *ngIf="selected.description"
            fxFlex="0 1 calc(80% - 2em)"
            [innerHtml]="selected.description | innerHtml : 'tool_desc'"
        ></div>

        <div
            class="mt-1 mb-1"
            *ngIf="selected.tags_attached"
            fxFlex="0 1 100%"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
        >
            <div class="pre"><span i18n="@@tags">Tags </span></div>
            <mat-chip-list *ngIf="resourceTags | async; let tags; else: loadingTemp">
                <mat-chip class="tags" (click)="showTagResources(tag)" *ngFor="let tag of tags"
                    >{{ tag.name }}
                    <app-language-selector
                        class="flag tag-chip-lang-select"
                        fxFlex="0 0 auto"
                        [language_id]="tag.language_id"
                    >
                    </app-language-selector
                ></mat-chip>
            </mat-chip-list>
        </div>
    </div>
</ng-container>

<ng-template #loadingTemp>
    <div fxLayout="row" fxLayoutAlign="left" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
