import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteActivatedEvent } from '@angular/material/autocomplete';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { ResourceService } from '@klickdata/core/resource';
import { Educator } from '@klickdata/core/resource/src/resource.model';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ResourceStaffRoles } from './../../../core/resource/src/types.enum';

@Component({
    selector: 'app-resource-publisher-handler',
    templateUrl: './resource-publisher-handler.component.html',
    styleUrls: ['./resource-publisher-handler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ResourcePublisherHandlerComponent),
            multi: true,
        },
    ],
})
export class ResourcePublisherHandlerComponent implements OnInit, ControlValueAccessor, AfterViewInit, OnDestroy {
    public publisherControl = new FormControl('');
    public publishers$: Observable<Educator[]>;
    public publisherExist = true;
    private destroy = new Subject<boolean>();
    public publisher: Educator;
    constructor(
        protected resourceService: ResourceService,
        protected cdr: ChangeDetectorRef,
        protected messageService: MessageService
    ) {}

    ngOnInit(): void {}
    ngAfterViewInit(): void {
        this.publishers$ = this.publisherControl.valueChanges.pipe(
            filter((query) => !!query && typeof query === 'string'),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((query) =>
                this.resourceService.getResourceEducators({ query: query, role: ResourceStaffRoles.PUBLISHER })
            ),
            tap((pulishers) => {
                this.publisherExist = this.publisher && pulishers.length > 0;
            }),
            takeUntil(this.destroy)
        );
    }
    public displayName(publisher: Educator): string {
        return publisher ? publisher.name : '';
    }
    public addNewEdu() {
        this.resourceService
            .createNewResEducator({ name: this.publisherControl.value, role: ResourceStaffRoles.PUBLISHER })
            .pipe(takeUntil(this.destroy))
            .subscribe((publisher) => {
                this.messageService.openMessage(MessageSavedComponent, $localize`Publisher created successfully`);
                this.publisher = publisher;
                this.cdr.markForCheck();
                this.notify();
            });
    }
    writeValue(publisherId: any): void {
        if (publisherId) {
            this.resourceService
                .getResourceEducators({ ids: [publisherId] })
                .pipe(takeUntil(this.destroy))
                .subscribe((publishers) => {
                    this.publisher = publishers[0];
                    this.cdr.markForCheck();
                });
        } else {
            this.resourceService
                .getDefaultEducatorsByRole(ResourceStaffRoles.PUBLISHER)
                .pipe(takeUntil(this.destroy))
                .subscribe((publishers) => {
                    this.publisher = publishers[0];
                    this.cdr.markForCheck();
                    this.notify();
                });
        }
    }
    optionActivated(event: MatAutocompleteActivatedEvent) {
        this.publisher = event.option.value;
        this.cdr.markForCheck();
        this.notify();
    }
    private notify() {
        this.propagateChange(this.publisher.id);
    }
    propagateChange = (_: any) => {};

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
