<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon class="{{ navData.data.iconClass }}">{{ navData.data.icon }}</mat-icon>
        <div class="{{ navData.data.titleClass }}">{{ navData.data.title }}</div>
    </div>
    <div class="desc">{{ navData.data.contentBody }}</div>
    <div
        *ngIf="navData.data.secContentBody"
        [innerHTML]="navData.data.secContentBody | innerHtml : 'tool_desc'"
        class="desc {{ navData.data.secContentClass }}"
    ></div>
    <div
        *ngIf="!navData.data.actions"
        class="action"
        fxLayout="row-reverse"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
    >
        <button
            *ngIf="navData.data.positiveBtn"
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="onClose.emit({ action: SideNaveActionsTypes.POSITIVE })"
            mat-raised-button
        >
            {{ navData.data.positiveBtn }}
        </button>
        <button
            *ngIf="navData.data.negativeBtn"
            fxFlex="0 0 auto"
            class="mobile-button negative"
            (click)="onClose.emit()"
            mat-button
        >
            {{ navData.data.negativeBtn }}
        </button>
        <button
            *ngIf="navData.data.customBtn"
            fxFlex="0 0 auto"
            class="mobile-button custom"
            (click)="onClose.emit({ action: SideNaveActionsTypes.CUSTOM })"
            mat-button
        >
            {{ navData.data.customBtn }}
        </button>
    </div>
    <div
        *ngIf="navData.data.actions && navData.data.actions?.length > 0"
        class="action"
        fxLayout="row-reverse"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
    >
        <button
            *ngFor="let action of navData.data.actions"
            fxFlex="0 0 auto"
            class="mobile-button {{ action.class }}"
            (click)="onClose.emit({ data: action.value })"
            mat-button
        >
            {{ action.label }}
        </button>
    </div>
</div>
