<div *ngFor="let alternative of alternatives | async" fxLayoutAlign="start center">
    <mat-icon *ngIf="question.question_type_value === QuestionTypes.RADIO">radio_button_checked</mat-icon>
    <mat-icon *ngIf="question.question_type_value === QuestionTypes.CHECKBOX">check_box</mat-icon>

    <div fxLayout="column">
        <div>
            {{ alternative.alternative }} {{ alternative.title }}

            <small class="alternative-metadata" *ngIf="alternative.correct || showPoints">
                <ng-container *ngIf="alternative.correct && showPoints">
                    (<ng-container *ngIf="alternative.correct"
                        ><ng-container i18n="@@correctAnswer">correct answer</ng-container>, </ng-container
                    ><ng-container *ngIf="showPoints"
                        >{{ alternative.points }}
                        <ng-container i18n="@@pointPlural"
                            >{alternative.points, plural, =1 {point} other {points}}</ng-container
                        >)</ng-container
                    >
                </ng-container>

                <ng-container *ngIf="!alternative.correct && showPoints">
                    ({{ alternative.points }}
                    <ng-container i18n="@@pointPlural"
                        >{alternative.points, plural, =1 {point} other {points}}</ng-container
                    >)
                </ng-container>

                <ng-container *ngIf="alternative.correct && !showPoints">
                    (<ng-container i18n="@@correctAnswer">correct answer</ng-container>)
                </ng-container>
            </small>
        </div>

        <figure *ngIf="alternative.media$ | async; let loadedMedia">
            <img src="{{ loadedMedia.url }}" alt="" />
        </figure>
    </div>
</div>

<ng-container *ngIf="question.question_type_value === QuestionTypes.RADIO && question.other_alternative">
    <div fxLayoutAlign="start center">
        <mat-icon>radio_button_checked</mat-icon>

        <div fxLayout="column">
            <ng-container i18n="@@otherAlternative">Other</ng-container>
        </div>
    </div>

    <mat-form-field fxFlex="0 0 100%">
        <input matInput disabled />
    </mat-form-field>
</ng-container>
