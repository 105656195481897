import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { Resource } from '@klickdata/core/resource';

@Component({
  selector: 'app-mobile-listing-item',
  templateUrl: './mobile-listing-item.component.html',
  styleUrls: ['./mobile-listing-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MobileListingItemComponent implements OnInit {
@Input() resources: Resource[];
@Output() onBrowse: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onPlay: EventEmitter<Resource> = new EventEmitter<Resource>();
  constructor() { }

  ngOnInit(): void {
  }
  public getPlayBtnTxt(resource: Resource): string {
    return resource.opportunity_id ? $localize`Continue` : $localize`Play`;
}

}
