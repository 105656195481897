import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { GroupStatistic } from '@klickdata/core/group/src/statistics/group-statistic.model';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { GroupData } from '../group.model';

@Injectable()
export class GroupStatisticService {
    protected resourceUrl: string;
    protected customer_id: Observable<number>;

    constructor(
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected auth: AuthService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}groups-statistics`;
        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map(customer => customer.id)
        );
    }

    fill(data: GroupData): GroupStatistic {
        return new GroupStatistic(data);
    }
}
