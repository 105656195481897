import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Host } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { MaterialItemComposerDirective } from '../material-item-composer-directive';
import { MaterialsManagerCoreComponent } from '../materials-manager-core.component';

@Component({
    selector: 'app-materials-manager-text',
    templateUrl: './materials-manager-text.component.html',
    styleUrls: ['./materials-manager-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class MaterialsManagerTextComponent extends MaterialItemComposerDirective implements AfterViewInit {
    constructor(
        @Host() protected readonly host: MaterialsManagerCoreComponent,
        protected cdRef: ChangeDetectorRef,
        protected parentFormDirective: FormGroupDirective
    ) {
        super(host, cdRef, parentFormDirective);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.focusEditor();
        }, 2000);
    }
    focusEditor() {
        const editors = document
            .getElementsByClassName('basic-tinymce-editor-ele')
            [this.index]?.getElementsByTagName('iframe');

        if (editors !== undefined && editors.length > 0) {
            const editor = editors[0];
            const body = editor.contentDocument.getElementsByTagName('body');
            if (body !== undefined && body.length > 0) {
                body[0].focus();
            }
        }
    }
}
