import { Injectable } from '@angular/core';
import { GloalSearchService, GlobalSearchData } from '@klickdata/core/global-search';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { Resource, ResourceData, ResourceService } from '@klickdata/core/resource';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SearchRelatedTagListingService extends GloalSearchService implements TableHttpService<GlobalSearchData> {
    public fetchData(source: TableSource<any>): Observable<ResponseData<GlobalSearchData[]>> {
        const req = this.builder.get<GlobalSearchData[]>(this.searchUrl);
        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<GlobalSearchData[]>) => {
                return {
                    data: res.data,
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }
}
