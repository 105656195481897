import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageFormErrorComponent, MessageService } from '@klickdata/core/message';
import {
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceTypes,
    AppScope,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemData, ResourceItemService } from '@klickdata/core/resource-item';
import * as moment from 'moment';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-add-material-text',
    templateUrl: './add-material-text.component.html',
    styleUrls: ['./add-material-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMaterialTextComponent implements OnChanges, OnInit, OnDestroy, CanComponentDeactivate {
    public materialTextForm: FormGroup;
    AppScope = AppScope;
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saved: EventEmitter<{ res: Resource; isParentSubmission?: boolean }> = new EventEmitter<{
        res: Resource;
        isParentSubmission?: boolean;
    }>();
    @Input() resource: Resource;
    ResourceTypes = ResourceTypes;
    @Input() publishOnly: boolean;
    @Input() active = true;
    @Input() publishLabel = $localize`Publish`;
    public isAdmin$: Observable<boolean>;
    private resourceItem: ResourceItem;
    private isParentSubmission: boolean;

    constructor(
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected itemService: ResourceItemService,
        protected auth: AuthService,
        protected messageService: MessageService,
        protected changeRef: ChangeDetectorRef,
        public router: Router,
        protected categoryService: ResourceCategoryService
    ) {
        this.buildForm();
    }

    ngOnChanges() {
        if (this.resource) {
            this.materialTextForm.patchValue({
                id: this.resource.id,
                priority: this.resource.priority,
                title: this.resource.title,
                media_id: this.resource.media_id,
                description: this.resource.description,
                // category_ids: this.resource.category_ids,
                language_id: this.resource.language_id,
            });
            FormHelper.resetForm(this.materialTextForm);
            this.update();
        }
    }

    public buildForm() {
        this.materialTextForm = this.fb.group({
            id: [],
            priority: [],
            media_id: [],
            title: ['', Validators.required],
            material_text: ['', Validators.required],
            tag_ids: [[]],
            category_ids: [[], Validators.required],
            language_id: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.isAdmin$ = this.auth.getUser().pipe(
            first(),
            map((user) => user.isAdmin())
        );
        this.setDefaultCategory();
    }

    private setDefaultCategory() {
        this.categoryService
            .getSuggestedCategory(ResourceTypes.MATERIAL)
            .pipe(takeUntil(this.destroy))
            .subscribe((category) => {
                if (!this.materialTextForm.controls.category_ids.value?.length) {
                    this.materialTextForm.patchValue({
                        category_ids: [category.id],
                    });
                    FormHelper.resetForm(<FormControl>this.materialTextForm.controls.category_ids);
                }
            });
    }

    public update(): void {
        this.resource.item$?.pipe(takeUntil(this.destroy)).subscribe((item) => {
            this.resourceItem = item;
            this.materialTextForm.patchValue({
                material_text: item.name,
            });
            FormHelper.resetForm(this.materialTextForm);
            this.changeRef.markForCheck();
        });
    }
    public submitMaterial() {
        this.isParentSubmission = true;
        this.submitTextMaterial();
    }
    public submitTextMaterial(form?: FormGroupDirective, publish?: boolean) {
        if (!this.materialTextForm.valid) {
            FormHelper.markForm(this.materialTextForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            this.onError.emit(true);
            return;
        }

        this.prepareSubmit(publish).subscribe((resource) => {
            form?.resetForm();
            this.materialTextForm.reset();
            FormHelper.resetForm(this.materialTextForm);
            this.saving.emit(false);
            this.saved.emit({ res: resource, isParentSubmission: this.isParentSubmission });
            this.isParentSubmission = false;
            if (this.publishOnly) {
                this.setDefaultCategory();
            }
        });
    }

    public prepareSubmit(publish: boolean): Observable<Resource> {
        this.saving.emit(true);
        return this.prepareData(publish).pipe(
            switchMap((data) =>
                !this.resource?.id ? this.resourceService.store(data) : this.resourceService.update(data)
            ),
            takeUntil(this.destroy)
        );
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    protected prepareMaterialTextItem(): ResourceItemData[] {
        if (this.materialTextForm.value.material_text !== '') {
            return this.resourceItem && this.resourceItem.id
                ? [
                      {
                          id: this.resourceItem.id,
                          name: this.materialTextForm.value.material_text,
                      },
                  ]
                : [
                      {
                          name: this.materialTextForm.value.material_text,
                          item_type_value: 'text_material',
                      },
                  ];
        } else {
            if (this.resource && this.resourceItem?.id) {
                return [{ id: this.resourceItem.id, deleted: true }];
            }
        }
    }

    protected prepareData(publish: boolean): Observable<ResourceData> {
        const data = new Resource(this.materialTextForm.value).getData();
        if (this.materialTextForm.get('material_text').dirty) {
            data.resource_items = this.prepareMaterialTextItem();
        }
        if (publish) {
            data.published = moment().format('YYYY-MM-DD HH:mm:ss');
        }

        if (!this.resource?.id) {
            return combineLatest([this.auth.getCustomer(), this.auth.getUser()]).pipe(
                first(),
                map(([customer, user]) => {
                    data.author_id = user.id;
                    data.customer_id = customer.id;
                    data.type_id = ResourceTypes.TextMaterial;
                    return data;
                })
            );
        } else {
            return of(data);
        }
    }

    public canDeactivate(): boolean {
        return this.materialTextForm.pristine;
    }
}
