<div class="res-listing-wrapper mobile-reso-listing" fxLayout="column" fxFlex="0 0 100%">
    <div *ngIf="this.selection.selected.length > 0" class="header-replace">
        <div class="hover-wrapper top" fxLayoutAlign="space-around center">
            <!-- <button
                *ngIf="
                    actionHeaderBtns.includes('download') &&
                    sectionType !== SectionType.REGULAR_SECTION &&
                    activitySectionType !== ActivitySectionType.COMPLETED_SECTIONS
                "
                class="hover-tools-btn"
                mat-raised-button
                matTooltip="Download PDF"
                i18n-matTooltip="@@downloadPDF"
                (click)="$event.stopPropagation(); download()"
            >
                <mat-icon>save_alt</mat-icon>
                <span class="btn-label" i18n="@@download">Download</span>
            </button> -->
            <button
                *ngIf="activitySectionType === ActivitySectionType.COMPLETED_SECTIONS"
                class="hover-tools-btn"
                mat-raised-button
                matTooltip="Export Course Overview Report"
                i18n-matTooltip="@@ExportCourseOverviewReport"
                (click)="$event.stopPropagation(); onFinalGrades.emit(selection.selected)"
            >
                <mat-icon>fact_check</mat-icon>
                <span class="btn-label" i18n="@@courseOverviewReport">Course overview report</span>
            </button>
            <button
                *ngIf="actionHeaderBtns.includes('exportcsv') && sectionType !== SectionType.REGULAR_SECTION"
                class="hover-tools-btn disableIt"
                mat-raised-button
                matTooltip="Export CSV"
                i18n-matTooltip="@@exportCSV"
            >
                <mat-icon>get_app</mat-icon>
                <span class="btn-label" i18n="@@exportcsv">Export CSV</span>
            </button>
            <button
                *ngIf="actionHeaderBtns.includes('hide')"
                class="hover-tools-btn"
                mat-raised-button
                matTooltip="Hide all"
                i18n-matTooltip="@@hideAll"
                (click)="onHide.emit({ resources: selection.selected, status: true }); selection.clear()"
            >
                <mat-icon>visibility_off</mat-icon>
                <span class="btn-label" i18n="@@hideAll">Hide all</span>
            </button>
        </div>
    </div>

    <mat-table
        [matSortDisableClear]="true"
        (matSortChange)="sortData($event)"
        matSort
        #table
        [dataSource]="dataSource"
        [class.mobileView]="isMobile"
        [class.showHeader]="this.selection.selected.length > 0"
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(resource) : null"
                    [checked]="selection.isSelected(resource)"
                    [aria-label]="checkboxLabel(resource)"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="columns">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@image">Image</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <span i18n="@@image" class="mobile-label"> Image </span>
                <app-image-view
                    [media_id]="resource.media$"
                    [proportional]="!isMobile"
                    [paddingBottom]="'58px'"
                    [smallSpinner]="true"
                ></app-image-view>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell
                [class.title-limit-width]="isECourseSection"
                mat-sort-header
                *matHeaderCellDef
                i18n="@@title"
            >
                Title
            </mat-header-cell>
            <mat-cell [class.title-limit-width]="isECourseSection" *matCellDef="let resource" class="pr-0-m">
                <ng-container *ngIf="!isMobile">
                    <span i18n="@@title" class="mobile-label"> Title </span>
                    <span class="original">{{ resource.title }}</span>
                    <div class="hover">
                        <div class="hover-wrapper" fxLayoutAlign="space-between center">
                            <button
                                *ngIf="actionCellBtns.includes('view')"
                                class="hover-tools-btn view"
                                mat-raised-button
                                matTooltip="View"
                                (click)="$event.stopPropagation(); !resource.loading && onBrowse.emit(resource)"
                                i18n-matTooltip="@@view"
                            >
                                <mat-icon>visibility</mat-icon>
                            </button>
                            <button
                                *ngIf="actionCellBtns.includes('play')"
                                class="hover-tools-btn view"
                                mat-raised-button
                                [matTooltip]="getPlayBtnTxt(resource)"
                                (click)="$event.stopPropagation(); onPlay.emit(resource)"
                                i18n-matTooltip
                            >
                                <mat-icon>play_circle</mat-icon>
                            </button>
                            <ng-container *ngIf="resource.last_publish">
                                <button
                                    class="hover-tools-btn"
                                    *ngIf="!(shareLoading | async); else shareLoadTemp"
                                    mat-icon-button
                                    matTooltip="Share"
                                    i18n-matTooltip="@@share"
                                    (click)="
                                        $event.stopPropagation(); shareService.openShareDialog(resource, shareLoading)
                                    "
                                >
                                    <mat-icon>share</mat-icon>
                                </button>
                            </ng-container>
                            <!-- Current possiblle download for courses only -->
                            <button
                                *ngIf="resource.downloads"
                                class="hover-tools-btn"
                                mat-raised-button
                                matTooltip="Download PDF"
                                i18n-matTooltip="@@downloadPDF"
                                (click)="$event.stopPropagation(); download(resource)"
                            >
                                <mat-icon>save_alt</mat-icon>
                            </button>
                            <button
                                *ngIf="actionCellBtns.includes('edit')"
                                class="hover-tools-btn view admin-green"
                                mat-raised-button
                                matTooltip="Edit"
                                (click)="$event.stopPropagation(); onPlay.emit(resource)"
                                i18n-matTooltip="@@edit"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>

                            <button
                                *ngIf="actionCellBtns.includes('hide')"
                                class="hover-tools-btn view admin-green"
                                mat-raised-button
                                matTooltip="Hide"
                                (click)="
                                    $event.stopPropagation();
                                    onHide.emit({ resources: [resource], status: !resource.excluded_from_section })
                                "
                                i18n-matTooltip="@@hide"
                            >
                                <mat-icon>{{
                                    resource.excluded_from_section ? 'visibility_off' : 'visibility'
                                }}</mat-icon>
                            </button>
                            <button
                                *ngIf="
                                    (ResourceTypes.parentType(resource.type_id) === ResourceTypes.COURSE ||
                                        resource.scope_id === AppScope.COURSE) &&
                                    authUser?.isAdmin()
                                "
                                class="hover-tools-btn view admin-green"
                                mat-raised-button
                                matTooltip="Assign"
                                (click)="$event.stopPropagation(); onAssign.emit(resource)"
                                i18n-matTooltip="@@assign"
                            >
                                <mat-icon>assignment_ind</mat-icon>
                            </button>

                            <button
                                *ngIf="resource.occasionStatus === 'cancelled' && actionCellBtns.includes('delete')"
                                class="hover-tools-btn view admin-green"
                                mat-raised-button
                                matTooltip="Delete"
                                (click)="$event.stopPropagation(); deleteCourseFromList(resource)"
                                i18n-matTooltip="@@delete"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </ng-container>
                <div class="mobile-row-title" *ngIf="isMobile">
                    <div class="mobile-img-wrapper">
                        <app-image-view
                            (click)="expandedRow = expandedRow === resource ? null : resource"
                            [media_id]="resource.media$"
                            [proportional]="true"
                            [smallSpinner]="true"
                        ></app-image-view>
                    </div>
                    <div class="info-main-wrapper">
                        <span class="item-title" (click)="expandedRow = expandedRow === resource ? null : resource">{{
                            resource.title
                        }}</span>
                        <div class="nu-items">
                            <span (click)="expandedRow = expandedRow === resource ? null : resource">
                                {{ resource.number_of_items }}
                            </span>
                        </div>
                    </div>
                    <button
                        class="expand-btn"
                        mat-icon-button
                        (click)="expandedRow = expandedRow === resource ? null : resource"
                    >
                        <mat-icon>
                            {{ expandedRow === resource ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="occasionStatus">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@status">Status</span>
                </div>
            </mat-header-cell>
            <mat-cell
                *matCellDef="let resource"
                class="pr-0-m"
                [ngStyle]="{ color: (resource | resourceStatus : true)?.color }"
            >
                <span i18n="@@status" class="mobile-label"> Status </span>
                <mat-icon>
                    {{ (resource | resourceStatus : true)?.icon }}
                </mat-icon>
                &nbsp;
                <span>{{ (resource | resourceStatus : true)?.status }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="examination">
            <mat-header-cell *matHeaderCellDef i18n="@@certificate"> Certificate </mat-header-cell>
            <mat-cell
                *matCellDef="let resource"
                class="pr-0-m"
                [ngStyle]="{ color: (resource | resourceCertificate).color }"
                (click)="download(resource)"
            >
                <span i18n="@@certificate" class="mobile-label"> Certificate </span>
                <mat-icon [ngClass]="{ 'disabled-icon': !resource.downloads }" class="cert-icon">
                    {{ (resource | resourceCertificate).icon }}
                </mat-icon>
                &nbsp;
                <span>
                    {{ (resource | resourceCertificate).certificate }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@created"> Created </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@created" class="mobile-label"> Created </span>
                <span> {{ resource.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="result">
            <mat-header-cell *matHeaderCellDef i18n="@@result"> Results </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@result" class="mobile-label"> Results </span>
                <span *ngIf="resource.done">
                    {{ resource.done }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="percent">
            <mat-header-cell *matHeaderCellDef i18n="@@percent"> Percent </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@percent" class="mobile-label"> Percent </span>
                <span *ngIf="resource.done"> 100% </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef i18n="@@price"> Price </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@price">Price</span>
                <span *ngIf="resource.price !== 0 && resource.price" class="row-content"
                    >{{ resource.price }}&nbsp;{{ resource.currency }}</span
                >
                <span *ngIf="resource.price === 0 || !resource.price" class="row-content" i18n="@@free">Free</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="done_items">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@activityProgress"> Progress </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@activityProgress">Progress</span>
                <span class="row-content">
                    <div class="progress-wrapper">
                        <span class="progress" [ngStyle]="{ width: '50%' }"></span>
                        <span class="remain">{{ resource.done_items ? resource.done_items : '0' }}</span>
                        <span class="total">{{ resource.number_of_items }}</span>
                    </div>
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="question_number">
            <mat-header-cell *matHeaderCellDef i18n="@@activityProgress"> Progress </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@activityProgress">Progress</span>
                <span class="row-content">
                    <div class="progress-wrapper">
                        <span class="progress" [ngStyle]="{ width: '50%' }"></span>
                        <span class="remain">{{ resource.done_items ? resource.done_items : '0' }}</span>
                        <span class="total">{{ resource.number_of_items }}</span>
                    </div>
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="number_of_items">
            <mat-header-cell *matHeaderCellDef i18n="@@activityProgress"> Progress </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@activityProgress">Progress</span>
                <span class="row-content">
                    <div class="progress-wrapper">
                        <span class="progress" [ngStyle]="{ width: '50%' }"></span>
                        <span class="remain">{{ resource.done_items ? resource.done_items : '0' }}</span>
                        <span class="total">{{ resource.number_of_items }}</span>
                    </div>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="recommended_by">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@recommendedBy"> Recommended by </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@recommendedBy">Recommended by</span>
                <span class="row-content recommended">
                    <ng-container *ngIf="resource.recommended_by && resource.recommended_by.length > 0">
                        <div class="recommend-wrapper" *ngFor="let recommendee of resource.recommended_by">
                            <div class="user-img-wrapper">
                                <div [matTooltip]="recommendee.name" class="user-img">
                                    {{ recommendee.name | nameSign }}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="opportunity_assign_date">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@assignedDate"> Assigned date </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@assignedDate">Assigned date</span>
                <span>
                    {{ resource.opportunity_assign_date | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
                <!-- <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span> -->
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="assigned_by">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@assignedBy"> Assigned by </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@assignedBy">Assigned by</span>
                <ng-container *ngIf="resource.assigned_by && resource.assigned_by.length > 0">
                    <div class="recommend-wrapper" *ngFor="let assignee of resource.assigned_by">
                        <div class="user-img-wrapper">
                            <div [matTooltip]="assignee.name" class="user-img">
                                {{ assignee.name | nameSign }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="done">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@completed"> Completed </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@completed">Completed</span>
                <span *ngIf="resource.done">
                    {{ resource.done | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
                <!-- <span *ngIf="!resource.done" i18n="@@notYet">Not yet</span> -->
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="material_type">
            <mat-header-cell *matHeaderCellDef i18n="@@type"> Type </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span class="mobile-label" i18n="@@type"> Type </span>
                <span>
                    {{ ResourceTypes.materialType(resource.type_id) }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="certifier">
            <mat-header-cell *matHeaderCellDef i18n="@@certifier"> Certifier </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@certifier" class="mobile-label"> Certifier </span>
                <span>
                    {{ resource.author_name || $any(resource.author$ | async)?.name }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_by">
            <mat-header-cell *matHeaderCellDef i18n="@@createdBy"> Created by </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@createdBy">Created by</span>
                <span>
                    {{ resource.author_name || $any(resource.author$ | async)?.name }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="producedBy">
            <mat-header-cell *matHeaderCellDef i18n="@@pulisher"> Publisher </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@publisher">Publisher</span>
                <span> Klick Data </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="instructor">
            <mat-header-cell *matHeaderCellDef i18n="@@instructor"> Instructor </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@instructor">Instructor</span>
                <span>
                    {{ resource.author_name }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="priority">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@priority"> Priority </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@priority" class="mobile-label"> Priority </span>
                <span>
                    {{ resource.priority }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="language_id">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@lang">Lang</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@lang" class="mobile-label"> Lang </span>
                <ng-container>
                    <app-language-selector
                        [language_id]="resource.language_id"
                        class="icon-resource-builder"
                        fxFlex="0 0 auto"
                    ></app-language-selector>
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="summary">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.bullets" *matCellDef="let resource">
                {{ resource.bullets }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="opportunity_end_date">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@deadline"> Deadline </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.opportunity_end_date" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@deadline">Deadline</span>
                <span>
                    {{ resource.opportunity_end_date | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="end_date">
            <mat-header-cell *matHeaderCellDef i18n="@@deadline"> Deadline </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.opportunity_end_date" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@deadline">Deadline</span>
                <span>
                    {{ resource.opportunity_end_date | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="last_action">
            <mat-header-cell mat-sort-header *matHeaderCellDef i18n="@@lastAction"> Last action </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.last_action" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@lastAction">Last action</span>
                <span>
                    {{ resource.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="stats">
            <mat-header-cell *matHeaderCellDef i18n="@@status"> Status </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.occasionStatus" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@status">Status</span>
                <span>
                    {{ (resource | resourceStatus : true)?.status }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="publishedAt">
            <mat-header-cell *matHeaderCellDef i18n="@@publishedAt"> Published at </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.published" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@publishedAt">Published at</span>
                <span>
                    {{ resource.published | kdDateFormat : 'YYYY-MM-DD' }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="endReward">
            <mat-header-cell *matHeaderCellDef i18n="@@endReward"> End reward </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@endReward">End reward</span>
                <span>
                    <span
                        *ngIf="
                            (resource.examination?.type !== 'Certification' &&
                                resource.examination?.type !== 'Diploma') ||
                            !resource.examination
                        "
                        i18n="@@participation"
                        >Participation</span
                    >
                    <span *ngIf="resource.examination?.type === 'Certification'" i18n="@@certification"
                        >Certification</span
                    >
                    <span *ngIf="resource.examination?.type === 'Diploma'" i18n="@@diploma">Diploma</span>

                    <!-- <span *ngIf="resource.examination && resource.examination?.label">{{
                        resource.examination?.label
                    }}</span>
                    <span *ngIf="!resource.examination" i18n="@@participationCertificate"
                        >Participation Certificate</span
                    > -->
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="duration">
            <mat-header-cell *matHeaderCellDef i18n="@@duration"> Duration </mat-header-cell>
            <mat-cell [class.no-display-cell]="!resource.duration" *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@duration">Duration</span>
                <span>
                    {{ resource?.duration | formatIsoDuration }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tools_play">
            <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <div fxLayoutAlign="start center" class="tools-cell-wrapper">
                    <button
                        *ngIf="type_scope_id"
                        class="tools-btn"
                        color="primary"
                        mat-icon-button
                        matTooltip="Play"
                        (click)="$event.stopPropagation(); onPlay.emit(resource)"
                        i18n-matTooltip="@@play"
                    >
                        <mat-icon>play_circle</mat-icon>
                    </button>
                    <button
                        *ngIf="!type_scope_id"
                        class="tools-btn"
                        color="primary"
                        mat-icon-button
                        matTooltip="Hide"
                        (click)="
                            $event.stopPropagation();
                            onHide.emit({ resources: [resource], status: !resource.excluded_from_section })
                        "
                        i18n-matTooltip="@@hide"
                    >
                        <mat-icon>{{ resource.excluded_from_section ? 'visibility_off' : 'visibility' }}</mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <!-- <span *ngIf="isMobile" class="mobile-label" i18n="@@tools">Tools</span> -->
                <div class="tools-cell-wrapper">
                    <button
                        class="tools-btn"
                        mat-icon-button
                        matTooltip="View"
                        (click)="$event.stopPropagation(); onBrowse.emit(resource)"
                        i18n-matTooltip="@@view"
                    >
                        <mat-icon>visibility</mat-icon>
                    </button>
                    <button
                        class="tools-btn"
                        mat-icon-button
                        matTooltip="Play"
                        (click)="$event.stopPropagation(); onPlay.emit(resource)"
                        i18n-matTooltip="@@view"
                    >
                        <mat-icon>play_circle</mat-icon>
                    </button>
                    <ng-container *ngIf="resource.last_publish">
                        <button
                            class="tools-btn"
                            mat-icon-button
                            matTooltip="View"
                            (click)="$event.stopPropagation(); shareService.openShareDialog(resource, shareLoading)"
                            i18n-matTooltip="@@view"
                        >
                            <mat-icon>share</mat-icon>
                        </button>
                    </ng-container>
                    <button
                        *ngIf="resource.downloads"
                        class="tools-btn"
                        mat-icon-button
                        matTooltip="Download PDF"
                        i18n-matTooltip="@@downloadPDF"
                        (click)="$event.stopPropagation(); download(resource)"
                    >
                        <mat-icon>save_alt</mat-icon>
                    </button>

                    <button
                        *ngIf="
                            (ResourceTypes.parentType(resource.type_id) === ResourceTypes.COURSE ||
                                resource.scope_id === AppScope.COURSE) &&
                            authUser?.isAdmin()
                        "
                        class="tools-btn admin-green"
                        mat-icon-button
                        matTooltip="Assign"
                        i18n-matTooltip="@@assign"
                        (click)="$event.stopPropagation(); onAssign.emit(resource)"
                    >
                        <mat-icon>assignment_ind</mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            [ngClass]="!resource.loading ? 'link' : 'disableIt'"
            (click)="$event.stopPropagation()"
            *matRowDef="let resource; columns: columns"
            [class.res-expanded-row]="expandedRow === resource"
        ></mat-row>
    </mat-table>
</div>

<ng-template #shareLoadTemp>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
