import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
} from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { MobileService } from '@klickdata/core/mobile';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { ChipItem, Filter } from '../filter';
import { FilterBase } from '../filter-base';

@Component({
    selector: 'app-table-chip-filter',
    templateUrl: './chip-filter.component.html',
    styleUrls: ['./chip-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: FilterBase, useExisting: ChipFilterComponent }],
})
export class ChipFilterComponent extends FilterBase implements AfterViewInit, OnChanges, OnDestroy {
    @Input() title: string;
    @Input() multiple = true;
    @Input() source: ChipItem[];
    @Input() ignoreCaching: boolean;
    protected _filter: Filter<string | number>;
    @Output() filterChange: EventEmitter<Filter<string | number>> = new EventEmitter<Filter<string | number>>();
    @Output() filterRemoval: EventEmitter<Filter<string>> = new EventEmitter<Filter<string>>();

    @ViewChildren(MatChip) chips: QueryList<MatChip>;
    protected clickSub: Subscription;
    public isMobile: Observable<boolean>;
    protected _hidden: boolean;
    get hidden(): boolean {
        return this._hidden;
    }

    constructor(protected changeRef: ChangeDetectorRef, protected mobile: MobileService) {
        super();
        this.isMobile = this.mobile.isMobile().pipe(shareReplay());
    }

    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.clickSub = this.chips.changes
            .pipe(
                switchMap((change: QueryList<MatChip>) => {
                    return merge(
                        ...change.map((item) => {
                            return fromEvent(item._elementRef.nativeElement, 'click').pipe(
                                map((ev) => {
                                    this.toggleFilter(item);
                                    return ev;
                                })
                            );
                        })
                    );
                })
            )
            .subscribe();
    }

    public ngOnChanges(change: SimpleChanges): void {
        if (this.source && change?.source) {
            this.updateSelection();
        }
    }

    protected getFilterProperty(): string {
        return this.filter.property;
    }
    protected ignoreCache(): boolean {
        return this.ignoreCaching;
    }
    /**
     * @override
     * @param items
     */
    protected setFilterItems(items: ChipItem[]) {
        this.setChips(items);
        this.filter.items = items.map((item) => item.id);
    }

    protected setFilter(fltr: Filter<string | number>) {
        this.setFilterItems(<ChipItem[]>fltr.chips);
    }

    /**
     * @override
     * @returns
     */
    protected getItemsForCache(): any {
        return this.filter.chips;
    }

    public ngOnDestroy(): void {
        if (this.clickSub) {
            this.clickSub.unsubscribe();
            this.clickSub = null;
        }
    }

    public toggleFilter(chip: MatChip): void {
        chip.toggleSelected();
        if (chip.selected) {
            if (this.multiple) {
                this._filter.items.push(chip.value);
            } else {
                this._filter.items = [chip.value];
            }
        } else {
            this._filter.items.splice(this._filter.items.indexOf(chip.value), 1);
        }
        this.updateFilters();
    }

    protected updateFilters() {
        const items = this.source?.filter((item) => this._filter.items.indexOf(item.id) !== -1);
        this.setChips(items);
        this.filterChange.emit(this._filter);
        this.changeRef.detectChanges();
    }

    protected setChips(items: ChipItem[]) {
        this.filter.chips = items;
    }

    public removeFilter() {
        this.setChips([]);
        this._filter.items = [];
        this.changeRef.detectChanges();
        this.filterRemoval.emit(<Filter<string>>this.filter);
    }

    public clear(chipId?: number): void {
        if (this._filter.items.length !== 0) {
            if (chipId) {
                this.chips.forEach((chip) => {
                    if (chip.value === chipId) {
                        chip.deselect();
                    }
                });
                this._filter.items.splice(
                    this._filter.items.findIndex((item) => item === chipId.toString()),
                    1
                );
            } else {
                this.chips.forEach((chip) => chip.deselect());
                this._filter.items.splice(0, this._filter.items.length);
            }
            this.updateFilters();
        }
    }

    public selectAll(): void {
        let change = false;
        this.chips.forEach((chip) => {
            if (!chip.selected) {
                chip.select();
                this._filter.items.push(chip.value);
                change = true;
            }
        });

        if (change) {
            this.updateFilters();
        }
    }
    /**
     * Detect @ChipFilterSource source changed and update selection.
     * @see ngOnChanges
     */
    protected updateSelection(): void {
        this._filter.items.splice(0, this._filter.items.length);
        this.source.forEach((item) => {
            if (item.selected) {
                this._filter.items.push(item.id);
            }
        });
        this.updateFilters();
    }

    /**
     * Check for item checked state by name.
     * @param name name of item to check it's check select state.
     */
    public isItemSelected(name: string): boolean {
        return !!this.source?.find((item) => item.selected && item.label.toLowerCase === name.toLowerCase);
    }
}
