import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { HttpErrorService, RequestBuilderService, ResponseData } from '@klickdata/core/http';
import { UserService } from '@klickdata/core/user';
import { Observable, map } from 'rxjs';
import { Quote, QuoteData } from './quote.model';

@Injectable({
    providedIn: 'root',
})
export class QuoteService {
    protected resourceUrl: string;

    constructor(
        protected auth: AuthService,
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected error: HttpErrorService,
        protected userService: UserService,
    ) {
        this.resourceUrl = `${config.config.apiUrl}quotes`;
    }

    public getQuote(id: number): Observable<Quote> {
        return this.getQuoteData(id).pipe(map((res) => this.createQuote(res.data)));
    }

    public getQuoteData(id: number): Observable<ResponseData<QuoteData>> {
        return this.builder.get<QuoteData>(`${this.resourceUrl}/${id}`).request();
    }

    public create(post: QuoteData): Observable<Quote> {
        return this.builder
            .post<QuoteData>(this.resourceUrl, post)
            .request()
            .pipe(
                map((res) => {
                    return this.createQuote(res.data);
                })
            );
    }
    public getQuotes(params: { resource?: number; tags?: number[] }): Observable<Quote[]> {
        return this.builder
            .get<QuoteData[]>(this.resourceUrl)
            .putParam(params)
            .request()
            .pipe(map((res) => res.data.map((quo) => this.createQuote(quo))));
    }
    public getQuotesOfQuotee(quoteeId: number): Observable<Quote[]> {
        return this.builder
            .get<QuoteData[]>(this.resourceUrl)
            .param('quotee', quoteeId)
            .request()
            .pipe(map((res) => res.data.map((quo) => this.createQuote(quo))));
    }
    public update(data: QuoteData): Observable<Quote> {
        return this.builder
            .put<QuoteData>(`${this.resourceUrl}/${data.id}`, data)
            .request()
            .pipe(
                map((res) => {
                    return this.createQuote(res.data);
                })
            );
    }

    public prompt(data: QuoteData): Observable<Quote> {
        return this.builder
            .post<QuoteData>(`${this.resourceUrl}/prompt`, data)
            .request()
            .pipe(map((res) => this.createQuote(res.data)));
    }

    public listenToQuote(id: number): Observable<Quote> {
        return this.auth
            .listenPrivate(`quote.${id}`, 'QuoteEvent', this.getQuoteData(id))
            .pipe(map((res) => this.createQuote(res.data)));
    }
    public destroy(quote: Quote): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${quote.id}`)
            .request()
            .pipe(map((res) => res.data));
    }
    protected createQuote(data: QuoteData): Quote {
        const quote = new Quote(data);
        quote.author$ = this.userService.getAuthor(quote.created_by);
        return quote;
    }
}
