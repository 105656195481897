<div fxLayout="row wrap" class="res-item qs-form-build">
    <mat-accordion
        class="resources-list"
        fxFlex="0 1 100%"
        fxFlex.lt-lg="100%"
        fxFlex.lt-md="100%"
        cdkDropList
        [multi]="true"
        #accordion
        (cdkDropListDropped)="drop($event)"
    >
        <mat-expansion-panel
            *ngFor="let item of itemFormArray.controls; let i = index"
            #panel
            class="item no-shadow"
            [class.disabled]="item.disabled"
            cdkDrag
            cdkDragLockAxis="y"
            [expanded]="expanded === i || allExpanded"
            (opened)="onOpen(i)"
            [disabled]="item.disabled"
        >
            <mat-expansion-panel-header [collapsedHeight]="'60px'" [expandedHeight]="'60px'" class="question-header">
                <mat-panel-title fxLayout="row" fxLayoutAlign="start center">
                    <div
                        fxFlex="0 1 auto"
                        class="question-title-index"
                        [ngClass]="{ 'wok-qus': item.get('question')?.value['isWOKQs'] }"
                    >
                        {{ i + 1 }}
                    </div>
                    <div fxFlex="0 1 auto" fxLayout="column" class="item-desc">
                        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
                            <span fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
                                <mat-icon>{{
                                    QuestionType.questionTypeIcon(item.value.question?.question_type_value) ||
                                        QuestionType.questionTypeIcon(items[i]?.question_type_value) ||
                                        'live_help'
                                }}</mat-icon>
                                <span i18n>Question</span>
                            </span>
                        </div>

                        <p *ngIf="!panel.expanded" fxFlex="0 1 auto">{{ item.get('name').value }}</p>
                    </div>
                </mat-panel-title>

                <mat-panel-description fxLayout="column" *ngIf="!item.valid && !item.disabled">
                    <ng-container *ngIf="item.touched && !panel.expanded">
                        <p class="color-warn" i18n="@@QuestionFormError">
                            Question contains errors, fill in the required* fields.
                        </p>
                        <ng-container
                            *ngIf="
                                QuestionType.questionTypesWhichHaveAlts().includes(
                                    item.value.question?.question_type_value
                                )
                            "
                        >
                            <p
                                *ngIf="item.get('question.alternatives').errors?.minLength"
                                class="color-warn"
                                i18n="@@alternativesShouldBeMoreThan1"
                            >
                                Alternatives should be more than 1.
                            </p>

                            <p *ngIf="item.get('question.alternatives').errors?.missCorrect" class="color-warn" i18n>
                                Correct answer is required!
                            </p>
                        </ng-container>
                    </ng-container>
                </mat-panel-description>
                <mat-panel-description class="btns-desc" fxLayout="row" fxLayoutAlign="end center">
                    <button
                        mat-icon-button
                        *ngIf="editable(items[i])"
                        (click)="toggleDisable($event, item); panel.close()"
                    >
                        <mat-icon *ngIf="!item.disabled" fxFlex="0 1 auto" class="remove-item"> delete </mat-icon>
                        <mat-icon *ngIf="item.disabled" fxFlex="0 1 auto" class="remove-item"> undo </mat-icon>
                    </button>
                    <button
                        class="img-upload-btn"
                        *ngIf="$any(loadingUploader | async) != i"
                        mat-icon-button
                        (click)="$event.stopPropagation(); addMedia(item, items[i], i)"
                    >
                        <mat-icon
                            *ngIf="items[i].media?.url || item.get('question').value.media_id; else add_q_image"
                            class="has-img"
                            [ngClass]="{ 'ai-uploaded': items[i].media?.type === 'gpt' }"
                            >image</mat-icon
                        >
                        <ng-template #add_q_image>
                            <mat-icon>add_photo_alternate</mat-icon>
                        </ng-template>
                    </button>

                    <app-kd-logo-loading *ngIf="$any(loadingUploader | async) == i" size="small"></app-kd-logo-loading>

                    <button
                        *ngIf="!item.disabled && editable(items[i])"
                        mat-icon-button
                        (click)="duplicate($event, item)"
                        [disabled]="$any(loadingUploader | async) == i"
                    >
                        <mat-icon fxFlex="0 1 auto" class="duplicate-item"> content_copy </mat-icon>
                    </button>

                    <mat-icon
                        [ngClass]="{ 'disable-draging': (loadingUploader | async) }"
                        cdkDragHandle
                        fxFlex="0 1 auto"
                        class="question-reorder"
                    >
                        reorder
                    </mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-question-item-form
                #qs_item_ref
                *ngIf="panel.expanded"
                [itemGroup]="item | cast"
                [item]="items[i]"
                [index]="i"
                [usePoints]="usePoints"
            ></app-question-item-form>
        </mat-expansion-panel>
    </mat-accordion>
</div>
