<app-resource-details-info
    [resource]="resource"
    [user]="user"
    [customer]="customer"
    [resourceItems]="resourceItems"
    [resourceCategories$]="resourceCategories$"
    [resourceTags$]="resourceTags$"
    [resourceType]="ResourceTypes.parentType(resource.type_id)"
    [resourceIsAvailable]="resourceIsAvailable"
    resourceLabel="SCORM"
    i18n-resourceLabel
>
    <ng-container itemsHeader>
        <h3 class="sec-res-title">{{ resource.title }}</h3>
    </ng-container>
    <span managerLabel i18n="@@author">Author</span>
    <ng-container resourceItemsSection></ng-container>
    <span actionBtnLabel i18n="@@start">Start</span>
    <span resCodeLabel i18n>SCORM code</span>
    <ng-container resourceDates></ng-container>
    <ng-container resourceCircles>
        <div class="circle-item" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div class="icon-wrapper">
                <mat-icon>business_center</mat-icon>
            </div>
            <span>SCORM</span>
        </div>
    </ng-container>
</app-resource-details-info>
