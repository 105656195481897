import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveData } from '@klickdata/core/mobile/src/mobile.service';
import { Notification, NotificationService } from '@klickdata/core/notification';
import { AppScope } from '@klickdata/core/resource';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-send-message',
    templateUrl: './menu-side-send-message.component.html',
    styleUrls: ['./menu-side-send-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideSendMessageComponent implements OnDestroy, OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<boolean> = new EventEmitter();
    public messageForm: FormGroup;
    AppScope = AppScope;
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected fb: FormBuilder,
        protected messageService: MessageService,
        protected router: Router,
        protected notificationService: NotificationService
    ) {
        this.messageForm = this.fb.group({
            subject: [''],
            body: [''],
            media_ids: [[]],
            scope_id: [AppScope.USERS],
        });
    }
    ngOnInit(): void {}
    public submit() {
        if (!this.messageForm.value.subject) {
            return;
        }
        this.saving.emit(true);
        const message = new Notification(this.messageForm.value).getData();
        this.notificationService
            .create({
                ...message,
                ...(this.navData.data.isUser && { recipient_ids: [this.navData.data.model.id] }),
                ...(this.navData.data.isGroup && { group_ids: [this.navData.data.model.id] }),
            })
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.onClose.emit(true);
                this.saving.emit(false);
                this.messageService.openMessage(
                    MessageErrorComponent,
                    $localize`Message sent to ${this.navData.data.model.name}`
                );
            });
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
