import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';

@Component({
    selector: 'app-table-menu',
    templateUrl: './table-menu.component.html',
    styleUrls: ['./table-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableMenuComponent implements OnInit, OnChanges {
    /**
     * Unique identifier for a table, for saving settings to local storage.
     */
    @Input() id: string;

    /**
     * Input columns that the user will be able to change.
     */
    @Input() columns: string[];

    /**
     * Emits the displayed value on changes.
     */
    @Output() onChange: EventEmitter<string[]> = new EventEmitter<string[]>();

    /**
     * The chosen column's to display in table.
     */
    public displayed: string[];

    constructor() {}

    public ngOnInit(): void {
        this.displayed = this.columns;
    }

    ngOnChanges() {
        this.displayed = this.columns;
    }

    public save(): void {}

    public load(): void {}
}
