<app-chips-with-add-handler
    (onItemSelection)="onSelectedGamCat($event)"
    (onItemRemoval)="removeGamCat($event)"
    (onSearchInput)="onSeachFromList.next($event)"
    [user]="user"
    [loadingUserSelectedItems]="loadingUserCats"
    [activeItemsSelection]="activeCatsSelection"
    [fullItemsList$]="gamificationCategories$"
    [itemScope]="AppScope.CATEGORIES"
    searchInputLabel="Search or pick category"
    i18n-searchInputLabel="@@searchOrPickCategory"
    mainTitle="Personal Interests"
    i18n-mainTitle="@@personalInterests"
    subTitle="This helps us to specify your experience in KLMS"
    i18n-subTitle="@@thisHelpsUsToSpecifyYourExp"
    class="user-personal-cats-chips"
></app-chips-with-add-handler>
