import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Directive, Host, OnInit } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { ResourceItemComposerDirective, ResourceItemData } from '@klickdata/core/resource-item';
import { takeUntil, tap } from 'rxjs';
import { MaterialsManagerCoreComponent } from './materials-manager-core.component';

@Directive()
export abstract class MaterialItemComposerDirective extends ResourceItemComposerDirective implements OnInit {
    constructor(
        @Host() protected readonly host: MaterialsManagerCoreComponent,
        protected cdRef: ChangeDetectorRef,
        protected parentFormDirective: FormGroupDirective
    ) {
        super(parentFormDirective);
    }
    ngOnInit(): void {
        this.selection.changed
            .pipe(
                takeUntil(this.destroy),
                tap(() => this.cdRef.markForCheck())
            )
            .subscribe();
    }

    deleteItem(index: number): void {
        this.host.delete([index]);
    }
    copyItem(itemData: ResourceItemData): void {
        this.host.copy(itemData);
    }
    translateItem(itemData: { language_id: number; indices: number[] }): void {
        this.host.translate(itemData);
    }
    regenerateItem(index: number): void {
        this.host.regenerate([index]);
    }
    duplicateItem(index: number): void {
        this.host.duplicate(index);
    }

    get isItemsProcessing(): boolean {
        return this.host.isItemsProcessing;
    }

    get selection(): SelectionModel<any> {
        return this.host.selection;
    }
}
