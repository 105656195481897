<mat-expansion-panel
    [expanded]="index == opened && !itemForm.disabled"
    (opened)="opened = index"
    (closed)="opened === index && (opened = -1)"
    [disabled]="itemForm.disabled"
    #expansionPanel
>
    <mat-expansion-panel-header
        class="on-hover-mat-item"
        fxLayoutGap="10px"
        fxLayout="row"
        fxLayoutAlign="start center"
    >
        <mat-checkbox
            [disabled]="itemForm.disabled"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(index) : null"
            [checked]="selection.isSelected(index)"
        >
        </mat-checkbox>
        <app-material-manager-text-item-header
            #textMaterialHeader
            [ngClass]="{
                'panel-expanded': expansionPanel.expanded,
                'in-edit-mode': textMaterialHeader.editMode.value
            }"
            [expanded]="expansionPanel.expanded"
            [fxLayout.lt-md]="expansionPanel.expanded ? 'column' : 'row'"
            [item]="itemForm | cast"
            [resource]="resource"
            [index]="index"
            [disabled]="itemForm.disabled"
            (onDelete)="deleteItem($event)"
            (onCopy)="copyItem({ title: itemForm.value.title, name: itemForm.value.name })"
            (onTranslate)="translateItem({ language_id: $event, indices: [index] })"
            (onRegenerate)="regenerateItem($event)"
            (onDuplicate)="duplicateItem($event)"
        ></app-material-manager-text-item-header>
        <ng-content *ngIf="!expansionPanel.expanded"></ng-content>
    </mat-expansion-panel-header>
    <app-text-editor
        *ngIf="active"
        class="text-mat-editor"
        fxFlex="1 1 100%"
        title="Material Text"
        i18n-title
        [showTemplates]="false"
        [formControl]="itemForm.get('name') | cast"
    ></app-text-editor>
</mat-expansion-panel>
