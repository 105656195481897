<div fxFlex="0 0 100%" fxLayout="row wrap" class="assignments-select">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.contextTitle"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss()"
        [savingStatus]="saving"
    ></app-sticky-header>
    <div class="wrapper">
        <div class="toggle-wrapper">
            <div class="red" fxLayoutAlign="flex-start center">
                <mat-icon>accessibility</mat-icon>
                <span i18n>Public access of academy users</span>
            </div>
            <mat-slide-toggle #academyPublic color="warn" [formControl]="assignForm.get('users_access_open') | cast">
                <h4 class="red" *ngIf="academyPublic.checked" i18n>On</h4>
                <h4 *ngIf="!academyPublic.checked" i18n>Off</h4>
            </mat-slide-toggle>
        </div>
    </div>
    <mat-tab-group
        [ngClass]="{ 'hide-tabs': assignForm.value.users_access_open }"
        class="user-manage-cp-access"
        fxFlex="0 0 90%"
        mat-stretch-tabs
        ngClass.xs="xs"
    >
        <mat-tab>
            <ng-template mat-tab-label>
                <span i18n="@@users">Users</span>
            </ng-template>
            <app-admin-users-select
                [referenceFilter]="data.post.id ? 'referencePost' : null"
                [accessForm]="assignForm"
                sync_all_key="sync_all_users"
                attachKey="users_attach"
                detachKey="users_detach"
                [defaultSorting]="data.post.id ? 'checked' : 'name'"
                class="mt-1"
            ></app-admin-users-select>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <span i18n="@@groups">Groups</span>
            </ng-template>
            <app-admin-groups-select
                [referenceFilter]="data.post.id ? 'referencePost' : null"
                [accessForm]="assignForm"
                sync_all_key="sync_all_groups"
                attachKey="groups_attach"
                detachKey="groups_detach"
                [defaultSorting]="data.post.id ? 'checked' : 'name'"
                class="mt-1"
            ></app-admin-groups-select>
        </mat-tab>
    </mat-tab-group>
</div>
