import { AccessCapability } from '../can-access.directive';

export namespace CoursePlanCapability {
    export const NONE = 0;

    // Computed by customer services
    // export const COURSE_PLAN_ACCESS = 1 << 0; // Replace by ServicesAccessCapability

    // Course plan - Role access types
    export const VIEW_ALL_COURSE_PLANS = 1 << 1;
    export const VIEW_CUSTOMER_COURSE_PLANS = 1 << 2;
    export const VIEW_GROUP_COURSE_PLANS = 1 << 3;
    export const MANAGE_CUSTOMER_COURSE_PLANS = 1 << 4;
    export const MANAGE_GROUP_COURSE_PLANS = 1 << 5;
    export const MANAGE_PUBLIC_COURSE_PLANS = 1 << 6;

    export function getName(): string {
        return 'course-plan';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
