import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nameSign',
})
export class NameSignPipe implements PipeTransform {
    transform(name: string): string {
        // return name.match(/(\b\w)?/g).join("").match(/(^\w|\w$)?/g).join("").toUpperCase();
        return name?.match(/\b(\w)/g)?.join('').toUpperCase();
    }
}
