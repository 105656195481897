import { IDataModel, Model } from '@klickdata/core/application/src/model/model-interface';

export interface CustomerCategoryData extends IDataModel {
    value?: string;
    label?: string;
}

export class CustomerCategory extends Model<CustomerCategoryData> {
    public id: string | number;
    value: string;
    label: string;
}
