import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import * as RecordRTC from 'recordrtc';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { RecorderTypes, RecordingOutput } from './media-type';

export interface RecordResultsMetaData {
    title: string;
    extension: string;
    type: string;
}
@Injectable({
    providedIn: 'root',
})
export class RecorderService {
    public recorder: RecordRTC;
    public activeRecordingType: RecorderTypes;
    public isRecordingSub = new BehaviorSubject<boolean>(false);
    public onToggleRecordingState = new Subject<boolean>();
    private submitUsingRecord = new BehaviorSubject<boolean>(false);
    private isRecordingOptionsBtnClicked = new Subject<boolean>();
    public stream: MediaStream | MediaStream[];
    public recordedSub = new BehaviorSubject<RecordingOutput>(null);
    private currentResourceId = new BehaviorSubject<number>(null);
    public recordResultsMetaData: RecordResultsMetaData;
    public cameraPreview: HTMLVideoElement;
    public wrapperHTMLCameraView: HTMLElement;
    private isLimitedRecord: boolean;

    constructor(protected configService: ConfigService) {}

    public record(
        stream: MediaStream | MediaStream[],
        config: any,
        recordResultsMetaData: RecordResultsMetaData,
        limitedRecord: boolean,
        liveStream?: MediaStream
    ) {
        if (liveStream) {
            this.handleStreamView(liveStream);
        }
        this.isLimitedRecord = limitedRecord;
        this.isRecordingSub.next(true);
        this.stream = stream;
        this.recordResultsMetaData = recordResultsMetaData;
        this.recorder = new RecordRTC(stream, { disableLogs: true, ...config });
        if (limitedRecord) {
            setTimeout(() => this.stopRecorder(), this.configService.config.limitedRecordingDuration);
        }
        this.recorder.startRecording();
        Array.isArray(this.stream)
            ? this.stream.forEach((subStream) =>
                  subStream.getTracks().forEach((track) => (track.onended = () => this.stopRecorder()))
              )
            : this.stream.getTracks().forEach((track) => (track.onended = () => this.stopRecorder()));
    }
    public clickRecorderOptionsBtn() {
        this.isRecordingOptionsBtnClicked.next(true);
    }
    public setCurrentResourceId(resourceId: number) {
        this.currentResourceId.next(resourceId);
    }
    public getCurrentResourceId(): Observable<number> {
        return this.currentResourceId.asObservable();
    }
    public onRecorderOptionsBtnClicked(): Observable<boolean> {
        return this.isRecordingOptionsBtnClicked.asObservable();
    }
    public setUsingRecordSubmitStatus(status: boolean) {
        this.submitUsingRecord.next(status);
    }
    public getUsingRecordSubmitStatus(): Observable<boolean> {
        return this.submitUsingRecord.asObservable();
    }
    public stopRecorder() {
        this.recorder.stopRecording(this.processRecording.bind(this));
    }

    public processRecording(audioVideoWebMURL: any) {
        this.onToggleRecordingState.next(true);
        const recordingName = this.getNameWithTime();
        this.isRecordingSub.next(false);
        const recordedBlob = this.recorder.getBlob();
        this.recorder.getDataURL(function (dataURL: any) {});
        const encodedrecordingName = encodeURIComponent(recordingName);
        this.recorder.save(encodedrecordingName);
        this.recordedSub.next({
            blob: recordedBlob,
            url: audioVideoWebMURL,
            title: encodedrecordingName,
            file: new File([recordedBlob], recordingName, {
                type: this.recordResultsMetaData.type,
            }),
            limitedRecord: this.isLimitedRecord,
        });
        this.stopStream();
        this.stopMedia();
    }
    private getNameWithTime(): string {
        const time = new DatePipe('en').transform(Date.now(), 'yyMMdd_HHmm');
        return this.recordResultsMetaData.title + '_' + time + '.' + this.recordResultsMetaData.extension;
    }

    public handleStreamView(camera: MediaStream) {
        this.wrapperHTMLCameraView = document.createElement('div');
        this.wrapperHTMLCameraView.setAttribute('id', 'wrapper-camera-view');

        const cameraPreview = document.createElement('video');
        const span = document.createElement('span');
        const bulbIcon = document.createElement('div');
        const node = document.createTextNode('Rec');
        span.appendChild(node);
        this.wrapperHTMLCameraView.appendChild(cameraPreview);
        this.wrapperHTMLCameraView.appendChild(span);
        this.wrapperHTMLCameraView.appendChild(bulbIcon);

        cameraPreview.srcObject = camera;
        cameraPreview.autoplay = true;
        cameraPreview.controls = false;
        cameraPreview.muted = true;

        document
            .getElementsByTagName('app-klickdata')[0]
            .insertBefore(this.wrapperHTMLCameraView, document.getElementsByClassName('parent-root')[0]);
    }

    public stopStream() {
        if (this.stream) {
            Array.isArray(this.stream)
                ? this.stream.forEach((subStream) => this.clearStream(subStream))
                : this.clearStream(this.stream);
            this.stream = null;
            if (this.wrapperHTMLCameraView) {
                this.wrapperHTMLCameraView.parentNode?.removeChild(this.wrapperHTMLCameraView);
            }
        }
    }

    private clearStream(stream: MediaStream) {
        stream.getAudioTracks().forEach((track: any) => track.stop());
        stream.getVideoTracks().forEach((track: any) => track.stop());
        stream.removeAllListeners();
    }

    public stopMedia() {
        if (this.recorder) {
            this.recorder = null;
        }
    }
}
