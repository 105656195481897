import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-resource-collect-sheet',
    templateUrl: './resource-collect-sheet.component.html',
    styleUrls: ['./resource-collect-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceCollectSheetComponent implements OnInit, OnDestroy {
    public assignForm: FormGroup;
    public resource: Observable<Resource>;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public saving: boolean;
    public publicAccess: Observable<boolean>;

    constructor(
        public bottomSheetRef: MatBottomSheetRef<ResourceCollectSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected resourceService: ResourceService
    ) {
        this.assignForm = this.fb.group({
            id: [],
            sync_all_folders: [],
            folders_attach: [[]],
            folders_detach: [[]],

            sync_all_sections: [],
            sections_attach: [[]],
            sections_detach: [[]],
        });
    }

    ngOnInit() {
        this.resource = this.resourceService.getResource(this.data.id).pipe(shareReplay());
        this.assignForm.patchValue({ id: this.data.id });
    }

    public submit() {
        if (
            this.assignForm.value.folders_attach?.length ||
            this.assignForm.value.folders_detach?.length ||
            this.assignForm.value.sections_attach?.length ||
            this.assignForm.value.sections_detach?.length ||
            this.assignForm.value.sync_all_folders !== null ||
            this.assignForm.value.sync_all_sections !== null
        ) {
            const data = new Resource(this.assignForm.value).getData();
            this.saving = true;
            this.resourceService
                .update(data)
                .pipe(
                    map(res => {
                        this.assignForm.patchValue({ id: data.id });
                        return res;
                    }),
                    takeUntil(this.destroy)
                )
                .subscribe(() => {
                    this.saving = false;
                    this.bottomSheetRef.dismiss(this.data);
                });
        } else {
            this.bottomSheetRef.dismiss(this.data);
        }
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
