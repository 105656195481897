<div class="data-wrap" fxLayout="column" [formGroup]="form">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>{{ navData.data.icon }}</mat-icon
        ><span>{{ navData.data.title }}</span>
    </div>

    <div class="desc">{{ navData.data.description }}</div>

    <mat-form-field class="mb-0" floatLabel="never" appearance="fill" fxFlex="0 0 100%">
        <textarea
            maxlength="5000"
            rows="5"
            [placeholder]="navData.data.placeholder"
            #message
            matInput
            [formControl]="value"
        ></textarea>
    </mat-form-field>

    <div
        *ngIf="!!navData.data.actions.length"
        class="action"
        fxLayout="row-reverse"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
    >
        <ng-container *ngFor="let action of navData.data.actions">
            <button
                fxFlex="0 0 auto"
                class="mobile-button positive {{ action.class }}"
                (click)="
                    onClose.emit(
                        action.value
                            ? {
                                  data: { type: navData.data.type, response: action.value, value: value }
                              }
                            : null
                    )
                "
                mat-button
                i18n
            >
                {{ action.label }}
            </button>
        </ng-container>
    </div>
</div>
