import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Component({
    selector: 'app-main-search',
    templateUrl: './main-search.component.html',
    styleUrls: ['./main-search.component.scss'],
    animations: [
        trigger('enterLeave', [
            state(
                'in',
                style({
                    transform: 'translateY(0)',
                    opacity: 1,
                })
            ),
            transition('void => *', [
                style({
                    transform: 'translateY(-100%)',
                    opacity: 0,
                }),
                animate(200),
            ]),
            transition('* => void', [
                animate(
                    100,
                    style({
                        transform: 'translateY(-100%)',
                        opacity: 0,
                    })
                ),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSearchComponent implements OnInit {
    public showFooter = true;
    public isMobile: Observable<boolean>;
    @Output() dismissSearchArea: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(protected mobile: MobileService, protected auth: AuthService, protected router: Router) {}

    ngOnInit(): void {
        this.isMobile = this.mobile.isMobile().pipe(shareReplay());
    }
    public showCatalog() {
        this.dismissSearchArea.emit(true);
        if (this.auth.getNK3PlatformValue() === 'admin' || this.auth.getNK3PlatformValue() === 'master') {
            this.router.navigate(['/admin/content/content-catalog']);
        } else {
            this.router.navigate(['/home/user-catalog']);
        }
    }
}
