import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceOpportunityService } from '@klickdata/core/opportunity';
import { AppScope, Resource, ResourceTypes } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    StatusFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { ShareService } from '@klickdata/shared-components/src/share/share.service';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { WelcomeMessageDialogComponent } from 'apps/klickdata/src/app/shared/email/welcome-message-dialog/welcome-message-dialog.component';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { ResourcesListingUserOccasionService } from './resources-listing-user-occasion.service';

@Component({
    selector: 'app-resources-listing-user-occasions',
    templateUrl: './resources-listing-user-occasions.component.html',
    styleUrls: ['./resources-listing-user-occasions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourcesListingUserOccasionService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ResourcesListingUserOccasionsComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() type_scope_ids: AppScope[];
    @Input() context: string;
    @Input() expandFirst: boolean;
    @Input() pageSize = 25;
    @Input() tableFilterItems: Filter<string | number>[];
    @Output() onBrowse: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onPlay: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onDelete: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onAssign: EventEmitter<Resource> = new EventEmitter<Resource>();
    public dataSource = new TableSource<Resource>();
    public columns: string[] = [
        'select',
        'img',
        'title',
        'type',
        'last_action',
        'occasionStatus',
        'done_items',
        'examination',
        'tools',
    ];
    public isMobile: boolean;
    public authUser: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    selection = new SelectionModel<Resource>(true, []);
    public showActionHeader = false;
    public shareLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    AppScope = AppScope;
    public resourceStatusOptions: StatusFilterOption[];
    public expandedRow: Resource | null;
    public expandedMobRow: Resource | null;
    public cacheScope: AppScope | null;
    GlobalFilterProperty = GlobalFilterProperty;
    ResourceTypes = ResourceTypes;

    constructor(
        protected resourceService: ResourcesListingUserOccasionService,
        protected mobile: MobileService,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        public shareService: ShareService,
        protected downloadHelper: DownloadHelperService,
        protected resourceOpportunityService: ResourceOpportunityService,
        protected dialog: MatDialog,
        protected router: Router,
        protected messageService: MessageService,
        protected location: Location
    ) {
        this.resourceStatusOptions = [
            { title: $localize`Done`, value: 'done', color: '#3e5365', icon: 'done_all', selected: true },
            { title: $localize`Ongoing`, value: 'ongoing', color: '#ff9961', icon: 'cached', selected: true },
            { title: $localize`Deleted`, value: 'deleted', color: '#ff9961', icon: 'delete_forever', selected: true },
            {
                title: $localize`Not Started`,
                value: 'not_started',
                color: '#93cbd1',
                icon: 'remove_done',
                selected: true,
            },
            { title: $localize`Overdue`, value: 'overdue', color: '#a7a7a7', icon: 'event_busy', selected: true },
            { title: $localize`Cancelled`, value: 'cancelled', color: '#e44a66', icon: 'cancel', selected: true },
        ];

        this.cacheScope = this.auth.checkPlatform('user') ? null : AppScope.RESOURCES;
    }

    public ngOnInit() {
        this.dataSource.service = this.resourceService;
        this.dataSource.data.forEach(
            (res) => (res.opportunity = this.resourceOpportunityService.getOpportunity(res.opportunity_id))
        );
        this.dataSource.latestData
            .pipe(
                map((data) => data.data),
                tap((resources) => {
                    if (this.expandFirst && this.type_scope_ids.length > 1) {
                        this.expandedRow = resources[0];
                        this.cdRef.markForCheck();
                    }
                }),
                map((resources) =>
                    resources.map(
                        (res) => (res.opportunity = this.resourceOpportunityService.getOpportunity(res.opportunity_id))
                    )
                )
            )
            .subscribe();
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMob) => {
                this.isMobile = isMob;
                this.cdRef.markForCheck();
            });
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => {
                this.authUser = user;
                this.updatefilters();
            });
    }
    private updatefilters() {
        const appliedFilters = [];
        appliedFilters.push(new Filter('scope', this.type_scope_ids));
        if (this.tableFilterItems) {
            this.tableFilterItems.forEach((filterItem) => {
                appliedFilters.push(new Filter(filterItem.property, filterItem.items));
            });
        } else {
            appliedFilters.push(new Filter('userOccasions', [this.authUser.id]));
        }
        this.filter.createOrUpdateWithoutEmitFilter(appliedFilters);
    }
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    }
    signoffRes(resource: Resource) {}
    getSignOffToolTip(resource: Resource) {
        switch (resource.occasionStatus) {
            case 'done':
                return 'In Review';

            case 'completed':
                return 'In Review';
        }
    }
    getSignOffColor(resource: Resource) {
        switch (resource.occasionStatus) {
            case 'done':
                return '#cbcbcb';

            case 'completed':
                return '#cbcbcb';
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Resource): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    public getPlayBtnTxt(resource: Resource): string {
        return resource.occasionStatus === 'ongoing' ? $localize`:@@continue:Continue` : $localize`:@@play:Play`;
    }
    public mailUser(user: User) {
        const dialogRef = this.dialog.open(WelcomeMessageDialogComponent, {
            width: !this.isMobile ? '70%' : '100%',
            data: { users_attach: [user.id], title: user.name },
            disableClose: true,
            panelClass: 'sheet-wrapper',
        });
    }

    public download(resource?: Resource) {
        if (resource?.downloads) {
            this.downloadHelper.downloadWithOptions(resource.downloads);
        } else if (resource) {
            this.messageService.openMessage(
                MessageErrorComponent,
                !resource.done
                    ? $localize`:@@completeToViewCertificate:Complete the resource to view the certificate`
                    : $localize`:@@noCertificateAvailable:No certificate available for this resource`
            );
        } else {
            // TODO handle multiply selection.
            // this.selection.selected
        }
    }

    public deleteCourseFromList(resource: Resource) {
        if (this.onDelete.observed) return this.onDelete.emit(resource);

        this.resourceOpportunityService
            .destroy(resource.opportunity_id)
            .pipe(takeUntil(this.destroy))
            .subscribe({
                complete: () => {
                    this.dataSource.removeById(resource.id);
                    this.messageService.openMessage(
                        MessageSavedComponent,
                        $localize`:@@resoureHasBeenHidden:The resource has been successfully hidden.`
                    );
                },
                error: () => {
                    this.messageService.openMessage(
                        MessageErrorComponent,
                        $localize`:@@someThingWentWrong:Oops! Something went wrong. Please try again later.`
                    );
                },
            });
    }
    retakeCourse(res: Resource) {
        this.router.navigate(['/player/resource', res.id, ResourceTypes.playerRoute(res.type_id)], {
            queryParams: {
                referrer: '/home/dashboard/main',
            },
        });
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
