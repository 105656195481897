<div class="block-wrapper section-item-parent-wrapper" fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-accordion [fxShow]="!!attachedLanguages?.length" fxFlex="0 0 100%" displayMode="flat">
        <mat-expansion-panel
            [class.icons-items-layout]="mode === 'list'"
            [hideToggle]="expansionPanel.expanded"
            [expanded]="true"
            #expansionPanel
        >
            <mat-expansion-panel-header
                [class.expanded-section-filters]="expandedSectionFilters"
                [class.relative]="expansionPanel.expanded"
            >
                <div class="header-collapsed-state w-100" *ngIf="!expansionPanel.expanded">
                    <div fxFlex="100" fxLayoutAlign="space-between center">
                        <span class="title">{{ title }}</span>
                        <!-- <span class="open-section" i18n="@@openSection">Open section</span> -->
                    </div>
                </div>

                <div class="header-expanded-state" *ngIf="expansionPanel.expanded">
                    <div class="toggle-block">
                        <!-- <span *ngIf="expandedSectionFilters" i18n="@@closeFilter">Close filter</span>
                        <span *ngIf="!expandedSectionFilters" i18n="@@filter">Filter</span> -->
                        <button
                            matTooltip="Change view"
                            i18n-matTooltip="@@changeView"
                            mat-icon-button
                            fxFlex="0 0 auto"
                            color="primary"
                            class="button-toggle-view"
                            (click)="$event.stopPropagation(); modeToggle()"
                        >
                            <mat-icon>
                                {{ mode === 'list' ? 'apps' : 'view_list' }}
                            </mat-icon>
                        </button>
                        <button
                            matTooltip="Hide section"
                            i18n-matTooltip="@@hideSection"
                            mat-icon-button
                            fxFlex="0 0 auto"
                            color="primary"
                            class="hide-section-btn"
                        >
                            <mat-icon>cancel</mat-icon>
                        </button>
                        <mat-icon
                            (click)="$event.stopPropagation(); expandedSectionFilters = !expandedSectionFilters"
                            class="open-filters-icon"
                            [class.flip-filter-toggler]="expandedSectionFilters"
                            matTooltip="Open/Close filters"
                            i18n-matTooltip="@@open/closeFilters"
                            >expand_more</mat-icon
                        >
                    </div>
                    <div
                        (click)="$event.stopPropagation()"
                        class="mt-1 mb-1 expand-filters-wrapper"
                        fxLayout="column wrap"
                        fxLayoutAlign="start start"
                    >
                        <div class="w-100 filter-wrappers" [class.hide-filters]="!expandedSectionFilters">
                            <!-- <ng-container *ngTemplateOutlet="filters"></ng-container> -->
                            <app-table-filter
                                [filtersTogglesInOnBtn]="filtersTogglesInOnBtn"
                                [cacheScope]="cacheScope"
                                [inGlobalFilter]="true"
                                color="accent"
                                #filter
                                (filterChange)="filterChangedEmitter($event)"
                                class="mb-1"
                            >
                                <app-table-search
                                    [inGlobalFilter]="true"
                                    class="align-space"
                                    searchFilterRef
                                ></app-table-search>
                                <app-language-filter
                                    [inGlobalFilter]="true"
                                    [prefLangIds]="attachedLanguages"
                                    #langFilter
                                    fxFlex="0 0 auto"
                                    fxLayout="row"
                                    langFilterRef
                                ></app-language-filter>
                                <app-select-filter
                                    sectionSortingRef
                                    class="filter-space select-filter"
                                    label="Sort by"
                                    i18n-label="@@sortBy"
                                    [property]="GlobalFilterProperty.SECTION_SORTING"
                                    [options]="sectionSortingOptions"
                                    [inGlobalFilter]="true"
                                ></app-select-filter>
                            </app-table-filter>
                        </div>

                        <span class="title">{{ title }}</span>
                    </div>
                </div>
            </mat-expansion-panel-header>
            <div
                class="expansion-body"
                [class.filtersToggled]="toggleFilterButtons"
                *ngIf="resources$ | async as resources; else loading"
            >
                <div
                    [class.wrap-items-layout]="itemsLessThanFullWidth"
                    class="items-wrapper icons-section"
                    [fxLayoutAlign]="gap ? 'start start' : 'space-between start'"
                    [ngStyle]="{ gap: gap ? gap - 3 + 'px' : '0' }"
                >
                    <div
                        *ngIf="!attachedLanguages?.length"
                        class="mt-2 mb-2"
                        fxFlex="1 1 100%"
                        fxLayout="row"
                        fxLayoutAlign="center center"
                    >
                        <p i18n="@@youHaveNoItems">You have no items</p>
                    </div>
                    <ng-container *ngIf="!AppScope.isNotResource(param.scope) || Number(param.scope) === AppScope.POST">
                        <ng-container *ngIf="mode === 'icons'">
                            <app-section-item
                                [hasThumbnailHoverEffect]="hasThumbnailHoverEffect"
                                *ngFor="let resource of resources"
                                [mode]="mode"
                                [resource]="resource"
                                [fxFlex]="mode === 'icons' ? '0 0 auto' : '0 0 95%'"
                                class="section-item"
                                [ngStyle]="{ width: sectionItemWidth + 'px' }"
                            ></app-section-item>
                        </ng-container>

                        <ng-container *ngIf="mode === 'list'">
                            <app-section-items-list-view
                                [type_scope_id]="
                                    param.resourceScope
                                        ? param.resourceScope
                                        : param.scope
                                        ? param.scope
                                        : AppScope.SECTIONS
                                "
                                [resources]="resources"
                                [allowActionHeader]="true"
                                [sectionType]="sectionType"
                                [actionCellBtns]="actionCellBtns"
                                [activitySectionType]="activitySectionType"
                                (onBrowse)="onBrowseActivity.emit($event)"
                                (onDelete)="onDeleteActivity.emit($event)"
                                (onPlay)="onPlayActivity.emit($event)"
                                (onFinalGrades)="downloadFinalGrades($event)"
                                (onAssign)="onAssign.emit($event)"
                                (onFilterEmission)="filterEmitter($event)"
                                [displayedColumns]="getDisplayedColumns(resources)"
                            ></app-section-items-list-view>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="AppScope.isNotResource(param.scope)">
                        <ng-container *ngIf="AppScope.isResourceContainer(param.scope)">
                            <mat-list
                                [ngClass]="{
                                    'is-tag-res': Number(param.scope) === AppScope.TAGS,
                                    'is-cat-res': Number(param.scope) === AppScope.CATEGORIES
                                }"
                                class="search-res-not-res"
                                role="list"
                            >
                                <mat-list-item
                                    (click)="
                                        AppScope.isResourceContainer(param.scope)
                                            ? showResources(container)
                                            : handleLinkClick(container)
                                    "
                                    *ngFor="let container of resources"
                                    role="listitem"
                                >
                                    <mat-icon
                                        mat-list-icon
                                        fontSet="klickdata"
                                        fontIcon="kd-icon-user"
                                        *ngIf="Number(param.scope) === AppScope.USERS"
                                    ></mat-icon>
                                    <mat-icon
                                        mat-list-icon
                                        *ngIf="
                                            AppScope.isResourceContainer(param.scope) ||
                                            Number(param.scope) === AppScope.GROUPS ||
                                            Number(param.scope) === AppScope.CUSTOMERS
                                        "
                                        >{{ container.scope_id | typeFontIcon : container.scope_id }}</mat-icon
                                    >
                                    {{ container.label }}</mat-list-item
                                >
                            </mat-list>
                        </ng-container>
                        <ng-container *ngIf="AppScope.isLink(param.scope)">
                            <ng-container *ngIf="mode === 'list'">
                                <mat-list class="search-res-not-res" role="list">
                                    <mat-list-item
                                        (click)="
                                            AppScope.isResourceContainer(param.scope)
                                                ? showResources(container)
                                                : handleLinkClick(container)
                                        "
                                        *ngFor="let container of resources"
                                        role="listitem"
                                    >
                                        <mat-icon
                                            mat-list-icon
                                            fontSet="klickdata"
                                            fontIcon="kd-icon-user"
                                            *ngIf="Number(param.scope) === AppScope.USERS"
                                        ></mat-icon>
                                        <mat-icon
                                            mat-list-icon
                                            *ngIf="
                                                AppScope.isResourceContainer(param.scope) ||
                                                Number(param.scope) === AppScope.GROUPS ||
                                                Number(param.scope) === AppScope.CUSTOMERS
                                            "
                                            >{{ container.scope_id | typeFontIcon : container.scope_id }}</mat-icon
                                        >
                                        {{ container.label }}</mat-list-item
                                    >
                                </mat-list>
                            </ng-container>
                            <ng-container *ngIf="mode === 'icons'">
                                <app-section-item
                                    *ngFor="let resource of resources"
                                    [mode]="'icons'"
                                    [isLinkScopeType]="true"
                                    [resource]="resource"
                                    [fxFlex]="'0 0 auto'"
                                    class="section-item sectionItem-link"
                                ></app-section-item>
                            </ng-container>
                        </ng-container>
                        <!-- <ng-container *ngIf="Number(param.scope) === AppScope.POST">
                            <ng-container
                                [ngTemplateOutlet]="news_items"
                                [ngTemplateOutletContext]="{ resources: resources }"
                            ></ng-container>
                        </ng-container> -->
                        <ng-container *ngIf="Number(param.scope) === AppScope.QUOTE">
                            <ng-container
                                [ngTemplateOutlet]="quotes_items"
                                [ngTemplateOutletContext]="{ resources: resources }"
                            ></ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div fxLayoutAlign="end center">
                <mat-paginator
                    [fxShow]="!itemsLessThanFullWidth"
                    [pageSize]="defaultNumberOfItemsPerSectionRow"
                    [pageSizeOptions]="[25, 50, 100]"
                >
                </mat-paginator>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<ng-template #news_items let-resources="resources">
    <app-post-detail class="global-search-result-news" [postsIds]="getMappedResources(resources)"></app-post-detail>
</ng-template>
<ng-template #quotes_items let-resources="resources">
    <app-quotes-slider [quotes]="resources"></app-quotes-slider>
</ng-template>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
