import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Resource, ResourceData, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { Filter } from '@klickdata/core/table';
import { HrNotes, HrNotesData, UserNotesService } from '@klickdata/core/user-notes';
import { Utils } from '@klickdata/core/util';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import {
    BehaviorSubject,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    Observable,
    of,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs';

@Component({
    selector: 'app-menu-side-quick-add-competence',
    templateUrl: './menu-side-quick-add-competence.component.html',
    styleUrls: ['./menu-side-quick-add-competence.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideQuickAddCompetenceComponent extends OnDestroyHandler implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public resourceForm: FormGroup;
    public resourceCtrl = new FormControl('');
    public resourceSearch$: Observable<Resource[]>;
    public selectedResource: Resource;
    onSearchResource$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected notesService: UserNotesService
    ) {
        super();
    }

    ngOnInit(): void {
        this.resourceForm = this.fb.group({
            title: ['', Validators.required],
            done_at: [],
            description: [''],
            note: [''],
            article_code: [''],
            language_id: ['', Validators.required],
            tag_ids: [[]],
        });
    }
    submit() {
        if (!this.resourceForm.valid) {
            return;
        }
        this.prepareData()
            .pipe(
                switchMap((data) =>
                    this.notesService
                        .addHrNote(this.prepareNotesData(), ['managers,tags'])
                        .pipe(switchMap(() => this.resourceService.store(data)))
                ),
                takeUntil(this.destroy)
            )
            .subscribe((resource) =>
                this.onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: { resource: resource, type: this.navData.data.type },
                })
            );
    }
    private prepareData() {
        let data = this.resourceForm.value;
        const formValues = this.resourceForm.value;
        data.occasion = {
            user_id: this.navData.data.user.id,
            done_at: formValues.done_at?.toLocaleString() ?? new Date(0).toISOString().toLocaleString(),
        };
        data.type_id = ResourceTypes.OTHER;
        return of(data);
    }
    private prepareNotesData(): HrNotesData {
        const title = this.resourceForm.value.title ? $localize`title: ${this.resourceForm.value.title}` : '';
        const sku = this.resourceForm.value.article_code ? $localize`SKU: ${this.resourceForm.value.article_code}` : '';
        const note = this.resourceForm.value.note ? $localize`note: ${this.resourceForm.value.note}` : '';
        const done = this.resourceForm.value.done_at
            ? $localize`date of completion: ${new DatePipe('en')
                  .transform(this.resourceForm.value.done_at, 'yyyy-MM-dd')
                  .toString()}`
            : '';

        let data: HrNotesData = {
            body: $localize`Added competence: ${[title, sku, done, note].filter(Boolean).join(', ')}`,
            staff: {
                manager: [this.navData.data.authUser.id],
            },
            tag_ids: this.resourceForm.value.tag_ids,
            created_at: new DatePipe('en').transform(Date.now(), 'yyyy-MM-dd').toString(),
            user_id: this.navData.data.user.id,
        };

        return new HrNotes(data).getPayload();
    }
}
