<div
    [ngClass]="{ 'other-media': !isDefinedMedia }"
    fxLayout="column"
    class="viewers-wrapper"
    *ngIf="media$ | async; let item; else: loading"
>
    <div class="down_wrapper" *ngIf="isS3Media && (canDownlaodMedia || displayName)">
        <button
            *ngIf="canDownlaodMedia"
            class="mr-top"
            fxFlex="0 1 auto"
            fxFlexAlign="start"
            mat-icon-button
            (click)="openFileByLink(item.url || item.src)"
            type="button"
        >
            <mat-icon>{{ item.type === MediaType.LINK ? 'link' : 'cloud_download' }}</mat-icon>
        </button>
        <span *ngIf="displayName" class="item_name"> {{ title ?? item.filename }} </span>
    </div>
    <ng-container [ngSwitch]="item.mediaType">
        <!-- Handle image type -->
        <ng-container *ngSwitchCase="MediaType.IMAGE">
            <app-image-view
                [media_id]="item"
                [proportional]="false"
                (srcLoaded)="mediaCompleted.emit(!!$event)"
            ></app-image-view>
        </ng-container>

        <!-- Handle media player type video -->
        <ng-container *ngSwitchCase="MediaType.VIDEO">
            <ng-container *ngTemplateOutlet="mediaPlayer"></ng-container>
        </ng-container>

        <!-- Handle media player type audio -->
        <ng-container *ngSwitchCase="MediaType.AUDIO">
            <ng-container *ngTemplateOutlet="mediaPlayer"></ng-container>
        </ng-container>

        <ng-template #mediaPlayer>
            <app-media-player
                class="w-100"
                [media]="item"
                [mediaCompleted]="mediaCompleted"
                [playerReady]="playerReady"
                [played]="played"
            >
            </app-media-player>
        </ng-template>

        <!-- Handle dynamic content hosted on NK3-datestore link -->
        <iframe
            *ngSwitchCase="MediaType.WEBSITE"
            width="100%"
            scrolling="no"
            frameborder="0"
            [src]="item.url || item.src | safeUrl"
            allowfullscreen="allowfullscreen"
            allow="autoplay"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
        ></iframe>

        <!-- Handle PDF type -->
        <ng-container class="pdf-viewer-container" *ngSwitchCase="MediaType.PDF">
            <pdf-viewer
                class="pdf-viewer-wrapper"
                [src]="item.url || item.src"
                [show-all]="false"
                [page]="page"
                (after-load-complete)="afterLoadComplete($event)"
                [original-size]="false"
                [autoresize]="true"
                [render-text]="true"
                (page-rendered)="pageRendered()"
            >
            </pdf-viewer>
            <div class="controls_wrapper" *ngIf="isPDFLoaded; else loading">
                <button
                    mat-raised-button
                    (click)="previousPage()"
                    [disabled]="page === 1"
                    type="button"
                    i18n="@@previous"
                >
                    Previous
                </button>

                <span>{{ page }} / {{ totalPages }}</span>

                <button
                    mat-raised-button
                    (click)="nextPage()"
                    [disabled]="page === totalPages"
                    type="button"
                    i18n="@@next"
                >
                    Next Page
                </button>
            </div>
        </ng-container>

        <!-- Handle doc type -->
        <ng-container *ngSwitchCase="MediaType.DOC">
            <!-- <iframe
                #iframe
                (load)="iframeLoaded()"
                width="100%"
                height="500"
                [src]="item.url || item.src | safeUrl"
            >
            </iframe> -->
            <!-- viewer: google (default), office, mammoth, pdf or url -->
            <ngx-doc-viewer
                [url]="item.src"
                viewer="office"
                style="width: 100%; height: 70vh"
                (loaded)="iframeLoaded()"
            ></ngx-doc-viewer>
        </ng-container>

        <!-- Handle URL/Link type -->
        <ng-container *ngSwitchCase="MediaType.URL">
            <a class="media-url" href="{{ item.url || item.src }}" (click)="mediaCompleted.emit(true)" target="_blank">
                {{ item.url || item.src }}
            </a>
        </ng-container>
        <ng-container *ngSwitchCase="MediaType.LINK">
            <a class="media-url" href="{{ item.url || item.src }}" (click)="mediaCompleted.emit(true)" target="_blank">
                {{ item.url || item.src }}
            </a>
        </ng-container>

        <!-- Other text type -->
        <ng-container *ngSwitchDefault>
            <!-- <h3>{{ item.url || item.src }}</h3> -->
            <mat-icon>file_present</mat-icon>
        </ng-container>
    </ng-container>
</div>
<ng-content></ng-content>
<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
