import { SelectionModel } from '@angular/cdk/collections';
import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ViewChild,
    ChangeDetectorRef,
    EventEmitter,
    Output,
    Input,
    AfterViewInit,
    OnDestroy,
    ElementRef,
    Renderer2,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { Quote } from '@klickdata/core/quote';
import { AppScope, ResourceTag, ResourceTagData } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    GlobalFilterPropertyType,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Observable, Subject } from 'rxjs';
import { filter, first, shareReplay, takeUntil } from 'rxjs/operators';
import { QuoteListService } from './quote-list.service';
import { Router } from '@angular/router';
import { Utils } from '@klickdata/core/util';
import { FilterSpecs } from '@klickdata/core/table/src/table-filter/filter';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';

@Component({
    selector: 'app-general-quotes-listing',
    templateUrl: './general-quotes-listing.component.html',
    styleUrls: ['./general-quotes-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [QuoteListService],
})
export class GeneralQuotesListingComponent implements OnInit, AfterViewInit, OnDestroy {
    public showActionHeader: boolean;
    public columns: string[] = [
        'select',
        'img',
        'quote',
        'tags',
        'status',
        'quotee',
        'author',
        'language',
        'created_at',
        'tools',
    ];
    public isMobile: boolean;
    public canRestoreUser: boolean;
    public allowActionRow: boolean;
    public expandedRow: Quote | null = null;
    public authUser: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    @ViewChild('quoteCell') quoteCell: ElementRef;
    @ViewChild('table') table: ElementRef;
    // @ViewChild('quoteCellRef') quoteCellRef!: ElementRef;
    @ViewChildren('quoteCellRef') quoteCellRefs: QueryList<ElementRef>;
    selection = new SelectionModel<Quote>(true, []);
    @Output() onRowClicked: EventEmitter<Quote> = new EventEmitter<Quote>();
    @Output() onBrowse: EventEmitter<Quote> = new EventEmitter<Quote>();
    @Input() tableFilterItems: Filter<string | number>[];
    AppScope = AppScope;
    public dataSource = new TableSource<Quote>();
    public user$: Observable<User>;
    @Output() onEdit: EventEmitter<boolean> = new EventEmitter<boolean>();
    GlobalFilterProperty = GlobalFilterProperty;
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    public generalModelStatusSelectOptions: SelectFilterOption[];
    ResourceStaffRoles = ResourceStaffRoles;
    public filterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.QUOTEE,
        label: $localize`:@@quotee:Quotee`,
        icon: 'record_voice_over',
    };

    constructor(
        protected mobile: MobileService,
        protected cdRef: ChangeDetectorRef,
        protected auth: AuthService,
        protected quoteService: QuoteListService,
        protected router: Router,
        private renderer: Renderer2
    ) {
        this.generalModelStatusSelectOptions = Utils.getGeneralModelStatusSelectOptions();
    }

    ngOnInit(): void {
        this.mobile
            .isMobile()
            .pipe(shareReplay())
            .subscribe((mobile) => {
                this.isMobile = mobile;
                this.cdRef.markForCheck();
            });
        this.user$ = this.auth.getUser();
        this.dataSource.service = this.quoteService;
        // Handle 3 dots when quote text exceed 3 lines
        this.dataSource.status.pipe(takeUntil(this.destroy)).subscribe((loaded) => {
            if (loaded === 'loaded') {
                setTimeout(() => {
                    this.quoteCellRefs.forEach((item) => {
                        if (item.nativeElement.clientHeight > 60) {
                            item.nativeElement.classList.add('add-dots');
                        }
                    });
                }, 500);
            }
        });
    }
    public editQuote(quoteId: number) {
        this.router.navigate(['/admin/content/quotes', quoteId]);
    }
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        if (this.tableFilterItems && !!this.tableFilterItems.length) {
            const appliedFilters = [];
            this.tableFilterItems.forEach((filterItem) => {
                appliedFilters.push(new Filter(filterItem.property, filterItem.items));
            });
            this.filter.createOrUpdateWithoutEmitFilter(appliedFilters);
        }
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Quote): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }
    public onTagClick(tag: ResourceTagData) {
        this.filter.filterByModel(new ResourceTag(tag).getFilter());
    }
    public onQuoteeClick(quoteeName: string) {
        this.filter.setActiveFilterWithQuery(GlobalFilterProperty.QUOTEE, quoteeName);
    }
    remove(quotes: Quote[]) {}
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
