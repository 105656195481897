import { Cast, CastType, IDataModel, Model } from '@klickdata/core/application';

export interface TicketData extends IDataModel {
    id?: number;
    message_id?: number;
    resource_ids?: number[];
    media_ids?: number[];
    author_id?: number;
    subject?: string;
    description?: string;
    assignee?: number[];
    status?: TicketStatus[];
    created_at?: string;
    updated_at?: string;
}
export class Ticket extends Model<TicketData> {
    id: number;
    message_id: number;
    resource_ids: number[];
    media_ids: number[];
    author_id: number;
    subject: string;
    description: string;
    assignee: number[];
    status: TicketStatus[];
    @Cast(CastType.MOMENT)
    created_at: string;
    @Cast(CastType.MOMENT)
    updated_at: string;
}

export interface TicketStatus {
    date?: any;
    type?: string;
    comment?: string;
}
export namespace AlertTicket {
    export function getAllAlertSubjects() {
        return [
            $localize`Report a Problem`,
            $localize`Suggest an improvement`,
            $localize`Content / Course wish`,
            $localize`Fact-checking`,
            $localize`I need help`,
            $localize`Other`,
        ];
    }
}
