<ng-container *ngIf="type === 'select'">
    <span
        class="item-value"
        [ngClass]="{ capitalize: capitalize }"
        (click)="$event.stopPropagation()"
        [matMenuTriggerFor]="itemsMenu"
        >{{ value }}</span
    >
    <mat-menu #itemsMenu="matMenu">
        <button (click)="onValueChange.emit(option.value)" *ngFor="let option of selectionOptions" mat-menu-item>
            <span>{{ option.title }}</span>
        </button>
    </mat-menu>
</ng-container>
<ng-container *ngIf="type === 'number'">
    <mat-form-field class="w-100 number">
        <input matInput type="number" [min]="min" [max]="max" [formControl]="numberControl" />
    </mat-form-field>
</ng-container>
