import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from '@klickdata/core/message';

@Component({
    selector: 'app-welcome-message-dialog',
    templateUrl: './welcome-message-dialog.component.html',
    styleUrls: ['./welcome-message-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeMessageDialogComponent implements OnInit {
    public title: string;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected messageService: MessageService,
        protected dialogRef: MatDialogRef<WelcomeMessageDialogComponent>
    ) {
        this.title = $localize`:@@sendEmail:Send E-mail`;
    }
    ngOnInit(): void {
        if (this.data.title) {
            this.title = $localize`:@@sendEmailTo:Send E-mail to ` + this.data.title;
        }

    }

    public onMessageSent() {
        this.dialogRef.close();
    }

    public onClose() {
        this.dialogRef.close();
    }
}
