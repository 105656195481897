import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { MsgCase, MsgCaseData } from '@klickdata/core/notification';
import { AppScope } from '@klickdata/core/resource';
import { Filter, TableFilterComponent, TableSource } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { UsersCasesService } from './users-cases.service';
import { Customer } from '@klickdata/core/customer';

@Component({
    selector: 'app-shared-log-users-tabs-cases',
    templateUrl: './shared-log-users-tabs-cases.component.html',
    styleUrls: ['./shared-log-users-tabs-cases.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UsersCasesService],
})
export class SharedLogUsersTabsCasesComponent implements OnInit, AfterViewInit {
    @Input() user: User;
    @Input() customer: Customer;
    @Input() isMobile: boolean;
    AppScope = AppScope;
    public dataSource = new TableSource<MsgCase>();
    public columns = ['title', 'author', 'created_at', 'end_date', 'status', 'status_time'];
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(protected casesService: UsersCasesService, protected mobileService: MobileService) {}

    ngOnInit(): void {
        this.dataSource.service = this.casesService;
    }
    public getUsersStatus(msgCase: any) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.USERS_CASE_STATUS,
            data: {
                icon: 'school',
                title: $localize`${msgCase.title}`,
                users: msgCase.users,
                positiveBtn: $localize`Done`,
            },
        });
    }
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        if (this.filter) {
            if (this.customer) {
                this.filter.createOrUpdateFilter([new Filter('customer', [this.customer.id])]);
            } else if (this.user) {
                this.filter.createOrUpdateFilter([new Filter('user', [this.user.id])]);
            }
        }
    }
}
