<div *ngIf="data.customer" fxFlex="0 0 100%" fxLayout="row wrap" class="assignments-select">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.perm.title"
        [contextIcon]="data.perm.icon"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss()"
        [savingStatus]="saving"
    ></app-sticky-header>
    <mat-tab-group class="standard-mat" fxFlex="0 0 90%" ngClass.xs="xs">
        <mat-tab>
            <ng-template mat-tab-label>
                <span>{{ getScopeName(data.customer, AppScope.USERS) }}</span>
            </ng-template>
            <app-admin-users-select
                referenceFilter="referenceResource"
                [filtersToBeApplied]="[
                    { property: 'permissionType', items: [data.perm.type] },
                    { property: 'dir', items: [data.sortingDir] }
                ]"
                [accessForm]="permForm.get('users') | cast"
                [columns]="['select', 'name', 'email']"
                sync_all_key="sync_all"
                attachKey="attach_ids"
                detachKey="detach_ids"
                class="mt-1"
            ></app-admin-users-select>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <span>{{ getScopeName(data.customer, AppScope.GROUPS) }}</span>
            </ng-template>
            <app-admin-groups-select
                referenceFilter="referenceResource"
                [filtersToBeApplied]="[
                    { property: 'permissionType', items: [data.perm.type] },
                    { property: 'dir', items: [data.sortingDir] }
                ]"
                [accessForm]="permForm.get('groups') | cast"
                sync_all_key="sync_all"
                attachKey="attach_ids"
                detachKey="detach_ids"
                class="mt-1"
            ></app-admin-groups-select>
        </mat-tab>

        <mat-tab *ngIf="data.customer.division_mode">
            <ng-template mat-tab-label>
                <span>{{ getScopeName(data.customer, AppScope.TEAM) }}</span>
            </ng-template>
            <app-admin-teams-select
                referenceFilter="referenceResource"
                [filtersToBeApplied]="[
                    { property: 'permissionType', items: [data.perm.type] },
                    { property: 'dir', items: [data.sortingDir] }
                ]"
                [accessForm]="permForm.get('teams') | cast"
                sync_all_key="sync_all"
                attachKey="attach_ids"
                detachKey="detach_ids"
                class="mt-1"
            ></app-admin-teams-select>
        </mat-tab>
    </mat-tab-group>
</div>
