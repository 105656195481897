<ng-container *ngIf="comments">
    <div class="wrapper" *ngFor="let comment of comments">
        <div class="date">
            <app-date-time-picker-item
                (click)="$event.stopPropagation()"
                [value]="comment.created_at"
                (onDateTimeChanged)="updateComment($event, comment)"
            ></app-date-time-picker-item>
        </div>
        <!-- <div class="date">{{ comment.created_at | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</div> -->
        <div [matTooltip]="comment.author?.fname + ' ' + comment.author?.lname" class="sign">
            {{ comment.author.fname | nameSign }}{{ comment.author.lname | nameSign }}
        </div>
        <div class="comment">{{ comment.body }}</div>
        <div class="actions">
            <button
                *ngIf="comment.media_ids && comment.media_ids.length"
                (click)="$event.stopPropagation(); showCommentMedia(comment)"
                matTooltip="View attachment"
                mat-icon-button
                i18n-matTooltip="@@viewAttachment"
                class="comment-add"
            >
                <mat-icon>attachment</mat-icon>
            </button>
            <button
                (click)="$event.stopPropagation(); editComment(comment)"
                matTooltip="Edit"
                mat-icon-button
                i18n-matTooltip="@@edit"
                class="comment-add"
            >
                <mat-icon>edit</mat-icon>
            </button>
            <button
                (click)="$event.stopPropagation(); deleteComment(comment)"
                matTooltip="Delete"
                mat-icon-button
                i18n-matTooltip="@@delete"
                class="comment-add"
            >
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </div>
</ng-container>
