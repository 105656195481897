<ng-container *ngIf="course">
    <a
        [routerLink]="['/home/courses/' + course.article_code]"
        title="{{ course.title }}"
        [queryParams]="eCourseItem ? { position: eCourseItem.play_position } : {}"
        class="course-prev"
    >
        <!-- If design approved comment this div-->
        <div class="banner" fxLayout="column" fxFlexAlign="center center">
            <mat-icon fxFlex="0 1 auto" class="color-light md-48">
                play_circle_filled
            </mat-icon>
            <h4 fxFlex="1 1 auto">{{ course.title }}</h4>
        </div>
        <app-image-view
            *ngIf="course.media$ || course.media_id; else placeholder"
            [media_id]="course.media$ || course.media_id"
            (srcLoaded)="srcLoaded.emit($event)"
        ></app-image-view>
        <ng-template #placeholder>
            <div
                class="imagewrapper"
                style="background-image: url('assets/images/dashboard/course-preview-placeholder.png')"
            >
                <img src="assets/images/dashboard/course-preview-placeholder.png" alt="course preview placeholder" />
            </div>
        </ng-template>
        <!-- If design approved uncomment this -->
        <!-- <div
            class="banner"
            fxLayout="row"
            fxFlexAlign="center center">
            <h4 fxFlex="1 1 auto">{{course.title}}</h4>
            <mat-icon
                fxFlex="0 1 auto"
                class="color-red md-48">
                play_circle_filled
            </mat-icon>
            <div class="white-box"></div>
        </div> -->
    </a>
</ng-container>
