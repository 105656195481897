import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { MessageService } from '@klickdata/core/message';
import { AppScope } from '@klickdata/core/resource';
import { Observable, Subject } from 'rxjs';
import { MediaService } from '../media.service';
import { S3MediaService } from '../s3-media.service';
import { UploaderBaseComponent } from '../uploader-base.component';

@Component({
    selector: 'app-media-attachment',
    templateUrl: './media-attachment.component.html',
    styleUrls: ['./media-attachment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: UploaderBaseComponent, useExisting: forwardRef(() => MediaAttachmentComponent) },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MediaAttachmentComponent),
            multi: true,
        },
    ],
})
export class MediaAttachmentComponent extends UploaderBaseComponent implements ControlValueAccessor, OnDestroy {
    @Input() multiple = true;
    @Input() isCreateForm: boolean;
    @Input() viewMode: 'normal' | 'disabled' = 'normal';
    @Input() type: 'link' | 'file' | 'both' = 'both';
    @Input() isIconBtns = true;
    @Input() fileTooltip = $localize`Attach file`;
    @Input() linkTooltip = $localize`Add Youtube or Vimeo link`;
    @Input() dropLabel = $localize`Drop file`;
    public saving$: Observable<boolean>;
    AppScope = AppScope;
    public destroy: Subject<boolean> = new Subject<boolean>();
    constructor(
        protected dialog: MatDialog,
        protected mediaService: MediaService,
        protected auth: AuthService,
        protected token: AuthService,
        protected cd: ChangeDetectorRef,
        protected messageService: MessageService,
        protected s3Service: S3MediaService
    ) {
        super(dialog, mediaService, auth, token, cd, messageService, s3Service);
    }

    public fileOverBase(ev: any): void {}

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }
    public openLink(url: string) {
        window.open(url, '_blank');
    }

    public registerOnTouched(fn: any): void {}

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
