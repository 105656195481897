import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { ResourceTypes, AppScope } from '@klickdata/core/resource';
import { ResourceCategory, ResourceCategoryData } from '@klickdata/core/resource/src/category/resource-category.model';
import { Resource, ResourceData } from '@klickdata/core/resource/src/resource.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class TestsCatalogService {
    protected resourceUrl: string;
    protected categoryUrl: string;
    protected search: BehaviorSubject<string> = new BehaviorSubject('');
    protected hideCategoryUpdate: Subject<ResourceCategory> = new Subject();
    protected user_id: Observable<number>;
    protected customer_id: Observable<number>;

    constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected config: ConfigService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}resources`;
        this.categoryUrl = `${this.config.config.apiUrl}categories`;

        this.user_id = this.auth.getUser().pipe(
            first(),
            map(user => user.id)
        );

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map(customer => customer.id)
        );
    }

    public updateSearch(term: string): void {
        this.search.next(term);
    }

    public getSearch(): Observable<string> {
        return this.search.asObservable();
    }

    public getSearchValue(): string {
        return this.search.value;
    }

    public getHideCategoryUpdate(): Observable<ResourceCategory> {
        return this.hideCategoryUpdate.asObservable();
    }

    public getMyTests(isPublic?: string): Observable<Resource[]> {
        const build = this.builder
            .get<ResourceData[]>(this.resourceUrl)
            .param('user', this.user_id)
            .param('scope', AppScope.TEST);

        if (isPublic) {
            build.param('public', isPublic);
        }

        return build.request().pipe(map(res => this.mapResources(res.data)));
    }

    public getMyCustomerTests(): Observable<Resource[]> {
        return this.builder
            .get<ResourceData[]>(this.resourceUrl)
            .param('user', this.user_id)
            .param('scope', AppScope.TEST)
            .request()
            .pipe(map(res => this.mapResources(res.data)));
    }

    public getMyTestsByCategoryAndQuery(
        category: ResourceCategory,
        query: string,
        page?: number,
        limit?: number
    ): Observable<Resource[]> {
        const req = this.builder
            .get<ResourceData[]>(this.resourceUrl)
            .param('user', this.user_id)
            .param('scope', AppScope.TEST)
            .param('categories', category.id.toString())
            .param('sort', 'title')
            .param('published', 1)
            .param('query', query)
            .page(page)
            .limit(limit);

        return req.request().pipe(map(res => this.mapResources(res.data)));
    }

    public getStartedTests(limit: number = 25): Observable<Resource[]> {
        return this.search.pipe(
            switchMap(query => {
                return this.builder
                    .get<ResourceData[]>(this.resourceUrl)
                    .param('started', this.user_id)
                    .param('scope', AppScope.TEST)
                    .limit(limit)
                    .param('query', query)
                    .request()
                    .pipe(map(res => this.mapResources(res.data)));
            })
        );
    }

    public getMyPublicRootCategories(): Observable<ResourceCategory[]> {
        return this.builder
            .get<ResourceCategoryData[]>(this.categoryUrl)
            .param('user', this.user_id)
            .param('public', 'true')
            .param('parent', 'null')
            .param('type', ResourceTypes.TEST)
            .param('sort', 'weight,title')
            .param('dir', 'desc')
            .request()
            .pipe(map(res => this.mapCategories(res.data)));
    }

    public getMyCustomerRootCategories(): Observable<ResourceCategory[]> {
        return this.builder
            .get<ResourceCategoryData[]>(this.categoryUrl)
            .param('user', this.user_id)
            .param('customer', this.customer_id)
            .param('parent', 'null')
            .param('type', ResourceTypes.TEST)
            .param('sort', 'weight,title')
            .param('dir', 'desc')
            .request()
            .pipe(map(res => this.mapCategories(res.data)));
    }

    public getMyRootCategories(): Observable<ResourceCategory[]> {
        return this.builder
            .get<ResourceCategoryData[]>(this.categoryUrl)
            .param('user', this.user_id)
            .param('parent', 'null')
            .param('type', ResourceTypes.TEST)
            .param('sort', 'weight,title')
            .param('dir', 'desc')
            .request()
            .pipe(map(res => this.mapCategories(res.data)));
    }

    public getMyCategoriesByParent(category: ResourceCategory): Observable<ResourceCategory[]> {
        return this.builder
            .get<ResourceCategoryData[]>(this.categoryUrl)
            .param('user', this.user_id)
            .param('parent', category.id.toString())
            .param('type', ResourceTypes.TEST)
            .param('sort', 'weight,title')
            .param('dir', 'desc')
            .request()
            .pipe(map(res => this.mapCategories(res.data)));
    }

    public getMyCategoriesByTests(course: Resource): Observable<ResourceCategory[]> {
        return this.builder
            .get<ResourceCategoryData[]>(this.categoryUrl)
            .param('user', this.user_id)
            .param('course', course.id.toString())
            .param('sort', 'weight,title')
            .param('dir', 'desc')
            .request()
            .pipe(map(res => this.mapCategories(res.data)));
    }

    protected mapResources(data: ResourceData[]): Resource[] {
        return data.map(item => this.makeResource(item));
    }

    protected makeResource(data: ResourceData): Resource {
        const resource = new Resource(data);
        resource.categories$ = this.getMyCategoriesByTests(resource);

        return resource;
    }

    protected mapCategories(data: ResourceCategoryData[]): ResourceCategory[] {
        return data.map(item => this.makeCategory(item));
    }

    protected makeCategory(data: ResourceCategoryData): ResourceCategory {
        const category = new ResourceCategory(data);
        category.children = this.getMyCategoriesByParent(category);
        category.resources$ = this.search.pipe(switchMap(query => this.getMyTestsByCategoryAndQuery(category, query)));

        // handle lazy load more
        if (category.resources_attached) {
            category.resourcesPaginator = [];

            /**
             * TODO handle load more total.
             */
            // for (let i = 0; i < category.resource_ids.length; i += category.limit) {
            category.resourcesPaginator.push(
                this.search.pipe(
                    tap(quary => {
                        category.loading = true;
                        if (typeof category.page === 'undefined' || category.query !== quary || category.hide) {
                            category.page = 0;
                            category.loadedResources = [];
                            category.query = quary;
                        }
                    }),
                    switchMap(query => {
                        const searchMatched =
                            category.title.toLowerCase().indexOf(query) !== -1 ||
                            category.parentTitle.toLowerCase().indexOf(query) !== -1;
                        return this.getMyTestsByCategoryAndQuery(
                            category,
                            !searchMatched ? query : '',
                            category.page,
                            category.limit
                        ).pipe(
                            tap(res => {
                                category.loadedResources = category.loadedResources.concat(res);
                                category.page++;
                                category.hide = !!query && !category.loadedResources.length && !searchMatched;
                                if (category.hide) {
                                    this.hideCategoryUpdate.next(category);
                                }
                                category.loading = false;
                            })
                        );
                    })
                )
            );
            // }
        }
        return category;
    }
}
