import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector: 'app-message-error',
    templateUrl: './message-error.component.html',
    styleUrls: ['./message-error.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageErrorComponent implements OnInit {
    @Input()
    public error: string;
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string }) {
        if (data) {
            this.error = data.message;
        }
    }

    ngOnInit() {}
}
