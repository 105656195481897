import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    EventEmitter,
    Output,
    Input,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { Language, LanguageService } from '@klickdata/core/localization';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-item-translator',
    templateUrl: './item-translator.component.html',
    styleUrls: ['./item-translator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemTranslatorComponent implements OnInit, OnDestroy {
    @Input() disabled: boolean;
    @Input() languageId: number;
    public languages$: Observable<Language[]>;
    public selectedLang: Language;
    @Output() onTranslate: EventEmitter<number> = new EventEmitter<number>();
    public destroy: Subject<boolean> = new Subject<boolean>();

    constructor(private localizationService: LanguageService, protected cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.languages$ = this.localizationService.supportLanguages;
        if (this.languageId) {
            this.localizationService
                .getLanguageByKey(this.languageId)
                .pipe(takeUntil(this.destroy))
                .subscribe((language) => {
                    this.selectedLang = language;
                    this.cdr.markForCheck();
                });
        }
    }
    changeLanguage(lang: Language) {
        this.onTranslate.emit(lang.id);
    }
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
