<div
    class="form-field chips-container"
    fxLayoutGap="0px"
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutGap.gt-sm="15px"
>
    <div class="chips-wrapper" fxFlex="grow" fxLayout="column" fxLayoutGap="10px">
        <div fxLayoutGap="5px">
            <span class="header-cont-title"> {{ mainTitle }}</span>
            <mat-icon class="desc-tooltip" [matTooltip]="subTitle">question_mark</mat-icon>
        </div>
        <div
            [class.is-user-chip]="itemScope === AppScope.USERS"
            *ngIf="!(loadingUserSelectedItems | async)"
            class="tags"
            fxLayout="row wrap"
            fxLayoutGap="15px"
        >
            <mat-chip
                [disableRipple]="true"
                *ngFor="let item of activeItemsSelection"
                [removable]="true"
                (removed)="onItemRemoval.emit(item)"
            >
                <app-image-view
                    *ngIf="itemScope === AppScope.USERS"
                    [ngStyle]="{ minWidth: '50px' }"
                    [isCircularImg]="true"
                    [media_id]="item.media"
                    paddingBottom="50px"
                >
                    <mat-icon class="rem-user" matChipRemove> cancel </mat-icon></app-image-view
                >
                <span>{{ itemScope === AppScope.USERS ? item.name : item.title }}</span>
                <mat-icon matChipRemove> cancel </mat-icon>
            </mat-chip>
        </div>
        <div *ngIf="loadingUserSelectedItems | async">
            <app-kd-logo-loading size="small"></app-kd-logo-loading>
        </div>
    </div>

    <div class="add-process-wrapper" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <ng-container *ngIf="fullItemsList$ | async; else loading; let fullItems">
            <mat-form-field [class.open-control]="toggleSearchField">
                <mat-label>{{ searchInputLabel }}</mat-label>
                <input
                    type="text"
                    matInput
                    [formControl]="addItemControl"
                    [matAutocomplete]="auto"
                    #autoCompleteInput
                    #trigger="matAutocompleteTrigger"
                    (keyup)="onSearchInput.emit(autoCompleteInput.value)"
                />
                <mat-autocomplete
                    [displayWith]="displayName"
                    #auto="matAutocomplete"
                    (optionSelected)="
                        onItemSelection.emit($event.option.value); addItemControl.setValue(''); reInitPanelSelection()
                    "
                >
                    <mat-option *ngFor="let item of fullItems" [value]="item">
                        {{ itemScope === AppScope.USERS ? (item.name | spaceToNewLineString) : item.title }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <button
                class="add-Btn"
                matTooltip="Add"
                mat-icon-button
                i18n-matTooltip="@@add"
                (click)="toggleSearchField = !toggleSearchField; toggleAutoCompletPanel(toggleSearchField)"
                [class.close-control]="toggleSearchField"
            >
                <mat-icon>add_circle</mat-icon>
            </button>
        </ng-container>
    </div>
</div>
<ng-template #loading>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
