import { Pipe, PipeTransform } from '@angular/core';
import { SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';

@Pipe({
    name: 'actionLogEvent',
})
export class ActionLogEventPipe implements PipeTransform {
    transform(value: string): SelectFilterOption {
        return Utils.getActionLogEventOptions().find((option) => option.value === value);
    }
}
