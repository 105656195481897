import { Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ConfigService } from '@klickdata/core/config';
import { FormHelper } from '@klickdata/core/form';
import { GradeSystem } from '@klickdata/core/grade-system';
import { LanguageService } from '@klickdata/core/localization';
import { Resource } from '@klickdata/core/resource';
import { GradeSystemService } from 'apps/klickdata/src/app/admin/core/grade-system/grade-system.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-admin-grade-system-control',
    templateUrl: './grade-system-control.component.html',
    styleUrls: ['./grade-system-control.component.scss'],
})
export class GradeSystemControlComponent implements OnInit, OnDestroy {
    @Input() gradeControl: FormControl;
    @Input() resource: Resource;
    @Input() set languageId(languageId: number) {
        this.language$.next(languageId);
    }
    private language$: BehaviorSubject<number | string> = new BehaviorSubject<number | string>(null);
    public gradesSystems: Observable<GradeSystem[]>;
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected gradeSystemService: GradeSystemService,
        protected configService: ConfigService,
        protected languageService: LanguageService,
        @Inject(LOCALE_ID) protected localeId: string
    ) {
        this.language$.next(localeId === 'en' ? 'en-US' : localeId);
    }

    public ngOnInit(): void {
        this.gradesSystems = combineLatest([
            this.gradeSystemService.getGradeSystems(this.resource).pipe(
                finalize(() => {
                    if (!this.gradeControl.value) {
                        this.patchDefaultGradeSystem();
                    }
                })
            ),
            this.language$.pipe(
                filter(lngid => !!lngid),
                switchMap(lngid => this.languageService.getLanguageByKey(lngid))
            ),
        ]).pipe(
            map(([grades, language]) => {
                grades.map(
                    gradeSystem =>
                        (gradeSystem.title =
                            gradeSystem.title_translation[language.value] ||
                            gradeSystem.title_translation[0] ||
                            gradeSystem.title)
                );
                return grades;
            })
        );

        this.gradeControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe(change => {
            if (!change) {
                this.patchDefaultGradeSystem();
            }
        });
    }

    private patchDefaultGradeSystem() {
        this.gradeControl.patchValue(this.configService.config.defaultGradeSystemId, { emitEvent: false });
        FormHelper.resetForm(this.gradeControl);
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
