import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'spaceToNewLineString',
})
export class SpaceToNewLineStringPipe implements PipeTransform {
    transform(value: string): string {
        return value?.replace(/(?:\r\n|\r|\n)/g, '<br/>');
    }
}
