import { IDataModel, Model } from '@klickdata/core/application';

export interface WidgetData extends IDataModel {
    id?: number;
    total?: any;
    order?: number;
    customer_id?: number;
    name?: string;
    color?: string;
    event?: string;
    description?: string;
    visible?: boolean;
    details?: WidgetDetails[];
}

export class Widget extends Model<WidgetData> {
    id: number;
    total: any;
    order: number;
    customer_id: number;
    name: string;
    color: string;
    event: string;
    description: string;
    visible: boolean;
    details: WidgetDetails[];
}

export interface WidgetDetails {
    title: string;
    value: number;
    percentage: number;
}
