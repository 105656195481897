import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    LOCALE_ID,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MobileService } from '@klickdata/core/mobile';
import { Resource } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    GlobalFilterPropertyType,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { shareReplay, takeUntil } from 'rxjs';
import { UserResourcesPermissionsHandlerComponent } from '../resource-access-control-sheet/user-resources-permissions-handler/user-resources-permissions-handler.component';
import { ResourceListingService } from './resource-listing.service';

@Component({
    selector: 'app-user-resources-access-control',
    templateUrl: './user-resources-access-control.component.html',
    styleUrls: ['./user-resources-access-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceListingService],
})
export class UserResourcesAccessControlComponent extends OnDestroyHandler implements OnInit, AfterViewInit {
    @Input() user: User;
    @Input() resourcePermissionsArray: FormArray;
    public resourcePermissions: {
        index: number;
        value: string;
        label: string;
        tooltip?: string;
        disableUser?: boolean;
    }[];
    @ViewChildren(UserResourcesPermissionsHandlerComponent)
    resourcePermHandlerchilldren: QueryList<UserResourcesPermissionsHandlerComponent>;
    public resourceTypeOptions: SelectFilterOption[];
    public dataSource = new TableSource<Resource>();
    public columns = ['select', 'title', 'permissions'];
    public isMobile: boolean;
    GlobalFilterProperty = GlobalFilterProperty;
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;

    constructor(
        protected mobile: MobileService,
        protected cdRef: ChangeDetectorRef,
        protected resourceListingService: ResourceListingService,
        @Inject(LOCALE_ID) public localeId: string
    ) {
        super();
        this.resourcePermissions = Utils.getResourcePermissions();
        this.resourceTypeOptions = Utils.getResourceTypeOptions();
    }

    ngOnInit(): void {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((mobile) => {
                this.isMobile = mobile;
                this.cdRef.markForCheck();
            });
        this.dataSource.service = this.resourceListingService;
    }
    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.filter.createOrUpdateWithoutEmitFilter([
            new Filter('referenceUser', [this.user.id]),
            new Filter('assignedOrCustomer', [this.user.customer_id]),
        ]);
    }
    selectToggle(resource: Resource, status: boolean) {
        this.resourcePermHandlerchilldren.forEach((child) => child.toggleAll(status, resource.id));
    }
}
