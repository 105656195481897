import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userRoleIcon',
})
export class UserRoleIconPipe implements PipeTransform {
    transform(role: string): string {
        switch (role) {
            case 'user':
                return 'person';
            case 'groupadmin':
                return 'group';
            case 'customeradmin':
                return 'supervised_user_circle';
            case 'superadmin':
                return 'admin_panel_settings';
            default:
                return 'person';
        }
    }
}
