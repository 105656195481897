import {Component} from '@angular/core';

@Component({
    selector: 'app-message-duplicated',
    templateUrl: './message-duplicated.component.html',
    styleUrls: ['./message-duplicated.component.scss']
})
export class MessageDuplicatedComponent {
    constructor() {
    }
}
