import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { MediaAttachmentComponent } from '@klickdata/core/media/src/media-attachment/media-attachment.component';
import { MessageFormErrorComponent, MessageService } from '@klickdata/core/message';
import {
    MessageContextTypes,
    MessageScopes,
    SubMessage,
    Notification,
    NotificationService,
} from '@klickdata/core/notification';
import { AppScope } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { map, scan } from 'rxjs/operators';

@Component({
    selector: 'app-message-dialogue',
    templateUrl: './message-dialogue.component.html',
    styleUrls: ['./message-dialogue.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageDialogueComponent implements OnInit, OnDestroy {
    @ViewChild('message') message: ElementRef<HTMLInputElement>;
    @ViewChild(MediaAttachmentComponent) mediaAttachments: MediaAttachmentComponent;
    @Input() public msg: Notification;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public subMessageForm: FormGroup;
    @Input() user: User;
    @Input() msgContextType: MessageContextTypes;
    AppScope = AppScope;
    public dialogs$: Observable<SubMessage[]>;
    public updateDialogSubject: Subject<SubMessage> = new Subject<SubMessage>();
    public onUploading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    MessageScopes = MessageScopes;
    constructor(
        protected fb: FormBuilder,
        protected notificationService: NotificationService,
        protected messageService: MessageService,
        protected changeRef: ChangeDetectorRef
    ) {
        this.buildForm();
    }

    ngOnInit(): void {
        this.dialogs$ = merge(
            this.notificationService.getSubMessages(this.msg.id).pipe(
                map((res) => res.data),
                map((dialogs) =>
                    this.msg.body && this.msg.scope_id !== MessageScopes.CASE
                        ? [
                              new SubMessage({
                                  body: this.msg.body,
                                  author_id: this.msg.author.id,
                                  author: this.msg.author$,
                                  created_at: this.msg.created_at,
                              }),
                              ...dialogs,
                          ]
                        : dialogs
                )
            ),
            this.updateDialogSubject.asObservable(),
            this.notificationService.listenToNewDialog(this.msg.id)
        ).pipe(scan((acc, val) => acc.concat(val), <SubMessage[]>[]));
    }

    public buildForm() {
        this.subMessageForm = this.fb.group({
            body: [''],
            media_ids: [[]],
        });
    }
    public submitMsg() {
        if (!this.subMessageForm.valid) {
            FormHelper.markForm(this.subMessageForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            return;
        }
        this.createMsg();
    }
    createMsg() {
        this.notificationService
            .createSubMessage({
                parent_id: this.msg.id,
                body: this.subMessageForm.get('body').value,
                media_ids: this.subMessageForm.get('media_ids').value,
            })
            .subscribe((msg) => {
                this.mediaAttachments.mediaItems.length = 0;
                this.updateDialogSubject.next(msg);
                this.message.nativeElement.value = '';
                this.subMessageForm.get('body').setValue('');
                this.changeRef.markForCheck();
            });
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
