import {AccessCapability} from '../can-access.directive';

export enum ImportCapability {
    NONE              = 0,
    MANAGE_CUSTOMER_IMPORTS = 1 << 1,
    MANAGE_GROUP_IMPORTS = 1 << 2,
    USE_GROUP_TEMPLATES = 1 << 3,
    USE_CUSTOMER_TEMPLATES = 1 << 4,
    MANAGE_CUSTOMER_TEMPLATES = 1 << 5,
    USE_PUBLIC_TEMPLATES = 1 << 6,
    MANAGE_PUBLIC_TEMPLATES = 1 << 7,
}

export namespace ImportCapability {
    export function getName(): string {
        return 'import';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
