import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LoggerService } from '@klickdata/core/application';
import { ConfigService } from '@klickdata/core/config';
import { MediaService } from '../media.service';
import { S3MediaService } from '../s3-media.service';
import { MediaViewComponent } from '../media-view/media-view.component';
import { ExpandAnimation } from '@klickdata/core/animations/expand.animation';
import { Media } from '../media.model';
import { MediaType } from '../media-type';
import { map, catchError, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IframeDisplayLinkComponent } from '@klickdata/shared-components/src/iframe-display-link/iframe-display-link.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
// import { ResourceItem } from '@klickdata/core/resource-item';

@Component({
    selector: 'app-player-media-view',
    templateUrl: './player-media-view.component.html',
    styleUrls: ['./player-media-view.component.scss'],
    animations: [ExpandAnimation],
})
export class PlayerMediaViewComponent extends MediaViewComponent {
    public linkDisplay: 'bottomSheet' | 'browser' | null = 'browser';
    @Output() onLinkDisplayDetected: EventEmitter<'bottomSheet' | 'browser' | null> = new EventEmitter<
        'bottomSheet' | 'browser' | null
    >();
    constructor(
        protected s3MediaService: S3MediaService,
        protected mediaService: MediaService,
        protected configService: ConfigService,
        protected elementRef: ElementRef,
        protected location: Location,
        protected logger: LoggerService,
        private http: HttpClient,
        protected bottomSheet: MatBottomSheet
    ) {
        super(s3MediaService, mediaService, configService, elementRef, location, logger);
    }

    onUpdateMedia(media: Media) {
        super.onUpdateMedia(media);
        if (media.mediaType == MediaType.URL || media.mediaType == MediaType.LINK) {
            this.played.emit(true);
            this.http
                .head(media.url || media.src, { observe: 'response' })
                .pipe(
                    map((response) => {
                        const headers = response.headers;
                        const statusCode = response.status;
                        const hasCSP = headers.has('content-security-policy') || headers.has('Content-Security-Policy');
                        const hasCORS =
                            headers.has('access-control-allow-origin') || headers.has('Access-Control-Allow-Origin');
                        const xFrameOptions = headers.get('x-frame-options') || headers.get('X-Frame-Options');
                        const hasXFrameOptions =
                            xFrameOptions === 'DENY' ||
                            xFrameOptions === 'sameorigin' ||
                            xFrameOptions === 'SAMEORIGIN' ||
                            xFrameOptions?.startsWith('ALLOW-FROM');
                        const hasContentTypeOptions =
                            headers.get('x-content-type-options') === 'nosniff' ||
                            headers.get('X-Content-Type-Options') === 'nosniff';
                        const xssProtection = headers.get('x-xss-protection') || headers.get('X-XSS-Protection');
                        const hasXSSProtection = xssProtection === '1; mode=block' || xssProtection === '0';
                        const hasReferrerPolicy = headers.has('referrer-policy') || headers.has('Referrer-Policy');
                        const hasFeaturePolicy =
                            headers.has('feature-policy') ||
                            headers.has('Feature-Policy') ||
                            headers.has('permissions-policy') ||
                            headers.has('Permissions-Policy');

                        return {
                            hasCSP,
                            hasCORS,
                            hasXFrameOptions,
                            hasContentTypeOptions,
                            hasXSSProtection,
                            hasReferrerPolicy,
                            hasFeaturePolicy,
                            statusCode,
                        };
                    }),

                    catchError((error) => {
                        return of({
                            hasCSP: true,
                            hasCORS: true,
                            hasXFrameOptions: true,
                            hasContentTypeOptions: true,
                            hasXSSProtection: true,
                            hasReferrerPolicy: true,
                            hasFeaturePolicy: true,
                            statusCode: error.status,
                        });
                    })
                )
                .subscribe((info) => {
                    const refusedToConnect = [403, 404, 500, 502, 503, 504].includes(info.statusCode);
                    const isBrowserOption =
                        refusedToConnect ||
                        info.hasCORS ||
                        info.hasCSP ||
                        info.hasXFrameOptions ||
                        info.hasContentTypeOptions ||
                        info.hasXSSProtection ||
                        info.hasReferrerPolicy ||
                        info.hasFeaturePolicy;
                    this.linkDisplay = isBrowserOption ? 'browser' : 'bottomSheet';
                    this.onLinkDisplayDetected.emit(isBrowserOption ? 'browser' : 'bottomSheet');
                });
        } else {
            this.linkDisplay = null;
            this.onLinkDisplayDetected.emit(null);
        }
    }
    public styleInnerHTML(description) {
        if (description) {
            return '<div class="inner-HTML">' + description + '</div>';
        } else {
            return;
        }
    }
    public onUrlClick(url: string) {
        if (this.linkDisplay == 'browser') {
            window.open(url, '_blank');
        } else {
            this.bottomSheet.open(IframeDisplayLinkComponent, {
                data: url,
                disableClose: false,
                panelClass: 'link-display-sheet',
            });
        }
    }
    firstPage() {
        this.page = 1;
        this.nextBtnClickedInPDF.emit(true);
        this.checkFinishingPDF();
    }
    lastPage() {
        this.page = this.totalPages;
        this.nextBtnClickedInPDF.emit(true);
        this.checkFinishingPDF();
    }
}
