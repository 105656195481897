import {Component} from '@angular/core';

@Component({
    selector:    'app-message-deleted',
    templateUrl: './message-deleted.component.html',
    styleUrls:   ['./message-deleted.component.scss']
})
export class MessageDeletedComponent {
    constructor() {
    }
}
