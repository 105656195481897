import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxDefaultOptions, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';

export interface CheckboxStateValues {
    property: string;
    neutralFilter: string[] | number[];
    activeFilter: string[] | number[];
    notActiveFilter: string[] | number[];
    state: null | true | false;
}
@Component({
    selector: 'app-checkbox-filter',
    templateUrl: './checkbox-filter.component.html',
    styleUrls: ['./checkbox-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { clickAction: 'noop', color: 'primary' } as MatCheckboxDefaultOptions,
        },
        { provide: FilterBase, useExisting: CheckboxFilterComponent },
    ],
})
export class CheckboxFilterComponent extends FilterBase {
    @Input() label: String;
    @Input() title: String;
    @Input()
    set stateFilter(stateFilter: CheckboxStateValues) {
        this._filter.property = stateFilter.property;
        this._stateFilter = stateFilter;
    }

    get stateFilter(): CheckboxStateValues {
        return this._stateFilter;
    }
    public _stateFilter: CheckboxStateValues;

    @Output() filterChange: EventEmitter<Filter<string | number>> = new EventEmitter<Filter<string | number>>();
    protected _filter: Filter<string | number> = new Filter<string | number>();

    public toggle() {
        switch (this.stateFilter.state) {
            case true:
                this.stateFilter.state = false;
                this._filter.items = this.stateFilter.notActiveFilter;
                break;
            case false:
                this.stateFilter.state = null;
                this._filter.items = this.stateFilter.neutralFilter;
                break;
            default:
                this.stateFilter.state = true;
                this._filter.items = this.stateFilter.activeFilter;
        }
        this.filterChange.emit(this._filter);
    }

    public clear(): void {}
}
