import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
    selector: 'app-iframe-display-link',
    templateUrl: './iframe-display-link.component.html',
    styleUrls: ['./iframe-display-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This comp update the user tags only, not user task tags
 */
export class IframeDisplayLinkComponent {
    constructor(
        public bottomSheetRef: MatBottomSheetRef<IframeDisplayLinkComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: string
    ) {}
}
