import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { CaseStatus, CaseStatusTypes, MsgCase, NotificationService } from '@klickdata/core/notification';
import { User } from '@klickdata/core/user';
import { combineLatest, EMPTY, merge, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, tap, takeUntil } from 'rxjs/operators';
import moment from 'moment';

@Component({
    selector: 'app-message-case-status-action',
    templateUrl: './message-case-status-action.component.html',
    styleUrls: ['./message-case-status-action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageCaseStatusActionComponent implements OnInit, OnDestroy {
    @Input() message_id: number;
    @Input() ownUser: User;
    @Input() msgCase: MsgCase;
    public comment: FormControl = new FormControl();
    public updatedMsgCase$: Observable<MsgCase>;
    private UpdateCaseSub$: Subject<MsgCase> = new Subject();
    public updateStatusSub$: Subject<any> = new Subject();
    CaseStatusTypes = CaseStatusTypes;
    public expandAll: boolean;
    public onEditMode: boolean;
    private destroy: Subject<boolean> = new Subject<boolean>();
    constructor(
        protected notificationService: NotificationService,
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService,
        @Inject(LOCALE_ID) public localeId: string
    ) {}

    ngOnInit(): void {
        if (this.msgCase) {
            this.updatedMsgCase$ = merge(
                of(this.msgCase),
                this.notificationService.listenToNewCaseStatus(this.msgCase.id),
                this.UpdateCaseSub$
            );
        }
        combineLatest([
            this.mobile.getSideNavAction().pipe(
                tap((decision) => {
                    this.onEditMode = decision === SideNaveActionsTypes.POSITIVE;
                    this.changeRef.markForCheck();
                })
            ),
            this.updateStatusSub$.asObservable().pipe(distinctUntilChanged()),
        ])
            .pipe(
                takeUntil(this.destroy),
                switchMap(([action, status]) =>
                    action === SideNaveActionsTypes.POSITIVE
                        ? this.notificationService.updateUserMsgCaseStatus(
                              status.id,
                              { type: status.status },
                              status.uid
                          )
                        : EMPTY
                )
            )
            .subscribe((msgCase) => this.UpdateCaseSub$.next(msgCase));
    }
    public onUserClick(id: number) {}
    public updateCaseStatus(action: string, msgId: number, comment?: string, uid?: number) {
        if (comment) {
            this.notificationService
                .updateUserMsgCaseStatus(msgId, { type: action, comment: comment }, uid)
                .subscribe((msgCase) => {
                    this.UpdateCaseSub$.next(msgCase);
                    this.comment.setValue('');
                });
        } else {
            this.updateStatusSub$.next(uid ? { status: action, id: msgId, uid: uid } : { status: action, id: msgId });
        }
        this.onEditMode = false;
        this.changeRef.markForCheck();
    }
    public onToggleMenu(comment: string, warningTxt: string): void {
        if (!comment) {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.MESSAGE_ACTIONS,
                title: $localize`Are you sure?`,
                desc: warningTxt,
                actionIcon: 'check_circle',
            });
        }
    }
    public onEdit(isAdmin: boolean, status: CaseStatus) {
        isAdmin
            ? this.onToggleMenu(
                  null,
                  $localize`You have approved this case on ${moment(status.date)
                      .local()
                      .locale(this.localeId)
                      .format('YYYY-MM-DD HH:mm')},
            Do you want to reject or reopen the case? `
              )
            : this.onToggleMenu(
                  null,
                  $localize`You have submitted this case on ${moment(status.date)
                      .local()
                      .locale(this.localeId)
                      .format('YYYY-MM-DD HH:mm')},
            Do you want to resubmit or reopen the case? `
              );
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
