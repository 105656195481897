import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceTypes } from '@klickdata/core/resource';
import { ActivitySectionType, SectionType } from '@klickdata/core/resource/src/types.enum';
import { Filter, TableSource } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { UserStatisticsService } from '@klickdata/home/src/core/user-statistics.service';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { first, shareReplay, takeUntil } from 'rxjs/operators';
import { ShareService } from '../share/share.service';
import { ResourceOpportunityService } from '@klickdata/core/opportunity';

@Component({
    selector: 'app-section-items-list-view',
    templateUrl: './section-items-list-view.component.html',
    styleUrls: ['./section-items-list-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionItemsListViewComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    public set resources(resources: Resource[]) {
        this.dataSource.set(resources);
        this._resources = resources;
    }
    public get resources(): Resource[] {
        return this._resources;
    }
    private _resources: Resource[];
    SectionType = SectionType;
    ActivitySectionType = ActivitySectionType;
    @Input() type_scope_id: number;
    @Input() isRegularSection: boolean;
    public isECourseSection: boolean;
    @Input() sectionType: SectionType;
    @Input() activitySectionType: ActivitySectionType;
    @Input() selectingStyleClass: string;
    public dataSource = new TableSource<Resource>();
    public authUser: User;
    public columns: string[];
    @Input() displayedColumns: string[];
    @Input() actionHeaderBtns = ['download', 'exportcsv'];
    @Input() actionCellBtns: string[];
    @Input() allowActionHeader: boolean;
    public isMobile: boolean;
    selection = new SelectionModel<Resource>(true, []);
    public destroy: Subject<boolean> = new Subject<boolean>();
    @Output() onBrowse: EventEmitter<any> = new EventEmitter<any>();
    @Output() onFilterEmission: EventEmitter<Filter<string>[]> = new EventEmitter<Filter<string>[]>();
    @Output() onPlay: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() onHide: EventEmitter<{ resources: Resource[]; status: boolean }> = new EventEmitter<{
        resources: Resource[];
        status: boolean;
    }>();
    @Output() onFinalGrades: EventEmitter<Resource[]> = new EventEmitter<Resource[]>();
    @Output() onAssign: EventEmitter<Resource> = new EventEmitter<Resource>();
    public shareLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    ResourceTypes = ResourceTypes;
    AppScope = AppScope;
    public expandedRow: Resource | null;

    constructor(
        protected auth: AuthService,
        protected mobile: MobileService,
        public shareService: ShareService,
        protected router: Router,
        protected dialog: MatDialog,
        protected location: Location,
        protected userStatisticsService: UserStatisticsService,
        protected resourceOpportunityService: ResourceOpportunityService,
        protected downloadHelper: DownloadHelperService,
        protected bottomSheet: MatBottomSheet,
        protected messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.mobile
            .isMobile()
            .pipe(shareReplay(), takeUntil(this.destroy))
            .subscribe((isMobile) => (this.isMobile = isMobile));
        this.columns = ['columns', 'title', 'created_at', 'created_by', 'language_id'];
        if (
            this.sectionType === SectionType.REGULAR_SECTION &&
            ResourceTypes.parentType(this.resources.map((res) => res.type_id)[0]) === ResourceTypes.E_COURSE &&
            !this.isMobile
        ) {
            this.columns = ['columns', 'title', 'summary', 'producedBy', 'instructor', 'language_id'];
            this.isECourseSection = true;
        }

        switch (this.type_scope_id) {
            case AppScope.TEST:
                this.columns.splice(4, 0, 'question_number');
                break;
            case AppScope.SURVEY:
                this.columns.splice(4, 0, 'question_number');
                break;
            case AppScope.COURSE:
                this.columns.splice(4, 0, 'examination', 'done', 'certifier');
                const index: number = this.columns.indexOf('created_by');
                if (index !== -1) {
                    this.columns.splice(index, 1);
                }
                break;
            case AppScope.MATERIAL:
                this.columns.splice(4, 0, 'material_type');
                break;
            case AppScope.E_COURSE:
                this.columns.splice(4, 0, 'price');
                if (!this.isMobile) {
                    this.columns.splice(2, 0, 'summary');
                }
                break;
        }

        if (!this.isRegularSection) {
            this.columns.splice(0, 0, 'select');
        }
        if (this.displayedColumns) {
            this.columns = this.displayedColumns;
            this.columns.push('tools_play');
        }
        if (this.isMobile) {
            this.columns.splice(3, 0, 'summary');
            this.columns.push('tools');
            if (this.columns.indexOf('done_items') !== -1) {
                this.columns.splice(this.columns.indexOf('done_items'), 1);
            }
            if (this.columns.indexOf('tools_play') !== -1) {
                this.columns.splice(this.columns.indexOf('tools_play'), 1);
            }
        }
    }
    ngAfterViewInit() {
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => {
                this.authUser = user;
            });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.resources.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ? this.selection.clear() : this.resources.forEach((row) => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Resource): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    public getPlayBtnTxt(resource: Resource): string {
        return resource.occasionStatus === 'ongoing' ? $localize`Continue` : $localize`Play`;
    }

    public sortData(fltr: any) {
        const sortedFilter = [
            { property: 'sort', items: [fltr.active], primary: false },
            { property: 'dir', items: [fltr.direction], primary: false },
        ];
        this.onFilterEmission.emit(sortedFilter);
    }

    public download(resource?: Resource) {
        if (resource?.downloads) {
            this.downloadHelper.downloadWithOptions(resource.downloads);
        } else if (resource) {
            this.messageService.openMessage(
                MessageErrorComponent,
                !resource.done
                    ? $localize`:@@completeToViewCertificate:Complete the resource to view the certificate`
                    : $localize`:@@noCertificateAvailable:No certificate available for this resource`
            );
        } else {
            // TODO handle multiply selection.
            // this.selection.selected
        }
    }

    public deleteCourseFromList(resource: Resource) {
        // if (this.onDelete.observed) return this.onDelete.emit(resource);
        this.onDelete.emit(resource);

        this.resourceOpportunityService
            .destroy(resource.opportunity_id)
            .pipe(takeUntil(this.destroy))
            .subscribe({
                complete: () => {
                    this.dataSource.removeById(resource.id);
                    this.messageService.openMessage(
                        MessageSavedComponent,
                        $localize`:@@resoureHasBeenHidden:The resource has been successfully hidden.`
                    );
                },
                error: () => {
                    this.messageService.openMessage(
                        MessageErrorComponent,
                        $localize`:@@someThingWentWrong:Oops! Something went wrong. Please try again later.`
                    );
                },
            });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
