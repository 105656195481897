import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { ShareDialogComponent } from './share-dialog/share-dialog.component';
import { ShareFbComponent } from './share-fb/share-fb.component';
import { ShareService } from './share.service';

@NgModule({
    declarations: [ShareFbComponent, ShareDialogComponent],
    imports: [CommonModule, MatDialogModule, MatIconModule, ShareButtonsModule, ShareIconsModule],
    providers: [ShareService],
})
export class ShareModule {}
