import { Model, Cast } from '../application';
import { CastType } from '../application/src/model/model-interface';

export class TranslatableModel<T> extends Model<T> {
    public id: number;
    @Cast(CastType.OBJECT)
    title_translation: { [key: string]: string };
    @Cast(CastType.OBJECT)
    description_translation?: { [key: string]: string };
}
