import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import {
    AppScope,
    ResourceCategory,
    ResourceCategoryService,
    ResourceService,
    ResourceTag,
} from '@klickdata/core/resource';
import { GlobalFilterProperty, SelectFilterOption, TableFilterComponent } from '@klickdata/core/table';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { Utils } from './../../../../core/util/src/utils';

@Component({
    selector: 'app-main-resource-catalog',
    templateUrl: './main-resource-catalog.component.html',
    styleUrls: ['./main-resource-catalog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainResourceCatalogComponent implements AfterViewInit {
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    @Input() scope: AppScope;
    public isLoading = true;
    GlobalFilterProperty = GlobalFilterProperty;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public categories$: Observable<ResourceCategory[]>;
    public catalogSourceOptions: SelectFilterOption[];
    AppScope = AppScope;

    constructor(
        protected changeRef: ChangeDetectorRef,
        protected categoryService: ResourceCategoryService,
        protected resourceService: ResourceService
    ) {
        this.catalogSourceOptions = Utils.getcatalogSourceOptions();
    }

    ngAfterViewInit() {
        this.categories$ = this.filter.filterChange.pipe(
            takeUntil(this.destroy),
            switchMap((fc) => {
                this.categoryService.onDownloadCatalog.next({ downloads: null, disabled: true, totalResCount: 0 });
                this.isLoading = true;
                return this.categoryService.getCatalogCategoriesByScope(this.scope, fc.query, fc.filters);
            }),
            tap(() => {
                this.isLoading = false;
                this.changeRef.markForCheck();
            })
        );
    }

    public onTagClick(tag: ResourceTag) {
        this.filter.setActiveFilterWithQuery(GlobalFilterProperty.TAGS, tag.name);
        setTimeout(() => window.scroll(0, 150), 500);
    }
}
