<div class="item-wrapper">
    <div *ngIf="!showInput" class="item-view">
        <span>{{ data }}</span>
        <ng-content></ng-content>
    </div>
    <app-user-setting-input *ngIf="showInput" type="text" [control]="control | cast"> </app-user-setting-input>
    <button
        *ngIf="!showInput"
        class="btn"
        matTooltip="Edit"
        mat-icon-button
        i18n-matTooltip
        (click)="showInput = !showInput; control.setValue(data)"
    >
        <mat-icon>edit</mat-icon>
    </button>
    <button
        *ngIf="!showInput"
        class="btn remove"
        matTooltip="Remove"
        mat-icon-button
        i18n-matTooltip
        (click)="onRemoveItem.emit(index)"
    >
        <mat-icon>delete_outline</mat-icon>
    </button>

    <button
        *ngIf="showInput"
        [disabled]="control.value === ''"
        class="btn"
        matTooltip="Edit"
        mat-icon-button
        i18n-matTooltip
        (click)="showInput = !showInput; onUpdateItem.emit({ data: control.value, index: index })"
    >
        <mat-icon>check_circle</mat-icon>
    </button>
</div>
