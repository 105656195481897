import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { APP_MOMENT_DATE_FORMATS } from '@klickdata/core/application/src/dates/app-date-format';
import { FormModule } from '@klickdata/core/form';
import { LocalizationModule } from '@klickdata/core/localization';
import { MobileModule } from '@klickdata/core/mobile';
import { MaterialModule } from '@klickdata/material';
import { BreadcrumbFilterComponent } from './table-filter/breadcrumb-filter/breadcrumb-filter.component';
import { CategoryChipSearchFilterComponent } from './table-filter/category-chip-search-filter/category-chip-search-filter.component';
import { CheckboxFilterComponent } from './table-filter/checkbox-filter/checkbox-filter.component';
import { CategoryChipFilterComponent } from './table-filter/chip-filter/category-chip-filter.component';
import { ChipFilterComponent } from './table-filter/chip-filter/chip-filter.component';
import { FolderChipFilterComponent } from './table-filter/chip-filter/folder-chip-filter.component';
import { GroupChipFilterComponent } from './table-filter/chip-filter/group-chip-filter.component';
import { DateFilterComponent } from './table-filter/date-filter/date-filter.component';
import { ExpansionComponent } from './table-filter/expansion/expansion.component';
import { GroupChipSearchFilterComponent } from './table-filter/group-chip-search-filter/group-chip-search-filter.component';
import { LanguageFilterComponent } from './table-filter/language-filter/language-filter.component';
import { SelectFilterComponent } from './table-filter/select-filter/select-filter.component';
import { TableFilterComponent } from './table-filter/table-filter.component';
import { TableMenuComponent } from './table-menu/table-menu.component';
import { MatPaginatorIntlI18nService } from './table-paginator/mat-paginator-intl-i18n.service';
import { TableSearchComponent } from './table-search/table-search.component';
import { TableSelectedComponent } from './table-selected/table-selected.component';
import { TableToolbarComponent } from './table-toolbar/table-toolbar.component';
import { TagChipSearchFilterComponent } from './table-filter/tag-chip-search-filter/tag-chip-search-filter.component';
import { StatusFilterComponent } from './table-filter/status-filter/status-filter.component';
import { GlobalTableFilterComponent } from './table-filter/global-table-filter/global-table-filter.component';
import { CustomerFilterComponent } from './table-filter/customer-filter/customer-filter.component';
import { AuthorChipSearchFilterComponent } from './table-filter/author-chip-search-filter/author-chip-search-filter.component';
import { InstructorChipSearchFilterComponent } from './table-filter/instructor-chip-search-filter/instructor-chip-search-filter.component';
import { MessageNameOrEmailChipSearchFilterComponent } from './table-filter/message-name-or-email-chip-search-filter/message-name-or-email-chip-search-filter.component';
import { AppDirectiveModule } from '@klickdata/core/directive';
import { UserChipSearchFilterComponent } from './table-filter/user-chip-search-filter/user-chip-search-filter.component';
import { ResizeColumnDirective } from './resizable-column.directive';
import { CustomFieldsFilterComponent } from './table-filter/custom-fields-filter/custom-fields-filter.component';
import { CustomFieldsModule } from '@klickdata/core/custom-field';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MaterialModule,
        MobileModule,
        FormModule,
        FormsModule,
        LocalizationModule,
        AppDirectiveModule,
        CustomFieldsModule,
    ],
    exports: [
        TableSearchComponent,
        TableSelectedComponent,
        TableMenuComponent,
        TableFilterComponent,
        TableToolbarComponent,
        DateFilterComponent,
        ChipFilterComponent,
        ExpansionComponent,
        CheckboxFilterComponent,
        SelectFilterComponent,
        StatusFilterComponent,
        CategoryChipFilterComponent,
        FolderChipFilterComponent,
        BreadcrumbFilterComponent,
        GroupChipFilterComponent,
        GroupChipSearchFilterComponent,
        LanguageFilterComponent,
        CategoryChipSearchFilterComponent,
        TagChipSearchFilterComponent,
        GlobalTableFilterComponent,
        CustomerFilterComponent,
        AuthorChipSearchFilterComponent,
        InstructorChipSearchFilterComponent,
        MessageNameOrEmailChipSearchFilterComponent,
        UserChipSearchFilterComponent,
        ResizeColumnDirective,
        CustomFieldsFilterComponent,
    ],
    declarations: [
        TableSearchComponent,
        TableSelectedComponent,
        TableMenuComponent,
        TableFilterComponent,
        TableToolbarComponent,
        DateFilterComponent,
        ChipFilterComponent,
        ExpansionComponent,
        CheckboxFilterComponent,
        SelectFilterComponent,
        CategoryChipFilterComponent,
        FolderChipFilterComponent,
        BreadcrumbFilterComponent,
        GroupChipFilterComponent,
        GroupChipSearchFilterComponent,
        LanguageFilterComponent,
        CategoryChipSearchFilterComponent,
        TagChipSearchFilterComponent,
        StatusFilterComponent,
        GlobalTableFilterComponent,
        CustomerFilterComponent,
        AuthorChipSearchFilterComponent,
        InstructorChipSearchFilterComponent,
        MessageNameOrEmailChipSearchFilterComponent,
        UserChipSearchFilterComponent,
        ResizeColumnDirective,
        CustomFieldsFilterComponent,
    ],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: APP_MOMENT_DATE_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },

        {
            provide: MatPaginatorIntl,
            useClass: MatPaginatorIntlI18nService,
        },
    ],
})
export class TableModule {}
