<div class="activity-log-landing" fxLayout="column wrap" fxLayoutAlign="center center">
    <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start" class="stats-title-wrapper">
        <mat-icon fxFlex="0 0 auto" color="primary" class="course-plan-icon kd-icon-48 no-shadow">insights </mat-icon>
        <h1 class="headline" i18n="@@actionLog">Action Log</h1>
    </div>
    <ng-container *ngIf="authCustomer$ | async; let customer">
        <app-activity-log-sx context="admin" [model]="customer"> </app-activity-log-sx>
    </ng-container>

    <div class="stats-bulk-wrapper" fxFlex="0 1 90%" fxFlex-lt.md="0 1 100%">
        <div fxFlex="0 1 100%">
            <div class="log-activity-wrapper" fxFlex="0 0 100%" fxLayout="column wrap" fxLayoutAlign="start center">
                <mat-toolbar>
                    <nav
                        *ngIf="!(isMobile | async)"
                        class="test-results-nav"
                        mat-tab-nav-bar
                        fxFlex="0 0 100%"
                        ngClass.xs="xs"
                        #navBar
                    >
                        <a
                            mat-tab-link
                            [routerLink]="['./overall']"
                            routerLinkActive
                            (click)="activeIndex !== 0 && resetWidgetsFilter(); activeIndex = 0"
                            #rlaOverall="routerLinkActive"
                            [active]="rlaOverall.isActive"
                            i18n="@@overall"
                        >
                            Overall
                        </a>
                        <a
                            mat-tab-link
                            [routerLink]="['./users']"
                            routerLinkActive
                            (click)="activeIndex !== 1 && resetWidgetsFilter(); activeIndex = 1"
                            #rlaUsers="routerLinkActive"
                            [active]="rlaUsers.isActive"
                            i18n="@@learners"
                        >
                            Learners
                        </a>
                        <a
                            mat-tab-link
                            [routerLink]="['./resources']"
                            routerLinkActive
                            (click)="activeIndex !== 2 && resetWidgetsFilter(); activeIndex = 2"
                            #rlaResources="routerLinkActive"
                            [active]="rlaResources.isActive"
                            i18n="@@resources"
                        >
                            Resources
                        </a>
                    </nav>
                    <app-generic-mobile-tabs
                        fxFlex="100"
                        *ngIf="isMobile | async"
                        class="action-log-mob-tabs"
                        wrapperClass="activites-accordion-list course-results"
                        [activeOption]="activeOption"
                        [options]="activityOptions"
                    ></app-generic-mobile-tabs>
                </mat-toolbar>
                <mat-card class="kd-green-top">
                    <router-outlet></router-outlet>
                </mat-card>
            </div>
        </div>
    </div>
</div>
