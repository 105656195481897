<div class="mb-1 results-wrapper-qs-table" fxLayout="column" fxLayoutAlign="center center">
    <div class="results-wrapper" fxLayout="column wrap">
        <app-table-toolbar class="toolbar" color="accent">
            <mat-accordion fxFlex="0 0 100%" displayMode="flat">
                <mat-expansion-panel
                    (closed)="accordionExoanded = false"
                    (opened)="accordionExoanded = true"
                    [expanded]="false"
                    #expansionPanel
                >
                    <mat-expansion-panel-header> </mat-expansion-panel-header>
                    <app-table-filter [inGlobalFilter]="true" color="accent" #filter>
                        <app-table-search
                            [inGlobalFilter]="true"
                            class="align-space"
                            searchFilterRef
                        ></app-table-search>
                    </app-table-filter>
                </mat-expansion-panel>
            </mat-accordion>
            <!-- <mat-progress-bar
                class="progress"
                *ngIf="(dataSource.status | async) === 'loading'"
                mode="indeterminate"
            ></mat-progress-bar> -->
        </app-table-toolbar>
        <button
            class="toggler"
            [class.expanded-accordion]="accordionExoanded"
            matTooltip="View alternatives"
            mat-icon-button
            i18n-matTooltip="@@viewAlternatives"
            [class.expandAll]="allRowsExpanded"
            (click)="toggle()"
        >
            <mat-icon>first_page</mat-icon>
        </button>
        <div class="table-wrapper">
            <mat-table
                #table
                [dataSource]="dataSource"
                matSortStart="desc"
                multiTemplateDataRows
                matSort
                matSortDirection="desc"
            >
                <ng-container matColumnDef="title">
                    <mat-header-cell mat-sort-header i18n="@@question" *matHeaderCellDef>Question</mat-header-cell>
                    <mat-cell *matCellDef="let question">
                        <span [fxShow]="!(isMobile$ | async)">{{ question.title }}</span>
                        <ng-container
                            *ngIf="isMobile$ | async"
                            [ngTemplateOutlet]="mobileTableRow"
                            [ngTemplateOutletContext]="{ question: question }"
                        ></ng-container>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="correct">
                    <mat-header-cell mat-sort-header color="success" *matHeaderCellDef
                        ><mat-icon>done</mat-icon></mat-header-cell
                    >
                    <mat-cell [fxShow]="!(isMobile$ | async)" color="success" *matCellDef="let question">
                        {{ question.correct }}%
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="points">
                    <mat-header-cell mat-sort-header i18n="@@points" color="accent" *matHeaderCellDef
                        >Points</mat-header-cell
                    >
                    <mat-cell [fxShow]="!(isMobile$ | async)" color="accent" *matCellDef="let question">
                        {{ question.points }}%
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="incorrect">
                    <mat-header-cell mat-sort-header color="warn" *matHeaderCellDef
                        ><mat-icon>close</mat-icon></mat-header-cell
                    >
                    <mat-cell [fxShow]="!(isMobile$ | async)" color="warn" *matCellDef="let question">
                        {{ question.incorrect }}%
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="no_entry">
                    <mat-header-cell mat-sort-header *matHeaderCellDef><mat-icon>edit_off</mat-icon></mat-header-cell>
                    <mat-cell [fxShow]="!(isMobile$ | async)" *matCellDef="let question"
                        >{{ question.noEntry }}%</mat-cell
                    >
                </ng-container>
                <ng-container matColumnDef="sequence">
                    <mat-header-cell mat-sort-header *matHeaderCellDef> Id </mat-header-cell>
                    <mat-cell [fxShow]="!(isMobile$ | async)" *matCellDef="let question">
                        <div class="number-wrapper">
                            <span>{{ question.sequence }}</span>
                        </div>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="expand_all">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell [fxShow]="!(isMobile$ | async)" *matCellDef="let question">
                        <button matTooltip="View alternatives" mat-icon-button i18n-matTooltip="@@viewAlternatives">
                            <mat-icon>{{
                                expandedElement === question || allRowsExpanded ? 'expand_less' : 'expand_more'
                            }}</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="expandedDetail">
                    <mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                        <div
                            class="example-element-detail"
                            [@detailExpand]="element == expandedElement || allRowsExpanded ? 'expanded' : 'collapsed'"
                        >
                            <div *ngIf="element.media_id" class="example-element-diagram">
                                <app-image-view [media_id]="element.media_id" [proportional]="false"></app-image-view>
                            </div>
                            <div class="example-element-description">
                                <!-- {{ element | json }} -->
                                <div *ngFor="let alt of element.alternatives">
                                    <div [class.correct-alt]="alt.correct" class="alt-wrapper">
                                        <div *ngIf="alt.media" class="media-alt">
                                            <app-image-view
                                                [media_id]="alt.media"
                                                [proportional]="false"
                                            ></app-image-view>
                                        </div>
                                        <span class="alt-perc">{{ alt.title }}</span> &nbsp;&nbsp;<span class="alt-perc"
                                            >({{ alt.percentage }}%)</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                <mat-row
                    *matRowDef="let element; columns: columnsToDisplay"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element"
                ></mat-row>
                <!-- <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row> -->
                <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
            </mat-table>
        </div>
        <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
    </div>
</div>

<ng-template #smallLoading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #mobileTableRow let-question="question">
    <div class="mobile-row-wrapper">
        <div class="qs-wrapper">
            <div class="number-wrapper">
                <span>{{ question.sequence }}</span>
            </div>
            <div class="title-qs-mob">
                {{ question.title }}
            </div>
            <button
                class="toggle-action-btn"
                matTooltip="View alternatives"
                mat-icon-button
                i18n-matTooltip="@@viewAlternatives"
            >
                <mat-icon>{{
                    expandedElement === question || allRowsExpanded ? 'expand_less' : 'expand_more'
                }}</mat-icon>
            </button>
        </div>
        <div class="per-wrapper">
            <span class="corr">{{ question.correct }}%</span>
            <span class="incor">{{ question.incorrect }}%</span>
            <span class="noEnt">{{ question.noEntry }}%</span>
        </div>
    </div>
</ng-template>
