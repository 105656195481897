<div class="general-res-listing-mobile" *ngIf="resources$ | async; let resources; else: smallLoading">
    <div class="accordion-wrapper">
        <mat-accordion fxFlex="0 0 100%" displayMode="flat">
            <mat-expansion-panel *ngFor="let resource of resources" [expanded]="true" #expansionPanel>
                <mat-expansion-panel-header>
                    <div class="header-wrapper" fxLayout="row" fxLayoutGap="8px">
                        <div class="header-body-img">
                            <app-image-view [media_id]="resource.media$" [proportional]="false"></app-image-view>
                        </div>
                        <div class="header-body-info" fxLayout="column" fxLayoutAlign="space-between start">
                            <span class="item-title">{{ resource.title }}</span>
                            <span class="tasks-wrapper">
                                <span class="tasks">{{ resource.number_of_items }}</span>
                                <span fxHide i18n="@@tasks">Tasks</span></span
                            >
                        </div>
                    </div>
                </mat-expansion-panel-header>
                <div class="expansion-body">
                    <div class="expansion-body-info">
                        <div *ngIf="resource.bullets" class="row">
                            <span class="row-header" i18n="@@summary">Summary</span>
                            <span class="row-content">{{ resource.bullets }}</span>
                        </div>
                        <div *ngIf="resource.end_date" class="row">
                            <span class="row-header" i18n="@@deadline">Deadline</span>
                            <span class="row-content">{{ resource.opportunity_end_date | kdDateFormat: 'YYYY-MM-DD' }}</span>
                        </div>
                        <div class="row">
                            <span class="row-header" i18n="@@status">Status</span>
                            <span class="row-content">
                                {{ (resource | resourceStatus: true)?.status }}
                            </span>
                        </div>
                        <div fxHide *ngIf="resource.author$ | async; let loadedAuthor" class="row">
                            <span class="row-header" i18n="@@createdBy">Created by</span>
                            <span class="row-content">{{ loadedAuthor.name }}</span>
                        </div>
                        <div *ngIf="resource.published" class="row">
                            <span class="row-header" i18n="@@publishedAt">Published at</span>
                            <span class="row-content">{{ resource.published | kdDateFormat: 'YYYY-MM-DD' }}</span>
                        </div>
                        <div fxHide class="row">
                            <span class="row-header" i18n="@@endReward">End reward</span>
                            <span class="row-content">
                                <span
                                    *ngIf="
                                        (resource.examination?.type !== 'Certification' &&
                                            resource.examination?.type !== 'Diploma') ||
                                        !resource.examination
                                    "
                                    i18n="@@participation">Participation</span
                                >
                                <span *ngIf="resource.examination?.type === 'Certification'" i18n="@@certification">Certification</span>
                                <span *ngIf="resource.examination?.type === 'Diploma'" i18n="@@diploma">Diploma</span>

                                <span *ngIf="resource.examination && resource.examination?.label">{{
                                    resource.examination?.label
                                }}</span>
                                <span *ngIf="!resource.examination" i18n="@@participationCertificate"
                                    >Participation Certificate</span
                                >
                            </span>
                        </div>
                        <div *ngIf="resource.duration" class="row">
                            <span class="row-header" i18n="@@duration">Duration</span>
                            <span class="row-content">{{ resource?.duration | formatIsoDuration }}</span>
                        </div>
                        <div class="row">
                            <span class="row-header" i18n="@@price">Price</span>
                            <span *ngIf="resource.price !== 0 && resource.price" class="row-content"
                                >{{ resource.price }}&nbsp;{{ resource.currency }}</span
                            >
                            <span *ngIf="resource.price === 0 || !resource.price" class="row-content" i18n="@@free"
                                >Free</span
                            >
                        </div>
                        <div class="row">
                            <span class="row-header" i18n="@@fullInfo">Full info</span>
                            <button
                                class="view"
                                mat-icon-button
                                matTooltip="View"
                                (click)="onBrowse.emit(resource)"
                                i18n-matTooltip="@@view"
                            >
                                <mat-icon>visibility</mat-icon>
                            </button>
                        </div>
                        <div class="row">
                            <button
                                class="play"
                                mat-raised-button
                                [matTooltip]="getPlayBtnTxt(resource)"
                                (click)="onPlay.emit(resource)"
                            >
                                <mat-icon *ngIf="!resource.opportunity_id">play_circle</mat-icon>
                                <mat-icon *ngIf="resource.opportunity_id">play_circle_outline</mat-icon>
                                <ng-container *ngIf="resource.opportunity_id" i18n="@@continue">Continue</ng-container>
                                <ng-container *ngIf="!resource.opportunity_id" i18n="@@start">Start</ng-container>
                            </button>
                        </div>
                    </div>
                    <div fxHide class="expansion-body-btn">
                        <button
                            class="view"
                            mat-icon-button
                            matTooltip="View"
                            (click)="onBrowse.emit(resource)"
                            i18n-matTooltip="@@view"
                        >
                            <mat-icon>visibility</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-expansion-panel>

            <!-- <app-mobile-listing-item *ngFor="let resource of resources"></app-mobile-listing-item> -->
        </mat-accordion>
    </div>
</div>
<ng-template #smallLoading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
