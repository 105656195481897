import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarRef,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { DirectionalityService } from '@klickdata/core/localization';
import { MobileService } from '@klickdata/core/mobile';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    protected applicationMessages: any[] = [];
    protected applicationMessagesSubject = new BehaviorSubject(null);

    protected position: {
        verticalPosition: MatSnackBarVerticalPosition;
        horizontalPosition: MatSnackBarHorizontalPosition;
    } = {
        verticalPosition: 'bottom',
        horizontalPosition: 'center',
    };

    constructor(
        protected snackBar: MatSnackBar,
        protected mobileService: MobileService,
        private directionality: DirectionalityService
    ) {
        this.mobileService.isMobile().subscribe((isMobile) => {
            if (isMobile) {
                this.position.verticalPosition = 'bottom';
                this.position.horizontalPosition = 'center';
            } else {
                this.position.verticalPosition = 'bottom';
                this.position.horizontalPosition = 'center';
            }
        });
    }

    public message(message: string) {
        this.snackBar.open(message, '', {
            duration: 6000,
            verticalPosition: this.position.verticalPosition,
            horizontalPosition: this.position.horizontalPosition,
        });
    }

    public openMessage<T>(
        component: ComponentType<T>,
        message?: string,
        config = {
            duration: 6000,
            verticalPosition: this.position.verticalPosition,
            horizontalPosition: this.position.horizontalPosition,
            panelClass:
                this.directionality.value === 'rtl' ? ['right-direction', 'red_container_bg'] : 'red_container_bg',
        }
    ): MatSnackBarRef<T> {
        // let config = {
        //     duration: 6000,
        //     verticalPosition: this.position.verticalPosition,
        //     horizontalPosition: this.position.horizontalPosition,
        //     panelClass: this.directionality.value === 'rtl' ? ['right-direction', 'red_container_bg'] : 'red_container_bg',
        // };
        if (message) {
            config = Object.assign(config, { data: { message: message } });
        }
        return this.snackBar.openFromComponent<T>(component, config);
    }

    public addApplicationMessage<T>(component: ComponentType<T>, duration = 0) {
        this.applicationMessagesSubject.next(this.applicationMessages);
    }

    public getMessages(): Observable<any[]> {
        return this.applicationMessagesSubject.asObservable();
    }

    protected removeAfter(component, duration) {
        setTimeout(() => {
            const index = this.applicationMessages.indexOf(component);
            this.applicationMessages = this.applicationMessages.splice(index, 1);
        }, duration);
    }
}
