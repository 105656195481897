import { CastType, IDataModel, Ignore, Model } from '@klickdata/core/application/src/model/model-interface';
import { ResponseData } from '@klickdata/core/http';
import { Media } from '@klickdata/core/media/src/media.model';
import { ResourceOpportunity } from '@klickdata/core/opportunity';
import { Question, QuestionData } from '@klickdata/core/question/src/question';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { AppScope } from '@klickdata/core/resource/src/type.scope.enum';
import { User } from '@klickdata/core/user';
import { Observable } from 'rxjs';
import { Cast, MethodType, Nullable } from './../../application/src/model/model-interface';
import { ResourceItemType } from './type/resource-item-type.model';

export type PromptType = 'prompt' | 'translate' | 'merge' | 'regenerate' | 'image';
export type ItemTypeValue = 'text_material' | 'url_material' | string;

export interface ResourceItemUpdate<T> extends ResponseData<T> {
    type?: PromptType;
    item_id?: number;
    error?: any;
}

export interface ResourceItemPivot {
    next_item_id?: number;
    previous_item_id?: number;
    resource_id?: number;
    resource_item_id?: number;
    sequence?: number;
}

export interface ResourceItemData extends IDataModel {
    id?: number;
    name?: string;
    title?: string;
    description?: string;
    instructions?: string;
    learning_field?: string;
    references?: string;
    item_type_value?: ItemTypeValue;
    mandatory?: boolean;
    play_position?: string;
    duration?: number;
    parent_id?: number;
    last_action?: string;
    started_at?: string;
    text_validation?: string;
    prompt?: string;
    language_id?: number;
    status?: PromptType;
    can_download?: boolean;

    // Question
    question?: QuestionData;
    deleted?: boolean;

    // Pivot data
    resource_id?: number;
    sequence?: number;
    next_item_id?: number;
    previous_item_id?: number;

    // CP specific
    child_resource_id?: number;
    child_resource_published?: string;
    child_resource_language_id?: number;
    child_items?: any;

    // Player specific
    index?: number;

    // Test Question
    media_id?: boolean;
    question_id?: number;
    alternative_ids?: number[];

    // handle User can edit child resource.
    child_resource_customer_id?: number;
    child_resource_author_id?: number;
    child_opportunity_id?: number;
    child_opportunity_done?: moment.Moment;

    // Handling of the audio and video material chapters
    chapters?: MaterialChapter[];
    subtitles?: MaterialSubtitles[];

    content?: any;
}

/**
 * Resource Item
 * @description Used to describe a linked list of items available in a resource.
 */
export class ResourceItem extends Model<ResourceItemData> {
    public id: number;
    public name: string;
    public title: string;
    public description: string;
    public instructions: string;
    public learning_field: string;
    public references: string;
    public item_type_value: ItemTypeValue;
    @Nullable(MethodType.PUT)
    public mandatory: boolean;
    public play_position: string;
    public duration: number;
    public parent_id: number;

    // Pivot
    public resource_id: number;
    public sequence: number;
    public next_item_id: number;
    public previous_item_id: number;

    // CP specific
    public child_resource_id: number;
    public child_resource_published: string;
    public child_resource_language_id: number;

    // Player specific
    public index: number;
    public resource: Observable<Resource>;
    public child_resource: Observable<Resource>;
    public child_occasion: Observable<ResourceOpportunity>;
    public itemType: Observable<ResourceItemType>;
    public nextItem: Observable<ResourceItem>;
    public previousItem: Observable<ResourceItem>;
    public question$: Observable<Question>;
    @Cast(CastType.OBJECT)
    public question: Question;
    @Cast(CastType.MOMENT, 'YYYY-MM-DD HH:mm')
    public last_action: moment.Moment;
    @Cast(CastType.MOMENT, 'YYYY-MM-DD HH:mm')
    public started_at: moment.Moment;
    // Test question
    public media_id: boolean;
    public question_id: number;
    public alternative_ids: number[];
    public media$: Observable<Media>;
    public can_download: boolean;
    @Ignore()
    public deleted: boolean;

    // handle User can edit child resource.
    public child_resource_customer_id: number;
    public child_resource_author_id: number;
    public child_opportunity_id: number;
    @Cast(CastType.MOMENT)
    public child_opportunity_done: moment.Moment;

    public child_items: any;

    // Test result.

    @Ignore()
    public pivot: ResourceItemPivot;
    @Ignore()
    public total_tokens: number;

    @Ignore()
    public child_opportunity_started_at: string;
    @Ignore()
    public child_opportunity_last_action: string;
    @Ignore()
    public prompt: string;
    @Ignore()
    public created_at: string;
    @Ignore()
    public updated_at: string;
    @Ignore()
    public question_type_value: any;
    public text_validation: string;
    public language_id: number;
    @Ignore()
    public status: PromptType;

    chapters: MaterialChapter[];
    subtitles: MaterialSubtitles[];
    @Ignore()
    public content: any;
    @Ignore()
    public startStatus: any;

    public editable(user: User, resource: Resource) {
        if (!resource) {
            return true;
        }

        if (resource.scope_id == AppScope.COURSE) {
            const childResource = {
                customer_id: this.child_resource_customer_id,
                author_id: this.child_resource_author_id,
            };
            return user?.canEdit(childResource);
        }

        return user?.canEdit(resource);
    }

    isDocType(): boolean {
        return this.item_type_value !== 'text_material' && this.item_type_value !== 'url_material';
    }
}

export interface MaterialChapter {
    chapterName: string;
    chapterStart: string;
}

export interface MaterialSubtitles {
    lang: string;
    link: string;
}
