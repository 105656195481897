import { Pipe, PipeTransform } from '@angular/core';
import { FieldType } from '@klickdata/core/custom-field';

@Pipe({
    name: 'formBuilderItemPipe',
})
export class FormBuilderItemIconPipe implements PipeTransform {
    transform(name: string): { icon: string; label: string } {
        switch (name) {
            case FieldType.TEXT_INPUT:
                return { icon: 'notes', label: $localize`Text content` };
            case FieldType.SINGLE_CHOICE:
                return { icon: 'radio_button_checked', label: $localize`Single choice` };
            case FieldType.MULTIPLE_CHOICE:
                return { icon: 'check_box', label: $localize`Multiple choice` };
            case FieldType.DATE:
                return { icon: 'calendar_month', label: $localize`Date picker` };
            case FieldType.DROPDOWN:
                return { icon: 'format_list_numbered', label: $localize`Drop down` };
            case FieldType.SWITCH:
                return { icon: 'toggle_off', label: $localize`Switch` };
        }
    }
}
