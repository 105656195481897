import { Injectable } from '@angular/core';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { Resource, ResourceData } from '@klickdata/core/resource';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { UserStatisticsService } from '@klickdata/home/src/core/user-statistics.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ResourceCompetenceListService extends UserStatisticsService implements TableHttpService<Resource> {
    public fetchData(source: TableSource<Resource>): Observable<ResponseData<Resource[]>> {
        const req = this.builder.get<ResourceData[]>(this.resourceUrl);
        // req.param('relationType', 'mandatory');
        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<ResourceData[]>) => {
                return {
                    data: this.mapResource(res.data),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }
}
