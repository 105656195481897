import { Injectable } from '@angular/core';
import { Resource } from './resource.model';
import { User } from '@klickdata/core/user';
import { ResourceItem } from '@klickdata/core/resource-item';
import { ResourceTypes } from './types.enum';
import { SignupFormComponent } from '@klickdata/guest/src/signup/signup-form/signup-form.component';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { takeUntil, filter, switchMap, map, of, Observable, BehaviorSubject, first, tap, EMPTY } from 'rxjs';
import { ResourceService } from './resource.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { PrivilegeType } from '@klickdata/core/util';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import * as moment from 'moment';
import { Customer } from '@klickdata/core/customer';
import { ResourcePurchaseDialogComponent } from '@klickdata/shared-components/src/purchase/resource-purchase-dialog/resource-purchase-dialog.component';
import { ResourceOpportunity } from '@klickdata/core/opportunity';
export interface EventJoin {
    status: PrivilegeType | 'Payment_needed';
    label: string;
    icon: string;
    checkType: string;
    fg?: string;
    bg?: string;
}
export interface BypassEventCheckKey {
    date: boolean;
    seats: boolean;
    enroll: boolean;
    price: boolean;
}
export enum EventCheckKeys {
    DATE = 'date',
    SEATS = 'seats',
    ENROLL = 'enroll',
    PRICE = 'price',
}
@Injectable({
    providedIn: 'root',
})
export class StartEventService extends OnDestroyHandler {
    public event: BehaviorSubject<Resource> = new BehaviorSubject<Resource>(null);
    public availableToJoin: EventJoin = {
        status: 'allowed',
        label: $localize`Register`,
        icon: 'event_seat',
        checkType: EventCheckKeys.DATE,
    };
    public mainEventType: { title: string; icon: string; color: string };
    public subEventType: { title: string; icon: string; color: string };
    public defaultbyPassEventCheck: BypassEventCheckKey = { date: false, seats: false, enroll: false, price: false };
    public moment = moment();
    private customer: Customer;
    constructor(
        protected resourceService: ResourceService,
        protected message: MessageService,
        protected auth: AuthService,
        protected bottomSheet: MatBottomSheet,
        protected mobile: MobileService
    ) {
        super();
        auth.getCustomer()
            .pipe(takeUntil(this.destroy))
            .subscribe((customer) => (this.customer = customer));
    }

    public checkEventJoin(resource: Resource, bypassCheck?: BypassEventCheckKey): Observable<EventJoin> {
        // prio : dates > seats > enrollment approval > price
        const bypassCheckRoles = bypassCheck ? bypassCheck : this.defaultbyPassEventCheck;
        if (this.checkDates(resource).status != 'allowed' && !bypassCheckRoles.date) {
            return of(this.checkDates(resource));
        } else if (this.checkSeats(resource).status != 'allowed' && !bypassCheckRoles.date) {
            return of(this.checkSeats(resource));
        } else if (this.checkEnrollment(resource).status != 'allowed' && !bypassCheckRoles.date) {
            return of(this.checkEnrollment(resource));
        } else if (this.customer && this.checkPrice(resource).status == 'Payment_needed' && !bypassCheckRoles.price) {
            return of(this.checkPrice(resource));
        } else {
            return of(this.availableToJoin);
        }
    }
    private checkDates(resource: Resource): EventJoin {
        const allDates = resource.occasion_dates.reduce((dates, occasion) => {
            dates.push(new Date(occasion.start_date));
            dates.push(new Date(occasion.end_date));
            return dates;
        }, []);
        const maxDate = new Date(Math.max(...allDates));
        if (!!resource.occasion_dates?.length) {
            resource.occasion_dates = resource.occasion_dates?.map((item) => {
                return {
                    ...item,
                    isValid: new Date(item.end_date) > new Date(),
                };
            });
        }

        return !!resource.occasion_dates?.length && moment(maxDate).isAfter(this.moment)
            ? { ...this.availableToJoin, ...{ checkType: EventCheckKeys.DATE } }
            : { status: 'not_allowed', label: $localize`Closed`, icon: 'event_busy', checkType: EventCheckKeys.DATE };
    }
    private checkSeats(resource: Resource): EventJoin {
        return resource.total_seats == 0 || (resource.total_seats != 0 && resource.available_seats > 0)
            ? { ...this.availableToJoin, ...{ checkType: EventCheckKeys.SEATS } }
            : { status: 'not_allowed', label: $localize`Closed`, icon: 'event_busy', checkType: EventCheckKeys.SEATS };
    }
    private checkEnrollment(resource: Resource): EventJoin {
        if (!resource.user_collaboration?.enroll_approval_required) {
            return { ...this.availableToJoin, ...{ checkType: EventCheckKeys.ENROLL } };
        }

        if (resource.user_collaboration?.enroll_approval_required) {
            switch (resource.enrollment?.approval_status) {
                case 'approved':
                    return { ...this.availableToJoin, ...{ checkType: EventCheckKeys.ENROLL } };

                case 'pending':
                    return {
                        status: 'not_allowed',
                        label: $localize`Request Pending`,
                        icon: 'pending_actions',
                        checkType: EventCheckKeys.ENROLL,
                    };
                case 'rejected':
                    return {
                        status: 'not_allowed',
                        label: $localize`Request Rejected`,
                        icon: 'cancel',
                        checkType: EventCheckKeys.ENROLL,
                    };
                default:
                    return {
                        status: 'approval_required',
                        label: $localize`Register`,
                        icon: 'event_seat',
                        checkType: EventCheckKeys.ENROLL,
                    };
            }
        }
    }
    public checkPrice(resource: Resource): EventJoin {
        return this.customer.license_type !== 'sitelicense' &&
            resource.price &&
            !resource.purchased &&
            !resource.opportunity_id
            ? {
                  status: 'Payment_needed',
                  label: $localize`Join`,
                  icon: 'credit_card',
                  checkType: EventCheckKeys.PRICE,
              }
            : { ...this.availableToJoin, ...{ checkType: EventCheckKeys.PRICE } };
    }
    public takeEvent(resource: Resource, user: User, eventJoin: EventJoin): Observable<Resource> {
        switch (eventJoin.status) {
            case 'allowed':
                return this.requestJoinEvent(resource, user);
            case 'not_allowed':
                return this.onNotAllowed(resource, user, eventJoin);
            case 'Payment_needed':
                return this.requestPayment(resource, user);
            case 'approval_required':
                return this.onApprovalRequired(resource, user);
        }
    }

    public requestPayment(resource: Resource, user: User): Observable<Resource> {
        return this.bottomSheet
            .open(ResourcePurchaseDialogComponent, {
                data: resource,
                panelClass: 'sheet-wrapper',
                disableClose: false,
            })
            .afterDismissed()
            .pipe(
                filter((status) => !!status),
                takeUntil(this.destroy),
                switchMap(() =>
                    resource.occasion_dates.length > 1
                        ? this.requestJoinEvent(resource, user)
                        : this.resourceService
                              .joinEvent({
                                  resourceId: resource.id,
                                  userId: user.id,
                                  eventOccasionId: resource.occasion_dates[0].id,
                              })
                              .pipe(
                                  map((occ: ResourceOpportunity) => {
                                      resource.opportunity_id = occ.id;
                                      return resource;
                                  })
                              )
                )
            );
    }
    public requestJoinEvent(resource: Resource, user: User): Observable<Resource> {
        const mainEventType = ResourceTypes.getEventTypeByMainId(
            ResourceTypes.getEventTypes().find((type) => type.value == resource?.type_id)?.eventTypeId
        );
        if (!!resource.occasion_dates?.length) {
            resource.occasion_dates = resource.occasion_dates.map((item) => {
                return {
                    ...item,
                    isValid: new Date(item.end_date) > new Date(),
                };
            });
        }
        const subEventType = ResourceTypes.getEventTypeByTypeId(resource.type_id);
        const actions = [
            { label: $localize`Cancel`, value: 0, class: 'null-btn' },
            { label: $localize`Confirm`, value: 1, class: 'pos-btn' },
        ];
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.EVENT_JOIN,
            data: {
                icon: subEventType.icon,
                title: resource.title,
                resource: resource,
                contentBody: $localize`You are about to join this ${mainEventType.title} ${subEventType.title} event`,
                actions: actions,
                type: 'conformJoin',
            },
        });
        return this.mobile.getSideNavResponseData().pipe(
            filter((data) => data.type === 'conformJoin' && data.response),
            switchMap((data) =>
                this.resourceService.joinEvent({
                    resourceId: resource.id,
                    userId: user.id,
                    eventOccasionId: data.eventOccasionId,
                })
            ),
            map((occ) => {
                if (!!resource.occasion_dates?.length) {
                    resource.occasion_dates.map((occ) => (occ.selected = false));
                    resource.occasion_dates.find((dateOcc) => dateOcc.id == occ.metadata.event_occasion_id).selected =
                        true;
                }

                resource.opportunity_id = occ.id;
                return resource;
            })
        );
    }
    private onApprovalRequired(resource: Resource, user: User): Observable<Resource> {
        const actions = [
            { label: $localize`Cancel`, value: 0, class: 'null-btn' },
            { label: $localize`Request join`, value: 1, class: 'pos-btn' },
        ];
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.EVENT_JOIN,
            data: {
                icon: 'task_alt',
                title: $localize`Ask for joining`,
                inputDesc: $localize`Send message to admin requesting to join`,
                resource: {
                    ...resource,
                    ...{ occasion_dates: resource.occasion_dates.filter((date) => date.isValid) },
                },
                actions: actions,
                type: 'enrollRequest',
            },
        });
        return this.mobile.getSideNavResponseData().pipe(
            filter((data) => data.type === 'enrollRequest' && data.response),
            switchMap((data) =>
                this.resourceService.userRequestEventPermission({
                    resId: resource.id,
                    eventOccasionId: data.eventOccasionId,
                    message: { body: data.value },
                    eager: ['enrollment'],
                })
            )
        );
    }

    private onNotAllowed(resource: Resource, user: User, eventJoin: EventJoin): Observable<Resource> {
        switch (eventJoin.checkType) {
            case EventCheckKeys.DATE:
                this.takeClosedEvent();
                break;
            case EventCheckKeys.ENROLL:
                this.informRejectionStatus(resource);
                break;
            case EventCheckKeys.SEATS:
                this.takeFullyBookedEvent();
                break;
        }
        return of(resource);
    }

    private informRejectionStatus(resource: Resource) {
        const msgBody = resource.enrollment.admin?.message?.body;

        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: resource.enrollment.approval_status == 'rejected' ? 'cancel' : 'pending_actions',
                iconClass: resource.enrollment.approval_status == 'rejected' ? 'red' : 'orange',
                titleClass: resource.enrollment.approval_status == 'rejected' ? 'red' : 'orange',
                title:
                    resource.enrollment.approval_status == 'rejected'
                        ? $localize`Enrollment Request rejected`
                        : $localize`Enrollment Request pending`,
                contentBody:
                    resource.enrollment.approval_status == 'rejected'
                        ? $localize`Unfortunately the administrator has rejected you request to enroll in the ${ResourceTypes.label(
                              resource.type_id,
                              { capitalize: false }
                          )} ${resource.title}`
                        : $localize`The administrator is still reviewing you request to enroll in the ${ResourceTypes.label(
                              resource.type_id,
                              { capitalize: false }
                          )} ${resource.title}`,

                secContentBody: msgBody
                    ? '<span>' + $localize`The administrator message: ` + `</span><span>${msgBody}</span>`
                    : null,
                secContentClass: msgBody ? 'reviewer-msg' : null,
                positiveBtn: $localize`Ok`,
                negativeBtn: $localize`Cancel`,
            },
        });
    }
    takeClosedEvent() {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'event_busy',
                title: $localize`Event is closed`,
                contentBody: $localize`This event has passed the date. Check for more Event dates.`,
                positiveBtn: $localize`Ok`,
            },
        });
    }
    private takeFullyBookedEvent() {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'event_busy',
                title: $localize`Event is closed`,
                contentBody: $localize`This event is fully booked. Check for more Events.`,
                positiveBtn: $localize`Ok`,
            },
        });
    }
}
