import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService, ResponseData } from '@klickdata/core/http';
import { Observable, map, merge } from 'rxjs';

export interface DeadlineItem {
    deadline: string;
    count: number;
    managers_count: number;
    assignees_count: number;
}
@Injectable({
    providedIn: 'root',
})
export class K3CrmService {
    protected deadlinesUrl: string;
    constructor(
        protected configService: ConfigService,
        protected builder: RequestBuilderService,
        protected auth: AuthService
    ) {
        this.deadlinesUrl = `${configService.config.apiUrl}tasks/deadlines`;
    }

    public getDeadlines(): Observable<DeadlineItem[]> {
        const request = this.builder.get<DeadlineItem[]>(this.deadlinesUrl).request();
        const updates = this.auth.listenUserChannel<ResponseData<DeadlineItem[]>>('TodoEvent');
        return merge(request, updates).pipe(map((res) => res.data));
    }
}
