import { Resource } from './../../../../core/resource/src/resource.model';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { Subject, Observable } from 'rxjs';
import { shareReplay, takeUntil, map, filter } from 'rxjs/operators';
import { UserListingService } from './user-listing.service';
import { Customer } from '@klickdata/core/customer';

@Component({
    selector: 'app-shared-action-log-users',
    templateUrl: './shared-action-log-users.component.html',
    styleUrls: ['./shared-action-log-users.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UserListingService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class SharedActionLogUsersComponent implements OnInit, AfterViewInit, OnDestroy {
    public dataSource = new TableSource<User>();
    public columns = [
        'title',
        'last_action',
        'role',
        'cases_count',
        'started_courses_count',
        'completed_courses_count',
        'completed_tests_count',
        'tools',
    ];
    public isMobile: boolean;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    public userRoleOptions: SelectFilterOption[];
    public predefinedTimeSpentOptions: SelectFilterOption[];
    public resourceEventOptions: SelectFilterOption[];
    public expandedRow: User | null;
    public expandedCell: User | null;
    public klmsSpentTimeColTitle = $localize`Login time`;
    public coursesSpentTimeColTitle = $localize`Learning Time`;
    public isMasterPlatform$: Observable<boolean>;
    public customer: Customer;

    constructor(
        protected userService: UserListingService,
        protected mobile: MobileService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        protected router: Router,
        protected message: MessageService,
        protected route: ActivatedRoute
    ) {
        this.userRoleOptions = Utils.getUserRoleOptions();
        this.predefinedTimeSpentOptions = Utils.getPredefinedTimeSpentOptions();
        this.isMasterPlatform$ = auth.getNK3Platform().pipe(map((platform) => platform === 'master'));
        this.resourceEventOptions = Utils.getActionLogEventOptions().filter((option) => option.data.isResRelated);
    }
    public ngOnInit() {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((mobile) => {
                this.isMobile = mobile;
                this.cdRef.markForCheck();
            });

        this.dataSource.service = this.userService;
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.dataSource.refresh();
        this.route.queryParams
            .pipe(
                takeUntil(this.destroy),
                filter((params) => params.userName)
            )
            .subscribe((params) => this.onSearchFilterClick(GlobalFilterProperty.SEARCH, params.userName));
        if (this.customer && this.filter) {
            this.filter.createOrUpdateFilter([new Filter('customer', [this.customer.id])]);
        }
    }
    public onItemClick(filter: GlobalFilterProperty, value: string) {
        this.filter.setActiveFilterWithSelectedOption(filter, value);
    }
    public onSearchFilterClick(filter: GlobalFilterProperty, query: string) {
        this.filter.setActiveFilterWithQuery(filter, query);
    }

    public sendEmail(user: User) {
        if (user) {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.SEND_MESSAGE,
                data: { model: user, isUser: true },
            });
        }
    }
    public noteUser(user: User) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_NOTE,
            data: {
                privacy: 'user',
                user: user,
                status: 'create',
            },
        });
    }
    public viewUserResults(user: User) {
        if (user.name) {
            this.router.navigate(['/admin/statistics/action-log/overall'], {
                queryParams: {
                    userName: user.name,
                },
            });
        }
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
