import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpandAnimation } from '../../../core/animations/expand.animation';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { ResourceItem } from '@klickdata/core/resource-item';

@Component({
    selector: 'app-course-preview',
    templateUrl: './course-preview.component.html',
    styleUrls: ['./course-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [ExpandAnimation],
})
export class CoursePreviewComponent implements OnInit {
    @Input() course: Resource;
    @Input() eCourseItem: ResourceItem;
    @Input() height = '200px';
    @Input() maxHeight = '200px';
    @Input() width = 'auto';
    @Output() srcLoaded: EventEmitter<string> = new EventEmitter<string>();

    constructor() {}

    ngOnInit() {}
}
