<ng-container *ngIf="!loading; else loadingTemp">
    <div
        *ngIf="selected && !changeMode; else selectTemp"
        fxFlex="0 0 100%"
        class="selected-wrapper"
        fxLayout="row wrap"
    >
        <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="start center">
                <h2 fxFlex="1 1 auto">{{ selected.title }}</h2>
                <a
                    fxFlex="0 0 auto"
                    [routerLink]="['/home/courses/', selected.article_code]"
                    target="_blank"
                    mat-icon-button
                    matTooltip="Preview course"
                    i18n-matTooltip="@@previewCourse"
                >
                    <mat-icon class="md-24">open_in_new</mat-icon>
                </a>
            </div>
            <button
                fxFlex="0 0 auto"
                mat-icon-button
                color="primary"
                matTooltip="Change course"
                i18n-matTooltip="@@changeCourse"
                (click)="changeMode = true"
            >
                <mat-icon class="md-24">swap_horiz</mat-icon>
            </button>
            <button
                *ngIf="user$ | async as user"
                fxFlex="0 0 auto"
                mat-icon-button
                color="primary"
                matTooltip="Edit course"
                i18n-matTooltip="@@editCourse"
                [disabled]="!user.canEdit(selected)"
                (click)="user.canEdit(selected) && onEdit()"
            >
                <mat-icon class="md-24">edit</mat-icon>
            </button>
        </div>
        <app-image-view
            fxFlex="0 1 20%"
            *ngIf="selected.media_id"
            [media_id]="selected.media_id"
            [proportional]="true"
        ></app-image-view>
        <div fxFlex="0 1 calc(80% - 2em)" [innerHtml]="selected.description"></div>
    </div>
</ng-container>

<ng-template #selectTemp>
    <app-resource-library
        [selection]="selection"
        [type_scope_id]="AppScope.E_COURSE"
        (select)="selectResource($event); changeMode = false"
    ></app-resource-library>
</ng-template>

<ng-template #loadingTemp>
    <div fxLayout="row" fxLayoutAlign="left" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
