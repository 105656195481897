import { ChangeDetectionStrategy, Component, Input, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirectionalityService } from '@klickdata/core/localization/src/directionality.service';

@Component({
    selector: 'app-alert-dialog',
    templateUrl: './alert-dialog.component.html',
    styleUrls: ['./alert-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent implements OnInit {
    @Input() title: string;
    @Input() icon = 'warning';
    @Input() iconClass: string;
    @Input() color = 'warn';
    @Input() contentTitle: string;
    @Input() contentBody: string;
    @Input() showOnlyNeutralBtn: boolean;
    @Input() positiveBtn: string;
    @Input() negativeBtn: string;
    @Input() neutralBtn: string;
    @Input() countDown: number;

    constructor(
        public dialogRef: MatDialogRef<AlertDialogComponent>,
        public directionality: DirectionalityService,
        @Inject(MAT_DIALOG_DATA)
        protected data: {
            title: string;
            icon: string;
            iconClass: string;
            color: string;
            contentTitle: string;
            contentBody: string;
            positiveBtn: string;
            negativeBtn: string;
            neutralBtn?: string;
            showOnlyNeutralBtn?: boolean;
        }
    ) {
        if (data) {
            this.title = data.title;
            this.icon = data.icon || 'warning';
            this.iconClass = data.iconClass;
            this.color = data.color || 'warn';
            this.contentTitle = data.contentTitle;
            this.contentBody = data.contentBody;
            this.positiveBtn = data.positiveBtn;
            this.negativeBtn = data.negativeBtn;
            this.neutralBtn = data.neutralBtn;
            this.showOnlyNeutralBtn = data.showOnlyNeutralBtn;
        }
    }

    ngOnInit(): void {
        if (!this.positiveBtn) {
            this.positiveBtn = $localize`Yes`;
        }
        if (!this.negativeBtn) {
            this.negativeBtn = $localize`No`;
        }

        // Handle count down option
        if (this.countDown && this.countDown === 0) {
            this.dialogRef.close(false);
        }
    }
}
