import { IDataModel, Model } from '@klickdata/core/application/src/model/model-interface';

export interface UserRoleData extends IDataModel {
    value?: string;
    label?: string;
}

export class UserRole extends Model<UserRoleData> {
    public id: string | number;
    value: string;
    label: string;
}
