import { Pipe, PipeTransform } from '@angular/core';
import { ResourceTypes } from '@klickdata/core/resource';
@Pipe({
    name: 'materialTypeLabel',
})
export class MaterialTypeLabelPipe implements PipeTransform {
    constructor() {}
    transform(resourceTypeId: number, hasIcon = false): any {
        switch (resourceTypeId) {
            case ResourceTypes.URLMaterial:
                return hasIcon ? { label: $localize`Link Material`, icon: 'link' } : $localize`Link Material`;
            case ResourceTypes.DocMaterial:
                return hasIcon
                    ? { label: $localize`Document Material`, icon: 'attachment' }
                    : $localize`Document Material`;
            case ResourceTypes.TextMaterial:
                return hasIcon ? { label: $localize`Text Material`, icon: 'text_fields' } : $localize`Text Material`;
            case ResourceTypes.WebsiteMaterial:
                return hasIcon ? { label: $localize`Website Material`, icon: 'http' } : $localize`Website Material`;
        }
    }
}
