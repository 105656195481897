import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItem } from '@klickdata/core/resource-item';
import { ResourceEditDialogComponent } from '@klickdata/shared-components/src/resource-edit-dialog/resource-edit-dialog.component';
import { Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ResourceItemDirective } from '../resource-item.directive';

@Component({
    selector: 'app-resource-builder-course',
    templateUrl: './course.component.html',
    styleUrls: ['./course.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseComponent extends ResourceItemDirective implements OnDestroy {
    public courses: Observable<Resource[]>;
    public filteredCourses: Observable<Resource[]>;

    constructor(
        protected resourceService: ResourceService,
        protected changeRef: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected authService: AuthService,
        protected mobileService: MobileService
    ) {
        super(resourceService, changeRef, dialog, authService, mobileService);
    }
}
