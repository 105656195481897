import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http';
import { ResourceService } from '@klickdata/core/resource';
import { UserService } from '@klickdata/core/user';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Ticket, TicketData } from './ticket.model';

@Injectable({
    providedIn: 'root',
})
export class TicketService {
    public ticketUrl: string;
    public messageUrl: string;
    protected user_id: Observable<number>;

    constructor(
        protected configService: ConfigService,
        protected builder: RequestBuilderService,
        protected userSevice: UserService,
        protected resourceService: ResourceService,
        protected auth: AuthService
    ) {
        this.ticketUrl = `${configService.config.apiUrl}tickets`;
        this.messageUrl = `${configService.config.apiUrl}messages`;
        this.user_id = this.auth.getUser().pipe(
            first(),
            map((user) => user.id)
        );
    }

    public createTicket(ticket: TicketData): Observable<Ticket> {
        return this.builder
            .post<TicketData>(this.ticketUrl, ticket)
            .request()
            .pipe(map((noti) => this.mapTicket(noti.data)));
    }

    public getTicketById(id: number | string): Observable<Ticket> {
        return this.builder
            .get<Ticket>(`${this.ticketUrl}/${id}`)
            .request()
            .pipe(map((res) => this.mapTicket(res.data)));
    }

    public getTicketByMsgId(msgId: number | string): Observable<Ticket> {
        return this.builder
            .get<Ticket>(`${this.messageUrl}/${msgId}/ticket`)
            .request()
            .pipe(map((res) => this.mapTicket(res.data)));
    }

    protected mapTicket(ticketData: TicketData): Ticket {
        const ticketsData = new Ticket(ticketData);
        return ticketsData;
    }
}
