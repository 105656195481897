<div class="educator-field-wrap">
    <div
        *ngIf="!maxNumberOfEducatore || (maxNumberOfEducatore && maxNumberOfEducatore > educators.length)"
        class="instr-search-field-wrapper"
        fxLayout="row"
        fxLayoutAlign="space-between start"
    >
        <mat-form-field class="small-field instr-search-field" appearance="fill">
            <input
                type="text"
                matInput
                #educatorInput
                [placeholder]="searchPlaceholder"
                [formControl]="educatorCtrl"
                [matAutocomplete]="searchEdu"
            />
            <mat-autocomplete #searchEdu="matAutocomplete" (optionSelected)="selected(searchEdu, $event)">
                <mat-option
                    class="inst-search-option"
                    *ngFor="let educator of educatorSearch$ | async"
                    [value]="educator"
                >
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                        <app-image-view
                            [ngStyle]="{ minWidth: '40px' }"
                            [isCircularImg]="true"
                            [media_id]="educator.media$"
                            paddingBottom="40px"
                        ></app-image-view>
                        <!-- <div class="auto-comp-inst" *ngIf="!educator.media_id">
                            <mat-icon>engineering</mat-icon>
                        </div> -->
                        <span>{{ educator.name }}</span>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <button mat-icon-button class="btn-add" [matTooltip]="addingLabel" (click)="addEdu()">
            <mat-icon>add_circle</mat-icon>
        </button>
    </div>

    <div class="inst-list" fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="start center">
        <mat-radio-group [value]="defaultEdu" (change)="setDefaultEdu($event.value)">
            <div
                *ngFor="let educator of educators; let first = first"
                class="edu-row-wrapper"
                fxLayout="row"
                fxLayoutAlign="space-between center"
            >
                <mat-radio-button class="edu-option" [value]="educator" fxLayout="row">
                    <app-image-view
                        [ngStyle]="{ minWidth: '40px' }"
                        [isCircularImg]="true"
                        [media_id]="educator.media$"
                        paddingBottom="40px"
                    >
                    </app-image-view>
                    <div class="inst-name">{{ educator.name }}</div>
                    <button
                        class="remove-ins-def"
                        mat-icon-button
                        matTooltip="Remove"
                        i18n-matTooltip
                        (click)="$event.stopPropagation(); removeIns(educator)"
                    >
                        <mat-icon>{{ removeBtn }}</mat-icon>
                    </button>
                </mat-radio-button>
                <button mat-icon-button class="btn-add" matTooltip="Show more" (click)="showEduInfo(educator)">
                    <mat-icon>info</mat-icon>
                </button>
            </div>
        </mat-radio-group>
    </div>
    <!-- <div *ngIf="educators.length === 0" class="empty-inst">Pls search or add educator.</div> -->
</div>
<ng-template *ngIf="loading">
    <app-kd-logo-loading class="small-load-header" size="small"></app-kd-logo-loading>
</ng-template>
