<form class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>{{ navData.data.icon }}</mat-icon
        ><span>{{ navData.data.title }}</span>
    </div>
    <div class="desc">{{ navData.data.description }}</div>
    <app-kd-logo-loading class="img-loader" *ngIf="savingImg" size="small"></app-kd-logo-loading>
    <div [class.disabled]="savingImg" class="qs-options mt-2" fxLayoutGap="1em" fxLayout="column">
        <app-menu-side-media-upload-img
            [allowedMimeType]="['image/png', 'image/jpg', 'image/jpeg']"
            [maxFileSize]="1 * 1024 * 1024"
            (saving)="savingImg = $event"
            (onMediaReady)="onMediaReady($event)"
        >
        </app-menu-side-media-upload-img>
        <app-menu-side-media-library
            #mediaLib
            (onSelectMedia)="onMediaReady($event)"
            (onExpanding)="showAI = false"
        ></app-menu-side-media-library>
        <!-- <div class="option">
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>collections</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n="@@multipleChoice"> Library </span>
                <mat-icon class="qs-m" matTooltip="From library" i18n-matTooltip>question_mark</mat-icon>
            </div>
        </div> -->
        <div (click)="onShowAI()" class="option blue-wok">
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon class="item-icon">smart_toy</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n>Create image</span>
                <mat-icon class="qs-m" matTooltip="Create image from AI Prompter" i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
        </div>
    </div>
</form>
<div class="record-hint" fxLayout="column" *ngIf="showAI">
    <mat-form-field fxFlex="0 1 100%">
        <textarea
            #input
            matInput
            type="text"
            placeholder="Describe your image"
            i18n-placeholder
            [formControl]="aiText"
            (keyup)="onErrorMsg.next(null)"
            required
        >
        </textarea>
    </mat-form-field>
    <div class="error-msg" *ngIf="onErrorMsg.value">{{ onErrorMsg.value }}</div>
    <button
        [disabled]="!aiText.value || onErrorMsg.value"
        fxFlex="0 0 auto%"
        class="submit-btn"
        (click)="submitAI()"
        color="primary"
        mat-raised-button
    >
        <span i18n>Create image</span>
        <!-- <app-kd-logo-loading class="ai-loader" size="small"></app-kd-logo-loading> -->
    </button>
</div>
