<div
    fxLayout="row"
    fxLayoutAlign="center center">
    <mat-icon
        class="md-24 icon"
        color="warn">
        done
    </mat-icon>
    <ng-container>{{msg}}</ng-container>
</div>
