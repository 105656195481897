import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CustomerService } from '@klickdata/core/customer';
import { MobileService } from '@klickdata/core/mobile';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../../global-filters-types.enum';
import { ChipFilterComponent } from '../chip-filter/chip-filter.component';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';

@Component({
    selector: 'app-customer-filter',
    templateUrl: './customer-filter.component.html',
    styleUrls: ['./customer-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: ChipFilterComponent, useExisting: forwardRef(() => CustomerFilterComponent) },
        { provide: FilterBase, useExisting: CustomerFilterComponent },
    ],
})
export class CustomerFilterComponent extends ChipFilterComponent implements OnInit, OnDestroy {
    @Input() inGlobalFilter: boolean;
    @Input() placeholder = $localize`:@@filterByCustomer:Filter by customer...`;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public control: FormControl = new FormControl();
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.CUSTOMERS,
        items: [],
        label: $localize`:@@customers:Customers`,
        icon: 'supervised_user_circle',
        color: { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.WHITE },
        styleClass: 'global-customer-filter-selector',
    };

    constructor(
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService,
        protected customerService: CustomerService
    ) {
        super(changeRef, mobile);
    }

    ngOnInit() {
        this.control.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((query) =>
                    query
                        ? this.customerService.getCustomers(query, 10).pipe(
                              map((customers) =>
                                  customers.map((customer, index) => {
                                      return {
                                          id: customer.id,
                                          label: customer.name,
                                          selected: null, // (AS, EB SSC on 230918 skype)
                                      };
                                  })
                              )
                          )
                        : of([])
                )
            )
            .subscribe((items) => {
                this.source = items;
                if (items?.length) {
                    this.updateSelection();
                } else {
                    this.removeFilter();
                }
                this.changeRef.markForCheck();
            });
    }

    public clear() {
        super.clear();
        this.control.setValue('');
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
