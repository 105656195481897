import { filter } from 'rxjs/operators';
import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    Output,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-res-efficiency',
    templateUrl: './menu-side-res-efficiency.component.html',
    styleUrls: ['./menu-side-res-efficiency.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideResEfficiencyComponent implements OnInit, OnChanges {
    @Input() public navData: SideNaveData;
    public mandatoryPercentage: number;
    public optionalPercentage: number;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    constructor() {}

    ngOnInit(): void {
        this.getPercentage();
    }
    getPercentage() {
        if (this.navData && this.navData.data) {
            this.mandatoryPercentage =
                (100 *
                    this.navData.data.mandatory.filter((key: { [key: string]: string | boolean }) => key.hasValidValue)
                        .length) /
                this.navData.data.mandatory.length;
            this.optionalPercentage =
                (100 *
                    this.navData.data.optional.filter((key: { [key: string]: string | boolean }) => key.hasValidValue)
                        .length) /
                this.navData.data.optional.length;
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.getPercentage();
    }
}
