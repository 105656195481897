import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Alternative, AlternativeData } from './alternative';

import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { MediaService } from '@klickdata/core/media/src/media.service';

@Injectable({
    providedIn: 'root',
})
export class AlternativeService {
    protected resourceUrl: string;

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected mediaService: MediaService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}alternatives`;
    }

    public getByQuestion(questionId: number): Observable<Alternative[]> {
        return this.builder
            .get<AlternativeData[]>(this.resourceUrl)
            .param('question', questionId.toString())
            .param('sort', 'weight')
            .request()
            .pipe(map(res => this.mapAlternatives(res.data)));
    }

    public create(alternative: Alternative | AlternativeData): Observable<Alternative> {
        return this.builder
            .post<AlternativeData>(this.resourceUrl, alternative)
            .request()
            .pipe(map(res => this.createAlternative(res.data)));
    }

    /**
     * Method for storing multiple alternatives at a time.
     *
     * @param alternatives
     */
    public storeAll(alternatives: Alternative[] | AlternativeData[]): Observable<Alternative[]> {
        return this.builder
            .post<AlternativeData[]>(`${this.resourceUrl}/all`, { question_alternatives: alternatives })
            .request()
            .pipe(map(res => this.mapAlternatives(res.data)));
    }

    public update(alternative: Alternative | AlternativeData): Observable<Alternative> {
        return this.builder
            .put<AlternativeData>(`${this.resourceUrl}/${alternative.id}`, alternative)
            .request()
            .pipe(map(res => this.createAlternative(res.data)));
    }

    public destroy(alternative: Alternative | AlternativeData): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${alternative.id}`)
            .request()
            .pipe(map(res => res.data));
    }

    protected mapAlternatives(data: AlternativeData[]): Alternative[] {
        return data.map(item => this.createAlternative(item));
    }

    protected createAlternative(data: AlternativeData): Alternative {
        const alternative = new Alternative(data);
        alternative.media$ = alternative.media_id ? this.mediaService.getMedia(alternative.media_id) : EMPTY;
        return alternative;
    }
}
