import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Group, GroupService } from '@klickdata/core/group';
import { MessageDeletedComponent, MessageService } from '@klickdata/core/message';
import { Utils } from '@klickdata/core/util';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { EditForm } from './edit.form';
import { EditorTemplate } from '@klickdata/shared-components/src/text-editor/editor-template.model';
import { EditorTemplateService } from '@klickdata/shared-components/src/text-editor/editor-template.service';

@Component({
    selector: 'app-editor-edit-template',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EditForm],
})
export class EditComponent implements OnInit, OnDestroy {
    @Input()
    public customerId: number;
    @Input()
    public set template(template: EditorTemplate) {
        if (!template) {
            return;
        }
        this._template = template;
        this.editForm.import(this._template);
    }
    public get template(): EditorTemplate {
        return this._template;
    }
    protected _template: EditorTemplate;

    @Input()
    public newContent: string;

    @Input()
    public subject: string;

    @Input()
    public set fieldName(name: string) {
        this._fieldName = name;
    }

    public get fieldName(): string {
        return this._fieldName;
    }
    public _fieldName: string;

    @Input()
    public set templateSelected(selected: boolean) {
        this._templateSelected = selected;
    }

    public get templateSelected(): boolean {
        return this._templateSelected;
    }
    public _templateSelected: boolean;

    @Output()
    public cancel: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public selectTemplate: EventEmitter<EditorTemplate> = new EventEmitter<EditorTemplate>();

    protected destroy: Subject<boolean> = new Subject<boolean>();

    public groups: Observable<Group[]>;

    public loading = false;
    public tempChanged = false;
    public error: Subject<any> = new Subject();

    constructor(
        protected fb: FormBuilder,
        public editForm: EditForm,
        protected groupService: GroupService,
        protected templateService: EditorTemplateService,
        protected message: MessageService,
        protected changeRef: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        if (!this.template) {
            this.template = new EditorTemplate({
                subject: this.subject,
                body: this.newContent,
                field_name: this.fieldName,
            });
        }
        this.editForm.create(this.template).subscribe();

        if (this.customerId) {
            this.groups = this.groupService.getCustomerGroupsById(this.customerId).pipe(
                map(groups => {
                    if (groups.length === 0) {
                        this.editForm.disable('group_ids');
                    }
                    return groups;
                }),
                takeUntil(this.destroy)
            );
        } else {
            this.groups = this.groupService.getCustomerGroups().pipe(
                map(groups => {
                    if (groups.length === 0) {
                        this.editForm.disable('group_ids');
                    }
                    return groups;
                }),
                takeUntil(this.destroy)
            );
        }

        this.editForm
            .onChange()
            .pipe(
                map(change => {
                    if (
                        change.title === this.template.title &&
                        change.subject === this.template.subject &&
                        change.body === this.template.body &&
                        Utils.arraysEqual(change.group_ids, this.template.group_ids)
                    ) {
                        this.editForm.form.markAsPristine();
                    }
                    this.tempChanged = this.editForm.form.dirty && this.editForm.form.valid;
                    if (this.tempChanged) {
                        this.error.next(this.tempChanged);
                    }
                    this.changeRef.markForCheck();
                }),
                takeUntil(this.destroy)
            )
            .subscribe();
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public onCancel(): void {
        this.cancel.emit();
    }

    public onDelete(): void {
        this.loading = true;

        if (this.template && this.template.id) {
            this.templateService.destroy(this.template).subscribe(() => {
                this.cancel.emit();
                this.message.openMessage(MessageDeletedComponent);
            });
        } else {
            this.cancel.emit();
            this.message.openMessage(MessageDeletedComponent);
        }
    }

    public onSubmit(): void {
        this.loading = true;
        this.tempChanged = false;
        this.editForm.submit().subscribe(data => {
            this.loading = false;
            data.editable = this.template.editable;
            this.template = data;
            this.changeRef.markForCheck();
        });
    }

    public create(): void {
        this.loading = true;
        this.tempChanged = false;
        this.editForm.submitNew(this.template).subscribe(
            data => {
                this.loading = false;
                this.editForm.import(data);
                this.tempChanged = false;
                this.template = data;
                this.template.editable = true;
                this.changeRef.markForCheck();
            },
            error => {
                this.loading = false;
                this.error.next(error);
            }
        );
    }

    public onUseTemplate(): void {
        /**
         * Commit body changes when use without save,
         * User may be need to use template with some change and don't need to save this changes.
         */
        this.editForm.model.body = this.editForm.get('body').value;
        this.editForm.model.title = this.editForm.get('title').value;
        this.editForm.model.subject = this.editForm.get('subject').value;
        this.selectTemplate.emit(this.editForm.model);
    }

    selectAllGroups(groups: Group[]) {
        const groupControl = this.editForm.get('group_ids');
        groupControl.patchValue(groups.map(group => group.id));
        groupControl.markAsDirty();
        groupControl.updateValueAndValidity();
    }
}
