<div class="group-select-wrapper" fxLayout="column" fxFlex="0 0 100%">
    <app-table-filter [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>

        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Filter by selection"
            i18n-label="@@filterBySelection"
            [property]="GlobalFilterProperty.SELECTED"
            [options]="itemSelectedOptions"
            selectedItemsRef
        >
        </app-select-filter>
    </app-table-filter>

    <div fxLayoutAlign="start center" fxLayoutGap="10px" class="selection-Info">
        <div fxLayoutAlign="start center" fxLayoutGap="5px" class="main">
            <span i18n="@@numberOfCases" class="label">Number of cases:</span>
            <span class="total">{{ total }}</span>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="5px" class="selected" *ngIf="selected">
            <span class="selectedNu" i18n="@@selected">Selected:</span>
            {{ selected }}
        </div>
    </div>
    <mat-progress-bar
        class="progress progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        [matSortActive]="defaultSorting"
        (matSortChange)="onSortDataChages($event)"
        matSort
        [class.mobileView]="isMobile"
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="masterToggle()"
                    [checked]="isChecked()"
                    [indeterminate]="isIndeterminate()"
                >
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let case">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectToggle(case)"
                    [checked]="modelChecked(case)"
                    [disabled]="case.disabled"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let case">
                <span class="mobile-label" i18n="@@title"> Title </span>
                {{ case.title }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@createdAt"> Created at </mat-header-cell>
            <mat-cell *matCellDef="let case">
                <span i18n="@@createdAt" class="mobile-label"> Created at </span>
                {{ case.created_at | kdDateFormat: 'YYYY-MM-DD HH:mm' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updated_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@updatedAt"> Updated at </mat-header-cell>
            <mat-cell *matCellDef="let case">
                <span i18n="@@updatedAt" class="mobile-label"> Updated at </span>
                {{ case.updated_at | kdDateFormat: 'YYYY-MM-DD HH:mm' }}
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let case; columns: columns"
            [class.disable-row]="case.disabled"
            (click)="!case.disabled && selectToggle(case)"
        ></mat-row>
    </mat-table>

    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
