<div fxLayout="column" class="wrap">
    <div fxLayout="column" fxLayoutGap="15px">
        <app-chat-dialog
            *ngFor="let dialog of dialogs$ | async"
            [dialog]="dialog"
            [authorDir]="user?.id === dialog.author_id ? 'right' : 'left'"
            [fxLayoutAlign]="user?.id === dialog.author_id ? 'end center' : 'start center'"
        ></app-chat-dialog>
    </div>
    <form #formDirective="ngForm" fxLayout="row wrap" [formGroup]="subMessageForm">
        <div class="input-wrapper" fxLayout="column" fxLayoutGap="10px">
            <div fxLayoutAlign="space-between center">
                <span class="label" i18n>Reply</span>
                <mat-hint
                    >{{ subMessageForm.get('body')?.value.length }} <span> (5000)</span>
                    <mat-icon class="info" i18n-matTooltip matTooltip="Max input characters are 5000 letter"
                        >question_mark</mat-icon
                    ></mat-hint
                >
            </div>
            <mat-form-field floatLabel="never" appearance="fill">
                <textarea
                    maxlength="5000"
                    rows="3"
                    i18n-placeholder
                    placeholder="Type in your reply"
                    #message
                    matInput
                    formControlName="body"
                ></textarea>
            </mat-form-field>
        </div>
        <div fxFlex="100" fxLayoutAlign="end center">
            <button
                i18n-matTooltip
                matTooltip="Send chat message"
                mat-icon-button
                type="submit"
                [disabled]="!subMessageForm.get('body').value.length && !subMessageForm.get('media_ids').value.length"
                class="submit-btn"
                (click)="submitMsg()"
            >
                <mat-icon>send</mat-icon>
            </button>
        </div>
        <div class="input-wrapper" fxLayout="column" fxLayoutGap="10px">
            <app-media-attachment
                #mediaAttachments
                [maxImgCount]="10"
                formControlName="media_ids"
                [scope]="AppScope.USERS"
                [mediaIds]="subMessageForm.get('media_ids').value"
                (saving)="onUploading.next($event)"
                [isCreateForm]="true"
            ></app-media-attachment>
            <div fxLayoutAlign="center center" *ngIf="onUploading.value">
                <app-kd-logo-loading></app-kd-logo-loading>
            </div>
        </div>
    </form>
</div>
