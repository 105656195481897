import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MobileService } from '@klickdata/core/mobile';
import { UserService } from '@klickdata/core/user';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../../global-filters-types.enum';
import { ChipFilterComponent } from '../chip-filter/chip-filter.component';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';

@Component({
    selector: 'app-message-name-or-email-chip-search-filter',
    templateUrl: './message-name-or-email-chip-search-filter.component.html',
    styleUrls: ['./message-name-or-email-chip-search-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: ChipFilterComponent, useExisting: forwardRef(() => MessageNameOrEmailChipSearchFilterComponent) },
        { provide: FilterBase, useExisting: MessageNameOrEmailChipSearchFilterComponent },
    ],
})
export class MessageNameOrEmailChipSearchFilterComponent extends ChipFilterComponent implements OnInit, OnDestroy {
    private destroy: Subject<boolean> = new Subject<boolean>();
    public control: FormControl = new FormControl();
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.MESSAGE_USER,
        items: [],
        label: $localize`:@@name:Name`,
        icon: '3p',
        color: { bg: GlobalFilterColor.C3, fg: GlobalFilterColor.C1 },
        styleClass: 'global-message-user-filter-selector',
    };

    constructor(
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService,
        protected userService: UserService
    ) {
        super(changeRef, mobile);
    }

    ngOnInit() {
        this.control.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((query) => {
                    return query?.trim()
                        ? this.userService.getUsersRelatedToMessages(query).pipe(
                              map((users) =>
                                  users.map((user, index) => {
                                      return {
                                          id: user.id,
                                          label: user.name,
                                          extra_info: user.email,
                                          selected: index === 0,
                                      };
                                  })
                              )
                          )
                        : of([]);
                })
            )
            .subscribe((users) => {
                this.source = users;
                if (users?.length) {
                    this.updateSelection();
                } else {
                    this.removeFilter();
                }
                this.changeRef.markForCheck();
            });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
