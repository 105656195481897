import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { AuthService } from '@klickdata/core/auth';
import { Media } from '@klickdata/core/media/src/media.model';
import { Filter } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Observable, first, tap } from 'rxjs';

@Component({
    selector: 'app-menu-side-media-library',
    templateUrl: './menu-side-media-library.component.html',
    styleUrls: ['./menu-side-media-library.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideMediaLibraryComponent implements OnInit {
    @Output() onExpanding: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onSelectMedia: EventEmitter<Media> = new EventEmitter<Media>();
    public columns = ['thumbnil', 'filename', 'min_tools'];
    @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;
    public privateTableFilterItems: Filter<string | number>[];
    public academyTableFilterItems: Filter<string | number>[];
    public libraryTableFilterItems: Filter<string | number>[] = [
        { property: 'public', items: ['true'] },
        { property: 'media_type', items: ['image'] },
    ];
    public authUser: Observable<User>;
    constructor(protected auth: AuthService) {}

    ngOnInit(): void {
        this.authUser = this.auth.getUser().pipe(
            first(),
            tap((user) => {
                this.privateTableFilterItems = [
                    { property: 'author', items: [user.id] },
                    { property: 'media_type', items: ['image'] },
                ];
                this.academyTableFilterItems = [
                    { property: 'customer', items: [user.customer_id] },
                    { property: 'media_type', items: ['image'] },
                ];
            })
        );
    }
}
