<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon>engineering</mat-icon>
        <div fxLayoutAlign="start center" fxLayoutGap="5px">
            <span>{{ navData.data.title }}</span>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
        <mat-expansion-panel
            *ngFor="let manager of navData.data.managers"
            [expanded]="
                navData.data.managers?.length === 1 &&
                (navData.data.managers[0].title || navData.data.managers[0].about)
            "
            #expansionPanel
            class="expans-panel"
        >
            <mat-expansion-panel-header>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <app-image-view
                        class="manger-img"
                        [scope]="AppScope.USERS"
                        [media_id]="manager.media$"
                        [paddingBottom]="'56px'"
                    >
                    </app-image-view>
                    <!-- <div class="default-inst-img-def" *ngIf="!manager.media_id">
                        <mat-icon>engineering</mat-icon>
                    </div> -->
                    <span class="manager-name">{{ manager.name }}</span>
                </div>
            </mat-expansion-panel-header>
            <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
                <div
                    *ngIf="manager.title"
                    fxLayout="row"
                    fxLayoutAlign="center start"
                    fxLayoutGap="5px"
                    class="position"
                >
                    <span class="header-title" i18n>Position:</span>
                    <span>{{ manager.title }}</span>
                </div>
                <div
                    *ngIf="manager.about"
                    fxLayout="column"
                    fxLayoutAlign="center start"
                    fxLayoutGap="4px"
                    class="about"
                >
                    <span class="header-title" i18n>About:</span>
                    <span>{{ manager.about }}</span>
                </div>
            </div>
        </mat-expansion-panel>
    </div>
    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button fxFlex="0 0 auto" class="mobile-button positive" (click)="onClose.emit()" mat-button i18n>Ok</button>
    </div>
</div>
