import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Media } from '@klickdata/core/media/src/media.model';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-inner-html-display-dialog',
    templateUrl: './inner-html-display-dialog.component.html',
    styleUrls: ['./inner-html-display-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InnerHtmlDisplayDialogComponent implements OnInit {
    public contentBody: any;
    public neutralBtn: string;
    public contentTitle: string;
    public contentImg: any;
    public contentIngress: string;

    constructor(
        public dialogRef: MatDialogRef<InnerHtmlDisplayDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: any
    ) {
        if (data) {
            this.contentBody = data.contentBody;
            this.neutralBtn = data.neutralBtn;
            this.contentTitle = data.contentTitle;
            this.contentImg = data.contentImg;
            this.contentIngress = data.contentIngress;
        }
    }

    ngOnInit(): void {}
}
