import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {
    ControlContainer,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { FormHelper } from '@klickdata/core/form';
import { MessageFormErrorComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import {
    AppScope,
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemData, ResourceItemService } from '@klickdata/core/resource-item';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import * as moment from 'moment';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';
import { MaterialsManagerTextComponent } from '../../materials-manager-core/materials-manager-text/materials-manager-text.component';

@Component({
    selector: 'app-add-material-ai',
    templateUrl: './add-material-ai.component.html',
    styleUrls: ['./add-material-ai.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class AddMaterialAiComponent implements OnChanges, OnInit, OnDestroy, CanComponentDeactivate {
    public materialAIForm: FormGroup;
    AppScope = AppScope;
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saved: EventEmitter<{ res: Resource; isParentSubmission?: boolean }> = new EventEmitter<{
        res: Resource;
        isParentSubmission?: boolean;
    }>();
    @Input() resource: Resource;
    ResourceTypes = ResourceTypes;
    @Input() publishOnly: boolean;
    @Input() active = true;
    public prompt = new FormControl('');
    @Input() publishLabel = $localize`Publish`;
    public isAdmin$: Observable<boolean>;
    private resourceItem: ResourceItem;
    public customer$: Observable<Customer>;
    @ViewChild('aiCompRef') aiCompRef: MaterialsManagerTextComponent;
    ResourceBuildingItems = ResourceBuildingItems;
    private isParentSubmission: boolean;

    constructor(
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected itemService: ResourceItemService,
        protected auth: AuthService,
        protected messageService: MessageService,
        protected changeRef: ChangeDetectorRef,
        public router: Router,
        protected categoryService: ResourceCategoryService
    ) {
        this.buildForm();
        this.customer$ = this.auth.getCustomer().pipe(first());
        this.isAdmin$ = this.auth.getUser().pipe(
            first(),
            map((user) => user.isAdmin())
        );
    }
    ngOnChanges() {
        if (this.resource) {
            this.materialAIForm.patchValue({
                id: this.resource.id,
                priority: this.resource.priority,
                title: this.resource.title,
                media_id: this.resource.media_id,
                description: this.resource.description,
                // category_ids: this.resource.category_ids,
                language_id: this.resource.language_id,
            });
            FormHelper.resetForm(this.materialAIForm);
            this.update();
        } else {
            this.resource = new Resource();
        }
    }

    public buildForm() {
        this.materialAIForm = this.fb.group({
            id: [],
            priority: [],
            description: [''],
            media_id: [],
            title: ['', Validators.required],
            tag_ids: [[]],
            category_ids: [[], Validators.required],
            language_id: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.setDefaultCategory();
    }

    onSaved(res: Resource) {
        this.saved.emit({ res: res, isParentSubmission: this.isParentSubmission });
    }

    private setDefaultCategory() {
        this.categoryService
            .getSuggestedCategory(ResourceTypes.MATERIAL)
            .pipe(takeUntil(this.destroy))
            .subscribe((category) => {
                if (!this.materialAIForm.controls.category_ids.value?.length) {
                    this.materialAIForm.patchValue({
                        category_ids: [category.id],
                    });
                    FormHelper.resetForm(<FormControl>this.materialAIForm.controls.category_ids);
                }
            });
    }

    public update(): void {
        this.resource.item$?.pipe(takeUntil(this.destroy)).subscribe((item) => {
            this.resourceItem = item;
            FormHelper.resetForm(this.materialAIForm);
            this.changeRef.markForCheck();
        });
    }
    public submitMaterial() {
        this.isParentSubmission = true;
        this.submitAIMaterial();
    }
    public submitAIMaterial(form?: FormGroupDirective, publish?: boolean) {
        if (!this.materialAIForm.valid) {
            FormHelper.markForm(this.materialAIForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            this.onError.emit(true);
            return;
        }
        if (!this.materialAIForm.value.resource_items) {
            FormHelper.markForm(this.materialAIForm);
            this.messageService.openMessage(MessageErrorComponent, $localize`No items added`);
            this.onError.emit(true);
            return;
        }
        const items = this.materialAIForm.get('resource_items') as FormArray;
        if (
            this.materialAIForm.value.resource_items &&
            items.controls.some((item) => !item.value.name || !item.value.title)
        ) {
            FormHelper.markForm(this.materialAIForm);
            this.messageService.openMessage(MessageErrorComponent, $localize`Items should have content`);
            this.onError.emit(true);
            return;
        }

        this.prepareSubmit(publish).subscribe((resource) => {
            form?.resetForm();
            this.materialAIForm.reset();
            FormHelper.resetForm(this.materialAIForm);
            this.saving.emit(false);
            this.saved.emit({ res: resource, isParentSubmission: this.isParentSubmission });
            this.isParentSubmission = false;
            if (this.publishOnly) {
                this.setDefaultCategory();
            }
        });
    }

    public prepareSubmit(publish: boolean): Observable<Resource> {
        this.saving.emit(true);
        return this.prepareData(publish).pipe(
            switchMap((data) =>
                !this.resource?.id ? this.resourceService.store(data) : this.resourceService.update(data)
            ),
            takeUntil(this.destroy)
        );
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
    onLoading(ev: boolean) {
        this.saving.emit(ev);
    }
    protected prepareMaterialTextItem(): ResourceItemData[] {
        if (this.materialAIForm.value.material_text !== '') {
            return this.resourceItem && this.resourceItem.id
                ? [
                      {
                          id: this.resourceItem.id,
                          name: this.materialAIForm.value.material_text,
                      },
                  ]
                : [
                      {
                          name: this.materialAIForm.value.material_text,
                          item_type_value: 'text_material',
                      },
                  ];
        } else {
            if (this.resource && this.resourceItem?.id) {
                return [{ id: this.resourceItem.id, deleted: true }];
            }
        }
    }

    protected prepareData(publish: boolean): Observable<ResourceData> {
        const data = new Resource(this.materialAIForm.value).getData();
        // if (this.materialAIForm.get('material_text').dirty) {
        //     data.resource_items = this.prepareMaterialTextItem();
        // }
        if (publish) {
            data.published = moment().format('YYYY-MM-DD HH:mm:ss');
        }

        if (!this.resource?.id) {
            return combineLatest([this.auth.getCustomer(), this.auth.getUser()]).pipe(
                first(),
                map(([customer, user]) => {
                    data.author_id = user.id;
                    data.customer_id = customer.id;
                    data.type_id = ResourceTypes.TextMaterial;
                    return data;
                })
            );
        } else {
            return of(data);
        }
    }
    addFUQ(value: string) {}
    public canDeactivate(): boolean {
        return this.materialAIForm.pristine;
    }
}
