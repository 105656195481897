<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon class="{{ navData.data.iconClass }}">{{ navData.data.icon }}</mat-icon>
        <div>{{ navData.data.title }}</div>
    </div>
    <div class="desc">{{ navData.data.contentBody }}</div>

    <div
        *ngIf="!!navData.data.actions.length"
        class="action"
        fxLayout="row-reverse"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
    >
        <ng-container *ngFor="let action of navData.data.actions">
            <button
                fxFlex="0 0 auto"
                class="mobile-button positive {{ action.class }}"
                (click)="
                    onClose.emit(
                        action.value
                            ? {
                                  data: { type: navData.data.type, response: action.value }
                              }
                            : null
                    )
                "
                mat-button
            >
                {{ action.label }}
            </button>
        </ng-container>
    </div>
</div>
