<div
    fxLayout="row"
    fxLayoutAlign="center center">
    <mat-icon
        class="md-24 icon"
        color="warn">
        error
    </mat-icon>
    <ng-container>{{error}}</ng-container>
</div>
