import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { FilterSpecs } from '@klickdata/core/table/src/table-filter/filter';
import { User } from '@klickdata/core/user';
import { UserNotesService } from '@klickdata/core/user-notes';
import { UserNotes } from '@klickdata/core/user-notes/src/user-notes.model';
import { Utils } from '@klickdata/core/util';
import { Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { UserNotesListingService } from '../user-notes-listing.service';

@Component({
    selector: 'app-notes-resource-listing-shared',
    templateUrl: './notes-resource-listing-shared.component.html',
    styleUrls: ['./notes-resource-listing-shared.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    providers: [UserNotesListingService],
})
export class NotesResourceListingSharedComponent implements OnDestroy, AfterViewInit, OnInit {
    public destroy: Subject<boolean> = new Subject<boolean>();
    public dataSource = new TableSource<UserNotes>();
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    public columns = ['created_at', 'notes', 'author', 'context', 'expand'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    public predefinedTimeSpentOptions: SelectFilterOption[];
    public expandedRow: any | null;
    public authUser: User;
    public filterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.AUTHOR,
        label: $localize`:@@author:Author`,
        icon: 'drive_file_rename_outline',
    };

    constructor(
        protected route: ActivatedRoute,
        protected auth: AuthService,
        protected mobileService: MobileService,
        protected notesService: UserNotesService,
        protected userNotesListingService: UserNotesListingService,
        protected message: MessageService
    ) {
        this.predefinedTimeSpentOptions = Utils.getPredefinedTimeSpentOptions();
    }
    ngOnInit(): void {
        this.dataSource.service = this.userNotesListingService;
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => (this.authUser = user));
    }
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        if (this.filter) {
            this.filter.setActiveFilter(GlobalFilterProperty.SEARCH);
            this.filter.createOrUpdateFilter([new Filter('eager', ['resource'])]);
            // this.filter.createOrUpdateFilter([new Filter('author', ['false'])]);
        }
    }
    public onResClick(res: string) {
        this.filter.setActiveFilterWithQuery(GlobalFilterProperty.SEARCH, res);
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
