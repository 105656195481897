<mat-form-field
    *ngIf="gradesSystems | async; else loading; let systems"
    fxFlex="0 0 400px"
    fxFlex.lt-sm="0 0 100%">
    <mat-select
        [formControl]="gradeControl"
        placeholder="Grade system"
        i18n-placeholder>
        <mat-option
            *ngFor="let system of systems"
            [value]="system.id">
            {{system.title}}
        </mat-option>
    </mat-select>
    <mat-error i18n>Grade system is required!</mat-error>
</mat-form-field>

<ng-template #loading>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
