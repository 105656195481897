import { ValidatorFn } from '@angular/forms';
import 'reflect-metadata';

const formatMetadataKey = Symbol('property');

export interface PropertyData {
    validation?: ValidatorFn | ValidatorFn[];
    default?: any;
}

export function Prop(data?: PropertyData) {
    return (target, property) => {
        const classConstructor = target.constructor;
        const metadata = Reflect.getMetadata(formatMetadataKey, classConstructor) || {};
        metadata[property] = data || true;
        Reflect.defineMetadata(formatMetadataKey, metadata, classConstructor);
    };
}

export function getAllProperties(target): { [key: string]: PropertyData } {
    return Reflect.getOwnMetadata(formatMetadataKey, target);
}
