import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { Alternative } from '@klickdata/core/question';
import { ResourceTypes, AppScope } from '@klickdata/core/resource';
import { Observable, Subscription } from 'rxjs';
import { ResourceBuilderService } from '../../../../resource-builder.service';

@Component({
    selector: 'app-resource-builder-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('title') titleInput: ElementRef;
    @Input()
    public alternative: Alternative;

    @Input()
    public alternativeForm: FormGroup;

    @Input()
    public editable: boolean;
    @Output() onShowExplaination: EventEmitter<boolean> = new EventEmitter<boolean>();
    public changeSub: Subscription;

    public showBranching = false;
    // public published: boolean;
    public isMobile: Observable<boolean>;
    AppScope = AppScope;

    constructor(
        protected builder: ResourceBuilderService,
        protected messageService: MessageService,
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService
    ) {}

    public ngOnInit() {
        this.isMobile = this.mobile.isMobile();
        this.showBranching = this.builder.resource_type_id === ResourceTypes.GeneralSurvey; // TODO handle all types
        this.changeSub = this.alternativeForm.valueChanges.subscribe(() => {
            this.changeRef.markForCheck();
        });
        // this.published = this.builder.published;
        /**
         * EB asked on 221215 for >> the qs can be edited (if permitted)
         * even if published
         */
        // if (this.published) {
        //     this.alternativeForm.get('points').disable();
        // }
    }

    ngAfterViewInit() {
        this.titleInput?.nativeElement?.focus();
    }

    ngOnDestroy(): void {
        if (this.changeSub) {
            this.changeSub.unsubscribe();
            this.changeSub = null;
        }
    }

    public onToggle() {
        if (!this.alternativeForm.disabled) {
            this.alternativeForm.disable();
        } else {
            this.alternativeForm.enable();
        }
        FormHelper.markForm(this.alternativeForm);
    }

    public removeImage() {
        this.alternativeForm.patchValue({ media_id: null });
        FormHelper.markForm(this.alternativeForm);
    }

    public onBranshing(): void {
        this.messageService.message($localize`:@@branchingIsNotYetAvailable:Branching is not yet available`);
    }
}
