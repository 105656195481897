import { AccessCapability } from '../can-access.directive';

export namespace GeneralCapability {
    export const NONE = 0;
    export const VIEW_KLICK_DATA = 1 << 0;
    export const VIEW_ADMIN = 1 << 1;
    export const VIEW_MASTER = 1 << 2;
    export const VIEW_PLAYER = 1 << 3;

    export const VIEW_ADMIN_ACCOUNTS = 1 << 4;
    /** admin stats */
    export const VIEW_ADMIN_STATISTICS = 1 << 5;
    /** admin content */
    export const VIEW_ADMIN_CONTENT = 1 << 6;
    /** admin content */
    export const VIEW_ADMIN_SETTINGS = 1 << 7;
    /** user content */
    export const VIEW_USER_CONTENT = 1 << 8;

    export function getName(): string {
        return 'general';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
