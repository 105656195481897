<ng-container *ngIf="!(loading$ | async); else loading">
    <ng-container *ngIf="editableMsg">
        <div fxLayout="column" fxLayoutAlign="start center">
            <div class="w-100" fxLayout="row" fxLayoutAlign="start center">
                <span class="label" i18n>Deadline</span>
            </div>
            <div class="input-wrapper dates-time-piker" fxLayout="column">
                <mat-form-field class="small-field mt-1" appearance="fill">
                    <input
                        matInput
                        [ngxMatDatetimePicker]="picker"
                        placeholder="Choose a date and time"
                        i18n-placeholder
                        [formControl]="endDate"
                        [min]="minDate"
                    />
                    <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker color="primary" #picker>
                        <ng-template>
                            <span>Done</span>
                        </ng-template>
                    </ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span class="label" i18n>Add content</span>
            <button
                class="clear-all"
                mat-icon-button
                matTooltip="Clear all content"
                i18n-matTooltip
                *ngIf="contents.length > 1"
                (click)="contents.length = 0"
            >
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
        <div class="input-wrapper" fxLayout="column">
            <mat-form-field class="small-field mt-1" appearance="fill">
                <input
                    type="text"
                    matInput
                    #resourceInput
                    i18n-placeholder
                    placeholder="Search material by title"
                    [formControl]="contentCtrl"
                    [matAutocomplete]="auto"
                />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedContent($event, auto)">
                    <mat-option class="mb-1" *ngFor="let resource of filteredContent | async" [value]="resource">
                        <div class="selct-mat-msg" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <app-image-view
                                [smallSpinner]="true"
                                [media_id]="resource.media$"
                                [proportional]="true"
                            ></app-image-view>
                            <span>{{ resource.title }}</span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <ng-container *ngTemplateOutlet="contentsTemp"></ng-container>
    </ng-container>
    <ng-container *ngIf="!editableMsg">
        <div class="chat-row left" fxLayout="left" fxLayoutAlign="start start" fxLayoutGap="10px">
            <div class="icon-bubble">
                <mat-icon>school</mat-icon>
            </div>
            <div fxLayout="column" class="mt-1 w-100 chat-bulk" fxLayoutAlign="left" fxLayoutGap="5px">
                <span fxLayout="row" class="time-right"
                    ><span i18n>Case</span> &nbsp;
                    <span>({{ msg?.created_at | kdDateFormat: 'YYYY-MM-DD HH:mm' }})</span></span
                >
                <div class="body-wrapper">
                    <span *ngIf="msg?.subject" class="subject">{{ msg?.subject }}</span>
                    <span *ngIf="msg?.body" class="body">{{ msg?.body }}</span>
                    <ng-container *ngTemplateOutlet="contentsTemp"></ng-container>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #contentsTemp>
    <mat-chip-list *ngIf="contents.length" class="res-chips mb-1" #chipList aria-label="contents selection">
        <mat-chip *ngFor="let content of contents" (removed)="removeContent(content)" [selectable]="false">
            <div class="selct-mat-msg" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <app-image-view
                    [smallSpinner]="true"
                    [media_id]="content.media$"
                    [proportional]="true"
                ></app-image-view>
                <span>{{ content.title }}</span>
            </div>

            <button *ngIf="editableMsg" matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
            <button
                *ngIf="!editableMsg"
                mat-icon-button
                [routerLink]="['/home/dashboard/resource-details/' + content.id]"
            >
                <mat-icon>visibility</mat-icon>
            </button>
        </mat-chip></mat-chip-list
    >
</ng-template>
