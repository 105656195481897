import { ErrorHandler, Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import * as Sentry from '@sentry/browser';
import { environment } from 'apps/klickdata/src/environments/environment';
import { ApplicationService } from './application.service';
import { ErrorDialogService } from './error-dialog/error-dialog.service';
import { LoggerService } from './logger/logger.service';

Sentry.init({
    dsn: 'https://cffbc5a6998c4d548be108f1f02e2417@sentry.klickdata.se/2',
    allowUrls: [
        /.+?\.k3\.io/,
        /.+?\.klickdata\.se/,
        /.+?\.klickportalen\.se/,
        /.+?\.utbildning\.online/,
        /.+?\.growknowledge\.net/,
    ],
    environment: environment.buildMode,
    ignoreErrors: ['Non-Error exception captured'],
});

@Injectable({ providedIn: 'root' })
export class AppErrorHandler extends ErrorHandler {
    protected errorReported = false;

    constructor(
        protected app: ApplicationService,
        protected auth: AuthService,
        protected config: ConfigService,
        protected logger: LoggerService,
        protected dialog: ErrorDialogService
    ) {
        super();
    }

    /**
     * Handled error by @see HttpErrorService throwError @see ErrorResponse
     * None handled error of type @see HttpErrorResponse
     */
    handleError(error) {
        /**
         * Using lazy loading may happens you to face an odd issue Loading chunk [number] failed
         */
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (error.message && chunkFailedMessage.test(error.message)) {
            window.location.reload();
            return;
        }
        // Handle Http Error (error.status === 403, 404...)
        const status = error.error?.statusCode || error.status || 0;
        if (!navigator.onLine) {
            // Handle offline error
            this.logger.error(
                $localize`We encountered an error, please check your internet connection and reload this page.`
            );
        } else if (status === 401) {
            this.auth.handleInvalidToken();
        } else if (status === 403) {
            this.auth.handleForbidden();
        } else if (status === 500) {
            this.dialog.open(error?.error?.error?.messages);
        } else {
            // Handle Client Error (Angular Error, ReferenceError...)
            if (this.config.config.buildMode === 'local') {
                throw error;
            } else {
                Sentry.configureScope((scope) =>
                    scope.addEventProcessor((event) => {
                        event.release = this.app.getVersionValue();
                        return event;
                    })
                );
                Sentry.captureException(
                    error instanceof Error ? error : new Error(JSON.stringify(error.error || error.message || error))
                );
                this.dialog.open(Sentry.lastEventId());
            }
        }
        this.errorReported = true;
    }
}
