<div fxLayout="row wrap">
    <div fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="space-between center">
        <!-- <app-general-recorder [user]="user$ | async"></app-general-recorder> -->
        <button fxFlex="0 0 auto" class="mobile-button" (click)="onClose()" mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
    </div>

    <div fxFlex="1 1 100%" class="module-menu">
        <ng-content></ng-content>
    </div>
</div>
