<form
    *ngIf="!inGlobalFilter; else globalFilterTemplate"
    [formGroup]="rangeForm"
    fxLayout="row"
    fxFlex.lt-sm="0 0 80%"
    fxFlex="0 0 350px"
    class="date-filter-wrapper"
    fxLayoutAlign="start center"
>
    <mat-form-field floatLabel="never" appearance="fill">
        <mat-label>{{ label }}</mat-label>
        <mat-date-range-input [min]="min | async" [max]="max" [rangePicker]="picker">
            <input matStartDate formControlName="begin" placeholder="Start date" />
            <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error i18n>A valid date range is required!</mat-error>
    </mat-form-field>
</form>

<ng-template #globalFilterTemplate>
    <form [formGroup]="rangeForm">
        <mat-form-field appearance="fill">
            <mat-label>{{ label }}</mat-label>
            <mat-date-range-input [min]="min | async" [max]="max" [rangePicker]="picker">
                <input matStartDate formControlName="begin" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error i18n>A valid date range is required!</mat-error>
        </mat-form-field>
    </form>
</ng-template>

<ng-template #activeFilterTemplate let-filter="filter">
    <mat-icon>{{ filter.icon }}</mat-icon>
    <span>
        <span i18n="@@from">From</span>: {{ filter.items[0] }} - <span i18n="@@to">To</span>:
        {{ filter.items[1] }}</span
    >
</ng-template>
