import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-resource-creation-assignment-sheet',
    templateUrl: './resource-creation-assignment-sheet.component.html',
    styleUrls: ['./resource-creation-assignment-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceCreationAssignmentSheetComponent implements OnInit, OnDestroy {
    public assignForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public saving: boolean;

    constructor(
        public bottomSheetRef: MatBottomSheetRef<ResourceCreationAssignmentSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected resourceService: ResourceService
    ) {
        this.assignForm = this.fb.group({
            id: [],
            sync_all_users: [],
            users_attach: [[]],
            users_detach: [[]],
            sync_all_groups: [],
            groups_attach: [[]],
            groups_detach: [[]],
        });
    }

    ngOnInit() {
        this.assignForm.patchValue({ id: this.data.id });
    }

    public submit() {
        if (
            this.assignForm.value.sync_all_users !== null ||
            this.assignForm.value.users_attach?.length ||
            this.assignForm.value.users_detach?.length ||
            this.assignForm.value.sync_all_groups !== null ||
            this.assignForm.value.groups_attach?.length ||
            this.assignForm.value.groups_detach?.length
        ) {
            if (this.data.id) {
                const data = new Resource(this.assignForm.value).getData();
                this.saving = true;
                this.resourceService
                    .update(data)
                    .pipe(
                        map((res) => {
                            this.assignForm.patchValue({ id: data.id });
                            return res;
                        }),
                        takeUntil(this.destroy)
                    )
                    .subscribe((res) => {
                        this.saving = false;
                        this.bottomSheetRef.dismiss(res);
                    });
            } else {
                this.bottomSheetRef.dismiss(this.assignForm.value);
            }
        } else {
            this.bottomSheetRef.dismiss();
        }
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
