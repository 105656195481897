import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { Customer } from '@klickdata/core/customer';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { PaymentService } from '@klickdata/core/payment';
import { Resource } from '@klickdata/core/resource';
import { DialogPaytabsPaymentPageComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-paytabs-payment-page/dialog-paytabs-payment-page.component';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-purchase-manager',
    templateUrl: './purchase-manager.component.html',
    styleUrls: ['./purchase-manager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseManagerComponent extends OnDestroyHandler {
    @Input() resource: Resource;
    @Input() gateway: string;
    @Input() subscriptionPlanId: number;
    @Output() paymentSucceeded: EventEmitter<boolean> = new EventEmitter();
    public customer: Customer;
    public isLoading$: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected configService: ConfigService,
        protected paymentService: PaymentService,
        protected dialog: MatDialog,
        protected messageService: MessageService,
        protected auth: AuthService
    ) {
        super();
        this.auth
            .getCustomer()
            .pipe(takeUntil(this.destroy))
            .subscribe((customer) => (this.customer = customer));
    }

    pay() {
        this.isLoading$.next(true);
        this.paymentService
            .pay(this.gateway, { resourceId: this.resource?.id, planId: this.subscriptionPlanId })
            .pipe(takeUntil(this.destroy))
            .subscribe(
                (res) => {
                    if (res?.redirect_url) {
                        this.openIframe(res);
                    } else {
                        this.messageService.openMessage(MessageErrorComponent, $localize`No redirect url`);
                    }
                    this.isLoading$.next(false);
                },
                (err) => {
                    if (err && err.error && err.error.error) {
                        err.error.error.messages;
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                    }
                    this.isLoading$.next(false);
                }
            );
    }
    openIframe(res) {
        this.dialog
            .open(DialogPaytabsPaymentPageComponent, {
                width: '700px',
                height: '600px',
                data: res,
                disableClose: true,
            })
            .afterClosed()
            .pipe(takeUntil(this.destroy))
            .subscribe((status) => this.paymentSucceeded.emit(status));
    }
}
