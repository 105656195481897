import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Alternative, AlternativeService, Question } from '@klickdata/core/question';

@Component({
    selector: 'app-resource-builder-alternatives',
    templateUrl: './alternatives.component.html',
    styleUrls: ['./alternatives.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlternativesComponent implements OnInit, OnChanges {
    @Input()
    public alternativeFormArray: FormArray;

    @Input()
    public question: Question;

    @Input()
    public editable: boolean;

    @Input()
    public questionType: string;

    @Input()
    public usePoints = true;

    public alternatives: Observable<Alternative[]>;

    constructor(protected alternativeService: AlternativeService) {}

    public ngOnInit() {
        if (this.question) {
            this.loadAlternatives();
        }
    }

    public ngOnChanges() {
        if (this.question) {
            this.loadAlternatives();
        }
    }

    public loadAlternatives(): void {
        if (this.question.id) {
            this.alternatives = this.alternativeService.getByQuestion(this.question.id);
        } else {
            this.alternatives = of([]);
        }
    }
}
