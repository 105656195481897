<div *ngIf="!inGlobalFilter; else globalFilterTemplate" class="chip-container" fxLayout="column">
    <div fxLayout="row" fxFlex.lt-sm="0 0 80%" fxFlex="0 0 40%" fxLayoutAlign="start center">
        <mat-form-field fxFlex="1 1 450px" class="search-box">
            <input fxFlex="0 0 100%" matInput #search [placeholder]="placeholder" [formControl]="control" />
            <button matSuffix mat-icon-button *ngIf="!search.value.length">
                <mat-icon class="search-icon">search</mat-icon>
            </button>
            <button mat-icon-button matSuffix *ngIf="search.value.length" (click)="clear()">
                <mat-icon>clear</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <mat-chip-list [ngClass]="{ 'mobile-view': isMobile | async }" [multiple]="multiple">
        <ng-container *ngFor="let item of source">
            <mat-chip class="chip-point" [value]="item.id" [selected]="item.selected">
                {{ item.label }}
            </mat-chip>
        </ng-container>
    </mat-chip-list>
</div>

<ng-template #globalFilterTemplate>
    <div class="chip-container" fxLayout="column" fxLayoutGap="0px" fxLayoutGap.gt-sm="10px">
        <mat-form-field class="search-box" appearance="fill">
            <mat-label i18n="@@customer">Customer</mat-label>
            <input
                matInput
                #search
                placeholder="Filter by customer..."
                i18n-placeholder="@@filterByCustomer"
                [formControl]="control"
            />
        </mat-form-field>
        <h4 *ngIf="source?.length" class="filter-header" i18n="@@chooseCustomer">Choose customer</h4>
        <mat-chip-list
            fxFlex="0 0 100%"
            [class.top-align]="source?.length"
            [ngClass]="{ 'mobile-view': isMobile | async }"
            [multiple]="multiple"
        >
            <ng-container *ngFor="let item of source">
                <mat-chip class="chip-point" [value]="item.id" [selected]="item.selected">
                    {{ item.label }}
                </mat-chip>
            </ng-container>
            <div *ngIf="source?.length" class="seperater"></div>
        </mat-chip-list>
    </div>
</ng-template>

<ng-template #activeFilterTemplate let-filter="filter">
    <mat-icon>{{ filter.icon }}</mat-icon>
    <span *ngFor="let item of filter.chips; let last = last">{{ item.label }}<span *ngIf="!last">,&nbsp;</span> </span>
</ng-template>
