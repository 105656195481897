<app-header class="nk3-header"></app-header>

<div class="nk3-container">
    <article class="nk3-content" role="main">
        <mat-card class="nk3-404-card"><h2 class="title" i18n>404: Page not found</h2></mat-card>
        <div fxLayoutAlign="center center">
            <button mat-raised-button color="primary" [routerLink]="['/home']" i18n="@@goToDashboard">
                Go to dashboard
            </button>
        </div>
    </article>

    <app-footer></app-footer>
</div>
