import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostBinding,
    Inject,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    SkipSelf,
} from '@angular/core';
import { MatAccordionBase, MAT_ACCORDION } from '@angular/material/expansion';
import { MobileService } from '@klickdata/core/mobile';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export type FilterPanelState = 'expanded' | 'collapsed';

/** Time and timing curve for expansion panel animations. */
export const EXPANSION_PANEL_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';

@Component({
    selector: 'app-table-filter-expansion',
    templateUrl: './expansion.component.html',
    styleUrls: ['./expansion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('bodyExpansion', [
            state('collapsed', style({ height: '0px', visibility: 'hidden' })),
            state('expanded', style({ height: '*', visibility: 'visible' })),
            transition('expanded <=> collapsed', animate(EXPANSION_PANEL_ANIMATION_TIMING)),
        ]),
    ],
})
export class ExpansionComponent extends CdkAccordionItem implements OnInit, OnDestroy, OnChanges {
    @HostBinding('@bodyExpansion') bodyExpansion: string;
    private destroy: Subject<boolean> = new Subject<boolean>();
    private _expandedInputChanged: boolean;

    constructor(
        @Optional() @SkipSelf() @Inject(MAT_ACCORDION) accordion: MatAccordionBase,
        _changeDetectorRef: ChangeDetectorRef,
        _uniqueSelectionDispatcher: UniqueSelectionDispatcher,
        protected mobile: MobileService
    ) {
        super(accordion, _changeDetectorRef, _uniqueSelectionDispatcher);
    }

    ngOnInit() {
        if (!this._expandedInputChanged) {
            this.mobile
                .isMobile()
                .pipe(takeUntil(this.destroy))
                .subscribe(isMobile => {
                    this.expanded = !isMobile;
                    this.bodyExpansion = this.getExpandedState();
                });
        } else {
            this.bodyExpansion = this.getExpandedState();
        }
    }

    public ngOnChanges(change: any) {
        this._expandedInputChanged = !!change.expanded;
    }

    public toggle(): void {
        super.toggle();
        this.bodyExpansion = this.getExpandedState();
    }

    public close(): void {
        super.close();
        this.bodyExpansion = 'collapsed';
    }

    protected getExpandedState(): FilterPanelState {
        return this.expanded ? 'expanded' : 'collapsed';
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
