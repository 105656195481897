import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatChip } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, PlatformType } from '@klickdata/core/auth';
import { GloalSearchService, GlobalSearchData } from '@klickdata/core/global-search';
import { LanguageService } from '@klickdata/core/localization';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceOpportunityService } from '@klickdata/core/opportunity';
import { AppScope } from '@klickdata/core/resource';
import { SectionType } from '@klickdata/core/resource/src/types.enum';
import { Section } from '@klickdata/core/section';
import {} from '@klickdata/core/table';
import { UserStatisticsService } from '@klickdata/home/src/core/user-statistics.service';
import { DashboardActivityActionDirective } from '@klickdata/home/src/dashboard-with-sections/progress-overview-section/dashboard-activity-action';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { ResourceListingSheetComponent } from '../../resource-listing-sheet/resource-listing-sheet.component';
import { GenericTabsOption } from './../../generic-mobile-tabs/generic-mobile-tabs.component';
import { SearchResultsSectionDataService } from './search-results-section-data.service';

@Component({
    selector: 'app-main-results',
    templateUrl: './main-results.component.html',
    styleUrls: ['./main-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SearchResultsSectionDataService],
})
export class MainResultsComponent extends DashboardActivityActionDirective implements OnInit, AfterViewInit, OnDestroy {
    public section: Section;
    public isMobile: Observable<boolean>;
    public searchDataItems$: Observable<Map<number, GlobalSearchData[]>>;
    public loading: boolean;
    public hideFooter: boolean;
    private destory: Subject<boolean> = new Subject<boolean>();
    AppScope = AppScope;
    SectionType = SectionType;
    Number = Number;
    public mappedScopesWithCounts: { scope: AppScope; count: number }[] = [];
    public scopes: AppScope[];
    public attachedLanguages: Observable<number[]>;
    @ViewChildren(MatChip) chips: QueryList<MatChip>;
    public selectedScope: AppScope;
    public availableScopes: number[];
    public active: 0 | 1 = 1;
    searchKey: string;
    public isSearchBoxActive: Observable<boolean>;
    public nk3Platform: string;
    public activityOptions: GenericTabsOption[];
    filesSamples = [];

    constructor(
        protected location: Location,
        protected router: Router,
        protected route: ActivatedRoute,
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService,
        protected auth: AuthService,
        protected searchService: GloalSearchService,
        protected bottomSheet: MatBottomSheet,
        protected localizationService: LanguageService,
        public service: SearchResultsSectionDataService,
        protected userStatisticsService: UserStatisticsService,
        protected dialog: MatDialog,
        protected resourceOpportunityService: ResourceOpportunityService
    ) {
        super(
            router,
            userStatisticsService,
            dialog,
            resourceOpportunityService,
            mobile,
            auth,
            location,
            bottomSheet,
            null
        );
        this.activityOptions = [
            {
                label: $localize`All activity`,
                url: './all',
                value: 'all',
                index: 0,
            },
            {
                label: $localize`:@@activityOptionOngoing:Ongoing`,
                url: './ongoing',
                value: 'ongoing',
                index: 1,
            },
            {
                label: $localize`Assigned`,
                url: './assigned',
                value: 'assigned',
                index: 2,
            },
            {
                label: $localize`Recommended`,
                url: './recommended',
                value: 'recommended',
                index: 3,
            },

            {
                label: $localize`Completed`,
                url: './completed',
                value: 'completed',
                index: 4,
            },
        ];
        this.auth
            .getNK3Platform()
            .pipe(takeUntil(this.destory))
            .subscribe((platform) => {
                this.nk3Platform = platform;
                this.hideFooter = platform === 'guest';
                this.scopes = this.getFilters(platform);
                this.getFilters(platform).forEach((filterScope) =>
                    this.mappedScopesWithCounts.push({ scope: filterScope, count: 0 })
                );
                this.changeRef.markForCheck();
            });
    }
    ngOnInit() {
        this.isSearchBoxActive = this.searchService.getSearchBoxState();
        this.attachedLanguages = this.localizationService.attachedLanguages.pipe(
            map((langs) => langs.map((lang) => lang.id))
        );
        this.searchDataItems$ = this.route.queryParams.pipe(
            debounceTime(50),
            distinctUntilChanged(),
            switchMap((queryParam) => {
                this.selectedScope = AppScope[<string>queryParam.scope.toUpperCase()] || AppScope.ALL;
                this.loading = true;
                this.searchKey = queryParam.q;
                return this.service.getData({ query: queryParam.q, param: { scope: 'all' }, page: 0 });
            }),
            map((res) => {
                this.mapScopesWithCount(res);
                this.loading = false;
                this.availableScopes = [...res.keys()];
                return res;
            })
        );
        this.isMobile = this.mobile.isMobile().pipe(shareReplay());
        // this.scopes$ = this.auth.getNK3Platform().pipe(
        //     tap((platorm) => (this.hideFooter = platorm === 'guest')),
        //     map((platfrom) => this.getFilters(platfrom))
        // );
        if (!this.route.snapshot.queryParams.scope) {
            this.searchAll();
        }
    }
    mapScopesWithCount(res) {
        for (let entry of res.entries()) {
            if (this.mappedScopesWithCounts.find((mappedScope) => mappedScope.scope === entry[0])) {
                this.mappedScopesWithCounts.find((mappedScope) => mappedScope.scope === entry[0]).count =
                    entry[1].length;
            }
        }
    }
    public ngAfterViewInit(): void {
        this.chips.changes
            .pipe(
                takeUntil(this.destory),
                switchMap((change: QueryList<MatChip>) => {
                    return merge(
                        ...change.map((item) => {
                            return fromEvent(item._elementRef.nativeElement, 'click').pipe(
                                map((ev) => {
                                    this.updateSelectedChip(item);
                                    return ev;
                                })
                            );
                        })
                    );
                })
            )
            .subscribe();
        setTimeout(() => this.searchService.activateSearchBox(true), 500);
    }

    public updateSelectedChip(item: any) {
        if (item instanceof MatChip) {
            item.select();
            this.updateScope(AppScope[item.value].toLowerCase());
        } else {
            this.updateScope(AppScope[item].toLowerCase());
        }
    }

    private updateScope(scope: string) {
        this.router.navigate([], {
            queryParams: {
                scope: scope,
            },
            queryParamsHandling: 'merge',
        });
    }

    public searchAll() {
        this.selectedScope = AppScope.ALL;
        this.updateScope(AppScope[AppScope.ALL].toLowerCase());
    }

    public showResources(item: GlobalSearchData) {
        this.bottomSheet.open(ResourceListingSheetComponent, {
            data: {
                id: item.id,
                label: item.label,
                scope_id: item.scope_id,
            },
            panelClass: 'sheet-wrapper',
        });
    }
    downloadFile(file) {}
    editFile(file) {}
    public handleLinkClick(item: GlobalSearchData) {
        this.dismissFilterBar();
        switch (item.scope_id) {
            case AppScope.USERS:
                this.router.navigate(['/admin/accounts/users/users/', item.id]);
                break;
            case AppScope.GROUPS:
                this.router.navigate(['/admin/accounts/groups/', item.id]);
                break;
            case AppScope.CUSTOMERS:
                this.router.navigate(['/master/customers/', item.id]);
                break;
        }
    }
    ngOnDestroy(): void {
        this.destory.next(true);
        this.destory.unsubscribe();
    }

    private getFilters(platfrom: PlatformType): AppScope[] {
        const base = [
            AppScope.COURSE,
            AppScope.SURVEY,
            AppScope.TEST,
            AppScope.E_COURSE,
            AppScope.MATERIAL,
            AppScope.FOLDERS,
            AppScope.SECTIONS,
            AppScope.TAGS,
            AppScope.CATEGORIES,
            AppScope.SCORM,
            AppScope.POST,
            AppScope.QUOTE,
            AppScope.FILE,
        ];
        if (platfrom === 'admin') {
            return [...[AppScope.ALL, AppScope.USERS, AppScope.GROUPS], ...base];
        } else if (platfrom === 'master') {
            return [...[AppScope.ALL, AppScope.CUSTOMERS, AppScope.USERS, AppScope.GROUPS], ...base];
        } else if (platfrom === 'guest') {
            return [AppScope.COURSE, AppScope.E_COURSE, AppScope.ALL];
        } else {
            return [AppScope.ALL, ...base];
        }
    }

    public isEmpty(obj: Map<number, GlobalSearchData[]> | GlobalSearchData[]): boolean {
        return !(obj instanceof Map ? obj.size : obj.length);
    }
    public dismissFilterBar() {
        this.searchService.activateSearchBox(false);
    }

    noSort() {
        return 0;
    }
}
