import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'taskType',
})
export class TaskTypePipe implements PipeTransform {
    transform(type: string): { label: string; color: string; icon: string } {
        switch (type) {
            case 'client':
                return { label: $localize`Client`, color: '#454545', icon: 'business' };
            case 'offer':
                return { label: $localize`Offerers`, color: '#93cbd1', icon: 'gpp_good' };
            case 'prospect':
                return { label: $localize`Prospect`, color: '#ff9961', icon: 'gpp_maybe' };
            case 'nothanker':
                return { label: $localize`Nothanker`, color: '#e44a66', icon: 'gpp_bad' };
            case 'contact':
                return { label: $localize`Contact`, color: '#bfd8d0', icon: 'person' };
            case 'other':
                return { label: $localize`Other`, color: '#bfd8d0', icon: 'dynamic_feed' };
            default:
                return { label: $localize`Contact`, color: '#bfd8d0', icon: 'person' };
        }
    }
}
