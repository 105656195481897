import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MobileService } from '@klickdata/core/mobile';
import { Resource } from '@klickdata/core/resource';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-tests-results',
    templateUrl: './tests-results.component.html',
    styleUrls: ['./tests-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestsResultsComponent implements OnInit {
    @Input() public resource: Resource;
    public isMobile$: Observable<boolean>;
    constructor(protected mobileService: MobileService) {}

    ngOnInit(): void {
        this.isMobile$ = this.mobileService.isMobile();
    }
}
