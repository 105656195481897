import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { SideNaveActionsTypes, SideNaveData, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { first } from 'rxjs/operators';
import { AiPrompterManagerComponent } from '../ai-prompter-manager/ai-prompter-manager.component';
import { Utils } from '@klickdata/core/util';

@Component({
    selector: 'app-menu-side-ai-prompter',
    templateUrl: './menu-side-ai-prompter.component.html',
    styleUrls: ['./menu-side-ai-prompter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAiPrompterComponent {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    @ViewChild('ai-prompter') AiPrompterComp: AiPrompterManagerComponent;
    SideNaveActionsTypes = SideNaveActionsTypes;
    constructor(protected router: Router, protected auth: AuthService, protected cdr: ChangeDetectorRef) {}
    onCreateMaterial(resource: Resource) {}
    onEditMaterial(resource: Resource) {
        this.auth
            .getNK3Platform()
            .pipe(first())
            .subscribe((platform) => {
                this.onClose.emit({ clearCompData: true });
                this.router.navigate(
                    [
                        platform === 'guest' || platform === 'user'
                            ? '/home/manage/material'
                            : '/admin/content/materials',
                        resource.id,
                    ],
                    {
                        queryParams: {
                            bypassGuardWithoutSaving: true,
                        },
                    }
                );
            });
    }
}
