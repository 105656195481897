import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { HttpErrorService, PaginatorResponse, RequestBuilderService, ResponseData } from '@klickdata/core/http';
import { Filter, TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { User, UserData, UserRoleService, UserService } from '@klickdata/core/user';
import { WidgetsService } from '@klickdata/core/widget/src/widget.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UserListingService extends UserService implements TableHttpService<User> {
    constructor(
        protected configService: ConfigService,
        protected builder: RequestBuilderService,
        protected auth: AuthService,
        protected error: HttpErrorService,
        protected roleService: UserRoleService,
        protected widgetsService: WidgetsService
    ) {
        super(configService, builder, auth, error, roleService);
    }
    public fetchData(source: TableSource<User>): Observable<ResponseData<User[]>> {
        const req = this.builder.get<UserData[]>(this.usersLogUrl);
        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);
        this.widgetsService.setActiveWidgetsFilters(
            source.filter?.filter?.filters?.length
                ? source.filter.filter.filters.map((filter) => new Filter(filter.property, filter.items))
                : []
        );
        return req.request().pipe(
            map((res: PaginatorResponse<UserData[]>) => {
                return {
                    data: res.data.map((user) => this.mapUser(user)),
                    notify: res.notify,
                    more: res.more,
                    paginator: res.paginator,
                };
            })
        );
    }
}
