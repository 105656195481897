<div class="multi-img-wrapper">
    <div *ngFor="let mediaId of mediaIds; let index = index" class="img-view-wrapper">
        <app-image-view  [media_id]="mediaId">
            <button
                class="remove-image"
                mat-icon-button
                color="warn"
                matTooltip="Remove image"
                *ngIf="viewMode === 'normal'"
                i18n-matTooltip
                (click)="removeImg(mediaIds.indexOf(mediaId))"
            >
                <mat-icon class="md-24">delete_outline</mat-icon>
            </button>
        </app-image-view>
    </div>
</div>
<div class="uploader" *ngIf="enableUpload && maxImgCount > mediaIds?.length">
    <ng-container *ngIf="!(saving$ | async); else loading">
        <div
            *ngIf="enableDrop"
            fxFlex="0 0 50%"
            ng2FileDrop
            (fileOver)="fileOverBase($event)"
            [uploader]="uploader"
            class="media-dropzone"
            fxLayout="row wrap"
            fxLayoutAlign="center center"
        >
            <p *ngIf="!simple" fxFlex="0 0 60%">
                {{ dropLabel }}
            </p>
            <button
                *ngIf="!simple"
                type="button"
                class="media-upload-button"
                fxFlex="0 0 60%"
                mat-raised-button
                (click)="uploadInput.click()"
                color="primary"
                i18n
            >
                Upload file
            </button>
            <button
                *ngIf="simple"
                matSuffix
                mat-icon-button
                type="button"
                (click)="uploadInput.click()"
                matTooltip="Upload image"
                i18n-matTooltip
            >
                <mat-icon>add_photo_alternate</mat-icon>
            </button>
        </div>
        <input
            #uploadInput
            class="media-upload"
            type="file"
            [accept]="allowedMimeType?.toString()"
            ng2FileSelect
            [uploader]="uploader"
            [multiple]="multiple"
        />
    </ng-container>
</div>

<ng-template #loading>
    <app-kd-logo-loading *ngIf="!simple"></app-kd-logo-loading>
    <app-kd-logo-loading *ngIf="simple" size="small"></app-kd-logo-loading>
</ng-template>
