import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-menu-side-generate-ai',
    templateUrl: './menu-side-generate-ai.component.html',
    styleUrls: ['./menu-side-generate-ai.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideGenerateAIComponent implements OnChanges, OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public form: FormGroup;
    public saving: Subject<boolean> = new Subject<boolean>();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public promptOptionControl = new FormControl(false);

    constructor(protected fb: FormBuilder, protected cd: ChangeDetectorRef) {
        this.form = this.fb.group({
            value: ['', Validators.required],
        });
    }
    ngOnInit(): void {
        if (this.navData?.data?.value) {
            this.updateForm();
        }
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.navData?.data?.value) {
            this.updateForm();
        }
    }
    private updateForm() {
        this.form.patchValue({ value: this.navData.data.value });
        this.cd.markForCheck();
    }
    public submit() {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                value: this.form.get('value').value,
            },
        });
        this.clear();
    }
    public clear() {
        this.form.patchValue({ value: '' });
        this.cd.markForCheck();
    }
}
