<div fxFlex="grow" fxLayout="column">
    <app-table-filter-expansion-panel class="task-expansion" #filterExpansion [hasTopBorder]="false">
        <app-table-filter
            [filtersTogglesInOnBtn]="filtersTogglesInOnBtn"
            [defaultFilter]="GlobalFilterProperty.MY_TASKS"
            [cacheScope]="cacheScope"
            [inGlobalFilter]="true"
            color="accent"
            #filter
        >
            <app-table-search
                toggleBtnLabel="Task"
                i18n-toggleBtnLabel
                [inGlobalFilter]="true"
                class="align-space"
                searchFilterRef
            ></app-table-search>
            <app-table-date-filter
                [filterSpecs]="deadlineFilterSpecs"
                [inGlobalFilter]="true"
                color="accent"
                deadlineFilterRef
            ></app-table-date-filter>
            <app-user-chip-search-filter
                [inGlobalFilter]="true"
                [filterSpecs]="assigneeFilterSpecs"
                [additionalSearchFilters]="[{ property: 'userRole', items: ['superadmin'] }]"
                [isMaster]="true"
                i18n-searchLabel
                searchLabel="Assignee"
                i18n-chosingLabel
                chosingLabel="Choose assignee"
                i18n-searchPlaceholder
                searchPlaceholder="Filter by assignees"
                assigneesFilterRef
            ></app-user-chip-search-filter>
            <app-user-chip-search-filter
                [inGlobalFilter]="true"
                [additionalSearchFilters]="[{ property: 'userRole', items: ['superadmin'] }]"
                [isMaster]="true"
                [filterSpecs]="managerFilterSpecs"
                i18n-searchLabel
                searchLabel="Owner"
                i18n-chosingLabel
                chosingLabel="Choose owner"
                i18n-searchPlaceholder
                searchPlaceholder="Filter by owners"
                managersFilterRef
            ></app-user-chip-search-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Tasks Access"
                i18n-label
                [property]="GlobalFilterProperty.MY_TASKS"
                [options]="myTasksOptions"
                myTasksFilterRef
            ></app-select-filter>
            <app-tag-chip-search-filter
                [inGlobalFilter]="true"
                fxFlex="50%"
                fxFlex.lt-md="100%"
                fxLayoutAlign="start"
                tagsFilterRef
            ></app-tag-chip-search-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Action"
                i18n-label="@@action"
                [property]="GlobalFilterProperty.TASK_ACTION"
                [options]="taskActionsOptions"
                taskActionRef
            ></app-select-filter>
            <!-- <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Type"
                i18n-label="@@type"
                [property]="GlobalFilterProperty.TASK_TYPE"
                [options]="taskTypesOptions"
                taskTypeRef
            ></app-select-filter> -->
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Task Completion"
                i18n-label="@@taskCompletion"
                [property]="GlobalFilterProperty.TASK_DONE"
                [options]="taskDoneOptions"
                taskDoneRef
            ></app-select-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Prio"
                i18n-label="@@prio"
                [property]="GlobalFilterProperty.TASK_PRIO"
                [options]="taskPrioOptions"
                taskPrioRef
            ></app-select-filter>
        </app-table-filter>
    </app-table-filter-expansion-panel>
    <mat-progress-bar
        class="progress-table-loader mt-1"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>

    <mat-table
        #table
        class="mt-1 todos-table"
        [dataSource]="dataSource"
        matSortActive="priority"
        matSortDirection="asc"
        matSort
        multiTemplateDataRows
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox (click)="$event.stopPropagation()" (change)="isAllSelected = $event?.checked">
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <mat-checkbox
                    *ngIf="!task.loading"
                    (click)="$event.stopPropagation()"
                    (change)="task.loading = true; changeTaskDone(task, $event?.checked)"
                    [checked]="task.done"
                >
                </mat-checkbox>
                <ng-container *ngIf="task.loading">
                    <app-kd-logo-loading size="small"></app-kd-logo-loading>
                </ng-container>
                <div *ngIf="isAllSelected" fxLayoutAlign="space-around center" class="multi-select-tasks">
                    <!-- <button mat-button (click)="markSelectedAsDone()">
                        <mat-icon>checked</mat-icon>
                        <span i18n>Mark as done</span>
                    </button> -->
                    <button mat-button (click)="delete()">
                        <mat-icon>delete</mat-icon>
                        <span i18n>Delete</span>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deadline">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span *ngIf="clientUserModel" i18n>Contact Date</span>
                    <span *ngIf="!clientUserModel" i18n>Deadline</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <span class="mobile-label" *ngIf="clientUserModel" i18n>Contact Date:</span>
                <span class="mobile-label" *ngIf="!clientUserModel" i18n>Deadline:</span>
                <div class="contact-date-wrap">
                    <app-date-time-picker-item
                        (click)="$event.stopPropagation()"
                        *ngIf="!task.loading"
                        [value]="task.deadline"
                        (onDateTimeChanged)="updateDeadline($event, task)"
                    ></app-date-time-picker-item>
                    <!-- <mat-form-field *ngIf="!task.loading">
                        <input
                            matInput
                            (focus)="$event.stopPropagation(); contactDate.open()"
                            (blur)="$event.stopPropagation(); contactDate.open()"
                            [value]="task.deadline | kdDateFormat : 'YYYY-MM-DD'"
                            (dateChange)="task.loading = true; updateDeadline($event, task)"
                            [matDatepicker]="contactDate"
                        />
                        <mat-datepicker-toggle matIconSuffix [for]="contactDate">
                            <mat-icon matDatepickerToggleIcon>edit_calendar</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #contactDate disabled="false"></mat-datepicker>
                    </mat-form-field> -->
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assignee">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Assignee</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <span class="mobile-label" i18n>Assignee:</span>
                <ng-container *ngIf="!!task.assignees?.length">
                    <span
                        [matTooltip]="assignee?.fname + ' ' + assignee?.lname"
                        class="pointer task-name"
                        *ngFor="let assignee of task.assignees; let isLast = last"
                        (click)="$event.stopPropagation(); onSelectAssignee(assignee)"
                        ><span>{{ assignee?.fname | nameSign }}{{ assignee?.lname | nameSign }}</span>
                        <span *ngIf="!isLast">,</span></span
                    >
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="body">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Task</span>
                </div>
            </mat-header-cell>
            <mat-cell [ngClass]="{ 'cell-is-expanded': expandedRow === task }" #taskCellRef *matCellDef="let task">
                <span *ngIf="isMobile && clientUserModel" class="mobile-label" i18n>Task:</span>
                <span *ngIf="!isMobile" class="task">{{ task.body }}</span>
                <div
                    *ngIf="isMobile && !clientUserModel"
                    class="mobile-top-row"
                    [ngClass]="{ expandedExtra: expandedExtra === task }"
                    fxFlex="grow"
                    fxLayoutAlign="center start"
                >
                    <mat-checkbox
                        class="mobile-task-check"
                        *ngIf="!task.loading"
                        (click)="$event.stopPropagation()"
                        (change)="task.loading = true; changeTaskDone(task, $event?.checked)"
                        [checked]="task.done"
                    >
                    </mat-checkbox>
                    <ng-container *ngIf="task.loading">
                        <app-kd-logo-loading size="small"></app-kd-logo-loading>
                    </ng-container>
                    <span fxFlex="grow" (click)="$event.stopPropagation()">{{ task.body }}</span>
                    <app-item-change-on-the-fly
                        [capitalize]="true"
                        [selectionOptions]="taskPrioOptions"
                        [value]="task.priority"
                        (onValueChange)="task.loading = true; updateTaskPrio(task, $event)"
                    ></app-item-change-on-the-fly>
                    <button
                        class="expand-extra-btn"
                        mat-icon-button
                        (click)="
                            $event.stopPropagation();
                            expandedRow = null;
                            expandedExtra = expandedExtra === task ? null : task
                        "
                    >
                        <mat-icon>
                            {{ expandedExtra === task ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Name</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <span *ngIf="isMobile && !clientUserModel" class="mobile-label" i18n>Name:</span>
                <span
                    *ngIf="!isMobile"
                    [matTooltipDisabled]="task.user_contact"
                    [matTooltip]="(task.client_contact?.type | clientStatus)?.label"
                    [ngStyle]="{
                        color: !task.user_contact && (task.client_contact?.type | clientStatus)?.fg,
                        background: !task.user_contact && (task.client_contact?.type | clientStatus)?.bg,
                        border: !task.user_contact && (task.client_contact?.type | clientStatus)?.border
                    }"
                    (click)="onModelNameClick(task)"
                    class="task name"
                    >{{ task.user_contact?.name || task.client_contact?.name }}</span
                >
                <div
                    *ngIf="isMobile && clientUserModel"
                    class="mobile-top-row clientUserModel"
                    [ngClass]="{ expandedClientExtra: expandedClientExtra === task }"
                    fxFlex="grow"
                    fxLayoutAlign="center start"
                    fxLayout="column"
                >
                    <div class="w-100" fxFlex="grow" fxLayoutAlign="center start">
                        <span
                            [ngStyle]="{
                                color: !task.user_contact && (task.client_contact?.type | clientStatus)?.fg,
                                background: !task.user_contact && (task.client_contact?.type | clientStatus)?.bg
                            }"
                            class="name"
                            [ngClass]="{ client: !task.user_contact, connection: task.user_contact }"
                            fxFlex="grow"
                            (click)="$event.stopPropagation(); onModelNameClick(task)"
                            >{{ task.client_contact?.name || task.user_contact?.name }}</span
                        >
                        <app-item-change-on-the-fly
                            [capitalize]="true"
                            [selectionOptions]="taskPrioOptions"
                            [value]="task.priority"
                            (onValueChange)="task.loading = true; updateTaskPrio(task, $event)"
                        ></app-item-change-on-the-fly>
                        <button
                            class="expand-extra-btn"
                            mat-icon-button
                            (click)="
                                $event.stopPropagation();
                                expandedRow = null;
                                expandedClientExtra = expandedClientExtra === task ? null : task
                            "
                        >
                            <mat-icon>
                                {{ expandedClientExtra === task ? 'expand_less' : 'expand_more' }}
                            </mat-icon>
                        </button>
                    </div>
                    <div fxFlex="grow" fxLayoutAlign="center start" class="w-100">
                        <app-table-tags-on-the-fly
                            (click)="$event.stopPropagation()"
                            [tags]="task.tags"
                            (onUpdateTags)="task?.id && updateTaskTags(task, $event)"
                            (onTagClick)="onTagClick($event)"
                        ></app-table-tags-on-the-fly>
                    </div>
                    <div class="w-100 extra-content" fxFlex="grow" fxLayoutAlign="space-between center">
                        <ng-container *ngIf="allManagersList$ | async; let allManagers">
                            <app-signature-change-on-the-fly
                                (click)="$event.stopPropagation()"
                                (onSelectSignature)="onSelectSignature($event, task)"
                                [manager]="!!task?.managers?.length && task?.managers[0]"
                                [allManagersList]="allManagers"
                            ></app-signature-change-on-the-fly>
                        </ng-container>
                        <app-date-time-picker-item
                            (click)="$event.stopPropagation()"
                            *ngIf="!task.loading"
                            [value]="task.deadline"
                            (onDateTimeChanged)="updateDeadline($event, task)"
                        ></app-date-time-picker-item>
                        <button
                            *ngIf="clientUserModel && task.client_contact && !task.user_contact"
                            (click)="$event.stopPropagation(); onCustomerClick(task.client_contact.id)"
                            matTooltip="Edit Client"
                            mat-icon-button
                            i18n-matTooltip="@@editClient"
                            class="expand-btn"
                        >
                            <mat-icon>account_balance</mat-icon>
                        </button>
                        <button
                            *ngIf="clientUserModel && task.user_contact"
                            (click)="$event.stopPropagation(); editUser(task.user_contact.id)"
                            matTooltip="Edit Contact"
                            mat-icon-button
                            i18n-matTooltip="@@editContact"
                            class="expand-btn"
                        >
                            <mat-icon>person</mat-icon>
                        </button>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="signature">
            <mat-header-cell *matHeaderCellDef i18n> Sign </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <span *ngIf="isMobile" class="mobile-label" i18n> Sign </span>
                <ng-container *ngIf="allManagersList$ | async; let allManagers">
                    <app-signature-change-on-the-fly
                        (click)="$event.stopPropagation()"
                        (onSelectSignature)="onSelectSignature($event, task)"
                        [manager]="!!task?.managers?.length && task?.managers[0]"
                        [allManagersList]="allManagers"
                    ></app-signature-change-on-the-fly>
                </ng-container>
                <!-- <span *ngIf="!!user.task?.managers?.length" class="sign-name" [matTooltip]="user.task.managers[0]">
                    {{ user.task.managers[0].fname | nameSign }}{{ user.task.managers[0].lname | nameSign }}
                </span> -->
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Tags</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <!-- <div class="shadow-maker"></div> -->
                <!-- <div *ngIf="!!task.tags?.length" class="tags-wrapper">
                    <div (click)="$event.stopPropagation(); onTagClick(tag)" *ngFor="let tag of task.tags">
                        <span>{{ tag.name }}</span>
                    </div>
                </div> -->
                <app-table-tags-on-the-fly
                    (click)="$event.stopPropagation()"
                    [tags]="task.tags"
                    (onUpdateTags)="task?.id && updateTaskTags(task, $event)"
                    (onTagClick)="onTagClick($event)"
                ></app-table-tags-on-the-fly>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="customer">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Client</span>
                </div>
            </mat-header-cell>
            <mat-cell [ngClass]="{ 'cell-is-expanded': expandedRow === task }" #customerCellRef *matCellDef="let task">
                <span class="mobile-label" i18n>Customer:</span>
                <div class="shadow-maker"></div>
                <div *ngIf="!!task.customers?.length" class="tags-wrapper customer-item">
                    <div
                        (click)="$event.stopPropagation(); onCustomerClick(customer.id)"
                        *ngFor="let customer of task.customers"
                        [ngStyle]="{
                            color: (customer.type | clientStatus)?.fg,
                            background: (customer.type | clientStatus)?.bg
                        }"
                    >
                        <span [matTooltip]="(customer.type | clientStatus)?.label">{{ customer.name }}</span>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="priority">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Prio</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task"
                ><span class="mobile-label" i18n>Priority:</span>
                <app-item-change-on-the-fly
                    [capitalize]="true"
                    [selectionOptions]="taskPrioOptions"
                    [value]="task.priority"
                    (onValueChange)="task.loading = true; updateTaskPrio(task, $event)"
                ></app-item-change-on-the-fly>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Action</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <span class="mobile-label" i18n>Action:</span>
                <span class="task orange capitaliza">{{ task.action }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Type</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let task">
                <span class="mobile-label" i18n>Type:</span>

                <mat-icon
                    [matTooltip]="(task.type | taskType)?.label"
                    [ngStyle]="{ color: (task.type | taskType)?.color }"
                    class="task"
                    >{{ (task.type | taskType).icon }}</mat-icon
                >
                <span class="task-mobile-type">{{ (task.type | taskType)?.label }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef>Tools</mat-header-cell>
            <mat-cell class="btns-actions" *matCellDef="let task">
                <button
                    *ngIf="!clientUserModel"
                    (click)="$event.stopPropagation(); UpdateTask(task)"
                    matTooltip="Edit task"
                    mat-icon-button
                    i18n-matTooltip="@@editTask"
                    class="expand-btn"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    *ngIf="clientUserModel && task.client_contact && !task.user_contact"
                    (click)="$event.stopPropagation(); onCustomerClick(task.client_contact.id)"
                    matTooltip="Edit Client"
                    mat-icon-button
                    i18n-matTooltip="@@editClient"
                    class="expand-btn"
                >
                    <mat-icon>account_balance</mat-icon>
                </button>
                <button
                    *ngIf="clientUserModel && task.user_contact"
                    (click)="$event.stopPropagation(); editUser(task.user_contact.id)"
                    matTooltip="Edit Contact"
                    mat-icon-button
                    i18n-matTooltip="@@editContact"
                    class="expand-btn"
                >
                    <mat-icon>person</mat-icon>
                </button>

                <button
                    *ngIf="!clientUserModel"
                    (click)="$event.stopPropagation()"
                    [matTooltip]="getInfo(task)"
                    matTooltipClass="task-info-tip"
                    mat-icon-button
                    class="expand-btn"
                >
                    <mat-icon class="material-icons-outlined">info</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); addChildTask(task)"
                    matTooltip="Add task"
                    mat-icon-button
                    i18n-matTooltip="@@addTask"
                    class="expand-btn add-task"
                >
                    <mat-icon>add_circle</mat-icon>
                </button>
                <button
                    *ngIf="!task.loading"
                    (click)="$event.stopPropagation(); expandedRow = expandedRow === task ? null : task"
                    matTooltip="View details"
                    mat-icon-button
                    i18n-matTooltip="@@viewDetails"
                    [ngClass]="{ 'has-child': task.children_count > 0 }"
                    class="expand-btn"
                >
                    <mat-icon>{{ expandedRow === task ? 'expand_less' : 'expand_more' }}</mat-icon>
                </button>

                <ng-container *ngIf="task.loading">
                    <app-kd-logo-loading size="small"></app-kd-logo-loading>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let task" [attr.colspan]="columns.length">
                <div class="example-element-detail" [@detailExpand]="task == expandedRow ? 'expanded' : 'collapsed'">
                    <ng-container *ngIf="expandedRow == task">
                        <div class="expanded-item-wrapper">
                            <app-task-listing-child
                                #childTasksList
                                class="child-table"
                                *ngIf="task.children_count > 0"
                                [parentId]="task.id"
                                [authUser]="authUser"
                            ></app-task-listing-child>
                            <div class="divider"></div>
                            <app-task-notes-list
                                class="customer-hr-notes-parent"
                                [taskId]="task.id"
                            ></app-task-notes-list>
                            <!-- <app-shared-hr-notes
                                *ngIf="!!task.assignees?.length"
                                [user]="task.assignees[0]"
                                class="customer-hr-notes-parent"
                            ></app-shared-hr-notes> -->
                        </div>
                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let task; columns: columns"
            [class.example-expanded-row]="expandedRow === task"
            [ngClass]="{ 'expanded-extra': expandedExtra === task }"
            [class.loading-row]="task.loading"
            class="example-element-row"
            (click)="expandedRow = expandedRow === task ? null : task"
        ></mat-row>
        <mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            [ngClass.lt-md]="{ 'expanded-row-task': row == expandedRow }"
            class="example-detail-row"
        ></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"> </mat-paginator>
</div>
