import { takeUntil } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { RecordingOutput, RecorderTypes } from '@klickdata/core/media/src/media-type';
import { Observable, Subject } from 'rxjs';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { NumericalBotVersion } from 'aws-sdk/clients/lexmodelsv2';

export interface RecorderViewerData {
    recordedOutput: RecordingOutput;
    type: RecorderTypes;
}
@Component({
    selector: 'app-recorder-view',
    templateUrl: './recorder-view.component.html',
    styleUrls: ['./recorder-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecorderViewComponent implements OnInit, OnDestroy {
    public recordType: RecorderTypes;
    RecorderTypes = RecorderTypes;
    public recordedOutput: RecordingOutput;
    private destroy = new Subject<boolean>();
    public limitedRecordingDurationInMin: number;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: RecorderViewerData,
        public dialogRef: MatDialogRef<RecorderViewComponent>,
        private sanitizer: DomSanitizer,
        protected cdr: ChangeDetectorRef,
        protected configService: ConfigService
    ) {
        this.limitedRecordingDurationInMin = this.configService.config.limitedRecordingDuration / 60000;
    }

    ngOnInit(): void {}
    public getSantizeUrl(url: string | Blob) {
        if (typeof url === 'string') {
            return this.sanitizer.bypassSecurityTrustUrl(url);
        } else {
            return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(url));
        }
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
