<div [formGroup]="materialForm" class="data-wrap create-link-mat" fxLayout="column">
    <div class="selection-types mt-1-5">
        <ng-container *ngFor="let selected of selectionTypes">
            <button
                *ngIf="getSelectedInfo(selected); let selectedItem"
                mat-icon-button
                [matTooltip]="selectedItem.tooltip"
                (click)="changeContext(selected)"
            >
                <mat-icon [ngStyle]="{ color: selectedItem.color }">{{ selectedItem.icon }}</mat-icon>
            </button>
        </ng-container>

        <app-ai-model-selector fxHide (modelChange)="changeModel($event)"></app-ai-model-selector>
    </div>
    <ng-container [ngSwitch]="materialForm.value.type_id">
        <ng-container *ngSwitchCase="'url'">
            <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>add_link</mat-icon>
                <div i18n>New link material</div>
            </div>
            <div class="desc" i18n>Paste a link you want to save as Material.</div>
            <div fxLayoutGap="5px" fxLayoutAlign="inherit baseline">
                <mat-form-field fxFlex="0 1 100%">
                    <textarea
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="15"
                        #input
                        matInput
                        type="url"
                        placeholder="Link"
                        i18n-placeholder
                        formControlName="link"
                    >
                    </textarea>
                    <!-- <input matInput placeholder="Link" type="url" i18n-placeholder formControlName="link" /> -->
                    <button
                        matTooltip="Open link in new tab"
                        i18n-matTooltip
                        type="button"
                        [disabled]="!Utils.isUrl(materialForm.value.link)"
                        class="open-link"
                        mat-icon-button
                        matSuffix
                        (click)="goToLink(materialForm.value.link)"
                    >
                        <mat-icon>open_in_new</mat-icon>
                    </button>
                    <mat-hint
                        fxLayout="row"
                        fxLayoutAlign="start center"
                        fxLayoutGap="5px"
                        *ngIf="videoDuration"
                        align="start"
                    >
                        <strong i18n>Duration:</strong>
                        <span>{{ videoDuration | formatIsoDuration }}</span>
                    </mat-hint>
                </mat-form-field>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'ai'">
            <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon class="ai-color">smart_toy</mat-icon>
                <div class="ai-color" i18n>New material from AI</div>
            </div>
            <div class="desc" i18n>Use Artificial Intelligence (AI) to create your material.</div>
            <div fxLayoutGap="5px" fxLayoutAlign="inherit baseline">
                <mat-form-field fxFlex="0 1 100%">
                    <textarea
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="15"
                        #input
                        matInput
                        placeholder="Write a prompt text to ask AI anything"
                        i18n-placeholder
                        type="text"
                        formControlName="prompt"
                    >
                    </textarea>
                </mat-form-field>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
            <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>post_add</mat-icon>
                <div i18n>New text material</div>
            </div>
            <div class="desc" i18n>Add the text you want to save as Material.</div>
            <div fxLayoutGap="5px" fxLayoutAlign="inherit baseline">
                <app-text-editor
                    class="text-mat-editor"
                    fxFlex="1 1 100%"
                    title="Material Text"
                    i18n-title
                    [showTemplates]="false"
                    formControlName="text"
                ></app-text-editor>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="'doc'">
            <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon>cloud_upload</mat-icon>
                <div i18n>New file material</div>
            </div>
            <div class="desc" i18n>Upload the file you want to save as Material.</div>
            <div fxFlex="0 1 100%" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="!materialForm.value.file">
                <div class="media-dropzone" fxLayout="row wrap" fxLayoutAlign="center center">
                    <input
                        class="media-upload-button"
                        type="file"
                        (change)="prepareFile($event)"
                        [disabled]="onSavingFile | async"
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                        text/plain, application/pdf,.xlsx,.xls, image/*, ,.heic,audio/*, video/*, application/vnd.apple.keynote"
                    />
                </div>
                <ng-container *ngIf="!materialForm.value.file">
                    <div class="progress-wrapper" *ngIf="progress$ | async; let progress">
                        <mat-progress-bar class="progress" mode="determinate" [value]="progress"></mat-progress-bar>
                        <span class="progress-value">{{ progress }}%</span>
                    </div>
                </ng-container>
            </div>
            <app-media-view
                *ngIf="materialForm.value.file"
                fxLayoutAlign="start center"
                fxFlex="0 0 90%"
                fxFlex.lt-md="0 0 100%"
                [media_id]="materialForm.value.file"
            >
                <button
                    class="remove-file"
                    color="warn"
                    type="button"
                    fxFlex="0 1 auto"
                    mat-icon-button
                    matTooltip="Remove file"
                    i18n-matTooltip
                    (click)="
                        materialForm.patchValue({ file: '' });
                        materialForm.get('file').markAsDirty();
                        vTQ.material_name = null;
                        vTQ.material_type = null
                    "
                >
                    <mat-icon class="md-24">delete_outline</mat-icon>
                </button>
            </app-media-view>
        </ng-container>
    </ng-container>
    <div *ngIf="materialForm.value.type_id != 'ai'" fxLayoutGap="5px" fxLayoutAlign="inherit baseline">
        <mat-form-field fxFlex="0 1 100%">
            <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="15"
                #input
                matInput
                type="text"
                placeholder="Ask AI"
                i18n-placeholder
                formControlName="prompt"
            >
            </textarea>

            <button
                *ngIf="!!authUser.prompts_translation"
                [disabled]="!materialForm.value.link && !materialForm.value.file && !materialForm.value.text"
                matTooltip="Choose predefined prompts"
                i18n-matTooltip
                type="button"
                class="open-link"
                mat-icon-button
                matSuffix
                [matMenuTriggerFor]="predefinedFollowup"
            >
                <mat-icon>expand_less</mat-icon>
            </button>
        </mat-form-field>
    </div>
    <ng-container *ngIf="langId">
        <div *ngIf="showTags" fxFlex="grow">
            <app-tag-chip-select
                placeholder="Add Tag"
                i18n-placeholder
                [language_id]="langId"
                formControlName="tag_ids"
                [sortedAlph]="true"
            ></app-tag-chip-select>
        </div>
    </ng-container>

    <div fxLayout="column" *ngIf="showQuiz" fxFlex="grow">
        <app-vtq-sliders
            [hasCheckBox]="true"
            (onCheckBoxChange)="$event && (showQuiz = false)"
            (valuesChange)="
                vTQ.question_count = $event.question_count;
                vTQ.difficulty_level = $event.difficulty_level;
                vTQ.create_course = $event.create_course;
                vTQ.question_type_value = $event.question_type_value
            "
        ></app-vtq-sliders>
    </div>

    <div
        *ngIf="nk3Platform$ | async as platform"
        class="action-link-btns"
        fxLayout="row-reverse"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
    >
        <button
            *ngIf="!loading.value"
            fxFlex="0 0 auto"
            class="mobile-button positive"
            [disabled]="!canCreate"
            (click)="submit(platform)"
            mat-raised-button
        >
            <mat-icon>add_circle</mat-icon>
            <span i18n>Create</span>
        </button>
        <app-kd-logo-loading *ngIf="loading.value" size="small"></app-kd-logo-loading>

        <!-- <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button>
            <mat-icon>close</mat-icon>
            <span i18n>Cancel</span>
        </button> -->
        <button
            *ngIf="!loading.value"
            matTooltip="Add tags"
            i18n-matTooltip
            class="tag-btn"
            [ngClass]="{
                'tags-displayed': showTags,
                'tags-hidden': !showTags,
                'has-tags': materialForm.value.tag_ids?.length > 0
            }"
            mat-icon-button
            (click)="showTags = !showTags"
        >
            <mat-icon>tag</mat-icon>
        </button>
        <button
            matTooltip="Add quiz from valid video link"
            i18n-matTooltip
            class="tag-btn"
            [ngClass]="{
                'disable-quiz': !canCreate,
                'quiz-displayed': showQuiz,
                'quiz-hidden': !showQuiz
            }"
            mat-icon-button
            (click)="showQuiz = !showQuiz"
        >
            <mat-icon
                fxFlex="0 0 auto"
                fontSet="klickdata"
                fontIcon="kd-icon-test"
                class="course-plan-icon side-nave-icon no-shadow"
            ></mat-icon>
        </button>

        <button
            mat-icon-button
            fxFlex="0 0 auto"
            class="mobile-button custom"
            (click)="listMaterial(platform)"
            mat-button
            matTooltip="Go to list"
            i18n-matTooltip
        >
            <mat-icon class="m-0">format_list_bulleted</mat-icon>
            <!-- <span i18n>List</span> -->
        </button>
    </div>
</div>
<mat-menu #predefinedFollowup="matMenu">
    <button
        *ngFor="let prompt of userPromptsList"
        (click)="materialForm.get('prompt').patchValue(prompt)"
        mat-menu-item
        class="toolbar-button"
    >
        <span>{{ prompt }}</span>
    </button>
</mat-menu>
