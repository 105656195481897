import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChildren,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDrawerMode } from '@angular/material/sidenav';
import { ApplicationService } from '@klickdata/core/application';
import {
    MobileService,
    SideNaveActionsTypes,
    SideNaveData,
    SideNaveDataTypes,
    SideNaveResponseData,
} from '@klickdata/core/mobile/src/mobile.service';
import { EMPTY, Observable, Subject, of } from 'rxjs';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MenuSideMobileBottomSheetComponent } from '../menu-side-mobile-bottom-sheet/menu-side-mobile-bottom-sheet.component';
import { User } from '@klickdata/core/user';
import { AuthService } from '@klickdata/core/auth';
export enum Direction {
    Up = 'Up',
    Down = 'Down',
}
@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
    public isMobile: Observable<boolean>;
    public showingMenu: Observable<boolean>;
    public sideNavData$: Observable<SideNaveData>;
    public version$: Observable<{ version: string; date: string }>;
    @ContentChild(TemplateRef)
    public template: TemplateRef<void>;
    public position: 'start' | 'end' = 'end';
    public mode: MatDrawerMode = 'over';
    public isSideNavDataMenu$: Subject<boolean> = new Subject();
    SideNaveDataTypes = SideNaveDataTypes;
    SideNaveActionsTypes = SideNaveActionsTypes;
    @ViewChildren('menuSideComp') menuSideComps: QueryList<any>;
    private isMob: boolean;
    public userIsAU$: Observable<boolean>;

    constructor(
        protected mobile: MobileService,
        protected changeRef: ChangeDetectorRef,
        protected appService: ApplicationService,
        protected cdr: ChangeDetectorRef,
        protected bottomSheet: MatBottomSheet,
        protected auth: AuthService
    ) {
        this.version$ = appService.getVersion();
    }

    public ngOnInit() {
        this.userIsAU$ = this.auth.getUser().pipe(
            first(),
            map((user) => !user.isAdmin())
        );
        this.isMobile = this.mobile.isMobile().pipe(
            tap((ismob) => (this.isMob = ismob)),
            map((res) => {
                this.update();
                return res;
            })
        );
        this.showingMenu = this.mobile.showingMenu().pipe(
            map((res) => {
                this.update();
                return res;
            })
        );
        this.sideNavData$ = this.mobile.getSideNavSub().pipe(
            switchMap((navData) => {
                return this.mobile
                    .isMobile()
                    .pipe(
                        switchMap((isMob) =>
                            isMob && navData.dataType !== SideNaveDataTypes.MOBILE_MENU
                                ? this.showBottomSheet(navData)
                                : of(navData)
                        )
                    );
            }),
            map((res) => {
                this.isSideNavDataMenu$.next(res.dataType !== SideNaveDataTypes.MOBILE_MENU);
                this.update();
                return res;
            })
        );
    }
    public showBottomSheet(navData: SideNaveData) {
        this.bottomSheet
            .open(MenuSideMobileBottomSheetComponent, {
                data: navData,
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .subscribe((results) => {
                this.onClose(results);
            });

        return EMPTY;
    }
    public onClose(responsData?: SideNaveResponseData): void {
        this.mobile.showMenu(false);
        this.update();
        if (!responsData) {
            this.clearDrawer();
        }
        if (responsData?.action) {
            this.mobile.updateSideNavAction(responsData.action);
        }
        if (responsData?.data) {
            this.mobile.setSideNavResponseData(responsData.data);
        }
    }
    public clearDrawer() {
        setTimeout(() => {
            this.mobile.showMenu(false);
            this.mobile.updateSideNavAction(SideNaveActionsTypes.PANEL_CLOSED);
            this.mobile.setSideNavResponseData({ data: null });
            this.mobile.updateSideNavSub(
                this.isMob
                    ? { dataType: SideNaveDataTypes.MOBILE_MENU, ignoreDrawer: true }
                    : { dataType: SideNaveDataTypes.CLEARDRAWER, ignoreDrawer: true }
            );
        }, 500);
    }
    protected update() {
        this.changeRef.markForCheck();
    }
}
