import { ChangeDetectorRef, Directive, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItem } from '@klickdata/core/resource-item';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import { User } from '@klickdata/core/user';
import { Observable, Subject, filter, first, takeUntil } from 'rxjs';
import { ResourceEditDialogComponent } from '../../resource-edit-dialog/resource-edit-dialog.component';
import { FormHelper } from '@klickdata/core/form';

@Directive()
export abstract class ResourceItemDirective implements OnDestroy {
    @Input() selection: number[] = [];
    @Input()
    public item: ResourceItem;
    @Input()
    public set childGroup(childGroup: FormGroup) {
        this._childGroup = childGroup;

        if (childGroup.value.child_resource_id) {
            this.loading = true;
            this.resourceService
                .getResource(childGroup.value.child_resource_id)
                .pipe(takeUntil(this.destroy))
                .subscribe((resource) => {
                    this.selected = resource;
                    this.loading = false;
                    this.changeRef.markForCheck();
                });
        }
    }

    public get childGroup(): FormGroup {
        return this._childGroup;
    }
    protected _childGroup: FormGroup;

    protected destroy: Subject<boolean> = new Subject<boolean>();
    public loading: boolean;
    private _selected: Resource;
    public get selected(): Resource {
        return this._selected;
    }

    public set selected(value: Resource) {
        this._selected = value;
        this.afterResourceInit();
    }

    public user$: Observable<User>;
    private isMobile: boolean;
    ResourceTypes = ResourceTypes;
    AppScope = AppScope;
    ResourceBuildingItems = ResourceBuildingItems;
    changeMode = false;

    constructor(
        protected resourceService: ResourceService,
        protected changeRef: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected authService: AuthService,
        protected mobileService: MobileService
    ) {
        this.user$ = this.authService.getUser().pipe(first());
        this.mobileService
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMobile) => (this.isMobile = isMobile));
    }

    selectResource(resource: Resource) {
        this.selected = resource;
        const patch = {
            child_resource_id: resource.id,
            child_resource_published: resource.published,
            child_resource_language_id: resource.language_id,
        };
        // Inheritance of title and description when select test, If not defined by user.
        if (!this.childGroup.value.name) {
            patch['name'] = resource.title;
        }
        if (!this.childGroup.value.description) {
            patch['description'] = resource.description;
        }
        this.childGroup.patchValue(patch);
        this.afterResourceSelect();
        FormHelper.markForm(this.childGroup);
        this.changeRef.markForCheck();
    }

    protected afterResourceSelect() {}
    protected afterResourceInit() {}

    onEdit() {
        const dialogRef = this.dialog.open(ResourceEditDialogComponent, {
            width: !this.isMobile ? '80%' : '100%',
            data: { ...this.selected, scope_id: ResourceTypes.scopeByChildType(this.selected.type_id) },
            disableClose: true,
            panelClass: 'sheet-wrapper',
        });
        dialogRef
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                filter((res) => !!res)
            )
            .subscribe((result: Resource) => {
                this.selectResource(result);
            });
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
