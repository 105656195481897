import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@klickdata/core/auth';
import { RecorderTypes } from '@klickdata/core/media';
import { RecorderService, RecordResultsMetaData } from '@klickdata/core/media/src/recorder.service';
import { MobileService } from '@klickdata/core/mobile';
import { takeUntil } from 'rxjs/operators';
import { RecorderBase } from '../recorder-base';

@Component({
    selector: 'app-recorder-screen-with-video',
    templateUrl: './recorder-screen-with-video.component.html',
    styleUrls: ['./recorder-screen-with-video.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecorderScreenWithVideoComponent extends RecorderBase {
    public recorderConfig: any;
    public recordResultsMetaData: RecordResultsMetaData;
    public isSafari: boolean;
    public safariTip = $localize`This type is not available on Safari yet.`

    constructor(
        protected snackBar: MatSnackBar,
        public recordingService: RecorderService,
        protected cdr: ChangeDetectorRef,
        protected auth: AuthService,
        protected mobile: MobileService,
        protected platform: Platform
    ) {
        super(snackBar, recordingService, auth, mobile, platform);
        this.recorderConfig = {
            type: 'video',
            mimeType: 'video/webm',
            canvas: {
                width: 1280,
                height: 720,
            },
        };
        this.recordResultsMetaData = {
            title: $localize`KLMS_recorded_screen_with_video`,
            extension: 'webm',
            type: 'video/webm',
        };
        this.isSafari = platform.SAFARI;
    }

    public startRecorder(canRecordUnlimited: boolean) {
        if (this.recordingService.recorder) {
            return;
        }
        if (this.isMobile) {
            this.mobile.toggleMenu();
        }
        const microphone = 'microphone' as PermissionName;
        const camera = 'camera' as PermissionName;
        this.haveRequestedPermissions([{ name: microphone }, { name: camera }])
            .pipe(takeUntil(this.destroy))
            .subscribe((havePermissions: boolean) => {
                if (!havePermissions) {
                    return;
                } else {
                    this.recordingService.activeRecordingType = RecorderTypes.SCREEN_WITH_VIDEO;
                    return new Promise((resolve, reject) => {
                        var browser = <any>navigator;
                        browser.mediaDevices
                            .getDisplayMedia({
                                video: {
                                    displaySurface: 'monitor',
                                    logicalSurface: true,
                                    cursor: 'always',
                                },
                            })
                            .then((screenStream: MediaStream) => {
                                navigator.mediaDevices
                                    .getUserMedia({ audio: true, video: true })
                                    .then((cam: MediaStream) => {
                                        this.prepareStreamLive([screenStream, cam]);
                                        this.recordingService.record(
                                            [screenStream, cam],
                                            this.recorderConfig,
                                            this.recordResultsMetaData,
                                            !canRecordUnlimited
                                        );
                                        this.showSnackBar();
                                        this.cdr.markForCheck();
                                        resolve(this.stream);
                                    })
                                    .catch((error: any) => {
                                        this.initPermissionWarningSlider({
                                            body: $localize`Pls give KlickData needed camera and microphone permissions from your browser/system security settings to record and then reload the page`,
                                        });
                                    });
                            })
                            .catch((error: any) => {
                                this.initPermissionWarningSlider({
                                    body: $localize`Pls give KlickData needed permissions from your systm security settings to use screen recording`,
                                });
                            });
                    });
                }
            });
    }
    private prepareStreamLive(streams: any[]) {
        streams[0].width = window.screen.width;
        streams[0].height = window.screen.height;
        streams[0].fullcanvas = true;
        streams[1].width = 320;
        streams[1].height = 240;
        streams[1].top = streams[0].height - streams[1].height;
        streams[1].left = streams[0].width - streams[1].width;
    }
}
