<div class="dialog-wrapper" [dir]="directionality.value">
    <div
        matDialogTitle
        class="color-warn"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="5px"
        fxLayoutAlign.lt-md="start flex-start"
    >
        <mat-icon fxFlex="0 0 36px" class="md-36" [ngClass]="iconClass ? iconClass : ''" [color]="color">
            {{ icon }}
        </mat-icon>
        <div class="title-wrapper" fxFlex="0 1 auto">
            <span class="title">{{ title }}</span>
        </div>
    </div>

    <div matDialogContent>
        <div *ngIf="contentTitle" class="title">{{ contentTitle }}</div>
        <div>{{ contentBody }}</div>
    </div>

    <div class="flex" mat-dialog-actions>
        <div class="flex-space"></div>
        <div class="neutrals" *ngIf="neutralBtn">
            <button class="neutral" mat-button type="button" (click)="dialogRef.close(null)">
                {{ neutralBtn }}
            </button>
            <div class="space"></div>
        </div>
        <button *ngIf="!showOnlyNeutralBtn" mat-button type="button" color="primary" (click)="dialogRef.close(false)">
            {{ negativeBtn }}
            <span *ngIf="countDown">({{ countDown }})</span>
        </button>
        <div class="space"></div>
        <button *ngIf="!showOnlyNeutralBtn" mat-button type="button" color="accent" (click)="dialogRef.close(true)">
            {{ positiveBtn }}
        </button>
    </div>
</div>
