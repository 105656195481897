import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@klickdata/core/http';
import { MediaModule } from '@klickdata/core/media';
import { ConfigModule } from '@klickdata/core/config';

@NgModule({
    imports: [CommonModule, HttpModule, MediaModule, ConfigModule],
})
export class QuestionModule {}
