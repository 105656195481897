<!-- <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="1em" *ngIf="items$ | async; let items; else: loading">
    <ng-container *ngIf="parentOpp$ | async; let parentOpp; else: loading">
        <div
            class="w-100 wrap"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            *ngFor="let item of items; let index = index"
        >
            <div class="title" fxLayout="row" fxLayoutGap="5px">
                <span class="label">{{ index + 1 }}-</span>
                <span class="label">{{ item.title ?? 'Material' }}</span>
            </div>
            <div class="type" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <span class="label">Type:</span>
                <span>{{ getItemType(item.item_type_value) }}</span>
            </div>
            <div class="info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <span class="label">Started:</span>
                <span>{{
                    (item.child_opportunity_started_at ? item.child_opportunity_started_at : parentOpp.started_at)
                        | kdDateFormat : 'YYYY-MM-DD HH:mm'
                }}</span>
            </div>

            <div class="info" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <span class="label">Completed:</span>
                <span>{{
                    (item.child_opportunity_done ? item.child_opportunity_done : parentOpp.done)
                        | kdDateFormat : 'YYYY-MM-DD HH:mm'
                }}</span>
            </div>
        </div>
    </ng-container>
</div> -->
<div class="materials-items-wrapper" fxLayout="column" *ngIf="items$ | async; let items; else: loading">
    <div fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let item of items">
        <div [matTooltip]="getItemType(item.item_type_value)" fxLayoutAlign="start center" fxLayoutGap="10px">
            <mat-icon class="dark-blue-color">{{
                ResourceTypes.materialTypeIcon({ itemTypeValue: item.item_type_value })
            }}</mat-icon>
            <span class="dark-blue-color"> {{ item.title ?? 'Material' }}</span>
        </div>
        <div class="info-wrap" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
            <div *ngIf="item.created_at" fxLayoutAlign="start center" fxLayoutGap="5px">
                <span class="dark-blue-color" i18n="@@createdAt">Created at</span>:
                <span class="ml-5">{{ item.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
            </div>
            <div *ngIf="item.updated_at" fxLayoutAlign="start center" fxLayoutGap="5px">
                <span class="dark-blue-color" i18n="@@updatedAt">Updated at</span>:
                <span class="ml-5">{{ item.updated_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
            </div>
            <button
                [ngClass]="{ 'mand-item': item.mandatory }"
                class="repeat-item"
                (click)="$event.stopPropagation(); repeatItem(item)"
                matTooltip="Study again. No records of your practice will be displayed in the course"
                mat-icon-button
                i18n-matTooltip
            >
                <mat-icon>play_circle_outline</mat-icon>
            </button>
        </div>
    </div>
</div>
<ng-template #loading>
    <div class="mt-2" fxLayout="row" fxLayoutAlign="center center">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
