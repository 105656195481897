import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Customer } from '@klickdata/core/customer';
import { AppScope } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';

@Component({
    selector: 'app-shared-log-users-tabs',
    templateUrl: './shared-log-users-tabs.component.html',
    styleUrls: ['./shared-log-users-tabs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedLogUsersTabsComponent implements OnInit {
    @Input() user: User;
    @Input() customer: Customer;
    @Input() isMobile: boolean;
    AppScope = AppScope;
    constructor() {}

    ngOnInit(): void {}
}
