<div fxLayout="column" fxLayoutGap="1em" fxFlex="0 1 100%">
    <app-sticky-header
        class="sticky-header"
        [title]="data.name + ' competence'"
        contextIcon=" verified"
        [showSubmit]="false"
        (onCancel)="onCancel()"
    ></app-sticky-header>
    <app-resources-competence-list
        fxFlex="1 1 100%"
        class="p-1"
        [user]="data"
        [tableFilterItems]="mandatoryTableFilterItems"
    ></app-resources-competence-list>
</div>
