<form [formGroup]="welcomeForm" fxFlex="0 0 100%" fxLayout="row wrap">
    <mat-form-field fxFlex="1 0 100%">
        <input matInput placeholder="Subject/Title" i18n-placeholder formControlName="subject" />
        <mat-error i18n="@@aSubjectIsRequired">A subject is required!</mat-error>
    </mat-form-field>
    <mat-expansion-panel
        *ngIf="!params.users_attach || params.users_attach.length > 1"
        [expanded]="false"
        class="no-shadow users-panel"
        fxFlex="0 0 100%"
    >
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon class="mr-1 app-icon">group</mat-icon>
                <ng-container i18n="@@editRecipients">Edit recipients</ng-container>
            </mat-panel-title>
            <mat-panel-description *ngIf="welcomeForm.value.users_attach?.length || userSelect.total">
                ({{ userSelect.selected }}/{{ userSelect.total }})
                <ng-container i18n="@@userSelected">user selected</ng-container>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <app-admin-users-select
            #userSelect
            [customer_id]="params.customer_id"
            [referenceIdKey]="params.idKey"
            [referenceFilter]="params.filterKey || params.idKey"
            [accessForm]="welcomeForm"
            defaultSorting="fname"
        ></app-admin-users-select>
    </mat-expansion-panel>

    <app-text-editor
        [subject]="welcomeForm.value.subject"
        (onSubject)="welcomeForm.controls['subject'].patchValue($event)"
        formControlName="body"
        fieldName="userWelcomeMessage"
        title="Content"
        i18n-title
        fxFlex="1 0 100%"
    >
        <mat-error i18n="@@messageBodyError">Message body is required!</mat-error>
    </app-text-editor>

    <div
        *ngIf="!hideActions"
        fxFlex="1 0 100%"
        fxLayout="row wrap"
        fxLayoutAlign="end center"
        fxLayoutGap=".5em"
        class="mt-1"
    >
        <ng-container *ngIf="loading">
            <app-saving-button></app-saving-button>
        </ng-container>
        <button
            *ngIf="!loading && params?.importing"
            mat-raised-button
            type="button"
            color="accent"
            [routerLink]="['/admin/accounts/import/history/', params?.import]"
            i18n="@@sendEmailLater"
        >
            Send e-mail later
        </button>
        <button
            *ngIf="!loading && (params?.user_activation || params?.importing)"
            mat-raised-button
            type="button"
            color="accent"
            (click)="submitWelcomeMessage(true)"
            i18n="@@standardActivation"
        >
            Standard Activation
        </button>
        <button
            *ngIf="!loading"
            mat-raised-button
            color="primary"
            [disabled]="welcomeForm.invalid && welcomeForm.touched"
            (click)="submitWelcomeMessage()"
            i18n="@@sendEmail"
        >
            Send e-mail
        </button>
    </div>
</form>
