import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MessageDialogueComponent } from '../message-dialogue/message-dialogue.component';
import { map, merge, Observable, of, scan, switchMap } from 'rxjs';
import { SubMessage } from '@klickdata/core/notification/src/subMessage.model';

@Component({
    selector: 'app-message-sub',
    templateUrl: './message-sub.component.html',
    styleUrls: ['./message-sub.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageSubComponent extends MessageDialogueComponent implements OnInit {
    @Input() public itemId: number;
    @Input() public paramKey: string;
    public parentId: number;
    public subMessages$: Observable<SubMessage[]>;
    ngOnInit(): void {
        this.subMessages$ = this.notificationService.getSubMessages(this.itemId, this.paramKey).pipe(
            switchMap((res) => {
                const messages$ = of(res.data);
                const newDialog$ = this.notificationService.listenToNewDialog(res.more?.message_id);
                const updateDialog$ = this.updateDialogSubject.asObservable();
                this.parentId = !!res.data?.length ? res.data[0].id : null;
                return merge(messages$, newDialog$, updateDialog$);
            }),
            scan((acc, val) => acc.concat(val), <SubMessage[]>[])
        );
    }
    createMsg() {
        this.notificationService
            .createSubMessage({
                parent_id: this.parentId,
                body: this.subMessageForm.get('body').value,
                media_ids: this.subMessageForm.get('media_ids').value,
            })
            .subscribe((msg) => {
                this.mediaAttachments.mediaItems.length = 0;
                this.updateDialogSubject.next(msg);
                this.message.nativeElement.value = '';
                this.subMessageForm.get('body').setValue('');
                this.changeRef.markForCheck();
            });
    }
}
