import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Evaluation } from '@klickdata/core/opportunity/src/resource-opportunity.model';
import { User } from '@klickdata/core/user';

@Component({
    selector: 'app-formative-evaluation-item',
    templateUrl: './formative-evaluation-item.component.html',
    styleUrls: ['./formative-evaluation-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormativeEvaluationItemComponent implements OnInit {
    @Input() evaluation: Evaluation;
    @Input() authUser: User;
    constructor() {}

    ngOnInit(): void {}
}
