export class CacheUtils {
    public static setCache<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public static removeFromCache(key: string) {
        localStorage.removeItem(key);
    }

    public static removeAllCachedFilters() {
        Object.entries(localStorage)
            .map((x) => x[0])
            .filter((x) => x.substring(0, 13) == 'table-filter-')
            .map((x) => localStorage.removeItem(x));
    }

    public static getFromCache<T>(key: string): T {
        try {
            return JSON.parse(localStorage.getItem(key));
        } catch (e) {
            return null;
        }
    }
}
