import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { AppScope } from '@klickdata/core/resource';
import { map, Observable } from 'rxjs';

@Injectable()
export class UserAllowedResourcesService {
    constructor(protected auth: AuthService) {}

    public checkResAllowed(scope?: AppScope): Observable<boolean> {
        return this.auth
            .getCustomer()
            ?.pipe(
                map((cust) =>
                    scope
                        ? cust.authoring_allowed_types?.includes(AppScope.RESOURCES) ||
                          cust.authoring_allowed_types?.includes(scope)
                        : !!cust.authoring_allowed_types?.length
                )
            );
    }
    public checkHomeLeftSideNavHide() {
        return this.auth
            .getCustomer()
            ?.pipe(
                map(
                    (cust) =>
                        !cust.authoring_allowed_types?.includes(AppScope.RESOURCES) &&
                        !cust.authoring_allowed_types?.includes(AppScope.MATERIAL)
                )
            );
    }
}
