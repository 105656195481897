import { Injectable } from '@angular/core';
import { Folder, FolderData, FolderService } from '@klickdata/core/folder';
import { PaginatorResponse } from '@klickdata/core/http';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FolderSelectDatatableService extends FolderService implements TableHttpService<Folder> {
    public fetchData(source: TableSource<Folder>): Observable<PaginatorResponse<Folder[]>> {
        const req = this.builder.get<FolderData[]>(this.folderUrl);

        req.param('referenceCount', 1);
        // req.param('publicOrCustomer', this.customer_id);

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<FolderData[]>) => {
                return {
                    data: res.data.map(item => this.createFolder(item)),
                    notify: res.notify,
                    paginator: res.paginator,
                    more: res.more,
                };
            })
        );
    }
}
