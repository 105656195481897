<div fxLayout="column" fxFlex="0 0 100%">
    <app-table-toolbar class="toolbar" color="accent">
        <app-table-filter
            [filtersTogglesInOnBtn]="true"
            [cacheScope]="AppScope.RESOURCES"
            [inGlobalFilter]="true"
            color="accent"
            #filter
        >
            <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>

            <app-user-chip-search-filter
                *ngIf="!assignedByAdmin"
                [inGlobalFilter]="true"
                [filterSpecs]="filterSpecs"
                searchLabel="Author"
                chosingLabel="Choose author"
                searchPlaceholder="Filter by author name.."
                i18n-chosingLabel
                i18n-searchPlaceholder
                i18n-searchLabel
                authorsFilterRef
            ></app-user-chip-search-filter>

            <app-tag-chip-search-filter
                [inGlobalFilter]="true"
                [languageChange]="langFilter.filterChange"
                fxFlex="50%"
                fxFlex.lt-md="100%"
                fxLayoutAlign="start"
                tagsFilterRef
            ></app-tag-chip-search-filter>

            <app-category-chip-search-filter
                [inGlobalFilter]="true"
                fxFlex="50%"
                fxFlex.lt-md="100%"
                [scope_id]="type_scope_id"
                categoriesFilterRef
            ></app-category-chip-search-filter>

            <app-table-date-filter [inGlobalFilter]="true" color="accent" dateFilterRef></app-table-date-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Assign status"
                i18n-label
                [property]="GlobalFilterProperty.ASSGN_STATUS"
                [options]="resourceAssignStatusOptions"
                assignStatusFilterRef
            ></app-select-filter>
            <app-language-filter
                [inGlobalFilter]="true"
                #langFilter
                fxFlex="0 0 auto"
                fxLayout="row"
                langFilterRef
            ></app-language-filter>
        </app-table-filter>
        <mat-progress-bar
            class="progress progress-table-loader"
            *ngIf="(dataSource.status | async) === 'loading'"
            mode="indeterminate"
        >
        </mat-progress-bar>
    </app-table-toolbar>

    <mat-table
        #table
        [dataSource]="dataSource"
        [matSortActive]="'published'"
        matSortDirection="desc"
        matSort
        [class.mobileView]="isMobile"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <span i18n="@@title" class="mobile-label"> Title </span>
                {{ resource.title }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="opportunity_assign_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@assignDate"> Assign date </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@assignDate" class="mobile-label"> Assign date </span>
                {{ resource.opportunity_assign_date | kdDateFormat : 'YYYY-MM-DD' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="opportunity_end_date">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@deadline"> Deadline </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@deadline" class="mobile-label"> Deadline </span>
                {{ resource.opportunity_end_date | kdDateFormat : 'YYYY-MM-DD' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="assigned_by">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@assignedBy"> Assigned by </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@assignedBy">Assigned by</span>
                <ng-container *ngIf="resource.assigned_by && resource.assigned_by.length > 0">
                    <div class="recommend-wrapper" *ngFor="let assignee of resource.assigned_by">
                        <div class="user-img-wrapper">
                            <div [matTooltip]="assignee.name" class="user-img">
                                {{ assignee.name | nameSign }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="users_count">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@totalUsers"> Total Users </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@totalUsers" class="mobile-label"> Total Users </span>
                {{ resource.users_count }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="end_reward">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@endReward"> End reward </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@endReward" class="mobile-label"> End reward </span>
                {{ resource.examination?.type | resourceExaminationTypes }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="started_users">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@ongoing"> Ongoing </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@ongoing" class="mobile-label"> Ongoing </span>
                {{ resource.started_users }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="completed_users">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@completed"> Completed </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@completed" class="mobile-label"> Completed </span>
                {{ resource.completed_users }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="assign_deleted_at">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@deleted"> Deleted </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span class="mobile-label" i18n="@@deleted"> Deleted </span>
                {{ resource.assign_deleted_at | kdDateFormat : 'YYYY-MM-DD' }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="overdue">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@overdue"> Overdue </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@overdue" class="mobile-label"> Overdue </span>
                {{ resource.overdue_users }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="language">
            <mat-header-cell *matHeaderCellDef i18n="@@language"> Language </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@language" class="mobile-label"> Language </span>
                <ng-container *ngIf="resource.language_id">
                    <app-language-selector
                        [language_id]="resource.language_id"
                        class="icon-resource-builder"
                        fxFlex="0 0 auto"
                    ></app-language-selector>
                </ng-container>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
            <mat-cell *matCellDef="let resource" (click)="$event.stopPropagation()">
                <span i18n="@@tools" class="mobile-label mobile-label-tools"> Tools </span>
                <ng-container *ngIf="!resource.loading; else loadingTemp">
                    <button
                        class="tools-btn"
                        mat-icon-button
                        (click)="$event.stopPropagation(); !resource.loading && openAssignmentSheet(resource)"
                        matTooltip="Show assignment"
                        i18n-matTooltip="@@showAssignment"
                    >
                        <mat-icon *ngIf="resource.assignStatus != 'ongoing'">assignment_ind</mat-icon>
                        <img
                            *ngIf="resource.assignStatus == 'ongoing'"
                            src="assets/images/edit-assign.svg"
                            width="24"
                            alt="Edit Assign"
                        />
                    </button>

                    <button
                        *ngIf="resource.assignStatus != 'deleted'"
                        class="tools-btn"
                        mat-icon-button
                        (click)="$event.stopPropagation(); !resource.loading && deleteAssign(resource)"
                        matTooltip="Delete assignment"
                        i18n-matTooltip
                    >
                        <mat-icon>delete_forever</mat-icon>
                    </button>

                    <mat-icon
                        class="tools-btn"
                        *ngIf="resource | resourceStatus : resource.assignStatus as assignStatus"
                        [matTooltip]="assignStatus.status"
                        [ngStyle]="{ color: assignStatus.color }"
                    >
                        {{ assignStatus.icon }}
                    </mat-icon>

                    <button
                        (click)="
                            $event.stopPropagation(); expandedElement = expandedElement === resource ? null : resource
                        "
                        matTooltip="View users"
                        mat-icon-button
                        i18n-matTooltip="@@viewUsers"
                    >
                        <mat-icon>{{ expandedElement === resource ? 'expand_less' : 'expand_more' }}</mat-icon>
                    </button>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let resource" [attr.colspan]="columns.length">
                <div
                    class="example-element-detail"
                    [@detailExpand]="resource == expandedElement ? 'expanded' : 'collapsed'"
                >
                    <app-users-assign-status
                        *ngIf="expandedElement === resource"
                        [resource]="resource"
                    ></app-users-assign-status>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            [ngClass]="!resource.loading ? 'link' : 'disableIt'"
            [class.example-expanded-row]="expandedElement === resource"
            (click)="expandedElement = expandedElement === resource ? null : resource"
            *matRowDef="let resource; columns: columns"
            class="example-element-row"
        ></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading sizeee="small"></app-kd-logo-loading>
    </div>
</ng-template>
