import { Cast, CastType, IDataModel, Model } from '@klickdata/core/application';
import { AppScope } from '@klickdata/core/resource';

export interface CustomFieldData extends IDataModel {
    id?: number;
    name?: string;
    placeholder?: string;
    type?: string;
    options?: string[];
    default?: boolean;
    scope_id?: AppScope;
    customer_id?: number;
    order?: number;
    enabled?: boolean;
    disabled?: boolean;
    value?: any;
    initial_value?: any;
}

export class CustomField extends Model<CustomFieldData> {
    id: number;
    name: string;
    placeholder: string;
    type: string;
    options: string[];
    default: boolean;
    scope_id: AppScope;
    customer_id: number;
    @Cast(CastType.CLOSURE, (order: string | number) => +order)
    order: number;
    enabled: boolean;
    disabled: boolean;
    value: any;
    initial_value: any;
}
export enum FieldType {
    TEXT_INPUT = 'input',
    SINGLE_CHOICE = 'radio',
    MULTIPLE_CHOICE = 'checkbox',
    DATE = 'date',
    DROPDOWN = 'dropdown',
    SWITCH = 'switch',
    FILE = 'file',
}
