import { IDataModel, Ignore, Model } from '@klickdata/core/application';
export interface UserMessageData extends IDataModel {
    groups?: number[];
    import?: number;
    customer?: number;
    users_attach?: number[];
    users_detach?: number[];
    subject?: string;
    body?: string;
}

export class UserMessage extends Model<UserMessageData> {
    public id: string | number;
    public groups: number[];
    public import: number;
    public customer: number;
    public users_attach: number[];
    public users_detach: number[];
    public subject: string;
    public body: string;

    @Ignore()
    public filterKey: string;
    @Ignore()
    public idKey: string;
}
