<app-full-layout [hideFooter]="hideFooter" class="layout">
    <div class="dashboard-flex" fxLayout="column wrap" fxLayoutAlign="center center">
        <div *ngIf="isMobile | async" class="mobile-custom-tabs-wrapper">
            <mat-accordion displayMode="flat">
                <mat-expansion-panel #panel>
                    <mat-expansion-panel-header>
                        <ng-container
                            ><span>{{ AppScope.label(selectedScope, true) }}</span></ng-container
                        >
                    </mat-expansion-panel-header>
                    <div class="activites-list-wrapper">
                        <mat-selection-list (selectionChange)="panel.close()" #activities [multiple]="false">
                            <ng-container *ngFor="let mappedScope of mappedScopesWithCounts">
                                <mat-list-option
                                    *ngIf="
                                        (mappedScope.scope === AppScope.ALL ||
                                            availableScopes?.includes(mappedScope.scope)) &&
                                        mappedScope.scope !== selectedScope
                                    "
                                    (click)="updateSelectedChip(mappedScope.scope)"
                                >
                                    <span>{{ AppScope.label(mappedScope.scope, true) }}</span>
                                    <mat-icon mat-list-icon>navigate_next</mat-icon>
                                </mat-list-option>
                            </ng-container>
                        </mat-selection-list>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <mat-chip-list
            *ngIf="!(isMobile | async)"
            class="mat-chip-list-search"
            [ngClass]="{ 'mobile-view': isMobile | async }"
            [multiple]="false"
            [class.top-box-search]="isSearchBoxActive | async"
        >
            <ng-container *ngFor="let mappedScope of mappedScopesWithCounts">
                <div
                    *ngIf="mappedScope.scope === AppScope.ALL || availableScopes?.includes(mappedScope.scope)"
                    class="search-info-wrapper"
                    fxLayout="column"
                    fxLayoutAlign="center center"
                    fxLayoutGap="10px"
                >
                    <!-- <span class="chip-title">{{ AppScope.label(mappedScope.scope, true) }}</span> -->
                    <mat-chip
                        class="chip-point chip-top-search"
                        [value]="mappedScope.scope"
                        [selected]="selectedScope === mappedScope.scope"
                    >
                        {{ AppScope.label(mappedScope.scope, true) }}
                    </mat-chip>
                </div>
            </ng-container>
        </mat-chip-list>

        <div *ngIf="searchDataItems$ | async; else loadingTemp; let data" class="parent-section-wrapper">
            <div
                *ngIf="!loading; else loadingTemp"
                class="block-wrapper search-body-wrap"
                fxLayout="row wrap"
                fxLayoutAlign="center center"
            >
                <mat-accordion
                    *ngIf="selectedScope === AppScope.ALL"
                    multi
                    fxFlex="0 0 100%"
                    fxLayout="column"
                    displayMode="flat"
                >
                    <mat-expansion-panel
                        *ngFor="let entry of data | keyvalue : noSort"
                        [expanded]="true"
                        #expansionPanel
                        [ngClass]="{
                            'is-tag-expand': Number(entry.key) === AppScope.TAGS,
                            'is-cat-expand': Number(entry.key) === AppScope.CATEGORIES
                        }"
                    >
                        <mat-expansion-panel-header>
                            <span class="title"> {{ AppScope.label(Number(entry.key), true) }}: {{ searchKey }} </span>
                        </mat-expansion-panel-header>
                        <ng-container>
                            <div
                                class="items-wrapper section-list"
                                *ngIf="AppScope.isResourceContainer(entry.key) || AppScope.isLink(entry.key)"
                            >
                                <mat-list
                                    [ngClass]="{
                                        'is-tag-res': Number(entry.key) === AppScope.TAGS,
                                        'is-cat-res': Number(entry.key) === AppScope.CATEGORIES
                                    }"
                                    role="list"
                                >
                                    <ng-container
                                        [ngTemplateOutlet]="container_items"
                                        [ngTemplateOutletContext]="{ containers: entry.value, scope: entry.key }"
                                    ></ng-container>
                                </mat-list>
                            </div>
                            <div
                                class="items-wrapper"
                                *ngIf="AppScope.isResource(entry.key) || Number(entry.key) === AppScope.POST"
                            >
                                <ng-container
                                    [ngTemplateOutlet]="section_items"
                                    [ngTemplateOutletContext]="{ resources: entry.value, mode: 'icons' }"
                                ></ng-container>
                            </div>
                            <div class="items-wrapper" *ngIf="Number(entry.key) === AppScope.QUOTE">
                                <ng-container
                                    [ngTemplateOutlet]="quotes_items"
                                    [ngTemplateOutletContext]="{ resources: entry.value }"
                                ></ng-container>
                            </div>
                            <div class="items-wrapper" *ngIf="Number(entry.key) === AppScope.FILE">
                                <ng-container [ngTemplateOutlet]="files_items"></ng-container>
                            </div>
                        </ng-container>
                    </mat-expansion-panel>
                    <ng-container *ngIf="isEmpty(data)" [ngTemplateOutlet]="noSearchResults"></ng-container>
                </mat-accordion>
            </div>
        </div>
        <div class="parent-section-wrapper" *ngIf="selectedScope !== AppScope.ALL && selectedScope !== AppScope.FILE">
            <div
                *ngIf="!loading; else loadingTemp"
                class="block-wrapper"
                fxLayout="row wrap"
                fxLayoutAlign="center center"
            >
                <app-section-item-parent
                    *ngIf="attachedLanguages | async; let langs"
                    [title]="AppScope.label(selectedScope, true) + ' : ' + searchKey"
                    [attachedLanguages]="langs"
                    [sectionType]="SectionType.GLOBAL_SEARCH_SECTION"
                    [param]="{ scope: selectedScope, query: searchKey }"
                    [service]="service"
                    class="search-section-parent-wrapper"
                    (onPlayActivity)="dismissFilterBar(); play($event)"
                    (onDeleteActivity)="deleteCourseFromList($event)"
                    (onBrowseActivity)="dismissFilterBar(); browse($event)"
                    (onAssign)="assignCourse($event)"
                ></app-section-item-parent>
            </div>
        </div>

        <div class="items-wrapper" *ngIf="selectedScope === AppScope.FILE">
            <ng-container [ngTemplateOutlet]="files_items"></ng-container>
        </div>
    </div>
</app-full-layout>
<ng-template #loadingTemp>
    <div>
        <div class="mt-2 mb-2" fxFlex="1 1 100%" fxLayout="row" fxLayoutAlign="center center">
            <app-kd-logo-loading></app-kd-logo-loading>
        </div>
    </div>
</ng-template>

<ng-template #quotes_items let-resources="resources" let-mode="mode">
    <app-quotes-slider [quotes]="resources"></app-quotes-slider>
</ng-template>
<ng-template #files_items>
    <div fxLayout="column" fxLayoutGap="5" class="w-100">
        <div class="files-table" fxLayoutAlign="start center" fxLayout="row">
            <span class="id">Id</span>
            <span class="name">Name</span>
            <div class="prio">Priority</div>
            <span class="creator">Creator</span>
            <div class="tools">Tools</div>
        </div>
        <div class="files-table" fxLayout="row" fxLayoutAlign="start center" *ngFor="let file of filesSamples">
            <span class="id">{{ file.id }}</span>
            <span class="name">{{ file.title }}</span>
            <div class="prio">
                <div class="{{ file.priority }} customer-item-not-task ">{{ file.priority }}</div>
            </div>
            <span class="creator">{{ file.creator }}</span>
            <div class="tools">
                <button
                    (click)="$event.stopPropagation(); downloadFile(file)"
                    matTooltip="Download"
                    mat-icon-button
                    i18n-matTooltip="@@download"
                    class="comment-add"
                >
                    <mat-icon>file_download</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); editFile(file)"
                    matTooltip="Edit"
                    mat-icon-button
                    i18n-matTooltip="@@edit"
                    class="comment-add"
                >
                    <mat-icon>edit</mat-icon>
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #section_items let-resources="resources" let-mode="mode">
    <app-section-item
        class="res-list-item"
        *ngFor="let resource of resources"
        [mode]="mode"
        [resource]="resource"
        [fxFlex]="mode === 'icons' ? '0 0 15%' : '0 0 95%'"
        [fxLayout.lt-md]="mode === 'icons' ? '0 0 25%' : '0 0 95%'"
        [fxLayout.lt-sm]="mode === 'icons' ? '0 0 35%' : '0 0 95%'"
    ></app-section-item>

    <ng-container *ngIf="!resources?.length" [ngTemplateOutlet]="noSearchResults"></ng-container>
</ng-template>
<ng-template #container_items let-containers="containers" , let-scope="scope">
    <mat-list-item
        (click)="AppScope.isResourceContainer(scope) ? showResources(container) : handleLinkClick(container)"
        *ngFor="let container of containers"
        role="listitem"
    >
        <mat-icon *ngIf="AppScope.isResourceContainer(scope)" mat-list-icon>{{
            container | typeFontIcon : container.scope_id
        }}</mat-icon>
        <mat-icon
            mat-list-icon
            fontSet="klickdata"
            fontIcon="kd-icon-user"
            *ngIf="Number(scope) === AppScope.USERS"
        ></mat-icon>
        <mat-icon mat-list-icon *ngIf="Number(scope) === AppScope.GROUPS || Number(scope) === AppScope.CUSTOMERS">{{
            container | typeFontIcon : container.scope_id
        }}</mat-icon>
        {{ container.label }}</mat-list-item
    >

    <ng-container *ngIf="!containers?.length" [ngTemplateOutlet]="noSearchResults"></ng-container>
</ng-template>

<ng-template #noSearchResults>
    <div class="mt-2 mb-2" fxFlex="1 1 100%" fxLayout="column" fxLayoutAlign="center center">
        <p i18n="@@noSearchResultsMSG">Sorry, we couldn't find any results for this search.</p>

        <button *ngIf="selectedScope !== AppScope.ALL" mat-raised-button color="primary" (click)="searchAll()" i18n>
            Search All
        </button>
    </div>
</ng-template>
