import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { EditorTemplate, EditorTemplateData } from './editor-template.model';

@Injectable()
export class EditorTemplateService {
    protected resourceUrl: string;
    protected assessmentTemplUrl: string;

    constructor(
        protected builder: RequestBuilderService,
        protected auth: AuthService,
        protected config: ConfigService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}editor-templates`;
        this.assessmentTemplUrl = `${this.config.config.resourceUrl}assessments_templates`;
    }

    public getDefaultTemplate(field_name: string): Observable<EditorTemplate> {
        return this.builder
            .get<EditorTemplate[]>(this.resourceUrl)
            .param('default', 'true')
            .param('field_name', field_name)
            .request()
            .pipe(map((res) => res.data[0]));
    }

    public getFilteredTemplates(
        field_name: string,
        group?: string,
        customer?: string,
        master?: boolean
    ): Observable<EditorTemplate[]> {
        const builder = this.builder.get<EditorTemplate[]>(this.resourceUrl).param('field_name', field_name);

        if (group) {
            builder.param('group', group);
        }

        if (customer) {
            builder.param('customer', customer);
        }

        if (master) {
            builder.param('master', master.toString());
        }

        return builder.request().pipe(map((res) => res.data));
    }

    public getUserFieldTemplates(field_name: string, query?: string): Observable<EditorTemplate[]> {
        const req = this.builder.get<EditorTemplateData[]>(this.resourceUrl).param('field_name', field_name);
        if (query) {
            req.param('query', query);
        }
        return this.auth.getUser().pipe(
            first(),
            switchMap((user) =>
                req
                    .param('user', user.id)
                    .request()
                    .pipe(
                        map((res) =>
                            res.data.map((data) => {
                                const temp = new EditorTemplate(data);
                                temp.editable = user.canEdit(temp);
                                return temp;
                            })
                        )
                    )
            )
        );
    }
    public getAssessmentTemplates(query?: string): Observable<EditorTemplate[]> {
        const req = this.builder.get<EditorTemplateData[]>(this.assessmentTemplUrl);
        if (query) {
            req.param('query', query);
        }
        return this.auth.getUser().pipe(
            first(),
            switchMap((user) =>
                req
                    .param('user', user.id)
                    .request()
                    .pipe(
                        map((res) =>
                            res.data.map((data) => {
                                const temp = new EditorTemplate(data);
                                temp.editable = user.canEdit(temp);
                                return temp;
                            })
                        )
                    )
            )
        );
    }

    public create(template: EditorTemplate): Observable<EditorTemplate> {
        return this.builder
            .post<EditorTemplate>(this.resourceUrl, template)
            .request()
            .pipe(map((res) => res.data));
    }
    public createAssessmentTemplate(template: EditorTemplate): Observable<EditorTemplate> {
        return this.builder
            .post<EditorTemplate>(this.assessmentTemplUrl, template)
            .request()
            .pipe(map((res) => res.data));
    }

    public update(template: EditorTemplate): Observable<EditorTemplate> {
        return this.builder
            .put<EditorTemplate>(`${this.resourceUrl}/${template.id}`, template)
            .request()
            .pipe(map((res) => res.data));
    }

    public destroy(template: EditorTemplate): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${template.id}`)
            .request()
            .pipe(map((res) => res.data));
    }
}
