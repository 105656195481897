import { IDataModel, Model } from 'libs/core/application';

export interface RoleData extends IDataModel {
    id?: number;
    title?: string;
    description?: string;
    icon?: string;
    displayed_name?: string;
    public?: boolean;
    parent_id?: number;
    customer_id?: number;
    name_options?: string[];
}
export class Role extends Model<RoleData> {
    id: number;
    title: string;
    description: string;
    icon: string;
    displayed_name: string;
    public: boolean;
    parent_id: number;
    customer_id: number;
    name_options: string[];
}
