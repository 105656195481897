import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Collection } from '@klickdata/core/collection';
import { Section, SectionService } from '@klickdata/core/section';
import { Filter, GlobalFilterProperty, TableFilterComponent, TableSource } from '@klickdata/core/table';
import { Subject } from 'rxjs';
import { SectionsListingDatatableService } from './sections-listing-datatable.service';

@Component({
    selector: 'app-collection-item-children-list',
    templateUrl: './collection-item-children-list.component.html',
    styleUrls: ['./collection-item-children-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SectionsListingDatatableService],
})
export class CollectionItemChildrenListComponent implements OnInit, AfterViewInit {
    @Input() collection: Collection;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public dataSource = new TableSource<Section>();
    columns: string[] = ['title', 'created_at', 'updated_at', 'public'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;

    constructor(protected sectionSerice: SectionService, public datatableService: SectionsListingDatatableService) {}

    ngOnInit(): void {
        this.dataSource.service = this.datatableService;
    }
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        if (this.filter && this.collection) {
            this.filter.setActiveFilter(GlobalFilterProperty.SEARCH);
            this.filter.createOrUpdateFilter([new Filter('collection', [this.collection.id])]);
        }
    }
}
