import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { PaymentData, PaymentService } from '@klickdata/core/payment';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-dialog-paytabs-payment-page',
    templateUrl: './dialog-paytabs-payment-page.component.html',
    styleUrls: ['./dialog-paytabs-payment-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogPaytabsPaymentPageComponent implements OnInit, OnDestroy {
    private destroy: Subject<boolean> = new Subject<boolean>();
    constructor(
        public dialogRef: MatDialogRef<DialogPaytabsPaymentPageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { id: number; redirect_url: string },
        protected messageService: MessageService,
        protected auth: AuthService,
        protected paymentService: PaymentService
    ) {}

    ngOnInit(): void {
        this.buildPaymentSocket(this.data.id);
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    buildPaymentSocket(id: number) {
        this.auth
            .listenPrivate(`payment.${id}`, 'PaymentEvent', this.paymentService.get(id))
            .pipe(
                takeUntil(this.destroy),
                map((response) => response.data),
                filter((payment) => payment.status !== 'PROCESSING')
            )
            .subscribe((payment: PaymentData) => {
                if (payment.status === 'COMPLETED') {
                    this.dialogRef.close(true);
                } else {
                    this.messageService.openMessage(MessageErrorComponent, payment.status);
                    this.dialogRef.close(false);
                }
            });
    }
}
