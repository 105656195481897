import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterOption } from '@klickdata/core/table';
import { Subject, startWith, takeUntil } from 'rxjs';

@Component({
    selector: 'app-notes-context',
    templateUrl: './notes-context.component.html',
    styleUrls: ['./notes-context.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesContextComponent implements OnInit, OnDestroy {
    @Input() noteContextOptions: FilterOption[];
    public contextTypeForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @Output() onNotesContextUpdate: EventEmitter<any> = new EventEmitter<any>();

    constructor(protected fb: FormBuilder) {}

    ngOnInit(): void {
        this.contextTypeForm = this.fb.group({
            type: [this.noteContextOptions[0].property],
            user: [[]],
            customer: [[]],
        });

        this.contextTypeForm.valueChanges
            .pipe(takeUntil(this.destroy), startWith(this.contextTypeForm.value))
            .subscribe((value) => {
                switch (value.type) {
                    case 'none':
                        this.onNotesContextUpdate.emit({ parent_id: 'null' });
                        break;
                    case 'learner':
                        this.onNotesContextUpdate.emit(value.user ? { user_id: value.user[0] } : { parent_id: 'null' });
                        break;
                    case 'client':
                        this.onNotesContextUpdate.emit(
                            value.customer ? { customer_id: value.customer[0] } : { parent_id: 'null' }
                        );
                        break;
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
