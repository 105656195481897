import { SideNaveActionsTypes } from '@klickdata/core/mobile/src/mobile.service';
import { Injectable, Injector, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { DialogDiscardChangesComponent } from '@klickdata/core/application/src/dialog-discard-changes/dialog-discard-changes.component';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Observable, of } from 'rxjs';
import { first, map, switchMap, filter } from 'rxjs/operators';
import { AuthService } from '../token/auth.service';

export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | boolean;
    saveAndDeactivate?: () => Observable<boolean>;
}

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateGuardService implements CanDeactivate<CanComponentDeactivate> {
    constructor(
        protected dialog: MatDialog,
        protected injector: Injector,
        protected authService: AuthService,
        protected mobileService: MobileService,
        protected router: Router
    ) {}

    public canDeactivate(
        component: CanComponentDeactivate,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): boolean | Observable<boolean> {
        return this.authService.check().pipe(
            first(),
            switchMap((authenication) => {
                if (!authenication) {
                    return of(true); // When token expired deactivate shall valid to kill session.
                }

                if (!component) {
                    component = this.injector.get(currentRoute.component as Type<any>);
                }
                if (this.router.getCurrentNavigation()?.extras?.queryParams?.bypassGuardWithSaving) {
                    return component.saveAndDeactivate();
                }
                if (this.router.getCurrentNavigation()?.extras?.queryParams?.bypassGuardWithoutSaving) {
                    return of(true);
                }
                const canDeactivate = component.canDeactivate();

                if (typeof canDeactivate === 'boolean') {
                    if (!canDeactivate) {
                        return this.showDialog(component);
                    }
                    return of(true);
                }

                return canDeactivate.pipe(
                    switchMap((result: boolean) => {
                        if (!result) {
                            // if (this.router.getCurrentNavigation()?.extras?.queryParams?.bypassGuardWithSaving) {
                            //     return component.saveAndDeactivate();
                            // }
                            return this.showDialog(component);
                        }
                        return of(true);
                    })
                );
            })
        );
    }

    public showDialog(component: CanComponentDeactivate): Observable<boolean> {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: !!component.saveAndDeactivate ? 'save' : 'cancel_presentation',
                title: !!component.saveAndDeactivate ? $localize`Save changes?` : $localize`Discard changes?`,
                type: 'discardSlider',
                contentBody: $localize`You have unsaved changes. If you continue your changes will be discarded.`,
                customBtn: $localize`Discard changes`,
                negativeBtn: $localize`Cancel`,
                positiveBtn: !!component.saveAndDeactivate ? $localize`Save` : null,
            },
        });
        return this.mobileService.getSideNavAction().pipe(
            switchMap((action) => {
                if (action === SideNaveActionsTypes.POSITIVE) {
                    return component.saveAndDeactivate();
                } else {
                    return of(action === SideNaveActionsTypes.CUSTOM);
                }
            })
        );
    }
}
