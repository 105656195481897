import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from '@klickdata/core/customer';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { User } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-date-range',
    templateUrl: './menu-side-date-range.component.html',
    styleUrls: ['./menu-side-date-range.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideDateRangeComponent extends OnDestroyHandler implements AfterViewInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public max = moment().utc();
    public rangeForm: FormGroup;
    public minDate: moment.Moment;
    public min: Observable<moment.Moment>;
    constructor(protected fb: FormBuilder) {
        super();
        this.buildRangeForm();
    }

    ngAfterViewInit(): void {
        this.rangeForm.valueChanges
            .pipe(
                takeUntil(this.destroy),
                filter((value) => value && value.begin && value.end),
                filter((range) => this.vaildRange(range))
            )
            .subscribe((value) =>
                this.onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: { begin: value.begin.format('YYYY-MM-DD'), end: value.end.format('YYYY-MM-DD') },
                })
            );

        this.min = this.navData.data.model.pipe(
            first(),
            map((model: Customer | User) => {
                this.minDate = model instanceof Customer ? model.published : model.created_at;
                return this.minDate;
            })
        );
    }
    protected buildRangeForm(): void {
        this.rangeForm = this.fb.group({
            begin: [null, Validators.required],
            end: [null, Validators.required],
        });
    }
    private vaildRange(range: { begin: moment.Moment; end: moment.Moment }): boolean {
        return (
            range.begin.isSameOrBefore(range.end) &&
            range.begin.isBetween(this.minDate, this.max, 'day', '[]') &&
            range.end.isBetween(this.minDate, this.max, 'day', '[]')
        );
    }
}
