import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Alternative, AlternativeService, Question, QuestionTypes } from '@klickdata/core/question';

@Component({
    selector: 'app-resource-item-question-alternatives',
    templateUrl: './resource-item-question-alternatives.component.html',
    styleUrls: ['./resource-item-question-alternatives.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceItemQuestionAlternativesComponent implements OnInit {
    @Input()
    public question: Question;

    @Input()
    public showPoints = true;

    public alternatives: Observable<Alternative[]>;

    public QuestionTypes = QuestionTypes;

    constructor(protected alternativeService: AlternativeService) {}

    ngOnInit() {
        if (this.question && this.question.id) {
            this.alternatives = this.alternativeService.getByQuestion(this.question.id);
        }
    }
}
