import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';

@Component({
    selector: 'app-general-chat-gpt',
    templateUrl: './general-chat-gpt.component.html',
    styleUrls: ['./general-chat-gpt.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralChatGPTComponent implements OnInit {
    public platform: string;
    constructor(protected mobileService: MobileService, protected auth: AuthService) {}

    ngOnInit(): void {
        this.platform = this.auth.getNK3PlatformValue();
    }
    createMaterial() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.AI_PROMPTER,
            data: {
                icon: 'smart_toy',
                title: $localize`Create material with AI`,
                contentBody: $localize`Use Artificial Intelligence (AI) to create your material. Start typing and let the fantasy flow in
                the prompter!`,
            },
        });
    }
}
