export class UserLogin {
    public ip: string;
    public country: string; // location.country.name
    public region: string; // location.region.name
    public city: string; // location.city
    public latitude: number; // location.latitude
    public longitude: number; // location.longitude

    // user_agent
    public browser: string; // user_agent.name + user_agent.version
    public device: string; // user_agent.device.name
    public os: string; // user_agent.os.name + user_agent.os.version

    public constructor(data?: any) {
        if (data) {
            this.ip = data.ip;
            this.country = data.location.country.name;
            this.region = data.location.region.name;
            this.city = data.location.city;
            this.latitude = data.location.latitude;
            this.longitude = data.location.longitude;
            this.browser = data.user_agent.name + ' ' + data.user_agent.version;
            this.device = data.user_agent.device.name;
            this.os = data.user_agent.os.name + ' ' + data.user_agent.os.version;
        }
    }
}
