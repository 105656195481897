import { Cast, CastType, IDataModel, Model } from '@klickdata/core/application';
import { CustomerData } from '@klickdata/core/customer';
import { AppScope } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import * as moment from 'moment';

export interface ActivityLogData extends IDataModel {
    id?: number;
    created_at?: string;
    scope_id?: number;
    action?: string;
    subject_title?: string;
    subject_id?: number;
    author?: { [key: string]: string };
    user?: User;
    customer?: CustomerData;
}

export class ActivityLog extends Model<ActivityLogData> {
    id: number;
    @Cast(CastType.MOMENT)
    created_at: moment.Moment;
    scope_id: number;
    action: string;
    subject_title: string;
    subject_id: number;
    author: { [key: string]: string };
    user: User;
    customer: CustomerData;

    get isResource() {
        return AppScope.isResource(this.scope_id);
    }
}
