<mat-sidenav-container
    [ngClass]="{ sideBarCollapsed: sideBarCollapsed, sideBarUnCollapsed: !sideBarCollapsed }"
    class="app-sidenav-container"
>
    <mat-sidenav
        mode="side"
        [opened]="isOpen"
        [disableClose]="true"
        class="app-sidenav main-side-nave-parent header-visible"
    >
        <div class="white-bg-wrapper"></div>
        <div class="white-bg-wrapper first"></div>
        <ng-content select="app-sidebar-menu"></ng-content>
        <div (click)="collapseToggle()" class="collapse-wrapper">
            <mat-icon>menu_open</mat-icon>
            <span>Collapse</span>
        </div>
    </mat-sidenav>

    <div class="side-nav-content">
        <article class="app-content">
            <ng-content></ng-content>
        </article>

        <app-footer></app-footer>
    </div>
</mat-sidenav-container>
