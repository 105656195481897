<mat-accordion displayMode="flat">
    <mat-expansion-panel [expanded]="true" #expansionPanel>
        <mat-expansion-panel-header> </mat-expansion-panel-header>
        <app-table-filter
            class="action-log-res"
            [cacheScope]="AppScope.NOTIFICATION"
            [inGlobalFilter]="true"
            color="accent"
            #filter
        >
            <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        </app-table-filter>
    </mat-expansion-panel>
</mat-accordion>
<div class="cases-users-log">
    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table #table [dataSource]="dataSource" matSortDirection="desc">
        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Case</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let case">
                <span *ngIf="isMobile" class="mobile-label" i18n>Title</span>
                {{ case.title }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="author">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Role</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let case">
                <span *ngIf="isMobile" class="mobile-label" i18n>Role</span>
                <div
                    [ngClass]="{ teacher: case.author, learner: !case.author }"
                    fxLayoutGap="5px"
                    fxLayoutAlign="start center"
                >
                    <mat-icon>{{ case.author ? 'manage_accounts' : 'person' }}</mat-icon>
                    <span i18n *ngIf="case.author">Teacher</span>
                    <span i18n *ngIf="!case.author">Learner</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Created</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let case">
                <span *ngIf="isMobile" class="mobile-label" i18n>Created</span>
                {{ case.created_at | kdDateFormat: 'YYYY-MM-DD HH:mm' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Status</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let case">
                <span *ngIf="isMobile" class="mobile-label" i18n>Status</span>
                <ng-container *ngIf="!case.author">
                    <div *ngIf="case.last_status" class="case-status" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <mat-icon>{{ (case.last_status?.type | caseStatus)?.icon }}</mat-icon>
                        <span>{{ (case.last_status?.type | caseStatus)?.title }}</span>
                    </div>
                    <span *ngIf="!case.last_status" class="na">-</span>
                </ng-container>
                <ng-container *ngIf="case.author">
                    <button [disabled]="!case.users || !case.users.length" class="teacher" (click)="getUsersStatus(case)" mat-icon-button>
                        <mat-icon>people_alt</mat-icon>
                    </button>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status_time">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Status time</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let case">
                <span *ngIf="isMobile" class="mobile-label" i18n>Status time</span>
                <ng-container *ngIf="!case.author">
                    <span *ngIf="case.last_status">{{ case.last_status?.date | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
                <span *ngIf="!case.last_status" class="na">-</span>
                </ng-container>
                <ng-container *ngIf="case.author">
                    <button [disabled]="!case.users || !case.users.length" class="teacher" (click)="getUsersStatus(case)" mat-icon-button>
                        <mat-icon>people_alt</mat-icon>
                    </button>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="end_date">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Deadline</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let case">
                <span *ngIf="isMobile" class="mobile-label" i18n>Deadline</span>
                <span *ngIf="case.end_date">{{ case.end_date | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
                <span class="na" *ngIf="!case.end_date">NA</span>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row (click)="$event.stopPropagation()" *matRowDef="let case; columns: columns"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"> </mat-paginator>
</div>
