<mat-accordion displayMode="flat">
    <mat-expansion-panel [expanded]="true" #expansionPanel>
        <mat-expansion-panel-header> </mat-expansion-panel-header>
        <app-table-filter
            class="action-log-res"
            [cacheScope]="AppScope.RESOURCES"
            [inGlobalFilter]="true"
            color="accent"
            #filter
        >
            <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="All Time"
                i18n-label
                [property]="GlobalFilterProperty.TIME_SPENT"
                [options]="predefinedTimeSpentOptions"
                fixedTimeSpentFilterRef
            >
            </app-select-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Resource Action"
                i18n-label="@@resourceAction"
                [property]="GlobalFilterProperty.LOG_EVENT"
                [options]="resourceEventOptions"
                logEventRef
            ></app-select-filter>
            <app-language-filter
                [inGlobalFilter]="true"
                #langFilter
                fxFlex="0 0 auto"
                fxLayout="row"
                langFilterRef
            ></app-language-filter>
        </app-table-filter>
    </mat-expansion-panel>
</mat-accordion>
<div class="activty-wrapper">
    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table #table [dataSource]="dataSource">
        <ng-container matColumnDef="last_action">
            <mat-header-cell *matHeaderCellDef i18n="@@lastAction"> Last action </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="!isMobile">
                    {{ resource.last_action | kdDateFormat: 'YYYY-MM-DD HH:mm' }}
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="image">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@image">Image</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <app-image-view
                    [media_id]="resource.media$"
                    [proportional]="!isMobile"
                    [paddingBottom]="'58px'"
                    [smallSpinner]="true"
                ></app-image-view>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef i18n="@@title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="!isMobile">
                    {{ resource.title }}
                </span>
                <div fxLayout="column" fxLayoutGap="10px" *ngIf="isMobile">
                    <div class="mobile-title-row" fxLayoutAlign="start center">
                        <app-image-view
                            [media_id]="resource.media$"
                            [paddingBottom]="'58px'"
                            [proportional]="false"
                            [smallSpinner]="true"
                        ></app-image-view>
                        <span>{{ resource.title }}</span>
                    </div>
                    <div>
                        <span class="mobile-label" i18n="@@lastAction">Last action</span>
                        <span> {{ resource.last_action | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
                    </div>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="event">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@action">Action</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@action">Action</span>
                <div fxLayoutAlign="start center" fxLayoutGap="5px" class="info-wrap">
                    <mat-icon> {{ (resource.event | actionLogEvent)?.icon }}</mat-icon>
                    <span> {{ (resource.event | actionLogEvent).title }}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="language_id">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@lang">Lang</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@lang">Lang</span>
                <ng-container>
                    <app-language-selector
                        [language_id]="resource.language_id"
                        class="icon-resource-builder"
                        fxFlex="0 0 auto"
                    ></app-language-selector>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deadline">
            <mat-header-cell *matHeaderCellDef i18n="@@deadline"> Deadline </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@deadline">Deadline</span>
                <span *ngIf="resource.deadline">
                    {{ resource.deadline | kdDateFormat: 'YYYY-MM-DD' }}
                </span>
                <span *ngIf="!resource.deadline"> NA </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="started_at">
            <mat-header-cell *matHeaderCellDef i18n="@@started"> Started </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@started">Started</span>
                <span *ngIf="resource.started_at">
                    {{ resource.started_at | kdDateFormat: 'YYYY-MM-DD' }}
                </span>
                <span *ngIf="!resource.started_at"> NA </span>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row (click)="$event.stopPropagation()" *matRowDef="let resource; columns: columns"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"> </mat-paginator>
</div>
