import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DownloadData } from '@klickdata/core/resource/src/download.model';
import * as moment from 'moment';
import { DownloadService } from './download.service';

@Component({
    selector: 'app-download-pdf-dialog',
    templateUrl: './download-pdf-dialog.component.html',
    styleUrls: ['./download-pdf-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadPdfDialogComponent implements OnInit {
    public title: string;
    public savingTitle: string;
    public form: FormGroup;
    downloading: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public downloads: any,
        protected dialogRef: MatDialogRef<DownloadPdfDialogComponent>,
        protected fb: FormBuilder,
        protected downloadService: DownloadService
    ) {
        this.title = $localize`:@@download:Download`;
        this.savingTitle = $localize`:@@download:Download`;
    }

    ngOnInit(): void {
        this.form = new FormGroup({
            downloadOptionsForm: new FormGroup({}),
        });
        this.downloads.data.forEach((downloadData: DownloadData) => {
            (this.form.get('downloadOptionsForm') as FormGroup).addControl(
                downloadData.value,
                new FormControl({ value: false, disabled: !downloadData.canSee })
            );
        });
    }

    public onMessageSent() {
        this.dialogRef.close();
    }

    public onClose() {
        this.dialogRef.close();
    }

    public download() {
        const checkedOptionKeys = Object.keys(this.form.get('downloadOptionsForm').value).filter(
            (key) => this.form.get('downloadOptionsForm').value[key]
        );
        if (checkedOptionKeys.length === 1) {
            const url = this.downloads.data.find((option: DownloadData) => option.value === checkedOptionKeys[0]).url;
            window.open(url, '_blank');
            this.onClose();
        } else if (this.downloads.archive_link && checkedOptionKeys.length > 1) {
            this.downloading = true;
            this.downloadService
                .download(this.downloads.archive_link, { types: checkedOptionKeys })
                .subscribe((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.download = `KLMS_package_${moment().format('yMD_hms')}.zip`;
                    anchor.href = url;
                    anchor.click();
                    this.downloading = false;
                    this.onClose();
                });
        }
    }
}
