import { BookmarkCapability } from './capability/bookmark-capability.enum';
import { CourseCapability } from './capability/course-capability.enum';
import { CoursePlanCapability } from './capability/course-plan-capability.enum';
import { CustomerCapability } from './capability/customer-capability.enum';
import { EditorTemplateCapability } from './capability/editor-template-capability.enum';
import { GeneralCapability } from './capability/general-capability.enum';
import { GroupCapability } from './capability/group-capability.enum';
import { ImportCapability } from './capability/import-capabilty.enum';
import { MaterialCapability } from './capability/material-capability.enum';
import { NoteCapability } from './capability/note-capability.enum';
import { PostCapability } from './capability/post-capability.enum';
import { ServicesAccessCapability } from './capability/services-access-capability.enum';
import { SurveyCapability } from './capability/survey-capability.enum';
import { TestCapability } from './capability/test-capability.enum';
import { FolderCapability } from './capability/folder-capability.enum.component';
import { CustomMaterialCapability } from './capability/custom-material-capability.enum';
import { AccountCapability } from './capability/account-capability.enum';

export class Capabilities {

    public static BookmarkCapability = BookmarkCapability;
    public static CourseCapability = CourseCapability;
    public static CustomerCapability = CustomerCapability;
    public static EditorTemplateCapability = EditorTemplateCapability;
    public static GeneralCapability = GeneralCapability;
    public static GroupCapability = GroupCapability;
    public static AccountCapability = AccountCapability;
    public static NoteCapability = NoteCapability;
    public static TestCapability = TestCapability;
    public static PostCapability = PostCapability;
    public static ImportCapability = ImportCapability;
    public static SurveyCapability = SurveyCapability;
    public static CoursePlanCapability = CoursePlanCapability;
    public static MaterialCapability = MaterialCapability;
    public static FolderCapability = FolderCapability;
    public static ServicesAccessCapability = ServicesAccessCapability;
    public static CustomMaterialCapability = CustomMaterialCapability;


    public static enums = [
        BookmarkCapability,
        CourseCapability,
        CustomerCapability,
        EditorTemplateCapability,
        GeneralCapability,
        GroupCapability,
        AccountCapability,
        NoteCapability,
        TestCapability,
        PostCapability,
        ImportCapability,
        SurveyCapability,
        CoursePlanCapability,
        MaterialCapability,
        ServicesAccessCapability,
        FolderCapability,
        CustomMaterialCapability
    ];

    public static getType(name): any {
        for (const item of this.enums) {
            if (item.getName() === name) {
                return item;
            }
        }

        return null;
    }
}
