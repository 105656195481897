import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-inputs-item',
    templateUrl: './inputs-item.component.html',
    styleUrls: ['./inputs-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputsItemComponent implements OnInit {
    @Input() data: string;
    @Input() index: number;
    public showInput: boolean;
    public control: FormControl = new FormControl('');
    @Output() onUpdateItem: EventEmitter<{ data: string; index: number }> = new EventEmitter();
    @Output() onRemoveItem: EventEmitter<number> = new EventEmitter();
    constructor() {}

    ngOnInit(): void {}
    public edit(data: string) {
        this.control.setValue(data);
        // this.cdr.markForCheck();
    }
    public commitEditing(index: number, data: string) {
        this.control.setValue(null);
    }
}
