import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-vtq-sliders',
    templateUrl: './vtq-sliders.component.html',
    styleUrls: ['./vtq-sliders.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VtqSlidersComponent implements OnInit, OnDestroy, AfterViewInit {
    public form: FormGroup;
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Input() hasCheckBox: boolean;
    @Output() valuesChange: EventEmitter<{
        question_count: number;
        difficulty_level: number;
        create_course: boolean;
        question_type_value: any;
    }> = new EventEmitter<{
        question_count: number;
        difficulty_level: number;
        create_course: boolean;
        question_type_value: any;
    }>();
    @Output() onCheckBoxChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(protected fb: FormBuilder) {
        this.form = fb.group({
            question_count: [10],
            difficulty_level: [3],
            create_course: [false],
            question_type_value: this.fb.group(
                {
                    radio: [true],
                    checkbox: [true],
                    input: [true],
                },
                { validators: this.requireCheckboxesToBeChecked }
            ),
        });
    }
    requireCheckboxesToBeChecked(group: FormGroup) {
        const checked = Object.keys(group.controls).some((key) => group.get(key).value);
        return checked ? null : { requireCheckboxesToBeChecked: true };
    }

    ngOnInit(): void {
        this.form.valueChanges
            .pipe(takeUntil(this.destroy))
            .subscribe((val) => this.valuesChange.emit(this.updateEmittedValue(val)));
    }
    ngAfterViewInit(): void {
        this.valuesChange.emit(this.updateEmittedValue(this.form.value));
    }
    updateEmittedValue(value: any) {
        const selectedQsTypes = [];
        Object.keys(this.form.value.question_type_value).forEach((key) => {
            if (this.form.get('question_type_value').get(key).value) {
                selectedQsTypes.push(key);
            }
        });
        return { ...value, ...{ question_type_value: selectedQsTypes.join(',') } };
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
