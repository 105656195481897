import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Reminder, ReminderData } from './reminder.model';
import { map } from 'rxjs/operators';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http';

@Injectable({
    providedIn: 'root',
})
export class ReminderService {
    protected resourceUrl: string;

    constructor(protected config: ConfigService, protected builder: RequestBuilderService) {
        this.resourceUrl = `${this.config.config.apiUrl}reminder-messages`;
    }

    /**
     * Get reminders belonging to a specific resource.
     */
    public getResourceReminders(resource_id: number): Observable<Reminder[]> {
        return this.builder
            .get<ReminderData[]>(this.resourceUrl)
            .param('resource', resource_id.toString())
            .request()
            .pipe(map(res => this.mapReminder(res.data)));
    }
    /**
     * Get reminders belonging to a specific resource.
     */
    public getAssignedReminders(assign_id: number): Observable<Reminder[]> {
        return this.builder
            .get<ReminderData[]>(this.resourceUrl)
            .param('assign', assign_id)
            .request()
            .pipe(map(res => this.mapReminder(res.data)));
    }

    /**
     * Create/Store a new reminder with dates array..
     */
    public store(reminder: ReminderData): Observable<Reminder[]> {
        return this.builder
            .post<ReminderData[]>(this.resourceUrl, reminder)
            .request()
            .pipe(map(res => res.data.map(r => this.createReminder(r))));
    }

    public destroy(reminder: Reminder): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${reminder.id}`)
            .request()
            .pipe(map(res => res.data));
    }

    /**
     * Map results to reminders.
     */
    protected mapReminder(data: ReminderData[]): Reminder[] {
        return data.map(item => this.createReminder(item));
    }

    /**
     * Create a reminder object from store data.
     */
    protected createReminder(data: ReminderData): Reminder {
        return new Reminder(data);
    }
}
