import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DirectionalityService {
    constructor(@Inject(LOCALE_ID) protected localeId: string) {}

    get value(): 'rtl' | 'ltr' {
        return this.localeId === 'ar' ? 'rtl' : 'ltr';
    }
}
