<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon class="icon">category</mat-icon>
        <div fxLayoutAlign="start center" fxLayoutGap="3px">
            <span class="break">Related resources</span>
        </div>
    </div>
    <div class="wrapper" fxLayout="column" *ngIf="navData?.data?.resources?.length">
        <app-resource-title-with-icon
            *ngFor="let res of navData.data.resources"
            (click)="onResClic(res)"
            [displayLabel]="false"
            [resource]="res"
        ></app-resource-title-with-icon>
    </div>
    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button fxFlex="0 0 auto" class="mobile-button positive" (click)="onClose.emit()" mat-raised-button i18n>
            Ok
        </button>
    </div>
</div>
<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <mat-spinner fxFlex="0 0 auto"></mat-spinner>
    </div>
</ng-template>
