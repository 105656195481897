<div class="search-part-wrapper" @enterLeave style="background-image: url('assets/images/dashboard-backgroud-min.jpg')">
    <div class="layer-search"></div>
    <div fxLayoutAlign="center center" fxLayoutAlign.lt-md="space-evenly center" class="middle-part">
        <div fxHide.lt-md="true" fxFlex="1 1 30%"></div>
        <div class="search-box" fxFlex="1 1 40%" fxFlex.lt-md="1 1 80%" fxLayout="column" fxLayoutAlign="center center">
            <app-search (searchSubmitted)="showFooter = !showFooter"></app-search>
        </div>
        <div class="right-block">
            <span (click)="showCatalog()" *ngIf="!(isMobile | async)" i18n>Browse Catalog</span>
            <mat-icon class="white" (click)="showCatalog()" *ngIf="isMobile | async">library_books</mat-icon>
            <button mat-icon-button (click)="dismissSearchArea.emit(true)">
                <mat-icon>highlight_off</mat-icon>
            </button>
        </div>
    </div>
</div>
<!-- <div class="search-result">
    <router-outlet></router-outlet>
</div> -->
