import { Injectable } from '@angular/core';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { MsgCase, MsgCaseData, NotificationService } from '@klickdata/core/notification';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class UsersCasesService extends NotificationService implements TableHttpService<MsgCase> {
    public fetchData(source: TableSource<MsgCase>): Observable<ResponseData<MsgCase[]>> {
        const req = this.builder.get<MsgCaseData[]>(this.casesUrl);
        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<MsgCaseData[]>) => {
                return {
                    data: res.data.map((data: MsgCaseData) => {
                        if (data.status) {
                            data.last_status = data.status[data.status.length - 1];
                        }
                        return <MsgCase>data;
                    }),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }
}
