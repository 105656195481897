import { Pipe, PipeTransform } from '@angular/core';
import { Resource } from '@klickdata/core/resource';

@Pipe({
    name: 'resourceCertificate',
})
export class ResourceCertificatePipe implements PipeTransform {
    transform(res: Resource): { certificate: string; color: string; icon: string } {
        if (res.examination) {
            switch (res.examination.type) {
                case 'Diploma':
                    return { certificate: $localize`Diploma`, color: '#3e5365', icon: 'military_tech' };
                case 'Certification':
                    return { certificate: $localize`Certification`, color: '#3e5365', icon: 'local_police' };
                default:
                    return { certificate: $localize`Participation`, color: '#3e5365', icon: 'school' };
            }
        } else {
            return { certificate: $localize`Participation`, color: '#3e5365', icon: 'school' };
        }
    }
}
