import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FieldType } from '@klickdata/core/custom-field';
import { FormHelper } from '@klickdata/core/form';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Utils } from '@klickdata/core/util';

@Component({
    selector: 'app-menu-side-form-builder',
    templateUrl: './menu-side-form-builder.component.html',
    styleUrls: ['./menu-side-form-builder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideFormBuilderComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    FieldType = FieldType;
    Utils: Utils;
    public form: FormGroup;
    constructor(protected fb: FormBuilder, protected cdr: ChangeDetectorRef) {
        this.form = this.fb.group({
            name: ['', Validators.required],
            placeholder: [''],
            initial_value: [''],
            order: [1],
            type: [FieldType.TEXT_INPUT, Validators.required],
            disabled: [true],
            options: this.fb.array([this.fb.control('')]),
        });
    }
    ngOnInit(): void {
        if (this.navData?.data?.field) {
            this.form.patchValue(this.navData.data.field);
            this.patchOptions();
        }
    }
    private patchOptions() {
        if (!!this.navData.data.field.options?.length) {
            this.optionsFormArray.controls.length = 0;
            this.navData.data.field.options.forEach((option) => this.optionsFormArray.push(this.fb.control(option)));
        }
    }
    get optionsFormArray(): FormArray {
        return <FormArray>this.form.get('options');
    }

    get formBuilderItemTypes(): FieldType[] {
        return [
            FieldType.TEXT_INPUT,
            FieldType.DATE,
            FieldType.DROPDOWN,
            FieldType.MULTIPLE_CHOICE,
            FieldType.SINGLE_CHOICE,
            FieldType.SWITCH,
        ];
    }
    removeOption(index: number) {
        this.optionsFormArray.removeAt(index);
        FormHelper.markForm(this.form);
    }

    addNewOption() {
        this.optionsFormArray.push(this.fb.control(''));
        this.form.markAsDirty();
        this.cdr.markForCheck();
    }

    onSubmit() {
        const data = this.form.value;
        data.options = Array.from(new Set(data.options.filter((item) => item.trim() !== '')));
        if (!data.options.length) {
            delete data.options;
        }
        data.disabled = !this.form.value.disabled;
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: this.form.value,
        });
        this.form.reset();
    }
}
