import { StepperSelectionEvent } from '@angular/cdk/stepper';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { FormHelper } from '@klickdata/core/form';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-event-reg',
    templateUrl: './menu-side-event-reg.component.html',
    styleUrls: ['./menu-side-event-reg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideEventRegComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    @ViewChild('stepper') stepper: MatStepper;
    SideNaveActionsTypes = SideNaveActionsTypes;
    public registrationForm: FormGroup;
    public formats: { [key: string]: string };
    private destroy: Subject<boolean> = new Subject<boolean>();
    ResourceTypes = ResourceTypes;
    public displayedTitle = $localize`Registration`;
    public displayedIcon = 'receipt';

    constructor(
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected message: MessageService,
        protected cdr: ChangeDetectorRef
    ) {
        this.buildForm();
        this.formats = {
            fname: $localize`First name`,
            lname: $localize`Last name`,
            email: $localize`Email`,
            box: $localize`Your address box`,
        };
    }
    ngOnInit(): void {
        this.registrationForm
            .get('ticketsForm')
            .get('ticketsAmount')
            .valueChanges.pipe(
                takeUntil(this.destroy),
                filter((value) => value > 0)
            )
            .subscribe((value) => {
                this.personalInfo.clear();
                for (let i = 1; i <= value; i++) {
                    this.personalInfo.push(this.addUserInfoGroup());
                }
            });
    }

    ngAfterViewInit(): void {}
    private buildForm() {
        this.registrationForm = this.fb.group({
            policy_agree: ['', [Validators.required]],

            personalRegForm: this.fb.group({
                personalInfo: this.fb.array([
                    this.fb.group({
                        fname: ['', [Validators.required]],
                        lname: ['', [Validators.required]],
                        email: ['', [Validators.email, Validators.required]],
                    }),
                ]),
                update: [true],
                events: [true],
                terms: [true],
            }),
            ticketsForm: this.fb.group({
                ticketsAmount: [1, [Validators.required, Validators.min(1)]],
                voucher: [''],
            }),
            paymentForm: this.fb.group({
                payment_method: ['card'],
                card_number: [''],
                exp_month: [''],
                cvv: [''],
                exp_year: [''],
            }),

            invoiceRegForm: this.fb.group({
                business_company: [''],
                department: [''],
                orgnumber: [''],
                person_role: [''],
                purchase_order: [''],
                cost_department: [''],
                campaign_code: [''],
                address: this.fb.group({
                    box: [''],
                    street: [''],
                    zipcode: [''],
                    city: [''],
                    country: [''],
                    other: [''],
                }),
            }),
        });
    }
    get personalInfo(): FormArray {
        return this.registrationForm.get('personalRegForm').get('personalInfo') as FormArray;
    }
    public addUserInfoGroup() {
        return this.fb.group({
            fname: ['', [Validators.required]],
            lname: ['', [Validators.required]],
            email: ['', [Validators.email, Validators.required]],
        });
    }
    public register() {
        if (this.registrationForm.invalid) {
            this.message.openMessage(
                MessageErrorComponent,
                $localize`${FormHelper.formatInvalidForm(this.registrationForm, this.formats)} is missing`
            );
            return;
        }

        this.resourceService
            .registerResource(this.prepareData())
            .pipe(
                map((regData) => regData.id),
                tap((regId) => localStorage.setItem('registrationId', regId)),
                switchMap((regId) => this.resourceService.bookResource(this.navData.data.resource.id, regId)),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                this.message.openMessage(
                    MessageSavedComponent,
                    $localize`Congratulations, you have registered successfully.`
                );
                this.onClose.emit({ action: SideNaveActionsTypes.POSITIVE, clearCompData: false });
                this.destroy.next(true);
                this.destroy.unsubscribe();
            });
    }
    private prepareData() {
        const data = this.registrationForm.value;
        data.email = this.registrationForm.value.email.trim();
        data.address = this.filterEmptyFields(this.registrationForm.get('address').value);
        return this.filterEmptyFields(data);
    }
    filterEmptyFields(data: any): any {
        let fields = {};
        Object.keys(data).forEach((key) => (data[key] != '' ? (fields[key] = data[key]) : key));

        return fields;
    }
    public readMoreLink() {
        window.open('https://klickdata.se/faq-klms/legal-links-for-klick-data', '_blank');
    }
    public getCurrenySign(value): string {
        return `${Utils.getAllCurrencies().find((curr) => curr.code === value).label} ${
            Utils.getAllCurrencies().find((curr) => curr.code === value).sign
        }`;
    }
    public editStep() {
        this.stepper.selectedIndex = 1;
    }
    public getDisplayedTitle(stepperEvent: StepperSelectionEvent) {
        switch (stepperEvent.selectedIndex) {
            case 0:
                this.displayedTitle = $localize`Registration`;
                this.displayedIcon = 'receipt';
                break;
            case 1:
                this.displayedTitle = $localize`Participants`;
                this.displayedIcon = 'calendar_month';
                break;
            case 2:
                this.displayedTitle = $localize`Payment`;
                this.displayedIcon = 'credit_card';
                break;
        }

        this.cdr.markForCheck();
    }
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
