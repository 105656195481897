<div fxLayoutGap="4px" class="input-wrapper default-select" fxLayout="column">
    <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-md="10px">
        <mat-form-field class="small-field users-rec-input" appearance="fill">
            <input
                type="text"
                matInput
                #managersInput
                i18n-placeholder
                [formControl]="managerCtrl"
                [matAutocomplete]="autoManager"
            />
            <mat-autocomplete
                panelWidth="200px"
                class="manager-autocomplete"
                #autoManager="matAutocomplete"
                [displayWith]="displaySign"
                (optionSelected)="selectedSignature($event)"
            >
                <mat-option *ngFor="let manager of managers$ | async" [value]="manager">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span>{{ manager.signature }} - {{ manager.name }}</span>
                    </div>
                </mat-option>
            </mat-autocomplete>
            <button
                *ngIf="wrongSignature"
                mat-icon-button
                class="cancel-signature"
                matTooltip="Invalid signature"
                i18n-matTooltip
                matSuffix
                (click)="managerCtrl.patchValue('', { emitEvent: false }); wrongSignature = !wrongSignature"
            >
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-form-field>
    </div>
</div>
