import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { LanguageService } from '@klickdata/core/localization';
import { MessageService } from '@klickdata/core/message';
import { ResourceService, ResourceTypes, ResourceSocketService } from '@klickdata/core/resource';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
declare var webkitSpeechRecognition;

@Component({
    selector: 'app-ai-prompter-manager',
    templateUrl: './ai-prompter-manager.component.html',
    styleUrls: ['./ai-prompter-manager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiPrompterManagerComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() model_name: string; // llm name
    @Input() resource: Resource;
    @Input() placeholder = $localize`Write a prompter text to ask AI anything`;
    @Output() onListMaterial: EventEmitter<any> = new EventEmitter();
    @Output() onMaterialCreated: EventEmitter<Resource> = new EventEmitter();
    @Output() onMaterialEdited: EventEmitter<Resource> = new EventEmitter();
    @ViewChild('input') input: ElementRef<HTMLInputElement>;
    loading = false;
    public prompterLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showTags = false;
    showQuiz = false;
    ResourceTypes = ResourceTypes;
    promptForm: FormGroup;
    public currentLaguageId$: Observable<number>;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public currentResource$: BehaviorSubject<Resource> = new BehaviorSubject(null);
    public vtqData: { question_count: number; difficulty_level: number; create_course: boolean };

    constructor(
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected resourceWS: ResourceSocketService,
        protected message: MessageService,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        protected langService: LanguageService,
        protected auth: AuthService
    ) {
        this.promptForm = this.fb.group({
            prompt: null,
            type_id: [ResourceTypes.TextMaterial],
            category_ids: [[135]],
            tag_ids: [[]],
        });
    }

    ngOnInit(): void {
        this.listenToNewResource();
        this.currentLaguageId$ = this.langService
            .getLanguageByKey(this.langService.getCurrentLanguage().value)
            .pipe(map((lang) => lang.id));
        this.promptForm.valueChanges.subscribe(
            (text) =>
                (this.resourceService.lastPrompterValues = {
                    tag_ids: this.promptForm.value.tag_ids,
                    prompt: this.promptForm.value.prompt,
                })
        );
        this.promptForm.patchValue({
            prompt: this.resourceService.lastPrompterValues.prompt,
            tag_ids: this.resourceService.lastPrompterValues.tag_ids,
        });
    }
    ngAfterViewInit(): void {
        setTimeout(() => {
            this.input?.nativeElement?.focus();
        }, 1000);
    }
    getSubmitBtnTooltip(): string {
        return this.promptForm.value.prompt
            ? $localize`Send your request to AI and wait for the View to lid to read the reply`
            : $localize`Create Material with AI`;
    }
    addMaterial() {
        this.loading = true;
        this.createPromptObservable()
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => {
                this.showQuiz = false;
                this.vtqData = null;
                this.loading = false;
                this.onMaterialCreated.emit(Array.isArray(resource) ? resource[0] : resource);
            });
        this.cleanInput();
    }
    private createPromptObservable() {
        return this.resourceService.prompter(
            this.showQuiz && !!this.vtqData
                ? { ...this.promptForm.value, ai_metadata: { ...this.vtqData, model_name: this.model_name } }
                : { ...this.promptForm.value, model_name: this.model_name },
            this.resource?.id
        );
    }
    public listingMaterial() {
        this.onListMaterial.emit();
        this.router.navigate(
            [
                this.auth.getNK3PlatformValue() === 'user' || this.auth.getNK3PlatformValue() === 'guest'
                    ? '/home/manage/material/list'
                    : '/admin/content/materials/list',
            ],
            {
                queryParams: {
                    bypassGuardWithoutSaving: true,
                },
            }
        );
    }
    private cleanInput() {
        const promptControl = <FormControl>this.promptForm.get('prompt');
        promptControl.setValue('');
        this.resourceService.lastPrompterValues.prompt = '';
        FormHelper.resetForm(promptControl);
        setTimeout(() => {
            this.input?.nativeElement?.focus();
        }, 1000);
    }
    // startListing(){
    //     if ('webkitSpeechRecognition' in window) {
    //         const vSearch = new webkitSpeechRecognition();
    //         vSearch.continuous = false;
    //         vSearch.interimresults = false;
    //         vSearch.lang = 'en-US';
    //         vSearch.start();
    //         vSearch.onresult = (e) => {
    //           console.log(e);
    //           // voiceHandler.value = e?.results[0][0]?.transcript;
    //           this.results = e.results[0][0].transcript;
    //         //   this.getResult();
    //           console.log(this.results);
    //           vSearch.stop();
    //         };
    //       } else {
    //         alert('Your browser does not support voice recognition!');
    //       }
    // }
    public listenToNewResource() {
        return this.resourceWS
            .listenToNewResource()
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => {
                this.prompterLoading.next(false);
                this.currentResource$.next(resource);
            });
    }

    editMaterial() {
        this.onMaterialEdited.emit(this.currentResource$.value);
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
