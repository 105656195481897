import { Directive, Input, ViewContainerRef } from '@angular/core';
import { HasOne } from './has-one';
import { AbstractForm } from '../abstract-form';
import { IModel } from '@klickdata/core/application';

@Directive({
    selector: '[appHasOne]',
})
export class HasOneDirective {
    protected _relation: HasOne<AbstractForm<IModel>>;
    protected _initialized = false;

    constructor(
        protected view: ViewContainerRef,
    ) {
    }

    @Input() set appHasOne(relation: HasOne<AbstractForm<IModel>>) {
        this._relation = relation;

        if (this._relation) {
            this.init();
        }
    }

    /**
     * Initialize relationship.
     */
    protected init(): void {
        if (this._initialized) {
            return;
        }
        this._initialized = true;

        const childForm = this.view.injector.get(this._relation.childClass);
        this._relation.attach(childForm);
    }
}
