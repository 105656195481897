<div *ngIf="!inGlobalFilter; else globalFilterTemplate" fxLayout="row" fxLayoutAlign="center center">
    <app-language-selector
        [globeDefault]="true"
        [prefLangIds]="prefLangIds"
        [globe]="true"
        [formControl]="languageControl"
    ></app-language-selector>
</div>

<ng-template #globalFilterTemplate>
    <div class="global-filter-lang" fxLayout="row" fxLayoutAlign="start center">
        <span i18n="@@selectLanguage">Select Language</span>
        <ng-container
            *ngIf="prefLangIds; else customerPreLangIds"
            [ngTemplateOutlet]="langSelector"
            [ngTemplateOutletContext]="{ prefLangIds: prefLangIds }"
        >
        </ng-container>
    </div>
</ng-template>

<ng-template #activeFilterTemplate let-filter="filter">
    <app-language-selector class="flag" fxFlex="0 0 auto" [language_id]="filter.items[0]"></app-language-selector>
</ng-template>
<ng-template #customerPreLangIds>
    <ng-container
        *ngIf="customer$ | async; let customer"
        [ngTemplateOutlet]="langSelector"
        [ngTemplateOutletContext]="{ prefLangIds: customer.pref_lang_ids }"
    >
    </ng-container>
</ng-template>
<ng-template #langSelector let-prefLangIds="prefLangIds">
    <app-language-selector
        [globeDefault]="true"
        [globe]="true"
        [formControl]="languageControl"
        [prefLangIds]="prefLangIds"
        [disableRipple]="true"
    ></app-language-selector>
</ng-template>
