/**
 * Register model for trial accounts.
 */
export class Register {
    email: string;
    username: string;
    fname: string;
    lname: string;
    organisation: string;
    password: string;
    password_confirmation: string;
    phone: string;

    subscription_plan_id: number;
}
