import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { GroupService } from '@klickdata/core/group';
import { UserService } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { Observable, takeUntil } from 'rxjs';

@Component({
    selector: 'app-users-groups-access-control-sheet',
    templateUrl: './users-groups-access-control-sheet.component.html',
    styleUrls: ['./users-groups-access-control-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersGroupsAccessControlSheetComponent extends OnDestroyHandler {
    public saving: boolean;
    public groupControl = new FormArray([]);
    public userControl = new FormArray([]);
    constructor(
        public bottomSheetRef: MatBottomSheetRef<UsersGroupsAccessControlSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected userService: UserService,
        protected groupService: GroupService
    ) {
        super();
    }

    submit() {
        if (!this.groupControl.value?.length && !this.userControl.value?.length) return;
        this.saving = true;
        let data: Observable<any> = this.data.group
            ? this.groupService.update({
                  id: this.data.group.id,
                  resource_permissions: this.groupControl.value,
              })
            : this.userService.update({
                  id: this.data.user.id,
                  resource_permissions: this.userControl.value,
              });
        data.pipe(takeUntil(this.destroy)).subscribe((res) => {
            this.saving = false;
            this.bottomSheetRef.dismiss(res);
        });
    }
    private getDirtyValues(formArray: FormArray) {
        const values = [];
        formArray.controls.forEach((control: FormGroup) => {
            const dirtyPerms = (control.get('permissions') as FormArray).controls.filter((perm) => perm.dirty);
            values.push({ id: control.value.id, permissions: dirtyPerms.map((perm) => perm.value) });
        });
        return values;
    }
}
