<div [formGroup]="itemGroup" fxLayout="row wrap" (keydown.enter)="$event.preventDefault()">
    <!-- displaying Qs image before the question -->
    <div xFlex="1 0 100%" class="imag-view-wrapper res-item-form" fxLayout="column" fxLayoutAlign="center">
        <app-image-view
            fxFlex="0 1 100%"
            [proportional]="false"
            *ngIf="
                itemGroup.get('question').value['media_id'] ||
                itemGroup.get('question').value['img_url'] ||
                item?.media?.url
            "
            [media_id]="
                itemGroup.get('question').value['media_id'] ||
                itemGroup.get('question').value['img_url'] ||
                item?.media?.url
            "
        >
            <button
                *ngIf="
                    editable &&
                    (itemGroup.get('question').value['media_id'] ||
                        itemGroup.get('question').value['img_url'] ||
                        item?.media?.url)
                "
                class="remove-question-image"
                mat-icon-button
                color="warn"
                matTooltip="Remove image"
                i18n-matTooltip="Remove question image"
                (click)="removeImage()"
            >
                <mat-icon class="md-24">delete_outline</mat-icon>
            </button>
        </app-image-view>
    </div>
    <div fxFlex="1 1 100%" fxLayoutAlign="start start">
        <mat-form-field fxFlex="0 1 100%">
            <textarea
                #title
                matInput
                type="text"
                formControlName="name"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="10"
                required
                [maxLength]="256"
                placeholder="Overview"
                i18n-placeholder="@@question"
            >
            </textarea>
            <span *ngIf="title.value" class="char-count">
                {{ title.value.length }}
            </span>

            <mat-error i18n>This field is required!</mat-error>
        </mat-form-field>
        <div
            fxLayoutAlign="center center"
            fxHide="true"
            *ngIf="
                questionLoaded && !itemGroup.get('question.media_id').value && !itemGroup.get('question.img_url').value
            "
            fxFlex="0 0 40px"
            class="coursePlan-layout"
        >
            <app-media
                #app_media
                fxFlex="0 0 auto"
                [simple]="true"
                [formControl]="
                    (itemGroup.get('question.media_id') | cast) || (itemGroup.get('question.img_url') | cast)
                "
                [scope]="AppScope.QUESTIONS"
                dropLabel="Drop question image here"
                i18n-dropLabel="Drop for question images@@questionImageDnD"
                dropLabel="Drop question image"
                i18n-dropLabel="@@questionImageDropzoneLabel"
            ></app-media>
        </div>
        <div
            class="toggle-button-wrapper"
            fxFlex="0 0 auto"
            *ngIf="questionLoaded"
            [ngClass]="{
                'has-explain':
                    itemGroup.get('question').value.description ||
                    itemGroup.get('question').value.learning_field ||
                    itemGroup.get('question').value.references
            }"
        >
            <button mat-button (click)="getQsExtraInfo(itemGroup.get('question'))">
                <div class="indicator"></div>
                <mat-icon>feed</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayoutGap="5px" fxFlex="grow" fxLayout="column">
        <div class="whole-wrap" *ngIf="itemGroup.get('question').value.description">
            <div i18n>Description:</div>
            <span [innerHtml]="itemGroup.get('question').value.description"></span>
        </div>
        <div class="whole-wrap" *ngIf="itemGroup.get('question').value.learning_field">
            <div i18n>Learning Field:</div>
            <span [innerHtml]="itemGroup.get('question').value.learning_field"></span>
        </div>
        <div class="whole-wrap" *ngIf="itemGroup.get('question').value.references">
            <div i18n>References:</div>
            <span [innerHtml]="itemGroup.get('question').value.references"></span>
        </div>
    </div>

    <app-resource-builder-question
        fxFlex="1 1 100%"
        [group]="itemGroup.get('question') | cast"
        [item]="item"
        [editable]="editable"
        [usePoints]="usePoints"
    ></app-resource-builder-question>
</div>
