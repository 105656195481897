import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@klickdata/core/auth';
import { ConfigModule } from '@klickdata/core/config';
import { CustomerModule } from '@klickdata/core/customer';
import { GroupModule } from '@klickdata/core/group';
import { HttpModule } from '@klickdata/core/http';
import { LocalizationModule } from '@klickdata/core/localization';
import { MediaModule } from '@klickdata/core/media';
import { PostDetailComponent } from '@klickdata/core/post/src/post/post-detail/post-detail.component';
import { PostPageContentComponent } from '@klickdata/core/post/src/post/post-page/post-page-content/post-page-content.component';
import { PostPageComponent } from '@klickdata/core/post/src/post/post-page/post-page.component';
import { PostPanelComponent } from '@klickdata/core/post/src/post/post-panel/post-panel.component';
import { PostComponent } from '@klickdata/core/post/src/post/post.component';
import { UserModule } from '@klickdata/core/user';
import { KdLoaderModule } from '@klickdata/kd-loader';
import { MaterialModule } from '@klickdata/material';
import { PipesModule } from 'apps/klickdata/src/app/shared/pipes/pipes.module';
import { MomentModule } from 'ngx-moment';
import { PostItemComponent } from './post/post-detail/post-item/post-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from '@klickdata/core/table';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MomentModule,
        PipesModule,
        AuthModule,
        HttpModule,
        ConfigModule,
        GroupModule,
        UserModule,
        CustomerModule,
        GroupModule,
        MediaModule,
        FlexLayoutModule,
        MaterialModule,
        LocalizationModule,
        KdLoaderModule,
        ReactiveFormsModule,
        TableModule,
    ],
    declarations: [
        PostComponent,
        PostPanelComponent,
        PostPageComponent,
        PostDetailComponent,
        PostPageContentComponent,
        PostItemComponent,
    ],
    exports: [PostComponent, PostDetailComponent, PostPanelComponent, PostItemComponent],
})
export class PostModule {}
