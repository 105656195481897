<div fxLayout="column" fxFlex="0 0 100%" class="hr-notes-wrapper">
    <app-table-filter [filtersTogglesInOnBtn]="true" [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search
            toggleBtnLabel="File"
            i18n-toggleBtnLabel
            [inGlobalFilter]="true"
            class="align-space"
            searchFilterRef
        ></app-table-search>
    </app-table-filter>

    <!-- <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar> -->
    <mat-table #table [dataSource]="dataSource" matSortActive="created_at" matSortDirection="desc" matSort>
        <ng-container matColumnDef="thumbnil">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Image</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let media">
                <span class="mobile-label" i18n>Image:</span>
                <app-image-view
                    [smallSpinner]="true"
                    [media_id]="media.thumbnail ?? media.url"
                    [proportional]="true"
                ></app-image-view>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="filename">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Name</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let media">
                <span class="mobile-label" i18n>Name:</span>
                <span class="notes">{{ media.filename }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Created at</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let media"> <span>25-11-2023</span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@description">Description</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let media"> <span>This is file description</span></mat-cell>
        </ng-container>
        <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Tags</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let media">
                <span *ngIf="isMobile" class="mobile-label" i18n> Tags </span>
                <!-- <app-table-tags-on-the-fly
                    (click)="$event.stopPropagation()"
                    [tags]="note.tags"
                    (onUpdateTags)="note?.id && updateMediaTags(note, $event)"
                    (onTagClick)="onTagClick($event)"
                ></app-table-tags-on-the-fly> -->
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="author">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Created by</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let media">
                <span class="mobile-label" i18n>Creator:</span>
                <span>Erik Bolinder</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Id</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let media">
                <span class="mobile-label" i18n>Id:</span>
                <span>{{ media.id }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="btns-actions" *matCellDef="let media">
                <button
                    (click)="$event.stopPropagation(); download(media.id)"
                    matTooltip="Downlaod"
                    mat-icon-button
                    i18n-matTooltip="@@viewAttachment"
                    class="comment-add"
                >
                    <mat-icon>file_download</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); view(media)"
                    matTooltip="View"
                    mat-icon-button
                    i18n-matTooltip="@@view"
                >
                    <mat-icon>visibility</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); edit(media.id)"
                    matTooltip="Edit"
                    mat-icon-button
                    i18n-matTooltip="@@edit"
                    class="comment-add"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); delete(media.id)"
                    matTooltip="Delete"
                    mat-icon-button
                    i18n-matTooltip="@@delete"
                    class="comment-add"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="min_tools">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="btns-actions" *matCellDef="let media">
                <button
                    (click)="$event.stopPropagation(); view(media)"
                    matTooltip="View"
                    mat-icon-button
                    i18n-matTooltip="@@view"
                >
                    <mat-icon>visibility</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let media; columns: columns"
            class="example-element-row"
            (click)="onMediaClick.emit(media)"
        ></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <mat-spinner fxFlex="0 0 auto"></mat-spinner>
    </div>
</ng-template>
