import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Quote } from '@klickdata/core/quote';
import { Utils } from '@klickdata/core/util';

@Component({
    selector: 'app-quotes-slider',
    templateUrl: './quotes-slider.component.html',
    styleUrls: ['./quotes-slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotesSliderComponent implements OnInit {
    @Input() quotes: Quote[];
    @Input() scrolledItemWidth = 355;

    customOptions = Utils.getOwlOptions();
    constructor() {}

    ngOnInit(): void {}
}
