import { Component, OnInit } from '@angular/core';
import { ApplicationService } from '@klickdata/core/application';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-version-alert',
    templateUrl: './version-alert.component.html',
    styleUrls: ['./version-alert.component.scss'],
})
export class VersionAlertComponent implements OnInit {
    public version$: Observable<{ version: string; date: string }>;

    constructor(protected app: ApplicationService) {
        this.version$ = app.getNewVersion();
    }

    ngOnInit() {}

    public onClick() {
        this.app.reload();
    }
}
