<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon class="{{ navData.data.iconClass }}">{{ navData.data.icon }}</mat-icon>
        <div>{{ navData.data.title }}</div>
    </div>
    <div class="body" fxLayout="column" fxLayoutAlign="start center">
        <!-- <div fxLayout="row" fxLayoutAlign="start center" *ngFor="let user of navData.data.user"></div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <span>Ahmed Samir</span>
            <span>Ahmed Samir</span>
        </div> -->

        <div class="w-100" fxLayoutAlign="start center" fxLayout="column" fxLayoutGap="5px">
            <div *ngIf="navData.data.users.length > 1" class="toggle-all-wrapper" fxLayoutAlign="space-between center">
                <div fxLayoutAlign="start center" fxLayout="row wrap" fxLayoutGap="4px">
                    <mat-icon>groups</mat-icon> <span>Group</span> <span>({{ navData.data.users.length }})</span>
                </div>
                <button
                    mat-icon-button
                    type="button"
                    color="primary"
                    fxFlex="0 0 auto"
                    matTooltip="Open All"
                    i18n-matTooltip="@@openAll"
                    (click)="expandAll = !expandAll"
                >
                    <mat-icon class="expanding" [class.expanded-all]="expandAll">first_page</mat-icon>
                </button>
            </div>
            <mat-accordion [multi]="true" class="rec-accordion" fxFlex="0 0 100%" displayMode="flat">
                <mat-expansion-panel
                    [expanded]="expandAll"
                    #statusExpansion
                    class="{{ user.status[user.status.length - 1].type }}"
                    *ngFor="let user of navData.data.users; let last = last"
                    [class.status-expanded]="statusExpansion.expanded"
                >
                    <mat-expansion-panel-header class="user-case-header">
                        <div class="grey" fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row wrap">
                            <mat-icon>person</mat-icon>
                            <span>{{ user.fname }}</span> <span>{{ user.lname }}</span>
                        </div></mat-expansion-panel-header
                    >
                    <div
                        *ngFor="let status of user.status; let last = last; let first = first"
                        fxLayout="column"
                        fxLayoutGap="10px"
                    >
                        <div fxLayout="column" fxLayoutGap="10px">
                            <div
                                class="info-wrapper"
                                fxLayoutAlign="start center"
                                fxLayout="row wrap"
                                fxLayoutGap="4px"
                            >
                                <mat-icon>{{ (status.type | caseStatus).icon }}</mat-icon>
                                <span i18n>Case</span>
                                <span class="orange">{{ (status.type | caseStatus).title }}</span>
                                <span *ngIf="localeId === 'en'" i18n>on</span>
                                <span class="fxgrow-1">{{ status.date | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
                                <button
                                    [disableRipple]="true"
                                    [class.expanded]="adminExpansionPanel?.expanded"
                                    class="comment-toggle"
                                    *ngIf="status.comment"
                                    mat-icon-button
                                    (click)="adminExpansionPanel?.toggle()"
                                >
                                    <mat-icon>chat_bubble</mat-icon>
                                </button>
                            </div>
                            <mat-accordion
                                class="expansion-aa"
                                [fxHide]="!adminExpansionPanel.expanded"
                                fxFlex="0 0 100%"
                                displayMode="flat"
                            >
                                <mat-expansion-panel #adminExpansionPanel>
                                    <mat-expansion-panel-header fxHide> </mat-expansion-panel-header>
                                    <ng-container
                                        [ngTemplateOutlet]="commentLabel"
                                        [ngTemplateOutletContext]="{ comment: status.comment }"
                                    ></ng-container>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            *ngIf="navData.data.positiveBtn"
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="onClose.emit({ action: SideNaveActionsTypes.POSITIVE })"
            mat-raised-button
        >
            {{ navData.data.positiveBtn }}
        </button>
        <button
            *ngIf="navData.data.negativeBtn"
            fxFlex="0 0 auto"
            class="mobile-button negative"
            (click)="onClose.emit()"
            mat-button
        >
            {{ navData.data.negativeBtn }}
        </button>
        <button
            *ngIf="navData.data.customBtn"
            fxFlex="0 0 auto"
            class="mobile-button custom"
            (click)="onClose.emit({ action: SideNaveActionsTypes.CUSTOM })"
            mat-button
        >
            {{ navData.data.customBtn }}
        </button>
    </div>
</div>
<ng-template #commentLabel let-comment="comment">
    <div class="commnt-wrap" [innerHTML]="comment"></div>
</ng-template>
