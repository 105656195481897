import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatTabGroup } from '@angular/material/tabs';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { FormHelper } from '@klickdata/core/form';
import { GlobalSearchData } from '@klickdata/core/global-search';
import { MediaType } from '@klickdata/core/media/src/media-type';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { ResourceMediaTypes } from '@klickdata/core/question/src/question-type/question-type';
import { AppScope, Resource, ResourceCategory, ResourceTag } from '@klickdata/core/resource';
import { ResourceBuildingItems, ResourceTypes } from '@klickdata/core/resource/src/types.enum';
import { EventManagerCoreComponent } from '@klickdata/shared-components/src/event-manager-core/event-manager-core.component';
import { ResourceListingSheetComponent } from '@klickdata/shared-components/src/resource-listing-sheet/resource-listing-sheet.component';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, filter, first, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-course-manager-event-create',
    templateUrl: './course-manager-event-create.component.html',
    styleUrls: ['./course-manager-event-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseManagerEventCreateComponent extends OnDestroyHandler implements OnInit, AfterViewInit {
    public resourceForm: FormGroup;
    ResourceBuildingItems = ResourceBuildingItems;
    @Output() saved: EventEmitter<{ res: Resource; isParentSubmission?: boolean }> = new EventEmitter<{
        res: Resource;
        isParentSubmission?: boolean;
    }>();
    public loadingSubmit: Subject<boolean> = new Subject<boolean>();
    public loadingUploader: Subject<boolean> = new Subject<boolean>();
    public isAIUploadedImg: Subject<boolean> = new Subject<boolean>();
    public mediaAIPrompterText: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    @ViewChild('eventlManager') eventlManager: EventManagerCoreComponent;
    @ViewChild('coverImagePanel') coverImagePanel: MatExpansionPanel;
    @ViewChild('mainTabs') mainTabs: MatTabGroup;
    AppScope = AppScope;
    public isMobile: Observable<boolean>;
    public isAdmin: boolean;
    public showCreationOption: boolean;
    public defaultResourceGoalCount: number;
    public EventIsSubmitted: boolean;
    ResourceTypes = ResourceTypes;
    private formats = {
        title: $localize`:@@title:Title`,
        agenda: $localize`:@@eventAagenda:Event agenda`,
        bullets: $localize`:@@eventSummary:Event summary`,
        language_id: $localize`:@@language:Language`,
        categories: $localize`:@@categories:Categories`,
    };
    constructor(
        protected auth: AuthService,
        protected mobile: MobileService,
        protected cdref: ChangeDetectorRef,
        protected fb: FormBuilder,
        protected mediaService: MediaService,
        protected messageService: MessageService,
        protected configService: ConfigService,
        protected dialog: MatDialog,
        protected bottomSheet: MatBottomSheet
    ) {
        super();
        this.resourceForm = this.fb.group({
            id: [],
            // priority: [],
            bullets: [],
            media_id: [],
            title: ['', Validators.required],
            category_ids: [[], Validators.required],
            tag_ids: [[]],
            description: [''],
            instructions: [''],
            language_id: ['', Validators.required],
            published: [],
        });
        this.defaultResourceGoalCount = this.configService.config.defaultResourceGoalCharCount;
    }
    ngOnInit() {
        this.isMobile = this.mobile.isMobile().pipe(shareReplay());
        this.auth
            .getUser()
            .pipe(
                first(),
                map((user) => user.isAdmin())
            )
            .subscribe((isAdmin) => {
                this.isAdmin = isAdmin;
                this.cdref.markForCheck();
            });
    }
    ngAfterViewInit(): void {
        this.resourceForm.get('agenda')?.setValidators(Validators.required);
        this.resourceForm.get('bullets')?.setValidators(Validators.required);
    }
    submitEvent() {
        this.loadingSubmit.next(true);
        if (this.resourceForm.invalid) {
            FormHelper.markForm(this.resourceForm);
            const inValidsKeys = FormHelper.formatInvalidForm(this.resourceForm, this.formats);
            this.messageService.openMessage(
                MessageErrorComponent,
                inValidsKeys.length < 2 ? $localize`${inValidsKeys} is missing` : $localize`${inValidsKeys} are missing`
            );
            this.loadingSubmit.next(false);
        } else {
            this.EventIsSubmitted = true;
            this.resourceForm.get('published').patchValue(moment().format('YYYY-MM-DD HH:mm:ss'));
            this.eventlManager.onSubmit();
        }
    }
    onSaved(ev: Resource) {
        if (this.EventIsSubmitted) {
            this.loadingSubmit.next(false);
            this.saved.emit({ res: ev });
        }
    }

    public showTagResources(tag: ResourceTag) {
        this.showResources({ id: tag.id, label: tag.name, scope_id: AppScope.TAGS });
    }
    public showCatgeoryResources(category: ResourceCategory) {
        this.showResources({ id: category.id, label: category.title, scope_id: AppScope.CATEGORIES });
    }
    private showResources(item: GlobalSearchData) {
        this.bottomSheet.open(ResourceListingSheetComponent, {
            data: {
                id: item.id,
                label: item.label,
                scope_id: item.scope_id,
            },
            panelClass: 'sheet-wrapper',
        });
    }
    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '70%',
            maxHeight: '50vh',
            data: id,
        });
    }
    public addMedia(errorMsg?: string) {
        if (this.resourceForm.value.media_id) {
            this.mainTabs.selectedIndex = 1;
            this.coverImagePanel.open();
            return;
        }
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_RESOURCE_MEDIA,
            data: {
                icon: 'add_photo_alternate',
                title: $localize`Add an image`,
                description: $localize`Please select your preferred way to add an image.`,
                type: ResourceTypes.EVENT,
                errorMsg: errorMsg,
            },
        });
        this.getMediaTypeFromSlideIn();
    }
    private getMediaTypeFromSlideIn() {
        this.mobile
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobile.getSideNavResponseData()),
                filter((data) => data.type === ResourceTypes.EVENT),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                if (data && data.value) {
                    this.resourceForm.patchValue({ media_id: data.mediaId });
                    if (data.value === ResourceMediaTypes.FILE) {
                        this.isAIUploadedImg.next(false);
                        this.mediaAIPrompterText.next(null);
                    } else {
                        this.loadingUploader.next(true);
                        this.initAIUpload(data);
                    }
                    this.resourceForm.markAsDirty();
                }
            });
    }
    private initAIUpload(data: any) {
        this.mediaService
            .uploadMediaByLink({
                prompt: data.aiText,
                type: MediaType.PROMPT,
                scope_id: ResourceTypes.scopeByChildType(ResourceTypes.EVENT),
            })
            .pipe(
                takeUntil(this.destroy),
                catchError((err) => {
                    this.handleAIRequestFailure(err);
                    return of(null);
                })
            )
            .subscribe((media) => {
                this.loadingUploader.next(false);
                if (media && media.id) {
                    this.isAIUploadedImg.next(true);
                    this.resourceForm.patchValue({ media_id: media.id });
                    this.mediaAIPrompterText.next(data.aiText);
                }
            });
    }
    handleAIRequestFailure(err) {
        if (err && err.error && err.error.error) {
            this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
            this.addMedia(err.error.error.messages.join('/n'));
        }
    }

    submit() {}
}
