import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ResourceCollectSheetComponent } from '../resource-collect-sheet/resource-collect-sheet.component';

@Component({
    selector: 'app-resource-publish-select-sheet',
    templateUrl: './resource-publish-select-sheet.component.html',
    styleUrls: ['./resource-publish-select-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcePublishSelectSheetComponent implements OnInit, OnDestroy {
    public assignForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public saving: boolean;

    constructor(
        public bottomSheetRef: MatBottomSheetRef<ResourceCollectSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected resourceService: ResourceService
    ) {
        this.assignForm = this.fb.group({
            id: [],
            sync_all_users: [],
            users_attach: [[]],
            users_detach: [[]],

            sync_all_groups: [],
            groups_attach: [[]],
            groups_detach: [[]],
        });
    }

    ngOnInit() {
        this.assignForm.patchValue({ id: this.data.resources.map((res: Resource) => res.id).join(',') });
    }

    public submit() {
        if (
            this.assignForm.value.users_attach?.length ||
            this.assignForm.value.users_detach?.length ||
            this.assignForm.value.groups_attach?.length ||
            this.assignForm.value.groups_detach?.length ||
            this.assignForm.value.sync_all_users !== null ||
            this.assignForm.value.sync_all_groups !== null
        ) {
            const data = new Resource(this.assignForm.value).getData();
            this.saving = true;
            this.resourceService
                .update(data)
                .pipe(
                    map((res) => {
                        this.assignForm.patchValue({ id: data.id });
                        return res;
                    }),
                    takeUntil(this.destroy)
                )
                .subscribe((res) => {
                    this.saving = false;
                    this.bottomSheetRef.dismiss(res);
                });
        } else {
            this.bottomSheetRef.dismiss();
        }
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
