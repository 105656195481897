import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { ResourceTypes } from '@klickdata/core/resource';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { takeUntil } from 'rxjs';

@Component({
    selector: 'app-menu-side-resource-done-au-activity',
    templateUrl: './menu-side-resource-done-au-activity.html',
    styleUrls: ['./menu-side-resource-done-au-activity.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideResourceDoneAUActivityComponent extends OnDestroyHandler implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    ResourceTypes = ResourceTypes;
    public signoffOption = new FormControl();
    constructor() {
        super();
    }
    ngOnInit(): void {
        this.signoffOption.valueChanges.pipe(takeUntil(this.destroy)).subscribe((value) =>
            this.onClose.emit({
                action: SideNaveActionsTypes.POSITIVE,
                data: {
                    value: value,
                },
            })
        );
    }
}
