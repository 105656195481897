import { Injectable } from '@angular/core';
import { PaginatorResponse } from '@klickdata/core/http';
import { ResourceTag, ResourceTagData } from '@klickdata/core/resource';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TagSelectDatatableService
    extends TagService
    implements TableHttpService<ResourceTag>
{
    public fetchData(source: TableSource<ResourceTag>): Observable<PaginatorResponse<ResourceTag[]>> {
        const req = this.builder.get<ResourceTagData[]>(this.resourceUrl);

        req.param('referenceCount', 1);
        // req.param('publicOrCustomer', this.customer_id);

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<ResourceTagData[]>) => {
                return {
                    data: res.data.map((item) => this.createTag(item)),
                    notify: res.notify,
                    paginator: res.paginator,
                    more: res.more,
                };
            })
        );
    }
}
