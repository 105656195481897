import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-swish-purchase',
  templateUrl: './swish-purchase.component.html',
  styleUrls: ['./swish-purchase.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwishPurchaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
