import {Component} from '@angular/core';

@Component({
    selector: 'app-saving-button',
    templateUrl: 'saving-button.component.html',
    styleUrls: ['saving-button.component.scss'],
})
export class SavingButtonComponent {
    constructor() {
    }
}
