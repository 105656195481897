import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from '@klickdata/core/util';

@Component({
    selector: 'app-ai-model-selector',
    templateUrl: './ai-model-selector.component.html',
    styleUrls: ['./ai-model-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AiModelSelectorComponent implements OnInit {
    Utils = Utils;
    model = 'gpt-4o'; // default model
    @Output() modelChange = new EventEmitter<string>();

    constructor() {}

    ngOnInit(): void {
        // Emit the initial value on component initialization
        this.modelChange.emit(this.model);
    }

    selectModel(value: string): void {
        this.model = value;
        this.modelChange.emit(value);
    }
}
