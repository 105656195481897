<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="2em">
    <div
        class="radio-wrap"
        fxLayout.lt-md="column"
        fxLayoutAlign="start center"
        fxLayoutAlign.lt-md="center start"
        fxLayoutGap="30px"
    >
        <span class="radio-label">{{ radioLabel }}:</span>
        <mat-radio-group
            fxLayoutAlign="start center"
            fxLayoutGap="70px"
            fxLayoutGap.lt-sm="20px"
            [formControl]="videoTypeControl"
        >
            <mat-radio-button value="file">
                <span i18n>Upload File</span>
            </mat-radio-button>
            <mat-radio-button value="link">
                <span i18n>Video Link</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <ng-container *ngIf="videoMedia">
        <ng-container *ngIf="videoTypeControl.value === 'file'">
            <app-media-view
                *ngIf="videoMedia.type != MediaType.LINK"
                fxLayoutAlign="start center"
                fxFlex="0 0 100%"
                [media]="videoMedia"
            >
                <button
                    class="remove-file"
                    color="warn"
                    type="button"
                    fxFlex="0 1 auto"
                    mat-icon-button
                    matTooltip="Remove file"
                    i18n-matTooltip
                    (click)="clearMedia()"
                >
                    <mat-icon class="md-24">delete_outline</mat-icon>
                </button>
            </app-media-view>
            <ng-container *ngIf="videoMedia.type === MediaType.LINK" [ngTemplateOutlet]="videoUploader"></ng-container>
        </ng-container>
        <mat-form-field *ngIf="videoTypeControl.value === 'link'" fxFlex="0 1 100%">
            <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="15"
                #input
                matInput
                type="url"
                placeholder="Link"
                i18n-placeholder
                [formControl]="linkControl"
            >
            </textarea>

            <button
                matTooltip="Open link in new tab"
                i18n-matTooltip
                type="button"
                *ngIf="Utils.isUrl(linkControl.value)"
                class="open-link"
                mat-icon-button
                matSuffix
                (click)="goToLink(linkControl.value)"
            >
                <mat-icon>open_in_new</mat-icon>
            </button>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="!videoMedia">
        <ng-container *ngIf="videoTypeControl.value === 'file'" [ngTemplateOutlet]="videoUploader"></ng-container>
        <mat-form-field *ngIf="videoTypeControl.value === 'link'" fxFlex="0 1 100%">
            <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="15"
                #input
                matInput
                type="url"
                placeholder="Link"
                i18n-placeholder
                [formControl]="linkControl"
            >
            </textarea>

            <button
                matTooltip="Open link in new tab"
                i18n-matTooltip
                type="button"
                *ngIf="Utils.isUrl(linkControl.value)"
                class="open-link"
                mat-icon-button
                matSuffix
                (click)="goToLink(linkControl.value)"
            >
                <mat-icon>open_in_new</mat-icon>
            </button>
        </mat-form-field>
    </ng-container>
</div>
<ng-template #videoUploader>
    <div class="video-uploader" fxFlex="0 1 100%" fxLayout="column" fxLayoutAlign="center center">
        <div
            [ngStyle]="dropStyle"
            fxFlex="0 0 50%"
            fxFlex.lt-md="0 0 90%"
            class="media-dropzone"
            fxLayout="row wrap"
            fxLayoutAlign="center center"
        >
            <input
                #videoInput
                class="media-upload-button"
                type="file"
                (change)="prepareFile($event)"
                [disabled]="onSavingFile | async"
                accept="video/3gpp, video/mp4,
                video/webm"
            />
        </div>

        <div class="progress-wrapper" *ngIf="progress$ | async; let progress">
            <mat-progress-bar class="progress" mode="determinate" [value]="progress"></mat-progress-bar>
            <span class="progress-value">{{ progress }}%</span>
        </div>
    </div>
</ng-template>
