import { User, UserData } from '@klickdata/core/user/src/user.model';

export class UserStatistic extends User {
    public total_groups = 0;
    public total_activities = 0;
    public started_course_plan = 0;
    public started_survey = 0;
    public started_test = 0;
    public started_course = 0;
    public completed_course_plan = 0;
    public completed_survey = 0;
    public completed_test = 0;
    public completed_course = 0;
    public passed_test = 0;
    public failed_test = 0;
    public material_taken = 0;

    constructor(data?: UserData) {
        super(data);
        if (data) {
            this.total_groups = data.total_groups;
            this.total_activities = data.total_activities;
            this.started_course_plan = data.started_course_plan;
            this.started_survey = data.started_survey;
            this.started_test = data.started_test;
            this.started_course = data.started_course;
            this.completed_course_plan = data.completed_course_plan;
            this.completed_survey = data.completed_survey;
            this.completed_test = data.completed_test;
            this.completed_course = data.completed_course;
            this.passed_test = data.passed_test;
            this.failed_test = data.failed_test;
            this.material_taken = data.material_taken;
        }
    }
}
