import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { ChangeDetectionStrategy, Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { CollectionService, Collection } from '@klickdata/core/collection';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { AppScope } from '@klickdata/core/resource';
import { ObjectValidator } from 'apps/klickdata/src/app/shared/validator/object.validator';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-collection-manage-bottom-sheet',
    templateUrl: './collection-manage-bottom-sheet.component.html',
    styleUrls: ['./collection-manage-bottom-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionManageBottomSheetComponent implements OnInit, OnDestroy {
    public saving: Subject<boolean> = new Subject<boolean>();
    public destroy: Subject<boolean> = new Subject<boolean>();
    AppScope = AppScope;
    public collectionForm: FormGroup;
    constructor(
        public bottomSheetRef: MatBottomSheetRef<CollectionManageBottomSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected collectionService: CollectionService,
        protected message: MessageService
    ) {
        this.collectionForm = this.fb.group({
            id: [],
            sync_all_items: [],
            items_attach: [[]],
            items_detach: [[]],
            title_translation: [{}, ObjectValidator.validTranslation],
        });
    }

    ngOnInit(): void {
        if (this.data.collection) {
            this.collectionForm.patchValue({
                id: this.data.collection.id,
                title_translation: this.data.collection.title_translation,
            });
        }
    }
    submit() {
        this.saving.next(true);
        const data = new Collection(this.collectionForm.value).getData();
        data.scope_id = this.data.scope;
        data.public = this.data.public;
        data.customer_id = this.data.customer.id;
        const createOrUpdate = !data.id
            ? this.collectionService.addCollection(data)
            : this.collectionService.updateCollection(data);

        createOrUpdate
            .pipe(
                takeUntil(this.destroy),
                catchError((err) => {
                    if (err && err.error && err.error.error) {
                        this.message.openMessage(MessageErrorComponent, err.error.error.messages);
                    }
                    return of(null);
                })
            )
            .subscribe((collection) => {
                this.saving.next(false);
                this.message.openMessage(MessageSavedComponent);
                this.bottomSheetRef.dismiss(collection);
            });
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
