<div class="mt-1" fxLayout="column" fxLayoutGap="10px" [formGroup]="form">
    <mat-form-field fxFlex="0 0 100%">
        <input type="text" matInput formControlName="subject" placeholder="Set reminder subject" i18n-placeholder />
    </mat-form-field>

    <app-text-editor
        fxFlex="0 0 100%"
        [subject]="form.value.subject"
        (onSubject)="form.controls['subject'].patchValue($event)"
        fieldName="coursePlanPublishBody"
        title="Reminder message"
        i18n-title
        formControlName="body"
    >
        <mat-error i18n="@@messageBodyError">Message body is required!</mat-error>
    </app-text-editor>
</div>
<div fxLayoutAlign="end center">
    <button class="tools" mat-button (click)="sendReminder()">
        <span>Send reminder!</span>
    </button>
</div>
