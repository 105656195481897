<div [dir]="directionality.value" [@transformPost]="'showing'">
    <!-- <button (click)="onExit()" mat-icon-button>
        <mat-icon class="md-24">close</mat-icon>
    </button> -->

    <ng-container *ngIf="customer$ | async; let customer">
        <app-post-detail fxLayout="column" [customer]="customer"></app-post-detail>
        <mat-divider></mat-divider>

        <div class="version"><span i18n="@@academy">Academy</span>: {{ customer.name }}</div>
        <div class="version" *ngIf="user$ | async; let user">{{ user.name }} - {{ user.email }}</div>

        <p class="version" [routerLink]="['/changelog']">
            KlickData Learning Management System KLMS
            <span *ngIf="version$ | async; let ver"> {{ ver.version }} {{ ver.date }} </span>
        </p>
    </ng-container>
</div>
