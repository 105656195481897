import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@klickdata/core/http';
import { AuthModule } from '@klickdata/core/auth';
import { UserModule } from '@klickdata/core/user';
import { ConfigModule } from '@klickdata/core/config';

@NgModule({
    imports: [CommonModule, HttpModule, AuthModule, UserModule, ConfigModule],
})
export class CustomerModule {
}
