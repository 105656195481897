import { Injectable } from '@angular/core';
import { PaginatorResponse } from '@klickdata/core/http';
import { ResourceCategory, ResourceCategoryData, ResourceCategoryService } from '@klickdata/core/resource';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CategorySelectDatatableService
    extends ResourceCategoryService
    implements TableHttpService<ResourceCategory>
{
    public fetchData(source: TableSource<ResourceCategory>): Observable<PaginatorResponse<ResourceCategory[]>> {
        const req = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);
        if (source.filter?.filter?.filters?.findIndex((filter) => filter.property.indexOf('reference') !== -1) !== -1) {
            req.param('referenceCount', 1);
        }
        req.param('publicOrCustomer', this.customer_id);

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<ResourceCategoryData[]>) => {
                return {
                    data: res.data.map((item) => this.createCategory(item)),
                    notify: res.notify,
                    paginator: res.paginator,
                    more: res.more,
                };
            })
        );
    }
}
