export enum CustomerCapability {
    NONE                 = 0,
    VIEW_OWN_CUSTOMER    = 1 << 0,
    MANAGE_OWN_CUSTOMER  = 1 << 1,
    VIEW_ALL_CUSTOMER    = 1 << 2,
    MANAGE_ALL_CUSTOMERS = 1 << 3,
}
export namespace CustomerCapability {
    export function getName(): string {
        return 'customer';
    }
}
