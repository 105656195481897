import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { GroupService } from '@klickdata/core/group';
import { MobileService } from '@klickdata/core/mobile';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../../global-filters-types.enum';
import { ChipFilterComponent } from '../chip-filter/chip-filter.component';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';

@Component({
    selector: 'app-group-chip-search-filter',
    templateUrl: './group-chip-search-filter.component.html',
    styleUrls: ['./group-chip-search-filter.component.scss'],
    providers: [
        { provide: ChipFilterComponent, useExisting: forwardRef(() => GroupChipSearchFilterComponent) },
        { provide: FilterBase, useExisting: GroupChipSearchFilterComponent },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupChipSearchFilterComponent extends ChipFilterComponent implements OnInit, OnDestroy {
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Input() inGlobalFilter: boolean;
    public control: FormControl = new FormControl();
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.GROUPS,
        items: [],
        label: $localize`:@@groups:Groups`,
        icon: 'groups',
        color: { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.WHITE },
        styleClass: 'global-groups-filter-selector',
    };
    constructor(
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService,
        protected groupService: GroupService
    ) {
        super(changeRef, mobile);
    }

    ngOnInit() {
        this.control.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((query) =>
                    query
                        ? this.groupService.getCustomerGroups(query, 25).pipe(
                              map((groups) =>
                                  groups.map((group, index) => {
                                      return {
                                          id: group.id,
                                          label: group.name,
                                          selected: index === 0, // make 1st item selected.
                                      };
                                  })
                              )
                          )
                        : of([])
                )
            )
            .subscribe((items) => {
                this.source = items;
                if (items?.length) {
                    this.updateSelection();
                } else {
                    this.removeFilter();
                }
                this.changeRef.markForCheck();
            });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
