import { AccessCapability } from '../can-access.directive';

export namespace FolderCapability {
    export const NONE = 0;
    export const VIEW_ALL_FOLDERS = 1 << 0;

    export function getName(): string {
        return 'folder';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
