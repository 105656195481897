<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>settings</mat-icon>
        <div i18n="@@manageDashboard">Manage Dashboard</div>
    </div>
    <div i18n class="desc mb-1">
        Show or hide widgets according to your need. You can also arrange the widgets to have the most interesting
        first.
    </div>
    <div
        *ngIf="navData.data"
        class="widgets-wrapper"
        cdkDropList
        [cdkDropListData]="navData.data.widgets"
        (cdkDropListDropped)="drop($event)"
    >
        <div
            cdkDragBoundary=".data-wrap"
            cdkDrag
            [cdkDragData]="navData.data.widgets"
            class="widget-item-wrap"
            cdkDragHandle
        >
            <button mat-icon-button (click)="$event.stopPropagation()">
                <mat-icon>sort</mat-icon>
            </button>
            <span fxFlex="grow">Events</span>

            <mat-slide-toggle color="primary" [checked]="true"> </mat-slide-toggle>
        </div>
        <div
            cdkDragBoundary=".data-wrap"
            cdkDrag
            [cdkDragData]="navData.data.widgets"
            class="widget-item-wrap"
            cdkDragHandle
        >
            <button mat-icon-button (click)="$event.stopPropagation()">
                <mat-icon>sort</mat-icon>
            </button>
            <span fxFlex="grow">Latest News</span>

            <mat-slide-toggle color="primary" [checked]="true"> </mat-slide-toggle>
        </div>
        <div class="action mt-1" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
            <button
                fxFlex="0 0 auto"
                class="mobile-button dark-blue-bg white-color"
                (click)="onSubmit(navData.data.widgets)"
                mat-raised-button
                i18n
            >
                Done
            </button>
            <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button i18n>
                Cancel
            </button>
        </div>
    </div>
</div>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
