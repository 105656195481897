import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SubMessage } from '@klickdata/core/notification';
import { User } from '@klickdata/core/user';
import { UserNotes, UserNotesService } from '@klickdata/core/user-notes';
import { Observable, Subject, map, merge, scan, takeUntil } from 'rxjs';

@Component({
    selector: 'app-task-notes',
    templateUrl: './task-notes.component.html',
    styleUrls: ['./task-notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskNotesComponent implements OnInit, OnDestroy {
    public dialogNotes$: Observable<SubMessage[]>;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public loading: Subject<boolean> = new Subject<boolean>();
    @Input() taskId: number;
    @Input() authUser: User;
    public updateDialogNoteSubject: Subject<UserNotes> = new Subject<UserNotes>();
    constructor(protected notesService: UserNotesService) {}
    public note = new FormControl('');

    ngOnInit(): void {
        this.dialogNotes$ = merge(
            this.notesService.getAllTaskNotes(this.taskId),
            this.updateDialogNoteSubject.asObservable()
        ).pipe(
            scan((acc, val) => acc.concat(val), <UserNotes[]>[]),
            map((notes) =>
                notes.map((note) => {
                    return new SubMessage({
                        author: note.author$,
                        created_at: note.created_at,
                        body: note.body,
                        author_id: note.author.id,
                    });
                })
            )
        );
    }
    addNote() {
        this.loading.next(true);
        this.notesService
            .addUserNote(
                new UserNotes({
                    body: this.note.value,
                    task_id: this.taskId,
                }).getData()
            )
            .pipe(takeUntil(this.destroy))
            .subscribe((note) => {
                this.loading.next(false);
                this.updateDialogNoteSubject.next(note);
                this.note.setValue('');
            });
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
