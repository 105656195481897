<div fxFlex="0 0 100%" fxLayout="row wrap" class="assignments-select">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.context"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss(data)"
        [savingStatus]="saving"
    ></app-sticky-header>
    <div class="user-manage-cp-access" fxFlex="0 0 95%" mat-stretch-tabs ngClass.xs="xs">
        <app-admin-users-select
            referenceFilter="referenceResourceRecommend"
            [accessForm]="assignForm"
            sync_all_key="sync_all_users_recommend"
            attachKey="users_attach_recommend"
            detachKey="users_detach_recommend"
        ></app-admin-users-select>
    </div>
</div>
