import { TranslatableModel } from './../../resource/translatable.model';
import { IDataModel, Cast, Model, InitialValue, Ignore, CastType } from '@klickdata/core/application/src/model/model-interface';
import { Customer } from '@klickdata/core/customer/src/customer.model';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';

export interface FolderData extends IDataModel {
    id?: number;
    parent_id?: number;
    children_ids?: number[];
    customer_id?: number;
    customer_ids?: number[];
    title?: string;
    subtitle?: string;
    description?: string;
    color?: string;
    media_id?: number;
    public?: boolean;
    weight?: number;
    updated_at?: string;
    created_at?: string;
    type_ids?: number[];
    author_id?: number;
}

export class Folder extends TranslatableModel<FolderData> {
    public parent_id: number;
    public customer_id: number;
    public customer_ids: number[];
    public title: string;
    public subtitle: string;
    public description: string;
    public color: string;
    public media_id: number;
    public public: boolean;
    public weight: number;
    @Cast(CastType.MOMENT)
    @Ignore()
    public updated_at: moment.Moment;
    @Cast(CastType.MOMENT)
    @Ignore()
    public created_at: moment.Moment;
    public customer: Observable<Customer>;
    public parent: Observable<Folder>;
    public children: Observable<Folder[]>;
    public loadedChildren: Folder[];
    public resources: Observable<Resource[]>;
    public loadedResources: Resource[];
    public courses: Observable<Resource[]>;
    public author_id: number;

    @InitialValue([])
    public type_ids: number[];
    @InitialValue([])
    public children_ids: number[];

    /**
     * Ignore from adding to payload.
     */
    @Ignore()
    checked: boolean;

    public equals(obj: Folder): boolean {
        return this.id === obj.id;
    }
}
