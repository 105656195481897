import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from '@klickdata/core/auth';
import { MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, Resource } from '@klickdata/core/resource';
import { TableFilterComponent, TableSource } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { UserListingService } from '../user-listing.service';


@Component({
  selector: 'app-multi-row-table',
  templateUrl: './multi-row-table.html',
  styleUrls: ['./multi-row-table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserListingService],
  animations: [
      trigger('detailExpand', [
          state('collapsed', style({ height: '0px', minHeight: '0' })),
          state('expanded', style({ height: '*' })),
          transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      ]),
  ],
})

export class MultiRowsTable implements OnInit, AfterViewInit, OnDestroy {
    public dataSource = new TableSource<User>();
    expandedElement: User | null;
    public accordionExoanded: boolean;
    public columns = [
        'name',
        'tools',
    ];
    public isMobile: boolean;
    public authUser: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    AppScope = AppScope;
  
    constructor(
        protected listService: UserListingService,
        protected mobile: MobileService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        protected messageService: MessageService,
        protected bottomSheet: MatBottomSheet
    ) {}
  
    public ngOnInit() {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((isMobile) => (this.isMobile = isMobile));
        this.dataSource.service = this.listService;
    }
  
    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
    }
  
  
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
  }
  