<div fxLayout="column" fxLayoutGap="0.6em" fxLayoutAlign="center center" *ngIf="data.recordedOutput; else loading">
    <div class="title-info">
        <h3><span i18n>Recorded</span> {{ RecorderTypes.getRecordLabel(data.type) }}</h3>
        <p>({{ (data.recordedOutput.blob.size / (1024 * 1024)).toFixed(2) }}</p>
        <p>MB)</p>
    </div>
    <p class="downloaded" i18n>Your record has been downloaded.</p>
    <p *ngIf="data.recordedOutput.limitedRecord" class="limited" i18n>
        Upgrade your subscription plan to record longer than {{limitedRecordingDurationInMin}} min
    </p>

    <ng-container *ngIf="data.type">
        <div class="video-wrapper" *ngIf="data.type !== RecorderTypes.AUDIO">
            <video
                *ngIf="data.recordedOutput.url"
                [src]="getSantizeUrl(data.recordedOutput.url)"
                #video
                controls
                class="video"
                playsinline
            ></video>
        </div>
        <div class="video-wrapper" *ngIf="data.type === RecorderTypes.AUDIO">
            <audio
                *ngIf="data.recordedOutput.blob"
                [src]="getSantizeUrl(data.recordedOutput.blob)"
                type="audio/mp3"
                controls
            ></audio>
        </div>
    </ng-container>

    <div class="output-btns">
        <button i18n mat-raised-button (click)="dialogRef.close(data.recordedOutput)">Use in material</button>
        <button i18n mat-raised-button (click)="dialogRef.close()">Close</button>
    </div>
</div>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
