import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Resource } from '@klickdata/core/resource';
import { ResourceItem, ResourceItemService, ResourceItemTypes } from '@klickdata/core/resource-item';

@Component({
    selector: 'app-resource-items-list',
    templateUrl: './resource-items-list.component.html',
    styleUrls: ['./resource-items-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceItemsListComponent implements OnInit {
    @Input()
    public resource: Resource;

    @Input()
    public showPoints = true;

    public items: Observable<ResourceItem[]>;

    public openAll = false;

    public ResourceItemTypes = ResourceItemTypes;

    constructor(protected itemService: ResourceItemService) {}

    ngOnInit() {
        this.items = this.itemService.getResourceItems(this.resource.id);
    }

    public open() {
        this.openAll = !this.openAll;
    }
}
