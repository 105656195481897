import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { Resource, AppScope } from '@klickdata/core/resource';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';
import { CreateSurveyComponent } from '@klickdata/shared-components/src/survey-create/create-survey.component';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
    selector: 'app-course-manager-core-add-survey',
    templateUrl: './course-manager-core-add-survey.component.html',
    styleUrls: ['./course-manager-core-add-survey.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseManagerCoreAddSurveyComponent implements OnInit {
    @Input() selection: number[] = [];
    @Input() display: ResourceBuildingItems;
    @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() add: EventEmitter<{ res: Resource; isParentSubmission?: boolean }> = new EventEmitter<{
        res: Resource;
        isParentSubmission?: boolean;
    }>();
    @Output() onMultiSelect: EventEmitter<{ res: Resource[]; isParentSubmission?: boolean }> = new EventEmitter<{
        res: Resource[];
        isParentSubmission?: boolean;
    }>();
    @Output() selectChange: EventEmitter<{ value: Resource; selected: boolean }> = new EventEmitter();
    @Output() onCloseView: EventEmitter<boolean> = new EventEmitter<boolean>();
    AppScope = AppScope;
    ResourceBuildingItems = ResourceBuildingItems;
    public showCreationOption: boolean;
    @ViewChild('compRef') compRef: CreateSurveyComponent;

    public isAdmin$: Observable<boolean>;
    constructor(protected auth: AuthService) {}
    ngOnInit() {
        this.isAdmin$ = this.auth.getUser().pipe(
            first(),
            map((user) => user.isAdmin())
        );
    }
    submit() {
        if (this.display === ResourceBuildingItems.CREATE_NEW_SURVEY) {
            this.compRef?.onSubmit();
        }
    }
}
