<div class="builder-header">
    <ng-content select="[appBuilderTitle]"></ng-content>

    <ng-content select="[appBuilderEmpty]"></ng-content>
    <ng-container *ngIf="form | async; let form">
        <ng-container *ngIf="getItems() | async; let items">
            <button
                *ngIf="!(isMobile$ | async) && items.length"
                class="unFold-all expander"
                [ngClass]="{ unfold: !foldAll }"
                mat-icon-button
                type="button"
                (click)="$event.stopPropagation(); foldAll = !foldAll; onItemCollapsingChange(foldAll)"
            >
                <mat-icon>last_page</mat-icon>
            </button>
        </ng-container>

        <app-resource-builder-form
            #builderForm
            fxFlex="0 1 1000px"
            fxFlex.lt-lg="80%"
            fxFlex.lt-md="100%"
            [usePoints]="usePoints"
            [form]="form"
            (saving)="onSaving($event)"
            (removal)="removal.emit($event)"
            (saved)="onSaved($event)"
        >
        </app-resource-builder-form>
    </ng-container>
    <!-- <app-resource-builder-tools
        [ngClass]="{ 'hide-items-builder-tools': hideAddItems }"
        *ngIf="!hideTools"
        class="sticky"
        [class.in-card]="inCard"
        fxFlex="0 1 1000px"
        fxFlex.lt-lg="80%"
        fxFlex.lt-md="100%"
        [forceSubmit]="forceSubmit"
        [resourceTypes]="resourceTypes"
        [questionTypes]="questionTypes"
        [saveTitle]="saveTitle"
        [usePoints]="usePoints"
        [active]="active"
        [hideAddItems]="hideAddItems"
    >
    </app-resource-builder-tools> -->
    <div
        *ngIf="
            !hideTools &&
            (resource_type_id === ResourceTypes.GeneralSurvey || resource_type_id === ResourceTypes.GeneralTest)
        "
        fxFlex="grow"
        fxLayoutAlign="start center"
        class="mt-1"
    >
        <button *ngIf="!(getLoading() | async)" mat-button type="button" class="add-qs" (click)="addQuestions()">
            <mat-icon>add_circle</mat-icon>
            <span i18n>Add Question</span>
        </button>
        <ng-container *ngIf="getLoading() | async">
            <div class="loader-expansion-wrapper" *ngIf="aiMetaData | async; let aiData; else: smallLoading">
                <mat-expansion-panel [disabled]="true" [expanded]="false">
                    <mat-expansion-panel-header class="main">
                        <div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="grow" fxLayout="row" fxLayoutAlign="start center">
                                <div class="qs-num-label">{{ aiData.question_count }}</div>
                                <div fxLayout="column" fxLayoutAlign="center start">
                                    <div
                                        class="qs-type-icon-wrap"
                                        fxLayout="row"
                                        fxLayoutAlign="start center"
                                        fxLayoutGap="3px"
                                    >
                                        <mat-icon *ngIf="aiData.question_type_value.includes(QuestionTypes.INPUT)"
                                            >edit_note</mat-icon
                                        >
                                        <mat-icon *ngIf="aiData.question_type_value.includes(QuestionTypes.RADIO)"
                                            >radio_button_checked</mat-icon
                                        >
                                        <mat-icon *ngIf="aiData.question_type_value.includes(QuestionTypes.CHECKBOX)"
                                            >check_box</mat-icon
                                        >
                                        <span>Questions</span>
                                    </div>
                                    <div>{{ aiData.text }}</div>
                                </div>
                            </div>
                            <mat-icon class="loadingg-icon-ai">smart_toy</mat-icon>
                        </div>
                    </mat-expansion-panel-header>
                </mat-expansion-panel>
            </div>
        </ng-container>
    </div>
</div>
<ng-template #smallLoading>
    <app-saving-button></app-saving-button>
</ng-template>
