import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-custom-fields-checkbox-type',
    templateUrl: './custom-fields-checkbox-type.component.html',
    styleUrls: ['./custom-fields-checkbox-type.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsCheckboxTypeComponent implements OnInit {
    @Input() customFieldForm: FormGroup;

    constructor() {}
    ngOnInit(): void {
        if (!this.customFieldForm.value.value) {
            this.customFieldForm.get('value').patchValue([]);
        }
    }

    getCheckedOption(option: string): boolean {
        return this.customFieldForm.value.value?.includes(option);
    }
    updateValue(status: boolean, option: string) {
        const values = this.customFieldForm.value.value;
        if (status) {
            values.push(option);
        } else {
            if (values.indexOf(option) !== -1) {
                values.splice(values.indexOf(option), 1);
            }
        }
        this.customFieldForm.get('value').patchValue(values);
        this.customFieldForm.markAsDirty();
    }
}
