import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    LOCALE_ID,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Group } from '@klickdata/core/group';
import { MobileService } from '@klickdata/core/mobile';
import { Filter, GlobalFilterProperty, TableFilterComponent, TableSource } from '@klickdata/core/table';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { GroupListService } from './group-list.service';
import { takeUntil, shareReplay } from 'rxjs';
import { User } from '@klickdata/core/user';
import { ResourcePermissionsHandlerComponent } from '../resource-permissions-handler/resource-permissions-handler.component';

@Component({
    selector: 'app-resource-access-control-groups',
    templateUrl: './resource-access-control-groups.component.html',
    styleUrls: ['./resource-access-control-groups.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [GroupListService],
})
export class ResourceAccessControlGroupsComponent extends OnDestroyHandler implements OnInit, AfterViewInit {
    @Input() permissionsForm: FormGroup;
    @Input() resourcePermissions: {
        index: number;
        value: string;
        label: string;
        tooltip?: string;
        disableUser?: boolean;
    }[];
    @ViewChildren(ResourcePermissionsHandlerComponent)
    resourcePermHandlerchilldren: QueryList<ResourcePermissionsHandlerComponent>;
    public permissionsColSelectionState = {};
    public dataSource = new TableSource<Group>();
    public columns = ['select', 'name', 'user_count', 'permissions'];
    public isMobile: boolean;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    GlobalFilterProperty = GlobalFilterProperty;
    constructor(
        protected mobile: MobileService,
        protected cdRef: ChangeDetectorRef,
        protected groupService: GroupListService,
        @Inject(LOCALE_ID) public localeId: string
    ) {
        super();
    }

    ngOnInit(): void {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((mobile) => {
                this.isMobile = mobile;
                this.cdRef.markForCheck();
            });
        this.dataSource.service = this.groupService;
        // Handle vertical selection
        // this.permissionsColSelectionState = this.resourcePermissions.reduce((acc, perm) => {
        //     acc[perm.value] = false;
        //     return acc;
        // }, {});
    }
    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        if (this.filter && this.permissionsForm) {
            this.filter.createOrUpdateFilter([
                new Filter('referenceResource', [this.permissionsForm.value.id]),
                // new Filter('referenceCount', [1]),
                new Filter('customer', [this.groupService.customer_id]),
            ]);
        }
    }
    get groupPermissionsArray(): FormArray {
        if (this.permissionsForm) {
            return this.permissionsForm?.get('group_permissions') as FormArray;
        }
    }
    onTogglePerm(permName: string) {
        // this.permissionsColSelectionState[permName] = !this.permissionsColSelectionState[permName];
        // this.resourcePermHandlerchilldren.forEach((child) =>
        //     child.togglePerm(this.permissionsColSelectionState[permName], permName)
        // );
    }
    selectToggle(group: Group, status: boolean) {
        this.resourcePermHandlerchilldren.forEach((child) => child.toggleAll(status, group.id));
    }
}
