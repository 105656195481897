import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-show-all-quotes',
    templateUrl: './menu-side-show-all-quotes.component.html',
    styleUrls: ['./menu-side-show-all-quotes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideShowAllQuotesComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public expandAll: boolean = true;
    constructor() {}

    ngOnInit(): void {}
    clear() {}
}
