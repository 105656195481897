<button mat-icon-button [matMenuTriggerFor]="lms">
    <mat-icon>keyboard_arrow_down</mat-icon>
</button>

<mat-menu #lms="matMenu">
    <button
        mat-menu-item
        class="lms-item"
        *ngFor="let item of Utils.getLLmsList()"
        [disabled]="!item.enabled"
        (click)="selectModel(item.value)"
        [class.selected]="item.value === model"
    >
        <span>{{ item.name }}</span>
    </button>
</mat-menu>
