import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { Media } from '@klickdata/core/media/src/media.model';
import { MessageFormErrorComponent, MessageService } from '@klickdata/core/message';
import {
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceTypes,
    AppScope
} from '@klickdata/core/resource';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import * as moment from 'moment';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-scorm-create',
    templateUrl: './scorm-create.component.html',
    styleUrls: ['./scorm-create.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScormCreateComponent implements OnInit, OnChanges, OnDestroy {
    public scormForm: FormGroup;
    public media: Media;
    AppScope = AppScope;
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saved: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Input() resource: Resource;
    ResourceTypes = ResourceTypes;
    @Input() publishOnly: boolean;
    @Input() active = true;
    public isAdmin$: Observable<boolean>;

    dropSize = '200px';
    public get dropStyle() {
        return {
            height: this.dropSize,
            width: this.dropSize,
            flex: `0 1 ${this.dropSize}`,
        };
    }
    constructor(
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected auth: AuthService,
        protected messageService: MessageService,
        protected changeRef: ChangeDetectorRef,
        protected categoryService: ResourceCategoryService,
        protected dialog: MatDialog
    ) {
        this.buildForm();
    }

    ngOnInit() {
        this.isAdmin$ = this.auth.getUser().pipe(
            first(),
            map((user) => user.isAdmin())
        );
        this.setDefaultCategory();
    }

    private setDefaultCategory() {
        this.categoryService
            .getSuggestedCategory(ResourceTypes.SCORM)
            .pipe(takeUntil(this.destroy))
            .subscribe((category) => {
                if (!this.scormForm.controls.category_ids.value?.length) {
                    this.scormForm.patchValue({
                        category_ids: [category.id],
                    });
                    FormHelper.resetForm(<FormControl>this.scormForm.controls.category_ids);
                }
            });
    }

    ngOnChanges() {
        if (this.resource) {
            this.scormForm.patchValue({
                id: this.resource.id,
                priority: this.resource.priority,
                title: this.resource.title,
                media_id: this.resource.media_id,
                description: this.resource.description,
                category_ids: this.resource.category_ids,
                language_id: this.resource.language_id,
            });
            FormHelper.resetForm(this.scormForm);
            this.update();
        }
    }

    public buildForm() {
        this.scormForm = this.fb.group({
            id: [],
            priority: [],
            media_id: [],
            title: ['', Validators.required],
            tag_ids: [[]],
            category_ids: [[], Validators.required],
            description: [''],
            language_id: ['', Validators.required],
        });
    }

    public update(): void {
        FormHelper.resetForm(this.scormForm);
        this.changeRef.markForCheck();
    }

    public submitScorm(form?: FormGroupDirective, publish?: boolean) {
        if (!this.scormForm.valid) {
            FormHelper.markForm(this.scormForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            return;
        }

        this.prepareSubmit(publish).subscribe((resource) => {
            form?.resetForm();
            this.scormForm.reset();
            FormHelper.resetForm(this.scormForm);
            this.saving.emit(false);
            this.saved.emit(resource);
            if (this.publishOnly) {
                this.setDefaultCategory();
            }
        });
    }
    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '70%',
            maxHeight: '50vh',
            data: id,
        });
    }
    public prepareSubmit(publish: boolean): Observable<Resource> {
        this.saving.emit(true);
        return this.prepareData(publish).pipe(
            switchMap((data) =>
                !this.resource?.id
                    ? this.resourceService.store(data)
                    : of(Object.assign(new Resource(), this.resource, data))
            ),
            switchMap((resource) => this.resourceService.update(resource.getPayload())),
            takeUntil(this.destroy)
        );
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    protected prepareData(publish: boolean): Observable<ResourceData> {
        const data = new Resource(this.scormForm.value).getData();

        if (publish) {
            data.published = moment().format('YYYY-MM-DD HH:mm:ss');
        }

        if (!this.resource?.id) {
            return combineLatest([this.auth.getCustomer(), this.auth.getUser()]).pipe(
                first(),
                map(([customer, user]) => {
                    data.author_id = user.id;
                    data.customer_id = customer.id;
                    data.type_id = ResourceTypes.SCORM;
                    return data;
                })
            );
        } else {
            return of(data);
        }
    }

    public canDeactivate(): boolean {
        return this.scormForm.pristine;
    }
}
