import { FormArray, FormControl, FormGroup, AbstractControl } from '@angular/forms';

export interface FormError {
    name: string;
    control: FormControl;
    errors: any;
}

export class FormHelper {
    public static resetForm(group: AbstractControl, updateValidity = false) {
        group.markAsUntouched();
        group.markAsPristine();

        if (updateValidity) {
            group.updateValueAndValidity();
        }
        if (group instanceof FormGroup || group instanceof FormArray) {
            for (const i in group.controls) {
                if (group.controls[i] instanceof FormControl) {
                    group.controls[i].markAsUntouched();
                    group.controls[i].markAsPristine();
                } else {
                    this.resetForm(group.controls[i], updateValidity);
                }
            }
        }
    }

    public static markForm(group: AbstractControl) {
        if (group instanceof FormControl) {
            group.markAsTouched();
            group.markAsDirty();
            return;
        }
        group.markAsTouched();
        group.markAsDirty();
        if (group instanceof FormGroup) {
            Object.values(group.controls).forEach((control) => {
                this.markForm(control);
            });
        } else if (group instanceof FormArray) {
            group.controls.forEach((control) => {
                this.markForm(control);
            });
        }
    }

    public static getErrors(group: FormGroup | FormArray): any[] {
        let errors: FormError[] = [];

        for (const i in group.controls) {
            if (!group.controls[i].valid) {
                if (group.controls[i] instanceof FormControl) {
                    errors.push({
                        name: i,
                        control: group.controls[i],
                        errors: group.controls[i].errors,
                    });
                } else {
                    errors = [].concat(errors, this.getErrors(group.controls[i]));
                }
            }
        }

        return errors;
    }

    public static formatInvalidForm(
        group: FormGroup | FormArray,
        formats: { [key: string]: string } = {},
        ignore: string[] = []
    ) {
        let invalid = [];
        for (const i in group.controls) {
            if (ignore.indexOf(i) === -1 && group.controls[i].invalid) {
                if (group.controls[i] instanceof FormControl) {
                    const key = Object.keys(formats).find((k) => k === i);
                    invalid.push(key ? formats[key] : i);
                } else {
                    invalid = [].concat(invalid, this.formatInvalidForm(group.controls[i], formats));
                }
            }
        }
        return invalid;
    }

    public static updateFormValidity(group: FormGroup | FormArray): void {
        group.updateValueAndValidity();

        for (const i in group.controls) {
            if (group.controls.hasOwnProperty(i)) {
                const control = group.controls[i];

                if (control instanceof FormControl) {
                    control.updateValueAndValidity();
                } else {
                    this.updateFormValidity(control);
                }
            }
        }
    }

    public static getChangedValues(form: AbstractControl) {
        if (form instanceof FormGroup) {
            return Object.entries(form.controls)
                .filter(([, control]) => control.dirty || control.touched)
                .reduce((values, [key, control]) => {
                    values[key] = this.getChangedValues(control);
                    return values;
                }, {});
        } else if (form instanceof FormArray) {
            return form.controls
                .filter((control) => control.dirty || control.touched)
                .map((control) => this.getChangedValues(control));
        } else if (form instanceof FormControl) {
            return form.dirty || form.touched ? form.value : null;
        }
    }
}
