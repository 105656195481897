import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Resource, ResourceData, ResourceService, ResourceTypes, AppScope } from '@klickdata/core/resource';
import { CreateCoursePlanSimpleComponent } from '@klickdata/shared-components/src/create-course-plan-simple/create-course-plan-simple.component';
import { AddMaterialLinkComponent } from '@klickdata/shared-components/src/material-create/add-material-link/add-material-link.component';
import { AddMaterialTextComponent } from '@klickdata/shared-components/src/material-create/add-material-text/add-material-text.component';
import { UploadMaterialFileComponent } from '@klickdata/shared-components/src/material-create/upload-material-file/upload-material-file.component';
import { CreateSurveyComponent } from '@klickdata/shared-components/src/survey-create/create-survey.component';
import { CreateTestComponent } from '@klickdata/shared-components/src/test-create/create-test.component';
import { Observable } from 'rxjs';
import { CourseSettingsComponent } from '../e-course/course-settings/course-settings.component';

@Component({
    selector: 'app-resource-edit-dialog',
    templateUrl: './resource-edit-dialog.component.html',
    styleUrls: ['./resource-edit-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceEditDialogComponent implements OnInit {
    @ViewChild(CreateCoursePlanSimpleComponent) courseComp: CreateCoursePlanSimpleComponent;
    @ViewChild(CourseSettingsComponent) ecourseComp: CourseSettingsComponent;
    @ViewChild(CreateTestComponent) testComp: CreateTestComponent;
    @ViewChild(CreateSurveyComponent) surveyComp: CreateSurveyComponent;
    @ViewChild(AddMaterialLinkComponent) linkComp: AddMaterialLinkComponent;
    @ViewChild(UploadMaterialFileComponent) fileComp: UploadMaterialFileComponent;
    @ViewChild(AddMaterialTextComponent) textComp: AddMaterialTextComponent;

    resource$: Observable<Resource>;
    AppScope = AppScope;
    ResourceTypes = ResourceTypes;
    saving: boolean;
    scope_id: AppScope;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ResourceData,
        protected resourceService: ResourceService,
        protected dialogRef: MatDialogRef<ResourceEditDialogComponent>
    ) {}

    ngOnInit(): void {
        this.scope_id = this.data.scope_id;
        this.resource$ = this.resourceService.getResource(this.data.id);
    }

    onSubmit() {
        this.saving = true;
        this.courseComp?.onSubmit(true);
        this.testComp?.onSubmit();
        this.surveyComp?.onSubmit();
        this.linkComp?.submitLink();
        this.fileComp?.submitFile();
        this.textComp?.submitTextMaterial();
        this.ecourseComp?.onSubmit();
    }

    onClose(resource?: Resource) {
        this.dialogRef.close(resource);
    }
}
