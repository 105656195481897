import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceService } from '@klickdata/core/resource';
import { UserService } from '@klickdata/core/user';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../../global-filters-types.enum';
import { ChipFilterComponent } from '../chip-filter/chip-filter.component';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';

@Component({
    selector: 'app-instructor-chip-search-filter',
    templateUrl: './instructor-chip-search-filter.component.html',
    styleUrls: ['./instructor-chip-search-filter.component.scss'],
    providers: [
        { provide: ChipFilterComponent, useExisting: forwardRef(() => InstructorChipSearchFilterComponent) },
        { provide: FilterBase, useExisting: InstructorChipSearchFilterComponent },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstructorChipSearchFilterComponent extends ChipFilterComponent implements OnInit, OnDestroy {
    @Input() selectFirstItem = true;
    @Input() searchItemsLimit = 10;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public control: FormControl = new FormControl();
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.EDUCATOR,
        items: [],
        label: $localize`:@@instructor:Instructor`,
        icon: 'engineering',
        color: { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 },
        styleClass: 'global-instructor-filter-selector',
    };

    constructor(
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService,
        protected userService: UserService,
        protected resourceService: ResourceService
    ) {
        super(changeRef, mobile);
    }

    ngOnInit() {
        this.control.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((query) => {
                    return query?.trim()
                        ? this.resourceService
                              .getResourceInstructors({ query: query, limit: this.searchItemsLimit })
                              .pipe(
                                  map((instructors) =>
                                      instructors.map((instructor, index) => {
                                          return {
                                              id: instructor.id, // Make name as key //TODO update instructor to follow users.
                                              label: instructor.name,
                                              selected: true, // make 1st item selected.
                                          };
                                      })
                                  )
                              )
                        : of([]);
                })
            )
            .subscribe((items) => {
                this.source = items;
                if (items?.length) {
                    this.updateSelection();
                } else {
                    this.removeFilter();
                }
                this.changeRef.markForCheck();
            });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
