<form [formGroup]="customerForm" *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>badge</mat-icon>
        <div i18n>Add a client card</div>
    </div>
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div fxLayoutGap="4px" class="input-wrapper default-select" fxLayout="column">
            <span class="label" i18n>Organization</span>
            <div *ngIf="!selectedCustomer" fxLayout="column" fxLayoutGap="10px" fxLayoutGap.lt-md="10px">
                <mat-form-field class="small-field users-rec-input" appearance="fill">
                    <input
                        type="text"
                        matInput
                        #customerInput
                        i18n-placeholder
                        placeholder="Search"
                        formControlName="name"
                        [matAutocomplete]="autoCustomer"
                    />
                    <mat-autocomplete
                        #autoCustomer="matAutocomplete"
                        (optionSelected)="
                            selectedCustomer = $event.option.value;
                            customerForm.get('name').setValue(null);
                            customerForm.get('id').setValue($event.option.value.id)
                        "
                    >
                        <mat-option *ngFor="let customer of customerSearch$ | async" [value]="customer">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <span>{{ customer.name }}</span>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                    <app-kd-logo-loading *ngIf="onSearchCustomer$.value" matSuffix size="small"></app-kd-logo-loading>
                </mat-form-field>
            </div>
            <div *ngIf="selectedCustomer" fxLayout="column wrap" class="mt-1">
                <mat-chip-list class="reci-chips" #chipList aria-label="Recievers selection">
                    <mat-chip
                        (removed)="customerForm.get('id').setValue(null); selectedCustomer = null"
                        [selectable]="false"
                        class="customer-wrap"
                    >
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <span>{{ selectedCustomer?.name }}</span>
                        </div>
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip></mat-chip-list
                >
            </div>
        </div>

        <app-user-chip-select
            #userChipSelect
            titleLabel="Connections"
            i18n-titleLabel
            hint="click comma or enter to add new connections"
            i18n-hint
            [searchUsersParams]="{ limit: 20 }"
            [formControl]="connectionsControl"
            [maxUsersCount]="20"
            [hasDrafts]="true"
            (onNotifyUsers)="updateConections($event)"
        ></app-user-chip-select>

        <div class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
            <span i18n>Note</span>
            <mat-form-field appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                <textarea
                    #title
                    matInput
                    type="text"
                    formControlName="note"
                    placeholder="Enter note text"
                    i18n-placeholder
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>
        </div>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent k3-crm-tags">
            <span i18n>Tags</span>
            <app-tag-chip-select
                class="w-100"
                placeholder="Add Tag"
                [language_id]="currentLaguageId$ | async"
                i18n-placeholder
                formControlName="tag_ids"
            ></app-tag-chip-select>
        </div>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>State</span>
            <mat-form-field class="role-field">
                <mat-select fxFlex="1 1 100%" formControlName="type">
                    <mat-option value="client" i18n>Client</mat-option>
                    <mat-option value="partner" i18n>Partner</mat-option>
                    <mat-option value="offer" i18n>Offerers</mat-option>
                    <mat-option value="prospect" i18n>Prospect</mat-option>
                    <mat-option value="nothanker" i18n>Nothanker</mat-option>
                    <mat-option value="contact" i18n>Contact</mat-option>
                    <mat-option value="exclient" i18n>Ex-Client</mat-option>
                    <mat-option value="other" i18n>Other</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <ng-container formGroupName="task">
            <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
                <span i18n>Contact Date</span>
                <mat-form-field
                    appearance="outline"
                    floatLabel="never"
                    class="w-100"
                    fxFlex="grow"
                    fxFlex="1 1 100%"
                    fxFlex.lt-md="1 1 100%"
                >
                    <mat-label i18n>Enter contact date</mat-label>
                    <input
                        matInput
                        [matDatepicker]="deadlineDatepicker"
                        (focus)="deadlineDatepicker.open()"
                        formControlName="deadline"
                    />
                    <mat-datepicker-toggle matIconSuffix [for]="deadlineDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #deadlineDatepicker></mat-datepicker>
                </mat-form-field>
            </div>
        </ng-container>
        <ng-container formGroupName="staff">
            <app-user-chip-select
                titleLabel="Signature"
                i18n-titleLabel
                [searchUsersParams]="{ limit: 20, userRole: 'superadmin' }"
                [defaultMe]="true"
                formControlName="manager"
            ></app-user-chip-select>
        </ng-container>
    </div>
    <div class="action" fxLayout="row-reverse wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-container *ngIf="!(onLoading | async)">
            <button
                [disabled]="onSearchCustomer$.value"
                type="button"
                fxFlex="0 0 auto"
                class="mobile-button custom"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n>Create</span>
            </button>
        </ng-container>
        <app-kd-logo-loading *ngIf="onLoading | async" size="small"></app-kd-logo-loading>
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button>
            <span i18n>Cancel</span>
        </button>
    </div>
</form>
