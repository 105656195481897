import { Model, IModel } from '@klickdata/core/application';

export interface LoginData extends IModel {
    username?: string;
    password?: string;
    old_password?: string;
    oldToken?: string;
    token?: string;
    short_name?: string;
    password_confirmation?: string;
}
/**
 * Login model
 */
export class Login extends Model<LoginData> {
    public id: string | number;
    username: string;
    password: string;
    old_password: string;
    oldToken: string;
    short_name: string;
    password_confirmation: string;
    token: string;
    remember = false;
    logoutOthers = false;
}
