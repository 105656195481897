<div *ngIf="navData.data" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>date_range</mat-icon>
        <div i18n>Date range</div>
    </div>
    <div class="desc" i18n>Please select date range</div>
    <div>
        <form [formGroup]="rangeForm">
            <mat-form-field appearance="fill">
                <mat-label>{{ navData.data.label }}</mat-label>
                <mat-date-range-input [min]="min | async" [max]="max" [rangePicker]="picker">
                    <input matStartDate formControlName="begin" placeholder="Start date" />
                    <input matEndDate formControlName="end" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error i18n>A valid date range is required!</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button i18n>
            Cancel
        </button>
    </div>
</div>
