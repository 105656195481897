import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Utils } from '@klickdata/core/util';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-user-setting-input',
    templateUrl: './user-setting-input.component.html',
    styleUrls: ['./user-setting-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingInputComponent implements OnInit, OnDestroy {
    @Input() value: any;

    @Input() type: string;
    @Input() tips: string;
    @Input() textAreaRows: number;

    @Input() placeholder: string | null = '';

    @Input() allowRemove: boolean;
    @Input() showLabel: boolean;

    @Input() control: FormControl;

    @Input() clickToEdit = false;
    @Input() customAction = false;
    @Input() clickToOpenLink = false;
    @Input() numbersCounterChanger = false;
    @Input() switchDisabled = false;
    @Output() onChange = new EventEmitter<boolean>();
    public originalType: string;
    Utils = Utils;

    private destory = new Subject<boolean>();

    constructor() {}

    ngOnInit(): void {
        this.originalType = this.type;
        // TODO: Remove this
        if (!this.control) {
            this.control = new FormControl('');
        }
        if (this.clickToEdit || this.allowRemove) {
            this.control.disable();
        }
        if (this.value) {
            this.control.setValue(this.value, { emitEvent: false });
        }
        // add value changes observable and update value using API
        this.control.valueChanges.pipe(takeUntil(this.destory)).subscribe(() => this.onChange.emit(true));
    }

    clearInputField() {
        this.control.patchValue('');
        this.control.enable();
        // remove and change hide once input enabled
        this.allowRemove = false;
    }

    enableInputField() {
        this.control.enable();
        // remove and change hide once input enabled
        this.clickToEdit = false;
    }

    togglePasswordType() {
        this.type = this.type === 'text' ? 'password' : 'text';
    }
    changeNum(count: number) {
        this.control.patchValue(this.control.value + count);
    }
    openLink(url: string) {
        window.open(url, '_blank');
    }

    ngOnDestroy(): void {
        this.destory.next(true);
        this.destory.unsubscribe();
    }
}
