<div class="date-calndar-pick" fxLayout="column" fxLayoutAlign="center center">
    <div class="mt-1" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
        <mat-icon>{{ icon }}</mat-icon>
        <span>{{ title }}</span>
    </div>
    <mat-calendar (selectedChange)="notify()" [(selected)]="dateValue"></mat-calendar>
    <!-- <mat-form-field>
        <input
            matInput
            name="selected_time_A"
            [(ngModel)]="timeValue"
            [ngxMatTimepicker]="pickerA"
            placeholder="4:00 PM"
            readonly
        />
        <mat-icon matSuffix (click)="pickerA.open()"> watch_later </mat-icon>
    </mat-form-field>
    <ngx-mat-timepicker #pickerA></ngx-mat-timepicker> -->

    <!-- <mat-form-field class="small-field mt-1" appearance="fill"> -->
    <!-- <ngx-mat-datetime-picker color="primary" #picker>
        <ng-template>
            <span>Done</span>
        </ng-template>
    </ngx-mat-datetime-picker> -->
    <!-- </mat-form-field> -->

    <ngx-mat-timepicker
        class="time-picker-calendar-select"
        (click)="notify()"
        [(ngModel)]="timeValue"
        [disabled]="disabled"
        [showSpinners]="true"
        [enableMeridian]="true"
    >
    </ngx-mat-timepicker>
</div>
