import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResourceData, ResourceTypes } from '@klickdata/core/resource';

@Component({
    selector: 'app-resource-title-with-icon',
    templateUrl: './resource-title-with-icon.component.html',
    styleUrls: ['./resource-title-with-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceTitleWithIconComponent {
    @Input() resource: ResourceData;
    @Input() displayLabel: boolean;
    ResourceTypes = ResourceTypes;
    constructor() {}
}
