import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@klickdata/core/util';

@Pipe({
    name: 'preferredCommunication',
})
export class PreferredCommunicationPipe implements PipeTransform {
    transform(communications: {}): { value: string; preferred: boolean; key: string; label?: string } {
        let activeCommWay = null;
        for (const key in communications) {
            const label = Utils.getSocialMedias().find((sm) => sm.key === key).name;
            if (communications[key].preferred) {
                activeCommWay = { ...communications[key], key: key, label: label };
                break;
            }
            if (!activeCommWay && !communications[key].preferred) {
                activeCommWay = { ...communications[key], key: key, label: label };
            }
        }
        return activeCommWay;
    }
}
