import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AuthService } from '@klickdata/core/auth';
import { LanguageService } from '@klickdata/core/localization';
import { MsgCase, Notification, NotificationService } from '@klickdata/core/notification';
import { Resource, ResourceService, AppScope } from '@klickdata/core/resource';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-message-case',
    templateUrl: './message-case.component.html',
    styleUrls: ['./message-case.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageCaseComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input() msgCase$: Observable<MsgCase>;
    @Input() msg: Notification;
    public contents: Resource[] = [];
    public editableMsg = true;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public loading$: Subject<boolean> = new Subject<boolean>();
    public filteredContent: BehaviorSubject<Resource[]> = new BehaviorSubject<Resource[]>([]);
    @ViewChild('resourceInput') resourceInput: ElementRef<HTMLInputElement>;
    @Output() caseDeadline: EventEmitter<string> = new EventEmitter<string>();
    public contentCtrl = new FormControl();
    public endDate = new FormControl();
    public minDate = new Date();

    constructor(
        protected resourceService: ResourceService,
        protected languageService: LanguageService,
        protected authService: AuthService,
        protected notificationService: NotificationService,
        protected changeRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        if (this.msg) {
            this.editableMsg = !this.msg || this.msg.draft;
            this.loading$.next(true);
            combineLatest([
                this.msgCase$.pipe(
                    tap((msgCase) => {
                        if (msgCase.end_date) {
                            this.caseDeadline.emit(msgCase.end_date);
                            this.endDate.setValue(msgCase.end_date);
                            this.changeRef.markForCheck();
                        }
                    }),
                    map((msgCase) => msgCase.resource_ids)
                ),
                this.authService.getUser().pipe(take(1)),
                this.languageService.getContentLanguage(),
            ])
                .pipe(
                    switchMap(([ids, user, lang]) =>
                        ids?.length
                            ? this.resourceService.getResources(ids, null, 10, null, [
                                  { property: 'availableForCustomer', items: [user.customer_id] },
                                  { property: 'scope', items: [AppScope.MATERIAL] },
                                  { property: 'language', items: [lang.id] },
                              ])
                            : of([])
                    ),
                    takeUntil(this.destroy)
                )
                .subscribe((res) => {
                    this.loading$.next(false);
                    this.contents = [...this.contents, ...res];
                    this.changeRef.markForCheck();
                });
            this.changeRef.markForCheck();
        }
    }
    ngAfterViewInit(): void {
        combineLatest([
            this.contentCtrl.valueChanges.pipe(
                filter((term) => typeof term === 'string'),
                debounceTime(300),
                distinctUntilChanged()
            ),
            this.authService.getUser().pipe(take(1)),
            this.languageService.getContentLanguage(),
        ])
            .pipe(
                switchMap(([term, user, lang]) => {
                    return term.trim()
                        ? this.resourceService.getResources(null, null, 10, term, [
                              { property: 'availableForCustomer', items: [user.customer_id] },
                              { property: 'scope', items: [AppScope.MATERIAL] },
                              { property: 'language', items: [lang.id] },
                          ])
                        : EMPTY;
                }),
                takeUntil(this.destroy)
            )
            .subscribe((resources) => this.filteredContent.next(resources));
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.changeRef.markForCheck();
    }

    public removeContent(res: Resource): void {
        const index = this.contents.indexOf(res);
        if (index >= 0) {
            this.contents.splice(index, 1);
        }
    }

    public selectedContent(event: MatAutocompleteSelectedEvent, auto: MatAutocomplete): void {
        this.contents.push(event.option.value);
        this.resourceInput.nativeElement.value = '';
        auto.options.reset([]);
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
    dateUpdated() {}
}
