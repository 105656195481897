import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageFormErrorComponent, MessageSentComponent, MessageService } from '@klickdata/core/message';
import { UserMessage, UserMessageData, UserService } from '@klickdata/core/user';
import { UsersSelectComponent } from '@klickdata/shared-components/src/selection-handler/users-select/users-select.component';
import { Observable, of, Subject } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'admin-welcome-message',
    templateUrl: './welcome-message.component.html',
    styleUrls: ['./welcome-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeMessageComponent implements OnInit, CanComponentDeactivate, OnDestroy {
    @ViewChild(UsersSelectComponent) userSelectComp: UsersSelectComponent;
    public loading = false;
    public welcomeForm: FormGroup;
    @Input() params: any;
    @Input() hideActions: boolean;
    @Output() send: EventEmitter<UserMessageData> = new EventEmitter<UserMessageData>();
    @Output() sent: EventEmitter<{ [key: string]: any }> = new EventEmitter<{ [key: string]: any }>();
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        protected auth: AuthService,
        protected messageService: MessageService
    ) {
        this.welcomeForm = this.fb.group({
            subject: ['', Validators.required],
            body: ['', Validators.required],
            groups: [],
            customer: [],
            import: [],
            user_activation: [],
            sync_all_users: [],
            users_attach: [[]],
            users_detach: [[]],
        });
    }

    ngOnInit() {
        if (this.params) {
            this.welcomeForm.patchValue(this.params);
        }
    }

    public submitWelcomeMessage(standardActivation?: boolean): void {
        if ((this.welcomeForm.invalid && !standardActivation) || this.userSelectComp?.canDeactivate()) {
            FormHelper.markForm(this.welcomeForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            return;
        }
        this.prepareUserMessage(standardActivation)
            .pipe(
                filter(msg => {
                    if (!!this.send.observers.length) {
                        this.send.emit(msg);
                        return false;
                    }
                    this.loading = true;
                    return true;
                }),
                switchMap(msg => this.userService.send(msg))
            )
            .subscribe(result => {
                this.loading = false;
                this.messageService.openMessage(MessageSentComponent);
                this.welcomeForm.reset();
                this.welcomeForm.patchValue({ body: '' });
                FormHelper.resetForm(this.welcomeForm);
                setTimeout(() => {
                    this.sent.emit({ ...this.params, importing: this.params.importing });
                }, 1000);
            });
    }

    protected prepareUserMessage(standardActivation?: boolean): Observable<UserMessageData> {
        const data = new UserMessage(
            standardActivation
                ? this.params
                : { ...this.welcomeForm.value, ...this.params }
        ).getData();
        if (data.customer) {
            return of(data);
        } else {
            return this.auth.getCustomer().pipe(
                first(),
                map(customer => {
                    data.customer = customer.id;
                    return data;
                })
            );
        }
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    canDeactivate(): boolean {
        return this.welcomeForm.pristine;
    }
}
