<ng-container *ngIf="mode == 'select'">
    <div @fadeInOut fxLayout="row" fxLayoutAlign="start center" class="templates-toolbar">
        <button mat-icon-button type="button" class="btn" (click)="onSearch(search.value)">
            <mat-icon>search</mat-icon>
        </button>
        <div class="search-box">
            <mat-form-field floatPlaceholder="never">
                <input
                    matInput
                    #search
                    placeholder="Filter by name..."
                    i18n-placeholder="@@filterByName"
                    (keyup)="onSearch(search.value)"
                />
            </mat-form-field>
            <button
                mat-icon-button
                type="button"
                *ngIf="search.value.length"
                (click)="search.value = ''; onSearch(search.value)"
            >
                <mat-icon>clear</mat-icon>
            </button>
        </div>
    </div>

    <div
        class="templates"
        [fxLayout]="fitTemplatesInSmallScreen ? 'column' : 'row wrap'"
        fxLayoutAlign="start"
        fxLayoutGap="1em"
    >
        <mat-card [fxFlex]="fitTemplatesInSmallScreen ? '1 1 100%' : '1 1 30%'">
            <div mat-card-image class="template-shadow">
                <div fxLayout="row" fxLayoutAlign="center" class="template-preview">
                    <div class="template-shadow">
                        <div fxFlex="0 0 auto" class="template-preview-contents" [innerHTML]="newContent"></div>
                    </div>
                </div>
            </div>
            <mat-card-title class="title" i18n="@@newTemplate"> New template </mat-card-title>
            <mat-card-actions fxLayout="row">
                <button type="button" fxFlex="0 0 auto" (click)="createTemplate()" mat-button i18n="@@createTemplate">
                    Create template
                </button>
            </mat-card-actions>
        </mat-card>

        <mat-card fxFlex="1 1 32%" *ngFor="let template of templates | async">
            <div mat-card-image class="template-shadow">
                <div fxLayout="row" fxLayoutAlign="center" class="template-preview">
                    <div class="template-shadow">
                        <div fxFlex="0 0 auto" class="template-preview-contents" [innerHTML]="template.body"></div>
                    </div>
                </div>
            </div>
            <mat-card-title class="title">{{ template.title }}</mat-card-title>
            <mat-card-actions fxLayout="row">
                <button type="button" fxFlex="0 1 auto" (click)="selectTemplate(template)" mat-button>Use</button>
                <button type="button" fxFlex="0 1 auto" (click)="editTemplate(template)" mat-button i18n="@@edit">
                    Edit
                </button>

                <div fxFlex="1 1 auto"></div>

                <button
                    *ngIf="template.editable"
                    type="button"
                    fxFlex="0 0 auto"
                    (click)="deleteTemplate(template)"
                    mat-icon-button
                    color="warn"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-card-actions>
        </mat-card>
    </div>
</ng-container>

<ng-container *ngIf="mode == 'edit'">
    <app-editor-edit-template
        @fadeInOut
        *ngIf="templateSelected"
        [template]="selectedTemplate"
        [newContent]="newContent"
        [customerId]="customerId"
        [subject]="subject"
        [fieldName]="fieldName"
        (selectTemplate)="selectTemplate($event)"
        (cancel)="onCancelled()"
    ></app-editor-edit-template>
</ng-container>
