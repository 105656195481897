<div class="wrapper-main" fxLayoutGap="10px" fxLayout="column" fxLayoutAlign="start start">
    <span>{{ customFieldForm.value.name }}</span>
    <div class="checkboxes-wrapper" fxLayout="column">
        <mat-checkbox
            fxLayoutGap="10px"
            fxLayout="column"
            *ngFor="let option of customFieldForm.value.options"
            [checked]="getCheckedOption(option)"
            (change)="updateValue($event.checked, option)"
        >
            {{ option }}
        </mat-checkbox>
    </div>
</div>
