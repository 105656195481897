<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <ng-container [formGroup]="promptsTranslation">
        <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon>comment_bank</mat-icon>
            <div fxFlex="grow" i18n>Manage my AI questions</div>
            <app-language-selector
                [formControl]="lang"
                class="icon-resource-builder"
                fxFlex="0 0 auto"
            ></app-language-selector>
        </div>

        <div formArrayName="promptsFormArray">
            <ng-container *ngFor="let prompt of promptsArray.controls; let promptIndex = index">
                <div fxLayoutAlign="start center" fxLayoutGap="10px">
                    <mat-form-field fxFlex="0 1 100%">
                        <textarea
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="1"
                            cdkAutosizeMaxRows="15"
                            #input
                            matInput
                            type="text"
                            placeholder="Enter a favourite prompt"
                            i18n-placeholder
                            [formControl]="prompt | cast"
                        >
                        </textarea>
                    </mat-form-field>
                    <button
                        *ngIf="!!prompt.value"
                        matTooltip="Remove prompt"
                        i18n-matTooltip
                        type="button"
                        class="open-link"
                        mat-icon-button
                        (click)="removePrompt(promptIndex)"
                    >
                        <mat-icon class="removeIcon">remove_circle_outline</mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <div class="add-prompt" fxLayoutAlign="end center">
        <button matTooltip="Add prompt" i18n-matTooltip type="button" mat-icon-button (click)="addNewPrompt()">
            <mat-icon class="removeIcon">add_circle</mat-icon>
        </button>
    </div>

    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button fxFlex="0 0 auto" *ngIf="!saving" class="mobile-button positive" (click)="submit()" mat-button i18n>
            Save
        </button>
        <app-saving-button *ngIf="saving"></app-saving-button>
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button i18n>
            Cancel
        </button>
    </div>
</div>
