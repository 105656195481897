import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { ResourceOpportunity, ResourceOpportunityService } from '@klickdata/core/opportunity';
import { User, UserOccasion } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import * as moment from 'moment';
import { switchMap, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'app-users-select-tools',
    templateUrl: './users-select-tools.component.html',
    styleUrls: ['./users-select-tools.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersSelectToolsComponent extends OnDestroyHandler implements OnInit {
    @Input() user: User;
    @Input() maxDate;
    @Input() minDate;
    public endDate = new FormControl();
    @Output() onUpdateDate: EventEmitter<UserOccasion> = new EventEmitter<UserOccasion>();
    @Output() onCancelAssign: EventEmitter<any> = new EventEmitter<any>();
    @Output() onReminder: EventEmitter<any> = new EventEmitter<any>();
    constructor(protected oppoService: ResourceOpportunityService, protected messageService: MessageService) {
        super();
    }
    ngOnInit(): void {
        this.endDate.setValue(this.user.occasion?.end_date);
        this.endDate.valueChanges
            .pipe(
                switchMap((value) =>
                    this.oppoService.update(
                        new ResourceOpportunity().getData({
                            id: this.user.occasion.id,
                            occasion_end_date: value,
                        })
                    )
                ),
                takeUntil(this.destroy)
            )
            .subscribe((occasion) => {
                this.onUpdateDate.emit({
                    ...this.user.occasion,
                    end_date: occasion.occasion_end_date.format('YYYY-MM-DD'),
                });
                this.messageService.openMessage(MessageSavedComponent, $localize`Assignment end date updated.`);
            });
    }

    deleteOcc() {
        this.oppoService
            .destroy(this.user.occasion.id)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.onCancelAssign.emit();
                this.messageService.openMessage(MessageErrorComponent, $localize`Assignment has been cancelled.`);
            });
    }

    notify() {}
}
