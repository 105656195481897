<mat-list *ngIf="alternatives" class="alts-form">
    <ng-container [ngSwitch]="questionType">
        <mat-radio-group
            *ngSwitchCase="QuestionTypes.RADIO"
            (change)="updateCorrect($event)"
            [value]="getCorrect()"
            [disabled]="!usePoints"
        >
            <app-resource-builder-radio
                *ngFor="let alternative of formArray.controls; let i = index"
                (onShowExplaination)="$event && showExplaination(alternatives[i], i, alternative)"
                [alternative]="alternatives[i]"
                [editable]="editable"
                [alternativeForm]="alternative | cast"
            >
            </app-resource-builder-radio>
        </mat-radio-group>

        <ng-container *ngSwitchCase="QuestionTypes.CHECKBOX">
            <app-resource-builder-checkbox
                *ngFor="let alternative of formArray.controls; let i = index"
                (onShowExplaination)="$event && showExplaination(alternatives[i], i, alternative)"
                [alternative]="alternatives[i]"
                [alternativeForm]="alternative | cast"
                [editable]="editable"
            ></app-resource-builder-checkbox>
        </ng-container>

        <div *ngSwitchDefault></div>
    </ng-container>

    <mat-list-item *ngIf="questionType === QuestionTypes.CHECKBOX || questionType === QuestionTypes.RADIO">
        <button [disabled]="!editable" matLine mat-button color="primary" (click)="addAlternative()">
            <mat-icon class="md-18 grey">add_circle</mat-icon>
            <ng-container i18n>Add alternative</ng-container>
        </button>
    </mat-list-item>
</mat-list>
