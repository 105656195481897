<div class="notes-tabs-wrap">
    <mat-tab-group *ngIf="authUser$ | async; let authUser; else: loading" #tabGroup ngClass.xs="xs">
        <mat-tab>
            <ng-template mat-tab-label i18n> Created notes </ng-template>
            <ng-template matTabContent>
                <app-notes-resource-listing-owned [authUser]="authUser"></app-notes-resource-listing-owned>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label i18n> Shared notes </ng-template>
            <ng-template matTabContent>
                <app-notes-resource-listing-shared></app-notes-resource-listing-shared>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
