<div class="wrapper" [ngClass]="{ 'has-no-items': items.length == 0 }">
    <label class="label">{{ label }}</label>
    <div class="items-wrap" cdkDropList (cdkDropListDropped)="drop($event)">
        <app-inputs-item
            [cdkDragDisabled]="!sortable"
            cdkDrag
            *ngFor="let item of items; let index = index"
            [data]="item"
            [index]="index"
            (onUpdateItem)="update($event)"
            (onRemoveItem)="remove($event)"
        >
            <button *ngIf="sortable" class="sort" matTooltip="Sort" mat-icon-button i18n-matTooltip cdkDragHandle>
                <mat-icon>sort</mat-icon>
            </button>
        </app-inputs-item>
    </div>
    <div class="last-row">
        <app-user-setting-input type="text" [placeholder]="placeholder" [control]="control | cast">
        </app-user-setting-input>
        <button
            [disabled]="control.value === ''"
            class="btn"
            matTooltip="Add"
            mat-icon-button
            i18n-matTooltip="@@add"
            (click)="add()"
        >
            <mat-icon>add_circle</mat-icon>
        </button>
    </div>
</div>
