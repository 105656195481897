<mat-expansion-panel
    [expanded]="index == opened && !itemForm.disabled"
    (opened)="opened = index"
    (closed)="opened === index && (opened = -1)"
    [disabled]="itemForm.disabled"
    #expansionPanel
>
    <mat-expansion-panel-header
        class="on-hover-mat-item"
        fxLayoutGap="10px"
        fxLayout="row"
        fxLayoutAlign="space-between center"
    >
        <div fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
            <mat-checkbox
                [disabled]="!itemForm.value.name || itemForm.disabled"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(index) : null"
                [checked]="selection.isSelected(index)"
            >
            </mat-checkbox>
            <span class="title-item {{ disabled }}">{{ materialFileType.value }} - {{ itemForm.value.title }}</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <ng-container *ngIf="expansionPanel.expanded">
                <button
                    [disabled]="!itemForm.value.name || itemForm.disabled"
                    (click)="$event.stopPropagation(); duplicateItem(index)"
                    class="tools-btn-icon tools-btn"
                    mat-icon-button
                    matTooltip="Duplicate"
                    i18n-matTooltip="@@duplicate"
                >
                    <mat-icon class="material-icons-outlined">file_copy</mat-icon>
                </button>
                <button
                    [disabled]="itemForm.disabled"
                    (click)="$event.stopPropagation(); deleteItem(index)"
                    class="tools-btn"
                    mat-icon-button
                    matTooltip="Delete"
                    i18n-matTooltip="@@delete"
                >
                    <mat-icon class="material-icons-outlined">delete</mat-icon>
                </button>
            </ng-container>
            <mat-icon *ngIf="disabled" class="add-ai-btn on-loading">smart_toy</mat-icon>
            <ng-content *ngIf="!expansionPanel.expanded"></ng-content>
        </div>
    </mat-expansion-panel-header>
    <div class="file-wrapper" fxLayout="column">
        <ng-container *ngIf="itemForm.value.name">
            <div fxFlex="0 1 100%" fxLayout="column" fxLayoutAlign="center start">
                <div class="file-parent-wrap">
                    <app-media-view
                        #mediaView
                        fxLayoutAlign="start center"
                        fxFlex="0 0 100%"
                        fxFlex.lt-md="0 0 100%"
                        [media_id]="itemForm.value.name"
                    >
                        <button
                            class="remove-file"
                            color="warn"
                            type="button"
                            fxFlex="0 1 auto"
                            mat-icon-button
                            matTooltip="Remove file"
                            i18n-matTooltip
                            (click)="removeFile()"
                        >
                            <mat-icon class="md-24">delete_outline</mat-icon>
                        </button>
                    </app-media-view>
                </div>
                <mat-checkbox
                    [formControl]="itemForm.get('can_download') | cast"
                    color="primary"
                    class="can-download-wrapper mt-1"
                >
                    <div class="can-download" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <mat-icon class="material-icons-outlined">download_for_offline</mat-icon>
                        <span fxLayoutAlign="start center" fxLayoutGap="5px">
                            <span i18n>Learners can download</span>
                            <!-- <span class="item-name-download">{{ itemForm.value.title ?? itemForm.value.name }}</span> -->
                        </span>
                    </div></mat-checkbox
                >
            </div>
        </ng-container>
        <ng-container *ngIf="!itemForm.value.name">
            <div class="input-wrap" fxFlex="0 1 100%" fxLayout="row wrap" fxLayoutAlign="center center">
                <div
                    [ngStyle]="dropStyle"
                    fxFlex="0 0 50%"
                    fxFlex.lt-md="0 0 100%"
                    class="media-dropzone"
                    fxLayout="row wrap"
                    fxLayoutAlign="center center"
                >
                    <input
                        #fileInput
                        class="media-upload-button"
                        type="file"
                        multiple
                        (change)="prepareFiles($event)"
                        [disabled]="onSavingFile | async"
                        accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,.ppt, .pptx,
                text/plain, application/pdf,.xlsx,.xls, image/* ,.heic,audio/*, video/*, application/vnd.apple.keynote"
                    />
                </div>

                <div class="progress-wrapper" *ngIf="progress$ | async; let progress">
                    <mat-progress-bar class="progress" mode="determinate" [value]="progress"></mat-progress-bar>
                    <span class="progress-value">{{ progress }}%</span>
                </div>
            </div>
        </ng-container>
    </div>
</mat-expansion-panel>
