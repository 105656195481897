import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { Resource } from '@klickdata/core/resource';
import { Player } from '@klickdata/player/src/core/player/player';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CourseItemsActionsOnNext } from '../../course-plan/course-details-items/course-details-items.component';
import { PlayerItem, PlayerItemData } from './player-item';

/**
 * Is used instead of the old config service for player specific tasks.
 */
@Injectable()
export class PlayerService {
    protected player: Player = new Player();
    protected resourceUrl: string;
    private showSlider: Subject<boolean> = new Subject();
    private sliderData: Subject<SideNaveData> = new Subject();
    private activeResourceInTC: Subject<Resource> = new Subject();
    protected sideNavResponseData: Subject<any> = new Subject();
    protected sideNavActionSuject: Subject<SideNaveActionsTypes> = new Subject();
    protected courseItemActionsOnNext: BehaviorSubject<CourseItemsActionsOnNext[]> = new BehaviorSubject<
        CourseItemsActionsOnNext[]
    >([]);
    protected onSignoff: BehaviorSubject<{ didSignoff: boolean; signoff: { body: string } }> = new BehaviorSubject(
        null
    );

    constructor(protected builder: RequestBuilderService, protected config: ConfigService) {
        this.resourceUrl = `${this.config.config.apiUrl}player`;
    }

    public changeSliderStatus(state: boolean) {
        this.showSlider.next(state);
    }
    public updateSliderData(data: SideNaveData) {
        this.sliderData.next(data);
    }
    public updateActiveResourecinTC(res: Resource) {
        this.activeResourceInTC.next(res);
    }
    public getActiveResourecinTC(): Observable<Resource> {
        return this.activeResourceInTC.asObservable();
    }
    public getSideNavAction(): Observable<SideNaveActionsTypes> {
        return this.sideNavActionSuject.asObservable();
    }
    public setSideNavAction(value?: SideNaveActionsTypes): void {
        this.sideNavActionSuject.next(value);
    }
    public setSideNavResponseData(data: any) {
        this.sideNavResponseData.next(data);
    }
    public getSideNavResponseData(): Observable<any> {
        return this.sideNavResponseData.asObservable().pipe(first());
    }
    public getSliderStatus(): Observable<boolean> {
        return this.showSlider.asObservable();
    }
    public getSliderData(): Observable<SideNaveData> {
        return this.sliderData.asObservable();
    }
    public getCourseItemActionsOnNext(): Observable<CourseItemsActionsOnNext[]> {
        return this.courseItemActionsOnNext.asObservable();
    }
    public setCourseItemActionsOnNext(value?: CourseItemsActionsOnNext[]): void {
        this.courseItemActionsOnNext.next(value);
    }

    public getSignOff(): Observable<{ didSignoff: boolean; signoff: { body: string } }> {
        return this.onSignoff.asObservable();
    }
    public setSignOff(value?: { didSignoff: boolean; signoff: { body: string } }): void {
        this.onSignoff.next(value);
    }
    public start(item: PlayerItemData): Observable<PlayerItem> {
        return this.builder
            .post<PlayerItemData>(`${this.resourceUrl}/start`, item)
            .request()
            .pipe(map((res) => new PlayerItem(res.data)));
    }

    public update(resourceId: number, item: PlayerItemData): Observable<PlayerItem> {
        return this.builder
            .post<PlayerItemData>(`${this.resourceUrl}/update/${resourceId}`, item)
            .request()
            .pipe(map((res) => new PlayerItem(res.data)));
    }

    get opportunityId(): number {
        return this.player.opportunityId;
    }

    set opportunityId(opportunityId: number) {
        this.player.opportunityId = opportunityId;
    }

    get referrer(): string {
        return this.player.referrer;
    }

    set referrer(referrer: string) {
        this.player.referrer = referrer;
    }
}
