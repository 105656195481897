import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Utils } from '@klickdata/core/util';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import * as moment from 'moment';
import { startWith, takeUntil } from 'rxjs';

@Component({
    selector: 'app-menu-side-update-recurring',
    templateUrl: './menu-side-update-recurring.component.html',
    styleUrls: ['./menu-side-update-recurring.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideUpdateRecurringComponent extends OnDestroyHandler implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public recurringForm: FormGroup;
    public recurringPeriodControl = new FormControl('null');

    constructor(protected fb: FormBuilder, protected cdr: ChangeDetectorRef) {
        super();
        this.recurringForm = this.fb.group({
            recurring: [0],
        });
    }

    ngOnInit(): void {
        this.recurringForm
            .get('recurring')
            .valueChanges.pipe(takeUntil(this.destroy))
            .subscribe((days: number) => {
                this.recurringPeriodControl.patchValue(Utils.daysToString(days), { emitEvent: false });
            });

        if (this.navData.data.type != 'updateRecurringResource') {
            this.recurringForm.addControl(
                'sorting_code',
                new FormControl(
                    this.navData.data.resource.recurring?.sorting_code ?? this.navData.data.resource.sorting_code ?? 0
                )
            );
        }
        if (this.navData.data.resource?.recurring) {
            this.updateForm();
        }
        this.recurringPeriodControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe((value) => {
            if (value == 'null') {
                this.recurringForm.get('recurring').patchValue(null, { emitEvent: false });
            } else if (value == 'custom') {
                this.recurringForm.get('recurring')?.reset();
            } else {
                const days = Utils.stringToDays(value);
                this.recurringForm.get('recurring')?.setValue(days, { emitEvent: false });
            }
        });
        this.cdr.markForCheck();
    }
    updateForm() {
        if (this.navData.data.resource?.recurring?.recurring) {
            this.recurringForm.patchValue(this.navData.data.resource.recurring);
        } else {
            this.recurringPeriodControl.patchValue('null');
        }
    }

    public submitRecurring() {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                value: this.recurringForm.value,
                type: this.navData.data.type,
            },
        });
    }
}
