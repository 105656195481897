import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { Resource, AppScope } from '@klickdata/core/resource';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-add-course',
    templateUrl: './add-course.component.html',
    styleUrls: ['./add-course.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCourseComponent implements OnInit {
    @Input() selection: number[] = [];
    @Output() add: EventEmitter<Resource> = new EventEmitter<Resource>();
    AppScope = AppScope;

    public isAdmin$: Observable<boolean>;
    constructor(protected auth: AuthService) {}
    ngOnInit() {}
}
