<div fxLayout="column" class="main-wrapper-core">
    <div class="header-wrap" fxLayoutAlign="space-between center" fxLayout="row">
        <span i18n class="title-label">Test questions</span>
        <!-- <button mat-icon-button (click)="toggleQs()" matTooltip="show/hide questions" i18n-matTooltip>
            <mat-icon>expand_more</mat-icon>
        </button> -->
    </div>

    <div class="w-100">
        <app-resource-builder
            fxFlex="0 0 100%"
            #builderForm
            [resource]="resource"
            [language_id]="resourceForm?.get('language_id').value"
            [questionTypes]="questionTypes"
            [resource_type_id]="ResourceTypes.GeneralTest"
            [resourceType]="ResourceTypes.TEST"
            (saved)="$event && savingQuestions && submitResourceRegardlessQs()"
            [active]="bottomToolSheet"
            [hideAddItems]="false"
            class="res-builder-column-layout w-100 m-10-0"
            [ngClass]="{ 'rb-is-empty': isEmpty(resourceBuilder?.getService().getForm() | async) }"
        >
        </app-resource-builder>
    </div>
</div>

<ng-template #contentTemplateToBeInParent>
    <!--
            Grade system KL,EB agreed about:
            1. Test should has it's own default grade.
            2. When test included into course (as final test) = test default grade suggested for course creator to use it or choose another.
        -->
    <mat-expansion-panel
        *ngIf="resource?.video_url"
        [expanded]="true"
        class="user-manage-course-expantion res-creat-manag"
    >
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>smart_display</mat-icon>
                <div i18n>Video link</div>
                <mat-icon class="icon-explain" matTooltip="Priority">help_outline</mat-icon>
            </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end center">
                <span
                    matTooltip="Indicator of field change"
                    i18n-matTooltip
                    class="cell-indicator"
                    [ngClass]="{ green: resourceForm.get('priority').dirty }"
                ></span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div fxLayoutGap="5px" fxLayout="row" class="video-url mt-1">
                <mat-icon>link</mat-icon>
                <span (click)="goToLink(resource?.video_url)">{{ resource?.video_url }}</span>
                <mat-icon class="open">open_in_new</mat-icon>
            </div>
        </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true" class="user-manage-course-expantion res-creat-manag">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>grade</mat-icon>
                <div i18n>Grade system</div>
                <mat-icon class="icon-explain" matTooltip="Priority">help_outline</mat-icon>
            </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end center">
                <span
                    matTooltip="Indicator of field change"
                    i18n-matTooltip
                    class="cell-indicator"
                    [ngClass]="{ green: resourceForm.get('priority').dirty }"
                ></span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <app-admin-grade-system-control
                class="res-creator"
                fxFlex="0 1 100%"
                [resource]="resource"
                [languageId]="resourceForm?.get('language_id').value"
                [gradeControl]="resourceForm?.get('grade_system_id') | cast"
            >
            </app-admin-grade-system-control>
        </ng-template>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true" class="user-manage-course-expantion res-creat-manag">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>low_priority</mat-icon>
                <div i18n>Priority</div>
                <mat-icon class="icon-explain" matTooltip="Priority">help_outline</mat-icon>
            </mat-panel-title>
            <mat-panel-description fxLayoutAlign="end center">
                <span
                    matTooltip="Indicator of field change"
                    i18n-matTooltip
                    class="cell-indicator"
                    [ngClass]="{ green: resourceForm.get('priority').dirty }"
                ></span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
            <div fxLayoutGap="4px" fxLayout="column" class="w-50">
                <mat-form-field class="role-field">
                    <mat-select
                        placeholder="Select test priority"
                        i18n-placeholder
                        fxFlex="1 1 100%"
                        formControlName="priority"
                    >
                        <mat-option value="a1" i18n>A1</mat-option>
                        <mat-option value="a2" i18n>A2</mat-option>
                        <mat-option value="a3" i18n>A3</mat-option>
                        <mat-option value="b1" i18n>B1</mat-option>
                        <mat-option value="b2" i18n>B2</mat-option>
                        <mat-option value="b3" i18n>B3</mat-option>
                        <mat-option value="c1" i18n>C1</mat-option>
                        <mat-option value="c2" i18n>C2</mat-option>
                        <mat-option value="c3" i18n>C3</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-template>
    </mat-expansion-panel>
</ng-template>
