import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
    moduleId: module.id,
    selector: 'app-page-header',
    templateUrl: 'page-header.component.html',
    styleUrls: ['page-header.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.225s cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                style({ opacity: 0 }),
                animate('0.225s cubic-bezier(0.0, 0.0, 0.2, 1)', style({ opacity: 1 })),
            ]),
        ]),
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent implements OnInit {
    @HostBinding('@fadeIn')
    @Input()
    sidenav: MatSidenav;

    constructor() {}

    ngOnInit() {}
}
