import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ResourceOpportunity, ResourceOpportunityService } from '@klickdata/core/opportunity';
import { User } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { BehaviorSubject, filter, switchMap, takeUntil } from 'rxjs';
import { AddFormativeEvaluationComponent } from './add-formative-evaluation/add-formative-evaluation.component';
import { FormativeEvaluationBottomSheetComponent } from './formative-evaluation-bottom-sheet/formative-evaluation-bottom-sheet.component';

@Component({
    selector: 'app-formative-evaluation',
    templateUrl: './formative-evaluation.component.html',
    styleUrls: ['./formative-evaluation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormativeEvaluationComponent extends OnDestroyHandler implements OnChanges {
    @Input() occasion: ResourceOpportunity;
    @Input() authUser: User;
    public canAddEval$ = new BehaviorSubject<boolean>(false);
    currentIndex = 0;
    constructor(
        protected bottomSheet: MatBottomSheet,
        protected cdr: ChangeDetectorRef,
        protected occasionService: ResourceOpportunityService
    ) {
        super();
    }

    ngOnChanges(): void {
        if (this.occasion && this.authUser) {
            this.checkCanAddEval();
        }
    }
    private checkCanAddEval() {
        this.canAddEval$.next(
            !this.occasion.evaluations ||
                !this.occasion.evaluations?.length ||
                !this.occasion.evaluations?.find((ev) => ev.evaluator_id == this.authUser.id)
        );
    }

    previousItem() {
        this.currentIndex--;
    }

    nextItem() {
        this.currentIndex++;
    }
    public addEvaluation(data = {}) {
        this.bottomSheet
            .open(FormativeEvaluationBottomSheetComponent, {
                data: data,
                panelClass: 'evaluation-sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result),
                switchMap((data) => this.occasionService.update({ id: this.occasion.id, ...data }))
            )
            .subscribe((occasion) => {
                this.occasion = occasion;
                this.checkCanAddEval();
                this.currentIndex = 0;
                this.cdr.markForCheck();
            });
    }
}
