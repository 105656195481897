import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer } from '@klickdata/core/customer';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { HrNotes, UserNotesService } from '@klickdata/core/user-notes';
import { Utils } from '@klickdata/core/util';
import { HrNotesListingService } from 'apps/klickdata/src/app/shared/hr-notes/hr-notes-listing.service';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-task-notes-list',
    templateUrl: './task-notes-list.component.html',
    styleUrls: ['./task-notes-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [HrNotesListingService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TaskNotesListComponent implements OnInit {
    @Input() public customer: Customer;
    @Input() public taskId: number;
    @Output() public saving: EventEmitter<boolean> = new EventEmitter();
    public destroy: Subject<boolean> = new Subject<boolean>();
    public dataSource = new TableSource<HrNotes>();
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    public columns = ['created_at', 'author', 'notes', 'tools'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    public predefinedTimeSpentOptions: SelectFilterOption[];
    public userRoleOptions: SelectFilterOption[];
    public isMobile: boolean;
    public expandedRow: User | null;
    public expandedCell: User | null;

    constructor(
        protected hrNotesService: HrNotesListingService,
        protected route: ActivatedRoute,
        protected router: Router,

        protected cdr: ChangeDetectorRef,
        protected mobile: MobileService,
        protected notesService: UserNotesService,
        protected message: MessageService
    ) {
        this.predefinedTimeSpentOptions = Utils.getPredefinedTimeSpentOptions();
        this.userRoleOptions = Utils.getUserRoleOptions();
    }

    ngOnInit(): void {
        this.dataSource.service = this.hrNotesService;
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMobile) => (this.isMobile = isMobile));
    }
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        if (this.filter) {
            if (this.taskId) {
                this.filter.createOrUpdateFilter([new Filter('task', [this.taskId])]);
                this.filter.setActiveFilter(GlobalFilterProperty.TIME_SPENT);
            }
        }
    }
    public addNote() {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_TEXT_AREA,
            data: {
                icon: 'document_scanner',
                title: $localize`Add Task note`,
                placeholder: $localize`Type in your note`,
                btnLabel: $localize`Add note`,
                hasMediaUploader: true,
                scope: AppScope.TASK,
                type: 'create-hrNote',
            },
        });

        this.mobile
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'create-hrNote'),
                switchMap((data) =>
                    this.notesService.addHrNote({
                        body: data.value,
                        task_id: this.taskId,
                        media_ids: data.media_ids,
                    })
                ),
                takeUntil(this.destroy)
            )
            .subscribe((note) => {
                if (note) {
                    this.dataSource.add(note);
                }
            });
    }
    public editNote(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_TEXT_AREA,
            data: {
                icon: 'document_scanner',
                title: $localize`Edit Note`,
                btnLabel: $localize`Edit note`,
                hasMediaUploader: true,
                scope: AppScope.TASK,
                type: 'edit-hrNote',
                value: hrNote.body,
                media_ids: hrNote.media_ids,
            },
        });

        this.mobile
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'edit-hrNote'),
                switchMap((data) =>
                    this.notesService.updateHrNote({ id: hrNote.id, body: data.value, media_ids: data.media_ids })
                ),
                takeUntil(this.destroy)
            )
            .subscribe((note) => {
                if (note) {
                    this.dataSource.replace(note);
                    this.message.openMessage(MessageSavedComponent, $localize`Note edited successfully`);
                }
            });
    }
    public addComment(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_TEXT_AREA,
            data: {
                icon: 'maps_ugc',
                title: $localize`Add comment`,
                placeholder: $localize`Type in your comment`,
                btnLabel: $localize`Add comment`,
                hasMediaUploader: true,
                scope: AppScope.TASK,
                type: 'comment',
            },
        });
        this.mobile
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'comment'),
                switchMap((data) =>
                    this.notesService.addHrNote({
                        parent_id: hrNote.id,
                        body: data.value,
                        media_ids: data.media_ids,
                    })
                ),
                takeUntil(this.destroy)
            )
            .subscribe((comment) => {
                if (comment) {
                    const data = comment.getData();
                    hrNote.comments = hrNote.comments?.length ? [...hrNote.comments, data] : [data];
                    this.cdr.markForCheck();
                    this.message.openMessage(MessageSavedComponent, $localize`Comment added successfully`);
                }
            });
    }
    public deleteNote(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: $localize`Delete Note`,
                contentBody: $localize`Are you sure you want to delete this note ?`,
                positiveBtn: $localize`Delete Note`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.notesService.deleteHrNote(hrNote.id))
            )
            .subscribe((res) => {
                if (res) {
                    this.dataSource.removeById(hrNote.id);
                    this.message.openMessage(MessageSavedComponent, $localize`Note deleted successfully`);
                }
            });
    }
    public showNoteMedia(hrNote: HrNotes) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.MEDIA_ATTACHMENT,
            data: {
                mediaIds: hrNote.media_ids,
            },
        });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
