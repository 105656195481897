import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http';
import {
    ResourceStatistic,
    ResourceStatisticData,
} from '@klickdata/core/resource/src/statistics/resource-statistic.model';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ResourceStatisticService {
    protected url: string;
    protected customer_id: Observable<number>;

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected auth: AuthService
    ) {
        this.url = `${this.config.config.apiUrl}resource-statistics`;
        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map(customer => customer.id)
        );
    }

    protected fill(data: ResourceStatisticData): ResourceStatistic {
        return new ResourceStatistic(data);
    }
}
