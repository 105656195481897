import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoggerService } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import { Observable, Subject, merge } from 'rxjs';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { GlobalFilterProperty } from '../global-filters-types.enum';
import { Filter, FilterCollection } from './filter';
import { FilterBase } from './filter-base';

@Component({
    selector: 'app-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFilterComponent implements AfterContentInit, OnDestroy {
    @Input() cacheScope: AppScope;
    @Input() color: string;
    @Input() inGlobalFilter = false;
    @Input() filtersTogglesInOnBtn = false;
    @Input() toggleFilterButtons = true;
    @Input() defaultFilter = GlobalFilterProperty.SEARCH;
    @Output() filterChange: EventEmitter<FilterCollection<string | number>> = new EventEmitter<
        FilterCollection<string | number>
    >();
    @ContentChildren(FilterBase, { descendants: true }) filterComps: QueryList<FilterBase>;
    private destroy: Subject<boolean> = new Subject<boolean>();
    private _selectedFilter: FormControl<GlobalFilterProperty | string>;
    get selectedFilter(): FormControl<GlobalFilterProperty | string> {
        return this._selectedFilter;
    }
    public isMobile: Observable<boolean>;
    protected _filter: FilterCollection<string | number> = new FilterCollection();
    public get filter(): FilterCollection<string | number> | null {
        return this._filter;
    }
    public activeAppliedFilters: Filter<string | number>[] = [];
    GlobalFilterProperty = GlobalFilterProperty;
    Utils = Utils;
    private cacheLoaded: boolean;

    constructor(
        protected cdRef: ChangeDetectorRef,
        protected auth: AuthService,
        protected mobile: MobileService,
        protected logger: LoggerService
    ) {
        this.isMobile = this.mobile.isMobile().pipe(takeUntil(this.destroy), shareReplay());
    }
    public ngAfterContentInit(): void {
        this._selectedFilter = new FormControl(this.defaultFilter);

        const obs: Observable<Filter<string | number>>[] = [];
        this.filterComps?.forEach((filterComp) => {
            obs.push(this.mapFilterChange(filterComp));
            if (filterComp.filterRemoval) {
                obs.push(this.removeFilter(filterComp.filterRemoval));
            }
        });

        merge(...obs)
            .pipe(takeUntil(this.destroy))
            .subscribe((filtr) => {
                this.prepareActiveAppliedFilters(filtr);
                this.refresh();
                this.cacheLoaded = true;
            });

        this.initCache();
    }

    private initCache() {
        this.filterComps.forEach((fltr) => {
            fltr.cacheScope = this.cacheScope;
            const mfltr = fltr.mapCache((cachedFltr) => this.pushChangeWithoutEmit(cachedFltr));
            setTimeout(() => this.prepareActiveAppliedFilters(mfltr));
        });
    }

    public filterByModel(
        filter: Filter<string | number> | Filter<string | number>[],
        withClearOfAllOtherFilters = false
    ) {
        const filters = Utils.wrapInArray(filter);
        if (withClearOfAllOtherFilters) {
            this.activeAppliedFilters
                .filter((afltr) => -1 === filters.findIndex((fltr) => fltr.property === afltr.property))
                .forEach((fltr) => this.remove(fltr));
        }
        filters.forEach((fltr) => this.updateActiveAppliedFromFilter(fltr));
        this.refresh();
    }

    private updateActiveAppliedFromFilter(filter: Filter<string | number>) {
        const filterComp = this.getFilterComponentByProperty(filter.property);
        if (filterComp) {
            const mfltr = filterComp.mapByFilter(filter, (fltr) => this.pushChangeWithoutEmit(fltr), true);
            this.prepareActiveAppliedFilters(mfltr);
        }
    }

    private mapFilterChange(fltrBase: FilterBase): Observable<Filter<string | number>> {
        return fltrBase.mapFilterChange(
            (fltr) => this.checkLoadedFromCache(fltr),
            (fltr) => this.pushChangeWithoutEmit(fltr)
        );
    }

    private checkLoadedFromCache(fltr: Filter<string | number>): boolean {
        return this.cacheLoaded || this.getFilterIndex(fltr.property) === -1 || !fltr.lazy;
    }

    prepareActiveAppliedFilters(filterInAction: Filter<string | number>): void {
        if (!filterInAction?.items) {
            return;
        }

        /**
         * 4- types of filters.
         * 1. normal
         * 2. isChip
         * 3. Composite
         * 4. filterInAction => one show in active many remove from active.
         */
        const index = this.getActiveFilterIndex(filterInAction.property);
        // already exists in active filters
        if (index !== -1) {
            const currentFltr = this.activeAppliedFilters[index];
            if (filterInAction.items?.length > 1 && filterInAction.singleActive) {
                this.activeAppliedFilters.splice(index, 1);
            } else {
                this.updateCurrentActiveFilter(currentFltr, filterInAction);
            }
        } else {
            // Don't add filters with empty items...
            if (!filterInAction.items.length || filterInAction.items.every((item) => !item)) {
                return;
            }

            // Don't add singleActive filter with many items...
            if (filterInAction.singleActive && filterInAction.items.length > 1) {
                return;
            }
            this.activeAppliedFilters.push(filterInAction);
        }
        this.cdRef.markForCheck();
    }

    private updateCurrentActiveFilter(currentFltr: Filter<string | number>, filterInAction: Filter<string | number>) {
        if (!filterInAction.items.length || filterInAction.items.every((item) => !item)) {
            this.remove(currentFltr);
        } else {
            currentFltr.items = filterInAction.items;
            currentFltr.chips = filterInAction.chips;
        }
    }

    public remove(removedFilter: Filter<string | number>) {
        const index = this.getActiveFilterIndex(removedFilter.property);
        if (index === -1) {
            // console.log('not found');
            return;
        }
        this.activeAppliedFilters.splice(index, 1);
        const filterComp = this.getFilterComponentByProperty(removedFilter.property);
        if (filterComp) {
            filterComp.remove();
        }
        this.cdRef.markForCheck();
    }

    private pushChangeWithoutEmit(mappedFilter: Filter<string | number>) {
        const index = this.getFilterIndex(mappedFilter.property);
        if (index !== -1) {
            this.filter.filters.splice(index, 1);
        }
        this.filter.filters.push(mappedFilter);
    }

    /**
     * Get filter index in filters colections
     * @param property Filter.property
     * @returns index of given filter in a collection.
     */
    private getFilterIndex(property: string): number {
        return this.getIndex(this.filter.filters, property);
    }

    private getActiveFilterIndex(property: string): number {
        return this.getIndex(this.activeAppliedFilters, property);
    }

    private getIndex(filters: Filter<string | number>[], property: string): number {
        return filters.findIndex((value: Filter<string | number>) => value.property === property);
    }

    public getFilterValue(property: string): (string | number)[] {
        return this.filter.filters.find((value: Filter<string | number>) => value.property === property).items;
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public createOrUpdateWithoutEmitFilter(filters: Filter<string | number>[], ignored?: string[]) {
        filters.forEach((fltr) => this.pushChangeWithoutEmit(fltr));
        if (ignored) {
            this.removeFromFilters(ignored);
        }
    }

    /**
     * Remove filters from applied filters.
     * @param ignored filter array to be removed for applied filters.
     */
    private removeFromFilters(ignored: string[]) {
        for (const property of ignored) {
            const index = this.getFilterIndex(property);
            if (index !== -1) {
                this.filter.filters.splice(index, 1);
            }
        }
    }

    public createOrUpdateFilter(filters: Filter<string | number>[], ignored?: string[]) {
        this.createOrUpdateWithoutEmitFilter(filters, ignored);
        this.refresh();
        this.cdRef.markForCheck();
    }

    public ignoreFilter(ignored: string[], withoutEmission?: boolean) {
        this.removeFromFilters(ignored);
        if (!withoutEmission) {
            this.refresh();
        }
        this.cdRef.markForCheck();
    }

    public refresh() {
        this.filterChange.emit(this.filter);
    }

    public removeFilter(filterRemoval: EventEmitter<Filter<string | number>>): Observable<Filter<string | number>> {
        return filterRemoval.pipe(
            map((fltr) => [fltr, this.getFilterIndex(fltr.property)] as [Filter<string | number>, number]),
            filter(([fltr, index]) => index !== -1),
            map(([removedFilter, index]) => removedFilter)
        );
    }

    public clear(excludes: Filter<string | number>[] = []): void {
        this.filterComps
            ?.filter((filterComp) => -1 === excludes.findIndex((exclude) => filterComp.equals(exclude)))
            .forEach((filterComp) => filterComp.remove());
    }

    public selectAll(): void {
        this.filterComps?.forEach((filterComp) => filterComp.selectAll());
    }

    public setActiveFilterWithQuery(property: GlobalFilterProperty, query: string): void {
        this.selectedFilter.setValue(property);
        const filterComp = this.getFilterComponentByProperty(property);
        filterComp?.setQuery(query);
    }

    public setActiveFilterWithSelectedOption(property: GlobalFilterProperty, value: string | number): void {
        this.selectedFilter.setValue(property);
        const filterComp = this.getFilterComponentByProperty(property);
        filterComp?.setSelectedOption(value);
    }

    public setActiveFilter(property: GlobalFilterProperty): void {
        this.selectedFilter.setValue(property);
    }

    public getFilterComponentByProperty(property: GlobalFilterProperty | string): FilterBase {
        return this.filterComps?.find((filterComp) => filterComp.filter.property === property);
    }

    public getFilterByProperty(property: GlobalFilterProperty | string): Filter<string | number> {
        const filterComp = this.getFilterComponentByProperty(property);
        return filterComp?.filter;
    }
    public isDefaultFilter(property: GlobalFilterProperty | string): boolean {
        return property === GlobalFilterProperty.SEARCH;
    }
}
