import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter, Input,
    OnDestroy,
    Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveData } from '@klickdata/core/mobile/src/mobile.service';
import { AlertTicket, Ticket } from '@klickdata/core/notification';
import { TicketService } from '@klickdata/core/notification/src/ticket.service';
import { AppScope } from '@klickdata/core/resource';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-alert-msg',
    templateUrl: './menu-side-alert-msg.component.html',
    styleUrls: ['./menu-side-alert-msg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAlertMsgComponent implements OnDestroy {
    @Input() public alertData: SideNaveData;
    @Input() public context = 'message';
    @Output() onClose: EventEmitter<boolean> = new EventEmitter();
    public alertOptions: string[];
    public alertForm: FormGroup;
    AppScope = AppScope;
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected changeRef: ChangeDetectorRef,
        protected ticketService: TicketService,
        protected cdr: ChangeDetectorRef,
        protected fb: FormBuilder,
        protected messageService: MessageService,
        protected router: Router
    ) {
        this.alertOptions = AlertTicket.getAllAlertSubjects();
        this.alertForm = this.fb.group({
            subject: [''],
            description: [''],
            media_ids: [[]],
        });
    }

    public submit() {
        if (!this.alertForm.value.subject && !this.alertForm.value.description) {
            return;
        }
        this.saving.emit(true);
        const ticket = new Ticket(this.alertForm.value).getData();
        this.ticketService
            .createTicket(ticket)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.onClose.emit(true);
                this.saving.emit(false);
                this.messageService.openMessage(MessageErrorComponent, $localize`Ticket created in KlickData system`);
                if (this.context === 'message') {
                    this.router.navigate(['/home/messages/center/ticket']);
                }
            });
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
