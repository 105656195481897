import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Injector,
    Input,
    LOCALE_ID,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ControlValueAccessor, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DirectionalityService } from '@klickdata/core/localization';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
// Default icons are required for TinyMCE 5.3 or above
// A theme is also required
// import 'tinymce';
// Import TinyMCE
// declare var tinymce: any;
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
// Any plugins you want to use has to be imported
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/table';

import { EditorMergeField } from '../editor-merge-field.model';
import { EditorMergeFieldService } from '../editor-merge-field.service';

export interface ButtonModel {
    text?: string; // Use the <img> element
    icon?: string; // Optionally, you can add an icon class to the button https://www.tiny.cloud/docs/advanced/editor-icon-identifiers/
    tooltip?: string; // Optionally, you can add a tooltip
    onAction: () => void;
}

@Component({
    selector: 'app-text-editor-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditorComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorComponent implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor {
    tinyLoaded: boolean;
    fetchingMergeFields: boolean;
    mergeFields: EditorMergeField[];
    @Input()
    public set fieldName(name: string) {
        this._fieldName = name;

        if (this._fieldName) {
            this.fetchingMergeFields = true;
            this.loadFields();
        }
    }

    protected _fieldName: string;
    public disabled: boolean;

    @Input()
    public placeholder: string;

    /**
     * https://www.tiny.cloud/docs/advanced/editor-icon-identifiers/
     * https://www.tiny.cloud/docs/api/tinymce.editor.ui/tinymce.editor.ui.registry/#addicon
     *
     */
    @Input()
    public customButton: ButtonModel;

    @Input()
    public title: string;

    public set body(body: string) {
        this._body = body;
    }

    public get body(): string {
        return this._body;
    }
    public _body: string;

    @Output()
    public change: EventEmitter<string> = new EventEmitter();

    @Output()
    public focus: EventEmitter<boolean> = new EventEmitter();

    @Output()
    public blur: EventEmitter<any> = new EventEmitter();
    protected destroy: Subject<boolean> = new Subject<boolean>();

    public get editorInit() {
        return {
            toolbar:
                'customButton | undo redo | formatselect | bold italic backcolor  | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist outdent indent | link image | mergeFields',
            menubar: false,
            plugins: ['link', 'paste', 'table', 'image', 'autolink', 'lists', 'autoresize'],
            skin_url: this.location.prepareExternalUrl('/tinymce/skins/ui/oxide'),
            content_css: this.location.prepareExternalUrl('/tinymce/skins/content/default/content.min.css'),
            // skin: false,
            branding: false,
            // added to make the text area rtl
            directionality: this.directionality.value,
            statusbar: true,
            readonly: this.disabled,
            automatic_uploads: true,
            paste_data_images: true,
            // autoresize_min_height: 150,
            // autoresize_bottom_margin: 0,
            autoresize: true,
            resize: true,
            min_height: 250,
            // max_height: 300,
            bottom_margin: 0,
            language_url: this.getLangURL(),
            // For adding AR I made func that return url
            // language_url: this.localeId.includes('sv')
            //     ? this.location.prepareExternalUrl('/assets/langs/sv_SE.js')
            //     : null,
            images_upload_handler: (blobInfo, success, failure) => this.uploadImages(blobInfo, success, failure),
            // init_instance_callback: editor => this.initialized.next(editor),
            setup: (editor: any) => this.setupEditor(editor),
            placeholder: this.placeholder,
        };
    }

    protected editor = null;
    constructor(
        protected mediaService: MediaService,
        protected service: EditorMergeFieldService,
        @Inject(LOCALE_ID) protected localeId: string,
        protected changeRef: ChangeDetectorRef,
        protected element: ElementRef,
        protected location: Location,
        protected injector: Injector,
        protected zone: NgZone,
        private directionality: DirectionalityService
    ) {}

    getLangURL() {
        if (this.localeId.includes('sv')) {
            this.location.prepareExternalUrl('/assets/langs/sv_SE.js');
        } else if (this.localeId.includes('ar')) {
            this.location.prepareExternalUrl('/assets/langs/ar.js');
        } else if (this.localeId.includes('de')) {
            this.location.prepareExternalUrl('/assets/langs/de.js');
        } else if (this.localeId.includes('es')) {
            this.location.prepareExternalUrl('/assets/langs/es.js');
        } else if (this.localeId.includes('fr')) {
            this.location.prepareExternalUrl('/assets/langs/fr.js');
        } else if (this.localeId.includes('it')) {
            this.location.prepareExternalUrl('/assets/langs/it.js');
        } else if (this.localeId.includes('ta')) {
            this.location.prepareExternalUrl('/assets/langs/ta.js');
        } else {
            return null;
        }
    }
    propagateChange: any = () => {};
    public ngOnInit(): void {
        const tinyTimer = timer(0, 10)
            .pipe(
                map(() => {
                    this.tinyLoaded = !!tinymce && !this.fetchingMergeFields;
                    if (this.tinyLoaded) {
                        this.changeRef.markForCheck();
                        tinyTimer.unsubscribe();
                    }
                }),
                takeUntil(this.destroy)
            )
            .subscribe();
    }
    public ngOnDestroy(): void {
        if (this.editor) {
            // Unbind all events
            // this.editor.off();
            this.editor.destroy();
            this.editor = null;
        }

        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public onMergeInsert(name: string): void {
        this.editor.insertContent(`«${name}»`);
        this.change.emit(this.editor.getContent());
    }

    protected loadFields(): void {
        this.service
            .getFieldMerge(this._fieldName.replace('-template', ''))
            .pipe(takeUntil(this.destroy))
            .subscribe(
                (fields) => {
                    this.mergeFields = fields;
                    this.fetchingMergeFields = false;
                },
                (err) => (this.fetchingMergeFields = false)
            );
    }

    private buildMergeFieldMenu(fields: EditorMergeField[]) {
        this.editor.ui.registry.addMenuButton('mergeFields', {
            text: $localize`Insert merge field`,
            fetch: (callback) => {
                const items = [];
                for (const field of fields) {
                    items.push({
                        text: field.label,
                        type: 'menuitem',
                        onAction: () => this.onMergeInsert(field.name),
                    });
                }
                callback(items);
            },
        });
    }

    protected uploadImages(blobInfo, success, failure): void {
        this.mediaService
            .uploadMedia(new File([blobInfo.blob()], blobInfo.filename()))
            .pipe(takeUntil(this.destroy))
            .subscribe(
                (media) => {
                    success(media.url);
                    this.change.emit(this.editor.getContent());
                },
                (err) => {
                    failure(
                        err && err.error && err.error.error && err.error.error.messages
                            ? err.error.error.messages.join(',')
                            : $localize`We encountered an error, please check your internet connection and reload this page.`
                    );
                }
            );
    }

    protected setupEditor(editor): void {
        this.editor = editor;

        editor.on('keyup', () => {
            this.change.emit(this.editor.getContent());
            this.propagateChange(this.editor.getContent());
        });

        editor.on('change', (change: any) => {
            let content: string;
            if (change && change.level && change.level.content) {
                content = change.level.content;
            } else {
                content = this.editor.getContent();
            }
            this.change.emit(content);
            this.propagateChange(content);
        });

        editor.on('focus', () => {
            this.focus.emit(true);
        });

        editor.on('blur', () => {
            this.focus.emit(false);
            this.blur.emit(this.editor.getContent());
        });

        if (this.mergeFields?.length) {
            this.buildMergeFieldMenu(this.mergeFields);
        }

        if (this.customButton) {
            // Add the custom button to the toolbar
            this.editor.ui.registry.addToggleButton('customButton', this.customButton);
        }
        this.changeRef.markForCheck();
    }

    public writeValue(value: string): void {
        if (typeof value === 'undefined' || value === null) {
            return;
        }
        this.body = value;
        if (this.editor) {
            this.editor.setContent(value);
            this.changeRef.markForCheck();
        }
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}

    ngAfterViewInit(): void {
        const control = this.injector.get(NgControl);
        if (control) {
            this.updateReadonlyMode(control.status === 'DISABLED');
            control.statusChanges
                .pipe(
                    map((changes) => this.updateReadonlyMode(changes === 'DISABLED')),
                    takeUntil(this.destroy)
                )
                .subscribe();
        }
    }

    private updateReadonlyMode(disabled: boolean) {
        this.disabled = disabled;
        this.editorInit.readonly = this.disabled;
        if (this.editor && this.disabled) {
            this.editor.setMode('readonly');
        }
        this.changeRef.markForCheck();
    }
}
