import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MobileService } from '@klickdata/core/mobile';
import { Resource } from '@klickdata/core/resource';
import { TableFilterComponent, TableSource } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { WelcomeMessageDialogComponent } from 'apps/klickdata/src/app/shared/email/welcome-message-dialog/welcome-message-dialog.component';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { TestResultsOverallService } from '../tests-results-overall/test-results-overall.service';

@Component({
    selector: 'app-tests-results-answers',
    templateUrl: './tests-results-answers.component.html',
    styleUrls: ['./tests-results-answers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TestResultsOverallService],
})
export class TestsResultsAnswersComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() public resource: Resource;
    public dataSource = new TableSource<User>();
    public columns = ['name'];
    public date = new Date();
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    private isMobile: boolean;
    public qsIds: string[];
    public destroy: Subject<boolean> = new Subject<boolean>();
    

    constructor(
        protected listService: TestResultsOverallService,
        protected changeRef: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected mobileService: MobileService,
        protected router: Router
    ) {}

    public ngOnInit(): void {
        this.dataSource.latestData.pipe(first()).subscribe(res => {
            this.qsIds = res.more;
            setTimeout(() => {
                this.qsIds.forEach((_, index) => this.columns.push(`${index + 1}`));
                this.columns = this.columns.concat(['star']);
                this.changeRef.detectChanges();
            });
        });
        this.dataSource.service = this.listService;

        this.mobileService
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe(mobile => (this.isMobile = mobile));
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.filter?.createOrUpdateFilter([{ property: 'answers', items: [this.resource.id] }]);
    }

    public sendEmail(user: User) {
        const dialogRef = this.dialog.open(WelcomeMessageDialogComponent, {
            width: !this.isMobile ? '70%' : '100%',
            data: { users_attach: [user.id], title: user.name },
            disableClose: true,
            panelClass: 'sheet-wrapper',
        });
    }

    public viewUserResults(user: User) {
        this.router.navigateByUrl(
            `/player/occasion/${user.finalAnswer.opportunity_id}/test/result/${user.id}?referrer=/admin/statistics/test-results/tests/${this.resource.id}`
        );
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
