import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ResponseData } from '@klickdata/core/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Resource, ResourceData } from './resource.model';
import { ResourceService } from './resource.service';
@Injectable({
    providedIn: 'root',
})
export class ResourceSocketService {
    constructor(protected resourceService: ResourceService, protected auth: AuthService) {}

    public listenToNewResource(): Observable<Resource> {
        return this.auth
            .listenUserChannel('NewResource')
            .pipe(map((res: ResponseData<ResourceData>) => this.resourceService.createResource(res.data)));
    }
    public listenToResourceUpdates(params: {
        res_id: number;
        interval?: number;
        eager?: string[] | string;
    }): Observable<Resource> {
        const reqInterval = params.interval ?? 5000;
        return this.auth
            .listenPrivate(
                `resource.${params.res_id}`,
                'ResourceUpdatedEvent',
                this.resourceService.getResourceWithCompletionTime(params.res_id, reqInterval, params.eager),
                reqInterval
            )
            .pipe(map((res: ResponseData<ResourceData>) => this.resourceService.createResource(res.data)));
    }
}
