import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AuthService } from '@klickdata/core/auth';
import { Group, GroupService } from '@klickdata/core/group';
import { LanguageService } from '@klickdata/core/localization';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveData } from '@klickdata/core/mobile';
import {
    MobileService,
    SideNaveActionsTypes,
    SideNaveDataTypes,
    SideNaveResponseData,
} from '@klickdata/core/mobile/src/mobile.service';
import { Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { User, UserService } from '@klickdata/core/user';
import { UserNotesService } from '@klickdata/core/user-notes';
import { NotePrivacy, UserNotesData, UserNotesTypes } from '@klickdata/core/user-notes/src/user-notes.model';
import { Utils } from '@klickdata/core/util';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-add-note',
    templateUrl: './menu-side-add-note.component.html',
    styleUrls: ['./menu-side-add-note.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddNoteComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    UserNotesTypes = UserNotesTypes;
    ResourceTypes = ResourceTypes;
    public addNoteForm: FormGroup;
    public noteTitle = $localize`Add a note`;
    SideNaveDataTypes = SideNaveDataTypes;
    public selectedRecepientFilter = new FormControl('users');
    public userCtrl = new FormControl();
    public groupCtrl = new FormControl();
    public userSearch$: Observable<User[]>;
    public groupSearch$: Observable<Group[]>;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public authUser: User;
    public userRecipients: User[] = [];
    public groupRecipients: Group[] = [];
    @ViewChild('userRecieverInput') userRecieverInput: ElementRef<HTMLInputElement>;
    @ViewChild('groupRecieverInput') groupRecieverInput: ElementRef<HTMLInputElement>;
    public privacyOptions: NotePrivacy[];
    public contentCtrl = new FormControl();
    public relatedResources: Resource[] = [];
    public filteredContent: BehaviorSubject<Resource[]> = new BehaviorSubject<Resource[]>([]);

    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        protected groupService: GroupService,
        protected changeRef: ChangeDetectorRef,
        protected authService: AuthService,
        protected mobileService: MobileService,
        protected resourceService: ResourceService,
        protected languageService: LanguageService,
        protected notesService: UserNotesService,
        protected messageService: MessageService
    ) {
        this.privacyOptions = Utils.getNotesPrivacyOptions();
        this.addNoteForm = this.fb.group({
            id: [''],
            user_ids: [[]],
            academy_id: [''],
            group_ids: [[]],
            privacy: ['private'],
            body: [''],
        });
        this.authService
            .getUser()
            .pipe(take(1), takeUntil(this.destroy))
            .subscribe((user) => {
                this.authUser = user;
                if (!user.isAdmin()) {
                    this.privacyOptions = [this.privacyOptions[0], this.privacyOptions[3]];
                }
                this.changeRef.markForCheck();
            });
        this.mobileService
            .getSideNavAction()
            .pipe(filter((action) => action === SideNaveActionsTypes.PANEL_CLOSED))
            .subscribe(() => {});
    }
    ngOnInit(): void {
        if (this.navData.data.status === 'update') {
            this.updateNote();
        } else {
            this.resetNote();
        }
        if (this.navData.data && this.navData.data.resources) {
            this.relatedResources = this.navData.data.resources;
            this.changeRef.markForCheck();
        }
        if (this.navData.data && this.navData.data.privacy) {
            this.addNoteForm.patchValue({ privacy: this.navData.data.privacy });
            this.addNoteForm
                .get(
                    this.navData.data.privacy === 'user'
                        ? 'user_ids'
                        : this.navData.data.privacy === 'group'
                        ? 'group_ids'
                        : 'academy_id'
                )
                .setValue(
                    this.navData.data.privacy === 'user'
                        ? [this.navData.data.user.id]
                        : this.navData.data.privacy === 'group'
                        ? [this.navData.data.group.id]
                        : this.navData.data.academy?.id
                );
        }
    }
    private updateNote() {
        this.noteTitle = $localize`Edit note`;
        this.addNoteForm.patchValue({
            id: this.navData.data.note.id,
            user_ids:
                (this.navData.data.note.users.length && this.navData.data.note.users.map((user: User) => user.id)) ||
                [],
            academy_id: this.navData.data.note.academy_id,
            group_ids:
                (this.navData.data.note.groups.length &&
                    this.navData.data.note.groups.map((group: Group) => group.id)) ||
                [],
            body: this.navData.data.note.body,
            privacy: this.navData.data.note.privacy?.value,
        });
        if (this.navData.data.note.noteResource) {
            this.relatedResources = [this.navData.data.note.noteResource];
        }
        if (this.navData.data.note.noteUsers) {
            this.userRecipients = this.navData.data.note.noteUsers;
        }
        if (this.navData.data.note.noteGroups) {
            this.groupRecipients = this.navData.data.note.noteGroups;
        }
        this.changeRef.markForCheck();
    }
    private resetNote() {
        this.addNoteForm.reset();
        this.addNoteForm.get('privacy').patchValue('private');
        this.relatedResources = [];
        this.userRecipients = [];
        this.groupRecipients = [];
        this.noteTitle = $localize`Add a note`;
        this.changeRef.markForCheck();
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.navData.data.status === 'update') {
            this.updateNote();
        } else {
            this.resetNote();
        }
    }
    ngAfterViewInit(): void {
        if (this.authUser) {
            this.userSearch$ = this.userCtrl.valueChanges
                .pipe(
                    filter((term: string) => typeof term === 'string' && term != ''),
                    debounceTime(300),
                    distinctUntilChanged()
                )
                .pipe(
                    switchMap((term) =>
                        term.trim()
                            ? this.userService.getCustomerUsers({ customerId: this.authUser.customer_id, term: term })
                            : of(<User[]>[])
                    ),
                    map((users) =>
                        users.filter(
                            (user) =>
                                this.userRecipients.findIndex((recipient) => recipient.id === user.id) === -1 &&
                                user.id !== this.authUser.id
                        )
                    ),
                    takeUntil(this.destroy)
                );

            this.groupSearch$ = this.groupCtrl.valueChanges.pipe(
                filter((term) => typeof term === 'string'),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((term) => (term.trim() ? this.groupService.getCustomerGroups(term) : of(<Group[]>[]))),
                map((groups) =>
                    groups.filter(
                        (group) => this.groupRecipients.findIndex((recipient) => recipient.id === group.id) === -1
                    )
                ),
                takeUntil(this.destroy)
            );

            combineLatest([
                this.contentCtrl.valueChanges.pipe(
                    filter((term) => typeof term === 'string'),
                    debounceTime(300),
                    distinctUntilChanged()
                ),
                this.languageService.getContentLanguage(),
            ])
                .pipe(
                    switchMap(([term, lang]) => {
                        return term.trim()
                            ? this.resourceService.getResources(null, null, 10, term, [
                                  { property: 'publicOrCustomer', items: [this.authUser.customer_id] },
                                  { property: 'language', items: [lang.id] },
                              ])
                            : EMPTY;
                    }),
                    takeUntil(this.destroy)
                )
                .subscribe((resources) => this.filteredContent.next(resources));
        }
    }

    public removeResource(res: Resource): void {
        const index = this.relatedResources.indexOf(res);
        if (index >= 0) {
            this.relatedResources.splice(index, 1);
            this.changeRef.markForCheck();
        }
    }

    public selectResource(event: MatAutocompleteSelectedEvent, auto: MatAutocomplete): void {
        this.relatedResources.push(event.option.value);
        this.contentCtrl.setValue('');
        this.changeRef.markForCheck();
    }

    public submit() {
        if (!this.addNoteForm.value.body) {
            this.messageService.openMessage(MessageErrorComponent, $localize`You need to add note text.`);
            return;
        }
        if (this.relatedResources.length === 0) {
            this.messageService.openMessage(
                MessageErrorComponent,
                $localize`You need to add a context to attach the note to.`
            );
            return;
        }
        const createOrUpdate =
            this.navData.data.status === 'update'
                ? this.notesService.updateUserNote(this.prepareNote(), ['users', 'groups', 'academy', 'resource'])
                : this.notesService.addUserNote(this.prepareNote(), ['users', 'groups', 'academy', 'resource']);

        createOrUpdate.pipe(takeUntil(this.destroy)).subscribe((note) => {
            this.onClose.emit({ action: SideNaveActionsTypes.POSITIVE, data: { note: note, type: 'userNote' } });
            this.resetNote();
        });
    }
    private prepareNote(): UserNotesData {
        let data: UserNotesData = {};
        data.body = this.addNoteForm.value.body;
        data.resource_id = this.relatedResources[0].id;
        if (this.navData.data.status === 'update') {
            data.id = this.navData.data.note.id;
        }
        if (this.addNoteForm.value.privacy === 'private') {
            data.user_ids = [this.authUser.id];
        }
        if (this.addNoteForm.value.privacy === 'user') {
            data.user_ids = this.addNoteForm.value.user_ids;
        }
        if (this.addNoteForm.value.privacy === 'group') {
            data.group_ids = this.addNoteForm.value.group_ids;
        }
        if (this.addNoteForm.value.privacy === 'public') {
            data.public = true;
        }
        if (this.addNoteForm.value.privacy === 'academy' && this.navData.data?.customer) {
            data.academy_id = this.navData.data.customer.id;
        }

        return data;
    }
    public selected(auto: MatAutocomplete, event: MatAutocompleteSelectedEvent, isUserSearch: boolean): void {
        if (isUserSearch) {
            this.userRecipients.push(event.option.value);
            this.userRecieverInput.nativeElement.value = '';
            this.userCtrl.setValue('');
        } else {
            this.groupRecipients.push(event.option.value);
            this.groupRecieverInput.nativeElement.value = '';
            this.groupCtrl.setValue('');
        }

        auto.options.reset([]);
        this.updateReciepentIds();
        this.changeRef.markForCheck();
    }
    private updateReciepentIds() {
        this.addNoteForm.get('user_ids').setValue(this.userRecipients.map((user) => user.id));
        this.addNoteForm.get('group_ids').setValue(this.groupRecipients.map((group) => group.id));
    }
    public removeUser(user: User): void {
        const index = this.userRecipients.indexOf(user);
        if (index >= 0) {
            this.userRecipients.splice(index, 1);
            this.updateReciepentIds();
        }
    }
    public removeGroup(group: Group): void {
        const index = this.groupRecipients.indexOf(group);
        if (index >= 0) {
            this.groupRecipients.splice(index, 1);
            this.updateReciepentIds();
        }
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
