<ng-container *ngIf="occasion">
    <ng-container *ngIf="authUser$ | async; let authUser">
        <div
            [ngClass]="{ 'result-wrapper': isCourseItem }"
            class="item-container test-results"
            *ngIf="resultsObs | async; let questionData; else: loading"
        >
            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-card class="mb-1 wrapper-card w-100" fxFlex.xs="100" fxFlex.sm="100">
                    <mat-card-header class="test-card-header" fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-card-title-group>
                            <mat-card-title class="title" *ngIf="resource">
                                <span *ngIf="!user; else username" i18n>Your</span>&nbsp;<span i18n>result on</span>
                                {{ resource.title }}
                            </mat-card-title>
                            <mat-card-subtitle class="subtitle"></mat-card-subtitle>
                        </mat-card-title-group>
                        <button
                            class="action-button"
                            *ngIf="isCourseItem"
                            color="primary"
                            mat-raised-button
                            type="button"
                            (click)="done()"
                            i18n="@@next"
                        >
                            Next
                        </button>
                    </mat-card-header>
                    <mat-card-content class="content">
                        <div fxLayout="row wrap" class="relative">
                            <div
                                *ngIf="tab_group.selectedIndex === 1"
                                (click)="open()"
                                class="toggler"
                                fxLayoutAlign="end center"
                            >
                                <ng-container *ngTemplateOutlet="toggllers"></ng-container>
                                <span matTooltip="Hide all" i18n-matTooltip="@@resultHideAll" *ngIf="openAll === 1">
                                    <mat-icon>last_page </mat-icon>
                                </span>
                                <span matTooltip="Show all" i18n-matTooltip="@@resultShowAll" *ngIf="openAll === 0">
                                    <mat-icon>first_page </mat-icon>
                                </span>
                            </div>
                            <mat-tab-group
                                #tab_group
                                fxFlex="0 1 100%"
                                (selectedTabChange)="onSelectChange($event)"
                                class="results-tabs"
                                [selectedIndex]="1"
                            >
                                <mat-tab *ngIf="tabsVisisbility[0]" label="Result" i18n-label="@@result">
                                    <div fxFlex="0 1 100%" fxLayout="column">
                                        <div
                                            fxLayoutAlign="start center"
                                            fxFlex="0 1 100%"
                                            fxLayout="row"
                                            fxLayout.lt-md="column"
                                        >
                                            <div
                                                fxLayout="column"
                                                fxFlex="0 1 50%"
                                                fxFlex.lt-sm="0 1 90%"
                                                class="result"
                                            >
                                                <p>
                                                    <span i18n="@@your" *ngIf="!user; else username">Your</span
                                                    >&nbsp;<span i18n="@@result">result</span>
                                                    {{ userTotalPoints }}
                                                    <span i18n="@@of">of</span>
                                                    {{ maxTotalPoints }}
                                                    <span i18n="@@pointPlural"
                                                        >{userTotalPoints, plural, =1 {point} other {points}}</span
                                                    >
                                                </p>
                                                <div fxLayoutAlign="start center">
                                                    <app-circle-gauge
                                                        [percentage]="userPercent"
                                                        gaugeType="pie"
                                                        size="medium"
                                                    ></app-circle-gauge>
                                                    <div fxLayoutAlign="center center" fxLayoutGap="5px">
                                                        <span class="color main"></span>
                                                        <span *ngIf="!user; else username" i18n="@@your">Your</span
                                                        >&nbsp;<span i18n="@@result">result</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                fxLayout="column"
                                                fxFlex="0 1 50%"
                                                fxFlex.lt-sm="0 1 90%"
                                                class="result"
                                            >
                                                <p>
                                                    <span *ngIf="!user; else username" i18n="@@your">Your</span
                                                    >&nbsp;<span i18n="@@resultIs">result is</span> {{ userPercent }}%
                                                </p>
                                                <div fxLayoutAlign="start center">
                                                    <app-circle-gauge
                                                        [percentage]="userPercent"
                                                        gaugeType="chart"
                                                        size="medium"
                                                    ></app-circle-gauge>
                                                    <div fxLayoutAlign="center center" fxLayoutGap="5px">
                                                        <span class="color main"></span>
                                                        <span *ngIf="!user; else username" i18n="@@your">Your</span
                                                        >&nbsp;<span i18n="@@result">result</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div fxFlex="0 1 100%" class="grade">
                                            <span *ngIf="occasion">
                                                <ng-container i18n="@@completionDate">Completion date:</ng-container>
                                                <!-- {{ occasion.done | date }} -->
                                                {{ occasion.done | kdDateFormat : 'YYYY-MM-DD' }}
                                                <br />
                                            </span>
                                        </div>
                                    </div>
                                </mat-tab>
                                <mat-tab
                                    *ngIf="tabsVisisbility[1]"
                                    label="Questions"
                                    i18n-label="@@questions"
                                    fxFlex="0 1 100%"
                                    fxLayout="row"
                                >
                                    <div (click)="open()" class="up-toggler" fxLayoutAlign="end center">
                                        <ng-container *ngTemplateOutlet="toggllers"></ng-container>
                                        <span
                                            matTooltip="Hide all"
                                            i18n-matTooltip="@@resultHideAll"
                                            *ngIf="openAll === 1"
                                        >
                                            <mat-icon>last_page </mat-icon>
                                        </span>
                                        <span
                                            matTooltip="Show all"
                                            i18n-matTooltip="@@resultShowAll"
                                            *ngIf="openAll === 0"
                                        >
                                            <mat-icon>first_page </mat-icon>
                                        </span>
                                    </div>
                                    <mat-accordion
                                        *ngIf="questionsActive"
                                        fxFlex="1 0 100%"
                                        displayMode="flat"
                                        [multi]="multiExpand"
                                        class="result"
                                    >
                                        <mat-expansion-panel
                                            #expansionPanel
                                            *ngFor="let question of filteredQuestionData(questionData)"
                                            (click)="multiExpand = false"
                                            [expanded]="openAll === 1"
                                        >
                                            <span *ngIf="filteredQuestionData(questionData).length === 0">
                                                No questions
                                            </span>
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    <mat-icon
                                                        class="correctness-icon"
                                                        [ngStyle]="{
                                                            color: question.resultStyle?.questionNameIconColor
                                                        }"
                                                        >{{ question.resultStyle?.icon }}</mat-icon
                                                    >
                                                    {{ question.sequence }}. {{ question.title }}
                                                </mat-panel-title>
                                                <ng-container *ngIf="expansionPanel.expanded">
                                                    <button
                                                        *ngIf="expansionPanel.expanded"
                                                        mat-icon-button
                                                        class="report-btn"
                                                        (click)="$event.stopPropagation(); reportQs(question)"
                                                    >
                                                        <mat-icon>warning_amber</mat-icon>
                                                    </button>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="
                                                        !expansionPanel.expanded &&
                                                        question.question_type_value === QuestionTypes.INPUT
                                                    "
                                                >
                                                    <mat-icon
                                                        *ngIf="!question.metadata"
                                                        [ngStyle]="{ color: question.resultStyle?.color }"
                                                        class="review-icon material-icons-outlined"
                                                        >comments_disabled</mat-icon
                                                    >
                                                    <mat-icon
                                                        *ngIf="question.metadata?.verifier == 'ai'"
                                                        [ngStyle]="{ color: question.resultStyle?.color }"
                                                        class="review-icon material-icons-outlined"
                                                        >smart_toy</mat-icon
                                                    >
                                                    <mat-icon
                                                        *ngIf="question.metadata && question.metadata?.verifier != 'ai'"
                                                        [ngStyle]="{ color: question.resultStyle?.color }"
                                                        class="review-icon material-icons-outlined"
                                                        >insert_comment</mat-icon
                                                    >
                                                </ng-container>
                                            </mat-expansion-panel-header>
                                            <div
                                                *ngIf="question.media$ | async; let media"
                                                class="example-element-diagram"
                                            >
                                                <app-image-view
                                                    [media_id]="media.url"
                                                    [proportional]="false"
                                                ></app-image-view>
                                            </div>
                                            <ng-container *ngIf="user$ | async; let user">
                                                <ng-container *ngIf="authUser.canCheckOcc(user)">
                                                    <app-questions-with-alts-results
                                                        *ngIf="
                                                            question.question_type_value === QuestionTypes.CHECKBOX ||
                                                            question.question_type_value === QuestionTypes.RADIO
                                                        "
                                                        [question]="question"
                                                        [user]="user"
                                                    ></app-questions-with-alts-results>

                                                    <app-questions-with-no-alts-results
                                                        *ngIf="question.question_type_value === QuestionTypes.INPUT"
                                                        [question]="question"
                                                        [authUser]="authUser"
                                                        [occasion]="occasion"
                                                        (onQuestionUpdate)="calculatePoints(questionData, $event)"
                                                    ></app-questions-with-no-alts-results>
                                                </ng-container>
                                            </ng-container>
                                        </mat-expansion-panel>
                                        <div class="down-toggler" fxLayoutAlign="end center">
                                            <button
                                                (click)="onDownload()"
                                                class="download"
                                                mat-icon-button
                                                tooltip="Download PDF"
                                                i18n-tooltip="@@downloadPDF"
                                            >
                                                <mat-icon>save_alt</mat-icon>
                                            </button>
                                            <span
                                                tooltip="Hide all"
                                                (click)="open()"
                                                i18n-tooltip="@@resultHideAll"
                                                *ngIf="openAll === 1"
                                            >
                                                <mat-icon>last_page </mat-icon>
                                            </span>
                                            <span
                                                tooltip="Show all"
                                                (click)="open()"
                                                i18n-tooltip="@@resultShowAll"
                                                *ngIf="openAll === 0"
                                            >
                                                <mat-icon>first_page </mat-icon>
                                            </span>
                                        </div>
                                    </mat-accordion>
                                </mat-tab>
                                <mat-tab
                                    *ngIf="tabsVisisbility[2]"
                                    label="Evaluation"
                                    i18n-label="@@evaluation"
                                    fxFlex="0 1 100%"
                                    fxLayout="row"
                                >
                                    <app-formative-evaluation
                                        [authUser]="authUser"
                                        [occasion]="occasion"
                                    ></app-formative-evaluation>
                                </mat-tab>
                            </mat-tab-group>

                            <div fxLayoutAlign="space-between center" class="exit-holder">
                                <div class="compl-hint">
                                    <span i18n="@@testCompletedOn">Test completed on</span>
                                    <!-- {{ occasion.done | date }} -->
                                    {{ occasion.done | kdDateFormat : 'YYYY-MM-DD' }}
                                    <span i18n="@@with">with </span>
                                    {{ userPercent }}%
                                    <span i18n="@@correct">correct</span>
                                </div>
                                <button
                                    *ngIf="!isCourseItem"
                                    class="action-button"
                                    color="primary"
                                    mat-raised-button
                                    type="button"
                                    (click)="done()"
                                    i18n="@@close"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-template #loading>
    <div class="mt-2 mb-1" fxLayout="row" fxLayoutAlign="center center">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>

<ng-template #username>
    <span>{{ user.name }}</span>
</ng-template>
<ng-template #toggllers>
    <mat-button-toggle-group
        #btn_toggle
        class="qs-mode-toggle"
        [value]="viewMode$.value"
        (change)="viewMode$.next($event.value)"
        (click)="$event.stopPropagation()"
    >
        <!-- <mat-button-toggle
            *ngIf="availableQuestionTypes.includes(QuestionTypes.RADIO)"
            value="correct_radio"
            matTooltip="Correct radio questions"
            i18n-matTooltip="@@correctRadioQs"
        >
            <mat-icon class="correct material-icons-outlined">check_circle_outline</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle
            *ngIf="availableQuestionTypes.includes(QuestionTypes.CHECKBOX)"
            value="correct_checkbox"
            matTooltip="Correct checkbox questions"
            i18n-matTooltip="@@correctCheckboxQs"
        >
            <mat-icon class="correct material-icons-outlined">check_box</mat-icon>
        </mat-button-toggle> -->
        <mat-button-toggle
            *ngIf="availableQuestionTypes.includes(QuestionTypes.INPUT)"
            value="input"
            matTooltip="Input questions"
            i18n-matTooltip="@@inputQuestions"
        >
            <mat-icon class="material-icons-outlined">edit_note</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="incorrect" matTooltip="Incorrect questions" i18n-matTooltip="@@incorrectQs">
            <mat-icon class="wrong incorrect">clear</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="correct" matTooltip="Correct questions" i18n-matTooltip="@@correctQs">
            <mat-icon class="correct">check</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="all" matTooltip="All questions" i18n-matTooltip="@@allQs">
            <mat-icon>reorder</mat-icon>
        </mat-button-toggle>
    </mat-button-toggle-group>
</ng-template>
