import { Config, environmentBase } from '@klickdata/core/config';

export const environment: Config = {
    ...environmentBase,
    buildMode: 'production',
    apiUrl: 'https://store.klickdata.se/app/',
    resourceUrl: 'https://store.klickdata.se/resources/',
    jwtWhitelist: ['store.klickdata.se'],

    WS_ECHO_SERVER: 'socket.klickdata.se',
    WS_ECHO_AUTH: 'https://store.klickdata.se/broadcasting/auth',

    scormPlayer: 'https://store.klickdata.se/player/',
    domains: ['k3.io', 'klickdata.se', 'growknowledge.net'],

    playerUrl: 'https://player.klickdata.se/',
    messageRecipient: 'https://player.klickdata.se/',
};
