import { Injectable } from '@angular/core';
import { IDataModel, InitialValue, Model } from '@klickdata/core/application/src/model/model-interface';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { AbstractFormGroup, FormGeneratorService, FormHelper, Prop } from '@klickdata/core/form';
import { GroupService } from '@klickdata/core/group';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { UserService } from '@klickdata/core/user';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

export interface ResourceAccessData extends IDataModel {
    id?: number;
    // users
    users_attach?: number[];
    users_detach?: number[];

    // groups
    groups_attach?: number[];
    groups_detach?: number[];
}

export class ResourceAccess extends Model<ResourceAccessData> {
    @Prop()
    id: number;

    // users
    @Prop()
    sync_all_users: boolean;
    @Prop()
    @InitialValue([])
    users_attach: number[];
    @Prop()
    @InitialValue([])
    users_detach: number[];

    // groups
    @Prop()
    sync_all_groups: boolean;

    @Prop()
    @InitialValue([])
    groups_attach: number[];
    @Prop()
    @InitialValue([])
    groups_detach: number[];
}

@Injectable()
export class AccessForm extends AbstractFormGroup<ResourceAccess> {
    public onParentUpdated: Subject<Resource> = new Subject<Resource>();

    constructor(
        protected authService: AuthService,
        protected resourceService: ResourceService,
        protected groupService: GroupService,
        protected userService: UserService,
        protected generator: FormGeneratorService
    ) {
        super(ResourceAccess, generator);
    }

    public create(data?: any): ResourceAccess {
        const resource = new ResourceAccess(data);
        this.initForm();
        FormHelper.resetForm(this.form);
        return resource;
    }

    private initForm() {
        this.form.patchValue({
            id: this.model.id,
            users_attach: [],
            users_detach: [],
            groups_attach: [],
            groups_detach: [],
        });
    }

    public submit(): Observable<Resource> {
        return this.resourceService.update(this.export()).pipe(
            // map(resource => this.import(resource)),
            finalize(() => {
                this.form.patchValue({ id: this.model.id });
                this.initForm();
                FormHelper.resetForm(this.form);
            })
        );
    }

    /**
     * Import/update the form with new values.
     */
    public import(resource: ResourceAccess): ResourceAccess {
        this.initForm();
        FormHelper.resetForm(this.form);
        return resource;
    }

    /**
     * Get course plan data as a resource.
     */
    public export(): ResourceAccess {
        this.commit();

        const model = this.model.getData();

        return new ResourceAccess({
            ...model,
        });
    }

    public afterParentAttached(): void {
        this.parent.modelUpdate.pipe(takeUntil(this.destroy)).subscribe((update: Resource) => {
            this.model.id = update.id;
            this.initForm();
            this.onParentUpdated.next(update);
        });
    }
}
