import { Injectable, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog.component';

@Injectable({ providedIn: 'root' })
export class ErrorDialogService implements OnInit {
    protected dialog: MatDialog;

    constructor(protected injector: Injector) {}

    public ngOnInit(): void {}

    public open(eventId?: string | undefined): void {
        this.injector.get(MatDialog).open(ErrorDialogComponent, {
            disableClose: true,
            data: {
                eventId: eventId,
            },
        });
    }
}
