<app-header class="nk3-header"></app-header>

<div class="nk3-container">
    <article class="nk3-content" role="main">
        <div class="ml-1 mr-1" fxLayoutAlign="center center">
            <markdown src="../../../CHANGELOG.md"></markdown>
        </div>
    </article>
    <app-footer></app-footer>
</div>
