<div class="dialog-wrapper" fxLayout="column">
    <div>
        <h3>{{ contentTitle }}</h3>
    </div>
    <!-- <div class="image" *ngIf="contentImg | async; let image" fxFlex="1 1 100%">
        <img src="{{ image.url }}" />
    </div> -->
    <app-image-view *ngIf="contentImg" class="w-50" [proportional]="true" fxFlex="0 1 50%" [media_id]="contentImg">
    </app-image-view>
    <div matDialogContent [innerHTML]="contentBody"></div>
    <div class="flex" fxLayoutAlign="end center" mat-dialog-actions>
        <button mat-raised-button mat-button type="button" (click)="dialogRef.close(null)">
            {{ neutralBtn }}
        </button>
    </div>
</div>
