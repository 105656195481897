import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-circle-gauge',
    templateUrl: './circle-gauge.component.html',
    styleUrls: ['./circle-gauge.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleGaugeComponent implements OnInit {
    @Input() mainColor = 'green';
    @Input() gaugeType: 'pie' | 'chart';
    @Input() percentage: number;
    @Input() size: 'small' | 'medium' | 'large' = 'medium';
    public pieStyle = 'conic-gradient(#95cbd0 0% 50%, #cccccc 50% 100%)';

    constructor(protected cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (this.gaugeType === 'pie' && this.percentage) {
            this.pieStyle = `conic-gradient(#95cbd0 0% ${this.percentage}%, #cccccc ${this.percentage}% 100%)`;
            this.cdr.markForCheck();
        }
    }
}
