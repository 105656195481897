<div *ngIf="!hideSticky; else sectionSelect" fxFlex="0 0 100%" fxLayout="row wrap" class="assignments-select">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.context"
        [contextIcon]="data.contextIcon"
        (onSubmit)="onSubmit()"
        (onCancel)="bottomSheetRef.dismiss(data)"
        [savingStatus]="saving"
    ></app-sticky-header>
    <div *ngIf="data.hint" class="sticky-info">
        <mat-icon>info</mat-icon>
        <span>{{ data.hint }}</span>
    </div>
    <mat-tab-group class="sticky-after-info user-manage-cp-access" fxFlex="0 0 90%" mat-stretch-tabs ngClass.xs="xs">
        <mat-tab>
            <ng-template mat-tab-label>
                <span i18n="@@sections">Sections</span>
            </ng-template>
            <ng-container *ngTemplateOutlet="sectionSelect"></ng-container>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #sectionSelect>
    <app-section-select
        [hideTools]="true"
        [accessForm]="assignForm"
        [referenceFilter]="data.referenceFilter"
    ></app-section-select>
</ng-template>
