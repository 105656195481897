import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MobileService } from '@klickdata/core/mobile';
import { UserService } from '@klickdata/core/user';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../../global-filters-types.enum';
import { ChipFilterComponent } from '../chip-filter/chip-filter.component';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';

@Component({
    selector: 'app-author-chip-search-filter',
    templateUrl: './author-chip-search-filter.component.html',
    styleUrls: ['./author-chip-search-filter.component.scss'],
    providers: [
        { provide: ChipFilterComponent, useExisting: forwardRef(() => AuthorChipSearchFilterComponent) },
        { provide: FilterBase, useExisting: AuthorChipSearchFilterComponent },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorChipSearchFilterComponent extends ChipFilterComponent implements OnInit, OnDestroy {
    @Input()
    private scope_id: number | number[];
    private destroy: Subject<boolean> = new Subject<boolean>();
    public control: FormControl = new FormControl();
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.AUTHOR,
        items: [],
        label: $localize`Authors`,
        icon: 'account_circle',
        color: { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 },
        styleClass: 'global-authors-filter-selector',
    };

    constructor(
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService,
        protected userService: UserService
    ) {
        super(changeRef, mobile);
    }

    ngOnInit() {
        this.control.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((query) => {
                    return query?.trim()
                        ? this.userService.getAuthors(<number>this.scope_id, query, 10).pipe(
                              map((authors) =>
                                  authors.map((author, index) => {
                                      return {
                                          id: author.id,
                                          label: author.name,
                                          selected: null, // (AS, EB SSC on 230918 skype)
                                      };
                                  })
                              )
                          )
                        : of([]);
                })
            )
            .subscribe((items) => {
                this.source = items;
                if (items?.length) {
                    this.updateSelection();
                } else {
                    this.removeFilter();
                }
                this.changeRef.markForCheck();
            });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
