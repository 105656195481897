<ng-container *ngIf="!loading; else loadingTemp">
    <div
        *ngIf="selected && !changeMode; else selectTemp"
        fxFlex="0 0 100%"
        class="selected-wrapper"
        fxLayout="row wrap"
    >
        <app-image-view
            fxFlex="0 1 20%"
            *ngIf="selected.media_id"
            [media_id]="selected.media_id"
            [proportional]="true"
        ></app-image-view>
        <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="end center">
                <!-- <h2 class="title-header-res" fxFlex="1 1 auto">{{ selected.title }}</h2> -->
                <a
                    fxFlex="0 0 auto"
                    [routerLink]="['/player/resource/', selected.id]"
                    target="_blank"
                    mat-icon-button
                    fxHide.lt-md="true"
                    matTooltip="Preview material"
                    i18n-matTooltip="@@previewMaterial"
                >
                    <mat-icon class="md-24">open_in_new</mat-icon>
                </a>
            </div>
            <button
                fxFlex="0 0 auto"
                mat-icon-button
                color="primary"
                matTooltip="Change material"
                i18n-matTooltip="@@changeMaterial"
                (click)="changeMode = true"
            >
                <mat-icon class="md-24">swap_horiz</mat-icon>
            </button>

            <button
                *ngIf="user$ | async as user"
                fxFlex="0 0 auto"
                mat-icon-button
                color="primary"
                matTooltip="Edit material"
                i18n-matTooltip="@@editMaterial"
                [disabled]="!user.canEdit(selected)"
                (click)="user.canEdit(selected) && onEdit()"
            >
                <mat-icon class="md-24">edit</mat-icon>
            </button>
        </div>

        <div
            *ngIf="selected.description"
            fxFlex="0 1 calc(80% - 2em)"
            [innerHtml]="selected.description | innerHtml : 'tool_desc'"
        ></div>

        <div
            class="mt-1 mb-1"
            *ngIf="selected.tags_attached"
            fxFlex="0 1 100%"
            fxLayoutAlign="start center"
            fxLayoutGap="10px"
        >
            <div class="pre"><span i18n="@@tags">Tags </span></div>
            <mat-chip-list *ngIf="resourceTags | async; let tags; else: loadingTemp">
                <mat-chip class="tags" (click)="showTagResources(tag)" *ngFor="let tag of tags"
                    >{{ tag.name }}
                    <app-language-selector
                        class="flag tag-chip-lang-select"
                        fxFlex="0 0 auto"
                        [language_id]="tag.language_id"
                    >
                    </app-language-selector
                ></mat-chip>
            </mat-chip-list>
        </div>
        <ng-container *ngIf="selected.number_of_items > 0">
            <ng-container *ngIf="resourceItems$ | async; let resourceItems; else: loadingTemp">
                <div class="file-parent-wrap-items">
                    <div
                        *ngIf="resourceItems[currentIndex].isDocType()"
                        fxLayout="column"
                        class="file-parent-wrap-items-item"
                        fxLayoutAlign="center start"
                    >
                        <app-media-view [media_id]="resourceItems[currentIndex].name"> </app-media-view>
                        <mat-checkbox
                            [checked]="resourceItems[currentIndex].can_download"
                            (change)="toggleDownload(resourceItems[currentIndex].id, $event.checked)"
                            color="primary"
                            class="can-download-wrapper mb-1"
                        >
                            <div class="can-download" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                                <mat-icon class="material-icons-outlined">download_for_offline</mat-icon>
                                <span fxLayoutAlign="start center" fxLayoutGap="5px">
                                    <span i18n>Learners can download</span>
                                </span>
                            </div></mat-checkbox
                        >
                    </div>
                    <div fxFlex="0 0 100%">
                        <div
                            *ngIf="resourceItems[currentIndex].item_type_value === 'text_material'"
                            [innerHtml]="resourceItems[currentIndex].name | innerHtml : 'tool_desc'"
                        ></div>
                        <app-media-view
                            *ngIf="resourceItems[currentIndex].item_type_value === 'url_material'"
                            [media_id]="resourceItems[currentIndex].name"
                        ></app-media-view>
                    </div>
                </div>
            </ng-container>
            <div fxLayout="row" class="w-100" fxFlex="0 1 auto" fxLayoutAlign="end">
                <div *ngIf="selected.number_of_items > 1">
                    <span>{{ currentIndex + 1 }} / {{ selected.number_of_items }}</span>
                    <button
                        mat-icon-button
                        [disabled]="currentIndex === 0"
                        (click)="previousItem()"
                        matTooltip="Previous"
                        aria-label="Previous"
                    >
                        <mat-icon>arrow_back_ios</mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        [disabled]="currentIndex === selected.number_of_items - 1"
                        (click)="nextItem()"
                        matTooltip="Next"
                        aria-label="Next"
                    >
                        <mat-icon>arrow_forward_ios</mat-icon>
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>

<ng-template #selectTemp>
    <!-- <app-resource-library
        [selection]="selection"
        [type_scope_id]="AppScope.MATERIAL"
        (select)="selectResource($event)"
    ></app-resource-library> -->

    <app-course-manager-core-add-material
        [display]="ResourceBuildingItems.MATERIAL_LIBRARY"
        (selectChange)="selectResource($event.value); changeMode = false"
        (onCloseView)="changeMode = false"
    ></app-course-manager-core-add-material>
</ng-template>

<ng-template #loadingTemp>
    <div fxLayout="row" fxLayoutAlign="left" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
