import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { AppScope, Resource, ResourceService, ResourceTypeService } from '@klickdata/core/resource';
import { LoggerService } from '@klickdata/core/application';
import { CapabilityService } from '@klickdata/core/auth';
import { CustomerService } from '@klickdata/core/customer';
import { GradeSystemService } from '@klickdata/core/grade-system';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { ResourceItemService } from '@klickdata/core/resource-item';
import { UserService } from '@klickdata/core/user';

@Injectable()
export class UserStatisticsService extends ResourceService {
    protected api: string;
    protected resourceOccasionUrl: string;
    constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected typeService: ResourceTypeService,
        protected gradeSystemService: GradeSystemService,
        protected logger: LoggerService,
        protected userService: UserService,
        protected customerService: CustomerService,
        protected itemService: ResourceItemService,
        protected capabilityService: CapabilityService,
        protected mediaService: MediaService
    ) {
        super(
            auth,
            builder,
            config,
            typeService,
            gradeSystemService,
            logger,
            userService,
            customerService,
            itemService,
            capabilityService,
            mediaService
        );
        this.api = `${this.config.config.apiUrl}statistics/`;
        this.resourceOccasionUrl = `${this.config.config.resourceUrl}opportunities`;
    }

    public getGuageData(type: string): Observable<any> {
        return this.builder
            .get<any>(`${this.api}dashboardGauges`)
            .param('user', this.user_id)
            .param('type_value', type)
            .request()
            .pipe(map(res => res.data));
    }

    public getUserStartedCoursePlanStatistics(): Observable<any[]> {
        return this.builder
            .get<any>(`${this.api}userStartedCoursePlans`)
            .param('user', this.user_id)
            .request()
            .pipe(map(res => res.data));
    }

    public getUserStartedCoursePlanStatisticsByScope(started: boolean): Observable<any[]> {
        const builder = this.builder.get<any>(`${this.resourceUrl}`).param('scope', AppScope.COURSE);
        if (started) {
            builder.param('started', this.user_id);
        } else {
            builder.param('public', 1);
            builder.param('published', 1);
        }
        return builder.request().pipe(map(res => res.data));
    }

    public startCourse(resource: Resource): Observable<any[]> {
        return this.user_id.pipe(
            switchMap(uid =>
                this.builder
                    .post<any>(`${this.resourceOccasionUrl}`, { resource_id: resource.id, user_id: uid })
                    .request()
                    .pipe(map(res => res.data))
            )
        );
    }

    // public getUnfinishedUserCoursePlans(type: ResourceTypes = ResourceTypes.AllCoursePlans): Observable<Resource[]> {
    //     return this.builder.get<ResourceData[]>(this.resourceOccasionUrl)
    //         .param('started', this.user_id)
    //         .param('published', 1)
    //         .param('current', moment().format('YYYY-MM-DD HH:mm:ss'))
    //         .param('type_value', type)
    //         .request().pipe(
    //             map(res => this.mapResource(res.data))
    //         );
    // }
}
