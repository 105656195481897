<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>auto_graph</mat-icon>
        <div i18n>Manage Summarization</div>
    </div>
    <div i18n class="desc mb-1">
        Show or hide the summarization boxes according to your need. You can also arrange the boxes to have the most
        interesting first.
    </div>
    <div
        *ngIf="navData.data && navData.data.widgets"
        class="widgets-wrapper"
        cdkDropList
        [cdkDropListData]="navData.data.widgets"
        (cdkDropListDropped)="drop($event)"
    >
        <div
            cdkDragBoundary=".data-wrap"
            cdkDrag
            [cdkDragData]="navData.data.widgets"
            class="widget-item-wrap"
            *ngFor="let widget of navData.data.widgets"
            cdkDragHandle
            [ngStyle]="{ 'border-color': widget.color }"
        >
            <button mat-icon-button [ngStyle]="{ color: widget.color }" (click)="$event.stopPropagation()">
                <mat-icon>sort</mat-icon>
            </button>
            <span [ngStyle]="{ color: widget.color }" fxFlex="grow">{{ widget.name }}</span>
            <button
                mat-icon-button
                matTooltip="Choose box color"
                i18n-matTooltip
                [ngStyle]="{ color: widget.color }"
                [matMenuTriggerFor]="menu"
                class="color-badge-btn"
            >
                <mat-icon class="color-badge">fiber_manual_record</mat-icon>
            </button>
            <mat-menu class="widgets-colors-selection" #menu="matMenu">
                <button
                    class="menu-color-badge-btn"
                    mat-menu-item
                    *ngFor="let color of kdColorsSet"
                    (click)="widget.color = color"
                >
                    <mat-icon [ngStyle]="{ color: color }" class="color-badge">fiber_manual_record</mat-icon>
                </button>
            </mat-menu>
            <mat-slide-toggle (change)="widget.visible = $event.checked" [checked]="widget.visible"> </mat-slide-toggle>
        </div>
        <div class="action mt-1" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
            <button
                fxFlex="0 0 auto"
                class="mobile-button positive"
                (click)="onSubmit(navData.data.widgets)"
                mat-raised-button
                i18n
            >
                Done
            </button>
            <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button i18n>
                Cancel
            </button>
        </div>
    </div>
</div>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
