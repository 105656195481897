import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { RecorderTypes } from '@klickdata/core/media';

@Component({
    selector: 'app-recorder-bar',
    templateUrl: './recorder-bar.component.html',
    styleUrls: ['./recorder-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecorderBarComponent implements OnInit {
    public snackBarRef: MatSnackBarRef<RecorderBarComponent>;
    RecorderTypes = RecorderTypes;
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: RecorderTypes) {}

    ngOnInit(): void {}

    public stopRecording() {
        if (this.snackBarRef) {
            this.snackBarRef.dismissWithAction();
        }
    }
}
