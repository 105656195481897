import { IDataModel, Ignore, Model } from '@klickdata/core/application/src/model/model-interface';
import { Observable } from 'rxjs';
import { ImportField } from './import-field';

export interface ImportTemplateData extends IDataModel {
    id?: number;
    title?: string;
    author_id?: number;
    customer_id?: number;
    group_ids?: string;
    public?: boolean;
    link?: string; // Download
    created_at?: string;
    updated_at?: string;
}

export class ImportTemplate extends Model<ImportTemplateData> {
    public id: number;
    public title: string;
    public author_id: number;
    public customer_id: number;
    public group_ids: string;
    public public: boolean;
    public link: string; // Download
    @Ignore()
    public created_at: string;
    @Ignore()
    public updated_at: string;

    public fields: Observable<ImportField[]>;

}
