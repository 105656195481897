import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceItem } from '@klickdata/core/resource-item';
import { Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { ConfigService, Config } from '@klickdata/core/config';

@Component({
    selector: 'app-resource-item',
    templateUrl: './resource-item.component.html',
    styleUrls: ['./resource-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceItemComponent {
    @Input() previewTooltip: string;
    @Input()
    public set item(item: ResourceItem) {
        this._item = item;
        this.childResource$ = this.resourceService.getResource(this.item.child_resource_id);
    }

    public get item() {
        return this._item;
    }

    public _item: ResourceItem;

    public childResource$: Observable<Resource>;
    ResourceTypes = ResourceTypes;

    constructor(protected resourceService: ResourceService) {}
}
