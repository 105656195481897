<mat-card>
    <app-resource-builder-tools-view
        [resourceTypes]="resourceTypes"
        [questionTypes]="questionTypes"
        [resource]="resource"
        [saving]="saving"
        [saved]="saved"
        [hideAddItems]="hideAddItems"
        [saveTitle]="saveTitle"
        (addItem)="onAddItem($event)"
        (addQuestion)="onAddQuestion($event)"
        (itemsCollapsingState)="onItemCollapsingChange($event)"
        (submit)="onSubmit()"
    >
    </app-resource-builder-tools-view>
</mat-card>
