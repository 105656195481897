<mat-form-field class="chip-list" fxFlex="0 1 100%" class="mb-4">
    <mat-chip-list i18n-aria-label class="tag-chips" #chipList aria-label="Add Tags">
        <mat-chip
            *ngFor="let tag of selection"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(tag)"
            (click)="onTagClick.emit(tag)"
            class="tag-chip-item"
        >
            {{ tag.name }}
            <ng-container
                [ngTemplateOutlet]="tagFlag"
                [ngTemplateOutletContext]="{ language: tag.language_id }"
            ></ng-container>
            <mat-icon matChipRemove *ngIf="removable"> cancel </mat-icon>
        </mat-chip>
        <input
            #tagInput
            #inp="matAutocompleteTrigger"
            (focus)="inputFocused(inp)"
            (keyup)="inputkeyDown(inp, tagInput.value)"
            [placeholder]="placeholder"
            [formControl]="tagCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
            (matChipInputTokenEnd)="add($event)"
        />
        <app-kd-logo-loading *ngIf="loading" size="small"></app-kd-logo-loading>
    </mat-chip-list>
    <mat-autocomplete
        #auto="matAutocomplete"
        (closed)="optionClosed()"
        (optionActivated)="optionActivated($event)"
        (optionSelected)="onSelected($event)"
    >
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag" class="tag-options">
            {{ tag.name }}
            <ng-container
                [ngTemplateOutlet]="tagFlag"
                [ngTemplateOutletContext]="{ language: tag.language_id }"
            ></ng-container>
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

<ng-template #tagFlag let-language="language">
    <app-language-selector class="flag tag-chip-lang-select" fxFlex="0 0 auto" [language_id]="language">
    </app-language-selector>
</ng-template>
