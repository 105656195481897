import { Injectable, Injector } from '@angular/core';
import { CanLoad, PreloadingStrategy, Route } from '@angular/router';
import { from, Observable, of } from 'rxjs';

import { every, map, mergeAll } from 'rxjs/operators';

@Injectable()
export class SelectivePreloadingStrategy implements PreloadingStrategy {
    constructor(private injector: Injector) {}

    preload(route: Route, load: () => Observable<any>): Observable<any> {
        if (route.data && route.data['canLoad']) {
            const canLoad: CanLoad[] = route.data['canLoad'];

            if (route.data['canLoad'].length === 0) {
                return of(true);
            }

            const obs = from(canLoad).pipe(
                map((c: any) => {
                    const guard = this.injector.get(c);
                    if (guard.canLoad) {
                        return of(guard.canLoad(route));
                    } else {
                        return of(guard(route));
                    }
                })
            );

            const merged = mergeAll.call(obs);
            return every.call(merged, (result: any) => result === true);
        } else {
            return of(null);
        }
    }
}
