import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@klickdata/material';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ConfirmPublishDialogComponent } from './confirm-publish-dialog/confirm-publish-dialog.component';
import { ConfirmQuitDialogComponent } from './confirm-quit-dialog/confirm-quit-dialog.component';
import { ConfirmTestWithoutDescDialogComponent } from './confirm-test-without-desc-dialog/confirm-test-without-desc-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { ReportQsDialogComponent } from './report-qs-dialog/report-qs-dialog.component';
import { SelectOptionsDialogComponent } from './select-options-dialog/select-options-dialog.component';
import { SoftDeleteDialogComponent } from './soft-delete-dialog/soft-delete-dialog.component';
import { DateRangeDialogComponent } from './date-range-dialog/date-range-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogDiscardChangesComponent } from './dialog-discard-changes/dialog-discard-changes.component';
import { AddLinkDialogComponent } from './add-link-dialog/add-link-dialog.component';

@NgModule({
    imports: [CommonModule, MaterialModule, ReactiveFormsModule],
    providers: [],
    declarations: [
        ErrorDialogComponent,
        DeleteDialogComponent,
        AlertDialogComponent,
        ConfirmTestWithoutDescDialogComponent,
        ConfirmQuitDialogComponent,
        ConfirmPublishDialogComponent,
        ReportQsDialogComponent,
        InfoDialogComponent,
        SelectOptionsDialogComponent,
        SoftDeleteDialogComponent,
        DateRangeDialogComponent,
        DialogDiscardChangesComponent,
        AddLinkDialogComponent,
    ],
    exports: [
        ErrorDialogComponent,
        AlertDialogComponent,
        DeleteDialogComponent,
        SoftDeleteDialogComponent,
        ConfirmTestWithoutDescDialogComponent,
        ConfirmQuitDialogComponent,
        ConfirmPublishDialogComponent,
        ReportQsDialogComponent,
        DateRangeDialogComponent,
        DialogDiscardChangesComponent,
    ],
})
export class ApplicationModule {}
