import { FunctionGroup } from '@klickdata/core/role/src/permission.model';
import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@klickdata/core/util';

@Pipe({
    name: 'resourcePermissionPipe',
})
export class ResourcePermissionPipe implements PipeTransform {
    transform(permValue: string): {
        index: number;
        value: string;
        label: string;
        class: string;
        tooltip?: string;
    } {
        return Utils.getResourcePermissions().find((perm) => perm.value === permValue);
    }
}
