import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Quote, QuoteService } from '@klickdata/core/quote';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-menu-side-show-quotee-quotes',
    templateUrl: './menu-side-show-quotee-quotes.component.html',
    styleUrls: ['./menu-side-show-quotee-quotes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideShowQuoteeQuotesComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public quotes$: Observable<Quote[]>;

    constructor(protected quoteService: QuoteService) {}

    ngOnInit(): void {
        if (this.navData?.data) {
            this.quotes$ = this.quoteService.getQuotesOfQuotee(this.navData.data.id);
        }
    }
}
