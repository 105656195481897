<div class="course-items-switch-wrapper">
    <ng-container [ngSwitch]="display">
        <ng-container *ngSwitchCase="ResourceBuildingItems.SURVEY_LIBRARY">
            <mat-tab-group class="res-tabs-course-item" #tabGroup ngClass.xs="xs">
                <mat-tab>
                    <ng-template mat-tab-label i18n> My surveys </ng-template>
                    <ng-template matTabContent>
                        <app-resource-library-multiple-select
                            [type_scope_id]="AppScope.SURVEY"
                            context="author"
                            [selection]="selection"
                            (onSubmitMultiSelect)="onMultiSelect.emit({ res: $event, isParentSubmission: false })"
                            (selectChange)="selectChange.emit($event)"
                        ></app-resource-library-multiple-select>
                    </ng-template>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label i18n> Academy surveys </ng-template>
                    <ng-template matTabContent>
                        <app-resource-library-multiple-select
                            [type_scope_id]="AppScope.SURVEY"
                            context="academy"
                            [selection]="selection"
                            (onSubmitMultiSelect)="onMultiSelect.emit({ res: $event, isParentSubmission: false })"
                            (selectChange)="selectChange.emit($event)"
                        ></app-resource-library-multiple-select>
                    </ng-template>
                </mat-tab>

                <mat-tab>
                    <ng-template mat-tab-label i18n> Open library (KOL) </ng-template>
                    <ng-template matTabContent>
                        <app-resource-library-multiple-select
                            [type_scope_id]="AppScope.SURVEY"
                            context="kol"
                            [selection]="selection"
                            (onSubmitMultiSelect)="onMultiSelect.emit({ res: $event, isParentSubmission: false })"
                            (selectChange)="selectChange.emit($event)"
                        ></app-resource-library-multiple-select>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </ng-container>
        <ng-container *ngSwitchCase="ResourceBuildingItems.CREATE_NEW_SURVEY"
            ><app-create-survey
                #compRef
                [publishOnly]="true"
                publishLabel="Add to course"
                i18n-publishLabel
                (published)="add.emit($event)"
                (onError)="onError.emit($event)"
            ></app-create-survey
        ></ng-container>
    </ng-container>
    <button
        matTooltip="Change"
        mat-icon-button
        [ngClass]="display === ResourceBuildingItems.SURVEY_LIBRARY ? 'lib' : 'normal'"
        (click)="display = null; onCloseView.emit(true)"
        i18n-matTooltip
        class="add-creation-tab"
    >
        <mat-icon>cancel</mat-icon>
    </button>
</div>
