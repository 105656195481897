<div fxFlex="0 0 100%" fxLayout="row wrap" class="assignments-select">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.contextTitle"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss()"
        [savingStatus]="saving"
    ></app-sticky-header>
    <div *ngIf="customer$ | async; let customer; else: loading">
        <app-customer-select
            [accessForm]="assignForm"
            [selectionFilters]="selectionFilters"
        ></app-customer-select>
    </div>
</div>
<ng-template #loading>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
