import { MobileService } from './../../../../core/mobile/src/mobile.service';
import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { ResourceTypes } from '@klickdata/core/resource';
import { Resource, ResourceData } from '@klickdata/core/resource/src/resource.model';
import { ResourceTypeService } from '@klickdata/core/resource/src/type/resource-type.service';
import { User, UserService } from '@klickdata/core/user';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, share, shareReplay, switchMap } from 'rxjs/operators';

@Injectable()
export class ResourceService {
    protected resourceUrl: string;
    private authorsMap = new Map<number, Observable<User>>();
    public infoWrapperWidth: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private takeTaskMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected typeService: ResourceTypeService,
        protected mediaService: MediaService,
        protected userService: UserService,
        protected mobile: MobileService
    ) {
        this.resourceUrl = `${this.config.config.resourceUrl}resources`;
    }
    public updateTakeTaskMode(isTakeTaskMode: boolean) {
        this.takeTaskMode$.next(isTakeTaskMode);
    }
    public getTakeTaskMode(): Observable<boolean> {
        return combineLatest([this.takeTaskMode$.asObservable(), this.mobile.isMobile()]).pipe(
            map(([isTaskMode, isMobile]) => isTaskMode && isMobile)
        );
    }
    getResource(id: number, eager?: string | string[]): Observable<Resource> {
        const request = this.builder.get<ResourceData>(`${this.resourceUrl}/${id}`);
        request.putEager(eager);
        return request.request().pipe(map((res) => this.createResource(res.data)));
    }

    protected createResource(data: ResourceData) {
        const resource = new Resource(data);

        if (resource.managers?.length) {
            resource.author$ = of(new User(resource.managers[0]));
        } else if (resource.staff?.manager?.length) {
            resource.author$ = this.userService.getAuthor(resource.staff?.manager[0]);
        } else if (data.author) {
            resource.author$ = of(new User(data.author));
        } else if (resource.author_id) {
            resource.author$ = this.userService.getAuthor(resource.author_id);
        } else {
            resource.author$ = of(new User({ fname: 'Anonymous' }));
        }

        resource.media$ = of(resource.media);
        return resource;
    }
}
