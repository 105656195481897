import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-display-img',
  templateUrl: './dialog-display-img.component.html',
  styleUrls: ['./dialog-display-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogDisplayImgComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogDisplayImgComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
