<app-chips-with-add-handler
    (onItemSelection)="onSelectedCorCat($event)"
    (onItemRemoval)="removeCorCat($event)"
    (onSearchInput)="onSeachFromList.next($event)"
    [user]="user"
    [loadingUserSelectedItems]="loadingUserCats"
    [activeItemsSelection]="activeCatsSelection"
    [fullItemsList$]="corporateCategories$"
    [itemScope]="AppScope.CATEGORIES"
    searchInputLabel="Search or pick category"
    i18n-searchInputLabel="@@searchOrPickCategory"
    mainTitle="Preferred Categories"
    i18n-mainTitle="@@preferredCategories"
    subTitle="In your dashboard this categories will be highlighted"
    i18n-subTitle="@@inYourDashboardThisCategoriesWillBeHighlighted"
    class="user-preferred-cats-chips"
></app-chips-with-add-handler>
