import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-table-toolbar',
    templateUrl: './table-toolbar.component.html',
    styleUrls: ['./table-toolbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableToolbarComponent implements OnInit {

    /**
     * Material theme color settings.
     */
    @Input() color: string;

    constructor() {
    }

    ngOnInit() {
    }

}
