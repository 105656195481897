import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MobileService } from '@klickdata/core/mobile';
import { UsersSelectComponent } from '../users-select.component';
import { UsersService } from '../users.service';
import { Filter } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AuthService } from '@klickdata/core/auth';

@Component({
    selector: 'app-user-select-with-check-edit',
    templateUrl: './user-select-with-check-edit.component.html',
    styleUrls: ['./user-select-with-check-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UsersService],
})
export class UserSelectWithCheckEditComponent extends UsersSelectComponent {
    public columns = ['select', 'name', 'email', 'edit'];
    @Output() onDismissBottomSheet: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        protected datatableService: UsersService,
        protected mobileService: MobileService,
        protected auth: AuthService
    ) {
        super(datatableService, mobileService, auth);
    }
}
