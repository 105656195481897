import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Injector,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Group, GroupService } from '@klickdata/core/group';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';
import { ConfigService } from '@klickdata/core/config';
import { FormHelper } from '@klickdata/core/form';

@Component({
    selector: 'app-group-chip-select',
    templateUrl: './group-chip-select.component.html',
    styleUrls: ['./group-chip-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GroupChipSelectComponent),
            multi: true,
        },
    ],
})
export class GroupChipSelectComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Input() selectable = true;
    @Input() removable = true;
    @Input() maxSelected = 3;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    control = new FormControl();
    filteredGroups: Observable<Group[]>;
    private _selection: Group[] = [];

    get selection(): Group[] {
        return this._selection;
    }
    set selection(value: Group[]) {
        this._selection = value;
        this.propagateChange(this._selection);
    }

    constructor(
        protected groupService: GroupService,
        protected configService: ConfigService,
        protected elementRef: ElementRef,
        protected cdRef: ChangeDetectorRef,
        protected injector: Injector
    ) {}

    ngOnInit() {
        this.filteredGroups = this.control.valueChanges.pipe(
            filter((term) => typeof term === 'string'),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term) => (term.trim() ? this.groupService.getCustomerGroups(term) : of([]))),
            takeUntil(this.destroy)
        );
    }

    ngAfterViewInit() {
        this.checkDisable();
    }

    /**
     * Not yet supported!
     * @param event from separators events.
     */
    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add Category
        if ((value || '').trim()) {
            // this.selection.push(value.trim());
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }

        this.control.setValue(null);
        // this.checkDisable();
    }

    private checkDisable() {
        if (this.maxSelected && this.selection && this.selection.length >= this.maxSelected) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    remove(category: Group): void {
        const index = this.selection.findIndex((cat) => cat.name === category.name);
        if (index !== -1) {
            this.selection.splice(index, 1);
            this.propagateChange(this.selection);
            this.control.enable();
        }
        this.checkDisable();
    }

    onSelected(event: MatAutocompleteSelectedEvent): void {
        const selected = event.option.value;
        // Duplication not aceptable.
        if (this.selection.findIndex((cat) => cat.id === selected.id) === -1) {
            this.selection.push(selected);
            this.propagateChange(this.selection);
            this.searchInput.nativeElement.value = '';
            this.control.setValue(null);
        }

        this.checkDisable();
    }

    public propagateChange = (_: any) => {};
    public writeValue(value: (Group | number)[]): void {
        const control = this.injector.get(NgControl)?.control;
        if (value?.length && !(value[0] instanceof Group)) {
            this.groupService
                .getGroupsByIds(<number[]>value)
                .pipe(takeUntil(this.destroy))
                .subscribe((users) => {
                    this.selection = users || [];
                    this.cdRef.markForCheck();
                    if (control instanceof FormControl) {
                        FormHelper.resetForm(control);
                    }
                });
        } else {
            this.selection = <Group[]>value || [];
            this.cdRef.markForCheck();
            if (control instanceof FormControl) {
                FormHelper.resetForm(control);
            }
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}

    public stopPropagation(ev: MouseEvent): void {
        ev.stopPropagation();
    }
}
