import { Injectable } from '@angular/core';
import { GloalSearchService, GlobalSearchData } from '@klickdata/core/global-search';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { Resource } from '@klickdata/core/resource';
import { ResourceItemService } from '@klickdata/core/resource-item';
import { Filter } from '@klickdata/core/table';
import { SectionDataService } from '@klickdata/shared-components/src/section-item-parent/section-data.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SearchResultsSectionDataService implements SectionDataService<Resource> {
    constructor(
        protected searchService: GloalSearchService,
        protected mediaService: MediaService,
        protected itemService: ResourceItemService
    ) {}
    getData(config: {
        param?: { [key: string]: any };
        language?: number[];
        query?: string;
        page?: number;
        limit?: number;
        sort?: Filter<string>;
        direction?: Filter<string>;
    }): Observable<any> {
        return this.searchService.getScopeSearchResults(Object.assign({}, config, { scope: config.param.scope })).pipe(
            map(res => {
                return config.param.scope === 'all'
                    ? this.mapSearchItems(res.data)
                    : {
                          data: this.mapSectionItems(res.data),
                          total_count: res.paginator.total_count,
                      };
            })
        );
    }
    private mapSearchItems(data: GlobalSearchData[]) {
        const itemsMap = new Map<number, GlobalSearchData[]>();
        data.forEach(item => {
            // item.media$ = this.mediaService.getMedia(item.media_id, item.scope_id, item.artnr);
            item.media$ = of(item.media);
            item.title = item.label;
            const group = itemsMap.get(item.scope_id);
            if (!group) {
                itemsMap.set(item.scope_id, [item]);
            } else {
                group.push(item);
            }
        });
        return itemsMap;
    }
    private mapSectionItems(res) {
        return res.map(item => this.createResource(item));
    }
    private createResource(resource): Resource {
        // resource.media$ = this.mediaService.getMedia(resource.media_id, resource.scope_id, resource.artnr);
        resource.media$ = of(resource.media);
        resource.title = resource.label;
        return resource;
    }
}
