import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { ResponseData } from '@klickdata/core/http/src/responce/responce';
import { Observable, of } from 'rxjs';
import { first, map, share } from 'rxjs/operators';
import { Folder, FolderData } from './folder.model';
import { Resource, ResourceService, ResourceData } from '@klickdata/core/resource';
import { PaginatorResponse } from '@klickdata/core/http';

@Injectable({
    providedIn: 'root',
})
export class FolderService {
    protected resourceUrl: string;
    protected folderUrl: string;
    protected user_id: Observable<number>;
    protected customer_id: Observable<number>;
    private parentsMap = new Map<number, Observable<Folder>>();

    /**
     * FolderService constructor
     */
    constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected resourceService: ResourceService,
        protected config: ConfigService
    ) {
        this.folderUrl = `${config.config.apiUrl}folders`;
        this.resourceUrl = `${config.config.apiUrl}resources`;

        this.user_id = this.auth.getUser().pipe(
            first(),
            map((user) => user.id)
        );

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }

    /**
     * Create a RequestBuilder for fetching Folders.
     */
    public getFolders(params: {
        type?: number;
        scope?: 'customer' | 'assignedOrCustomer';
        query?: string;
        ids?: number[];
    }): Observable<Folder[]> {
        const builder = this.builder.get<FolderData[]>(`${this.folderUrl}`);
        if (params.type) {
            builder.param('type', params.type);
        }
        builder.param(params.scope || 'assignedOrCustomer', this.customer_id);
        builder.query(params.query);
        builder.ids(params.ids);
        return builder.request().pipe(map((res) => this.createFolders(res)));
    }

    public getSectionFolders(
        section_id: number,
        query: string,
        page: number,
        limit: number
    ): Observable<{ data: Folder[]; total_count: number }> {
        const builder = this.builder.get<FolderData[]>(`${this.folderUrl}`);
        builder.param('section', section_id);
        if (query?.length) {
            builder.param('query', query);
        }
        builder.page(page);
        builder.limit(limit || 25);
        return builder.request().pipe(
            map((res: PaginatorResponse<FolderData[]>) => ({
                data: this.createFolders(res),
                total_count: res.paginator.total_count,
            }))
        );
    }

    public getFolder(folderId: number): Observable<Folder> {
        if (!folderId) {
            return of(new Folder());
        }

        return this.builder
            .get<FolderData>(`${this.folderUrl}/${folderId}`)
            .request()
            .pipe(map((res) => this.createFolder(res.data)));
    }

    public getParentFolders(params: { [name: string]: any }): Observable<Folder[]> {
        const builder = this.builder.get<FolderData[]>(`${this.folderUrl}`);

        Object.entries(params)
            .filter((param) => !!param[1])
            .forEach((param) => builder.param(param[0], <any>param[1]));

        return builder.request().pipe(map((res) => this.createFolders(res)));
    }

    /**
     * Create a new Folder.
     */
    public create(folder: FolderData): Observable<Folder> {
        return this.builder
            .post<FolderData>(this.folderUrl, folder)
            .request()
            .pipe(map((res) => this.createFolder(res.data)));
    }

    /**
     * Update a Folder
     */
    public update(folder: FolderData): Observable<Folder> {
        return this.builder
            .put<FolderData>(`${this.folderUrl}/${folder.id}`, folder)
            .request()
            .pipe(map((res) => new Folder(res.data)));
    }

    /**
     * Send publish request for Folder to the server.
     */
    // public publish(customer: Customer, publish: CustomerPublish): Observable<MessageSent> {
    //     return this.builder.put<MessageSent>(`${this.folderUrl}/${customer.id}/publish`, publish)
    //         .request().pipe(
    //             map(res => res.data),
    //         );
    // }

    /**
     * Delete Folder
     */
    public destroy(folder: Folder): Observable<{ success: boolean }> {
        return this.builder
            .delete<{ success: boolean }>(`${this.folderUrl}/${folder.id}`)
            .request()
            .pipe(map((res) => res.data));
    }

    protected createFolder(folderData: FolderData): Folder {
        const folder = new Folder(folderData);
        if (folder.parent_id) {
            folder.parent = this.getParent(folder.parent_id);
        }
        return folder;
    }

    protected getParent(parent_id: number): Observable<Folder> {
        let parent = this.parentsMap.get(parent_id);
        if (parent) {
            return parent;
        }
        parent = this.getFolder(parent_id).pipe(share());
        this.parentsMap.set(parent_id, parent);
        return parent;
    }

    protected createFolders(res: ResponseData<FolderData[]>): Folder[] {
        return res.data.map((data) => this.createFolder(data));
    }

    protected createFolderOrResource(res: ResponseData<(FolderData | ResourceData)[]>): (Folder | Resource)[] {
        return res.data.map((data) =>
            'weight' in data ? this.createFolder(data) : this.resourceService.createResource(<ResourceData>data)
        );
    }
}
