<div fxLayout="row wrap" fxLayoutAlign="center">
    <app-flash-saved *ngIf="saved" (action)="onChange()" fxFlex="0 1 1000px" fxFlex.lt-lg="80%" fxFlex.lt-md="100%">
        {{ resource?.title }} <ng-container i18n>course saved!</ng-container>
    </app-flash-saved>

    <mat-card class="mt-2" fxFlex="0 1 1000px" fxFlex.lt-lg="80%" fxFlex.lt-md="100%">
        <mat-card-content fxLayout="row wrap">
            <app-admin-course-from (submit)="onSubmit()" [resourceSettings]="resourceSettings">
                <div *ngIf="!showInDialog" class="mt-1 mb-1" fxFlex="1 0 100%" fxLayout="row" fxLayoutAlign="end">
                    <button mat-raised-button color="primary" button type="submit">Save</button>
                </div>
            </app-admin-course-from>
        </mat-card-content>
    </mat-card>
</div>
