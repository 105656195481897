import { ResourceItem } from '@klickdata/core/resource-item/src/resource-item';

export interface PlaylistItem {
    index: number;
    item?: ResourceItem;
}

export class Playlist {
    playList: PlaylistItem[];
    constructor(data?: PlaylistItem[]) {
        this.playList = data;
    }
}
