import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { LanguageService } from '@klickdata/core/localization';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { User, UserService } from '@klickdata/core/user';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { combineLatest, takeUntil } from 'rxjs';

@Component({
    selector: 'app-menu-side-manage-ask-ai-predefined-qs',
    templateUrl: './menu-side-manage-ask-ai-predefined-qs.component.html',
    styleUrls: ['./menu-side-manage-ask-ai-predefined-qs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideManageAskAiPredefinedQsComponent extends OnDestroyHandler implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public lang = new FormControl('');
    public promptsTranslation: FormGroup;
    public promptLang: string;
    public userPrompts: { [key: string]: string[] };
    public saving: boolean;
    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        protected localizationService: LanguageService,
        protected cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.promptsTranslation = this.fb.group({
            promptsFormArray: this.fb.array([]),
        });
        this.userPrompts = this.user.prompts_translation;
        this.promptLang = this.localizationService.getCurrentLanguage()?.lang ?? 'en-US';
        combineLatest([this.lang.valueChanges, this.localizationService.supportLanguages])
            .pipe(takeUntil(this.destroy))
            .subscribe(([selectedId, languages]) => {
                this.promptLang = languages.find((lang) => lang.id == +selectedId)?.lang ?? 'en-US';
                this.updatePromptsArray();
            });
        this.promptsTranslation.valueChanges
            .pipe(takeUntil(this.destroy))
            .subscribe((groupValue) => (this.userPrompts[this.promptLang] = groupValue.promptsFormArray));
    }
    updatePromptsArray() {
        const userPrompt = this.userPrompts[this.promptLang];
        this.promptsArray.clear();
        if (!!userPrompt?.length) {
            userPrompt.forEach((prompt) => this.promptsArray.push(new FormControl(prompt)));
        } else {
            this.promptsArray.push(new FormControl(''));
        }
        this.cdr.markForCheck();
    }
    get user(): User {
        return this.navData.data;
    }
    get promptsArray(): FormArray {
        return this.promptsTranslation?.get('promptsFormArray') as FormArray;
    }
    removePrompt(promptIndex: number) {
        this.promptsArray.removeAt(promptIndex);
        FormHelper.markForm(this.promptsArray);
    }
    addNewPrompt() {
        this.promptsArray.push(this.fb.control(''));
        this.promptsArray.markAsDirty();
    }
    submit() {
        this.saving = true;
        this.userService
            .update({ id: this.user.id, prompts_translation: this.userPrompts })
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.saving = false;
                this.onClose.emit();
            });
    }
}
