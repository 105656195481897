import {
    ChangeDetectionStrategy, Component, EventEmitter,
    Input,
    Output
} from '@angular/core';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-select-permission-level',
    templateUrl: './menu-side-select-permission-level.component.html',
    styleUrls: ['./menu-side-select-permission-level.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideSelectPermissionLevelComponent {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;

    constructor() {}
}
