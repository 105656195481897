import { AbstractForm } from './abstract-form';
import { AbstractControl, FormGroup } from '@angular/forms';
import { IModel } from '@klickdata/core/application';
import { FormHelper } from '../form-helper';

export abstract class AbstractFormGroup<T extends IModel> extends AbstractForm<T> {
    public get form(): FormGroup {
        return this._form as FormGroup;
    }

    public get(fieldName: string): AbstractControl {
        return this.form.get(fieldName);
    }

    public set(controlName: string, value: any): void {
        this.model[controlName] = value;

        if (this.form.contains(controlName)) {
            this.form.get(controlName).setValue(value);
        }
    }

    /**
     * Resets all form component states to an initial state.
     * No data is affected
     */
    public resetState(recursive: boolean = false): void {
        FormHelper.resetForm(this.form);
    }

    public connect(childForm: AbstractControl, propertyName: string) {
        this.form.addControl(propertyName, childForm);
    }

    protected commit(): void {
        const controls = this.form.controls;
        const keys = Object.keys(controls);
        // ignore override id value
        const idIndex = keys.indexOf('id');
        if (idIndex !== -1) {
            keys.splice(idIndex, 1);
        }

        for (const key of keys) {
            this.model[key] = controls[key].value;
        }

        this.model = new this.class_t({
            ...this.model.getData(),
        });
    }
}
