import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterTabDirective } from './router-tab.directive';
import { RouterTabsDirective } from './router-tabs.directive';

@NgModule({
  declarations: [RouterTabDirective, RouterTabsDirective],
  exports: [MatTabsModule, RouterTabsDirective, RouterTabDirective]
})
export class RouterTabModule {
}
