<div class="input-data" fxLayout.lt-sm="column">
    <div fxLayout="column">
        <strong class="answer-label" i18n="@@answer"> Answer: </strong>

        <span>
            {{ question.value }}
        </span>
    </div>
    <ng-container *ngIf="question.value">
        <ng-container *ngIf="question.metadata">
            <div fxLayout="column">
                <strong [ngStyle]="{ color: question.resultStyle?.color }" i18n> Feedback: </strong>

                <span>
                    {{ question.metadata.notes }}
                </span>
            </div>

            <div fxLayout="row" fxLayoutGap="5px">
                <span i18n="@@score">Score:</span>
                <span> {{ question.metadata.user_points }}/{{ question.max_points }} </span>
            </div>
            <div
                fxHide
                [ngClass]="{
                    'true-color': question.metadata.correct,
                    'wrong-color': !question.metadata.correct
                }"
                fxLayout="row"
                fxLayoutGap="5px"
            >
                <span>Learner answer is</span>
                <strong *ngIf="question.metadata.correct">Correct</strong>
                <strong *ngIf="!question.metadata.correct">Incorrect</strong>
            </div>
            <ng-container *ngIf="question.metadata.verifier">
                <div
                    fxLayout="row"
                    fxLayoutGap="5px"
                    class="fs-14"
                    [ngStyle]="{ color: question.resultStyle?.color }"
                    *ngIf="question.metadata.verifier == 'ai'"
                >
                    <span i18n>Validated by AI on</span>
                    <span>{{ question.metadata.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                </div>
                <div
                    fxLayout="row"
                    fxLayoutGap="5px"
                    class="fs-14"
                    [ngStyle]="{ color: question.resultStyle?.color }"
                    *ngIf="question.metadata.verifier != 'ai'"
                >
                    <span>Validated by</span>
                    <span *ngIf="question.metadata.name">{{ question.metadata.name }}</span>
                    <span *ngIf="!question.metadata.name">AI</span>
                    <span i18n>on</span>
                    <span>{{ question.metadata.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                </div>
            </ng-container>
        </ng-container>
        <div
            class="mt-1"
            *ngIf="authUser.isAdmin()"
            fxLayout="row"
            fxLayoutGap="10px"
            [ngClass]="{ 'on-loading': onLoading.value }"
        >
            <button
                i18n-mattoolTip
                mat-button
                class="review-AA-btn"
                (click)="$event.stopPropagation(); updateQsReviewByAA()"
            >
                <mat-icon class="material-icons-outlined">rate_review</mat-icon>
                <span *ngIf="question.metadata?.name">Update question review</span>
                <span *ngIf="!question.metadata?.name">Review question</span>
            </button>
            <button
                i18n-mattoolTip
                mat-button
                class="review-Ai-btn"
                (click)="$event.stopPropagation(); updateQsReviewByAI()"
            >
                <mat-icon class="material-icons-outlined ai-loading-icon">smart_toy</mat-icon>
                <span *ngIf="question.metadata?.verifier == 'ai'">Update AI review</span>
                <span *ngIf="question.metadata?.verifier != 'ai'">Review by AI</span>
            </button>
        </div>
    </ng-container>
</div>
