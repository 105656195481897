import { ChangeDetectorRef, Directive, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { takeUntil, tap } from 'rxjs';

@Directive()
export abstract class customFieldsBase extends OnDestroyHandler implements OnInit {
    @Input() customFieldForm: FormGroup;
    constructor(protected cdr: ChangeDetectorRef) {
        super();
    }
    ngOnInit(): void {
        this.customFieldForm.valueChanges
            .pipe(
                takeUntil(this.destroy),
                tap(() => this.cdr.markForCheck())
            )
            .subscribe();
    }
}
