<div fxFlex="0 0 100%" fxLayout="column" class="evaluation-wrapper">
    <form fxLayout="column" class="main-form" fxLayoutGap="10px" [formGroup]="evaluationForm">
        <mat-expansion-panel
            *ngIf="titlesExpansionsStatus?.length"
            [expanded]="titlesExpansionsStatus && titlesExpansionsStatus[0]"
            class="fe-expansion"
        >
            <mat-expansion-panel-header>
                <ng-content select="[feedbackTitle]"></ng-content>
            </mat-expansion-panel-header>
            <div fxLayout="column" fxLayoutGap="10px">
                <ng-container [ngTemplateOutlet]="feedback" [ngTemplateOutletContext]="{ form: evaluationForm }">
                </ng-container>
            </div>
        </mat-expansion-panel>
        <ng-container
            *ngIf="!titlesExpansionsStatus?.length"
            [ngTemplateOutlet]="feedback"
            [ngTemplateOutletContext]="{ form: evaluationForm }"
        >
        </ng-container>
        <mat-expansion-panel
            *ngIf="titlesExpansionsStatus?.length"
            [expanded]="titlesExpansionsStatus && titlesExpansionsStatus[1]"
            class="fe-expansion"
        >
            <mat-expansion-panel-header>
                <ng-content select="[assessTitle]"></ng-content>
            </mat-expansion-panel-header>
            <div fxLayout="column" fxLayoutGap="10px">
                <ng-container [ngTemplateOutlet]="assessments" [ngTemplateOutletContext]="{ form: evaluationForm }">
                </ng-container>
            </div>
        </mat-expansion-panel>
        <ng-container
            *ngIf="!titlesExpansionsStatus?.length"
            [ngTemplateOutlet]="assessments"
            [ngTemplateOutletContext]="{ form: evaluationForm }"
        >
        </ng-container>
    </form>
</div>
<ng-template #feedback let-form="form">
    <form fxLayout="column" fxLayoutGap="10px" [formGroup]="form">
        <div
            *ngIf="feedBackTemplates && !!feedBackTemplates.length"
            fxLayoutGap="4px"
            fxLayout="column"
            class="w-50 input-field-parent select"
        >
            <span i18n>Feedback template</span>
            <mat-form-field class="role-field">
                <mat-select fxFlex="1 1 100%" [formControl]="selectedFeedbackTemplate">
                    <mat-option *ngFor="let template of feedBackTemplates" [value]="template.id">{{
                        template.title
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <app-user-setting-input
            type="text"
            i18n-placeholder
            placeholder="Type your feedback title"
            [control]="evaluationForm.get('title') | cast"
        >
            <label role="input-label" fxLayoutGap="4px">
                <span i18n>Feedback Title</span>
            </label>
        </app-user-setting-input>
        <div class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
            <span i18n>Review</span>
            <mat-form-field appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                <textarea
                    #title
                    matInput
                    type="text"
                    formControlName="body"
                    placeholder="Enter review text"
                    i18n-placeholder
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="5"
                    cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>
        </div>
        <mat-checkbox (change)="saveFeedbackTemplate = $event.checked" color="primary" i18n
            >Save as feedback template</mat-checkbox
        >
        <form *ngIf="saveFeedbackTemplate" fxLayout="column" fxLayoutGap="10px" [formGroup]="feedbackTemplateForm">
            <app-user-setting-input
                type="text"
                i18n-placeholder
                placeholder="Set template name"
                [control]="feedbackTemplateForm.get('name') | cast"
            >
            </app-user-setting-input>
            <mat-radio-group fxLayout="row" formControlName="visibility" fxLayoutAlign="space-between center">
                <mat-radio-button value="private" i18n> Private </mat-radio-button>
                <mat-radio-button value="academy" i18n> Academy </mat-radio-button>
            </mat-radio-group>
        </form>
    </form>
</ng-template>
<ng-template #assessments let-form="form">
    <form fxLayout="column" fxLayoutGap="10px" [formGroup]="form">
        <div
            *ngIf="assessmentTemplates && !!assessmentTemplates.length"
            fxLayoutGap="4px"
            fxLayout="column"
            class="w-100 input-field-parent select"
        >
            <span i18n>Assessments template</span>
            <mat-form-field class="role-field">
                <mat-select fxFlex="1 1 100%" [formControl]="selectedAssessmentTemplate">
                    <mat-option *ngFor="let template of assessmentTemplates" [value]="template.id">{{
                        template.title
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="column" fxLayoutGap="20px" formArrayName="criteria" class="assess-wrapper">
            <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngFor="let criterionForm of criteriaFormArray.controls; let i = index"
                [formGroup]="criterionForm | cast"
                class="criteria-item-wrapper"
            >
                <div class="criteria-item" fxFlex="50%" fxLayout="column">
                    <div
                        [ngStyle]="{ color: criterionForm.value.color | colorsCodesByNames }"
                        *ngIf="!criterionForm.value.editable"
                        class="label criteria-item-label"
                    >
                        {{ criterionForm.value.label }}
                    </div>
                    <app-user-setting-input
                        *ngIf="criterionForm.value.editable"
                        [customAction]="true"
                        type="text"
                        i18n-placeholder
                        placeholder="Type your assessment title"
                        [control]="criterionForm.get('label') | cast"
                    >
                        <label role="input-label" fxLayoutGap="4px">
                            <span i18n>Assessment title</span>
                        </label>
                        <button
                            *ngIf="criterionForm.value.label"
                            role="custom-action"
                            class="add-assess"
                            matTooltip="Update"
                            mat-icon-button
                            (click)="criterionForm.get('editable').patchValue(false)"
                        >
                            <mat-icon>check_circle</mat-icon>
                        </button>
                    </app-user-setting-input>
                    <div class="mt--10" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                        <mat-slider
                            thumbLabel="true"
                            showTickMarks
                            class="eval-slider {{ criterionForm.value.color }}"
                            formControlName="value"
                            fxFlex="grow"
                            min="0"
                            max="10"
                            step="1"
                        >
                            <input matSliderThumb />
                        </mat-slider>
                        <span
                            [ngStyle]="{ color: criterionForm.value.color | colorsCodesByNames }"
                            class="slider-value"
                            >{{ criterionForm.value.value }}</span
                        >
                    </div>
                    <div class="number-labels">
                        <p class="sec-font" i18n>0</p>
                        <p class="sec-font" i18n>5</p>
                        <p class="sec-font" i18n>10</p>
                    </div>
                </div>
                <div class="criteria-item actions" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="center center">
                    <button
                        mat-icon-button
                        matTooltip="Choose assessment color"
                        i18n-matTooltip
                        [ngStyle]="{ color: criterionForm.value.color | colorsCodesByNames }"
                        [matMenuTriggerFor]="menu"
                        class="color-badge-btn"
                    >
                        <mat-icon class="color-badge">fiber_manual_record</mat-icon>
                    </button>
                    <mat-menu class="widgets-colors-selection" #menu="matMenu">
                        <button
                            class="menu-color-badge-btn"
                            mat-menu-item
                            *ngFor="let color of kdColorsSet"
                            (click)="criterionForm.get('color').patchValue(color)"
                        >
                            <mat-icon [ngStyle]="{ color: color | colorsCodesByNames }" class="color-badge"
                                >fiber_manual_record</mat-icon
                            >
                        </button>
                    </mat-menu>

                    <button
                        class="grey"
                        mat-icon-button
                        matTooltip="Remove assessment"
                        i18n-matTooltip
                        (click)="removeCriterion(i)"
                    >
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                    <button
                        *ngIf="!criterionForm.value.editable"
                        mat-icon-button
                        matTooltip="Edit assessment"
                        i18n-matTooltip
                        class="green"
                        (click)="criterionForm.get('editable').patchValue(true)"
                    >
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div>

            <div fxLayoutAlign="start center" fxLayout="row">
                <button class="add-btn" mat-button matTooltip="Add assessment" i18n-matTooltip (click)="addCriterion()">
                    <mat-icon>assessment</mat-icon>
                    <span i18n>Add assessment</span>
                </button>
            </div>

            <mat-checkbox (change)="saveAssessTemplate = $event.checked" color="primary" i18n
                >Save as formative evaluation template</mat-checkbox
            >
            <form *ngIf="saveAssessTemplate" fxLayout="column" fxLayoutGap="10px" [formGroup]="assessmentTemplateForm">
                <app-user-setting-input
                    type="text"
                    i18n-placeholder
                    placeholder="Set template name"
                    [control]="assessmentTemplateForm.get('name') | cast"
                >
                </app-user-setting-input>
                <mat-radio-group fxLayout="row" formControlName="visibility" fxLayoutAlign="space-between center">
                    <mat-radio-button value="private" i18n> Private </mat-radio-button>
                    <mat-radio-button value="academy" i18n> Academy </mat-radio-button>
                    <!-- <mat-radio-button value="public" i18n> Public </mat-radio-button> -->
                </mat-radio-group>
            </form>
        </div>
    </form>
</ng-template>
