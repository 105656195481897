import { ChangeDetectionStrategy, Component } from '@angular/core';
import { customFieldsBase } from '../custom-fields-base';

@Component({
    selector: 'app-custom-fields-switch-type',
    templateUrl: './custom-fields-switch-type.component.html',
    styleUrls: ['./custom-fields-switch-type.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsSwitchTypeComponent extends customFieldsBase {}
