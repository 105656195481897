<div fxLayout="column" fxFlex="0 0 100%">
    <app-table-filter-expansion-panel [hasTopBorder]="true">
        <app-table-filter [cacheScope]="AppScope.RESOURCES" [inGlobalFilter]="true" color="accent" #filter>
            <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
            <app-language-filter
                [inGlobalFilter]="true"
                #langFilter
                fxFlex="0 0 auto"
                fxLayout="row"
                langFilterRef
            ></app-language-filter>
        </app-table-filter>
    </app-table-filter-expansion-panel>
    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    >
    </mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        [matSortActive]="'name'"
        matSortDirection="desc"
        matSort
        [class.mobileView]="isMobile"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name"> Name </mat-header-cell>
            <mat-cell *matCellDef="let user">
                <div fxLayoutAlign="start center" fxLayoutGap="10px">
                    <app-image-view
                        [ngStyle]="{ minWidth: '50px' }"
                        [isCircularImg]="true"
                        [media_id]="user.media"
                        paddingBottom="50px"
                    ></app-image-view>
                    <span *ngIf="!isMobile">{{ user.name }}</span>
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
            <mat-cell *matCellDef="let user" (click)="$event.stopPropagation()">
                <button
                    (click)="
                            $event.stopPropagation(); expandedElement = expandedElement === user ? null : user
                        "
                    matTooltip="View users"
                    mat-icon-button
                    i18n-matTooltip="@@viewUsers"
                >
                    <mat-icon>{{ expandedElement === user ? 'expand_less' : 'expand_more' }}</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let user" [attr.colspan]="columns.length">
                <div
                    class="example-element-detail"
                    [@detailExpand]="user == expandedElement ? 'expanded' : 'collapsed'"
                >
                    <h2>Expanded Cell</h2>
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            [ngClass]="!user.loading ? 'link' : 'disableIt'"
            [class.example-expanded-row]="expandedElement === user"
            (click)="expandedElement = expandedElement === user ? null : user"
            *matRowDef="let user; columns: columns"
        ></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading sizeee="small"></app-kd-logo-loading>
    </div>
</ng-template>
