import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatTabGroup } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { LoggerService } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { LanguageService } from '@klickdata/core/localization';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { MessageDeletedComponent, MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Quote, QuoteData, QuoteService, QuoteTypes } from '@klickdata/core/quote';
import { AppScope, Resource, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';
import { User } from '@klickdata/core/user';
import { BehaviorSubject, EMPTY, Observable, Subject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { ButtonModel } from '../../text-editor/editor/editor.component';
import { Utils } from '@klickdata/core/util';
import { Educator } from '@klickdata/core/resource/src/resource.model';
@Component({
    selector: 'app-quotes-manager',
    templateUrl: './quotes-manager.component.html',
    styleUrls: ['./quotes-manager.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuotesManagerComponent implements OnInit, OnDestroy, AfterViewInit {
    public quoteForm: FormGroup;
    @Input() user: User;
    @Input() set quote(quote: Quote) {
        this._quote = quote;
        this.buildManager();
    }
    get quote(): Quote {
        return this._quote;
    }
    private _quote: Quote;
    ResourceTypes: ResourceTypes;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public onLoading: Subject<boolean> = new Subject<boolean>();
    @ViewChild('mainTabs') mainTabs: MatTabGroup;
    QuoteTypes = QuoteTypes;
    ResourceStaffRoles = ResourceStaffRoles;
    public resource = new Resource();
    public contentCtrl = new FormControl();
    public relatedResources: Resource[] = [];
    public filteredContent: BehaviorSubject<Resource[]> = new BehaviorSubject<Resource[]>([]);
    AppScope = AppScope;
    loading: boolean;

    constructor(
        protected fb: FormBuilder,
        protected mobileService: MobileService,
        protected mediaService: MediaService,
        protected messageService: MessageService,
        protected router: Router,
        protected cdr: ChangeDetectorRef,
        protected resourceService: ResourceService,
        protected languageService: LanguageService,
        protected quoteService: QuoteService,
        protected auth: AuthService,
        protected logger: LoggerService
    ) {
        this.buildForm();
    }

    ngOnInit(): void {}
    private buildManager() {
        if (this.quote && this.quote.id) {
            this.updateForm();
        } else {
            this.resourceService
                .getEducatorByUserId()
                .pipe(takeUntil(this.destroy))
                .subscribe((edu) => {
                    this.quoteForm.get('staff').get('manager')?.patchValue([edu.id]);
                    this.quoteForm.get('staff').get('manager')?.markAsPristine();
                    this.cdr.markForCheck();
                });
            this.resourceService
                .getDefaultEducatorsByRole(ResourceStaffRoles.PUBLISHER)
                .pipe(takeUntil(this.destroy))
                .subscribe((publishers) => {
                    this.quoteForm.get('staff').get('publisher')?.patchValue([publishers[0].id]);
                    this.quoteForm.get('staff').get('publisher')?.markAsPristine();
                    this.cdr.markForCheck();
                });
        }
    }

    buildForm() {
        this.quoteForm = this.fb.group({
            quote: ['', Validators.required],
            transcript: [],
            source: [],
            source_link: [],
            source_file: [],
            language_id: ['', Validators.required],
            public: [false],
            type: ['general'],
            publish: [false],
            tag_ids: [[]],
            resource_ids: [[]],
            staff: this.fb.group({
                publisher: [],
                manager: [],
                quotee: [],
            }),
            medias: this.fb.group({
                cover: [],
            }),
        });
    }
    updateForm() {
        this.quoteForm.patchValue({
            quote: this.quote.quote,
            publish: !!this.quote.published_at,
            public: this.quote.public,
            language_id: this.quote.language_id,
            tag_ids: this.quote.tag_ids,
            staff: this.quote.staff,
            medias: this.quote.medias,
        });
        if (this.quote.resource_ids?.length) {
            this.updateQuoteRes();
        }
        FormHelper.resetForm(this.quoteForm);
    }
    updateQuoteRes() {
        this.resourceService
            .getResources(this.quote.resource_ids, null, 10, null, [
                { property: 'publicOrCustomer', items: [this.quote.customer_id] },
                { property: 'language', items: [this.quote.language_id] },
            ])
            .pipe(takeUntil(this.destroy))
            .subscribe((resources) => {
                this.relatedResources = resources;
                this.quoteForm.get('resource_ids').patchValue(this.relatedResources.map((res) => res.id));
                this.quoteForm.get('resource_ids').markAsPristine();
                this.quoteForm.get('resource_ids').markAsUntouched();
                this.cdr.markForCheck();
            });
    }
    addAIQuote() {
        if (this.loading) return;
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.AI_GENERATE,
            data: {
                headline: $localize`Provide a context for a quote and AI will help you`,
                placeholder: $localize`The context for generating Quote using AI`,
                value: this.quoteForm.value.quote,
                button: $localize`Add quote from AI`,
                hasData: this.quoteForm.value.quote,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                if (data && data.value) {
                    this.quoteForm.get('quote').patchValue(data.value);
                    this.generate();
                    this.quoteForm.get('quote').disable();
                }
            });
    }
    onQuoteeClick(quotee: Educator) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.SHOW_QUOTES_OF_QUOTEE,
            data: quotee,
        });
    }
    save() {
        this.checkSubmitValid()
            .pipe(
                filter((isValid) => !!isValid),
                switchMap(() => this.prepareSubmit()),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                this.messageService.openMessage(MessageSavedComponent);
                this.onLoading.next(false);
                this.quoteForm.markAsPristine();
                this.router.navigate(['/admin/content/quotes']);
            });
    }
    checkEfficiency() {}

    private checkSubmitValid(): Observable<boolean> {
        if (!this.quoteForm.valid) {
            this.messageService.openMessage(MessageErrorComponent, $localize`quote text is required.`);
            return of(false);
        }
        return of(true);
    }
    private prepareSubmit(): Observable<Quote> {
        this.onLoading.next(true);
        return this.prepareData().pipe(
            switchMap((data) => (data.id ? this.quoteService.update(data) : this.quoteService.create(data)))
        );
    }
    protected prepareData(): Observable<QuoteData> {
        const data = this.quote?.id
            ? this.quote.getPayload(this.quoteForm.value)
            : new Quote(this.quoteForm.value).getPayload();
        if (data.id) {
            return of(data);
        } else {
            return combineLatest([this.auth.getUser(), this.auth.getCustomer()]).pipe(
                first(),
                takeUntil(this.destroy),
                map(([user, customer]) => {
                    data.created_by = user.id;
                    data.customer_id = customer.id;
                    return data;
                })
            );
        }
    }

    generate() {
        if (!this.loading) {
            this.loading = true;
            this.quotePrompt();
        }
    }

    list() {
        this.router.navigate(['/admin/content/quotes']);
    }

    ngAfterViewInit(): void {
        combineLatest([
            this.contentCtrl.valueChanges.pipe(
                filter((term) => typeof term === 'string'),
                debounceTime(300),
                distinctUntilChanged()
            ),
            this.languageService.getContentLanguage(),
        ])
            .pipe(
                switchMap(([term, lang]) => {
                    return term.trim()
                        ? this.resourceService.getResources(null, null, 10, term, [
                              { property: 'publicOrCustomer', items: [this.user.customer_id] },
                              { property: 'language', items: [lang.id] },
                          ])
                        : EMPTY;
                }),
                takeUntil(this.destroy)
            )
            .subscribe((resources) => this.filteredContent.next(resources));
    }
    public removeResource(res: Resource): void {
        const index = this.relatedResources.indexOf(res);
        if (index >= 0) {
            this.relatedResources.splice(index, 1);
            this.quoteForm.get('resource_ids').patchValue(this.relatedResources.map((res) => res.id));
            this.quoteForm.get('resource_ids').markAsDirty();
            this.cdr.markForCheck();
        }
    }

    public selectResource(event: MatAutocompleteSelectedEvent, auto: MatAutocomplete): void {
        this.relatedResources.push(event.option.value);
        this.contentCtrl.setValue('');
        this.quoteForm.get('resource_ids').patchValue(this.relatedResources.map((res) => res.id));
        this.quoteForm.get('resource_ids').markAsDirty();
        this.cdr.markForCheck();
    }
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    private quotePrompt() {
        this.quoteService
            .prompt({ ...this.quoteForm.value, id: this.quote?.id, quote_type: 'ai' })
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((quote) => {
                if (!this.quote?.id) {
                    this.quote = quote;
                } else {
                    this.quote.prompt_status = 'PROCESSING';
                }
                this.buildSocket();
            });
    }

    private buildSocket() {
        this.quoteService
            .listenToQuote(this.quote.id)
            .pipe(takeUntil(this.destroy))
            .subscribe((quote) => {
                this.loading = false;
                this.quote = quote;
                this.quoteForm.get('quote').enable();
            });
    }

    delete() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: $localize`Delete quote`,
                contentBody: $localize`You are about to delete this quote. Please use caution and make sure that you really intend to delete before confirming delete below.`,
                positiveBtn: $localize`Delete`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap((res) => {
                    if (res) {
                        return this.quoteService.destroy(this.quote);
                    }
                })
            )
            .subscribe((action) => {
                if (action) {
                    this.messageService.openMessage(MessageDeletedComponent);
                    this.quoteForm.markAsPristine();
                    this.router.navigate(['/admin/content/quotes']);
                }
            });
    }
    public saveAndDeactivate(): Observable<boolean> {
        return this.checkSubmitValid().pipe(
            switchMap((isValid: boolean) => (isValid ? this.prepareSubmit().pipe(map(() => true)) : of(false)))
        );
    }
    public canDeactivate(): boolean {
        return this.quoteForm.pristine;
    }
}
