<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon class="{{ navData.data.iconClass }}">{{ navData.data.icon }}</mat-icon>
        <div>{{ navData.data.title }}</div>
    </div>
    <div class="desc">{{ navData.data.contentBody }}</div>
    <div
        class="stars-wrapper"
        [ngClass]="{
            perfect: mandatoryPercentage === 100 && optionalPercentage === 100,
            'not-valid': mandatoryPercentage < 100,
            'not-optional': mandatoryPercentage === 100 && optionalPercentage === 0,
            'semi-optional': mandatoryPercentage === 100 && optionalPercentage < 100
        }"
    >
        <mat-icon>{{ mandatoryPercentage === 100 ? 'star' : 'star_border' }}</mat-icon>
        <mat-icon>{{ mandatoryPercentage === 100 ? 'star' : 'star_border' }}</mat-icon>
        <mat-icon>{{ mandatoryPercentage === 100 && optionalPercentage > 33.3 ? 'star' : 'star_border' }}</mat-icon>
        <mat-icon>{{ mandatoryPercentage === 100 && optionalPercentage > 66.6 ? 'star' : 'star_border' }}</mat-icon>
        <mat-icon>{{ mandatoryPercentage === 100 && optionalPercentage === 100 ? 'star' : 'star_border' }}</mat-icon>
    </div>
    <div class="content-wrapper" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="15px">
        <div class="mandatory-parts">
            <span class="title" i18n>Mandatory</span>
            <div
                *ngFor="let mandatoryKey of navData.data.mandatory"
                fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="5px"
            >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon>{{ mandatoryKey.hasValidValue ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
                    <span>{{ mandatoryKey.title }}</span>
                    <mat-icon *ngIf="mandatoryKey.info" class="icon-explain" [matTooltip]="mandatoryKey.info"
                        >help_outline</mat-icon
                    >
                </div>
            </div>
        </div>
        <div class="optional-parts">
            <span class="title" i18n>Complementry</span>
            <div
                *ngFor="let optionalKey of navData.data.optional"
                fxLayout="column"
                fxLayoutAlign="start start"
                fxLayoutGap="5px"
            >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon>{{ optionalKey.hasValidValue ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
                    <span>{{ optionalKey.title }}</span>
                    <mat-icon *ngIf="optionalKey.info" class="icon-explain" [matTooltip]="optionalKey.info"
                        >help_outline</mat-icon
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            *ngIf="navData.data.positiveBtn"
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="onClose.emit({ action: SideNaveActionsTypes.POSITIVE })"
            mat-raised-button
        >
            {{ navData.data.positiveBtn }}
        </button>
        <button
            *ngIf="navData.data.negativeBtn"
            fxFlex="0 0 auto"
            class="mobile-button negative"
            (click)="onClose.emit()"
            mat-button
        >
            {{ navData.data.negativeBtn }}
        </button>
        <button
            *ngIf="navData.data.customBtn"
            fxFlex="0 0 auto"
            class="mobile-button custom"
            (click)="onClose.emit({ action: SideNaveActionsTypes.CUSTOM })"
            mat-button
        >
            {{ navData.data.customBtn }}
        </button>
    </div>
</div>
