import { Directive, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { Subject } from 'rxjs';

@Directive()
export abstract class ResourceItemComposerDirective implements OnDestroy {
    @Input() readonly index: number;
    @Input() resource: Resource;
    @Input() disabled: boolean;
    @Input() opened: number;
    @Input() active: boolean;

    @Input() set itemForm(itemForm: FormGroup) {
        this._itemForm = itemForm;
        this.afterItemFormInit();
    }
    private _itemForm: FormGroup;
    public get itemForm(): FormGroup {
        return this._itemForm;
    }
    public resourceForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();

    constructor(protected parentFormDirective: FormGroupDirective) {
        this.resourceForm = this.parentFormDirective.form;
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    afterItemFormInit():void {}
}
