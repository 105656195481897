import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Customer } from '@klickdata/core/customer';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil, filter, map, first } from 'rxjs/operators';

@Component({
    selector: 'app-date-range-dialog',
    templateUrl: './date-range-dialog.component.html',
    styleUrls: ['./date-range-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeDialogComponent implements AfterViewInit, OnDestroy {
    public max = moment().utc();
    public rangeForm: FormGroup;
    public minDate: moment.Moment;
    public min: Observable<moment.Moment>;
    protected destroy: Subject<boolean> = new Subject<boolean>();
    constructor(
        protected fb: FormBuilder,
        public dialogRef: MatDialogRef<DateRangeDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            customer: Observable<Customer>;
            label: string;
        }
    ) {
        this.buildRangeForm();
    }

    ngAfterViewInit(): void {
        this.rangeForm.valueChanges
            .pipe(
                takeUntil(this.destroy),
                filter(value => value && value.begin && value.end),
                filter(range => this.vaildRange(range))
            )
            .subscribe(value => {
                this.dialogRef.close({ begin: value.begin.format('YYYY-MM-DD'), end: value.end.format('YYYY-MM-DD') });
            });
        this.min = this.data.customer.pipe(
            first(),
            map((customer: Customer) => {
                this.minDate = customer.published;
                return this.minDate;
            })
        );
    }
    protected buildRangeForm(): void {
        this.rangeForm = this.fb.group({
            begin: [null, Validators.required],
            end: [null, Validators.required],
        });
    }
    private vaildRange(range: { begin: moment.Moment; end: moment.Moment }): boolean {
        return (
            range.begin.isSameOrBefore(range.end) &&
            range.begin.isBetween(this.minDate, this.max, 'day', '[]') &&
            range.end.isBetween(this.minDate, this.max, 'day', '[]')
        );
    }
    public clear(): void {
        if (this.rangeForm) {
            // this.rangeForm.reset();
            // this.filterRemoval.emit(new Filter('begin', []));
            // this.filterRemoval.emit(new Filter('end', []));
        }
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
