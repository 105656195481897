import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { Collection, CollectionService } from '@klickdata/core/collection';
import { MessageDeletedComponent, MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceTypes } from '@klickdata/core/resource';
import { Section, SectionService } from '@klickdata/core/section';
import { GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { BrowseSectionComponent } from '../../browse-section/browse-section.component';
import { SelectModelBaseDirective } from '../../selection-handler/select-model-base/select-model-base.component';
import { CollectionBrowseBottomSheetComponent } from '../collection-browse-bottom-sheet/collection-browse-bottom-sheet.component';
import { CollectionSelectService } from '../../collections/collections-listing-select/collection-select.service';

@Component({
    selector: 'app-collection-select',
    templateUrl: './collection-select.component.html',
    styleUrls: ['./collection-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CollectionSelectService],
})
export class CollectionSelectComponent
    extends SelectModelBaseDirective<Collection>
    implements OnInit, AfterViewInit, OnDestroy
{
    @Input() accessForm: FormGroup;
    @Input() referenceFilter: string;
    @Input() AdditionBtn: boolean;
    @Input() scope: 'public' | 'assignedOrCustomer' = 'assignedOrCustomer';
    @Input() hideTools: boolean;
    @Output() add: EventEmitter<void> = new EventEmitter<void>();
    @Output() edit: EventEmitter<Collection> = new EventEmitter<Collection>();
    ResourceTypes = ResourceTypes;
    protected sync_all_key = 'sync_all_sections';
    protected attachKey = 'sections_attach';
    protected detachKey = 'sections_detach';
    columns: string[];
    public itemSelectedOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;

    constructor(
        public datatableService: CollectionSelectService,
        protected bottomSheet: MatBottomSheet,
        protected mobileService: MobileService,
        protected collectionService: CollectionService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected messageService: MessageService
    ) {
        super(datatableService, mobileService);
        this.itemSelectedOptions = Utils.getItemSelectedOptions();
    }

    /**
     *
     */
    public ngOnInit(): void {
        super.ngOnInit();
        this.columns = this.hideTools ? ['select', 'title', 'updated_at'] : ['select', 'title', 'updated_at', 'tools'];
    }

    protected refresh(id: any): void {
        if ('public' === this.scope) {
            this.filter.createOrUpdateFilter([
                { property: this.referenceFilter, items: [id] },
                { property: this.scope, items: [1] },
            ]);
        } else {
            this.auth
                .getCustomer()
                .pipe(first(), takeUntil(this.destroy))
                .subscribe((customer) =>
                    this.filter.createOrUpdateFilter([
                        { property: this.referenceFilter, items: [id] },
                        {
                            property: this.scope,
                            items: [customer.id],
                        },
                    ])
                );
        }
    }

    public showSections(collection: Collection) {
        this.bottomSheet.open(CollectionBrowseBottomSheetComponent, {
            data: collection,
            panelClass: 'sheet-wrapper',
        });
    }

    public deleteCollection(collection: Collection) {
        this.dialog
            .open(DeleteDialogComponent)
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => result),
                switchMap(() => this.collectionService.deleteCollection(collection.id))
            )
            .subscribe((success) => {
                if (success) {
                    this.dataSource.refresh();
                    this.messageService.openMessage(MessageDeletedComponent);
                }
            });
    }
}
