<div class="container" fxLayout="row" fxLayoutAlign="center center">
    <button *ngIf="showPrev"
            (click)="picker.prevMonth()"
            class="nav-btn"
            mat-mini-fab color="primary" fxFlex="0 0 auto"
            matTooltip="Previous month" i18n-matTooltip="@@previousMonth">
        <mat-icon>navigate_before</mat-icon>
    </button>

    <button *ngIf="!showPrev" [disabled]="true"
            (click)="picker.prevMonth()"
            class="nav-btn"
            mat-icon-button color="primary" fxFlex="0 0 auto"
            matTooltip="Previous month" i18n-matTooltip="@@previousMonth">
        <mat-icon>navigate_before</mat-icon>
    </button>
    <div fxFlex="0 1 auto" class="date-container" #container></div>
    <button *ngIf="showNext"
            (click)="picker.nextMonth()"
            class="nav-btn"
            mat-mini-fab color="primary" fxFlex="0 0 auto"
            matTooltip="Next month" i18n-matTooltip="@@nextMonth">
        <mat-icon>navigate_next</mat-icon>
    </button>

    <button *ngIf="!showNext" [disabled]="true"
            (click)="picker.nextMonth()"
            class="nav-btn"
            mat-icon-button color="primary" fxFlex="0 0 auto"
            matTooltip="Next month" i18n-matTooltip="@@nextMonth">
        <mat-icon>navigate_next</mat-icon>
    </button>
</div>

