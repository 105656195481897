<mat-accordion *ngIf="authUser | async; else loading" class="media-uploader-lib" displayMode="flat">
    <mat-expansion-panel [expanded]="false" (opened)="onExpanding.emit(true)" #expansionPanel>
        <mat-expansion-panel-header>
            <div class="tool-button-inner" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-icon class="item-icon">collections</mat-icon>
                <span fxFlex="grow" class="tool-button-text" i18n="@@multipleChoice"> Library </span>
                <mat-icon class="qs-m" matTooltip="From library" i18n-matTooltip>question_mark</mat-icon>
            </div>
        </mat-expansion-panel-header>
        <mat-tab-group mat-stretch-tabs #tabGroup>
            <mat-tab>
                <ng-template mat-tab-label i18n>Private</ng-template>
                <ng-template matTabContent>
                    <app-media-listing-general
                        (onMediaClick)="onSelectMedia.emit($event)"
                        [columns]="columns"
                        [fixedFilters]="privateTableFilterItems"
                    ></app-media-listing-general>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label i18n>Academy</ng-template>
                <ng-template matTabContent>
                    <app-media-listing-general
                        (onMediaClick)="onSelectMedia.emit($event)"
                        [columns]="columns"
                        [fixedFilters]="academyTableFilterItems"
                    ></app-media-listing-general>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label i18n>Library</ng-template>
                <ng-template matTabContent>
                    <app-media-listing-general
                        (onMediaClick)="onSelectMedia.emit($event)"
                        [columns]="columns"
                        [fixedFilters]="libraryTableFilterItems"
                    ></app-media-listing-general>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </mat-expansion-panel>
</mat-accordion>
<ng-template #loading>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
