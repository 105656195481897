<div fxLayout="column" fxFlex="0 0 100%" class="hr-notes-wrapper">
    <app-table-filter-expansion-panel class="task-expansion" #filterExpansion [hasTopBorder]="false">
        <app-table-filter [filtersTogglesInOnBtn]="true" [inGlobalFilter]="true" color="accent" #filter>
            <app-table-search
                toggleBtnLabel="File"
                i18n-toggleBtnLabel
                [inGlobalFilter]="true"
                class="align-space"
                searchFilterRef
            ></app-table-search>

            <app-tag-chip-search-filter
                [inGlobalFilter]="true"
                fxFlex="50%"
                fxFlex.lt-md="100%"
                fxLayoutAlign="start"
                tagsFilterRef
            ></app-tag-chip-search-filter>
            <app-select-filter
                [inGlobalFilter]="true"
                class="filter-space"
                label="Prio"
                i18n-label="@@prio"
                [property]="GlobalFilterProperty.TASK_PRIO"
                [options]="filePrioOptions"
                taskPrioRef
            ></app-select-filter>
        </app-table-filter>
    </app-table-filter-expansion-panel>
    <!-- <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar> -->
    <mat-table
        #table
        [dataSource]="dataSource"
        matSortActive="created_at"
        matSortDirection="desc"
        matSort
        multiTemplateDataRows
    >
        <ng-container matColumnDef="title">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Name</span>
                </div>
            </mat-header-cell>
            <mat-cell [ngClass]="{ 'a1-name': file.priority == 'a1' }" *matCellDef="let file">
                <span class="mobile-label" i18n>Name:</span>
                <span class="notes">{{ file.title }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created_at">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Created at</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
                <span>{{ file.created_at | kdDateFormat : 'YYMMDD_HHmm' }}</span></mat-cell
            >
        </ng-container>
        <ng-container matColumnDef="updated_at">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Last edit</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
                <span>{{ file.updated_at | kdDateFormat : 'YYMMDD_HHmm' }}</span></mat-cell
            >
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@description">Description</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
                <span>{{ file.description }}</span></mat-cell
            >
        </ng-container>
        <ng-container matColumnDef="tags">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Tags</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
                <span *ngIf="isMobile" class="mobile-label" i18n> Tags </span>
                <app-table-tags-on-the-fly
                    (click)="$event.stopPropagation()"
                    [tags]="file.tags"
                    (onUpdateTags)="file.id && updateFileTags(file, $event)"
                    (onTagClick)="onTagClick($event)"
                ></app-table-tags-on-the-fly>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="author">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Creator</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
                <span class="mobile-label" i18n>Creator:</span>
                <span class="author-name" *ngIf="file.author?.name" [matTooltip]="file.author?.name">{{
                    file.author?.name | nameSign
                }}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="id">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Id</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
                <span class="mobile-label" i18n>Id:</span>
                <div [matTooltip]="file.priority" class="id-column">
                    <span>{{ file.id }}</span>
                    <span *ngIf="significantPrio.includes(file.priority)" class="prio {{ file.priority }}">*</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="priority">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Priority</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
                <span class="mobile-label" i18n>Priority:</span>
                <app-item-change-on-the-fly
                    class="customer-item-not-task {{ file.priority }}"
                    [capitalize]="true"
                    [selectionOptions]="filePrioOptions"
                    [value]="file.priority"
                    (onValueChange)="onSelectPriority(file, $event)"
                ></app-item-change-on-the-fly>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="btns-actions" *matCellDef="let file">
                <button
                    (click)="$event.stopPropagation(); createMaterial(file)"
                    matTooltip="Create material"
                    mat-icon-button
                    i18n-matTooltip="@@createMaterial"
                    class="creat-mat"
                >
                    <mat-icon
                        fxFlex="0 0 auto"
                        color="primary"
                        fontSet="klickdata"
                        fontIcon="kd-icon-document"
                        class="course-plan-icon kd-icon-24 no-shadow"
                    ></mat-icon>
                </button>

                <div class="download-wrapper">
                    <span class="files-count">{{ file.medias?.file?.length }}</span>
                    <button
                        (click)="$event.stopPropagation(); download(file)"
                        matTooltip="Download"
                        mat-icon-button
                        i18n-matTooltip="@@download"
                        class="comment-add"
                    >
                        <mat-icon>file_download</mat-icon>
                    </button>
                </div>

                <button
                    (click)="$event.stopPropagation(); edit(file)"
                    matTooltip="Edit"
                    mat-icon-button
                    i18n-matTooltip="@@edit"
                    class="comment-add"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); delete(file)"
                    matTooltip="Delete"
                    mat-icon-button
                    i18n-matTooltip="@@delete"
                    class="comment-add"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let file; columns: columns" class="example-element-row"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <mat-spinner fxFlex="0 0 auto"></mat-spinner>
    </div>
</ng-template>
