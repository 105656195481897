import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { LanguageService } from '@klickdata/core/localization';
import { MessageService, MessageSavedComponent } from '@klickdata/core/message';
import { Task, TaskService } from '@klickdata/core/task';
import { User, UserService } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { Observable, Subject, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'app-task-tags-handler',
    templateUrl: './task-tags-handler.component.html',
    styleUrls: ['./task-tags-handler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * This comp update the user tags only, not user task tags
 */
export class TaskTagsHandlerComponent implements OnInit, OnDestroy, AfterViewInit {
    public destroy: Subject<boolean> = new Subject<boolean>();
    public tags = new FormControl([]);
    public currentLaguageId$: Observable<number>;
    @Input() user: User;
    @Output() onUserUpdate: EventEmitter<User> = new EventEmitter<User>();
    constructor(
        protected langService: LanguageService,
        protected taskService: TaskService,
        protected userService: UserService,
        protected messageService: MessageService
    ) {}

    ngOnInit(): void {
        if (this.user?.tag_ids != this.tags.value) {
            this.tags.patchValue(this.user?.tag_ids);
        }
        this.currentLaguageId$ = this.langService
            .getLanguageByKey(this.langService.getCurrentLanguage().value)
            .pipe(map((lang) => lang.id));
    }
    ngAfterViewInit(): void {
        this.tags.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                filter((ids) => !Utils.arraysEqual(ids, this.user?.tag_ids)),
                switchMap((ids) =>
                    this.userService.update(
                        {
                            id: this.user.id,
                            // task: { ...(this.user.task ? this.user.task : {}), ...{ tag_ids: ids } },
                            tag_ids: ids,
                        },
                        ['tags,task,managers']
                    )
                )
            )
            .subscribe((updatedUser) => this.onUserUpdate.emit(updatedUser));
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
