import { Injectable } from '@angular/core';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { Question, QuestionData, QuestionService } from '@klickdata/core/question';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TestsResultsQuestionsService extends QuestionService implements TableHttpService<Question> {
    public fetchData(source: TableSource<Question>): Observable<ResponseData<QuestionData[]>> {
        const req = this.builder.get<QuestionData[]>(this.resourceUrl);
        req.param('customer', this.customer_id);
        req.param('result', 1);
        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<QuestionData[]>) => {
                return {
                    data: res.data.map(data => this.createQuestion(data)),
                    notify: res.notify,
                    more: res.more,
                    paginator: res.paginator,
                };
            })
        );
    }
}
