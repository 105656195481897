<div class="chip-container" fxLayout="column" fxLayoutGap.gt-sm="10px">
    <mat-form-field class="search-box" appearance="fill">
        <mat-label i18n="@@instructor">Instructor</mat-label>
        <input
            matInput
            #search
            placeholder="Filter by instructor name..."
            i18n-placeholder="@@filterByInstructorName"
            [formControl]="control"
        />
    </mat-form-field>
    <h4 *ngIf="source?.length" class="filter-header" i18n="@@chooseFilter">Choose filter</h4>
    <mat-chip-list
        fxFlex="0 0 100%"
        [class.top-align]="source?.length"
        [ngClass]="{ 'mobile-view': isMobile | async }"
        [multiple]="multiple"
    >
        <ng-container *ngFor="let item of source">
            <mat-chip class="chip-point" [value]="item.id" [selected]="item.selected">
                {{ item.label }}
            </mat-chip>
        </ng-container>
        <div *ngIf="source?.length" class="seperater"></div>
    </mat-chip-list>
</div>

<ng-template #activeFilterTemplate let-filter="filter">
    <mat-icon>{{ filter.icon }}</mat-icon>
    <span *ngFor="let item of filter.chips; let last = last">{{ item.label }}<span *ngIf="!last">,&nbsp;</span> </span>
</ng-template>
