import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Question, QuestionTypes } from '@klickdata/core/question';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@klickdata/core/user';

@Component({
    selector: 'app-questions-with-alts-results',
    templateUrl: './questions-with-alts-results.component.html',
    styleUrls: ['./questions-with-alts-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuestionsWithAltsResultsComponent extends OnDestroyHandler implements OnInit {
    @Input() question: Question;
    @Input() user: User;
    QuestionTypes = QuestionTypes;
    public onLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor() {
        super();
    }

    ngOnInit(): void {}
}
