<div fxLayoutAlign="start center" fxLayout="row">
    <button matTooltip="Remind" i18n-matTooltip class="tools" mat-icon-button (click)="onReminder.emit()">
        <mat-icon>notifications_active</mat-icon>
    </button>

    <mat-form-field class="datePicker">
        <mat-label>Choose a date</mat-label>
        <input matInput [max]="maxDate" [min]="minDate" [matDatepicker]="picker" [formControl]="endDate" />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <button matTooltip="Update end date" i18n-matTooltip class="tools" mat-icon-button (click)="picker.open()">
        <mat-icon>insert_invitation</mat-icon>
    </button>

    <button matTooltip="Delete assignment" i18n-matTooltip class="tools" mat-icon-button (click)="deleteOcc()">
        <mat-icon>delete_forever</mat-icon>
    </button>
</div>
