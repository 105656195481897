import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PublishMessage, PublishMessageData } from './publish-message.model';

@Injectable({
    providedIn: 'root',
})
export class PublishMessageService {
    protected _resourceUrl: string;
    protected _inviteUrl: string;

    constructor(protected _bs: RequestBuilderService, protected _config: ConfigService) {
        this._resourceUrl = `${this._config.config.apiUrl}publish-messages`;
        this._inviteUrl = `${this._config.config.apiUrl}invite-messages`;
    }

    public getResourcePublishMessages(resource_id: number): Observable<PublishMessage[]> {
        return this._bs
            .get<PublishMessageData[]>(this._resourceUrl)
            .param('resource', resource_id.toString())
            .request()
            .pipe(map((res) => this.mapData(res.data)));
    }

    public getAssignedPublishMessages(assign_id: number): Observable<PublishMessage[]> {
        return this._bs
            .get<PublishMessageData[]>(this._resourceUrl)
            .param('assign', assign_id)
            .request()
            .pipe(map((res) => this.mapData(res.data)));
    }

    public createOrUpdate(message: PublishMessageData): Observable<PublishMessage> {
        return message.id ? this.update(message) : this.store(message);
    }

    public inviteResource(body: {
        body: string;
        resource_id: number;
        subject: string;
        emails: string[];
    }): Observable<{ success: boolean } | {}> {
        return this._bs
            .post(`${this._inviteUrl}`, body)
            .request()
            .pipe(map((res) => res.data));
    }

    public store(message: PublishMessageData): Observable<PublishMessage> {
        return this._bs
            .post<PublishMessageData>(this._resourceUrl, message)
            .request()
            .pipe(map((res) => this.create(res.data)));
    }

    public update(message: PublishMessageData): Observable<PublishMessage> {
        return this._bs
            .put<PublishMessageData>(`${this._resourceUrl}/${message.id}`, message)
            .request()
            .pipe(map((res) => this.create(res.data)));
    }

    protected mapData(data: PublishMessageData[]): PublishMessage[] {
        return data.map((item) => this.create(item));
    }

    protected create(data?: PublishMessageData): PublishMessage {
        return new PublishMessage(data);
    }
}
