<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>mail</mat-icon><span i18n>Email to</span> <span>{{ navData.data.title }}</span
        >*
    </div>
    <form [formGroup]="welcomeForm" fxFlex="0 0 100%" fxLayout="row wrap">
        <mat-form-field fxFlex="1 0 100%">
            <input matInput placeholder="Subject/Title" i18n-placeholder formControlName="subject" />
            <mat-error i18n="@@aSubjectIsRequired">A subject is required!</mat-error>
        </mat-form-field>
        <mat-expansion-panel
            *ngIf="!navData.data.users_attach || navData.data.users_attach.length > 1"
            [expanded]="false"
            class="no-shadow users-panel"
            fxFlex="0 0 100%"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <mat-icon class="mr-1 app-icon">group</mat-icon>
                    <ng-container i18n="@@editRecipients">Edit recipients</ng-container>
                </mat-panel-title>
                <mat-panel-description *ngIf="welcomeForm.value.users_attach?.length || userSelect.total">
                    ({{ userSelect.selected }}/{{ userSelect.total }})
                    <ng-container i18n="@@userSelected">user selected</ng-container>
                </mat-panel-description>
            </mat-expansion-panel-header>

            <app-admin-users-select
                #userSelect
                [customer_id]="navData.data.customer_id"
                [referenceIdKey]="navData.data.idKey"
                [referenceFilter]="navData.data.filterKey || navData.data.idKey"
                [accessForm]="welcomeForm"
                defaultSorting="fname"
            ></app-admin-users-select>
        </mat-expansion-panel>

        <app-text-editor
            [subject]="welcomeForm.value.subject"
            (onSubject)="welcomeForm.controls['subject'].patchValue($event)"
            formControlName="body"
            fieldName="userWelcomeMessage"
            title="Content"
            i18n-title
            fxFlex="1 0 100%"
        >
            <mat-error i18n="@@messageBodyError">Message body is required!</mat-error>
        </app-text-editor>

        <div fxFlex="1 0 100%" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap=".5em" class="mt-1">
            <ng-container *ngIf="loading">
                <app-saving-button></app-saving-button>
            </ng-container>

            <button
                *ngIf="!loading"
                mat-raised-button
                color="primary"
                [disabled]="welcomeForm.invalid && welcomeForm.touched"
                (click)="submitWelcomeMessage()"
                i18n="@@sendEmail"
            >
                Send e-mail
            </button>
        </div>
    </form>
</div>
