import { Injectable } from '@angular/core';
import { Folder, FolderData, FolderService } from '@klickdata/core/folder';
import { Paginator, PaginatorResponse } from '@klickdata/core/http';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { ResourceData, Resource } from '@klickdata/core/resource';

@Injectable()
export class BrowseSectionListService extends FolderService implements TableHttpService<Folder | Resource> {
    private joinedResponse: PaginatorResponse<(FolderData | ResourceData)[]>;
    private ignoreFetchFolder: boolean;
    private ignoreFetchResource: boolean;
    fetchData(source: TableSource<Folder | Resource>): Observable<PaginatorResponse<(Folder | Resource)[]>> {
        const filters = source.filter.filter.filters;

        if (
            source.paginator?.pageIndex === 0 ||
            (this.joinedResponse && this.joinedResponse.paginator?.limit !== source.paginator?.pageSize)
        ) {
            source.paginator.pageIndex = 0;
            this.joinedResponse = null;
            this.ignoreFetchFolder = false;
            this.ignoreFetchResource = false;
        }
        let breadcrumbs: number[];
        filters.forEach((filter) => {
            if (filter.property === 'breadcrumbs') {
                breadcrumbs = <number[]>filter.items;
            }
        });

        const folderReq = this.builder.get<FolderData[]>(this.folderUrl);
        TableHttpHelpers.managePagination(source.paginator, folderReq);
        // TableHttpHelpers.manageSort(source.sort, folderReq);
        TableHttpHelpers.mapFilter(source.filter, folderReq, ['breadcrumbs', 'language']);

        const resourceReq = this.builder.get<FolderData[]>(this.resourceUrl);
        TableHttpHelpers.managePagination(source.paginator, resourceReq);
        // TableHttpHelpers.manageSort(source.sort, resourceReq);
        TableHttpHelpers.mapFilter(source.filter, resourceReq, ['breadcrumbs']);

        const scopeFilter = filters.find(
            (filter) => filter.property === 'assignedOrCustomer' || filter.property === 'public'
        );
        if (!scopeFilter) {
            resourceReq.param('assignedOrCustomer', this.customer_id);
            folderReq.param('assignedOrCustomer', this.customer_id);
        } else {
        }

        let obs: Observable<PaginatorResponse<FolderData[] | ResourceData[]>>;
        if (!(breadcrumbs && breadcrumbs.length)) {
            // fetch all assign folders
            folderReq.param('parent', 'null');
            TableHttpHelpers.manageSort(source.sort, folderReq);
            obs = folderReq.request();
        } else {
            // Main folder fetch it's sub folders & it's resources.
            // Main folder fetch it's sub folders & it's resources.
            const folder_id = breadcrumbs[breadcrumbs.length - 1];
            folderReq.param('parent', folder_id);
            resourceReq.param('folder', folder_id);
            if (!(this.ignoreFetchFolder || this.ignoreFetchResource)) {
                obs = forkJoin(folderReq.request(), resourceReq.request()).pipe(
                    map(([folder, resources]) => {
                        return this.handleResponse(
                            source.paginator,
                            <PaginatorResponse<FolderData[]>>folder,
                            <PaginatorResponse<ResourceData[]>>resources
                        );
                    })
                );
            } else if (this.ignoreFetchFolder) {
                // No more folders continue fetching resources.
                obs = resourceReq.request().pipe(
                    map((res: PaginatorResponse<ResourceData[]>) => {
                        this.joinedResponse.data = res.data;
                        return this.joinedResponse;
                    })
                );
            } else {
                // No more Resource continue fetching folders.
                obs = folderReq.request().pipe(
                    map((res: PaginatorResponse<FolderData[]>) => {
                        this.joinedResponse.data = res.data;
                        return this.joinedResponse;
                    })
                );
            }
        }

        return obs.pipe(
            map((res: PaginatorResponse<(FolderData | ResourceData)[]>) => {
                return {
                    data: this.createFolderOrResource(res),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }

    protected handleResponse(
        paginator: MatPaginator,
        folderResponse: PaginatorResponse<FolderData[]>,
        resourceResponse: PaginatorResponse<ResourceData[]>
    ): PaginatorResponse<FolderData[] | ResourceData[]> {
        if (!this.joinedResponse) {
            this.joinedResponse = {};
            // handle paginator.
            this.joinedResponse.paginator = <Paginator>{};
            const folderCount = folderResponse.paginator.total_count;
            const resCount = resourceResponse.paginator.total_count;
            this.joinedResponse.paginator.total_count = folderCount + resCount;
            this.joinedResponse.paginator.limit = paginator.pageSize;
        }

        this.ignoreFetchFolder = paginator.pageSize > folderResponse.data.length;
        this.ignoreFetchResource = paginator.pageSize > resourceResponse.data.length;

        this.joinedResponse.data = [...folderResponse.data, ...resourceResponse.data];
        if (paginator.pageSize < this.joinedResponse.data.length) {
            paginator.pageSize = this.joinedResponse.data.length;
            this.joinedResponse.paginator.limit = paginator.pageSize;
        }
        return this.joinedResponse;
    }
}
