import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResourceData } from '@klickdata/core/resource';

@Component({
    selector: 'app-share-dialog',
    templateUrl: './share-dialog.component.html',
    styleUrls: ['./share-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<ShareDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ResourceData
    ) {}

    ngOnInit(): void {}
}
