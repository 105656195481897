<app-resource-details-info
    [resource]="resource"
    [user]="user"
    [customer]="customer"
    [resourceItems]="resourceItems"
    [resourceCategories$]="resourceCategories$"
    [resourceTags$]="resourceTags$"
    [resourceType]="ResourceTypes.parentType(resource.type_id)"
    [resourceIsAvailable]="resourceIsAvailable"
    resourceLabel="E-course"
    i18n-resourceLabel
>
    <ng-container itemsHeader>
        <div fxLayoutGap="7px" fxLayoutAlign="start center">
            <mat-icon>category</mat-icon>
            <span i18n>E-course include</span>
        </div>
    </ng-container>
    <span managerLabel i18n="@@author">Author</span>
    <ng-container resourceItemsSection>
        <div
            (click)="infoComp.takeResource(resource, user, item)"
            class="res-bull mt-1"
            *ngFor="let item of resourceItems; let index = index"
        >
            <span class="index" [class.taken-task]="!!item.child_opportunity_done">{{ index + 1 }}</span>
            <span class="res-item-name ecourse" [class.parent]="!item.parent_id">{{ item.name }}</span>
        </div>
    </ng-container>
    <span actionBtnLabel i18n="@@start">Start</span>
    <ng-container resourceDates></ng-container>
    <span resCodeLabel i18n>E-Course code</span>
    <ng-container resourceCircles>
        <div class="circle-item" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div class="icon-wrapper">
                <mat-icon matListIcon fontSet="klickdata" fontIcon="kd-icon-player"></mat-icon>
            </div>
            <span i18n>E-Course</span>
        </div>
    </ng-container>
    <ng-container resourceDuration>
        <div class="details-elem">
            <div class="pre">
                <mat-icon>timelapse</mat-icon>
                <span i18n="@@duration">Duration </span>
            </div>
            <span class="normal">{{ resource?.duration | formatIsoDuration : true }} </span>
        </div>
    </ng-container>
</app-resource-details-info>
