<mat-expansion-panel
    [expanded]="index == opened && !itemForm.disabled"
    (opened)="opened = index"
    (closed)="opened === index && (opened = -1)"
    #expansionPanel
>
    <mat-expansion-panel-header
        class="on-hover-mat-item"
        fxLayoutGap="10px"
        fxLayout="row"
        fxLayoutAlign="start center"
    >
        <div fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
            <mat-checkbox
                [disabled]="!itemForm.value.name"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(index) : null"
                [checked]="selection.isSelected(index)"
            >
            </mat-checkbox>
            <span i18n>Link</span>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center">
            <ng-container *ngIf="expansionPanel.expanded">
                <button
                    [disabled]="!itemForm.value.name"
                    (click)="$event.stopPropagation(); duplicateItem(index)"
                    class="tools-btn-icon tools-btn"
                    mat-icon-button
                    matTooltip="Duplicate"
                    i18n-matTooltip="@@duplicate"
                >
                    <mat-icon class="material-icons-outlined">file_copy</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); deleteItem(index)"
                    class="tools-btn"
                    mat-icon-button
                    matTooltip="Delete"
                    i18n-matTooltip="@@delete"
                >
                    <mat-icon class="material-icons-outlined">delete</mat-icon>
                </button>
                <button
                    [disabled]="!itemForm.value.name"
                    (click)="$event.stopPropagation(); copyItem({ title: 'Link', name: itemForm.value.name })"
                    class="tools-btn-icon tools-btn copy"
                    mat-icon-button
                    matTooltip="Copy to clipboard"
                    i18n-matTooltip="@@CopyToClipboard"
                >
                    <mat-icon class="material-icons-outlined">content_copy</mat-icon>
                </button>
            </ng-container>
            <ng-content *ngIf="!expansionPanel.expanded"></ng-content>
        </div>
    </mat-expansion-panel-header>
    <div class="link-wrapper" fxLayout="column">
        <mat-form-field fxFlex="0 1 100%" class="mb-4">
            <input
                matInput
                #link_input
                placeholder="Link"
                type="url"
                i18n-placeholder
                [formControl]="itemForm.get('name') | cast"
            />
            <button
                matTooltip="Open link in new tab"
                i18n-matTooltip
                type="button"
                [disabled]="!itemForm.value.name || (!!itemForm.value.name && !Utils.isUrl(itemForm.value.name))"
                class="open-link"
                mat-icon-button
                matSuffix
                (click)="goToLink(itemForm.value.name)"
            >
                <mat-icon>open_in_new</mat-icon>
            </button>
        </mat-form-field>
        <div
            *ngIf="!!itemForm.value.name && Utils.isUrl(itemForm.value.name)"
            fxLayoutAlign="start start"
            fxLayoutGap="10px"
            fxLayout.lt-md="column"
        >
            <app-media-view
                fxLayoutAlign="center center"
                *ngIf="mediaKey as mediaId"
                fxFlex="0 0 100%"
                [media_id]="mediaId"
            >
            </app-media-view>
            <ng-container *ngIf="!!itemForm.value.name && Utils.isYoutubeURL(itemForm.value.name)">
                <ng-container *ngIf="youTubeVideoInfo$ | async; let videoData; else: youTubeNoInfo">
                    <div class="info-wrapper" fxLayout="column" fxLayoutGap="10px">
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <span class="info-title" i18n>Video title: </span>
                            <span class="info">{{ videoData.title }}</span>
                        </div>
                        <div fxLayoutAlign="start center" fxLayoutGap="5px">
                            <span class="info-title" i18n>Duration: </span>
                            <span class="info">{{ videoData.duration | formatIsoDuration }}</span>
                        </div>
                        <div fxLayoutAlign="start center" fxLayoutGap="5px">
                            <span class="info-title" i18n>Source: </span>
                            <span class="info">Youtube</span>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <ng-template #youTubeNoInfo
        ><ng-container *ngIf="isLoadingVideoInfo" [ngTemplateOutlet]="smallLoading"></ng-container>
    </ng-template>

    <ng-template #smallLoading>
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </ng-template>
</mat-expansion-panel>
