import { Component, HostBinding } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
    moduleId: module.id,
    selector: 'app-message-deleted',
    templateUrl: './message-deleted-undo.component.html',
    styleUrls: ['./message-deleted-undo.component.scss'],
})
export class MessageDeletedUndoComponent {
    @HostBinding('class') host = '.mat-simple-snackbar';

    public snackBarRef: MatSnackBarRef<MessageDeletedUndoComponent>;

    public action(): void {
        if (this.snackBarRef) {
            this.snackBarRef.closeWithAction();
        }
    }
}
