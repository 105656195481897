import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { Media } from '@klickdata/core/media/src/media.model';
import { MessageFormErrorComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import {
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceTypes,
    AppScope,
} from '@klickdata/core/resource';
import { ResourceItem, ResourceItemData, ResourceItemService } from '@klickdata/core/resource-item';
import { Utils } from '@klickdata/core/util';
import * as moment from 'moment';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-add-material-link',
    templateUrl: './add-material-link.component.html',
    styleUrls: ['./add-material-link.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMaterialLinkComponent implements OnChanges, OnInit, OnDestroy, CanComponentDeactivate {
    public linkForm: FormGroup;
    public media: Media;
    AppScope = AppScope;
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saved: EventEmitter<{ res: Resource; isParentSubmission?: boolean }> = new EventEmitter<{
        res: Resource;
        isParentSubmission?: boolean;
    }>();
    @Input() resource: Resource;
    @Input() publishOnly: boolean;
    @Input() active = true;
    @Input() publishLabel = $localize`Publish`;
    public isAdmin$: Observable<boolean>;
    ResourceTypes = ResourceTypes;
    private resourceItem: ResourceItem;
    Utils = Utils;
    private isParentSubmission: boolean;

    constructor(
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected itemService: ResourceItemService,
        protected auth: AuthService,
        protected messageService: MessageService,
        protected changeRef: ChangeDetectorRef,
        public router: Router,
        protected categoryService: ResourceCategoryService
    ) {
        this.buildForm();
    }

    ngOnChanges() {
        this.updateForm();
    }

    public buildForm() {
        this.linkForm = this.fb.group({
            id: [],
            priority: [],
            media_id: [],
            title: ['', Validators.required],
            link: ['', Validators.required],
            category_ids: [[], Validators.required],
            tag_ids: [[]],
            description: [''],
            instructions: [''],
            language_id: ['', Validators.required],
        });
    }

    ngOnInit() {
        this.isAdmin$ = this.auth.getUser().pipe(
            first(),
            map((user) => user.isAdmin())
        );
        this.setDefaultCategory();
    }

    private setDefaultCategory() {
        this.categoryService
            .getSuggestedCategory(ResourceTypes.MATERIAL)
            .pipe(takeUntil(this.destroy))
            .subscribe((category) => {
                if (!this.linkForm.controls.category_ids.value?.length) {
                    this.linkForm.patchValue({
                        category_ids: [category.id],
                    });
                    FormHelper.resetForm(<FormControl>this.linkForm.controls.category_ids);
                }
            });
    }

    public updateForm(): void {
        if (this.resource && this.linkForm) {
            this.linkForm.patchValue({
                id: this.resource.id,
                priority: this.resource.priority,
                title: this.resource.title,
                media_id: this.resource.media_id,
                description: this.resource.description,
                instructions: this.resource.instructions,
                // category_ids: this.resource.category_ids,
                language_id: this.resource.language_id,
            });
            if (this.resource.item$) {
                this.resource.item$.pipe(first(), takeUntil(this.destroy)).subscribe((item) => {
                    this.resourceItem = item;
                    this.linkForm.patchValue({
                        link: item.name,
                    });
                    FormHelper.resetForm(this.linkForm);
                    this.changeRef.markForCheck();
                });
            } else {
                FormHelper.resetForm(this.linkForm);
            }
        }
    }
    public submitMaterial() {
        this.isParentSubmission = true;
        this.submitLink();
    }
    public submitLink(form?: FormGroupDirective, publish?: boolean) {
        this.linkForm.patchValue({
            link: this.linkForm.get('link').value.replace(/\s/g, ''),
        });
        if (!Utils.isUrl(this.linkForm.get('link').value)) {
            this.messageService.openMessage(
                MessageErrorComponent,
                $localize`:@@linkShoudBeValidURL:The link should be a valid URL`
            );
            this.onError.emit(true);
            return;
        }
        if (!this.linkForm.valid) {
            FormHelper.markForm(this.linkForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            this.onError.emit(true);
            return;
        }

        this.prepareSubmit(publish).subscribe((resource) => {
            form?.resetForm();
            this.linkForm.reset();
            FormHelper.resetForm(this.linkForm);
            this.saving.emit(false);
            this.saved.emit({ res: resource, isParentSubmission: this.isParentSubmission });
            this.isParentSubmission = false;
            if (this.publishOnly) {
                this.setDefaultCategory();
            }
        });
    }

    public prepareSubmit(publish: boolean): Observable<Resource> {
        this.saving.emit(true);
        return this.prepareData(publish).pipe(
            switchMap((data) =>
                !this.resource?.id
                    ? this.resourceService.store(data)
                    : of(Object.assign(new Resource(), this.resource, data))
            ), // store resource
            switchMap((resource) => {
                // store item by resource id.
                const itemData = this.prepareLinkItem(resource);
                return this.itemService.getResourceItemByResourceId(resource.id).pipe(
                    switchMap((item) =>
                        !item
                            ? this.itemService.store(itemData)
                            : this.itemService.update({ ...itemData, ...{ id: item.id } })
                    ),
                    map((item) => {
                        resource.item_ids = [item.id];
                        resource.start_item = item.id;
                        resource.last_item = item.id;
                        resource.article_code = `${this.media?.src}-${item.id}`;
                        return resource;
                    })
                );
            }),
            switchMap((resource) => this.resourceService.update(resource.getPayload())), // update resource item id.
            takeUntil(this.destroy)
        );
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    protected prepareLinkItem(resource: Resource): ResourceItemData {
        return {
            name: this.linkForm.value.link,
            item_type_value: 'url_material',
            resource_id: resource.id,
        };
    }

    protected prepareData(publish: boolean): Observable<ResourceData> {
        const data = new Resource(this.linkForm.value).getData();
        if (publish) {
            data.published = moment().format('YYYY-MM-DD HH:mm:ss');
        }
        data.resource_items = this.prepareMaterialLinkItem();
        if (Utils.isUrl(this.linkForm.value.media_id)) {
            data.media_url = this.linkForm.value.media_id;
        }
        if (!this.resource?.id) {
            return combineLatest([this.auth.getCustomer(), this.auth.getUser()]).pipe(
                first(),
                map(([customer, user]) => {
                    data.author_id = user.id;
                    data.customer_id = customer.id;
                    data.type_id = ResourceTypes.URLMaterial;
                    return data;
                })
            );
        } else {
            return of(data);
        }
    }
    protected prepareMaterialLinkItem(): ResourceItemData[] {
        if (this.linkForm.value.link !== '') {
            return this.resourceItem && this.resourceItem.id
                ? [
                      {
                          id: this.resourceItem.id,
                          name: this.linkForm.value.link,
                      },
                  ]
                : [
                      {
                          name: this.linkForm.value.link,
                          item_type_value: 'url_material',
                      },
                  ];
        } else {
            if (this.resource && this.resourceItem?.id) {
                return [{ id: this.resourceItem.id, deleted: true }];
            }
        }
    }
    get mediaKey() {
        const link = this.linkForm.value.link;
        if (link && Utils.isVideoUrl(link)) {
            this.media = Utils.getMedia(link);
            // update resource article code used on start/resume feature player module.
            return link;
        }
        this.media = null;
    }
    goToLink(url: string) {
        if (Utils.isUrl(url)) {
            window.open(url, '_blank');
        }
    }

    public canDeactivate(): boolean {
        return this.linkForm.pristine;
    }
}
