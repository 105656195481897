<div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
    <mat-icon>my_location</mat-icon>
    <div i18n>Add a new address</div>
</div>
<form
    class="group-item"
    fxFlex="100%"
    fxFlex.gt-sm="50%"
    fxLayout="column"
    fxLayoutGap="15px"
    [formGroup]="addressForm"
>
    <div class="relative" fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="column" fxLayoutGap="15px">
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>Address Type</span>
            <mat-form-field class="role-field">
                <mat-label>Type</mat-label>
                <mat-select fxFlex="1 1 100%" formControlName="address_type">
                    <mat-option value="main">Head Quarter</mat-option>
                    <mat-option value="delivery">Delivery</mat-option>
                    <mat-option value="local">Local Branch</mat-option>
                    <mat-option value="invoice">Invoice</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="column" fxLayoutGap="15px">
        <app-user-setting-input
            type="text"
            i18n-placeholder
            placeholder="Enter address"
            [control]="addressForm.get('street') | cast"
        >
            <label role="input-label" i18n="@@address">Address</label>
        </app-user-setting-input>
    </div>
    <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="row" fxLayoutGap="15px">
        <app-user-setting-input
            fxFlex="48%"
            type="text"
            i18n-placeholder
            placeholder="Zipcode"
            [control]="addressForm.get('zipcode') | cast"
        >
            <label role="input-label" i18n="@@zipcode">Zipcode</label>
        </app-user-setting-input>
        <app-user-setting-input
            fxFlex="48%"
            type="text"
            i18n-placeholder
            placeholder="Enter country"
            [control]="addressForm.get('country') | cast"
        >
            <label role="input-label" i18n="@@country">Country</label>
        </app-user-setting-input>
    </div>
    <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="row" fxLayoutGap="15px">
        <app-user-setting-input
            fxFlex="48%"
            type="text"
            i18n-placeholder
            placeholder="Zipcode"
            [control]="addressForm.get('zipcode') | cast"
        >
            <label role="input-label" i18n="@@zipcode">Zipcode</label>
        </app-user-setting-input>
        <app-user-setting-input
            fxFlex="48%"
            type="text"
            i18n-placeholder
            placeholder="Enter CO"
            [control]="addressForm.get('box') | cast"
        >
            <label role="input-label" i18n="@@co">CO</label>
        </app-user-setting-input>
    </div>

    <ng-container formGroupName="communications">
        <ng-container formArrayName="phone">
            <div class="relative" fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="row wrap" fxLayoutGap="10px">
                <button matTooltip="Add phone" class="add-address phone" mat-icon-button (click)="addNewPhone()">
                    <mat-icon>add_circle</mat-icon>
                </button>
                <ng-container
                    *ngFor="let phoneGroup of phoneFormArray.controls; let phoneIndex = index"
                    [formGroup]="phoneGroup | cast"
                >
                    <app-user-setting-input
                        class="phone-control-{{ phoneIndex }}"
                        [customAction]="true"
                        fxFlex="47%"
                        type="text"
                        i18n-placeholder
                        [placeholder]="'Enter phone ' + (phoneIndex + 1)"
                        [control]="phoneGroup.get('phone') | cast"
                    >
                        <label role="input-label">Phone {{ phoneIndex + 1 }}</label>
                        <button
                            *ngIf="phoneIndex != 0"
                            role="custom-action"
                            class="remove-phone"
                            matTooltip="Remove phone"
                            mat-icon-button
                            (click)="removePhone(phoneIndex)"
                        >
                            <mat-icon>remove_circle_outline</mat-icon>
                        </button>
                    </app-user-setting-input>
                </ng-container>
            </div>
        </ng-container>
    </ng-container>
</form>
<div class="action">
    <button
        [disabled]="!addressForm.dirty"
        fxFlex="0 0 auto"
        class="mobile-button"
        (click)="submit()"
        mat-raised-button
        type="button"
    >
        <span>Done</span>
    </button>
</div>
