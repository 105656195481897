<mat-tab-group class="main-reg-tabs" mat-stretch-tabs="true" mat-align-tabs="start">
    <mat-tab *ngIf="!navData.data.user">
        <div class="first-action" fxLayout="column">
            <div><h2>Data</h2></div>
        </div>
    </mat-tab>
    <mat-tab>
        <div class="data-wrap" fxLayout="column">
            <div fxLayoutAlign="space-between center">
                <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon>how_to_reg</mat-icon>
                    <div>{{ displayedTitle }}</div>
                </div>
                <div class="action-btns">
                    <button
                        *ngIf="stepper.selectedIndex !== 0"
                        color="accent"
                        fxFlex="0 0 auto"
                        mat-icon-button
                        (click)="stepper?.previous()"
                        type="button"
                    >
                        <mat-icon>arrow_back_ios</mat-icon>
                    </button>
                    <button
                        *ngIf="stepper.selectedIndex < stepper.steps.length - 1"
                        fxFlex="0 0 auto"
                        mat-button
                        color="primary"
                        type="button"
                        (click)="stepper?.next()"
                        i18n
                    >
                        Next
                    </button>
                    <button
                        *ngIf="stepper.selectedIndex === stepper.steps.length - 1"
                        fxFlex="0 0 auto"
                        mat-button
                        color="primary"
                        type="submit"
                        (click)="register()"
                        i18n
                    >
                        Confirm
                    </button>
                </div>
            </div>
            <form [formGroup]="registrationForm">
                <div fxFlex="100%" fxLayout="row">
                    <mat-stepper
                        (selectionChange)="getDisplayedTitle($event)"
                        class="regis-stepper"
                        labelPosition="bottom"
                        linear
                        #stepper
                    >
                        <mat-step
                            *ngIf="!navData.data.user"
                            [stepControl]="registrationForm.get('personalRegForm')"
                            [editable]="true"
                        >
                            <div
                                fxLayout="column"
                                fxLayoutGap="10px"
                                [formGroup]="registrationForm.get('personalRegForm') | cast"
                            >
                                <!-- <span class="stepper-title">Basic information</span> -->
                                <app-user-setting-input
                                    type="text"
                                    i18n-placeholder
                                    placeholder="First Name*"
                                    [control]="registrationForm.get('personalRegForm').get('fname') | cast"
                                >
                                    <!-- <label role="input-label" i18n="@@firstName">First Name*</label> -->
                                </app-user-setting-input>

                                <app-user-setting-input
                                    type="text"
                                    i18n-placeholder
                                    placeholder="Last Name*"
                                    [control]="registrationForm.get('personalRegForm').get('lname') | cast"
                                >
                                    <!-- <label role="input-label" i18n="@@lastName">Last Name*</label> -->
                                </app-user-setting-input>
                                <app-user-setting-input
                                    type="email"
                                    i18n-placeholder
                                    placeholder="Email*"
                                    [control]="registrationForm.get('personalRegForm').get('email') | cast"
                                >
                                    <label role="input-label">
                                        <!-- <ng-container i18n="@@email"> Email*</ng-container> -->
                                    </label>
                                </app-user-setting-input>
                                <app-user-setting-input
                                    [control]="registrationForm.get('personalRegForm').get('phone') | cast"
                                    type="text"
                                    i18n-placeholder
                                    placeholder="Mobile Number"
                                >
                                    <label role="input-label">
                                        <!-- <ng-container i18n="mobileNumber">Mobile Number</ng-container> -->
                                    </label>
                                </app-user-setting-input>
                                <div
                                    *ngIf="!navData.data.user"
                                    class="mt-1"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start center"
                                >
                                    <mat-checkbox formControlName="remember" color="primary" i18n
                                        >Rememeber me</mat-checkbox
                                    >
                                </div>
                                <div *ngIf="!navData.data.user" fxLayoutGap="5px" fxLayoutAlign="start center">
                                    <mat-checkbox formControlName="add" color="primary" i18n
                                        >Add me in KLMS</mat-checkbox
                                    >
                                </div>
                            </div>
                        </mat-step>
                        <mat-step [stepControl]="registrationForm.get('invoiceRegForm')" [editable]="true">
                            <div
                                fxLayout="column"
                                fxLayoutGap="10px"
                                fxLayout="column"
                                [formGroup]="registrationForm.get('invoiceRegForm') | cast"
                            >
                                <app-user-setting-input
                                    type="text"
                                    i18n-placeholder
                                    placeholder="Organization name"
                                    [control]="registrationForm.get('personalRegForm').get('business_company') | cast"
                                >
                                </app-user-setting-input>
                                <app-user-setting-input
                                    type="text"
                                    i18n-placeholder
                                    placeholder="Department"
                                    [control]="registrationForm.get('invoiceRegForm').get('department') | cast"
                                >
                                </app-user-setting-input>

                                <app-user-setting-input
                                    type="text"
                                    i18n-placeholder
                                    placeholder="Organization address"
                                    [control]="registrationForm.get('invoiceRegForm').get('orgnumber') | cast"
                                >
                                </app-user-setting-input>
                                <div
                                    fxLayout="column"
                                    fxLayoutGap="10px"
                                    [formGroup]="$any(registrationForm.get('invoiceRegForm').get('address'))"
                                >
                                    <app-user-setting-input
                                        type="text"
                                        i18n-placeholder
                                        placeholder="Street adress"
                                        [control]="
                                            registrationForm.get('invoiceRegForm').get('address').get('street') | cast
                                        "
                                    >
                                    </app-user-setting-input>
                                    <app-user-setting-input
                                        type="text"
                                        i18n-placeholder
                                        placeholder="Box"
                                        [control]="
                                            registrationForm.get('invoiceRegForm').get('address').get('box') | cast
                                        "
                                    >
                                    </app-user-setting-input>
                                    <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="row" fxLayoutGap="10px">
                                        <app-user-setting-input
                                            type="text"
                                            i18n-placeholder
                                            placeholder="Zipcode"
                                            [control]="
                                                registrationForm.get('invoiceRegForm').get('address').get('zipcode')
                                                    | cast
                                            "
                                        >
                                        </app-user-setting-input>
                                        <app-user-setting-input
                                            type="text"
                                            i18n-placeholder
                                            placeholder="City"
                                            [control]="
                                                registrationForm.get('invoiceRegForm').get('address').get('city') | cast
                                            "
                                        >
                                        </app-user-setting-input>
                                    </div>
                                    <app-user-setting-input
                                        type="text"
                                        i18n-placeholder
                                        placeholder="Country"
                                        [control]="
                                            registrationForm.get('invoiceRegForm').get('address').get('country') | cast
                                        "
                                    >
                                    </app-user-setting-input>
                                    <app-user-setting-input
                                        type="text"
                                        i18n-placeholder
                                        placeholder="Other info"
                                        [control]="
                                            registrationForm.get('invoiceRegForm').get('address').get('other') | cast
                                        "
                                    >
                                    </app-user-setting-input>
                                </div>
                                <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="row" fxLayoutGap="10px">
                                    <app-user-setting-input
                                        type="text"
                                        i18n-placeholder
                                        placeholder="Role in organization"
                                        [control]="registrationForm.get('invoiceRegForm').get('person_role') | cast"
                                    >
                                    </app-user-setting-input>
                                    <app-user-setting-input
                                        type="text"
                                        i18n-placeholder
                                        placeholder="Cost department"
                                        [control]="registrationForm.get('invoiceRegForm').get('cost_department') | cast"
                                    >
                                    </app-user-setting-input>
                                </div>
                                <div fxFlex="100%" fxFlex.gt-sm="50%" fxLayout="row" fxLayoutGap="10px">
                                    <app-user-setting-input
                                        type="text"
                                        i18n-placeholder
                                        placeholder="Purchase order"
                                        [control]="registrationForm.get('invoiceRegForm').get('purchase_order') | cast"
                                    >
                                    </app-user-setting-input>

                                    <app-user-setting-input
                                        type="text"
                                        i18n-placeholder
                                        placeholder="Campaign code"
                                        [control]="registrationForm.get('invoiceRegForm').get('campaign_code') | cast"
                                    >
                                    </app-user-setting-input>
                                </div>
                            </div>
                        </mat-step>
                        <mat-step
                            *ngIf="navData.data.resource.price"
                            [stepControl]="registrationForm.get('payment_method')"
                            [editable]="true"
                        >
                            <div fxLayout="column" fxLayoutGap="10px">
                                <mat-radio-group
                                    class="reg-payment"
                                    fxLayout="column"
                                    fxLayoutGap="10px"
                                    formControlName="payment_method"
                                    aria-label="Select payment"
                                >
                                    <mat-radio-button i18n value="invoice">Invoice</mat-radio-button>
                                    <mat-radio-button i18n value="card">Card</mat-radio-button>
                                    <mat-radio-button i18n value="paypal">Paypal</mat-radio-button>
                                    <mat-radio-button i18n value="klarna">Klarna</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </mat-step>
                        <mat-step [stepControl]="registrationForm.get('policy_agree')" [editable]="true">
                            <div fxLayout="column" fxLayoutGap="10px">
                                <div fxLayoutGap="5px" fxLayoutAlign="start center">
                                    <span class="reg-res-title">{{ navData.data.resource.title }}</span>
                                </div>
                                <div
                                    *ngIf="navData.data.resource.start_date"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>From: </span>
                                    <span>{{
                                        navData.data.resource.start_date | kdDateFormat: 'YYYY-MM-DD HH:MM'
                                    }}</span>
                                </div>
                                <div
                                    *ngIf="navData.data.resource.end_date"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>To: </span>
                                    <span>{{ navData.data.resource.end_date | kdDateFormat: 'YYYY-MM-DD HH:MM' }}</span>
                                </div>
                                <div
                                    *ngIf="navData.data.resource.publishers$ | async; let publishers"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Publisher: </span>
                                    <span *ngFor="let publisher of $any(publishers)">{{ publisher.name }}, </span>
                                </div>
                                <div
                                    *ngIf="navData.data.resource.hosts$ | async; let hosts"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Host: </span>
                                    <span *ngFor="let host of $any(hosts)">{{ host.name }}, </span>
                                </div>
                                <div
                                    *ngIf="navData.data.resource.managers$ | async; let managers"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span
                                        class="reg-label"
                                        *ngIf="navData.data.resource.type_id !== ResourceTypes.EVENT"
                                        i18n
                                    >
                                        Author:
                                    </span>
                                    <span
                                        class="reg-label"
                                        *ngIf="navData.data.resource.type_id === ResourceTypes.EVENT"
                                        i18n
                                    >
                                        Tutor:
                                    </span>
                                    <span *ngFor="let manager of $any(managers)">{{ manager.name }}, </span>
                                </div>
                                <div *ngIf="navData.data.resource.price" fxLayoutGap="5px" fxLayoutAlign="start start">
                                    <span class="reg-label" i18n>Price: </span>
                                    <span>{{ navData.data.resource.price }}</span>
                                    <span>{{ getCurrenySign(navData.data.resource.currency) }}</span>
                                </div>
                                <div
                                    *ngIf="navData.data.resource.wel_msg"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Welcome message: </span>
                                    <span>{{ navData.data.resource.wel_msg' }}</span>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.business_company"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Organization name: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.business_company }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.department"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Cost department: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.department }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.orgnumber"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Organization address: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.orgnumber }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.person_role"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Role: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.person_role }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.person_role"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Role: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.person_role }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.purchase_order"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Purchase order: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.purchase_order }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.cost_department"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Cost department: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.cost_department }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.campaign_code"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Campaign code: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.campaign_code }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.address.street"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Street: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.address.street }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.address.box"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Box: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.address.box }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.address.zipcode"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Zipcode: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.address.zipcode }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.address.city"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>City: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.address.city }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.address.country"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Country: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.address.country }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>
                                <div
                                    *ngIf="registrationForm.value.invoiceRegForm.address.other"
                                    fxLayoutGap="5px"
                                    fxLayoutAlign="start start"
                                >
                                    <span class="reg-label" i18n>Other info: </span>
                                    <span>{{ registrationForm.value.invoiceRegForm.address.other }}</span>
                                    <mat-icon class="edit" (click)="editStep()">edit</mat-icon>
                                </div>

                                <div class="mt-1" fxLayoutGap="5px" fxLayoutAlign="start center">
                                    <mat-checkbox formControlName="policy_agree" color="primary"></mat-checkbox
                                    ><span i18n
                                        >I accept KlickData's
                                        <span (click)="$event.stopPropagation(); readMoreLink()" class="orange"
                                            >terms of use</span
                                        ></span
                                    >
                                </div>
                            </div>
                        </mat-step>
                    </mat-stepper>
                </div>
            </form>
        </div>
    </mat-tab>
</mat-tab-group>
