import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MediaType } from '@klickdata/core/media/src/media-type';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { ResourceMediaTypes } from '@klickdata/core/question/src/question-type/question-type';
import { AppScope, ResourceTypes } from '@klickdata/core/resource';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { takeUntil, catchError } from 'rxjs/operators';
import { MenuSideMediaLibraryComponent } from './menu-side-media-library/menu-side-media-library.component';
import { Media } from '@klickdata/core/media/src/media.model';

@Component({
    selector: 'app-menu-side-add-media-with-options',
    templateUrl: './menu-side-add-media-with-options.component.html',
    styleUrls: ['./menu-side-add-media-with-options.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddMediaWithOptionsComponent implements OnDestroy, OnInit, OnChanges {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    @ViewChild('mediaLib') mediaLib: MenuSideMediaLibraryComponent;
    public selectedValue: ResourceMediaTypes;
    public aiText = new FormControl('');
    ResourceMediaTypes = ResourceMediaTypes;
    AppScope = AppScope;
    public showAI: boolean;
    public expandedLib: boolean;
    SideNaveActionsTypes = SideNaveActionsTypes;
    public savingImg: boolean;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public onErrorMsg: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(protected mediaService: MediaService, protected cdr: ChangeDetectorRef) {}
    ngOnInit(): void {}
    ngOnChanges(changes: SimpleChanges): void {
        if (this.navData.data && this.navData.data.errorMsg) {
            this.onErrorMsg.next(this.navData.data.errorMsg);
        }
    }
    onMediaReady(media: Media) {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            clearCompData: true,
            data: {
                value: ResourceMediaTypes.FILE,
                type: this.navData.data.type,
                media: media,
                mediaId: media.id,
            },
        });
    }
    onShowAI() {
        this.showAI = true;
        this.mediaLib.expansionPanel?.close();
        this.cdr.markForCheck();
    }
    public submitAI() {
        if (!this.aiText.value) {
            return;
        }
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                value: ResourceMediaTypes.PROMPT,
                type: this.navData.data.type,
                aiText: this.aiText.value,
            },
        });
        return;
        this.mediaService
            .uploadMediaByLink({
                prompt: this.aiText.value,
                type: MediaType.PROMPT,
                scope_id: ResourceTypes.scopeByChildType(this.navData.data.type),
            })
            .pipe(
                takeUntil(this.destroy),
                catchError((err) => {
                    if (err && err.error && err.error.error) {
                        this.onErrorMsg.next(err.error.error.messages.join('/n'));
                        this.aiText.reset();
                        // this.savingAI = false;
                        this.cdr.markForCheck();
                    }
                    return of(null);
                })
            )
            .subscribe((media) => {
                if (media && media.id) {
                    this.onClose.emit({
                        action: SideNaveActionsTypes.POSITIVE,
                        clearCompData: true,
                        data: {
                            value: ResourceMediaTypes.PROMPT,
                            type: this.navData.data.type,
                            mediaId: media.id,
                        },
                    });
                }
                // this.loadingUploader.next(false);
                // if (media && media.id) {
                //     this.isAIUploadedImg.next(true);
                //     this.resourceForm.patchValue({ media_id: media.id });
                // }
            });
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
