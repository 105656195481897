<div
    [ngClass]="simple ? 'simple' : ''"
    [ngClass]="{ 'disabled-btn': isDisabled | async }"
    fxLayout="row wrap"
    fxLayoutAlign="center center"
>
    <div class="text-center" fxFlex="0 0 100%">
        <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!(saving | async); else loading">
        <div
            *ngIf="enableDrop; else onErrorMsg"
            fxFlex="0 0 100%"
            [ngStyle]="dropStyle"
            ng2FileDrop
            (fileOver)="fileOverBase($event)"
            [uploader]="uploader"
            [ngClass]="!simple ? 'media-dropzone' : 'simple'"
            fxLayout="row wrap"
            fxLayoutAlign="center center"
        >
            <ng-container [ngTemplateOutlet]="onErrorMsg"></ng-container>
            <p *ngIf="!simple" fxFlex="0 0 60%">
                {{ dropLabel }}
            </p>
            <button
                *ngIf="!simple"
                type="button"
                class="media-upload-button"
                fxFlex="0 0 60%"
                mat-raised-button
                (click)="hasAIUploader ? onUpload() : uploadInput.click()"
                color="primary"
            >
                <span *ngIf="uploadBtnLabel">{{ uploadBtnLabel }}</span>
                <mat-icon *ngIf="uploadIcon">{{ uploadIcon }}</mat-icon>
            </button>

            <button
                *ngIf="simple"
                matSuffix
                mat-icon-button
                type="button"
                (click)="uploadInput.click()"
                [matTooltip]="uploadLabel"
            >
                <mat-icon>{{ uploadIcon }}</mat-icon>
            </button>
        </div>
        <input
            #uploadInput
            class="media-upload"
            type="file"
            [accept]="allowedMimeType?.toString()"
            ng2FileSelect
            [uploader]="uploader"
        />
    </ng-container>
</div>

<ng-template #loading>
    <app-kd-logo-loading *ngIf="!simple"></app-kd-logo-loading>
    <app-kd-logo-loading *ngIf="simple" size="small"></app-kd-logo-loading>
</ng-template>

<ng-template #onErrorMsg>
    <mat-error *ngIf="onError | async; let msg">{{ msg }}</mat-error>
</ng-template>
