import { Playlist } from '@klickdata/player/src/core/playlist/playlist';

export class Player {
    playlists: Map<string, Playlist> = new Map<string, Playlist>();
    opportunityId: number;
    referrer?: string;

    public getPlaylist(resourceId: number, opportunityId: number) {
        this.playlists.get(`${resourceId},${opportunityId}`);
    }

    public addPlaylist(resourceId: number, opportunityId: number, playlist: Playlist) {
        this.playlists.set(`${resourceId},${opportunityId}`, playlist);
    }
}
