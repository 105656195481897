<div
    class="alert alert-danger"
    *ngIf="messages">
    <h4 class="alert-heading title">
        <i class="material-icons app-input-icon warn">error</i>
        {{header}}
    </h4>
    <ul
        class="error-list"
        *ngIf="messages">
        <li
            *ngFor="let message of messages"
            [innerHtml]="message">
        </li>
    </ul>
    <div class="extra">
        <ng-content></ng-content>
    </div>
</div>
