import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-add-address',
    templateUrl: './menu-side-add-address.component.html',
    styleUrls: ['./menu-side-add-address.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddAddressComponent {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public addressForm: FormGroup;
    SideNaveActionsTypes = SideNaveActionsTypes;

    constructor(protected fb: FormBuilder, protected cd: ChangeDetectorRef) {
        this.addressForm = this.fb.group({
            address_type: [''],
            street: [''],
            zipcode: [''],
            box: [''],
            city: [''],
            country: [''],
            communications: this.fb.group({
                phone: this.fb.array([
                    this.fb.group({
                        phone: [''],
                    }),
                ]),
            }),
        });
    }
    get phoneFormArray(): FormArray {
        return this.addressForm.get('communications.phone') as FormArray;
    }
    public submit() {
        const values = this.addressForm.value;
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                value: values,
                type: this.navData.data.type,
            },
        });
        this.clear();
    }
    removePhone(phoneArrayIndex: number) {
        this.phoneFormArray.removeAt(phoneArrayIndex);
        FormHelper.markForm(this.phoneFormArray);
    }
    addNewPhone() {
        this.phoneFormArray.push(this.fb.group({ phone: [] }));
        this.phoneFormArray.markAsDirty();
    }
    public clear() {
        this.addressForm.reset();
        this.cd.markForCheck();
    }
}
