import { filter, takeUntil } from 'rxjs/operators';
import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    EventEmitter,
    Output,
    Input,
    OnDestroy,
    ChangeDetectorRef,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Resource, ResourceCategoryService, ResourceTypes } from '@klickdata/core/resource';
import { FormHelper } from '@klickdata/core/form';
import { Subject } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ResourceCreationAssignmentSheetComponent } from '../resource-assignment/resource-creation-assignment-sheet/resource-creation-assignment-sheet.component';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
    selector: 'app-resource-edit-third-column',
    templateUrl: './resource-edit-third-column.component.html',
    styleUrls: ['./resource-edit-third-column.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceEditThirdColumnComponent implements OnInit, OnDestroy, AfterViewInit {
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Input() resource: Resource;
    ResourceTypes = ResourceTypes;
    @Input() materialForm: FormGroup;
    @ViewChild('pulishCheckbox') pulishCheckbox: MatCheckbox;

    constructor(
        protected categoryService: ResourceCategoryService,
        protected bottomSheet: MatBottomSheet,
        protected message: MessageService,
        protected cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.setDefaultCategory();
        this.materialForm
            .get('publish')
            .valueChanges.pipe(takeUntil(this.destroy))
            .subscribe((value) => {
                if (!value) {
                    this.clearAttachments();
                }
            });
    }

    ngAfterViewInit(): void {
        if (this.resource && (this.resource.groups_attached || this.resource.users_attached)) {
            this.pulishCheckbox.indeterminate = true;
            this.cdr.markForCheck;
        }
    }

    private setDefaultCategory() {
        this.categoryService
            .getSuggestedCategory(ResourceTypes.MATERIAL)
            .pipe(takeUntil(this.destroy))
            .subscribe((category) => {
                if (!this.materialForm.controls.category_ids.value?.length) {
                    this.materialForm.patchValue({
                        category_ids: [category.id],
                    });
                    FormHelper.resetForm(<FormControl>this.materialForm.controls.category_ids);
                }
            });
    }
    public specify() {
        this.bottomSheet
            .open(ResourceCreationAssignmentSheetComponent, {
                data: {
                    context: 'publish',
                    contextTitle: $localize`:@@publish:Publish`,
                    contextIcon: 'publish_outline',
                    id: this.resource?.id,
                    defaultSorting: this.resource ? 'checked' : '',
                    title: this.resource ? this.resource.title : $localize`New material`,
                    referenceFilter: this.resource ? 'referenceResource' : null,
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe((value) => {
                if (this.resource) {
                    this.message.openMessage(MessageSavedComponent);
                } else {
                    this.materialForm.patchValue(this.clean(value));
                    this.pulishCheckbox.indeterminate = true;
                    this.cdr.markForCheck();
                }
            });
    }
    private clean(obj: {}) {
        for (var key in obj) {
            if (obj[key] === null || obj[key] === undefined || obj[key].length === 0 || key === 'filters') {
                delete obj[key];
            }
        }
        return obj;
    }

    public IsIndeterminate() {
        return (
            this.materialForm.value.users_attach?.length ||
            this.materialForm.value.users_detach?.length ||
            this.materialForm.value.groups_attach?.length ||
            this.materialForm.value.groups_detach?.length ||
            this.materialForm.value.sync_all_users !== null ||
            this.materialForm.value.sync_all_groups !== null
        );
    }
    private clearAttachments() {
        this.materialForm.value.users_attach = null;
        this.materialForm.value.users_detach = null;
        this.materialForm.value.groups_attach = null;
        this.materialForm.value.groups_detach = null;
        this.materialForm.value.sync_all_users = null;
        this.materialForm.value.sync_all_groups = null;
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
    onSubmit() {
        this.materialForm.patchValue({ submit: true });
    }
}
