import { AccessCapability } from '../can-access.directive';

export namespace MaterialCapability {
    export const NONE = 0;
    export const VIEW_ALL_MATERIALS = 1 << 0;
    export const MANAGE_ALL_MATERIALS = 1 << 1;
    export const VIEW_GROUP_MATERIALS = 1 << 2;
    export const MANAGE_GROUP_MATERIALS = 1 << 3;
    export const VIEW_CUSTOMER_MATERIALS = 1 << 4;
    export const MANAGE_CUSTOMER_MATERIALS = 1 << 5;
    export const MANAGE_PUBLIC_MATERIALS = 1 << 6;

    export function getName(): string {
        return 'material';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
