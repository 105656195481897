import { UserNotes, UserNotesService } from '@klickdata/core/user-notes';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-notes-resource-listing-details',
    templateUrl: './notes-resource-listing-details.component.html',
    styleUrls: ['./notes-resource-listing-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesResourceListingDetailsComponent implements OnInit {
    @Input() note: UserNotes;
    public relatedNotes$: Observable<UserNotes[]>;

    constructor(protected notesService: UserNotesService) {}

    ngOnInit(): void {
        if (this.note && this.note.resource?.id) {
            this.relatedNotes$ = this.notesService
                .getNotesRelatedToResource(this.note.resource.id)
                .pipe(map((notes) => notes.filter((note) => note.id != this.note.id)));
        }
    }
}
