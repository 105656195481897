import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
    protected colors = [
        { background: '#e44a66', color: '#ffffff' },
        { background: '#ff9961', color: 'rgba(0,0,0,.87)' },
        { background: '#93cbd1', color: 'rgba(0,0,0,.87)' },
        { background: '#3e5365', color: '#ffffff' },
        { background: '#bfd8d0', color: 'rgba(0,0,0,.87)' },
        { background: '#ec93a4', color: 'rgba(0,0,0,.87)' },
        { background: '#fdc1a2', color: 'rgba(0,0,0,.87)' },
        { background: '#c0e0e3', color: 'rgba(0,0,0,.87)' },
        { background: '#8c98a2', color: 'rgba(0,0,0,.87)' },
        { background: '#d9e8e3', color: 'rgba(0,0,0,.87)' },
    ];

    protected alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ';

    @Input()
    public set text(text: string) {
        this._text = text && text.length && this.alphabet.indexOf(text[0]) !== -1 ? text[0].toUpperCase() : 'K';
        const index = this.alphabet.indexOf(this._text);
        this.backgroundColor = this.colors[index % 10].background;
        this.elColor = this.colors[index % 10].color;
    }
    public get text(): string {
        return this._text;
    }
    protected _text: string;

    @Input()
    public set media_id(media_id) {
        this._media_id = media_id;
        this.padding = '0';

        if (this.size) {
            const size = parseInt(this.size.match(/\d/g).join(''), 10);
            this.size = `${size + 4}px`;
        }
    }
    public get media_id(): string {
        return this._media_id;
    }
    protected _media_id: string;

    @Input()
    public set size(size: string) {
        this.width = size;
        this.height = size;
        this.fontSize = size;
        this._size = size;
    }

    public get size(): string {
        return this._size;
    }

    protected _size: string;

    @HostBinding('style.color')
    public elColor: string;

    @HostBinding('style.background-color')
    public backgroundColor: string;

    @HostBinding('style.border-radius')
    public borderRadius: string;

    @HostBinding('style.width')
    public width: string;

    @HostBinding('style.height')
    public height: string;

    @HostBinding('style.fontSize')
    public fontSize: string;

    @HostBinding('style.padding')
    public padding: string;

    constructor() {}
}
