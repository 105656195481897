<div fxLayout="column" fxFlex="0 0 100%" class="hr-notes-wrapper">
    <app-table-filter [filtersTogglesInOnBtn]="true" [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search
            toggleBtnLabel="File"
            i18n-toggleBtnLabel
            [inGlobalFilter]="true"
            class="align-space"
            searchFilterRef
        ></app-table-search>
    </app-table-filter>

    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table #table [dataSource]="dataSource" matSortActive="created_at" matSortDirection="desc" matSort>
        <ng-container matColumnDef="title">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Name</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let file">
                <span class="mobile-label" i18n>Name:</span>
                <span class="notes">{{ file.title }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell class="btns-actions" *matCellDef="let file">
                <button
                    (click)="$event.stopPropagation(); view(file)"
                    matTooltip="View"
                    mat-icon-button
                    i18n-matTooltip="@@view"
                >
                    <mat-icon>visibility</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let file; columns: columns"
            (click)="onFileClick.emit(file)"
            class="example-element-row"
        ></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 25, 50, 100]"> </mat-paginator>
</div>
