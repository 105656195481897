import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { Observable, takeUntil } from 'rxjs';
import { EditorTemplate } from '../../text-editor/editor-template.model';
import { EditorTemplateService } from '../../text-editor/editor-template.service';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';

@Component({
    selector: 'app-add-formative-evaluation',
    templateUrl: './add-formative-evaluation.component.html',
    styleUrls: ['./add-formative-evaluation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddFormativeEvaluationComponent extends OnDestroyHandler implements OnInit {
    @Input() data: any;
    @Input() titlesExpansionsStatus: boolean[];
    public evaluationForm: FormGroup;
    public kdColorsSet = ['green', 'magenta', 'cyan', 'blue', 'orange'];
    public saveAssessTemplate = false;
    public saveFeedbackTemplate = false;
    public assessmentTemplateForm: FormGroup;
    public feedbackTemplateForm: FormGroup;
    public selectedFeedbackTemplate = new FormControl();
    public selectedAssessmentTemplate = new FormControl();
    public feedBackTemplates: EditorTemplate[];
    public assessmentTemplates: EditorTemplate[];

    constructor(
        protected fb: FormBuilder,
        protected templateService: EditorTemplateService,
        protected cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.evaluationForm = this.fb.group({
            title: [],
            body: [],
            evaluator_id: [],
            criteria: this.fb.array([]),
        });
        this.assessmentTemplateForm = this.fb.group({
            name: [],
            visibility: ['private'],
        });
        this.feedbackTemplateForm = this.fb.group({
            name: [],
            visibility: ['private'],
        });
        this.evaluationForm.patchValue(this.data);
        // Handle criteria array separately
        this.data?.criteria?.forEach((item) => this.criteriaFormArray.push(this.fb.group(item)));
        FormHelper.resetForm(this.evaluationForm);
        this.templateService
            .getUserFieldTemplates('occasionFeedback')
            .pipe(takeUntil(this.destroy))
            .subscribe((templates) => {
                this.feedBackTemplates = templates;
                this.cdr.markForCheck();
            });
        this.templateService
            .getAssessmentTemplates()
            .pipe(takeUntil(this.destroy))
            .subscribe((templates) => {
                this.assessmentTemplates = templates;
                this.cdr.markForCheck();
            });

        this.selectedFeedbackTemplate.valueChanges.pipe(takeUntil(this.destroy)).subscribe((feedbackTemplId) => {
            const selectedTemplate = this.feedBackTemplates.find((temp) => temp.id == feedbackTemplId);
            this.evaluationForm.patchValue({
                title: selectedTemplate.title,
                body: selectedTemplate.body,
            });
        });
        this.selectedAssessmentTemplate.valueChanges.pipe(takeUntil(this.destroy)).subscribe((assessmentTemplId) => {
            const selectedTemplate = this.assessmentTemplates.find((temp) => temp.id == assessmentTemplId);
            this.updateCriteria(selectedTemplate);
        });
    }
    updateCriteria(selectedTemplate: EditorTemplate) {
        selectedTemplate.criteria.forEach((item) => this.criteriaFormArray.push(this.fb.group(item)));
    }

    get criteriaFormArray(): FormArray {
        return <FormArray>this.evaluationForm.get('criteria');
    }

    addCriterion() {
        this.criteriaFormArray.push(
            this.fb.group({
                label: [''],
                value: [7],
                color: ['green'],
                editable: true,
            })
        );
        this.criteriaFormArray.markAsDirty();
    }

    removeCriterion(index: number) {
        this.criteriaFormArray.removeAt(index);
        FormHelper.markForm(this.criteriaFormArray);
    }
}
