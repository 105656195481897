import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertDialogComponent } from '@klickdata/core/application/src/alert-dialog/alert-dialog.component';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Resource, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItem } from '@klickdata/core/resource-item';
import { of, Subject } from 'rxjs';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-course-player-results-content',
    templateUrl: './course-player-results-content.component.html',
    styleUrls: ['./course-player-results-content.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoursePlayerResultsContentComponent implements OnInit, OnDestroy {
    @Input() items: ResourceItem[];
    @Input() resource: Resource;
    @Output() repeatCourse: EventEmitter<boolean> = new EventEmitter<boolean>();
    private destroy: Subject<boolean> = new Subject<boolean>();
    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected location: Location,
        protected mobile: MobileService,
        protected dialog: MatDialog
    ) {}

    ngOnInit(): void {}
    displayCorrectIcon(type) {
        if (type.indexOf('test') !== -1) {
            return 'kd-icon-test';
        } else if (type.indexOf('course_plan') !== -1) {
            return 'kd-icon-task';
        } else if (type.indexOf('survey') !== -1) {
            return 'kd-icon-survey';
        } else if (type.indexOf('course') !== -1) {
            return 'kd-icon-player';
        } else if (type.indexOf('material') !== -1) {
            return 'kd-icon-document';
        }
    }
    public getItemSpecs(item: ResourceItem) {
        if (item.child_opportunity_done) {
            return { class: 'green-color', tooltip: $localize`Completed` };
        } else if (item.child_opportunity_started_at) {
            return { class: 'orange-color', tooltip: $localize`Ongoing` };
        } else {
            return { class: 'dark-blue-color', tooltip: $localize`Not started` };
        }
    }
    public repeatItem(item: ResourceItem) {
        switch (item.startStatus.status) {
            case 'repeat':
                this.mobile.updateSideNavSub({
                    dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
                    data: {
                        icon: 'play_circle_outline',
                        title: $localize`Study again`,
                        contentBody: $localize`Do you want to repeat this part or do you want to enroll to the course again?`,
                        positiveBtn: $localize`Repeat ${item.item_type_value} `,
                        customBtn: $localize`Start the Course again`,
                    },
                });
                this.mobile
                    .getSideNavAction()
                    .pipe(takeUntil(this.destroy))
                    .subscribe((action) => {
                        if (action == SideNaveActionsTypes.POSITIVE) {
                            this.router.navigate(['/player/resource', item.child_resource_id, item.item_type_value], {
                                queryParams: {
                                    queryParams: item ? { position: item.play_position } : {},
                                    referrer: this.location.path(),
                                },
                            });
                        } else if (action == SideNaveActionsTypes.CUSTOM) {
                            this.repeatCourse.emit(true);
                        }
                    });
                break;
            case 'continue':
                this.mobile.updateSideNavSub({
                    dataType: SideNaveDataTypes.RESOURCE_ENROLLMENT_REQUEST,
                    data: {
                        icon: 'play_circle',
                        title: $localize`Continue`,
                        resource: { id: this.resource.id, title: item.name, media: this.resource.media },
                        actions: [
                            {
                                label: $localize`Continue`,
                                value: 2,
                                class: 'pos-btn',
                            },
                            { label: $localize`Cancel`, value: 0, class: 'null-btn' },
                        ],
                        type: 'enrollRequest',
                    },
                });
                this.mobile
                    .getSideNavResponseData()
                    .pipe(filter((data) => data.type == 'enrollRequest' && data.response == 2))
                    .subscribe(() => {
                        this.startResource(item);
                    });
                break;
            case 'dimmed':
                this.mobile.updateSideNavSub({
                    dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
                    data: {
                        icon: 'report_problem',
                        title: $localize`Not yet availible`,
                        contentBody: $localize`Please take the parts in Course needed before you start this part`,
                        positiveBtn: $localize`Ok`,
                        negatibeBtn: $localize`Cancel`,
                    },
                });
                break;
            case 'start':
                this.mobile.updateSideNavSub({
                    dataType: SideNaveDataTypes.RESOURCE_ENROLLMENT_REQUEST,
                    data: {
                        icon: 'play_circle',
                        title: $localize`Start`,
                        resource: { id: this.resource.id, title: item.name, media: this.resource.media },
                        actions: [
                            {
                                label: $localize`Start`,
                                value: 2,
                                class: 'pos-btn',
                            },
                            { label: $localize`Cancel`, value: 0, class: 'null-btn' },
                        ],
                        type: 'enrollRequest',
                    },
                });
                this.mobile
                    .getSideNavResponseData()
                    .pipe(filter((data) => data.type == 'enrollRequest' && data.response == 2))
                    .subscribe(() => {
                        this.startResource();
                    });
                break;
            default:
                break;
        }
    }
    public startResource(item?: ResourceItem) {
        this.router.navigate(['/player/resource', this.resource.id, ResourceTypes.playerRoute(this.resource.type_id)], {
            queryParams: {
                queryParams: item ? { position: item.play_position } : {},
                referrer: this.location.path(),
            },
        });
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
