import { ChangeDetectorRef, Directive } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '@klickdata/core/application/src/alert-dialog/alert-dialog.component';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { Subject } from 'rxjs';
import { takeUntil, tap, filter, switchMap } from 'rxjs/operators';

@Directive()
export abstract class CreateResourceBaseDirective {
    resource: Resource;
    public isLoading: boolean;
    public destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected cdRef: ChangeDetectorRef,
        protected dialog: MatDialog
    ) {}

    public makePublish(resource: Resource, publish: boolean) {
        this.isLoading = true;
        this.dialog
            .open(AlertDialogComponent, {
                disableClose: true,
                data: {
                    icon: publish ? 'publish_outline' : 'unpublished',
                    iconClass: 'material-icons-outlined',
                    title: publish ? $localize`:@@publish:Publish` : $localize`:@@unpublish:Unpublish`,
                    contentBody: $localize`:@@sureUpdatePublication:Are you sure you want to change the status of publication?`,
                    positiveBtn: publish ? $localize`:@@publish:Publish` : $localize`:@@unpublish:Unpublish`,
                    negativeBtn: $localize`:@@cancel:Cancel`,
                },
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                tap(result => {
                    if (!result) {
                        this.isLoading = false;
                        this.cdRef.markForCheck();
                    }
                }),
                filter(result => !!result),
                switchMap(() => {
                    const ids = resource.id;
                    return publish ? this.resourceService.publish(ids) : this.resourceService.unpublish(ids);
                })
            )
            .subscribe(
                (newResource: Resource) => {
                    this.isLoading = false;
                    this.afterPublishDone(newResource);
                    this.messageService.openMessage(MessageSavedComponent);
                },
                err => {
                    this.isLoading = false;
                    this.cdRef.markForCheck();
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                    }
                }
            );
    }

    protected afterPublishDone(newResource: Resource) {
        this.resource = newResource;
        this.cdRef.markForCheck();
    }
}
