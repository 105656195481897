import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../../global-filters-types.enum';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';

@Component({
    selector: 'app-language-filter',
    templateUrl: './language-filter.component.html',
    styleUrls: ['./language-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: FilterBase, useExisting: LanguageFilterComponent }],
})
export class LanguageFilterComponent extends FilterBase implements OnInit, OnDestroy {
    @Input() fxLayout: 'row' | 'column';
    @Output() filterChange: EventEmitter<Filter<number>> = new EventEmitter<Filter<number>>();
    @Output() filterRemoval: EventEmitter<Filter<number>> = new EventEmitter<Filter<number>>();
    @Input() inGlobalFilter: boolean;
    @Input() prefLangIds: number[];
    public customer$: Observable<Customer>;
    public languageControl = new FormControl();

    protected _filter: Filter<number> = {
        property: GlobalFilterProperty.LANGUAGE,
        lazy: true,
        items: [],
        singleActive: true,
        label: $localize`:@@language:Language`,
        icon: 'language',
        color: { bg: GlobalFilterColor.C5, fg: GlobalFilterColor.WHITE },
        styleClass: 'global-lang-filter-selector',
    };
    private destroy: Subject<boolean> = new Subject<boolean>();

    constructor(protected auth: AuthService) {
        super();
        this.customer$ = this.auth.getCustomer();
    }

    ngOnInit(): void {
        this.languageControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe((lang) => {
            setTimeout(() => {
                const globe = Array.isArray(lang);
                this.filter.primary = globe;
                this.filter.items = globe ? lang : [lang];
                this.filterChange.emit(this._filter);
            });
        });
    }

    /**
     * @override
     * @param items
     */
    protected onCacheLoaded(items: number[]) {
        this.languageControl.setValue(items[0]); // in case of single item init from cache.
    }

    protected ignoreCache(): boolean {
        return this.filter.items?.length > 1; // ignore cache all
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public clear() {
        this.languageControl.reset();
    }

    public get filter(): Filter<number> {
        return this._filter;
    }
}
