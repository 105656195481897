import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpModule } from '@klickdata/core/http';
import { AuthModule } from '@klickdata/core/auth';
import { ConfigModule } from '@klickdata/core/config';
import { ApplicationModule } from '@klickdata/core/application';

@NgModule({
    imports: [
        CommonModule,
        ApplicationModule,
        HttpModule,
        AuthModule,
        ConfigModule,
    ],
})
export class UserModule {
}
