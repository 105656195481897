<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>save</mat-icon>
        <span *ngIf="!file" i18n>Upload a new file</span>
        <span *ngIf="file" i18n>{{ file.title }}</span>
    </div>

    <form fxLayoutGap="20px" fxLayout="column" [formGroup]="fileForm">
        <!-- <mat-form-field appearance="fill" class="templates-select" fxFlex="0 0 100%">
            <input matInput placeholder="File name" i18n-placeholder formControlName="filename" />
        </mat-form-field> -->

        <app-user-setting-input
            type="text"
            i18n-placeholder
            [placeholder]="'Enter file name'"
            [control]="fileForm.get('title') | cast"
        >
            <label role="input-label" fxLayoutGap="4px">
                <span i18n>File name</span>
            </label>
        </app-user-setting-input>

        <div class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
            <span i18n>Description</span>
            <mat-form-field appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                <textarea
                    #title
                    matInput
                    type="text"
                    formControlName="description"
                    placeholder="Enter file description"
                    i18n-placeholder
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>
        </div>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>Priority</span>
            <mat-form-field class="role-field">
                <mat-select fxFlex="1 1 100%" formControlName="priority">
                    <mat-option value="a1" i18n>A1</mat-option>
                    <mat-option value="a2" i18n>A2</mat-option>
                    <mat-option value="a3" i18n>A3</mat-option>
                    <mat-option value="b1" i18n>B1</mat-option>
                    <mat-option value="b2" i18n>B2</mat-option>
                    <mat-option value="b3" i18n>B3</mat-option>
                    <mat-option value="c1" i18n>C1</mat-option>
                    <mat-option value="c2" i18n>C2</mat-option>
                    <mat-option value="c3" i18n>C3</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div
            *ngIf="fileForm.value.visibility != 'master'"
            fxLayoutGap="4px"
            fxLayout="column"
            class="w-100 input-field-parent select"
        >
            <span i18n>Privacy</span>
            <mat-form-field class="role-field">
                <mat-select fxFlex="1 1 100%" formControlName="visibility">
                    <mat-option value="private" i18n>Private</mat-option>
                    <mat-option value="academy" i18n>Academy</mat-option>
                    <mat-option value="public" i18n>Public</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent k3-crm-tags">
            <span i18n>Tags</span>
            <app-tag-chip-select
                class="w-100"
                placeholder="Add Tag"
                [language_id]="currentLaguageId$ | async"
                i18n-placeholder
                formControlName="tag_ids"
            ></app-tag-chip-select>
        </div>
        <div class="alert-media-attach">
            <app-media-attachment
                #uploader
                type="file"
                dropLabel="Drop files"
                i18n-dropLabel
                [formControl]="fileForm.get('medias.file') | cast"
                [mediaIds]="fileForm.value.medias?.file"
                (onUploadOrRemoveCompleted)="onUploadComplete($event)"
                [isCreateForm]="true"
                [isIconBtns]="false"
            ></app-media-attachment>
            <div fxLayoutAlign="center center" *ngIf="uploader.saving | async">
                <app-kd-logo-loading size="small"></app-kd-logo-loading>
            </div>
        </div>
        <div class="action">
            <button
                [disabled]="!fileForm.value.medias?.file?.length"
                *ngIf="!(saving | async)"
                fxFlex="0 0 auto"
                class="mobile-button"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n>Submit</span>
            </button>
            <ng-container *ngIf="saving | async">
                <app-saving-button></app-saving-button>
            </ng-container>
        </div>
    </form>
</div>
