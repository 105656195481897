import {
    Cast,
    CastType,
    IDataModel,
    Ignore,
    InitialValue,
} from '@klickdata/core/application/src/model/model-interface';
import { Customer } from '@klickdata/core/customer/src/customer.model';
import { Media } from '@klickdata/core/media/src/media.model';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { TranslatableModel } from './../../translatable.model';

export interface ResourceCategoryData extends IDataModel {
    id?: number;
    parent_id?: number;
    children_ids?: number[];
    resource_ids?: number[];
    customer_id?: number;
    title?: string;
    subtitle?: string;
    description?: string;
    color?: string;
    media_id?: string;
    public?: boolean;
    default?: boolean;
    weight?: number;
    updated_at?: string;
    created_at?: string;
    type_ids?: number[];
    translations?: string[];
    language_id?: number;
    resources_attached?: boolean;
    children_attached?: boolean;
    parent?: ResourceCategoryData;
    // title?: { [key: string]: string };
    // description?: { [key: string]: string };
    resources_count?: number;
    courses_count?: number;
    surveys_count?: number;
    tests_count?: number;
    ecourses_count?: number;
    materials_count?: number;
    packages_count?: number;
}

/**
 * 'resource_id', 'title', 'subtitle', 'color', 'public', 'parent_id'
 */
export class ResourceCategory extends TranslatableModel<ResourceCategoryData> {
    public id: number;
    public parent_id: number;
    public customer_id: number;
    @Ignore()
    public title: string;
    public subtitle: string;
    @Ignore()
    public description: string;
    public color: string;
    public media_id: string;
    public public: boolean;
    public default: boolean;
    public weight: number;
    @Cast(CastType.MOMENT)
    @Ignore()
    public updated_at: moment.Moment;
    @Cast(CastType.MOMENT)
    @Ignore()
    public created_at: moment.Moment;
    public language_id: number;

    /** Inialized param */
    @InitialValue([])
    public children_ids: number[];
    @InitialValue([])
    public resource_ids: number[];
    @InitialValue([])
    public type_ids: number[];

    @Ignore()
    public resources_attached: boolean;
    @Ignore()
    public children_attached: boolean;

    // Properies that handle lazy loading of resources and search parent/children/child resources;
    @Ignore()
    public parentTitle: string;
    public parent: ResourceCategoryData;
    @Ignore()
    public opened: boolean;
    @Ignore()
    public page: number;
    @Ignore()
    public limit = 25;
    @Ignore()
    public loading: boolean;
    @Ignore()
    public hide: boolean;
    @Ignore()
    public query: string;
    public media$: Observable<Media>;
    @Ignore()
    public media_url: string;
    @Ignore()
    public translations: string[];
    public media_fallback_url: string;

    public courses: Observable<Resource[]>;
    public customer$: Observable<Customer>;
    public parent$: Observable<ResourceCategory>;
    public children: Observable<ResourceCategory[]>;
    public loadedChildren: ResourceCategory[];
    public resources$: Observable<Resource[]>;
    public loadedResources: Resource[];

    public resourcesPaginator: Observable<Resource[]>[];
    public updateSubject: Subject<ResourceCategory> = new Subject<ResourceCategory>();

    public getNext(): Observable<Resource[]> {
        if (this.page < this.resourcesPaginator.length) {
            return this.resourcesPaginator[this.page];
        }
    }

    public get canLoadMore(): boolean {
        return this.page < this.resourcesPaginator.length;
    }

    // public get courses_count(): number {
    //     return this.page < this.resourcesPaginator.length;
    // }

    // public get materials_count(): number {
    //     return this.resources;
    // }
}
