import { Injectable, OnDestroy } from '@angular/core';
import { LoggerService } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { AbstractFormGroup, FormGeneratorService, HasOne, FormHelper } from '@klickdata/core/form';
import { Resource, ResourceData, ResourceService } from '@klickdata/core/resource';
import * as moment from 'moment';
import { EMPTY, Observable, Subject, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AccessForm } from './course-plan-access/access.form';
import { SettingsForm } from './course-plan-informations/settings.form';
import { PublishForm } from './course-plan-publish/publish.form';
import { ReminderForm } from './course-plan-reminders/reminder.form';
import { ResourceItem, ResourceItemData } from '@klickdata/core/resource-item';

@Injectable()
export class CoursePlanForm extends AbstractFormGroup<Resource> implements OnDestroy {
    protected destroy: Subject<boolean> = new Subject<boolean>();
    public courseResourceItems: BehaviorSubject<ResourceItemData[]> = new BehaviorSubject<ResourceItemData[]>([]);

    constructor(
        protected auth: AuthService,
        protected generator: FormGeneratorService,
        protected resourceService: ResourceService,
        protected logger: LoggerService
    ) {
        super(Resource, generator);
    }

    public get settings(): HasOne<SettingsForm> {
        return this.hasOne(SettingsForm, 'settings');
    }

    /**
     * Has a form for handling user & group assignment.
     */
    public get access(): HasOne<AccessForm> {
        return this.hasOne(AccessForm, 'access');
    }

    /**
     * Has a form for handling multiple reminders.
     */
    public get reminder(): HasOne<ReminderForm> {
        return this.hasOne(ReminderForm, 'reminder');
    }

    /**
     * Has a form for publishing the resource and send a message.
     */
    public get publish(): HasOne<PublishForm> {
        return this.hasOne(PublishForm, 'publish');
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    /**
     * Initialize a base form for creating a new course plan.
     */
    public create(data?: ResourceData): Observable<undefined> {
        this.logger.info('CoursePlan create is deprecated use settings instead');

        return EMPTY;
    }

    public submit(): Observable<undefined> {
        this.logger.info('CoursePlan submit is deprecated use settings instead');

        return EMPTY;
    }

    public publishCoursePlan(): Observable<Resource> {
        return this.publishResource(this.model.id, this.model.language_id).pipe(
            tap((resource) => (this.model.published = resource.published))
        );
    }

    public publishChildResource(item: ResourceItem): Observable<Resource> {
        return this.publishResource(item.child_resource_id, item.child_resource_language_id).pipe(
            tap((resource) => (item.child_resource_published = resource.published))
        );
    }

    public publishResource(id: number, language_id: number): Observable<Resource> {
        return this.resourceService.update({
            id: id,
            language_id: language_id,
            published: moment().format('YYYY-MM-DD HH:mm:ss'),
        });
    }

    /**
     * Import/update the form with new values.
     */
    public import(resource: ResourceData): Resource {
        this.model = new Resource(this.model ? { ...this.model.getData(), ...resource } : resource);
        this.form.patchValue(resource);
        this.updateEditable();
        FormHelper.resetForm(this.form);
        return this.model;
    }

    public updateEditable(): void {
        if (this.model.published && this.model.start_date?.isBefore(moment())) {
            // this.form.disable({ onlySelf: false, emitEvent: false });

            // const access = this.form.get('access') as FormGroup;
            // if (access) {
            //     access.enable({ onlySelf: false, emitEvent: false });
            // }
            // if (this.settings.child) {
            //     this.settings.child.form.disable({
            //         onlySelf: false,
            //         emitEvent: false,
            //     });
            // }

            if (this.reminder.child) {
                this.reminder.child.form.disable({
                    onlySelf: false,
                    emitEvent: false,
                });
            }

            if (this.publish.child) {
                this.publish.child.form.disable({
                    onlySelf: false,
                    emitEvent: false,
                });
            }
        } else {
            this.form.enable({ onlySelf: false, emitEvent: false });
        }
    }

    /**
     * Get course plan data as a resource.
     */
    public export(): Resource {
        this.commit();

        // this.model.available_types = this.value.type_id;
        return this.model;
    }
}
