<ng-container *ngIf="question | async; else loading; let loadedQuestion">
    <figure *ngIf="loadedQuestion.media$ | async; let loadedMedia">
        <img src="{{ loadedMedia.url }}" alt="" />
    </figure>

    <p *ngIf="loadedQuestion.description" [innerHTML]="loadedQuestion.description"></p>

    <app-resource-item-question-alternatives
        [question]="loadedQuestion"
        *ngIf="loadedQuestion.alternative_ids.length > 0"
        fxLayout="column"
        [showPoints]="showPoints"
    ></app-resource-item-question-alternatives>

    <div *ngIf="loadedQuestion.question_type_value === QuestionTypes.INPUT">
        <mat-form-field fxFlex="0 0 100%">
            <mat-icon *ngIf="loadedQuestion.question_type_value === QuestionTypes.INPUT">edit_note</mat-icon>
            <input matInput disabled />
        </mat-form-field>
    </div>
    <div *ngIf="loadedQuestion.question_type_value === QuestionTypes.TEXTAREA">
        <mat-form-field fxFlex="0 0 100%">
            <mat-icon *ngIf="loadedQuestion.question_type_value === QuestionTypes.TEXTAREA">text_fields</mat-icon>
            <textarea matInput disabled></textarea>
        </mat-form-field>
    </div>
</ng-container>
<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <app-kd-logo-loading></app-kd-logo-loading>
    </div>
</ng-template>
