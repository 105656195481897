import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuestionType, QuestionTypeData } from './question-type';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';

@Injectable({
    providedIn: 'root',
})
export class QuestionTypeService {
    protected resourceUrl: string;

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}questions/type`;
    }

    /**
     * Get all question types.
     *
     * @returns {Observable<QuestionType[]>}
     */
    public getTypes(): Observable<QuestionType[]> {
        return this.builder.get<QuestionTypeData[]>(this.resourceUrl)
            .request().pipe(
                map(res => res.data.map(item => new QuestionType(item))),
            );
    }

    /**
     *  Get a question type
     *
     * @param {string} value
     * @returns {Observable<QuestionType>}
     */
    public getType(value: string): Observable<QuestionType> {
        return this.builder.get<QuestionTypeData>(`${this.resourceUrl}/${value}`)
            .request().pipe(
                map(res => new QuestionType(res.data)),
            );
    }
}
