<form [formGroup]="form" fxFlex="0 0 100%" fxLayout="row wrap">
    <mat-divider fxFlex="0 0 100%" class="mb-1"></mat-divider>

    <div fxFlex="0 0 100%" class="note mt-1">
        <span i18n="reminderDesc1">
            Select one ore multiple dates on the calendars to send reminders to the users on.
        </span>
        <br />
        <span i18n="reminderDesc2">
            One day every week will be selected as default but will avoid weekends with the exception of the first and
            last day of the course plan.
        </span>
    </div>

    <app-pikaday fxFlex="0 0 100%" [minDate]="minDate" [maxDate]="maxDate" formControlName="dates"></app-pikaday>

    <mat-form-field fxFlex="0 0 100%">
        <input
            matInput
            formControlName="subject"
            placeholder="Reminder subject (editable)"
            i18n-placeholder="@@reminderSubject"
        />
        <mat-error i18n="@@subjectIsRequired">Subject/Title is required</mat-error>
    </mat-form-field>

    <app-text-editor
        fxFlex="0 0 100%"
        [subject]="form.value.subject"
        (onSubject)="form.controls['subject'].patchValue($event)"
        fieldName="coursePlanReminderMessage"
        title="Reminder body"
        i18n-title
        formControlName="body"
    >
        <mat-error i18n="@@messageBodyError">Message body is required!</mat-error>
    </app-text-editor>

    <div *ngIf="!hideActions" fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="end">
        <button *ngIf="!loading" mat-raised-button color="primary" (click)="submit()" i18n="@@next">
            Next
        </button>
        <app-saving-button *ngIf="loading"></app-saving-button>
    </div>
</form>
