import { HttpClient } from '@angular/common/http';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LoggerService } from '@klickdata/core/application';
import { AuthService, PlatformType } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { FormHelper } from '@klickdata/core/form';
import { LanguageService } from '@klickdata/core/localization';
import { S3MediaService } from '@klickdata/core/media/src/s3-media.service';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Resource, ResourceData, ResourceService } from '@klickdata/core/resource';
import { ResourceItemData } from '@klickdata/core/resource-item';
import { YouTubeVideoData } from '@klickdata/core/resource/src/resource.service';
import { TagService } from '@klickdata/core/resource/src/tag/tag.service';
import { ResourceTypes } from '@klickdata/core/resource/src/types.enum';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { VTQ, VTQService } from '@klickdata/core/vtq';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { debounceTime, finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
@Component({
    selector: 'app-menu-side-create-link-material',
    templateUrl: './menu-side-create-link-material.component.html',
    styleUrls: ['./menu-side-create-link-material.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideCreateLinkMaterialComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public materialForm: FormGroup;
    Utils = Utils;
    showTags = false;
    showQuiz = false;
    // public vTQData: { question_count: number; difficulty_level: number } = { question_count: 6, difficulty_level: 2 };
    public vTQ = new VTQ({ question_count: 6, difficulty_level: 2, create_course: false });
    ResourceTypes = ResourceTypes;
    public authUser: User;
    public selectionTypes = ['ai', 'text', 'doc'];
    public nk3Platform$: Observable<PlatformType>;
    public currentLaguageId$: Observable<number>;
    public youTubeVideoInfo$: Observable<YouTubeVideoData>;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public langId: number;
    public customerId: number;
    public videoDuration: number;
    onSavingFile: EventEmitter<boolean> = new EventEmitter<boolean>();
    public progress$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    @ViewChild('input') input: ElementRef<HTMLInputElement>;
    linkTagsLangId: number;
    public userPromptsList: string[];

    constructor(
        protected auth: AuthService,
        protected router: Router,
        protected resourceService: ResourceService,
        protected langService: LanguageService,
        protected fb: FormBuilder,
        protected config: ConfigService,
        protected http: HttpClient,
        protected tagsService: TagService,
        protected cdr: ChangeDetectorRef,
        protected mediaService: S3MediaService,
        protected vTQService: VTQService,
        protected messageService: MessageService,
        protected logger: LoggerService,
        @Inject(LOCALE_ID) protected localeId: string
    ) {
        this.localeId = localeId === 'en' ? 'en-US' : localeId;
        this.materialForm = this.fb.group({
            link: null,
            text: null,
            file: null,
            file_type: null,
            type_id: 'url',
            prompt: [{ value: null, disabled: true }],
            tag_ids: [[]],
            title: [''],
            bullets: [''],
            description: [''],
            img_url: [],
        });
    }

    ngOnInit(): void {
        this.nk3Platform$ = this.auth.getNK3Platform();
        this.langService
            .getLanguageByKey(this.langService.getCurrentLanguage().value)
            .pipe(map((lang) => lang.id))
            .subscribe((langId) => (this.langId = langId));

        this.auth
            .getUser()
            .pipe(
                tap((user) => {
                    this.authUser = user;
                    this.userPromptsList = this.authUser.prompts_translation[this.localeId];
                }),
                first(),
                takeUntil(this.destroy)
            )
            .subscribe((user) => {
                this.customerId = user.customer_id;
                this.vTQ.user_id = user.id;
            });
        this.materialForm
            .get('link')
            .valueChanges.pipe(takeUntil(this.destroy), debounceTime(300))
            .subscribe((link) => {
                this.getVideoInfo(link);
                if (link && Utils.isUrl(link)) {
                    this.vTQ.material_name = link;
                    this.vTQ.material_type = 'link';
                } else {
                    this.vTQ.material_name = null;
                    this.vTQ.material_type = null;
                }
            });
        this.materialForm
            .get('type_id')
            .valueChanges.pipe(takeUntil(this.destroy))
            .subscribe((type) => {
                this.showQuiz = false;
                this.cdr.markForCheck();
            });

        this.materialForm.valueChanges.pipe(takeUntil(this.destroy)).subscribe(() => {
            const { type_id, link, file, text } = this.materialForm.value;

            // Check if the 'prompt' should be disabled
            if (['url', 'text', 'doc'].includes(type_id) && !link && !file && !text) {
                this.materialForm.get('prompt')?.disable({ emitEvent: false });
            } else {
                this.materialForm.get('prompt')?.enable({ emitEvent: false });
            }
        });

        // EB changed his mind
        // this.resourceService
        //     .getMaterialFromSlideIn()
        //     .pipe(
        //         takeUntil(this.destroy),
        //         filter((res) => !!res.tag_ids.length)
        //     )
        //     .subscribe((materialData) => {
        //         this.materialForm.patchValue({ tag_ids: materialData.tag_ids });
        //     });
    }
    formatLabel(value: number): string {
        if (value >= 1000) {
            return Math.round(value / 1000) + 'k';
        }

        return `${value}`;
    }
    goToLink(url: string) {
        if (Utils.isUrl(url)) {
            window.open(url, '_blank');
        }
    }
    ngAfterViewInit(): void {
        this.focusInput();
    }
    focusInput() {
        setTimeout(() => {
            this.input?.nativeElement?.focus();
        }, 1000);
    }
    changeContext(selected: string) {
        let initialTypes = ['text', 'doc', 'ai', 'url'];
        this.selectionTypes = initialTypes.filter((type) => type !== selected);
        this.focusInput();
        this.materialForm.reset({ type_id: selected });
        this.clearOtherTypes(selected);
        this.cdr.markForCheck();
    }
    clearOtherTypes(type_id: string) {
        switch (type_id) {
            case 'text':
                this.materialForm.get('link').reset();
                this.materialForm.get('file').reset();
                this.materialForm.get('prompt').reset();
                break;
            case 'doc':
                this.materialForm.get('text').reset();
                this.materialForm.get('link').reset();
                this.materialForm.get('prompt').reset();
                break;
            case 'ai':
                this.materialForm.get('text').reset();
                this.materialForm.get('link').reset();
                this.materialForm.get('file').reset();
                break;
            case 'url':
                this.materialForm.get('text').reset();
                this.materialForm.get('file').reset();
                this.materialForm.get('prompt').reset();
                break;
        }
    }
    getSelectedInfo(selected: string): { icon: string; tooltip: string; color?: string } {
        switch (selected) {
            case 'doc':
                return { icon: 'cloud_upload', tooltip: $localize`Create new file material` };
            case 'url':
                return { icon: 'add_link', tooltip: $localize`Create new link material` };
            case 'text':
                return { icon: 'post_add', tooltip: $localize`Create new text material` };
            case 'ai':
                return { icon: 'smart_toy', tooltip: $localize`Create new AI material` };
        }
    }
    public prepareFile(event) {
        const fileList: FileList = event.target.files;
        if (fileList.length > 0) {
            this.fileHandler(fileList[0]);
        }
    }
    public fileHandler(file: File) {
        this.onSavingFile.emit(true);
        this.auth
            .getCustomer()
            .pipe(
                first(),
                takeUntil(this.destroy),
                switchMap((customer) => this.mediaService.uploadMaterialToS3(customer.id, file, this.progress$))
            )
            .subscribe(
                (mediaKey) => {
                    if (mediaKey.isSupportedPromptType()) {
                        this.vTQ.material_name = mediaKey.filename;
                        this.vTQ.material_type = mediaKey.type;
                    } else {
                        this.vTQ.material_name = null;
                        this.vTQ.material_type = null;
                    }
                    this.onSavingFile.emit(false);
                    if (mediaKey) {
                        this.materialForm.patchValue({
                            file: mediaKey.src,
                            title: mediaKey.filename,
                            file_type: mediaKey.type,
                        });
                        FormHelper.markForm(this.materialForm);
                    }
                },
                (err) => {} // TODO on error
            );
    }

    submit(platform: string) {
        this.loading.next(true);
        if (this.materialForm.value.type_id === 'ai') {
            this.addMaterial(platform);
        } else {
            this.resourceService
                .store(this.prepareData())
                .pipe(
                    first(),
                    switchMap((resource) => (this.showQuiz ? this.createMakeQuizObs(resource) : of(resource)))
                )
                .subscribe((resource) => this.redirect(resource, platform));
        }
    }

    addMaterial(platform: string) {
        this.createPromptObservable()
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => {
                this.redirect(Array.isArray(resource) ? resource[0] : resource, platform);
            });
    }

    private createPromptObservable() {
        return this.resourceService.prompter(
            {
                prompt: this.materialForm.value.prompt,
                category_ids: [135],
                type_id: ResourceTypes.TextMaterial,
                tag_ids: this.materialForm.value.tag_ids,
                ai_metadata: this.showQuiz ? this.vTQ : null,
            },
            null
        );
    }

    changeModel(model: string) {
        this.vTQ.model_name = model;
    }

    private prepareData(): ResourceData {
        const data = new Resource(this.materialForm.value).getData();
        if (this.linkTagsLangId && this.materialForm.value.type_id === 'url') {
            data.language_id = this.linkTagsLangId;
        }
        data.resource_items = this.prepareMaterialTextItem(data);
        return data;
    }

    private prepareMaterialTextItem(data: ResourceData): ResourceItemData[] {
        const item: ResourceItemData = {};
        switch (this.materialForm.value.type_id) {
            case 'url':
                item.item_type_value = 'url_material';
                item.name = this.materialForm.value.link;
                data.type_id = ResourceTypes.URLMaterial;
                break;
            case 'doc':
                item.item_type_value = 'doc_material';
                item.title = this.materialForm.value.title;
                item.name = this.materialForm.value.file;
                data.type_id = ResourceTypes.DocMaterial;
                break;
            case 'text':
            case 'ai':
                item.item_type_value = 'text_material';
                item.name = this.materialForm.value.text;
                data.type_id = ResourceTypes.TextMaterial;
                break;
        }
        return [item];
    }

    private createMakeQuizObs(resource: Resource): Observable<Resource> {
        this.vTQ.material_id = resource.id;
        this.vTQ.material_name ??= this.materialForm.value.title;
        this.vTQ.material_type ??= 'text';
        this.vTQ.language_id = this.langId;

        return this.vTQService.create(this.vTQ).pipe(
            tap(() => {
                this.messageService.openMessage(
                    MessageSavedComponent,
                    $localize`Test creation process takes a few minutes, You will be notified when test is created.`
                );
            }),
            map(() => resource),
            finalize(() => this.vTQService.setActiveVTQ(null))
        );
    }

    private redirect(resource: Resource, platform: string) {
        this.loading.next(false);
        this.router.navigate(
            [platform === 'admin' ? '/admin/content/materials' : '/home/manage/material', resource.id],
            {
                queryParams: {
                    bypassGuardWithoutSaving: true,
                },
            }
        );
        this.videoDuration = null;
        this.materialForm.reset();
        this.onClose.emit();
    }

    listMaterial(platform: string) {
        this.router.navigate(platform === 'admin' ? ['/admin/content/materials/list'] : ['/home/manage/material/list']);
        this.onClose.emit();
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
    private getVideoInfo(link: string) {
        if (Utils.isYoutubeURL(link)) {
            this.loading.next(true);
            const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${Utils.getYoutubeMedia(link).src}&key=${
                this.config.config.YOUTUBE_DATA_API_KEY
            }&part=snippet,contentDetails`;
            this.http
                .get<any>(apiUrl)
                .pipe(
                    switchMap((res) => {
                        if (res?.items?.length) {
                            const item = res.items[0];
                            this.logger.log(item);
                            this.videoDuration = moment.duration(item.contentDetails.duration).asSeconds();
                            this.materialForm.patchValue({
                                title: item.snippet.title,
                                bullets: item.snippet?.description?.split('.')?.shift(),
                                description: item.snippet?.description,
                                img_url: item.snippet?.thumbnails?.medium?.url,
                            });
                            const tagsLang = item.snippet.defaultLanguage ?? item.snippet.defaultAudioLanguage ?? null;

                            if (tagsLang) {
                                this.langService
                                    .getLanguageByKey(tagsLang)
                                    .pipe(first(), takeUntil(this.destroy))
                                    .subscribe((lang) => (this.linkTagsLangId = lang.id));
                            }

                            return item.snippet?.tags && !!item.snippet?.tags?.length
                                ? this.tagsService.createManyTags(
                                      this.getAlphSortedTags(item.snippet?.tags, false),
                                      this.langId,
                                      this.customerId
                                  )
                                : of([]);
                        }
                        return of([]);
                    }),
                    map((tags) => this.getAlphSortedTags(tags, true)),
                    map((tags) => tags.map((tag) => tag?.id))
                )
                .subscribe((tagsIds) => {
                    if (tagsIds && tagsIds.length) {
                        this.materialForm.patchValue({
                            tag_ids: tagsIds,
                        });
                        this.showTags = true;
                    }

                    this.loading.next(false);
                    this.cdr.markForCheck();
                });
        }
    }
    private getAlphSortedTags(tags, isModel: boolean) {
        const sortedTags = tags.sort((a, b) => {
            const tagA = isModel ? a.name.toLowerCase() : a.toLowerCase();
            const tagB = isModel ? b.name.toLowerCase() : b.toLowerCase();

            if (tagA < tagB) {
                return -1;
            }
            if (tagA > tagB) {
                return 1;
            }
            return 0;
        });
        return sortedTags;
    }

    get canCreate(): boolean {
        const { link, text, file, prompt } = this.materialForm.value;
        return Utils.isUrl(link) || text || file || prompt;
    }
}
