import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
    selector: 'app-resource-details-bottom-sheet',
    templateUrl: './resource-details-bottom-sheet.component.html',
    styleUrls: ['./resource-details-bottom-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDetailsBottomSheetComponent implements OnInit {
    constructor(
        public bottomSheetRef: MatBottomSheetRef<ResourceDetailsBottomSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {}

    ngOnInit(): void {}
}
