import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AuthService } from '@klickdata/core/auth';
import { MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceService } from '@klickdata/core/resource';
import {
    Filter,
    FilterSpecs,
    GlobalFilterProperty,
    StatusFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Subject } from 'rxjs';
import { filter, first, map, shareReplay, takeUntil } from 'rxjs/operators';
import { ResourcesAssignSheetComponent } from '../resource-assign-sheet/resource-assign-sheet.component';
import { ResourceAssignedService } from './resource-assigned-listing.service';
import { ResourceOpportunityService } from '@klickdata/core/opportunity';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';

@Component({
    selector: 'app-resource-assigned',
    templateUrl: './resource-assigned-listing.component.html',
    styleUrls: ['./resource-assigned-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceAssignedService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ResourceAssignedListingComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() type_scope_id: number;
    @Input() assignedByAdmin: boolean;
    public dataSource = new TableSource<Resource>();
    expandedElement: Resource | null;
    GlobalFilterProperty = GlobalFilterProperty;
    public resourceAssignStatusOptions: StatusFilterOption[];
    public accordionExoanded: boolean;
    public columns = [
        'title',
        'assigned_by',
        'opportunity_end_date',
        'end_reward',
        'users_count',
        'started_users',
        'completed_users',
        'overdue',
        'language',
        'tools',
    ];
    public isMobile: boolean;
    public authUser: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    AppScope = AppScope;

    public filterSpecs: FilterSpecs = {
        property: GlobalFilterProperty.AUTHOR,
        label: $localize`:@@author:Author`,
        icon: 'drive_file_rename_outline',
    };

    constructor(
        protected listService: ResourceAssignedService,
        protected mobile: MobileService,
        protected resourceService: ResourceService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        protected messageService: MessageService,
        protected bottomSheet: MatBottomSheet,
        protected oppoService: ResourceOpportunityService
    ) {
        this.resourceAssignStatusOptions = [
            { title: $localize`Ongoing`, value: 'ongoing', color: '#ff9961', icon: 'cached', selected: true },
            { title: $localize`Deleted`, value: 'deleted', color: '#e44a66', icon: 'delete_forever', selected: false },
            { title: $localize`Ended`, value: 'ended', color: '#e44a66', icon: 'event_busy', selected: false },
            {
                title: $localize`Not started`,
                value: 'not_started',
                color: '#e44a66',
                icon: 'directions_run',
                selected: false,
            },
            { title: $localize`All`, value: 'all', color: '#e44a66', icon: 'fact_check', selected: false },
        ];
    }

    public ngOnInit() {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((isMobile) => (this.isMobile = isMobile));
        this.dataSource.service = this.listService;
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => {
                this.authUser = user;
                this.filter.createOrUpdateWithoutEmitFilter([
                    new Filter('scope', [this.type_scope_id]),
                    new Filter('assignedInCustomer', [user.customer_id]),
                    new Filter('published', ['1']),
                ]);
                if (this.assignedByAdmin) {
                    this.filter.createOrUpdateWithoutEmitFilter([new Filter('author', [user.id])]);
                }
                this.filter.setActiveFilterWithSelectedOption(GlobalFilterProperty.ASSGN_STATUS, 'all');
            });
        this.filter.filterChange
            .pipe(
                takeUntil(this.destroy),
                map((filters) => filters.filters.find((fltr) => fltr.property === GlobalFilterProperty.ASSGN_STATUS)),
                filter((fltr) => fltr && !!fltr.items?.length)
            )
            .subscribe((filter) => this.filterColumnBasedOnAssignStatus(filter.items[0].toString()));
    }

    private filterColumnBasedOnAssignStatus(status: string) {
        switch (status) {
            case 'ongoing':
                this.columns = [
                    'title',
                    'assigned_by',
                    'opportunity_end_date',
                    'end_reward',
                    'users_count',
                    'started_users',
                    'language',
                    'tools',
                ];
                break;
            case 'deleted':
                this.columns = [
                    'title',
                    'assigned_by',
                    'opportunity_end_date',
                    'end_reward',
                    'users_count',
                    'assign_deleted_at',
                    'language',
                    'tools',
                ];
                break;
            case 'ended':
                this.columns = [
                    'title',
                    'assigned_by',
                    'opportunity_end_date',
                    'end_reward',
                    'users_count',
                    'completed_users',
                    'overdue',
                    'language',
                    'tools',
                ];
                break;
            case 'not_started':
                this.columns = [
                    'title',
                    'assigned_by',
                    'opportunity_end_date',
                    'end_reward',
                    'users_count',
                    'language',
                    'tools',
                ];
                break;

            default:
                this.columns = [
                    'title',
                    'assigned_by',
                    'opportunity_end_date',
                    'end_reward',
                    'users_count',
                    'started_users',
                    'completed_users',
                    'overdue',
                    'language',
                    'tools',
                ];
                break;
        }
        this.cdRef.markForCheck();
    }
    public openAssignmentSheet(resource: Resource): void {
        this.bottomSheet
            .open(ResourcesAssignSheetComponent, {
                data: {
                    width: !this.isMobile ? '70%' : '100%',
                    id: resource.id,
                    context: resource.assign_id ? $localize`Edit assign` : $localize`Assign`,
                    contextIcon: 'assignment_ind',
                    title: resource.title,
                    referenceIdKey: 'resource_id',
                    referenceFilter: 'assignedResource',
                    assign_id: resource.assign_id,
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe((value) => {
                resource.assign_id = value;
                this.dataSource.replace(resource);
            });
    }
    deleteAssign(resource: Resource) {
        this.oppoService
            .destroyAssign(resource.assign_id)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.messageService.openMessage(MessageErrorComponent, $localize`Assignment has been cancelled.`);
                this.dataSource.removeById(resource.id);
            });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
