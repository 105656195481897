<div class="course-items-switch-wrapper">
    <ng-container *ngIf="display === ResourceBuildingItems.MATERIAL_LIBRARY">
        <mat-tab-group class="res-tabs-course-item material-tabs" #tabGroup ngClass.xs="xs">
            <mat-tab>
                <ng-template mat-tab-label i18n> My materials </ng-template>
                <ng-template matTabContent>
                    <app-resource-library-multiple-select
                        [type_scope_id]="AppScope.MATERIAL"
                        context="author"
                        [selection]="selection"
                        (select)="select.emit({ res: $event, isParentSubmission: false })"
                        (onSubmitMultiSelect)="onMultiSelect.emit({ res: $event, isParentSubmission: false })"
                        (selectChange)="selectChange.emit($event)"
                    ></app-resource-library-multiple-select>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label i18n> Academy materials </ng-template>
                <ng-template matTabContent>
                    <app-resource-library-multiple-select
                        [type_scope_id]="AppScope.MATERIAL"
                        context="academy"
                        [selection]="selection"
                        (onSubmitMultiSelect)="onMultiSelect.emit({ res: $event, isParentSubmission: false })"
                        (selectChange)="selectChange.emit($event)"
                    ></app-resource-library-multiple-select>
                </ng-template>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label i18n> Open library (KOL) </ng-template>
                <ng-template matTabContent>
                    <app-resource-library-multiple-select
                        [type_scope_id]="AppScope.MATERIAL"
                        context="kol"
                        [selection]="selection"
                        (onSubmitMultiSelect)="onMultiSelect.emit({ res: $event, isParentSubmission: false })"
                        (selectChange)="selectChange.emit($event)"
                    ></app-resource-library-multiple-select>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </ng-container>
    <ng-container *ngIf="display !== ResourceBuildingItems.MATERIAL_LIBRARY">
        <app-course-manager-core-material-manager (saved)="select.emit($event)" [display]="display" #compRef>
        </app-course-manager-core-material-manager>
    </ng-container>
    <!-- <ng-container
        *ngIf="display !== ResourceBuildingItems.MATERIAL_LIBRARY"
        [ngTemplateOutlet]="materialTypes"
    ></ng-container> -->

    <button
        matTooltip="Change"
        [ngClass]="display === ResourceBuildingItems.MATERIAL_LIBRARY ? 'lib' : 'normal'"
        mat-icon-button
        (click)="display = null; onCloseView.emit(true)"
        i18n-matTooltip
        class="add-creation-tab"
        type="button"
    >
        <mat-icon>cancel</mat-icon>
    </button>
</div>
