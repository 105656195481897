import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole, UserRoleData } from './user-role.model';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';

@Injectable({
    providedIn: 'root',
})
export class UserRoleService {
    protected resourceUrl: string;

    constructor(
        protected builder: RequestBuilderService,
        protected auth: AuthService,
        protected config: ConfigService,
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}roles`;
    }

    public getUserRoles(): Observable<UserRole[]> {
        return this.builder.get<UserRoleData[]>(this.resourceUrl)
            .request().pipe(
                map(res => this.mapUserRole(res.data)),
            );
    }

    public getUserRole(id: string): Observable<UserRole> {
        return this.builder.get<UserRoleData>(`${this.resourceUrl}/${id}`)
            .request().pipe(
                map(res => this.createUserRole(res.data)),
            );
    }

    protected mapUserRole(data: UserRoleData[]) {
        return data.map(item => this.createUserRole(item));
    }

    protected createUserRole(data: UserRoleData) {
        return new UserRole(data);
    }
}
