import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-sticky-header',
    templateUrl: './sticky-header.component.html',
    styleUrls: ['./sticky-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StickyHeaderComponent implements OnInit {
    @Input() title: string;
    @Input() context: string;
    @Input() contextIcon: string;
    @Input() savingTitle = $localize`:@@save:Save`;
    @Input() cancelTitle = $localize`:@@cancel:Cancel`;
    @Input() savingStatus: boolean;
    @Input() showSubmit = true;
    @Output() onSubmit = new EventEmitter<boolean>();
    @Output() onCancel = new EventEmitter<boolean>();
    constructor() {}

    ngOnInit(): void {}
}
