<app-table-filter [inGlobalFilter]="true" color="accent" #filter>
    <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
    <app-table-date-filter [inGlobalFilter]="true" color="accent" dateFilterRef></app-table-date-filter>
</app-table-filter>

<mat-progress-bar
    class="progress progress-table-loader"
    *ngIf="(dataSource.status | async) === 'loading'"
    mode="indeterminate"
>
</mat-progress-bar>
<mat-table #table [dataSource]="dataSource" matSort matSortActive="updated_at" matSortDirection="desc">
    <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
        <mat-cell *matCellDef="let resource">{{ resource.title }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="updated_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@updated"> Updated at </mat-header-cell>
        <mat-cell *matCellDef="let resource">
            {{ resource.updated_at | kdDateFormat: 'YYYY-MM-DD' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="created_at">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Created at </mat-header-cell>
        <mat-cell *matCellDef="let resource">
            {{ resource.created_at | kdDateFormat: 'YYYY-MM-DD' }}
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="public">
        <mat-header-cell *matHeaderCellDef> Public </mat-header-cell>
        <mat-cell *matCellDef="let resource">
            <mat-icon>
                {{ resource.public ? 'check' : 'clear' }}
            </mat-icon>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
    <mat-row *matRowDef="let resource; columns: columns"></mat-row>
</mat-table>

<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
