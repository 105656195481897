import { Customer } from '@klickdata/core/customer';
import { map, Observable } from 'rxjs';

/**
 * App scopes
 * https://git.klickportalen.se/klickdata2016/nk3-datastore/blob/develop/docs/scopes.json
 */
export enum AppScope {
    ALL = 0,
    RESOURCES = 1,
    USERS = 2,

    COURSE = 3,
    SURVEY = 4,
    TEST = 5,
    E_COURSE = 6,
    MATERIAL = 7,

    FOLDERS = 8,
    SECTIONS = 9,
    TAGS = 10,
    CATEGORIES = 11,
    GROUPS = 12,
    CUSTOMERS = 13,
    QUESTIONS = 14,
    ALTERNATIVES = 15,
    ANSWERS = 16,
    RESOURCE_ITEMS = 17,
    PACKAGE = 18,
    AUTHOR = 19,
    SCORM = 20,
    ALERT = 21,
    IMPORT = 22,
    POST = 23,
    NOTIFICATION = 24,
    EVENT = 25,
    QUOTE = 26,
    TASK = 27,
    NOTE = 28,
    FILE = 29,
    MEDIA = 30,
    TEAM = 31,
    PLANNER = 32,
    CLIENT_CARD = 50,
    CURRICULUM = 51,
    CONTACT_CARD = 52,
}

export namespace AppScope {
    export function label(scope: AppScope, plural?: boolean): string {
        switch (scope) {
            case AppScope.ALL:
                return $localize`:@@all:All`;
            case AppScope.RESOURCES:
                return plural ? $localize`Resources` : $localize`Resource`;
            case AppScope.PACKAGE:
                return plural ? $localize`Packages` : $localize`Package`;
            case AppScope.SCORM:
                return plural ? $localize`SCORMs` : $localize`SCORM`;
            case AppScope.USERS:
                return plural ? $localize`:@@learners:Learners` : $localize`:@@learner:Learner`;
            case AppScope.COURSE:
                return plural ? $localize`:@@courses:Courses` : $localize`:@@course:Course`;
            case AppScope.SURVEY:
                return plural ? $localize`:@@surveys:Surveys` : $localize`:@@survey:Survey`;
            case AppScope.TEST:
                return plural ? $localize`:@@tests:Tests` : $localize`:@@test:Test`;
            case AppScope.E_COURSE:
                return plural ? $localize`:@@e_courses:E-Courses` : $localize`:@@e_course:E-Course`;
            case AppScope.MATERIAL:
                return plural ? $localize`:@@materials:Materials` : $localize`:@@material:Material`;
            case AppScope.EVENT:
                return plural ? $localize`:@@events:Events` : $localize`:@@event:Event`;
            case AppScope.FOLDERS:
                return plural ? $localize`:@@folders:Folders` : $localize`:@@folder:Folder`;
            case AppScope.TAGS:
                return plural ? $localize`:@@tags:Tags` : $localize`:@@tag:Tag`;
            case AppScope.CATEGORIES:
                return plural ? $localize`:@@categories:Categories` : $localize`:@@category:Category`;
            case AppScope.SECTIONS:
                return plural ? $localize`:@@sections:Sections` : $localize`:@@section:Section`;
            case AppScope.GROUPS:
                return plural ? $localize`:@@groups:Groups` : $localize`:@@group:Group`;
            case AppScope.CUSTOMERS:
                return plural ? $localize`:@@customers:Academies` : $localize`:@@customer:Academy`;
            case AppScope.SCORM:
                return plural ? $localize`:@@scorrms:SCORMs` : $localize`:@@scorm:SCORM`;
            case AppScope.ANSWERS:
                return plural ? $localize`:@@answers:Answers` : $localize`:@@answer:Answer`;
            case AppScope.RESOURCE_ITEMS:
                return plural ? $localize`:@@tasks:Tasks` : $localize`:@@task:Task`;
            case AppScope.QUESTIONS:
                return plural ? $localize`:@@questions:Questions` : $localize`:@@question:Question`;
            case AppScope.ALTERNATIVES:
                return plural ? $localize`:@@alternatives:Alternatives` : $localize`:@@alternative:Alternative`;
            case AppScope.QUOTE:
                return plural ? $localize`:@@quotes:Quotes` : $localize`:@@quote:Quote`;
            case AppScope.POST:
                return plural ? $localize`:@@news:News` : $localize`:@@news:News`;
            case AppScope.FILE:
                return plural ? $localize`:@@files:Files` : $localize`:@@file:File`;
            case AppScope.TEAM:
                return plural ? $localize`:@@divisions:Divisions` : $localize`:@@division:Division`;
            case AppScope.PLANNER:
                return plural ? $localize`:@@planners:Planners` : $localize`:@@planner:Planner`;
            case AppScope.TASK:
                return plural ? $localize`:@@tasks:Tasks` : $localize`:@@task:Task`;
            case AppScope.NOTE:
                return plural ? $localize`:@@note:Notes` : $localize`:@@note:Note`;
        }
    }
    export function getMainScopesOptions() {
        return [
            { value: AppScope.USERS, title: $localize`:@@learner:Learner`, icon: 'kd-icon-user', isMainResource: true },
            { value: AppScope.COURSE, title: $localize`:@@course:Course`, icon: 'kd-icon-task', isMainResource: true },
            { value: AppScope.TEST, title: $localize`:@@test:Test`, icon: 'kd-icon-test', isMainResource: true },
            {
                value: AppScope.E_COURSE,
                title: $localize`:@@e_course:E-Course`,
                icon: 'kd-icon-player',
                isMainResource: true,
            },
            {
                value: AppScope.SURVEY,
                title: $localize`:@@survey:Survey`,
                icon: 'kd-icon-survey',
                isMainResource: true,
            },
            {
                value: AppScope.MATERIAL,
                title: $localize`:@@material:Material`,
                icon: 'kd-icon-document',
                isMainResource: true,
            },
            { value: AppScope.FOLDERS, title: $localize`:@@folder:Folder`, icon: 'folder_open' },
            { value: AppScope.SECTIONS, title: $localize`:@@section:Section`, icon: 'widgets' },
            { value: AppScope.CATEGORIES, title: $localize`:@@category:Category`, icon: 'folder' },
            { value: AppScope.GROUPS, title: $localize`:@@group:Group`, icon: 'groups' },
            { value: AppScope.CUSTOMERS, title: $localize`:@@customer:Academy`, icon: 'supervised_user_circle' },
            { value: AppScope.SCORM, title: $localize`:@@scorm:SCORM`, icon: 'business_center' },
            { value: AppScope.EVENT, title: $localize`:@@event:Event`, icon: 'event' },
            { value: AppScope.TASK, title: $localize`:@@task:Task`, icon: 'today' },
            { value: AppScope.NOTE, title: $localize`:@@note:Note`, icon: 'document_scanner' },
        ];
    }
    export function getScopeOptionByScopeId(scopeId: number) {
        return this.getMainScopesOptions().find((scope) => scope.value == scopeId) ?? null;
    }
    export function getScopeLabel(customer$: Observable<Customer>, scope: AppScope): Observable<string> {
        return customer$.pipe(map((customer) => customer.scope_names[scope] ?? AppScope.label(scope)));
    }

    export function fontIconType(type: AppScope): string {
        switch (type) {
            case AppScope.COURSE:
                return 'kd-icon-task';
            case AppScope.TEST:
                return 'kd-icon-test';
            case AppScope.E_COURSE:
                return 'kd-icon-player';
            case AppScope.SURVEY:
                return 'kd-icon-survey';
            case AppScope.USERS:
                return 'person';
            case AppScope.GROUPS:
                return 'groups';
            case AppScope.CATEGORIES:
                return 'folder';
            case AppScope.TAGS:
                return 'tag';
            case AppScope.CUSTOMERS:
                return 'supervised_user_circle';
            case AppScope.SECTIONS:
                return 'widgets';
            case AppScope.FOLDERS:
                return 'folder_open';
            case AppScope.SCORM:
                return 'business_center';
            case AppScope.ANSWERS:
                return 'fact_check';
            case AppScope.ALTERNATIVES:
                return 'alt_route';
            case AppScope.RESOURCE_ITEMS:
                return 'task';
            case AppScope.QUESTIONS:
                return 'contact_support';
            case AppScope.QUOTE:
                return 'format_quote';
            case AppScope.POST:
                return 'speaker_notes';
            default:
                return 'kd-icon-document';
        }
    }

    export function isResource(scope: AppScope | string): boolean {
        if (typeof scope === 'string') {
            scope = Number(scope);
        }
        return (
            [
                AppScope.PACKAGE,
                AppScope.SCORM,
                AppScope.COURSE,
                AppScope.SURVEY,
                AppScope.TEST,
                AppScope.E_COURSE,
                AppScope.MATERIAL,
            ].indexOf(scope) !== -1
        );
    }

    export function isResourceContainer(scope: AppScope | string): boolean {
        if (typeof scope === 'string') {
            scope = Number(scope);
        }
        return [AppScope.SECTIONS, AppScope.FOLDERS, AppScope.TAGS, AppScope.CATEGORIES].indexOf(scope) !== -1;
    }
    export function isNotResource(scope: AppScope | string): boolean {
        if (typeof scope === 'string') {
            scope = Number(scope);
        }
        return (
            [
                AppScope.SECTIONS,
                AppScope.FOLDERS,
                AppScope.TAGS,
                AppScope.CATEGORIES,
                AppScope.USERS,
                AppScope.GROUPS,
                AppScope.CUSTOMERS,
                AppScope.ALTERNATIVES,
                AppScope.RESOURCE_ITEMS,
                AppScope.QUOTE,
                AppScope.POST,
            ].indexOf(scope) !== -1
        );
    }

    export function isLink(scope: AppScope | string): boolean {
        if (typeof scope === 'string') {
            scope = Number(scope);
        }
        return [AppScope.USERS, AppScope.GROUPS, AppScope.CUSTOMERS].indexOf(scope) !== -1;
    }
}
