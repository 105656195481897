<div
    [matTooltipDisabled]="!showTimeHover"
    [matTooltip]="dateTime.value | kdDateFormat : 'YYYY-MM-DD HH:mm'"
    class="dates-time-piker"
    fxLayout="column"
>
    <mat-form-field class="small-field mt-1" appearance="fill">
        <input #input matInput [ngxMatDatetimePicker]="picker" i18n-placeholder [formControl]="dateTime" />
        <mat-icon *ngIf="showTimeHover" class="hover-time">watch_later</mat-icon>
        <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker color="primary" #picker>
            <ng-template>
                <span>Done</span>
            </ng-template>
        </ngx-mat-datetime-picker>
    </mat-form-field>
</div>
