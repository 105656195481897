<form [formGroup]="resourceForm" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>category</mat-icon>
        <span i18n>Add competence</span>
        <app-language-selector
            formControlName="language_id"
            class="icon-resource-builder"
            fxFlex="0 0 auto"
        ></app-language-selector>
    </div>
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
        <div class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
            <span i18n>Title</span>
            <mat-form-field appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                <textarea
                    #title
                    matInput
                    type="text"
                    formControlName="title"
                    placeholder="Pls set title"
                    i18n-placeholder
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>
        </div>
        <div class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
            <span i18n>Description</span>
            <mat-form-field appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                <textarea
                    #title
                    matInput
                    type="text"
                    formControlName="description"
                    placeholder="Enter description"
                    i18n-placeholder
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>
        </div>
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent k3-crm-tags">
            <span i18n>Tags</span>
            <app-tag-chip-select
                class="w-100"
                placeholder="Add Tag"
                [language_id]="resourceForm.value.language_id"
                i18n-placeholder
                formControlName="tag_ids"
            ></app-tag-chip-select>
        </div>

        <div class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
            <span i18n>SKU/ Article number</span>
            <mat-form-field appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                <textarea
                    #title
                    matInput
                    type="text"
                    formControlName="article_code"
                    placeholder="Pls set article code"
                    i18n-placeholder
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>
        </div>

        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
            <span i18n>Completion Date</span>
            <mat-form-field
                appearance="outline"
                floatLabel="never"
                class="w-100"
                fxFlex="grow"
                fxFlex="1 1 100%"
                fxFlex.lt-md="1 1 100%"
            >
                <mat-label i18n>Enter Completion date</mat-label>
                <input
                    matInput
                    [matDatepicker]="deadlineDatepicker"
                    (focus)="deadlineDatepicker.open()"
                    formControlName="done_at"
                />
                <mat-datepicker-toggle matIconSuffix [for]="deadlineDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #deadlineDatepicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
            <span i18n>Note</span>
            <mat-form-field appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
                <textarea
                    #title
                    matInput
                    type="text"
                    formControlName="note"
                    placeholder="Add note"
                    i18n-placeholder
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="10"
                ></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="action">
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button i18n>
            Cancel
        </button>
        <button
            [disabled]="!resourceForm.value.title"
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="submit()"
            mat-raised-button
            i18n
        >
            Submit
        </button>
    </div>
</form>
