import { SectionData } from '@klickdata/core/section/src/section.model';
import { Section, SectionService } from '@klickdata/core/section';
import { Injectable } from '@angular/core';
import { PaginatorResponse } from '@klickdata/core/http';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SectionsListingDatatableService extends SectionService implements TableHttpService<Section> {
    public fetchData(source: TableSource<Section>): Observable<PaginatorResponse<Section[]>> {
        const req = this.builder.get<SectionData[]>(this.apiURL);
        req.param('translations', 1);

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<SectionData[]>) => {
                return {
                    data: res.data.map((item) => this.createSection(item)),
                    notify: res.notify,
                    paginator: res.paginator,
                    more: res.more,
                };
            })
        );
    }
}
