<div fxLayout="column" fxFlex="0 0 100%" class="user-notes-listing">
    <mat-accordion displayMode="flat">
        <mat-expansion-panel expanded="true" #expansionPanel>
            <mat-expansion-panel-header> </mat-expansion-panel-header>
            <app-table-filter [cacheScope]="AppScope.RESOURCES" [inGlobalFilter]="true" color="accent" #filter>
                <app-table-search
                    [inGlobalFilter]="true"
                    placeHolder="Search for note or resource"
                    toggleBtnLabel="Search"
                    i18n-toggleBtnLabel
                    i18n-placeHolder
                    class="align-space"
                    searchFilterRef
                ></app-table-search>
                <app-user-chip-search-filter [inGlobalFilter]="true" userSearchRef></app-user-chip-search-filter>
                <app-select-filter
                    [inGlobalFilter]="true"
                    class="filter-space"
                    label="All Time"
                    i18n-label
                    [property]="GlobalFilterProperty.TIME_SPENT"
                    [options]="predefinedTimeSpentOptions"
                    fixedTimeSpentFilterRef
                >
                </app-select-filter>
                <app-group-chip-search-filter [inGlobalFilter]="true" groupSearchRef></app-group-chip-search-filter>
            </app-table-filter>
        </mat-expansion-panel>
    </mat-accordion>
    <button
        mat-icon-button
        type="button"
        fxFlex="0 0 auto"
        class="new-note"
        (click)="newNote()"
        matTooltip="Create a new note"
        i18n-matTooltip
    >
        <mat-icon class="kd-icon-24">add_circle</mat-icon>
    </button>
    <mat-progress-bar
        class="progress-table-loader mt-1"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        multiTemplateDataRows
        matSortDirection="desc"
        matSortActive="created_at"
        matSort
    >
        <ng-container matColumnDef="created_at">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@time">Time</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let note">
                <span class="mobile-label" i18n="@@time">Time:</span>
                {{ note.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="notes">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n>Note</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let note">
                <span class="mobile-label" i18n>Note:</span>
                <div fxLayout="row" fxLayoutGap="3px" fxLayoutAlign="start center">
                    <span class="notes-holder">{{ note.body }}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="reciever">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@availableFor">Available for</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let note">
                <span class="mobile-label" i18n="@@availableFor">Available for</span>
                <ng-container *ngIf="note.privacy?.value === 'user'">
                    <div
                        [ngClass]="{
                            'dark-blue-bg': user.role_value === 'user',
                            'green-bg': user.role_value !== 'user'
                        }"
                        *ngFor="let user of note.users"
                        class="reciever-user chip-wrap"
                        fxLayoutGap="5px"
                        fxLayout="row"
                    >
                        <mat-icon>{{ user.role_value | userRoleIcon }}</mat-icon>
                        <span>{{ user.fname }} {{ user.lname }}</span>
                    </div>
                </ng-container>
                <ng-container *ngIf="note.privacy?.value === 'group'">
                    <div
                        *ngFor="let group of note.groups"
                        class="reciever-group chip-wrap"
                        fxLayoutGap="5px"
                        fxLayout="row"
                    >
                        <mat-icon>groups</mat-icon>
                        <span>{{ group.name }}</span>
                    </div>
                </ng-container>

                <div
                    *ngIf="note.privacy?.value === 'public'"
                    class="reciever-me reciever-academy chip-wrap"
                    fxLayoutGap="5px"
                    fxLayout="row"
                >
                    <mat-icon>public</mat-icon>
                    <span i18n>Public</span>
                </div>

                <div
                    *ngIf="note.privacy?.value === 'private'"
                    class="reciever-me reciever-academy chip-wrap"
                    fxLayoutGap="5px"
                    fxLayout="row"
                >
                    <mat-icon>perm_identity</mat-icon>
                    <span i18n>Only me</span>
                </div>

                <div
                    *ngIf="note.privacy?.value === 'academy'"
                    class="reciever-academy chip-wrap"
                    fxLayoutGap="5px"
                    fxLayout="row"
                >
                    <mat-icon>account_balance</mat-icon>
                    <span>{{ note.academy.name }}</span>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="privacy">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@privacy">Privacy</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let note">
                <span class="mobile-label" i18n="@@privacy">Privacy:</span>
                {{ note.privacy && note.privacy.label }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="context">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@resource">Resource</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let note">
                <span class="mobile-label" i18n="@@resource">Resource:</span>
                <span (click)="$event.stopPropagation(); onResClick(note.noteResource.title)">
                    {{ note.noteResource?.title }}
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expand">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let note">
                <button
                    (click)="$event.stopPropagation(); editNote(note)"
                    matTooltip="Edit"
                    mat-icon-button
                    i18n-matTooltip="@@edit"
                    class="comment-add"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); deleteNote(note)"
                    matTooltip="Delete"
                    mat-icon-button
                    i18n-matTooltip="@@delete"
                    class="comment-add"
                >
                    <mat-icon>delete</mat-icon>
                </button>
                <button
                    (click)="$event.stopPropagation(); expandedRow = expandedRow === note ? null : note"
                    matTooltip="View"
                    mat-icon-button
                    i18n-matTooltip="@@view"
                >
                    <mat-icon>{{ expandedRow === note ? 'expand_less' : 'expand_more' }}</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let note" [attr.colspan]="columns.length">
                <div class="example-element-detail" [@detailExpand]="note == expandedRow ? 'expanded' : 'collapsed'">
                    <ng-container *ngIf="expandedRow == note">
                        <app-notes-resource-listing-details [note]="note"></app-notes-resource-listing-details
                    ></ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            *matRowDef="let note; columns: columns"
            [class.example-expanded-row]="expandedRow === note"
            (click)="expandedRow = expandedRow === note ? null : note"
            class="example-element-row"
        ></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>

<ng-template #loading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container mt-2">
        <mat-spinner fxFlex="0 0 auto"></mat-spinner>
    </div>
</ng-template>
