<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>post_add</mat-icon>
        <div i18n>Add field from list</div>
    </div>

    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button fxFlex="0 0 auto" class="mobile-button positive" (click)="onSubmit()" mat-raised-button i18n>
            Done
        </button>
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button i18n>
            Cancel
        </button>
    </div>
</div>
