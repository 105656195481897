import { AccessCapability } from '../can-access.directive';

export namespace SurveyCapability {
    export const NONE = 0;

    // Computed by customer services
    // export const SURVEY_ACCESS = 1 << 0; // Replace by ServicesAccessCapability

    // Surveys - Role
    export const VIEW_ALL_SURVEYS = 1 << 1;
    export const VIEW_CUSTOMER_SURVEYS = 1 << 2;
    export const VIEW_GROUP_SURVEYS = 1 << 3;
    export const MANAGE_CUSTOMER_SURVEYS = 1 << 4;
    export const MANAGE_GROUP_SURVEYS = 1 << 5;
    export const MANAGE_PUBLIC_SURVEYS = 1 << 6;

    export function getName(): string {
        return 'survey';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
