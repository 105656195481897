<ng-container *ngIf="updatedMsgCase$ | async; let msgCase; else: smallLoading">
    <div fxLayoutAlign="start center" fxLayout="column" fxLayoutGap="5px" *ngIf="msgCase.users">
        <div *ngIf="msgCase.users.length > 1" class="toggle-all-wrapper" fxLayoutAlign="space-between center">
            <div fxLayoutAlign="start center" fxLayout="row wrap" fxLayoutGap="4px">
                <mat-icon>groups</mat-icon> <span>Group</span> <span>({{ msgCase.users.length }})</span>
            </div>
            <button
                mat-icon-button
                type="button"
                color="primary"
                fxFlex="0 0 auto"
                matTooltip="Open All"
                i18n-matTooltip="@@openAll"
                (click)="expandAll = !expandAll"
            >
                <mat-icon class="expanding" [class.expanded-all]="expandAll">first_page</mat-icon>
            </button>
        </div>
        <mat-accordion [multi]="true" class="rec-accordion" fxFlex="0 0 100%" displayMode="flat">
            <mat-expansion-panel
                [expanded]="expandAll"
                #statusExpansion
                class="{{ user.status[user.status.length - 1].type }}"
                *ngFor="let user of msgCase.users; let last = last"
                [class.status-expanded]="statusExpansion.expanded"
            >
                <mat-expansion-panel-header class="user-case-header">
                    <div class="grey" fxLayoutAlign="start center" fxLayoutGap="5px" fxLayout="row wrap">
                        <mat-icon>person</mat-icon>
                        <span>{{ user.fname }}</span> <span>{{ user.lname }}</span>
                    </div></mat-expansion-panel-header
                >
                <div
                    *ngFor="let status of user.status; let last = last; let first = first"
                    fxLayout="column"
                    fxLayoutGap="10px"
                >
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div class="info-wrapper" fxLayoutAlign="start center" fxLayout="row wrap" fxLayoutGap="4px">
                            <mat-icon>{{ (status.type | caseStatus).icon }}</mat-icon>
                            <span i18n>Case</span>
                            <span class="orange">{{ (status.type | caseStatus).title }}</span>
                            <span *ngIf="localeId === 'en'" i18n>on</span>
                            <span class="fxgrow-1">{{ status.date | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
                            <button
                                [disableRipple]="true"
                                class="edit-btn"
                                *ngIf="last && status.type === 'approved'"
                                mat-icon-button
                                (click)="onEdit(true, status)"
                            >
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button
                                [disableRipple]="true"
                                [class.expanded]="adminExpansionPanel?.expanded"
                                class="comment-toggle"
                                *ngIf="status.comment"
                                mat-icon-button
                                (click)="adminExpansionPanel?.toggle()"
                            >
                                <mat-icon>chat_bubble</mat-icon>
                            </button>
                        </div>
                        <mat-accordion
                            class="expansion-aa"
                            [fxHide]="!adminExpansionPanel.expanded"
                            fxFlex="0 0 100%"
                            displayMode="flat"
                        >
                            <mat-expansion-panel #adminExpansionPanel>
                                <mat-expansion-panel-header fxHide> </mat-expansion-panel-header>
                                <ng-container
                                    [ngTemplateOutlet]="commentLabel"
                                    [ngTemplateOutletContext]="{ comment: status.comment }"
                                ></ng-container>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div
                        class="comment"
                        *ngIf="last && status.type !== 'approved' && (status.type | caseStatusActions: true).length > 0"
                        fxLayout="row"
                    >
                        <ng-container [ngTemplateOutlet]="commentFormField"></ng-container>
                    </div>
                    <div class="actions admin-context" *ngIf="last">
                        <button
                            *ngFor="let UserStatus of status.type | caseStatusActions: true"
                            mat-raised-button
                            color="accent"
                            [disabled]="status.type === 'not_approved' && !comment.value"
                            (click)="
                                onToggleMenu(comment.value, UserStatus.warning);
                                updateCaseStatus(UserStatus.action, msgCase.message_id, comment.value, user.id)
                            "
                        >
                            <mat-icon>{{ (UserStatus.action | caseStatus).icon }}</mat-icon>
                            <span class="capital-letter">{{ (UserStatus.action | caseStatus).action }} </span>
                        </button>
                    </div>
                    <ng-container *ngIf="onEditMode && last && status.type === 'approved'">
                        <div class="comment" fxLayout="row">
                            <ng-container [ngTemplateOutlet]="commentFormField"></ng-container>
                        </div>
                        <div class="actions admin-context">
                            <button
                                mat-raised-button
                                color="accent"
                                (click)="updateCaseStatus('not_approved', msgCase.message_id, comment.value)"
                            >
                                <mat-icon>{{ ('not_approved' | caseStatus).icon }}</mat-icon>
                                <span class="capital-letter">{{ ('not_approved' | caseStatus).action }} </span>
                            </button>
                            <button
                                mat-raised-button
                                color="accent"
                                (click)="updateCaseStatus('reopen', msgCase.message_id, comment.value)"
                            >
                                <mat-icon>{{ ('reopen' | caseStatus).icon }}</mat-icon>
                                <span class="capital-letter">{{ ('reopen' | caseStatus).action }} </span>
                            </button>
                        </div>
                    </ng-container>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div *ngIf="msgCase.status">
        <div fxLayout="column" fxLayoutGap="10px" *ngFor="let status of msgCase.status; let last = last">
            <div class="info-wrapper user-context" fxLayoutAlign="start center" fxLayout="row wrap" fxLayoutGap="4px">
                <mat-icon>{{ (status.type | caseStatus).icon }}</mat-icon>
                <span i18n>Case</span>
                <span class="orange">{{ (status.type | caseStatus).title }}</span>
                <span *ngIf="localeId === 'en'" i18n>on</span>
                <span class="fxgrow-1">{{ status.date | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
                <button
                    [disableRipple]="true"
                    class="edit-btn"
                    *ngIf="last && (status.type === 'done' || status.type === 'not_approved')"
                    mat-icon-button
                    (click)="onEdit(false, status)"
                >
                    <mat-icon>edit</mat-icon>
                </button>
                <button
                    [disableRipple]="true"
                    [class.expanded]="expansionPanel.expanded"
                    class="comment-toggle"
                    *ngIf="status.comment"
                    mat-icon-button
                    (click)="expansionPanel.toggle()"
                >
                    <mat-icon>chat_bubble</mat-icon>
                </button>
            </div>
            <mat-accordion fxFlex="0 0 100%" displayMode="flat">
                <mat-expansion-panel #expansionPanel>
                    <mat-expansion-panel-header fxHide> </mat-expansion-panel-header>
                    <ng-container
                        [ngTemplateOutlet]="commentLabel"
                        [ngTemplateOutletContext]="{ comment: status.comment }"
                    ></ng-container>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="comment" *ngIf="last && (status.type | caseStatusActions: false).length > 0" fxLayout="row">
                <ng-container [ngTemplateOutlet]="commentFormField"></ng-container>
            </div>
            <div *ngIf="last" class="actions user-context">
                <button
                    *ngFor="let status of status.type | caseStatusActions: false"
                    mat-raised-button
                    color="primary"
                    (click)="
                        onToggleMenu(comment.value, status.warning);
                        updateCaseStatus(status.action, msgCase.message_id, comment.value)
                    "
                >
                    <mat-icon>{{ (status.action | caseStatus).icon }}</mat-icon>
                    <span class="capital-letter">{{ (status.action | caseStatus).action }} </span>
                </button>
            </div>
            <ng-container *ngIf="onEditMode && last">
                <div class="comment" fxLayout="row">
                    <ng-container [ngTemplateOutlet]="commentFormField"></ng-container>
                </div>
                <div class="actions user-context">
                    <button
                        mat-raised-button
                        color="primary"
                        (click)="updateCaseStatus('done', msgCase.message_id, comment.value)"
                    >
                        <mat-icon>{{ ('done' | caseStatus).icon }}</mat-icon>
                        <span class="capital-letter">{{ ('done' | caseStatus).action }} </span>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-template #commentLabel let-comment="comment">
    <div class="commnt-wrap" [innerHTML]="comment"></div>
</ng-template>

<ng-template #smallLoading>
    <div fxLayout="row" fxLayoutAlign="center center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #commentFormField>
    <mat-form-field floatLabel="never" appearance="fill">
        <textarea
            maxlength="5000"
            rows="1"
            i18n-placeholder
            placeholder="Type in your comment"
            matInput
            [formControl]="comment"
        ></textarea>
    </mat-form-field>
</ng-template>
