import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostListener, Output } from '@angular/core';
import { ApplicationService } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { DirectionalityService } from '@klickdata/core/localization';
import { User } from '@klickdata/core/user';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
    selector: 'app-post-panel',
    styleUrls: ['post-panel.component.scss'],
    templateUrl: 'post-panel.component.html',
    animations: [
        trigger('transformPost', [
            state(
                'showing',
                style({
                    opacity: 1,
                    transform: `scale(1)`,
                })
            ),
            transition('void => *', [
                style({
                    opacity: 0,
                    transform: `scale(0)`,
                }),
                animate(`200ms cubic-bezier(0.25, 0.8, 0.25, 1)`),
            ]),
            transition('* => void', [animate('50ms 100ms linear', style({ opacity: 0 }))]),
        ]),
    ],
})
export class PostPanelComponent {
    @Output() exit: EventEmitter<null> = new EventEmitter();
    public version$: Observable<{ version: string; date: string }>;
    public customer$: Observable<Customer>;
    public user$: Observable<User>;

    @HostListener('click')
    public onExit(): void {
        this.exit.emit();
    }
    constructor(
        public directionality: DirectionalityService,
        protected appService: ApplicationService,
        protected auth: AuthService
    ) {
        this.version$ = appService.getVersion();
        this.user$ = auth.getUser().pipe(first());
        this.customer$ = auth.getCustomer().pipe(first());
    }
}
