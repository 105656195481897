<ng-container *ngIf="languages$ | async; let languages; else: loading">
    <button
        [disabled]="disabled"
        (click)="$event.stopPropagation()"
        mat-icon-button
        matTooltip="Translate"
        i18n-matTooltip="@@translate"
        [matMenuTriggerFor]="menu"
    >
        <mat-icon *ngIf="!selectedLang">translate</mat-icon>
        <span *ngIf="selectedLang && selectedLang.flag" class="flag-icon flag-icon-{{ selectedLang.flag }}"></span>
    </button>
    <mat-menu #menu="matMenu">
        <a mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang); selectedLang = lang">
            <span class="flag-icon flag-icon-list flag-icon-{{ lang.flag }}"></span>
            {{ lang.label }}
        </a>
    </mat-menu>
</ng-container>
<ng-template #loading>
    <div fxFlex="0 0 100%" fxLayout="column" fxLayoutAlign="center center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
