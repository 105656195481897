import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'resourceProgress',
})
export class ResourceProgressPipe implements PipeTransform {
    transform(keys: { [key: string]: string | boolean }[]): { color: string; status: number } {
        const mandatoryPer =
            (100 * keys.filter((key) => key.mandatory && key.hasValidValue).length) /
            keys.filter((key) => key.mandatory).length;

        const optionalPer =
            (100 * keys.filter((key) => !key.mandatory && key.hasValidValue).length) /
            keys.filter((key) => !key.mandatory).length;

        if (mandatoryPer < 100) {
            // not-valid
            return { color: '#ff0000', status: -1 };
        } else if (mandatoryPer === 100 && optionalPer === 100) {
            // perfect
            return { color: '#abd5da', status: 2 };
        } else if (mandatoryPer === 100 && optionalPer === 0) {
            // not-optional
            return { color: '#d6d6d6', status: 2 };
        } else if (mandatoryPer === 100 && optionalPer < 100) {
            // semi-optional
            return { color: '#ff9961', status: 1 };
        }
    }
}
