<div *ngIf="navData" fxLayoutGap="10px" class="data-wrap pt-0" fxLayout="column">
    <div class="title mt-0" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon class="title-icon dark-blue {{ navData.data.class }}">{{ navData.data.icon }}</mat-icon>
        <div class="title-label dark-blue {{ navData.data.class }}" fxFlex="grow">{{ navData.data.title }}</div>
    </div>

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
        <app-image-view
            [ngStyle]="{ minWidth: '110px' }"
            [media_id]="navData.data.resource.media"
            paddingBottom="70px"
        ></app-image-view>
        <div fxLayout="column" fxLayoutAlign="center start">
            <span class="dark-blue res-title">{{ navData.data.resource.title }}</span>
            <!-- <div *ngIf="!navData.data.resource.price || navData.data.resource.price === 0" class="price-wrap">
                <div class="dark-blue-color">FREE!</div>
            </div> -->
        </div>
    </div>

    <div
        class="dates"
        *ngIf="navData.data.resource.occasion_dates && navData.data.resource.occasion_dates?.length == 1"
    >
        <mat-icon class="date-icon">today</mat-icon>
        <span>{{ navData.data.resource.occasion_dates[0].start_date | kdDateFormat : 'MMM DD, YYYY HH:mm' }}</span>
        <span>-</span>
        <span>{{ navData.data.resource.occasion_dates[0].end_date | kdDateFormat : 'MMM DD, YYYY HH:mm' }}</span>
        <span>{{ navData.data.resource.time_zone }}</span>
    </div>
    <div
        fxLayoutAlign="start start"
        fxLayoutGap="5px"
        fxLayout="column"
        *ngIf="navData.data.resource.occasion_dates?.length > 1"
    >
        <span i18n>Please select joining date</span>
        <mat-radio-group
            fxLayout="column"
            [formControl]="eventOccasionId"
            class="item"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
        >
            <mat-radio-button
                [disabled]="occ.selected || !occ.isValid"
                class="dates"
                *ngFor="let occ of navData.data.resource.occasion_dates"
                [value]="occ.id"
            >
                <mat-icon class="date-icon">today</mat-icon>
                <span>{{ occ.start_date | kdDateFormat : 'MMM DD, YYYY HH:mm' }}</span>
                <span>-</span>
                <span>{{ occ.end_date | kdDateFormat : 'MMM DD, YYYY HH:mm' }}</span>
                <span>{{ navData.data.resource.time_zone }}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="desc">{{ navData.data.contentBody }}</div>
    <div *ngIf="!!navData.data.inputDesc" class="input-field-parent" fxLayout="column" fxLayoutGap="5px">
        <span>{{ navData.data.inputDesc }}</span>
        <mat-form-field cdkTrapFocus appearance="outline" floatLabel="never" class="w-100" fxFlex="grow">
            <textarea
                cdkFocusInitial
                #taskInput
                matInput
                type="text"
                [formControl]="requestValue"
                placeholder="Message to the admin"
                i18n-placeholder
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="3"
                cdkAutosizeMaxRows="50"
                required
            ></textarea>
        </mat-form-field>
    </div>
</div>
<ng-container [ngTemplateOutlet]="actions"></ng-container>
<ng-template #actions>
    <div
        *ngIf="!!navData.data.actions?.length"
        class="action"
        fxLayout="row-reverse"
        fxLayoutAlign="start center"
        fxLayoutGap="10px"
    >
        <ng-container *ngFor="let action of navData.data.actions">
            <button
                [disabled]="
                    action.value == 1 && navData.data.resource.occasion_dates?.length > 1 && !eventOccasionId.value
                "
                fxFlex="0 0 auto"
                class="mobile-button positive {{ action.class }}"
                (click)="
                    onClose.emit(
                        action.value
                            ? {
                                  data: {
                                      type: navData.data.type,
                                      response: action.value,
                                      requestValue: requestValue.value,
                                      eventOccasionId: eventOccasionId.value
                                  }
                              }
                            : null
                    )
                "
                mat-button
                i18n
            >
                {{ action.label }}
            </button>
        </ng-container>
    </div>
</ng-template>
