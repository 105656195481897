<div class="res-listing-wrapper user-gen-listing action-log-users" fxLayout="column" fxFlex="0 0 100%">
    <app-table-toolbar class="toolbar" color="accent">
        <mat-accordion fxFlex="0 0 100%" displayMode="flat">
            <mat-expansion-panel [expanded]="true" #expansionPanel>
                <mat-expansion-panel-header> </mat-expansion-panel-header>
                <app-table-filter
                    [cacheScope]="AppScope.USERS"
                    [toggleFilterButtons]="true"
                    [inGlobalFilter]="true"
                    color="accent"
                    #filter
                >
                    <app-table-search
                        toggleBtnLabel="Name"
                        i18n-toggleBtnLabel
                        [inGlobalFilter]="true"
                        class="align-space"
                        searchFilterRef
                    ></app-table-search>
                    <app-select-filter
                        [inGlobalFilter]="true"
                        class="filter-space"
                        label="All Time"
                        i18n-label
                        [property]="GlobalFilterProperty.TIME_SPENT"
                        [options]="predefinedTimeSpentOptions"
                        fixedTimeSpentFilterRef
                    >
                    </app-select-filter>
                    <app-select-filter
                        [inGlobalFilter]="true"
                        class="filter-space"
                        label="Academy Roles"
                        i18n-label="@@academyRoles"
                        [property]="GlobalFilterProperty.USER_ROLE"
                        [options]="userRoleOptions"
                        userRolesRef
                    >
                    </app-select-filter>
                    <ng-container *ngIf="isMasterPlatform$ | async; let isMasterPlatform">
                        <app-customer-filter
                            *ngIf="isMasterPlatform && !customer"
                            [inGlobalFilter]="true"
                            class="customer-select"
                            fxFlex="1 1 auto"
                            customerSearchRef
                        ></app-customer-filter>
                    </ng-container>
                </app-table-filter>
            </mat-expansion-panel>
        </mat-accordion>

        <mat-progress-bar
            class="progress progress-table-loader"
            *ngIf="(dataSource.status | async) === 'loading'"
            mode="indeterminate"
        >
        </mat-progress-bar>
    </app-table-toolbar>

    <div class="table-wrapper">
        <mat-table
            #table
            [dataSource]="dataSource"
            matSortActive="last_action"
            matSortDirection="desc"
            matSort
            [class.mobileView]="isMobile"
            multiTemplateDataRows
        >
            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name"> Name </mat-header-cell>
                <mat-cell *matCellDef="let user">
                    <app-image-view
                        [ngStyle]="{ minWidth: '50px' }"
                        [isCircularImg]="true"
                        [media_id]="user.media"
                        paddingBottom="50px"
                    ></app-image-view>
                    <span
                        [ngStyle]="{
                            color:
                                user.role_value === 'superadmin'
                                    ? '#ff9961'
                                    : user.role_value === 'user'
                                    ? '#3e5365'
                                    : '#a6c2b7'
                        }"
                        class="grow-1"
                    >
                        {{ user.name }}
                    </span>
                    <button
                        *ngIf="isMobile"
                        class="expand-btn-cell"
                        mat-icon-button
                        (click)="
                            $event.stopPropagation();
                            expandedRow = null;
                            expandedCell = expandedCell === user ? null : user
                        "
                    >
                        <mat-icon>
                            {{ expandedCell === user ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="last_action">
                <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@lastAction"> Last action </mat-header-cell>
                <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === user }" *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@lastAction"> Last action</span>
                    <span *ngIf="user.last_login">{{ user.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef i18n="@@role"> Role </mat-header-cell>
                <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === user }" *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@role"> Role</span>
                    <div
                        (click)="$event.stopPropagation(); onItemClick(GlobalFilterProperty.USER_ROLE, user.role_value)"
                        fxLayout="row"
                        fxLayoutGap="5px"
                        fxLayoutAlign="start center"
                        class="pointer"
                    >
                        <mat-icon>{{ user.roleValueSpecs.icon }}</mat-icon>
                        <span class="orange">{{ user.roleValueSpecs.title }}</span>
                    </div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="cases_count">
                <mat-header-cell i18n="@@cases" *matHeaderCellDef mat-sort-header> Cases </mat-header-cell>
                <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === user }" *matCellDef="let user">
                    <span i18n="@@cases" *ngIf="isMobile" class="mobile-label"> Cases</span>
                    <span *ngIf="user.cases_count || user.author_cases_count">{{
                        user.cases_count + user.author_cases_count
                    }}</span
                    ><span class="na" *ngIf="!user.cases_count && !user.author_cases_count">NA</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="started_courses_count">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@startedCourses">Started courses</span>
                </mat-header-cell>
                <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === user }" *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@startedCourses">Started courses</span
                    ><span *ngIf="user.started_courses_count">{{ user.started_courses_count }}</span>
                    <span class="na" *ngIf="!user.started_courses_count">NA</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="completed_courses_count">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@completedCourses">Completed courses</span>
                </mat-header-cell>
                <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === user }" *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@completedCourses">Completed courses</span
                    ><span *ngIf="user.completed_courses_count">{{ user.completed_courses_count }}</span>
                    <span class="na" *ngIf="!user.completed_courses_count">NA</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="completed_tests_count">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span i18n="@@completedTests">Completed tests</span>
                </mat-header-cell>
                <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === user }" *matCellDef="let user">
                    <span *ngIf="isMobile" class="mobile-label" i18n="@@completedTests">Completed tests</span
                    ><span *ngIf="user.completed_tests_count">{{ user.completed_tests_count }}</span
                    ><span class="na" *ngIf="!user.completed_tests_count">NA</span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tools">
                <mat-header-cell *matHeaderCellDef i18n="@@tools"> Tools </mat-header-cell>
                <mat-cell [ngClass]="{ 'main-rows-expanded': expandedCell === user }" *matCellDef="let user">
                    <button
                        *ngIf="user.fname"
                        class="tools-btn"
                        mat-icon-button
                        [matTooltip]="'Send message to ' + user.name"
                        (click)="$event.stopPropagation(); sendEmail(user)"
                        i18n-matTooltip
                    >
                        <mat-icon>speaker_notes</mat-icon>
                    </button>

                    <button
                        class="tools-btn orange"
                        mat-icon-button
                        (click)="$event.stopPropagation(); viewUserResults(user)"
                        [matTooltip]="'Check ' + user.name + ' progress'"
                        i18n-matTooltip
                    >
                        <mat-icon>insights</mat-icon>
                    </button>

                    <button
                        class="tools-btn orange"
                        mat-icon-button
                        (click)="$event.stopPropagation(); noteUser(user)"
                        matTooltip="Add note"
                        i18n-matTooltip
                    >
                        <mat-icon class="material-icons-outlined">note_alt</mat-icon>
                    </button>
                    <button
                        (click)="$event.stopPropagation(); expandedRow = expandedRow === user ? null : user"
                        matTooltip="View"
                        mat-icon-button
                        i18n-matTooltip="@@view"
                        class="expand-btn"
                    >
                        <mat-icon>{{ expandedRow === user ? 'expand_less' : 'expand_more' }}</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <mat-cell *matCellDef="let user" [attr.colspan]="columns.length">
                    <div
                        class="example-element-detail"
                        [@detailExpand]="user == expandedRow ? 'expanded' : 'collapsed'"
                    >
                        <app-shared-log-users-tabs
                            *ngIf="expandedRow === user"
                            [customer]="customer"
                            [user]="user"
                            [isMobile]="isMobile"
                        ></app-shared-log-users-tabs>
                    </div>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row
                *matRowDef="let user; columns: columns"
                [class.example-expanded-row]="expandedRow === user"
                class="example-element-row"
                (click)="!isMobile && (expandedRow = expandedRow === user ? null : user)"
            ></mat-row>
            <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
        </mat-table>
    </div>
</div>
<mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #tableCellActionBtns let-user="user"> </ng-template>
