<div class="user-select-wrapper user-list-access-control" fxLayout="column" fxFlex="0 0 100%">
    <app-table-filter [filtersTogglesInOnBtn]="true" [inGlobalFilter]="true" color="accent" #filter>
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-tag-chip-search-filter
            [inGlobalFilter]="true"
            [languageChange]="langFilter.filterChange"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            fxLayoutAlign="start"
            tagsFilterRef
        ></app-tag-chip-search-filter>
        <app-category-chip-search-filter
            [inGlobalFilter]="true"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            categoriesFilterRef
        ></app-category-chip-search-filter>
        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Type"
            i18n-label="@@type"
            [property]="{
                name: GlobalFilterProperty.SCOPE,
                type: GlobalFilterPropertyType.RESOURCE_TYPE
            }"
            [options]="resourceTypeOptions"
            scopesFilterRef
        >
        </app-select-filter>
        <app-language-filter
            [inGlobalFilter]="true"
            #langFilter
            fxFlex="0 0 auto"
            fxLayout="row"
            langFilterRef
        ></app-language-filter>
    </app-table-filter>
    <mat-progress-bar
        class="progress progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>
    <mat-table
        #table
        [dataSource]="dataSource"
        matSortActive="title"
        matSortDirection="desc"
        matSort
        [class.mobileView]="isMobile"
        [ngClass]="{ sv: localeId == 'sv' }"
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="selectToggle(resource, $event.checked); resource.some_resource_perm_checked = false"
                    [checked]="resource.all_resource_perm_checked"
                    [indeterminate]="resource.some_resource_perm_checked"
                    [disabled]="resource.disabled"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span i18n="@@title" class="mobile-label"> Title </span>
                <span>{{ resource.title }}</span></mat-cell
            >
        </ng-container>
        <ng-container matColumnDef="permissions">
            <mat-header-cell *matHeaderCellDef>
                <div class="perm" fxLayout="row" fxLayoutAlign="space-between center">
                    <div
                        class="{{ (perm.value | resourcePermissionPipe).class }}"
                        *ngFor="let perm of resourcePermissions"
                    >
                        <span [matTooltip]="perm.tooltip">{{ perm.label }}</span>
                    </div>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span class="mobile-label perm">
                    <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                        <div
                            class="{{ (perm.value | resourcePermissionPipe).class }}"
                            *ngFor="let perm of resourcePermissions"
                        >
                            <span [matTooltip]="perm.tooltip">{{ perm.label }}</span>
                        </div>
                    </div>
                </span>
                <app-user-resources-permissions-handler
                    #resourcePermHandler
                    *ngIf="resourcePermissionsArray"
                    [permissionsArray]="resourcePermissionsArray"
                    [resource]="resource"
                    [permissions]="resource.user_permissions"
                    [resourcePermissions]="resourcePermissions"
                ></app-user-resources-permissions-handler>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let resource; columns: columns" [class.disable-row]="resource.disabled"></mat-row>
    </mat-table>

    <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
</div>
