import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AuthService, CapabilityService, GeneralCapability } from '@klickdata/core/auth';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Injectable()
export class ExitService implements OnDestroy {
    protected referrer: string;
    protected data: { [key: string]: string };
    protected destroy: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected route: ActivatedRoute,
        protected router: Router,
        protected capability: CapabilityService,
        protected token: AuthService
    ) {
        this.route.queryParamMap.pipe(takeUntil(this.destroy)).subscribe((params: ParamMap) => {
            if (params.has('referrer')) {
                this.setReferrer(params.get('referrer'));
            }
            if (params.has('data')) {
                this.setData(params.get('data'));
            }
        });
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public exit(): void {
        this.capability
            .check(GeneralCapability.getName(), GeneralCapability.VIEW_KLICK_DATA)
            .pipe(first())
            .subscribe((canViewHome) => {
                if (!canViewHome) {
                    this.token.destroyToken();
                }

                if (this.referrer) {
                    this.router.navigate([this.referrer]);
                } else {
                    this.router.navigate(['home']);
                }
            });
    }
    public exitToAUActivity(resourceId?: number) {
        this.router.navigate(['/home/dashboard/main'], {
            queryParams: resourceId ? { expand: resourceId } : {},
        });
    }

    public setReferrer(referrer: string): void {
        this.referrer = referrer;
    }
    public getReferrer(): string {
        return this.referrer;
    }
    public setData(data: {}): void {
        this.data = data;
    }
    public getData(): {} {
        return this.data;
    }
}
