<mat-tab-group *ngIf="!(isMobile | async)" mat-stretch-tabs ngClass.xs="xs">
    <mat-tab>
        <ng-template mat-tab-label i18n> Library </ng-template>
        <ng-template matTabContent>
            <app-resource-library
                [selection]="selection"
                [type_scope_id]="AppScope.MATERIAL"
                (select)="select.emit($event)"
            ></app-resource-library>
        </ng-template>
    </mat-tab>
    <mat-tab #link *ngIf="isAdmin">
        <ng-template mat-tab-label i18n> Add link </ng-template>
        <app-add-material-link
            [active]="link.isActive"
            [publishOnly]="true"
            (saved)="select.emit($event.res)"
        ></app-add-material-link>
    </mat-tab>
    <mat-tab #file *ngIf="isAdmin">
        <ng-template mat-tab-label i18n> Upload file </ng-template>
        <app-upload-material-file
            [active]="file.isActive"
            [publishOnly]="true"
            (saved)="select.emit($event.res)"
        ></app-upload-material-file>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label i18n> My material </ng-template>
        <ng-template matTabContent>
            <app-resource-library
                [type_scope_id]="AppScope.MATERIAL"
                context="author"
                [selection]="selection"
                (select)="select.emit($event)"
            ></app-resource-library>
        </ng-template>
    </mat-tab>

    <mat-tab #text *ngIf="isAdmin">
        <ng-template mat-tab-label i18n> Textmaterial </ng-template>
        <app-add-material-text
            [active]="text.isActive"
            [publishOnly]="true"
            (saved)="select.emit($event.res)"
        ></app-add-material-text>
    </mat-tab>
</mat-tab-group>
<app-generic-mobile-tabs
    fxFlex="100"
    [isNavTab]="false"
    *ngIf="isMobile | async"
    wrapperClass="activites-accordion-list"
    [options]="activityOptions"
>
    <ng-template>
        <app-add-material-link [publishOnly]="true" (saved)="select.emit($event.res)"></app-add-material-link>
    </ng-template>
    <ng-template>
        <app-upload-material-file [publishOnly]="true" (saved)="select.emit($event.res)"></app-upload-material-file>
    </ng-template>
    <ng-template>
        <app-resource-library
            [type_scope_id]="AppScope.MATERIAL"
            context="author"
            [selection]="selection"
            (select)="select.emit($event)"
        ></app-resource-library>
    </ng-template>
    <ng-template>
        <app-resource-library
            [selection]="selection"
            [type_scope_id]="AppScope.MATERIAL"
            (select)="select.emit($event)"
        ></app-resource-library>
    </ng-template>
    <ng-template>
        <app-add-material-text [publishOnly]="true" (saved)="select.emit($event.res)"></app-add-material-text>
    </ng-template>
</app-generic-mobile-tabs>
