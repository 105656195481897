import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, Resource } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    GlobalFilterPropertyType,
    SelectFilterOption,
    StatusFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { Observable, Subject } from 'rxjs';
import { UsersResourcesService } from './users-resources.service';
import { Customer } from '@klickdata/core/customer';

@Component({
    selector: 'app-shared-log-users-tabs-resources',
    templateUrl: './shared-log-users-tabs-resources.component.html',
    styleUrls: ['./shared-log-users-tabs-resources.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UsersResourcesService],
})
export class SharedLogUsersTabsResourcesComponent implements OnInit, AfterViewInit {
    @Input() user: User;
    @Input() customer: Customer;
    @Input() scope: AppScope;
    @Input() isMobile: boolean;
    AppScope = AppScope;
    public dataSource = new TableSource<Resource>();
    public predefinedTimeSpentOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public resourceStatusOptions: StatusFilterOption[];
    public resourceEventOptions: SelectFilterOption[];
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    public columns = ['last_action', 'image', 'title', 'event', 'deadline', 'started_at', 'language_id'];
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;

    constructor(protected resourceService: UsersResourcesService) {
        this.predefinedTimeSpentOptions = Utils.getPredefinedTimeSpentOptions();
        this.resourceEventOptions = Utils.getActionLogEventOptions().filter((option) => option.data.isResRelated);
    }

    ngOnInit(): void {
        this.dataSource.service = this.resourceService;
    }
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        if (this.filter) {
            if (this.customer) {
                this.filter.createOrUpdateWithoutEmitFilter([
                    new Filter('scope', [this.scope]),
                    new Filter('customer', [this.customer.id]),
                ]);
            } else if (this.user) {
                this.filter.createOrUpdateWithoutEmitFilter([
                    new Filter('scope', [this.scope]),
                    new Filter('user', [this.user.id]),
                ]);
            }
        }
    }
}
