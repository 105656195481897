import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService, CanComponentDeactivate } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { QuestionTypes } from '@klickdata/core/question';
import {
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceTypes,
    AppScope,
} from '@klickdata/core/resource';
import { ResourceItemTypes } from '@klickdata/core/resource-item';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import * as moment from 'moment';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { ResourceBuilderComponent } from '../resource-builder/resource-builder.component';
import { CreateResourceBaseDirective } from '../resource-create/create-resource-base.directive';

@Component({
    selector: 'app-create-test',
    templateUrl: './create-test.component.html',
    styleUrls: ['./create-test.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTestComponent
    extends CreateResourceBaseDirective
    implements OnInit, AfterViewInit, OnDestroy, OnChanges, CanComponentDeactivate
{
    @Input() resource: Resource;
    @ViewChild(ResourceBuilderComponent) resourceBuilder: ResourceBuilderComponent;
    @Output() saved: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() published: EventEmitter<{ res: Resource; isParentSubmission?: boolean }> = new EventEmitter<{
        res: Resource;
        isParentSubmission?: boolean;
    }>();
    @Output() onError: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() bottomToolSheet: boolean;
    public questionTypes = [
        QuestionTypes.CHECKBOX,
        QuestionTypes.INPUT,
        QuestionTypes.RADIO,
        QuestionTypes.WOK,
        QuestionTypes.AI,
    ];
    public testForm: FormGroup;
    public formats: { [key: string]: string };
    ResourceTypes = ResourceTypes;
    @Input() publishOnly: boolean;
    @Input() publishLabel = $localize`Publish`;
    private publish: boolean;
    public isAdmin$: Observable<boolean>;
    public ResourceItemTypes = ResourceItemTypes;
    public savingQuestions: boolean;
    AppScope = AppScope;
    private isParentSubmission: boolean;

    constructor(
        protected fb: FormBuilder,
        protected auth: AuthService,
        protected route: ActivatedRoute,
        protected categoryService: ResourceCategoryService,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected cdRef: ChangeDetectorRef,
        protected dialog: MatDialog
    ) {
        super(messageService, resourceService, cdRef, dialog);
        this.buildForm();
        this.bottomToolSheet = this.bottomToolSheet || this.route.snapshot.data.bottomToolSheet;
    }

    ngOnInit() {
        this.isAdmin$ = this.auth.getUser().pipe(
            first(),
            map((user) => user.isAdmin())
        );
    }

    ngOnChanges() {
        if (this.resource) {
            this.update();
        }
    }

    public createQs() {
        const type = QuestionTypes.RADIO;
        this.resourceBuilder.getService().createItem({
            item_type_value: ResourceItemTypes.QUESTION,
            questionForm: this.resourceBuilder.getQuestionService().createEmptyFormGroup(type, 3, true),
        });
        // hide WOK generator.
        this.resourceBuilder.getService().showWOKGenerator.next(false);
        this.resourceBuilder.getService().selectedWOKQuestionType.next(null);
    }

    public update() {
        this.testForm.patchValue({
            id: this.resource.id,
            priority: this.resource.priority,
            media_id: this.resource.media_id,
            title: this.resource.title,
            description: this.resource.description,
            grade_system_id: this.resource.grade_system_id,
            // category_ids: this.resource.category_ids,
            language_id: this.resource.language_id,
        });
        this.cdRef.markForCheck();
        FormHelper.resetForm(this.testForm);
    }

    ngAfterViewInit() {
        this.resourceBuilder
            .getResource()
            .pipe(
                filter((resource) => !!resource),
                takeUntil(this.destroy)
            )
            .subscribe((resource) => this.updateForm(resource));
    }

    updateForm(resource: Resource): void {
        this.testForm.patchValue({
            title: this.testForm.value.title ? this.testForm.value.title : resource.title,
        });
        FormHelper.resetForm(this.testForm);
    }

    public buildForm() {
        this.testForm = this.fb.group({
            id: [],
            priority: [],
            media_id: [],
            title: ['', Validators.required],
            description: [''],
            grade_system_id: ['', Validators.required],
            tag_ids: [[]],
            category_ids: [[], Validators.required],
            language_id: ['', Validators.required],
        });

        this.formats = {
            title: $localize`:@@title:Title`,
            description: $localize`:@@description:Description`,
            grade_system_id: $localize`:@@gradeSystem:Grade system`,
            categories: $localize`:@@categories:Categories`,
        };
    }
    public submitTest() {
        this.isParentSubmission = true;
        this.onSubmit(true);
    }

    /**
     * Save/Publish button action.
     */
    public onSubmit(publish?: boolean) {
        this.publish = publish;
        const itemsReady = this.resourceBuilder.getService().readyForSubmit();
        if (!itemsReady) {
            if (publish) {
                this.messageService.openMessage(MessageErrorComponent, $localize`Questions is required!`);
                this.onError.emit(true);
            } else if (this.testForm.dirty) {
                this.submitWithQuestion();
            }
        } else {
            this.savingQuestions = true;
            this.resourceBuilder.getService().submit();
        }
    }

    /**
     * Submit test after question saved event emitted from RB!
     */
    public submitWithQuestion() {
        this.savingQuestions = false;
        if (this.testForm.invalid) {
            FormHelper.markForm(this.testForm);
            const inValidsKeys = FormHelper.formatInvalidForm(this.testForm, this.formats);
            this.messageService.openMessage(
                MessageErrorComponent,
                inValidsKeys.length < 2 ? $localize`${inValidsKeys} is missing` : $localize`${inValidsKeys} are missing`
            );
            this.setLoading(false);
        } else {
            this.setLoading(true);
            this.prepareForm()
                .pipe(
                    takeUntil(this.destroy),
                    switchMap((data) =>
                        !data.id ? this.resourceService.store(data) : this.resourceService.update(data)
                    )
                )
                .subscribe((resource) => {
                    this.setLoading(false);
                    this.onSaved(resource);
                    this.cdRef.markForCheck();
                });
        }
    }

    private onSaved(resource: Resource) {
        FormHelper.resetForm(this.testForm);
        this.messageService.openMessage(MessageSavedComponent);

        if (this.publish) {
            this.published.emit({ res: resource, isParentSubmission: this.isParentSubmission });
            this.isParentSubmission = false;
        } else {
            this.saved.emit(resource);
        }
    }

    private setLoading(loading: boolean) {
        this.resourceBuilder.getService().setLoading(loading);
        this.saving.emit(loading);
    }

    public prepareForm(): Observable<ResourceData> {
        return combineLatest([this.auth.getUser(), this.resourceBuilder.getResource()]).pipe(
            first(),
            map(([user, resource]) => {
                const data = resource
                    ? resource.getData(this.testForm.value)
                    : new Resource(this.testForm.value).getData();

                if (!data.id) {
                    data.type_id = ResourceTypes.GeneralTest; // Defines that this resource is of type general_test.
                    data.customer_id = user.customer_id;
                    data.author_id = user.id;
                }
                if (this.publish) {
                    data.published = moment().format('YYYY-MM-DD HH:mm:ss');
                }
                if (!this.publish && data) {
                    data.last_publish = null;
                }
                return new Resource(data).getData();
            })
        );
    }
    public showImgModal(id: number) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '70%',
            maxHeight: '50vh',
            data: id,
        });
    }
    public canDeactivate(): boolean {
        return this.testForm.pristine;
    }

    @HostListener('window:beforeunload', ['$event'])
    handleClose($event) {
        $event.returnValue = this.canDeactivate();
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
