import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { LanguageService } from '@klickdata/core/localization';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { SideNaveActionsTypes, SideNaveData, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Resource, ResourceData, ResourceService, ResourceTypes } from '@klickdata/core/resource';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-create-resource',
    templateUrl: './menu-side-create-resource.component.html',
    styleUrls: ['./menu-side-create-resource.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideCreateResourceComponent implements OnDestroy, OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public resourceForm: FormGroup;
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    public currentLaguageId$: Observable<number>;
    private destroy: Subject<boolean> = new Subject<boolean>();
    ResourceTypes = ResourceTypes;

    constructor(
        protected fb: FormBuilder,
        protected resourceService: ResourceService,
        protected langService: LanguageService,
        protected messageService: MessageService,
        protected auth: AuthService
    ) {
        this.resourceForm = this.fb.group({
            title: [''],
            bullets: [''],
            tag_ids: [[]],
        });
    }
    ngOnInit(): void {
        this.currentLaguageId$ = this.langService
            .getLanguageByKey(this.langService.getCurrentLanguage().value)
            .pipe(map((lang) => lang.id));
    }

    public submit() {
        if (!this.resourceForm.value.title || !this.resourceForm.value.bullets) {
            return;
        }
        this.saving.emit(true);
        this.prepareForm()
            .pipe(
                switchMap((data) => this.resourceService.store(data)),
                takeUntil(this.destroy)
            )
            .subscribe((resource) => {
                this.saving.emit(false);
                this.messageService.openMessage(
                    MessageSavedComponent,
                    $localize`Course ${this.resourceForm.value.title} created successfully.`
                );
                this.onClose.emit({
                    action: SideNaveActionsTypes.POSITIVE,
                    data: { value: resource, type: this.navData.data.type },
                });
                this.resourceForm.reset();
            });
    }
    private prepareForm(): Observable<ResourceData> {
        return this.auth.getUser().pipe(
            map((user) => {
                const data = new Resource(this.resourceForm.value).getData();
                data.resource_items = this.navData.data.items;
                data.type_id = this.navData.data.typeId;
                data.customer_id = user.customer_id;
                data.author_id = user.id;
                return data;
            })
        );
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
