<ng-container *ngIf="user$ | async; let user">
    <button
        [disabled]="isSafari"
        (click)="startRecorder(user.canRecordUnlimited())"
        type="button"
        class="screen-record"
        mat-menu-item
    >
        <mat-icon>fit_screen</mat-icon>
        <span i18n>Record screen with video</span>
    </button>
</ng-container>
