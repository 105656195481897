import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { SideNaveActionsTypes, SideNaveData, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { ResourceData, ResourceTypes } from '@klickdata/core/resource';
import { VTQService } from '@klickdata/core/vtq';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-video-to-quiz',
    templateUrl: './menu-side-video-to-quiz.component.html',
    styleUrls: ['./menu-side-video-to-quiz.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideVideoToQuizComponent implements OnDestroy, OnChanges {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public destroy: Subject<boolean> = new Subject<boolean>();
    public loading: Subject<boolean> = new Subject<boolean>();
    SideNaveActionsTypes = SideNaveActionsTypes;
    public form: FormGroup;
    public relatedTests: BehaviorSubject<ResourceData[]> = new BehaviorSubject<ResourceData[]>([]);

    constructor(
        protected fb: FormBuilder,
        protected vTQService: VTQService,
        protected auth: AuthService,
        protected messageService: MessageService,
        protected router: Router
    ) {
        this.form = fb.group({
            question_count: [10, Validators.required],
            difficulty_level: [3, Validators.required],
            create_course: [false],
            question_type_value: [],
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.navData?.data?.resource?.related_resources?.length) {
            this.relatedTests.next(
                this.navData.data.resource.related_resources.filter((res) => res.type_id === ResourceTypes.GeneralTest)
            );
        }
    }
    updateForm(ev: {
        question_count: number;
        difficulty_level: number;
        create_course: boolean;
        question_type_value: any;
    }) {
        this.form.patchValue({
            question_count: ev.question_count,
            difficulty_level: ev.difficulty_level,
            create_course: ev.create_course,
            question_type_value: ev.question_type_value,
        });
    }
    onTestClick(id: number) {
        this.onClose.emit();
        this.router.navigate(['/home/dashboard/resource-details/' + id]);
    }
    submitForm() {
        this.loading.next(true);
        const vTQ = this.navData.data.vTQ;
        if (this.navData.data.resource && this.navData.data.resource.id && this.form.value.create_course) {
            vTQ.material_id = this.navData.data.resource.id;
        }
        vTQ.question_count = this.form.value.question_count;
        vTQ.create_course = this.form.value.create_course;
        vTQ.difficulty_level = this.form.value.difficulty_level;
        vTQ.question_type_value = this.form.value.question_type_value;
        this.vTQService
            .create(vTQ)
            .pipe(takeUntil(this.destroy))
            .subscribe(
                () => {
                    this.loading.next(false);
                    this.messageService.openMessage(
                        MessageSavedComponent,
                        $localize`Test creation process takes a few minutes, You will be notified when test is created.`
                    );
                    this.vTQService.setActiveVTQ(null);
                    this.onClose.emit({ action: SideNaveActionsTypes.POSITIVE, clearCompData: true });
                },
                (err) => {
                    this.loading.next(false);
                    this.vTQService.setActiveVTQ(null);
                    this.onClose.emit();
                    // this.messageService.openMessage(MessageErrorComponent, $localize`Test not created`);
                }
            );
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
