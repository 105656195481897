import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { ApplicationModule } from '@klickdata/core/application';
import { CanAccessDirective } from '@klickdata/core/auth/src/capabilities/can-access.directive';
import { ConfigModule } from '@klickdata/core/config';
import { HttpModule } from '@klickdata/core/http';
import { KdLoaderModule } from '@klickdata/kd-loader';
import { OccasionAuthComponent } from './occasion-auth/occasion-auth.component';

@NgModule({
    imports: [ApplicationModule, CommonModule, HttpModule, ConfigModule, KdLoaderModule],
    providers: [JwtModule],
    declarations: [CanAccessDirective, OccasionAuthComponent],
    exports: [CanAccessDirective],
})
export class AuthModule {}
