import { AccessCapability } from '../can-access.directive';

export namespace ServicesAccessCapability {
    export const ACCESS_COURSE_PLANS = 1 << 0;
    export const ACCESS_COURSES = 1 << 1;
    export const ACCESS_MATERIALS = 1 << 2;
    export const ACCESS_EXAMS = 1 << 3;
    export const ACCESS_SETTINGS = 1 << 4;
    export const ACCESS_STATISTICS = 1 << 5;
    export const ACCESS_SURVEYS = 1 << 6;
    export const ACCESS_FOLDERS = 1 << 7;
    export const ACCESS_CONTENT = 1 << 8;
    export const ACCESS_ACCOUNTS = 1 << 9;
    // export const ACCESS_ADD_MATERIAL = 1 << 9;
    export function getName(): string {
        return 'access';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
