import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlI18nService extends MatPaginatorIntl {
    constructor() {
        super();
        const ofstr = $localize`:@@of:of`;
        /** A label for the range of items within the current page and the length of the whole list. */
        this.getRangeLabel = (page: number, pageSize: number, length: number) => {
            if (length === 0 || pageSize === 0) {
                return `0 ${ofstr} ${length}`;
            }

            length = Math.max(length, 0);

            const startIndex = page * pageSize;

            // If the start index exceeds the list length, do not try and fix the end index to the end.
            const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

            return `${startIndex + 1} – ${endIndex} ${ofstr} ${length}`;
        };

        this.itemsPerPageLabel = $localize`:@@itemsPerPage:Items per page:`;
        this.nextPageLabel = $localize`:@@nextPage:Next page`;
        this.previousPageLabel = $localize`:@@previousPage:Previous page`;
    }
}
