import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerServiceModel } from './customer-service.model';
import { RequestBuilderService } from '@klickdata/core/http';
import { ConfigService } from '@klickdata/core/config';

@Injectable({
    providedIn: 'root',
})
export class CustomerServiceService {
    protected resourceUrl: string;

    constructor(protected builder: RequestBuilderService, protected config: ConfigService) {
        this.resourceUrl = `${this.config.config.apiUrl}services`;
    }

    getServices(customer_id?: number): Observable<CustomerServiceModel[]> {
        const builder = this.builder.get<CustomerServiceModel[]>(this.resourceUrl);
        if (customer_id) {
            builder.param('customer', customer_id);
        }
        return builder.request().pipe(map(res => res.data));
    }

    getService(id: string): Observable<CustomerServiceModel> {
        return this.builder
            .get<CustomerServiceModel>(`${this.resourceUrl}/${id}`)
            .request()
            .pipe(map(res => res.data));
    }
}
