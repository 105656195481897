<div class="w-100 mt-2 mb-2" fxLayout="column" fxLayoutAlign="center center">
    <div class="main-eval-wrapper">
        <span class="vertical-label" i18n>Assessments</span>
        <div class="horizontal-line"></div>
        <div class="vertical-line"></div>
        <span class="horizontal-label" i18n>Value</span>
        <div class="evaluation-wrapper">
            <div
                [style.width.%]="(criterion.value / 10) * 100"
                [style.background]="criterion.color | colorsCodesByNames"
                class="evaluation-items {{ criterion.color }}"
                *ngFor="let criterion of evaluation.criteria"
            >
                <span>{{ criterion.label }}</span>
                <span>{{ criterion.value }}</span>
            </div>
        </div>
    </div>
    <div class="feedback">
        <div fxLayout="row" fxLayoutAlign="start center">
            <h4>{{ evaluation.title }}</h4>
        </div>
        <p>{{ evaluation.body }}</p>
    </div>
    <div class="eval-info">
        <span i18n *ngIf="evaluation.evaluator_id == authUser?.id"> You </span>
        <span *ngIf="evaluation.evaluator_id != authUser?.id">
            {{ evaluation.name }}
        </span>

        <span i18n>added evaluation on </span>
        <span>
            {{ evaluation.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}
        </span>
    </div>
</div>
