import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { LanguageService } from '@klickdata/core/localization';
import { Notification, NotificationService, Ticket } from '@klickdata/core/notification';
import { TicketService } from '@klickdata/core/notification/src/ticket.service';
import { AppScope, Resource, ResourceService } from '@klickdata/core/resource';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-message-ticket',
    templateUrl: './message-ticket.component.html',
    styleUrls: ['./message-ticket.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageTicketComponent implements OnInit, OnDestroy, OnChanges {
    public ticket$: Observable<Ticket>;
    @Input() msg: Notification;
    public contents: Resource[];
    private destroy: Subject<boolean> = new Subject<boolean>();
    public loading$: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected resourceService: ResourceService,
        protected languageService: LanguageService,
        protected authService: AuthService,
        protected notificationService: NotificationService,
        protected changeRef: ChangeDetectorRef,
        protected ticketService: TicketService
    ) {}

    ngOnInit(): void {
        if (this.msg) {
            this.loading$.next(true);
            this.ticket$ = this.ticketService.getTicketByMsgId(this.msg.id).pipe(shareReplay());
            combineLatest([
                this.ticket$.pipe(map((ticket) => ticket.resource_ids)),
                this.authService.getUser().pipe(take(1)),
                this.languageService.getContentLanguage(),
            ])
                .pipe(
                    switchMap(([ids, user, lang]) =>
                        ids?.length
                            ? this.resourceService.getResources(ids, null, 10, null, [
                                  { property: 'availableForCustomer', items: [user.customer_id] },
                                  { property: 'scope', items: [AppScope.MATERIAL] },
                                  { property: 'language', items: [lang.id] },
                              ])
                            : of([])
                    ),
                    takeUntil(this.destroy)
                )
                .subscribe((res) => {
                    this.loading$.next(false);
                    this.contents = res;
                    this.changeRef.markForCheck();
                });
            this.changeRef.markForCheck();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.changeRef.markForCheck();
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
    dateUpdated() {}
}
