import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnDestroy,
    Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { QuestionTypes } from '@klickdata/core/question';
import { Resource, ResourceType } from '@klickdata/core/resource';
import { ResourceItemTypes } from '@klickdata/core/resource-item';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResourceBuilderService } from '../resource-builder.service';
import { QuestionService } from '../shared/question.service';
import { ResourceBuilderToolsViewComponent } from './resource-builder-tools-view/resource-builder-tools-view.component';
/**
 * this resource builder tools is deprecated on 221206
 * As discussion between AS & KL based on EB busiiness requirments.
 */
@Component({
    selector: 'app-resource-builder-tools',
    templateUrl: './resource-builder-tools.component.html',
    styleUrls: ['./resource-builder-tools.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceBuilderToolsComponent implements OnChanges, AfterViewInit, OnDestroy {
    /**
     * Item- and QuestionTypes that should be enabled for addition.
     */
    @Input() resourceTypes: ResourceType[] = [];
    @Input() questionTypes: QuestionTypes[] = [];
    @Input() hideAddItems: boolean;
    @Input()
    public set forceSubmit(forceSubmit: boolean) {
        if (forceSubmit) {
            this.onSubmit();
        }
    }

    /**
     * Available types
     */
    public QuestionTypes = QuestionTypes;
    public ResourceItemTypes = ResourceItemTypes;

    /**
     * Is reordering active?
     */
    // public reorder = false;

    // Save
    public saving: boolean;
    public saved: boolean;
    @Input() submitText: string;
    @Input() submitIcon: string;
    @Input() usePoints: boolean;

    @Input()
    public saveTitle: string;

    @Input() active: boolean;

    @Output() submit: EventEmitter<null> = new EventEmitter();

    protected destroy: Subject<boolean> = new Subject();
    protected bottomSheetActive = false;
    protected bottomSheetRef: MatBottomSheetRef<ResourceBuilderToolsViewComponent>;
    public resource: Resource;

    constructor(
        protected resourceBuilder: ResourceBuilderService,
        protected fb: FormBuilder,
        protected questionService: QuestionService,
        protected changeRef: ChangeDetectorRef,
        protected element: ElementRef,
        protected bottomSheet: MatBottomSheet
    ) {
        this.resourceBuilder
            .isLoading()
            .pipe(takeUntil(this.destroy))
            .subscribe((isLoading) => {
                this.saving = isLoading;
                this.ngOnChanges();
                this.changeRef.markForCheck();
            });

        this.resourceBuilder
            .getResource()
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => (this.resource = resource));
    }

    public ngAfterViewInit() {
        this.blur();
    }

    /**
     * Revoke focus,
     * That fix scrolling shake issue afterDismiss MatBottomSheetRef.
     */
    private blur() {
        const activeElement = document.activeElement;
        if (activeElement instanceof HTMLElement) {
            activeElement.blur();
        }
    }

    public ngOnChanges() {
        if (this.bottomSheetRef) {
            this.bottomSheetRef.instance.update({
                resourceTypes: this.resourceTypes,
                questionTypes: this.questionTypes,
                resource: this.resource,
                // reorder: this.reorder,
                saving: this.saving,
                saved: this.saved,
                saveTitle: this.saveTitle,
            });
        }
    }

    @HostListener('window:scroll')
    public checkInView() {
        const rect = this.element.nativeElement.getBoundingClientRect();
        const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        // Calculate if element is in view.
        const hide =
            rect.y > offset ||
            !(
                rect.x + rect.width < 0 ||
                rect.y + rect.height < 0 ||
                rect.x > window.innerWidth ||
                rect.y > window.innerHeight
            );

        if (this.active && !hide && !this.bottomSheetActive) {
            this.blur(); // revoke focused element before open bottom sheet ref, fix shake scrolling issue.
            this.bottomSheetRef = this.bottomSheet.open(ResourceBuilderToolsViewComponent, {
                hasBackdrop: false,
                autoFocus: false,
                data: {
                    resourceTypes: this.resourceTypes,
                    questionTypes: this.questionTypes,
                    resource: this.resource,
                    // reorder: this.reorder,
                    saving: this.saving,
                    saved: this.saved,
                    saveTitle: this.saveTitle,
                },
            });

            const instance = this.bottomSheetRef.instance;
            instance.addQuestion.pipe(takeUntil(this.destroy)).subscribe((type) => this.onAddQuestion(type));

            // instance.selectWOK.pipe(takeUntil(this.destroy)).subscribe((type) => this.onAddFromWOK(type));

            instance.addItem.pipe(takeUntil(this.destroy)).subscribe((type) => this.onAddItem(type));

            // instance.toggleReorder.pipe(takeUntil(this.destroy)).subscribe(() => this.onToggleReorder());

            instance.submit.pipe(takeUntil(this.destroy)).subscribe(() => this.onSubmit());

            this.bottomSheetRef.afterDismissed().pipe(takeUntil(this.destroy)).subscribe();
            this.bottomSheetActive = true;
        } else if (hide) {
            this.dismissBottomSheetRef();
        }
    }

    public ngOnDestroy(): void {
        this.dismissBottomSheetRef();
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    private dismissBottomSheetRef() {
        if (this.bottomSheetRef && this.bottomSheetActive) {
            this.bottomSheetActive = false;
            this.bottomSheetRef.dismiss();
        }
    }

    public onAddItem(type: ResourceItemTypes) {
        this.resourceBuilder.createItem({
            item_type_value: type,
            isEmpty: true,
        });
    }

    public onAddQuestion(type: QuestionTypes) {
        if (type === QuestionTypes.WOK) {
            this.onAddFromWOK(QuestionTypes.RADIO);
            return;
        }
        const isRadioOrCheckbox = type === QuestionTypes.RADIO || type === QuestionTypes.CHECKBOX;
        const alternatives_count = isRadioOrCheckbox ? 3 : 0;

        this.resourceBuilder.createItem({
            item_type_value: ResourceItemTypes.QUESTION,
            questionForm: this.questionService.createEmptyFormGroup(type, alternatives_count, this.usePoints),
            isEmpty: true,
        });

        // hide WOK generator.
        this.resourceBuilder.showWOKGenerator.next(false);
        this.resourceBuilder.selectedWOKQuestionType.next(null);
    }

    public onAddFromWOK(questionType: QuestionTypes) {
        this.resourceBuilder.selectedWOKQuestionType.next(questionType);
        this.resourceBuilder.showWOKGenerator.next(true);
    }

    public onAddVideo() {}

    // public onToggleReorder(): void {
    //     this.reorder = !this.reorder;
    //     this.resourceBuilder.sort(this.reorder);
    //     this.ngOnChanges();
    // }

    public onSubmit(): void {
        this.resourceBuilder.submit();
        this.ngOnChanges();
    }

    public onItemCollapsingChange(ev) {
        this.resourceBuilder.updateItemsFoldingState(ev);
    }
}
