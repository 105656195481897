<div class="wrapper-main" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
    <span>{{ customFieldForm.value.name }}</span>
    <mat-radio-group
        [formControl]="customFieldForm.get('value') | cast"
        class="course-sett-not"
        fxLayout="column"
        fxLayoutGap="10px"
    >
        <mat-radio-button *ngFor="let option of customFieldForm.value.options" [value]="option">{{
            option
        }}</mat-radio-button>
    </mat-radio-group>
</div>
