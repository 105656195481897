<div class="expanded-note-wrapper" fxLayout="column" fxLayoutGap="10px">
    <div class="expanded-note">
        {{ note.body }}
    </div>
    <div *ngIf="relatedNotes$ | async; let notes" class="related-note">
        <span *ngIf="notes.length" i18n>Related Notes:</span>
        <div *ngFor="let relatedNote of notes">
            <span>
                {{ relatedNote.body }}
            </span>
            <span class="strong-details">
                <span class="res" fxLayoutGap="5px">
                    <mat-icon class="course-plan-icon no-shadow">draw</mat-icon>
                    <span class="orange">{{ relatedNote.author.fname }} {{ relatedNote.author.lname }}</span>
                </span>
                <span class="time" fxLayoutGap="5px">
                    <mat-icon>av_timer</mat-icon
                    ><span>{{ relatedNote.created_at | kdDateFormat: 'YYYY-MM-DD HH:mm' }}</span>
                </span>
            </span>
        </div>
    </div>
</div>
