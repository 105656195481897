import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CustomerService } from '@klickdata/core/customer';
import { LanguageService } from '@klickdata/core/localization';
import { MessageService } from '@klickdata/core/message';
import { ResourceService } from '@klickdata/core/resource';
import { Educator } from '@klickdata/core/resource/src/resource.model';
import { User, UserService } from '@klickdata/core/user';
import * as moment from 'moment';
import {
    Observable,
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    merge,
    of,
    share,
    switchMap,
    takeUntil,
} from 'rxjs';
import { MenuSideAddEducatorComponent } from '../menu-side-add-educator/menu-side-add-educator.component';

@Component({
    selector: 'app-menu-side-add-next-contact',
    templateUrl: './menu-side-add-next-contact.component.html',
    styleUrls: ['./menu-side-add-next-contact.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideAddNextContactComponent extends MenuSideAddEducatorComponent implements OnInit, AfterViewInit {
    public userSearch$: Observable<User[]>;
    public nextContact: User;
    public moment = moment();
    constructor(
        protected fb: FormBuilder,
        protected educatorService: ResourceService,
        protected messageService: MessageService,
        protected userService: UserService,
        protected customerService: CustomerService,
        protected changeRef: ChangeDetectorRef,
        protected langService: LanguageService
    ) {
        super(fb, educatorService, messageService, userService, customerService, changeRef, langService);
    }
    buildForm() {
        this.educatorForm = this.fb.group({
            name: [''],
            deadline: [new DatePipe('en').transform(Date.now(), 'yyyy-MM-dd').toString()],
            tag_ids: [[]],
            note: [''],
            phone: [''],
            type: ['prospect'],
            manager: [[]],
            media_id: [],
            email: [''],
            position: [''],
            about: [''],
            customer_id: [''],
        });
    }

    ngOnInit(): void {
        this.currentLaguageId$ = this.langService
            .getLanguageByKey(this.langService.getCurrentLanguage().value)
            .pipe(map((lang) => lang.id));
        if (this.navData.data.customer) {
            this.educatorForm.get('customer_id').patchValue(this.navData.data.customer.id);
            this.customerRecipient = this.navData.data.customer;
            this.changeRef.markForCheck();
        }
    }
    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.userSearch$ = merge(
            this.educatorForm.get('name').valueChanges,
            this.educatorForm.get('email').valueChanges
        ).pipe(
            filter((term: string) => typeof term === 'string' && term != ''),
            debounceTime(300),
            distinctUntilChanged(),
            share(),
            switchMap((term) =>
                term.trim() ? this.userService.getLightMasterUsers({ query: term, limit: 20 }) : of(<User[]>[])
            ),
            takeUntil(this.destroy)
        );
    }
    prepareData(): Educator {
        const data = this.educatorForm.value;
        data.task = {
            deadline: this.educatorForm.value.deadline,
            tag_ids: this.educatorForm.value.tag_ids,
            // type: this.educatorForm.value.type,
        };
        if (this.nextContact?.id) {
            data.id = this.nextContact.id;
        }
        if (this.educatorForm.value.manager?.length) {
            data.task.staff = { manager: this.educatorForm.value.manager };
        }
        if (!data.customer_id) {
            delete data.customer_id;
        }
        delete data.deadline;
        delete data.manager;
        delete data.tag_ids;
        // delete data.type;
        return data;
    }
    createOrUpdate(): Observable<Educator> {
        return this.nextContact?.id
            ? this.educatorService.updateResEducator(this.prepareData(), ['task', 'tags', 'managers'])
            : this.educatorService.createNewResEducator(this.prepareData(), ['task', 'tags', 'managers']);
    }
    public selected(auto: MatAutocomplete, event: MatAutocompleteSelectedEvent, isCustomer: boolean) {
        if (isCustomer) {
            this.customerRecipient = event.option.value;
            this.customerRecieverInput.nativeElement.value = '';
            this.customerCtrl.setValue('');
            this.educatorForm.get('customer_id').setValue(this.customerRecipient.id);
        } else {
            this.educatorForm.get('name').patchValue(event.option.value.name);
            this.educatorForm.get('email').patchValue(event.option.value.email);
            this.educatorForm.get('name').disable();
            this.educatorForm.get('email').disable();
            this.nextContact = event.option.value;
        }
        auto.options.reset([]);
        this.changeRef.markForCheck();
    }
    onEditNameOrEmail() {
        this.educatorForm.get('name').patchValue('');
        this.educatorForm.get('email').patchValue('');
        this.educatorForm.get('name').enable();
        this.educatorForm.get('email').enable();
        this.nextContact = null;
        this.changeRef.markForCheck();
    }
}
