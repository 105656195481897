<div [ngClass]="{ small: size === 'small', medium: size === 'medium', large: size === 'large' }">
    <svg [attr.viewBox]="viewBoxValue">
        <polygon
            class="path"
            fill="none"
            stroke="#dcdcdc"
            stroke-width="19.8425"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            points="
            23.857,22.168 71.822,153.456 96.726,95.345 155.76,68.903 "
        />
    </svg>
</div>
