<div matDialogTitle>
    <div fxLayoutAlign="start center" fxLayoutGap="10px">
        <mat-icon>swap_horiz</mat-icon>
        <span i18n> Welcome back! </span>
    </div>
</div>

<div mat-dialog-content i18n>
    FYI. Confirm to be logged out on another device or browser <br />
    since you can’t be logged in on different devices at the same time.
</div>

<div mat-dialog-actions class="flex">
    <div class="flex-space"></div>
    <button mat-button (click)="onCancel()" tabindex="-1" i18n>Cancel</button>
    <div class="space"></div>
    <button mat-raised-button color="primary" tabindex="2" (click)="onConfirm()" i18n>Confirm</button>
</div>
