import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { MessageFormErrorComponent, MessageSentComponent, MessageService } from '@klickdata/core/message';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { UserMessage, UserMessageData, UserService } from '@klickdata/core/user';
import { UsersSelectComponent } from '@klickdata/shared-components/src/selection-handler/users-select/users-select.component';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { Observable, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-send-group-email',
    templateUrl: './menu-side-send-group-email.component.html',
    styleUrls: ['./menu-side-send-group-email.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideSendGroupEmailComponent extends OnDestroyHandler implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    @ViewChild(UsersSelectComponent) userSelectComp: UsersSelectComponent;
    public loading = false;
    public welcomeForm: FormGroup;

    constructor(
        protected fb: FormBuilder,
        protected userService: UserService,
        protected auth: AuthService,
        protected messageService: MessageService
    ) {
        super();
        this.welcomeForm = this.fb.group({
            subject: ['', Validators.required],
            body: ['', Validators.required],
            groups: [],
            customer: [],
            import: [],
            user_activation: [],
            sync_all_users: [],
            users_attach: [[]],
            users_detach: [[]],
        });
    }

    ngOnInit(): void {
        if (this.navData.data) {
            this.welcomeForm.patchValue(this.navData.data);
        }
    }
    public submitWelcomeMessage(standardActivation?: boolean): void {
        if ((this.welcomeForm.invalid && !standardActivation) || this.userSelectComp?.canDeactivate()) {
            FormHelper.markForm(this.welcomeForm);
            this.messageService.openMessage(MessageFormErrorComponent);
            return;
        }
        this.prepareUserMessage(standardActivation)
            .pipe(
                filter((msg) => {
                    this.loading = true;
                    return true;
                }),
                switchMap((msg) => this.userService.send(msg))
            )
            .subscribe((result) => {
                this.loading = false;
                this.messageService.openMessage(MessageSentComponent);
                this.welcomeForm.reset();
                this.welcomeForm.patchValue({ body: '' });
                FormHelper.resetForm(this.welcomeForm);
            });
    }

    protected prepareUserMessage(standardActivation?: boolean): Observable<UserMessageData> {
        const data = new UserMessage(
            standardActivation ? this.navData.data : { ...this.welcomeForm.value, ...this.navData.data }
        ).getData();
        if (data.customer) {
            return of(data);
        } else {
            return this.auth.getCustomer().pipe(
                first(),
                map((customer) => {
                    data.customer = customer.id;
                    return data;
                })
            );
        }
    }
}
