import { Injectable } from '@angular/core';
import { File, FileData, FileService } from '@klickdata/core/file';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class K3FilesListingService extends FileService implements TableHttpService<File> {
    public fetchData(source: TableSource<File>): Observable<ResponseData<File[]>> {
        const req = this.builder.get<FileData[]>(this.filesUrl);
        req.putEager(['author', 'tags']);
        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<FileData[]>) => {
                return {
                    data: res.data.map((file) => this.createFile(file)),
                    notify: res.notify,
                    more: res.more,
                    paginator: res.paginator,
                };
            })
        );
    }
}
