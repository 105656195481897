import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { ResourceMediaTypes } from '@klickdata/core/question/src/question-type/question-type';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { of, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil } from 'rxjs/operators';
import { MediaImgUploaderBase } from './media-img-uploader-base';
import { MediaType } from './media-type';

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MediaComponent),
            multi: true,
        },
    ],
})
export class MediaComponent extends MediaImgUploaderBase implements ControlValueAccessor {
    public get dropStyle() {
        return {
            height: this.dropSize,
            width: this.dropSize,
            flex: `0 1 ${this.dropSize}`,
        };
    }

    public get value(): string {
        return this._value;
    }

    public set value(value: string) {
        this._value = value;
        this.propagateChange(this._value);
    }
    @Input() simple: boolean;
    @Input() enableDrop = true;
    @Input() dropLabel: string;
    @Input() uploadIcon = 'add_photo_alternate';
    @Input() uploadLabel = $localize`Upload image`;
    @Input() uploadBtnLabel = $localize`Upload file`;
    @Input() itemAlias: string;
    @Input() dropSize = '400px';
    @Input() hasAIUploader = false;
    public isDisabled: Subject<boolean> = new Subject<boolean>();
    @ViewChild('uploadInput') uploadInput: ElementRef;
    /**
     * FileUploader
     */
    public uploader: FileUploader;

    /**
     * Media ID
     */
    @Input() _value: string;

    constructor(
        protected mediaService: MediaService,
        protected auth: AuthService,
        protected message: MessageService,
        protected mobileService: MobileService
    ) {
        super(mediaService, auth, message);
    }
    public propagateChange = (_: any) => {};

    public fileOverBase(ev: any): void {}

    public onComplete(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): void {
        const responseJSON = JSON.parse(response);
        try {
            if (item.isSuccess) {
                this.value = responseJSON.data[this.mediaIdKey];
            } else if (responseJSON.error) {
                this.message.openMessage(MessageErrorComponent, responseJSON.error.messages);
                this.onError.emit(responseJSON.error.messages);
            }
        } catch (e) {
            this.message.openMessage(MessageErrorComponent, responseJSON.error.messages);
            this.onError.emit(response);
        }
        this.saving.emit(false);
    }
    onUpload(errorMsg?: string) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_RESOURCE_MEDIA,
            data: {
                icon: 'add_photo_alternate',
                title: $localize`Add an image`,
                description: $localize`Please select your preferred way to add an image.`,
                errorMsg: errorMsg,
            },
        });
        this.getMediaTypeFromSlideIn();
    }
    private getMediaTypeFromSlideIn() {
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                this.saving.emit(true);
                if (data && data.value) {
                    if (data.value === ResourceMediaTypes.FILE) {
                        this.saving.emit(false);
                        this.value = data.mediaId;
                    } else {
                        this.initAIUpload(data);
                    }
                }
            });
    }
    private initAIUpload(data: any) {
        this.mediaService
            .uploadMediaByLink({
                prompt: data.aiText,
                type: MediaType.PROMPT,
                scope_id: this.scope,
            })
            .pipe(
                takeUntil(this.destroy),
                catchError((err) => {
                    this.handleAIRequestFailure(err);
                    return of(null);
                })
            )
            .subscribe((media) => {
                this.saving.emit(false);
                if (media && media.id) {
                    this.value = media.id;
                }
            });
    }
    handleAIRequestFailure(err) {
        if (err && err.error && err.error.error) {
            this.message.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
            this.onUpload(err.error.error.messages.join('/n'));
        }
    }

    public writeValue(value: string): void {
        this._value = value;
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}
    setDisabledState(isDisabled: boolean) {
        this.isDisabled.next(isDisabled);
    }
}
