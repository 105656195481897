import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-message-system',
  templateUrl: './message-system.component.html',
  styleUrls: ['./message-system.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageSystemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
