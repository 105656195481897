<div *ngIf="childResource$ | async as resource" fxLayout="row wrap" fxLayoutGap="1em">
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="start center">
        <h2 fxFlex="0 0 auto">{{ resource.title }}</h2>
        <a
            fxFlex="0 0 auto"
            [routerLink]="['/player/resource', resource.id]"
            target="_blank"
            mat-icon-button
            [matTooltip]="previewTooltip"
        >
            <mat-icon class="md-24">open_in_new</mat-icon>
        </a>
    </div>

    <app-media-view
        *ngIf="
            resource.type_id === ResourceTypes.DocMaterial ||
            resource.type_id === ResourceTypes.URLMaterial ||
            resource.type_id === ResourceTypes.WebsiteMaterial
        "
        fxFlex="0 0 100%"
        [media_id]="(resource.item$ | async)?.name"
    >
    </app-media-view>

    <app-image-view
        *ngIf="resource.media_id"
        fxFlex="0 0 100%"
        [media_id]="resource.media_id"
        [proportional]="true"
    ></app-image-view>

    <div fxFlex="0 1 calc(80% - 2em)" [innerHtml]="resource.description"></div>
</div>
