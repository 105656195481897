import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { ResourceBuildingItems } from '@klickdata/core/resource/src/types.enum';

@Component({
    selector: 'app-menu-side-log-user-resource-events',
    templateUrl: './menu-side-log-user-resource-events.component.html',
    styleUrls: ['./menu-side-log-user-resource-events.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideLogUserResourceEventsComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    constructor() {}

    ngOnInit(): void {}
}
