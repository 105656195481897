import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-guest-devices-dialog',
    templateUrl: 'devices-dialog.component.html'
})
export class DevicesDialogComponent {
    constructor(
        protected dialogRef: MatDialogRef<DevicesDialogComponent>
    ) { }

    public onConfirm() {
        this.dialogRef.close(true);
    }

    public onCancel() {
        this.dialogRef.close(false);
    }
}
