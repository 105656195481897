import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AuthService } from '@klickdata/core/auth';
import { Customer, CustomerService } from '@klickdata/core/customer';
import { FormHelper } from '@klickdata/core/form';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-customer-chip-select',
    templateUrl: './customer-chip-select.component.html',
    styleUrls: ['./customer-chip-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomerChipSelectComponent),
            multi: true,
        },
    ],
})
export class CustomerChipSelectComponent implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit {
    @Input() titleLabel: string;
    @Input() hint: string;
    @Input() chipRemovable = true;
    @Input() controlName: boolean;
    @Input() hasDrafts: boolean;
    @Input() defaultAcademy: boolean;
    @Input() maxCustomersCount = 1;
    @Output() onNotifyCustomers: EventEmitter<{ customers: Customer[]; draftCustomers: string[] }> = new EventEmitter<{
        customers: Customer[];
        draftCustomers: string[];
    }>();
    public customerSearch$: Observable<Customer[]>;
    public hideInput: boolean;
    @ViewChild('customerInput') customerRecieverInput: ElementRef<HTMLInputElement>;
    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
    public customerCtrl = new FormControl('');
    public destroy: Subject<boolean> = new Subject<boolean>();
    public draftCustomers: string[] = [];
    private _customers: Customer[] = [];
    get customers(): Customer[] {
        return this._customers;
    }
    set customers(value: Customer[]) {
        this._customers = value;
        this.propagateChange(this._customers.map((customer) => customer.id));
    }

    constructor(
        protected customerService: CustomerService,
        protected changeRef: ChangeDetectorRef,
        protected injector: Injector,
        protected auth: AuthService
    ) {}

    ngOnInit() {
        this.customerSearch$ = this.customerCtrl.valueChanges.pipe(
            filter((term: string) => typeof term === 'string' && term != ''),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term) => (term.trim() ? this.customerService.getCustomers(term, 10) : of(<Customer[]>[]))),
            takeUntil(this.destroy)
        );
    }
    ngAfterViewInit(): void {
        if (this.defaultAcademy && !this.customers.length) {
            this.auth
                .getCustomer()
                .pipe(first())
                .subscribe((customer) => {
                    this.customers = [customer];
                    this.applyUpdates();
                });
        }
    }
    removeCustomer(customer: Customer | string): void {
        if (typeof customer === 'string') {
            const index = this.draftCustomers.findIndex((c) => c === customer);
            if (index !== -1) {
                this.draftCustomers.splice(index, 1);
            }
        } else {
            const index = this.customers.findIndex((c) => c.id === customer.id);
            if (index !== -1) {
                this.customers.splice(index, 1);
                this.propagateChange(this.customers.map((customer) => customer.id));
            }
        }
        this.applyUpdates();
    }
    public selectCustomer(selected: Customer) {
        if (this.customers.findIndex((customer) => customer.id === selected.id) === -1) {
            this.customers.push(selected);
            this.propagateChange(this.customers.map((customer) => customer.id));
            this.customerRecieverInput.nativeElement.value = '';
            this.customerCtrl.setValue('');
        }
        this.applyUpdates();
    }
    addDraftCustomer() {
        if (this.draftCustomers.findIndex((customer) => customer === this.customerCtrl.value) === -1) {
            this.draftCustomers.push(this.customerCtrl.value.replace(/,+/g, ',').replace(/^,|,$/g, '').trim());
            this.customerRecieverInput.nativeElement.value = '';
            this.customerCtrl.setValue('');
            this.autocomplete.closePanel();
        }
        this.applyUpdates();
    }
    public propagateChange = (_: any) => {};
    private applyUpdates() {
        this.onNotifyCustomers.emit({ customers: this.customers, draftCustomers: this.draftCustomers });
        this.hideInput = this.customers.length >= this.maxCustomersCount;
        this.changeRef.markForCheck();
    }

    public writeValue(value: (number | Customer)[]): void {
        if (value?.length && !(value[0] instanceof Customer)) {
            this.customerService
                .getCustomersByIds(<number[]>value)
                .pipe(takeUntil(this.destroy))
                .subscribe((customers) => {
                    this._customers = customers || [];
                    this.hideInput = this.customers.length >= this.maxCustomersCount;
                    this.changeRef.markForCheck();
                    this.resetFormControl();
                });
        } else if (value?.length && value[0] instanceof Customer) {
            this._customers = <Customer[]>value || [];
            this.hideInput = this.customers.length >= this.maxCustomersCount;
            this.changeRef.markForCheck();
            this.resetFormControl();
        }
    }

    private resetFormControl() {
        const control = this.injector.get(NgControl)?.control;
        if (control instanceof FormControl) {
            FormHelper.resetForm(control);
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}

    public stopPropagation(ev: MouseEvent): void {
        ev.stopPropagation();
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
