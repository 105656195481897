import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatTableDataSource } from '@angular/material/table';
import { Filter } from '@klickdata/core/table';

@Component({
    selector: 'app-collection-item-sheet-browse-children',
    templateUrl: './collection-item-sheet-browse-children.component.html',
    styleUrls: ['./collection-item-sheet-browse-children.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionItemSheetBrowseChildrenComponent implements OnInit {
    constructor(
        public bottomSheetRef: MatBottomSheetRef<CollectionItemSheetBrowseChildrenComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {}

    ngOnInit(): void {}
}
