import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { AppScope } from '@klickdata/core/resource';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-menu-side-text-area-field',
    templateUrl: './menu-side-text-area-field.component.html',
    styleUrls: ['./menu-side-text-area-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideTextAreaFieldComponent implements OnChanges, OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    AppScope = AppScope;
    public form: FormGroup;
    public buttonDisabled: boolean;
    public saving: Subject<boolean> = new Subject<boolean>();
    SideNaveActionsTypes = SideNaveActionsTypes;

    constructor(protected fb: FormBuilder, protected cd: ChangeDetectorRef) {
        this.form = this.fb.group({
            value: ['', Validators.required],
            media_ids: [[]],
        });
    }
    ngOnInit(): void {
        if (this.navData?.data) {
            this.updateForm();
        }
        this.form.valueChanges.subscribe(() => {
            this.buttonDisabled = false;
            this.cd.markForCheck();
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.navData?.data) {
            this.updateForm();
        }
    }
    private updateForm() {
        if (this.navData.data.media_ids) {
            this.form.patchValue({ media_ids: this.navData.data.media_ids });
        }
        if (this.navData.data.value) {
            this.form.patchValue({ value: this.navData.data.value });
        }
        this.buttonDisabled = true;
        this.cd.markForCheck();
    }
    public submit() {
        this.onClose.emit({
            action: SideNaveActionsTypes.POSITIVE,
            data: {
                value: this.form.get('value').value,
                type: this.navData.data.type,
                media_ids: this.form.get('media_ids').value,
            },
        });
        this.clear();
    }
    public clear() {
        this.form.patchValue({ media_ids: [], value: '' });
        this.buttonDisabled = true;
        this.cd.markForCheck();
    }
}
