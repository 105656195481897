import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicationService } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';

@Component({
    selector: 'app-sidebar-layout',
    templateUrl: './sidebar-layout.component.html',
    styleUrls: ['./sidebar-layout.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarLayoutComponent implements OnInit, OnDestroy {
    public destroy: Subject<boolean> = new Subject<boolean>();
    protected eventSub: Subscription;
    @Input() isOpen = true;
    public sideBarCollapsed = false;

    constructor(
        protected router: Router,
        protected scroll: ScrollDispatcher,
        protected elementRef: ElementRef,
        protected auth: AuthService,
        protected appService: ApplicationService
    ) {}

    ngOnInit() {
        if (window.innerWidth > 959 && window.innerWidth < 1400) {
            this.sideBarCollapsed = true;
        }

        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                // Scroll up
                this.scroll.scrollContainers.forEach((sub, scrollable) => {
                    scrollable.getElementRef().nativeElement.scrollTop = 0;
                });
            }
        });
        this.auth
            .getNK3Platform()
            .pipe(
                filter((platform) => platform == 'guest' || platform == 'user'),
                takeUntil(this.destroy)
            )
            .subscribe(() => {
                this.sideBarCollapsed = true;
            });
    }

    ngOnDestroy() {
        if (this.eventSub) {
            this.eventSub.unsubscribe();
            this.eventSub = null;
        }
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
    collapseToggle() {
        this.sideBarCollapsed = !this.sideBarCollapsed;
    }
}
