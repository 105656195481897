import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { AppScope, ResourceTypes } from '@klickdata/core/resource';

@Component({
    selector: 'app-menu-side-resource-managers',
    templateUrl: './menu-side-resource-managers.component.html',
    styleUrls: ['./menu-side-resource-managers.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideResourceManagersComponent implements OnInit {
    @Input() public navData: SideNaveData;
    ResourceTypes = ResourceTypes;
    AppScope = AppScope;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    constructor() {}

    ngOnInit(): void {}
    clear() {}
}
