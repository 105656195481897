import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'app-checkbox-with-custom-user-click',
    templateUrl: './checkbox-with-custom-user-click.component.html',
    styleUrls: ['./checkbox-with-custom-user-click.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CheckboxWithCustomUserClickComponent),
            multi: true,
        },
    ],
})
export class CheckboxWithCustomUserClickComponent implements OnInit, ControlValueAccessor {
    protected _value: true | false | null;
    public isDisabled: boolean;
    @Input() toolTipsMapping: { value: true | false | null; label: string }[];
    get value() {
        return this._value;
    }

    set value(value) {
        this._value = value;
    }
    constructor(protected cdr: ChangeDetectorRef) {}

    ngOnInit(): void {}
    onChange() {
        if (this.value === false) {
            this.value = true;
        } else if (this.value === true) {
            this.value = null;
        } else {
            this.value = false;
        }
        this.propagateChange(this._value);
    }
    writeValue(value: boolean): void {
        this.value = value;
        this.cdr.markForCheck();
    }
    public getToolTip(): string {
        return this.toolTipsMapping.find((map) => map.value === this.value)?.label;
    }
    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }
    propagateChange = (_: any) => {};

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}
}
