import { Cast, Model, Ignore, CastType } from '@klickdata/core/application';
import * as moment from 'moment';
import { ResourceData, Resource } from '../resource.model';

export interface ResourceStatisticData {
    id?: number;
    date?: string;
    resource_id?: number;
    type_id?: number;
    customer_id?: number;
    group_id?: number;

    // started_user_ids?: number[];
    // completed_user_ids?: number[];
    // passed_user_ids?: number[];
    // failed_user_ids?: number[];

    completed_course?: number;
    completed_course_plan?: number;
    completed_survey?: number;
    completed_test?: number;
    failed_test?: number;
    passed_test?: number;
    started_coures_plan: number;
    started_course?: number;
    started_survey?: number;
    started_test?: number;
    doc_material?: number;
    url_material?: number;
    text_material?: number;
    material?: number;

    resource_started?: number;
    resource_completed?: number;
    resource_passed?: number;
    resource_failed?: number;

    created_at?: string;
    updated_at?: string;
}

export class ResourceStatistic extends Model<ResourceStatisticData> {
    public id: number;

    @Cast(CastType.MOMENT)
    public date: moment.Moment;
    public resource_id: number;
    public type_id: number;
    public customer_id: number;
    public group_id: number;

    completed_course: number;
    completed_course_plan: number;
    completed_survey: number;
    completed_test: number;
    failed_test: number;
    passed_test: number;
    started_coures_plan: number;
    started_course: number;
    started_survey: number;
    started_test: number;
    doc_material: number;
    url_material: number;
    text_material: number;
    material: number;

    // Make default 0
    @Ignore()
    resource_started = 0;
    @Ignore()
    resource_completed = 0;
    @Ignore()
    resource_passed = 0;
    @Ignore()
    resource_failed = 0;

    @Cast(CastType.MOMENT)
    @Ignore()
    public created_at: moment.Moment;

    @Cast(CastType.MOMENT)
    @Ignore()
    public updated_at: moment.Moment;
}

export interface ResourceWithStatisticData extends ResourceData {
    statistic: ResourceStatisticData;
}

export class ResourceWithStatistic extends Resource {
    statistic: ResourceStatistic;

    constructor(data: ResourceWithStatisticData) {
        super(data);
        this.statistic = new ResourceStatistic(data.statistic);
    }
}
