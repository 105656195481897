import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { User } from '@klickdata/core/user';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-resource-purchase-dialog',
    templateUrl: './resource-purchase-dialog.component.html',
    styleUrls: ['./resource-purchase-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcePurchaseDialogComponent implements OnInit {
    public user$: Observable<User>;
    public customer$: Observable<Customer>;
    constructor(
        protected bottomSheetRef: MatBottomSheetRef<ResourcePurchaseDialogComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected authService: AuthService
    ) {}

    ngOnInit(): void {
        this.user$ = this.authService.getUser();
        this.customer$ = this.authService.getCustomer();
    }
    public onPaymentCompleted(isSucceeded: boolean) {
        this.bottomSheetRef.dismiss(isSucceeded);
    }
}
