import { Injectable } from '@angular/core';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';
import { MsgCase, MsgCaseData, NotificationService as Base } from '@klickdata/core/notification';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CasesService extends Base implements TableHttpService<MsgCase> {
    fetchData(source: TableSource<MsgCase>): Observable<ResponseData<MsgCase[]>> {
        const req = this.builder.get<MsgCaseData[]>(this.casesUrl);

        if (source.filter?.filter?.filters?.findIndex((filter) => filter.property.indexOf('reference') !== -1) !== -1) {
            req.param('referenceCount', 1);
        }

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<MsgCaseData[]>) => {
                return {
                    data: res.data.map((data: MsgCaseData) => <MsgCase>data),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }
}
