import { Location } from '@angular/common';
import { Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirectionalityService } from '@klickdata/core/localization/src/directionality.service';
import { ApplicationService } from '../application.service';

@Component({
    selector: 'app-error-dialog',
    templateUrl: './error-dialog.component.html',
    styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
    public eventId: string | undefined;

    constructor(
        public application: ApplicationService,
        private location: Location,
        protected zone: NgZone,
        public directionality: DirectionalityService,
        @Inject(MAT_DIALOG_DATA) protected data: { eventId: string | undefined }
    ) {
        this.eventId = this.data.eventId;
    }

    /*
     * MJ -> User back to the last step before crash page, Fix #227 After pressing Reload in crash message I'm returned to User dashboard and not Admin.
     */
    public reload(): void {
        if (document.referrer !== '') {
            this.zone.run(() => this.location.back());
        } else {
            window.location.assign('');
        }
    }
}
