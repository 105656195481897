<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon>format_quote</mat-icon>
        <div>{{ navData.data.name }} <span i18n>Quotes</span></div>
    </div>

    <div class="quotes-wrapper" *ngIf="quotes$ | async; let quotes; else: loading">
        <div
            [ngClass]="{ 'last-item': last }"
            *ngFor="let quote of quotes; let last = last"
            fxLayout="column"
            fxLayoutGap="10px"
            class="quote-item"
        >
            <div class="body">
                {{ quote.quote }}
            </div>

            <div fxLayoutAlign="space-between center">
                <span class="quotee-name-later">{{ navData.data.name }}</span>
                <span class="date">{{ quote.created_at | kdDateFormat : 'MMM D, YYYY' }}</span>
            </div>
        </div>
    </div>

    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="onClose.emit({ action: SideNaveActionsTypes.POSITIVE })"
            mat-raised-button
        >
            OK
        </button>
    </div>
</div>

<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
