<div fxFlex="grow" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">
    <span class="sx-title">{{ widget.name }}</span>
    <div class="circle" [ngClass]="{ 'in-compare': compareMode }" [ngStyle]="{ 'background-color': widget.color }">
        <span class="value" [matTooltip]="widget.description">{{ widget.total }}</span>
        <!-- <span class="compare-value" *ngIf="compareMode">{{ comparedValue }}</span> -->
    </div>
    <span class="sx-desc">{{ removeDot(periodTitle) }}</span>
    <div class="btn-togg">
        <mat-button-toggle-group
            [ngClass]="{ 'in-filtersOpened': filtersOpened }"
            [value]="selectedFilter"
            (click)="selectedFilter === 'select_date_range' && dateRangeClicked && compareChange('select_date_range')"
            (change)="compareChange($event.value); onToggleFilter(filtersOpened)"
        >
            <button
                class="toggle-filter"
                [ngClass]="{ 'filter-open': filtersOpened }"
                mat-icon-button
                (click)="filtersOpened = !filtersOpened; onToggleFilter(filtersOpened)"
            >
                <mat-icon>arrow_forward_ios</mat-icon>
            </button>
            <mat-button-toggle
                *ngFor="let option of predefinedTimeOptions"
                [value]="option.value"
                [matTooltip]="option.data?.tooltipValue"
            >
                <span *ngIf="widget.event === 'durationtime'">{{ option.title | formatIsoDuration }}</span>
                <span *ngIf="widget.event !== 'durationtime'">{{ option.title }}</span>
            </mat-button-toggle>
        </mat-button-toggle-group>
        <button
            fxHide
            class="clear-filter"
            [ngClass]="{ 'in-compare-btn': compareMode }"
            mat-icon-button
            (click)="compareMode = !compareMode; compareChange(compareMode ? predefinedTimeOptions[0].value : null)"
        >
            <mat-icon>filter_alt</mat-icon>
        </button>
    </div>
    <!-- <div class="border"></div> -->
    <div class="graph-wrapper">
        <mat-accordion fxFlex="0 0 100%" displayMode="flat">
            <mat-expansion-panel [expanded]="expanded$ | async" #expansionPanel>
                <ng-template matExpansionPanelContent>
                    <div class="graphs">
                        <div
                            *ngFor="let detailsItem of widget.details"
                            class="row green"
                            [ngStyle]="{ width: 'calc(' + detailsItem.percentage + '% + 50px)' }"
                        >
                            <span>{{ detailsItem.title }}</span>
                            <span>{{ detailsItem.value }}</span>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>
