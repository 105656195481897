import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FilterBase } from '../filter-base';
import { Filter } from '../filter';
import { GlobalFilterColor, GlobalFilterProperty } from '@klickdata/core/table';
import { CustomField, FieldType } from '@klickdata/core/custom-field';
import { AuthService } from '@klickdata/core/auth';
import { debounceTime, filter, first, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { CustomerService } from '@klickdata/core/customer';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import * as moment from 'moment';

@Component({
    selector: 'app-custom-fields-filter',
    templateUrl: './custom-fields-filter.component.html',
    styleUrls: ['./custom-fields-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: FilterBase, useExisting: CustomFieldsFilterComponent }],
})
export class CustomFieldsFilterComponent extends FilterBase implements OnInit, OnDestroy {
    @ViewChild(MatSelect, { static: false }) matSelect: MatSelect;
    @Input() label: string;
    @Input() inGlobalFilter: boolean;
    @Output() filterChange: EventEmitter<Filter<string>> = new EventEmitter<Filter<string>>();
    @Output() filterRemoval: EventEmitter<Filter<string>> = new EventEmitter<Filter<string>>();
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.CUSTOM_FIELD,
        items: [],
        label: $localize`:@@customField:Custom field`,
        icon: 'tune',
        color: { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.WHITE },
        styleClass: 'global-groups-filter-selector',
    };

    public customFields: CustomField[];
    private destroy: Subject<boolean> = new Subject<boolean>();
    FieldType = FieldType;
    public customFieldForm: FormGroup;
    constructor(protected auth: AuthService, protected fb: FormBuilder, protected cdr: ChangeDetectorRef) {
        super();
        this.customFieldForm = this.fb.group({
            id: [''],
            value: [''],
            name: [''],
            placeholder: [''],
            options: [''],
            type: [''],
        });
    }

    ngOnInit(): void {
        this.auth
            .getCustomer()
            .pipe(map((customer) => customer.custom_fields))
            .subscribe((customFields) => {
                this.customFields = customFields;
                this.cdr.markForCheck();
            });
        this.customFieldForm
            .get('value')
            .valueChanges.pipe(
                debounceTime(300),
                takeUntil(this.destroy),
                filter((value) => value != null)
            )
            .subscribe((value) => {
                this.filter.items = [`${this.customFieldForm.value.id},${value}`];
                this.filterChange.emit(this._filter);
            });
    }
    getFilterChip(filter: Filter<any>): { icon: string; tooltip: string; name: string; value: string } {
        const [id, value] = filter.items[0].split(',');
        const typeInfo = this.getCustomFieldTypeInfo(this.customFields.find((cf) => cf.id == id)?.type);
        if (id && typeInfo) {
            return {
                icon: typeInfo.icon,
                tooltip: typeInfo.label,
                name: this.customFields.find((cf) => cf.id == id)?.name,
                value:
                    this.customFields.find((cf) => cf.id == id)?.type == FieldType.DATE
                        ? new Date(value).toLocaleDateString('en-GB')
                        : value,
            };
        }
    }
    getCustomFieldTypeInfo(type: string) {
        switch (type) {
            case FieldType.TEXT_INPUT:
                return { icon: 'notes', label: $localize`Text content` };
            case FieldType.SINGLE_CHOICE:
                return { icon: 'radio_button_checked', label: $localize`Single choice` };
            case FieldType.MULTIPLE_CHOICE:
                return { icon: 'check_box', label: $localize`Multiple choice` };
            case FieldType.DATE:
                return { icon: 'calendar_month', label: $localize`Date picker` };
            case FieldType.DROPDOWN:
                return { icon: 'format_list_numbered', label: $localize`Drop down` };
            case FieldType.SWITCH:
                return { icon: 'toggle_off', label: $localize`Switch` };
        }
    }
    onSelectionChange(field: CustomField) {
        if (field) {
            this.matSelect.value = field;
            this.customFieldForm.patchValue(field);
            this.cdr.markForCheck();
        }
    }
    public clear(): void {
        this._filter.items = null;
        this.customFieldForm.reset({ emitEvent: false });
        this.matSelect.value = undefined;
        this.filterRemoval.emit(this.filter);
        this.cdr.markForCheck();
    }
    public get filter(): Filter<string> {
        return this._filter;
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
