<form [formGroup]="permForm" fxLayout="row" fxLayoutAlign="space-between center">
    <ng-container formArrayName="permissions">
        <div
            class="{{ (modelPermGroup.value.permission_type | resourcePermissionPipe)?.class }} w-100"
            *ngFor="let modelPermGroup of permFormArray.controls"
            [formGroup]="modelPermGroup | cast"
        >
            <app-checkbox-with-custom-user-click
                [toolTipsMapping]="toolTipsMapping"
                formControlName="permission_value"
            ></app-checkbox-with-custom-user-click>
        </div>
    </ng-container>
</form>
