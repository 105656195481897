import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from '@klickdata/core/auth';
import { HttpModule } from '@klickdata/core/http';
import { ConfigModule } from '@klickdata/core/config';
import { CustomerModule } from '@klickdata/core/customer';
import { UserModule } from '@klickdata/core/user';
import { GroupService } from '@klickdata/core/group/src/group.service';

@NgModule({
    imports: [
        CommonModule,

        AuthModule,
        HttpModule,
        ConfigModule,
        CustomerModule,
        UserModule,
    ],
    providers: [GroupService],
})
export class GroupModule {}
