<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>warning_amber</mat-icon><span i18n>Report an issue</span>
    </div>

    <div *ngIf="alertData?.desc" class="desc">{{ alertData.desc }}</div>

    <div class="desc" i18n>
        Please report any issues you feel should be adressed in the KlickData system below. We like to have all
        suggestions we can get on how to improve your learning experience.
    </div>
    <form fxLayoutGap="20px" fxLayout="column" [formGroup]="alertForm">
        <mat-form-field appearance="fill" class="templates-select" fxFlex="0 0 100%">
            <mat-select placeholder="Select report reason" i18n-placeholder formControlName="subject">
                <mat-option *ngFor="let option of alertOptions" [value]="option">
                    {{ option }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="mb-0" floatLabel="never" appearance="fill" fxFlex="0 0 100%">
            <textarea
                maxlength="5000"
                rows="5"
                i18n-placeholder
                placeholder="Type in your message"
                #message
                matInput
                formControlName="description"
            ></textarea>
        </mat-form-field>
        <div class="alert-media-attach">
            <app-media-attachment
                #uploader
                [maxImgCount]="10"
                formControlName="media_ids"
                [scope]="AppScope.ALERT"
                [mediaIds]="alertForm.get('media_ids').value"
                [isCreateForm]="true"
            ></app-media-attachment>
        </div>
        <div class="action">
            <button
                *ngIf="!(saving | async)"
                [disabled]="!alertForm.value.subject && !alertForm.value.description"
                fxFlex="0 0 auto"
                class="mobile-button"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n>Send report</span>
            </button>
            <ng-container *ngIf="saving | async">
                <app-saving-button></app-saving-button>
            </ng-container>
        </div>
    </form>
</div>
