import { Cast, CastType, IDataModel, Ignore, InitialValue, Model } from '@klickdata/core/application';
import { User, UserData } from '@klickdata/core/user/src/user.model';

export interface CollectionData extends IDataModel {
    id?: number;
    scope_id?: number;
    created_at?: string;
    title?: string;
    customer_id?: number;
    public?: boolean;
    default?: boolean;
    set_ids?: number[];
    set_attach?: number[];
    set_detach?: number[];
    sync_all_set?: number[];
    title_translation?: { [key: string]: string };
}
export class Collection extends Model<CollectionData> {
    id: number;
    scope_id: number;
    @Cast(CastType.MOMENT)
    created_at: moment.Moment;
    customer_id: number;
    public: boolean;
    title: string;
    default: boolean;
    set_ids: number[];
    set_attach: number[];
    set_detach: number[];
    sync_all_set: number[];
    title_translation: { [key: string]: string };
}
