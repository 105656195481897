import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MessageSavedComponent, MessageService } from '@klickdata/core/message';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { UserNotesService } from '@klickdata/core/user-notes';
import { UserNotes } from '@klickdata/core/user-notes/src/user-notes.model';
import { Utils } from '@klickdata/core/util';
import { Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { UserNotesListingService } from '../user-notes-listing.service';

@Component({
    selector: 'app-notes-resource-listing-owned',
    templateUrl: './notes-resource-listing-owned.component.html',
    styleUrls: ['./notes-resource-listing-owned.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    providers: [UserNotesListingService],
})
export class NotesResourceListingOwnedComponent implements OnDestroy, AfterViewInit, OnInit {
    @Input() authUser: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public dataSource = new TableSource<UserNotes>();
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    public columns = ['created_at', 'notes', 'reciever', 'privacy', 'context', 'expand'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    public predefinedTimeSpentOptions: SelectFilterOption[];
    public expandedRow: any | null;

    constructor(
        protected mobileService: MobileService,
        protected notesService: UserNotesService,
        protected userNotesListingService: UserNotesListingService,
        protected message: MessageService
    ) {
        this.predefinedTimeSpentOptions = Utils.getPredefinedTimeSpentOptions();
    }
    ngOnInit(): void {
        this.dataSource.service = this.userNotesListingService;
    }
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        if (this.filter) {
            this.filter.setActiveFilter(GlobalFilterProperty.SEARCH);
            this.filter.createOrUpdateFilter([
                new Filter('eager', ['users,groups,academy,resource']),
                new Filter('author', [this.authUser.id]),
            ]);
        }
    }
    public onResClick(res: string) {
        this.filter.setActiveFilterWithQuery(GlobalFilterProperty.SEARCH, res);
    }
    newNote() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_NOTE,
            data: {
                status: 'create',
            },
        });
        this.mobileService
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'userNote'),
                takeUntil(this.destroy),
                map((data) => data.note)
            )
            .subscribe((note) => {
                if (note) {
                    this.dataSource.add(note);
                    this.message.openMessage(MessageSavedComponent, $localize`Note updated successfully`);
                }
            });
    }
    editNote(note: UserNotes) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_NOTE,
            data: {
                note: note,
                status: 'update',
            },
        });
        this.mobileService
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'userNote'),
                takeUntil(this.destroy),
                map((data) => data.note)
            )
            .subscribe((note) => {
                if (note) {
                    this.dataSource.replace(note);
                    this.message.openMessage(MessageSavedComponent, $localize`Note added successfully`);
                }
            });
    }
    deleteNote(note: UserNotes) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: $localize`Delete Note`,
                contentBody: $localize`Are you sure you want to delete this note?`,
                positiveBtn: $localize`Delete Note`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.notesService.deleteUserNote(note.id))
            )
            .subscribe((res) => {
                if (res) {
                    this.dataSource.removeById(note.id);
                    this.message.openMessage(MessageSavedComponent, $localize`Note deleted successfully`);
                }
            });
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
