<form
    [ngClass]="{ 'mobile-search-form': isMobile | async, onboarding: inOnboarding }"
    class="global-search-form-field search-from-onboarding"
    (submit)="onSubmit()"
>
    <mat-form-field floatLabel="never" class="example-form-field">
        <mat-label i18n>Search for ...</mat-label>
        <input
            #searchInput
            matInput
            type="text"
            #trigger="matAutocompleteTrigger"
            (keyup.enter)="autocompleteTrigger?.closePanel(); trigger?.closePanel()"
            [formControl]="searchControl"
            [matAutocomplete]="auto"
        />
        <app-kd-logo-loading *ngIf="loading" class="search-kd-loader" matSuffix size="small"></app-kd-logo-loading>
        <button
            class="search-icon-size mr-1"
            *ngIf="searchInput.value.length"
            (click)="searchInput.value = ''"
            mat-button
            matSuffix
            mat-icon-button
            aria-label="Clear"
            type="button"
        >
            <mat-icon class="mr-0">clear</mat-icon>
        </button>
        <button class="search-icon-size" mat-button matSuffix mat-icon-button aria-label="Clear" type="submit">
            <mat-icon>search</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete" (opened)="autocompleteScroll()">
            <mat-option *ngIf="searchTerm && !loading" (click)="onSubmit()">
                <mat-icon class="option-icon">search</mat-icon>
                <span>{{ searchTerm }}</span>
            </mat-option>
            <mat-option (click)="showSuggestedResults(result)" *ngFor="let result of suggestedResults$ | async">
                <ng-container *ngIf="AppScope.isResource(result.scope_id) || result.scope_id === AppScope.USERS">
                    <mat-icon
                        *ngIf="result.scope_id !== AppScope.SCORM"
                        class="option-icon"
                        fontSet="klickdata"
                        [fontIcon]="result.scope_id | typeFontIcon : result.scope_id"
                    ></mat-icon>
                    <mat-icon class="scorm-search-icon" *ngIf="result.scope_id === AppScope.SCORM">{{
                        result.scope_id | typeFontIcon : result.scope_id
                    }}</mat-icon>
                </ng-container>
                <ng-container
                    *ngIf="
                        result.scope_id === AppScope.CATEGORIES ||
                        result.scope_id === AppScope.SECTIONS ||
                        result.scope_id === AppScope.FOLDERS ||
                        result.scope_id === AppScope.CUSTOMERS ||
                        result.scope_id === AppScope.GROUPS
                    "
                >
                    <mat-icon>{{ result.scope_id | typeFontIcon : result.scope_id }}</mat-icon>
                </ng-container>
                <ng-container *ngIf="result.scope_id === AppScope.TAGS">
                    <mat-icon class="material-icons-outlined option-icon">{{
                        result.scope_id | typeFontIcon : result.scope_id
                    }}</mat-icon>
                </ng-container>
                <ng-container *ngIf="result.scope_id === AppScope.QUOTE">
                    <mat-icon>format_quote</mat-icon>
                </ng-container>
                <ng-container *ngIf="result.scope_id === AppScope.POST">
                    <mat-icon>speaker_notes</mat-icon>
                </ng-container>
                <span class="search-text">
                    <span *ngIf="result.item" [matTooltip]="result.item.name"> {{ result.item.name }} - </span>
                    <span [matTooltip]="result.label"> {{ result.label }}</span>
                </span>
            </mat-option>
            <mat-option class="sugg-loader-option" *ngIf="loadingSuggestedResults">
                <app-kd-logo-loading size="small"></app-kd-logo-loading>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
