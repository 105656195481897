import { Resource } from '@klickdata/core/resource/src/resource.model';
import { Cast, CastType, IDataModel, Ignore, Model } from '@klickdata/core/application';
import * as moment from 'moment';
import { User, UserData } from '@klickdata/core/user/src/user.model';
import { CustomerData } from '@klickdata/core/customer';
import { Group, GroupData } from '@klickdata/core/group';
import { ResourceData } from '@klickdata/core/resource';
import { Observable } from 'rxjs';

export enum UserNotesTypes {
    DEFAULT_NOTE = 'defaultNote',
    USER_NOTE = 'UserNote',
    GROUP_NOTE = 'groupNote',
}

export interface UserNotesData extends IDataModel {
    id?: number;
    created_at?: string;
    scope_id?: number;
    action?: string;
    subject_title?: string;
    reciever?: any;
    privacy?: NotePrivacy;
    user_ids?: number[];
    group_ids?: number[];
    academy_id?: number;
    resource_id?: number;
    body?: string;
    media_ids?: number[];
    author?: UserData;
    creator?: User;
    comments?: HrNotes[];
    public?: boolean;
    academy?: CustomerData;
    users?: UserData[];
    groups?: GroupData[];
    noteUsers?: User[];
    noteGroups?: Group[];
    resource?: ResourceData;
    noteResource?: Resource;
    eager?: string;
    task_id?: number;
    user_id?: number;
}

export class UserNotes extends Model<UserNotesData> {
    id: number;
    @Cast(CastType.MOMENT)
    created_at: string;
    scope_id: number;
    action: string;
    subject_title: string;
    reciever: any;
    privacy: NotePrivacy;
    user_ids: number[];
    group_ids: number[];
    academy_id: number;
    resource_id: number;
    body: string;
    media_ids: number[];
    author: UserData;
    creator: User;
    comments: HrNotes[];
    public: boolean;
    academy: CustomerData;
    users: UserData[];
    groups: GroupData[];
    noteUsers: User[];
    noteGroups: Group[];
    noteResource: Resource;
    resource: ResourceData;
    eager: string;
    @Ignore()
    author$: Observable<User>;
}
export interface NotePrivacy {
    value: string;
    label: string;
}
export interface HrNotes {
    id: number;
    created_at: string;
    author: UserData;
    comment: string;
    media_ids?: number[];
}
