import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { ResourceStaffRoles } from '@klickdata/core/resource/src/types.enum';
import { User, UserService } from '@klickdata/core/user';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, switchMap, takeUntil } from 'rxjs/operators';

export interface SearchUsersParams {
    limit?: number;
    customerId?: number;
    role?: ResourceStaffRoles;
    userRole?: string;
    eager?: string[];
}
@Component({
    selector: 'app-user-chip-select',
    templateUrl: './user-chip-select.component.html',
    styleUrls: ['./user-chip-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UserChipSelectComponent),
            multi: true,
        },
    ],
})
export class UserChipSelectComponent implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit {
    @Input() titleLabel: string;
    @Input() hint: string;
    @Input() chipRemovable = true;
    @Input() controlName: boolean;
    @Input() hasDrafts: boolean;
    @Input() defaultMe: boolean;
    @Input() maxUsersCount = 1;
    @Input() searchUsersParams: SearchUsersParams;
    @Output() onNotifyUsers: EventEmitter<{ users: User[]; draftUsers: string[] }> = new EventEmitter<{
        users: User[];
        draftUsers: string[];
    }>();
    public userSearch$: Observable<User[]>;
    public hideInput: boolean;
    @ViewChild('userInput') userRecieverInput: ElementRef<HTMLInputElement>;
    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
    public userCtrl = new FormControl('');
    public destroy: Subject<boolean> = new Subject<boolean>();
    public draftUsers: string[] = [];
    private _users: User[] = [];
    get users(): User[] {
        return this._users;
    }
    set users(value: User[]) {
        this._users = value;
        this.propagateChange(this._users.map((user) => user.id));
    }

    constructor(
        protected userService: UserService,
        protected changeRef: ChangeDetectorRef,
        protected injector: Injector,
        protected auth: AuthService
    ) {}

    ngOnInit() {
        this.userSearch$ = this.userCtrl.valueChanges.pipe(
            filter((term: string) => typeof term === 'string' && term != ''),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((term) =>
                term.trim()
                    ? this.userService.getLightMasterUsers({ ...{ query: term }, ...this.searchUsersParams })
                    : of(<User[]>[])
            ),
            takeUntil(this.destroy)
        );
    }
    ngAfterViewInit(): void {
        if (this.defaultMe && !this.users.length) {
            this.auth
                .getUser()
                .pipe(first())
                .subscribe((user) => {
                    this.users = [user];
                    this.applyUpdates();
                });
        }
    }
    removeUser(user: User | string): void {
        if (typeof user === 'string') {
            const index = this.draftUsers.findIndex((u) => u === user);
            if (index !== -1) {
                this.draftUsers.splice(index, 1);
            }
        } else {
            const index = this.users.findIndex((u) => u.id === user.id);
            if (index !== -1) {
                this.users.splice(index, 1);
                this.propagateChange(this.users.map((user) => user.id));
            }
        }
        this.applyUpdates();
    }
    public selectUser(selected: User) {
        if (this.users.findIndex((user) => user.id === selected.id) === -1) {
            this.users.push(selected);
            this.propagateChange(this.users.map((user) => user.id));
            this.userRecieverInput.nativeElement.value = '';
            this.userCtrl.setValue('');
        }
        this.applyUpdates();
    }
    addDraftUser() {
        if (this.draftUsers.findIndex((user) => user === this.userCtrl.value) === -1) {
            this.draftUsers.push(this.userCtrl.value.replace(/,+/g, ',').replace(/^,|,$/g, '').trim());
            this.userRecieverInput.nativeElement.value = '';
            this.userCtrl.setValue('');
            this.autocomplete.closePanel();
        }
        this.applyUpdates();
    }
    public propagateChange = (_: any) => {};
    private applyUpdates() {
        this.onNotifyUsers.emit({ users: this.users, draftUsers: this.draftUsers });
        this.hideInput = this.users.length >= this.maxUsersCount;
        this.changeRef.markForCheck();
    }

    public writeValue(value: (number | User)[]): void {
        if (value?.length && !(value[0] instanceof User)) {
            this.userService
                .getUsers(<number[]>value, false)
                .pipe(takeUntil(this.destroy))
                .subscribe((users) => {
                    this._users = users || [];
                    this.hideInput = this.users.length >= this.maxUsersCount;
                    this.changeRef.markForCheck();
                    this.resetFormControl();
                });
        } else if (value?.length && value[0] instanceof User) {
            this._users = <User[]>value || [];
            this.hideInput = this.users.length >= this.maxUsersCount;
            this.changeRef.markForCheck();
            this.resetFormControl();
        }
    }

    private resetFormControl() {
        const control = this.injector.get(NgControl)?.control;
        if (control instanceof FormControl) {
            FormHelper.resetForm(control);
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}

    public stopPropagation(ev: MouseEvent): void {
        ev.stopPropagation();
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
