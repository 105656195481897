import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { Post } from '@klickdata/core/post';
import { TableSource, TableFilterComponent } from '@klickdata/core/table';

@Component({
    selector: 'app-menu-side-custom-fields-listing',
    templateUrl: './menu-side-custom-fields-listing.component.html',
    styleUrls: ['./menu-side-custom-fields-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideCustomFieldsListingComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    selection = new SelectionModel<Post>(true, []);
    public dataSource = new TableSource<Post>();
    public columns = ['select', 'columns', 'title', 'tags', 'status', 'created_at', 'published_at', 'language_id'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    constructor() {}

    ngOnInit(): void {}
    onSubmit() {}
}
