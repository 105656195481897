import { IDataModel } from '@klickdata/core/application/src/model/model-interface';

export enum QuestionTypes {
    CHECKBOX = 'checkbox',
    RADIO = 'radio',
    SELECT = 'select',
    INPUT = 'input',
    TEXTAREA = 'textarea',
    DATE = 'date',
    TIME = 'time',
    FILE = 'file',
    WOK = 'wok',
    AI = 'ai',
}

export enum ResourceMediaTypes {
    LINK = 'link',
    PROMPT = 'prompt',
    FILE = 'file',
}

export interface QuestionTypeData extends IDataModel {
    value?: string;
    label?: string;
}

export class QuestionType {
    public value: string;
    public label: string;

    constructor(data?: QuestionTypeData) {
        if (data) {
            this.value = data.value;
            this.label = data.label;
        }
    }
    static questionTypesWhichHaveAlts() {
        return [QuestionTypes.RADIO, QuestionTypes.CHECKBOX];
    }

    static questionTypeIcon(type: QuestionTypes): string {
        switch (type) {
            case QuestionTypes.RADIO:
                return 'radio_button_checked';
            case QuestionTypes.CHECKBOX:
                return 'check_box';
            case QuestionTypes.INPUT:
                return 'edit_note';
            case QuestionTypes.AI:
                return 'smart_toy';
        }
    }
}
