import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldType } from '../custom-field.model';

@Component({
    selector: 'app-custom-fields-wrapper',
    templateUrl: './custom-fields-wrapper.component.html',
    styleUrls: ['./custom-fields-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsWrapperComponent {
    @Input() customFieldForm: FormGroup;
    FieldType = FieldType;
}
