import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { Resource, AppScope } from '@klickdata/core/resource';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
    selector: 'app-add-surveys',
    templateUrl: './add-surveys.component.html',
    styleUrls: ['./add-surveys.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSurveysComponent implements OnInit {
    @Input() selection: number[] = [];
    @Output() add: EventEmitter<Resource> = new EventEmitter<Resource>();
    AppScope = AppScope;

    public isAdmin$: Observable<boolean>;
    constructor(protected auth: AuthService) {}
    ngOnInit() {
        this.isAdmin$ = this.auth.getUser().pipe(
            first(),
            map(user => user.isAdmin())
        );
    }
}
