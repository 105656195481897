<form [formGroup]="quoteForm" fxLayout="row wrap" fxFlex="100%" fxLayoutAlign="space-between stretch">
    <div fxFlex="0 0 70%" fxFlex.lt-md="100%" class="main-row" fxLayout="row wrap" fxLayoutAlign="start start">
        <mat-tab-group #mainTabs fxFlex="grow" class="main-creator-tabs">
            <mat-tab label="Content" i18n-label style="max-height: 100%">
                <div class="main-wrapper">
                    <div fxLayout="column" fxLayoutGap="5px">
                        <span class="quotee-label" i18n> Quote text </span>
                        <div class="w-100" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" class="w-100 k3-form">
                                <textarea
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="4"
                                    cdkAutosizeMaxRows="15"
                                    #input
                                    type="text"
                                    matInput
                                    placeholder="Please add a quote text"
                                    i18n-placeholder
                                    formControlName="quote"
                                ></textarea>
                            </mat-form-field>
                        </div>
                        <button
                            [ngClass]="{ 'on-loading': loading }"
                            class="follow-btn"
                            mat-button
                            (click)="addAIQuote()"
                        >
                            <mat-icon class="add-ai-btn">smart_toy</mat-icon>
                            <span i18n>Generate Quote</span>
                        </button>
                    </div>
                    <div class="mt-1" fxLayout="column" fxLayoutGap="10px">
                        <span class="quotee-label" i18n> Quotee of the quote </span>
                        <app-resource-educator-handler
                            searchPlaceholder="Search quotee"
                            educatorLabel="Quotee"
                            i18n-educatorLabel
                            i18n-searchPlaceholder
                            addingLabel="Add quotee"
                            i18n-addingLabel
                            [educatorRole]="ResourceStaffRoles.QUOTEE"
                            class="w-100 contactor-person quotee-wrap"
                            [formControl]="quoteForm.get('staff').get('quotee') | cast"
                            [maxNumberOfEducatore]="1"
                            (onEducatorClick)="onQuoteeClick($event)"
                        ></app-resource-educator-handler>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Extra" i18n-label style="max-height: 100%">
                <mat-accordion multi="false" fxFlex="0 1 100%" displayMode="flat">
                    <mat-expansion-panel formGroupName="medias" #expansionPanel class="user-manage-quote-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>add_photo_alternate</mat-icon>
                                <div i18n="@@quoteImg">Quote image</div>
                                <mat-icon class="icon-explain" matTooltip="It's the image of the quote" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: quoteForm.get('medias')?.get('cover').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayoutAlign="center center">
                            <app-media
                                *ngIf="!quoteForm.get('medias.cover').value"
                                formControlName="cover"
                                dropLabel="Drop quote image"
                                i18n-dropLabel="@@dropQuoteImage"
                                [hasAIUploader]="true"
                                [scope]="AppScope.QUOTE"
                            ></app-media>
                            <app-image-view
                                *ngIf="quoteForm.get('medias.cover').value as mediaId"
                                [media_id]="mediaId"
                                [proportional]="false"
                            >
                                <button
                                    class="remove-image"
                                    mat-icon-button
                                    color="warn"
                                    matTooltip="Remove image"
                                    i18n-matTooltip
                                    (click)="quoteForm.get('medias')?.get('cover').setValue(null)"
                                >
                                    <mat-icon class="md-24">delete_outline</mat-icon>
                                </button>
                            </app-image-view>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-quote-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>wysiwyg</mat-icon>
                                <div i18n>Quote source</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="This field would store the source of the quote, such as a book, article, or speech."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: quoteForm.get('source').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayoutAlign="center center">
                            <mat-form-field appearance="outline" class="w-100 k3-form">
                                <textarea
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="2"
                                    cdkAutosizeMaxRows="15"
                                    #input
                                    type="text"
                                    matInput
                                    placeholder="Add the quote source"
                                    i18n-placeholder
                                    formControlName="source"
                                ></textarea>
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-quote-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>dataset_linked</mat-icon>
                                <div i18n>Source link</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="This field would store the link related to source of the quote."
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: quoteForm.get('source_link').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayoutAlign="center center">
                            <mat-form-field appearance="outline" class="w-100 k3-form">
                                <textarea
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="2"
                                    cdkAutosizeMaxRows="15"
                                    #input
                                    type="text"
                                    matInput
                                    placeholder="Add the quote source link"
                                    i18n-placeholder
                                    formControlName="source_link"
                                ></textarea>
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-quote-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>document_scanner</mat-icon>
                                <div i18n>Source file</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="It's the file of the quote's source"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: quoteForm.get('source_file').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayoutAlign="center center">
                            <app-media
                                *ngIf="!quoteForm.get('source_file')?.value"
                                formControlName="source_file"
                                dropLabel="Drop quote source file"
                                i18n-dropLabel
                            ></app-media>
                            <app-image-view
                                *ngIf="quoteForm.get('source_file')?.value as mediaId"
                                [media_id]="mediaId"
                                paddingBottom="200px"
                            >
                                <button
                                    *ngIf="quoteForm.get('source_file')?.value"
                                    class="remove-image"
                                    mat-icon-button
                                    color="warn"
                                    matTooltip="Remove image"
                                    i18n-matTooltip
                                    (click)="quoteForm.get('source_file').setValue(null)"
                                >
                                    <mat-icon class="md-24">delete_outline</mat-icon>
                                </button>
                            </app-image-view>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-quote-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>format_indent_increase</mat-icon>
                                <div i18n>Transcript</div>
                                <mat-icon
                                    class="icon-explain"
                                    matTooltip="Transcript is the text from where the quote is taken. Click on the Quote generating button to extract a quote"
                                    i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: quoteForm.get('transcript').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayoutAlign="center center" fxLayout="column">
                            <mat-form-field appearance="outline" class="w-100 k3-form">
                                <textarea
                                    cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize"
                                    cdkAutosizeMinRows="4"
                                    cdkAutosizeMaxRows="15"
                                    #input
                                    type="text"
                                    matInput
                                    placeholder="Please add a text from where you can find the quote"
                                    i18n-placeholder
                                    formControlName="transcript"
                                ></textarea>
                            </mat-form-field>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-quote-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>grading</mat-icon>
                                <div i18n>Resource</div>
                                <mat-icon class="icon-explain" matTooltip="Attach a resource to a quote" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: quoteForm.get('transcript').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayoutAlign="center center" fxLayout="column">
                            <div class="input-wrapper default-note context-res" fxLayout="column">
                                <mat-form-field class="small-field mt-1" appearance="fill">
                                    <input
                                        type="text"
                                        matInput
                                        #resourceInput
                                        i18n-placeholder
                                        placeholder="Attach the quote to a resource"
                                        [formControl]="contentCtrl"
                                        [matAutocomplete]="auto"
                                    />
                                    <mat-autocomplete
                                        #auto="matAutocomplete"
                                        (optionSelected)="selectResource($event, auto)"
                                    >
                                        <mat-option
                                            class="context-res"
                                            *ngFor="let resource of filteredContent | async"
                                            [value]="resource"
                                        >
                                            <div
                                                class="selct-mat-msg"
                                                fxLayout="row"
                                                fxLayoutAlign="start center"
                                                fxLayoutGap="5px"
                                            >
                                                <app-image-view
                                                    [smallSpinner]="true"
                                                    [media_id]="resource.media$"
                                                    [proportional]="true"
                                                ></app-image-view>
                                                <span>{{ resource.title }}</span>
                                            </div>
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <div
                                    fxLayout="row wrap"
                                    fxLayoutAlign="space-between start"
                                    class="mt-1"
                                    fxLayoutGap="10px"
                                >
                                    <div class="res-selected-context" *ngFor="let relatedResource of relatedResources">
                                        <div
                                            class="selct-mat-msg"
                                            fxLayout="row"
                                            fxLayoutAlign="start center"
                                            fxLayoutGap="5px"
                                        >
                                            <app-image-view
                                                [smallSpinner]="true"
                                                [media_id]="relatedResource.media$"
                                                [proportional]="true"
                                                paddingBottom="60px"
                                            ></app-image-view>
                                            <span>{{ relatedResource.title }}</span>
                                        </div>

                                        <button mat-icon-button (click)="removeResource(relatedResource)">
                                            <mat-icon>cancel</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel #expansionPanel class="user-manage-quote-expantion">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <mat-icon>category</mat-icon>
                                <div i18n>Type</div>
                                <mat-icon class="icon-explain" matTooltip="Set the type of the quote" i18n-matTooltip
                                    >help_outline</mat-icon
                                >
                            </mat-panel-title>
                            <mat-panel-description fxLayoutAlign="end center">
                                <span
                                    matTooltip="Indicator of field change"
                                    i18n-matTooltip
                                    class="cell-indicator"
                                    [ngClass]="{ green: quoteForm.get('type').dirty }"
                                ></span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div fxLayoutAlign="center start" fxLayout="column">
                            <div class="mt-1" fxLayoutAlign="start center" fxLayoutGap="30px">
                                <span class="video-source-labbel">Quote type:</span>
                                <mat-radio-group
                                    fxLayoutAlign="start center"
                                    fxLayoutGap="70px"
                                    fxLayoutGap.lt-sm="20px"
                                    formControlName="type"
                                >
                                    <mat-radio-button value="general">
                                        <span i18n>General</span>
                                    </mat-radio-button>
                                    <mat-radio-button value="review">
                                        <span i18n>Review</span>
                                    </mat-radio-button>
                                    <mat-radio-button value="other">
                                        <span i18n>Other</span>
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div
        fxFlex="0 0 28%"
        fxFlex.lt-md="100%"
        class="main-row"
        fxLayoutGap="10px"
        fxLayout="column"
        fxLayoutAlign="start start"
    >
        <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <span>Save options</span>
            <div fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-buttontype="button" class="specify-btn" mat-icon-button (click)="list()">
                    <mat-icon>list</mat-icon>
                </button>
                <button
                    matTooltip="Check what you can improve before save."
                    i18n-matTooltip
                    type="button"
                    mat-icon-button
                    (click)="checkEfficiency()"
                >
                    <mat-icon>task_alt</mat-icon>
                </button>
            </div>
        </div>
        <button
            *ngIf="!(onLoading | async)"
            [ngClass]="{ publish: quoteForm.value.publish, 'disabled-submit': quoteForm.pristine }"
            class="sub-btn"
            mat-button
            (click)="save()"
        >
            <mat-icon>save</mat-icon>
            <div fxLayoutAlign="start start" fxLayoutGap="5px">
                <span i18n>Save</span>
                <span *ngIf="!quoteForm.value.publish" i18n>as private</span>
                <span *ngIf="quoteForm.value.publish" i18n>as public</span>
            </div>
        </button>
        <button
            [disabled]="true"
            *ngIf="onLoading | async"
            [ngClass]="{ publish: quoteForm.value.public || quoteForm.value.published }"
            class="sub-btn"
            mat-button
        >
            <ng-container [ngTemplateOutlet]="loadingTemp"></ng-container>
        </button>
        <div class="w-100" fxLayout="column" fxLayoutAlign="start start">
            <mat-checkbox #publishCheckbox formControlName="publish" color="primary" class="draft-checkbox">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon class="option">published_with_changes</mat-icon>
                    <span fxLayoutAlign="start center" fxLayoutGap="5px">
                        <span i18n>Publish as public content</span>
                    </span>
                </div></mat-checkbox
            >
            <mat-checkbox #publishCheckbox formControlName="public" color="primary" class="draft-checkbox">
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <mat-icon class="option">public</mat-icon>
                    <span fxLayoutAlign="start center" fxLayoutGap="5px">
                        <span i18n>Publish in academy</span>
                    </span>
                </div></mat-checkbox
            >
        </div>
        <div class="lang-select-profile mt-1">
            <span i18n="@@displayLanguage">Display Language</span>
            <app-language-selector
                formControlName="language_id"
                [showLabel]="true"
                [disableRipple]="true"
            ></app-language-selector>
        </div>
        <app-tag-chip-select
            class="w-100"
            placeholder="Add Tag"
            i18n-placeholder
            [language_id]="quoteForm.value.language_id"
            formControlName="tag_ids"
        ></app-tag-chip-select>
        <ng-container formGroupName="staff">
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="0 1 100%" class="manager-wrapper">
                <div class="w-100" fxLayout="column" fxLayoutAlign="start start">
                    <div class="manager-label">
                        <span i18n>Publisher</span>
                        <mat-icon
                            class="icon-explain"
                            matTooltip="The academy which publish this resource."
                            i18n-matTooltip
                            >help_outline</mat-icon
                        >
                    </div>
                    <app-resource-educator-handler
                        class="publisher-edu-handler"
                        educatorLabel="Publisher"
                        [resource]="resource"
                        [maxNumberOfEducatore]="1"
                        formControlName="publisher"
                        searchPlaceholder="Search existing publishers"
                        i18n-searchPlaceholder
                        removeBtn="autorenew"
                        [educatorRole]="ResourceStaffRoles.PUBLISHER"
                        i18n-addingLabel
                        i18n-educatorLabel
                        addingLabel="Add a new publisher"
                    ></app-resource-educator-handler>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="0 1 100%" class="manager-wrapper mt-1">
                <div class="w-100" fxLayout="column" fxLayoutAlign="start start">
                    <div class="manager-label">
                        <span i18n>Author</span>
                        <mat-icon class="icon-explain" matTooltip="The creator of the quote" i18n-matTooltip
                            >help_outline</mat-icon
                        >
                    </div>
                    <app-resource-educator-handler
                        searchPlaceholder="Search"
                        i18n-searchPlaceholder
                        addingLabel="Add quote creator"
                        i18n-addingLabel
                        [educatorRole]="ResourceStaffRoles.MANAGER"
                        class="w-100 contactor-person"
                        formControlName="manager"
                        [maxNumberOfEducatore]="1"
                        educatorLabel="Author"
                        i18n-educatorLabel
                    ></app-resource-educator-handler>
                </div>
            </div>
        </ng-container>
        <div class="tools-lower-actions-btns" fxLayoutAlign="space-between start">
            <div class="infor-res-wrap" *ngIf="quote && quote.id" fxLayout="column" fxLayoutAlign="start start">
                <div class="info-res" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <mat-icon>edit</mat-icon>
                        <span i18n>Created:</span>
                    </div>
                    <span class="inf">{{ quote.created_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                </div>
                <div
                    *ngIf="quote.published_at"
                    class="info-res"
                    fxLayout="row"
                    fxLayoutAlign="start center"
                    fxLayoutGap="5px"
                >
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <mat-icon>published_with_changes</mat-icon>
                        <span i18n>Published:</span>
                    </div>
                    <span class="inf">{{ quote.published_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                </div>
                <div class="info-res" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <div fxLayoutAlign="start center" fxLayoutGap="5px">
                        <mat-icon>update</mat-icon>
                        <span i18n>Updated:</span>
                    </div>
                    <span class="inf">{{ quote.updated_at | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                </div>
            </div>
            <div *ngIf="quote && quote.id" fxLayout="row" fxLayoutAlign="end center">
                <button
                    matTooltip="Delete quote"
                    i18n-matTooltip
                    mat-icon-button
                    type="button"
                    (click)="delete()"
                    *ngIf="quote"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</form>
<ng-template #loadingTemp>
    <div fxLayout="row" fxLayoutAlign="left" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
