import { IDataModel, Model } from '@klickdata/core/application/src/model/model-interface';

export interface ResourceTypeData extends IDataModel {
    value?: string;
    lang?: string;
    description?: string;
    scope_id?: number;
    label?: string;
}

export class ResourceType extends Model<ResourceTypeData> {
    public id: number;
    value: string;
    lang: string;
    label: string;
    description: string;
    scope_id: number;
}
