<div fxFlex="0 0 100%" fxLayout="row wrap" class="assignments-select">
    <app-sticky-header
        class="sticky-header cats-sticky"
        [title]="data.title"
        [context]="data.context"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss()"
        [savingStatus]="saving"
    ></app-sticky-header>
    <div class="user-manage-cp-access" fxFlex="0 0 95%" ngClass.xs="xs">
        <app-categories-select
            [referenceFilter]="data.referenceFilter"
            [accessForm]="assignForm"
            sync_all_key="sync_all_categories"
            attachKey="categories_attach"
            detachKey="categories_detach"
            [appliedFilters]="data.appliedFilters"
            [defaultSorting]="data.defaultSorting"
            (onLatestData)="allCategories = $event"
        ></app-categories-select>
    </div>
</div>
