import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import { RequestBuilderService } from '@klickdata/core/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VTQ, VTQData } from './vtq.model';

@Injectable({
    providedIn: 'root',
})
export class VTQService {
    protected vTQUrl: string;
    private activeVTQ: BehaviorSubject<VTQ> = new BehaviorSubject<VTQ>(null);

    constructor(protected config: ConfigService, protected builder: RequestBuilderService) {
        this.vTQUrl = `${this.config.config.apiUrl}ai/material`;
    }
    public setActiveVTQ(data: VTQ) {
        this.activeVTQ.next(data);
    }
    public getActiveVTQ(): Observable<VTQ> {
        return this.activeVTQ.asObservable();
    }
    public create(data: VTQData): Observable<VTQ> {
        return this.builder
            .post<VTQData>(`${this.vTQUrl}`, data)
            .request()
            .pipe(map((res) => new VTQ(res.data)));
    }
}
