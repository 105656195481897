import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
    selector: 'app-table-filter-expansion-panel',
    templateUrl: './table-filter-expansion-panel.component.html',
    styleUrls: ['./table-filter-expansion-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFilterExpansionPanelComponent implements OnInit {
    @Input() hasTopBorder = true;
    @Input() expanded = true;
    @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;
    constructor() {}

    ngOnInit(): void {}
}
