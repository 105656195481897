import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';

@Component({
    selector: 'app-breadcrumb-filter',
    templateUrl: './breadcrumb-filter.component.html',
    styleUrls: ['./breadcrumb-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: FilterBase, useExisting: BreadcrumbFilterComponent }],
})
export class BreadcrumbFilterComponent extends FilterBase {
    get property(): string {
        return 'breadcrumbs';
    }
    @Input()
    public set item(item: { id: number; title: string }) {
        if (item) {
            const alreadyAdded = this.breadcrumbs.some((bc) => bc.id === item.id);
            if (!alreadyAdded) {
                this._breadcrumbs = this.breadcrumbs.concat(item);
                this.updateFilter();
            }
        }
    }
    private _breadcrumbs: { id: number; title: string }[] = [];
    public get breadcrumbs() {
        return this._breadcrumbs;
    }
    @Output() filterChange: EventEmitter<Filter<number>> = new EventEmitter<Filter<number>>();
    protected _filter: Filter<number> = { property: this.property, items: [] };
    constructor(protected cdRef: ChangeDetectorRef) {
        super();
    }

    updateFilter() {
        this._filter.items = this.breadcrumbs.map((item) => item.id);
        this.filterChange.emit(this._filter);
    }

    select(position: number) {
        this._breadcrumbs = this.breadcrumbs.splice(0, position);
        this.cdRef.markForCheck();
        this.updateFilter();
    }

    clear() {
        if (this.breadcrumbs.length) {
            this.select(0);
        }
    }

    isEmpty(): boolean {
        return !this.breadcrumbs.length;
    }
}
