import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { Customer } from '@klickdata/core/customer';
import { Resource, ResourceCategory, ResourceTag, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItem } from '@klickdata/core/resource-item';
import { User } from '@klickdata/core/user';
import moment from 'moment';
import { Observable } from 'rxjs';
import { ResourceDetailsInfoComponent } from '../resource-details-info/resource-details-info.component';

@Component({
    selector: 'app-resource-details-base',
    templateUrl: './resource-details-base.component.html',
    styleUrls: ['./resource-details-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceDetailsBaseComponent {
    @Input() resource: Resource;
    @Input() user: User;
    @Input() customer: Customer;
    @Input() resourceItems: ResourceItem[];
    @Input() resourceCategories$: Observable<ResourceCategory[]>;
    @Input() resourceTags$: Observable<ResourceTag[]>;
    @Input() resourceType: ResourceTypes;
    @ViewChild(ResourceDetailsInfoComponent) infoComp: ResourceDetailsInfoComponent;
    ResourceTypes = ResourceTypes;
    public moment = moment();
    constructor() {}

    public get resourceIsAvailable(): boolean {
        return (
            this.resource.always_available ||
            (this.resource.start_date?.isBefore(this.moment) && this.resource.end_date?.isAfter(this.moment))
        );
    }
}
