<div *ngIf="authUser$ | async; let authUser" fxLayout="row wrap" fxLayoutAlign="center">
    <h3
        *ngIf="!user || (user && user.id == authUser.id)"
        fxFlex="0 0 100%"
        class="text-center"
        i18n="@@rewardCongratulations"
    >
        Congratulations, You have signed off the Course!
    </h3>
    <h3 *ngIf="user && user.id != authUser.id" fxFlex="0 0 100%" class="text-center">
        <span>{{ user.name }}</span>
        <span i18n> has signed off the Course!</span>
    </h3>
    <div class="mb-1" fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center">
        <mat-icon
            *ngIf="rewardType === 'Diploma'"
            color="primary"
            fontSet="klickdata"
            fontIcon="kd-icon-exam"
            class="diploma-icon"
        ></mat-icon>
        <mat-icon *ngIf="rewardType === 'Certification'" color="primary" class="diploma-icon">military_tech</mat-icon>
        <mat-icon *ngIf="rewardType === 'Participation'" color="primary" class="diploma-icon">school</mat-icon>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em" class="mb-2">
        <a
            fxFlex="0 0 auto"
            mat-raised-button
            color="primary"
            target="_blank"
            (click)="viewDiploma()"
            i18n="@@viewReward"
        >
            View reward!
        </a>
        <button class="repeat" (click)="repeatCourse.emit(true)" mat-raised-button>
            <span i18n="@@repeatCourse">Repeat Course</span>
        </button>
    </div>
</div>
