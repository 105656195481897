import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResourceItem } from '@klickdata/core/resource-item/src/resource-item';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { AnswerService } from '@klickdata/player/src/core/answers/answer.service';
import { PlayerService } from '@klickdata/player/src/core/player/player.service';
import { ResourceItemOpportunityService } from '@klickdata/player/src/core/resource-item/resource-item.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { Playlist } from './playlist';

/**
 * @deprecated
 */
@Injectable()
export class PlaylistService {
    public playlist: Playlist = null;
    public resourceId: number;
    public playListBehavior: BehaviorSubject<Playlist> = new BehaviorSubject(null);
    public progress: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(
        protected playerService: PlayerService,
        protected itemService: ResourceItemOpportunityService,
        protected answerService: AnswerService
    ) {}

    realInitPlayList(itemId: number, resource: Resource, occasionId: number) {
        if (occasionId && (itemId || resource?.start_item)) {
            (itemId
                ? this.itemService.getItem(itemId, occasionId)
                : this.answerService.getAllAnswers(occasionId).pipe(
                      switchMap(answers => {
                          if (answers.length > 0) {
                              const answer = answers.pop();
                              return this.itemService.getItem(answer.question_item_id, answer.opportunity_id);
                          } else {
                              return this.itemService.getItem(resource.start_item, occasionId);
                          }
                      })
                  )
            ).subscribe(item => {
                if (item) {
                    this.setUpPlaylist(item, resource.number_of_items);
                }
            });
        }
    }

    setUpPlaylist(item: ResourceItem, numberOfItems: number) {
        const data = [];
        for (let i = 1; i <= numberOfItems; i++) {
            if (i === item.index) {
                data.push({
                    index: i,
                    item: item,
                });
            } else {
                data.push({
                    index: i,
                    item: null,
                });
            }
        }
        this.playlist = new Playlist(data);
        this.playListBehavior.next(this.playlist);
        this.updateProgress(item);
    }

    getPlayList() {
        if (this.playlist) {
            return this.playlist;
        } else {
            return null;
        }
    }

    getCurrentIndex(item: ResourceItem) {
        if (item) {
            for (const list of this.playlist.playList) {
                if (list.item !== null) {
                    if (list.item.id === item.id) {
                        return list.index;
                    }
                }
            }
        }

        this.updateProgress(item);

        return 1;
    }

    getCurrentIndexOnLoaded(item: ResourceItem) {
        return this.playListBehavior.pipe(
            filter(plist => !!plist),
            map(plist => plist.playList.find(li => li.item?.id === item.id)?.index)
        );
    }

    updatePlaylist(item: ResourceItem) {
        let exit = false;

        this.playlist.playList.forEach((value, key) => {
            if (value.item !== null && !exit) {
                if (value.item.id === item.id) {
                    exit = true;
                }
            }
            if (value.item === null && !exit) {
                if (item.index === value.index) {
                    this.playlist.playList[key] = {
                        index: value.index,
                        item: item,
                    };
                    exit = true;
                    return value.index;
                }
            }
        });
        this.updateProgress(item);
    }

    updateProgress(item: ResourceItem) {
        const count: number = this.playlist.playList.length;
        this.getCurrentIndexOnLoaded(item).subscribe(currentIndex => {
            const value = (currentIndex - 1) / count;

            this.progress.next(value * 100);
        });
    }

    public getProgress(): Observable<number> {
        return this.progress.asObservable();
    }
}
