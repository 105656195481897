import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-add-link-dialog',
    templateUrl: './add-link-dialog.component.html',
    styleUrls: ['./add-link-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLinkDialogComponent {
    public urlPattern = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
    public linkUrl: FormControl = new FormControl('', [Validators.required, Validators.pattern(this.urlPattern)]);
    constructor(public dialogRef: MatDialogRef<AddLinkDialogComponent>, @Inject(MAT_DIALOG_DATA) protected data: any) {}
}
