export enum NoteCapability {
    NONE               = 0,
    VIEW_OWN_NOTES     = 1 << 0,
    MANAGE_OWN_NOTES   = 1 << 1,
    VIEW_GROUPS_NOTES  = 1 << 2,
    MANAGE_GROUP_NOTES = 1 << 3,
}
export namespace NoteCapability {
    export function getName(): string {
        return 'note';
    }
}
