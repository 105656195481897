import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Input, Output } from '@angular/core';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-notifier',
    templateUrl: './menu-side-notifier.component.html',
    styleUrls: ['./menu-side-notifier.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideNotifierComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    constructor() {}

    ngOnInit(): void {}
    clear(){}
}
