import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'innerHtml',
})
export class InnerHtmlPipe implements PipeTransform {
    transform(value: string, ...styleClasses: string[]): string {
        if (value) {
            return `<div class="${styleClasses?.join(' ') || 'inner-HTML'}"> ${value.replace(/\n/g, '<br>')} </div>`;
        } else {
            return;
        }
    }
}
