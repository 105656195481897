import { Directive } from '@angular/core';

export interface ITextCountable {
    readonly characters: number;
    readonly words: number;
}

@Directive({
    selector: '[textCountable]',
})
export abstract class TextCountableDirective implements ITextCountable {
    private _characters: number;
    private _words: number;
    get characters() {
        return this._characters;
    }
    get words() {
        return this._words;
    }
    protected setCounts(characters: number, words: number) {
        this._characters = characters;
        this._words = words;
    }
}
