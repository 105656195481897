import { Cast, CastType, IDataModel, Ignore, Model } from '@klickdata/core/application';
import { Prop } from '@klickdata/core/form';
import { Media } from '@klickdata/core/media/src/media.model';
import { ResourceTagData } from '@klickdata/core/resource';
import { Medias } from '@klickdata/core/resource/src/resource.model';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { Observable } from 'rxjs';

export interface FileData extends IDataModel {
    id: number;
    description?: string;
    title?: string;
    tags?: ResourceTagData[];
    tag_ids?: number[];
    created_at?: string;
    url?: string;
    visibility?: string;
    author?: {};
    created_by?: number;
    medias?: Medias;
    downloads?: {};
    priority?: string;
    files$?: Observable<Media[]>;
}

export class File extends Model<FileData> {
    id: number;
    description: string;
    title: string;
    tags: ResourceTagData[];
    tag_ids: number[];
    created_by: number;
    url: string;
    @Prop()
    visibility: string;
    public created_at: moment.Moment;
    author$: Observable<User>;
    @Cast(CastType.CLOSURE, Utils.mediasPayload)
    public medias: Medias;
    priority: string;
    @Ignore()
    downloads: {};
    @Ignore()
    files$: Observable<Media[]>;
}
