import { DatePipe } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Customer } from '@klickdata/core/customer';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Filter, SelectFilterOption } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { Widget } from '@klickdata/core/widget';
import { WidgetsService } from '@klickdata/core/widget/src/widget.service';
import { Observable, Subject, of } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-activity-log-sx-box',
    templateUrl: './activity-log-sx-box.component.html',
    styleUrls: ['./activity-log-sx-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityLogSxBoxComponent implements OnInit, OnDestroy {
    @Input() widget: Widget;
    @Input() expanded$: Observable<boolean>;
    @Input() model: Customer | User;
    public selectedFilter: any = 'all_time';
    public compareMode: boolean;
    @Input() public filtersOpened: boolean;
    public dateRangeClicked: boolean;
    public periodTitle: string;
    public predefinedTimeOptions: SelectFilterOption[];
    private destroy = new Subject<boolean>();
    @Input() activeAppliedDateFilters: Filter<string>[];

    constructor(
        protected cdr: ChangeDetectorRef,
        protected widgetsService: WidgetsService,
        protected mobileService: MobileService,
        @Inject(LOCALE_ID) public localeId: string
    ) {
        this.predefinedTimeOptions = this.prepareTimeOptions().filter((opt) => opt.value === this.selectedFilter);
    }

    ngOnInit(): void {
        this.periodTitle = $localize`from ${this.getFormatedDate(
            this.model.created_at.toString()
        )} to ${this.getFormatedDate()}`;

        if (this.activeAppliedDateFilters && this.activeAppliedDateFilters.length) {
            this.updateTimePeriod(this.activeAppliedDateFilters);
        }
    }
    compareChange(value?: any) {
        this.selectedFilter = value;
        this.dateRangeClicked = false;
        this.getWidgetByFilters(this.selectedFilter)
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe((widget) => {
                this.widget.total = widget && widget.total ? widget.total : 0;
                this.cdr.markForCheck();
            });
    }

    private getWidgetByFilters(selectedFilter?: string): Observable<Widget> {
        switch (selectedFilter) {
            case 'select_date_range':
                return this.getDatesFromUser();
            case 'all_time':
                this.periodTitle = $localize`from ${this.getFormatedDate(
                    this.model.created_at.toString()
                )} to ${this.getFormatedDate()}`;
                return this.widgetsService.getWidgetById(this.widget.id);
            default:
                this.periodTitle = $localize`from ${this.getFormatedDate(selectedFilter)} to ${this.getFormatedDate()}`;
                return this.widgetsService.getWidgetById(this.widget.id, selectedFilter);
        }
    }

    private getDatesFromUser(): Observable<Widget> {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.DATE_RANGE,
            data: {
                model: of(this.model),
                label: $localize`Pls select date range`,
            },
        });

        return this.mobileService.getSideNavAction().pipe(
            filter((action) => action === SideNaveActionsTypes.POSITIVE),
            switchMap(() => this.mobileService.getSideNavResponseData()),
            switchMap((dateValue) => {
                this.dateRangeClicked = true;
                if (dateValue) {
                    this.periodTitle = $localize`from ${this.getFormatedDate(
                        dateValue.begin
                    )} to ${this.getFormatedDate(dateValue.end)}`;
                    return this.widgetsService.getWidgetById(this.widget.id, dateValue.begin, dateValue.end);
                } else {
                    return of(null);
                }
            })
        );
    }
    private prepareTimeOptions(): SelectFilterOption[] {
        const options = Utils.getPredefinedTimeSpentOptions();
        // options.splice(3, 1); // Remove 6 months filter > Not required;
        options.splice(6, 0, {
            title: $localize`All time`,
            value: 'all_time',
            icon: '',
            class: 'date_range',
            data: {
                tooltipValue: $localize`Display data from the start of the Academy`,
            },
        }); // Add all time filter option;
        options[5].title = $localize`Date`;

        return options;
    }
    onToggleFilter(isOpened: boolean) {
        this.predefinedTimeOptions = !isOpened
            ? this.predefinedTimeOptions.filter((opt) => opt.value === this.selectedFilter)
            : this.prepareTimeOptions();

        this.cdr.markForCheck();
    }
    private getFormatedDate(date?: string) {
        return new DatePipe(this.localeId).transform(
            date ? date : Date.now(),
            this.localeId === 'en' ? 'MMM d, y' : 'd MMM y'
        );
    }
    private updateTimePeriod(activeAppliedDateFilters: Filter<string>[]) {
        this.periodTitle = $localize`from ${this.getFormatedDate(
            activeAppliedDateFilters.find((fil) => fil.property === 'begin').items[0]
        )} to ${
            this.getFormatedDate(activeAppliedDateFilters.find((fil) => fil.property === 'end')?.items[0]) ||
            this.getFormatedDate()
        }`;

        this.selectedFilter = activeAppliedDateFilters.find((fil) => fil.property === 'end')?.items[0]
            ? 'select_date_range'
            : activeAppliedDateFilters.find((fil) => fil.property === 'begin').items[0];

        this.predefinedTimeOptions = this.prepareTimeOptions().filter((opt) => opt.value === this.selectedFilter);

        this.cdr.markForCheck();
    }
    public removeDot(date) {
        return date.replace(/\./g, '');
    }
    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
