<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>event_repeat</mat-icon><span>{{ navData.data.title }}</span>
    </div>
    <form [formGroup]="recurringForm" fxLayoutGap="1em" fxFlex="0 0 100%" fxLayout="column">
        <!-- Repeats Dropdown -->
        <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent select">
            <span i18n>Repeats</span>
            <mat-form-field class="role-field">
                <mat-select [formControl]="recurringPeriodControl" fxFlex="1 1 100%">
                    <mat-option value="null" i18n>None</mat-option>
                    <mat-option value="daily" i18n>Daily</mat-option>
                    <mat-option value="weekly" i18n>Weekly</mat-option>
                    <mat-option value="monthly" i18n>Monthly</mat-option>
                    <mat-option value="quarterly" i18n>Quarterly</mat-option>
                    <mat-option value="half_yearly" i18n>Half-Yearly</mat-option>
                    <mat-option value="yearly" i18n>Yearly</mat-option>
                    <mat-option value="2years" i18n>2 Years</mat-option>
                    <mat-option value="5years" i18n>5 Years</mat-option>
                    <mat-option value="custom" i18n>Custom</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!-- Repeat every X days -->
        <div
            *ngIf="recurringPeriodControl.value != 'null'"
            fxLayoutGap="4px"
            fxLayout="column"
            class="w-100 input-field-parent"
        >
            <span i18n>Repeat every (number of days)</span>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    min="0"
                    type="number"
                    i18n-placeholder
                    formControlName="recurring"
                    placeholder="Enter number of days"
                />
            </mat-form-field>
        </div>

        <div
            *ngIf="navData.data.type != 'updateRecurringResource'"
            fxLayoutGap="4px"
            fxLayout="column"
            class="w-100 input-field-parent"
        >
            <span i18n>Sorting code</span>
            <mat-form-field appearance="outline">
                <input
                    matInput
                    type="number"
                    min="0"
                    i18n-placeholder
                    formControlName="sorting_code"
                    placeholder="Enter sorting code"
                />
            </mat-form-field>
        </div>

        <!-- Action buttons -->
        <div fxFlex="1 0 100%" fxLayout="row wrap" fxLayoutAlign="end center" fxLayoutGap=".5em" class="mt-1">
            <button class="cancelBtn" mat-raised-button (click)="onClose.emit()" i18n>Cancel</button>
            <button
                mat-raised-button
                color="primary"
                [disabled]="recurringForm.invalid && recurringForm.touched"
                (click)="submitRecurring()"
                i18n
            >
                Done
            </button>
        </div>
    </form>
</div>
