<ng-container *ngIf="authUser">
    <form
        [formGroup]="addNoteForm"
        *ngIf="navData"
        [ngClass]="navData.data?.styleClass"
        class="data-wrap note-add-wrapper"
        fxLayout="column"
    >
        <div class="title mb-1" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon class="material-icons-outlined">note_alt</mat-icon>
            <div>{{ noteTitle }}</div>
        </div>

        <!-- User Note -->
        <div fxLayoutGap="10px" class="user-note" fxLayout="column" *ngIf="navData.data?.privacy === 'user'">
            <span class="intro" i18n>You have selected to add a note for</span>
            <div fxLayoutGap="10px" fxLayoutAlign="start center" class="user-data">
                <app-image-view
                    [ngStyle]="{ minWidth: '50px' }"
                    [isCircularImg]="true"
                    [media_id]="navData.data.user.media"
                    paddingBottom="50px"
                ></app-image-view>
                <span class="name">{{ navData.data.user.name }}</span>
            </div>
        </div>
        <!-- Group Note -->
        <div fxLayoutGap="10px" class="user-note" fxLayout="column" *ngIf="navData.data?.privacy === 'group'">
            <span class="intro" i18n>You have selected to add a note for</span>
            <div fxLayoutGap="10px" fxLayoutAlign="start center" class="user-data">
                <mat-icon class="groups">groups</mat-icon>
                <span class="name">{{ navData.data.group.name }}</span>
            </div>
        </div>
        <!-- Note -->
        <div fxLayout="column" fxLayoutAlign="space-between center" class="w-100 mt-1">
            <div class="w-100" fxLayoutAlign="space-between center">
                <span class="label mb-7p" i18n>Note*</span>
                <mat-hint
                    >{{ addNoteForm.value.body?.length }} <span> (5000)</span>
                    <mat-icon
                        class="info"
                        i18n-matTooltip
                        matTooltip="Max number of characters for note is 5000 characters."
                        >question_mark</mat-icon
                    >
                </mat-hint>
            </div>

            <mat-form-field class="w-100 note-text" floatLabel="never" appearance="fill" fxFlex="0 0 100%">
                <textarea
                    maxlength="5000"
                    rows="5"
                    i18n-placeholder
                    placeholder="Type in your note here"
                    #message
                    matInput
                    formControlName="body"
                ></textarea>
            </mat-form-field>
        </div>

        <!-- Privacy -->
        <ng-container *ngIf="!navData.data?.privacy">
            <div fxLayout="column" class="mt-1 w-100">
                <div fxLayoutAlign="start center" fxLayoutGap="3px">
                    <span class="label mb-7p" i18n>Set note privacy</span>
                </div>
                <mat-form-field appearance="fill" class="templates-select" fxFlex="0 0 100%">
                    <mat-select placeholder="Select note privacy" i18n-placeholder formControlName="privacy">
                        <mat-option *ngFor="let option of privacyOptions" [value]="option.value">
                            {{ option.label }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- User search -->
            <div class="input-wrapper default-note" fxLayout="column" *ngIf="addNoteForm.value.privacy === 'user'">
                <span class="label" i18n>To</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-md="10px">
                    <mat-form-field
                        *ngIf="selectedRecepientFilter.value === 'users'"
                        class="small-field mt-1 users-rec-input"
                        appearance="fill"
                    >
                        <input
                            type="text"
                            matInput
                            #userRecieverInput
                            i18n-placeholder
                            placeholder="Search users"
                            [formControl]="userCtrl"
                            [matAutocomplete]="autoUser"
                        />
                        <mat-autocomplete
                            #autoUser="matAutocomplete"
                            (optionSelected)="selected(autoUser, $event, true)"
                        >
                            <mat-option *ngFor="let user of userSearch$ | async" [value]="user">
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon> {{ user.role_value | userRoleIcon }}</mat-icon>
                                    <span>{{ user.name }}</span>
                                </div>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div fxLayout="column wrap">
                    <mat-chip-list
                        *ngIf="userRecipients.length"
                        class="reci-chips mb-1"
                        #chipList
                        aria-label="Recievers selection"
                    >
                        <mat-chip
                            *ngFor="let recipient of userRecipients"
                            (removed)="removeUser(recipient)"
                            [selectable]="false"
                            class="users-wrap"
                            [ngClass]="{
                                'dark-blue-bg': recipient.role_value === 'user',
                                'green-bg': recipient.role_value !== 'user'
                            }"
                        >
                            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                                <mat-icon>{{ recipient.role_value | userRoleIcon }}</mat-icon>
                                <span>{{ recipient.fname }}</span>
                                <span> {{ recipient.lname }}</span>
                            </div>
                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip></mat-chip-list
                    >
                </div>
            </div>
            <!-- Group search -->
            <div class="input-wrapper default-note" fxLayout="column" *ngIf="addNoteForm.value.privacy === 'group'">
                <span class="label" i18n>To</span>
                <div fxLayout="row" fxLayoutGap="10px" fxLayoutGap.lt-md="10px">
                    <mat-form-field class="small-field mt-1 groups-rec-input" appearance="fill">
                        <input
                            type="text"
                            matInput
                            #groupRecieverInput
                            i18n-placeholder
                            placeholder="Search groups"
                            [formControl]="groupCtrl"
                            [matAutocomplete]="autoGroup"
                        />
                        <mat-autocomplete
                            #autoGroup="matAutocomplete"
                            (optionSelected)="selected(autoGroup, $event, false)"
                        >
                            <mat-option *ngFor="let group of groupSearch$ | async" [value]="group">
                                {{ group.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div fxLayout="column wrap">
                    <mat-chip-list
                        *ngIf="groupRecipients.length"
                        class="reci-chips mb-1"
                        #chipList
                        aria-label="Recievers selection"
                    >
                        <mat-chip
                            *ngFor="let recipient of groupRecipients"
                            (removed)="removeGroup(recipient)"
                            [selectable]="false"
                            class="groups-wrap"
                        >
                            <div fxLayout="row" class="groups" fxLayoutAlign="start center" fxLayoutGap="5px">
                                <mat-icon>groups</mat-icon>
                                <span>{{ recipient.name }}</span>
                            </div>

                            <button matChipRemove>
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip></mat-chip-list
                    >
                </div>
            </div>
        </ng-container>

        <!-- Context -->
        <div class="input-wrapper default-note context-res" fxLayout="column">
            <div class="w-100" fxLayoutAlign="space-between center">
                <span class="label mb-7p" i18n>Context (Related resources)*</span>
                <mat-hint>
                    <mat-icon
                        class="info"
                        i18n-matTooltip
                        matTooltip="The resource that will have this note when learners take it."
                        >question_mark</mat-icon
                    >
                </mat-hint>
            </div>

            <mat-form-field *ngIf="relatedResources.length === 0" class="small-field mt-1" appearance="fill">
                <input
                    type="text"
                    matInput
                    #resourceInput
                    i18n-placeholder
                    placeholder="Attach the note to a resource"
                    [formControl]="contentCtrl"
                    [matAutocomplete]="auto"
                />
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectResource($event, auto)">
                    <mat-option class="context-res" *ngFor="let resource of filteredContent | async" [value]="resource">
                        <div class="selct-mat-msg" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <app-image-view
                                [smallSpinner]="true"
                                [media_id]="resource.media$"
                                [proportional]="true"
                            ></app-image-view>
                            <span>{{ resource.title }}</span>
                        </div>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <div fxLayout="column" class="mt-1" fxLayoutGap="10px" *ngIf="relatedResources.length > 0">
                <div class="res-selected-context" *ngFor="let relatedResource of relatedResources">
                    <div class="selct-mat-msg" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <app-image-view
                            [smallSpinner]="true"
                            [media_id]="relatedResource.media$"
                            [proportional]="true"
                        ></app-image-view>
                        <span>{{ relatedResource.title }}</span>
                    </div>

                    <button mat-icon-button (click)="removeResource(relatedResource)">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <!-- Action -->
        <div class="actions">
            <button
                [disabled]="!(addNoteForm.value.body && relatedResources.length === 1)"
                fxFlex="0 0 auto"
                class="mobile-button positive"
                (click)="submit()"
                mat-button
            >
                <span i18n>Save Note</span>
            </button>
        </div>
    </form>
</ng-container>
