import { TemplateRef } from '@angular/core';
import { GlobalFilterProperty, GlobalFilterPropertyType } from '../global-filters-types.enum';

export interface SelectFilterOption {
    title: string;
    value: string | number;
    icon: string;
    class?: string;
    sorting?: string;
    isMainResource?: boolean;
    data?: any;
    color?: string;
}
export interface ChipItem {
    id: any;
    label: string;
    selected: boolean;
    language_id?: number;
    extra_info?: any;
}

export interface FilterOption {
    property?: GlobalFilterProperty | string;
    label?: string;
}

export interface FilterSpecs extends FilterOption {
    items?: any[];
    icon?: string;
    color?: { bg: string; fg: string };
    styleClass?: string;
    composite?: any[];
}
export class FilterItem<T> {
    property: string;
    value: T;
}

export class FilterCollection<T> {
    query?: string;
    filters: Filter<T>[] = [];
    constructor(data?: { filters: Filter<T>[] }) {
        this.filters = this.filters;
    }

    clone(): FilterCollection<T> {
        return new FilterCollection({ filters: this.filters.map((fltr) => new Filter(fltr.property, fltr.items)) });
    }
}

export interface FilterObserver {
    updateFilter(filter: Filter<string | number>): void;
}

export class Filter<T> {
    property: GlobalFilterProperty | string;
    type?: GlobalFilterPropertyType;
    primary?: boolean;
    default?: boolean;
    /**
     * singleActive added to active filter Only when not many.
     */
    singleActive?: boolean;
    lazy?: boolean;
    dir?: string;
    composite?: Filter<T>[];
    chips?: ChipItem[] | Map<string | number, ChipItem[]> | SelectFilterOption[];
    items: T[] = [];

    //
    label?: string;
    icon?: string;
    styleClass?: string;
    filterResultsChipStyleClass?: string;
    color?: { bg: string; fg: string };
    hasMainResType?: boolean;

    // template
    template?: TemplateRef<any>;
    /**
     *
     * @param property
     * @param items
     */
    constructor(property?: GlobalFilterProperty | string, items?: T[]) {
        this.property = property ? property : this.property;
        this.items = items ? items : this.items;
    }
}
