import { Location } from '@angular/common';
import { Directive, OnDestroy } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { AlertDialogComponent } from '@klickdata/core/application/src/alert-dialog/alert-dialog.component';
import { SelectOptionsDialogComponent } from '@klickdata/core/application/src/select-options-dialog/select-options-dialog.component';
import { AuthService } from '@klickdata/core/auth';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { ResourceOpportunityService } from '@klickdata/core/opportunity';
import { AppScope, Resource, ResourceTypes } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { ResourcesAssignSheetComponent } from '@klickdata/shared-components/src/resource-assignment/resource-assign-sheet/resource-assign-sheet.component';
import { EMPTY, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { UserStatisticsService } from '../../core/user-statistics.service';
import { StartResourceService } from '@klickdata/core/resource/src/start-resource.service';

@Directive()
export abstract class DashboardActivityActionDirective implements OnDestroy {
    public authUser: User;
    public isMob: boolean;
    public destroy: Subject<boolean> = new Subject<boolean>();
    ResourceTypes = ResourceTypes;
    public extra: NavigationExtras = {
        queryParams: {
            referrer: this.router.url.split('?')[0],
        },
    };
    constructor(
        protected router: Router,
        protected userStatisticsService: UserStatisticsService,
        protected dialog: MatDialog,
        protected resourceOpportunityService: ResourceOpportunityService,
        protected mobile: MobileService,
        protected auth: AuthService,
        protected location: Location,
        protected bottomSheet: MatBottomSheet,
        protected startResourceService?: StartResourceService
    ) {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMobile) => (this.isMob = isMobile));
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => {
                this.authUser = user;
            });
    }
    public browse(resource: Resource): void {
        this.routeToResourceDetails(resource);
        // EB asked view to go to CD whatever oppo exist 0n 2772
        // switch (resource.occasionStatus) {
        //     case 'not_started':
        //     case 'cancelled':
        //         this.routeToResourceDetails(resource);
        //         break;
        //     case 'overdue':
        //         resource.opportunity_started_at ? this.startResource(resource) : this.routeToResourceDetails(resource);
        //         break;
        //     case 'done':
        //         this.routeToResourceResults(resource);
        //         break;
        //     case 'ongoing':
        //         this.startResource(resource);
        //         break;
        //     case 'completed':
        //         this.handleBrowseDoneStatusPopup(resource);
        //         break;
        //     default:
        //         this.routeToResourceDetails(resource);
        // }
    }
    public play(resource: Resource): void {
        switch (resource.occasionStatus) {
            case 'not_started':
            case 'ongoing':
            case 'overdue':
                this.startResource(resource);
                break;
            case 'cancelled':
                this.handlePlayCancelledStatusPopup(resource);
                break;
            case 'done':
                this.routeToResourceResults(resource);
                break;
            case 'completed':
                this.handleBrowseDoneStatusPopup(resource);
                break;
            default:
                this.startResource(resource);
        }
    }

    public handleBrowseDoneStatusPopup(resource: Resource) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.RESOURCE_DONE_ACTIVITY,
            data: {
                resource: resource,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobile.getSideNavResponseData()),
                map((data) => data.value),
                takeUntil(this.destroy),
                switchMap((response) => {
                    switch (response) {
                        case 'restart':
                            return this.resourceOpportunityService.getOpportunity(resource.opportunity_id).pipe(
                                switchMap((opp) =>
                                    this.resourceOpportunityService.done(opp).pipe(
                                        switchMap(() => {
                                            this.startResource(resource);
                                            return EMPTY;
                                        })
                                    )
                                )
                            );
                        case 'signOff':
                            return this.resourceOpportunityService.getOpportunity(resource.opportunity_id).pipe(
                                switchMap((opp) =>
                                    this.resourceOpportunityService.done(opp).pipe(
                                        switchMap(() => {
                                            // this.routeToResourceResults(resource);
                                            return EMPTY;
                                        })
                                    )
                                )
                            );
                        case 'continue':
                            this.startResource(resource);
                    }
                    return EMPTY;
                })
            )
            .subscribe();
        return;
        this.dialog
            .open(SelectOptionsDialogComponent, {
                disableClose: true,
                panelClass: 'done-status-dialog',
                data: {
                    icon: 'task_alt',
                    title: $localize`Complete ${ResourceTypes.label(resource.type_id, {
                        plural: false,
                        capitalize: false,
                    })}`,
                    contentBody: $localize`Do you want to mark this ${ResourceTypes.label(resource.type_id, {
                        plural: false,
                        capitalize: false,
                    })} as completed or do you want to go back and complete the complimentary parts?`,
                    selectedValue: 'signOff',
                    options: [
                        {
                            value: 'signOff',
                            viewValue: $localize`Sign off and mark this ${ResourceTypes.label(resource.type_id, {
                                plural: false,
                                capitalize: false,
                            })} as Completed`,
                        },
                        { value: 'continue', viewValue: $localize`I am not done yet` },
                        { value: 'restart', viewValue: $localize`I like to restart from the beginning` },
                    ],
                },
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result),
                switchMap((response) => {
                    switch (response) {
                        case 'restart':
                            return this.resourceOpportunityService.getOpportunity(resource.opportunity_id).pipe(
                                switchMap((opp) =>
                                    this.resourceOpportunityService.done(opp).pipe(
                                        switchMap(() => {
                                            this.startResource(resource);
                                            return EMPTY;
                                        })
                                    )
                                )
                            );
                        case 'signOff':
                            return this.resourceOpportunityService.getOpportunity(resource.opportunity_id).pipe(
                                switchMap((opp) =>
                                    this.resourceOpportunityService.done(opp).pipe(
                                        switchMap(() => {
                                            this.routeToResourceResults(resource);
                                            return EMPTY;
                                        })
                                    )
                                )
                            );
                        case 'continue':
                            this.startResource(resource);
                    }
                    return EMPTY;
                })
            )
            .subscribe();
    }

    public assignCourse(resource: Resource): void {
        this.bottomSheet.open(ResourcesAssignSheetComponent, {
            data: {
                width: !this.isMob ? '70%' : '100%',
                id: resource.id,
                context: $localize`Assign`,
                contextIcon: 'assignment_ind',
                title: resource.title,
                referenceIdKey: 'resource_id',
                referenceFilter: 'assignedResource',
            },
            panelClass: 'sheet-wrapper',
        });
    }

    public handlePlayCancelledStatusPopup(resource: Resource) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'cancel',
                title: $localize`Cancelled ${ResourceTypes.label(resource.type_id, {
                    plural: false,
                    capitalize: false,
                })}`,
                contentBody: $localize`Do you want to restart this ${ResourceTypes.label(resource.type_id, {
                    plural: false,
                    capitalize: false,
                })}?`,
                positiveBtn: $localize`Start ${ResourceTypes.label(resource.type_id, {
                    plural: false,
                    capitalize: false,
                })}`,
                negativeBtn: $localize`Delete from the list`,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(filter((action) => action === SideNaveActionsTypes.POSITIVE))
            .subscribe((action) => {
                switch (action) {
                    case SideNaveActionsTypes.POSITIVE:
                        this.startResource(resource);
                        break;
                    // case SideNaveActionsTypes.NEGATIVE:
                    //     this.deleteCourseFromList(resource);
                    //     break;
                    default:
                        break;
                }
            });
        return;
        this.dialog
            .open(AlertDialogComponent, {
                disableClose: true,
                panelClass: 'cancel-status-dialog',
                data: {
                    icon: 'cancel',
                    title: $localize`Cancelled ${ResourceTypes.label(resource.type_id, {
                        plural: false,
                        capitalize: false,
                    })}`,
                    contentBody: $localize`Do you want to restart this ${ResourceTypes.label(resource.type_id, {
                        plural: false,
                        capitalize: false,
                    })}?`,
                    positiveBtn: $localize`Start ${ResourceTypes.label(resource.type_id, {
                        plural: false,
                        capitalize: false,
                    })}`,
                    negativeBtn: $localize`Delete from the list`,
                    neutralBtn: $localize`Cancel`,
                },
            })
            .afterClosed()
            .pipe(takeUntil(this.destroy))
            .subscribe((response) => {
                switch (response) {
                    case true:
                        this.startResource(resource);
                        break;
                    case false:
                        this.deleteCourseFromList(resource);
                        break;
                    default:
                        break;
                }
            });
    }
    public startResource(resource: Resource) {
        this.startResourceService.takeResource(resource, this.authUser)?.subscribe();
    }
    public routeToResourceDetails(resource: Resource) {
        this.router.navigate(['/home/dashboard/resource-details/' + resource.id]);
    }
    public routeToResourceResults(resource: Resource) {
        const extras: NavigationExtras = {
            queryParams: {
                referrer: this.location.path(),
                opportunity: resource.opportunity_id,
            },
        };
        switch (ResourceTypes.parentType(resource.type_id)) {
            case ResourceTypes.COURSE:
                this.router.navigate(
                    ['/player', 'occasion', resource.opportunity_id, 'course', 'result', 'me'],
                    extras
                );
                break;
            case ResourceTypes.E_COURSE:
                this.router.navigate(['/home/courses/' + resource.article_code]);
                break;
            case ResourceTypes.TEST:
            case ResourceTypes.SURVEY:
                this.router.navigate(
                    [
                        `/player/occasion/${resource.opportunity_id}/${ResourceTypes.label(resource.type_id, {
                            plural: false,
                            capitalize: false,
                        })}/result`,
                    ],
                    extras
                );
        }
    }

    public deleteCourseFromList(resource: Resource) {
        // console.log('Deleted');
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
