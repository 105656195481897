import { Platform } from '@angular/cdk/platform';
import { Directive, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { AuthService } from '@klickdata/core/auth';
import { RecorderTypes } from '@klickdata/core/media';
import { RecorderService } from '@klickdata/core/media/src/recorder.service';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { User } from '@klickdata/core/user';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { first, map, shareReplay, takeUntil } from 'rxjs/operators';
import { RecorderBarComponent } from './recorder-bar/recorder-bar.component';

export interface PermissionsSliderOptions {
    deniedPerm?: any[];
    body?: string;
}
@Directive()
export abstract class RecorderBase implements OnDestroy {
    public destroy = new Subject<boolean>();
    public snackBarRef: MatSnackBarRef<RecorderBarComponent>;
    RecorderTypes = RecorderTypes;
    public abstract recorderConfig: any;
    public stream: MediaStream;
    public user$: Observable<User>;
    public isMobile: boolean;

    constructor(
        protected snackBar: MatSnackBar,
        protected recordingService: RecorderService,
        protected auth: AuthService,
        protected mobile: MobileService,
        protected platform: Platform
    ) {
        this.user$ = this.auth.getUser().pipe(first(), shareReplay());
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMob) => (this.isMobile = isMob));
    }

    public showSnackBar() {
        this.snackBarRef = this.snackBar.openFromComponent(RecorderBarComponent, {
            duration: 1000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: 'recorder-bar',
            data: this.recordingService.activeRecordingType,
        });
    }

    public stopRecorder() {
        this.recordingService.stopRecorder();
    }

    /**
     * To check for needed permissions is given to the system or not and notify user with the denied.
     * This will work only on GChrome and MEdge, Firefox and Safari don't support navigator.permissions.query
     * for the camera and microphone.
     * This is just as UX for the chrome and edge users.
     * @param permissionDescriptors
     * @returns Observable<boolean>
     */

    public haveRequestedPermissions(permissionDescriptors: PermissionDescriptor[]): Observable<boolean> {
        if (this.platform.SAFARI || this.platform.FIREFOX || !navigator.permissions) {
            return of(true);
        } else {
            return forkJoin(
                permissionDescriptors.map((permissionDescriptor) => navigator.permissions.query(permissionDescriptor))
            ).pipe(
                map((permissios: any[]) => {
                    const deniedPerm = permissios.filter((per) => per.state === 'denied');
                    this.updatePrmissionsWithLabel(deniedPerm);
                    if (deniedPerm.length > 0) {
                        this.initPermissionWarningSlider({ deniedPerm: deniedPerm });
                        return false;
                    } else {
                        return true;
                    }
                })
            );
        }
    }

    private updatePrmissionsWithLabel(deniedPerm: any[]): any[] {
        return deniedPerm.map(
            (perm) => (perm['title'] = perm.name === 'audio_capture' ? $localize`microphone` : $localize`camera`)
        );
    }

    public initPermissionWarningSlider(sideSliderOptions: PermissionsSliderOptions) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'pan_tool',
                title: $localize`Access Denied`,
                contentBody:
                    sideSliderOptions.body ||
                    $localize`Pls give KlickData needed ${sideSliderOptions.deniedPerm
                        .map((per) => per.title)
                        .join(
                            ', '
                        )} permissions from your browser/system security settings to record and then reload the page`,
                negativeBtn: $localize`Ok`,
            },
        });
    }

    public abstract startRecorder(canRecordUnlimited: boolean): void;
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
