import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanComponentDeactivate } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceCategory } from '@klickdata/core/resource';
import { Filter, GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { CategorySelectDatatableService } from '../categories-select/category-select-datatable.service';
import { SelectModelBaseDirective } from '../select-model-base/select-model-base.component';

@Component({
    selector: 'app-categories-select',
    templateUrl: './categories-select.component.html',
    styleUrls: ['./categories-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CategorySelectDatatableService],
})
export class CategoriesSelectComponent
    extends SelectModelBaseDirective<ResourceCategory>
    implements OnInit, AfterViewInit, CanComponentDeactivate, OnDestroy
{
    @Input() accessForm: FormGroup;
    @Input() referenceFilter: string;
    @Input() referenceIdKey = 'id';
    @Input() selectionLabel: string;
    @Input() sync_all_key = 'sync_all_categories';
    @Input() attachKey = 'categories_attach';
    @Input() detachKey = 'categories_detach';
    @Input() appliedFilters: Filter<string | number>[];
    public columns = ['select', 'title', 'parent'];
    public itemSelectedOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;

    constructor(protected datatableService: CategorySelectDatatableService, protected mobileService: MobileService) {
        super(datatableService, mobileService);
        this.itemSelectedOptions = Utils.getItemSelectedOptions();
    }

    /**
     * @override
     * @param id
     */
    protected refresh(id?: any): void {
        const filters = [];
        if (this.referenceFilter) {
            filters.push({ property: this.referenceFilter, items: [id] });
        }
        if (this.appliedFilters) {
            this.appliedFilters.forEach((fltr) => filters.push(fltr));
        }
        this.filter.createOrUpdateFilter(filters);
    }

    detectIdKeyValueChange() {
        if (this.referenceFilter) {
            super.detectIdKeyValueChange();
        } else {
            this.refresh();
        }
    }
}
