import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from 'apps/klickdata/src/app/shared/pipes/pipes.module';
import { HtmlViewComponent } from './html-view/html-view.component';

@NgModule({
    imports: [CommonModule, PipesModule],
    declarations: [HtmlViewComponent],
    exports: [HtmlViewComponent],
})
export class HtmlModule {}
