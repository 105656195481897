import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TableHttpHelpers, TableHttpService, TableSource } from '@klickdata/core/table';
import { Group, GroupData, GroupService as Base } from '@klickdata/core/group';
import { PaginatorResponse, ResponseData } from '@klickdata/core/http';

@Injectable({
    providedIn: 'root',
})
export class GroupsService extends Base implements TableHttpService<Group> {
    /**
     * Service for fetching customer groups in a table.
     */
    fetchData(source: TableSource<Group>): Observable<ResponseData<Group[]>> {
        const req = this.builder.get<GroupData[]>(this.resourceUrl);

        if (source.filter?.filter?.filters?.findIndex((filter) => filter.property === 'customer') === -1) {
            req.param('customer', this.customer_id);
        }
        if (source.filter?.filter?.filters?.findIndex((filter) => filter.property.indexOf('reference') !== -1) !== -1) {
            req.param('referenceCount', 1);
        }

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<GroupData[]>) => {
                return {
                    data: this.mapGroups(res.data),
                    notify: res.notify,
                    paginator: res.paginator,
                    more: res.more,
                };
            })
        );
    }
}
