import { MessageService } from './../../../core/message/src/message.service';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { PaymentItem, PaymentMethod } from '@klickdata/core/payment/src/payment.model';
import { Resource, ResourceTypes } from '@klickdata/core/resource';
import { Observable, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { User } from '@klickdata/core/user';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { Utils } from '@klickdata/core/util';

@Component({
    selector: 'app-purchase',
    templateUrl: './purchase.component.html',
    styleUrls: ['./purchase.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PurchaseComponent implements OnInit, OnDestroy {
    @Input() resource: Resource;
    @Input() user: User;
    @Input() customer: Customer;
    public paymentOptions: PaymentItem[];
    public paymentMethods: PaymentMethod[];
    public selectedPaymentOption: PaymentItem;
    public selectedPaymentMethod: PaymentMethod;
    public paymentOptionControl = new FormControl(1, Validators.required);
    public termsCheckControl = new FormControl(true, Validators.required);
    public paymentMethodControl = new FormControl(0, Validators.required);
    @ViewChild('stepper') stepper: MatStepper;
    @Output() paymentSucceeded: EventEmitter<boolean> = new EventEmitter();
    private destroy: Subject<boolean> = new Subject<boolean>();
    public showPurchaseMethod: boolean;
    public planName = 'monthly';
    ResourceTypes = ResourceTypes;

    constructor(protected cdr: ChangeDetectorRef, protected messageService: MessageService) {
        this.paymentMethods = Utils.getPaymentMethods();
    }

    ngOnInit(): void {
        this.paymentOptions = [
            {
                id: 0,
                price: this.resource.price,
                currency: this.resource.currency,
                name: 'value',
            },
        ];
        this.paymentOptionControl.patchValue(this.customer.enable_subscription_plans ? 1 : 0);
        if (this.customer && this.customer.enable_subscription_plans) {
            this.resource.currency === 'SEK'
                ? this.paymentOptions.push(
                      ...[
                          {
                              id: 1,
                              price: 246,
                              currency: this.resource.currency,
                              name: 'monthly',
                          },
                          {
                              id: 2,
                              price: 2541,
                              currency: this.resource.currency,
                              name: 'yearly',
                          },
                      ]
                  )
                : this.paymentOptions.push(
                      ...[
                          {
                              id: 1,
                              price: 29,
                              currency: 'USD',
                              name: 'monthly',
                          },
                          {
                              id: 2,
                              price: 299,
                              currency: 'USD',
                              name: 'yearly',
                          },
                      ]
                  );
        }

        this.paymentMethodControl.valueChanges
            .pipe(startWith(this.paymentMethods[0].id), takeUntil(this.destroy))
            .subscribe(
                (methodId) =>
                    (this.selectedPaymentMethod = this.paymentMethods.find((method) => method.id === methodId))
            );
        this.paymentOptionControl.valueChanges
            .pipe(startWith(this.paymentOptions[0].id), takeUntil(this.destroy))
            .subscribe((optionId) => {
                this.selectedPaymentOption = this.paymentOptions.find((option) => option.id === optionId);
                this.planName = this.selectedPaymentOption.name;
            });
    }
    public goToNext(stepper: MatStepper, disabledOption?: boolean) {
        if (disabledOption) {
            return;
        }
        stepper.next();
    }
    public readMoreLink() {
        window.open('https://klickdata.se/faq-klms/legal-links-for-klick-data', '_blank');
    }

    public purchasePermission() {
        if (!this.termsCheckControl.value) {
            this.messageService.openMessage(MessageErrorComponent, $localize`Please accept the terms first`);
        }
    }
    public ngOnDestroy() {
        this.destroy.next(true);
    }
}
