import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope } from '@klickdata/core/resource';
import { Downloads } from '@klickdata/core/resource/src/download.model';
import { Resource } from '@klickdata/core/resource/src/resource.model';
import { ActivitySectionType, SectionType } from '@klickdata/core/resource/src/types.enum';
import { Filter, FilterCollection, GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ResourceListingSheetComponent } from '../resource-listing-sheet/resource-listing-sheet.component';
import { SectionDataService } from './section-data.service';
import { AuthService } from '@klickdata/core/auth';
import { User } from '@klickdata/core/user';

@Component({
    selector: 'app-section-item-parent',
    templateUrl: './section-item-parent.component.html',
    styleUrls: ['./section-item-parent.component.scss'],
})
export class SectionItemParentComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @Input() title: string;
    @Input() sectionType: SectionType;
    @Input() activitySectionType: ActivitySectionType;
    @Input() displayedColumns: string[];
    @Input() actionCellBtns = ['view', 'play', 'delete'];
    @Input() hasThumbnailHoverEffect: boolean;
    @Output() onNotEmptyResource: EventEmitter<boolean> = new EventEmitter();
    @Output() onPlayActivity: EventEmitter<Resource> = new EventEmitter();
    @Output() onBrowseActivity: EventEmitter<any> = new EventEmitter();
    @Output() onDeleteActivity: EventEmitter<any> = new EventEmitter();
    @Output() onFinalGrades: EventEmitter<Resource[]> = new EventEmitter<Resource[]>();
    @Output() onAssign: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Input()
    public set param(value: any) {
        this._param = value;
        this.paramValue.next(value);
        if (
            this.param.status === 'done' &&
            (this.param.resourceScope === AppScope.TEST || this.param.resourceScope === AppScope.SURVEY)
        ) {
            this.activeSort[0] = {
                property: 'sort',
                items: ['done'],
            };
        }
    }
    private resizeId;
    private _param: any;
    private paramValue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private relatedDownloadInfo: BehaviorSubject<Downloads> = new BehaviorSubject<Downloads>(null);
    public get param() {
        return this._param;
    }
    @Input() attachedLanguages: number[];
    /**
     * Assign service to get data from.
     */
    @Input() service: SectionDataService<any>;
    public langFilter: Subject<number[]> = new Subject<number[]>();
    public searchFilter: Subject<string> = new Subject<string>();
    public sortingFilter: Subject<Filter<string>> = new Subject<Filter<string>>();
    public directionFilter: Subject<Filter<string>> = new Subject<Filter<string>>();
    @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;
    public languageControl = new FormControl();
    public resources$: Observable<Resource[]>;
    @Input() public mode: 'icons' | 'list' = 'icons';
    @Input() public expandedSectionFilters = false;
    @Input() public toggleFilterButtons = false;
    @Input() public filtersTogglesInOnBtn = false;
    @Input() public user: User;
    public isMobile: Observable<boolean>;
    GlobalFilterProperty = GlobalFilterProperty;
    public searchControl: FormControl = new FormControl();
    public gap: number;
    public sectionItemWidth: number;
    public lastScreenWidth: number;
    public pagPageEvent: PageEvent;
    public defaultNumberOfItemsPerSectionRow: number;
    public itemsLessThanFullWidth: boolean;
    public destroy: Subject<boolean> = new Subject<boolean>();
    AppScope = AppScope;
    Number = Number;
    public cacheScope: AppScope | null;

    public activeSort = [
        { property: 'sort', items: ['last_action'] },
        { property: 'dir', items: ['desc'] },
    ];
    public sectionSortingOptions: SelectFilterOption[];

    constructor(
        protected router: Router,
        protected changeRef: ChangeDetectorRef,
        protected elementRef: ElementRef,
        protected mobile: MobileService,
        protected auth: AuthService,
        protected bottomSheet: MatBottomSheet,
        protected downloadHelper: DownloadHelperService
    ) {
        this.sectionSortingOptions = Utils.getSectionsSortingOptions();
        this.cacheScope = this.auth.checkPlatform('user') ? null : AppScope.RESOURCES;
    }
    ngOnInit() {}
    ngAfterViewInit() {
        this.lastScreenWidth = window.innerWidth;
        this.handleItemsPerRow();
        this.mobile
            .isMobile()
            .pipe(shareReplay(), takeUntil(this.destroy))
            .subscribe((isMobile) => {
                this.mode = isMobile || this.sectionType === SectionType.ACTIVITY_SECTION ? 'list' : 'icons';
                this.changeRef.markForCheck();
            });
        this.resources$ = combineLatest([
            this.paramValue,
            this.sectionType === SectionType.GLOBAL_SEARCH_SECTION
                ? of(this.param.query)
                : this.searchFilter.pipe(distinctUntilChanged()),
            this.langFilter.pipe(distinctUntilChanged()),
            this.sortingFilter
                .asObservable()
                .pipe(
                    startWith(
                        this.sectionType === SectionType.ACTIVITY_SECTION
                            ? { property: 'sort', items: ['last_action'] }
                            : { property: '', items: [] }
                    )
                ),
            this.directionFilter
                .asObservable()
                .pipe(
                    startWith(
                        this.sectionType === SectionType.ACTIVITY_SECTION
                            ? { property: 'dir', items: ['desc'] }
                            : { property: '', items: [] }
                    )
                ),
            this.paginator.page.pipe(
                startWith(<PageEvent>{
                    pageIndex: this.paginator.pageIndex,
                    pageSize: this.defaultNumberOfItemsPerSectionRow,
                })
            ),
        ]).pipe(
            switchMap(([param, query, language, sort, dir, pageEvent]) => {
                return this.service.getData({
                    userId: this.user?.id,
                    param: param,
                    query,
                    page: pageEvent.pageIndex,
                    language,
                    sort,
                    dir,
                    limit: pageEvent.pageSize,
                });
            }),
            map((res) => {
                this.itemsLessThanFullWidth = res.total_count < this.defaultNumberOfItemsPerSectionRow;
                this.changeRef.markForCheck();
                this.pagPageEvent = this.pagPageEvent || {
                    pageSize: this.defaultNumberOfItemsPerSectionRow,
                    length: res.total_count,
                    pageIndex: this.paginator.pageIndex,
                };
                this.updatePaginator(res.total_count);
                this.onNotEmptyResource.emit(res.total_count > 0);
                /**
                 * Handle the issue of the display of all items in section to be started from left not centered
                 */
                if (
                    (this.paginator.pageSize === res.total_count || this.paginator.pageSize === 100) &&
                    !this.itemsLessThanFullWidth &&
                    !!this.elementRef.nativeElement.getElementsByClassName('section-item')[1]
                ) {
                    this.gap = this.getDefaultGap();
                } else {
                    this.gap = null;
                }
                if (res.more?.downloads) {
                    this.relatedDownloadInfo.next(res.more.downloads);
                }

                this.changeRef.markForCheck();
                return res.data;
            })
        );
    }
    public getDefaultGap(): number {
        return Math.round(
            this.elementRef.nativeElement.getElementsByClassName('section-item')[1].getBoundingClientRect().left -
                (this.elementRef.nativeElement.getElementsByClassName('section-item')[0].getBoundingClientRect().left +
                    this.elementRef.nativeElement.getElementsByClassName('section-item')[0].getBoundingClientRect()
                        .width)
        );
    }
    public filterChangedEmitter(filters: FilterCollection<string | number>) {
        this.searchFilter.next(filters.query);
        if (!!filters.filters.find((filter) => filter.property === 'language')) {
            this.langFilter.next(<number[]>filters.filters.find((filter) => filter.property === 'language').items);
        }
        if (
            !!filters.filters.find((filter) => filter.property === 'query') &&
            !!filters.filters.find((filter) => filter.property === 'query').items[0]
        ) {
            this.searchFilter.next(<string>filters.filters.find((filter) => filter.property === 'query').items[0]);
        }
        if (!!filters.filters.find((filter) => filter.property === 'sort')) {
            this.sortingFilter.next({
                property: 'sort',
                items: <string[]>filters.filters.find((filter) => filter.property === 'sort').items,
            });
        }
        if (!!filters.filters.find((filter) => filter.property === 'dir')) {
            this.directionFilter.next({
                property: 'dir',
                items: <string[]>filters.filters.find((filter) => filter.property === 'dir').items,
            });
        }
    }
    private handleItemsPerRow() {
        const wrapperWidth =
            this.elementRef.nativeElement.getElementsByClassName('mat-expansion-panel-content')[0].offsetWidth;
        if (wrapperWidth < 479) {
            this.defaultNumberOfItemsPerSectionRow = 3;
        } else if (wrapperWidth > 480 && wrapperWidth < 839) {
            this.defaultNumberOfItemsPerSectionRow = 4;
        } else if (wrapperWidth > 840 && wrapperWidth < 1147) {
            this.defaultNumberOfItemsPerSectionRow = 5;
        } else if (wrapperWidth > 1148 && wrapperWidth < 1787) {
            this.defaultNumberOfItemsPerSectionRow = 6;
        } else if (wrapperWidth > 1788 && wrapperWidth < 2386) {
            this.defaultNumberOfItemsPerSectionRow = 7;
        } else {
            this.defaultNumberOfItemsPerSectionRow = 8;
        }
        // padding value of the child item should removed to get
        // plain width of items container (wrapperWidth)
        // 10 is extra space between items
        const paddingValue = window.innerWidth > 959 ? 24 * 2 : 12 * 2;
        this.sectionItemWidth = (wrapperWidth - paddingValue) / this.defaultNumberOfItemsPerSectionRow - 10;
    }

    @HostListener('window:resize', ['$event']) onResize(event) {
        if (this.lastScreenWidth !== event.target.innerWidth) {
            // This method cancels a timeout previously established by calling
            clearTimeout(this.resizeId);
            this.resizeId = setTimeout(() => {
                this.handleItemsPerRow();
                this.paginator.pageSize = this.defaultNumberOfItemsPerSectionRow;
                this.pagPageEvent.pageSize = this.defaultNumberOfItemsPerSectionRow;
                this.paginator.page.emit(this.pagPageEvent);
                this.lastScreenWidth = event.target.innerWidth;
            }, 500);
        }
    }

    public filterEmitter(fltr: Filter<string>[]) {
        this.sortingFilter.next(fltr[0]);
        this.directionFilter.next(fltr[1]);
    }
    public getDisplayedColumns(resources) {
        if (this.displayedColumns) {
            let returnedCols = [];
            returnedCols = Utils.getMatchedItemsFromArrays(
                this.displayedColumns,
                Utils.getValuedKeysFromArrayOfObjects(resources)
            );
            returnedCols.splice(0, 0, 'select', 'columns');
            this.changeRef.markForCheck();
            return returnedCols;
        }
    }
    public modeToggle() {
        this.mode === 'icons' ? (this.mode = 'list') : (this.mode = 'icons');
        // this.expand();
    }
    public expand() {
        if (this.expansionPanel.expanded === false) {
            this.expansionPanel.toggle();
        }
        this.changeRef.markForCheck();
    }

    private updatePaginator(max: number) {
        this.paginator.length = max;
        const pageOptions = [];

        for (let i = this.defaultNumberOfItemsPerSectionRow; i <= max; i++) {
            if (i % this.defaultNumberOfItemsPerSectionRow === 0 && pageOptions.length <= 3) {
                pageOptions.push(i);
            }
        }
        if (pageOptions[pageOptions.length - 1] !== max) {
            max > 100 ? pageOptions.push(100) : pageOptions.push(max);
        }

        this.paginator.pageSizeOptions = pageOptions;

        // #161 Hack to fix when current paginator page more then total pages paginator doesn't make correction by default.
        // https://github.com/angular/material2/issues/5812
        // https://github.com/angular/material2/issues/10227
        if (this.paginator.pageIndex !== 0 && this.paginator.pageIndex >= this.paginator.getNumberOfPages()) {
            this.paginator.previousPage();
        }
    }
    public downloadFinalGrades(resources: Resource[]) {
        this.downloadHelper.download(this.relatedDownloadInfo.value, {
            opportunity: resources.map((res) => res.opportunity_id),
        });
    }

    public showResources(item: Resource) {
        this.bottomSheet.open(ResourceListingSheetComponent, {
            data: {
                id: item.id,
                label: item.label,
                scope_id: item.scope_id,
            },
            panelClass: 'sheet-wrapper',
        });
    }

    public handleLinkClick(item: Resource) {
        switch (item.scope_id) {
            case AppScope.USERS:
                this.router.navigate(['/admin/accounts/users/users/', item.id]);
                break;
            case AppScope.GROUPS:
                this.router.navigate(['/admin/accounts/groups/', item.id]);
                break;
            case AppScope.CUSTOMERS:
                this.router.navigate(['/master/customers/', item.id]);
                break;
        }
    }
    public getMappedResources(resources: Resource[]): number[] {
        return resources.map((res) => res.id);
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
