import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthModule } from '@klickdata/core/auth';
import { SelectivePreloadingStrategy } from './preload/selective-preloading-strategy';
import { TitleDirective } from './title/title.directive';
import { TitleService } from './title/title.service';

@NgModule({
    imports: [
        CommonModule,
        MatSnackBarModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        FlexLayoutModule,

        HttpClientModule,
        AuthModule,
    ],
    providers: [TitleService],
    declarations: [TitleDirective],
    exports: [TitleDirective],
})
export class CoreModule {
    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [SelectivePreloadingStrategy],
        };
    }
}
