<div class="faq-purch" fxLayout="column">
    <mat-accordion displayMode="flat">
        <mat-expansion-panel class="wrapper-panel" #expansionPanel>
            <mat-expansion-panel-header class="post-item-expansion-header">
                <p i18n>What's a site license from KlickData?</p>
            </mat-expansion-panel-header>
            <div i18n>
                Klick Data Learning Management System KLMS contains a large number of Courses, E-courses, Tests, and
                Surveys for online Training. If you purchase a Site license you will have full access to a Learner level
                of all content during a period of 30 days, 12 months, or a specific time set.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="wrapper-panel" #expansionPanel>
            <mat-expansion-panel-header class="post-item-expansion-header">
                <p i18n>Can I cancel my subscription?</p>
            </mat-expansion-panel-header>
            <div i18n>
                Yes. At any time with no additional charge. For the first period, 90 days of cancellation apply. Then a
                30 days cancellation time will affect. You can cancel within 60 days of purchase to get the minimum
                charge of 90 days. The amount you will be charged is stated above. You can use the service on mobile,
                tablet and Desktop Computers.
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel class="wrapper-panel" #expansionPanel>
            <mat-expansion-panel-header class="post-item-expansion-header">
                <p i18n>Which devices does KlickData KLMS work on?</p>
            </mat-expansion-panel-header>
            <div i18n>
                You can use the service on mobile, tablet, and Desktop Computers and log in from any IP address. One
                license is for one person only.
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
