import {
    AfterModelInit,
    IDataModel,
    Ignore,
    MethodType,
    Model,
    Nullable,
} from '@klickdata/core/application/src/model/model-interface';
import { Media } from '@klickdata/core/media/src/media.model';
import { Observable } from 'rxjs';

export interface AlternativeData extends IDataModel {
    id?: number;
    question_id?: number;
    alternative?: string;
    title?: string;
    percentage?: number;
    correct?: boolean;
    points?: number;
    weight?: number;
    media_id?: number;
    deleted?: boolean;
    explanation?: string;
    user_choice?: boolean;
    color?: string;
}

/**
 * Question alternative class
 */
export class Alternative extends Model<AlternativeData> {
    public id: number;
    public question_id: number;
    public alternative: string;
    public title: string;
    public explanation: string;
    public percentage: number;
    public color: string;
    public correct: boolean;
    @Nullable()
    public points: number;
    @Nullable()
    public weight: number;
    @Nullable(MethodType.PUT)
    public media_id: number;
    public deleted: boolean;
    public user_choice: boolean;
    media$: Observable<Media>;
}
