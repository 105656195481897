import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ChangeDetectorRef,
} from '@angular/core';
import { Resource, AppScope } from '@klickdata/core/resource';
import { Observable, pipe } from 'rxjs';
import { AuthService } from '@klickdata/core/auth';
import { first, map, shareReplay, tap } from 'rxjs/operators';
import { MobileService } from '@klickdata/core/mobile';
import { GenericTabsOption } from '@klickdata/shared-components/src/generic-mobile-tabs/generic-mobile-tabs.component';

@Component({
    selector: 'app-add-materials',
    templateUrl: './add-materials.component.html',
    styleUrls: ['./add-materials.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMaterialsComponent implements OnInit {
    @Input() selection: number[] = [];
    @Output() select: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
    AppScope = AppScope;
    public isMobile: Observable<boolean>;
    public isAdmin: boolean;
    public activityOptions: GenericTabsOption[];
    constructor(protected auth: AuthService, protected mobile: MobileService, protected cdref: ChangeDetectorRef) {
        this.activityOptions = [
            {
                label: $localize`Add link`,
                url: '',
                value: 'addLink',
                index: 0,
            },
            {
                label: $localize`Upload file`,
                url: '',
                value: 'uploadFile',
                index: 1,
            },
            {
                label: $localize`My material`,
                url: '',
                value: 'myMaterial',
                index: 2,
            },
            {
                label: $localize`Library`,
                url: '',
                value: 'library',
                index: 3,
            },
            {
                label: $localize`Textmaterial`,
                url: '',
                value: 'textMaterial',
                index: 4,
            },
        ];
    }
    ngOnInit() {
        this.isMobile = this.mobile.isMobile().pipe(shareReplay());
        this.auth
            .getUser()
            .pipe(
                first(),
                map(user => user.isAdmin())
            )
            .subscribe(isAdmin => {
                this.isAdmin = isAdmin;
                this.activityOptions = isAdmin
                    ? this.activityOptions
                    : this.activityOptions.filter(opt => opt.index === 2 || opt.index === 3);
                this.cdref.markForCheck();
            });
    }
}
