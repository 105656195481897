<div class="lang-select-profile pre-langs">
    <span class="control-label">{{ label }}</span>
    <mat-form-field [floatLabel]="floatLabel">
        <mat-label>{{ placeholder }}</mat-label>
        <mat-select
            #matSelect
            [compareWith]="compareFn"
            (openedChange)="openedChange.emit($event)"
            [formControl]="prefLangsCtrl"
            multiple
        >
            <mat-select-trigger *ngIf="matSelect.value as langs">
                <mat-chip-list>
                    <mat-chip *ngFor="let lang of langs" [removable]="true" (removed)="onLangRemoved(lang)">
                        <span class="flag-icon flag-icon-list flag-icon-{{ lang.flag }}"></span>
                        {{ lang.label }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                </mat-chip-list>
            </mat-select-trigger>

            <mat-option class="select-lang-option" *ngFor="let lang of attachedLanguages | async" [value]="lang">
                <span class="flag-icon flag-icon-list flag-icon-{{ lang.flag }}"></span>
                {{ lang.label }}</mat-option
            >
        </mat-select>
    </mat-form-field>
</div>
