<mat-card class="mt-2" fxFlex="0 0 100%">
    <mat-card-content>
        <div fxFlex="1 1 100%" fxLayoutAlign="center" fxLayout="column" fxLayoutAlign="center" class="mt-1 mb-1">
            <form
                #formDirective="ngForm"
                fxLayout="row wrap"
                (ngSubmit)="submitFile(formDirective)"
                [formGroup]="fileForm"
            >
                <div fxFlex="0 0 100%">
                    <mat-form-field fxFlex="0 1 100%">
                        <input
                            matInput
                            placeholder="Add file headline"
                            i18n-placeholder
                            formControlName="title"
                            required
                        />
                        <mat-error i18n>Title field is required!</mat-error>
                    </mat-form-field>
                    <div fxLayoutAlign="center center" fxFlex="0 0 40px" class="coursePlan-layout">
                        <app-language-selector
                            formControlName="language_id"
                            class="icon-resource-builder"
                            fxFlex="0 0 auto"
                        ></app-language-selector>
                    </div>
                    <div fxLayoutAlign="center center" fxFlex="0 0 40px" class="coursePlan-layout">
                        <app-media
                            #app_media
                            fxFlex="0 0 auto"
                            [simple]="true"
                            *ngIf="!fileForm.get('media_id')?.value"
                            formControlName="media_id"
                            [scope]="AppScope.MATERIAL"
                            dropLabel="Drop course plan image"
                            i18n-dropLabel="@@coursePlanImageDropzoneLabel"
                        ></app-media>
                    </div>
                </div>
                <app-image-view
                    class="mt-2 mb-2"
                    *ngIf="fileForm.get('media_id')?.value"
                    fxFlex="0 1 300px"
                    [media_id]="fileForm.get('media_id')?.value"
                    [proportional]="false"
                >
                    <button
                        class="remove-image"
                        mat-icon-button
                        color="warn"
                        *ngIf="fileForm.get('media_id').value as mediaId"
                        matTooltip="Remove image"
                        i18n-matTooltip
                        (click)="fileForm.get('media_id').setValue(null)"
                    >
                        <mat-icon class="md-24">delete_outline</mat-icon>
                    </button>
                </app-image-view>

                <app-tag-chip-select
                    fxFlex="0 1 100%"
                    [resource_id]="resource?.id"
                    [language_id]="fileForm?.get('language_id').value"
                    formControlName="tag_ids"
                ></app-tag-chip-select>

                <app-category-chip-select
                    fxFlex="0 1 100%"
                    [resource_id]="resource?.id"
                    [language_id]="fileForm?.get('language_id').value"
                    formControlName="category_ids"
                    [type_id]="ResourceTypes.MATERIAL"
                ></app-category-chip-select>

                <div fxFlex="0 1 100%" fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="!fileForm.value.file">
                    <div
                        [ngStyle]="dropStyle"
                        fxFlex="0 0 50%"
                        fxFlex.lt-md="0 0 100%"
                        class="media-dropzone"
                        fxLayout="row wrap"
                        fxLayoutAlign="center center"
                    >
                        <input
                            class="media-upload-button"
                            type="file"
                            (change)="prepareFile($event)"
                            [disabled]="saving | async"
                            accept="*"
                        />
                    </div>

                    <div class="progress-wrapper" *ngIf="progress$ | async; let progress">
                        <mat-progress-bar class="progress" mode="determinate" [value]="progress"></mat-progress-bar>
                        <span class="progress-value">{{ progress }}%</span>
                    </div>
                </div>
                <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                    <mat-expansion-panel #expansionPanel>
                        <mat-expansion-panel-header class="material-acc-header-explain">
                            <div *ngIf="!expansionPanel.expanded" i18n="@@contentDescription">Content description</div>
                            <mat-icon
                                matTooltip="A description of the material that will be visible above the video/ PDF etc"
                                i18n-matTooltip
                                >help_outline</mat-icon
                            >
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <app-text-editor
                                *ngIf="active"
                                fxFlex="1 1 95%"
                                title="Content description"
                                i18n-title
                                [showTemplates]="false"
                                formControlName="description"
                            ></app-text-editor>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                    <mat-expansion-panel #expansionPanelDesc>
                        <mat-expansion-panel-header class="material-acc-header-explain">
                            <div *ngIf="!expansionPanelDesc.expanded" i18n="@@contentInformation">
                                Content information
                            </div>
                            <mat-icon
                                matTooltip="Extra information will be displayed under the material and used for specifications
                                like timestamps etc"
                                i18n-matTooltip
                                >help_outline</mat-icon
                            >
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <app-text-editor
                                *ngIf="active"
                                fxFlex="1 1 95%"
                                title="Content information"
                                i18n-title
                                [showTemplates]="false"
                                formControlName="instructions"
                            ></app-text-editor>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                    <mat-expansion-panel #expansionPanelPrio>
                        <mat-expansion-panel-header class="material-acc-header-explain">
                            <div *ngIf="!expansionPanelPrio.expanded" i18n="@@priority">Priority</div>
                            <mat-icon
                                matTooltip="Prio will determine the order that it will be displayed in sections"
                                i18n-matTooltip
                                >help_outline</mat-icon
                            >
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <mat-form-field fxFlex="1 1 95%">
                                <input
                                    matInput
                                    fxLayoutAlign="end center"
                                    type="number"
                                    placeholder="Please set priority"
                                    i18n-placeholder="@@plsSetPriority"
                                    formControlName="priority"
                                />
                            </mat-form-field>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-checkbox [formControl]="canDownloadControl" color="primary" class="can-download-wrapper mb-1">
                    <div class="can-download" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <mat-icon class="material-icons-outlined">download_for_offline</mat-icon>
                        <span fxLayoutAlign="start center" fxLayoutGap="5px">
                            <span i18n>Learners can download material</span>
                            <mat-icon
                                matTooltip="More functions available in material and then assemble material in course"
                                i18n-matTooltip
                                class="material-icons-outlined info-Icon"
                                >info</mat-icon
                            >
                        </span>
                    </div></mat-checkbox
                >

                <app-media-view
                    *ngIf="fileForm.value.file"
                    fxLayoutAlign="center center"
                    fxFlex="0 0 700px"
                    fxFlex.lt-md="0 0 100%"
                    [media_id]="fileForm.value.file"
                    class="upload-file-material"
                >
                    <button
                        class="remove-file"
                        color="warn"
                        fxFlex="0 1 auto"
                        mat-icon-button
                        matTooltip="Remove file"
                        i18n-matTooltip
                        (click)="fileForm.patchValue({ file: '' }); progress$.next(null)"
                    >
                        <mat-icon class="md-24">delete_outline</mat-icon>
                    </button>
                </app-media-view>
                <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="end" fxLayoutGap=".5em">
                    <button
                        *ngIf="!(saving | async) && !publishOnly"
                        [disabled]="fileForm.invalid"
                        mat-raised-button
                        color="primary"
                        type="submit"
                        i18n="@@save"
                    >
                        Save
                    </button>

                    <button
                        *ngIf="!(saving | async) && (isAdmin$ | async) && !resource?.published"
                        mat-raised-button
                        [disabled]="fileForm.invalid"
                        (click)="submitFile(formDirective, true)"
                        color="primary"
                    >
                        {{ publishLabel }}
                    </button>
                    <app-saving-button *ngIf="saving | async"></app-saving-button>
                </div>
            </form>
        </div>
    </mat-card-content>
</mat-card>
