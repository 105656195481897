import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import {
    MessageFormErrorComponent,
    MessageSavedComponent,
    MessageSentComponent,
    MessageService,
} from '@klickdata/core/message';
import { SideNaveActionsTypes, SideNaveData } from '@klickdata/core/mobile';
import { User, UserMessage, UserService } from '@klickdata/core/user';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-menu-side-mail-text-block',
    templateUrl: './menu-side-mail-text-block.component.html',
    styleUrls: ['./menu-side-mail-text-block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideMailTextBlockComponent implements OnDestroy, OnInit, OnChanges {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<boolean> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    private destroy: Subject<boolean> = new Subject<boolean>();
    public mailForm: FormGroup;
    public userCtrl = new FormControl();
    public userRecipients: User[] = [];
    public userSearch$: Observable<User[]>;
    @ViewChild('userRecieverInput') userRecieverInput: ElementRef<HTMLInputElement>;
    public authUser: User;

    constructor(
        protected fb: FormBuilder,
        protected messageService: MessageService,
        protected userService: UserService,
        protected auth: AuthService,
        protected cdr: ChangeDetectorRef
    ) {
        this.buildForm();
        this.auth
            .getUser()
            .pipe(take(1), takeUntil(this.destroy))
            .subscribe((user) => {
                this.authUser = user;
                this.cdr.markForCheck();
            });
    }
    ngOnInit(): void {
        if (this.navData.data) {
            this.mailForm.patchValue({ subject: this.navData.data.title, body: this.navData.data.name });
        }
        this.userSearch$ = this.userCtrl.valueChanges
            .pipe(
                filter((term: string) => typeof term === 'string' && term != ''),
                debounceTime(300),
                distinctUntilChanged()
            )
            .pipe(
                switchMap((term) =>
                    term.trim()
                        ? this.userService.getCustomerUsers({ customerId: this.authUser.customer_id, term: term })
                        : of(<User[]>[])
                ),
                map((users) =>
                    users.filter(
                        (user) =>
                            this.userRecipients.findIndex((recipient) => recipient.id === user.id) === -1 &&
                            user.id !== this.authUser.id
                    )
                ),
                takeUntil(this.destroy)
            );
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (this.navData.data) {
            this.mailForm.patchValue({ subject: this.navData.data.title, body: this.navData.data.name });
        }
    }

    public buildForm() {
        this.mailForm = this.fb.group({
            subject: [''],
            body: [''],
            type: ['client'],
            userIds: [],
        });
    }
    public selected(auto: MatAutocomplete, event: MatAutocompleteSelectedEvent): void {
        this.userRecipients.push(event.option.value);
        this.userRecieverInput.nativeElement.value = '';
        this.userCtrl.setValue('');

        auto.options.reset([]);
        this.updateReciepentIds();
        this.cdr.markForCheck();
    }
    public removeUser(user: User): void {
        const index = this.userRecipients.indexOf(user);
        if (index >= 0) {
            this.userRecipients.splice(index, 1);
            this.updateReciepentIds();
        }
    }
    private updateReciepentIds() {
        this.mailForm.get('userIds').setValue(this.userRecipients.map((user) => user.id));
    }
    public submit() {
        if (this.mailForm.value.type === 'client') {
            this.onClose.emit(true);
            window.open(
                `mailto:?subject=${this.mailForm.value.subject}&body=${this.convertToPlain(this.mailForm.value.body)}`,
                '_blank'
            );
            return;
        } else {
            if (!this.mailForm.value.body || !this.mailForm.value.userIds) {
                FormHelper.markForm(this.mailForm);
                this.messageService.openMessage(MessageFormErrorComponent);
                return;
            }
        }

        this.saving.emit(true);
        this.userService
            .sendMailToMultipleUsers({
                ...new UserMessage({ body: this.mailForm.value.body, subject: this.mailForm.value.subject }),
                users_attach: this.mailForm.value.userIds,
            })
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.onClose.emit(true);
                this.saving.emit(false);
                this.messageService.openMessage(MessageSavedComponent, $localize`Mail sent`);
                this.mailForm.reset();
                this.mailForm.patchValue({ body: '' });
                FormHelper.resetForm(this.mailForm);
            });
    }
    convertToPlain(html: string) {
        const htmlText = html.replace(/<\/p>/g, '</p>%0D%0A %0D%0A');
        const tempDivElement = document.createElement('div');
        tempDivElement.innerHTML = htmlText;
        return tempDivElement.textContent || tempDivElement.innerText || '';
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
