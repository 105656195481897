<form [formGroup]="userForm" *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>person</mat-icon>
        <div i18n>Add a new learner</div>
    </div>
    <app-form-error
        class="error-form"
        fxFlex="0 0 100%"
        fxFlex.lt-md="1 1 100%"
        header="Error"
        i18n-header
        [messages]="error | async"
    ></app-form-error>
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start center">
        <app-user-setting-input
            type="text"
            i18n-placeholder
            placeholder="Enter first name"
            [control]="userForm.get('fname') | cast"
        >
            <label role="input-label" i18n="@@firstName">First Name*</label>
        </app-user-setting-input>

        <app-user-setting-input
            type="text"
            i18n-placeholder
            placeholder="Enter last name"
            [control]="userForm.get('lname') | cast"
        >
            <label role="input-label" i18n="@@lastName">Last Name*</label>
        </app-user-setting-input>

        <app-user-setting-input
            type="email"
            i18n-placeholder
            placeholder="Enter email address"
            [control]="userForm.get('email') | cast"
            tips="This is used for login to KLMS"
            i18n-tips
        >
            <label role="input-label">
                <ng-container i18n="@@email"> Email*</ng-container>
            </label>
        </app-user-setting-input>

        <div *ngIf="navData.data.user" fxLayoutGap="4px" fxLayout="column" class="w-100 roles role-validity">
            <span i18n>Learner role*</span>
            <mat-form-field class="role-field">
                <mat-select fxFlex="1 1 100%" placeholder="Select role" i18n-placeholder formControlName="role_value">
                    <mat-option value="user" i18n>Learner</mat-option>
                    <mat-option value="groupadmin" i18n> Group Admin </mat-option>
                    <mat-option *ngIf="navData.data.user.role_value !== 'groupadmin'" value="customeradmin" i18n>
                        Main Admin
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="userForm.value.role_value === 'groupadmin'" class="mt-1">
                <app-group-chip-select formControlName="attached_groups"></app-group-chip-select>
            </div>
        </div>

        <div fxLayoutGap="4px" fxLayout="column" class="w-100 notes-wrap role-validity">
            <span i18n>HR Note</span>
            <mat-form-field class="notes-form" fxFlex="0 1 100%" floatLabel="never" appearance="fill">
                <textarea
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="2"
                    cdkAutosizeMaxRows="15"
                    #input
                    matInput
                    type="text"
                    i18n-placeholder
                    placeholder="Type in your note here"
                    formControlName="notes"
                >
                </textarea>
            </mat-form-field>
        </div>
    </div>

    <div class="action" fxLayout="row-reverse wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
        <ng-container *ngIf="!(loading$ | async)">
            <button
                [disabled]="userForm.invalid"
                fxFlex="0 0 auto"
                class="mobile-button custom"
                (click)="submit(true)"
                mat-raised-button
            >
                <span i18n>Create and Send</span>
            </button>
            <button
                [disabled]="userForm.invalid"
                fxFlex="0 0 auto"
                class="mobile-button positive"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n>Create and Edit</span>
            </button>
        </ng-container>
        <app-kd-logo-loading *ngIf="loading$ | async" size="small"></app-kd-logo-loading>
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit(); userForm.reset()" mat-button>
            <span i18n>Cancel</span>
        </button>
    </div>
</form>
