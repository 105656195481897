import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SideNaveData, SideNaveActionsTypes } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-user-actions-template',
    templateUrl: './menu-side-user-actions-template.component.html',
    styleUrls: ['./menu-side-user-actions-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideUserActionsTemplateComponent {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;

    constructor() {}
}
