<ng-container *ngIf="data">
    <ng-container *ngIf="user$ | async; let user">
        <app-resource-search-listing
            [id]="data.id"
            [label]="data.label"
            [user]="user"
            [scope_id]="data.scope_id"
            (onDismissSheet)="dismissSheet()"
        ></app-resource-search-listing>
    </ng-container>
</ng-container>
