import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppScope } from '@klickdata/core/resource';
import { User } from '@klickdata/core/user';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'app-chips-with-add-handler',
    templateUrl: './chips-with-add-handler.component.html',
    styleUrls: ['./chips-with-add-handler.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsWithAddHandlerComponent implements OnChanges {
    @ViewChild('autoCompleteInput', { static: false }) inputEl: ElementRef;
    @Input() loadingUserSelectedItems: BehaviorSubject<boolean>;
    @Input() fullItemsList$: Observable<any[]>;
    @Input() user: User;
    @Input() searchInputLabel: string;
    @Input() mainTitle: string;
    @Input() subTitle: string;
    @Input() itemScope: AppScope;
    @Input() activeItemsSelection: any[];
    @Output() onItemSelection: EventEmitter<any> = new EventEmitter();
    @Output() onItemRemoval: EventEmitter<any> = new EventEmitter();
    @Output() onSearchInput: EventEmitter<string> = new EventEmitter();
    public toggleSearchField: boolean;
    public addItemControl: FormControl = new FormControl();
    AppScope = AppScope;
    constructor(protected cdr: ChangeDetectorRef) {}

    public reInitPanelSelection() {
        // Show the panel selection
        this.toggleAutoCompletPanel(false);
        this.toggleAutoCompletPanel(true);
        this.cdr.markForCheck();
    }
    ngOnChanges(changes: SimpleChanges) {
        this.activeItemsSelection = changes.activeItemsSelection?.currentValue;
        this.cdr.markForCheck();
    }
    /**
     * Display name instead of id.
     */
    public displayName(item: any): string {
        return item ? item.title : '';
    }
    /**
     * Workaround to show autocomplete opened panel
     * By focus the input trigger
     */
    public toggleAutoCompletPanel(foucsInput: boolean) {
        setTimeout(() => (foucsInput ? this.inputEl.nativeElement.focus() : this.inputEl.nativeElement.blur()), 300);
    }
}
