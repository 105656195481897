<mat-expansion-panel *ngFor="let resource of resources" [expanded]="true" #expansionPanel>
    <mat-expansion-panel-header>
        <div class="header-wrapper" fxLayout="row" fxLayoutGap="8px">
            <div class="header-body-img">
                <app-image-view [media_id]="resource.media$" [proportional]="false"></app-image-view>
            </div>
            <div class="header-body-info" fxLayout="column" fxLayoutAlign="space-between start">
                <span class="item-title">{{ resource.title }}</span>
                <span class="tasks-wrapper">
                    <span class="tasks">{{ resource.number_of_items }}</span>
                    <span fxHide i18n="@@tasks">Tasks</span></span
                >
            </div>
        </div>
    </mat-expansion-panel-header>
    <div class="expansion-body">
        <div class="expansion-body-info">
            <div *ngIf="resource.bullets" class="row">
                <span class="row-header" i18n="@@summary">Summary</span>
                <span class="row-content">{{ resource.bullets }}</span>
            </div>
            <div *ngIf="resource.end_date" class="row">
                <span class="row-header" i18n="@@deadline">Deadline</span>
                <span class="row-content">{{ resource.opportunity_end_date | kdDateFormat: 'YYYY-MM-DD' }}</span>
            </div>
            <div class="row">
                <span class="row-header" i18n="@@status">Status</span>
                <span class="row-content">
                    {{ (resource | resourceStatus: true)?.status }}
                </span>
            </div>
            <div fxHide *ngIf="resource.author$ | async; let loadedAuthor" class="row">
                <span class="row-header" i18n="@@createdBy">Created by</span>
                <span class="row-content">{{ $any(loadedAuthor).name }}</span>
            </div>
            <div *ngIf="resource.published" class="row">
                <span class="row-header" i18n="@@publishedAt">Published at</span>
                <span class="row-content">{{ resource.published | kdDateFormat: 'YYYY-MM-DD' }}</span>
            </div>
            <div fxHide class="row">
                <span class="row-header" i18n="@@endReward">End reward</span>
                <span class="row-content">
                    <mat-icon
                        *ngIf="
                            (resource.examination?.type !== 'Certification' &&
                                resource.examination?.type !== 'Diploma') ||
                            !resource.examination
                        "
                        >school</mat-icon
                    >
                    <mat-icon *ngIf="resource.examination?.type === 'Certification'">local_police</mat-icon>
                    <mat-icon *ngIf="resource.examination?.type === 'Diploma'">military_tech</mat-icon>

                    <span *ngIf="resource.examination && resource.examination?.label">{{
                        resource.examination?.label
                    }}</span>
                    <span *ngIf="!resource.examination" i18n="@@participationCertificate"
                        >Participation Certificate</span
                    >
                </span>
            </div>
            <div *ngIf="resource.duration" class="row">
                <span class="row-header" i18n="@@duration">Duration</span>
                <span class="row-content">{{ resource?.duration | formatIsoDuration }}</span>
            </div>
            <div class="row">
                <span class="row-header" i18n="@@price">Price</span>
                <span *ngIf="resource.price !== 0 && resource.price" class="row-content"
                    >{{ resource.price }}&nbsp;{{ resource.currency }}</span
                >
                <span *ngIf="resource.price === 0 || !resource.price" class="row-content" i18n="@@free"
                    >Free</span
                >
            </div>
            <div class="row">
                <span class="row-header" i18n="@@fullInfo">Full info</span>
                <button
                    class="view"
                    mat-icon-button
                    matTooltip="View"
                    (click)="onBrowse.emit(resource)"
                    i18n-matTooltip="@@view"
                >
                    <mat-icon>visibility</mat-icon>
                </button>
            </div>
            <div class="row">
                <button
                    class="play"
                    mat-raised-button
                    [matTooltip]="getPlayBtnTxt(resource)"
                    (click)="onPlay.emit(resource)"
                >
                    <mat-icon *ngIf="!resource.opportunity_id">play_circle</mat-icon>
                    <mat-icon *ngIf="resource.opportunity_id">play_circle_outline</mat-icon>
                    <ng-container *ngIf="resource.opportunity_id" i18n="@@continue">Continue</ng-container>
                    <ng-container *ngIf="!resource.opportunity_id" i18n="@@start">Start</ng-container>
                </button>
            </div>
        </div>
        <div fxHide class="expansion-body-btn">
            <button
                class="view"
                mat-icon-button
                matTooltip="View"
                (click)="onBrowse.emit(resource)"
                i18n-matTooltip="@@view"
            >
                <mat-icon>visibility</mat-icon>
            </button>
        </div>
    </div>
</mat-expansion-panel>
