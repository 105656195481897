import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { User } from '@klickdata/core/user/src/user.model';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';

@Component({
    selector: 'app-notes-resource-listing',
    templateUrl: './notes-resource-listing.component.html',
    styleUrls: ['./notes-resource-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesResourceListingComponent implements OnInit {
    public authUser$: Observable<User>;

    constructor(protected auth: AuthService) {}

    ngOnInit(): void {
        this.authUser$ = this.auth.getUser().pipe(take(1));
    }
}
