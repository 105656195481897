import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import {
    AbstractFormGroup,
    AfterFormInit,
    AfterParentAttached,
    FormGeneratorService,
    FormHelper,
} from '@klickdata/core/form';
import { Resource } from '@klickdata/core/resource';
import { Observable, of, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { Reminder, ReminderData } from './reminder.model';
import { ReminderService } from './reminder.service';

@Injectable()
export class ReminderForm extends AbstractFormGroup<Reminder> implements AfterFormInit, AfterParentAttached, OnDestroy {
    protected destroy: Subject<boolean> = new Subject<boolean>();
    public onParentUpdated: Subject<Resource> = new Subject<Resource>();

    constructor(
        protected auth: AuthService,
        protected generator: FormGeneratorService,
        protected reminderService: ReminderService
    ) {
        super(Reminder, generator);
    }

    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }

    public create(parentModel: Resource): Observable<Reminder> {
        const subject = $localize`Course plan reminder`;
        this.model.subject = `${subject}  ${parentModel.title}`;
        this.model.body = `Hi ${'«first-name»'}, <br/> This is the link to ${
            parentModel.title
        } <br/> ${'«access-link»'}`;
        return of(this.model);
    }

    public export(): ReminderData {
        // this.commit();
        return new Reminder({ ...this.model.getData(), ...this.form.value }).getData();
    }

    public import(data: Reminder[]): void {
        const reminderDate = data[0].getData();
        this.model = new Reminder(reminderDate);
    }

    public submit(): Observable<Reminder[]> {
        return this.reminderService.store(this.export()).pipe(
            finalize(() => {
                FormHelper.resetForm(this.form);
            })
        );
    }

    public afterFormInit(): void {
        this.form.addControl('dates', this.generator.control([]));
    }

    public afterParentAttached(): void {
        this.parent.modelUpdate.pipe(takeUntil(this.destroy)).subscribe((update: Resource) => {
            this.onParentUpdated.next(update);
        });
    }
}
