import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment-duration-format';
@Pipe({
    name: 'formatIsoDuration',
})
export class FormatIsoDurationPipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) protected localeId: string) {
        this.localeId = localeId === 'en' ? 'en-US' : localeId;
    }

    transform(duration: any, isECourse?: boolean): string {
        if (isECourse) {
            return `${duration.toString()?.substring(0, 1)}hr ${duration.toString()?.substring(2, 4)}min`;
        } else {
            const transformetTime = moment.duration({ s: duration }).format('H[h] m[m] s[s]');

            return transformetTime.includes('h') && transformetTime.includes('m')
                ? moment.duration({ s: duration }).format('H[h] m[m]')
                : moment.duration({ s: duration }).format('m[m] s[s]');
        }
    }
}
