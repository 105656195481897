import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { HasOne } from './relations/has-one';
import { Type } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IModel } from '@klickdata/core/application';
import { FormGeneratorService } from './form-generator.service';
import { FormHelper } from '../form-helper';

export interface AfterFormInit {
    afterFormInit(): void;
}

export interface AfterParentAttached {
    afterParentAttached(): void;
}

export interface OnValidation {
    onValidation(status: boolean): void;
}

export abstract class AbstractForm<T extends IModel> {
    protected _modelUpdate: BehaviorSubject<T> = new BehaviorSubject<T>(undefined);
    protected _formSubject: Subject<AbstractControl> = new Subject<AbstractControl>();
    protected _loading: Subject<boolean> = new Subject<boolean>();
    protected _relations: { [key: string]: HasOne<any> } = {};
    protected destroy: Subject<boolean> = new Subject<boolean>();

    protected constructor(protected class_t: new (data?) => T, protected generator: FormGeneratorService) {
        this.model = new this.class_t();
        this._form = this.generator.generate<T>(this._model);

        this._form.reset(this._model.getData());

        if (this['afterFormInit']) {
            this['afterFormInit']();
        }
    }

    protected _model: T;

    public get model(): T {
        return this._model;
    }

    public set model(model: T) {
        this._model = model;
        this._modelUpdate.next(this._model);
    }

    protected _form: FormGroup | FormArray;

    public get form(): FormGroup | FormArray {
        return this._form;
    }

    public set form(form: FormGroup | FormArray) {
        this._form = form;
        this._formSubject.next(this._form);
    }

    protected _parent: AbstractForm<IModel> | null;

    public get parent(): AbstractForm<IModel> | null {
        return this._parent;
    }

    public get modelUpdate(): Observable<T> {
        return this._modelUpdate;
    }

    public get value(): any {
        return this._form.value;
    }

    public get valid(): any {
        return this._form.valid;
    }

    /**
     * Check form status if the form is pristine.
     */
    public isPristine(): boolean {
        return this._form.pristine;
    }

    public export(): any {
        return this.model;
    }

    public disable(fieldName: string) {
        this.form.get(fieldName).disable();
    }

    public onChange(): Observable<any> {
        return this.form.valueChanges;
    }

    public setParent(parent: AbstractForm<IModel>) {
        this._parent = parent;

        if (this['afterParentAttached']) {
            this['afterParentAttached']();
        }
    }

    public validate(): boolean {
        if (this['onValidation']) {
            this['onValidation'](this.form.valid);
        }

        if (this.form.valid) {
            return true;
        }

        FormHelper.markForm(this.form);
        return false;
    }

    public abstract resetState(recursive?: boolean): void;

    public abstract submit(recursive: boolean): Observable<any>;

    public abstract import(data: any): void;

    public abstract create(data?: any): Observable<T> | T;

    public abstract connect(childForm: AbstractControl, propertyName: string): void;

    /**
     * Apply form edits to model
     */
    protected commit(): void {
        this.model = new this.class_t(Object.assign({}, this.model.getData(), this.form.value));
    }

    protected hasOne<R>(childClass: Type<R>, propertyName: string): HasOne<R> {
        if (!this._relations[propertyName]) {
            this._relations[propertyName] = new HasOne<R>(this as AbstractForm<IModel>, childClass, propertyName);
        }

        return this._relations[propertyName];
    }

    public setLoading(loading: boolean | Subject<boolean>) {
        if (typeof loading === 'boolean') {
            this._loading.next(loading);
        } else if (loading) {
            this._loading = loading;
        }
    }

    public isLoading(): Observable<boolean> {
        return this._loading.asObservable();
    }

    public getLoading(): Subject<boolean> {
        return this._loading;
    }
}
