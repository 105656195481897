<div fxLayout="column" class="wrap">
    <app-chat-dialog
        class="notes-dialog"
        *ngFor="let dialogNote of dialogNotes$ | async"
        [dialog]="dialogNote"
        [authorDir]="authUser?.id === dialogNote.author_id ? 'right' : 'left'"
        [fxLayoutAlign]="authUser?.id === dialogNote.author_id ? 'end center' : 'start center'"
    ></app-chat-dialog>
    <div class="form" fxLayout="row wrap">
        <div class="input-wrapper" fxLayout="column" fxLayoutGap="10px">
            <div fxLayoutAlign="space-between center">
                <span class="label" i18n>Add note</span>
                <mat-hint
                    >{{ note?.value.length }} <span> (256)</span>
                    <mat-icon class="info" i18n-matTooltip matTooltip="Max input characters are 256 letter"
                        >question_mark</mat-icon
                    ></mat-hint
                >
            </div>
            <mat-form-field floatLabel="never" appearance="fill">
                <textarea
                    maxlength="256"
                    rows="1"
                    i18n-placeholder
                    placeholder="Type in your note"
                    matInput
                    [formControl]="note"
                    class="note-body-input"
                ></textarea>
            </mat-form-field>
        </div>
        <div class="submit-wrapper">
            <button
                *ngIf="!(loading | async)"
                i18n-matTooltip
                matTooltip="Add note to task"
                mat-icon-button
                type="submit"
                [disabled]="!note.value.length"
                class="submit-btn"
                (click)="addNote()"
            >
                <mat-icon>note_alt</mat-icon>
            </button>
            <app-kd-logo-loading *ngIf="loading | async" size="small"></app-kd-logo-loading>
        </div>
    </div>
</div>
