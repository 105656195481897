import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { DateRangeDialogComponent } from '@klickdata/core/application/src/date-range-dialog/date-range-dialog.component';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Filter, SelectFilterOption } from '../filter';
import { FilterBase } from '../filter-base';
import { GlobalFilterColor, GlobalFilterProperty, GlobalFilterPropertyType } from './../../global-filters-types.enum';
import { Utils } from '@klickdata/core/util';

@Component({
    selector: 'app-select-filter',
    templateUrl: './select-filter.component.html',
    styleUrls: ['./select-filter.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectFilterComponent),
            multi: true,
        },
        { provide: FilterBase, useExisting: SelectFilterComponent },
    ],
})
export class SelectFilterComponent extends FilterBase implements ControlValueAccessor, OnInit {
    @Input() icon: boolean;
    @Input() inGlobalFilter: boolean;
    @Input() ignoreCaching: boolean;
    @Input() label: string;
    @Input() context: string;
    @Input() options: SelectFilterOption[];
    @Input()
    set property(property: GlobalFilterProperty | { name: GlobalFilterProperty; type?: GlobalFilterPropertyType }) {
        if (typeof property === 'object') {
            this.filter.property = property.name;
            this.filter.type = property.type;
        } else {
            this.filter.property = property;
        }
        this.updateFilterExtra();
    }
    @ViewChild(MatSelect) matSelect: MatSelect;
    @Output() filterChange: EventEmitter<Filter<string>> = new EventEmitter<Filter<string>>();
    @Output() onSelectingOption: EventEmitter<{ option: SelectFilterOption; property: string }> = new EventEmitter<{
        option: SelectFilterOption;
        property: string;
    }>();
    @Output() sortChange: EventEmitter<Filter<string>[]> = new EventEmitter<Filter<string>[]>();
    @Output() filterRemoval: EventEmitter<Filter<string>> = new EventEmitter<Filter<string>>();
    protected _filter: Filter<string> = new Filter<string>();
    GlobalFilterProperty = GlobalFilterProperty;

    public direction: 'asc' | 'desc' = 'asc';
    // public filterProperrty: string;
    public customer: Observable<Customer>;

    get selected(): SelectFilterOption {
        return this._selected;
    }

    set selected(selected: SelectFilterOption) {
        this._selected = selected;
        this.propagateChange(this._selected);
    }
    private _selected: SelectFilterOption;

    public propagateChange = (_: any) => {};
    constructor(protected changeRef: ChangeDetectorRef, protected dialog: MatDialog, protected auth: AuthService) {
        super();
    }
    ngOnInit() {
        this.customer = this.auth.getCustomer();
    }

    public onSelectionChanged(value: any) {
        this.onSelectingOption.emit({ option: value, property: this.filter.property });
        /**
         * Handle case when select filter has date range
         * selection as an option
         */
        if (this.filter.property === GlobalFilterProperty.TIME_SPENT && value.value === 'select_date_range') {
            this.dialog
                .open(DateRangeDialogComponent, {
                    disableClose: true,
                    data: {
                        customer: this.customer,
                        label: $localize`Pls select date range`,
                    },
                })
                .afterClosed()
                .pipe(filter((result) => !!result))
                .subscribe((dateValue) => {
                    this.selected = value;
                    this.setFilterItems([dateValue.begin, dateValue.end]);
                    this.filterChange.emit(this.filter);
                });
        } else {
            if ((!value && this.filter.items && this.filter.items.length) || !value) {
                this.filterRemoval.emit(new Filter('end', []));
                this.filter.items = null;
                this.selected = null;
                this.filterChange.emit(this.filter);
            } else if (!this.filter.items || value !== this.filter.items[0]) {
                this.filterRemoval.emit(new Filter('end', []));
                this.setFilterItems([value]);
                this.filterChange.emit(this.filter);
                if (value.sorting) {
                    this.direction = value.sorting;
                }
                this.sort();
            }
            this.changeRef.detectChanges();
        }
    }

    /**
     * @override
     * @param items
     */
    protected setFilterItems(items: any[]) {
        const item = items[0];
        if (items.length > 1) {
            this.filter.items = items;
            this.filter.composite = [
                { property: 'begin', items: [items[0]] },
                { property: 'end', items: [items[1]] },
            ];
        } else if (item?.value) {
            this.filter.items = [item.value];
            this.filter.chips = items;
            this.selected = item;
        } else {
            this.setSelectedOption(item);
        }
    }

    private sort() {
        this.sortChange.emit([this.filter, { property: 'dir', items: [this.direction] }]);
    }
    public sortToggle() {
        this.direction = this.direction === 'asc' ? 'desc' : 'asc';
        this.sort();
    }
    protected ignoreCache(): boolean {
        return this.ignoreCaching;
    }
    private updateFilterExtra() {
        switch (this.filter.property) {
            case GlobalFilterProperty.USER_ROLE:
                this.filter.label = $localize`:@@academyRole:Academy role`;
                this.filter.icon = 'account_circle';
                this.filter.color = { bg: GlobalFilterColor.C3, fg: GlobalFilterColor.C1 };
                this.filter.styleClass = 'global-userRole-filter-selector';
                break;
            case GlobalFilterProperty.TASK_PRIO:
                this.filter.label = $localize`:@@prio:Prio`;
                this.filter.icon = 'electric_bolt ';
                this.filter.color = { bg: GlobalFilterColor.C3, fg: GlobalFilterColor.C1 };
                this.filter.styleClass = 'global-userRole-filter-selector';
                break;
            case GlobalFilterProperty.REVIEW_STATUS:
                this.filter.label = $localize`:@@approval:Approval`;
                this.filter.icon = 'assignment_turned_in ';
                this.filter.color = { bg: GlobalFilterColor.C3, fg: GlobalFilterColor.C1 };
                this.filter.styleClass = 'global-resApprovalStatus-filter-selector';
                break;
            case GlobalFilterProperty.NEWS_TYPE:
                this.filter.label = $localize`:@@newsType:News Type`;
                this.filter.icon = 'speaker_notes';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-newsType-filter-selector';
                break;
            case GlobalFilterProperty.TASK_ACTION:
                this.filter.label = $localize`:@@actions:Actions`;
                this.filter.icon = 'touch_app';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-userRole-filter-selector';
                break;
            case GlobalFilterProperty.CUSTOM_FIELD_STATUS:
                this.filter.label = $localize`:@@status:Status`;
                this.filter.icon = 'touch_app';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-userRole-filter-selector';
                break;
            case GlobalFilterProperty.PERMISSION_TYPE:
                this.filter.label = $localize`:@@accessPermission:Access permission`;
                this.filter.icon = 'key';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-userRole-filter-selector';
                break;
            case GlobalFilterProperty.COMPETENCE_PLANNER_STATUS:
                this.filter.label = $localize`:@@status:Status`;
                this.filter.icon = 'touch_app';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.filterResultsChipStyleClass = 'competence-status-chip';
                break;
            case GlobalFilterProperty.NOTE_VISIBILITY:
                this.filter.label = $localize`:@@visibility:Visibility`;
                this.filter.icon = 'visibility';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-userRole-filter-selector';
                break;
            case GlobalFilterProperty.NOTE_CONTEXT:
                this.filter.label = $localize`:@@context:Context`;
                this.filter.icon = 'category';
                this.filter.color = { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-userRole-filter-selector';
                break;
            case GlobalFilterProperty.CUSTOM_FIELD_TYPE:
                this.filter.label = $localize`:@@type:Type`;
                this.filter.icon = 'category';
                this.filter.color = { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.C1 };
                this.filter.styleClass = 'global-userRole-filter-selector';
                break;
            case GlobalFilterProperty.TASK_TYPE:
                this.filter.label = $localize`:@@types:Types`;
                this.filter.icon = 'person';
                this.filter.color = { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 };
                this.filter.styleClass = 'global-userRole-filter-selector';
                break;
            case GlobalFilterProperty.TASK_DONE:
                this.filter.label = $localize`:@@done:Done`;
                this.filter.icon = 'done_outline';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-taskDone-filter-selector';
                break;
            case GlobalFilterProperty.CUSTOMER_SIZE:
                this.filter.label = $localize`:@@academySize:Academy size`;
                this.filter.icon = 'badge';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-customerSize-filter-selector';
                break;
            case GlobalFilterProperty.MY_TASKS:
                this.filter.label = $localize`:@@myTasks:My Tasks`;
                this.filter.icon = 'switch_account';
                this.filter.color = { bg: GlobalFilterColor.C3, fg: GlobalFilterColor.C1 };
                this.filter.styleClass = 'global-myTasks-filter-selector';
                break;
            case GlobalFilterProperty.CUSTOMER_CONTACT:
                this.filter.label = $localize`:@@contact:Contact`;
                this.filter.icon = 'admin_panel_settings';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-userRole-filter-selector';
                break;
            case GlobalFilterProperty.CATALOG_SOURCE:
                this.filter.label = $localize`:@@source:Source`;
                this.filter.icon = 'library_books';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-content-source-filter-selector';
                break;
            case GlobalFilterProperty.RESOURCS_OCCASION:
                this.filter.label = $localize`:@@status:Status`;
                this.filter.icon = 'library_books';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-content-source-filter-selector';
                break;
            case GlobalFilterProperty.ASSGN_STATUS:
                this.filter.label = $localize`:@@assignStatus:Assign Status`;
                this.filter.icon = 'assignment_ind';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-content-source-filter-selector';
                break;
            case GlobalFilterProperty.MESSAGE_SCOPEID:
                this.filter.label = $localize`:@@type:Type`;
                this.filter.icon = 'mms';
                this.filter.color = { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 };
                this.filter.styleClass = 'global-message-type-filter-selector';
                break;
            case GlobalFilterProperty.SCOPE:
                if (this.filter.type === GlobalFilterPropertyType.RESOURCE_TYPE) {
                    this.filter.label = $localize`:@@type:Type`;
                    this.filter.icon = 'category';
                    this.filter.color = { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.WHITE };
                    this.filter.styleClass = 'global-resource-type-filter-selector';
                } else {
                    this.filter.label = $localize`:@@function:Function`;
                    this.filter.icon = 'build_circle';
                    this.filter.color = { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 };
                    this.filter.styleClass = 'global-message-type-filter-selector';
                }

                break;
            case GlobalFilterProperty.STATUS:
                switch (this.filter.type) {
                    case GlobalFilterPropertyType.STATUS_RESOURCE:
                        this.filter.label = $localize`:@@state:State`;
                        this.filter.icon = 'settings_suggest';
                        this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                        this.filter.styleClass = 'global-resourceRole-filter-selector';
                        break;
                    case GlobalFilterPropertyType.STATUS_TAG:
                        this.filter.label = $localize`:@@tagStatus:Tag Status`;
                        this.filter.icon = 'label';
                        this.filter.color = { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 };
                        this.filter.styleClass = 'global-date-filter-selector';
                        break;
                    case GlobalFilterPropertyType.STATUS_USER:
                        this.filter.label = $localize`:@@status:Status`;
                        this.filter.icon = 'manage_accounts';
                        this.filter.color = { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 };
                        this.filter.styleClass = 'global-userStatus-filter-selector';
                        break;
                }
                break;
            case GlobalFilterProperty.SECTION_SORTING:
                this.filter.label = $localize`:@@sort:Sort`;
                this.filter.icon = 'sort';
                this.filter.color = { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 };
                this.filter.styleClass = 'global-resourceRole-filter-selector';
                break;
            case GlobalFilterProperty.LOG_EVENT:
                this.filter.label = $localize`:@@action:Action`;
                this.filter.icon = 'touch_app';
                this.filter.color = { bg: GlobalFilterColor.C4, fg: GlobalFilterColor.C1 };
                this.filter.styleClass = 'global-logEvent-filter-selector';
                break;
            case GlobalFilterProperty.TIME_SPENT:
                this.filter.label = $localize`:@@duration:Duration`;
                this.filter.icon = 'av_timer';
                this.filter.color = { bg: GlobalFilterColor.C5, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-timeSpent-filter-selector';
                break;
            default:
                this.filter.label = $localize`:@@selected:Selected`;
                this.filter.icon = 'done_outline';
                this.filter.color = { bg: GlobalFilterColor.C1, fg: GlobalFilterColor.WHITE };
                this.filter.styleClass = 'global-selected-filter-selector';
        }
    }

    /**
     * @override
     * @returns
     */
    protected getItemsForCache(): any {
        return this.selected.value !== 'select_date_range' ? [this.selected] : this.filter.items;
    }

    clear() {
        this.filter.items = null;
        this.filterRemoval.emit(this.filter);
        this.matSelect.value = undefined;
        this.onSelectingOption.emit({
            option: {
                title: '',
                value: '',
                icon: '',
                class: 'default',
            },
            property: this.filter.property,
        });
        this.changeRef.markForCheck();
    }

    writeValue(selected: string): void {
        if (this.options) {
            this._selected = this.options.find((item) => item.value === selected);
        }
    }

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}

    public get filter(): Filter<string> {
        return <Filter<string>>this._filter;
    }

    public setSelectedOption(value: string | number): void {
        /**
         * unify true/false equility with 0/1
         */
        const activeOption = this.options.find((opt) => Utils.isEqual(opt.value, value));
        if (activeOption) {
            this.matSelect.value = activeOption;
            this.onSelectionChanged(activeOption);
            this.changeRef.markForCheck();
        }
    }
}
