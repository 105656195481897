import 'reflect-metadata';
import { AbstractForm } from '../abstract-form';
import { FormArray, FormGroup } from '@angular/forms';
import { IModel } from '@klickdata/core/application';
import { Subject } from 'rxjs';
import { FormGeneratorService } from '@klickdata/core/form';

const hasManyMetadataKey = Symbol('has-many');

export function hasMany() {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        // console.log(target);
    };
}

export function getAllHasManyRelationships<T extends IModel>(target): AbstractForm<T> {
    return Reflect.getOwnMetadata(hasManyMetadataKey, target);
}

export class HasMany<P, C> {
    public change: Subject<void> = new Subject<void>();
    protected _formArray: FormArray;
    protected _modelArray: C[] = [];

    constructor(
        protected _parent: P,
        protected generator: FormGeneratorService,
    ) {
        this._formArray = this.generator.array();
    }

    public get controls(): FormGroup[] {
        return this._formArray.controls as FormGroup[];
    }

    public attach(child: C): void {
        this._modelArray.push(child);
    }

    public add(): void {
        this.change.next();
    }
}
