import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { UserStatistic } from '@klickdata/core/user/src/statistics/user-statistic.model';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UserData } from '../user.model';

@Injectable({
    providedIn: 'root',
})
export class UserStatisticService {
    protected resourceUrl: string;
    protected customer_id: Observable<number>;

    constructor(
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected auth: AuthService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}user-statistics`;
        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map(customer => customer.id)
        );
    }

    fill(data: UserData): UserStatistic {
        return new UserStatistic(data);
    }
}
