import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DeleteDialogComponent } from '@klickdata/core/application';
import { AlertDialogComponent } from '@klickdata/core/application/src/alert-dialog/alert-dialog.component';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import {
    MessageDeletedComponent,
    MessageDeletedUndoComponent,
    MessageDuplicatedComponent,
    MessageSavedComponent,
    MessageService,
} from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService } from '@klickdata/core/mobile';
import { Resource, ResourceData, AppScope } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    GlobalFilterPropertyType,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, first, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ResourcesAssignSheetComponent } from '../resource-assignment/resource-assign-sheet/resource-assign-sheet.component';
import { ResourceCollectSheetComponent } from '../resource-assignment/resource-collect-sheet/resource-collect-sheet.component';
import { ResourceListService } from './resource-list.service';
@Component({
    selector: 'app-resource-list',
    templateUrl: './resource-list.component.html',
    styleUrls: ['./resource-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceListService],
})
export class ResourceListComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() type_scope_id: number;
    @Input() author: boolean;
    @Input() master: boolean;
    @Input() customer: Customer;
    @Output() onBrowse: EventEmitter<Resource> = new EventEmitter<Resource>();
    public dataSource = new TableSource<Resource>();
    public columns: string[];
    public isMobile: boolean;
    public authUser: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;

    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    public resourceStatusOptions: SelectFilterOption[];

    constructor(
        protected resourceService: ResourceListService,
        protected mobile: MobileService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        protected bottomSheet: MatBottomSheet,
        protected messageService: MessageService
    ) {
        this.resourceStatusOptions = [
            { title: $localize`Public`, value: 'public', icon: 'public' },
            { title: $localize`None Public`, value: 'not_public', icon: 'public_off' },
            {
                title: $localize`Published`,
                value: 'published',
                icon: 'published_with_changes',
                class: 'material-icons-outlined',
            },
            {
                title: $localize`Not on Publish`,
                value: 'not_last_published',
                icon: 'unpublished',
                class: 'material-icons-outlined',
            },
            { title: $localize`Not Published yet`, value: 'not_published', icon: 'unpublished' },
            { title: $localize`Deleted`, value: 'deleted', icon: 'auto_delete' },
            { title: $localize`Expired`, value: 'expired', icon: 'event_busy' },
        ];
    }

    public ngOnInit() {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((mobile) => (this.isMobile = mobile));
        this.dataSource.service = this.resourceService;
        const columns = this.author
            ? ['title', 'created_at', 'updated_at', 'published', 'language', 'priority', 'tools']
            : this.master
            ? ['title', 'author_name', 'customer_name', 'published', 'language', 'priority', 'tools']
            : ['title', 'author_name', 'published', 'created_at', 'updated_at', 'language', 'priority', 'tools'];
        if (this.type_scope_id === AppScope.TEST || this.type_scope_id === AppScope.SURVEY) {
            columns.splice(2, 0, 'question_number');
        }
        this.columns = columns;
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => {
                this.authUser = user;
                this.updatefilters();
            });
    }

    private updatefilters() {
        this.filter.createOrUpdateWithoutEmitFilter(
            this.master
                ? this.getMatserFilter()
                : this.author
                ? [new Filter('scope', [this.type_scope_id]), new Filter('author', [this.authUser.id])]
                : this.authUser.isAdmin()
                ? [
                      new Filter('scope', [this.type_scope_id]),
                      new Filter('assignedOrCustomer', [this.customer?.id || this.authUser.customer_id]),
                  ]
                : [
                      new Filter('scope', [this.type_scope_id]),
                      new Filter('assignedOrCustomer', [this.authUser.customer_id]),
                      new Filter('published', ['1']),
                  ]
        );
    }

    public updateCustomersFilter(customersFilter: Filter<string | number>) {
        if (this.master && this.filter && this.authUser) {
            this.filter.createOrUpdateFilter(this.getMatserFilter(customersFilter), [
                customersFilter?.items.length ? 'master' : 'customer',
            ]);
        }
    }

    private getMatserFilter(customersFilter?: Filter<string | number>) {
        return !customersFilter?.items.length
            ? [new Filter('scope', [this.type_scope_id]), new Filter('master', [this.authUser.id])]
            : [new Filter('scope', [this.type_scope_id]), customersFilter];
    }

    public remove(resource: Resource): void {
        resource.loading = true;
        this.dialog
            .open(DeleteDialogComponent)
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                tap((result) => {
                    if (!result) {
                        resource.loading = false;
                        this.cdRef.markForCheck();
                    }
                }),
                filter((result) => !!result),
                switchMap(() => this.resourceService.destroy(resource))
            )
            .subscribe((success) => {
                if (success) {
                    this.messageService.openMessage(MessageDeletedComponent);
                    this.dataSource.removeById(resource.id);
                    const ref = this.messageService.openMessage(MessageDeletedUndoComponent);
                    ref.instance.snackBarRef = ref;
                    // Check if user want to undo.
                    ref.onAction()
                        .pipe(takeUntil(this.destroy))
                        .subscribe(() => {
                            this.restoreResource(resource);
                        });
                }
            });
    }

    public restoreResource(resource: Resource) {
        this.resourceService
            .restore(resource.id)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                this.messageService.openMessage(MessageSavedComponent);
                this.dataSource.refresh();
            });
    }

    public duplicate(resource: Resource): void {
        this.resourceService
            .duplicate(resource)
            .pipe(takeUntil(this.destroy))
            .subscribe((newResource) => {
                if (newResource) {
                    this.messageService.openMessage(MessageDuplicatedComponent);
                    this.onBrowse.emit(newResource);
                }
            });
    }

    public publish(resource: Resource, publish: boolean) {
        resource.loading = true;
        this.dialog
            .open(AlertDialogComponent, {
                disableClose: true,
                data: {
                    icon: publish ? 'publish_outline' : 'unpublished',
                    iconClass: 'material-icons-outlined',
                    title: publish ? $localize`:@@publish:Publish` : $localize`:@@unpublish:Unpublish`,
                    contentBody: $localize`:@@sureUpdatePublication:Are you sure you want to change the status of publication?`,
                    positiveBtn: publish ? $localize`:@@publish:Publish` : $localize`:@@unpublish:Unpublish`,
                    negativeBtn: $localize`:@@cancel:Cancel`,
                },
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                tap((result) => {
                    if (!result) {
                        resource.loading = false;
                        this.cdRef.markForCheck();
                    }
                }),
                filter((result) => !!result),
                switchMap(() => this.resourceService.update(this.getPublishData(resource, publish)))
            )
            .subscribe(
                (updatedResource) => {
                    resource.loading = false;
                    resource.published = updatedResource.published;
                    resource.last_publish = updatedResource.last_publish;
                    this.cdRef.markForCheck();
                    this.messageService.openMessage(MessageSavedComponent);
                },
                (err) => {
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                    }
                }
            );
    }

    private getPublishData(resource: Resource, publish: boolean): ResourceData {
        const publishDate = moment().format('YYYY-MM-DD HH:mm:ss');
        return {
            id: resource.id,
            published: resource.published || publishDate,
            last_publish: publish ? publishDate : null,
        };
    }

    public public(resource: Resource, makePublic: boolean) {
        resource.loading = true;
        this.dialog
            .open(AlertDialogComponent, {
                disableClose: true,
                data: {
                    icon: makePublic ? 'public' : 'public_off',
                    title: makePublic ? $localize`:@@makePublic:Make public` : $localize`:@@makeUnpublic:Make unpublic`,
                    contentBody: $localize`Are you sure you want change the public status?`,
                    positiveBtn: makePublic
                        ? $localize`:@@makePublic:Make public`
                        : $localize`:@@makeUnpublic:Make unpublic`,
                    negativeBtn: $localize`:@@cancel:Cancel`,
                },
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                tap((result) => {
                    if (!result) {
                        resource.loading = false;
                        this.cdRef.markForCheck();
                    }
                }),
                filter((result) => !!result),
                switchMap(() =>
                    this.resourceService.update({
                        id: resource.id,
                        public: makePublic,
                    })
                )
            )
            .subscribe(
                (updatedResource) => {
                    resource.loading = false;
                    resource.public = updatedResource.public;
                    this.cdRef.markForCheck();
                    this.messageService.openMessage(MessageSavedComponent);
                },
                (err) => {
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                    }
                }
            );
    }

    public showAssignments(resource: Resource): void {
        this.bottomSheet.open(ResourceCollectSheetComponent, {
            data: {
                id: resource.id,
                title: resource.title,
            },
            panelClass: 'sheet-wrapper',
        });
    }

    public sendCoursePlan(resource: Resource): void {
        this.bottomSheet.open(ResourcesAssignSheetComponent, {
            data: {
                width: !this.isMobile ? '70%' : '100%',
                id: resource.id,
                context: $localize`Assign`,
                contextIcon: 'assignment_ind',
                title: resource.title,
                referenceIdKey: 'resource_id',
                referenceFilter: 'assignedResource',
            },
            panelClass: 'sheet-wrapper',
        });
    }

    public browse(resource: Resource): void {
        this.onBrowse.emit(resource);
    }

    public updatePriority(resource: Resource) {
        resource.loading = true;
        this.resourceService
            .update(
                {
                    id: resource.id,
                    priority: resource.priority,
                },
                true
            )
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                resource.loading = false;
                this.cdRef.markForCheck();
                this.messageService.openMessage(MessageSavedComponent);
            });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
