import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import * as Pikaday from 'pikaday';

@Component({
    selector: 'app-pikaday',
    templateUrl: 'pikaday.component.html',
    styleUrls: ['pikaday.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PikadayComponent),
            multi: true,
        },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class PikadayComponent implements AfterViewInit, ControlValueAccessor {
    @Input() set minDate(date: moment.Moment) {
        this._minDate = date ? date : moment();
        this.updateRange();
    }
    protected _minDate: moment.Moment;

    @Input() set maxDate(date: moment.Moment) {
        this._maxDate = date ? date : moment().add(1, 'M');
        this.updateRange();
    }
    protected _maxDate: moment.Moment;

    get value(): moment.Moment[] {
        return this._value;
    }
    set value(value: moment.Moment[]) {
        this._value = value;
        this.propagateChange(this._value);

        this.updatePicked();

        if (this.picker) {
            this.picker.setDate(null, true);
        }

        this.changeRef.markForCheck();
    }
    protected _value: moment.Moment[] = [];

    public picker: Pikaday;
    public disabled = false;

    public showPrev = false;
    public showNext = false;

    @ViewChild('container') container: ElementRef;

    constructor(protected element: ElementRef, protected changeRef: ChangeDetectorRef) {}

    public ngAfterViewInit(): void {
        const localeData = moment.localeData('sv');

        // Patch Pikaday
        const orgConfig = Pikaday.prototype.config;
        Pikaday.prototype.config = function(config) {
            const opts = orgConfig.apply(this, [config]);
            opts.numberOfMonths = config.numberOfMonths < 12 ? config.numberOfMonths : 12;

            return opts;
        };

        this.picker = new Pikaday({
            format: 'L',
            i18n: {
                previousMonth: $localize`:@@previousMonth:Previous month`,
                nextMonth: $localize`:@@nextMonth:Next month`,
                months: localeData.months(),
                weekdays: localeData.weekdays(),
                weekdaysShort: localeData.weekdaysShort(),
            },
            firstDay: 1,
            minDate: this.getMinDate(),
            maxDate: this.getMaxDate(),
            numberOfMonths: this.getNumberOfMonths(),
            onSelect: () => {
                this.onSelected(this.picker.getMoment());
            },
            onDraw: (piker?) => {
                this.updatePicked();

                if (piker) {
                    const first = piker.el.firstElementChild;
                    const last = piker.el.lastElementChild;

                    if (first && !this.disabled) {
                        this.showPrev =
                            first.getElementsByClassName('pika-prev')[0].className.indexOf('is-disabled') === -1;
                    } else {
                        this.showPrev = false;
                    }

                    if (last && !this.disabled) {
                        this.showNext =
                            last.getElementsByClassName('pika-next')[0].className.indexOf('is-disabled') === -1;
                    } else {
                        this.showNext = false;
                    }
                }
                this.changeRef.markForCheck();
            },
        });

        this.container.nativeElement.appendChild(this.picker.el);
        this.updatePicked();
    }

    public propagateChange = (_: any) => {};

    public registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: any): void {}

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        this.updateRange();
    }

    public writeValue(date: moment.Moment[]): void {
        this.value = date;
    }

    protected getMinDate(): Date {
        if (this.disabled) {
            return moment(0).toDate();
        }

        return this._minDate ? this._minDate.toDate() : null;
    }

    protected getMaxDate(): Date {
        if (this.disabled) {
            return moment(0).toDate();
        }

        return this._maxDate ? this._maxDate.toDate() : null;
    }

    protected onSelected(newDate: moment.Moment) {
        const index = this._value.findIndex(date => {
            return date.format('YYYY-MM-DD') === newDate.format('YYYY-MM-DD');
        });

        if (index === -1) {
            this.value = [...this._value, newDate];
        } else {
            this._value.splice(index, 1);
            this.value = this._value;
        }
    }

    protected updatePicked(): void {
        if (!this.picker) {
            return;
        }

        for (const date of this._value) {
            const year = date.year();
            const month = date.month();
            const day = date.date();

            const el = this.picker.el.querySelector(
                `.pika-button.pika-day[data-pika-year='${year}'][data-pika-month='${month}'][data-pika-day='${day}']`
            );

            if (el) {
                el.parentElement.className += ' is-picked';
            }
        }
    }

    protected updateRange(): void {
        if (this.picker) {
            this.picker.destroy();
            this.ngAfterViewInit();
        }
    }

    protected getNumberOfMonths(): number {
        let numberOfMonths = 0;
        let month = this._minDate.clone();
        while (month.startOf('M').isBefore(this._maxDate)) {
            numberOfMonths++;
            month = month.add(1, 'M');
        }

        return numberOfMonths;
    }
}
