export enum MediaType {
    IMAGE = 'image',
    VIDEO = 'video',
    AUDIO = 'audio',
    PDF = 'pdf',
    DOC = 'doc',
    WEBSITE = 'website',
    URL = 'url_material',
    LINK = 'link',
    PROMPT = 'prompt',
    TEXT = 'text',
}
export enum RecorderTypes {
    AUDIO = 'audio',
    SCREEN = 'screen',
    SCREEN_WITH_VIDEO = 'screenWithVideo',
    VIDEO = 'video',
}

export interface RecordingOutput {
    blob: Blob;
    url?: string;
    title?: string;
    file?: File;
    limitedRecord?: boolean;
}
export namespace RecorderTypes {
    export function getRecordLabel(type: RecorderTypes, capitalize?: boolean) {
        switch (type) {
            case RecorderTypes.AUDIO:
                return capitalize ? $localize`Audio` : $localize`audio`;
            case RecorderTypes.SCREEN:
                return capitalize ? $localize`Screen` : $localize`screen`;
            case RecorderTypes.VIDEO:
                return capitalize ? $localize`Video` : $localize`video`;
        }
    }
}
