import {animate, style, transition, trigger} from '@angular/animations';

export const FadeInAnimation = trigger('fadeIn', [
    transition(':enter', [
        style({opacity: 0}),
        animate('375ms cubic-bezier(0.4,0.0,0.2,1)', style({opacity: 1})),
    ])
]);

export const FadeInOutAnimation = trigger('fadeInOut', [
    transition(':enter', [
        style({opacity: 0}),
        animate('375ms cubic-bezier(0.4,0.0,0.2,1)', style({opacity: 1})),
    ]),
    transition(':leave', [
        animate('195ms cubic-bezier(0.4,0.0,0.2,1)', style({opacity: 0})),
    ])
]);
