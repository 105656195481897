<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>format_quote</mat-icon>
        <div>What learners say?</div>
        <button
            mat-icon-button
            type="button"
            color="primary"
            fxFlex="0 0 auto"
            matTooltip="Open All"
            class="expand-arrow"
            i18n-matTooltip="@@openAll"
            (click)="expandAll = !expandAll"
        >
            <mat-icon class="expanding" [class.expanded-all]="expandAll">first_page</mat-icon>
        </button>
    </div>

    <mat-accordion multi="true" fxFlex="0 1 100%" displayMode="flat">
        <mat-expansion-panel
            *ngFor="let quote of navData.data; let index = index"
            [expanded]="expandAll"
            #expansionPanel
            class="quote-expantion"
        >
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <app-image-view
                        class="quote-img"
                        [ngStyle]="{ width: '50px' }"
                        [isCircularImg]="true"
                        [media_id]="quote.quotee?.media"
                        paddingBottom="50px"
                    ></app-image-view>
                    <span class="quote-title">{{ quote.quotee?.name }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxLayoutAlign="center start" fxLayout="column">
                <div class="body">
                    {{ quote.quote }}
                </div>
                <p class="date">{{ quote.created_at | kdDateFormat : 'MMM D, YYYY' }}</p>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="onClose.emit({ action: SideNaveActionsTypes.POSITIVE })"
            mat-raised-button
        >
            OK
        </button>
    </div>
</div>
