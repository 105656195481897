import { Component, OnInit } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { MobileService } from '@klickdata/core/mobile/src/mobile.service';
import { User } from '@klickdata/core/user';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
    public user$: Observable<User>;
    constructor(public mobile: MobileService, protected auth: AuthService) {
        this.user$ = this.auth.getUser();
    }

    ngOnInit() {}

    public onClose(): void {
        this.mobile.toggleMenu();
    }
}
