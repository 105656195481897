import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-posts-assignment',
    templateUrl: './posts-assignment.component.html',
    styleUrls: ['./posts-assignment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PostsAssignmentComponent implements OnInit, OnDestroy {
    public assignForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public saving: boolean;

    constructor(
        public bottomSheetRef: MatBottomSheetRef<PostsAssignmentComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder
    ) {
        this.assignForm = this.fb.group({
            id: [],
            sync_all_users: [],
            users_attach: [[]],
            users_detach: [[]],
            sync_all_groups: [],
            groups_attach: [[]],
            groups_detach: [[]],
            users_access_open: [false],
        });
    }

    ngOnInit() {
        this.assignForm.patchValue({
            id: this.data.post.id,
            users_access_open: this.data.users_access_open,
            sync_all_users: this.data.post.sync_all_users,
            users_attach: this.data.post.users_attach,
            users_detach: this.data.post.users_detach,
            sync_all_groups: this.data.post.sync_all_groups,
            groups_attach: this.data.post.groups_attach,
            groups_detach: this.data.post.groups_detach,
        });
    }

    public submit() {
        if (
            this.assignForm.value.users_access_open ||
            !this.assignForm.value.users_access_open ||
            this.assignForm.value.sync_all_users !== null ||
            this.assignForm.value.users_attach?.length ||
            this.assignForm.value.users_detach?.length ||
            this.assignForm.value.sync_all_groups !== null ||
            this.assignForm.value.groups_attach?.length ||
            this.assignForm.value.groups_detach?.length
        ) {
            this.bottomSheetRef.dismiss(this.assignForm.value);
        } else {
            this.bottomSheetRef.dismiss();
        }
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
