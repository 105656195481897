import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivityLog, ActivityLogService } from '@klickdata/core/activity-log';
import { MobileService } from '@klickdata/core/mobile';
import { Resource, ResourceTypes } from '@klickdata/core/resource';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-resource-history-listing',
    templateUrl: './resource-history-listing.component.html',
    styleUrls: ['./resource-history-listing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceHistoryListingComponent implements OnInit {
    @Input() resource: Resource;
    public resLogs$: Observable<ActivityLog[]>;
    public isMobile$: Observable<boolean>;

    constructor(protected activityLogService: ActivityLogService, protected mobileService: MobileService) {}

    ngOnInit(): void {
        this.isMobile$ = this.mobileService.isMobile();
        this.resLogs$ = this.activityLogService.getUserRelatedResourceActivities(
            ResourceTypes.parentType(this.resource.type_id) === ResourceTypes.MATERIAL
                ? { resource: this.resource.id, eager: ['user_prompt'], sort: 'activity_id' }
                : { resource: this.resource.id }
        );
    }
}
