import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ResourceData, ResourceTypes, AppScope } from '@klickdata/core/resource';
import { ResourceSelectComponent } from './resource-select/resource-select.component';

@Component({
    selector: 'app-resources-assign',
    templateUrl: './resources-assign.component.html',
    styleUrls: ['./resources-assign.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesAssignComponent implements OnInit {
    @ViewChildren(ResourceSelectComponent) selectComponents: QueryList<ResourceSelectComponent>;
    @Input() referenceProperty: string;
    @Input() sync_all_key = 'sync_all_resources';
    @Input() attachKey = 'resources_attach';
    @Input() detachKey = 'resources_detach';
    @Input() context: string;
    @Input() contextIcon: string;
    @Input() data: any;
    @Input() hideSticky: boolean;
    @Input() stickytabs: boolean;
    @Output() onSave: EventEmitter<ResourceData> = new EventEmitter<ResourceData>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();
    ResourceTypes = ResourceTypes;
    AppScope = AppScope;
    public resourceForm: FormGroup;
    openedTabIndices = [0];
    public isSaving: boolean;
    private currentTab = 0;
    constructor(protected fb: FormBuilder) {}

    ngOnInit(): void {
        this.resourceForm = this.fb.group({
            id: [],
            [this.sync_all_key]: [],
            [this.attachKey]: [[]],
            [this.detachKey]: [[]],
        });
        this.updateForm();
    }

    tabChanged(event: MatTabChangeEvent) {
        this.currentTab = event.index;
        if (!this.openedTabIndices.includes(event.index)) {
            this.openedTabIndices.push(event.index);
        }
    }

    public updateForm(): void {
        this.resourceForm.reset(this.data);
    }

    public reset(): void {
        this.selectComponents?.get(this.currentTab).reset();
    }

    public get formData() {
        return this.resourceForm.value;
    }

    public canDeactivate(): boolean {
        return this.selectComponents ? this.selectComponents.toArray().every((item) => item.canDeactivate()) : true;
    }

    public dirty(): boolean {
        return !this.canDeactivate();
    }
}
