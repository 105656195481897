<div class="dialog-wrapper">
    <div
        matDialogTitle
        class="red"
        fxLayout="row"
        fxLayoutAlign="start flex-end"
        fxLayoutAlign.lt-md="start flex-start"
    >
        <mat-icon fxFlex="0 0 36px" class="md-36">
            date_range
        </mat-icon>
        <div class="title-wrapper" fxFlex="0 1 auto">
            <span class="title" i18n>Select date range</span>
        </div>
    </div>

    <div matDialogContent>
        <form [formGroup]="rangeForm">
            <mat-form-field appearance="fill">
                <mat-label>{{ data.label }}</mat-label>
                <mat-date-range-input [min]="min | async" [max]="max" [rangePicker]="picker">
                    <input matStartDate formControlName="begin" placeholder="Start date" />
                    <input matEndDate formControlName="end" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error i18n>A valid date range is required!</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div class="flex" fxLayoutAlign="end center" mat-dialog-actions>
        <button i18n class="neutral" mat-button type="button" (click)="dialogRef.close(null)">
            Cancel
        </button>
    </div>
</div>
