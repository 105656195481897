<div class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>speaker_notes</mat-icon><span i18n>Message to</span><span>{{ navData.data.model.name }}</span>
    </div>

    <form fxLayoutGap="20px" fxLayout="column" [formGroup]="messageForm">
        <mat-form-field appearance="fill" class="templates-select" fxFlex="0 0 100%">
            <input
                matInput
                placeholder="Type in the subject"
                i18n-placeholder
                formControlName="subject"
            />
        </mat-form-field>
        <mat-form-field class="mb-0" floatLabel="never" appearance="fill" fxFlex="0 0 100%">
            <textarea
                maxlength="5000"
                rows="5"
                i18n-placeholder
                placeholder="Type in your message"
                #message
                matInput
                formControlName="body"
            ></textarea>
        </mat-form-field>
        <div class="alert-media-attach">
            <app-media-attachment
                #uploader
                [maxImgCount]="10"
                formControlName="media_ids"
                [scope]="AppScope.ALERT"
                [mediaIds]="messageForm.get('media_ids').value"
                [isCreateForm]="true"
            ></app-media-attachment>
        </div>
        <div class="action">
            <button
                *ngIf="!(saving | async)"
                [disabled]="!messageForm.value.subject"
                fxFlex="0 0 auto"
                class="mobile-button"
                (click)="submit()"
                mat-raised-button
            >
                <span i18n>Send Message</span>
            </button>
            <ng-container *ngIf="saving | async">
                <app-saving-button></app-saving-button>
            </ng-container>
        </div>
    </form>
</div>
