import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-html-view',
    templateUrl: './html-view.component.html',
    styleUrls: ['./html-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlViewComponent {
    @Input()
    public set html(html: string) {
        if (html) {
            const doc = new DOMParser().parseFromString(html, 'text/html');
            Object.values(doc.querySelectorAll('a')).map((link) => {
                link.setAttribute('target', '_blank');
            });
            this._html = doc.documentElement.innerHTML;
            this.load.emit(true);
        }
    }

    public get html(): string {
        return this._html;
    }

    private _html: string;

    @Output()
    public load: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor() {}
}
