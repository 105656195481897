import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Optional,
    Output,
    QueryList,
    ViewChildren,
} from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatTooltip } from '@angular/material/tooltip';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer/src/customer.model';
import { DirectionalityService } from '@klickdata/core/localization';
import { MobileService } from '@klickdata/core/mobile';
import { QuestionTypes } from '@klickdata/core/question';
import { Resource, ResourceType, ResourceTypes } from '@klickdata/core/resource';
import { ResourceItemTypes } from '@klickdata/core/resource-item';
import { ResourceBuilderService } from '@klickdata/shared-components';
import { Observable } from 'rxjs';

export interface ResourceBuilderToolsViewData {
    resourceTypes: ResourceType[];
    questionTypes: QuestionTypes[];
    resource: Resource;
    // reorder: boolean;
    saving: boolean;
    saved: boolean;
    saveTitle: string;
}

@Component({
    selector: 'app-resource-builder-tools-view',
    templateUrl: './resource-builder-tools-view.component.html',
    styleUrls: ['./resource-builder-tools-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceBuilderToolsViewComponent implements OnInit {
    /**
     * Available types
     */
    public QuestionTypes = QuestionTypes;
    public ResourceItemTypes = ResourceItemTypes;
    public customer: Observable<Customer>;
    public foldAll = true;
    public isMobile$: Observable<boolean>;

    @Input() resourceTypes: ResourceType[] = [];
    @Input() questionTypes: QuestionTypes[] = [];
    @Input() resource: Resource;
    // @Input() reorder: boolean;
    @Input() saving: boolean;
    @Input() saved: boolean;
    @Input() saveTitle: string;

    @Input() hideAddItems: boolean;

    @Output() itemsCollapsingState: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() addItem: EventEmitter<ResourceItemTypes> = new EventEmitter<ResourceItemTypes>();
    @Output() addQuestion: EventEmitter<any> = new EventEmitter<any>();
    // @Output() toggleReorder: EventEmitter<null> = new EventEmitter<null>();
    @Output() submit: EventEmitter<null> = new EventEmitter<null>();

    // Tooltips
    @ViewChildren(MatTooltip) tooltips: QueryList<MatTooltip>;
    ResourceTypes = ResourceTypes;

    constructor(
        public changeRef: ChangeDetectorRef,
        @Optional()
        @Inject(MAT_BOTTOM_SHEET_DATA)
        data: ResourceBuilderToolsViewData,
        protected auth: AuthService,
        public directionality: DirectionalityService,
        protected mobileService: MobileService
    ) {
        if (data) {
            this.update(data);
        }
    }
    ngOnInit() {
        this.customer = this.auth.getCustomer();
        this.isMobile$ = this.mobileService.isMobile();
    }

    public update(data: ResourceBuilderToolsViewData) {
        this.resourceTypes = data.resourceTypes;
        this.questionTypes = data.questionTypes;
        this.resource = data.resource;
        // this.reorder = data.reorder;
        this.saving = data.saving;
        this.saved = data.saved;
        this.saveTitle = data.saveTitle;
        this.changeRef.markForCheck();
    }

    containsType(itemType: ResourceTypes): boolean {
        return !!this.resourceTypes?.find((type) => type.id === itemType);
    }
}
