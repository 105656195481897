import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Media } from '@klickdata/core/media/src/media.model';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope } from '@klickdata/core/resource';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import { MatTableBase } from 'apps/klickdata/src/app/shared/table/mat-table-base';
import { filter, map, switchMap, takeUntil } from 'rxjs';
import { K3MediaListingService } from '../k3-media-listing.service';

@Component({
    selector: 'app-media-listing-general',
    templateUrl: './media-listing-general.component.html',
    styleUrls: ['./media-listing-general.component.scss'],
    providers: [K3MediaListingService],
})
export class MediaListingGeneralComponent extends MatTableBase<Media> {
    @Output() onMediaClick: EventEmitter<Media> = new EventEmitter<Media>();
    @Input() public columns = ['id', 'filename', 'description', 'tags', 'author', 'created_at', 'tools'];
    AppScope = AppScope;

    constructor(
        protected listService: K3MediaListingService,
        protected mobileService: MobileService,
        protected cdr: ChangeDetectorRef,
        protected dialog: MatDialog
    ) {
        super(cdr, mobileService, listService);
    }

    public addMedia() {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.ADD_FILE,
            data: {
                type: 'addMedia',
            },
        });

        this.mobileService
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobileService.getSideNavResponseData()),
                filter((data) => data.type === 'addMedia'),
                takeUntil(this.destroy),
                map((data) => data.value)
            )
            .subscribe((media) => {
                this.dataSource.replace(new Media(media));
                this.cdr.markForCheck();
            });
    }
    public download(id: number) {}
    public delete(id: number) {}
    public edit(id: number) {}
    public view(media: Media) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '60%',
            data: media.id,
        });
    }
}
