<div class="res-listing-wrapper res-listing-mobile" fxLayout="column" fxFlex="0 0 100%">
    <mat-accordion displayMode="flat">
        <mat-expansion-panel [expanded]="context === 'admin'" #expansionPanel>
            <mat-expansion-panel-header> </mat-expansion-panel-header>
            <ng-container [ngTemplateOutlet]="filtersParent"></ng-container>
        </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="showActionHeader" class="header-replace">
        <ng-container [ngTemplateOutlet]="tableMultipleRowsTools"></ng-container>
    </div>
    <ng-content></ng-content>
    <mat-progress-bar
        class="progress-table-loader"
        *ngIf="(dataSource.status | async) === 'loading'"
        mode="indeterminate"
    ></mat-progress-bar>

    <mat-table
        #table
        [dataSource]="dataSource"
        matSortDirection="desc"
        matSortActive="last_action"
        matSort
        [class.showHeader]="showActionHeader"
        multiTemplateDataRows
    >
        <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? masterToggle() : null; showActionHeader = $event.checked"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()"
                    [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(resource) : null; showActionHeader = $event.checked"
                    [checked]="selection.isSelected(resource)"
                    [aria-label]="checkboxLabel(resource)"
                >
                </mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="img">
            <mat-header-cell *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@image">Image</span>
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <app-image-view
                    [media_id]="resource.media$"
                    [proportional]="!isMobile"
                    [paddingBottom]="'58px'"
                    [smallSpinner]="true"
                ></app-image-view>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@title"> Title </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <span *ngIf="!isMobile" class="original">{{ resource.title }}</span>
                <div *ngIf="!isMobile" class="hover">
                    <ng-container
                        [ngTemplateOutlet]="tableSingleRowTools"
                        [ngTemplateOutletContext]="{ resource: resource }"
                    ></ng-container>
                </div>
                <div class="mobile-row-title" *ngIf="isMobile">
                    <div class="mobile-img-wrapper">
                        <app-image-view
                            [smallSpinner]="true"
                            [media_id]="resource.media$"
                            [proportional]="true"
                        ></app-image-view>
                    </div>
                    <div class="info-main-wrapper">
                        <span class="item-title">{{ resource.title }}</span>
                        <div class="nu-items">
                            <span class="nu">
                                {{ resource.number_of_items }}
                            </span>
                            <span class="last-action-mob">{{
                                resource.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm'
                            }}</span>
                        </div>
                    </div>
                    <button
                        class="expand-btn"
                        mat-icon-button
                        (click)="expandedMobRow = expandedMobRow === resource ? null : resource; expandedRow = null"
                    >
                        <mat-icon>
                            {{ expandedMobRow === resource ? 'expand_less' : 'expand_more' }}
                        </mat-icon>
                    </button>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="occasionStatus">
            <mat-header-cell mat-sort-header *matHeaderCellDef>
                <div fxLayoutAlign="start center">
                    <span i18n="@@status">Status</span>
                </div>
            </mat-header-cell>
            <mat-cell
                *matCellDef="let resource"
                class="pr-0-m"
                [ngStyle]="{ color: (resource | resourceStatus : true)?.color }"
            >
                <span *ngIf="isMobile" class="mobile-label" i18n="@@status">Status</span>
                <mat-icon>
                    {{ (resource | resourceStatus : true)?.icon }}
                </mat-icon>
                &nbsp;
                {{ (resource | resourceStatus : true)?.status }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="examination">
            <mat-header-cell *matHeaderCellDef i18n="@@certificate"> Certificate </mat-header-cell>
            <mat-cell
                *matCellDef="let resource"
                class="pr-0-m"
                [ngStyle]="{ color: (resource | resourceCertificate).color }"
                (click)="download(resource)"
            >
                <span *ngIf="isMobile" class="mobile-label" i18n="@@certificate">Certificate</span>
                <mat-icon [ngClass]="{ 'disabled-icon': !resource.downloads }" class="cert-icon">
                    {{ (resource | resourceCertificate).icon }}
                </mat-icon>
                &nbsp;
                {{ (resource | resourceCertificate).certificate }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef i18n="@@certificate"> Type </mat-header-cell>
            <mat-cell *matCellDef="let resource" class="pr-0-m">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@type">Type</span>
                <div class="type-wrap">
                    <mat-icon
                        fontSet="klickdata"
                        [fontIcon]="resource.type_id | typeFontIcon"
                        class="kd-icon"
                    ></mat-icon>
                    {{ ResourceTypes.label(resource.type_id, { capitalize: true }) }}
                </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="last_action">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n>Last action </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n>Last action</span>
                <span>{{ resource.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm' }} </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="done_items">
            <mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@activityProgress"> Progress </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <span *ngIf="isMobile" class="mobile-label" i18n="@@activityProgress">Progress</span>
                <span *ngIf="ResourceTypes.parentType(resource.type_id) != ResourceTypes.MATERIAL">
                    <div
                        [ngClass]="{
                            'grey-done': !resource.done_items,
                            'darkblue-done': resource.done_items == resource.number_of_items
                        }"
                        class="progress-wrapper"
                    >
                        <span>{{ resource.done_items ? resource.done_items : '0' }}</span
                        >/
                        <span>{{ resource.number_of_items }}</span>
                    </div>
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="tools">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let resource">
                <!-- <span *ngIf="isMobile" class="mobile-label" i18n="@@tools">Tools</span> -->
                <div *ngIf="!isMobile" class="tools-cell-wrapper">
                    <button
                        *ngIf="resource.type_id == ResourceTypes.GeneralCoursePlan && resource.occasionStatus == 'done'"
                        (click)="$event.stopPropagation(); signoffRes(resource)"
                        [matTooltip]="getSignOffToolTip(resource)"
                        mat-icon-button
                        i18n-matTooltip
                    >
                        <mat-icon [ngStyle]="{ color: getSignOffColor(resource) }">assignment_turned_in</mat-icon>
                    </button>
                    <button
                        *ngIf="
                            ResourceTypes.parentType(resource.type_id) === ResourceTypes.TEST ||
                            ResourceTypes.parentType(resource.type_id) === ResourceTypes.COURSE ||
                            ResourceTypes.parentType(resource.type_id) === ResourceTypes.MATERIAL
                        "
                        (click)="$event.stopPropagation(); expandedRow = expandedRow === resource ? null : resource"
                        mat-icon-button
                    >
                        <mat-icon>{{ expandedRow === resource ? 'expand_less' : 'expand_more' }}</mat-icon>
                    </button>
                </div>
                <div *ngIf="isMobile" class="tools-cell-wrapper mob-tools">
                    <ng-container
                        [ngTemplateOutlet]="tableSingleRowTools"
                        [ngTemplateOutletContext]="{ resource: resource }"
                    ></ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expandedDetail">
            <mat-cell *matCellDef="let resource" [attr.colspan]="columns.length">
                <div
                    class="example-element-detail"
                    [@detailExpand]="resource == expandedRow ? 'expanded' : 'collapsed'"
                >
                    <!-- <div class="expand-info-wrap">
                        <div fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start center">
                            <span>Last action:</span>
                            <span>{{ resource.last_action | kdDateFormat : 'YYYY-MM-DD HH:mm' }}</span>
                        </div>
                    </div> -->
                    <ng-container
                        *ngIf="
                            ResourceTypes.parentType(resource.type_id) === ResourceTypes.TEST &&
                            expandedRow === resource
                        "
                    >
                        <app-general-test-results
                            class="res-test-results"
                            [user]="authUser"
                            [isCourseItem]="false"
                            [resource]="resource"
                            [occasionId]="resource.opportunity_id"
                        ></app-general-test-results>
                    </ng-container>
                    <ng-container
                        *ngIf="
                            ResourceTypes.parentType(resource.type_id) === ResourceTypes.COURSE &&
                            expandedRow === resource
                        "
                    >
                        <app-course-player-results
                            [tabsIndex]="0"
                            [user]="authUser"
                            [occasionId]="resource.opportunity_id"
                            [resource]="resource"
                            (retakeCourse)="retakeCourse($event)"
                        ></app-course-player-results>
                    </ng-container>
                    <ng-container
                        *ngIf="
                            ResourceTypes.parentType(resource.type_id) === ResourceTypes.MATERIAL &&
                            expandedRow === resource
                        "
                    >
                        <app-material-items-display-info
                            class="user-occ-mat-info"
                            [user]="authUser"
                            [occasionId]="resource.opportunity_id"
                            [resource]="resource"
                        ></app-material-items-display-info>
                    </ng-container>
                </div>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row
            [ngClass]="!resource.loading ? 'link' : 'disableIt'"
            (click)="$event.stopPropagation()"
            *matRowDef="let resource; columns: columns"
            [class.res-expanded-row]="expandedRow === resource"
            [class.res-expanded-mob-row]="expandedMobRow === resource"
        ></mat-row>
        <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></mat-row>
    </mat-table>
</div>
<mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>

<ng-template #loadingTemp>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #rowHover>
    <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="center" class="spinner-container">
        <app-kd-logo-loading size="small"></app-kd-logo-loading>
    </div>
</ng-template>
<ng-template #shareLoadTemp>
    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
<ng-template #filtersParent>
    <app-table-filter
        [filtersTogglesInOnBtn]="true"
        [cacheScope]="cacheScope"
        [inGlobalFilter]="true"
        color="accent"
        #filter
    >
        <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
        <app-tag-chip-search-filter
            [inGlobalFilter]="true"
            [languageChange]="langFilter.filterChange"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            fxLayoutAlign="start"
            tagsFilterRef
        ></app-tag-chip-search-filter>
        <app-category-chip-search-filter
            [inGlobalFilter]="true"
            fxFlex="50%"
            fxFlex.lt-md="100%"
            [scope_id]="type_scope_ids"
            categoriesFilterRef
        ></app-category-chip-search-filter>

        <app-select-filter
            [inGlobalFilter]="true"
            class="filter-space"
            label="Status"
            i18n-label
            [property]="GlobalFilterProperty.RESOURCS_OCCASION"
            [options]="resourceStatusOptions"
            resStatusFilterRef
        ></app-select-filter>

        <app-table-date-filter [inGlobalFilter]="true" color="accent" dateFilterRef></app-table-date-filter>

        <app-language-filter
            [inGlobalFilter]="true"
            #langFilter
            fxFlex="0 0 auto"
            fxLayout="row"
            langFilterRef
        ></app-language-filter>
    </app-table-filter>
</ng-template>
<ng-template #tableMultipleRowsTools>
    <div class="hover-wrapper top" fxLayoutAlign="space-around center">
        <!-- <button
            *ngIf="false"
            class="hover-tools-btn"
            mat-raised-button
            matTooltip="Download PDF"
            i18n-matTooltip="@@downloadPDF"
            (click)="download()"
        >
            <mat-icon>save_alt</mat-icon>
            <span class="btn-label" i18n="@@download">Download</span>
        </button> -->
        <button
            class="hover-tools-btn disableIt"
            mat-raised-button
            matTooltip="Export CSV"
            i18n-matTooltip="@@exportCSV"
        >
            <mat-icon>get_app</mat-icon>
            <span class="btn-label" i18n="@@exportcsv">Export CSV</span>
        </button>
    </div>
</ng-template>
<ng-template #tableSingleRowTools let-resource="resource">
    <div class="hover-wrapper" fxLayoutAlign="space-between center">
        <button
            class="hover-tools-btn view"
            mat-raised-button
            matTooltip="View"
            (click)="onBrowse.emit(resource)"
            i18n-matTooltip="@@view"
        >
            <mat-icon>visibility</mat-icon>
        </button>
        <button
            *ngIf="context !== 'admin' && resource.occasionStatus !== 'done'"
            class="hover-tools-btn view"
            mat-raised-button
            [matTooltip]="getPlayBtnTxt(resource)"
            (click)="!resource.loading && onPlay.emit(resource)"
        >
            <mat-icon>play_circle</mat-icon>
        </button>
        <ng-container *ngIf="resource.last_publish">
            <button
                class="hover-tools-btn"
                *ngIf="!(shareLoading | async); else shareLoadTemp"
                mat-icon-button
                matTooltip="Share"
                i18n-matTooltip="@@share"
                (click)="shareService.openShareDialog(resource, shareLoading)"
            >
                <mat-icon>share</mat-icon>
            </button>
        </ng-container>
        <button
            *ngIf="resource.downloads"
            class="hover-tools-btn"
            mat-raised-button
            matTooltip="Download PDF"
            i18n-matTooltip="@@downloadPDF"
            (click)="download(resource)"
        >
            <mat-icon>save_alt</mat-icon>
        </button>
        <button
            *ngIf="context === 'admin' && resource.occasionStatus === 'cancelled'"
            class="hover-tools-btn view admin-green"
            mat-raised-button
            matTooltip="Email User"
            (click)="mailUser(resource.user)"
        >
            <mat-icon>email</mat-icon>
        </button>
        <button
            *ngIf="authUser?.isAdmin()"
            class="hover-tools-btn assign-res view admin-green"
            mat-raised-button
            matTooltip="Assign"
            i18n-matTooltip="@@assign"
            (click)="!resource.loading && authUser?.isAdmin() && onAssign.emit(resource)"
        >
            <mat-icon *ngIf="!resource.assign_id">assignment_ind</mat-icon>
            <img *ngIf="resource.assign_id" src="assets/images/edit-assign.svg" width="24" alt="Edit Assign" />
        </button>

        <button
            *ngIf="!resource.opportunity_deleted_at && resource.occasionStatus === 'cancelled' && context !== 'admin'"
            class="hover-tools-btn view admin-green"
            mat-raised-button
            matTooltip="Delete from the list"
            i18n-matTooltip
            (click)="!resource.loading && deleteCourseFromList(resource)"
        >
            <mat-icon>delete</mat-icon>
        </button>
        <button
            *ngIf="resource.type_id == ResourceTypes.GeneralCoursePlan && resource.occasionStatus == 'done' && isMobile"
            (click)="$event.stopPropagation(); signoffRes(resource)"
            [matTooltip]="getSignOffToolTip(resource)"
            mat-icon-button
            i18n-matTooltip
        >
            <mat-icon [ngStyle]="{ color: getSignOffColor(resource) }">assignment_turned_in</mat-icon>
        </button>
        <button
            *ngIf="
                (ResourceTypes.parentType(resource.type_id) === ResourceTypes.TEST ||
                    ResourceTypes.parentType(resource.type_id) === ResourceTypes.COURSE ||
                    ResourceTypes.parentType(resource.type_id) === ResourceTypes.MATERIAL) &&
                isMobile
            "
            (click)="$event.stopPropagation(); expandedRow = expandedRow === resource ? null : resource"
            mat-icon-button
        >
            <mat-icon>{{ expandedRow === resource ? 'expand_less' : 'expand_more' }}</mat-icon>
        </button>
    </div>
</ng-template>
