import { Utils } from './../../util/src/utils';
import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config';
import { HttpErrorService, RequestBuilderService } from '@klickdata/core/http';
import { User } from '@klickdata/core/user';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { WidgetData, Widget } from './widget.model';
import { Filter } from '@klickdata/core/table';

@Injectable({
    providedIn: 'root',
})
export class WidgetsService {
    protected widgetsUrl: string;
    protected authUser: User;
    private activiteWidgetsFilters = new BehaviorSubject<Filter<string | number>[]>([]);

    constructor(
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected error: HttpErrorService
    ) {
        this.widgetsUrl = `${config.config.apiUrl}widgets/stats`;
    }

    public setActiveWidgetsFilters(filters: Filter<string | number>[]) {
        this.activiteWidgetsFilters.next(filters);
    }

    public getActiveWidgetsFilters(): Observable<Filter<string | number>[]> {
        return this.activiteWidgetsFilters.asObservable();
    }
    public getAllWidgets(filters?: Filter<any>[]): Observable<Widget[]> {
        const request = this.builder.get<WidgetData[]>(`${this.widgetsUrl}`).filters(filters);
        // if (customerId) {
        //     request.param('customer', customerId);
        // }
        return request.request().pipe(map((res) => this.mapWidgets(res.data)));
    }
    public getWidgetById(widgetId: number, begin?: string, end?: string): Observable<Widget> {
        const request = this.builder.get<WidgetData>(`${this.widgetsUrl}`);
        request.param('widget', widgetId);
        if (begin) {
            request.param('begin', begin);
        }
        if (end) {
            request.param('end', end);
        }
        return request.request().pipe(map((res) => this.mapWidget(res.data[0])));
    }
    private mapWidgets(widgetsData: WidgetData[]): Widget[] {
        // const colors = Utils.getKDColors();
        // for (let i = 0; i < widgetsData.length; i++) {
        //     widgetsData[i].color = colors[i % colors.length];
        // }
        return widgetsData.map((widget) => this.mapWidget(widget));
    }
    protected mapWidget(widgetData: WidgetData): Widget {
        const widget = new Widget(widgetData);
        if (widget.event !== 'durationtime' && widget.details && widget.details.length) {
            widget.details.forEach((detItem) => {
                detItem.percentage = 100 / widget.total;
            });
        }
        return widget;
    }
}
