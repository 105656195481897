import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { PaginatorResponse } from '@klickdata/core/http';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { Filter } from '@klickdata/core/table';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, finalize, first, map, share, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { Downloads } from '../download.model';
import { ResourceService } from '../resource.service';
import { AppScope } from '../type.scope.enum';
import { ResourceTypeService } from '../type/resource-type.service';
import { ResourceCategory, ResourceCategoryData } from './resource-category.model';

@Injectable({ providedIn: 'root' })
export class ResourceCategoryService {
    protected resourceUrl: string;
    protected customer_id: Observable<number>;
    protected parent_id: number;
    protected defaultCategoryMap: Map<string, Observable<ResourceCategory>> = new Map<
        string,
        Observable<ResourceCategory>
    >();
    public onDownloadCatalog: BehaviorSubject<{
        downloads: Downloads;
        disabled: boolean;
        totalResCount: number;
    }> = new BehaviorSubject<{
        downloads: Downloads;
        disabled: boolean;
        totalResCount: number;
    }>({ downloads: null, disabled: true, totalResCount: 0 });

    constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected resourceService: ResourceService,
        protected resourceTypeService: ResourceTypeService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}categories`;

        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }

    public getCustomerCategories(resourceType?: string): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);

        builder.param('customer', this.customer_id);

        if (resourceType) {
            builder.param('containsResourceType', resourceType);
        }

        return builder.request().pipe(map((res) => this.mapCategories(res.data)));
    }

    public getCatalogCategoriesByScope(scope_id: number, query?: string, filters?: Filter<any>[]) {
        return this.builder
            .get<ResourceCategoryData[]>(this.resourceUrl)
            .param('childrenByScope', scope_id)
            .param('source', 'publicOrCustomer')
            .filters(filters) // filters must follow source param for user filters to override default.
            .query(query)
            .request()
            .pipe(
                map((res: PaginatorResponse<ResourceCategoryData[]>) => {
                    if (res.more) {
                        this.onDownloadCatalog.next({
                            downloads: res.more.downloads,
                            disabled: false,
                            totalResCount: 0,
                        });
                    }
                    const lazySubject = new BehaviorSubject<{ parent: number; child: number }>({ parent: 0, child: 0 });
                    return res.data.map((item, parentIndex) => {
                        const category = new ResourceCategory(item);
                        category.loadedChildren.map((child, childIndex, children) => {
                            child.resources$ = lazySubject.pipe(
                                filter((lazy) => lazy.parent === parentIndex && lazy.child === childIndex),
                                switchMap(() =>
                                    this.resourceService
                                        .getResources(
                                            child.resource_ids,
                                            {
                                                source: 'publicOrCustomer',
                                                scope: scope_id,
                                                eager: 'tags,author',
                                            },
                                            null,
                                            query,
                                            filters
                                        )
                                        .pipe(
                                            tap((resources) =>
                                                this.onDownloadCatalog.next({
                                                    ...this.onDownloadCatalog.value,
                                                    totalResCount:
                                                        this.onDownloadCatalog.value.totalResCount + resources.length,
                                                })
                                            ),
                                            finalize(() => {
                                                if (childIndex !== children.length - 1) {
                                                    lazySubject.next({
                                                        parent: parentIndex,
                                                        child: childIndex + 1,
                                                    });
                                                } else {
                                                    lazySubject.next({
                                                        parent: parentIndex + 1,
                                                        child: 0,
                                                    });
                                                }
                                            })
                                        )
                                )
                            );
                        });
                        return category;
                    });
                })
            );
    }

    public getPublicOrCustomerCategories(params: {
        type_id: number;
        scope_id?: number | number[];
        query?: string;
        limit?: number;
        parent?: 'null' | 'notNull' | number;
        sortDir?: 'asc' | 'desc';
    }): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);

        builder.param('publicOrCustomer', this.customer_id);

        if (params.type_id) {
            builder.param('type', params.type_id);
        }

        if (params.scope_id) {
            builder.param('scope', params.scope_id);
        }

        builder.param('sort', 'title');
        if (params.sortDir) {
            builder.param('dir', params.sortDir);
        }

        if (params.query) {
            builder.param('query', params.query);
        }

        if (params.parent) {
            builder.param('parent', params.parent);
        }

        if (params.limit) {
            builder.limit(params.limit);
        }

        return builder.request().pipe(map((res) => this.mapCategories(res.data)));
    }

    public getSuggestedCategory(type_id: number): Observable<ResourceCategory> {
        const cache_id = `${type_id}`;
        const cached = this.defaultCategoryMap.get(cache_id);
        if (cached) {
            return cached;
        }
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);
        builder.param('default', 'true');
        builder.param('parent', 'notNull');
        builder.param('type', type_id);
        builder.param('publicOrCustomer', this.customer_id);
        builder.limit(1);
        const fetchedDefault = builder.request().pipe(
            map((res) => (res.data.length ? new ResourceCategory(res.data[0]) : null)),
            shareReplay()
        );

        // add to cache.
        this.defaultCategoryMap.set(cache_id, fetchedDefault);
        return fetchedDefault;
    }

    public getParentDefaultCategory(type_id: number, customer_id: number): Observable<ResourceCategory> {
        const cache_id = `Default`;
        const cached = this.defaultCategoryMap.get(cache_id);
        if (cached) {
            return cached;
        }
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);
        builder.param('default', 'true');
        builder.param('parent', 'null');
        builder.param('type', type_id);
        builder.param('customer', this.customer_id);
        builder.limit(1);

        const fetchedDefault = this.resourceTypeService.getResourceTypes(AppScope.RESOURCES).pipe(
            map((types) => types.map((type) => type.id)),
            switchMap((type_ids) =>
                builder.request().pipe(
                    switchMap((res) =>
                        res.data.length
                            ? of(new ResourceCategory(res.data[0]))
                            : this.createLightResourceCategory({
                                  title: `Default_${moment().format('YYYY_MM_DD_HHmmss')}`,
                                  type_ids: type_ids,
                                  default: true,
                                  customer_id: customer_id,
                              })
                    ),
                    share()
                )
            )
        );

        // add to cache.
        this.defaultCategoryMap.set(cache_id, fetchedDefault);
        return fetchedDefault;
    }

    public getPublicCategories(type_id?: number): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);

        builder.param('public', '1');

        if (type_id) {
            builder.param('type', type_id);
        }

        return builder.request().pipe(map((res) => this.mapCategories(res.data)));
    }

    public getCorporateCategories(query?: string): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);
        builder.param('public', '1');
        builder.param('parentType', 'corporate');
        if (query && query.length) {
            builder.param('query', query);
        }
        return builder.request().pipe(map((res) => this.mapCategories(res.data)));
    }

    public getCorporateCategoriesPerUser(user_id: number): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);
        builder.param('parentType', 'corporate');
        builder.param('user', user_id);
        builder.param('public', '1');
        return builder.request().pipe(map((res) => this.mapCategories(res.data)));
    }

    public getGamificationCategories(query?: string): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);
        builder.param('public', '1');
        builder.param('parentType', 'gamification');
        if (query && query.length) {
            builder.param('query', query);
        }
        return builder.request().pipe(map((res) => this.mapCategories(res.data)));
    }

    public getGamificationCategoriesPerUser(user_id: number): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);
        builder.param('parentType', 'gamification');
        builder.param('user', user_id);
        builder.param('public', '1');
        return builder.request().pipe(map((res) => this.mapCategories(res.data)));
    }
    /**
     * @returns Observable<ResourceCategory[]>
     */
    public getCustomerRootCategories(type_id?: number): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);

        builder.param('publicOrCustomer', this.customer_id);
        builder.param('parent', 'null');

        if (type_id) {
            // Return Only Parent categories that connect with this resourceType when param is define
            builder.param('type', type_id);
        }
        return builder.request().pipe(map((res) => this.mapCategories(res.data)));
    }

    public getCustomerRootCategoriesWithPages(
        type_id: number,
        limit: number,
        page: number,
        query?: string
    ): Observable<PaginatorResponse<ResourceCategory[]>> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);

        builder.param('publicOrCustomer', this.customer_id);
        builder.param('parent', 'null');
        builder.param('type', type_id);
        builder.param('limit', limit);
        builder.param('page', page);
        builder.param('sort', 'title');
        if (query) {
            builder.param('query', query);
        }
        return builder.request().pipe(
            map((res: PaginatorResponse<ResourceCategoryData[]>) => {
                return {
                    data: this.mapCategories(res.data),
                    notify: res.notify,
                    paginator: res.paginator,
                };
            })
        );
    }

    /**
     * @returns Observable<ResourceCategory[]>
     */
    public getCustomerCategoriesByQuery(query: string, type_id?: number): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);

        builder.param('customer', this.customer_id);
        builder.param('parent', 'null');

        if (type_id) {
            builder.param('type', type_id);
        }

        return builder.request().pipe(map((res) => this.mapCategories(res.data, query, type_id)));
    }

    public getResourceCategory(category: ResourceCategory | number): Observable<ResourceCategory> {
        const categoryId = category instanceof ResourceCategory ? category.id : category;

        return this.builder
            .get<ResourceCategoryData>(`${this.resourceUrl}/${categoryId}`)
            .request()
            .pipe(map((res) => this.createCategory(res.data)));
    }

    public getResourceCategories(ids: number[], type_id?: number): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);

        builder.param('ids', ids.join(','));

        if (type_id) {
            builder.param('type', type_id);
        }

        return builder.request().pipe(map((res) => this.mapCategories(res.data)));
    }

    public getResourceCategoriesByResourceId(id: number): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);

        builder.param('resource', id);

        return builder.request().pipe(map((res) => this.mapCategories(res.data)));
    }

    public getResourceCategoriesByParent(parent: number, type_ids?: number[]): Observable<ResourceCategory[]> {
        const builder = this.builder.get<ResourceCategoryData[]>(this.resourceUrl);

        builder.param('parent', parent);

        if (type_ids) {
            builder.param('type', type_ids.join());
        }

        return builder.request().pipe(map((res) => this.mapCategories(res.data)));
    }

    public createLightResourceCategory(category: ResourceCategoryData): Observable<ResourceCategory> {
        return this.builder
            .post<ResourceCategoryData>(this.resourceUrl, category)
            .request()
            .pipe(map((res) => (res.data ? new ResourceCategory(res.data) : null)));
    }

    public createResourceCategory(category: ResourceCategoryData): Observable<ResourceCategory> {
        return this.builder
            .post<ResourceCategoryData>(this.resourceUrl, category)
            .request()
            .pipe(map((res) => this.createCategory(res.data)));
    }

    public updateResourceCategory(category: ResourceCategoryData): Observable<ResourceCategory> {
        return this.builder
            .put<ResourceCategoryData>(`${this.resourceUrl}/${category.id}`, category)
            .request()
            .pipe(map((res) => this.createCategory(res.data)));
    }

    public deleteCategory(category: ResourceCategory | number): Observable<{ success: boolean }> {
        const categoryId = category instanceof ResourceCategory ? category.id : category;

        return this.builder
            .delete<{ success: boolean }>(`${this.resourceUrl}/${categoryId}`)
            .request()
            .pipe(map((res) => res.data));
    }

    protected mapCategories(data: ResourceCategoryData[], query?: string, type_id?: number): ResourceCategory[] {
        return data.map((item) => this.createCategory(item, query, type_id));
    }

    protected createCategory(data: ResourceCategoryData, query?: string, type_id?: number): ResourceCategory {
        const category = new ResourceCategory(data);

        if (category.parent_id) {
            category.parent$ = this.getResourceCategory(category.parent_id);
        }

        if (category.resources_attached) {
            category.resources$ = this.resourceService.getResources(null, {
                category: category.id,
                availableForCustomer: this.customer_id,
            });
            category.courses = this.resourceService.getResources(null, {
                category: category.id,
                type_id: AppScope.E_COURSE,
            });
        }

        if (category.children_attached) {
            category.children = category.updateSubject.pipe(
                startWith(null),
                switchMap((createdCategory) => {
                    if (createdCategory && category.loadedChildren) {
                        category.loadedChildren.push(createdCategory);
                        return of(category.loadedChildren);
                    }
                    return this.getResourceCategoriesByParent(category.id, category.type_ids).pipe(
                        tap((loadedChildren) => (category.loadedChildren = loadedChildren))
                    );
                })
            );

            if (query) {
                category.children = category.children.pipe(
                    map((children) => {
                        return children.filter((child) => {
                            return child.title.toLocaleLowerCase().indexOf(query.toLocaleLowerCase()) !== -1;
                        });
                    })
                );
            }
        }
        category.media$ = of(category.media);

        return category;
    }
}
