import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ResourceService } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import { OnDestroyHandler } from 'apps/klickdata/src/app/shared/onDestroy-handler/onDestroy-handler';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-resource-access-control-sheet',
    templateUrl: './resource-access-control-sheet.component.html',
    styleUrls: ['./resource-access-control-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceAccessControlSheetComponent extends OnDestroyHandler implements OnInit {
    public permissionsForm: FormGroup;
    public saving: boolean;
    Utils = Utils;
    public resourcePermissions: {
        index: number;
        value: string;
        label: string;
        tooltip?: string;
        disableUser?: boolean;
    }[];

    constructor(
        public bottomSheetRef: MatBottomSheetRef<ResourceAccessControlSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected resourceService: ResourceService
    ) {
        super();
        this.permissionsForm = this.fb.group({
            id: [],
            user_permissions: this.fb.array([]),
            group_permissions: this.fb.array([]),
        });
        this.resourcePermissions = Utils.getResourcePermissions();
    }
    ngOnInit(): void {
        this.permissionsForm.get('id').patchValue(this.data.resource.id);
    }
    public submit() {
        if (
            !!this.permissionsForm.value.user_permissions?.length ||
            !!this.permissionsForm.value.group_permissions?.length
        ) {
            this.saving = true;
            this.resourceService
                .update(
                    {
                        id: this.data.resource.id,
                        user_permissions: this.getDirtyValues(
                            this.permissionsForm.get('user_permissions') as FormArray
                        ),
                        group_permissions: this.getDirtyValues(
                            this.permissionsForm.get('group_permissions') as FormArray
                        ),
                    },
                    true,
                    { eager: 'user_collaboration' }
                )
                .pipe(takeUntil(this.destroy))
                .subscribe((res) => {
                    this.saving = false;
                    this.bottomSheetRef.dismiss(res);
                });
        } else {
            this.bottomSheetRef.dismiss();
        }
    }
    private getDirtyValues(formArray: FormArray) {
        const values = [];
        formArray.controls.forEach((control: FormGroup) => {
            const dirtyPerms = (control.get('permissions') as FormArray).controls.filter((perm) => perm.dirty);
            values.push({ id: control.value.id, permissions: dirtyPerms.map((perm) => perm.value) });
        });
        return values;
    }
}
