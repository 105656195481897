import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth/src/token/auth.service';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { AppScope, ResourceTypes } from '@klickdata/core/resource';
import { ResourceCategory, ResourceCategoryData } from '@klickdata/core/resource/src/category/resource-category.model';
import { Resource, ResourceData } from '@klickdata/core/resource/src/resource.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

@Injectable()
export class CoursesCatalogService {
    protected resourceUrl: string;
    protected categoryUrl: string;
    protected search: BehaviorSubject<string> = new BehaviorSubject('');
    protected user_id: Observable<number>;
    protected customer_id: Observable<number>;

    constructor(
        protected auth: AuthService,
        protected builder: RequestBuilderService,
        protected config: ConfigService
    ) {
        this.resourceUrl = `${this.config.config.apiUrl}resources`;
        this.categoryUrl = `${this.config.config.apiUrl}categories`;

        this.user_id = this.auth.getUser().pipe(
            first(),
            map(user => user.id)
        );
        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map(customer => customer.id)
        );
    }

    public updateSearch(term: string): void {
        this.search.next(term);
    }

    public getSearch(): Observable<string> {
        return this.search.asObservable();
    }

    public getMyCourses(isPublic?: string): Observable<Resource[]> {
        const build = this.builder
            .get<ResourceData[]>(this.resourceUrl)
            .param('user', this.user_id)
            .param('scope', AppScope.E_COURSE);

        if (isPublic) {
            build.param('public', isPublic);
        }

        return build.request().pipe(
            map(res => {
                return this.mapResources(res.data);
            })
        );
    }

    public getMyPopularCourses(): Observable<Resource[]> {
        return this.builder
            .get<ResourceData[]>(this.resourceUrl)
            .param('user', this.user_id)
            .param('scope', AppScope.E_COURSE)
            .param('public', 'true')
            .param('sort', 'popularity')
            .limit(6)
            .request()
            .pipe(
                map(res => {
                    return this.mapResources(res.data);
                })
            );
    }

    public getMyCustomerCourses(): Observable<Resource[]> {
        return this.builder
            .get<ResourceData[]>(this.resourceUrl)
            .param('user', this.user_id)
            .param('scope', AppScope.E_COURSE)
            .request()
            .pipe(
                map(res => {
                    return this.mapResources(res.data);
                })
            );
    }

    public getMyCoursesByCategoryAndQuery(category: ResourceCategory, query: string): Observable<Resource[]> {
        return this.builder
            .get<ResourceData[]>(this.resourceUrl)
            .param('user', this.user_id)
            .param('scope', AppScope.E_COURSE)
            .param('category', category.id.toString())
            .param('query', query)
            .param('published', 1)
            .request()
            .pipe(
                map(res => {
                    return this.mapResources(res.data);
                })
            );
    }

    public getStartedCourses(limit?: number): Observable<Resource[]> {
        const req = this.builder
            .get<ResourceData[]>(this.resourceUrl)
            .param('started', this.user_id)
            .param('scope', AppScope.E_COURSE);

        if (limit) {
            req.limit(limit);
        }

        return req.request().pipe(
            map(res => {
                return this.mapResources(res.data);
            })
        );
    }

    public getCompletedCourses(limit?: number): Observable<Resource[]> {
        const req = this.builder
            .get<ResourceData[]>(this.resourceUrl)
            .param('done', this.user_id)
            .param('scope', AppScope.E_COURSE);

        if (limit) {
            req.limit(limit);
        }

        return req.request().pipe(
            map(res => {
                return this.mapResources(res.data);
            })
        );
    }

    public getStartedTests(limit?: number): Observable<Resource[]> {
        const req = this.builder
            .get<ResourceData[]>(this.resourceUrl)
            .param('started', this.user_id)
            .param('scope', AppScope.TEST);

        if (limit) {
            req.limit(limit);
        }

        return req.request().pipe(
            map(res => {
                return this.mapResources(res.data);
            })
        );
    }

    public getMyRootCategories(): Observable<ResourceCategory[]> {
        return this.builder
            .get<ResourceCategoryData[]>(this.categoryUrl)
            .param('user', this.user_id)
            .param('parent', 'null')
            .param('type', ResourceTypes.E_COURSE)
            .param('sort', 'weight,title')
            .param('dir', 'desc')
            .request()
            .pipe(
                map(res => {
                    return this.mapCategories(res.data);
                })
            );
    }

    public getMyPublicRootCategories(): Observable<ResourceCategory[]> {
        return this.builder
            .get<ResourceCategoryData[]>(this.categoryUrl)
            .param('user', this.user_id)
            .param('public', 'true')
            .param('parent', 'null')
            .param('type', ResourceTypes.E_COURSE)
            .param('sort', 'weight,title')
            .param('dir', 'desc')
            .request()
            .pipe(
                map(res => {
                    return this.mapCategories(res.data);
                })
            );
    }

    public getMyCustomerRootCategories(): Observable<ResourceCategory[]> {
        return this.builder
            .get<ResourceCategoryData[]>(this.categoryUrl)
            .param('user', this.user_id)
            .param('customer', this.customer_id)
            .param('parent', 'null')
            .param('type', ResourceTypes.E_COURSE)
            .param('sort', 'weight,title')
            .param('dir', 'desc')
            .request()
            .pipe(
                map(res => {
                    return this.mapCategories(res.data);
                })
            );
    }

    public getMyCategoriesByParent(category: ResourceCategory): Observable<ResourceCategory[]> {
        return this.builder
            .get<ResourceCategoryData[]>(this.categoryUrl)
            .param('user', this.user_id)
            .param('parent', category.id.toString())
            .param('type', ResourceTypes.E_COURSE)
            .param('sort', 'weight,title')
            .param('dir', 'desc')
            .request()
            .pipe(
                map(res => {
                    return this.mapCategories(res.data);
                })
            );
    }

    public getMyCategoriesByCourse(course: Resource): Observable<ResourceCategory[]> {
        return this.builder
            .get<ResourceCategoryData[]>(this.categoryUrl)
            .param('user', this.user_id)
            .param('course', course.id.toString())
            .param('sort', 'weight,title')
            .param('dir', 'desc')
            .request()
            .pipe(
                map(res => {
                    return this.mapCategories(res.data);
                })
            );
    }

    protected mapResources(data: ResourceData[]): Resource[] {
        return data.map(item => this.makeResource(item));
    }

    protected makeResource(data: ResourceData): Resource {
        const resource = new Resource(data);
        resource.categories$ = this.getMyCategoriesByCourse(resource);

        return resource;
    }

    protected mapCategories(data: ResourceCategoryData[]): ResourceCategory[] {
        return data.map(item => this.makeCategory(item));
    }

    protected makeCategory(data: ResourceCategoryData): ResourceCategory {
        const category = new ResourceCategory(data);

        category.children = this.getMyCategoriesByParent(category);
        category.resources$ = this.search.pipe(switchMap(query => this.getMyCoursesByCategoryAndQuery(category, query)));

        return category;
    }
}
