import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AppScope } from '@klickdata/core/resource';

@Component({
    selector: 'app-main-catalog-courses',
    templateUrl: './main-catalog-courses.component.html',
    styleUrls: ['./main-catalog-courses.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainCatalogCoursesComponent implements OnInit {
    AppScope = AppScope;
    constructor() {}

    ngOnInit(): void {}
}
