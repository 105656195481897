import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-kd-logo-loading',
    templateUrl: './kd-logo-loading.component.html',
    styleUrls: ['./kd-logo-loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KdLogoLoadingComponent {
    @Input()
    public set size(size: 'small' | 'medium' | 'large') {
        switch (size) {
            case 'small':
                this.viewBoxValue = '-50 -50 300 300';
                break;
            case 'large':
                this.viewBoxValue = '10 10 155 155';
                break;
            default:
                this.viewBoxValue = '0 0 180 180';
                break;
        }
        this._size = size;
    }
    public get size(): 'small' | 'medium' | 'large' {
        return this._size;
    }
    private _size: 'small' | 'medium' | 'large' = 'medium';

    public viewBoxValue = '0 0 180 180';
    constructor() {}
}
