import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceCategoryService } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, takeUntil } from 'rxjs/operators';
import { GlobalFilterColor, GlobalFilterProperty } from '../../global-filters-types.enum';
import { ChipFilterComponent } from '../chip-filter/chip-filter.component';
import { Filter } from '../filter';
import { FilterBase } from '../filter-base';

@Component({
    selector: 'app-category-chip-search-filter',
    templateUrl: './category-chip-search-filter.component.html',
    styleUrls: ['./category-chip-search-filter.component.scss'],
    providers: [
        { provide: ChipFilterComponent, useExisting: forwardRef(() => CategoryChipSearchFilterComponent) },
        { provide: FilterBase, useExisting: CategoryChipSearchFilterComponent },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryChipSearchFilterComponent extends ChipFilterComponent implements OnInit, OnDestroy {
    @Input()
    private type_id: number;
    @Input()
    private scope_id: number | number[];
    @Input() inGlobalFilter: boolean;
    private destroy: Subject<boolean> = new Subject<boolean>();
    public control: FormControl = new FormControl();
    protected _filter: Filter<string> = {
        property: GlobalFilterProperty.CATEGORIES,
        items: [],
        label: $localize`:@@category:Category`,
        icon: 'folder',
        color: { bg: GlobalFilterColor.C2, fg: GlobalFilterColor.WHITE },
        styleClass: 'global-cat-filter-selector',
    };

    constructor(
        protected changeRef: ChangeDetectorRef,
        protected mobile: MobileService,
        protected categoryService: ResourceCategoryService
    ) {
        super(changeRef, mobile);
    }

    ngOnInit() {
        this.control.valueChanges
            .pipe(
                takeUntil(this.destroy),
                debounceTime(300),
                distinctUntilChanged(),
                switchMap((query) => {
                    return query?.trim()
                        ? this.categoryService
                              .getPublicOrCustomerCategories({
                                  query: query,
                                  type_id: this.type_id,
                                  scope_id: this.scope_id,
                                  limit: 10,
                              })
                              .pipe(
                                  map((categories) =>
                                      Utils.mapParentCategories(categories).map((category, index) => {
                                          return {
                                              id: category.id,
                                              label: category.title,
                                              selected: index === 0, // make 1st item selected.
                                          };
                                      })
                                  )
                              )
                        : of([]);
                })
            )
            .subscribe((items) => {
                this.source = items;
                if (items?.length) {
                    this.updateSelection();
                } else {
                    this.removeFilter();
                }
                this.changeRef.markForCheck();
            });
    }

    public setQuery(query: string): void {
        this.control.setValue(query);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
