<form [formGroup]="educatorForm" *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>engineering</mat-icon>
        <div *ngIf="!navData.data.educator" fxLayoutGap="4px">
            <span i18n>Create new</span>
            <span class="smallize">{{ navData.data.educatorLabel }}</span>
        </div>
        <div *ngIf="navData.data.educator" fxLayoutGap="4px">
            <span i18n>Edit</span>
            <span class="smallize">{{ navData.data.educatorLabel }}</span>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" class="role-name">
        <label fxLayoutGap="4px"
            ><span>{{ navData.data.educatorLabel }}</span> <span i18n>photo</span>
        </label>

        <div class="user-img-uploader" fxLayoutAlign="center center">
            <app-media
                [simple]="true"
                *ngIf="!educatorForm.value.media_id"
                formControlName="media_id"
                dropLabel="Drop educator image"
                i18n-dropLabel="@@dropEducatorImage"
                uploadIcon="engineering"
                [scope]="AppScope.USERS"
            ></app-media>
            <app-image-view
                *ngIf="educatorForm.value.media_id"
                [media_id]="educatorForm.value.media_id"
                paddingBottom="100px"
            >
                <button
                    *ngIf="educatorForm.value.media_id"
                    class="remove-image"
                    mat-icon-button
                    color="warn"
                    matTooltip="Remove image"
                    i18n-matTooltip
                    (click)="educatorForm.get('media_id').setValue(null)"
                >
                    <mat-icon class="md-24">delete_outline</mat-icon>
                </button>
            </app-image-view>
        </div>
        <small i18n>Photo size shouldn't exceed 1MB.</small>
    </div>

    <div class="role-name" fxLayoutAlign="start start" fxLayoutGap="15px" fxLayout="column">
        <!-- <mat-form-field class="role-name-field" appearance="outline">
            <mat-label i18n>Educator name</mat-label>
            <input matInput type="text" formControlName="name" />
            <mat-error i18n>New educator name is required</mat-error>
        </mat-form-field> -->

        <app-user-setting-input
            type="text"
            i18n-placeholder
            [placeholder]="'Enter ' + navData.data.educatorLabel + ' name'"
            [control]="educatorForm.get('name') | cast"
        >
            <label role="input-label" fxLayoutGap="4px">
                <span>{{ navData.data.educatorLabel }}</span>
                <span i18n>name</span>
            </label>
        </app-user-setting-input>

        <app-user-setting-input
            type="email"
            i18n-placeholder
            [placeholder]="'Enter ' + navData.data.educatorLabel + ' email'"
            [control]="educatorForm.get('email') | cast"
        >
            <label role="input-label" fxLayoutGap="4px">
                <span>{{ navData.data.educatorLabel }}</span>
                <span i18n>email</span>
            </label>
        </app-user-setting-input>

        <app-user-setting-input
            type="text"
            i18n-placeholder
            [placeholder]="'Enter ' + navData.data.educatorLabel + ' phone number'"
            [control]="educatorForm.get('phone') | cast"
        >
            <label role="input-label" fxLayoutGap="4px">
                <span>{{ navData.data.educatorLabel }}</span>
                <span i18n>phone number</span>
            </label>
        </app-user-setting-input>

        <app-user-setting-input
            type="text"
            i18n-placeholder
            [placeholder]="'Enter ' + navData.data.educatorLabel + ' position'"
            [control]="educatorForm.get('title') | cast"
        >
            <label role="input-label">
                <span i18n>Position</span
                ><mat-icon
                    class="icon-explain"
                    matTooltip="Explain the role of the educator in the context of the resource like event, course etc."
                    i18n-matTooltip
                    >help_outline</mat-icon
                ></label
            >
        </app-user-setting-input>
        <div
            *ngIf="navData.data.role === ResourceStaffRoles.CONTACT"
            fxLayoutGap="4px"
            class="input-wrapper default-select"
            fxLayout="column"
        >
            <span class="label" i18n>Academy</span>
            <div fxLayout="row" *ngIf="!customerRecipient" fxLayoutGap="10px" fxLayoutGap.lt-md="10px">
                <mat-form-field class="small-field users-rec-input" appearance="fill">
                    <input
                        type="text"
                        matInput
                        #customerRecieverInput
                        i18n-placeholder
                        placeholder="Search academies"
                        [formControl]="customerCtrl"
                        [matAutocomplete]="autoCustomer"
                    />
                    <mat-autocomplete
                        #autoCustomer="matAutocomplete"
                        (optionSelected)="selected(autoCustomer, $event, true)"
                    >
                        <mat-option *ngFor="let customer of customerSearch$ | async" [value]="customer">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <span>{{ customer.name }}</span>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div fxLayout="column wrap" [ngClass]="{ 'mt-1': !!customerRecipient }">
                <mat-chip-list
                    *ngIf="customerRecipient"
                    class="reci-chips mb-1"
                    #chipList
                    aria-label="Recievers selection"
                >
                    <mat-chip (removed)="removeCustomer()" [selectable]="false" class="users-wrap customer">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                            <span>{{ customerRecipient?.name }}</span>
                        </div>
                        <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                        </button>
                    </mat-chip></mat-chip-list
                >
            </div>
        </div>
        <ng-container *ngIf="navData.data.role === ResourceStaffRoles.CONTACT">
            <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
                <span i18n>Contact Date</span>
                <mat-form-field
                    appearance="outline"
                    floatLabel="never"
                    class="w-100"
                    fxFlex="grow"
                    fxFlex="1 1 100%"
                    fxFlex.lt-md="1 1 100%"
                >
                    <mat-label i18n>Enter next contact date</mat-label>
                    <input
                        matInput
                        [matDatepicker]="contactDatepicker"
                        (focus)="contactDatepicker.open()"
                        formControlName="contact_date"
                    />
                    <mat-datepicker-toggle matIconSuffix [for]="contactDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #contactDatepicker></mat-datepicker>
                </mat-form-field>
            </div>
        </ng-container>
        <ng-container *ngIf="navData.data.role === ResourceStaffRoles.CONTACT">
            <div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent k3-crm-tags">
                <span i18n>Tags</span>
                <app-tag-chip-select
                    class="w-100"
                    placeholder="Add Tag"
                    [language_id]="currentLaguageId$ | async"
                    i18n-placeholder
                    formControlName="tag_ids"
                ></app-tag-chip-select>
            </div>
        </ng-container>
        <app-user-setting-input
            [control]="educatorForm.get('about') | cast"
            type="textarea"
            class="textArea-field-user"
            [textAreaRows]="3"
            [placeholder]="'Explain the role of the ' + navData.data.educatorLabel"
            i18n-placeholder
        >
            <label role="textarea-label"
                ><span i18n> About</span
                ><mat-icon
                    class="icon-explain"
                    matTooltip="Explain the role of the educator in the context of the resource like event, course etc so the attendees can understand the meaning of this person in the context of KLMS. Its an extract of a cv, not a full version."
                    i18n-matTooltip
                    >help_outline</mat-icon
                >
            </label>
        </app-user-setting-input>
    </div>

    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            [disabled]="educatorForm.invalid"
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="submit()"
            mat-raised-button
        >
            <span>{{ navData.data.positiveBtnText }}</span>
        </button>
        <button
            fxFlex="0 0 auto"
            class="mobile-button negative"
            (click)="onClose.emit(); educatorForm.reset()"
            mat-button
        >
            <span i18n>Cancel</span>
        </button>
    </div>
</form>
