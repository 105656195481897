import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MobileService } from '@klickdata/core/mobile';
import { Question } from '@klickdata/core/question';
import { Resource } from '@klickdata/core/resource';
import { TableFilterComponent, TableSource } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { TestsResultsQuestionsService } from './tests-results-questions.service';

@Component({
    selector: 'app-tests-results-questions',
    templateUrl: './tests-results-questions.component.html',
    styleUrls: ['./tests-results-questions.component.scss'],
    providers: [TestsResultsQuestionsService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class TestsResultsQuestionsComponent implements OnInit, AfterViewInit {
    @Input() public resource: Resource;
    public dataSource = new TableSource();
    public columnsToDisplay = ['sequence', 'title', 'correct', 'incorrect', 'no_entry', 'expand_all'];
    public date = new Date();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    public isMobile$: Observable<boolean>;
    expandedElement: Question | null;
    public isAscIndex = true;
    public accordionExoanded: boolean;

    allRowsExpanded = false;
    constructor(protected qsService: TestsResultsQuestionsService, protected mobileService: MobileService) {}

    ngOnInit(): void {
        this.dataSource.service = this.qsService;
        this.isMobile$ = this.mobileService.isMobile().pipe(shareReplay());
    }
    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.filter.createOrUpdateFilter([{ property: 'test', items: [this.resource.id] }]);
    }
    public toggle() {
        this.allRowsExpanded = !this.allRowsExpanded;
        this.expandedElement = null;
    }
    public sortIndex() {
        // this.filter.createOrUpdateFilter([{ property: 'sort', items: ['sequence'] }]);
        this.filter.createOrUpdateFilter([{ property: 'dir', items: [this.isAscIndex ? 'asc' : 'desc'] }]);
    }
}
