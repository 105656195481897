<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start center" fxLayoutGap="5px">
        <mat-icon>{{ navData.data.icon }}</mat-icon>
        <div>{{ navData.data.title }}</div>
    </div>
    <div class="desc">{{ navData.data.desc }}</div>

    <div class="role-select">
        <mat-form-field class="user-role-select add-new-level-select" appearance="outline">
            <mat-select
                placeholder="Permission level"
                i18n-placeholder
                panelClass="user-roles-panel-select"
                #levelPanel
            >
                <mat-option *ngFor="let level of navData.data.levels" [value]="level">
                    {{ level | capitalizeFirstLetter }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            [disabled]="!levelPanel.value"
            fxFlex="0 0 auto"
            class="mobile-button positive"
            (click)="
                onClose.emit({ action: SideNaveActionsTypes.POSITIVE, data: levelPanel.value }); levelPanel.value = null
            "
            mat-raised-button
        >
            <span>{{ navData.data.positiveBtnText }}</span>
        </button>
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button>
            <span i18n>Cancel</span>
        </button>
    </div>
</div>
