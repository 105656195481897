import { WidgetsService } from '@klickdata/core/widget/src/widget.service';
import { Injectable } from '@angular/core';
import { ActivityLog, ActivityLogData } from '@klickdata/core/activity-log/src/activity-log.model';
import { ActivityLogService } from '@klickdata/core/activity-log/src/activity-log.service';
import { PaginatorResponse, RequestBuilderService, ResponseData } from '@klickdata/core/http';
import { TableHttpHelpers, TableHttpService, TableSource, Filter } from '@klickdata/core/table';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '@klickdata/core/config';

@Injectable()
export class ActionLogListingService extends ActivityLogService implements TableHttpService<ActivityLog> {
    constructor(
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected widgetsService: WidgetsService
    ) {
        super(config, builder);
    }
    public fetchData(source: TableSource<ActivityLog>): Observable<ResponseData<ActivityLog[]>> {
        const req = this.builder.get<ActivityLogData[]>(this.activityUrl);

        this.widgetsService.setActiveWidgetsFilters(
            source.filter?.filter?.filters?.length
                ? source.filter.filter.filters.map((filter) => new Filter(filter.property, filter.items))
                : []
        );

        TableHttpHelpers.managePagination(source.paginator, req);
        TableHttpHelpers.manageSort(source.sort, req);
        TableHttpHelpers.mapFilter(source.filter, req);

        return req.request().pipe(
            map((res: PaginatorResponse<ActivityLogData[]>) => {
                return {
                    data: res.data.map((activity) => this.mapActivityLog(activity)),
                    notify: res.notify,
                    more: res.more,
                    paginator: res.paginator,
                };
            })
        );
    }
}
