import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { ResourceCategory, ResourceCategoryService, AppScope } from '@klickdata/core/resource';
import { User, UserService } from '@klickdata/core/user';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-user-perosonal-settings-fav-categories',
    templateUrl: './user-perosonal-settings-fav-categories.component.html',
    styleUrls: ['./user-perosonal-settings-fav-categories.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPerosonalSettingsFavCategoriesComponent implements OnInit, OnDestroy {
    public corporateCategories$: Observable<ResourceCategory[]>;
    public activeCatsSelection: ResourceCategory[];
    public loadingUserCats: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public onSeachFromList: BehaviorSubject<string> = new BehaviorSubject<string>('');
    @Input() public user: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    AppScope = AppScope;
    constructor(
        protected categoryService: ResourceCategoryService,
        protected userService: UserService,
        protected auth: AuthService,
        protected cdr: ChangeDetectorRef,
        protected messageService: MessageService
    ) {}

    ngOnInit(): void {
        this.corporateCategories$ = this.onSeachFromList.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            switchMap((query) => this.categoryService.getCorporateCategories(query))
        );
        this.loadingUserCats.next(true);
        this.categoryService
            .getCorporateCategoriesPerUser(this.user.id)
            .pipe(takeUntil(this.destroy))
            .subscribe((cats) => {
                this.activeCatsSelection = cats;
                this.loadingUserCats.next(false);
                this.cdr.markForCheck();
            });
    }

    public onSelectedCorCat(category: ResourceCategory) {
        if (this.activeCatsSelection.findIndex((cat) => cat.id === category.id) !== -1) {
            this.messageService.openMessage(MessageErrorComponent, $localize`Category already chosen`);
        } else {
            this.userService
                .update({
                    id: this.user.id,
                    categories_attach: [category.id],
                })
                .pipe(takeUntil(this.destroy))
                .subscribe(() => {
                    this.messageService.openMessage(MessageErrorComponent, $localize`Category added`);
                    this.activeCatsSelection = [...this.activeCatsSelection, category];
                    this.cdr.markForCheck();
                });
        }
    }
    public removeCorCat(category: ResourceCategory) {
        const index = this.activeCatsSelection.findIndex((cat) => cat === category);
        this.userService
            .update({
                id: this.user.id,
                categories_detach: [category.id],
            })
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                if (index !== -1) {
                    this.messageService.openMessage(MessageErrorComponent, $localize`Category removed`);
                    this.activeCatsSelection = this.activeCatsSelection.filter((cat) => cat !== category);
                    this.cdr.markForCheck();
                }
            });
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
