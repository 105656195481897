<div resizeObserver (resize)="onParentResize($event.contentRect?.width)" class="sx-main-wrap">
    <ng-container *ngIf="widgets$ | async; let widgets; else: loading">
        <button class="settings" mat-icon-button (click)="manageSX(widgets)">
            <mat-icon>settings</mat-icon>
        </button>
        <div *ngIf="widgets[0]?.details" class="expand-wrap">
            <button class="expand" mat-icon-button (click)="panelExpanded = !panelExpanded; onExpand(panelExpanded)">
                <mat-icon>{{ panelExpanded ? 'expand_less' : 'expand_more' }}</mat-icon>
            </button>
        </div>
        <div *ngIf="widgetsEmpty === 'none'" class="boxes-wrap">
            <owl-carousel-o [options]="customOptions">
                <ng-container *ngFor="let widget of widgets">
                    <ng-container *ngIf="widget.visible">
                        <ng-template carouselSlide [id]="widget.id.toString()" [width]="scrolledItemWidth">
                            <app-activity-log-sx-box
                                #activityBox
                                [model]="model"
                                [expanded$]="expanded$"
                                [widget]="widget"
                                [activeAppliedDateFilters]="activeAppliedDateFilters"
                            ></app-activity-log-sx-box>
                        </ng-template>
                    </ng-container>
                </ng-container>
            </owl-carousel-o>
        </div>
        <div class="no-widgets" *ngIf="widgetsEmpty === 'hidden'">
            <!-- <mat-icon>visibility_off</mat-icon>
            <span>All the summarization boxes are currently hidden.</span>
            <div>
                <span>Click on the wheel button and choose which boxes you like to view.</span>
                <img src="assets/images/pointer.svg" />
            </div> -->
        </div>
        <div class="no-widgets" *ngIf="widgetsEmpty === 'filters'">
            <mat-icon>visibility_off</mat-icon>
            <span>No summarization boxes meet your filters.</span>
            <div>
                <span>Update the table filters to get results.</span>
            </div>
        </div>
    </ng-container>
</div>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
