import {Component, OnInit} from '@angular/core';

@Component({
    selector:    'app-module-menu',
    templateUrl: './module-menu.component.html',
    styleUrls:   ['./module-menu.component.scss']
})
export class ModuleMenuComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
