import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {UserStatisticsService} from './user-statistics.service';
import { CoursesCatalogService } from '@klickdata/home/src/core/catalog/courses-catalog.service';
import { TestsCatalogService } from '@klickdata/home/src/core/catalog/tests-catalog.service';
import { PlayerCoreModule } from '@klickdata/player/src/core/core.module';
import { ResourceModule } from '@klickdata/core/resource';
import { GradeSystemModule } from '@klickdata/core/grade-system';
import { ResourceItemModule } from '@klickdata/core/resource-item';
import { OpportunityModule } from '@klickdata/core/opportunity';
import { OccasionService } from '@klickdata/home/src/core/occasion/occasion.service';

@NgModule({
    imports: [
        CommonModule,
        PlayerCoreModule,

        ResourceModule,
        ResourceItemModule,
        OpportunityModule,
        GradeSystemModule,
    ],
    providers: [
        UserStatisticsService,
        CoursesCatalogService,
        TestsCatalogService,
        OccasionService,
    ]
})
export class UserCoreModule {
}
