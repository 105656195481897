<span *ngIf="!editMode">
    <ng-container [ngSwitch]="true">
        <span
            *ngSwitchCase="fieldFormControl.value && (resourceField === 'language_id' || resourceField === 'subtitles')"
            ><app-language-selector
                [language_id]="fieldFormControl.value"
                class="icon-resource-builder"
                fxFlex="0 0 auto"
            ></app-language-selector
        ></span>
        <span
            *ngSwitchCase="fieldFormControl.value && (resourceField === 'duration' || resourceField === 'time_limit')"
            >{{ fieldFormControl.value | formatIsoDuration }}</span
        >
        <span *ngSwitchCase="!fieldFormControl.value" i18n>Not set yet.</span>
        <span *ngSwitchDefault>{{ fieldFormControl.value }}</span>
    </ng-container>
</span>
<button mat-icon-button color="primary" *ngIf="!editMode" (click)="editMode = true" matTooltip="Edit" i18n-matTooltip>
    <mat-icon class="ml-1">edit</mat-icon>
</button>

<form *ngIf="editMode" (ngSubmit)="onControlSubmit()">
    <div class="smallView" matLine fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <ng-container [ngSwitch]="true">
            <ng-container *ngSwitchCase="resourceField === 'bullets' || resourceField === 'other_info'">
                <ng-container *ngTemplateOutlet="textArea"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="resourceField === 'language_id'">
                <ng-container *ngTemplateOutlet="lang"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="resourceField === 'subtitles'">
                <ng-container *ngTemplateOutlet="subtitles"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="resourceField === 'description'">
                <ng-container *ngTemplateOutlet="description"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="resourceField === 'img'">
                <ng-container *ngTemplateOutlet="img"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="resourceField === 'duration' || resourceField === 'time_limit'">
                <ng-container *ngTemplateOutlet="timer"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="resourceField === 'price'">
                <ng-container *ngTemplateOutlet="price"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="input"></ng-container>
            </ng-container>
        </ng-container>

        <button mat-icon-button type="submit" color="primary">
            <mat-icon class="md-24">save</mat-icon>
            <span color="primary" i18n="@@save"> Save </span>
        </button>
    </div>
</form>
<ng-template #input>
    <mat-form-field floatLabel="never" fxFlex="0 0 50%">
        <input matInput type="text" [formControl]="fieldFormControl" i18n-placeholder />
    </mat-form-field>
</ng-template>
<ng-template #textArea>
    <mat-form-field floatLabel="never" fxFlex="0 0 200px">
        <textarea
            matInput
            [placeholder]="resourceField === 'bullets' ? 'Please set summary' : 'Please set other info'"
            i18n-placeholder
            [formControl]="fieldFormControl"
        ></textarea>
    </mat-form-field>
</ng-template>
<ng-template #lang>
    <app-language-selector
        [formControl]="fieldFormControl"
        class="icon-resource-builder"
        fxFlex="0 0 auto"
    ></app-language-selector>
</ng-template>

<ng-template #subtitles>
    <app-title-translation
        type="description"
        placeholder="Enter language subtitle"
        i18n-placeholder
        [formControl]="fieldFormControl"
        class="icon-resource-builder"
        fxFlex="0 0 auto"
    ></app-title-translation>
</ng-template>
<ng-template #description>
    <app-text-editor
        title="Course Description"
        i18n-title
        placeholder="Write a description and list what you will learn in this course. Why the learner shall spend time and what will be the benefit of take the Course."
        i18n-placeholder
        fieldName="course-plan-description"
        [formControl]="fieldFormControl"
    ></app-text-editor>
</ng-template>
<ng-template #img>
    <mat-form-field floatLabel="never" fxFlex="0 0 50%">
        <input matInput type="text" required [formControl]="fieldFormControl" i18n-placeholder />
    </mat-form-field>
</ng-template>
<ng-template #timer>
    <div class="duration-picker-wrapper">
        <ngx-duration-picker [formControl]="fieldFormControl" [options]="timeOptions"></ngx-duration-picker>
    </div>
</ng-template>
<ng-template #price>
    <div fxFlex="0 0 50%">
        <mat-form-field fxFlex="0 0 40%" fxLayout.lt-md="0 0 90%">
            <input matInput placeholder="Please set course price" i18n-placeholder [formControl]="fieldFormControl" />
        </mat-form-field>
        <mat-select
            class="select-curr"
            fxFlex="0 0 40%"
            fxLayout.lt-md="0 0 90%"
            placeholder="Place choose currency"
            i18n-placeholder
            formControlName="currency"
        >
            <mat-option *ngFor="let currency of currencies" [value]="currency.code">
                {{ currency.label }} - {{ currency.sign }}
            </mat-option>
        </mat-select>
    </div>
</ng-template>
