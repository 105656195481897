import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveActionsTypes, SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';
import { ResourceData, ResourceTypes } from '@klickdata/core/resource';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
    selector: 'app-menu-side-related-resoures',
    templateUrl: './menu-side-related-resources.component.html',
    styleUrls: ['./menu-side-related-resources.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideRelatedResourcesComponent {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    SideNaveActionsTypes = SideNaveActionsTypes;
    constructor(protected router: Router) {}

    onResClic(res: ResourceData) {
        this.onClose.emit();
        this.router.navigate(['/home/dashboard/resource-details/' + res.id]);
    }
}
