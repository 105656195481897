<form class="data-wrap" fxLayout="column" [formGroup]="form">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>feed</mat-icon><span i18n>Question extra information</span>
    </div>

    <div class="desc" i18n>Please set some extra information that will be displayed to the learner.</div>
    <div fxFlex="grow" fxLayout="column" fxLayoutAlign="10px">
        <div class="rel" fxFlex="grow">
            <div class="notice-wrap">
                <mat-icon
                    matTooltip="The description field will be displayed before the learner takes the question. The information is related to the question in order for the question to be answered. For example: It could be a map, an image, a video, a mathematical formula or a poem that is to be studied before the question can be answered. It is to be viewed as a help to the question text within the context of the question. The description field is a necessary part of the question in order for the depth of the subject to be understood. In comparison to the question image it is much more relevant, since the question image is to be more considered an illustration for gamification purposes without revealing the correct answer. "
                    i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
            <app-text-editor
                fxFlex="1 1 100%"
                title="Description"
                i18n-title
                [showTemplates]="false"
                formControlName="description"
            ></app-text-editor>
        </div>
        <div class="rel" fxFlex="grow">
            <div class="notice-wrap">
                <mat-icon
                    matTooltip="Learning field is a field related to a alternative that will be displayed after the question is answered in order to enhance the knowledge and give the learner some extra boost in the learning process. It is not seen during the process of taking the test or quiz, but is added as display after the test or quiz is taken and the correct and incorrect answers are examined. It iss an opportunity also for the creator to boost and cheer the learners for given the correct answer and also to reveal incorrect answers into becoming correct the next time. "
                    i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
            <app-text-editor
                fxFlex="1 1 100%"
                title="Learning Field"
                i18n-title
                [showTemplates]="false"
                formControlName="learning_field"
            ></app-text-editor>
        </div>
        <div class="rel" fxFlex="grow">
            <div class="notice-wrap">
                <mat-icon
                    matTooltip="This field is for learning more about the subject with a quick clic in order for the learner to learn more after the test or quiz. It could for example be an Wikipedia article or a relevant link on YouTube or PDF where the information in order to answer the correct information is given so the learner does not need to Google the correct answer and can simplify his or her learning process with a single clic. Its a factchecking feature for both admins, creators and learners."
                    i18n-matTooltip
                    >question_mark</mat-icon
                >
            </div>
            <app-text-editor
                fxFlex="1 1 100%"
                title="References"
                i18n-title
                [showTemplates]="false"
                formControlName="references"
            ></app-text-editor>
        </div>
    </div>
    <div class="action">
        <button
            [disabled]="!form.valid || buttonDisabled"
            fxFlex="0 0 auto"
            class="mobile-button"
            (click)="submit()"
            mat-raised-button
            type="button"
        >
            <span>Done</span>
        </button>
    </div>
</form>
