<form class="course-access" [formGroup]="form" fxFlex="0 0 100%" fxLayout="row wrap">
    <mat-tab-group class="user-manage-cp-access" fxFlex="0 0 100%" mat-stretch-tabs ngClass.xs="xs">
        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="mr-1">person</mat-icon>
                <span i18n="@@learners">Learners</span>
            </ng-template>
            <p class="note">
                <span i18n="userListDesc1">
                    Assign users that should complete this course plan. Only active users can be selected.
                </span>
            </p>

            <app-admin-users-select referenceFilter="referenceResource" [accessForm]="form"></app-admin-users-select>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <mat-icon class="mr-1">groups</mat-icon>
                <span i18n="@@groups">Groups</span>
            </ng-template>
            <p class="form-note" i18n>
                Assigning groups to this course plan will assign it to the users of the groups.
            </p>

            <app-admin-groups-select [accessForm]="form"></app-admin-groups-select>
        </mat-tab>
    </mat-tab-group>
    <div *ngIf="!hideActions" fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="end">
        <button *ngIf="!isLoading" mat-raised-button color="primary" (click)="submit()" i18n="@@next">
            Next
        </button>
        <app-saving-button *ngIf="isLoading"></app-saving-button>
    </div>
</form>
