<div *ngIf="navData" class="data-wrap" fxLayout="column">
    <div class="title" fxLayoutAlign="start start" fxLayoutGap="5px">
        <mat-icon>exit_to_app</mat-icon>
        <div i18n>Leave course</div>
    </div>

    <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
        <button
            fxFlex="0 0 auto"
            class="mobile-button positive pos-btn"
            (click)="
                onClose.emit({
                    data: { type: navData.data.type, response: 2 }
                });
                showWarning = false
            "
            i18n
            mat-button
        >
            Continue later
        </button>
        <button
            fxFlex="0 0 auto"
            class="mobile-button positive neg-btn"
            (click)="onClose.emit(); showWarning = false"
            i18n
            mat-button
        >
            Go back
        </button>
        <button
            *ngIf="!showWarning"
            fxFlex="0 0 auto"
            class="mobile-button positive null-btn"
            (click)="showWarning = true"
            i18n
            mat-button
        >
            Cancel course
        </button>
    </div>
    <ng-container *ngIf="showWarning">
        <div class="desc mt-1">
            Are you sure you like to cancel the Course ? This means you will not get any statistics or award for your
            efforts.
        </div>
        <div class="action" fxLayout="row-reverse" fxLayoutAlign="start center" fxLayoutGap="10px">
            <button
                fxFlex="0 0 auto"
                class="mobile-button positive neg-btn"
                (click)="
                    onClose.emit({
                        data: { type: navData.data.type, response: 1 }
                    });
                    showWarning = false
                "
                i18n
                mat-button
            >
                Cancel course
            </button>
        </div>
    </ng-container>
</div>
