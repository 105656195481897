<div [dir]="directionality.value">
    <h1
        class="error-title"
        matDialogTitle>
        <mat-icon
            color="warn"
            class="mat-icon mat-warn material-icons">
            error_outline
        </mat-icon>
        <ng-container i18n="@@errorDialogTitle">Something unexpected happened</ng-container>
    </h1>
    <div mat-dialog-content>
        <p
            class="text-center"
            i18n>
            We came across something we didn't expect.
        </p>
        <p
            class="text-center"
            i18n>
            Please try to reload KlickData.
        </p>
        <small class="app-caption text-center">Event ID: {{eventId}}</small>
    </div>
    <div
        mat-dialog-actions
        fxLayout="row"
        fxLayoutAlign="center center">
        <button
            mat-raised-button
            color="primary"
            (click)="reload()"
            tabindex="2"
            i18n>
            Reload
        </button>
    </div>
</div>
