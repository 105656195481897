import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { ResponseData, PaginatorResponse } from '@klickdata/core/http';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { MediaService } from '@klickdata/core/media/src/media.service';
import { AppScope } from '@klickdata/core/resource';
import { Filter } from '@klickdata/core/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalSearchData } from './global-search.model';

@Injectable({
    providedIn: 'root',
})
export class GloalSearchService {
    protected searchUrl: string;
    protected suggestedSearchUrl: string;
    protected user_id: Observable<number>;
    public customer_id: Observable<number>;
    protected isSearchBoxActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public lastSerchTerm: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
        protected mediaService: MediaService
    ) {
        this.suggestedSearchUrl = `${config.config.apiUrl}global/complete/search`;
        this.searchUrl = `${config.config.apiUrl}global/search`;
    }

    public getSuggestedSearch(query: string, page?: number): Observable<PaginatorResponse<GlobalSearchData[]>> {
        const builder = this.builder.get<GlobalSearchData[]>(`${this.suggestedSearchUrl}`);
        if (query?.length) {
            builder.param('query', query);
        }
        if (page > 0) {
            builder.param('page', page);
        }
        builder.limit(30);
        return builder.request().pipe(
            map((res: PaginatorResponse<GlobalSearchData[]>) => {
                return {
                    data: res.data,
                    paginator: res.paginator,
                };
            })
        );
    }

    public getScopeSearchResults(param: {
        scope: number | string;
        sectionCustomer?: number;
        query?: string;
        page?: number;
        limit?: number;
        sort?: Filter<string>;
        direction?: Filter<string>;
        language?: number[];
    }): Observable<any> {
        const builder = this.builder.get<GlobalSearchData[]>(`${this.searchUrl}`);
        const scopeId =
            typeof param.scope === 'string' ? <number>AppScope[param.scope.toUpperCase()] : param.scope;
        builder.param('query', param.query);
        builder.param('scope_id', scopeId);
        builder.page(param.page);
        if (param.language) {
            builder.param('language', Array.isArray(param.language) ? param.language.join() : param.language);
        }

        if (param.sort && param.sort.items?.length) {
            builder.param(param.sort.property, param.sort.items.join());
            builder.param('dir', param.sort.dir || 'desc');
        }

        if (param.direction && param.direction.items?.length) {
            builder.param(param.direction.property, param.direction.items.join());
        }
        builder.limit(param.limit);
        return builder.request();
    }
    public activateSearchBox(stickBar: boolean) {
        this.isSearchBoxActive.next(stickBar);
    }
    public getSearchBoxState(): Observable<boolean> {
        return this.isSearchBoxActive.asObservable();
    }
}
