import { Component, OnInit } from '@angular/core';

@Component({
    selector:    'app-message-sent',
    templateUrl: './message-sent.component.html',
    styleUrls:   [ './message-sent.component.scss' ]
})
export class MessageSentComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
