import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import { GeneralCapability } from '@klickdata/core/auth/src/capabilities/capability/general-capability.enum';

@Component({
    selector: 'app-admin-menu',
    templateUrl: './admin-menu.component.html',
    styleUrls: ['./admin-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminMenuComponent implements OnInit {
    public accessAdmin = GeneralCapability.access(GeneralCapability.VIEW_ADMIN);
    public accessMaster = GeneralCapability.access(GeneralCapability.VIEW_MASTER);
    public accessAdminOrMaster = GeneralCapability.access(GeneralCapability.VIEW_ADMIN || GeneralCapability.VIEW_MASTER);

    constructor() {
    }

    ngOnInit() {
    }

}
