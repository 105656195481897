<form class="data-wrap" fxLayout="column" [formGroup]="form">
    <div class="title" fxLayoutGap="5px" fxLayout="row" fxLayoutAlign="start start">
        <mat-icon>smart_toy</mat-icon><span>{{ navData.data.headline }}</span>
    </div>

    <mat-form-field class="mb-0" floatLabel="never" appearance="fill" fxFlex="0 0 100%">
        <textarea
            maxlength="5000"
            rows="5"
            [placeholder]="navData.data.placeholder"
            #message
            matInput
            formControlName="value"
        ></textarea>
    </mat-form-field>
    <mat-radio-group
        *ngIf="navData?.data?.hasData"
        [formControl]="promptOptionControl"
        fxLayout="column"
        fxLayoutGap="10px"
    >
        <mat-radio-button [value]="false">Update current content</mat-radio-button>
        <mat-radio-button [value]="true">Add new after current content</mat-radio-button>
    </mat-radio-group>
    <div class="action">
        <button fxFlex="0 0 auto" class="mobile-button negative" (click)="onClose.emit()" mat-button i18n>
            Cancel
        </button>
        <button fxFlex="0 0 auto" class="mobile-button" (click)="submit()" mat-raised-button type="button">
            <span>{{ navData.data.button }}</span>
        </button>
    </div>
</form>
