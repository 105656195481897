import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MobileService } from '@klickdata/core/mobile';
import { Resource, AppScope } from '@klickdata/core/resource';
import { ResourceTableActions } from '@klickdata/core/resource/src/types.enum';
import { Filter,  } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { WelcomeMessageDialogComponent } from 'apps/klickdata/src/app/shared/email/welcome-message-dialog/welcome-message-dialog.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-users-assign-status',
    templateUrl: './users-assign-status.component.html',
    styleUrls: ['./users-assign-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersAssignStatusComponent implements OnInit {
    @Input() resource: Resource;
    private isMobile: boolean;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public tableFilterItems: Filter<string | number>[];
    
    ResourceTableActions = ResourceTableActions;
    AppScope = AppScope;

    constructor(protected dialog: MatDialog, protected mobileService: MobileService) {}

    ngOnInit(): void {
        this.tableFilterItems = [{ property: 'assigned', items: [this.resource.assign_id] }];
        this.mobileService
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe(mobile => (this.isMobile = mobile));
    }
    public sendEmail(user: User) {
        const dialogRef = this.dialog.open(WelcomeMessageDialogComponent, {
            width: !this.isMobile ? '70%' : '100%',
            data: { users_attach: [user.id], title: user.name },
            disableClose: true,
            panelClass: 'sheet-wrapper',
        });
    }
}
