import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { Answer, AnswerData } from '@klickdata/core/question/src/answer/answer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AnswerService {
    protected resourceUrl: string;

    constructor(
        protected builder: RequestBuilderService,
        protected config: ConfigService,
    ) {
        this.resourceUrl = `${this.config.config.resourceUrl}answers`;
    }

    public postAnswer(opportunity_id: number, question_id: number, alternative_id: any, answer: any): Observable<Answer> {
        return this.builder.post<AnswerData>(
            this.resourceUrl, {
                opportunity_id: opportunity_id,
                question_id: question_id,
                alternative_id: alternative_id,
                value: answer,
            })
            .request().pipe(
                map(res => this.createAnswer(res.data)),
            );
    }

    public getAnswer(opportunity_id: number, question_id: number): Observable<Answer[]> {
        return this.builder.get<AnswerData[]>(this.resourceUrl)
            .param('question', question_id)
            .param('opportunity', opportunity_id)
            .request().pipe(
                map(res => this.mapAnswer(res.data)),
            );
    }

    public getAllAnswers(opportunity_id: number): Observable<Answer[]> {
        return this.builder.get<AnswerData[]>(this.resourceUrl)
            .param('opportunity', opportunity_id)
            .param('sort', 'created_at')
            .request().pipe(
                map(res => this.mapAnswer(res.data)),
            );
    }

    public deleteAnswer(id): Observable<{ result: boolean }> {
        return this.builder.delete<{ result: boolean }>(`${this.resourceUrl}/${id}`)
            .request().pipe(map(res => res.data));
    }

    public mapAnswer(data: AnswerData[]): Answer[] {
        return data.map(item => this.createAnswer(item));
    }

    public createAnswer(data: AnswerData): Answer {
        return new Answer(data);
    }
}
