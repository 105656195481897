import { catchError, first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '@klickdata/core/auth';
import { ConfigService } from '@klickdata/core/config';
import { PaginatorResponse, RequestBuilderService } from '@klickdata/core/http';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { Role, RoleData } from './role.model';
import { AccessLevel, FunctionGroup, Policy } from './permission.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class RoleService {
    protected roleUrl: string;
    protected permUrl: string;
    protected customer_id: Observable<number>;
    private _activeRole: BehaviorSubject<Role> = new BehaviorSubject<Role>(null);

    constructor(
        protected configService: ConfigService,
        protected builder: RequestBuilderService,
        protected auth: AuthService
    ) {
        this.roleUrl = `${configService.config.apiUrl}roles`;
        this.permUrl = `${configService.config.apiUrl}permissions`;
        this.customer_id = this.auth.getCustomer().pipe(
            first(),
            map((customer) => customer.id)
        );
    }
    public setActiveRole(role: Role) {
        this._activeRole.next(role);
    }
    getActiveRole(): Observable<Role> {
        return this._activeRole.asObservable();
    }
    public getAllRoles(customerId: number): Observable<Role[]> {
        const request = this.builder.get<RoleData[]>(`${this.roleUrl}`);
        request.param('publicOrCustomer', customerId);
        return request.request().pipe(map((res) => res.data.map((role) => this.mapRole(role))));
    }

    public getDefaultAcademyRole(): Observable<Role> {
        const request = this.builder.get<RoleData>(`${this.roleUrl}`);
        request.param('default', 'true');
        return request.request().pipe(map((res) => this.mapRole(res.data)));
    }

    public duplicateRole(id: number, data: RoleData): Observable<Role> {
        return this.builder
            .post<RoleData>(`${this.roleUrl}/${id}/duplicate`, data)
            .request()
            .pipe(map((res) => this.mapRole(res.data)));
    }

    public updateRole(id: number, data: RoleData): Observable<Role> {
        return this.builder
            .put<RoleData>(`${this.roleUrl}/${id}`, data)
            .request()
            .pipe(map((res) => this.mapRole(res.data)));
    }

    public deleteRole(id: number): Observable<{ success: boolean } | {}> {
        return this.builder
            .delete<{ success: boolean }>(`${this.roleUrl}/${id}`)
            .request()
            .pipe(
                map((res) => res.data),
                catchError((res: HttpErrorResponse) => {
                    return EMPTY;
                })
            );
    }

    public getPermissionsByFunction(roleId: number): Observable<FunctionGroup[]> {
        const request = this.builder.get<FunctionGroup[]>(`${this.permUrl}`);
        request.param('groupBy', 'functions');
        request.param('referenceCount', 1);
        request.param('referenceRole', roleId);
        return request.request().pipe(map((res: PaginatorResponse<FunctionGroup[]>) => res.data));
    }

    public getPermissionsByAccessLevel(roleId: number): Observable<AccessLevel[]> {
        const request = this.builder.get<AccessLevel[]>(`${this.permUrl}`);
        request.param('groupBy', 'accessLevel');
        request.param('referenceCount', 1);
        request.param('referenceRole', roleId);
        return request.request().pipe(map((res: PaginatorResponse<AccessLevel[]>) => res.data));
    }

    public updateFunctionGroupStatus(
        roleId: number,
        body: { functions_attach?: string[]; functions_detach?: string[]; permissions_level?: string }
    ): Observable<Role> {
        return this.builder
            .put<Role>(`${this.roleUrl}/${roleId}`, body)
            .request()
            .pipe(map((res) => this.mapRole(res.data)));
    }

    public updatePolicyStatus(
        roleId: number,
        body: { policies_attach?: string[]; policies_detach?: string[], permissions_level?: string }
    ): Observable<Role> {
        return this.builder
            .put<Role>(`${this.roleUrl}/${roleId}`, body)
            .request()
            .pipe(map((res) => this.mapRole(res.data)));
    }

    public updateAccessLevelStatus(
        roleId: number,
        body: { access_levels_attach?: string[]; access_levels_detach?: string[] }
    ): Observable<Role> {
        return this.builder
            .put<Role>(`${this.roleUrl}/${roleId}`, body)
            .request()
            .pipe(map((res) => this.mapRole(res.data)));
    }

    public updateRolePermissions(
        roleId: number,
        body: { permissions_attach?: number[]; permissions_detach?: number[]; permissions_level?: string }
    ): Observable<Role> {
        return this.builder
            .put<Role>(`${this.roleUrl}/${roleId}`, body)
            .request()
            .pipe(map((res) => this.mapRole(res.data)));
    }

    protected mapRole(roleData: RoleData): Role {
        return new Role(roleData);
    }
}
