import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {CapabilityService} from '../capabilities/capability.service';
import {GeneralCapability} from '../capabilities/capability/general-capability.enum';

@Injectable({
    providedIn: 'root',
})
export class MasterGuard implements CanLoad, CanActivate, CanActivateChild {

    constructor(
        protected capability: CapabilityService,
        protected router: Router
    ) {
    }

    canLoad(route: Route): Observable<boolean> {
        return this.check();
    }

    canActivate(
        route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.check();
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.check();
    }

    private check(): Observable<boolean> {
        return this.capability
            .check(GeneralCapability.getName(), GeneralCapability.VIEW_MASTER)
            .pipe(
                first(),
                map(result => {
                    if (!result) {
                        this.router.navigate(['/unauthorised']);
                    }

                    return result;
                }),
            );
    }

}
