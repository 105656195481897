import {AccessCapability} from '../can-access.directive';

export namespace GradeSystemCapability {
    export const NONE                       = 0;
    export const VIEW_GRADE_SYSTEM          = 1 << 1;
    export const MANAGE_GROUP_GRADE_SYSTEMS = 1 << 2;
    export const MANAGE_GRADE_SYSTEM        = 1 << 3;
    export const MANAGE_PUBLIC_GRADE_SYSTEM = 1 << 4;

    export function getName(): string {
        return 'gradeSystem';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
