<div fxFlex="0 0 100%" fxLayout="row wrap" class="assignments-select">
    <app-sticky-header
        fxFlex="grow"
        [title]="data.title"
        [context]="data.context"
        [contextIcon]="data.contextIcon"
        (onCancel)="bottomSheetRef.dismiss()"
    ></app-sticky-header>
    <div class="user-manage-cp-access" fxFlex="grow" ngClass.xs="xs">
        <app-collection-item-children-list
            *ngIf="data"
            [collection]="data.collection"
        ></app-collection-item-children-list>
    </div>
</div>
