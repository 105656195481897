import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { MessageDeletedComponent, MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { ResourceTypes } from '@klickdata/core/resource';
import { Section, SectionService } from '@klickdata/core/section';
import { GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { BrowseFolderComponent } from '../../folder/browse-folder/browse-folder.component';
import { SelectModelBaseDirective } from '../select-model-base/select-model-base.component';
import { SectionSelectDatatableService } from './section-select-datatable.service';

@Component({
    selector: 'app-collections-children-select',
    templateUrl: './collections-children-select.component.html',
    styleUrls: ['./collections-children-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SectionSelectDatatableService],
})
export class CollectionsChildrenSelectComponent
    extends SelectModelBaseDirective<Section>
    implements OnInit, AfterViewInit, OnDestroy
{
    @Input() customer: Customer;
    @Input() accessForm: FormGroup;
    @Input() referenceFilter: string;
    @Input() public: boolean;
    @Output() add: EventEmitter<void> = new EventEmitter<void>();
    @Output() edit: EventEmitter<Section> = new EventEmitter<Section>();
    ResourceTypes = ResourceTypes;
    protected sync_all_key = 'sync_all_items';
    protected attachKey = 'items_attach';
    protected detachKey = 'items_detach';
    columns: string[] = ['select', 'title', 'created_at', 'updated_at', 'public'];
    public itemSelectedOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;

    constructor(
        public datatableService: SectionSelectDatatableService,
        protected bottomSheet: MatBottomSheet,
        protected mobileService: MobileService,
        protected sectionService: SectionService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected messageService: MessageService
    ) {
        super(datatableService, mobileService);
        this.itemSelectedOptions = Utils.getItemSelectedOptions();
    }

    protected refresh(id?: any): void {
        const filters = [];
        if (this.public) {
            filters.push({
                property: 'public',
                items: [1],
            });
        } else if (this.customer) {
            filters.push({
                property: 'assignedOrCustomer',
                items: [this.customer.id],
            });
        }
        if (this.referenceFilter) {
            filters.push({ property: this.referenceFilter, items: [id] });
        }
        if (id) {
            filters.push({ property: 'referenceCount', items: [1] });
        }
        this.filter.createOrUpdateFilter(filters);
    }

    detectIdKeyValueChange() {
        if (this.referenceFilter) {
            super.detectIdKeyValueChange();
        } else {
            this.refresh();
        }
    }
}
