import { NgModule } from '@angular/core';
import { PreloadAllModules, Route, RouterModule } from '@angular/router';
import {
    AdminGuard,
    AuthGuard,
    KlickdataGuard,
    MasterGuard,
    OccasionAuthComponent,
    PaymentGuard,
    PlayerGuard,
} from '@klickdata/core/auth';
import { PostPageComponent } from '@klickdata/core/post';
import { ChangelogComponent } from './shared/changelog/changelog.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { UnauthorisedComponent } from './shared/unauthorised/unauthorised.component';

const routes: Route[] = [
    { path: '', redirectTo: 'guest/start', pathMatch: 'full' },
    { path: 'post/:id', component: PostPageComponent, outlet: 'popup' },
    {
        path: 'guest',
        loadChildren: () => import('@klickdata/guest').then((m) => m.GuestModule),
    },
    {
        path: 'home',
        loadChildren: () => import('@klickdata/home').then((m) => m.HomeModule),
        canLoad: [AuthGuard, KlickdataGuard, PaymentGuard],
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
        canLoad: [AuthGuard, AdminGuard, PaymentGuard],
    },
    {
        path: 'master',
        loadChildren: () => import('./master/master.module').then((m) => m.MasterModule),
        canLoad: [AuthGuard, MasterGuard, PaymentGuard],
    },
    {
        path: 'player',
        loadChildren: () => import('@klickdata/player').then((m) => m.PlayerModule),
        canLoad: [AuthGuard, PlayerGuard, PaymentGuard],
    },
    { path: 'occasion/:occasionId', component: OccasionAuthComponent },
    { path: 'unauthorised', component: UnauthorisedComponent },
    { path: 'changelog', component: ChangelogComponent },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            enableTracing: false,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
