import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Group, GroupService } from '@klickdata/core/group';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-team-groups-users-select-sheet',
    templateUrl: './team-groups-users-select-sheet.component.html',
    styleUrls: ['./team-groups-users-select-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamGroupsUsersSelectSheetComponent implements OnInit, OnDestroy {
    public assignForm: FormGroup;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public saving: boolean;

    constructor(
        public bottomSheetRef: MatBottomSheetRef<TeamGroupsUsersSelectSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
        protected fb: FormBuilder,
        protected groupService: GroupService
    ) {}

    ngOnInit() {
        this.assignForm = this.fb.group({
            id: [],
            sync_all: [],
            attach_ids: [[]],
            detach_ids: [[]],
        });
        this.assignForm.patchValue({ id: this.data.id });
    }

    public submit() {
        if (
            this.assignForm.value.sync_all !== null ||
            this.assignForm.value.attach_ids?.length ||
            this.assignForm.value.detach_ids?.length
        ) {
            const data = this.prepareData();
            this.saving = true;
            this.groupService
                .update(data)
                .pipe(takeUntil(this.destroy))
                .subscribe(() => {
                    this.saving = false;
                    this.bottomSheetRef.dismiss(this.data);
                });
        } else {
            this.bottomSheetRef.dismiss();
        }
    }
    private prepareData() {
        let users = this.assignForm.value;
        delete users.id;
        users.team_id = +this.data.id.split(',')[0];
        return { id: +this.data.id.split(',')[1], users: users };
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
