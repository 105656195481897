import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResourceItem, ResourceItemTypes } from '@klickdata/core/resource-item';
import { User } from '@klickdata/core/user';

@Component({
    selector: 'app-course-player-results-questions',
    templateUrl: './course-player-results-questions.component.html',
    styleUrls: ['./course-player-results-questions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoursePlayerResultsQuestionsComponent {
    @Input() items: ResourceItem[];
    @Input() user: User;
    constructor() {}

    get testItems(): ResourceItem[] {
        return !!this.items.length
            ? this.items.filter((resourceItem) => resourceItem.item_type_value === ResourceItemTypes.TEST)
            : [];
    }
}
