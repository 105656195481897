import { Component, Input, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
    selector:    'app-message-saved',
    templateUrl: './message-saved.component.html',
    styleUrls:   ['./message-saved.component.scss']
})
export class MessageSavedComponent {
    @Input()
    public msg: string;
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string }) {
        this.msg = data ? data.message : $localize`Saved`;
    }
}
