import { Component, OnInit, ChangeDetectionStrategy, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirectionalityService } from '@klickdata/core/localization/src/directionality.service';

@Component({
    selector: 'app-info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDialogComponent implements OnInit {
    @Input() contentBody: string;
    @Input() neutralBtn: string;

    constructor(
        public dialogRef: MatDialogRef<InfoDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        protected data: {
            contentBody: string;

            neutralBtn?: string;
        }
    ) {
        if (data) {
            this.contentBody = data.contentBody;
            this.neutralBtn = data.neutralBtn;
        }
    }

    ngOnInit(): void {}
}
