import { MessageSavedComponent } from '@klickdata/core/message/src/message-saved/message-saved.component';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { CollectionManageBottomSheetComponent } from '@klickdata/shared-components/src/collection-manage-bottom-sheet/collection-manage-bottom-sheet.component';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { Collection, CollectionData, CollectionService } from '@klickdata/core/collection';
import { Customer } from '@klickdata/core/customer';
import { MessageDeletedComponent, MessageService } from '@klickdata/core/message';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope, ResourceTypes } from '@klickdata/core/resource';
import { GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { filter, first, switchMap, takeUntil } from 'rxjs/operators';
import { CollectionSelectService } from '../../collections/collections-listing-select/collection-select.service';
import { SelectModelBaseDirective } from '../../selection-handler/select-model-base/select-model-base.component';
import { Subject } from 'rxjs';
import { CollectionItemSheetBrowseChildrenComponent } from '../collection-item-sheet-browse-children/collection-item-sheet-browse-children.component';

@Component({
    selector: 'app-collections-listing-select',
    templateUrl: './collections-listing-select.component.html',
    styleUrls: ['./collections-listing-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CollectionSelectService],
})
export class CollectionsListingSelectComponent
    extends SelectModelBaseDirective<Collection>
    implements OnInit, AfterViewInit, OnDestroy
{
    @Input() accessForm: FormGroup;
    @Input() referenceFilter: string;
    @Input() AdditionBtn: boolean;
    @Input() scope: AppScope;
    @Input() hideTools: boolean;
    @Input() public: boolean;
    @Input() customer: Customer;
    ResourceTypes = ResourceTypes;
    protected sync_all_key = 'sync_all_collections';
    protected attachKey = 'collections_attach';
    protected detachKey = 'collections_detach';
    columns: string[] = ['select', 'title', 'created_at', 'updated_at', 'tools'];
    public itemSelectedOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;

    constructor(
        public datatableService: CollectionSelectService,
        protected bottomSheet: MatBottomSheet,
        protected mobileService: MobileService,
        protected collectionService: CollectionService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected messageService: MessageService,
        protected cdr: ChangeDetectorRef
    ) {
        super(datatableService, mobileService);
        this.itemSelectedOptions = Utils.getItemSelectedOptions();
    }

    /**
     *
     */
    public ngOnInit(): void {
        super.ngOnInit();
    }

    protected refresh(id: any): void {
        const filters = [
            { property: this.referenceFilter, items: [id] },
            { property: 'scope', items: [this.scope] },
        ];

        if (this.public) {
            filters.push({ property: 'public', items: ['1'] });
        }

        this.filter.createOrUpdateFilter(filters);
    }
    // handleDefaultToggle(checked: boolean, collection: Collection) {
    //     this.collectionService
    //         .updateCollection({ id: collection.id, default: checked })
    //         .pipe(takeUntil(this.destroy))
    //         .subscribe(() => this.messageService.openMessage(MessageSavedComponent));
    // }

    public showSections(collection: Collection) {
        this.bottomSheet.open(CollectionItemSheetBrowseChildrenComponent, {
            data: {
                collection: collection,
                title: collection.title,
                contextIcon: 'ballot',
            },
            panelClass: 'sheet-wrapper',
        });
    }

    public deleteCollection(collection: Collection) {
        this.dialog
            .open(DeleteDialogComponent)
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => result),
                switchMap(() => this.collectionService.deleteCollection(collection.id))
            )
            .subscribe((success) => {
                if (success) {
                    this.dataSource.removeById(collection.id);
                    this.dataSource.paginator.length = this.dataSource.length;
                    this.cdr.markForCheck();
                    this.messageService.openMessage(MessageDeletedComponent);
                }
            });
    }

    public editCollection(collection: Collection) {
        this.openCollectionSheet(collection);
    }

    public addCollection() {
        this.openCollectionSheet();
    }

    private openCollectionSheet(collection?: Collection) {
        this.bottomSheet
            .open(CollectionManageBottomSheetComponent, {
                data: {
                    title: collection ? $localize`Edit collection` : $localize`Add new collection`,
                    contextIcon: 'ballot',
                    collection: collection,
                    scope: this.scope,
                    public: true,
                    customer: this.customer,
                    defaultSorting: collection ? 'checked' : '',
                    referenceFilter: collection ? 'referenceCollection' : null,
                    isMasterAdmin: true,
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((data) => data && data.id)
            )
            .subscribe((data) => {
                if (collection) {
                    this.dataSource.replace(data);
                } else {
                    this.dataSource.add(data);
                }
            });
    }
}
