import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { DeleteDialogComponent } from '@klickdata/core/application';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { Folder } from '@klickdata/core/folder';
import { MessageDeletedComponent, MessageService } from '@klickdata/core/message';
import { Resource, ResourceTypes, AppScope } from '@klickdata/core/resource';
import { TableFilterComponent, TableSource } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { FolderListService } from './folder-list.service';

@Component({
    selector: 'app-list-folders',
    templateUrl: './folder-list.component.html',
    styleUrls: ['./folder-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FolderListService],
})
export class FolderListComponent implements OnInit, OnDestroy, AfterViewInit {
    public dataSource = new TableSource();
    @Input() folder: Folder;
    @Input() customer: Customer;
    @Input() scope: 'public' | 'assignedOrCustomer' = 'assignedOrCustomer';
    @Output() createOrUpdate: EventEmitter<Folder> = new EventEmitter<Folder>();

    public columns$ = new BehaviorSubject<string[]>([
        'title',
        'type',
        'author_name',
        'created_at',
        'updated_at',
        'language',
        'tools',
    ]);

    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    public destroy: Subject<boolean> = new Subject<boolean>();
    public authUser: User;
    public breadcrumb$: Subject<Folder> = new Subject<Folder>();
    AppScope = AppScope;
    ResourceTypes = ResourceTypes;

    constructor(
        protected listService: FolderListService,
        protected changeRef: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected router: Router,
        protected route: ActivatedRoute,
        protected auth: AuthService,
        protected messageService: MessageService
    ) {}

    public ngOnInit(): void {
        this.dataSource.service = this.listService;
        this.auth
            .getUser()
            .pipe(takeUntil(this.destroy))
            .subscribe(user => (this.authUser = user));
    }

    public ngAfterViewInit(): void {
        if (this.scope === 'public' || this.customer) {
            this.filter.createOrUpdateWithoutEmitFilter([
                { property: this.scope, items: [this.scope === 'public' ? 1 : this.customer.id] },
            ]);
        }
        this.dataSource.filter = this.filter;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        // this.dataSource.refresh();
        if (this.folder) {
            this.open(this.folder);
            this.columns$.next(['title', 'author_name', 'created_at', 'updated_at', 'language']);
        }
    }

    public open(item: Folder | Resource): void {
        if (!this.isFolder(item)) {
            return;
        }
        this.breadcrumb$.next(<Folder>item);
    }

    public isFolder(item: Folder | Resource): boolean {
        return typeof item === 'object' && 'parent_id' in item;
    }

    viewResource(resource: Resource) {
        switch (resource.type_id) {
            case ResourceTypes.GeneralCoursePlan:
                this.router.navigateByUrl(`/player/resource/${resource.id}`);
                break;
            case ResourceTypes.GeneralSurvey:
                this.router.navigateByUrl(`/admin/surveys/surveys/${resource.id}/questions-browse`);
                break;
            case ResourceTypes.GeneralTest:
                this.router.navigateByUrl(`/admin/tests/${resource.id}/questions-browse`);
                break;
            case ResourceTypes.General_E_Course:
                this.router.navigateByUrl(`/admin/courses/${resource.id}/settings-browse`);
                break;
            default:
                this.router.navigateByUrl(`/admin/authoring-tool/authoringTools/${resource.id}/details-browse`);
                break;
        }
    }

    public edit(folder: Folder): void {
        if (this.createOrUpdate.observers.length) {
            this.createOrUpdate.emit(folder);
        } else {
            this.router.navigate(['/admin/folders/', folder.id]);
        }
    }

    public remove(folder: Folder, ev: Event): void {
        this.dialog
            .open(DeleteDialogComponent)
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                filter(result => result),
                switchMap(() => this.listService.destroy(folder))
            )
            .subscribe(success => {
                if (success) {
                    this.messageService.openMessage(MessageDeletedComponent);
                    this.dataSource.removeById(folder.id);
                }
            });
    }

    public ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
