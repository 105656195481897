export enum EditorTemplateCapability {
    NONE                                   = 0,
    MASTER_EDITOR_TEMPLATES                = 1 << 0,
    VIEW_CUSTOMER_GROUP_EDITOR_TEMPLATES   = 1 << 1,
    VIEW_CUSTOMER_EDITOR_TEMPLATES         = 1 << 2,
    CREATE_EDITOR_TEMPLATES                = 1 << 3,
    CREATE_CUSTOMER_GROUP_EDITOR_TEMPLATES = 1 << 4,
    CREATE_CUSTOMER_EDITOR_TEMPLATES       = 1 << 5,
    MANAGE_CUSTOMER_EDITOR_TEMPLATES       = 1 << 6,
}
export namespace EditorTemplateCapability {
    export function getName(): string {
        return 'editorTemplate';
    }
}
