import { takeUntil, filter } from 'rxjs/operators';
import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Input,
    Optional,
    OnDestroy,
    ChangeDetectorRef,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { User } from '@klickdata/core/user';
import { Subject } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-multiple-string-inputs',
    templateUrl: './multiple-string-inputs.component.html',
    styleUrls: ['./multiple-string-inputs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class MultipleStringInputsComponent implements OnInit, OnDestroy {
    @Input() placeholder: string | null = '';
    @Input() label: string;
    @Input() controlName: string;
    @Input() public sortable: boolean;
    public showInput: boolean;
    private _value: string[];
    @Input() set value(value: string[]) {
        this._value = value;
        this.items = this.value.filter((value) => !!value);
        this.updateConrol();
        this.cdr.markForCheck();
    }
    public get value(): string[] {
        return this._value;
    }
    private destroy: Subject<boolean> = new Subject<boolean>();
    public control: FormControl = new FormControl('');
    public form: FormGroup;
    public items = [];
    private lastInputValue: string;

    constructor(protected parentFormDirective: FormGroupDirective, protected cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (this.parentFormDirective) {
            this.form = this.parentFormDirective.form;
            this.form.addControl(this.controlName, new FormControl([]));
        }
        this.control.valueChanges
            .pipe(
                takeUntil(this.destroy),
                filter((value) => value !== '')
            )
            .subscribe((value) => {
                this.lastInputValue = value;
                this.updateConrol();
            });
    }

    add() {
        if (this.items.includes(this.control.value)) {
            this.control.setValue('');
            return;
        }
        if (this.control.value) {
            this.items.push(this.control.value);
            this.control.setValue('');
            this.lastInputValue = null;
            this.updateConrol();
        }
    }

    remove(index: number) {
        this.items.splice(index, 1);
        this.updateConrol();
    }
    update(item: { data: string; index: number }) {
        this.items[item.index] = item.data;
        this.updateConrol();
        this.cdr.markForCheck();
    }

    private updateConrol() {
        this.form
            ?.get(this.controlName)
            .patchValue(this.lastInputValue ? [...this.items, this.lastInputValue] : this.items);
    }
    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
        this.updateConrol();
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
