import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MenuSideTextAreaFieldComponent } from '../menu-side-text-area-field/menu-side-text-area-field.component';

@Component({
    selector: 'app-menu-side-textarea-with-multiple-actions',
    templateUrl: './menu-side-textarea-with-multiple-actions.component.html',
    styleUrls: ['./menu-side-textarea-with-multiple-actions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideTextareaWithMultipleActionsComponent extends MenuSideTextAreaFieldComponent implements OnInit {
    public value = new FormControl();
    constructor(protected fb: FormBuilder, protected cd: ChangeDetectorRef) {
        super(fb, cd);
    }

    ngOnInit(): void {}
}
