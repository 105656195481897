<div class="mb-1 results-wrapper-answer-table" fxLayout="column" fxLayoutAlign="center center">
    <app-table-toolbar class="toolbar" color="accent">
        <mat-accordion fxFlex="0 0 100%" displayMode="flat">
            <mat-expansion-panel [expanded]="false" #expansionPanel>
                <mat-expansion-panel-header> </mat-expansion-panel-header>
                <app-table-filter
                    [inGlobalFilter]="true"
                    
                    color="accent"
                    #filter
                >
                    <app-table-search [inGlobalFilter]="true" class="align-space" searchFilterRef></app-table-search>
                </app-table-filter>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-progress-bar
            class="progress progress-table-loader"
            *ngIf="(dataSource.status | async) === 'loading'"
            mode="indeterminate"
        ></mat-progress-bar>
    </app-table-toolbar>
    <div class="results-wrapper" fxLayout="column wrap">
        <div class="example-container">
            <table mat-table [dataSource]="dataSource" matSort matSortDirection="desc">
                <!-- Name Column -->
                <ng-container matColumnDef="name" sticky>
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let user">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px">
                            <!-- <div class="user-img">{{ user.name | nameSign }}</div> -->
                            <app-image-view
                                [ngStyle]="{ minWidth: '50px' }"
                                [isCircularImg]="true"
                                [media_id]="user.media"
                                paddingBottom="50px"
                            ></app-image-view>
                            {{ user.name }}
                        </div>
                    </td>
                </ng-container>

                <!-- Position Column -->
                <ng-container *ngFor="let qsId of qsIds; let i = index" matColumnDef="{{ i + 1 }}">
                    <th mat-header-cell [class.last-column-index]="i === qsIds.length - 1" *matHeaderCellDef>
                        <span class="qs-index">
                            {{ i + 1 }}
                        </span>
                    </th>
                    <td mat-cell [class.last-column-index]="i === qsIds.length - 1" *matCellDef="let user">
                        <mat-icon
                            [ngClass]="
                                user.finalAnswer.answer[qsId]
                                    ? 'correct'
                                    : user.finalAnswer.answer[qsId] === 0
                                    ? 'incorrect'
                                    : 'not-answered'
                            "
                            >{{
                                user.finalAnswer.answer[qsId]
                                    ? 'done'
                                    : user.finalAnswer.answer[qsId] === 0
                                    ? 'close'
                                    : 'horizontal_rule'
                            }}</mat-icon
                        >
                    </td>
                </ng-container>
                <ng-container matColumnDef="star" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let user">
                        <div fxLayoutAlign="start center" fxLayoutGap="5px" fxLayoutGap.lt-md="0px">
                            <button
                                matTooltip="View results"
                                mat-icon-button
                                class="view-Answer"
                                i18n-matTooltip="@@viewResults"
                                (click)="$event.stopPropagation(); viewUserResults(user)"
                            >
                                <mat-icon>visibility</mat-icon>
                            </button>
                            <mat-icon>more_vert</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columns"></tr>
                <tr mat-row *matRowDef="let row; columns: columns"></tr>
            </table>
        </div>
        <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]"> </mat-paginator>
    </div>
</div>
