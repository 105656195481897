import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Customer } from '@klickdata/core/customer';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Filter } from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { Widget } from '@klickdata/core/widget';
import { WidgetsService } from '@klickdata/core/widget/src/widget.service';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
    selector: 'app-activity-log-sx',
    templateUrl: './activity-log-sx.component.html',
    styleUrls: ['./activity-log-sx.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityLogSxComponent implements OnInit, OnDestroy {
    @Input() model: User | Customer;
    @Input() context: 'admin' | 'user' | 'master' = 'admin';
    public expanded$: Observable<boolean>;
    public panelExpanded: boolean;
    public widgetsEmpty: 'filters' | 'hidden' | 'none' = 'none';
    public scrolledItemWidth = 400;
    @Input() itemsInDiffWidths = [1, 2, 3, 3, 4, 5];
    private destroy = new Subject<boolean>();
    public widgets$: Observable<Widget[]>;
    public activeAppliedDateFilters: Filter<string>[];
    updateSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public openedFiltersWidget: number;
    customOptions = Utils.getOwlOptions({
        lazyLoadEager: 3,
        loop: true,
    });
    wrapperItemWidth: number;

    constructor(
        protected elementRef: ElementRef,
        protected cdr: ChangeDetectorRef,
        protected widgetsService: WidgetsService,
        protected mobileService: MobileService
    ) {}

    ngOnInit(): void {
        this.widgets$ = combineLatest([
            this.widgetsService.getActiveWidgetsFilters(),
            this.updateSubject.asObservable(),
        ]).pipe(
            tap(([filters, _]) => {
                this.activeAppliedDateFilters = filters.filter(
                    (filt) => filt.property === 'begin' || filt.property === 'end'
                ) as Filter<string>[];
            }),
            switchMap(([filters, _]) =>
                this.widgetsService.getAllWidgets([
                    ...filters,
                    ...[
                        this.context === 'master' && this.model instanceof Customer && this.model?.id
                            ? new Filter('customer', [this.model.id])
                            : this.context === 'user' && this.model instanceof User && this.model?.id
                            ? new Filter('user', [this.model.id])
                            : null,
                    ],
                ])
            ),
            tap((widgets) => {
                if (widgets.filter((widget) => widget.visible).length <= 0) {
                    this.widgetsEmpty = 'hidden';
                } else if (widgets.length <= 0) {
                    this.widgetsEmpty = 'none';
                } else {
                    this.widgetsEmpty = 'none';
                }
            })
        );
    }

    onParentResize(width: number) {
        this.getSlideWidth(width);
    }
    onExpand(isExpanded: boolean) {
        this.expanded$ = of(isExpanded);
    }

    manageSX(widgets: Widget[]) {
        this.mobileService.updateSideNavSub({
            dataType: SideNaveDataTypes.MANAGE_WIDGETS,
            data: {
                widgets: widgets,
                type: 'widgets',
            },
        });
        this.mobileService
            .getSideNavResponseData()
            .pipe(
                filter((data) => data.type === 'widgets'),
                takeUntil(this.destroy),
                map((data) => data.widgets)
            )
            .subscribe((widgets: Widget[]) => {
                this.widgetsEmpty = widgets.filter((widget) => widget.visible).length <= 0 ? 'hidden' : 'none';
                this.widgets$ = of(widgets);
                this.customOptions = { ...this.customOptions, mouseDrag: !this.customOptions.mouseDrag };
                this.cdr.markForCheck();
            });
    }
    private getSlideWidth(wrapperItemWidth?: number) {
        const wrapperWidth = wrapperItemWidth * 0.9;
        if (this.wrapperItemWidth == wrapperItemWidth) {
            return;
        }
        if (wrapperWidth < 650) {
            this.scrolledItemWidth = wrapperWidth / this.itemsInDiffWidths[0];
        } else if (wrapperWidth > 651 && wrapperWidth < 999) {
            this.scrolledItemWidth = wrapperWidth / this.itemsInDiffWidths[1];
        } else if (wrapperWidth > 1000 && wrapperWidth < 1147) {
            this.scrolledItemWidth = wrapperWidth / this.itemsInDiffWidths[2];
        } else if (wrapperWidth > 1148 && wrapperWidth < 1787) {
            this.scrolledItemWidth = wrapperWidth / this.itemsInDiffWidths[3];
        } else if (wrapperWidth > 1788 && wrapperWidth < 2386) {
            this.scrolledItemWidth = wrapperWidth / this.itemsInDiffWidths[4];
        } else {
            this.scrolledItemWidth = wrapperWidth / this.itemsInDiffWidths[5];
        }
        this.wrapperItemWidth = wrapperItemWidth;
        this.updateSubject.next(true);
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
