<div fxLayoutGap="4px" fxLayout="column" class="w-100 input-field-parent deadline">
    <span i18n>{{ customFieldForm.value.name }}</span>
    <mat-form-field
        appearance="outline"
        floatLabel="never"
        class="w-100"
        fxFlex="grow"
        fxFlex="1 1 100%"
        fxFlex.lt-md="1 1 100%"
    >
        <mat-label i18n>{{ customFieldForm.value.placeholder }}</mat-label>
        <input
            matInput
            [matDatepicker]="deadlineDatepicker"
            (focus)="deadlineDatepicker.open()"
            [formControl]="customFieldForm.get('value') | cast"
        />
        <mat-datepicker-toggle matIconSuffix [for]="deadlineDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #deadlineDatepicker></mat-datepicker>
    </mat-form-field>
</div>
