<div fxFlex="100%" fxLayout="column" class="invite-wrapper-sheet">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.context"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submitAction()"
        (onCancel)="onClose()"
        [showSubmit]="invitationMails.length > 0 && inviteForm.value.subject"
        [savingTitle]="actionText"
        [savingStatus]="isLoading"
    ></app-sticky-header>
    <form fxLayout="column" [formGroup]="inviteForm">
        <div class="input-wrapper" fxLayout="column">
            <span class="label" i18n>To*</span>
            <div fxLayoutAlign="start center" fxLayoutGap="15px" fxLayout="row">
                <mat-form-field class="small-field mt-1 users-rec-input" appearance="fill">
                    <input
                        type="email"
                        matInput
                        i18n-placeholder
                        #userInviteInput
                        placeholder="Search"
                        formControlName="email"
                        (keydown)="handleEmailKeyPress($event, userInviteInput.value)"
                    />
                </mat-form-field>
                <button
                    class="add-mail-invite"
                    [disabled]="!inviteForm.get('email').valid && !invitationMails.includes(userInviteInput.value)"
                    mat-icon-button
                    (click)="addToInvitationEmail(userInviteInput.value)"
                >
                    <mat-icon>outgoing_mail</mat-icon>
                </button>
            </div>
            <mat-chip-list
                *ngIf="invitationMails.length > 0"
                class="reci-chips mb-1"
                #chipList
                aria-label="Recievers selection"
            >
                <mat-chip
                    *ngFor="let invitationMail of invitationMails"
                    (removed)="removeMail(invitationMail)"
                    [selectable]="false"
                >
                    <div fxLayout="row" class="chip" fxLayoutAlign="start center" fxLayoutGap="5px">
                        <mat-icon>mail</mat-icon>
                        <span>{{ invitationMail }}</span>
                    </div>

                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip></mat-chip-list
            >
        </div>
        <div class="input-wrapper" fxLayout="column" fxLayoutGap="10px">
            <div fxLayoutAlign="space-between center">
                <span class="label" i18n>Subject*</span>
                <mat-hint
                    >{{ inviteForm.value.subject?.length }} <span> (128)</span>
                    <mat-icon class="info" i18n-matTooltip matTooltip="Max input characters are 128 letter"
                        >question_mark</mat-icon
                    ></mat-hint
                >
            </div>

            <mat-form-field appearance="fill">
                <input
                    maxlength="128"
                    i18n-placeholder
                    placeholder="Type in the subject for your invitation"
                    #subject
                    matInput
                    formControlName="subject"
                />
                <mat-error i18n>Subject is required</mat-error>
            </mat-form-field>
        </div>
        <div class="input-wrapper" fxLayout="column" fxLayoutGap="10px">
            <app-text-editor
                title="Invitation body"
                i18n-title
                fieldName="course-invite-message"
                formControlName="body"
            ></app-text-editor>
        </div>
    </form>
</div>
