import {AccessCapability} from '../can-access.directive';

export namespace CourseCapability {
    export const NONE = 0;
    export const VIEW_ALL_COURSES = 1 << 0;
    export const MANAGE_ALL_COURSES = 1 << 1;
    export const VIEW_GROUP_COURSES = 1 << 2;
    export const MANAGE_GROUP_COURSES = 1 << 3;
    export const VIEW_CUSTOMER_COURSES = 1 << 4;
    export const MANAGE_CUSTOMER_COURSES = 1 << 5;
    export const MANAGE_PUBLIC_COURSES = 1 << 6;

    export function getName(): string {
        return 'course';
    }

    export function access(value: number): AccessCapability {
        return { name: getName(), value: value };
    }
}
