<app-header class="nk3-header"></app-header>

<div class="nk3-container">
    <article class="nk3-content" role="main">
        <mat-card fxLayout="column" class="nk3-404-card">
            <h2 class="title" i18n>Error!
                <br>You don't have access to this resource, please contact with your administrator.</h2>
            <button mat-raised-button type="button" color="primary" [routerLink]="['/']" i18n>
                Back to Dashboard
            </button>
        </mat-card>
    </article>

    <app-footer></app-footer>
</div>