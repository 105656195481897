<form class="data-wrap" fxLayout="column" [formGroup]="form">
    <div class="title" fxLayoutGap="5px" fxLayout="row wrap" fxLayoutAlign="start center">
        <mat-icon>{{ navData.data.icon }}</mat-icon
        ><span>{{ navData.data.title }}</span>
    </div>

    <div class="desc">{{ navData.data.description }}</div>

    <mat-form-field class="mb-0" floatLabel="never" appearance="fill" fxFlex="0 0 100%">
        <textarea
            maxlength="5000"
            rows="5"
            [placeholder]="navData.data.placeholder"
            #message
            matInput
            formControlName="value"
        ></textarea>
    </mat-form-field>
    <div *ngIf="navData.data.hasMediaUploader" class="alert-media-attach">
        <app-media-attachment
            #uploader
            [maxImgCount]="10"
            formControlName="media_ids"
            [scope]="navData.data.scope"
            [mediaIds]="form.get('media_ids').value"
            [isCreateForm]="true"
            (onUploadOrRemoveCompleted)="buttonDisabled = false"
            type="file"
        ></app-media-attachment>
        <div fxLayoutAlign="center center" *ngIf="uploader.saving | async">
            <app-kd-logo-loading></app-kd-logo-loading>
        </div>
    </div>
    <div class="action">
        <button
            [disabled]="!form.valid || buttonDisabled"
            fxFlex="0 0 auto"
            class="mobile-button"
            (click)="submit()"
            mat-raised-button
            type="button"
        >
            <span>{{ navData.data.btnLabel }}</span>
        </button>
    </div>
</form>
