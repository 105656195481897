<mat-card class="mt-2" fxFlex="0 0 100%">
    <mat-card-content>
        <div fxFlex="1 1 100%" fxLayoutAlign="center" fxLayout="column wrap" fxLayoutAlign="center" class="mt-1 mb-1">
            <form
                #formDirective="ngForm"
                fxLayout="row wrap"
                (ngSubmit)="submitScorm(formDirective)"
                [formGroup]="scormForm"
            >
                <div fxFlex="0 0 100%">
                    <mat-form-field fxFlex="0 1 100%">
                        <input matInput placeholder="SCORM title" i18n-placeholder formControlName="title" required />
                        <mat-error i18n>Title field is required!</mat-error>
                    </mat-form-field>
                    <div fxLayoutAlign="center center" fxFlex="0 0 40px" class="coursePlan-layout">
                        <app-language-selector
                            formControlName="language_id"
                            class="icon-resource-builder"
                            fxFlex="0 0 auto"
                        ></app-language-selector>
                    </div>
                </div>

                <app-tag-chip-select
                    fxFlex="0 1 100%"
                    [resource_id]="resource?.id"
                    [language_id]="scormForm?.get('language_id').value"
                    formControlName="tag_ids"
                ></app-tag-chip-select>

                <app-category-chip-select
                    fxFlex="0 1 100%"
                    [resource_id]="resource?.id"
                    [language_id]="scormForm?.get('language_id').value"
                    formControlName="category_ids"
                    [type_id]="ResourceTypes.SCORM"
                ></app-category-chip-select>

                <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                    <mat-expansion-panel #expansionPanelImg>
                        <mat-expansion-panel-header>
                            <div *ngIf="!expansionPanelImg.expanded" i18n="@@coverImg">Cover image</div>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <div fxLayoutAlign="center center" class="upload-img-test">
                                <app-media
                                    #app_media
                                    *ngIf="!scormForm.get('media_id')?.value"
                                    fxFlex="1 1 40%"
                                    fxFlex.lt-md="1 1 100%"
                                    formControlName="media_id"
                                    [scope]="AppScope.TEST"
                                    dropLabel="Drop course image"
                                    i18n-dropLabel="@@dropCourseImage"
                                ></app-media>
                                <app-image-view
                                    (click)="
                                        showImgModal(
                                            scormForm.get('media_id')?.value
                                                ? scormForm.get('media_id')?.value
                                                : 'assets/images/Default_courses_img.png'
                                        )
                                    "
                                    class="mt-2 mb-2"
                                    *ngIf="scormForm.get('media_id')?.value"
                                    fxFlex="0 1 50%"
                                    fxFlex.lt-md="0 1 100%"
                                    [media_id]="
                                        scormForm.get('media_id')?.value
                                            ? scormForm.get('media_id')?.value
                                            : 'assets/images/Default_courses_img.png'
                                    "
                                    paddingBottom="50%"
                                >
                                    <button
                                        class="remove-image"
                                        mat-icon-button
                                        color="warn"
                                        *ngIf="scormForm.get('media_id').value as mediaId"
                                        matTooltip="Remove image"
                                        i18n-matTooltip
                                        (click)="scormForm.get('media_id').setValue(null)"
                                    >
                                        <mat-icon class="md-24">delete_outline</mat-icon>
                                    </button>
                                </app-image-view>
                            </div>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                    <mat-expansion-panel #expansionPanel>
                        <mat-expansion-panel-header>
                            <div *ngIf="!expansionPanel.expanded" i18n="@@description">Description</div>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <app-text-editor
                                *ngIf="active"
                                fxFlex="1 1 95%"
                                title="Content description"
                                i18n-title
                                [showTemplates]="false"
                                formControlName="description"
                            ></app-text-editor>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
                <mat-accordion fxFlex="0 1 100%" displayMode="flat">
                    <mat-expansion-panel #expansionPanelPrio>
                        <mat-expansion-panel-header>
                            <div *ngIf="!expansionPanelPrio.expanded" i18n="@@priority">Priority</div>
                        </mat-expansion-panel-header>
                        <ng-template matExpansionPanelContent>
                            <mat-form-field fxFlex="1 1 95%">
                                <input
                                    matInput
                                    fxLayoutAlign="end center"
                                    type="number"
                                    placeholder="Please set priority"
                                    i18n-placeholder="@@plsSetPriority"
                                    formControlName="priority"
                                />
                            </mat-form-field>
                        </ng-template>
                    </mat-expansion-panel>
                </mat-accordion>
                <div fxFlex="0 0 100%" fxLayout="row" fxLayoutAlign="end" fxLayoutGap=".5em">
                    <button
                        *ngIf="!(saving | async) && !publishOnly"
                        [disabled]="scormForm.invalid"
                        mat-raised-button
                        color="primary"
                        type="submit"
                        i18n="@@save"
                    >
                        Save
                    </button>

                    <button
                        *ngIf="!(saving | async) && (isAdmin$ | async) && !resource?.published"
                        mat-raised-button
                        [disabled]="scormForm.invalid"
                        (click)="submitScorm(formDirective, true)"
                        color="primary"
                        i18n="@@publish"
                    >
                        Publish
                    </button>
                    <app-saving-button *ngIf="saving | async"></app-saving-button>
                </div>
            </form>
        </div>
    </mat-card-content>
</mat-card>
