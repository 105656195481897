import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceTypes } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    GlobalFilterPropertyType,
    SelectFilterOption,
    StatusFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { Subject, Observable } from 'rxjs';
import { takeUntil, map, tap, finalize, filter } from 'rxjs/operators';
import { LogUsersResourcesService } from './log-users-resources.service';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityLogService } from '@klickdata/core/activity-log';

@Component({
    selector: 'app-shared-action-log-resources',
    templateUrl: './shared-action-log-resources.component.html',
    styleUrls: ['./shared-action-log-resources.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LogUsersResourcesService],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class SharedActionLogResourcesComponent implements OnInit, AfterViewInit, OnDestroy {
    ResourceTypes = ResourceTypes;
    public predefinedTimeSpentOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;
    @Input() tableFilterItems: Filter<string | number>[];
    public dataSource = new TableSource<Resource>();
    public columns: string[] = ['image', 'title', 'last_action', 'type', 'language_id', 'tools'];
    public isMobile: boolean;
    public destroy: Subject<boolean> = new Subject<boolean>();
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    AppScope = AppScope;
    public resourceStatusOptions: StatusFilterOption[];
    public resourceTypeOptions: SelectFilterOption[];
    public resourceEventOptions: SelectFilterOption[];
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    public expandedRow: Resource | null;
    public expandedCell: Resource | null;
    public isMasterPlatform$: Observable<boolean>;
    public customer: Customer;

    constructor(
        protected resourceService: LogUsersResourcesService,
        protected mobile: MobileService,
        protected auth: AuthService,
        protected cdr: ChangeDetectorRef,
        protected route: ActivatedRoute,
        protected router: Router,
        protected activityLogService: ActivityLogService
    ) {
        this.predefinedTimeSpentOptions = Utils.getPredefinedTimeSpentOptions();
        this.resourceTypeOptions = Utils.getResourceTypeOptions();
        this.resourceEventOptions = Utils.getActionLogEventOptions().filter((option) => option.data.isResRelated);
        this.isMasterPlatform$ = auth.getNK3Platform().pipe(map((platform) => platform === 'master'));
    }
    public ngOnInit() {
        this.dataSource.service = this.resourceService;
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMobile) => (this.isMobile = isMobile));
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.route.queryParams
            .pipe(
                takeUntil(this.destroy),
                filter((params) => params.resName)
            )
            .subscribe((params) => this.onSearchFilterClick(GlobalFilterProperty.SEARCH, params.resName));
        if (this.customer && this.filter) {
            this.filter.createOrUpdateFilter([new Filter('customer', [this.customer.id])]);
        }
    }
    public onItemClick(filter: GlobalFilterProperty, value: number | string) {
        typeof value === 'string'
            ? this.filter.setActiveFilterWithSelectedOption(filter, <string>value)
            : this.filter.setActiveFilterWithSelectedOption(filter, ResourceTypes.scopeByChildType(<number>value));
    }

    public onSearchFilterClick(filter: GlobalFilterProperty, query: string) {
        this.filter.setActiveFilterWithQuery(filter, query);
    }
    public viewResResults(res: Resource) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.USER_PROGRESS,
            data: {
                resource: res,
                logs: this.activityLogService.getUserRelatedResourceActivities({ resource: res.id }),
            },
        });
    }

    public viewRes(res: Resource) {
        this.router.navigate(['/home/dashboard/resource-details/' + res.id]);
    }
    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
