import { Injectable } from '@angular/core';
import { ConfigService } from '@klickdata/core/config/src/config.service';
import { Observable } from 'rxjs';
import { RequestBuilderService } from '@klickdata/core/http/src/request/request-builder.service';
import { map } from 'rxjs/operators';
import { Question, QuestionData } from '@klickdata/core/question/src/question';
import { AlternativeService } from '@klickdata/player/src/core/question/alternative/alternative.service';

@Injectable()
export class QuestionService {
    protected resourceUrl: string;

    constructor(
        protected config: ConfigService,
        protected builder: RequestBuilderService,
        protected alternativeService: AlternativeService
    ) {
        this.resourceUrl = `${this.config.config.resourceUrl}questions`;
    }

    public getQuestionByItem(id: number, opportunityId: number): Observable<Question> {
        return this.builder
            .get<QuestionData>(`${this.resourceUrl}/findByItem/${id}`)
            .param('opportunity', `${opportunityId}`)
            .request()
            .pipe(map(res => this.createQuestion(res.data)));
    }

    public getQuestion(id: number): Observable<Question> {
        return this.builder
            .get<QuestionData>(`${this.resourceUrl}/${id}`)
            .request()
            .pipe(map(res => this.createQuestion(res.data)));
    }

    protected mapQuestions(data: QuestionData[]): Question[] {
        return data.map(item => this.createQuestion(item));
    }

    protected createQuestion(data: QuestionData): Question {
        const question = new Question(data);
        question.alternatives$ = this.alternativeService.getAlternatives(question.id);
        return question;
    }
}
