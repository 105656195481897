import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';

@Pipe({
    name: 'connectionStatus',
})
export class ConnectionStatusPipe implements PipeTransform {
    transform(user?: User): any {
        if (user) {
            if (!user.activated) {
                return {
                    fg: 'rgba(0, 0, 0, 0.5)',
                    bg: '#fff',
                    label: $localize`Not activated yet`,
                };
            }
            switch (user.role_value) {
                case 'masteradmin':
                case 'superadmin':
                    return {
                        fg: '#fff',
                        bg: '#ff9961', //orange
                        label: $localize`Master Admin`,
                    };
                case 'customeradmin':
                    return {
                        fg: '#3e5365',
                        bg: '#bfd8d0', //light blue or ice-blue
                        label: $localize`Customer admin`,
                    };
                case 'groupadmin':
                    return {
                        fg: '#3e5365',
                        bg: '#bfd8d0', //light blue or ice-blue
                        label: $localize`Admin`,
                    };
                case 'user':
                    return {
                        fg: '#fff',
                        bg: '#3e5365', //Dark blue
                        label: $localize`Learner`,
                    };
                case 'staff':
                    return {
                        fg: '#3e5365', //Grey
                        bg: '#bfd8d0', //Green
                        label: $localize`Staff`,
                    };
            }
            return {
                fg: '#3e5365',
                bg: '#fff',
                label: '',
            };
        }
    }
}
