import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'caseStatusActions',
})
export class CaseStatusActionsPipe implements PipeTransform {
    transform(caseStatus: string, isTeacher: boolean): { action: string; warning: string }[] {
        switch (caseStatus) {
            case 'open':
                return isTeacher
                    ? [
                          {
                              action: 'close',
                              warning: $localize`By clicking on the ok button below, the case will be closed.`,
                          },
                      ]
                    : [
                          {
                              action: 'done',
                              warning: $localize`By clicking on the ok button below, the case will be submitted.`,
                          },
                      ];
            case 'reopen':
                return isTeacher
                    ? [
                          {
                              action: 'close',
                              warning: $localize`By clicking on the ok button below, the case will be closed.`,
                          },
                      ]
                    : [
                          {
                              action: 'done',
                              warning: $localize`By clicking on the ok button below, the case will be submitted.`,
                          },
                      ];
            case 'approved':
                return [];
            case 'not_approved':
                return isTeacher ? [
                    {
                        action: 'reopen',
                        warning: $localize`By clicking on the ok button below, the case will be reopened.`,
                    },
                    {
                        action: 'close',
                        warning: $localize`By clicking on the ok button below, the case will be closed.`,
                    },
                    {
                        action: 'approved',
                        warning: $localize`By clicking on the ok button below, the case will be considered as approved.`,
                    },
                ] : [];
            case 'done':
                return isTeacher
                    ? [
                          {
                              action: 'approved',
                              warning: $localize`By clicking on the ok button below, the case will be considered as approved.`,
                          },
                          {
                              action: 'not_approved',
                              warning: $localize`By clicking on the ok button below, the case will be considered as rejected.`,
                          },
                      ]
                    : [];
            case 'close':
                return isTeacher
                    ? [
                          {
                              action: 'reopen',
                              warning: $localize`By clicking on the ok button below, the case will be reopened.`,
                          },
                      ]
                    : [];
        }
    }
}
