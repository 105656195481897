import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SideNaveData } from '@klickdata/core/mobile';
import { SideNaveResponseData } from '@klickdata/core/mobile/src/mobile.service';

@Component({
    selector: 'app-menu-side-event-join',
    templateUrl: './menu-side-event-join.component.html',
    styleUrls: ['./menu-side-event-join.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSideEventJoinComponent implements OnInit {
    @Input() public navData: SideNaveData;
    @Output() onClose: EventEmitter<SideNaveResponseData> = new EventEmitter();
    public eventOccasionId = new FormControl();
    public requestValue = new FormControl();
    constructor() {}

    ngOnInit(): void {
        if (this.navData.data.resource.occasion_dates == 1) {
            this.eventOccasionId.patchValue(this.navData.data.resource.occasion_dates[0].id);
        }
    }
}
