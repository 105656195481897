import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { CapabilityService } from '../capabilities/capability.service';
import { GeneralCapability } from '../capabilities/capability/general-capability.enum';
import { AuthService } from '../token/auth.service';

@Injectable({
    providedIn: 'root',
})
export class KlickdataGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(protected capability: CapabilityService, protected router: Router, protected token: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.check();
    }

    canLoad(route: Route) {
        return this.check();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.check();
    }

    private check(): Observable<boolean> {
        return this.capability.check(GeneralCapability.getName(), GeneralCapability.VIEW_KLICK_DATA).pipe(
            first(),
            map(result => {
                if (!result) {
                    /**
                     * When user has no capability to view klickdata home,
                     * This token for special purpose.
                     * Like take course plan from invitation link.
                     */
                    if (['', '/', '/unauthorised'].some(url => url === this.router.url)) {
                        this.token.destroyToken();
                        return true;
                    }
                    this.router.navigate(['/unauthorised']);
                }

                return result;
            })
        );
    }
}
