<form [formGroup]="editForm.form" (ngSubmit)="onSubmit()" novalidate>
    <mat-card class="form-contents" fxLayout="row wrap">
        <app-form-error
            fxFlex="0 0 100%"
            header="Error"
            i18n-header
            [messages]="(error | async)?.error?.error?.messages"
        >
        </app-form-error>

        <mat-form-field fxFlex="0 0 100%">
            <input matInput placeholder="Title" type="text" formControlName="title" required />
        </mat-form-field>

        <mat-form-field fxFlex="0 0 100%">
            <input matInput placeholder="Subject/Title" type="text" formControlName="subject" />
        </mat-form-field>

        <mat-form-field
            fxFlex="0 1 300px"
            fxFlex.lt-sm="0 0 100%"
            *ngIf="groups | async; else loadingGroups; let loadedGroups"
        >
            <mat-select placeholder="Groups" [formControl]="editForm.get('group_ids') | cast" multiple>
                <!-- Add select all/ deselect all -->
                <mat-option disabled="disabled" class="filter-option">
                    <button
                        [disabled]="editForm.value.group_ids?.length === loadedGroups.length"
                        fxFlex="0 0 auto"
                        type="button"
                        color="primary"
                        mat-raised-button
                        (click)="selectAllGroups(loadedGroups)"
                        i18n="@@selectAll"
                    >
                        Select All
                    </button>
                    <div fxFlex="1 0 auto"></div>
                    <button
                        [disabled]="editForm.value.group_ids?.length === 0"
                        fxFlex="0 0 auto"
                        type="button"
                        color="primary"
                        mat-raised-button
                        (click)="editForm.get('group_ids').patchValue([])"
                        i18n="@@deselectAll"
                    >
                        Deselect All
                    </button>
                </mat-option>
                <mat-divider></mat-divider>
                <mat-option *ngFor="let item of loadedGroups" [value]="item.id">
                    {{ item.name }}
                </mat-option>
            </mat-select>
            <mat-hint *ngIf="loadedGroups.length === 0" i18n="@@noGroupsAvailable"> No groups available </mat-hint>
        </mat-form-field>

        <app-text-editor-editor
            fxFlex="0 1 100%"
            formControlName="body"
            title="Template content"
            [fieldName]="fieldName"
            i18n-title="@@templateContent"
        ></app-text-editor-editor>

        <mat-card-actions fxFlex="0 0 100%" fxLayout="row">
            <button
                *ngIf="template && template.editable"
                (click)="onDelete()"
                color="warn"
                fxFlex="0 0 auto"
                type="button"
                mat-icon-button
            >
                <mat-icon>delete</mat-icon>
            </button>

            <div fxFlex="1 0 auto"></div>
            <ng-container *ngIf="loading">
                <app-saving-button></app-saving-button>
            </ng-container>

            <button fxFlex="0 0 auto" type="button" mat-button (click)="onCancel()" i18n="@@cancel">Cancel</button>

            <button
                fxFlex="0 0 auto"
                *ngIf="!editForm.model.id"
                [disabled]="loading || !tempChanged"
                type="submit"
                color="primary"
                mat-raised-button
                (click)="create()"
                i18n="@@create"
            >
                Create
            </button>

            <button
                fxFlex="0 0 auto"
                *ngIf="template && template.editable"
                [disabled]="loading || !tempChanged"
                type="submit"
                color="primary"
                mat-raised-button
                i18n="@@save"
            >
                Save
            </button>

            <button
                fxFlex="0 0 auto"
                *ngIf="!!editForm.model.id"
                [disabled]="loading || !tempChanged"
                type="submit"
                color="primary"
                mat-raised-button
                (click)="create()"
                i18n="@@saveNew"
            >
                Save New
            </button>

            <button
                fxFlex="0 0 auto"
                [disabled]="loading"
                type="button"
                color="primary"
                mat-raised-button
                (click)="onUseTemplate()"
                i18n="@@useTemplate"
            >
                Use Template
            </button>
        </mat-card-actions>
    </mat-card>
</form>

<ng-template #loadingGroups>
    <mat-form-field fxFlex="0 0 300px" fxFlex.lt-sm="0 0 100%">
        <mat-select disabled="true" placeholder="Loading groups" i18n-placeholder="@@loadingGroups"></mat-select>
    </mat-form-field>

    <app-kd-logo-loading size="small"></app-kd-logo-loading>
</ng-template>
