<ng-container *ngIf="gateway">
    <button *ngIf="!(isLoading$ | async); else loading" mat-button (click)="pay()" class="submit">
        <span i18n>Pay with</span>
        <img [src]="'assets/images/' + gateway + '_logo.png'" />
    </button>
    <ng-container *ngIf="resource && gateway == 'paytabs'">
        <div class="fxr" *ngIf="resource.currency !== 'EGP' && customer.getFXR(resource.currency, 'EGP'); let fxr">
            <mat-icon>currency_exchange</mat-icon>
            <span fxLayoutAlign="center center" fxLayoutGap="5px">
                <span>1</span>
                <span>{{ fxr.from }}</span>
                <span>=</span>
                <span>{{ fxr.fxr }}</span>
                <span>{{ fxr.to }}</span>
            </span>
        </div>
    </ng-container>
</ng-container>
<ng-template #loading>
    <app-kd-logo-loading></app-kd-logo-loading>
</ng-template>
