import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '@klickdata/core/auth';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { User, UserService } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { PreferredLangsSelectComponent } from '@klickdata/shared-components/src/preferred-langs-select/preferred-langs-select.component';
import { combineLatest, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-user-perosonal-settings-and-language',
    templateUrl: './user-perosonal-settings-and-language.component.html',
    styleUrls: ['./user-perosonal-settings-and-language.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPerosonalSettingsAndLanguageComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild(PreferredLangsSelectComponent) prefLangSelectComp: PreferredLangsSelectComponent;
    @Input()
    public set user(user: User) {
        this._user = user;
        if (user && user.pref_lang_ids) {
            this.preferredContentLanguages.setValue(user.pref_lang_ids);
        }
    }
    public get user(): User {
        return this._user;
    }
    private _user: User;
    public authUser: User;
    public preferredContentLanguages = new FormControl();
    private destroy: Subject<boolean> = new Subject<boolean>();
    constructor(protected userService: UserService, protected auth: AuthService, protected message: MessageService) {}
    ngOnInit(): void {
        this.auth
            .getUser()
            .pipe(takeUntil(this.destroy))
            .subscribe((user) => (this.authUser = user));
    }
    ngAfterViewInit(): void {
        combineLatest([this.preferredContentLanguages.valueChanges, this.prefLangSelectComp.openedChange])
            .pipe(
                takeUntil(this.destroy),
                filter(([langs, isPanelOpen]) => !isPanelOpen),
                debounceTime(300),
                distinctUntilChanged((f1, f2) => Utils.arraysEqual(f1[0], f2[0])),
                switchMap(([langs, isPanelOpen]) =>
                    this.userService.update({
                        id: this.user.id,
                        pref_lang_ids: langs,
                    })
                ),
                catchError((err) => {
                    if (err && err.error && err.error.error) {
                        this.message.openMessage(MessageErrorComponent, err.error.error.messages);
                    }
                    return of(null);
                })
            )
            .subscribe((updatedUser) => {
                if (this.authUser.id === updatedUser.id) {
                    this.auth.refreshToken();
                }
            });
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
