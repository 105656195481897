<div class="viewer-wrapper" *ngFor="let section of sections">
    <app-section-item-parent
        class="dashboard-section-wrapper"
        [title]="section.title"
        [attachedLanguages]="section.pref_lang_ids"
        [param]="{ section: section.id, nk3_platform: 'user' }"
        [sectionType]="SectionType.REGULAR_SECTION"
        [service]="service"
    ></app-section-item-parent>
</div>
