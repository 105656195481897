import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityLogService } from '@klickdata/core/activity-log';
import { ActivityLog } from '@klickdata/core/activity-log/src/activity-log.model';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import { MobileService } from '@klickdata/core/mobile';
import { AppScope } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    SelectFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ActionLogListingService } from './action-log-listing.service';

@Component({
    selector: 'app-shared-action-log-overall',
    templateUrl: './shared-action-log-overall.component.html',
    styleUrls: ['./shared-action-log-overall.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ActionLogListingService],
})
export class SharedActionLogOverallComponent implements OnInit, OnDestroy, AfterViewInit {
    public destroy: Subject<boolean> = new Subject<boolean>();
    public dataSource = new TableSource<ActivityLog>();
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    public columns = ['created_at', 'user', 'email', 'role', 'scope_id', 'action'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    public predefinedTimeSpentOptions: SelectFilterOption[];
    public userRoleOptions: SelectFilterOption[];
    public functionOptions: SelectFilterOption[];
    public resourceEventOptions: SelectFilterOption[];
    public isMasterPlatform: boolean;
    @Input() user: User;
    public customer: Customer;
    public expandedRow: ActivityLog | null;
    public isMobile: boolean;

    constructor(
        protected activityService: ActionLogListingService,
        protected activityLogService: ActivityLogService,
        protected route: ActivatedRoute,
        protected router: Router,
        protected auth: AuthService,
        protected cdr: ChangeDetectorRef,
        protected mobile: MobileService
    ) {
        this.predefinedTimeSpentOptions = Utils.getPredefinedTimeSpentOptions();
        this.userRoleOptions = Utils.getUserRoleOptions();
        this.functionOptions = Utils.getfunctionOptions();
        this.resourceEventOptions = Utils.getActionLogEventOptions();
    }
    ngOnInit() {
        this.dataSource.service = this.activityService;
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy))
            .subscribe((isMobile) => (this.isMobile = isMobile));
        this.auth
            .getNK3Platform()
            .pipe(
                map((platform) => platform === 'master'),
                takeUntil(this.destroy)
            )
            .subscribe((isMasterPlatform) => {
                if (isMasterPlatform) {
                    this.isMasterPlatform = isMasterPlatform;
                    this.columns.splice(1, 0, 'customer');
                    this.cdr.markForCheck();
                }
            });
    }
    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        if (this.filter) {
            if (this.user) {
                this.filter.createOrUpdateFilter([new Filter('user', [this.user.id])]);
                this.filter.setActiveFilter(GlobalFilterProperty.LOG_EVENT);
                this.columns.splice(1, 3);
                this.cdr.markForCheck();
            } else if (this.customer) {
                this.filter.createOrUpdateFilter([new Filter('customer', [this.customer.id])]);
                this.filter.setActiveFilter(GlobalFilterProperty.LOG_EVENT);
                this.columns.splice(1, 3);
                this.cdr.markForCheck();
            } else {
                this.filter.setActiveFilter(GlobalFilterProperty.USERS);
                // There is no lang filter to init the request, so you need to
                // init it manually by calling .refresh
                this.dataSource.refresh();
            }
        }
        this.route.queryParams
            .pipe(
                takeUntil(this.destroy),
                filter((params) => params.userName)
            )
            .subscribe((params) => this.onSearchFilterClick(GlobalFilterProperty.USERS, params.userName));
    }
    onUserEmailClick(activity: ActivityLog, isMA?: boolean) {
        if (isMA) {
            this.router.navigate(['/master/customers/k3-connections/connection/', activity.customer.id]);
        } else {
            this.router.navigateByUrl('admin/accounts/users/users/' + activity.user.id + '/overview');
        }
    }
    public onSearchFilterClick(filter: GlobalFilterProperty, query: string) {
        this.filter.setActiveFilterWithQuery(filter, query);
    }
    public onItemClick(filter: GlobalFilterProperty, value: string) {
        this.filter.setActiveFilterWithSelectedOption(filter, value);
    }
    public onViewAcademy(id?: number) {
        if (id) {
            this.router.navigate(['/master/customers/customer-manager/', id]);
        }
    }
    public onEventClick(title: string, scope: number, isMA: boolean) {
        if (AppScope.isResource(scope)) {
            this.router.navigate(
                [isMA ? '/master/stats/action-log/resources' : '/admin/statistics/action-log/resources'],
                {
                    queryParams: {
                        resName: title,
                    },
                }
            );
        } else if (scope === AppScope.USERS) {
            // this.onSearchFilterClick(GlobalFilterProperty.USERS, title);

            this.router.navigate([isMA ? '/master/stats/action-log/users' : '/admin/statistics/action-log/users'], {
                queryParams: {
                    userName: title,
                },
            });
        }
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
