import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@klickdata/core/config';
import { HttpModule } from '@klickdata/core/http';
import { GradeSystemService } from '@klickdata/core/grade-system/src/grade-system.service';

@NgModule({
    imports: [
        CommonModule,

        ConfigModule,
        HttpModule,
    ],
    providers: [
        GradeSystemService,
    ]
})
export class GradeSystemModule {}
