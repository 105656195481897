<div fxLayoutGap="4px" class="input-wrapper-user default-select-user" fxLayout="column">
    <span class="label">{{ titleLabel }}</span>
    <div *ngIf="!hideInput" fxLayout="column" fxLayoutGap="10px" fxLayoutGap.lt-md="10px">
        <mat-form-field class="small-field users-rec-input" appearance="fill">
            <input
                type="text"
                matInput
                #userInput
                i18n-placeholder
                placeholder="Search"
                [formControl]="userCtrl"
                [matAutocomplete]="autoUser"
                (keyup.enter)="$event.stopPropagation(); addDraftUser()"
                (keyup.,)="addDraftUser()"
            />
            <mat-autocomplete #autoUser="matAutocomplete" (optionSelected)="selectUser($event.option.value)">
                <mat-option *ngFor="let user of userSearch$ | async" [value]="user">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <span>{{ user.name }}</span>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <span class="input-hint" *ngIf="hint">{{ hint }}</span>
    </div>
    <div fxLayout="column wrap" [ngClass]="{ 'mt-1': !!users.length }">
        <mat-chip-list *ngIf="users.length" class="reci-chips mb-1" #chipList aria-label="Recievers selection">
            <mat-chip
                *ngFor="let user of users"
                (removed)="removeUser(user)"
                [selectable]="false"
                class="users-wrap customer"
            >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <span>{{ user?.name }}</span>
                </div>
                <button *ngIf="chipRemovable" matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip></mat-chip-list
        >
    </div>
    <div *ngIf="hasDrafts" fxLayout="column wrap">
        <mat-chip-list *ngIf="draftUsers.length" class="draft-chips mb-1" #chipList aria-label="Recievers selection">
            <mat-chip
                *ngFor="let draftUser of draftUsers"
                (removed)="removeUser(draftUser)"
                [selectable]="false"
                class="users-wrap customer"
            >
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <span>{{ draftUser }}</span>
                </div>
                <button *ngIf="chipRemovable" matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip></mat-chip-list
        >
    </div>
</div>
