import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Filter } from '@klickdata/core/table';

@Component({
    selector: 'app-resources-competence-list-sheet',
    templateUrl: './resources-competence-list-sheet.component.html',
    styleUrls: ['./resources-competence-list-sheet.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourcesCompetenceListSheetComponent implements OnInit {
    public mandatoryTableFilterItems: Filter<string | number>[];
    constructor(
        public bottomSheetRef: MatBottomSheetRef<ResourcesCompetenceListSheetComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {}

    ngOnInit(): void {
        this.mandatoryTableFilterItems = [
            new Filter('user', [this.data.id]),
            new Filter('relationType', ['mandatory']),
        ];
    }
    onCancel() {
        this.bottomSheetRef.dismiss();
    }
}
