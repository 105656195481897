<app-admin-menu></app-admin-menu>
<app-version-alert *ngIf="notifications.indexOf('version-alert') > -1"></app-version-alert>
<mat-toolbar color="primary" fxLayout="column">
    <div color="primary" fxLayoutAlign="space-between center" class="nk3-toolbar header">
        <ng-container *ngIf="customerLanding$ | async; let customerLanding; else: smallLoading">
            <app-logotype
                class="header-mobile-logo"
                [image]="customerLanding.logotype_url"
                [padding]="customerLanding.logotype_padding"
                [alt]="customerLanding.title"
            ></app-logotype>
        </ng-container>

        <ng-content></ng-content>

        <div class="header-actions" fxLayout="row" fxLayoutAlign="center center">
            <ng-container *ngIf="authenticated | async">
                <button
                    *ngIf="!(isMasterPlatform$ | async)"
                    class="link-material-header-btn"
                    mat-icon-button
                    i18n-matTooltip
                    matTooltip="Create link material"
                    (click)="addLink()"
                >
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
                <button
                    *ngIf="isMasterPlatform$ | async"
                    class="link-material-header-btn"
                    mat-icon-button
                    [matMenuTriggerFor]="addInMA"
                    matTooltipShowDelay="1000"
                >
                    <mat-icon>add_circle_outline</mat-icon>
                </button>
                <app-general-chat-gpt></app-general-chat-gpt>
                <!-- <app-general-recorder [user]="user$ | async"></app-general-recorder> -->
            </ng-container>
            <div class="mobile-menu" fxLayout="row" fxLayoutAlign="center center">
                <button mat-icon-button (click)="isSearching = !isSearching">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
            <ng-container *ngIf="authenticated | async">
                <app-post fxLayout="row" fxLayoutAlign="center center"></app-post>
                <ng-container *ngIf="user$ | async; let user">
                    <app-image-view
                        *ngIf="user.media_id"
                        [matMenuTriggerFor]="profile"
                        [ngStyle]="{ minWidth: '24px' }"
                        [isCircularImg]="true"
                        [media_id]="user.media_id"
                        paddingBottom="24px"
                    ></app-image-view>
                    <button
                        class="user-icon"
                        *ngIf="!user.media_id"
                        mat-icon-button
                        [matMenuTriggerFor]="profile"
                        matTooltipShowDelay="1000"
                    >
                        <mat-icon>person</mat-icon>
                    </button>
                </ng-container>
            </ng-container>
            <mat-menu class="menu-header-options" #profile="matMenu" [overlapTrigger]="false">
                <a mat-menu-item [routerLink]="['/home/user-profile-settings']">
                    <mat-icon>settings</mat-icon>
                    <ng-container i18n="@@settings">Settings</ng-container>
                </a>

                <a mat-menu-item (click)="reset()">
                    <mat-icon>autorenew</mat-icon>
                    <ng-container i18n>Reload</ng-container>
                </a>
                <a mat-menu-item (click)="logout()">
                    <mat-icon>logout</mat-icon>
                    <ng-container i18n>Logout</ng-container>
                </a>
                <a [routerLink]="['/changelog']" class="version" mat-menu-item>
                    <ng-container *ngIf="version$ | async; let ver">
                        {{ ver.version.slice(0, ver.version.lastIndexOf('-')) }} -
                        {{ ver.date | date : 'MMM d' }}
                    </ng-container>
                </a>
            </mat-menu>
            <mat-menu class="menu-header-options" #addInMA="matMenu" [overlapTrigger]="false">
                <a
                    mat-menu-item
                    [routerLink]="['/master/customers/k3-customers']"
                    [queryParams]="{ action: 'addClient' }"
                >
                    <mat-icon>supervised_user_circle</mat-icon>
                    <ng-container>Client</ng-container>
                </a>
                <a
                    mat-menu-item
                    [routerLink]="['/master/customers/k3-connections']"
                    [queryParams]="{ action: 'addConnection' }"
                >
                    <mat-icon>spoke</mat-icon>
                    <ng-container>Connection</ng-container>
                </a>
                <a mat-menu-item [routerLink]="['/master/customers/k3-notes']" [queryParams]="{ action: 'addNote' }">
                    <mat-icon>document_scanner</mat-icon>
                    <ng-container>Notes</ng-container>
                </a>
                <a mat-menu-item [routerLink]="['/master/customers/k3-crm']" [queryParams]="{ action: 'addTask' }">
                    <mat-icon>today</mat-icon>
                    <ng-container>Task</ng-container>
                </a>
            </mat-menu>
            <!-- <div class="mobile-menu">
                <app-language-selector [user]="user$ | async"></app-language-selector>
            </div> -->
            <!-- <div *ngIf="authenticated | async" class="mobile-menu">
                <button mat-icon-button (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                </button>
            </div> -->

            <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!(authenticated | async)" class="mobile-menu">
                <button mat-icon-button routerLink="/guest/login">
                    <mat-icon>login</mat-icon>
                </button>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" *ngIf="authenticated | async" class="mobile-menu">
                <button (click)="onToggleMenu()" mat-icon-button>
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
        </div>
    </div>
</mat-toolbar>
<!-- <div class="mobile-search-wrapper" *ngIf="isSearching">
    <div fxFlex="1 0 100%" fxLayoutAlign="start center" fxLayout="row" class="mobile-search">
        <app-search></app-search>
        <button class="mobile-menu" fxFlex="0 0 auto" mat-icon-button (click)="onSearch()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div> -->
<app-main-search (dismissSearchArea)="isSearching = !isSearching" *ngIf="isSearching"></app-main-search>
<ng-template #smallLoading>
    <app-kd-logo-loading class="small-load-header" size="small"></app-kd-logo-loading>
</ng-template>
