import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormHelper } from '@klickdata/core/form';
import { Resource, ResourceService } from '@klickdata/core/resource';
import { Utils } from '@klickdata/core/util';
import * as moment from 'moment';

@Component({
    selector: 'app-edit-resource-field',
    templateUrl: './edit-resource-field.component.html',
    styleUrls: ['./edit-resource-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditResourceFieldComponent implements OnInit {
    @Input() resourceField: string;
    @Input()
    public set resource(resource: Resource) {
        this._resource = resource;
        this.initControl();
    }
    public get resource(): Resource {
        return this._resource;
    }
    public currencies: any;
    private _resource: Resource;
    public timeOptions: any;
    public editMode = false;
    public fieldFormControl = new FormControl();
    constructor(protected resourceService: ResourceService, protected cd: ChangeDetectorRef) {
        this.currencies = Utils.getAllCurrencies();
    }

    ngOnInit() {
        this.timeOptions =
            this.resourceField === 'time_limit'
                ? {
                      zeroValue: null,
                      showPreview: false,
                      showYears: false,
                      showSeconds: false,
                  }
                : {
                      zeroValue: null,
                      showPreview: false,
                      showYears: false,
                      showMonths: false,
                      showDays: false,
                      showWeeks: false,
                  };
    }
    protected initControl() {
        this.fieldFormControl.patchValue(this.resource[this.resourceField]);
    }
    onControlSubmit() {
        if (this.fieldFormControl.invalid) {
            FormHelper.markForm(this.fieldFormControl);
            return;
        }
        this.resourceService
            .update({
                id: this.resource.id,
                [this.resourceField]: this.getResourceFieldValue(),
            })
            .subscribe((res: Resource) => {
                this.editMode = false;
                this.fieldFormControl.patchValue(res[this.resourceField]);
                this.cd.markForCheck();
            });
    }

    getResourceFieldValue() {
        return this.resourceField === 'duration' || this.resourceField === 'time_limit'
            ? moment.duration(this.resource[this.resourceField]).asSeconds()
            : this.fieldFormControl.value;
    }
    updateTimeLabel(value) {
        return moment.duration(value).toISOString();
    }
}
